// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  userName,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  DatePicker,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { mainBody } from "../../../../Style/Global/Global";

// Import Global Styles
// Table's Column

const dataSource = [
  {
    key: "1",
    inventory_no: "res/123/123",
    inventory_name: "Kulkas",
    uom: "Pieces",
    dunit: "Pieces",
    content: "Pieces",
    runit: "Pieces",
    min_stock: "",
    actual_price: 11000,
    average_price: 12000,
    created_by: "adminlook",
    modified_by: "adminlook",
  },
];

const { TextArea } = Input;
const { confirm } = Modal;

const onChangeSelect = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};
const onChangeTextArea = (e) => {
  console.log("Change:", e.target.value);
};
const onChangeDatePicker = (date, dateString) => {
  console.log(date, dateString);
};
const onChangeNum = (value) => {
  console.log("changed", value);
};

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// const onChange = (pagination, filters, sorter, extra) => {
//     console.log('params', pagination, filters, sorter, extra);
// };

// CODE
export default function AllotmentTable() {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            <Row className="allotment-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <FormOutlined
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);

                    setOpen(true);
                    setEdit(true);
                  }}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              </Col>

              <Col span={12}>
                <DeleteFilled
                  className="delete-btn"
                  onClick={() => {
                    console.log("ABOUT TO DELETE > ", record);
                    showDeleteModal(record);
                  }}
                  style={{ fontSize: 20, color: "#F64E60" }}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Allotment ID",
      dataIndex: "allotment_id",
      key: "allotment_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.allotment_id.localeCompare(b.allotment_id),
    },
    {
      title: "Allotment Code",
      dataIndex: "allotment_code",
      key: "allotment_code",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.allotment_code.localeCompare(b.allotment_code),
    },
    {
      title: "Room Category ID",
      dataIndex: "room_category_id",
      key: "room_category_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_category_id.localeCompare(b.room_category_id),
    },
    {
      title: "Category Code",
      dataIndex: "category_code",
      key: "category_code",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.category_code.localeCompare(b.category_code),
    },
    {
      title: "Over Booking",
      dataIndex: "over_booking",
      key: "over_booking",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.over_booking.localeCompare(b.over_booking),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.start_date.localeCompare(b.start_date),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.end_date.localeCompare(b.end_date),
    },
    {
      title: "Arrangement ID",
      dataIndex: "arrangement_id",
      key: "arrangement_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.arrangement_id - b.arrangement_id,
    },
    {
      title: "Arrangement Code",
      dataIndex: "arrangement_code",
      key: "arrangement_code",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.arrangement_code - b.arrangement_code,
    },
  ];

  // CONTEXT
  const { state} = useContext(MasterContext);

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // STATE MANAGEMENT
  //const [open, setOpen] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = () => {
    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete guest named .... from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
  };
  // useEffect(() => {
  //     fetchRecords()
  // }, [])

  // const fetchRecords = () => {
  //     setLoading(true)
  //     fetch(`http://192.168.88.103:3100/supabase/reservation`).then(res => {
  //         res.json().then(response => {
  //             setDataSource(response.msg)
  //             setLoading(false)
  //             //console.log(response)
  //         }).catch((error) => { console.log(error) });
  //     })
  // }

  return (
    <>
      <Row className="allotment-main-table" justify="center" style={mainBody}>
        <Col span={24} className="col-table" style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-exp-btn"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={<></>}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-modal-btn"
            >
              <Row
                justify="end"
                className="row-modal-btn"
                gutter={[0, 15]}
                align="top"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Data Allotment`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={fetchRecords}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              loading={loading}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={dataSource}
              //onChange={onChange}
              pagination={{
                pageSize: 5,
              }}
              scroll={{
                // y: 270,
                x: 1500,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edot-allotment-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                Add Data Allotment
              </Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                Edit Data Allotment
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="allotment-modal-form"
          name="allotment-modal-form"
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          // onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-allotment-id">
            <Col span={8}>
              <Form.Item
                label="Allotment Code"
                name="alt_code"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Allotment Code" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="start_date"
                style={{ marginRight: 30, width: 205 }}
              >
                <DatePicker
                  onChange={onChangeDatePicker}
                  style={{ width: 205 }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="End Date" name="end_date">
                <DatePicker
                  onChange={onChangeDatePicker}
                  style={{ width: 205 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30} className="row-allotment-day">
            <Col span={8}>
              <Form.Item
                label="Cut Days"
                name="cut_days"
                style={{ marginRight: 30, width: 205 }}
              >
                <InputNumber min={1} max={10} onChange={onChangeNum} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Cut Date" name="cut_date">
                <DatePicker
                  onChange={onChangeDatePicker}
                  style={{ width: 205 }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Arrangement" name="arrangement">
                <Select
                  showSearch
                  placeholder="Select an Option"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "130-01-023/Inventory - Fuel, Gas, and Lubricants",
                      label: "130-01-023/Inventory - Fuel, Gas, and Lubricants",
                    },
                    {
                      value: "131-01-023/Inventory - Gas, and Lubricants",
                      label: "131-01-023/Inventory - Gas, and Lubricants",
                    },
                  ]}
                  style={{
                    minWidth: 205,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-room-allotment">
            <Col span={8}>
              <Form.Item label="Room Category" name="room_category">
                <Input
                  placeholder="Choose an option"
                  // disabled
                  addonAfter={
                    <MdOutlineDashboardCustomize
                      onClick={() => setOpen(true)}
                    />
                  }
                  // onClick={() => setOpen(true)}
                  style={{
                    width: 205,
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Room Qty" name="room_qty">
                <InputNumber min={1} max={10} onChange={onChangeNum} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Adult" name="adult">
                <InputNumber min={1} max={10} onChange={onChangeNum} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Child" name="child">
                <InputNumber min={1} max={10} onChange={onChangeNum} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Infant" name="infant">
                <InputNumber min={1} max={10} onChange={onChangeNum} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-allotment-overbook">
            <Col span={8}>
              <Form.Item
                label="Over Booking"
                name="over_booking"
                style={{ marginRight: 30 }}
              >
                <InputNumber min={1} max={10} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Contact"
                name="Contact"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Phone Number" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item label="Comment" name="comment">
              <TextArea
                showCount
                maxLength={200}
                style={{
                  height: 100,
                  width: 500,
                }}
                onChange={onChangeTextArea}
                placeholder="Descriptions"
              />
            </Form.Item>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-allotment">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-allotment">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  InputNumber,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { TbBedOff } from "react-icons/tb";
import { FiRefreshCw } from "react-icons/fi";
import { MdAccountBalanceWallet } from "react-icons/md";
import {
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  UndoOutlined,
} from "@ant-design/icons";

// Import Page Components
import MainAccountTable from "../../../Components/Table/Accounting/MainAccount/Table";

// Import Page Components
import OutOfOrderTable from "../../../Components/Table/HouseKeep/OOO/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function MainAccountPage() {
  // STATE MANAGEMENT
  const [coaNo, setCoaNo] = useState(null);
  const [coaName, setCoaName] = useState(null);
  // const [name, setName] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearchCoaNo = (e) => {
    e.preventDefault();

    setCoaNo(e.target.value);
  };

  // HANDLE SEARCH
  const handleSearchCoaName = (e) => {
    e.preventDefault();

    setCoaName(e.target.value);
  };

  // console.log(room);

  return (
    <Layout>
      <Content>
        <Col span={24} className="ooo-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <MdAccountBalanceWallet
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Chart of Account`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`COA No.`}</Typography>

                <Input
                  allowClear
                  placeholder="COA No."
                  onChange={handleSearchCoaNo}
                  style={{ minWidth: 150 }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 15px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`COA Name`}</Typography>

                <Input
                  allowClear
                  placeholder="COA Name"
                  onChange={handleSearchCoaName}
                  style={{ minWidth: 150 }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                  style={{ width: 91 }}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <MainAccountTable
            is_search={setSearch}
            searchCoaNo={coaNo}
            searchCoaName={coaName}
          />
        </Col>
      </Content>
    </Layout>
  );
}

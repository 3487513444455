// REACT COMPONENTS
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Select, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { ImBoxAdd } from "react-icons/im";
import { GrArticle } from "react-icons/gr";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import StockIncomingArticleTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/StockIncomingArticleTable/Table";
import WarehouseList from "../../../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";
import ArticleVendor from "../../../../../../Components/Reusable/Master/ArticleVendor/ArticleVendor";

export default function StockArticleIncomingReportPage() {
  const [article, setArticle] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [element, setElement] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/incoming-stock";

    navigate(`${path}`);
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticle = (value) => {
    setArticle(value);
    setWarehouse(null);
  };

  // HANDLE SELECTED WAREHOUSE
  const handleSelectedWarehouse = (value) => {
    setWarehouse(value);
    setArticle(null);
  };

  // HANDLE FILTER
  const handleFilter = (val) => {
    const _type = val;

    setFilterValue(_type);

    if (_type == 1) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
            margin: "0px 5px 0px 0px",
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`Article Name`}</Typography>

          <ArticleVendor
            selectedArticle={handleSelectedArticle}
            article_name={null}
            is_disable={false}
          />
        </Col>
      );
    } else if (_type == 2) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
            margin: "0px 5px 0px 0px",
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`Warehouse`}</Typography>

          <WarehouseList
            selectedItem={handleSelectedWarehouse}
            warehouse_name={null}
            is_disable={false}
          />
        </Col>
      );
    } else {
      null;
    }
  };

  const options = [
    {
      label: "Article Name",
      value: 1,
    },
    {
      label: "Warehouse",
      value: 2,
    },
  ];

  return (
    <Layout>
      <Content>
        <Col span={24} className="incoming-stock-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <ImBoxAdd
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Incoming Article Stock Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Filter By`}
                </Typography>

                <Select
                  className="type-select"
                  key="type-select"
                  allowClear
                  showSearch
                  value={filterValue}
                  onChange={handleFilter}
                  options={options}
                  placeholder="Filter By"
                  style={{
                    width: 250,
                  }}
                />
              </Col>

              {element}

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {warehouse || article ? (
            <StockIncomingArticleTable
              article_number={article?.article_number}
              id_warehouse={warehouse?.id_warehouse}
              type={filterValue}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

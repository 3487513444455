// Import React Components
import React from "react";

// Import Ant Design Components
import { Button, Col, Collapse, Layout, Row, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Icons
import { BsCoin } from "react-icons/bs";

// Import Page Components
import DetailReportLayout from "../../../../Components/PrintLayout/Accounting/Reports/DetailReport/Report";
import DSReportLayout from "../../../../Components/PrintLayout/Accounting/Reports/HeaderReport/Report";

// CODE
export default function DailyDetailReport() {
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <BsCoin
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Daily Report (Details)`}
              </Typography>
            </Row>

            <DetailReportLayout />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

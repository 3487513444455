// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { token } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";

// ANTD COMPONENTS
import { Col, Row, Table, Tag, Tooltip } from "antd";

// REACT ICONS
import { FaRegEye } from "react-icons/fa";

// NOTIFICATIONS
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// PAGE COMPONENTS
import GuestAccountBalanceModal from "../../../../Modals/GuestAccountBalance/Modal";

// PARAMS
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";

export default function GuestAccountAuditTable(props) {
  // PROPS
  const { dataGuestAccount, dateAudit } = props;

  // CONTEXT
  const { getNightAudit } = useContext(MainContext);

  // STATE MANAGEMENT
  // DATA
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [date_audit, setDate_Audit] = useState(null);
  // Bool
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // Params
  const currency = CurrencySymbols().code;

  // FETCH DATA N/A GUEST ACCOUNT HEADER
  const fetchData = async () => {
    setLoading(true);

    await getNightAudit({
      type: "header-guest-account-balance",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          // handleFilter(_response);
          setData(_response);
          dataGuestAccount(_response);
        } else {
          setData([]);
          dataGuestAccount([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // Fetch Data Re-Night Audit
  const fetchReAudit = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseurl}/re-night-audit/header-guest-account-balance`,
        {
          date: moment(dateAudit).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          setData(_response);
          dataGuestAccount(_response);
        } else {
          dataGuestAccount([]);
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dateAudit != date_audit && !cleanUp) {
      setDate_Audit(dateAudit);
      fetchReAudit();
    } else {
      if (date_audit) {
        setData([]);
        dataGuestAccount([]);
      } else {
        fetchData();
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [dateAudit]);

  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE CLOSE MODAL
  const handleClose = () => {
    setModalOpen(false);
    setDataDetail(null);
  };

  // COLUMN
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      fixed: "left",
      width: 75,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-guest-account-table-btn"
              style={{ width: "100%" }}
              justify="center"
            >
              <Col span={12}>
                <Tooltip title="Show Detail" className="edit-tooltip">
                  <FaRegEye
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO DETAIL > ", record);
                      setDataDetail(record);
                      handleOpen();
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
      // fixed: "left",
      // width: 150,
      render: (res, record, index) => {
        return res ? res.toUpperCase() : res;
      },
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // width: 150,
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
      // width: 150,
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "room_number",
      sorter: (a, b) => a.room_number - b.room_number,
      // width: 150,
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      // width: 175,
    },
    // {
    //   title: "Child",
    //   dataIndex: "child",
    //   key: "child",
    // width: 175,
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "infant",
    //   key: "infant",
    // width: 175,
    // },
    {
      title: `Total Trx (${currency})`,
      dataIndex: "total_trx",
      key: "total_trx",
      // width: 150,
      render: (price) => {
        let _price = price > 0 ? price : 0;

        return `${_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Deposit (${currency})`,
      dataIndex: "deposit",
      key: "deposit",
      // width: 150,
      render: (price) => {
        let _price = price > 0 ? price : 0;

        return `${_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];
  return (
    <>
      <Row style={{ margin: "30px 0px 0px", width: "100%" }}>
        <Table
          className="na-guest-account-table"
          key="na-guest-account-table"
          name="na-billoutstanding-table"
          loading={loading}
          columns={columns}
          dataSource={data}
          // expandable={{
          //   expandedRowRender: (record) => {
          //     // console.log(record?.children);
          //     return nestedTable(record?.children);
          //   },
          // }}
          size="small"
          bordered
          pagination={
            false
            //   {
            //   pageSizeOptions: [5, 10, 25, 50, 100],
            //   defaultPageSize: 5,
            //   showSizeChanger: true,
            //   showTotal: (total, range) =>
            //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            // }
          }
          scroll={{
            x: true,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => (record.id ? record.id : record.reservation_id)}
        />
      </Row>

      <GuestAccountBalanceModal
        dataDetail={dataDetail}
        openModal={modalOpen}
        closeModal={handleClose}
        is_refresh={fetchData}
      />
    </>
  );
}

// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../Style/Global/Global";

// REACT ICONS
import { GiPayMoney } from "react-icons/gi";
import { MdOutlinePlaylistAddCircle } from "react-icons/md";
import { TbCheckupList, TbFileTime } from "react-icons/tb";

// GLOBAL API
import { sub_children } from "../../../../API/Global/Payload";

// List
const listCard = [
  {
    path: "account-payable-payment",
    title: "A/P Payment",
    id_modules: 109,
    icon: <MdOutlinePlaylistAddCircle style={globalIcon} />,
  },
  {
    path: "account-payable-aging",
    title: "A/P Aging",
    id_modules: 108,
    icon: <TbFileTime style={globalIcon} />,
  },
  {
    path: "account-payable-payment-list",
    title: "A/P Payment List",
    id_modules: 110,
    icon: <TbCheckupList style={globalIcon} />,
  },
];

export default function APMainPage() {
  // STATE MANAGEMENT
  // Data
  const [listItems, setlistItems] = useState([]);

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const grandModule = sub_children?.length > 0 ? sub_children : [];

    const list = listCard.map((record) => {
      if (grandModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      } else {
        return null;
      }
    });

    // console.log("Grand Children Modules: ", sub_children);
    // console.log("Filtered: ", list);

    setlistItems(list);
  };
  return (
    <Layout>
      <Content>
        <Col span={24} className="ooo-main-layout">
          <Row
            className="row-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              margin: "0px 0px 30px",
              borderRadius: 5,
            }}
          >
            <GiPayMoney
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`A/P (Account Payable)`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={mainBody}
          >
            {listItems}
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

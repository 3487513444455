// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Col,
  Form,
  Modal,
  Row,
  Typography,
  Input,
  InputNumber,
  Checkbox,
  Divider,
  Table,
  Select,
  Space,
  Button,
  Alert,
  Tooltip,
} from "antd";

// Import React Icons
import {
  InfoCircleFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { TbCircleX, TbCircleCheck } from "react-icons/tb";

// Import Master Components
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterArticleSub from "../../../Reusable/Master/ArticleSub/ArticleSub";
import MasterMainAccount from "../../../Reusable/Accounting/MainAccount/MasterCoa";
import MasterArticleMain from "../../../Reusable/Master/ArticleMain/ArticleMain";
import MasterUOM from "../../../Reusable/Master/UOM/MasterUnit";
import ArticleVendor from "../../../Reusable/Master/ArticleVendor/ArticleVendor";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Notification
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";
import {
  ArticleIncomplete,
  failedFetch,
} from "../../../Reusable/Notification/Notification";

// Confirm Modal
const { confirm } = Modal;

// CODE
export default function ModalArticleInventory(props) {
  // PROPS
  const { is_open, is_close, article, is_refresh } = props;

  // CONTEXT
  const { getArticleInventory } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  const [isArticleFound, setArticleFound] = useState(false);
  const [isArticleInventory, setIsArticleInventory] = useState(false);
  // const [articleInventory, setArticleInventory] = useState(null);
  const [articleList, setArticleList] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  // Data Edit
  const [data, setData] = useState([]);
  // Main Group
  const [dept, setDept] = useState({
    department_name: "",
    department_id: 0,
  });
  const [mainGroup, setMainGroup] = useState({
    article_main_name: "",
    article_main_id: 0,
  });
  const [subGroup, setSubGroup] = useState({
    article_sub_name: "",
    article_sub_id: 0,
  });
  // Units
  const [units, setUnits] = useState({
    r_unit: "",
    m_unit: "",
    m_content: 0,
    d_unit: "",
    d_content: 0,
  });
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open === true && !cleanUp) {
      handleOpen();
      fetchData();
    } else {
      handleCancel(false);
    }

    if (article && !cleanUp) {
      setEdit(true);
      setData(article);
      setShow(true);

      handleFormFields(article);
    } else {
      setEdit(false);
      setData(null);
      setShow(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [article, is_open]);

  // Fetch Data
  const fetchData = async () => {
    await getArticleInventory({
      inventory: "list",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response article => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted && val?.article_type === 2;
          });

          console.log("FILTERED ARTICLE : ", filtered);

          setArticleList(filtered);
        } else {
          setArticleList([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // HANDLE FORM FIELDS
  const handleFormFields = (value) => {
    const record = value;

    setUnits({
      r_unit: record?.recipe_unit,
      m_unit: record?.mess_unit,
      m_content: record?.mess_content > 0 ? record.mess_content : 0,
      d_unit: record?.delivery_unit,
      d_content: record?.delivery_content > 0 ? record.delivery_content : 0,
    });

    form.setFieldsValue({
      department_id: record?.department_id,
      department_name: record?.department_name,
      article_number: record?.article_number,
      article_name: record?.article_name,
      article_main_name: record?.article_main_name,
      article_main_group_id: record?.article_main_group_id,
      article_sub_id: record?.article_sub_id,
      article_sub_name: record?.article_sub_name,

      mess_unit: record?.mess_unit,
      mess_unit_id: record?.mess_unit_id,
      delivery_unit: record?.delivery_unit,
      delivery_unit_id: record?.delivery_unit_id,
      delivery_content: record?.delivery_content,
      // delivery_content_id: record?.delivery_content_id,
      recipe_unit: record?.recipe_unit,
      recipe_unit_id: record?.recipe_unit_id,
      mess_content: record?.mess_content,
      is_dml: record?.is_dml,

      min_stock: record?.min_stock,
      average_price: record?.average_price,
      article_type: record?.article_type,
      chart_of_account: record?.chart_of_account,
      description: record?.description,

      last_price: record?.last_price,
      last_supplier: record?.last_supplier,

      created_date: moment(record?.created_date).format("YYYY-MM-DD"),
      created_by: record?.created_by,
      updated_date: moment(record?.updated_date).format("YYYY-MM-DD"),
      updated_by: record?.updated_by,
      // is_deleted: false,
      // deleted_date: record.deleted_date,
      // deleted_by: record.deleted_by,
    });
  };

  // Article Main & Article Sub
  const getDepartment = (value) => {
    console.log(value);
    // setEditDataDept(value);

    setDept({
      department_name: value?.name,
      department_id: value?.dept_id,
    });

    form.setFieldsValue({
      department_name: value?.name,
      department_id: value?.dept_id,
      article_name_addon: isArticleFound && value?.code,
    });
  };

  // GET ARTICLE MAIN GROUP NAME & ID
  const getArticleMain = (value) => {
    console.log(value);

    form.setFieldsValue({
      article_main_name: value?.name,
      article_main_id: value?.main_id,
    });

    setMainGroup({
      article_main_name: value?.name,
      article_main_id: value?.main_id,
    });
  };

  // Article Main & Article Sub
  const getArticleSub = (value) => {
    console.log(value);

    setSubGroup({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });

    form.setFieldsValue({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsArticleInventory(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    is_close(false);
    setOpen(false);
    setEdit(false);

    setData(null);

    setDept({
      department_name: "",
      department_id: 0,
    });

    setMainGroup({
      article_main_name: "",
      article_main_id: 0,
    });

    setSubGroup({
      article_sub_name: "",
      article_sub_id: 0,
    });

    setUnits({
      r_unit: "",
      m_unit: "",
      m_content: 0,
      d_unit: "",
      d_content: 0,
    });

    setIsLoading(false);

    setLoading(false);
    setArticleFound(false);
    setShow(false);
    setArticleList([]);
    setSelectedArticle(null);
    setIsArticleInventory(false);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (e) => {
    const deleteContent = e;

    confirm({
      className: "delete-art-invent-confirm",
      title: `Are you sure you want to ${
        edit ? "Update" : "Add a New"
      } Article Inventory named ${
        deleteContent?.article_name.toUpperCase() || " ---- "
      }?
      `,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",

      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    const _submit = e;
    console.log(_submit);

    // handleSubmit(e);
    setIsLoading(true);
    showModalConfirm(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log("Failed to Submit: ", e?.values);

    masterIncomplete(e);
  };

  // GET UNITS
  const getUnit = (e) => {
    console.log("UNITS: ", e);

    setUnits({
      ...units,
      m_unit: e?.uom_code,
    });

    form.setFieldsValue({
      mess_unit: e?.uom_name,
      mess_unit_id: e?.uom_id,
    });
  };

  // GET Delivery Unit
  const getDUnit = (e) => {
    console.log(e);

    setUnits({
      ...units,
      d_unit: e?.uom_code,
    });

    form.setFieldsValue({
      delivery_unit: e.uom_name,
      delivery_unit_id: e.uom_id,
    });
  };

  // GET Recipe Unit
  const getRUnit = (e) => {
    console.log(e);

    setUnits({
      ...units,
      r_unit: e?.uom_code,
    });

    form.setFieldsValue({
      recipe_unit: e.uom_name,
      recipe_unit_id: e.uom_id,
    });
  };

  // GET COA
  const getCoaName = (e) => {
    console.log("COA: ", e);

    form.setFieldsValue({
      chart_of_account: e?.code,
      account_name: e?.name,
    });
  };

  // Handle Content M-Unit
  const handleContentMess = (e) => {
    // console.log("Mess Unit: ", e);

    setUnits({
      ...units,
      m_content: e,
    });
  };

  // Handle Content D-Unit
  const handleContentDel = (e) => {
    // console.log("Del Unit: ", e);

    setUnits({
      ...units,
      d_content: e,
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentSubmit = e;
    console.log("ON Submit ==> ", contentSubmit);

    if (edit == false) {
      const findIndexItem = articleList.findIndex((item) => {
        if (contentSubmit?.article_name_addon) {
          return (
            contentSubmit.article_name +
              " " +
              contentSubmit.article_name_addon ===
            item.article_name
          );
        } else {
          return contentSubmit?.article_name === item.article_name;
        }
      });

      if (findIndexItem !== -1) {
        ArticleIncomplete(contentSubmit, { method: 1 });
        setIsLoading(false);
      } else {
        await axios
          .post(
            `${baseurl}/article/list`,
            {
              article_main_id:
                mainGroup.article_main_id > 0 ? mainGroup.article_main_id : 0,
              article_main_name: contentSubmit?.article_main_name || null,
              article_number: contentSubmit?.article_number || null,
              article_name:
                (contentSubmit?.article_name_addon
                  ? contentSubmit?.article_name +
                    " " +
                    contentSubmit?.article_name_addon
                  : contentSubmit?.article_name) || null,
              chart_of_account: contentSubmit?.chart_of_account || null,

              description: contentSubmit?.description || null,

              mess_unit: contentSubmit?.mess_unit_id || null,
              delivery_unit: contentSubmit?.delivery_unit_id || null,
              delivery_content: contentSubmit?.delivery_content || null,
              recipe_unit: contentSubmit?.recipe_unit_id || null,

              min_stock: contentSubmit?.min_stock || 0,

              average_price: 0,
              last_price: contentSubmit?.last_price || null,
              last_supplier: contentSubmit?.last_supplier || null,
              article_type: 2,

              department_id: dept.department_id > 0 ? dept.department_id : 0,
              department_name: contentSubmit?.department_name || null,
              article_sub_id:
                subGroup.article_sub_id > 0 ? subGroup.article_sub_id : 0,
              article_sub_name: contentSubmit?.article_sub_name || null,
              unit_price: null,

              gross: null,
              tax: null,
              is_tax: false,
              service: null,
              is_service: false,

              mess_content: contentSubmit?.mess_content || null,
              is_dml: contentSubmit?.is_dml ? true : false,

              created_by: user_name,
            },
            { headers: { Authorization: `Bearer ${token ? token : ""}` } }
          )
          .then((response) => {
            console.log("POST Art Inv => ", response);

            masterSuccessNotification(response, {
              method: 0,
              source: "Article Inventory",
            });

            is_refresh(true);
            handleCancel();
          })
          .catch((error) => {
            console.log("POST Art Inv => ", error);
            masterFailedNotification(error, {
              method: 0,
              source: "Article Inventory",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      await axios
        .put(
          `${baseurl}/article/list`,
          {
            id: data?.id,

            article_main_id:
              mainGroup.article_main_id > 0
                ? mainGroup.article_main_id
                : data?.article_main_id,
            article_main_name: contentSubmit?.article_main_name
              ? contentSubmit.article_main_name
              : data?.article_main_name,
            article_number: contentSubmit?.article_number
              ? contentSubmit.article_number
              : data?.article_number,
            article_name: contentSubmit?.article_name
              ? contentSubmit.article_name
              : data?.article_name,
            chart_of_account: contentSubmit?.chart_of_account
              ? contentSubmit.chart_of_account
              : data?.chart_of_account,
            description: contentSubmit?.description,

            mess_unit: contentSubmit?.mess_unit_id || data?.mess_unit_id,
            delivery_unit:
              contentSubmit?.delivery_unit_id || data?.delivery_unit_id,
            delivery_content:
              contentSubmit?.delivery_content || data?.delivery_content,
            recipe_unit: contentSubmit?.recipe_unit_id || data?.recipe_unit_id,

            min_stock: contentSubmit?.min_stock,
            average_price: contentSubmit?.average_price,
            last_price: contentSubmit?.last_price || data?.last_price,
            last_supplier: contentSubmit?.last_supplier || data?.last_supplier,
            article_type: data?.article_type > 0 ? data?.article_type : 2,

            department_id:
              dept.department_id > 0 ? dept.department_id : data?.department_id,
            department_name:
              contentSubmit?.department_name || data?.department_name,
            article_sub_id:
              subGroup.article_sub_id > 0
                ? subGroup.article_sub_id
                : data?.article_sub_id,
            article_sub_name:
              contentSubmit?.article_sub_name || data?.article_sub_name,

            unit_price: null,
            gross: null,
            tax: null,
            is_tax: false,
            service: null,
            is_service: false,

            is_dml: contentSubmit?.is_dml ? true : false,

            mess_content: contentSubmit?.mess_content || data?.mess_content,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("PUT Art Inv => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Article Inventory",
          });

          is_refresh(true);
          handleCancel();
        })
        .catch((error) => {
          console.log("PUT Art Inv => ", error);
          masterFailedNotification(error, {
            method: 1,
            source: "Article Inventory",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE FIND ARTICLE
  const handleFind = (value) => {
    setArticleFound(false);
    setShow(false);

    if (value) {
      setArticleFound(true);
      setShow(true);

      form.setFieldsValue({
        article_name: value?.article_name,
        article_number: value?.article_number,
      });
    } else {
      setArticleFound(false);
      setShow(true);
    }
  };

  // HANDLE NEW ARTICLE
  const handleNewArticle = () => {
    setArticleFound(false);
    setShow(true);
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticle = (value) => {
    setSelectedArticle(value);
  };

  return (
    <>
      <Modal
        className="article-inventory-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Article Inventory`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Article Inventory`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
        bodyStyle={{
          borderRadius: 30,
        }}
        // style={{ borderRadius: 15 }}
      >
        {/* <div style={{ padding: 24 }}> */}
        <Form
          name="article-inventory-modal-form"
          className="article-inventory-modal-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ padding: "0px 36px" }}
        >
          {!edit ? (
            <>
              <Row gutter={30} className="row-id-card-type">
                <Col span={12}>
                  <Form.Item label="Article Name">
                    <ArticleVendor
                      selectedArticle={handleSelectedArticle}
                      article_name={
                        selectedArticle?.article_name
                          ? selectedArticle.article_name
                          : null
                      }
                      is_disable={false}
                      is_article_inventory={isArticleInventory}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Row className="btn-row" justify="start">
                    <Button
                      className="next-btn"
                      type="primary"
                      icon={<SearchOutlined />}
                      onClick={() => {
                        handleFind(selectedArticle);
                      }}
                      style={{ marginTop: 30, marginRight: 25 }}
                    >
                      {`Select Data`}
                    </Button>

                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        handleNewArticle();
                      }}
                      style={{ marginTop: 30, marginRight: 25 }}
                    >
                      {`New Article`}
                    </Button>
                  </Row>
                </Col>
              </Row>

              {isArticleFound ? (
                <Row justify="center" style={{ marginBottom: 20 }}>
                  <Col span={24} style={{ width: "100%" }}>
                    <Alert
                      message="Article Found!"
                      description={`Article Number = ${form.getFieldValue(
                        "article_number"
                      )}, Article Name = ${form.getFieldValue(
                        "article_name"
                      )}, Article Add-On = ${
                        form.getFieldValue("article_name_addon") || "-"
                      }`}
                      type="info"
                      showIcon
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              ) : null}
              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          {show ? (
            <Row className="form-row" justify="center" align="top">
              <Col className="form-col" span={24}>
                <Row gutter={30} className="row-art-list-id">
                  <Col span={8}>
                    <Form.Item
                      label="Department"
                      name="department_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, select a Department!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <MasterDepartment
                        getDepartment={getDepartment}
                        department_name={edit ? data?.department_name : null}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Article Main-Group"
                      name="article_main_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, select an Article Main-Group!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <MasterArticleMain
                        getArticleMain={getArticleMain}
                        getDepartmentId={dept.department_id}
                        mainName={edit ? data?.article_main_name : null}
                        addArticleState={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Article Sub-Group"
                      name="article_sub_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, select an Article Sub-Group!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <MasterArticleSub
                        getArticleSub={getArticleSub}
                        articleSubName={edit ? data?.article_sub_name : null}
                        getArticleMainId={mainGroup.article_main_id}
                        getDepartmentId={dept.department_id}
                        addArticleState={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: "5px 0px", background: "#EBEDF3" }}
                />

                <Row gutter={30} className="row-article-header">
                  <Col
                    span={isArticleFound ? 8 : 12}
                    className="article-no-col"
                  >
                    <Form.Item
                      label="Article Number"
                      name="article_number"
                      rules={[
                        {
                          required: true,
                          message: "Please, input Article Inventory Code!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Input
                        placeholder="Article Number"
                        disabled={isArticleFound ? true : false}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={isArticleFound ? 8 : 12}
                    className="article-name-col"
                  >
                    <Form.Item
                      label="Article Name"
                      name="article_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, input Article Inventory Name!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Input
                        placeholder="Article Name"
                        disabled={isArticleFound ? true : false}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={8}
                    className="article-name-col"
                    hidden={isArticleFound ? false : true}
                  >
                    <Form.Item
                      label="Add-On"
                      name="article_name_addon"
                      rules={
                        !edit && [
                          {
                            required: true,
                            message: "Please, input Article Inventory Name!",
                          },
                        ]
                      }
                      style={{ width: "100%" }}
                    >
                      <Input placeholder="Add-On" disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={30} className="row-article-coa">
                  <Col span={16} className="coa-col">
                    <Form.Item
                      label="Chart of Account"
                      name="chart_of_account"
                      rules={[
                        {
                          required: true,
                          message: "Please, select a Chart of Account!",
                        },
                      ]}
                    >
                      <MasterMainAccount
                        is_header={false}
                        coa_name={edit ? data?.chart_of_account : null}
                        disable={false}
                        getCoaName={getCoaName}
                      />
                    </Form.Item>

                    <Form.Item label="Account Name" name="account_name" hidden>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8} className="avg-price-col">
                    <Form.Item
                      label="Average Price"
                      name="average_price"
                      hidden={!edit}
                    >
                      <InputNumber
                        addonBefore={currency}
                        disabled
                        min={0}
                        placeholder="Average Price"
                        formatter={(average_price) => {
                          return `${average_price}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  orientation="left"
                  orientationMargin={0}
                  style={{ margin: "5px 0px", borderColor: "#EBEDF3" }}
                >
                  {`Unit of Measurement`}
                </Divider>

                <Row gutter={30} className="row-article-unit">
                  <Col span={16} className="unit-col">
                    <Row gutter={30} className="row-stock-article">
                      <Col span={12} className="dunit-col">
                        <Form.Item
                          label="Delivery Unit (D-Unit)
                         "
                          name="delivery_unit"
                          rules={[
                            {
                              required: true,
                              message: "Please, Input Delivery Unit!",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          {/* <Input /> */}
                          <MasterUOM
                            getUnit={getDUnit}
                            unit_name={edit ? data?.delivery_unit : null}
                          />
                        </Form.Item>

                        <Form.Item
                          label="D-Unit ID"
                          name="delivery_unit_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="content-col">
                        <Form.Item
                          label="Delivery Content"
                          name="delivery_content"
                          rules={[
                            {
                              required: true,
                              message: "Please, Input Delivery Content!",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <InputNumber
                            placeholder="Delivery Content"
                            min={0}
                            // minLength={5}
                            onChange={handleContentDel}
                            formatter={(average_price) => {
                              return `${average_price}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              );
                            }}
                            style={{
                              width: "100%",
                            }}
                          />
                          {/* <MasterUOM
                       getUnit={getContent}
                       unit_name={edit ? data?.mess_content : null}
                     /> */}
                        </Form.Item>

                        <Form.Item
                          label="Content ID"
                          name="delivery_content_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={30} justify="start">
                      <Col span={12} className="uom-col">
                        <Form.Item
                          label="Mess Unit (M-Unit)"
                          name="mess_unit"
                          rules={[
                            {
                              required: true,
                              message: "Please, Select an Unit!",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <MasterUOM
                            getUnit={getUnit}
                            unit_name={edit ? data?.mess_unit : null}
                          />
                        </Form.Item>

                        <Form.Item label="Mess Unit" name="mess_unit_id" hidden>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="qty-col">
                        <Form.Item
                          label="Mess Content"
                          name="mess_content"
                          rules={[
                            {
                              required: true,
                              message: "Please, Input Mess Content!",
                            },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <InputNumber
                            placeholder="Mess Content"
                            className="qty-no"
                            min={0}
                            onChange={handleContentMess}
                            formatter={(average_price) => {
                              return `${average_price}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              );
                            }}
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={30} justify="start">
                      <Col span={12} className="runit-col">
                        <Form.Item
                          label="Recipe Unit (R-Unit)"
                          name="recipe_unit"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please, Input Recipe Unit!",
                          //   },
                          // ]}
                          style={{ width: "100%" }}
                        >
                          {/* <Input /> */}
                          <MasterUOM
                            getUnit={getRUnit}
                            unit_name={edit ? data?.recipe_unit : null}
                          />
                        </Form.Item>

                        <Form.Item
                          label="R-Unit ID"
                          name="recipe_unit_id"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={8} className="desc-unit">
                    <Alert
                      className="unit-info"
                      message="Unit Container Info"
                      type="info"
                      showIcon
                      icon={<InfoCircleFilled />}
                      description={null}
                    />

                    <Alert
                      className="unit-info"
                      // message="Unit Notes"
                      type="info"
                      description={
                        <>
                          {`For Delivery: 1 ${units.d_unit}`}
                          <br />
                          <br />
                          {`For Mess Unit:`}
                          <br />
                          {`1 ${units.d_unit} = ${
                            units.d_content + " " + units.m_unit
                          }`}
                          <br />
                          <br />
                          {`For Recipe Unit:`}
                          <br />
                          {`1 ${units.m_unit} = ${
                            units.m_content + " " + units.r_unit
                          }`}
                        </>
                      }
                      style={{
                        // padding: 0,
                        minHeight: "15vh",
                      }}
                    />
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: "5px 0px", background: "#EBEDF3" }}
                />

                <Row gutter={30} className="row-stock-article">
                  <Col span={8} className="stock-col">
                    <Form.Item
                      label="Min. Stock"
                      name="min_stock"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, input Article Inventory Stock!",
                      //   },
                      // ]}
                    >
                      <InputNumber
                        placeholder="Minimal Stock"
                        min={0}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Market List"
                      valuePropName="checked"
                      name={"is_dml"}
                    >
                      <Checkbox>Daily Market List stocks</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="row-desc-article">
                  <Col span={16} className="desc-col">
                    <Form.Item label="Description" name="description">
                      <Input.TextArea
                        placeholder="Descriptions"
                        className="desc-input"
                        showCount
                        maxLength={200}
                        allowClear
                        rows={2}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: "5px 0px", background: "#EBEDF3" }}
                />

                {edit == true ? (
                  <>
                    <Row gutter={30} className="edit-supplier-article-inv">
                      <Col span={8}>
                        <Form.Item
                          label="Last Price"
                          name="last_price"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Last Price" disabled />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Last Supplier"
                          name="last_supplier"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Last Supplier" disabled />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Created By"
                          name="created_by"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Created by" disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={30} className="edit-date-article-inv">
                      <Col span={8}>
                        <Form.Item
                          label="Created Date"
                          name="created_date"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Created Date" disabled />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Updated By"
                          name="updated_by"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Updated by" disabled />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Updated Date"
                          name="updated_date"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Updated Date" disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider
                      className="form-divider"
                      style={{ margin: "5px 0px 10px", background: "#EBEDF3" }}
                    />
                  </>
                ) : null}

                <Row justify="end" align="middle" className="row-btn-article">
                  <Button
                    type="primary"
                    htmlType="submit"
                    key={"submit"}
                    loading={isLoading}
                    className="submit-btn"
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {`Submit`}
                  </Button>

                  <Button
                    className="cancel-btn"
                    type="default"
                    onClick={handleCancel}
                  >
                    {`Cancel`}
                  </Button>
                </Row>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>
    </>
  );
}

// Import Messages
import {
  CLEAR_MESSAGE,
  RESET_MESSAGE,
  SET_MESSAGE,
} from "../API/AuthActions/types";

// Initial States
const initialState = {};

// CODE
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  // console.log("Mesage: ", type);
  // console.log("Message Payload: ", payload);

  switch (type) {
    case SET_MESSAGE:
      return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };

    case RESET_MESSAGE:
      return {
        ...state,
        message: "",
      };

    default:
      return state;
  }
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterCountry(props) {
  // PROPS
  const { getCountry, country } = props;

  // CONTEXT
  const { getMasterCountry } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [countryType, setCountryType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = () => {
    getMasterCountry({
      country: "countries",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        // let option = response.data.msg.map((dev) => ({
        //   value: dev.country,
        //   label: dev.country,
        //   key: dev.country,
        // }));

        setOptionValue(response.data.msg);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // setCountryType(selectedCountryType);
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (country && !cleanUp) {
      setCountryType(country);
    } else {
      setCountryType(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [country]);

  // Handle Clear
  const handleClear = () => {
    setCountryType(null);
  };

  //   console.log(first);

  return (
    <>
      <Select
        style={{ width: "100%" }}
        showSearch
        value={countryType}
        placeholder="Select a Nations!"
        allowClear
        fieldNames={{ label: "country", value: "country" }}
        onChange={(e) => {
          setCountryType(e);
          console.log(e);

          getCountry(e);
          //  if (onSet) onSet(deviceid);
        }}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

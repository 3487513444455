// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterBedType({ bed_name, getBedType }) {
  // STATE MANAGEMENT
  // Data & Option Value
  const [bed, setBed] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  const options = [
    {
      value: "SINGLE BED",
      label: "SINGLE BED",
    },
    {
      value: "DOUBLE BED",
      label: "DOUBLE BED",
    },
    {
      value: "TWIN BED",
      label: "TWIN BED",
    },
    {
      value: "KING SIZE",
      label: "KING SIZE",
    },
    {
      value: "SUPER KING SIZE",
      label: "SUPER KING SIZE",
    },
  ];

  return (
    <>
      <Select
        value={bed != null ? bed : bed_name}
        fieldNames={{ label: "label", value: "value" }}
        className="bed-type-select"
        key={`${options.value}`}
        onChange={(value, code) => {
          console.log(value, code);

          setBed(value);

          getBedType(value);
        }}
        options={options}
      />
    </>
  );
}

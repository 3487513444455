// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// Import React Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Space,
  List,
  Table,
} from "antd";

// Import React Icons
import { MdOutlineHorizontalSplit } from "react-icons/md";

// Import Page Components
import JoinBillModalTable from "../../../Table/Outlet/Order/JoinBill/TableJoinBill";
import SplitBillModalTable from "../../../Table/Outlet/Order/SplitBill/TableSplitBill";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CODE
export default function ModalJoinBill(props) {
  // PROPS
  const { openModal, closeModal, dataHeader, dataDetail, selectedTable } =
    props;

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [tableKeys, setTableKeys] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH Data Detail
  const fetchDataDetail = async () => {
    await axios
      .get(`${baseurl}/pos/order-detail`, {
        headers: { Authorization: `Bearer ${token ? token : null}` },
      })
      .then((response) => {
        console.log("DETAIL FOR JOIN BILL: ", response);

        if (response.data?.msg?.length > 0) {
          let selectedID = selectedHeader.map((e) => e.order_id_alias);
          // console.log("HASIL SELECTED ID :", selectedID);
          let _res = response.data.msg?.filter((x) => {
            return selectedID.includes(x.order_id_alias);
          });

          // console.log("HASIL DETAIL JOIN BILL :", _res);

          setSelectedDetail(_res);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchDataDetail();
  }, [selectedHeader]);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    // if (dataHeader && !cleanUp) {
    //   console.log("About Bill Header: ", dataHeader);

    //   setHeaders(dataHeader);
    // } else {
    //   setHeaders(null);
    // }

    // if (dataDetail && !cleanUp) {
    //   console.log("About Bill Detail: ", dataDetail);

    //   setDetails(dataDetail);
    // } else {
    //   setDetails(null);
    // }

    // if (selectedTable && !cleanUp) {
    //   console.log("About Bill Detail: ", selectedTable);

    //   setTableKeys(selectedTable);
    // } else {
    //   setTableKeys(null);
    // }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataHeader, dataDetail, tableKeys]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    navigate(`${pathname}/payment`, {
      state: {
        headerData: selectedHeader[0],
        detailData: selectedDetail,
        selectedTable: tableKeys,
        is_split: false,
        is_join: true,
      },
    });
  };

  const onFinishFailed = (e) => {};

  return (
    <>
      <Modal
        className="Join-bill-modal"
        title={
          <>
            <Row className="Join-bill-modal-row">
              <MdOutlineHorizontalSplit
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`POS Join Bill`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Row
          className="join-bill-modal-form-serve-row"
          justify="start"
          gutter={[30, 30]}
          style={{ padding: 20 }}
        >
          <JoinBillModalTable getSelectedHeader={setSelectedHeader} />
        </Row>

        <Divider
          className="join-bill-modal-form-divider-form"
          style={{ margin: "15px 0px" }}
        />

        <Row justify="end" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            key={"submit"}
            onClick={onFinish}
            loading={loading}
            className="submit-btn"
            style={{
              // backgroundColor: "#1BC5BD",
              // borderColor: "#1BC5BD",
              marginRight: 30,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={handleCancel}>
            {`Cancel`}
          </Button>
        </Row>
      </Modal>
    </>
  );
}

import React from "react";
// Functions to SUM all Taxes, Services and Grosses
// Export Functions
export function Pricing(props) {
  // Data Revecives
  const { nett, tax, service, is_service_taxed, coefficient } = props;

  // NETT TO INTEGERs
  const _price = parseInt(nett);
  // TAX PERCENTAGES
  const conservice = service / 100;
  const constax = tax / 100;
  // Pricing
  let _gross = 0;

  var services = 0;
  var taxes = 0;
  var gross = 0;

  console.log("Props Data Receives --> ", props);
  // console.log("Nett Data Receives --> ", _price);
  // console.log("Tax % Receives --> ", tax);
  // console.log("Service % Receives --> ", service);

  var _ser = _price * conservice;
  // var _tax = (_ser + _price) * (tax / 100);

  // Service not Taxed
  var _tax_with_ser = (_price + _ser) * constax;

  // Service is Taxed
  var _tax_without_ser = _price * constax;

  // Service and Tax
  if (is_service_taxed) {
    _gross = _price + _ser + _tax_with_ser;
    taxes = parseFloat(_tax_with_ser).toFixed(0);
  } else {
    _gross = _price + _ser + _tax_without_ser;
    taxes = parseFloat(_tax_without_ser).toFixed(0);
  }

  services = parseFloat(_ser).toFixed(0);
  gross = parseFloat(_gross).toFixed(0);

  // console.log("Service --> ", _service);
  // console.log("Tax --> ", _taxed);
  // console.log("Gross --> ", _gross);
  // console.log("Results: ", { nett, taxes, services, gross });

  return { gross, taxes, services };
}

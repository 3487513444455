// REACT COMPONENTS
import React, { useState } from "react";
import { VscServer } from "react-icons/vsc";
import { GoPackageDependents } from "react-icons/go";
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { SearchOutlined } from "@ant-design/icons";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// PAGE COMPONENTS
import StockTransferRequestTable from "../../../../Components/Table/BackOffice/Inventory/TransferRequest/StockTransferRequest/Table";
import WarehouseList from "../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";

export default function StockTransferRequestPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [reqDate, setReqDate] = useState(null);
  const [transferRequestType, setTransferRequestType] = useState(null);
  const [initialWarehouse, setInitialWarehouse] = useState(null);
  const [destinationWarehouse, setDestinationWarehouse] = useState(null);
  // SEARCH
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  // HANDE REQUEST DATE
  const handleRequestDate = (e) => {
    setReqDate(moment(e).format("YYYY-MM-DD"));
  };

  // HANDLE TRANSFER REQUEST TYPE
  const handleTransferRequestType = (value) => {
    const type = value ? value : "";
    setTransferRequestType(type);
  };

  // HANDLE INITIAL DESTINATION
  const handleInitialWarehouse = (data) => {
    setInitialWarehouse(data?.warehouse_name);
  };

  // HANDLE INITIAL DESTINATION
  const handleDestinationWarehouse = (data) => {
    setDestinationWarehouse(data?.warehouse_name);
  };

  // SELECT OPTION
  const selectOption = [
    {
      value: "Transfer To Other Storage",
      label: "Transfer To Other Storage",
    },
    {
      value: "Requisition/Consumed",
      label: "Requisition/Consumed",
    },
    {
      value: "Inter-Kitchen Transfer",
      label: "Inter-Kitchen Transfer",
    },
    {
      value: "Stock Item Transform",
      label: "Stock Item Transform",
    },
  ];

  return (
    <Layout>
      <Content>
        <Col span={24} className="transfer-request-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <GoPackageDependents
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Stock Transfer/Request`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 100,
                  maxWidth: 180,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Document Number"
                  onChange={handleSearch}
                  style={{
                    minWidth: 100,
                    maxWidth: 180,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  maxWidth: 180,
                  minWidth: 100,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Posting  Date`}</Typography>

                <DatePicker
                  allowClear
                  placeholder="Posting Date"
                  className="tr-datepicker"
                  onChange={handleRequestDate}
                  style={{
                    minWidth: 100,
                    maxWidth: 180,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 100,
                  maxWidth: 200,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Transfer/Request Type`}</Typography>

                <Select
                  placeholder="Transfer/Request Type"
                  style={{ width: 200 }}
                  allowClear
                  options={selectOption}
                  onChange={handleTransferRequestType}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 100,
                  maxWidth: 180,
                  marginRight: "18px",
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`From Store`}</Typography>

                <WarehouseList
                  selectedItem={handleInitialWarehouse}
                  warehouse_name={initialWarehouse}
                  is_disable={false}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 100,
                  maxWidth: 180,
                  marginRight: "18px",
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`To Store`}</Typography>

                <WarehouseList
                  selectedItem={handleDestinationWarehouse}
                  warehouse_name={destinationWarehouse}
                  is_disable={false}
                />
              </Col>

              <Col
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <StockTransferRequestTable
            is_search={setSearch}
            searchKey={value}
            requestDate={reqDate}
            transferRequestType={transferRequestType}
            initialWarehouse={initialWarehouse}
            destinationWarehouse={destinationWarehouse}
          />
        </Col>
      </Content>
    </Layout>
  );
}

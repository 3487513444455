// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Col, Input, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Ant Design Icons
import { SearchOutlined } from "@ant-design/icons";
import { HiMiniQueueList } from "react-icons/hi2";
import { PiListMagnifyingGlassBold } from "react-icons/pi";

// Import Page Components
import RoomQueueTable from "../../../../Components/Table/HouseKeep/RoomMonitor/Table";

// Import Global Styles
import {
  cardBody,
  cardHeader,
  mainBody,
} from "../../../../Style/Global/Global";

// CODE
export default function RoomQueuePage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState("");
  // Search
  const [search, setSearch] = useState();
  const [refresh, setRefresh] = useState(false);

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <HiMiniQueueList
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Room Queue`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Col
                style={{
                  textAlign: "start",
                  margin: "22px 15px 22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Source's Name"
                  onChange={handleSearch}
                  style={{
                    width: 300,
                  }}
                />
              </Col>

              <Col
                className="search-btn-col"
                style={{
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  type="primary"
                  className="search-btn"
                  icon={<SearchOutlined />}
                  onClick={handleSearch}
                >
                  {`Search`}
                </Button>
              </Col>
            </Row>

            <Row
              className="room-q-row"
              style={{
                padding: 30,
                backgroundColor: "#FFF",
                borderRadius: 5,
                width: "100%",
              }}
            >
              <RoomQueueTable
                is_search={setSearch}
                searchKey={value}
                is_refresh={setRefresh}
              />
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

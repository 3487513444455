// Import React's Components
import React, { useState } from "react";

// Import Antd's Components
import { Button, Col, Modal, Popover, Row, Tooltip, Typography } from "antd";

// Import React's Icons
import { FaClockRotateLeft, FaWpforms } from "react-icons/fa6";
import { TfiMenuAlt } from "react-icons/tfi";
import PriceHistoryModal from "../../Modals/Purchasing/PriceHistory/Modal";

// MODALS
const { confirm } = Modal;

// CODE
export default function QuotationPopover(props) {
  const { details, is_refresh } = props;

  // Open Popover
  const [open, setOpen] = useState(false);
  const [dataRecord, setDataRecord] = useState([]);
  // Open Modal
  const [historyForm, setHistoryForm] = useState(false);

  const detail = details;

  // Handle Open
  const handleClose = () => {
    setOpen(!open);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "add-update-supplier-confirm",
      title: `Are you sure want to delete supplier named ${_data?.supplier_name.toUpperCase()} from the table?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleDelete(_data);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  return (
    <>
      <Tooltip title="Option Menu" className="price-management-tooltip">
        <Popover
          className="quotation-popover"
          name="quotation-popover"
          key="quotation-popover"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`Price Management Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaClockRotateLeft
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    //   console.log("Price Overview: ", detail);
                    handleClose();
                    setDataRecord(detail);

                    setHistoryForm(true);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Price Changes Overview`}
                </Button>
              </Row>

              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FiEdit
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setArticle(!article);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Add Article Inventory`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <BsPrinter
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setOpenForm(!openForm);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Print List Article Vendor`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <DeleteFilled
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 10px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    showModalConfirm(detail);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Delete Supplier`}
                </Button>
              </Row> */}
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            style={{
              fontSize: 20,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              // marginLeft: 5,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <PriceHistoryModal
        is_open={historyForm}
        is_close={setHistoryForm}
        articles={dataRecord}
      />
    </>
  );
}

// REACT COMPONENTS
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Select, Space, Typography, DatePicker, Form, } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaRegCalendar } from "react-icons/fa6";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import MasterArticleMain from "../../../../../../Components/Reusable/Master/ArticleMain/ArticleMain";
import AnnualOutgoingReportTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/OutgoingStock/AnnualOutgoing/Table";

export default function AnnualOutgoingReportPage() {
  // STATE
  const [articleMain, setArticleMain] = useState(null);
  const [year, setYear] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [mainElement, setMainElement] = useState(null);
  const [typeElement, setTypeElement] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/outgoing-stock/";

    navigate(`${path}`);
  };

  const handleYear = (value) => {
    console.log("handleYear value", value);
    if(value){
      setYear(value);
      setMainElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
          minWidth: 250,
        }}
      >
        <Typography
          style={{ marginBottom: 10 }}
        >{`Article Main Group`}</Typography>

        <MasterArticleMain
          getArticleMain={handleSelectedArticleMain}
          mainName={null}
          isDisabled={false}
        />
      </Col>);
    } else {
      setYear(null);
      setMainElement(null);
      setArticleMain(null);
      setTypeElement(null);
      setFilterValue(null);
    }
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticleMain = (value) => {
    if(value.main_id){
      setArticleMain(value);
      setTypeElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        <Typography style={{ marginBottom: 10 }}>
          {`Data Type`}
        </Typography>
        <Form name="filter">
          <Select
            className="type-select"
            key="type-select"
            allowClear
            showSearch
            onChange={handleFilter}
            options={options}
            placeholder="Filter By"
            style={{
              width: 200,
            }}
          />
        </Form>
      </Col>);
    } else {
      setArticleMain(null);
      setTypeElement(null);
      setFilterValue(null);
    };
  };

  // HANDLE FILTER
  const handleFilter = (value, options) => {
    if(value) {
      setFilterValue(options);
    } else {
      setFilterValue(null);
    }
    
  };

  const options = [
    {
      label: "Quantity",
      value: 1,
    },
    {
      label: "Average Price",
      value: 2,
    },
    {
      label: "Amount",
      value: 3,
    },
  ];

  return (
    <Layout>
      <Content>
        <Col span={24} className="incoming-stock-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaRegCalendar
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Annual Outgoing Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Year`}
                </Typography>
                <DatePicker
                  className="year"
                  placeholder="Select Year"
                  format="YYYY"
                  allowClear
                  onChange={handleYear}
                  picker="year"
                  style={{
                    minWidth: 150,
                    maxWidth: 250,
                  }}
                />
              </Col>
              {mainElement}
              {typeElement}
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {year && articleMain && filterValue ? (
            <AnnualOutgoingReportTable
              _main={articleMain}
              _year={year}
              _type={filterValue}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_module } from "../../API/Global/Payload";

// Import Pages
import NotFound from "../../Pages/NotFound/NotFound";
import ReportPage from "../../Pages/Report/Main/Report";
import AccountingReportRoutes from "./Accounting/Routes";
import HKReportsPage from "../../Pages/Report/HouseKeeping/Main/MainPage";
import RoomReckonPage from "../../Pages/Report/HouseKeeping/Reckon/Reckon";
import RoomQueuePage from "../../Pages/Report/HouseKeeping/RoomQueue/RoomQueue";
import FOReportRoutes from "./FrontOffice/Routes";
import BOReportRoutes from "./BackOffice/Routes";

import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";

// ROUTES
const subRoute = [
  {
    path: "accounting/*",
    name: "ac-gr",
    id_modules: 63,
    element: <AccountingReportRoutes />,
  },
  {
    path: "back-office/*",
    name: "fo-bo",
    id_modules: 64,
    element: <BOReportRoutes />,
  },
  {
    path: "food-and-beverage",
    name: "fb-gr",
    id_modules: 65,
    element: <NotFound />,
  },
  {
    path: "front-office/*",
    name: "fo-gr",
    id_modules: 66,
    element: <FOReportRoutes />,
  },
  {
    path: "house-keeping",
    name: "hk-gr",
    id_modules: 67,
    element: <HKReportsPage />,
  },
  {
    path: "house-keeping/room-reckon",
    name: "hk-rk-gr",
    id_modules: 67,
    element: <RoomReckonPage />,
  },
  {
    path: "house-keeping/room-queue",
    name: "hk-rq-gr",
    id_modules: 67,
    element: <RoomQueuePage />,
  },
];

// CODE
export default function InventoryRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_module?.length > 0 ? sub_module : []; // values.module;

    const proRoute = subRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<ReportPage />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}
      </Routes>
      <Outlet />
    </>
  );
}

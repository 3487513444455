// AUTH
import { Typography } from "antd";
import { department, roles } from "../../API/Global/Payload";

// Import Icons
import {
  AiFillSetting,
  AiOutlineForm,
  AiTwotoneFolderOpen,
} from "react-icons/ai";
import { BiBox } from "react-icons/bi";
import { BsCalculatorFill, BsDoorOpenFill } from "react-icons/bs";
import { FaBuilding, FaConciergeBell } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { MdChair, MdLayers } from "react-icons/md";
import { VscLibrary } from "react-icons/vsc";

// GLOBAL STYLING
import { menuStyles, subMenuStyles } from "../../Style/Global/Global";

// Create Function Items
function getItem(label, key, icon, role, dept, children, sub_children) {
  return {
    label, // Label Modules
    key, // Unique Id
    icon, // Icons Module
    role, //
    dept,
    children, // Sub Modules
    sub_children, // Sub Children
    // roles
  };
}

// Create Function Items
function getModules(
  label,
  key,
  icon,
  id_module,
  children,
  id_children,
  sub_children,
  id_sub_children
) {
  return {
    label, // Label Modules
    key, // Unique Id
    icon, // Icons Module
    id_module, // Module ID
    children, // Sub Modules
    // id_children, // ID SUB MODULES
    // sub_children, // Sub Children
    // id_sub_children, // ID SUB CHILDREN
  };
}

// Items List
const itemsModule = [
  getModules("Dashboard", "dashboard", <MdLayers style={menuStyles} />, 6),
  getModules(
    "Front Office",
    "front-office",
    <MdChair className="list-icon" style={menuStyles} />,
    1,
    [
      getModules(
        "Reservation",
        "reservation",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        9
      ),
      getModules(
        "Reservation Deposit",
        "reservation-deposit",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        10
      ),
      getModules(
        "Expected Arrival ",
        "expected-arrival",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        11
      ),
      getModules(
        "Guest In House",
        "guest-inhouse",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        12
      ),
      getModules(
        "Expected Departure",
        "expected-departure",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        13
      ),
      getModules(
        "Foreign Guest List",
        "foreign-guest-list",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        14
      ),
      getModules(
        "History Guest List",
        "history-guest-list",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        15
      ),
      getModules(
        "Bill Outstanding List",
        "bill-outstanding-list",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        16
      ),
      getModules(
        "ABF Guest List",
        "abf-guest-list",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        17
      ),
      getModules(
        "Birthday",
        "birthday",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        18
      ),
      getModules(
        "Walk In Guest List",
        "walkin-guest-list",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        19
      ),
      getModules(
        "Room Chart",
        "room-chart",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        20
      ),
      getModules(
        "Room Availability",
        "room-availability",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        21
      ),
      getModules(
        "Non-Stay",
        "non-stay",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        22
      ),
      getModules(
        "History Non-Stay",
        "history-non-stay",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        23
      ),
      getModules(
        "Night Audit",
        "night-audit",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        24
      ),
      getModules(
        "Re-Night Audit",
        "re-audit",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        112
      ),
    ]
  ),
  getModules(
    "Back Office",
    "back-office",
    <VscLibrary style={menuStyles} />,
    3,
    [
      getModules(
        "Budget",
        "budget",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        25
      ),
      getModules(
        "A/R (Account Receivable)",
        "account-receivable",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        26
      ),
      getModules(
        "A/P (Account Payable)",
        "account-payable",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        107
      ),
      getModules(
        "Purchasing",
        "purchasing",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        27
      ),
      getModules(
        "Inventory",
        "inventory",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        28
      ),
      getModules(
        "Accounting",
        "accounting",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        4
      ),
    ]
  ),
  getModules(
    "House Keeping",
    "house-keeping",
    <BsDoorOpenFill style={menuStyles} />,
    2,
    [
      getModules(
        "Room Monitoring",
        "room-monitoring",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        37
      ),
      getModules(
        "Room Status",
        "room-status",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        38
      ),
      getModules(
        "Out of Order",
        "out-of-order",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        39
      ),
      getModules(
        "Off-Market",
        "off-market",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        40
      ),
      getModules(
        "Lost & Found",
        "lost-and-found",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        41
      ),
    ]
  ),
  getModules("F & B", "f-and-b", <FaConciergeBell style={menuStyles} />, 5, [
    getModules(
      "MICE",
      "mice",
      <GoDotFill className="list-icon" style={subMenuStyles} />,
      42
    ),
    getModules(
      "Outlet",
      "outlet",
      <GoDotFill className="list-icon" style={subMenuStyles} />,
      43
    ),
  ]),
  getModules(
    "General Report",
    "report",
    <AiTwotoneFolderOpen style={menuStyles} />,
    7
  ),
  getModules(
    "Configuration",
    "configuration",
    <AiFillSetting style={menuStyles} />,
    8,
    [
      getModules(
        "Master",
        "master",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        44
      ),
      getModules(
        "Parameters",
        "parameters",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        45
      ),
      getModules(
        "User Control",
        "user-management",
        <GoDotFill className="list-icon" style={subMenuStyles} />,
        46
      ),
    ]
  ),
];

const items = {
  ROLE_SUPER_ADMIN: [
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Dashboard
      </Typography>,
      "dashboard",
      <MdLayers className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Front Office
      </Typography>,
      "front-office",
      <MdChair className="list-icon" style={menuStyles} />,
      roles,
      department,
      [
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Reservation
          </span>,
          "reservation",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Reservation Deposit
          </span>,
          "reservation-deposit",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Expected Arrival
          </span>,
          "expected-arrival",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Guest In House
          </span>,
          "guest-inhouse",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Expected Departure
          </span>,
          "expected-departure",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        // getItem(
        //   "Early Check Out",
        //   "early-check-out",
        //   <GoDotFill className="list-icon" style={subMenuStyles} />
        // ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Foreign Guest List
          </span>,
          "foreign-guest-list",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            History Guest List
          </span>,
          "history-guest-list",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Bill Outstanding List
          </span>,
          "bill-outstanding-list",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            ABF Guest List
          </span>,
          "abf-guest-list",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Birthday
          </span>,
          "birthday",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Walk In Guest List
          </span>,
          "walkin-guest-list",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Room Chart
          </span>,
          "room-chart",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Room Availability
          </span>,
          "room-availability",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Non-Stay
          </span>,
          "non-stay",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            History Non-Stay
          </span>,
          "history-non-stay",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Night Audit
          </span>,
          "night-audit",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
      ]
    ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Back Office
      </Typography>,
      "back-office",
      <VscLibrary className="list-icon" style={menuStyles} />,
      roles,
      department,
      [
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Budget
          </span>,
          "budget",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            A/R (Account Receivable)
          </span>,
          "account-receivable",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Purchasing
          </span>,
          "purchasing",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Inventory
          </span>,
          "inventory",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <Typography
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Accounting
          </Typography>,
          "accounting",
          <GoDotFill className="list-icon" style={subMenuStyles} />,
          roles,
          department
        ),
      ]
    ),
    // getItem(
    //   <Typography
    //     className="text-title"
    //     style={{
    //       color: "#888c9f",
    //     }}
    //   >
    //     Accounting
    //   </Typography>,
    //   "accounting",
    //   <BsCalculatorFill className="list-icon" style={menuStyles} />,
    //   roles,
    //   department
    // [
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       Journal
    //     </span>,
    //     "create-journal",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       Journal List
    //     </span>,
    //     "journal-list",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       General Ledger
    //     </span>,
    //     "general-ledgers",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    //   // getItem(
    //   //   <span
    //   //     className="text-items"
    //   //     style={{
    //   //       color: "#888c9f",
    //   //     }}
    //   //   >
    //   //     Trial Balance
    //   //   </span>,
    //   //   "trial-balance",
    //   //   <GoDotFill className="list-icon" style={subMenuStyles} />
    //   // ),
    //   // getItem(
    //   //   <span
    //   //     className="text-items"
    //   //     style={{
    //   //       color: "#888c9f",
    //   //     }}
    //   //   >
    //   //     Profit & Lost
    //   //   </span>,
    //   //   "profit-and-lost",
    //   //   <GoDotFill className="list-icon" style={subMenuStyles} />
    //   // ),
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       Chart of Account
    //     </span>,
    //     "chart-of-account",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       Closing
    //     </span>,
    //     "closing",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       Accounting Reports
    //     </span>,
    //     "accounting-reports",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       Company Ledgers
    //     </span>,
    //     "accounting-ledgers",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    //   getItem(
    //     <span
    //       className="text-items"
    //       style={{
    //         color: "#888c9f",
    //       }}
    //     >
    //       Start Up Accounting
    //     </span>,
    //     "start-up-accounting",
    //     <GoDotFill className="list-icon" style={subMenuStyles} />
    //   ),
    // ]
    // ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        House Keeping
      </Typography>,
      "house-keeping",
      <BsDoorOpenFill className="list-icon" style={menuStyles} />,
      roles,
      department,
      [
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Room Monitoring
          </span>,
          "room-monitoring",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Room Status
          </span>,
          "room-status",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Out of Order
          </span>,
          "out-of-order",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Off-Market
          </span>,
          "off-market",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Lost & Found
          </span>,
          "lost-and-found",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
      ]
    ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        F & B
      </Typography>,
      "f-and-b",
      <FaConciergeBell className="list-icon" style={menuStyles} />,
      roles,
      department,
      [
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            MICE
          </span>,
          "mice",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Outlet
          </span>,
          "outlet",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        // getItem(
        //   "Table Order",
        //   "table-order",
        //   <GoDotFill className="list-icon" style={subMenuStyles} />
        // ),
        // getItem(
        //   "Kitchen Order",
        //   "kitchen-order",
        //   <GoDotFill className="list-icon" style={subMenuStyles} />
        // ),
        // getItem(
        //   "POS Cashier",
        //   "pos-cashier",
        //   <GoDotFill className="list-icon" style={subMenuStyles} />
        // ),
        // getItem(
        //   "Report Outlet",
        //   "report-outlet",
        //   <GoDotFill className="list-icon" style={subMenuStyles} />
        // ),
      ]
    ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Inventory
      </Typography>,
      "inventory",
      <BiBox className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        General Report
      </Typography>,
      "report",
      <AiTwotoneFolderOpen className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    // getItem(
    //   <Typography
    //     className="text-title"
    //     style={{
    //       color: "#888c9f",
    //     }}
    //   >
    //     Assets
    //   </Typography>,
    //   "assets",
    //   <FaBuilding className="list-icon" style={menuStyles} />,
    //   roles,
    //   department
    // ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Configuration
      </Typography>,
      "configuration",
      <AiFillSetting className="list-icon" style={menuStyles} />,
      roles,
      department,
      [
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Master
          </span>,
          "master",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Parameters
          </span>,
          "parameters",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            User Control
          </span>,
          "user-management",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
      ]
    ),
  ],
  ROLE_STAFF: [
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Dashboard
      </Typography>,
      "dashboard",
      <MdLayers className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    {
      FRONT_OFFICE: getItem(
        <Typography
          className="text-title"
          style={{
            color: "#888c9f",
          }}
        >
          Front Office
        </Typography>,
        "front-office",
        <MdChair className="list-icon" style={menuStyles} />,
        roles,
        department,
        [
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Reservation
            </span>,
            "reservation",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Reservation Deposit
            </span>,
            "reservation-deposit",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Expected Arrival
            </span>,
            "expected-arrival",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          // getItem(
          //   "Early Check Out",
          //   "early-check-out",
          //   <GoDotFill className="list-icon" style={subMenuStyles} />
          // ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Guest In House
            </span>,
            "guest-inhouse",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Expected Departure
            </span>,
            "expected-departure",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),

          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Foreign Guest List
            </span>,
            "foreign-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              History Guest List
            </span>,
            "history-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Bill Outstanding List
            </span>,
            "bill-outstanding-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              ABF Guest List
            </span>,
            "abf-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Birthday
            </span>,
            "birthday",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Walk In Guest List
            </span>,
            "walkin-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Room Chart
            </span>,
            "room-chart",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Room Availability
            </span>,
            "room-availability",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Non-Stay
            </span>,
            "non-stay",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              History Non-Stay
            </span>,
            "history-non-stay",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
        ]
      ),
      HOUSE_KEEPING: getItem(
        "House Keeping",
        "house-keeping",
        <BsDoorOpenFill style={menuStyles} />,
        roles,
        department,
        [
          getItem(
            "Room Monitoring",
            "room-monitoring",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Room Status",
            "room-status",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Out of Order",
            "out-of-order",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Off-Market",
            "off-market",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Lost & Found",
            "lost-and-found",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
        ]
      ),
    }[department] || "",
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        General Report
      </Typography>,
      "report",
      <AiTwotoneFolderOpen className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    getItem(
      <Typography
        className="text-items"
        style={{
          color: "#888c9f",
        }}
      >
        Master
      </Typography>,
      "configuration/master",
      <AiFillSetting className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
  ],
  ROLE_MANAGER: [
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Dashboard
      </Typography>,
      "dashboard",
      <MdLayers style={menuStyles} />,
      roles,
      department
    ),
    {
      FRONT_OFFICE: getItem(
        <Typography
          className="text-title"
          style={{
            color: "#888c9f",
          }}
        >
          Front Office
        </Typography>,
        "front-office",
        <MdChair className="list-icon" style={menuStyles} />,
        roles,
        department,
        [
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Reservation
            </span>,
            "reservation",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Reservation Deposit
            </span>,
            "reservation-deposit",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Expected Arrival
            </span>,
            "expected-arrival",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          // getItem(
          //   "Early Check Out",
          //   "early-check-out",
          //   <GoDotFill className="list-icon" style={subMenuStyles} />
          // ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Guest In House
            </span>,
            "guest-inhouse",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Expected Departure
            </span>,
            "expected-departure",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Foreign Guest List
            </span>,
            "foreign-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              History Guest List
            </span>,
            "history-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Bill Outstanding List
            </span>,
            "bill-outstanding-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              ABF Guest List
            </span>,
            "abf-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Birthday
            </span>,
            "birthday",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Walk In Guest List
            </span>,
            "walkin-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Room Chart
            </span>,
            "room-chart",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Room Availability
            </span>,
            "room-availability",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Non-Stay
            </span>,
            "non-stay",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              History Non-Stay
            </span>,
            "history-non-stay",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
        ]
      ),
      HOUSE_KEEPING: getItem(
        "House Keeping",
        "house-keeping",
        <BsDoorOpenFill style={menuStyles} />,
        roles,
        department,
        [
          getItem(
            "Room Monitoring",
            "room-monitoring",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Room Status",
            "room-status",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Out of Order",
            "out-of-order",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Off-Market",
            "off-market",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Lost & Found",
            "lost-and-found",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
        ]
      ),
    }[department] || "",
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        General Report
      </Typography>,
      "report",
      <AiTwotoneFolderOpen className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    getItem(
      <Typography
        className="text-items"
        style={{
          color: "#888c9f",
        }}
      >
        Master
      </Typography>,
      "configuration/master",
      <AiFillSetting className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
  ],
  ROLE_SUPERVISOR: [
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Dashboard
      </Typography>,
      "dashboard",
      <MdLayers style={menuStyles} />,
      roles,
      department
    ),
    {
      FRONT_OFFICE: getItem(
        <Typography
          className="text-title"
          style={{
            color: "#888c9f",
          }}
        >
          Front Office
        </Typography>,
        "front-office",
        <MdChair className="list-icon" style={menuStyles} />,
        roles,
        department,
        [
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Reservation
            </span>,
            "reservation",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Reservation Deposit
            </span>,
            "reservation-deposit",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Expected Departure
            </span>,
            "expected-departure",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Expected Arrival
            </span>,
            "expected-arrival",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          // getItem(
          //   "Early Check Out",
          //   "early-check-out",
          //   <GoDotFill className="list-icon" style={subMenuStyles} />
          // ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Guest In House
            </span>,
            "guest-inhouse",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Foreign Guest List
            </span>,
            "foreign-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              History Guest List
            </span>,
            "history-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Bill Outstanding List
            </span>,
            "bill-outstanding-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              ABF Guest List
            </span>,
            "abf-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Birthday
            </span>,
            "birthday",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Walk In Guest List
            </span>,
            "walkin-guest-list",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Room Chart
            </span>,
            "room-chart",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Room Availability
            </span>,
            "room-availability",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              Non-Stay
            </span>,
            "non-stay",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            <span
              className="text-items"
              style={{
                color: "#888c9f",
              }}
            >
              History Non-Stay
            </span>,
            "history-non-stay",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
        ]
      ),
      HOUSE_KEEPING: getItem(
        "House Keeping",
        "house-keeping",
        <BsDoorOpenFill style={menuStyles} />,
        roles,
        department,
        [
          getItem(
            "Room Monitoring",
            "room-monitoring",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Room Status",
            "room-status",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Out of Order",
            "out-of-order",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Off-Market",
            "off-market",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
          getItem(
            "Lost & Found",
            "lost-and-found",
            <GoDotFill className="list-icon" style={subMenuStyles} />
          ),
        ]
      ),
    }[department] || "",
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        General Report
      </Typography>,
      "report",
      <AiTwotoneFolderOpen className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    getItem(
      <Typography
        className="text-items"
        style={{
          color: "#888c9f",
        }}
      >
        Master
      </Typography>,
      "configuration/master",
      <AiFillSetting className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
  ],
  ROLE_ADMIN: [
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Dashboard
      </Typography>,
      "dashboard",
      <MdLayers className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        General Report
      </Typography>,
      "report",
      <AiTwotoneFolderOpen className="list-icon" style={menuStyles} />,
      roles,
      department
    ),
    getItem(
      <Typography
        className="text-title"
        style={{
          color: "#888c9f",
        }}
      >
        Configuration
      </Typography>,
      "configuration",
      <AiFillSetting className="list-icon" style={menuStyles} />,
      roles,
      department,
      [
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Master
          </span>,
          "master",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Parameters
          </span>,
          "parameters",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
        getItem(
          <span
            className="text-items"
            style={{
              color: "#888c9f",
            }}
          >
            Roles
          </span>,
          "roles",
          <GoDotFill className="list-icon" style={subMenuStyles} />
        ),
      ]
    ),
  ],
}[roles];

export { items, itemsModule };

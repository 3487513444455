// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import axios from "axios";

// ANTD COMPONETNS
import { DeleteFilled } from "@ant-design/icons";
import { Col, Modal, Row, Table, Tooltip } from "antd";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../../../Reusable/Notification/MasterNotif/Notification";

// GLOBAL API
import { baseurl } from "../../../../../../API/Config/Api";
import { token, user_name } from "../../../../../../API/Global/Payload";
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

const { confirm } = Modal;

// CODE
export default function StockTransferRequestDetailTable(props) {
  const {
    article,
    setArticle,
    editArticle,
    count,
    is_edit,
    is_loading,
    is_refresh,
    is_reset,
  } = props;

  const [dataSource, setDataSource] = useState([]);
  const [select, setSelect] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let cleanUp = false;

    if (article && !cleanUp) {
      console.log("ARTICLEEE >>> ", article);
      setDataSource(article);
    } else {
      setDataSource([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [article]);

  useEffect(() => {
    let cleanUp = false;

    if (is_edit && !cleanUp) {
      console.log("ARTICLEEE >>> ", article);
      setDataSource(article);
    } else {
      setDataSource([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_edit]);

  // SHOW DELETE MODAL
  const showDeleteModal = (record) => {
    const submitContent = record;

    console.log("Data About to Delete =>>> ", submitContent);

    confirm({
      className: "article-confirm",
      title: `Are you sure want to ${`Delete Article with named ${
        submitContent?.article_name.toUpperCase() || " ---- "
      }`}`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        handleDelete(submitContent);
      },
      onCancel() {
        setIsLoading(false);
      },
      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentDelete = record;

    console.log("CONTENT DELETEeee >>> ", contentDelete);

    if (contentDelete.id_warehouse_transfer_detail) {
      const reqDelete = {
        id_warehouse_transfer_detail:
          contentDelete?.id_warehouse_transfer_detail,
        deleted_by: user_name,
      };

      await axios
        .delete(`${baseurl}/inventory/transfer-request-stocks-detail`, {
          data: reqDelete,

          headers: { Authorization: `Bearer ${token ? token : ""}` },
        })
        .then((response) => {
          console.log("Response Delete Article: ", response);
          masterSuccessNotification(response, { method: 2, source: "Article" });

          const filterArticle = article.filter(
            (item) => item.article_name !== contentDelete.article_name
          );

          console.log(filterArticle);

          setArticle(filterArticle);
          is_refresh();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, { method: 2, source: "Article" });
          setIsLoading(false);
        });
    } else {
      const filterArticle = article.filter(
        (item) => item.article_name !== contentDelete.article_name
      );

      console.log("FILTERED DELETE >>>> ", filterArticle);
      setArticle(filterArticle);
      count((state) => (state > 0 ? state - 1 : 0));
      masterSuccessNotification(contentDelete, {
        method: 2,
        source: "Article",
      });
    }
  };

  useEffect(() => {
    setSelect([]);
  }, [is_reset]);

  const handleSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);

      setSelect(selectedRowKeys);
      editArticle(selectedRows[0]);
      is_edit(true);
    },
    columnTitle: "Edit",
  };

  // Params
  const currency = CurrencySymbols().code;

  // TABLE COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 75,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Stock Item",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Stock On Hand",
      dataIndex: "delivery_stock",
      key: "delivery_stock",
      render: (stock, record) => {
        return `${stock}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Price (${currency})`,
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity_requested",
      key: "quantity_requested",
      width: 75,
      render: (quantity_requested, record) => {
        return `${quantity_requested}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Amount (${currency})`,
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      render: (total_price, record) => {
        return `${total_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Table
        className="transfer-request-detail-table"
        name="transfer-request-detail-table"
        key="transfer-request-detail-table"
        rowSelection={{ type: "radio", ...handleSelection }}
        columns={columns}
        dataSource={dataSource}
        loading={is_loading}
        bordered
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} entries`,
        }}
        scroll={{
          x: 1100,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? "odd" : "even";
        }}
        rowKey={(record) => record.article_name}
      />
    </>
  );
}

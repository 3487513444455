// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons
import {
  FormOutlined,
  MenuOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { FaExclamationTriangle } from "react-icons/fa";
import { failedFetch } from "../../Notification/Notification";

// Notifications

// CODE
export default function MasterRateCodeMultiple(props) {
  const {
    // GET RATE
    getRateCode,
    rate_code,
    // Segment
    segment_id,
  } = props;

  // CONTEXT
  const { getMasterRateMulti } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Segment
  const [rate, setRate] = useState([]);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async (val) => {
    const _segId = val;

    await getMasterRateMulti({
      master: "guest",
      type: "company-and-ota",
      code: "rate-code",
      main_segment_id: _segId.join("|") || segment_id.join("|"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Rate Status Multiply: ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((filt) => {
            return !filt.is_deleted;
          });

          // console.log("Rate Options --> ", filtered);
          // Filtering by Room Category
          handleFilter(filtered);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // failedFetch()
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (segment_id?.length > 0 && !cleanUp) {
      console.log("Segment ID Arr: ", segment_id);

      fetchData(segment_id);
    } else {
      setOptionValue([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [segment_id]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (rate_code?.length > 0 && !cleanUp) {
      setRate(rate_code);
    } else {
      setRate([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [rate_code]);

  // HANDLE FILTER
  const handleFilter = (arr) => {
    const _option = arr;

    console.log("About to Filtering: ", _option);

    // Mapping to Option Value
    let _filter = _option.map((dev) => ({
      value: dev?.rate_code,
      label: dev?.rate_code,
      // code: dev?.rate_code_id,
      id: dev?.id,
      main_segment_id: dev?.main_segment_id,
      main_segment_name: dev?.main_segment_name,
      main_segment_code: dev?.main_segment_code,
      room: dev?.room_category_name,
      // rate_code: "STD0101",
      // description: dev?.description || null,
    }));

    console.log("Mapped Options: ", _filter);
    setOptionValue(_filter);
  };

  // HANDLE GET RATE CODE
  const handleGetRate = (val) => {
    const _value = val.value;
    let _arr = val.allKey;

    let _getId = _arr.map((val) => val?.id);

    console.log(_getId);

    getRateCode(_value, _getId);
  };

  return (
    <>
      {/*
      <Space
        className="rate-row"
        align="start"
        size={0}
        style={{ width: "100%" }}
      >
         <Col className="col" */}
      <Select
        key={"rate-code-multi-select"}
        className={"rate-code-multi-select"}
        value={rate}
        mode="multiple"
        placeholder="Select Rate Codes!"
        allowClear
        showSearch
        onChange={(value, allKey) => {
          setRate(value);

          console.log("Multiple: ", value, allKey);

          handleGetRate({ value: value, allKey: allKey });
          // getRateCode(value, allKey);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
        style={{ minWidth: "100%" }}
      />
      {/* <Button
          className="submit-btn"
          type="primary"
          disabled={is_disable == true ? true : false}
          onClick={handleOpen}
        >{`Rate`}</Button> 
      </Space>
        */}
    </>
  );
}

// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import { roles, token, user_name } from "../../../API/Global/Payload";
import {
  authorized_by,
  handleAuthorization,
} from "../../../API/Global/Permission";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Input,
  Divider,
  Button,
  Modal,
  TimePicker,
  InputNumber,
  Skeleton,
  Checkbox,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import { FormOutlined } from "@ant-design/icons";
import { MdLayers } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { BsCalculatorFill } from "react-icons/bs";

// Import Master Components
import InputSkeleton from "../../../Components/Reusable/Skeleton/InputSkeleton";
import ModalAuthorized from "../../../Components/Modals/Permissions/ModalCancel";
import MasterArticleList from "../../../Components/Reusable/Master/ArticleList/ArticleList";

// Import Functions
import { LocalizationDate } from "../../../Components/Reusable/Functions/Localizations/Localization";
import { Encoder } from "../../../API/Global/Encoder";
import { Decoder } from "../../../API/Global/Decoder";
import MasterCOA from "../../../Components/Reusable/Accounting/MainAccount/MainAccount";

// Import Notification
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";
import {
  failedParams,
  successParams,
} from "../../../Components/Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";

// MODAL
const { confirm } = Modal;

// CODE
export default function FrontOfficeParams() {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // Params
  const currency_symbols = sessionStorage.getItem("par-1");

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [articleID, setArticleID] = useState(null);
  const [disabled, setDisabled] = useState(true);
  // Loading
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // Modal Permissions
  const [open, setOpen] = useState(false);
  const [tokenParams, setTokenParams] = useState(null);
  const [permitted, setPermitted] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    handleSuccess();
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (data && !cleanUp) {
      setFormFields(data);
    }

    if (currency_symbols && !cleanUp) {
      const decode = Decoder(currency_symbols);

      setTokenParams(decode);
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "fo",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Params FO --> ", response);
        setData(response?.data?.msg[0]);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Params FO err --> ", error);
        setLoading(false);
        failedFetch(error);
      },
    });

    // setTimeout(() => {
    //   setLoading(false);
    // }, 2500);
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    navigate("/configuration/parameters");
  };

  // SET FORM FIELDS
  const setFormFields = (value) => {
    const _data = value;

    setArticleID(_data?.article_id);

    form.setFieldsValue({
      res_stay_id: _data?.res_stay_id ? _data.res_stay_id : "",
      res_nonstay_id: _data?.res_nonstay_id ? _data.res_nonstay_id : "",
      deposit_coa: _data?.deposit_coa ? _data.deposit_coa : "",
      revenue_coa: _data?.revenue_coa ? _data.revenue_coa : "",
      group_count: _data?.group_count ? _data.group_count : 0,
      breakfast_price: _data?.article_price ? _data.article_price : "",
      breakfast_name: _data?.article_name || null,
      article_id: _data?.article_id || null,
      bill_type: _data.bill_type == true ? true : false,
      is_cancel_ci: _data.is_cancel_ci == true ? true : false,
      is_cancel_co: _data.is_cancel_co == true ? true : false,
      check_in_sign_by: _data?.check_in_sign_by ? _data.check_in_sign_by : "",
      check_out_sign_by: _data?.check_out_sign_by
        ? _data.check_out_sign_by
        : "",
      registration_sign_by: _data?.registration_sign_by
        ? _data.registration_sign_by
        : "",
      updated_by: _data?.updated_by ? _data.updated_by : "",
      updated_date: moment(LocalizationDate(_data?.updated_date)).format(
        "HH:mm:ss, DD-MM-YYYY"
      ),
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;
    setIsLoading(true);

    console.log("ON FINISH >>> ", content);
    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const submitContent = value;

    console.log("About to Confirm: ", submitContent);
    console.log("Article ID: ", articleID);
    console.log("Data: ", data);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to update the parameters?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(submitContent);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
      },
    });
  };

  // HANDLE UPDATE
  const handleUpdate = async (value) => {
    const content = value;

    // setLoading(true);

    console.log(content);

    await axios
      .put(
        `${baseurl}/parameters/fo`,
        {
          res_stay_id: content?.res_stay_id
            ? content.res_stay_id
            : data?.res_stay_id || null,
          res_nonstay_id: content?.res_nonstay_id
            ? content.res_nonstay_id
            : data?.res_nonstay_id || null,
          deposit_coa: content?.deposit_coa
            ? content.deposit_coa
            : data?.deposit_coa,
          revenue_coa: content?.revenue_coa
            ? content.revenue_coa
            : data?.revenue_coa,
          check_in_sign_by: content?.check_in_sign_by
            ? content.check_in_sign_by
            : data?.check_in_sign_by,
          check_out_sign_by: content?.check_out_sign_by
            ? content.check_out_sign_by
            : data?.check_out_sign_by,
          registration_sign_by: content?.registration_sign_by
            ? content.registration_sign_by
            : data?.registration_sign_by,
          checkin_time: content?.checkin_time
            ? moment(content?.checkin_time).format()
            : moment(data?.checkin_time).format(),
          checkout_time: content?.checkout_time
            ? moment(content?.checkout_time).format()
            : moment(data?.checkout_time).format(),
          group_count: content?.group_count
            ? content.group_count
            : data?.group_count,
          article_id: articleID ? articleID : data.article_id,
          article_name: content?.breakfast_name
            ? content.breakfast_name
            : data?.article_name,
          article_price: content?.breakfast_price
            ? content.breakfast_price
            : data?.breakfast_price,
          bill_type: content.bill_type == true ? true : false,
          is_cancel_ci: content.is_cancel_ci == true ? true : false,
          is_cancel_co: content.is_cancel_co == true ? true : false,

          id: data?.id ? data.id : content?.id || null,

          updated_by: authorized_by,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response --> ", response);
        successParams(response, { method: 1 });

        handleSuccess(content);

        fetchData();
      })
      .catch((error) => {
        console.log("Error --> ", error);
        failedParams(error, { method: 1 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // DEPOSIT COA
  const getDepositCoa = (value, index) => {
    console.log("Value --> ", value, "Index --> " + index);

    form.setFieldsValue({
      deposit_coa: value,
    });
  };

  // REVENUE COA
  const getRevenueCoa = (value, index) => {
    console.log(value, index);

    form.setFieldsValue({
      revenue_coa: value,
    });
  };

  // Article List
  const getArticle = (value) => {
    console.log("Article List: ", value);

    setArticleID(value?.article_id);

    form.setFieldsValue({
      breakfast_name: value?.article_name,
      breakfast_price: value?.price,
    });
  };

  // HANDLE EDIT
  const handleEdit = () => {
    if (disabled) {
      if (permitted) {
        setDisabled(false);
      } else {
        setOpen(true);
      }
    } else {
      setDisabled(true);
    }
  };

  // HANDLE PERMISSION
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setDisabled(false);
        setPermitted(true);
        break;

      default:
        setDisabled(true);
        setPermitted(false);
        break;
    }
  };

  // HANDLE CANCEL
  const handleSuccess = (value) => {
    setDisabled(true);

    localStorage.clear();
    handleAuthorization("");

    if (value) {
      // Update Params
      const jwt = Encoder(value);
      // console.log("JWT GENERATED: ", jwt);

      sessionStorage.setItem("par-3", jwt);
    }
  };

  // CONSOLE
  // console.log("Data --> ", data);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={mainBody}>
              <MdLayers
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`FRONT OFFICE PARAMETERS`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-first"
                style={{ padding: 30 }}
              >
                {loading == true ? (
                  <>
                    <InputSkeleton is_loading={loading} />
                  </>
                ) : (
                  <Form
                    className="parameters-form"
                    name="parameters-form"
                    key="parameters-form"
                    autoComplete="false"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    initialValues={{
                      checkin_time: data?.checkin_time
                        ? moment(LocalizationDate(data.checkin_time), "HH:mm")
                        : null,
                      checkout_time: data?.checkout_time
                        ? moment(LocalizationDate(data.checkout_time), "HH:mm")
                        : null,
                    }}
                    style={{ padding: "36px 36px 0px 36px" }}
                  >
                    <Row gutter={30} className="row-header">
                      <Form.Item
                        label="Reservation Header's Code (Stay)"
                        name="res_stay_id"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          placeholder="Reservation Header's Code"
                          disabled={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Reservation Header's Code (NonStay)"
                        name="res_nonstay_id"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          placeholder="Reservation Header's Code for Non-stay"
                          disabled={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Deposit COA"
                        name="deposit_coa"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          placeholder="Chart of Accounts Deposit Guest"
                          disabled
                        />
                        {/* <MasterCOA
                          coa_name={data?.deposit_coa ? data.deposit_coa : ""}
                          getCoaName={getDepositCoa}
                          is_all={true}
                        /> */}
                      </Form.Item>

                      <Form.Item
                        label="Revenue COA"
                        name="revenue_coa"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          placeholder="Chart of Accounts Revenue"
                          disabled
                        />
                        {/* <MasterCOA
                          coa_name={data?.revenue_coa ? data.revenue_coa : ""}
                          getCoaName={getRevenueCoa}
                          is_all={true}
                        /> */}
                      </Form.Item>

                      <Form.Item
                        label="Check In Time"
                        name="checkin_time"
                        style={{
                          width: "100%",
                        }}
                      >
                        <TimePicker
                          disabled={disabled}
                          // defaultValue={moment("12:08", "HH:mm")}
                          value={moment(
                            LocalizationDate(data?.checkin_time),
                            "HH:mm"
                          )}
                          format={"HH:mm"}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Check Out Time"
                        name="checkout_time"
                        style={{
                          width: "100%",
                        }}
                      >
                        <TimePicker
                          disabled={disabled}
                          // defaultValue={moment('12:08', format)}
                          value={moment(
                            LocalizationDate(data?.checkout_time),
                            "HH:mm"
                          )}
                          format={"HH:mm"}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Group Count"
                        name="group_count"
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputNumber
                          min={1}
                          disabled={disabled}
                          placeholder="Group Count"
                          addonAfter={`Person`}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Breakfast Name"
                        name="breakfast_name"
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterArticleList
                          getArticle={getArticle}
                          articleName={
                            data?.article_name ? data?.article_name : null
                          }
                          addArticleState={true}
                          is_FOparams={true}
                          isDisabled={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Breakfast Price (Per Pax)"
                        name="breakfast_price"
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputNumber
                          min={0}
                          placeholder="Price"
                          addonBefore={tokenParams?.currency_code}
                          disabled
                          formatter={(unit_price) => {
                            return ` ${
                              unit_price > 0 ? unit_price : 0
                            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          }}
                          style={{ width: 205 }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Article ID"
                        name="article_id"
                        style={{
                          width: "100%",
                        }}
                        hidden
                      >
                        <InputNumber min={0} placeholder="Price" />
                      </Form.Item>

                      <Form.Item
                        label="Show Tax & Service on Bill"
                        name="bill_type"
                        valuePropName="checked"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Checkbox disabled={disabled}>{`Show`}</Checkbox>
                      </Form.Item>

                      <Tooltip
                        title="Authorized Users to Cancel C/I (Return to Reservations)"
                        className="checkbox-tooltip"
                      >
                        <Form.Item
                          label="Authorized to Cancel Check-In"
                          name="is_cancel_ci"
                          valuePropName="checked"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Checkbox
                            disabled={disabled}
                          >{`Authorized`}</Checkbox>
                        </Form.Item>
                      </Tooltip>

                      <Tooltip
                        title="Authorized Users to Cancel C/O (Re-Check-In C/O Guest)"
                        className="checkbox-tooltip"
                      >
                        <Form.Item
                          label="Authorized to Cancel Check-Out"
                          name="is_cancel_co"
                          valuePropName="checked"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Checkbox
                            disabled={disabled}
                          >{`Authorized`}</Checkbox>
                        </Form.Item>
                      </Tooltip>

                      <Form.Item
                        label="Check-In SIGN BY"
                        name="check_in_sign_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input disabled={disabled} placeholder="By" />
                      </Form.Item>

                      <Form.Item
                        label="Check-Out SIGN BY"
                        name="check_out_sign_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input disabled={disabled} placeholder="By" />
                      </Form.Item>

                      <Form.Item
                        label="Registration SIGN BY"
                        name="registration_sign_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input disabled={disabled} placeholder="By" />
                      </Form.Item>

                      <Form.Item
                        label="Last Update On"
                        name="updated_date"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated On" />
                      </Form.Item>

                      <Form.Item
                        label="Last Update by"
                        name="updated_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated By" disabled />
                      </Form.Item>
                    </Row>

                    <Divider
                      className="divider-form"
                      style={{ margin: "15px 0px 10px" }}
                    />

                    <Row
                      justify="end"
                      align="middle"
                      // style={{ padding: "15px 0px 0px" }}
                      className="modal-btn-form"
                    >
                      <Button
                        className="edit-form-btn"
                        type="primary"
                        onClick={handleEdit}
                        icon={
                          <FiEdit
                            style={{
                              margin: "0px 5px 0px 0px",
                            }}
                          />
                        }
                        style={{
                          marginRight: 30,
                        }}
                      >
                        {`${disabled ? "Edit" : "Cancel Editing"}`}
                      </Button>

                      <Button
                        className="submit-btn"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={disabled}
                        style={{
                          // backgroundColor: "#1BC5BD",
                          // borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        {`Submit`}
                      </Button>

                      <Button
                        className="cancel-btn"
                        type="default"
                        onClick={handleCancel}
                      >
                        {`Cancel`}
                      </Button>
                    </Row>
                  </Form>
                )}
              </Col>

              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-second"
              >
                {null}
              </Col>
            </Row>
          </Col>

          <ModalAuthorized
            openModal={open}
            closeModal={setOpen}
            is_permitted={handlePermit}
            params={1}
          />
        </Content>
      </Layout>
    </>
  );
}

// Import React's Components
import React, { useContext, useState, useEffect } from "react";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Antd Components
import { Select } from "antd";

// CODE
export default function MasterAccountType(props) {
  // PROPS
  const { getAccountType, account_type } = props;

  // console.log("account type", account_type);
  // CONTEXT
  const { masterAccountType } = useContext(FinanceContext);

  // Data
  const [account, setAccount] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await masterAccountType({
      account: "account-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Account Type => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            type_id: dev?.account_type_id,
            id: dev?.id,
            value: dev?.name,
            label: dev?.name,
          }));

          console.log(option);

          // setOptionValue(option);
          setOptionValue(option);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (account_type && !cleanUp) {
      console.log("Account Type: ", account_type);
      setAccount(account_type);
    }

    return () => {
      cleanUp = true;
    };
  }, [account_type]);

  // Handle Change
  const handleChange = (val, allKey) => {
    const _value = val;
    const _allKey = allKey;

    console.log(_value, _allKey);

    setAccount(_value);
    getAccountType({
      value: _value,
      id: _allKey?.id,
      type_id: _allKey?.type_id,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setAccount(null);
  };

  // console.log("Acc ", account);

  return (
    <>
      <Select
        className="account-type-selector"
        key="account-type-selector"
        name="account-type-selector"
        value={account}
        allowClear
        showSearch
        // fieldNames={{ label: "name", code: "account_type_id", value: "name" }}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

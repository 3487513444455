// Import React Components
import React, { useState, useEffect, useContext } from "react";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// Import React's Icons
import { FiEdit } from "react-icons/fi";
import { CaretRightOutlined } from "@ant-design/icons";

// Import Master Components
import MasterPaymentCardEdit from "../../../../Reusable/Master/PaymentCard/PaymentCardEdit";
import MasterMainAccount from "../../../../Reusable/Accounting/MainAccount/MasterCoa";

// Import Functions
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";

// Import Notification
import { incomplete } from "../../../../Reusable/Notification/Notification";
import axios from "axios";
import { baseurl } from "../../../../../API/Config/Api";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../../Reusable/Notification/MasterNotif/Notification";
import { token, user_name } from "../../../../../API/Global/Payload";
import MasterPaymentCard from "../../../../Reusable/Master/PaymentCard/PaymentCard";

// MODAL
const { confirm } = Modal;

// CODE
export default function ModalARPayment(props) {
  // PROPS
  const { ar_data, openModal, closeModal, is_refresh } = props;

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [dataPayment, setDataPayment] = useState([]);
  // TOTAL
  const [totalPay, setTotalPay] = useState({
    paid: 0,
    unpaid: 0,
  });
  // Boolean State
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (openModal && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (ar_data?.length > 0 && !cleanUp) {
      console.log("AR DATA:", ar_data);
      setDataPayment(ar_data);
      handleTotal(ar_data);
    } else {
      setDataPayment([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, ar_data]);

  // Columns
  const columns = [
    {
      title: "Bill No.",
      dataIndex: "bill_alias",
      key: "bill_alias",
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Trx. Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Balance",
      dataIndex: "final_balance",
      key: "final_balance",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },
  ];

  // HANDLE TOTAL PAYMENT
  const handleTotal = (value) => {
    const _arr = value;
    let _total = 0;
    let _paid = 0;

    _arr.forEach(({ final_balance, paid_amount }) => {
      _total += final_balance;
      _paid += paid_amount;
    });

    setTotalPay({
      paid: _total,
      unpaid: _paid,
    });

    form.setFieldsValue({
      payment_amount: _total > 0 ? _total : 0,
      precentages: 100,
      commision_amount: _total > 0 ? _total : 0,
    });
  };

  // TABLE SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      //   handlePick(selectedRowKeys);
      //   setDataPayment(selectedRows);
    },
  };

  // ON FINISH
  const onFinish = (val) => {
    const _data = val;

    console.log("Payment: ", _data);

    setIsLoading(true);

    showModalConfirm(_data);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _data = val;
    console.log("Failed: ", _data?.values);

    incomplete(_data);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _data = dataPayment?.map((item) => {
      return {
        id: item.id,
        chart_of_account: value.chart_of_account,
        debit: item.amount,
        paid_amount: value.payment_amount,
        payment_percentage: value.precentages,
        date: value.payment_date,
        final_balance: null,
        total_paid: null,
        created_by: user_name,
        // final_balance: totalPay.paid - value.payment_amount,
        // total_paid: value.payment_amount,
      };
    });

    await axios
      .post(
        `${baseurl}/accounting/ar-payment`,
        {
          data: _data,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("POST A/R Payment => ", response);

        masterSuccessNotification(response, {
          method: 1,
          source: "A/R Payment",
        });

        handleClose();
        is_refresh();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("POST A/R Payment => ", error);
        masterFailedNotification(error, { method: 1, source: "A/R Payment" });
        setIsLoading(false);
      });
  };

  // GET PAYMENT
  const getMethod = (val, code, coa) => {
    const _data = val;

    console.log(val, code, coa);

    form.setFieldsValue({
      payment_method: _data,
      payment_method_coa: coa,
    });
  };

  // GET COA
  const getCoa = (val) => {
    const _data = val;

    console.log(val);

    form.setFieldsValue({
      chart_of_account: _data?.code,
      coa_name: _data?.name,
    });
  };

  // Handle Commisions
  const handleCommisions = (val) => {
    let percent = val;
    let _pay = 0;

    console.log("Percent Commisions: ", totalPay);

    if (totalPay.paid > 0) {
      _pay = (totalPay.paid * percent) / 100;
    } else {
      _pay = 0;
    }

    form.setFieldsValue({
      commision_amount: _pay > 0 ? _pay.toFixed(0) : 0,
      payment_amount: _pay > 0 ? _pay.toFixed(0) : 0,
    });
  };

  // Handle Payment Amount
  const handlePaymentAmount = (val) => {
    let percentage = 0;
    let paymentAmount = parseFloat(val);
    let commision = 0;

    if (totalPay.paid > 0) {
      percentage = (paymentAmount / totalPay.paid) * 100;
      commision = (totalPay.paid * percentage) / 100;
    }

    form.setFieldsValue({
      precentages: percentage > 0 ? percentage.toFixed(1) : 0,
      commision_amount: commision > 0 ? commision.toFixed(0) : 0,
      payment_amount: paymentAmount > 0 ? paymentAmount.toFixed(0) : 0,
    });
  };

  // Handle Close
  const handleClose = () => {
    setOpen(false);
    closeModal(false);

    setDataPayment([]);
    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    console.log("DATA SUBMIT: ", _data);

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Execute an A/R Payments with amount of ${currency} ${`${_data?.payment_amount}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  return (
    <>
      <Modal
        className="ar-pay-modal"
        key="ar-pay-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`A/R Payment`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={open}
        closable
        onCancel={handleClose}
        width={1100}
      >
        <Form
          className="ar-pay-form"
          key="ar-pay-form"
          name="ar-pay-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="table-row" justify="start">
                <Col span={24} className="table-col">
                  {`A/R List`}

                  <Table
                    className="ar-pay-table"
                    name="ar-pay-table"
                    key="ar-pay-table"
                    loading={loading}
                    bordered
                    columns={columns}
                    dataSource={dataPayment}
                    pagination={{
                      defaultPageSize: 5,
                      pageSizeOptions: [10, 20, 50, 100],
                      showSizeChanger: true,
                      showTotal: (total, range) =>
                        `Showing ${range[0]}-${range[1]} of ${total} entries`,
                    }}
                    scroll={{
                      x: true,
                    }}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "odd" : "even";
                    }}
                    rowKey={(record) =>
                      record.id ? record.id : record.department_id
                    }
                    summary={(_data) => {
                      return (
                        <>
                          <Table.Summary fixed>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={2}>
                                <b>{`Total Amount`}</b>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell
                                index={1}
                                colSpan={2}
                                align="right"
                              >
                                <Typography
                                  className="total-txt"
                                  style={{
                                    fontWeight: 500,
                                    fontSize: 18,
                                  }}
                                >
                                  {`${totalPay.paid}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Typography>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell index={0} colSpan={2}>
                                <b>{`Total Paid Amount`}</b>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell
                                index={1}
                                colSpan={2}
                                align="right"
                              >
                                <Typography
                                  className="total-txt"
                                  style={{
                                    fontWeight: 500,
                                    fontSize: 18,
                                  }}
                                >
                                  {`${totalPay.unpaid}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Typography>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </Table.Summary>
                        </>
                      );
                    }}
                  />
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`A/R Form Payment`}
              </Divider>

              <Row className="pay-row" gutter={30}>
                <Col span={12} className="pay-col">
                  <Form.Item
                    label="Payment Method"
                    name="payment_method"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Payment Method!",
                      },
                    ]}
                  >
                    {/* <MasterPaymentCardEdit
                      getPayCard={getMethod}
                      
                    /> */}
                    <MasterPaymentCard getPayCard={getMethod} />
                  </Form.Item>

                  <Form.Item
                    label="Payment Method"
                    name="payment_method_coa"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="acc-col">
                  <Form.Item
                    label="Account"
                    name="chart_of_account"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select a Chart of Account!",
                      },
                    ]}
                  >
                    <MasterMainAccount disable={false} getCoaName={getCoa} />
                  </Form.Item>

                  <Form.Item label="Payment Method" name="coa_name" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30}>
                <Col span={8} className="pay-col">
                  <Form.Item
                    label="Payment Date"
                    name="payment_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, input a Percentage!",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Pay Date"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="perc-col">
                  <Form.Item
                    label="Commision Percentage"
                    name="precentages"
                    rules={[
                      {
                        required: true,
                        message: "Please, input a Percentage!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Percent"
                      min={0}
                      max={100}
                      addonAfter={"%"}
                      onChange={handleCommisions}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="comm-col">
                  <Form.Item
                    label="Commision Amount"
                    name="commision_amount"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, input a Percentage!",
                    //   },
                    // ]}
                  >
                    <InputNumber
                      placeholder="Commisions"
                      min={0}
                      disabled
                      formatter={(amount) => {
                        return `${amount}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30} justify="end">
                <Col span={8} className="pay-col">
                  <Form.Item
                    label="Payment Amount"
                    name="payment_amount"
                    rules={[
                      {
                        required: true,
                        message: "Please, input a Payment Amount!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Payment Amount"
                      min={0}
                      max={totalPay.paid}
                      formatter={(amount) => {
                        return `${amount}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      onChange={handlePaymentAmount}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  icon={<CaretRightOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Execute`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

// BASEAPI
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";

// Import React Icons
import { FaCashRegister } from "react-icons/fa";
import { PrinterFilled } from "@ant-design/icons";

// Import Page Components
import NonStayTable from "../../../Table/MICE/Article/Table";
import MasterGuest from "../../../Reusable/Master/Guest/Guest";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CODE
export default function ModalCashierMice({
  // Modal State
  openModal,
  closeModal,
  // Data
  dataCashier,
}) {
  // USE CONTEXT
  const { getMasterArticleNonStay } = useContext(MasterContext);
  const { getArticleMice } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [cashier, setCashier] = useState(null);
  const [edit, setEdit] = useState(false);
  // Print
  const [print, setPrint] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH DATA
  const fetchData = async () => {
    await getArticleMice({
      articleMice: "mice",
      type: "article-reservation",
      reservation_id: dataCashier?.reservation_id
        ? dataCashier.reservation_id
        : "",
      // price_id: dataCashier?.price_id ? dataCashier.price_id : 0,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Cashier Non-Stay >> ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((val) => {
            if (val.is_deleted == null || val.is_deleted == false) {
              return val;
            }
          });

          setData(filtered);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error >> ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT
  // HANDLE OPEN
  useEffect(() => {
    let cleanUp = false;

    if (openModal && !cleanUp) {
      setOpen(!open);
    }

    if (dataCashier && !cleanUp) {
      console.log("Cashiers Open --> ", dataCashier);
      fetchData();
      setCashier(dataCashier);

      setFormFields(dataCashier);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataCashier]);

  // SET FORM FIELDS
  const setFormFields = (val) => {
    const _value = val;
    console.log("Data Value => ", _value);

    form.setFieldsValue({
      guest_name: _value?.guest_name,
      reservation_id: _value?.reservation_id,
      event_start: _value?.event_start
        ? moment(_value.event_start).format("DD-MM-YYYY")
        : "",
      event_end: _value?.event_end
        ? moment(_value.event_end).format("DD-MM-YYYY")
        : "",
    });
  };

  // GUEST NAME, BILL RECEICER & BILL INSTRUCTION
  const getGuestName = (value) => {
    console.log(value);

    form.setFieldsValue({
      guest_name: value,
    });
  };

  // HANDLE CLOSE
  const handleCancel = () => {
    setOpen(!open);
    closeModal(false);

    setEdit(false);
    setCashier(null);
  };

  return (
    <>
      <Modal
        title={
          <>
            <Row>
              <FaCashRegister style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>Cashier MICE</Typography>
            </Row>
          </>
        }
        forceRender={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
        bodyStyle={{
          borderRadius: 15,
        }}
        style={{ zIndex: 100 }}
      >
        <Form
          form={form}
          //   onFinish={(e) => handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          name="article-modal-form"
          className="article-modal-form"
          layout="vertical"
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="Reservation ID" name={"reservation_id"}>
                <Input disabled placeholder="Reservation ID" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Guest Name"
                name="guest_name"
                style={{ width: "100%", marginRight: 30 }}
              >
                {/* <MasterGuest getGuestName={getGuestName} /> */}
                <Input disabled placeholder="Guest's Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="event-date-row">
            <Col span={12}>
              <Form.Item label="Event Start" name={"event_start"}>
                <Input disabled placeholder="Event Start" />
                {/* <DatePicker style={{ width: 205, marginRight: 30 }} /> */}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Event End" name={"event_end"}>
                <Input disabled placeholder="Event End" />
                {/* <DatePicker style={{ width: 205, marginRight: 30 }} /> */}
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <NonStayTable
            is_loading={loading}
            article={data?.length > 0 ? data : []}
            newArticle={dataCashier}
            editArticle={setPrint}
            is_cashier={true}
            is_edited={setEdit}
          />

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              onClick={handleCancel}
              icon={<PrinterFilled />}
              style={{
                marginRight: 30,
              }}
            >
              Print
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              Posting Article
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { AppstoreFilled } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Tooltip } from "antd";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";
import { LuRefreshCcw } from "react-icons/lu";

export default function ArticleVendor(props) {
  // PROPS
  const {
    selectedArticle,
    article_name,
    is_disable,
    is_article_inventory = false,
  } = props;

  // CONTEXT
  const { getVendorListArticle } = useContext(MainContext);

  // STATE
  const [optionValue, setOptionValue] = useState([]);
  const [article, setArticle] = useState(null);

  const navigate = useNavigate();

  // FETCH ARTICLE LIST
  const fetchData = async () => {
    await getVendorListArticle({
      type: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("RESPONSE VENDOR ARTICLE ALL=> ", response);

        if (response?.data.msg != null) {
          let filterData = response.data.msg.filter((val) => {
            if (
              (val.is_deleted == false || val.is_deleted == null) &&
              val.article_type == 2
            )
              return val;
          });

          // console.log("ARTICLE VENDOR >>> ", filterData);
          const filter = filterData.map((item) => {
            return {
              key: item.id,
              label: item.article_name,
              value: item.article_name,
              id: item.id,
              gross: item.gross,
              department_id: item.department_id,
              department_name: item.department_name,
              article_name: item.article_name,
              article_number: item.article_number,
              price: item.unit_price,
              description: item.description,
              created_by: item.created_by,
              created_date: item.created_date,
              updated_by: item.updated_by,
              updated_date: item.updated_date,
              deleted_by: item.deleted_by,
              deleted_date: item.deleted_date,
              is_deleted: item.is_deleted,
              article_main_id: item.article_main_id,
              article_main_name: item.article_main_name,
              chart_of_account: item.chart_of_account,
              article_sub_id: item.article_sub_id,
              article_sub_name: item.article_sub_name,
              tax: item.tax,
              is_tax: item.is_tax,
              service: item.service,
              is_service: item.is_service,
              mess_unit_id: item.mess_unit_id,
              mess_unit: item.mess_unit,
              mess_content: item.mess_content,
              delivery_unit_id: item.delivery_unit_id,
              delivery_unit: item.delivery_unit,
              delivery_content: item.delivery_content,
              recipe_unit_id: item.recipe_unit_id,
              recipe_unit: item.recipe_unit,
              min_stock: item.min_stock,
              average_price: item.average_price,
              article_type: item.article_type,
            };
          });

          // Filter by article_type == 2
          const filterOption = filter.filter((item) => item.article_type == 2);

          setOptionValue(filterOption);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT BY ARTICLE INVENTORY
  useEffect(() => {
    let cleanUp = false;

    if (is_article_inventory === true && !cleanUp) {
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [is_article_inventory]);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (article_name && !cleanUp) {
      setArticle(article_name);
    } else {
      setArticle(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [article_name]);

  // Move to Page
  const move = () => {
    navigate("/configuration/master/article-list");
  };

  // Handle Change
  const handleChange = (e, i) => {
    const _value = e;
    const _allKey = i;

    console.log("Allkeys: ", _allKey);

    setArticle(_value);
    selectedArticle({
      article_name: _value,
      article_id: _allKey?.id,
      article_number: _allKey?.article_number,
      key: _allKey?.id,
      label: _allKey?.article_name,
      value: _allKey?.article_name,
      gross: _allKey?.gross,
      department_id: _allKey?.department_id,
      department_name: _allKey?.department_name,
      price: _allKey?.price,
      description: _allKey?.description,
      created_by: _allKey?.created_by,
      created_date: _allKey?.created_date,
      updated_by: _allKey?.updated_by,
      updated_date: _allKey?.updated_date,
      deleted_by: _allKey?.deleted_by,
      deleted_date: _allKey?.deleted_date,
      is_deleted: _allKey?.is_deleted,
      article_main_id: _allKey?.article_main_id,
      article_main_name: _allKey?.article_main_name,
      chart_of_account: _allKey?.chart_of_account,
      article_sub_id: _allKey?.article_sub_id,
      article_sub_name: _allKey?.article_sub_name,
      tax: _allKey?.tax,
      is_tax: _allKey?.is_tax,
      service: _allKey?.service,
      is_service: _allKey?.is_service,
      min_stock: _allKey?.min_stock,
      average_price: _allKey?.average_price,
      article_type: _allKey?.article_type,
      mess_unit_id: _allKey?.mess_unit_id,
      mess_unit: _allKey?.mess_unit,
      mess_content: _allKey?.mess_content,
      delivery_unit_id: _allKey?.delivery_unit_id,
      delivery_unit: _allKey?.delivery_unit,
      delivery_content: _allKey?.delivery_content,
      recipe_unit_id: _allKey?.recipe_unit_id,
      recipe_unit: _allKey?.recipe_unit,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setArticle(null);
  };

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={22} className="select-col">
          <Select
            className="article-inv"
            key={optionValue.key}
            style={{ width: "100%" }}
            value={article}
            placeholder="Article Inventory"
            showSearch
            allowClear
            disabled={is_disable}
            onChange={handleChange}
            onClear={handleClear}
            options={optionValue}
          />
        </Col>

        <Col span={2} className="select-col">
          {is_article_inventory ? (
            <Tooltip className="redirect-tooltip" title={"Refresh Data"}>
              <Button
                className="master-btn"
                icon={
                  <LuRefreshCcw
                    onClick={fetchData}
                    style={{ fontSize: 20, color: "#3699FF", marginTop: 3 }}
                  />
                }
              />
            </Tooltip>
          ) : (
            <Tooltip
              className="redirect-tooltip"
              title={"Redirect to Master Article"}
            >
              <Button
                className="master-btn"
                icon={
                  <AppstoreFilled
                    onClick={move}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                }
              />
            </Tooltip>
          )}
        </Col>
      </Row>
    </>
  );
}

// Import's React Components
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import axios from "axios";
import { baseurl } from "../../../../API/Config/Api";

import { token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import's Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

// Import's React Icons
import { PlusOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import CompanyLedgersTable from "../../../Table/Accounting/LedgerBalances/Company/Table";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import {
  failedFetch,
  failedLedgers,
  incomplete,
  successLedgers,
} from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// MODALs
const { confirm } = Modal;

// CODE
export default function AddCreditModal(props) {
  // PROPS
  const {
    // Modal State
    modalOpen,
    closeModal,
    // Data State
    dataComp,
    // Type Company
    typeComp,
    is_edit,
  } = props;

  // CONTEXT
  const { getLedger } = useContext(FinanceContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // DATA
  const [dataCompany, setDataCompany] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // State
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  // Set Selected
  const [selectedData, setSelectedData] = useState({
    total_trx: 0,
    current: 0,
  });
  const [lastKeys, setLastKeys] = useState(0);
  // Credit Status
  const [creditStatus, setCreditStatus] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    console.log("THIS IS EXECUTE");

    await getLedger({
      type: "get-company-credit",
      master_comp_id: dataComp?.id > 0 ? dataComp.id : 0,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;
          setDataTable(_res);

          let _lastId = _res.reduce((acc, init) => {
            acc = init?.id;

            if (acc > init?.id) {
              return acc;
            }
          }, 0);

          console.log("Last ID: ", _lastId);

          setLastKeys(_lastId);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);

        failedFetch(error);
        setLoading(false);
      },
    });

    await axios
      .post(`${baseurl}/accounting/get-company-current-credit-limit`, {
        master_comp_id: dataComp?.id > 0 ? dataComp.id : 0,
      })
      .then((response) => {
        console.log("Response: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];

          const _ttl = _res?.total_transaction > 0 ? _res.total_transaction : 0;
          const _trx = _res?.current_credit_limit
            ? _res.current_credit_limit
            : 0;

          setSelectedData({
            current: _ttl,
            total_trx: _trx,
          });

          form.setFieldsValue({
            total_transaction: _ttl,
            current_credit_limit: _trx,
          });
        } else {
          setSelectedData({
            current: 0,
            total_trx: 0,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (modalOpen === true && !cleanUp) {
      console.log("IS EDIT: ", is_edit);

      setOpen(true);
      setIsEdit(is_edit);
    }

    if (dataComp && !cleanUp) {
      console.log("Data Company: ", dataComp);

      fetchData();

      setDataCompany(dataComp);
      setFormFields(dataComp);
    }

    return () => {
      cleanUp = true;
    };
  }, [modalOpen]);

  // HANDLE EDIT
  const setFormFields = (value) => {
    const _record = value;

    setCreditStatus(_record?.credit_limit > 0 ? true : false);

    form.setFieldsValue({
      company_name: _record?.company_name,
      company_email: _record?.company_email,
      company_phone: _record?.company_phone,
      contact_person: _record?.contact_person,

      credit_limit: _record?.credit_limit,
      allow_ledger: _record?.allow_ledger,
      credit_account_number: _record?.credit_account_no,
    });
  };

  // Handle Selected
  const getLedgers = (value) => {
    const _selected = value;
    const _date = moment(_selected?.expired_date);

    setDataEdit(value);

    console.log("Selected: ", _selected);
    console.log("Selected: ", _date);

    form.setFieldsValue({
      credit_limit: _selected?.initial_credit_limit,
      expired_date: moment(_selected?.expired_date),
      credit_status: _selected?.is_active,
      allow_ledger: _selected?.allow_ledger,
      start_date: moment(_selected?.start_date),
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    const values = e;
    console.log("SUBMITTED ==> ", values);

    // values.birthdate = moment(values["birthdate"]).format("YYYY-MM-DD");

    // handleSubmit(values);
    // showModalConfirm(values);
    handleCredit(values);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("On Finish Failed", error?.values);

    incomplete(error);
  };

  // Handle Clear
  const handleClear = () => {
    // setCreditStatus(false);
  };

  // Handle Change
  const handleChange = (value) => {
    form.setFieldValue("credit_status", value);
  };

  // Handle Edit
  const handleEdit = () => {
    setIsUpdate(false);
  };

  // Handle Credit
  const handleCredit = (val) => {
    const fields = val; // form.getFieldsValue();

    console.log("Add/Update: ", fields);

    const _newData = {
      credit_account_number: fields?.credit_account_number,
      initial_credit_limit: fields?.credit_limit,
      start_date: fields?.start_date,
      expired_date: fields?.expired_date,
      chart_of_account: fields?.coa,
      is_active: fields?.credit_status,
      is_expired: false,
      allow_ledger: fields?.allow_ledger,
      created_by: user_name,
      key: lastKeys + 1,
    };

    setLastKeys(lastKeys + 1);
    setDataTable([...(dataTable?.length > 0 ? dataTable : []), _newData]);

    form.resetFields(["expired_date", "credit_limit", "credit_status"]);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    form.resetFields();

    setIsEdit(false);
    setIsUpdate(false);
    setDataEdit(null);

    setDataTable([]);
    setDataCompany(null);
    setIsLoading(false);

    closeModal(false);
    setOpen(false);
  };

  // Show Modal Confirm
  const showModalConfirm = () => {
    const _submit = dataTable[dataTable.length - 1];
    const _update = form.getFieldsValue();

    console.log("About to ADD: ", _submit);
    console.log("About to Update: ", _update);
    setIsLoading(true);

    confirm({
      className: "article-stay-confirm",
      title:
        isUpdate === true
          ? `Are you sure want to Update Credit Limit with Account's ID ${
              _update?.credit_account_number || "Unknown"
            } ?`
          : `Are you sure want to Add Credit Limit with Account's ID ${
              _submit?.credit_account_number || "Unknown"
            } ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isUpdate ? handleUpdate(_update) : handleCreate(_submit);
        // console.log("Guest deleted");
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Submit
  const handleUpdate = async (value) => {
    const _submit = value;

    await axios
      .put(
        `${baseurl}/accounting/company-credit`,
        {
          master_comp_id: dataCompany?.id > 0 ? dataCompany.id : 0,
          id: dataEdit?.id > 0 ? dataEdit.id : 0,
          // initial_credit_limit: _submit?.initial_credit_limit || null,
          // start_date: moment(),
          // expired_date: _submit?.expired_date,
          // chart_of_account: _submit?.chart_of_account || null,
          is_active: _submit?.credit_status ? true : false,
          // is_expired: _submit?.is_expired || null,
          allow_ledger: _submit?.allow_ledger,
          updated_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Response Update Credit: ", response);

        handleClose();

        masterSuccessNotification(response, {
          method: 1,
          source: "Company Ledgers",
        });

        fetchData();
      })
      .catch((error) => {
        console.log("Error: ", error);

        masterFailedNotification(error, {
          method: 1,
          source: "Company Ledgers",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle Creates
  const handleCreate = async (value) => {
    const _submit = value;

    await axios
      .post(
        `${baseurl}/accounting/company-credit`,
        {
          master_comp_id: dataCompany?.id > 0 ? dataCompany.id : 0,
          initial_credit_limit: _submit?.initial_credit_limit || null,
          start_date: moment(),
          expired_date: _submit?.expired_date,
          chart_of_account: _submit?.chart_of_account || null,
          is_active: _submit?.is_active ? true : false,
          is_expired: _submit?.is_expired || null,
          allow_ledger: _submit?.allow_ledger || dataCompany?.allow_ledger,
          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Response Add Credit: ", response);

        handleClose();
        masterSuccessNotification(response, {
          method: 0,
          source: "Company Ledgers",
        });
        fetchData();
      })
      .catch((error) => {
        console.log("Error: ", error);

        masterFailedNotification(error, {
          method: 0,
          source: "Company Ledgers",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        className="ledgers-modal"
        key="ledgers-modal"
        title={
          <>
            <Row>
              <FiEdit style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`${isEdit === true ? "Edit " : "Creates "} ${
                  typeComp === 2 ? `Company` : `Travel Agent`
                } Ledgers`}
              </Typography>
            </Row>
          </>
        }
        open={open}
        centered
        closable
        width={800}
        footer={null}
        onCancel={handleClose}
      >
        <Form
          className="credit-form"
          name="credit-form"
          key="credit-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            padding: "0px 36px",
          }}
        >
          <Col span={24} className="form-col">
            <Row gutter={30} className="row-comp-details">
              <Col span={12}>
                <Form.Item
                  label={typeComp === 2 ? "Company Name" : "Travel Agent Name"}
                  name={"company_name"}
                  style={{ width: "100%" }}
                >
                  <Input
                    disabled
                    placeholder={
                      typeComp === 2 ? "Company Name" : "Travel Agent Name"
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    typeComp === 2 ? "Company Email" : "Travel Agent Email"
                  }
                  name={"company_email"}
                  style={{ width: "100%" }}
                >
                  <Input
                    disabled
                    placeholder={
                      typeComp === 2 ? "Company Email" : "Travel Agent Email"
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="credit-row">
              <Col span={12}>
                <Form.Item
                  label={
                    typeComp === 2 ? "Company Phone" : "Travel Agent Phone"
                  }
                  name={"company_phone"}
                >
                  <InputNumber
                    disabled
                    minLength={10}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Credit Account Number"
                  name="credit_account_number"
                  rules={[
                    {
                      required: true,
                      message: "Please, Create a Credit Number First!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Credit Account Number"
                    disabled
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="credit-row">
              <Col span={12}>
                <Form.Item
                  label="Current Credit Trx"
                  name="current_credit_limit"
                >
                  <InputNumber
                    placeholder="Current Credit"
                    disabled
                    // min={0}
                    formatter={(credits) => {
                      return `${credits}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Total Trx" name="total_transaction">
                  <InputNumber
                    placeholder="Total Trx"
                    disabled
                    min={0}
                    formatter={(credits) => {
                      return `${credits > 0 ? credits : 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              orientation="left"
              style={{
                margin: "10px 0px",
                borderColor: "#EBEDF3",
              }}
            >
              {`Add or Update Credit Limit`}
            </Divider>

            <Row className="credit-row" gutter={30}>
              <Col span={8}>
                <Form.Item
                  label="Credit Limit"
                  name="credit_limit"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input the Amount!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <InputNumber
                    addonBefore={currency}
                    placeholder="Credit Limit"
                    min={0}
                    formatter={(price) => {
                      return `${price > 0 ? price : 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col className="expiry-date" span={8}>
                <Form.Item
                  label="Expired Date"
                  name="expired_date"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Date!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <DatePicker
                    placeholder="Expired Date"
                    allowClear
                    format={"YYYY-MM-DD"}
                    // value={}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8} className="status-col">
                <Form.Item
                  label="Status"
                  name="credit_status"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Status!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Select
                    className="status-select"
                    allowClear
                    showSearch
                    onClear={handleClear}
                    onChange={handleChange}
                    // value={creditStatus}
                    options={[
                      {
                        label: "Active",
                        value: true,
                      },
                      {
                        label: "In-Active",
                        value: false,
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="ledger-row">
              <Col className="expiry-date" span={8}>
                <Form.Item
                  label="Started Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Date!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <DatePicker
                    placeholder="Started Date"
                    allowClear
                    format={"YYYY-MM-DD"}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Ledger Allowance"
                  name="allow_ledger"
                  valuePropName={"checked"}
                >
                  <Checkbox
                    className="ledger-checkbox"
                    // disabled
                  >{`Allow Ledger`}</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row className="btn-row" justify="end">
              {isUpdate === false ? (
                <Button
                  className="add-btn"
                  key="add-article-btn"
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                  disabled={creditStatus}
                >
                  {`Add Credit Limit`}
                </Button>
              ) : (
                <>
                  <Button
                    className="submit-btn"
                    key="submit-btn"
                    type="primary"
                    onClick={showModalConfirm}
                    loading={isLoading}
                    style={{
                      margin: "0px 15px 0px 0px",
                    }}
                  >
                    {`Update`}
                  </Button>

                  <Button
                    className="refresh-btn"
                    key="refresh-btn"
                    type="default"
                    onClick={handleEdit}
                  >
                    {`Cancel`}
                  </Button>
                </>
              )}
            </Row>

            <Divider
              orientation="left"
              className="form-divider"
              style={{
                margin: "10px 0px",
                borderColor: "#EBEDF3",
              }}
            >
              {`Credit List History`}
            </Divider>

            <Row className="table-ledger-row">
              <CompanyLedgersTable
                companyData={dataTable}
                get_ledger={getLedgers}
                is_edit={setIsUpdate}
                is_loading={loading}
              />
            </Row>
          </Col>

          <Divider
            className="form-divider"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              onClick={showModalConfirm}
              key={"submit"}
              loading={isLoading}
              disabled={isUpdate}
              hidden={isUpdate}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, Card, Col, ConfigProvider, List, Row, Typography } from "antd";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import EmptyListComponents from "../../../../Reusable/Empty/EmptyList";

// CODE
export default function SubCategoryList(props) {
  // PROPS
  const { main_selected, sub_selected } = props;

  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Loading
  const [loading, setLoading] = useState(true);

  // Fetch Data
  const fetchData = async () => {
    await getArticleList({
      list: "sub-group",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        // console.log("Response Article List => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          console.log("Response Article => ", _filtered);
          setBulks(_filtered);
          // setBulks(filtered);
        } else {
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECTS FILTER
  useEffect(() => {
    let cleanUp = false;

    if (main_selected && !cleanUp) {
      setLoading(true);
      handleFilter(main_selected);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [main_selected]);

  // Handle Filter
  const handleFilter = (value) => {
    const _main_id = value?.id;

    // console.log(_main_id);

    if (bulks.length > 0) {
      let _filtered = bulks.filter((val) => {
        if (val?.article_main_id === _main_id && val.is_show_pos === true) {
          return val;
        }
      });

      // console.log("Sub-Group: ", _filtered);
      setData(_filtered);
    }

    setLoading(false);
  };

  // Handle Pick
  const handlePick = (value) => {
    const _main = value;
    // console.log("Values: ", _main);

    sub_selected(_main);
  };

  // Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
    }
  };

  return (
    <>
      <ConfigProvider
        key="provider-list"
        renderEmpty={() => {
          return <EmptyListComponents is_refresh={handleRefresh} />;
        }}
      >
        <List
          className="subgroup-list"
          key={"subgroup-list"}
          itemLayout="vertical"
          dataSource={data}
          loading={loading}
          grid={{
            gutter: 5,
            // column: 3,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 2,
            xxl: 3,
          }}
          renderItem={(item, index) => (
            <List.Item key={item?.id} className="items-list-article">
              <Card
                className="card-table"
                key={item?.id}
                hoverable
                loading={loading}
                style={{
                  background: "rgba(54, 153, 255, 0.15)",
                  borderRadius: 5,
                  height: "100%",
                }}
                bodyStyle={{
                  padding: 10,
                }}
                onClick={() => {
                    handlePick(item, index);
                  // setTableKey(item.table);
                }}
                //   onDoubleClick={() => {
                //     handleOpen();
                //   }}
              >
                <Row className="row-table" justify="start" align="middle">
                  <Col span={24} className="titletable-col">
                    <Typography
                      className="txt-h2"
                      style={{
                        fontWeight: 500,
                        fontSize: 12,
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </Col>
                </Row>
              </Card>
              {/* <Button
              type="primary"
              key={item?.id}
              onClick={() => {
                handlePick(item);
              }}
              style={{
                color: "#7E8299",
                padding: 10,
                borderRadius: 4,
                background: "rgba(54, 153, 255, 0.15)",
                borderColor: "transparent",
                // opacity: 0.15,
              }}
            >
              {item?.name}
            </Button> */}
            </List.Item>
          )}
          rowKey={(val) => {
            return val?.id;
          }}
          bordered
          style={{
            border: "1px solid #E4E6EF",
            borderRadius: 4,
            padding: 10,
          }}
        />
      </ConfigProvider>
    </>
  );
}

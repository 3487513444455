// CODE
export const MaxCapacity = (values) => {
  const _capacity = [values];
  console.log("Values Get Max Capacity: ", _capacity);

  const selectedCap = _capacity.reduce((acc, cur) => {
    Object.keys(cur).some((key) => {
      if (key.includes("room_available_")) {
        if (acc > cur[key]) acc = cur[key];
      }
    });

    // console.log("Accumulated: ", acc);

    return acc;
  }, _capacity[0]?.room_available_1);

  //   console.log("Selected CAP: ", selectedCap);

  return selectedCap;
};

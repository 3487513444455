// Import React Components
import React from "react";

// Tree Item
import { dataAktiva } from "./TreeItem";

// Import Ant Design Components
import { Col, Row, Tree } from "antd";

// Import React Icons

export default function TreeAktiva() {
  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  return (
    <>
      <Row
        className="row-aktiva"
        justify="start"
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          minHeight: 900,
          borderRadius: "0px 0px 5px 5px",
        }}
      >
        <Col
          className="col-aktiva"
          span={24}
          style={{
            // padding: 30,
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            // borderRadius: "0px 0px 5px 5px",
          }}
        >
          <Tree
            showLine
            className="aktiva-tree"
            showIcon={false}
            defaultExpandedKeys={["0-0-0", "0-1-0", "0-1-4"]}
            onSelect={onSelect}
            treeData={dataAktiva}
            style={{
              padding: 30,
              width: "100%",
              // borderRadius: "0px 0px 5px 5px",
            }}
          />
        </Col>
      </Row>
    </>
  );
}

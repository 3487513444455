// Import React Components
import React, { useState, useEffect, useContext } from "react";

// Context
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Col, Row, Typography } from "antd";

// Import Ant Design Pie Charts
import { Pie, Area } from "@ant-design/plots";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// CODE
export default function ChartAreaRevenue() {
  // CONTEXT
  const { getDashboard } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // FETCH DATA
  const fetchData = async () => {
    await getDashboard({
      type: "dashboard",
      category: "revenue-stats",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Revenue Year --> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Dashboard Rev => ", error);
        setLoading(false);
        failedFetch(error);
      },
    });

    // setTimeout(() => {
    //   setLoading(false);
    // }, 2500);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // CONFIG
  const config = {
    data,
    meta: {
      total: {
        // To Update or Change use the yField/xField Value
        alias: "Total: ",
        formatter: (total) => {
          // console.log("Value: ", total);
          const _number = parseInt(total);

          return `${_number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    // tooltip: {
    //   customContent: (title, items) => {
    //     // console.log("Title: ", title);
    //     // console.log("Items: ", items);
    //     const _number = parseInt(items[0]?.value || 0);

    //     return (
    //       <>
    //         <Col
    //           span={24}
    //           className="tooltip-col"
    //           style={{
    //             padding: 15,
    //             display: "flex",
    //             flexDirection: "column",
    //             gap: 10,
    //             borderRadius: 5,
    //           }}
    //         >
    //           <Typography className="title-txt-tooltip">{title}</Typography>

    //           <Typography
    //             className="total-number"
    //             style={{ fontSize: 15, fontWeight: 500 }}
    //           >
    //             {`Total: ${_number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //           </Typography>
    //         </Col>
    //       </>
    //     );
    //   },
    // },
    xField: "date",
    yField: "total",
    xAxis: {
      range: [0, 1],
      lineWidth: 3,
    },
    animation: true,
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: "black",
            lineWidth: 0.5,
            lineDash: [3, 4],
            strokeOpacity: 0.4,
          },
        },
      },
    },
    // lineStyle: {
    //   stroke: "#4FC9DA",
    //   lineWidth: 3,
    //   lineDash: [4, 5],
    //   strokeOpacity: 0.7,
    //   shadowColor: "black",
    //   shadowBlur: 10,
    //   shadowOffsetX: 5,
    //   shadowOffsetY: 5,
    //   cursor: "pointer",
    // },
    color: "#4FC9DA",
    areaStyle: () => {
      return {
        fill: "l(270) 0:#EDF9FB 0.5:#4FC9DA 1:#4FC9DA",
        // fillOpacity: 0.4,
        lineWidth: 3,
        // lineDash: [4, 5],
        // strokeOpacity: 0.7,
        // shadowColor: "#4FC9DA",
        // shadowBlur: 10,
        // shadowOffsetX: 5,
        // shadowOffsetY: 5,
        cursor: "pointer",
      };
    },
    slider: {
      // start: 0.,
      // end: 0.5,
      trendCfg: {
        isArea: true,
      },
    },
  };

  // console.log("Data Plot: ", data);

  return (
    <>
      <Area {...config} />
    </>
  );
}

import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { TbDirectionSignFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { failedFetch } from "../../Reusable/Notification/Notification";
import axios from "axios";
import { baseurl } from "../../../API/Config/Api";
import { id_warehouse, token, user_level } from "../../../API/Global/Payload";

// CONTEXT
import { Context as StateManagementContext } from "../../../API/Context/StateManagementContext/StateManagementContext";

export default function WidgetToDoStockIncomingWoPO() {
  // CONTEXT
  // const { addToStore, state: stateManagement } = useContext(
  //   StateManagementContext
  // );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    let _body = {};

    if (user_level == 4 || user_level == 5 || user_level == 10) {
      _body = {
        user_level: user_level,
      };
    }

    await axios
      .post(`${baseurl}/inventory/receiving-approval`, _body, {
        headers: { Authorization: `Bearer ${token || ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.length > 0) {
          const _res = response.data.filter((e) => {
            if (e.si_status == 2 || e.si_status == 4) {
              return e;
            }
          });
          console.log("RES >>>", _res);

          setData(_res);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // REDIRECT
  const handleRedirect = () => {
    const _path = "/back-office/inventory/transfer-request-approval";

    navigate(_path);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Doc No.",
      dataIndex: "document_number",
      key: "document_number",
      width: 125,
    },
    {
      title: "Receiving Status",
      dataIndex: "si_status",
      key: "si_status",
      width: 175,
      sorter: (a, b) => a.id - b.id,
      render: (amount, record) => {
        let _tags = null;

        switch (record.si_status) {
          case 1:
            _tags = <Tag color="volcano">with PO & Non Direct</Tag>;
            break;

          case 2:
            _tags = <Tag color="magenta">w/o PO & Non Direct</Tag>;
            break;

          case 3:
            _tags = <Tag color="cyan">with PO & Direct</Tag>;
            break;

          case 4:
            _tags = <Tag color="purple">w/o PO & Direct</Tag>;
            break;

          case 5:
            _tags = <Tag color="green">DML & Non Direct</Tag>;
            break;

          case 6:
            _tags = <Tag color="gold">DML & Direct</Tag>;
            break;

          default:
            break;
        }

        return _tags;
      },
    },
    {
      title: "PO No.",
      dataIndex: "po_number",
      key: "po_number",
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      key: "invoice",
    },
    {
      title: "Department",
      dataIndex: "dept_name",
      key: "dept_name",
      render: (dept) => {
        return dept || "-";
      },
    },
    {
      title: "Arrival Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (date) => {
        return date ? moment(date).format("DD-MM-YYYY") : " - ";
      },
    },
  ];

  const handleRedirectReceiving = () => {
    const _path = "/back-office/inventory/approval-receiving";

    navigate(_path);
  };

  if (data.length == 0) {
    return <></>;
  }
  return (
    <>
      <Card
        style={{ borderRadius: 12 }}
        className="todo-card"
        title={
          <>
            <Row gutter={30}>
              <Col span={24}>
                <Typography
                  style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
                >
                  {`Task List`}
                </Typography>
                <Typography
                  style={{ fontSize: 20, fontWeight: 750, color: "#FFFFFF" }}
                >
                  {`Needs Attention!`}
                </Typography>
              </Col>
            </Row>
          </>
        }
        headStyle={{
          background: `var(--volcano-gradient, linear-gradient(262deg, #610b00 -60%, #fa541c 100%))`,
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <Row
          className="row-approvals"
          style={{
            padding: 30,
            // background: `var(--red-gradient, linear-gradient(262deg, #FF005C 100%, #EE7E2D 100%))`,
          }}
        >
          <Col span={24}>
            <Row className="btn-row" gutter={[0, 15]}>
              <Col
                className="title-col"
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                  }}
                >{`Stock Incoming without Purchase Order (PO)`}</Typography>
              </Col>

              <Col
                className="modal-col"
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Row justify="end" gutter={[0, 15]}>
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={<SyncOutlined />}
                    onClick={fetchData}
                    // onClick={fetchData}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Refresh`}
                  </Button>

                  <Button
                    className="next-btn"
                    type="primary"
                    icon={
                      <TbDirectionSignFilled
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleRedirectReceiving}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Redirect`}
                  </Button>
                </Row>
              </Col>
            </Row>

            <Table
              className="purchase-req-table"
              name="purchase-req-table"
              key="purchase-req-table"
              size="small"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record?.document_number
                  ? record.document_number
                  : record.po_number;
              }}
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

import React from "react";
// Function to Breaking Down Prices from Gross
export function Grossing(props) {
  const { gross, tax, service, is_service_taxed, coefficient } = props;

  // Pricing
  var services = 0;
  var taxes = 0;
  var nett = 0;

  console.log("Gross Data Receives --> ", props);

  // Find Service
  let _ser = 1 * (service / 100);

  // Service not Taxed
  let _tax_with_ser = (1 + _ser) * (tax / 100);

  // Service is Taxed
  let _tax_without_ser = 1 * (tax / 100);

  // Coefficient Service Taxed
  let coefficient_with_serv = 1 + _ser + _tax_with_ser;
  // Coefficient Service not Taxed
  let coefficient_serv = 1 + _ser + _tax_without_ser;

  let co_ser_taxed = coefficient_with_serv.toFixed(3);
  let co_ser_wo_tax = coefficient_serv.toFixed(3);

  // console.log("Coefficient Service with taxed: ", co_ser_taxed);
  // console.log("Coefficient Service w/o taxed: ", co_ser_wo_tax);

  // Service and Tax
  if (is_service_taxed) {
    nett = gross / co_ser_taxed;
    services = nett * (service / 100);
    taxes = (nett + services) * (tax / 100);
  } else {
    nett = gross / co_ser_wo_tax;
    services = nett * (service / 100);
    taxes = nett * (tax / 100);
  }

  // Service and Tax
  services = parseFloat(services).toFixed(0);
  taxes = parseFloat(taxes).toFixed(0);
  nett = parseFloat(nett).toFixed(0);

  // CHECK PRICE MUST BE EQUAL WITH GROSS
  let check_gross = parseInt(nett) + parseInt(taxes) + parseInt(services);

  switch (check_gross > gross) {
    case true:
      break;

    case false:
      break;

    default:
      break;
  }

  console.log("Results: ", { nett, taxes, services, check_gross });

  return { nett, taxes, services };
}

// Import React's Component
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import { Button, Col, Modal, Row, Table } from "antd";

// Import React Icons Components
import { FaMoneyBillWave } from "react-icons/fa";
import { FileExcelFilled, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import ModalARPayment from "../../../../Modals/Accounting/AR/Payments/Modal";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Confirm Modal
const { confirm } = Modal;

// PROPS
export default function ARPaymentTable(props) {
  // PROPS
  const { selected, is_search, is_research } = props;

  // CONTEXT
  const { getARList } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [details, setDetails] = useState([]);
  const [dataPayment, setDataPayment] = useState({
    payment: "",
    code: 0,
    coa: "",
  });

  const [detailsIsExist, setDetailsIsExist] = useState(false);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  // Loading
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // Navigate
  const navigate = useNavigate();

  // FETCH DATA
  const fetchData = async () => {
    // if (value) {
    await getARList({
      type: "ar-payment-header",
      // coa: value ? value : dataPayment.coa,
      coa: null,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Respones Headers: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });

    // await getARList({
    //   type: "ar-payment-details",
    //   // coa: value ? value : dataPayment.coa,
    //   onAwait: () => {
    //     setDetailsIsExist(false);
    //   },
    //   onSuccess: (response) => {
    //     console.log("Respones Details: ", response);

    //     if (response?.data?.msg?.length > 0) {
    //       const _res = response.data.msg;

    //       setDetails(_res);
    //     } else {
    //       setDetails([]);
    //     }

    //     setDetailsIsExist(true);
    //   },
    //   onReject: (error) => {
    //     console.log("Error Details: ", error);
    //     setLoading(false);
    //     failedFetch(error);
    //   },
    // });
    // }
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (selected && !cleanUp) {
      console.log("Selected: ", selected);

      setDataPayment({
        payment: selected.payment,
        code: selected.code,
        coa: selected.coa,
      });
    } else {
      setDataPayment({
        payment: "",
        code: 0,
        coa: "",
      });
    }

    if (is_search === true && !cleanUp) {
      console.log("Search: ", is_search);

      // fetchData(selected.coa);
      fetchData();
      is_research(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [selected, is_search]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (data.length > 0 && detailsIsExist === true && !cleanUp) {
      console.log("Headers: ", data);
      console.log("Details: ", details);
      handleJoin(data, details);
    } else {
    }

    return () => {
      cleanUp = true;
    };
  }, [data, detailsIsExist]);

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray(selectedRowKeys);
      handlePick(selectedRowKeys);
      setDataPayment(selectedRows);
    },
  };

  // HANDLE CLEAR TABLE SELECTION
  const handleClear = () => {
    setSelectedRowArray([]);
    handlePick([]);
    setDataPayment({
      payment: "",
      code: 0,
      coa: "",
    });
  };

  // HANDLE OPEN
  const handleOpen = (val) => {
    setModalOpen(true);
  };

  // HANDLE PICK
  const handlePick = (val) => {
    const _arr = val;

    if (_arr?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    console.log("Refresh: ", dataPayment);

    fetchData();
    handleClear();
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/back-office/account-receivable";

    navigate(`${path}`);
  };

  // HANDLE JOIN
  const handleJoin = (head, detail) => {
    const _headers = head;
    const _details = detail;

    const _result = _headers.map((itm) => ({
      ..._details.find((item) => item.bill_alias === itm.bill_alias && item),
      ...itm,
    }));

    // const _result = _headers.reduce((acc, init) => {
    //   const _paid = _details.map(
    //     (value) => value.bill_alias === init.bill_alias
    //   );

    //   console.log("Paid: ", _paid);

    //   if (_paid >= 0) {
    //     acc[_paid] = { ...acc[_paid], ...init };
    //   } else {
    //     acc.push(init);
    //   }

    //   return acc;
    // }, []);

    console.log("Results: ", _result);
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, `A/R Payment`);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Guest Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Bill No.",
      dataIndex: "bill_alias",
      key: "bill_alias",
      render: (desc) => {
        return desc ? desc : "-";
      },
    },
    {
      title: "Document No.",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Trx. Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        let _date = date ? moment(date).format("DD-MM-YYYY") : "-";

        return _date;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Balance",
      dataIndex: "final_balance",
      key: "final_balance",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Status",
      dataIndex: "is_company",
      key: "is_company",
      render: (status) => {
        return status ? "Company" : "Individual";
      },
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (name) => {
        return name ? name : "-";
      },
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      render: (desc) => {
        return desc ? desc : "-";
      },
    },
  ];

  return (
    <>
      <Row className="ar-pay-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  disabled={isDisabled}
                  onClick={handleOpen}
                  icon={
                    <FaMoneyBillWave
                      className="pay-icon"
                      style={{
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 15px 10px 5px",
                    // height: 40,
                    minWidth: 75,
                    borderColor: "transparent",
                  }}
                >
                  {`Pay`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={() => selected?.coa && handleRefresh()}
                  onClick={() => handleRefresh()}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="ar-pay-table"
              name="ar-pay-table"
              key="ar-pay-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id ? record.id : record.department_id
              }
            />
          </Row>
        </Col>
      </Row>

      <ModalARPayment
        openModal={modalOpen}
        closeModal={setModalOpen}
        is_refresh={handleRefresh}
        ar_data={dataPayment}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  FileExcelFilled,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Table's Column

const dataSource = [
  {
    key: "1",
    name: "Compliments ROOMS - Guest Supplies",
    desc: "ROOMS - Guest Supplies",
    account_code: "601-01-050",
    account_name: "ROOMS - Guest Supplies",
  },
];

const { TextArea } = Input;
const { confirm } = Modal;

const onChangeSelect = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};
const onChangeTextArea = (e) => {
  console.log("Change:", e.target.value);
};

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// const onChange = (pagination, filters, sorter, extra) => {
//     console.log('params', pagination, filters, sorter, extra);
// };

// CODE
export default function ComplimentArticleTable() {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-guest-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-table-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
    {
      title: "Account Code",
      dataIndex: "account_code",
      key: "account_code",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.account_code.localeCompare(b.account_code),
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.account_name.localeCompare(b.account_name),
    },
  ];

  // CONTEXT
  const { state } = useContext(MasterContext);

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // STATE MANAGEMENT
  //const [open, setOpen] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = () => {
    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete guest named .... from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const onCreate = (values) => {
    console.log("Received values of form: ", values);
    // setOpen(false);
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleSubmit = (e) => {
    // setComplimentArticleModal(false);
    // setEditComplimentArticleModal(false);
    console.log("ON OK ==> ", e);
  };

  // useEffect(() => {
  //     fetchRecords()
  // }, [])

  // const fetchRecords = () => {
  //     setLoading(true)
  //     fetch(`http://192.168.88.103:3100/supabase/reservation`).then(res => {
  //         res.json().then(response => {
  //             setDataSource(response.msg)
  //             setLoading(false)
  //             //console.log(response)
  //         }).catch((error) => { console.log(error) });
  //     })
  // }

  return (
    <>
      <Row
        className="compliment-art-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-table" style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-exp-btn"
            >
              <Row justify="start" className="row-exp-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  // onClick={<></>}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-modal-btn"
            >
              <Row
                justify="end"
                className="row-modal-btn"
                align="top"
                gutter={[0, 15]}
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Compliment Article`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                  // onClick={fetchRecords}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              loading={loading}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={dataSource}
              //onChange={onChange}
              pagination={{
                pageSize: 5,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-compliment-article-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Compliment Article`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Compliment Article`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="compliment-article-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 10px 36px" }}
        >
          <Row gutter={30} className="row-compl-art-id">
            <Col span={8}>
              <Form.Item label="Code" name="code">
                <Input placeholder="Amount" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Amount" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item label="COA" name="coa">
              <Select
                showSearch
                placeholder="Select an Option"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "130-01-023/Inventory - Fuel, Gas, and Lubricants",
                    label: "130-01-023/Inventory - Fuel, Gas, and Lubricants",
                  },
                  {
                    value: "131-01-023/Inventory - Gas, and Lubricants",
                    label: "131-01-023/Inventory - Gas, and Lubricants",
                  },
                ]}
                style={{
                  minWidth: 440,
                }}
              />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item label="Description" name="description">
              <TextArea
                showCount
                maxLength={200}
                style={{
                  height: 100,
                  width: 440,
                }}
                onChange={onChangeTextArea}
                placeholder="Descriptions"
              />
            </Form.Item>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created_date-art-sum">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-art-sum">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="art-sum-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              Submit
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  ExclamationCircleTwoTone,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Notification
import {
  failedArticleList,
  masterIncomplete,
  successArticleList,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Master Components
import ModalArticleSales from "../../../Modals/Article/List/Modal";
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterArticleMain from "../../../Reusable/Master/ArticleMain/ArticleMain";
import MasterArticleSub from "../../../Reusable/Master/ArticleSub/ArticleSub";

// Import Functions
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { Pricing } from "../../../Reusable/Functions/Pricing/Pricing";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Confirm Modal
const { confirm } = Modal;

// CODE
export default function ArticleListTable() {
  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Data Edit
  const [editData, setEditData] = useState(null);
  // Dept, Main and Sub Group
  const [dept, setDept] = useState({
    department_name: "",
    department_id: 0,
  });
  const [mainGroup, setMainGroup] = useState({
    article_main_name: "",
    article_main_id: 0,
  });
  const [subGroup, setSubGroup] = useState({
    article_sub_name: "",
    article_sub_id: 0,
  });

  const [searchKey, setSearchKey] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getArticleList({
      list: "list",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Article List => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return filt.article_type === 1;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12} className="col-edit">
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      // console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);

                      setEditData(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-del">
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      width: 300,
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name - b.article_name,
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      // width: 175,
      // fixed: "left",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      // width: 175,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.department_name - b.department_name,
      render: (dept) => {
        return dept === "NULL" ? " - " : dept;
      },
    },
    {
      title: "Main-Group",
      dataIndex: "article_main_name",
      key: "article_main_name",
      // width: 175,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_main_name - b.article_main_name,
      render: (main) => {
        return main === "NULL" ? " - " : main;
      },
    },
    {
      title: "Sub-Group",
      dataIndex: "article_sub_name",
      key: "article_sub_name",
      // width: 175,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_sub_name - b.article_sub_name,
      render: (sub) => {
        return sub === "NULL" ? " - " : sub;
      },
    },
    {
      title: "COA",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
      render: (coa) => {
        return coa === "NULL" ? "  " : coa;
      },
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      width: 150,
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Service ( ${currency} )`,
      dataIndex: "service",
      key: "service",
      align: "right",
      width: 150,
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Tax ( ${currency} )`,
      dataIndex: "tax",
      key: "tax",
      align: "right",
      width: 150,
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Gross ( ${currency} )`,
      dataIndex: "gross",
      key: "gross",
      align: "right",
      width: 150,
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_desc.localeCompare(b.article_desc),
    },
  ];

  // Department
  const getDepartment = (value) => {
    // console.log("Department: ", value);

    setDept({
      department_name: value?.name,
      department_id: value?.dept_id,
    });

    form.setFieldsValue({
      department_name: value?.name,
      department_id: value?.dept_id,
    });

    handleFilterDept(value);
  };

  // Article Main
  const getArticleMain = (value) => {
    // console.log("Article Main: ", value);

    setMainGroup({
      article_main_name: value?.name,
      article_main_id: value?.main_id,
    });

    form.setFieldsValue({
      article_main_name: value?.name,
      article_main_id: value?.main_id,
    });

    handleFilterMain(value);
  };

  // Article Sub
  const getArticleSub = (value) => {
    // console.log(value);

    setSubGroup({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });

    form.setFieldsValue({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });

    handleFilterSub(value?.sub_id);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle Refresh
  const handleRefresh = () => {
    fetchData();
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setEditData(null);

    setDept({
      department_name: "",
      department_id: 0,
    });

    setMainGroup({
      article_main_name: "",
      article_main_id: 0,
    });

    setSubGroup({
      article_sub_name: "",
      article_sub_id: 0,
    });

    // form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const content = value;

    console.log("Submit => ", content);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to Delete Article ${
        content?.article_name.toUpperCase() || " --- "
      } from the table?`,
      // icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(content);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log("contentDelete >>>>>>", contentdelete);

    let obj = {
      ["id"]: contentdelete?.id > 0 ? contentdelete.id : 0,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/article/list`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Delete => ", response);
        successArticleList(response, { method: 2, source: 1 });

        fetchData();
      })
      .catch((error) => {
        failedArticleList(error, { method: 2, source: 1 });
        console.log("Delete => ", error);
      });
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);

    downloadExcel(data, "Article Sales");
  };

  // Handle Filter Dept
  const handleFilterDept = (value) => {
    const queryDept = value?.dept_id;

    // console.log("DEPT: ", queryDept);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("Third Conditions");
        return item.department_id === queryDept;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Filter Main Group
  const handleFilterMain = (value) => {
    const queryMain = value?.main_id;

    // console.log("MAIN LIST: ", queryMain);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("Third Conditions");
        return item.article_main_id === queryMain;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Filter Sub Group
  const handleFilterSub = (value) => {
    const querySub = value;

    console.log("SUB ARTICLE: ", querySub);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("Third Conditions");
        return item.article_sub_id === querySub;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Input
  const handleInput = (val) => {
    val.preventDefault();
    const queryKey = val?.target?.value ? val.target.value.toLowerCase() : null;

    setSearchKey(queryKey);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const _keys = searchKey ? searchKey.toLowerCase() : null;
    console.log("Search Keywords: ", searchKey);

    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        const _name = item?.article_name.toLowerCase();

        return _name.indexOf() !== -1;
      });

      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row
        className="article-main-table"
        justify="center"
        style={{
          margin: "30px 0px",
          borderRadius: 5,
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col className="search-col" span={24}>
          <Space
            align="end"
            size={15}
            className="header-space"
            wrap={true}
            style={{
              padding: 30,
            }}
          >
            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
                minWidth: 150,
                marginRight: 15,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>
                {`Search by Department`}
              </Typography>

              <MasterDepartment
                getDepartment={getDepartment}
                department_name={null}
              />
            </Col>

            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
                minWidth: 150,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>
                {`Search by Article Main Group`}
              </Typography>

              <MasterArticleMain
                getArticleMain={getArticleMain}
                getDepartmentId={dept.department_id}
                mainName={null}
                // addArticleState={true}
              />
            </Col>

            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
                minWidth: 150,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>
                {`Search by Article Sub Group`}
              </Typography>

              <MasterArticleSub
                getArticleSub={getArticleSub}
                getArticleMainId={mainGroup.article_main_id}
                getDepartmentId={dept.department_id}
                articleSubName={null}
              />
            </Col>

            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>{`Keywords`}</Typography>

              <Input
                placeholder="Article's Name"
                allowClear
                onChange={handleInput}
                style={{ minWidth: 150 }}
              />
            </Col>

            <Col
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Button
                className="search-btn"
                type="primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
              >
                {`Search`}
              </Button>
            </Col>
          </Space>
        </Col>
      </Row>

      <Row className="article-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="btn-row" justify="start" align="top" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row
                justify="end"
                gutter={[0, 15]}
                align="top"
                className="row-btn"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add New Article`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="art-list-table"
              name="art-list-table"
              key="art-list-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <ModalArticleSales
        is_open={open}
        is_close={handleCancel}
        is_refresh={handleRefresh}
        article={edit === true ? editData : null}
      />
    </>
  );
}

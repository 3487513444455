// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { sub_module } from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  AiFillAppstore,
  AiFillFolderOpen,
  AiOutlineAppstore,
} from "react-icons/ai";
import { FaConciergeBell } from "react-icons/fa";
import { MdChair } from "react-icons/md";
import {
  BsCalculatorFill,
  BsDoorOpenFill,
  BsFillPersonBadgeFill,
  BsTable,
} from "react-icons/bs";

// Import Global Styles
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../Style/Global/Global";
import { VscLibrary } from "react-icons/vsc";

// List
const listCard = [
  {
    path: "accounting",
    title: "Accounting",
    id_modules: 63,
    icon: <BsCalculatorFill style={globalIcon} />,
  },
  {
    path: "back-office",
    title: "Back Office",
    id_modules: 64,
    icon: <VscLibrary style={globalIcon} />,
  },
  {
    path: "f-and-b",
    title: "F & B",
    id_modules: 65,
    icon: <FaConciergeBell style={globalIcon} />,
  },
  {
    path: "front-office",
    title: "Front Office",
    id_modules: 66,
    icon: <MdChair style={globalIcon} />,
  },
  {
    path: "house-keeping",
    title: "House Keeping",
    id_modules: 67,
    icon: <BsDoorOpenFill style={globalIcon} />,
  },
];

// CODE
export default function ReportPage() {
  // STATE MANAGEMENT
  // List Items
  const [listItems, setlistItems] = useState([]);

  // NAVIGATE
  const navigate = useNavigate();

  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const subModule = sub_module?.length > 0 ? sub_module : [];

    const list = listCard.map((record) => {
      if (subModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      }
    });

    console.log("Filtered: ", list);

    setlistItems(list);
  };

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  // console.log("You are in config master");

  return (
    <Layout>
      <Content>
        <Col span={24} className="report-main-layout">
          <Row
            className="row-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              margin: "0px 0px 30px",
              borderRadius: 5,
            }}
          >
            <AiFillFolderOpen
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`General Report Page`}
            </Typography>
          </Row>

          <Row
            // gutter={[30, 30]}
            className="row-body"
            justify="start"
            style={mainBody}
          >
            {listItems}
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterCOA(props) {
  // PROPS
  const { getCoaName, coa_name, is_all, disable } = props;

  // CONTEXT
  const { getMainAccount } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [coa, setCoa] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMainAccount({
      mainAccount: "coa",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        let filtered = response?.data?.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            if (is_all == true) {
              return filt;
            } else {
              return filt.account_code.slice(0, 1) == 4;
            }
          }
        });

        handleMap(filtered);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (coa_name && !cleanUp) {
      setCoa(coa_name);
    } else {
      setCoa(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [coa_name]);

  // Handle Map
  const handleMap = (values) => {
    let _arr = values;

    console.log(_arr[0]);

    let option = _arr.map((dev) => ({
      value: `${dev.account_code} / ${dev.name}`,
      label: `${dev.account_code} / ${dev.name}`,
      code: dev.account_code,
      id: dev.id,
      desc: dev.name,
      normal_balance: dev?.normal_balance,
      account_type: dev?.account_type_id,
      updated: dev?.updated_date,
      updated_by: dev?.updated_by,
    }));

    // console.log("Option Mapped: ", option);

    setOptionValue(option);
  };

  // Handle Change
  const handleChange = (e, all) => {
    const _value = e;
    const _allKeys = all;

    // console.log("COA Selected = ", code);

    setCoa(_value);
    getCoaName({
      code: _allKeys?.code,
      name: _allKeys?.desc,
      normal_balance: _allKeys?.normal_balance,
      account_type: _allKeys?.account_type,
      updated: _allKeys?.updated,
      updated_by: _allKeys?.updated_by,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setCoa(null);
  };

  return (
    <>
      <Select
        key="coa-select"
        className="coa-select"
        value={coa}
        showSearch
        allowClear
        disabled={disable}
        placeholder="Chart of Accounts"
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{ width: "100%" }}
      />
    </>
  );
}

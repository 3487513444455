// Import React's Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
  Divider,
  InputNumber,
  Typography,
  Input,
  Select,
} from "antd";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

import { FiEdit } from "react-icons/fi";

// Import Notification
import {
  masterSuccessNotification,
  masterFailedNotification,
  masterIncomplete,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Modal
const { confirm } = Modal;

// CODE
export default function StockOpnameModal(props) {
  // PROPS
  const {
    is_open,
    is_close,
    is_refresh,
    is_edit,
    articleFilter,
    _dataEdit,
    soDetail,
  } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  // STATE
  const [articleValue, setArticleValue] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    console.log("Real Data Modal: ", data);
  }, [data]);

  useEffect(() => {
    console.log("Real Data Edit: ", dataEdit);
  }, [dataEdit]);

  useEffect(() => {
    let cleanUp = false;

    if (is_open === true && !cleanUp) {
      setModalOpen(true);
      handleFormField(_dataEdit);

      if (is_edit === true) {
        setDataEdit(_dataEdit);
        setArticleValue(_dataEdit?.article_name);
      } else {
        null;
        null;
      }
    } else {
      setModalOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, is_edit, articleFilter, _dataEdit]);

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;
    console.log("Handle Form: ", _record);

    form.setFieldsValue({
      article: is_edit ? value?.article_name : null,
      article_number: is_edit ? value?.article_number : null,
      stock_quantity: is_edit ? value?.actual_stock_quantity : null,
      quantity: is_edit ? value.stock_quantity : null,
      notes: value.notes,
    });
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);

    setArticleValue([]);
    setDataEdit(null);
    setModalOpen(false);
    setData([]);

    form.resetFields();
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // Get Article Inventory
  const handleChange = (value, allKeys) => {
    const _allKeys = allKeys;
    console.log("ARTICLE INVENTORY: ", _allKeys);

    setArticleValue(value);
    form.setFieldsValue({
      article: value,
      article_number: _allKeys?.article_number,
    });
  };

  // ON FINISH SUBMIT
  const onFinish = (e) => {
    console.log("DATA ONSUBMIT >> ", e);

    if (moment() > moment(soDetail?.so_start_date)) {
      showModalConfirm(e);
    } else {
      null;
    }
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (e) => {
    const _value = e;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Submit a Stocks Actual Quantity?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleFinish(_value);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Finish
  const handleFinish = async (e) => {
    let value = e;

    let allBody = {
      data: [
        {
          id: _dataEdit?.id,
          stock_quantity: value?.quantity,
          notes: value?.notes,
          created_by: user_name,
        },
      ],
    };

    handleSubmit(allBody);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("Data About to PUT/POST: ", contentSubmit);

    await axios
      .post(`${baseurl}/inventory/stock-opname/article-stock`, contentSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Response Add Article Purchase Request: ", response);

        masterSuccessNotification(response, {
          method: 0,
          source: "Stocks Actual Quantity",
        });
        handleClose();
        is_refresh(true);
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: "Stocks Actual Quantity",
        });
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <>
      <Modal
        className="purchase-req-modal"
        key="purchase-req-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Article Stock Opname Detail`}</Typography>
            </Row>
          </>
        }
        centered
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={750}
      >
        <Form
          className="purchase-req-form"
          key="purchase-req-form"
          name="purchase-req-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="article-row" gutter={30} justify="start">
                <Col span={12} className="article-col">
                  <Form.Item
                    label="Article"
                    name="article"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select an Article!",
                      },
                    ]}
                  >
                    <Row
                      className="master-row"
                      align="top"
                      justify="start"
                      gutter={0}
                    >
                      <Col span={20} className="select-col">
                        <Select
                          // key={optionValue.code}
                          style={{ width: "100%" }}
                          value={articleValue}
                          showSearch
                          allowClear
                          placeholder="Select an Article!"
                          onChange={handleChange}
                          // onClear={handleClear}
                          options={optionValue}
                          disabled={is_edit ? true : false}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>

                <Col span={12} className="info-col">
                  <Form.Item label="Article Number" name="article_number">
                    <Input disabled placeholder="Article Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="info-row" gutter={30} justify="start">
                <Col span={12} className="req-date-col">
                  <Form.Item label="Stock Quantity" name="stock_quantity">
                    <Input
                      className="stock-quantity-input"
                      placeholder="stock_quantity"
                      min={0}
                      formatter={(qty) =>
                        ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="qty-col">
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input number of Quantity!",
                      },
                    ]}
                  >
                    <InputNumber
                      className="quantity-input"
                      placeholder="Quantity"
                      min={0}
                      max={_dataEdit?.actual_stock_quantity}
                      formatter={(qty) =>
                        ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row className="notes-row" gutter={30} justify="start">
                <Col span={24} className="pay-col">
                  <Form.Item label="Notes" name="notes">
                    <Input.TextArea
                      className="notes-area"
                      placeholder="Article Actual Stock Notes"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState, useContext, useEffect } from "react";
import QueueAnim from "rc-queue-anim";
import CountUp from "react-countup";

// AUTH
import { roles, token } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Card, Col, Row, Typography } from "antd";

// Import React Icons
import { EllipsisOutlined } from "@ant-design/icons";

// Import Notification
import { failedFetch } from "../../Reusable/Notification/Notification";
import {
  rowWidgets,
  styleNumbers,
  styleSecondaryText,
  widgetDash,
} from "../../../Style/Global/Global";

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 16,
  fontWeight: 500,
};

const styleText = {
  color: "#b3b3b3",
  fontSize: 16,
  fontWeight: 500,
};

const styleTextSecondary = {
  color: "#b3b3b3",
  fontSize: 16,
  fontWeight: 500,
  padding: "15px 0px 0px",
};

// CODE
export default function AverageRoomRateCard() {
  // CONTEXT
  const { getDashRateAvg } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState({
    today: 0,
    mtd: 0,
    budget: 0,
  });
  const [today, setToday] = useState(0);
  const [monthToDate, setMonthToDate] = useState(0);
  const [budget, setBudget] = useState(0);
  // Loading
  const [loading, setLoading] = useState(false);

  // FETCH DATA
  const fetchData = async () => {
    await getDashRateAvg({
      dashRateAvg: "dashboard",
      type: "room-rate",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Dashboard Rate Avg --> ", response);

        if (response?.data?.msg) {
          const _data = response.data.msg;

          setData({
            today: _data?.today,
            mtd: _data?.mtd,
            budget: _data?.budget_mtd,
          });
        } else {
          setData({
            today: 0,
            mtd: 0,
            budget: 0,
          });
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Dashboard Rate Avg --> ", error);
        // setLoading(false);
        failedFetch(error);
      },
    });

    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // console.log(data);

  return (
    <>
      <QueueAnim type="bottom" duration={1000} delay={200}>
        <Card
          key="card-header"
          title={
            <>
              <Row
                gutter={30}
                style={{ minHeight: 22, padding: "0px 30px 50px" }}
              >
                <Col span={24}>
                  <Typography
                    style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
                  >
                    {`Average Room Rate`}
                  </Typography>
                </Col>
              </Row>
            </>
          }
          className="card-room-sold"
          headStyle={{
            // backgroundColor: "#A173FD",
            background: `var(--purple-gradient, linear-gradient(262deg, #61045F -60.55%, #AA076B 105.3%))`,
            borderRadius: "10px 10px 0px 0px",
          }}
          bodyStyle={{
            backgroundColor: "#fafafa",
            borderRadius: 12,
            backgroundImage: "url(/assets/bg/dashboard3.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          style={{
            borderRadius: 12,
            // margin: "0px 0px 1px 0px",
          }}
        >
          <Row gutter={[30, 30]} style={rowWidgets}>
            <Col span={12} style={{ height: "100%", width: "100%" }}>
              <div className="col-widget" style={widgetDash}>
                <Typography style={styleNumbers}>
                  <CountUp end={data.today} separator="," />
                </Typography>

                <Typography
                  style={styleTextSecondary}
                >{`Actual Today`}</Typography>
              </div>
            </Col>

            <Col span={12} style={{ height: "100%", width: "100%" }}>
              <div className="col-widget" style={widgetDash}>
                <Typography style={styleNumbers}>
                  <CountUp end={data.mtd} separator="," />
                </Typography>

                <Typography
                  style={styleSecondaryText}
                >{`Actual MTD`}</Typography>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              padding: "0px 30px",
              fontSize: 20,
              fontWeight: 600,
              color: "#000000",
              width: "100%",
              // margin: 0,
            }}
          >
            <Col span={12} style={{ height: "100%", width: "100%" }}>
              <div
                className="col-widget"
                style={{
                  margin: "30px 0px 0px",
                  padding: "35px 20px 20px",
                  borderRadius: 12,
                  backgroundColor: "#FFFFFF",
                  // boxShadow: "5px 5px 5px #d9d9d9",
                  width: 150,
                  height: 120,
                }}
              >
                <Typography style={styleNumbers}>
                  <CountUp end={data.budget} separator="," />
                </Typography>

                <Typography
                  style={styleSecondaryText}
                >{`Budget MTD`}</Typography>
              </div>
            </Col>

            <Col
              span={12}
              style={{
                margin: "30px 0px 0px",
                // padding: 30,
                // border: "1 solid",
                // borderRadius: 12,
                // backgroundColor: "#FFFFFF",
                // boxShadow: "1px 2px 9px #d9d9d9",
              }}
            ></Col>
          </Row>
        </Card>
      </QueueAnim>
    </>
  );
}

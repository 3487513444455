// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, user_name, token } from "../../../API/Global/Payload";
import {
  authorized_by,
  handleAuthorization,
} from "../../../API/Global/Permission";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Button,
  Divider,
  Input,
  Modal,
  InputNumber,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import { FiEdit } from "react-icons/fi";
import { FormOutlined } from "@ant-design/icons";
import { BsCalculatorFill, BsLayersFill } from "react-icons/bs";

// Import Master Components
import MasterBudget from "../../../Components/Reusable/Master/Budget/Budget";
import MasterCOA from "../../../Components/Reusable/Accounting/MainAccount/MainAccount";
import MasterCurrency from "../../../Components/Reusable/Master/Currency/Currency";
import ModalAuthorized from "../../../Components/Modals/Permissions/ModalCancel";
import InputSkeleton from "../../../Components/Reusable/Skeleton/InputSkeleton";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Functions
import { LocalizationDate } from "../../../Components/Reusable/Functions/Localizations/Localization";
import { Encoder } from "../../../API/Global/Encoder";

// Import Notification
import {
  failedParams,
  successParams,
} from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function DashboardParams() {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [disabled, setDisabled] = useState(true);
  // Loading
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // Modals
  const [open, setOpen] = useState(false);
  const [permitted, setPermitted] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    handleSuccess();
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (data && !cleanUp) {
      setFormFields(data);
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "dashboard",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Params Dashboard --> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];
          setData(_res);
        } else {
          setData(null);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Params Acc err --> ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    navigate("/configuration/parameters");
    localStorage.clear();
  };

  // SET FORMS
  const setFormFields = (value) => {
    const _data = value;

    console.log("SET FORMS: ", _data);

    form.setFieldsValue({
      ..._data,
      room_sold_budget: _data?.room_budget,
      total_occupied_budget: _data?.occ_budget,
      average_room_rate_budget: _data?.avrg_budget,
      updated_date: moment(LocalizationDate(_data?.updated_date)).format(
        "HH:mm:ss, DD-MM-YYYY"
      ),
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("ON FINISH >>> ", content);
    setIsLoading(true);
    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const submitContent = value;

    console.log(submitContent);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to update the parameters?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(submitContent);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
      },
    });
  };

  // HANDLE UPDATE
  const handleUpdate = async (value) => {
    const content = value;

    // setLoading(true);

    console.log(content);

    await axios
      .put(
        `${baseurl}/parameters/dashboard`,
        {
          id: content?.id ? content.id : data?.id || null,
          room_sold_budget: content?.room_sold_budget
            ? content.room_sold_budget
            : data?.room_sold_budget || null,
          total_occupied_budget: content?.total_occupied_budget
            ? content.total_occupied_budget
            : data?.total_occupied_budget || null,
          average_room_rate_budget: content?.average_room_rate_budget
            ? content.average_room_rate_budget
            : data?.average_room_rate_budget || null,

          updated_by: authorized_by,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Acc --> ", response);

        successParams(response, { method: 8 });

        handleSuccess(content);

        fetchData();
      })
      .catch((error) => {
        console.log("Error --> ", error);
        failedParams(error, { method: 8 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // GET BUDGET ROOM OCCUPIED
  const getBudgetRoom = (value) => {
    console.log("Value --> ", value);

    form.setFieldsValue({
      room_sold_budget: value?.budget_id,
    });
  };

  // GET Budget OCC
  const getBudgetOcc = (value) => {
    console.log("Occ: ", value);

    form.setFieldsValue({
      total_occupied_budget: value?.budget_id,
    });
  };
  // GET AVERAGE ROOM RATE
  const getBudgetAvrg = (value) => {
    console.log("Average: ", value);

    form.setFieldsValue({
      average_room_rate_budget: value?.budget_id,
    });
  };

  // HANDLE EDIT
  const handleEdit = () => {
    if (disabled) {
      if (permitted) {
        setDisabled(false);
      } else {
        setOpen(true);
      }
    } else {
      setDisabled(true);
    }
  };

  // HANDLE PERMISSION
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setDisabled(false);
        setPermitted(true);
        break;

      default:
        setDisabled(true);
        setPermitted(false);
        break;
    }
  };

  // HANDLE CANCEL
  const handleSuccess = (value) => {
    setDisabled(true);

    localStorage.clear();
    handleAuthorization("");

    // console.log("CONTENT GENERATED: ", value);
    if (value) {
      // Update Params
      const jwt = Encoder(value);
      // console.log("JWT GENERATED: ", jwt);

      sessionStorage.setItem("par-1", jwt);
    }
  };

  // CONSOLE
  // console.log(data);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <BsLayersFill
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`DASHBOARD PARAMETERS`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-first"
                style={{ padding: 30 }}
              >
                {loading == true ? (
                  <>
                    <InputSkeleton is_loading={loading} />
                  </>
                ) : (
                  <Form
                    className="parameters-form"
                    name="parameters-form"
                    key="parameters-form"
                    autoComplete="false"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{ padding: "36px 36px 0px 36px" }}
                  >
                    <Row gutter={30} className="row-header">
                      <Form.Item
                        label="Room Sold Budget"
                        name="room_sold_budget"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterBudget
                          fromParams={"LABEL_PARAMS"}
                          budget={data?.room_budget ? data.room_budget : null}
                          getBudgetData={getBudgetRoom}
                          is_disabled={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Occupancy Budget"
                        name="total_occupied_budget"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterBudget
                          fromParams={"LABEL_PARAMS"}
                          budget={data?.occ_budget ? data.occ_budget : null}
                          getBudgetData={getBudgetOcc}
                          is_disabled={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Average Rate Budget"
                        name="average_room_rate_budget"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterBudget
                          fromParams={"LABEL_PARAMS"}
                          budget={data?.avrg_budget ? data.avrg_budget : null}
                          getBudgetData={getBudgetAvrg}
                          is_disabled={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Last Updated On"
                        name="updated_date"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated On" />
                      </Form.Item>

                      <Form.Item
                        label="Last Update by"
                        name="updated_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated By" disabled />
                      </Form.Item>
                    </Row>
                    <Divider
                      className="divider-form"
                      style={{ margin: "15px 0px 10px" }}
                    />
                    <Row
                      justify="end"
                      align="middle"
                      // style={{ padding: "15px 0px 0px" }}
                      className="modal-btn-form"
                    >
                      <Button
                        className="edit-form-btn"
                        type="primary"
                        onClick={handleEdit}
                        icon={
                          <FiEdit
                            style={{
                              margin: "0px 5px 0px 0px",
                            }}
                          />
                        }
                        style={{
                          // backgroundColor: "#1BC5BD",
                          // borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        {`${disabled ? "Edit" : "Cancel Editing"}`}
                      </Button>

                      <Button
                        className="submit-btn"
                        type="primary"
                        disabled={disabled}
                        loading={isLoading}
                        htmlType="submit"
                        style={{
                          backgroundColor: "#1BC5BD",
                          borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        {`Submit`}
                      </Button>

                      <Button
                        className="cancel-btn"
                        type="default"
                        onClick={handleCancel}
                      >
                        {`Cancel`}
                      </Button>
                    </Row>
                  </Form>
                )}
              </Col>

              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-second"
              >
                {null}
              </Col>
            </Row>
          </Col>

          <ModalAuthorized
            openModal={open}
            closeModal={setOpen}
            params={1}
            is_permitted={handlePermit}
          />
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../API/Config/Api";

// Import Ant Design Components
import { Button, Col, Row, Space, Typography } from "antd";
import { Footer } from "antd/lib/layout/layout";

// Import Components
import DrawerVersions from "../Drawer/Drawer";

// Import Notifications
import { failedFetch } from "../../Components/Reusable/Notification/Notification";

// CODE
export default function FooterComponents() {
  // Date & Year
  const date = new Date(Date.now());
  const year = date.getFullYear();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [version, setVersion] = useState(null);
  // Open
  const [open, setOpen] = useState(false);

  // HANDLE OPEN
  const handleOpen = () => {
    setOpen(!open);
  };

  // Fetch Data
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/version/web-changelog`)
      .then((response) => {
        console.log("Response Version: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];
          const _data = response.data.msg;

          console.log("Results: ", _data);

          setVersion(_res?.version);
          setData(_data);
        } else {
          setVersion("1.0.0");
          setData([]);
        }
        // setLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log(err);
        failedFetch(err);
        // setLoading(false);
      });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Footer
        className="main-footer"
        style={{
          position: "sticky",
          textAlign: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Row justify="center" align="middle" className="footer-row">
          <Col className="year-col">
            <Typography
              style={{
                color: "#B5B5C3",
                fontWeight: 500,
                marginRight: "0.5rem",
              }}
            >
              {year + " "}
            </Typography>
          </Col>

          <Col className="copyright-col">
            <Space size={0} className="ver-space">
              <Typography
                style={{
                  fontSize: 13,
                  color: "#7E8299",
                  fontWeight: 400,
                }}
              >
                {`© Copyright Look System`}
              </Typography>

              <Button className="drawer-btn" type="link" onClick={handleOpen}>
                {version}
              </Button>
            </Space>
          </Col>
        </Row>
      </Footer>

      <DrawerVersions
        is_open={open}
        is_close={setOpen}
        // list_data={data}
        // version={version}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
// import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaMoneyBillWave } from "react-icons/fa";

// Import Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Functions
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// Modal Form
const { confirm } = Modal;

// CODE
export default function DetailCreateJournalTable(props) {
  // PROPS
  const { searchKey, is_search, dataDetail } = props;

  // Params
  const currency = CurrencySymbols().code;

  // Table Columns
  const columns = [
    /*
    {
      title: "Action",
      dataIndex: "action",
      width: 60,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-journal-header-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={24} className="col-details">
                <Tooltip title="See Detail" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      dataDetail(record.reservation_id);
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    */
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    /*
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
    },
    */
    {
      title: "Trx Date",
      dataIndex: "trx_date",
      key: "trx_date",
      // sortDirections: ["descend", "ascend"],
      render: (trx, record) => {
        return trx ? moment(trx).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: `Debit ( ${currency} )`,
      dataIndex: "debit",
      key: "debit",
      // sortDirections: ["descend", "ascend"],
      render: (debt) => {
        return `${debt > 0 ? debt : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Credit ( ${currency} )`,
      dataIndex: "credit",
      key: "credit",
      // sortDirections: ["descend", "ascend"],
      render: (credit) => {
        return `${credit > 0 ? credit : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "COA No.",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
      sortDirections: ["descend", "ascend"],
    },
  ];

  // CONTEXT
  const { getBillHeader, getJournalDetails } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [selectData, setSelectData] = useState(null);
  // ID
  const [editData, setEditData] = useState(null);
  const [roomCode, setRoomCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getJournalDetails({
      type: "detail-reservation",
      // bill_as: dataDetail?.bill_alias,
      journal_as: dataDetail?.journal_alias,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Bill Details Data => ", response);

        if (response.data.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });
          setData(filtered);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    let cleanUp = false;

    if (dataDetail && !cleanUp) {
      console.log("Details: ", dataDetail);

      if (dataDetail?.journal_alias !== selectData?.journal_alias) {
        setSelectData(dataDetail);
        fetchData(dataDetail);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail]);

  // Use Effect Search
  // useEffect(() => {
  //   // is_search(() => handleSearch);
  // }, [searchKey]);

  // Handle Row Selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      // setRateCode(selectedRows);
      // getRateCode(selectedRows);
      dataDetail(
        // selectedRows?.length > 0 ? selectedRows[0].bill_alias : []
        selectedRows?.length > 0 ? selectedRows[0] : []
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    form.resetFields();
    setEditData(null);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let del = record;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete room ${record.room_category_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log(value);
    let content = value;

    handleSubmit(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
    let contentSubmit = e;

    // if (edit == false) {
    //   axios
    //     .post(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       created_by: "DEVELOPER",
    //       created_date: moment().format("YYYY-MM-DD"),
    //       // updated_by: "17",
    //       // deleted_by: "NULL",
    //       // is_deleted: null,
    //       // deleted_date: null,
    //       // updated_date: null,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       successRoomCat(response, { method: 0 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       failedRoomCat(error, { method: 0 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // } else {
    //   axios
    //     .put(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       id: editData.id,
    //       created_by: editData.created_by,
    //       created_date: editData.created_date,
    //       updated_by: "DEVELOPER",
    //       updated_date: moment().format("YYYY-MM-DD"),
    //       // is_deleted: false,
    //       // created_by: "DEVELOPER",
    //       // chart_of_account: contentsubmit.chart_of_account,
    //       // departement_id: contentsubmit.departement_id,
    //       // is_transaction: "",
    //       // liquiditas: "",
    //     })
    //     .then((response) => {
    //       console.log("Add Resp => ", response);
    //       successRoomCat(response, { method: 1 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log("Error Add => ", error);
    //       failedRoomCat(error, { method: 1 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // }
  };

  // HANDLE DELETE
  const handleDelete = (record) => {
    const contentdelete = record.id;

    console.log("contentDelete >>>>>>", contentdelete);

    // var obj = {
    //   ["id"]: contentdelete,
    //   ["deleted_by"]: "DEVELOPER",
    // };

    // axios
    //   .delete(`${baseurl}/room/category`, {
    //     data: obj,
    //   })
    //   .then((response) => {
    //     console.log("Del Room Cat => ", response);
    //     successRoomCat(response, { method: 2 });
    //   })
    //   .catch((error) => {
    //     failedRoomCat(error, { method: 2 });
    //     console.log("Del Room => ", error);
    //   })
    //   .finally(() => {
    //     fetchData();
    //   });
  };

  // HANDLE SEARCH
  //   const handleSearch = () => {
  //     const query = searchKey;
  //     // console.log("Type = ", query);

  //     var updatedList = [...data];
  //     // console.log("Update List = ", updatedList);

  //     updatedList = updatedList.filter((item) => {
  //       // console.log("ITEM => ", item)
  //       return (
  //         item.room_category_name.toLowerCase().indexOf(query.toLowerCase()) !==
  //         -1
  //       );
  //     });
  //     // Trigger render with updated values
  //     setData(updatedList);
  //     // console.log("FILTERED => ", updatedList);
  //   };

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={24}>
              <Typography
                className="text-journal-header"
                style={{
                  fontSize: 15,
                  fontWeight: 750,
                }}
              >
                {`Journal Detail: ${dataDetail?.journal_alias || " --- "}`}
              </Typography>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="header-table"
              name="header-table"
              key="header-table"
              columns={columns}
              dataSource={data}
              bordered
              loading={loading}
              size="small"
              // rowSelection={{
              //   type: "radio",
              //   ...rowSelection,
              // }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React's Component
import React, { useEffect, useState, useContext } from "react";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Checkbox,
} from "antd";

// Import Context
import { Context as MasterContext } from "../../../../../API/Context/InventoryContext/InventoryContext";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CODE
export default function StockOpnameArticleTable(props) {
  // PROPS
  const { _is_close, _id_storage, _dataCheck } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [bulks, setBulks] = useState();
  const [dataCheck, setDataCheck] = useState([]);
  const [id_storage, setId_storage] = useState(null);

  // Loadings
  const [loading, setLoading] = useState(false);

  // CONST EDITABLE CELL
  const [form] = Form.useForm();

  // CONTEXT
  const { getWarehouseArticle } = useContext(MasterContext);

  // USE EFFECTS
  useEffect(() => {
    setId_storage(_id_storage);
  },[_id_storage]);

  useEffect(() => {
    console.log("data >> ", data);
  }, [data]);

  useEffect(() => {
    console.log("ID Storage Det >> ", id_storage);
  },[id_storage]);

  useEffect(() => {
    fetchData(id_storage);
  },[id_storage]);

  useEffect(() => {
    setDataCheck(data);
    setBulks(data);
    _dataCheck(data);
    console.log("BulksDatacheck >> ");
  },[_is_close]);

  useEffect(() => {
    console.log("dataCheck >> ", dataCheck);
    _dataCheck(dataCheck);
  }, [dataCheck]);

  // Fetch Data
  const fetchData = async (e, a) => {
    await getWarehouseArticle({
      source: "warehouse-article-by-id-warehouse",
      id_warehouse: e,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Storage Article => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let mapData = _res.map((item) => {
            return {
              ...item,
              id_storage: item.id_warehouse
            }
          })

          setData(mapData);
          
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-guest-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Checkbox value={record}></Checkbox>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Stock Quantity",
      dataIndex: "mess_stock",
      key: "mess_stock",
    },
  ];

  return (
    <>
      <Form form={form} component={false}>
        <Checkbox.Group
          style={{
            width: '100%',
          }}
          onChange={(checkedValues) => {
            setDataCheck(checkedValues);
            setBulks(checkedValues);
          }}
          value={bulks}
        >
          <Table
            bordered
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            dataSource={data}
            columns={columns}
            size="small"
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            scroll={{
              x: true,
            }}
            rowKey={(record) => {
              return record.id_article;
            }}
          />
        </Checkbox.Group>
      </Form>
    </>
  );
}

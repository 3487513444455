// Import React Components
import React from "react";

// Import Ant Design Components
import { Card, Col, Row, Typography } from "antd";

// Import React Icons
import { EllipsisOutlined } from "@ant-design/icons";
import PiePayment from "../../Data/Pie/PiePayment";

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 14,
  fontWeight: 500,
  padding: "0px 30px",
};
const styleText = {
  color: "#000000",
  fontSize: 20,
  fontWeight: 600,
  padding: "0px 30px",
};

// CODE
export default function PaymentCard() {
  // STATE MANAGEMENT

  return (
    <>
      <Card
        style={{
          borderRadius: 12,
          // margin: "30px 0px 30px 0px",
        }}
      >
        <Row className="row-title">
          <Col span={24}>
            <Typography style={styleText}>{`Payment Type`}</Typography>
            <Typography
              style={{
                color: "#b3b3b3",
                fontSize: 14,
                fontWeight: 500,
                padding: "0px 30px",
              }}
            >
              {`Revenue Today`}
            </Typography>
          </Col>
        </Row>

        <Row className="row-pie">
          <Col span={24}>
            <PiePayment />
          </Col>
        </Row>
      </Card>
    </>
  );
}

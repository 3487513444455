// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedDepartmentList,
  successDepartmentList,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import DeparturePopover from "../../../Popover/Departure/Popover";
// import ModalAddArticle from "../../../Modals/Article/Modal";
// import EarlyCheckOutPopover from "../../../Popover/EarlyCheckOut/Popover";

// Import Global Styles
import { cardHeader } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function EarlyCheckOutTable({
  is_search,
  searchKey,
  searchDate,
}) {
  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      fixed: "left",
      render: (_, record) => {
        return checkAvailability(record) ? (
          // return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24}>
                <Tooltip title="Check-Out Early" className="check-out-popover">
                  <DeparturePopover details={record} is_refresh={setRefresh} />
                </Tooltip>
              </Col>
            </Row>
          </>
        ) : (
          // );
          <>
            <Row className="action-reserve-table-btn" style={{ width: "100%" }}>
              <Button
                type="danger"
                // style={{ backgroundColor: "#13c2c2", borderColor: "#13c2c2" }}
                disabled={true}
              >
                Check Out
              </Button>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      fixed: "left",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      fixed: "left",
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      width: 110,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_category - b.room_category,
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 75,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Check-In Date",
      dataIndex: "checkin_date",
      key: "checkin_date",
      sortDirections: ["descend", "ascend"],
      render: (checkin, record) => {
        return moment(checkin).format("HH:mm:ss - DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.checkin_date) - moment(b.checkin_date),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      sortDirections: ["descend", "ascend"],
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.departure.localeCompare(b.departure)),
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.adult.localeCompare(b.adult),
    },
    // {
    //   title: "Child",
    //   dataIndex: "child",
    //   key: "child",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.child.localeCompare(b.child),
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "infant",
    //   key: "infant",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.infant.localeCompare(b.infant),
    // },
    {
      title: "Room Rate",
      dataIndex: "room_rate_id",
      key: "room_rate_id",
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
  ];
  // CONTEXT
  const { getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);

  // Open Modal
  const openModal = () => {
    setOpen(!open);
  };

  // console.log(open);

  // Fetch Data
  const fetchData = () => {
    setLoading(true);
    getGuestInHouse({
      guestInHouse: "guest-in-house",
      type: "stay",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);
        // let filtered = response.data.msg.filter((filt) => {
        //   if (filt.is_deleted == false || filt.is_deleted == null) {
        //     return filt;
        //   }
        // });

        setData(response.data.msg);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();

    if (refresh == true) {
      setRefresh(false);
    }
  }, [refresh]);

  // USE EFFECT
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, searchDate]);

  // CHECK COMPLETENESS
  const checkAvailability = (param) => {
    return !Object.keys(
      (({
        group_name,
        guest_category,
        arrival_flight,
        eta,
        departure_flight,
        etd,
        is_dummy,
        is_group,
        //tak tambah iki sitik kelewat ning ngisor
        letter,
        sales,
        created_at,
        nationality, ///???
        reservation_id, ///???
        memo_room,
        room_type,
        arrangement,
        deposit,
        guest_id,
        created_by,
        room_rate_id,
        res_created_by,
        res_created_date,
        is_cm,

        updated_by,
        updated_date,
        rate_code,

        is_checkin,
        checkin_at,

        is_step,
        step_date,
        step_by,

        is_checkout,
        checkout_by,
        checkout_date,

        is_deleted,
        deleted_by,
        deleted_date,

        is_nonstay,
        nonstay_by,
        nonstay_count,
        nonstay_date,

        ...o
      }) => o)(param)
    ).some((key) => {
      // console.log(
      //   "FILTERED ==> ",
      //   param[key] === "" || param[key] === null || param[key] === undefined
      // );

      return (
        param[key] === "" || param[key] === null || param[key] === undefined
      );
    });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryName = searchKey ? searchKey.toLowerCase() : null;
    const queryDate = searchDate
      ? moment(searchDate).format("YYYY-MM-DD")
      : null;

    // console.log("Type = ", query);

    var updatedList = [...data];
    // console.log("Update List = ", updatedList);

    updatedList = updatedList.filter((item) => {
      // console.log("ITEM => ", item)
      const name = item?.guest_name ? item?.guest_name.toLowerCase() : "";
      const date = item?.departure
        ? moment(item.departure).format("YYYY-MM-DD")
        : "";

      if (queryName != null && queryDate == null) {
        console.log("first condition");
        // item.guest_name.toLowerCase().indexOf(queryName.toLowerCase()) !== -1
        return name.indexOf(queryName) !== -1;
      } else if (queryName == null && queryDate != null) {
        console.log("second condition");
        return moment(date).isSame(queryDate);
      } else {
        console.log("third condition");
        return moment(date).isSame(queryDate) && name.indexOf(queryName) !== -1;
      }
    });
    // Trigger render with updated values
    setData(updatedList);
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row className="check-out-main-table" justify="center" style={cardHeader}>
        <Col span={24} style={{ padding: "30px" }}>
          <Row justify="end">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              Refresh
            </Button>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="early-checkout-table"
              key="early-checkout-table"
              name="early-checkout-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return checkAvailability(record)
                  ? //&& (record.reservation_status == "Guaranteed" ||
                    //record.reservation_status == "Not Guaranteed" )
                    "completed"
                  : "incompleted";
              }}
              scroll={{
                x: 2000,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Layout, Result, Tag, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// CODE
export default function ResultSuccess() {
  // USE NAVIGATE
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("Response --> ", state);

  // HOME MOVE
  const move = (value) => {
    console.log(value);

    navigate("/channel-manager/dashboard");
  };

  const printRes = (value) => {
    console.log(value);

    // const back = (value) => {
    // console.log(value);

    navigate("/channel-manager/reservation-payment-gateway", {
      state: {
        data: state.data,
        article: state.article,
        guest: state.guest,
      },
    });
    // };
  };

  return (
    <>
      <Layout
        className="layout-process"
        style={{
          borderRadius: 5,
        }}
      >
        <Content
          className="content-main"
          style={{
            margin: "65px 30px 65px",
            padding: 24,
            minHeight: "50vh",
            borderRadius: 5,
          }}
        >
          <Result
            className="success-result"
            status="success"
            title="Successfully Create a Reservation!"
            subTitle={
              <>
                <Tag
                  color="success"
                  style={{
                    fontSize: 25,
                    margin: 15,
                    padding: 10,
                  }}
                >
                  Your Booking has been placed!
                </Tag>

                <Typography>
                  {`Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait for a minute!`}
                </Typography>

                <Typography>
                  {`Please, open your email frequently to check the invoice and reservation details!`}
                </Typography>
              </>
            }
            extra={[
              <Button
                type="primary"
                key="console"
                className="console"
                onClick={printRes}
              >
                Back
              </Button>,
              <Button key="create-res" className="home-btn" onClick={move}>
                Create Another Reservation
              </Button>,
            ]}
          />
        </Content>
      </Layout>
    </>
  );
}

// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Table, Tag, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// REACT ICONS
import { PiFileArchiveFill } from "react-icons/pi";

// PAGE COMPONENTS
import MasterFolioForm from "../../../Forms/MasterFolio/Form";

export default function ListBillPayTable({
  articleData,
  billReceiver,
  getReturnArticleData,
  getArticleData,
  guestData,
  typeData,
  is_refresh,

  // New
  // sourceData,
  // typeData,
  // fetchToggleState,
  // fetchToggleStateReturn,
  // selectedBillData,
}) {
  // PARAMS
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  const [billData, setBillData] = useState(null);
  const [source, setSource] = useState(-1);
  const [type, setType] = useState(-1);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  // Row Selection
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  // Modal
  const [open, setOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  const location = useLocation();
  useEffect(() => {
    console.log("Location ->> ", location);
  }, []);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (articleData.length > 0 && !cleanUp) {
      setData(articleData);
    } else {
      setData([]);
      setSelectedData([]);
      setSelectedRowArray([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleData]);

  // ============== New ===================
  // BILL DATA
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (selectedBillData && !cleanUp) {
  //     setBillData(selectedBillData);
  //     fetchData();
  //   } else {
  //     setData([]);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [selectedBillData, fetchToggleState]);

  // FETCH TOGGLE
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (fetchToggleState && !cleanUp) {
  //     fetchData();
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [fetchToggleState]);

  // SOURCE DATA
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (sourceData >= 0 && typeData >= 0 && !cleanUp) {
  //     setSource(sourceData);
  //     setType(typeData);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [sourceData, typeData]);

  // FETCH DETAIL DATA
  // const fetchData = async () => {
  //   setLoading(true);

  //   let resId = [];
  //   if (selectedBillData?.length > 0) {
  //     selectedBillData?.forEach((item) => resId.push(item.reservation_id));
  //   } else {
  //     resId.push(selectedBillData?.reservation_id);
  //   }

  //   await axios
  //     .post(`${baseurl}/bill/master-folio-res`, {
  //       reservation_id: resId,
  //       source: source,
  //     })
  //     .then((response) => {
  //       console.log("response: ", response);
  //       if (response?.data?.msg) {
  //         const _res = response.data.msg;

  //         const filteredResponse = _res.filter((item) => item.is_show);

  //         handleManipulateAPIResponse(filteredResponse);
  //       } else {
  //         setData([]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       failedFetch(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // const handleManipulateAPIResponse = (array) => {
  //   const result = [];

  //   array.forEach((item) => {
  //     const key = `${item.article_id}-${item.date}`;
  //     const existing = result.find((entry) => entry.key === key);

  //     if (existing) {
  //       existing.price += item.price;
  //     } else {
  //       result.push({
  //         key: key,
  //         article_id: item.article_id,
  //         article_name: item.article_name,
  //         reservation_id: item.reservation_id,
  //         price: item.price,
  //         quantity: item.quantity,
  //         date: item.date,
  //         is_show: item.is_show,
  //       });
  //     }
  //   });

  //   const newResponse = result.map(({ key, ...rest }) => rest);

  //   setData(newResponse);
  //   // setBulks(newResponse);
  // };
  // ============== New ===================

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray(selectedRowKeys);
      setSelectedData(selectedRows);
      // handlePick(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      // Disable checkbox berdasarkan kondisi
      disabled:
        typeData === 2 || typeData === 0
          ? record.reservation_id === billReceiver?.reservation_id
          : false,
    }),
  };

  // HANDLE MOVE DATA (CheckBox Option)
  const handlePick = () => {
    let sourceData = data;

    // Filter Source Data
    const filteredData = sourceData.filter(
      (sourceItem) =>
        !selectedData.some((selectedItem) => selectedItem.id === sourceItem.id)
    );

    // Set Data on Table Bill Pay List by FilteredData
    setData(filteredData);
    getArticleData(filteredData);
    // Set Data on Table Bill List by Selected Data
    getReturnArticleData(selectedData);
    // Reset Selected Data & Selected Row Array
    setSelectedData([]);
    setSelectedRowArray([]);
  };

  // HANDLE MOVE
  const handleMove = (record) => {
    const filtered = data.filter((item) => item.id !== record.id);

    setData(filtered);
    getArticleData(filtered);
    getReturnArticleData(record);
    console.log("List Bill Pay Return Article ->> ", record);
  };

  // Table Columns
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   width: 30,
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Row
    //           className="action-master-folio-table-btn"
    //           style={{ width: "100%" }}
    //         >
    //           <Button
    //             disabled={typeData === 2 ? true : false}
    //             className="submit-btn"
    //             type="primary"
    //             style={{
    //               fontSize: "12px",
    //               padding: 5,
    //             }}
    //             onClick={() => {
    //               handleMove(record);
    //               console.log(record);
    //             }}
    //           >
    //             {`Move`}
    //             <ArrowLeftOutlined />
    //           </Button>
    //         </Row>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (qty, record) => {
        return `${qty > 0 ? qty : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    // {
    //   title: "Total Price",
    //   dataIndex: "unit_price",
    //   key: "unit_price",
    // },
  ];

  return (
    <>
      <Row
        className="master-folio-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-master-folio" style={{ padding: 30 }}>
          <Row className="row-modal-btn" justify="end">
            <Col span={8} className="col-exp-btn">
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 750,
                }}
              >
                Bill Pay List
              </Typography>
            </Col>

            <Col span={16}>
              <Row justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  disabled={selectedRowArray?.length > 0 ? false : true}
                  onClick={handlePick}
                  style={{
                    marginRight: "15px",
                    borderColor: "transparent",
                  }}
                >
                  <ArrowLeftOutlined />
                  {`Move`}
                </Button>

                <Button
                  className="submit-btn"
                  onClick={() => {
                    setOpen((state) => !state);
                  }}
                  type="primary"
                  disabled={data.length > 0 ? false : true}
                  icon={
                    <PiFileArchiveFill
                      style={{
                        // margin: "0px 5px 0px 0px",
                        fontSize: 20,
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 10px 10px 5px",
                    marginRight: "15px",
                    minWidth: 100,
                    borderColor: "transparent",
                  }}
                >
                  {`Submit Bill`}
                </Button>

                {/* <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => (selectedBillData ? fetchData() : null)}
                >
                  Refresh
                </Button> */}
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 15, width: "100%" }}>
            <Table
              className="master-folio-table"
              key="master-folio-table"
              name="master-folio-table"
              title={() => (
                <Typography.Title level={5}>
                  Bill Receiver:{" "}
                  {billReceiver?.label ? billReceiver?.label : "-"}
                </Typography.Title>
              )}
              loading={loading}
              columns={columns}
              dataSource={data}
              size="small"
              //onChange={onChange}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                // y: 270,
                x: true,
              }}
              rowKey={(record) => record.id}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <MasterFolioForm
        openModal={open}
        closeModal={setOpen}
        billData={data}
        guestData={guestData}
        billReceiver={billReceiver}
        typeData={typeData}
        is_refresh={is_refresh}
      />
    </>
  );
}

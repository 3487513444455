// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Table,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Typography,
  Tooltip,
} from "antd";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Master Components
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Master Notification
import {
  failedFetch,
  incomplete,
} from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function UOMTable(props) {
  // PROPERTIES
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getFetchMaster } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);

  const [editData, setEditData] = useState(null);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  // Load
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // Navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    await getFetchMaster({
      master: "unit-of-measurement",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("UOM: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setBulks(_res);
          setData(_res);
        } else {
          setBulks([]);
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("UOM Error: ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12} className="col-edit">
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      // console.log("ABOUT TO EDIT > ", record);
                      handleOpen();
                      setEdit(true);

                      setEditData(record);
                      handleFormFields(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-del">
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Unit Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit Code",
      dataIndex: "code",
      key: "code",
    },
    // {
    //   title: "Converted Qty",
    //   dataIndex: "quantity",
    //   key: "quantity",
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // Handle Open
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
  };

  // Handle Cancel
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setIsDelete(true);

    setEditData(null);
    setIsLoading(false);

    form.resetFields();
  };

  // Handle Form Fields
  const handleFormFields = (value) => {
    const record = value;

    form.setFieldsValue({
      name: record?.name,
      code: record?.code,
      quantity: record?.quantity,
      description: record?.description,

      created_by: record?.created_by,
      created_date: moment(record?.created_date).format("DD MMM YYYY"),
      updated_by: record?.updated_by ? record.updated_by : null,
      updated_date: record?.updated_date
        ? moment(record.updated_date).format("DD MMM YYYY")
        : null,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const _submit = value;

    console.log("ON FINISH: ", _submit);

    setIsLoading(true);
    showModalConfirm(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _submit = value;

    console.log("Failed: ", _submit?.values);

    incomplete(_submit);
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const content = value;

    // console.log("Submit => ", content);

    confirm({
      className: "modal-confirm",
      title: isDelete
        ? `Are you sure want to Delete a Unit of Measurement named ${
            content?.name.toUpperCase() || " --- "
          } from the table?`
        : `Are you sure want to ${
            edit ? "Update" : "Add a New"
          } Unit of Measurement named ${
            content?.name.toUpperCase() || " --- "
          }?`,
      // icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(content) : handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentSubmit = e;
    // console.log("ON Submit ==> ", contentSubmit);
    // console.log("EDIT DATA :?>>>> ", editData);
    if (edit == false) {
      await axios
        .post(
          `${baseurl}/master/unit-of-measurement`,
          {
            name: contentSubmit?.name || null,
            code: contentSubmit?.code || null,
            // quantity: contentSubmit?.quantity > 0 ? contentSubmit.quantity : null,
            description: contentSubmit?.description || null,

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("POST Art Inv => ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Unit of Measurements",
          });
          fetchData();
          handleCancel();
        })
        .catch((error) => {
          console.log("POST Art Inv => ", error);

          masterFailedNotification(error, {
            method: 0,
            source: "Unit of Measurements",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/unit-of-measurement`,
          {
            id: editData?.id,
            name: contentSubmit?.name || editData?.name,
            code: contentSubmit?.code || editData?.code,
            // quantity: contentSubmit?.quantity > 0 ? contentSubmit.quantity : editData?.quantity,
            description: contentSubmit?.description || null,
            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("PUT Art Inv => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Unit of Measurements",
          });
          fetchData();
          handleCancel();
        })
        .catch((error) => {
          console.log("PUT Art Inv => ", error);

          masterFailedNotification(error, {
            method: 1,
            source: "Unit of Measurements",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (value) => {
    const _delete = value;

    let obj = {
      ["id"]: _delete?.id > 0 ? _delete.id : 0,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/master/unit-of-measurement`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("PUT Art Inv => ", response);

        masterSuccessNotification(response, {
          method: 1,
          source: "Unit of Measurements",
        });
        fetchData();
        handleCancel();
      })
      .catch((error) => {
        console.log("PUT Art Inv => ", error);
        masterFailedNotification(error, {
          method: 1,
          source: "Unit of Measurements",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    downloadExcel(data, "Unit of Measurement");
  };

  // NAVIGATE
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // Handle Search
  const handleSearch = () => {
    const queryKey = searchKey ? searchKey.toLowerCase() : null;

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.name.toLowerCase();

        return _name.indexOf(queryKey) !== -1;
      });

      //   console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="unitsentory-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Units`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="units-table"
              key="units-table"
              name="units-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="unitsentory-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Unit of Measurements`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Unit of Measurements`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
        bodyStyle={{
          borderRadius: 30,
        }}
        // style={{ borderRadius: 15 }}
      >
        {/* <div style={{ padding: 24 }}> */}
        <Form
          name="unitsentory-modal-form"
          className="unitsentory-modal-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row gutter={30} className="row-article-header">
                <Col span={12} className="article-no-col">
                  <Form.Item
                    label="Unit's Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please, input an Unit's Name!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Unit's Name" allowClear />
                  </Form.Item>
                </Col>

                <Col span={12} className="article-name-col">
                  <Form.Item
                    label="Unit's Code"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Please, input Article Inventory Name!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Unit's Code" allowClear />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              {/* <Row gutter={30} className="row-stock-article">
                <Col span={12} className="stock-col">
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please, input a Number!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Quantity in Pieces"
                      min={0}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row className="row-desc-article">
                <Col span={16} className="desc-col">
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      placeholder="Descriptions"
                      className="desc-input"
                      showCount
                      maxLength={200}
                      allowClear
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              {edit == true ? (
                <>
                  <Row gutter={30} className="edit-units">
                    <Col span={8}>
                      <Form.Item
                        label="Created By"
                        name="created_by"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Created by" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Created Date"
                        name="created_date"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Created Date" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Updated By"
                        name="updated_by"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Updated by" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Updated Date"
                        name="updated_date"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Updated Date" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    className="form-divider"
                    style={{ margin: "5px 0px 10px", background: "#EBEDF3" }}
                  />
                </>
              ) : null}

              <Row justify="end" align="middle" className="row-btn-article">
                <Button
                  type="primary"
                  htmlType="submit"
                  key={"submit"}
                  loading={isLoading}
                  className="submit-btn"
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleCancel}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// Import Page Components
import MainReportPage from "../../../Pages/Parameters/Report/MainPage/MainReport";
import StatisticReportPage from "../../../Pages/Parameters/Report/Children/Statistic";
import SegmentParamsPage from "../../../Pages/Parameters/Report/Children/SegmentParam";
import HeaderParamsPage from "../../../Pages/Parameters/Report/Children/HeaderDsr";
import DetailParamsPage from "../../../Pages/Parameters/Report/Children/DetailDsr";
import ReportParams from "../../../Pages/Parameters/Report/MainPage/ReportParams";
import ReportMasterHeaderPage from "../../../Pages/Parameters/Report/Master/Header/Header";
import ReportMasterDetailPage from "../../../Pages/Parameters/Report/Master/Detail/Detail";
import MasterSegmentHeaderPage from "../../../Pages/Parameters/Report/Master/SegmentHeader/SegmentHeader";
import MonthlyReportParams from "../../../Pages/Parameters/Report/MainPage/MonthlyReportParams";
import ReportMasterBalanceSheetHeader from "../../../Pages/Parameters/Report/BalanceSheet/Header/BalanceSheetHeader";

import NotFound from "../../../Pages/NotFound/NotFound";
import UnAuthorized from "../../../Pages/Unauthorized/Unauthorized";
import ReportMasterBalanceSheetDetail from "../../../Pages/Parameters/Report/BalanceSheet/Detail/BalanceSheetDetailMaster";
import ReportBalanceSheetMain from "../../../Pages/Parameters/Report/BalanceSheet/Main/BalancesheetMain";
import ReportBalanceSheetTopPage from "../../../Pages/Parameters/Report/BalanceSheet/Top/BalancesheetTop";
import ReportPnLHeaderPage from "../../../Pages/Parameters/Report/ProfitnLoss/Header/PnLHeader";
import ReportPnLDetailPage from "../../../Pages/Parameters/Report/ProfitnLoss/Detail/PnLDetailMaster";
import PnlDetailParams from "../../../Pages/Parameters/Report/ProfitnLoss/Detail/PnlDetailParams";
import ReportBalanceSheetDetailParams from "../../../Pages/Parameters/Report/BalanceSheet/Detail/BalanceSheetDetailParams";
import ReportPnLMainPage from "../../../Pages/Parameters/Report/ProfitnLoss/Main/PnLMain";

// CODE
export default function ReportParamsRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainReportPage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/report-params" element={<ReportParams />} />

        <Route
          path="/report-params/report-master-headers"
          element={<ReportMasterHeaderPage />}
        />
        <Route
          path="/report-params/report-master-details"
          element={<ReportMasterDetailPage />}
        />
        <Route
          path="/report-params/report-master-segmentations"
          element={<MasterSegmentHeaderPage />}
        />

        <Route
          path="/report-params/statistics"
          element={<StatisticReportPage />}
        />

        <Route
          path="/report-params/segmentations"
          element={<SegmentParamsPage />}
        />

        <Route
          path="/report-params/drr-header-params"
          element={<HeaderParamsPage />}
        />

        <Route
          path="/report-params/drr-detail-params"
          element={<DetailParamsPage />}
        />

        <Route
          path="/monthly-report-params"
          element={<MonthlyReportParams />}
        />

        <Route
          path="/monthly-report-params/top-balancesheet"
          element={<ReportBalanceSheetTopPage />}
        />
        <Route
          path="/monthly-report-params/main-balancesheet"
          element={<ReportBalanceSheetMain />}
        />
        <Route
          path="/monthly-report-params/report-master-balancesheet-headers"
          element={<ReportMasterBalanceSheetHeader />}
        />
        <Route
          path="/monthly-report-params/report-master-balancesheet-details"
          element={<ReportMasterBalanceSheetDetail />}
        />
        <Route
          path="/monthly-report-params/report-master-balancesheet-details-params"
          element={<ReportBalanceSheetDetailParams />}
        />

        <Route
          path="/monthly-report-params/report-profit-and-loss-main"
          element={<ReportPnLMainPage />}
        />
        <Route
          path="/monthly-report-params/report-profit-and-loss-header"
          element={<ReportPnLHeaderPage />}
        />
        <Route
          path="/monthly-report-params/report-profit-and-loss-detail"
          element={<ReportPnLDetailPage />}
        />
        <Route
          path="/monthly-report-params/pnl-detail-params"
          element={<PnlDetailParams />}
        />

        <Route path="/not-found" element={<NotFound />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />
      </Routes>
      <Outlet />
    </>
  );
}

// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// BASE URL
import { baseurl } from "../../../../API/Config/Api";

// Import CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Table,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
  Checkbox,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  EditOutlined,
  FileAddOutlined,
  FileExcelFilled,
  FormOutlined,
  PlusOutlined,
  SyncOutlined,
  PlusCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { TbCircleCheck, TbCircleX } from "react-icons/tb";

// Import Page Components
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";
import MasterSegment from "../../../Reusable/Master/Segment/Segment";
import MasterSalesman from "../../../Reusable/Master/Salesman/Salesman";
import MasterSource from "../../../Reusable/Master/Source/Source";
import MasterRateCodeMultiple from "../../../Reusable/Master/RateCode/MasterRate";
import MasterCountry from "../../../Reusable/Master/Country/Country";
import MasterPaymentType from "../../../Reusable/Master/PaymentType/PaymentType";
import MasterIdCardType from "../../../Reusable/Master/IdCardType/IdCardType";
import ModalCompanyOTA from "../../../Modals/Company/Modal";
import MasterSexType from "../../../Reusable/Master/Sex/Sex";
import MasterTitleType from "../../../Reusable/Master/Title/Title";
import ModalEditGuest from "../../../Modals/Guest/Modal";
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import Notification
import {
  failedFetch,
  failedGuest,
  incomplete,
  incompleteGuest,
  successGuest,
} from "../../../Reusable/Notification/Notification";
import MasterCompany from "../../../Reusable/Master/Company/MasterComp";
import { FiEdit } from "react-icons/fi";
import MasterPaymentTypeEdit from "../../../Reusable/Master/PaymentType/PaymentTypeEdit";
import { MdCorporateFare } from "react-icons/md";

// Import Global Styles

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function TableCompany(props) {
  // PROPS
  const {
    is_refresh,
    // IS SEARCH
    is_search,
    searchKey,
    // IS COMPANY OR OTA
    is_company,
    is_post,
  } = props;

  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [guest, setGuest] = useState(null);
  // Segment
  const [segmentid, setSegmentid] = useState(0);
  // Data About to Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Data Type
  const [type, setType] = useState(1);
  // Modal Open
  const [open, setOpen] = useState(false);
  // State
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  // Find
  const [alert, setAlert] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const [form] = Form.useForm();

  // Use Navigate
  const navigate = useNavigate();

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              {/* <Col span={8}>
                <Tooltip title="Create Reservation" className="create-tooltip">
                  <FileAddOutlined
                    className="create-btn"
                    onClick={handleMove}
                    style={{ fontSize: 20, color: "#1BC5BD" }}
                  />
                </Tooltip>
              </Col> */}

              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      setIsDelete(false);
                      setIsEdit(true);
                      setOpen(!open);
                      setDataEdit(record);

                      setFormFields(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: type == 2 ? "Company Name" : "Travel Agent Name",
      dataIndex: "company_name",
      key: "company_name",
      //   fixed: "left",
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      key: "contact_person",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Account ID",
      dataIndex: "credit_account_no",
      key: "credit_account_no",
    },
    {
      title: "Credit Limit",
      dataIndex: "credit_limit",
      key: "credit_limit",
      render: (price, record) => {
        // console.log(record);
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Allow Ledger",
      dataIndex: "allow_ledger",
      key: "allow_ledger",
      width: 100,
      render: (bool) => {
        return bool === true ? (
          <CheckCircleFilled
            className="allowed-ledger"
            style={{
              color: "#52c41a",
              padding: "0px 0px 0px 30px",
              fontSize: 20,
            }}
          />
        ) : (
          <CloseCircleFilled
            className="disallowed-ledger"
            style={{
              color: "#f5222d",
              padding: "0px 0px 0px 25px",
              fontSize: 20,
            }}
          />
        );
      },
    },
    {
      title: "Email",
      dataIndex: "company_email",
      key: "company_email",
      width: 200,
    },
  ];

  // MODAL
  const openModal = () => {
    setOpen(!open);
  };

  // Fetch Data
  const fetchData = async (e) => {
    await getGuest({
      guest: "guest",
      type: "company-and-ota",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          // setData(filtered);
          setBulks(filtered);
          handleCompany(is_company, filtered);
        } else {
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // FETCH DATA
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (is_company > 0 && !cleanUp) {
      console.log("Corporate Type: ", is_company);

      // if (is_company !== type && bulks.length > 0) {
      //   console.log("Type: ", is_company, type);
      setType(is_company);

      handleCompany(is_company, bulks);
      // }
    }

    return () => {
      cleanUp = true;
    };
  }, [is_company]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);

    // console.log("Search Key: ", searchKey);
  }, []);

  // Handle Open
  const handleOpen = (e) => {
    setIsDelete(false);
    setOpen(true);
  };

  // HANDLE EDIT
  const setFormFields = (value) => {
    const _record = value;

    console.log("Params: ", _record);
    setSegmentid(_record?.main_segment_id);

    form.setFieldsValue({
      contact_person: _record?.contact_person,
      company_name: _record?.company_name,
      cp_number: _record?.cp_number,
      country: _record?.country,
      province: _record?.province,
      company_phone: _record?.company_phone,
      city: _record?.city,
      zip_code: _record?.zip_code,
      address: _record?.address,
      company_email: _record?.company_email,
      fax: _record?.fax,
      main_segment_name: _record?.main_segment_name,
      main_segment_code: _record?.main_segment_code,
      main_segment_id: _record?.main_segment_id,
      comment: _record?.comment,
      payment_type: _record?.payment_type,
      credit_limit: _record?.credit_limit,
      master_comp: _record?.master_comp,
      master_comp_id: _record?.master_comp_id,
      allow_ledger: _record?.allow_ledger,
      book_source: _record?.book_source,
      sales_id: _record?.sales_id,
      rate_code: _record?.rate_code,
      rate_code_id: _record?.rate_code_id,
      expired_date: moment(_record?.expired_date),
      credit_account_no: _record?.credit_account_no,

      //   is_deleted: false,
      //   is_dummy: null,
      //   company_id: null,
      //   deleted_date: null,
      //   deleted_by: null,
      //   is_ota: false,
      //   created_by: "TES created_by",
      //   created_date: "2023-10-18T11:29:12.257141",
      //   updated_by: null,
      //   updated_date: null,
    });
  };

  // HANDLE COMPANY/OTA
  const handleCompany = async (params, array) => {
    const _params = params;
    const _array = array;

    console.log("Array: ", array);

    let _filtered = [];

    if (_array?.length > 0)
      switch (_params) {
        case 2:
          _filtered = _array.filter((value) => {
            return value?.is_ota === false || value?.is_ota === null;
          });
          break;

        case 3:
          _filtered = _array.filter((value) => {
            return value?.is_ota === true;
          });
          break;

        default:
          break;
      }

    console.log("Filtered IS_OTA: ", _filtered);

    setData(_filtered);
  };

  // Handle Move
  const handleMove = (record) => {
    const _record = record;
    console.log("ABOUT TO CREATE > ", _record);

    navigate("/front-office/reservation/add-reservation", {
      state: _record,
    });
  };

  // Handle Change
  const handleChange = (e) => {
    // console.log("Type: ", e);
    setType(e);

    fetchData(e);
  };

  const handleRefresh = () => {
    fetchData();

    // handleCompany(type);
  };

  // HANDLE SELECTOR MASTER
  // Get Rate Code
  const getRateCode = (value, allKey) => {
    console.log("Room rate: ", value, allKey);

    form.setFieldsValue({
      rate_code: value,
      rate_code_id: allKey,
    });
  };
  // Get Salesman
  const getSalesman = (value, code, index) => {
    console.log("Sales ID: ", value, code, index);

    form.setFieldsValue({
      salesman: value,
      sales_id: code?.sales_id,
    });
  };
  // Get Segments
  const getSegment = (value, segmentId, code) => {
    console.log("Segment: ", value, segmentId, code);

    setSegmentid(segmentId);

    form.setFieldsValue({
      main_segment_name: value,
      main_segment_id: segmentId,
      main_segment_code: code,
    });
  };
  // Get Book Source
  const getBookSource = (value, opt) => {
    console.log("Source: ", value, opt);

    form.setFieldsValue({
      source: value,
    });
  };
  // Get Payment Type
  const getPaymentType = (value) => {
    form.setFieldsValue({
      payment_type: value?.name,
    });
  };
  // Get Payment Type
  const getPayTypeId = (value) => {
    form.setFieldsValue({
      payment_type_id: value,
    });
  };
  // Get Country and Nationality
  const getCountry = (value) => {
    // console.log(value);

    form.setFieldsValue({
      country: value,
      nationality: value,
    });
  };
  // MASTER Company
  const getMasterComp = (value) => {
    console.log("Master Comp: ", value);

    form.setFieldsValue({
      master_comp: value.value,
      master_comp_id: value.main_comp_id,
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    const values = e;
    // console.log("SUBMITTED ==> ", values);
    // values.birthdate = moment(values["birthdate"]).format("YYYY-MM-DD");

    // handleSubmit(values);
    showModalConfirm(values);
    setIsLoading(true);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("On Finish Failed", error?.values);

    incomplete(error);
  };

  // MODAL CONFIRM
  const showModalConfirm = (e) => {
    const submitContent = e;

    console.log("Modals --> ", submitContent);

    confirm({
      className: "submit-guest-confirm",
      title:
        isDelete == true
          ? `Are you sure want to Delete a ${
              type == 2 ? "Company" : "Travel Agent"
            } named ${submitContent?.company_name?.toUpperCase() || "Unknown"}?`
          : `Are you sure want to ${isEdit ? "Update" : "Add a New"} ${
              type == 2 ? "Company" : "Travel Agent"
            } named ${
              submitContent?.company_name?.toUpperCase() || "Unknown"
            }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: isDelete ? "danger" : "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(submitContent) : handleSubmit(submitContent);
        // console.log("Guest deleted");
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: isDelete ? "danger-btn" : "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Submit
  const handleSubmit = async (values) => {
    const newGuest = values;
    // console.log("ON OK ==> ", newGuest);

    switch (isEdit) {
      case false:
        handleCreate(newGuest);
        break;

      default:
        handleUpdate(newGuest);
        break;
    }
  };

  // Handle Create
  const handleCreate = async (value) => {
    const _data = value;

    console.log("About to Post: ", _data);

    await axios
      .post(
        `${baseurl}/guest/company-and-ota`,
        {
          company_name: _data?.company_name ? _data.company_name : null,
          company_email: _data?.company_email || null,
          company_phone: _data?.company_phone || null,
          country: _data?.country || null,
          province: _data?.province || null,
          city: _data?.city || null,
          zip_code: _data?.zip_code || null,
          address: _data?.address || null,
          contact_person: _data?.contact_person || null,
          cp_number: _data?.cp_number || null,
          payment_type: _data?.payment_type || null,
          master_comp: _data?.master_comp || null,
          master_comp_id: _data?.master_comp_id > 0 ? _data?.master_comp_id : 0,
          main_segment_id:
            _data?.main_segment_id?.length > 0 ? _data.main_segment_id : null,
          main_segment_code:
            _data?.main_segment_code?.length > 0
              ? _data.main_segment_code
              : null,
          main_segment_name:
            _data?.main_segment_name?.length > 0
              ? _data.main_segment_name
              : null,
          rate_code_id:
            _data?.rate_code_id?.length > 0 ? _data.rate_code_id : 0,
          rate_code: _data?.rate_code?.length > 0 ? _data.rate_code : 0,
          sales_id: _data?.sales_id > 0 ? _data.sales_id : 0,
          credit_limit: _data?.credit_limit > 0 ? _data.credit_limit : 0,
          credit_account_no: _data?.credit_account_no || null,
          fax: _data?.fax ? _data.fax : 0,
          comment: _data?.comment || "",
          expired_date: moment(_data?.expired_date).format("YYYY-MM-DD") || "",
          source: _data?.source || null,
          is_dummy: true,
          allow_ledger: false,

          is_ota: type === 2 ? false : true,
          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Add Company/OTA --> ", response);

        // if (response?.data?.msg == null) {
        //   failedGuest(response, { method: 3 });
        // } else {
        successGuest(response, { method: 0, main_type: type });

        setRefresh(true);
        handleCancel();
        handleRefresh();
        // }
      })
      .catch((error) => {
        console.log(error);
        failedGuest(error, { method: 0, main_type: type });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle Update
  const handleUpdate = async (value) => {
    const _data = value;

    console.log("About to PUT: ", _data);

    await axios
      .put(
        `${baseurl}/guest/company-and-ota`,
        {
          id: dataEdit?.id,
          company_name: _data?.company_name
            ? _data.company_name
            : dataEdit?.company_name,
          company_email: _data?.company_email
            ? _data.company_email
            : dataEdit?.company_email,
          company_phone: _data?.company_phone
            ? _data?.company_phone
            : dataEdit?.company_phone,
          country: _data?.country || dataEdit?.country,
          province: _data?.province || dataEdit?.province,
          city: _data?.city || dataEdit?.city,
          zip_code: _data?.zip_code || dataEdit?.zip_code,
          address: _data?.address || dataEdit?.address,
          contact_person: _data?.contact_person || dataEdit?.contact_person,
          cp_number: _data?.cp_number || dataEdit?.cp_number,
          payment_type: _data?.payment_type || dataEdit?.payment_type,
          master_comp: _data?.master_comp || dataEdit?.master_comp,
          master_comp_id:
            _data?.master_comp_id > 0
              ? _data?.master_comp_id
              : dataEdit?.master_comp_id,
          main_segment_id:
            _data?.main_segment_id?.length > 0
              ? _data.main_segment_id
              : dataEdit?.main_segment_id,
          main_segment_code:
            _data?.main_segment_code?.length > 0
              ? _data.main_segment_code
              : dataEdit?.main_segment_code,
          main_segment_name:
            _data?.main_segment_name?.length > 0
              ? _data.main_segment_name
              : dataEdit?.main_segment_name,
          rate_code_id:
            _data?.rate_code_id?.length > 0
              ? _data.rate_code_id
              : dataEdit?.rate_code_id,
          rate_code:
            _data?.rate_code?.length > 0
              ? _data.rate_code
              : dataEdit?.rate_code,
          sales_id: _data?.sales_id > 0 ? _data.sales_id : dataEdit?.sales_id,
          credit_limit:
            _data?.credit_limit > 0
              ? _data.credit_limit
              : dataEdit?.credit_limit,
          credit_account_no:
            _data?.credit_account_no || dataEdit?.credit_account_no,
          fax: _data?.fax ? _data.fax : dataEdit?.fax,
          comment: _data?.comment || dataEdit?.comment,
          expired_date:
            moment(_data?.expired_date).format("YYYY-MM-DD") ||
            moment(dataEdit?.expired_date).format("YYYY-MM-DD"),
          source: _data?.source || null,
          is_dummy: dataEdit?.is_dummy || false,
          allow_ledger: dataEdit?.allow_ledger,

          is_ota: dataEdit?.is_ota,
          updated_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Update Company/OTA --> ", response);

        // if (response?.data?.msg == null) {
        //   failedGuest(response, { method: 3 });
        // } else {
        successGuest(response, { method: 1, main_type: type });

        setRefresh(true);
        handleCancel();
        handleRefresh();
        // }
      })
      .catch((error) => {
        console.log(error);
        failedGuest(error, { method: 1, main_type: type });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // CLOSE ADD GUEST MODAL
  const handleCancel = () => {
    form.resetFields();

    setIsDelete(true);
    setIsEdit(false);
    setDataEdit(null);

    setOpen(false);
    setShow(false);
    setAlert(false);
    setMessage("");
    setGuest(null);
    setSegmentid(0);
  };

  // HANDLE FIND
  const handleFind = async (e) => {
    // console.log("Handle Find --> ", e);

    setShow(false);
    setAlert(false);

    if (e?.id_card_type && e?.id_card_number) {
      const findData = data.filter((filt) => {
        if (
          filt.id_card_type == e?.id_card_type &&
          filt.id_card_number == e?.id_card_number
        ) {
          return filt;
        }
      });

      setGuest(findData[0]);

      // console.log("Guest --> ", findData);

      if (findData?.length == 0) {
        setShow(true);
        // console.log("noSame");
      } else {
        setAlert(true);
        setMessage(2);
      }
    } else {
      setAlert(true);
      setMessage(1);
    }
  };

  // Handle Delete
  const handleDelete = async (record) => {
    // console.log(record);
    const content = record;

    let obj = {
      id: content?.id,
      deleted_by: user_name,
    };

    await axios
      .delete(`${baseurl}/guest/company-and-ota`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        successGuest(response, { method: 2, main_type: type });
      })
      .catch((error) => {
        failedGuest(error, { method: 2, main_type: type });
        console.log(error);
      });
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);
    let _title = "";

    switch (type) {
      case 2:
        _title = "Company List";
        break;

      case 3:
        _title = "OTA List";
        break;

      default:
        break;
    }

    downloadExcel(data, _title);
  };

  // HANDLE SEARCH
  const handleSearch = (_value) => {
    let query = searchKey ? searchKey.toLowerCase() : null;
    // _value ? _value.toLowerCase() : null;

    // if (_value) {
    // } else {
    // }
    // console.log("Search Key = ", query);

    // let updatedList = bulks;
    // console.log("Update List = ", updatedList);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.company_name.toLowerCase();
        // console.log("ITEM => ", item);
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
    // dataTable(updatedList);
  };

  // CONSOLE LOG
  // console.log("DATA EDIT > ", dataEdit);

  return (
    <>
      <Row
        className="guest-card-main-table"
        justify="center"
        gutter={[0, 15]}
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100%",
          padding: 30,
          borderRadius: 5,
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="col-export"
        >
          <Row justify="start" className="row-export">
            <Button
              className="export-btn"
              type="primary"
              onClick={handleExport}
              icon={<FileExcelFilled />}
              style={{
                fontWeight: 500,
                margin: "0px 30px 0px 0px",
              }}
            >
              {`Export to Excel`}
            </Button>

            {/* <Select
              defaultValue="Individual"
              style={{
                width: "100%",
              }}
              onChange={handleChange}
              options={[
                {
                  value: 1,
                  label: "Individual",
                },
                {
                  value: 2,
                  label: "Company",
                },
                {
                  value: 3,
                  label: "OTA",
                },
              ]}
            /> */}
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="col-table-btn"
        >
          <Row justify="end" className="row-table-btn" gutter={[0, 15]}>
            <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                // marginRight: 15,
                fontWeight: 500,
              }}
            >
              {type == 2 ? `Add New Company` : `Add New OTA`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={handleRefresh}
              style={{
                fontWeight: 500,
                margin: "0px 0px 0px 15px",
              }}
            >
              {`Refresh`}
            </Button>
          </Row>
        </Col>

        <Row className="row-table" style={{ marginTop: 35, width: "100%" }}>
          <Col span={24} style={{ marginTop: 10 }}>
            <Table
              className="company-ota-table"
              key="company-ota-table"
              bordered
              loading={loading}
              dataSource={data}
              columns={columns}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1750,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Col>
        </Row>
      </Row>

      <Modal
        className="add-modal-guest"
        title={
          <>
            <Row>
              <MdCorporateFare style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`${isEdit === true ? "Edit" : "Add New"} ${
                  type === 2 ? `Company` : `Travel Agent`
                }`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          name="guest-modal-form"
          className="guest-modal-form"
          layout="vertical"
          //   initialValues={{
          //     id_card_type: "KTP",
          //     country: "Indonesia",
          //     nationality: "Indonesia",
          //     sex: "Male",
          //     title: "Mr.",
          //     city: "",
          //     local_region: "",
          //     province: "",
          //     payment_type: "",
          //   }}
          style={{ padding: "0px 36px" }}
        >
          {/* <Row gutter={30} className="row-id-card-type">
            <Col span={8}>
              <Form.Item
                label="ID Card Type"
                name={"id_card_type"}
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Identity Card Type!",
                  },
                ]}
                style={{ width: "100%", marginRight: 30 }}
              >
                <MasterIdCardType getIdCardType={getIdCardType} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="ID Card No"
                name={"id_card_num"}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Guest's Identity Number!",
                  },
                ]}
                style={{ width: "100%", marginRight: 30 }}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* <Col span={8}>
              <Row className="btn-row" justify="space-between">
                <Form.Item>
                  <Button
                    className="find-data"
                    type="primary"
                    onClick={() => {
                      handleFind({
                        id_card_type: form.getFieldValue("id_card_type"),
                        id_card_number: form.getFieldValue("id_card_num"),
                      });
                    }}
                    style={{ marginTop: 30 }}
                  >
                    Find Data
                  </Button>
                </Form.Item>

                {show == false ? (
                  <Tooltip className="icon-tooltip" title="ERROR!">
                    <TbCircleX
                      className="safe-icon"
                      style={{
                        margin: "10px 0px 0px",
                        fontSize: 50,
                        color: "#F64E60",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip className="icon-tooltip" title="SAFE!">
                    <TbCircleCheck
                      className="safe-icon"
                      style={{
                        margin: "10px 0px 0px",
                        fontSize: 50,
                        color: "#0BB7AF",
                      }}
                    />
                  </Tooltip>
                )}
              </Row>
            </Col> */}
          {/* </Row> */}

          {/* {alert == true ? ( 
          <Row
            className="row-alert"
            style={{
              padding: "15px 0px",
            }}
          >
            <Col span={24}>
              <Alert
                message={
                  message == 1
                    ? "You Haven't Select ID Card Type/ID Card Number!"
                    : "ID Card Number is Already Taken!"
                }
                description={
                  message == 1
                    ? "Please, Choose an ID Card Type and Input the ID Card Number to Proceed!"
                    : "Same ID Card Number has been detected! You can use the Guest's Identity to Create Reservations!"
                }
                type="error"
                showIcon
                style={{
                  textAlign: "justify",
                  margin: "0px 0px 15px",
                }}
              />

              {message == 2 ? (
                <Alert
                  message={`The ID is belonged to a Guest named:`}
                  description={
                    <Typography
                      className="text-info"
                      style={{
                        fontWeight: 750,
                        fontSize: 20,
                      }}
                    >
                      {`${
                        guest?.full_name ? guest.full_name.toUpperCase() : ""
                      } 
                          `}
                    </Typography>
                  }
                  type="info"
                  showIcon
                  // style={{
                  // fontWeight: 750,
                  // }}
                />
              ) : null}
            </Col>
          </Row>
           ) : null} */}

          {/* {show == true ? ( */}
          <>
            <Row gutter={30} className="row-comp-details">
              <Col span={8}>
                <Form.Item
                  label={type === 2 ? "Company Name" : "Travel Agent Name"}
                  name={"company_name"}
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Company's/Travel Agent Name!",
                    },
                  ]}
                  style={{ width: "100%", marginRight: 30 }}
                >
                  <Input
                    placeholder={
                      type === 2 ? "Company Name" : "Travel Agent Name"
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={type === 2 ? "Company Email" : "Travel Agent Email"}
                  name={"company_email"}
                  rules={[
                    {
                      required: true,
                      message: "Please, Input the Email!",
                      type: "email",
                    },
                  ]}
                  style={{ width: "100%", marginRight: 30 }}
                >
                  <Input
                    placeholder={
                      type === 2 ? "Company Email" : "Travel Agent Email"
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={type === 2 ? "Company Phone" : "Travel Agent Phone"}
                  name={"company_phone"}
                  rules={[
                    {
                      required: true,
                      message: "Please, Input the Phone Numbers!",
                    },
                  ]}
                >
                  <InputNumber minLength={10} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-cp-details">
              <Col span={8}>
                <Form.Item
                  label="Contact Persons"
                  name="contact_person"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Contact Persons!",
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input placeholder={"Contact Persons"} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="CP Phone"
                  name="cp_number"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Phone Number!",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Phone Number"
                    minLength={10}
                    style={{ marginRight: 30, width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please, Choose Company/Travel Agent's Country!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <MasterCountry
                    getCountry={getCountry}
                    country={isEdit == true ? dataEdit?.country : null}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-comp-address">
              <Col span={8}>
                <Form.Item
                  label="Province"
                  name="province"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input placeholder="Provinsi" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="City"
                  name="city"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input placeholder="City" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Zip Code"
                  name="zip_code"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <InputNumber
                    placeholder="Zip Code"
                    min={0}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-guest-address">
              <Col span={8}>
                <Form.Item label="Fax" name="fax">
                  <InputNumber placeholder="Fax" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item label="Expired Date" name="expired_date">
                  <DatePicker
                    placeholder="Expired Date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={16} style={{ width: "100%" }}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message:
                        "Guest Address must be filled and cannot be empty!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input.TextArea placeholder="Address" rows={5} />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "15px 0px", background: "#EBEDF3" }}
            />

            <Row className="credit-row" gutter={30}>
              <Col span={8}>
                <Form.Item
                  label="Credit Account Number"
                  name="credit_account_no"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input
                    placeholder="Credit Account Number"
                    style={{ width: "100%" }}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Credit Limit"
                  name="credit_limit"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <InputNumber
                    placeholder="Credit Limit"
                    min={0}
                    disabled
                    formatter={(price) => {
                      return `${price > 0 ? price : 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Ledger Allowed"
                  name="allow_ledger"
                  valuePropName={"checked"}
                >
                  <Checkbox
                    className="ledger-checkbox"
                    disabled
                  >{`Allow Ledger`}</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "15px 0px", background: "#EBEDF3" }}
            />

            <Row gutter={30}>
              <Col span={8}>
                <Form.Item label="Main Segment" name="main_segment_name">
                  <MasterSegment
                    getSegment={getSegment}
                    is_company={
                      isEdit === true ? dataEdit?.main_segment_id : []
                    }
                    sgmnt={isEdit === true ? dataEdit?.main_segment_name : ""}
                    is_fromSalesman={true}
                  />
                </Form.Item>

                <Form.Item
                  label="Sales"
                  name="sales_id"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <MasterSalesman
                    getSalesman={getSalesman}
                    salesman={isEdit === true ? dataEdit?.sales_id : 0}
                    segmentId={segmentid}
                  />
                </Form.Item>

                <Form.Item
                  label="Main Segment Code"
                  name="main_segment_code"
                  hidden
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Main Segment ID"
                  name="main_segment_id"
                  hidden
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Rate Code" name="rate_code">
                  {/* <Input placeholder="Rate Code" /> */}
                  <MasterRateCodeMultiple
                    getRateCode={getRateCode}
                    segment_id={segmentid}
                    rate_code={isEdit ? dataEdit?.rate_code : []}
                  />
                </Form.Item>

                <Form.Item label="Rate Code ID" hidden name="rate_code_id">
                  <Input placeholder="Rate Code" />
                </Form.Item>

                <Form.Item label="Book Source" name="source">
                  {/* <Input placeholder="Book Source" /> */}
                  <MasterSource
                    getSource={getBookSource}
                    srce={isEdit ? dataEdit?.source : null}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Comment" name="comment">
                  <Input.TextArea placeholder="Comment" rows={5} />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-guest-pay">
              <Col span={8}>
                <Form.Item
                  label="Payment Type"
                  name="payment_type"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <MasterPaymentTypeEdit
                    getPaymentType={getPaymentType}
                    // getPayTypeId={getPayTypeId}
                    pay={isEdit === true ? dataEdit?.payment_type : null}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                  <Form.Item label="Credit Limit" name="credit_limit">
                  <InputNumber
                      placeholder="Credit Limit"
                      type={"number"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col> */}

              <Col span={16}>
                <Form.Item
                  label="Master Comp"
                  name="master_comp"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <MasterCompany
                    getMasterComp={getMasterComp}
                    masterComp={isEdit ? dataEdit?.master_comp : null}
                    typeGuest={type}
                  />
                </Form.Item>

                <Form.Item label="Master Comp ID" name="master_comp_id" hidden>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
          {/* ) : null} */}

          <Divider
            className="form-divider"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

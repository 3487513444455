// Import React's Component
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Table,
  Button,
  Col,
  Row,
  Form,
  Typography,
  Card,
  Tag,
} from "antd";

// Import Notification
import {
  failedFetch,
} from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import KitchenProcessModal from "../../../Modals/Outlet/KitchenOrder/Modal";

// CODE
export default function KitchenDetailsTable({selectedData, is_created}) {

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataOrder, setDataOrder] = useState(null);
  const [dataDetails, setDataDetails] = useState(null);

  //Edit
  const [guest, setGuest] = useState(null);

  // Loading
  const [cardLoad, setCardLoad] = useState(true);

  // Selected Data & Keys
  const selectionType = 'checkbox';
  const [billStatus, setBillStatus] = useState(0);

  // State
  const [chargeToRoomState, setChargeToRoomState] = useState(false);

  // Modal
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItemsData, setSelectedItemsData] = useState([]);

  // USE FORM
  const [form] = Form.useForm();

  // USEEFFECT
  useEffect(() => {
    console.log("selectedRowKeys array", selectedRowKeys)
  }, [selectedRowKeys])
  

  useEffect(() => {
    console.log("selectedData on useEffect PerTable", selectedData);
    setDataDetails(selectedData?.items);
    uncheckAll();

    return () => {};
  }, [selectedData]);

  useEffect(() => {
    console.log("selectedData on useEffect PerTable", selectedData);
    if (selectedData?.table_id) {
      fetchTable(selectedData);
    } else {
      fetchOrder(selectedData);
    }

    if (selectedData?.id) {
      setBillStatus(1);
    }

    return () => {};
  }, [selectedData]);

  // FETCH ID
  const fetchTable = async (record) => {
    await axios
      .post(`${baseurl}/pos/order-table`, {
        // table_id: tableKey ? tableKey?.table_id : selectedData?.table_id ? selectedData?.table_id : tableSelected?.table_id,
        table_id: record?.table_id,
      })
      .then((response) => {
        console.log("Table Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
            (e) => {
              if (e.is_deleted == false) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          })

          setData(_resMap);
          setDataOrder(response.data.msg[0]);
          handleFormField(response.data.msg[0], record);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setCardLoad(false);
      });
  };

  const fetchOrder = async (record) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        // order_id_alias: tableKey?.order_id_alias,
        order_id_alias: record?.order_id_alias,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          });
          console.log("_resMap", _resMap)

          setData(_resMap);
          setDataOrder(response.data.msg[0]);
          handleFormField(response.data.msg[0], record);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setCardLoad(false);
      });
  };

  // Handle Form Field
  const handleFormField = (value, header) => {
    const _record = value;

    if(header?.name[header?.name?.indexOf(",") - 2] == 1) {
      setGuest(header?.name);
    } else {
      setGuest(null);
    };

    form.setFieldsValue({
      name: _record?.name,
      sales_type: _record?.sales_type,
      payment_status: _record?.payment_status != 1 ? true : false,
      pax: _record?.name[_record?.name?.indexOf(",") - 1] ? _record?.name[_record?.name?.indexOf(",") - 1] : null,
    });
    if (_record?.payment_status != 1) {
      setChargeToRoomState(true);
    }
  };

  // COLUMNS
  const columns = [
    {
      title: "Items",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
      render: (text) => {
        return text;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => {
        return `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // hidden: true,
      render: (text, record) => {
        let color = "";
        let txt = "";

        switch (record.notes?.substr(record.notes?.length - 1)) {
          case "0":
            color = "#2db7f5";
            txt = "Ordered";
            break;
          case "2":
            color = "#108ee9";
            txt = "Cooked";
            break;
          case "4":
            color = "#87d068";
            txt = "Served";
            break;

          default:
            color = "#d9d9d9";
            txt = "-";
            break;
        }

        return (
          <Tag color={color}>
            {txt ? txt.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
  ].filter(item => !item.hidden);

  // ON FINISH
  const onFinish = (value) => {
    console.log("onFinish", value);
  };
  
  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    console.log("onFinish", value);
  };

  const handleClear = () => {
    setData([]);
    setDataOrder(null);
    form.resetFields();
    is_created();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (key, record) => {
      setSelectedRowKeys(key);
      setSelectedItemsData(record);
    },
    getCheckboxProps: (record) => ({
      disabled: record.notes?.substr(record.notes?.length - 1) !== '0',
      // Column configuration not to be checked
      article_name: record.article_name,
    }),
  };

  const uncheckAll = () => {
    setSelectedRowKeys([]);
    setSelectedItemsData([]);
  };

  return (
    <>
      <Row className="row-pertable" style={mainBody}>
        <Col
          className="table-col"
          span={24}
          style={{ width: "100%", padding: "0px 30px 30px" }}
        >
          <Card
            className="table-card"
            bordered
            // loading={cardLoad}
            style={{
              width: "100%",
              // marginTop: 16,
            }}
            title={
              <Row justify="center" align="middle" className="title-row">
                <Col span={24} className="title-col">
                  <Typography
                    className="bill-txt"
                    key={"txt-bill"}
                    style={{ fontSize: 20 }}
                  >
                    {selectedData?.order_id_alias || dataOrder?.order_id_alias || "---"}
                  </Typography>
                </Col>
              </Row>
            }
            headStyle={{
              textAlign: "center",
              background: "#e4e6ef",
            }}
            bodyStyle={{
              textAlign: "center",
              display: selectedData?.table_id ?  "block" : "none",
            }}
          >
            <Typography
              className="bill-txt"
              key={"txt-bill"}
              style={{ fontSize: 20, fontWeight: 500 }}
            >
              {selectedData?.table_id || "No Table"}
            </Typography>
          </Card>

          <Form
            form={form}
            className="order-form"
            key="order-form"
            name="order-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            style={{
              padding: "15px 0px 0px",
              margin: 0,
            }}
          >
            <Form.Item
              name="article_list"
              label=""
              rules={[
                {
                  validator: async (keys) => {
                    form.setFieldsValue({
                      article_list: data,
                    });

                    if (data.length === 0) {
                      return Promise.reject("Please, Select a Menu!");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Table
                className="ordertable-tbl"
                name="ordertable-tbl"
                key="ordertable=tbl"
                columns={columns}
                dataSource={data}
                // dataSource={dataDetails}
                bordered
                size="medium"
                pagination={false}
                scroll={{
                  x: true,
                }}
                // rowKey={(record) => record?.article_id}
                rowKey={(record) => record?.key}
                rowClassName={(_, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
              />
            </Form.Item>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row className="row-modal-btn" justify="end" align="middle">
              <Button
                onClick={(e) => {
                  setModalConfirm(true);
                }}
                disabled={selectedRowKeys.length > 0 ? false : true}
                type="primary"
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Submit to Process`}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>

      <KitchenProcessModal
        openModal={modalConfirm}
        closeModal={setModalConfirm}
        data={{
          ...selectedData,
          selectedItems: selectedItemsData,
        }}
        allDetails={data}
        is_created={handleClear}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";

// Redux Night Audit
import * as na from "../../../../API/AuthActions/auditAction";

// AUTH
import {
  roles,
  department,
  user_name,
  token,
} from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Steps,
  Progress,
  Alert,
  Spin,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedPayCard,
  masterIncomplete,
  successPayCard,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import ComplimentaryAuditTable from "../../../Table/BackOffice/NightAudit/Compliment/Table";
import OutstandingAuditTable from "../../../Table/BackOffice/NightAudit/Bill/Table";
import CountryAuditTable from "../../../Table/BackOffice/NightAudit/Nation/Table";
import InHouseAuditTable from "../../../Table/BackOffice/NightAudit/InHouse/Table";
import ArrivalAuditTable from "../../../Table/BackOffice/NightAudit/Arrival/Table";
import DepartureAuditTable from "../../../Table/BackOffice/NightAudit/Departure/Table";
import RoomRateAuditTable from "../../../Table/BackOffice/NightAudit/RoomRate/Table";
import GuestAccountAuditTable from "../../../Table/BackOffice/NightAudit/GuestAccount/Table";
import UserShiftAuditTable from "../../../Table/BackOffice/NightAudit/UserShift/Table";
import ModalPermission from "../../../Modals/Permissions/Modal";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { handleAuthorization } from "../../../../API/Global/Permission";

// CONST
const { Step } = Steps;
const { confirm } = Modal;

// Dispatch Props
const mapDispatchToProps = (dispatch) => {
  console.log("DISPATCH: ", dispatch, na);

  return {
    setDateAfterNA: (value) => dispatch(na.setDate(value)),
  };
};

// User's Reducers
const mapStateToProps = (state) => {
  console.log("Dipatch To Props: ", state);

  let response = state;
  let naDate = response.rootReducer.nightaudit;

  // const {isLoggedIn, accessToken} = user
  return { naDate };
};

// CODE
const ReNightAuditSteps = (props) => {
  // PROPS
  const { dates } = props;

  // USE LOCATION & Navigate
  const { pathname, state } = useLocation();

  const navigate = useNavigate();

  // CONTEXT
  const { postRunNA } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data Table
  const [dataSales, setDataSales] = useState([]);
  const [roomRate, setRoomRate] = useState([]);
  const [compliment, setCompliment] = useState([]);
  const [outstanding, setOutstanding] = useState([]);
  const [guestAccount, setGuestAccount] = useState([]);
  const [dataCountry, setDataCountry] = useState([]);
  const [inHouse, setInHouse] = useState([]);
  const [arrival, setArrival] = useState([]);
  const [depart, setDepart] = useState([]);
  // New Audit Date
  const [newDate, setNewDate] = useState(null);
  // Steps Counter
  const [stepsCounter, setStepsCounter] = useState(1);
  const [stepsLast, setStepsLast] = useState(1);
  // Error State
  const [is_ErrorAlert, setIs_ErrorAlert] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [dateAudit, setDateAudit] = useState(null);
  // Permissions
  const [permission, setPermission] = useState(false);
  const [permitLoad, setPermitLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Progress Bar
  const [percent, setPercent] = useState(0);
  const [barState, setBarState] = useState(false);
  const [progressResult, setProgressResult] = useState("normal");

  // new state progress bar
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  // USE FORM
  const [form] = Form.useForm();

  // POST
  // Post Run Night Audit
  const postDataRunNA = async () => {
    if (arrival?.length == 0 && depart?.length == 0) {
      setLoadingProgress(true);

      await axios
        .post(
          `${baseurl}/night-audit/re-audit`,
          {
            audit_date: moment(dateAudit).format("YYYY-MM-DD"),
            created_by: user_name,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("Response Re-Audit: ", response);

          setProgressResult("success");
          setIs_ErrorAlert(false);

          if (response?.data?.posted) {
            const _res = response.data.posted;

            setNewDate(_res);
            let changeDate = moment(_res).format();

            console.log("Change Date: ", changeDate);
            handleSuccess(true);
            props.setDateAfterNA(changeDate);
          } else {
            setProgressResult("exception");
            setIs_ErrorAlert(true);
          }
        })
        .catch((error) => {
          setProgressResult("exception");
          setIs_ErrorAlert(true);
          setErrorCode(error);
          setLoadingProgress(false);
          console.log("Failed to Night Audit: ", error);
        })
        .finally(() => {
          setLoadingProgress(false);
        });
    } else {
      setProgressResult("exception");
      setIs_ErrorAlert(true);
      setLoadingProgress(false);
      setBarState(true);
    }
  };

  // USE EFFECTS
  useEffect(() => {
    if (barState == true) {
      // if (stepsLast == 8 && percent <= 100) {
      if (stepsLast == 10 && progress <= 100) {
        console.log("Processing Status: ", percent);

        // Fungsi untuk mengupdate progres setiap 50ms saat loading
        let interval;

        if (loadingProgress) {
          interval = setInterval(() => {
            setProgress((prevProgress) => {
              if (prevProgress < 100) {
                return prevProgress + 1;
              }
              return prevProgress;
            });
          }, 50);
        } else {
          setProgress(100);
          clearInterval(interval);

          if (is_ErrorAlert === false) {
            // setTimeout(() => {
            setLoading(true);
            // setLoading(false);
            handleAuditSuccess(newDate);
            // window.location.reload(false);
            // }, 2000);
          }
        }

        return () => clearInterval(interval);

        // console.log(interval);
        // if (percent == 100) {
        //   clearInterval(currentTimer);

        //   if (is_ErrorAlert === false) {
        //     setTimeout(() => {
        //       setLoading(true);
        //       // setLoading(false);
        //       handleAuditSuccess(newDate);
        //       // window.location.reload(false);
        //     }, 2000);
        //   }
        // }

        // console.log(percent);
        // return () => clearInterval(currentTimer);
      }
    }
  }, [stepsLast, barState, loadingProgress, newDate]);

  useEffect(() => {
    let cleanUp = false;

    console.log("Tanggal: ", dates);
    console.log("Error Alert: ", is_ErrorAlert);
    console.log("Bar status: ", barState);

    if (is_ErrorAlert && !cleanUp) {
      setBarState(true);
    }

    if (dates && !cleanUp) {
      if (!moment(dates).isSame(dateAudit)) {
        setStepsCounter(1);
        setDateAudit(dates);
      }
    } else {
      setDateAudit(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_ErrorAlert, barState, dates, errorCode]);

  // SHOW MODAL NA
  const showRunningModal = (record) => {
    let del = record;

    confirm({
      className: "confirm",
      title: `Are you sure want to Execute the Night Audit Process ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        setIsSuccess(true);
        setBarState(true);
        postDataRunNA();
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE CLICK
  const handleClick = () => {
    if (stepsCounter === 10) {
      handlePermit();
    } else {
      counter(1);
      if (stepsLast == stepsCounter) {
        setStepsLast(stepsLast + 1);
      }
    }
  };

  // STEPS
  // Steps Counter
  const counter = (e) => {
    const _steps = e;
    setStepsCounter(stepsCounter + _steps);
  };

  // STEPS STATUS
  const statusSteps = (e, data) => {
    const _steps = e;
    const _arr = data;

    // console.log("status steps => ", _steps);

    let _stats = "";

    switch (_steps) {
      case 8:
      case 9:
        if (_arr?.length > 0) {
          _stats = "error";
        } else if (stepsLast == _steps) {
          _stats = "process";
        } else {
          _stats = "finish";
        }
        break;

      default:
        if (stepsLast == _steps) {
          _stats = "process";
        } else if (stepsLast > _steps) {
          _stats = "finish";
        } else {
          _stats = "wait";
        }
        break;
    }

    // eval(`data${e}`)?.length > 0 && (_steps == 6 || _steps == 7)
    //   ? "error"
    //   : stepsLast == _steps
    //   ? "process"
    //   : stepsLast > _steps
    //   ? "finish"
    //   : "wait";

    return _stats;
  };

  // Button Steps
  const descSteps = (e, arr) => {
    const _steps = e;
    const _arr = arr;

    console.log("Desc Steps --> ", _steps);
    // console.log("Counter Steps --> ", stepsCounter);
    // console.log("Data --> ", eval(`data${e}`));

    return stepsCounter == _steps ? (
      <>
        <Row justify="end" style={{ margin: "30px 0px 0px" }}>
          {_steps > 1 ? (
            <Button
              className={`next-btn`}
              type="primary"
              disabled={
                _arr?.length > 0 && stepsLast < stepsCounter ? true : false
              }
              onClick={() => {
                counter(-1);
              }}
            >
              {`Previous`}
            </Button>
          ) : null}

          <Button
            type="primary"
            className={`next-btn`}
            disabled={
              _arr?.length > 0 && stepsLast < stepsCounter ? true : false
            }
            onClick={() => {
              counter(1);

              if (stepsLast == stepsCounter) {
                setStepsLast(stepsLast + 1);
              }
            }}
            style={{ marginLeft: 15 }}
          >
            {`Next`}
          </Button>
        </Row>
      </>
    ) : (
      descriptionSteps(e, _arr)
    );
  };

  // STEPS LAST
  const otherSteps = (e, arr) => {
    const _steps = e;
    const _arr = arr;
    // console.log("Other Steps --> ", e);
    // console.log("Counter Steps --> ", stepsCounter);

    return stepsCounter == _steps ? (
      <>
        <Row justify="end" style={{ margin: "0px 15px 0px 0px" }}>
          {stepsCounter != 1 ? (
            <Button
              type="primary"
              className="next-btn"
              disabled={
                _arr?.length > 0 && stepsLast < stepsCounter ? true : false
              }
              onClick={() => {
                counter(-1);
              }}
              loading={barState && progress !== 100 ? true : false}
            >
              {`Previous`}
            </Button>
          ) : null}

          <Button
            type="primary"
            className={stepsCounter === 10 ? `submit-btn` : `next-btn`}
            loading={barState && progress !== 100 ? true : false}
            // loading={barState && percent !== 100 ? true : false}
            disabled={barState === true ? true : false}
            onClick={handleClick}
            style={{ marginLeft: 15 }}
          >
            {stepsCounter === 10 ? "Execute Re-Audit" : "Next"}
          </Button>
        </Row>

        {barState === true ? progressBar() : null}

        {is_ErrorAlert === true && progress == 100
          ? errorAlert(true)
          : is_ErrorAlert === false && progress == 100
          ? successAlert()
          : null}
      </>
    ) : (
      descriptionSteps(e, _arr)
    );
  };

  // DESC STEPS
  const descriptionSteps = (e, arr) => {
    const _steps = e;
    const _arr = arr;
    let _txt = "";

    if (_arr?.length > 0) {
      _txt = "Cross Check Again";
    } else if (stepsLast > _steps) {
      _txt = "Finished";
    } else {
      _txt = "Waiting...";
    }

    return _txt;
  };

  // PROGRESS BAR
  const progressBar = () => {
    console.log(progressResult);

    return (
      <Row className="progress-row" style={{ margin: "30px 0px 30px 0px" }}>
        {/* <Progress percent={percent} status={progressResult} /> */}
        <Progress percent={progress} status={progressResult} />
      </Row>
    );
  };

  // Error Night Audit
  const errorAlert = (e) => {
    let runAgain = e;
    let err = "";

    console.log("Run Twice: ", runAgain);

    if (arrival?.length > 0 && depart?.length > 0) {
      err = "There are some data in Arrival & Departure!";
    } else if (arrival?.length > 0) {
      err = "There are some data in Arrival!";
    } else if (depart?.length > 0) {
      err = "There are some data in Departure!";
      // } else if (runAgain === true && errorCode === null) {
      //   err =
      //     "Its already audited today and this cannot be executed more than once a day!";
    } else {
      err = errorCode?.message;
    }

    return (
      <Alert
        className="audit-error-alert"
        message={<b>{`Error!`}</b>}
        description={`Re-Night Audit is Failed! ${err} \n Please, Check the Data Again and Try to Run Night Audit Again Later!`}
        type="error"
        showIcon
      />
    );
  };

  // Success Alert Night Audit
  const successAlert = () => {
    return (
      <>
        <Spin className="loading-spin" spinning={loading}>
          <Alert
            className="success-results"
            message="Success"
            description="Re-Night Audit is Run Successfully! Wait a second you are being redirected!"
            type="success"
            showIcon
          />
        </Spin>
      </>
    );
  };

  // Handle Audit Success
  const handleAuditSuccess = async (value) => {
    const _record = [value];

    if (value) {
      console.log("Navigate => ", pathname, state);
      console.log("Record => ", _record);

      setTimeout(() => {
        setLoading(false);

        navigate(`${pathname}/success`, {
          state: _record,
        });
      }, 2000);
    } else {
      setIs_ErrorAlert(true);
    }
  };

  // HANDLE EARLY CHECK IN
  const handlePermit = async (value) => {
    const _record = value;

    console.log("Permitted: ", _record);
    // console.log("Group: ", isGroup);

    if (
      _record?.auth_token &&
      _record?.auth_roles == "ROLE_SUPERVISOR" &&
      _record?.auth_dept.toLowerCase().includes("front")
    ) {
      showRunningModal();
    } else {
      setPermission(true);
    }
  };

  // HANDLE SUCCESS
  const handleSuccess = async (value) => {
    const _record = value;

    console.log("Value Success: ", _record);
    // console.log("Group: ", isGroup);

    if (_record == true) {
      localStorage.clear();
      handleAuthorization("");
      setPermission(false);
    }
  };

  // console.log("Last Steps", stepsLast);
  // console.log("Steps Count", stepsCounter);
  // console.log("Percent --> ", percent);
  // console.log("Steps Status ? ", statusSteps(6));

  // console.log("", progressResult);

  return (
    <>
      <Row
        className="row-night-audit"
        justify="center"
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100%",
          borderRadius: 5,
          width: "100%",
          //   marginTop: 30,
        }}
      >
        <Col
          className="col-night-audit"
          span={24}
          style={{ padding: "15px 30px" }}
        >
          <Row
            justify="start"
            style={{
              padding: "0px 0px 30px",
              fontSize: 20,
              fontWeight: 650,
            }}
          >{`Re-Night Audit for ${
            dateAudit ? moment(dateAudit).format("DD MMM YYYY") : ""
          }`}</Row>

          {dateAudit ? (
            <Row>
              <Steps
                className="nigh-audit-steps"
                direction="vertical"
                current={stepsCounter}
                percent={99}
                // status="process"
                // onChange={(e) => setStepsCounter(e)}
              >
                <Step
                  title="Statistics Sales by Users"
                  status={statusSteps(1, dataSales)}
                  disabled={
                    stepsLast >= 1 || dataSales?.length > 0 ? false : true
                  }
                  description={
                    stepsCounter == 1 ? (
                      <>
                        <UserShiftAuditTable
                          dataStats={setDataSales}
                          dateAudit={dateAudit}
                        />
                        {descSteps(1, dataSales)}
                      </>
                    ) : null
                  }
                />

                <Step
                  title="Room Rate"
                  status={statusSteps(2, roomRate)}
                  disabled={
                    stepsLast >= 2 || roomRate?.length > 0 ? false : true
                  }
                  // description={descSteps(1)}
                  description={
                    stepsCounter == 2 ? (
                      <>
                        <RoomRateAuditTable
                          dataRoomRate={(e) => {
                            // console.log("This is Data 1: ", e);
                            setRoomRate(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(2, roomRate)}
                      </>
                    ) : null
                  }
                />

                <Step
                  title="Complimentary"
                  status={statusSteps(3, compliment)}
                  disabled={
                    stepsLast >= 3 || compliment?.length > 0 ? false : true
                  }
                  description={
                    stepsCounter == 3 ? (
                      <>
                        <ComplimentaryAuditTable
                          dataComplimentary={(e) => {
                            // console.log(e);
                            setCompliment(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(3, compliment)}
                      </>
                    ) : null
                  }
                />
                <Step
                  title="Room Count Sheet"
                  status={statusSteps(4, outstanding)}
                  disabled={
                    stepsLast >= 4 || outstanding?.length > 0 ? false : true
                  }
                  description={
                    stepsCounter == 4 ? (
                      <>
                        <OutstandingAuditTable
                          dataBillOut={(e) => {
                            // console.log(e);
                            setOutstanding(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(4, outstanding)}
                      </>
                    ) : null
                  }
                />

                {/* START HERE */}

                <Step
                  title="Guest Account Balance"
                  status={statusSteps(5, guestAccount)}
                  disabled={
                    stepsLast >= 5 || guestAccount?.length > 0 ? false : true
                  }
                  description={
                    stepsCounter == 5 ? (
                      <>
                        <GuestAccountAuditTable
                          dataGuestAccount={(e) => {
                            // console.log(e);
                            setGuestAccount(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(5, guestAccount)}
                      </>
                    ) : null
                  }
                />

                {/* END HERE */}

                <Step
                  title="Foreign Guest List"
                  status={statusSteps(6, dataCountry)}
                  disabled={
                    stepsLast >= 6 || dataCountry?.length > 0 ? false : true
                  }
                  description={
                    stepsCounter == 6 ? (
                      <>
                        <CountryAuditTable
                          dataCountry={(e) => {
                            setDataCountry(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(6, dataCountry)}
                      </>
                    ) : null
                  }
                />
                <Step
                  title="In-House List"
                  status={statusSteps(7, inHouse)}
                  disabled={
                    stepsLast >= 7 || inHouse?.length > 0 ? false : true
                  }
                  description={
                    stepsCounter == 7 ? (
                      <>
                        <InHouseAuditTable
                          dataInHouse={(e) => {
                            // console.log(e);
                            setInHouse(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(7, inHouse)}
                      </>
                    ) : null
                  }
                />
                <Step
                  title="Arrival List"
                  status={statusSteps(8, arrival)}
                  disabled={
                    stepsLast >= 8 || arrival?.length > 0 ? false : true
                  }
                  description={
                    stepsCounter == 8 ? (
                      <>
                        <ArrivalAuditTable
                          dataArrival={(e) => {
                            // console.log("ARRIVAL: ", e);
                            setArrival(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(8, arrival)}
                      </>
                    ) : null
                  }
                />
                <Step
                  title="Departure List"
                  status={statusSteps(9, depart)}
                  disabled={stepsLast >= 9 || depart?.length > 0 ? false : true}
                  description={
                    stepsCounter == 9 ? (
                      <>
                        <DepartureAuditTable
                          dataDeparture={(e) => {
                            // console.log(e);
                            setDepart(e);
                          }}
                          dateAudit={dateAudit}
                        />
                        {descSteps(9, depart)}
                      </>
                    ) : null
                  }
                />

                <Step
                  title="Running Night"
                  status={statusSteps(10)}
                  disabled={stepsLast >= 10 ? false : true}
                  description={otherSteps(10)}
                />
              </Steps>
            </Row>
          ) : null}
        </Col>
      </Row>

      <ModalPermission
        openModal={permission}
        closeModal={setPermission}
        is_success={isSuccess}
        is_refresh={setRefresh}
        dataSubmit={null}
        roomStatus={3}
        is_deleted={false}
        is_authorized={handlePermit}
        is_loading={permitLoad}
        is_loadingFalse={setPermitLoad}
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReNightAuditSteps);

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaCreditCard } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import MasterPaymentTypeEdit from "../../../Components/Reusable/Master/PaymentType/PaymentTypeEdit";
import PaymentCardTable from "../../../Components/Table/Master/PaymentCard/Table";
import MasterPaymentType from "../../../Components/Reusable/Master/PaymentType/PaymentType";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function PaymentCardPage() {
  // STATE MANAGEMENT
  // Value Search
  const [value, setValue] = useState(null);
  const [payment, setPayment] = useState({
    id: 0,
    name: "",
  });
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  // Handle Payment Type
  const handlePay = (val) => {
    setPayment({
      id: val?.id,
      name: val?.name,
    });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="payment-card-main-layout">
          <Row className="row-title" justify="start" wrap style={cardHeader}>
            <FaCreditCard
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Payment Card List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 200,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Payment Type`}</Typography>

                <MasterPaymentTypeEdit getPaymentType={handlePay} />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 200,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Payment Card Name"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <PaymentCardTable
            is_search={setSearch}
            searchKey={value}
            searchPay={payment}
          />
        </Col>
      </Content>
    </Layout>
  );
}

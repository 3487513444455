// Import React's Components
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// REDUX
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../../API/AuthActions/authAction";

// Import Ant Design Components
import {
  Col,
  Layout,
  Row,
  Button,
  Form,
  Space,
  Typography,
  Input,
  Image,
  InputNumber,
  Checkbox,
  Modal,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { PiUserCirclePlusFill } from "react-icons/pi";
import { Loading3QuartersOutlined, LoadingOutlined } from "@ant-design/icons";

// Import Page Components
import MasterRoles from "../../../Components/Reusable/Auth/Roles/MasterRoles";
import MasterModules from "../../../Components/Reusable/Auth/Modules/MasterModules";
import MasterDepartment from "../../../Components/Reusable/Master/Department/Department";
import MasterApproval from "../../../Components/Reusable/Auth/Approval/ApprovalLevel";

// Import Success Login Notification
import {
  failedRegister,
  incomplete,
} from "../../../Components/Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";

// Modals
const { confirm } = Modal;

// Item's Layout
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch Register --> ", dispatch);

  return {
    disRegister: (values) => dispatch(auth.register(values)),
    disReset: (val) => dispatch(auth.resetRegister(val)),
  };
};

// Maps to Props
const mapStateToProps = (state) => {
  let response = state;
  let register = response.rootReducer.auth;

  console.log("Return to Parents: ", response);

  return { register };
};

// MAIN CODE
const RegisterPage = (props) => {
  // PROPS

  // STATE MANAGEMENT
  // Department
  const [dept, setDept] = useState({
    name: "",
    code: "",
    dept_id: 0,
    dept_uuid: "",
  });
  // Roles
  const [roleLevel, setRoleLevel] = useState(0);
  // Boolean
  const [loading, setLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();
  const token = sessionStorage.getItem("auth-token");

  // USE FORM
  const [form] = Form.useForm();

  // ON FINISH
  const onFinish = (value) => {
    const values = value;
    const _errors = {
      errorFields: [
        {
          errors: ["Please, make sure You Choose a Department!"],
        },
      ],
    };

    console.log("Success:", values);

    if (values?.module?.length > 0 && values?.department?.dept_uuid) {
      // if (values?.is_approve === false && values?.approval_level > 0) {

      setLoading(true);
      showModalConfirm(values);
    } else {
      incomplete(_errors, { method: 0 });
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (errorInfo) => {
    console.log("Failed: ", errorInfo?.values);
    console.log("Dept: ", dept);

    // incomplete(errorInfo, { method: 0 });
  };

  // Show Modal Confirm
  const showModalConfirm = (values) => {
    const _data = values;

    confirm({
      className: "submit-reservation-confirm",
      title: `Are you sure want to Register a New User with named ${
        _data?.user_name?.toUpperCase() || "Unknown"
      } to the systems?`,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleRegister(_data);
        setOpenLoading(true);
      },
      onCancel() {
        setLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE AUTH
  const handleRegister = (values) => {
    setLoading(true);

    if (values) {
      console.log("Ini value didalam handel Regis: ", values);
      console.log("Props: ", props);

      props.disRegister(values);
    } else {
      setLoading(false);
    }
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("AUTH! Success: ", props);

    if (props?.register?.register_status === true && !cleanUp) {
      handleClear();
      // navigate("/dashboard");

      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 1000);
    } else {
      setLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [props]);

  // Department
  const getDepartment = (val) => {
    const _value = val;

    console.log("Department: ", _value);
    setDept(_value);

    form.setFieldsValue({
      department: _value,
    });
  };

  // Get Roles
  const getRole = (value) => {
    const _value = value;

    console.log("Get Roles: ", _value);

    setRoleLevel(_value?.level);

    form.setFieldsValue({
      roles: _value?.role,
      approval_level: _value?.level + 1,
      user_level: _value?.level,
    });
  };

  // Get Module
  const getModule = (value) => {
    const _value = value;

    console.log("Get Modules: ", _value);

    if (_value.length > 0) {
      let _map = _value.map((value) => value?.id);

      console.log("Modules: ", _map);

      form.setFieldsValue({
        module: _map,
      });
    } else {
      form.setFieldsValue({
        module: [],
      });
    }
  };

  // Get Approval
  const getApproval = (value) => {
    const _value = value;

    console.log("Level: ", _value);

    form.setFieldsValue({
      approval_level: _value?.level,
    });
  };

  // Handle Check
  const handleCheck = (e) => {
    const _check = e.target.checked;
    // console.log(e);

    setIsApprove(_check);
  };

  // Handle Cancel
  const handleCancel = () => {
    const _path = "/configuration/user-management";

    // setLoading(false);
    navigate(_path);
  };

  // Handle Clear
  const handleClear = () => {
    form.resetFields();

    props.disReset(true);

    setTimeout(() => {
      handleCancel();
    }, 2500);
  };

  return (
    <>
      <Layout className="layout-register">
        <Content className="content-register">
          <Row className="row-t&c" align="bottom" style={mainBody}>
            <Col span={24} className="col-headers">
              <Space className="page-space" size={"large"}>
                <PiUserCirclePlusFill
                  style={{
                    height: 24,
                    width: 24,
                    margin: "22px 30px",
                    color: "#3699FF",
                  }}
                />
                <Typography
                  style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
                >
                  {`Register New User`}
                </Typography>
              </Space>
            </Col>
          </Row>

          <Row className="row-space" style={{ margin: 15 }} />

          <Row
            className="row-register"
            align="middle"
            justify="center"
            style={mainBody}
          >
            <Col
              span={24}
              className="col-register"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                flexDirection: "column",
                alignContent: "center",
                width: "100%",
                padding: 30,
              }}
            >
              <Row justify="start" align="middle" className="row-form-register">
                <Typography
                  className="text-hotel-name"
                  style={{
                    color: "#3F4254",
                    fontSize: 32,
                    fontWeight: 600,
                    fontStyle: "normal",
                    padding: 0,
                  }}
                >
                  {`Register New Users`}
                </Typography>
              </Row>

              <Form
                {...formItemLayout}
                className="register-form"
                key="register-form"
                name="register-form"
                form={form}
                colon={false}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                labelAlign="left"
                labelWrap
                initialValues={{
                  is_approve: false,
                }}
                style={{
                  margin: "30px 0px 0px",
                  width: "100%",
                }}
              >
                <Row className="row-register-form">
                  <Col span={24} className="col-register">
                    <Form.Item
                      className="form-item-register"
                      label="User Name"
                      name="user_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input your User Name!",
                        },
                      ]}
                    >
                      <Input placeholder="User Name" />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label={`Email`}
                      name={"email"}
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please, Input your Email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label={`Phone Number`}
                      name={"phone_number"}
                      rules={[
                        {
                          type: "number",
                          required: true,
                          message: "Please, Input your Email!",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Phone Number"
                        className="phone"
                        minLength={10}
                        maxLength={12}
                        addonBefore={62}
                      />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label="Department"
                      name={"department"}
                      rules={[
                        {
                          required: true,
                          message: "Please, Choose the Department!",
                        },
                      ]}
                    >
                      <MasterDepartment
                        getDepartment={getDepartment}
                        department_name={null}
                        disabled={false}
                      />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label="Roles"
                      name={"roles"}
                      rules={[
                        {
                          required: true,
                          message: "Please, Choose the Roles!",
                        },
                      ]}
                    >
                      <MasterRoles getRole={getRole} />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label="User Level"
                      name={"user_level"}
                      hidden
                    >
                      <InputNumber min={0} />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label="Modules"
                      name={"module"}
                      rules={[
                        {
                          required: true,
                          message: "Please, Choose the Modules!",
                        },
                      ]}
                    >
                      <MasterModules getModule={getModule} />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label="Approval Level"
                      name={"approval_level"}
                      hidden={true}
                      // rules={[
                      //   {
                      //     required: roleLevel > 3 && isApprove ? false : true,
                      //     message: "Please, Select an Option!",
                      //   },
                      // ]}
                    >
                      <MasterApproval
                        getApproval={getApproval}
                        disabled={false}
                        approvals={null}
                        roleLevel={roleLevel}
                      />
                    </Form.Item>

                    <Form.Item
                      valuePropName="checked"
                      label="No Approval Needed"
                      name="is_approve"
                      hidden={roleLevel > 3 ? false : true}
                    >
                      <Checkbox
                        disabled={roleLevel > 3 ? false : true}
                        className="is-approve"
                        onChange={handleCheck}
                      >
                        {`None`}
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label={`Password`}
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: "Please, Input your Password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const _check =
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
                            // /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!_@#$%^&*]{6,16}$/;

                            console.log(`Is Resolves: ${_check.test(value)}`);

                            if (_check.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error(
                                  "The passwords must have at least a Capital letter, a Number, a special character (@, $, !, %, *, ?, &) and must be between 8 to 16 characters!"
                                )
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="pw-inp"
                        placeholder="Password"
                        minLength={8}
                      />
                    </Form.Item>

                    <Form.Item
                      className="form-item-register"
                      label={`Confirm Password`}
                      name={"confirm_password"}
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please, Input your Password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                new Error(
                                  "The passwords that you entered do not match!"
                                )
                              );
                            }
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm Password"
                        className="pw-inp"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="btn-row" justify="end">
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="next-btn"
                    loading={loading}
                    style={{
                      margin: "0px 30px 0px 0px",
                    }}
                  >
                    {`Sign Up`}
                  </Button>

                  <Button
                    type="default"
                    className="cancel-btn"
                    onClick={handleCancel}
                  >
                    {`Cancel`}
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>

          <Modal
            className="loading-modal"
            open={openLoading}
            footer={null}
            centered
            closable={false}
            title={`Registering a New Users!`}
            bodyStyle={{
              padding: 30,
              margin: "0px 30px 30px",
            }}
          >
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <LoadingOutlined
                spin
                className="modal-loading"
                color="#2f54eb"
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  fontSize: 125,
                  padding: 30,
                  color: "#1890ff",
                }}
              />

              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >{` Please, Do not close this modal dialog!`}</Typography>
            </Col>
          </Modal>
        </Content>
      </Layout>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaWarehouse } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { SiAirtable } from "react-icons/si";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import {
  MdEmojiFoodBeverage,
  MdLunchDining,
  MdBreakfastDining,
  MdOutlineRestaurant,
} from "react-icons/md";
import { TbReport } from "react-icons/tb";

// Import Page Components
import ReportOutletTable from "../../../Components/Table/Outlet/Report/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function ReportOutletPage() {
  return (
    <Layout>
      <Content>
        <Col span={24} className="report-outlet-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <TbReport
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              Report Outlet
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Col
              style={{
                textAlign: "start",
                margin: "22px 30px",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>Keywords</Typography>

              <Input placeholder="Name" />
            </Col>

            <Col
              style={{
                margin: "54px 0px 0px",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Button
                className="search-btn"
                type="primary"
                icon={<SearchOutlined />}
              >
                {`Search`}
              </Button>
            </Col>
          </Row>

          <ReportOutletTable />
        </Col>
      </Content>
    </Layout>
  );
}

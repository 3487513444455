// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  FileExcelOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";

// Import Master Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedArticleInv,
  failedArticleList,
  masterIncomplete,
  successArticleInv,
  successArticleList,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterArticleMain from "../../../Reusable/Master/ArticleMain/ArticleMain";
import MasterArticleSub from "../../../Reusable/Master/ArticleSub/ArticleSub";
import ModalArticleInventory from "../../../Modals/Article/Inventory/Modal";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function ArticleInventoryTable() {
  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // Article Main-Group Code
  const [dept, setDept] = useState({
    department_name: "",
    department_id: 0,
  });
  const [mainGroup, setMainGroup] = useState({
    article_main_name: "",
    article_main_id: 0,
  });
  const [subGroup, setSubGroup] = useState({
    article_sub_name: "",
    article_sub_id: 0,
  });
  // Search Key
  const [searchKey, setSearchKey] = useState(null);
  // Supplier
  const [supplier, setSupplier] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getArticleList({
      list: "list",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Art Inventory => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return filt.article_type === 2;
          });

          // console.log("Filtered Data Art Inventory => ", filtered);
          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Art Inventory => ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // TABLE COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      // fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12} className="col-edit">
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-guest-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);
                      setDataEdit(record);

                      // handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      textAlign: "center",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-del">
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      // fixed: "left",
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Inventory Name",
      dataIndex: "article_name",
      key: "article_name",
      // fixed: "left",
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: 200,
    },
    {
      title: "Main-Group",
      dataIndex: "article_main_name",
      key: "article_main_name",
      width: 200,
    },
    {
      title: "Sub-Group",
      dataIndex: "article_sub_name",
      key: "article_sub_name",
      width: 200,
    },
    {
      title: "COA",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
      width: 150,
      render: (coa) => {
        return coa === "NULL" ? "  " : coa;
      },
    },
    {
      title: "D-Unit",
      dataIndex: "delivery_unit",
      key: "delivery_unit",
      width: 125,
    },
    {
      title: "Content",
      dataIndex: "delivery_content",
      key: "delivery_content",
      width: 100,
    },
    {
      title: "M-Unit",
      dataIndex: "mess_unit",
      key: "mess_unit",
      width: 125,
    },
    {
      title: "Content",
      dataIndex: "mess_content",
      key: "mess_content",
      width: 100,
    },
    {
      title: "R-Unit",
      dataIndex: "recipe_unit",
      key: "recipe_unit",
      width: 100,
    },
    {
      title: "Min Stock",
      dataIndex: "min_stock",
      key: "min_stock",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.min_stock.localeCompare(b.min_stock),
    },
    // {
    //   title: "Actual Price ( )",
    //   dataIndex: "actual_price",
    //   key: "actual_price",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.actual_price - b.actual_price,
    //   render: (actual_price, record) => {
    //     return `${actual_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   },
    // },
    {
      title: `Average Price ( ${currency} )`,
      dataIndex: "average_price",
      key: "average_price",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.average_price - b.average_price,
      render: (average_price, record) => {
        return `${average_price > 0 ? average_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    // {
    //   title: "Created by",
    //   dataIndex: "created_by",
    //   key: "created_by",
    //   // width: 150,
    // },
    // {
    //   title: "Modified by",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    //   // width: 150,
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // width: 250,
    },
  ];

  // Department
  const getDepartment = (value) => {
    // console.log("Department: ", value);

    setDept({
      department_name: value?.name,
      department_id: value?.dept_id,
    });

    handleFilterDept(value);
  };

  // Article Main
  const getArticleMain = (value) => {
    // console.log("Article Main: ", value);

    setMainGroup({
      article_main_name: value?.name,
      article_main_id: value?.main_id,
    });

    handleFilterMain(value?.main_id);
  };

  // Article Sub
  const getArticleSub = (value) => {
    // console.log(value);

    setSubGroup({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });

    handleFilterSub(value?.sub_id);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setDataEdit(null);

    setDept({
      department_name: "",
      department_id: 0,
    });

    setMainGroup({
      article_main_name: "",
      article_main_id: 0,
    });

    setSubGroup({
      article_sub_name: "",
      article_sub_id: 0,
    });

    form.resetFields();
  };

  // Handle Refresh
  const handleRefresh = () => {
    fetchData();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (e) => {
    const deleteContent = e;

    confirm({
      className: "delete-art-invent-confirm",
      title: `Are you sure want to Delete Article Inventory with named ${
        deleteContent?.article_name.toUpperCase() || " ---- "
      } from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",

      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record?.id;

    // console.log(contentdelete);

    var obj = {
      ["id"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/article/list`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Delete Art Inventory => ", response);

        successArticleList(response, { method: 2, source: 2 });
        fetchData();
      })
      .catch((error) => {
        failedArticleList(error, { method: 2, source: 2 });

        console.log("Delete Art Inventory => ", error);
      });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Article Inventory");
  };

  // Handle Filter Dept
  const handleFilterDept = (value) => {
    const queryDept = value?.dept_id;

    console.log("DEPT: ", queryDept);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("Third Conditions");
        return item.department_id === queryDept;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Filter Main Group
  const handleFilterMain = (value) => {
    const queryMain = value;
    console.log("MAIN LIST: ", queryMain);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("Third Conditions");
        return item.article_main_id === queryMain;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Filter Sub Group
  const handleFilterSub = (value) => {
    const querySub = value;

    console.log("SUB ARTICLE: ", querySub);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("Third Conditions");
        return item.article_sub_id === querySub;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Input
  const handleInput = (val) => {
    val.preventDefault();
    const queryKey = val?.target?.value ? val.target.value.toLowerCase() : null;

    setSearchKey(queryKey);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        const _name = item?.article_name.toLowerCase();

        return _name.indexOf(searchKey) !== -1;
      });

      console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row
        className="article-main-table"
        justify="center"
        style={{
          margin: "30px 0px",
          borderRadius: 5,
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col className="search-col" span={24}>
          <Space
            align="end"
            size={15}
            className="header-space"
            wrap={true}
            style={{
              padding: 30,
            }}
          >
            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
                minWidth: 150,
                marginRight: 15,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>
                {`Search by Department`}
              </Typography>

              <MasterDepartment
                getDepartment={getDepartment}
                department_name={null}
              />
            </Col>

            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
                minWidth: 150,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>
                {`Search by Article Main Group`}
              </Typography>

              <MasterArticleMain
                getArticleMain={getArticleMain}
                getDepartmentId={dept.department_id}
                mainName={null}
                // addArticleState={true}
              />
            </Col>

            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
                minWidth: 150,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>
                {`Search by Article Sub Group`}
              </Typography>

              <MasterArticleSub
                getArticleSub={getArticleSub}
                getArticleMainId={mainGroup.article_main_id}
                getDepartmentId={dept.department_id}
                articleSubName={null}
              />
            </Col>

            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>{`Keywords`}</Typography>

              <Input
                placeholder="Article's Name"
                allowClear
                onChange={handleInput}
                style={{ minWidth: 150 }}
              />
            </Col>

            <Col
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Button
                className="search-btn"
                type="primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
              >
                {`Search`}
              </Button>
            </Col>
          </Space>
        </Col>
      </Row>

      <Row
        className="article-inventory-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Article Inventory`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="art-inventory-table"
              key="art-inventory-table"
              name="art-inventory-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2200,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <ModalArticleInventory
        is_open={open}
        is_close={handleCancel}
        is_refresh={handleRefresh}
        article={edit === true ? dataEdit : null}
      />
    </>
  );
}

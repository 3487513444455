// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import StockItemTransformForm from "../../../../../../Forms/StockItemTransform/Form";

export default function StockItemTransformReportTable(props) {
  // PROPS
  const { _startDate, _endDate } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  //DUMMY DATA
  const articles = [
    { date: '2024-06-01', from_storage: 'Warehouse A', article_number: '10001', article_name: 'Widget', unit: 'pcs', content: 'A standard widget', average_price: (Math.random() * 100000), quantity: 100, amount: 1050, document_number: 'INV-001' },
    { date: '2024-06-02', from_storage: 'Warehouse B', article_number: '10002', article_name: 'Gadget', unit: 'pcs', content: 'A high-tech gadget', average_price: Math.random() * 100000, quantity: 50, amount: 1287.5, document_number: 'INV-002' },
    { date: '2024-06-03', from_storage: 'Warehouse C', article_number: '10003', article_name: 'Gizmo', unit: 'pcs', content: 'A multi-purpose gizmo', average_price: Math.random() * 100000, quantity: 75, amount: 1147.5, document_number: 'INV-003' },
    { date: '2024-06-04', from_storage: 'Warehouse D', article_number: '10004', article_name: 'Doodad', unit: 'pcs', content: 'A useful doodad', average_price: Math.random() * 100000, quantity: 200, amount: 1000, document_number: 'INV-004' },
    { date: '2024-06-05', from_storage: 'Warehouse E', article_number: '10005', article_name: 'Thingamajig', unit: 'pcs', content: 'An essential thingamajig', average_price: Math.random() * 100000, quantity: 120, amount: 900, document_number: 'INV-005' },
    { date: '2024-06-06', from_storage: 'Warehouse F', article_number: '10006', article_name: 'Contraption', unit: 'pcs', content: 'A complex contraption', average_price: Math.random() * 100000, quantity: 60, amount: 1200, document_number: 'INV-006' },
    { date: '2024-06-07', from_storage: 'Warehouse G', article_number: '10007', article_name: 'Apparatus', unit: 'pcs', content: 'A versatile apparatus', average_price: Math.random() * 100000, quantity: 40, amount: 1200, document_number: 'INV-007' },
    { date: '2024-06-08', from_storage: 'Warehouse H', article_number: '10008', article_name: 'Device', unit: 'pcs', content: 'A handy device', average_price: Math.random() * 100000, quantity: 80, amount: 1000, document_number: 'INV-008' },
    { date: '2024-06-09', from_storage: 'Warehouse I', article_number: '10009', article_name: 'Instrument', unit: 'pcs', content: 'A precise instrument', average_price: Math.random() * 100000, quantity: 55, amount: 1210, document_number: 'INV-009' },
    { date: '2024-06-10', from_storage: 'Warehouse J', article_number: '10010', article_name: 'Machine', unit: 'pcs', content: 'A powerful machine', average_price: Math.random() * 100000, quantity: 25, amount: 1250, document_number: 'INV-010' }
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    quantity: 0,
    amount: 0
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (_startDate && _endDate && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setTotal({
        quantity: 0,
        amount: 0
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_startDate, _endDate]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    let bodyReq = {};

    bodyReq = {
      from_date: moment(_startDate).format("YYYY-MM-DD"),
      to_date: moment(_endDate).format("YYYY-MM-DD")
    };

    await getByPostInventoryReport({
      type: `transfer-summary`,
      bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        // if (response?.data?.msg?.length > 0) {
        if (articles.length > 0) {
          // const _data = response.data.msg;
          const _data = articles;

          const filter = _data.map((item) => {
            return {
              ...item,
            };
          });

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      quantity: prev.quantity + curr.quantity,
                      amount: prev.amount + curr.amount,
                    };
                  },
                  { quantity: 0, amount: 0 }
                )
              : { quantity: 0, amount: 0 };

          setData(filter);
          setTotal(sumTotal);
        } else {
          setData([]);
          setTotal({
            quantity: 0,
            amount: 0
          });
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Stock Item Transform Reports ${
        _startDate ? moment(_startDate).format("YYYY-MM-DD") : ""} - ${
          _endDate ? moment(_endDate).format("YYYY-MM-DD") : ""}`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => {
        return text ? moment(text).format("YYYY-MM-DD") : "-";
      }
    },
    {
      title: "From Storage",
      dataIndex: "from_storage",
      key: "from_storage",
    },
    {
      title: "DocNumber",
      dataIndex: "document_number",
      key: "document_number",
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Average Price",
      dataIndex: "average_price",
      key: "average_price",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ].filter(item => !item.hidden);

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={7} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.quantity ? total.quantity : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.amount ? total.amount.toFixed(0) : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <StockItemTransformForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _startDate = {_startDate}
        _endDate = {_endDate}
        _total = {total}
      />
    </>
  );
}

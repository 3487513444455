// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Divider, Radio, Table, Space, Button, Col, Row } from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import {
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import MicePopover from "../../../Popover/MICE/Popover";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// CODE
export default function HistoryMICETable(props) {
  // PROPS
  const { searchKey, is_search } = props;

  // PARAMS
  const currency = CurrencySymbols().code;

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "",
      fixed: "left",
      width: 75,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-non-stay-table-btn"
              style={{ width: "100%" }}
            >
              {/* <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(!open);
                      setEdit(!edit);
                      setDataEdit(record);
                    }}
                    style={{ fontSize: 20, color: "#1BC5BD" }}
                  />
                </Tooltip>
              </Col> */}

              <Col span={24}>
                <MicePopover
                  // onClick={() => {
                  //   return record;
                  // }}
                  is_history={true}
                  details={record}
                  is_refresh={setRefresh}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation Number",
      dataIndex: "reservation_id",
      key: "reservation_id",
      fixed: "left",
      width: 250,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      fixed: "left",
      width: 250,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    },
    {
      title: "Event Start",
      dataIndex: "event_start",
      key: "event_start",
      render: (date, record) => {
        return moment(date).format("DD MMM YYYY");
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.arrival.localeCompare(b.arrival),
    },
    {
      title: "Event End",
      dataIndex: "event_end",
      key: "event_end",
      render: (date, record) => {
        return moment(date).format("DD MMM YYYY");
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.arrival.localeCompare(b.arrival),
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
    },
    {
      title: `Deposit ( ${currency} )`,
      dataIndex: "deposit",
      key: "deposit",
      render: (deposit, record) => {
        return `${deposit > 0 ? deposit : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // GET DATE
  const tdy = new Date(Date.now());
  const today = moment(tdy).format("YYYY-MM-DD");

  // CONTEXT
  const { getNonStay, getReservationMice } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Refresh State
  const [refresh, setRefresh] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE NAVIGATIONS
  const navigate = useNavigate();

  // Cancel
  const move = async () => {
    navigate(`/f-and-b/mice`);
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (refresh && !cleanUp) {
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Fetch Data
  const fetchData = async () => {
    await getReservationMice({
      mice: "mice",
      type: "reservation",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Non Stay Response History = ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            const arrival = moment(val?.event_end).format("YYYY-MM-DD");

            // console.log(arrival);
            // console.log("TODAY: ", today);
            // console.log("TODAY: ", moment(arrival).diff(today, "days"));

            if (moment(arrival).isBefore(today)) {
              return val;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.guest_name.toLowerCase();
        // console.log("ITEM => ", item)

        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="history-mice-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row justify="end" className="row-refresh-btn">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
              style={{
                margin: "0px 30px 0px",
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              icon={
                <IoReturnUpBackOutline
                  className="return-icons"
                  style={{
                    margin: "0px 5px 0px 0px",
                  }}
                />
              }
              onClick={move}
            >
              {`Return`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="history-mice-table"
              name="history-mice-table"
              key="history-mice-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Table, Tag, Typography } from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

export default function StockArticleReturnTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getInventoryReport } = useContext(MainContext);
  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  // Date
  const [datePicked, setDatePicked] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();

  // GET DATA FUNCTION
  const fetchData = async () => {
    setLoading(true);

    await getInventoryReport({
      type: "journal-incoming-stock",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("Response >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          setData(_data);
          setBulks(_data);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT Fetching Data
  useEffect(() => {
    fetchData();

    if (refresh == true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(
      data,
      `Stock Article Return List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // USEEFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // HANDLE FILTER DATE
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const _reqDate = moment(val.request_date).format("YYYY-MM-DD");

      return moment(_reqDate).isBetween(_start, _end, undefined, "[]");
    });

    console.log("Request Date Filtered: ", _filtered);
    setData(_filtered);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey;
    console.log("Searching = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        return item.pr_number.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/incoming-stock";

    navigate(`${path}`);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount (Rp.)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Delivery Note",
      dataIndex: "delivery_note",
      key: "delivery_note",
    },
  ];

  return (
    <>
      <Row className="inventory-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }} className="col-table">
          <Row justify="end" className="row-btn">
            <Col span={12} className="col-export">
              <Typography
                style={{ marginBottom: 10 }}
              >{`Pick Date`}</Typography>

              <DatePicker.RangePicker
                className="date-btn"
                allowClear
                onChange={handleDate}
                style={{
                  width: 300,
                }}
              />
            </Col>

            <Col span={12} className="col-table-btn">
              <Row justify="end" className="row-table-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<FileExcelFilled />}
                  style={{
                    fontWeight: 500,
                    margin: "0px 15px",
                  }}
                >
                  {`Export to Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table-main-row" style={{ width: "100%" }}>
            <Table
              className="table-inventory"
              name="table-inventory"
              key="table-inventory"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              size="large"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              scroll={{
                x: 1300,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

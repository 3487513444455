// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  DatePicker,
  Tooltip,
  Checkbox,
} from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Master Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master
import MasterBoolean from "../../../Reusable/Master/Boolean/Boolean";
import MasterCOA from "../../../Reusable/Accounting/MainAccount/MainAccount";
import MasterBedType from "../../../Reusable/Master/BedType/BedType";
import MasterRoomCat from "../../../Reusable/Master/RoomCat/RoomCat";

// Import Functions
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm, warning } = Modal;

// CODE
export default function RoomTable(props) {
  // PROPS
  const { is_search, searchKey, searchCat } = props;

  // CONTEXT
  const { getMasterRoom, getMasterRoomCat } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [editData, setEditData] = useState(null);
  // CATEGORY
  const [category, setCategory] = useState({
    name: "",
    room_code: "",
    id: 0,
  });
  // CheckBox
  const [isTax, setIsTax] = useState(false);
  const [isService, setIsService] = useState(false);
  // Option Value
  const [roomCount, setRoomCount] = useState([]);
  const [optionValue, setOptionValue] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [isDelete, setIsDelete] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [isServiceTax, setIsServiceTax] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterRoom({
      main: "main",
      onAwait: () => {
        setLoading(true);
        // "on Await";
      },
      onSuccess: (response) => {
        console.log("ISI Room List >> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            return !val?.is_deleted;
          });

          // let sortir = filtered.sort((a, b) => a.room_number - b.room_number);
          // console.log(filtered);

          setData(filtered);
          setBulks(filtered);
          handleOption(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        // setFilteredList(filtered);
        // console.log("ISI Room List >> ", filtered);
        setLoading(false);
      },
      onReject: (error) => {
        failedFetch(error);
        setLoading(false);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // FETCH ROOM CAT
  const fetchRoomCat = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        // FILTER IS DELETED
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            return !val?.is_deleted;
          });

          // CREATE OPTION
          let option = filtered.map((dev) => ({
            value: dev?.room_category_name,
            label: dev?.room_category_name,
            room_code: dev?.room_category_code,
            count: dev?.number_of_rooms > 0 ? dev.number_of_rooms : 0,
            id: dev?.id,
          }));

          console.log("Option Values: ", option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
    fetchRoomCat();
  }, []);

  // USE EFFECT Data
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (roomCount.length > 0 && roomCat.length > 0 && !cleanUp) {
  //     handleOptionValue();
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [roomCount, roomCat]);

  // Use Effect Search
  useEffect(() => {
    let cleanUp = false;

    is_search(() => handleSearch);

    if (searchCat > 0 && !cleanUp) {
      handleFilter();
    }

    return () => {
      cleanUp = true;
    };
  }, [searchKey, searchCat]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      // fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="room-table-btn" style={{ width: "100%" }}>
              <Col span={12} className="col-edit-btn">
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      // setOpen(true);
                      setEdit(true);
                      setEditData(record);

                      setIsTax(record.is_tax);
                      setIsService(record.is_service);
                      setIsServiceTax(record.is_service_taxed);

                      handleOpen();

                      handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-del-btn">
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Room ID",
      dataIndex: "room_id",
      key: "room_id",
      // fixed: true,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_id - b.room_id,
    },
    {
      title: "Room Name",
      dataIndex: "room_name",
      key: "room_name",
      // fixed: true,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_name.localeCompare(b.room_name),
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      // fixed: true,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      width: 100,
    },
    {
      title: "Children",
      dataIndex: "child",
      key: "child",
      width: 100,
    },
    {
      title: "Infant",
      dataIndex: "infant",
      key: "infant",
      width: 100,
    },
    {
      title: "Bed Type",
      dataIndex: "room_bedsetup",
      key: "room_bedsetup",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.bed_type - b.bed_type,
    },
    {
      title: "Room Overlook",
      dataIndex: "room_overlook",
      key: "room_overlook",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_status - b.room_status,
    },
    {
      title: "Smoking",
      dataIndex: "smoking",
      key: "smoking",
      width: 150,
      render: (smoke, record) => {
        // console.log(JSON.stringify(record.smoking));\

        return record.smoking == 1
          ? "Smoking"
          : record.smoking == 0
          ? "Non-Smoking"
          : "";
      },
    },
    {
      title: "Connecting Room",
      dataIndex: "room_connecting",
      key: "room_connecting",
      width: 150,
      render: (connect, record) => {
        // console.log("Room Connecting = ", record.room_connecting);

        return record.room_connecting == 1
          ? "Yes"
          : record.room_connecting == 0
          ? "No"
          : "";
      },
    },
  ];

  // Get Room Category Code
  const onChangeRoomCat = (value, e) => {
    let _totals = 0;
    const _allKeys = e;

    console.log(category);
    // console.log("Room: ", value);
    console.log("Room Options Value: ", _allKeys);
    // console.log("Room Counter: ", roomCount);

    setCategory({
      name: value,
      room_code: _allKeys?.room_code,
      id: _allKeys?.id,
    });

    for (const key in roomCount) {
      // console.log("Key: ", key, " = ", _allKeys?.id);
      // console.log("IFS: ", roomCount[key]);

      if (key == _allKeys?.id) {
        _totals = roomCount[key]?.length;
      }
    }

    // console.log("Total Rooms: ", _totals);

    form.setFieldsValue({
      room_category_name: value,
      room_category_code: _allKeys?.room_code,
      max_of_rooms: _allKeys?.count,
      number_of_rooms: _totals,
    });

    form.resetFields(["room_name"]);
  };

  // Handle Clear
  const handleClear = () => {
    setCategory({
      room_code: "",
      name: "",
      id: 0,
    });

    form.resetFields(["room_name"]);
  };

  const getRoomCode = (value, code) => {
    // console.log(value);
    // console.log(code);

    if (edit) {
      editData.room_category_code = code;
    }

    form.setFieldsValue({
      room_category_code: code,
    });
  };

  // Bed Type
  const getBedType = (value, code) => {
    console.log(value, code);

    form.setFieldsValue({
      room_bedsetup: value,
    });
  };

  // COA Main Account
  const getCoaName = (value) => {
    console.log(value);

    form.setFieldsValue({
      chart_of_account: value?.code,
    });
  };

  // Smooking Room
  const getSmoking = (value) => {
    form.setFieldsValue({
      smoking: value,
    });
  };

  // Connecting Room
  const getConnect = (value) => {
    form.setFieldsValue({
      room_connecting: value,
    });
  };

  // Checked Tax & Service
  const onCheckTax = (value) => {
    // console.log(value.target.checked);

    setIsTax(!isTax);

    if (edit == true) {
      editData.tax = 0;
      editData.is_tax = value.target.checked;
    }

    form.setFieldsValue({
      is_tax: value.target.checked,
      tax: 0,
    });
  };

  const onCheckService = (value) => {
    // console.log(value.target.checked);

    setIsService(!isService);

    if (edit == true) {
      editData.service = 0;
      editData.is_service = value.target.checked;
    }

    form.setFieldsValue({
      is_service: value.target.checked,
      service: 0,
    });
  };

  // Handle Form Fields
  const handleFormField = (value) => {
    const record = value;

    setCategory({
      name: optionValue.find((e) => e.id == record.room_category_id)?.value,
      id: record?.room_category_id,
      room_code: optionValue.find((e) => e.id == record.room_category_id)
        ?.room_code,
    });

    form.setFieldsValue({
      room_id: record?.room_id,
      room_number: record?.room_number,
      room_name: record?.room_name,
      room_category_name: optionValue.find(
        (e) => e.id == record.room_category_id
      )?.value,
      room_category_code: optionValue.find(
        (e) => e.id == record.room_category_id
      )?.room_code,
      room_floor: record?.room_floor,
      room_overlook: record?.room_overlook,
      room_bedsetup: record?.room_bedsetup,
      smoking: record?.smoking,
      room_connecting: record?.room_connecting,
      adult: record?.adult || 0,
      infant: record?.infant || 0,
      child: record?.child || 0,
      created_by: record.created_by,
      created_date: record.created_date,
      updated_by: record.updated_by,
      updated_date: record.updated_date,
      chart_of_account: record.chart_of_account,

      is_tax: record.is_tax,
      is_service: record.is_service,
      is_service_taxed: record.is_service_taxed,

      // record.is_tax == true
      // ? setIsTax(true)
      // : setIsTax(false),
      // record.is_service == true
      // ? setIsService(true)
      // : setIsService(false),

      tax: record?.tax,
      service: record?.service,
    });
  };

  // Handle Options
  const handleOption = (val) => {
    const _arr = val;

    let groupBy = Object.groupBy(_arr, (e) => e.room_category_id);

    console.log("GROUP: ", groupBy);
    setRoomCount(groupBy);
  };

  // Handle Option Value
  const handleOptionValue = () => {
    // const _arr = val;
    // let groupBy = Object.groupBy(_arr, (e) => e.room_category_code);
    console.log("GROUP and COUNT: ");
    // setRoomCount(groupBy);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setIsDelete(true);

    setOpen(false);
    setEdit(false);
    setEditData(null);

    setIsLoading(false);

    setIsServiceTax(false);
    setIsTax(false);
    setIsService(false);

    form.resetFields();
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    let content = val;

    console.log(content);

    confirm({
      className: "modal-room-confirm",
      title: isDelete
        ? `Are you sure want to delete Room ${content?.room_id.toUpperCase()} from the table?`
        : `Are you sure want to ${edit ? "Update" : "Add a New"} Room ${
            content?.room_name
          }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(content) : handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    // console.log(e);
    setIsLoading(true);

    // handleSubmit(e);
    showModalConfirm(e);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log("Failed: ", e.values);

    masterIncomplete(e);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    const contentSubmit = e;
    console.log("ON SUBMIT ==> ", contentSubmit);

    let editTax = 0;
    let editSer = 0;

    if (isTax == true) {
      if (contentSubmit?.tax != null) {
        editTax = contentSubmit.tax;
      } else {
        editTax = editData?.tax ? editData.tax : 0;
      }
    } else {
      editTax = 0;
    }

    if (isService == true) {
      if (contentSubmit?.service != null) {
        editSer = contentSubmit.service;
      } else {
        editSer = editData?.service ? editData.service : 0;
      }
    } else {
      editSer = 0;
    }

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/room/main`,
          {
            room_id:
              contentSubmit?.room_category_code + contentSubmit?.room_number ||
              null,
            room_number: contentSubmit?.room_number
              ? contentSubmit.room_number
              : 0,
            // room_category_code: contentSubmit?.room_category_code
            //   ? contentSubmit.room_category_code
            //   : null,
            // room_category_name: contentSubmit?.room_category_name
            //   ? contentSubmit.room_category_name
            //   : null,
            room_category_id: category.id,
            room_floor: contentSubmit?.room_floor
              ? contentSubmit.room_floor
              : 0,
            room_overlook: contentSubmit?.room_overlook
              ? contentSubmit.room_overlook
              : null,
            room_bedsetup: contentSubmit?.room_bedsetup
              ? contentSubmit.room_bedsetup
              : null,
            room_name: contentSubmit?.room_name
              ? contentSubmit.room_name
              : null,
            smoking: contentSubmit?.smoking ? contentSubmit.smoking : 0,
            room_connecting:
              contentSubmit?.room_connecting > 0
                ? contentSubmit.room_connecting
                : 0,
            adult: contentSubmit.adult > 0 ? contentSubmit.adult : 0,
            infant: contentSubmit.infant > 0 ? contentSubmit.infant : 0,
            child: contentSubmit.child > 0 ? contentSubmit.child : 0,

            chart_of_account: contentSubmit.chart_of_account
              ? contentSubmit.chart_of_account
              : null,

            tax: contentSubmit?.tax > 0 ? contentSubmit.tax : 0,
            is_tax: contentSubmit?.tax > 0 ? true : false,
            service: contentSubmit?.service > 0 ? contentSubmit.service : 0,
            is_service: contentSubmit?.service > 0 ? true : false,
            // is_service_taxed: contentSubmit?.service > 0 && contentSubmit?.tax > 0 && isServiceTax == true ? true : false, 

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Add Room => ", response);
          masterSuccessNotification(response, { method: 0, source: "Room" });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("Add Room => ", error);

          masterFailedNotification(error, { method: 0, source: "Room" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/room/main`,
          {
            id: editData.id,
            room_id: contentSubmit
              ? contentSubmit.room_category_code + contentSubmit.room_number
              : editData.room_id,
            room_number: contentSubmit?.room_number
              ? contentSubmit.room_number
              : editData.room_number,
            room_category_id: category.id,
            // room_category_code: contentSubmit?.room_category_code
            //   ? contentSubmit.room_category_code
            //   : editData.room_category_code,
            // room_category_name: contentSubmit?.room_category_name
            //   ? contentSubmit.room_category_name
            //   : "",
            room_floor: contentSubmit?.room_floor
              ? contentSubmit.room_floor
              : editData.room_floor,
            room_overlook: contentSubmit?.room_overlook
              ? contentSubmit.room_overlook
              : editData.room_overlook,
            room_bedsetup: contentSubmit?.room_bedsetup
              ? contentSubmit.room_bedsetup
              : editData.room_bedsetup,
            room_name: contentSubmit?.room_name
              ? contentSubmit.room_name
              : editData.room_name,
            adult: contentSubmit?.adult ? contentSubmit.adult : editData.adult,
            infant: contentSubmit?.infant
              ? contentSubmit.infant
              : editData.infant,
            child: contentSubmit?.child ? contentSubmit.child : editData.child,

            smoking: contentSubmit?.smoking
              ? contentSubmit.smoking
              : editData?.smoking || 0,
            room_connecting: contentSubmit?.room_connecting
              ? contentSubmit.room_connecting
              : editData?.room_connecting || 0,

            chart_of_account: contentSubmit?.chart_of_account
              ? contentSubmit.chart_of_account
              : editData.chart_of_account,

            tax: contentSubmit?.tax > 0 ? contentSubmit.tax : 0,
            is_tax: contentSubmit?.tax > 0 ? true : false,
            service: contentSubmit?.service > 0 ? contentSubmit.service : 0,
            is_service: contentSubmit?.service > 0 ? true : false,
            is_service_taxed: contentSubmit?.service > 0 && contentSubmit?.tax > 0 && isServiceTax == true ? true : false,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Update Room => ", response);
          masterSuccessNotification(response, { method: 1, source: "Room" });

          fetchData();
          handleCancel();
        })
        .catch((error) => {
          console.log("Update Room => ", error);

          masterFailedNotification(error, { method: 1, source: "Room" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentDelete = record;
    setLoading(true);

    console.log("content delete >>>>>>", contentDelete);

    let obj = {
      ["room_number"]: contentDelete?.room_number,
      ["deleted_by"]: user_name,
      ["room_category_id"]: contentDelete?.room_category_id,
    };

    await axios
      .delete(`${baseurl}/room/main`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Room => ", response);
        masterSuccessNotification(response, { method: 2, source: "Room" });

        fetchData();
      })
      .catch((error) => {
        console.log("DEL Room => ", error);
        masterFailedNotification(error, { method: 2, source: "Room" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Room List");
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH by Category ID
  const handleFilter = () => {
    const query = searchCat;

    if (bulks.length > 0) {
      const updatedList = bulks.filter((item) => {
        const _category =
          item?.room_category_id > 0 ? item.room_category_id : 0;

        return _category == query;
      });
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.room_name.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };
  
  // Checked Service Tax
  const onCheckServiceTax = (value) => {
    const _bool = value.target.checked;

    setIsServiceTax(_bool);

    form.setFieldsValue({
      is_service_taxed: _bool,
    });

  };

  // onChange Service
  const onChangeService = (value) => {
    if(value > 0) {
      setIsService(true);
    } else {
      setIsService(false);
    }
  };

  // onChange Tax
  const onChangeTax = (value) => {
    if(value > 0) {
      setIsTax(true)
    } else {
      setIsTax(false)
    }
  };

  return (
    <>
      <Row className="room-main-table" justify="center" style={mainBody}>
        <Col span={24} className="col-table" style={{ padding: "30px" }}>
          <Row className="row-table-btn" align="bottom" gutter={[0, 15]}>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
              className="col-exp-btn"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
              className="col-modal-btn"
            >
              <Row
                justify="end"
                className="row-modal-btn"
                align="top"
                gutter={[0, 15]}
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Room`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => {
                    fetchData();
                    fetchRoomCat();
                  }}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="room-table"
              name="room-table"
              key="room-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-room-modal"
        title={
          edit == false ? (
            <Row className="row-modal-title">
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>{`Add Room`}</Typography>
            </Row>
          ) : (
            <Row className="row-modal-title">
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>{`Edit Room`}</Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="room-modal-form"
          className="room-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-room-cat" justify="start">
            <Col span={16} className="col-room-cat">
              <Form.Item
                label="Room Category"
                name="room_category_name"
                rules={[
                  {
                    required: true,
                    message: "Please, select Room Category!",
                  },
                  // {
                  //   validator: async (_) => {
                  //     let _max = form.getFieldValue("max_of_rooms");
                  //     let _ttl = form.getFieldValue("number_of_rooms");

                  //     // console.log("UNDERSCORE = ", _);
                  //     // console.log("FIELDS VALUE = ", _max, _ttl);

                  //     if (_ttl >= _max)
                  //       return Promise.reject(
                  //         `You can not add more Room Category because it's already at Maximum!`
                  //       );
                  //   },
                  // },
                ]}
                style={{ marginRight: 30, width: 440 }}
              >
                <Select
                  className="category-select"
                  key="cat-sel"
                  allowClear
                  showSearch
                  placeholder="Select a Room Category!"
                  value={category.name || null}
                  onChange={onChangeRoomCat}
                  onClear={handleClear}
                  options={optionValue}
                />
              </Form.Item>

              <Form.Item label="Room Category" name="room_category_code" hidden>
                <Input />
              </Form.Item>
            </Col>

            <Col span={8} className="total-col">
              <Form.Item label="Total Room" name="number_of_rooms">
                <InputNumber
                  min={0}
                  placeholder="0"
                  disabled
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* 
            <Col span={4} className="max-col">
              <Form.Item label="Max. Room" name="max_of_rooms">
                <InputNumber min={0} placeholder="0" disabled />
              </Form.Item>
            </Col> */}
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-room-id">
            <Col span={8} className="col-room-id">
              <Form.Item
                label="Room Code"
                name="room_id"
                // hidden
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, input Room Code!",
                //   },
                // ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Room Code" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-name">
              <Form.Item
                label="Room Name"
                name="room_name"
                rules={[
                  {
                    required: true,
                    message: "Please, input Room Name!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Room Name" />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-no">
              <Form.Item
                label="Room Number"
                name="room_number"
                rules={[
                  {
                    required: true,
                    message: "Please, input Room Number!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Room Number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-room-bed">
            <Col span={8} className="col-bed-setup">
              <Form.Item
                label="Bed Type"
                name="room_bedsetup"
                rules={[
                  {
                    required: true,
                    message: "Please, input Room Bed Type!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                {/* <Input placeholder="Room Bed Type/Setup" /> */}
                <MasterBedType
                  bed_name={edit == true ? editData.room_bedsetup : ""}
                  getBedType={getBedType}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-floor">
              <Form.Item
                label="Room Floor"
                name="room_floor"
                rules={[
                  {
                    required: true,
                    message: "Please, input Room Floor!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Room Floor"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-view">
              <Form.Item
                label="Room Overview"
                name="room_overlook"
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Room Overview" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-room-smoke">
            <Col span={8} className="col-connect">
              <Form.Item
                label="Room Connecting"
                name="room_connecting"
                style={{ marginRight: 30, width: "100%" }}
              >
                <MasterBoolean
                  getBoolValue={getConnect}
                  bool={edit == true ? editData.room_connecting : ""}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="col-smoke">
              <Form.Item
                label="Smoking Room"
                name="smoking"
                style={{ width: "100%" }}
              >
                <MasterBoolean
                  getBoolValue={getSmoking}
                  bool={edit == true ? editData.smoking : ""}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-room-capacity">
            <Col span={4} className="col-adult">
              <Form.Item
                label="Adult"
                name="adult"
                rules={[
                  {
                    required: true,
                    message: "Please, input Adult Capacity!",
                  },
                ]}
                style={{ marginRight: 30, width: 90 }}
              >
                <InputNumber min={1} max={10} placeholder="Adult" />
              </Form.Item>
            </Col>

            <Col span={4} className="col-child">
              <Form.Item
                label="Children"
                name="child"
                style={{ marginRight: 30, width: 90 }}
              >
                <InputNumber min={1} max={10} placeholder="Children" />
              </Form.Item>
            </Col>

            <Col span={8} className="col-infant">
              <Form.Item label="Infant" name="infant" style={{ width: 90 }}>
                <InputNumber min={1} max={10} placeholder="Infant" />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row className="row-coa">
            <Col className="col-coa" span={16}>
              <Form.Item
                label="COA (Chart of Accounts)"
                name="chart_of_account"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, select Article Inventory COA!",
                //   },
                // ]}
                style={{ width: "100%" }}
              >
                <MasterCOA
                  getCoaName={getCoaName}
                  // getCoaNo={getCoaNo}
                  coa_name={edit ? editData.chart_of_account : null}
                  // is_fromRoom={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row
            className="row-tax-service"
            gutter={30}
            style={{ width: "100%" }}
          >
            <Col span={8} className="col-tax">
              {/* <Row className="row-is-tax">
                <Form.Item
                  name="is_tax"
                  valuePropName="checked"
                  style={{ fontSize: 15 }}
                >
                  <Checkbox
                    // checked={isTax}
                    onChange={onCheckTax}
                    className="tax-checkbox"
                  >
                    Include Tax
                  </Checkbox>
                </Form.Item>
              </Row> */}

              <Row className="row-tax">
                <Form.Item
                  label="Tax"
                  name="tax"
                  rules={
                    // isTax == true?
                    [
                      {
                        required: true,
                        message: "Please, input Tax Percentages!",
                      },
                    ]
                    // : [
                    //     {
                    //       required: false,
                    //     },
                    //   ]
                  }
                  style={{ margin: "5px 0px" }}
                >
                  <InputNumber
                    // disabled={isTax == true ? false : true}
                    placeholder="Tax"
                    onChange={onChangeTax}
                    addonAfter="%"
                    min={0}
                    max={100}
                  />
                </Form.Item>
              </Row>
            </Col>

            <Col span={8} className="col-service">
              {/* <Row className="row-is-service">
                <Form.Item
                  name="is_service"
                  valuePropName="checked"
                  style={{ fontSize: 15 }}
                >
                  <Checkbox
                    // checked={isService}
                    onChange={onCheckService}
                    className="service"
                  >
                    Include Service
                  </Checkbox>
                </Form.Item>
              </Row> */}

              <Row className="row-service">
                <Form.Item
                  label="Service"
                  name="service"
                  rules={
                    // isService == true ?
                    [
                      {
                        required: true,
                        message: "Please, input Service Percentages!",
                      },
                    ]
                    // : [{ required: false }]
                  }
                  style={{ margin: "5px 0px" }}
                >
                  <InputNumber
                    // disabled={isService == true ? false : true}
                    placeholder="Service"
                    onChange={onChangeService}
                    addonAfter="%"
                    min={0}
                    max={100}
                  />
                </Form.Item>
              </Row>
            </Col>
            {edit == true ? (<Col span={8} className="col-service">
              <Row className="row-service">
                <Form.Item
                  name="is_service_taxed"
                  valuePropName="checked"
                  style={{ fontSize: 15, marginTop: "35px" }}
                >
                  <Checkbox
                    onChange={onCheckServiceTax}
                    className="service-tax-checkbox"
                    disabled={isService && isTax ? false : true}
                  >
                    {`Include Service Tax`}
                  </Checkbox>
                </Form.Item>
              </Row>
            </Col>) : null}
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created_date-room">
                <Col span={8} className="col-created-by">
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created By" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-created-date">
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-updated-by">
                  <Form.Item
                    label="Modified By"
                    name="updated_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified By" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-room">
                <Col span={8} className="col-updated-date">
                  <Form.Item
                    label="Modified Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 15px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              loading={isLoading}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

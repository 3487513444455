// CODE
export function Capitalization(props) {
  const { names } = props;

  //   const _capital = names[0].toUpperCase() + names.slice(1);
  // const _capital = names.replace(/\b(\w)/g, (s) => s.toUpperCase());
  const _capital = names
    .toLowerCase()
    .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());

  console.log("PROPS CAPITALIZATIONS: ", names);
  console.log("Results: ", _capital);

  return _capital;
}

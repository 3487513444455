// Import Reacts Components
import moment from "moment";

// Import ANTD Icons
import {
  MdAddModerator,
  MdAutoDelete,
  MdDeleteForever,
  MdFiberNew,
  MdLocalFireDepartment,
} from "react-icons/md";
import { TbReplace } from "react-icons/tb";

// Functions
import { LocalizationDate } from "../Localizations/Localization";
import { useEffect } from "react";

// CODE
export const VersionControl = (props) => {
  // PROPS
  const { version, array } = props;

  let data = [];

  //   const Restructure = () => {
  const result = Object.values(
    array?.reduce((acc, el, idx) => {
      let arr_x = [];

      acc[el?.id_version] = acc[el?.id_version] || {
        title:
          el?.version +
          ` => [${moment(LocalizationDate(el.created_date)).format(
            "DD-MM-YYYY"
          )}]`,
        key: el?.id_version,
        children: [],
      };

      //   console.log(acc[el?.id_version].children);

      if (
        !acc[el?.id_version].children.some((e) => e?.title == el?.action + ":")
      ) {
        acc[el?.id_version].children.push({
          title: el?.action + ":",
          key: el?.id_action + el?.id_version,
          children: [
            {
              title: el?.description,
              key: el?.id_changelog + idx,
              icon: {
                Added: (
                  <MdFiberNew style={{ fontSize: 20, color: "#1890ff" }} />
                ),
                Fixed: (
                  <MdLocalFireDepartment
                    style={{ fontSize: 20, color: "#fa8c16" }}
                  />
                ),
                Changed: (
                  <TbReplace style={{ fontSize: 20, color: "#000000" }} />
                ),
                Deprecated: (
                  <MdAutoDelete style={{ fontSize: 20, color: "#ff4d4f" }} />
                ),
                Removed: (
                  <MdDeleteForever style={{ fontSize: 20, color: "#ff4d4f" }} />
                ),
                Security: (
                  <MdAddModerator style={{ fontSize: 20, color: "#87d068" }} />
                ),
              }[el?.action] ?? (
                <MdLocalFireDepartment
                  style={{ fontSize: 20, color: "#fa8c16" }}
                />
              ),
            },
          ],
        });
      } else {
        let ix = acc[el?.id_version].children.findIndex(
          (x) => x.key == el?.id_action + el?.id_version
        );

        acc[el?.id_version].children[ix == -1 ? 0 : ix].children.push({
          title: el?.description,
          key: el?.id_changelog + idx,
          icon: {
            Added: <MdFiberNew style={{ fontSize: 20, color: "#1890ff" }} />,
            Fixed: (
              <MdLocalFireDepartment
                style={{ fontSize: 20, color: "#fa8c16" }}
              />
            ),
            Changed: <TbReplace style={{ fontSize: 20, color: "#000000" }} />,
            Deprecated: (
              <MdAutoDelete style={{ fontSize: 20, color: "#ff4d4f" }} />
            ),
            Removed: (
              <MdDeleteForever style={{ fontSize: 20, color: "#ff4d4f" }} />
            ),
            Security: (
              <MdAddModerator style={{ fontSize: 20, color: "#87d068" }} />
            ),
          }[el?.action] ?? (
            <MdLocalFireDepartment style={{ fontSize: 20, color: "#fa8c16" }} />
          ),
        });
      }
      return acc;
    }, {})
  )
    .sort()
    .reverse();

  result.some(
    (item, idx) =>
      item.key == "verUn" &&
      result.unshift(
        // remove the found item, in-place (by index with splice),
        // returns an array of a single item removed
        result.splice(idx, 1)[0]
      )
  );

  //     return result;
  //   };

  //   const data = Restructure();
  //   useEffect(() => {
  //     let cleanUp = false;

  //     if (array.length > 0 && !cleanUp) {
  //       data = Restructure();
  //     }

  //     return () => {
  //       cleanUp = true;
  //     };
  //   }, [array]);

  return result;
};

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Input,
  Select,
  Modal,
  Typography,
  Tooltip,
  InputNumber,
} from "antd";
import { FormOutlined, MenuOutlined } from "@ant-design/icons";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import {
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import ReservationDepositModalTable from "../../../Modals/ReservationDeposit/Modal";
import ReservationId from "../../../Reusable/Reservation/Stay/ReservationID";
import MasterPaymentCard from "../../../Reusable/Master/PaymentCard/PaymentCard";
import MasterPaymentType from "../../../Reusable/Master/PaymentType/PaymentType";
import ParametersFrontOffice from "../../../Reusable/Parameters/ParamsFO";
// import ParametersFrontOffice from "../../../Reusable/Parameters/ParamsFO";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import MasterPaymentCardEdit from "../../../Reusable/Master/PaymentCard/PaymentCardEdit";
import MasterPaymentTypeEdit from "../../../Reusable/Master/PaymentType/PaymentTypeEdit";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function ReservationDepositTable(props) {
  // Props
  const { is_search, searchKey } = props;

  // Currency
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getResDeposit } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulk, setBulk] = useState([]);
  const [edit, setEdit] = useState(false);
  // Edit
  const [dataEdit, setDataEdit] = useState(null);
  const [dataGuest, setDataGuest] = useState(null);
  // Data Deposit
  const [addDepo, setAddDepo] = useState([]);
  const [dataDepo, setDataDepo] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [disableDeposit, setDisableDeposit] = useState(true);
  // Payment
  const [payId, setPayId] = useState({
    name: "",
    id: "",
  });
  const [payCode, setPayCode] = useState({ name: "", id: "" });
  // Modal State
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState("0");
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  // PARAMS
  const params_coa = ParametersFrontOffice().params;

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    is_search(() => handleFilter);
  }, [searchKey]);

  // USE EFFECT MODAL OPEN STATE
  useEffect(() => {
    let cleanUp = true;

    if (edit == true && !cleanUp) {
      form.resetFields([
        // "deposit_amount",
        "coa",
        "description",
        "payment_type",
        "payment_details",
      ]);
    }

    return () => {
      cleanUp = true;
    };
  }, [edit, dataGuest]);

  // Fetch Data
  const fetchData = async () => {
    await getResDeposit({
      reservation: "reservation",
      deposit: "deposit-stay-sum",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Deposit => ", response);

        if (response?.data?.length > 0) {
          const _res = response.data;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          // let arr = [];

          // for (let i = 0; i < filtered?.length; i++) {
          //   let newData = {
          //     ...filtered.data[i],
          //     id: i + 1,
          //   };
          //   arr.push(newData);
          // }

          // console.log("Data with ID: ", filtered);
          setData(filtered);
          setBulk(filtered);
        } else {
          setData([]);
          setBulk([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Tooltip title="Edit Data" className="edit-tooltip">
                <FiEdit
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);
                    setOpen(true);
                    setEdit(true);
                    setDataEdit(record);

                    handleFieldValue(record);
                  }}
                  style={{ fontSize: 20, color: "#1BC5BD", cursor: "pointer" }}
                />
              </Tooltip>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation No",
      dataIndex: "reservation_id",
      key: "reservation_id",
      fixed: "left",
      width: 250,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    // {
    //   title: "Guest Name",
    //   dataIndex: "guest_name",
    //   key: "guest_name",
    //   fixed: "left",
    //   width: 250,
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.guestname.localeCompare(b.guestname),
    // },
    // {
    //   title: "Room No",
    //   dataIndex: "room_number",
    //   key: "room_number",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.qty - b.qty,
    // },
    // {
    //   title: "Arrival",
    //   dataIndex: "arrival",
    //   key: "arrival",
    //   sortDirections: ["descend", "ascend"],
    //   render: (date, record) => {
    //     return moment(date).format("DD MMM YYYY");
    //   },
    //   //sorter: (a, b) => a.arrival.localeCompare(b.arrival),
    // },
    // {
    //   title: "Departure",
    //   dataIndex: "departure",
    //   key: "departure",
    //   sortDirections: ["descend", "ascend"],
    //   //sorter: (a, b) => a.arrival.localeCompare(b.arrival),
    // },
    {
      title: `Deposit Amount ( ${currency} )`,
      dataIndex: "deposit",
      key: "deposit",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.deposit - b.deposit,
      render: (deposit, record) => {
        // const total_price = parseInt(record.qty) * parseInt(record.unit_price);

        return `${deposit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "is_cancelled",
    //   key: "is_cancelled",
    //   // sortDirections: ["descend", "ascend"],
    //   // sorter: (a, b) => a.group.localeCompare(b.group),
    // },
    // {
    //   title: "Group Name",
    //   dataIndex: "group",
    //   key: "group",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.group.localeCompare(b.group),
    // },
  ];

  // Handle Form Fields
  const handleFieldValue = (value) => {
    const _rec = value;

    form.setFieldsValue({
      reservation_id: _rec?.reservation_id,
      guest_name: _rec?.guest_name,
    });
  };

  // HANDLE SELECTOR MASTER
  // Get Reservation ID
  const getReservationId = (value, res_id, guest_name, price_id, status) => {
    console.log(value, res_id, guest_name, price_id);

    form.setFieldsValue({
      reservation_id: res_id,
      guest_name: status == "INCOGNITO" ? "PRIVATE" : guest_name,
      price_id: price_id,
    });
  };

  // Get Payment Type
  const getPaymentType = (value, code) => {
    // console.log(value, code);
    setPayId({
      id: value?.id,
      name: value?.name,
    });

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };

  // Payment Details/Card
  const getPayCard = (value, code) => {
    console.log("Payment Details: ", value, code);

    setPayCode({
      id: value?.id,
      name: value?.name,
    });

    form.setFieldsValue({
      payment_details: value?.name,
    });
  };

  // GET DATA DEPO
  const getDataDepo = (value) => {
    const _rec = value?.length > 0 ? value[0] : value;
    console.log("About to Edit: ", value);

    setDataDepo(value);
    setDisableDeposit(false);

    form.setFieldsValue({
      edit_deposit: value?.length > 0 ? value[0] : {},
      deposit_amount: _rec?.deposit_amount,
      coa: _rec?.coa,
      description: _rec?.description,
      deposit_status_id: _rec?.deposit_status_id,
      guest_name: _rec?.guest_name,
    });
  };

  // Handle Deposit
  const handleDeposit = (val, code) => {
    console.log("Val: ", val, " - Code: ", code);

    form.setFieldsValue({
      deposit_status_id: val,
    });
  };

  // Handle Deposit Disable
  const handleDisable = (val) => {
    if (val > 0) {
      setDisableDeposit(false);
    } else {
      setDisableDeposit(true);
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (err) => {
    console.log("Error Failed: ", err);
  };

  // SHOW SUBMIT CONFIRMATION
  const showSubmitModal = (e) => {
    setIsLoad(true);
    let submitContent = e;

    console.log("On finish-->", submitContent);

    confirm({
      className: "submit-deposit-confirm",
      title: `Are you sure want to ${
        edit == true ? "Update" : "Add new"
      } deposit with guest Reservation ID ${
        submitContent?.reservation_id || " - - - - "
      } ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmitDeposit(submitContent);
        // console.log("Guest deleted");
      },
      onCancel() {
        setIsLoad(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const handleSubmitDeposit = async (e) => {
    // e.preventDefault();
    const contentSubmit = e;
    console.log("EDITED > ", contentSubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/reservation/deposit-stay`,
          {
            reservation_id: contentSubmit?.reservation_id
              ? contentSubmit.reservation_id
              : null,
            deposit_amount: contentSubmit?.deposit_amount,
            payment_type: contentSubmit?.payment_type,
            payment_type_id: payId.id > 0 ? payId.id : 0,
            payment_details: contentSubmit?.payment_details,
            payment_card_id: payCode.id > 0 ? payCode.id : 0,
            description: contentSubmit.description
              ? contentSubmit.description
              : null,
            coa: contentSubmit?.chart_of_account
              ? contentSubmit.chart_of_account
              : null,
            deposit_code: "100",
            params_coa: "11",
            price_id: contentSubmit?.price_id ? contentSubmit.price_id : 0,
            deposit_status_id:
              contentSubmit?.deposit_status_id > 0
                ? contentSubmit.deposit_status_id
                : 2,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log(response);

          masterSuccessNotification(response, { method: 0, source: "Deposit" });
          fetchData();
          // setEdit(null);
          handleCancel();
        })
        .catch((error) => {
          console.log(error);

          masterFailedNotification(error, { method: 0, source: "Deposit" });
        })
        .finally(() => {
          setIsLoad(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/reservation/deposit-stay`,
          {
            id: dataDepo ? dataDepo[0]?.id : 0,
            reservation_id: contentSubmit?.reservation_id
              ? contentSubmit.reservation_id
              : dataDepo[0]?.reservation_id,
            deposit_amount: contentSubmit?.deposit_amount
              ? contentSubmit.deposit_amount
              : dataDepo[0]?.deposit_amount,
            payment_type: contentSubmit?.payment_type
              ? contentSubmit.payment_type
              : dataDepo[0]?.payment_type,
            payment_type_id:
              payId.id > 0 ? payId.id : dataDepo[0]?.payment_type_id,
            payment_details: contentSubmit?.payment_details
              ? contentSubmit.payment_details
              : dataDepo[0]?.payment_details,
            payment_card_id:
              payCode.id > 0 ? payCode.id : dataDepo[0]?.payment_card_id,
            description: contentSubmit.description
              ? contentSubmit.description
              : dataDepo[0]?.description,
            coa: contentSubmit?.chart_of_account
              ? contentSubmit.chart_of_account
              : dataDepo[0]?.chart_of_account,
            deposit_status_id:
              contentSubmit?.deposit_status_id > 0
                ? contentSubmit.deposit_status_id
                : 2,

            deposit_code: "100",
            params_coa: "11",
            price_id: dataDepo?.length > 0 ? dataDepo[0]?.price_id : 0,
            updated_by: roles,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("Update Depo => ", response);

          masterSuccessNotification(response, { method: 1, source: "Deposit" });
          fetchData();
          setIsFetch(true);

          handleCancel();
        })
        .catch((error) => {
          console.log("Update Rate => ", error);

          masterFailedNotification(error, { method: 1, source: "Deposit" });
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setDataDepo([]);
    setDataEdit(null);
    setSelect([]);

    setPayCode({
      id: 0,
      name: "",
    });
    setPayId({
      id: 0,
      name: "",
    });

    // console.log("RESET");
    setDisableDeposit(true);
    setIsLoad(false);

    form.resetFields();
    setOpen(false);
    setEdit(false);
  };

  // Handle Filter
  const handleFilter = () => {
    const _keys = searchKey ? searchKey.toLowerCase() : null;

    if (bulk.length > 0) {
      let _filter = bulk.filter((items) => {
        const _res = items.reservation_id.toLowerCase();

        return _res.indexOf(_keys) !== -1;
      });

      console.log(_keys, _filter);
      setData(_filter);
    } else {
      setData([]);
    }
  };

  // Console
  // console.log("DATA >> ", data);
  // console.log("DEPO >> ", dataDepo);
  // console.log("Params COA >> ", params_coa);
  // console.log("is EDIT ?? >> ", edit);

  return (
    <>
      <Row
        className="reservation-deposit-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="end" className="row-modal-btn" gutter={[0, 30]}>
            <Button
              //onClick={}
              className="submit-btn"
              type="primary"
              onClick={() => setOpen(true)}
              icon={<PlusOutlined />}
            >
              {`Add Deposit`}
            </Button>

            <Button
              className="refresh-btn"
              onClick={fetchData}
              type="default"
              style={{
                margin: "0px 0px 0px 15px",
              }}
              icon={<SyncOutlined />}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="reservation-deposit-table"
              key="reservation-deposit-table"
              name="reservation-deposit-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              size="large"
              scroll={{
                x: true,
              }}
              rowKey={(record) =>
                record.reservation_id ? record.reservation_id : record.price_id
              }
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="deposit-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Reservation Deposit`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Reservation Deposit`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        // onOk={() => setOpen(false)}
        onCancel={handleCancel}
        // onCancel={(e) => handleCancel(e)}
        width={800}
        footer={null}
      >
        <Form
          className="modal-add-deposit"
          name="modal-add-deposit"
          key="modal-add-deposit"
          form={form}
          layout="vertical"
          onFinish={showSubmitModal}
          onFinishFailed={onFinishFailed}
          autoComplete="false"
          initialValues={{
            chart_of_account: params_coa?.deposit_coa
              ? params_coa.deposit_coa
              : "",
          }}
          style={{ padding: "0px 36px" }}
        >
          {/* <Row style={{ padding: "30px 60px 30px", width: "100%" }} gutter={30}> */}
          <Row gutter={30}>
            <Col span={16}>
              <Form.Item
                label="Reservation No."
                name="reservation_id"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose a Reservation ID!",
                  },
                ]}
              >
                {edit == false ? (
                  <ReservationId
                    getResvId={getReservationId}
                    resv_id={edit == true ? dataEdit?.reservation_id : ""}
                  />
                ) : (
                  <Input placeholder="Reservation No." disabled />
                )}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Guest Name" name="guest_name">
                <Input placeholder="Guest Name" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30}>
            <Col span={8}>
              <Form.Item
                label="Amount"
                name="deposit_amount"
                rules={[
                  {
                    required: true,
                    message: "Please, input Deposit Amount!",
                  },
                ]}
              >
                <InputNumber
                  addonBefore={currency}
                  placeholder="Amount"
                  onChange={handleDisable}
                  formatter={(deposit_amount) =>
                    ` ${deposit_amount > 0 ? deposit_amount : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )
                  }
                  style={{ marginRight: 30, width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Payment Type"
                name="payment_type"
                style={{ marginRight: 30, width: "100%" }}
              >
                <MasterPaymentTypeEdit
                  getPaymentType={getPaymentType}
                  pay={edit == true ? dataDepo[0]?.payment_type : null}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Payment Details"
                name="payment_details"
                // style={{ marginRight: 30 }}
              >
                <MasterPaymentCardEdit
                  getPayCard={getPayCard}
                  payCard={edit == true ? dataDepo[0]?.payment_details : null}
                  getPayTypeId={payId.id}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30}>
            <Col span={8}>
              <Form.Item
                label="COA"
                name="chart_of_account"
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input disabled placeholder="..." />
              </Form.Item>

              <Form.Item
                label="Deposit Payment"
                name="deposit_status_id"
                rules={[
                  {
                    required: true,
                    message: "Please, Select an Options!",
                  },
                ]}
              >
                <Select
                  placeholder="Payment"
                  className="deposit_status_id"
                  allowClear
                  showSearch
                  disabled={disableDeposit}
                  // onClear={}
                  // value={}
                  onChange={handleDeposit}
                  options={[
                    {
                      label: "EARLY PAYMENT",
                      value: 1,
                    },
                    {
                      label: "PAYMENT AT CHECK-OUT",
                      value: 2,
                    },
                  ]}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Description"
                name="description"
                style={{ width: 440 }}
              >
                <Input.TextArea
                  showCount
                  maxLength={100}
                  rows={4}
                  allowClear
                  placeholder="Descriptions"
                />
              </Form.Item>

              <Form.Item label="Price ID" name="price_id" hidden>
                <Input placeholder="Descriptions" />
              </Form.Item>
            </Col>

            <Divider
              className="form-divider"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />

            {edit === true ? (
              <>
                <Row style={{ width: "100%" }}>
                  <Form.Item
                    name="edit_deposit"
                    label="Deposit History"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose a Data to Edit!",
                      },
                    ]}
                  >
                    <ReservationDepositModalTable
                      resvId={dataEdit?.reservation_id}
                      guestName={setDataGuest}
                      getDataDepo={getDataDepo}
                      is_fetch={isFetch}
                      fetchFalse={setIsFetch}
                      selected={select}
                      modalClose={open}
                    />
                  </Form.Item>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
                >
                  {`*Please, select a Row to Edit!`}
                </Divider>
              </>
            ) : null}

            <Row justify="end" style={{ width: "100%" }}>
              <Form.Item>
                <Button
                  className="submit-btn"
                  type="primary"
                  loading={isLoad}
                  htmlType="submit"
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>
              </Form.Item>

              <Button
                className="cancel-btn"
                onClick={handleCancel}
              >{`Cancel`}</Button>
            </Row>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

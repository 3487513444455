// ANTD COMPONENTS
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";

// REACT COMPONENTS
import React, { useEffect, useState } from "react";

// REACT ICONS
import { FiEdit } from "react-icons/fi";

// PAGE COMPONENTS
import MasterMainAccount from "../../../Reusable/Accounting/MainAccount/MasterCoa";

export default function StockTransferRequestCOAModal(props) {
  // PROPS
  const { is_open, is_close, getCoa, coa_name } = props;

  // STATE
  const [modalOpen, setModalOpen] = useState(false);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_open && !cleanUp) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  const handleClose = () => {
    is_close(false);
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        className="tr-receiving-modal"
        key="tr-receiving-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Stock Transfer/Request COA`}</Typography>
            </Row>
          </>
        }
        centered
        footer={null}
        open={modalOpen}
        destroyOnClose={true}
        closable
        onCancel={handleClose}
        width={600}
      >
        <Row className="pay-row" gutter={30}>
          <Col span={24} className="pay-col">
            <Typography style={{ marginBottom: 15 }}>
              {`Select Chart of Account`}
            </Typography>

            <MasterMainAccount
              getCoaName={getCoa}
              coa_name={coa_name}
              is_all={true}
              disable={false}
            />
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: "end",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Button
            className="next-btn"
            type="primary"
            icon={<ArrowRightOutlined />}
            onClick={() => {
              // getSelectedCoa(selectedData);
              handleClose();
            }}
          >{`Submit COA`}</Button>
        </Row>
      </Modal>
    </>
  );
}

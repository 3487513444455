// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Button, Row, Select, Space, Tooltip } from "antd";
import { MdPersonAddAlt1 } from "react-icons/md";
import { AppstoreFilled, CloseCircleFilled } from "@ant-design/icons";
import ModalGuestHistory from "../../../Modals/GuestHistory/Modal";

// CODE
export default function MasterGuest(props) {
  // PROPS
  const { getGuestName, name, is_bill_receiver, bill_id, disable } = props;

  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [guest, setGuest] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  // Modal data
  const [dataGuest, setDataGuest] = useState(null);
  // Modal History
  const [open, setOpen] = useState(false);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/guest-card-file");
  };

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getGuest({
      guest: "guest",
      type: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest Master => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          let newData = filtered.map((gst) => ({
            value: gst?.full_name,
            label: gst?.full_name,
            // first_name: gst?.first_name,
            nationality: gst?.nationality,
            id: gst?.id,
            birthdate: gst?.birthdate,
            rate_code: gst?.rate_code,
            rate_id: gst?.rate_code_id,
            segment_name: gst?.main_segment_name,
            segment_id: gst?.main_segment_id,
            company_name: gst?.company_name,
            company_id: gst?.master_comp_id,
            is_allow: gst?.allow_ledger,
            credit_limit: gst?.credit_limit,
          }));

          // console.log("GUEST LIST == ", filtered);
          setOptionValue(newData);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // FILTERS
  const fetchFilter = async (val) => {
    const _data = val;

    await getGuest({
      guest: "guest",
      type: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest Master => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return val?.full_name == _data;
          });

          console.log("GUEST LIST == ", filtered);
          handleFilter(filtered);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Name
  useEffect(() => {
    let cleanUp = false;

    if (name > 0 && optionValue.length > 0 && !cleanUp) {
      handleFilter(name);
    }

    // Find Bill Receivers
    if (bill_id > 0 && optionValue.length > 0 && !cleanUp) {
      handleFilter(bill_id);
    }
    // else {
    //   if (!guest) {
    //     setGuest(null);
    //   } else {
    //     setGuest(guest);
    //   }
    // }

    return () => {
      cleanUp = true;
    };
  }, [name, bill_id, optionValue]);

  // Handle Change/Select
  const handleSelect = (value, allKeys, index) => {
    const _label = value;
    const _allKeys = allKeys;

    // console.log("Values: ", value);
    // console.log("All Keys: ", allKeys);
    // console.log("Index: ", index);

    if (value) {
      setGuest(value);
      getGuestName({
        value: _label,
        guest_id: _allKeys?.id,
        birthday: _allKeys?.birthdate,
        rate_code: _allKeys?.rate_code,
        rate_id: _allKeys?.rate_id,
        segment: _allKeys?.segment_name,
        segment_id: _allKeys?.segment_id,
        company_name: _allKeys?.company_name,
        company_id: _allKeys?.company_id,
        is_allow: _allKeys?.is_allow,
        credit_limit: _allKeys?.credit_limit,
      });

      setDataGuest(_allKeys);
      handleHistory(_allKeys);
    } else {
      handleClear();
    }
  };

  // Handle Open History Modal Guest
  const handleHistory = (value) => {
    const _data = value;

    // console.log("Please, open history!", _data, " || Bill: ", is_bill_receiver);

    setOpen(true);
  };

  // HANDLE FILTER
  const handleFilter = (value) => {
    const _guest = value;

    const newData = optionValue.filter((gst) => {
      return gst.id == _guest;
      // value: gst?.full_name,
      // label: gst?.full_name,
      // // first_name: gst?.first_name,
      // nationality: gst?.nationality,
      // id: gst?.id,
      // birthdate: gst?.birthdate,
      // rate_code: gst?.rate_code,
      // rate_id: gst?.rate_code_id,
      // segment_name: gst?.main_segment_name,
      // segment_id: gst?.main_segment_id,
      // company_name: gst?.company_name,
      // company_id: gst?.master_comp_id,
      // is_allow: gst?.allow_ledger,
      // credit_limit: gst?.credit_limit,
    });

    console.log("Filtered from Master Guest: ", newData);
    setDataGuest(newData?.length > 0 ? newData[0] : newData);

    getGuestName({
      value: newData[0]?.value,
      guest_id: newData[0]?.id,
      birthday: newData[0]?.birthdate,
      rate_code: newData[0]?.rate_code,
      rate_id: newData[0]?.rate_id,
      segment: newData[0]?.segment_name,
      segment_id: newData[0]?.segment_id,
      company_name: newData[0]?.company_name,
      company_id: newData[0]?.company_id,
      is_allow: newData[0]?.is_allow,
      credit_limit: newData[0]?.credit_limit,
    });

    if (is_bill_receiver) {
      setOpen(false);
    } else {
      setOpen(true);
    }

    setGuest(newData?.length > 0 ? newData[0]?.value : null);
  };

  // HANDLE CLEAR
  const handleClear = (e) => {
    // console.log("Clear on: ", e);

    setGuest(null);
    setOpen(false);
  };

  return (
    <>
      <Row
        className="guest-select-space"
        // size={0}
        // direction="horizontal"
        // align=""
        justify="start"
        style={{ width: "100%" }}
      >
        <div
          className="guest-row"
          align="start"
          size={0}
          style={{
            display: "flex",
            flexGrow: 6,
          }}
        >
          <Select
            key={"guest-name-select"}
            className={"guest-name-select"}
            style={{
              // maxWidth: 250,
              // minWidth: 150,
              width: "100%",
            }}
            value={guest}
            showSearch
            allowClear
            placeholder="Guest Name"
            // clearIcon={
            //   <CloseCircleFilled
            //     className="clear-icons"
            //     style={{
            //       padding: "0px 30px 0px 0px",
            //     }}
            //   />
            // }
            disabled={disable}
            onChange={handleSelect}
            onClear={handleClear}
            options={optionValue}
          />
        </div>

        <div
          className="space-btn"
          style={{
            display: "flex",
            flexGrow: 0,
          }}
        >
          <Tooltip className="guest-tooltip" title="Create New Guest?">
            <Button
              className="suffix-btn"
              type="default"
              shape="default"
              disabled={disable}
              icon={
                <MdPersonAddAlt1
                  className="move-btn-icon"
                  onClick={move}
                  style={{
                    fontSize: 25,
                    color: "#3699FF",
                    textAlign: "center",
                    width: "100%",
                  }}
                />
              }
              style={{
                width: 43,
              }}
            />
          </Tooltip>
        </div>
      </Row>

      <ModalGuestHistory
        is_open={open}
        is_close={setOpen}
        guest_id={dataGuest}
      />
    </>
  );
}

// Import React's Components
import React, { useState, useEffect } from "react";
import moment from "moment";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Typography,
  Modal,
  Form,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { InfoCircleOutlined } from "@ant-design/icons";
import { textForm } from "../../../../Style/Global/Global";

// CODE
export default function TableBook(props) {
  // PROPS
  const { openModal, closeModal, dataTable, dataForm } = props;

  // MODAL COLUMNS
  const column = [
    {
      title: "Reservation No",
      dataIndex: "reservation_id",
      key: "reservation_id",
      // fixed: "left",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // sortDirections: ["descend", "ascend"],
    },
  ];

  // STATE MANAGEMENT
  // DATA
  const [data, setData] = useState([]);
  // Modal State
  const [open, setOpen] = useState(false);
  // Load
  const [loading, setLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(true);
      setLoading(true);

      if (dataTable?.length > 0) {
        setData(dataTable);
      } else {
        setData([]);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataTable]);

  // HANDLE CLOSE
  const onFinish = () => {
    setOpen(false);
    closeModal(false);
  };

  return (
    <>
      <Modal
        className="room-chart-history-modal"
        title={
          <Row>
            <InfoCircleOutlined
              className="title-modal-icon"
              twoToneColor={"geekblue"}
              style={{
                fontSize: 24,
                color: "#3699FF",
                fontWeight: 750,
              }}
            />
            <Typography
              style={{ margin: "0px 0px 0px 15px" }}
            >{`AVAILABILITY DAILY`}</Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={onFinish}
        width={800}
        footer={null}
      >
        <Form
          className="book-form"
          key="book-table"
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          {/* <Row justify="space-between"> */}
          <Form.Item label="Date" style={{ margin: 0 }}>
            <Typography className="text" style={textForm}>
              {`${
                dataForm?.date ? moment(dataForm.date).format("DD-MM-YYYY") : 0
              }`}
            </Typography>
          </Form.Item>

          <Form.Item label="Room Category" style={{ margin: 0 }}>
            <Typography className="text" style={textForm}>
              {` ${dataForm?.room ? dataForm.room : ""}`}
            </Typography>
          </Form.Item>
          {/* </Row> */}

          <Table
            className="room-avl-table"
            name="room-avl-table"
            key="room-avl-table"
            bordered
            loading={loading}
            columns={column}
            dataSource={data}
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            scroll={{
              x: 750,
            }}
            rowKey={(record) => (record.id ? record.id : record.key)}
            rowClassName={(record, index) => {
              return index % 2 == 0 ? "odd" : "even";
            }}
            style={{
              margin: "30px 0px",
            }}
          />

          <Row className="btn-row" justify="end">
            <Button className="cancel-btn" htmlType="submit">
              {`Close`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import Antd Components
import { Grid } from "antd";

// Breakpoint
const { useBreakpoint } = Grid;

// const screenSize = useBreakpoint();
// const breakpoint = { lg, md, sm, xl, xs, xxl };

// Icons Menu Style Main Layout
const menuStyles = {
  height: 24,
  width: 24,
  // color: "#494b69",
};

// Icons Sub Menu Style Main Style
const subMenuStyles = {
  height: 9,
  width: 9,
  // color: "#494b69",
};

// Collapse
const collapseStyle = {
  height: 65,
  position: "fixed",
  top: 0,
  zIndex: 10,
  transition: "1s",
  width: "calc(100% - 85px)",
};

const uncollapseStyle = {
  height: 65,
  position: "fixed",
  top: 0,
  zIndex: 10,
  transition: "1s",
  width: "calc(100% - 256px)",
};

// STYLING CSS
// Tabpane Numbers
const styleActive = {
  height: 35,
  width: 35,
  fontSize: 19,
  fontWeight: 600,
  padding: 0,
  color: "#FFFFFF",
  backgroundColor: "#1890ff",
  borderColor: "#1890ff",
};

const styleInactive = {
  height: 35,
  width: 35,
  fontSize: 19,
  fontWeight: 600,
  padding: 0,
  color: "#1890ff",
  backgroundColor: "#E9EBF2",
  borderColor: "#E9EBF2",
};

const styleError = {
  height: 35,
  width: 35,
  fontSize: 22,
  fontWeight: 750,
  padding: 0,
  color: "#F64E60",
  backgroundColor: "#ffcccc",
  borderColor: "#ffcccc",
};

const cardHeader = {
  backgroundColor: "#FFFFFF",
  // marginBottom: 1,
  borderBottom: "1px solid #E4E6EF",
  borderRadius: "5px 5px 0px 0px",
};

const cardBody = {
  backgroundColor: "#FFFFFF",
  margin: "0px 0px 30px",
  borderRadius: "0px 0px 5px 5px",
};

const mainBody = {
  backgroundColor: "#FFFFFF",
  minHeight: "100%",
  borderRadius: 5,
  width: "100%",
};

const pageIcons = {
  color: "#1BC5BD",
  margin: "10px 0px",
  fontSize: 30,
};

const globalIcon = {
  margin: "2px 10px 0px",
  fontSize: 30,
  color: "#1BC5BD",
};

const globalText = {
  // fontSize: 20,
  fontWeight: 750,
  margin: "5px 5px 0px 5px",
  whiteSpace: "normal",
  wordWrap: "break-word",
  // height: 24,
  // width: 24,
  // color: "#3699FF",
};

const gridStyle = {
  // minWidth: 250,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
};

const linkStyle = {
  display: "flex",
  padding: "5px 5px 10px 10px",
  minWidth: 250,
  height: "auto",
  whiteSpace: "normal",
  wordWrap: "break-all",
};

// Text Styles
const textForm = {
  fontWeight: 750,
  padding: "5px 0px 0px",
  color: "#000000",
};

// Print Styles
const printPreview = {
  fontSize: 7,
  fontWeight: 500,
  textTransform: "capitalize",
};

// Dashboard
const widgetDash = {
  margin: "-60px 30px 0px 0px",
  borderRadius: 12,
  padding: 20,
  backgroundColor: "#FFFFFF",
  // boxShadow: "5px 5px 5px #dfdfdf",
  // minWidth: "100%",
  minHeight: 100,
};

const styleNumbers = {
  color: "#EE9D01",
  fontSize: 16,
  fontWeight: 500,
};

const styleSecondaryText = {
  color: "#b3b3b3",
  fontSize: 16,
  fontWeight: 500,
  padding: "15px 0px 0px",
};

const stylePrimaryText = {
  color: "#b3b3b3",
  fontSize: 16,
  fontWeight: 500,
};

const rowWidgets = {
  padding: "0px 0px 0px 30px",
  fontSize: 20,
  fontWeight: 600,
  color: "#000000",
  // width: "100%",
};

// Row Styles
const editStyle = {
  fontSize: 20,
  color: "#1BC5BD",
  cursor: "pointer",
};

// Success Master Styles Notification
const successMaster = {
  fontWeight: "bold",
  borderRadius: 5,
  padding: "0px 20px 5px",
  background: `#52c41a`,
  color: "#FFFFFF",
  minHeight: 100,
};

// Failed Master Styles Notification
const failedMaster = {
  fontWeight: "bold",
  borderRadius: 5,
  minHeight: 100,
  padding: "0px 20px 5px",
  background: "linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)",
  color: "#FFFFFF",
};

// Invoice Text
const invoText = {
  fontWeight: 750,
  padding: 0,
  color: "#8c8c8c",
};

export {
  // Menu & Sub-Menu Styling
  menuStyles,
  subMenuStyles,

  // Collapsibles
  uncollapseStyle,
  collapseStyle,

  // Errors Styling
  styleActive,
  styleInactive,
  styleError,

  // Page Styles
  cardHeader,
  cardBody,
  mainBody,

  // Breakpoint
  // screenSize,

  // Masters
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,

  // Form Text Styles
  textForm,

  // Print Styles
  printPreview,
  invoText,

  // Dashboard
  widgetDash,
  styleNumbers,
  rowWidgets,
  stylePrimaryText,
  styleSecondaryText,

  // Icon's Style Tables
  editStyle,

  // PAGE
  pageIcons,

  // Notifications
  successMaster,
  failedMaster,
};

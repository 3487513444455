// Import React Components
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// Import Page Components
import ResManager from "../../Pages/Manager/Reservation/ResManager";
import MasterManager from "../../Pages/Manager/Master/Master";
import RoomManager from "../../Pages/Manager/RoomManager/RoomManager";
import NotFound from "../../Pages/NotFound/NotFound";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";
import InternalError from "../../Pages/NotFound/InternalError";
import ReservationArticleManager from "../../Pages/Manager/Articles/Article";
import GuestInfoManager from "../../Pages/Manager/Guest/Guest";
import ResultSuccess from "../../Pages/Manager/Results/Success";
import ResultFailed from "../../Pages/Manager/Results/Failed";
import PaymentGateway from "../../Pages/Manager/Payment/PaymentGateway";

// CODE
export default function ManagerRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ResManager />} />
        <Route path="/dashboard" element={<ResManager />} />
        <Route path="/master" element={<MasterManager />} />

        <Route path="*" element={<NotFound />} />
        <Route path="/internal-error" element={<InternalError />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />

        <Route path="/room-availability" element={<RoomManager />} />
        <Route
          path="/reservation-article"
          element={<ReservationArticleManager />}
        />
        <Route path="/reservation-guest" element={<GuestInfoManager />} />
        <Route
          path="/reservation-payment-gateway"
          element={<PaymentGateway />}
        />

        <Route path="/reservation-success" element={<ResultSuccess />} />
        <Route path="/reservation-failed" element={<ResultFailed />} />
      </Routes>
      <Outlet />
    </>
  );
}

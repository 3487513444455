// Import React's Components
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../API/Config/Api";
import { token, user_level, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Form,
  Modal,
  Row,
  Col,
  Typography,
  Input,
  Radio,
  Divider,
  Button,
  DatePicker,
  Tag,
} from "antd";

// Import React Icons
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

// Import Page Components
import ReceivingApprovalDetailTable from "../../../Table/BackOffice/Inventory/Receiving/ReceivingApprovalDetail/Table";
import MasterApprovalType from "../../../Reusable/Master/Approval/ApprovalType";
import ApprovalDetailTable from "../../../Table/BackOffice/Purchasing/ApprovalsDetail/Table";

// Import Notifications
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  SyncOutlined,
} from "@ant-design/icons";

// Modals
const { confirm } = Modal;

// CODE
export default function ReceivingApprovalModal(props) {
  // PROPS
  const { is_open, is_close, is_refresh, dataPurchase } = props;

  // STATE MANAGEMENT
  // Data
  const [details, setDetails] = useState([]);
  // Table Edit
  const [updateData, setUpdateData] = useState([]);
  const [approvalList, setApprovalList] = useState([]);
  // Boolean
  const [isReject, setIsReject] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open === true && !cleanUp) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }

    if (dataPurchase?.details?.length > 0 && !cleanUp) {
      console.log("About to Approve: ", dataPurchase);

      setDetails(dataPurchase?.details);
      handleFormFields(dataPurchase);
    } else {
      setDetails([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, dataPurchase]);

  // On Finish
  const onFinish = (value) => {
    const _val = value;

    console.log("Submit: ", _val);
    console.log("Updated Data: ", updateData);

    setIsLoading(true);
    showModalConfirm(_val);
  };

  // On Finish Failed
  const onFinishFailed = (value) => {
    const _fail = value?.values;

    masterIncomplete(value);
    console.log("Submit Failed: ", _fail);
  };

  // Handle Cancel
  const handleCancel = () => {
    is_close(false);
    setModalOpen(false);

    setUpdateData([]);
    setIsReject(false);
    setApprovalList([]);
    setIsLoading(false);

    form.resetFields();
  };

  // Get Approval Type
  const getApprovalType = (value) => {
    console.log("Budget Allocation: ", value);

    form.setFieldsValue({
      approval_flow: value?.id,
    });
  };

  // Handle Direct
  const handleDirect = (val) => {
    const value = val.target.value;
    // e.preventDefault();

    console.log("Direct: ", value);
  };

  // Handle Reject
  const handleReject = (val) => {
    const value = val.target.value;
    // e.preventDefault();

    console.log("Reject: ", value);

    if (value === 1) {
      setIsReject(false);
    } else {
      setIsReject(true);
    }
  };

  // Handle Delete
  const handleEditDetail = (val) => {
    console.log("Edit data: ", val);

    if (updateData.length > 0) {
      const newData = [...updateData];
      const index = updateData.findIndex((item) => {
        // console.log(item);
        return val.id_pr_detail === item.id_pr_detail;
      });

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          id_pr_detail: val.id_pr_detail,
          quantity: val.quantity,
          description: val.description,
        });

        console.log("Edited: ", newData);
        setUpdateData(newData);
      } else {
        newData.push({
          id_pr_detail: val.id_pr_detail,
          quantity: val.quantity,
          description: val.description,
        });
        setUpdateData(newData);
      }
    } else {
      setUpdateData([
        {
          id_pr_detail: val.id_pr_detail,
          quantity: val.quantity,
          description: val.description,
        },
      ]);
    }
  };

  // Handle Form Field
  const handleFormFields = (rec) => {
    const _record = rec;
    const approval = rec?.approved_list?.length > 0 ? rec.approved_list : [];

    let _arr = [];

    form.setFieldsValue({
      is_direct: _record?.is_direct == true ? 1 : 0,
      document_number: _record?.document_number,
      created_date: _record?.created_date ? moment(_record.created_date) : null,
      remarks: _record?.remarks ? _record?.remarks : null,
      approval_flow: _record?.approval_flow > 0 ? _record?.approval_flow : 0,
    });

    if (approval?.length > 0) {
      let _tags = approval.map((value, index) => {
        // console.log("ADA");

        if (value == 1) {
          return (
            <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
              {`Approve ${index + 1}`}
            </Tag>
          );
        } else if (value === 2) {
          return (
            <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
              {`Rejected ${index + 1}`}
            </Tag>
          );
        } else {
          return (
            <Tag color="geekblue" key={index + 1} icon={<SyncOutlined spin />}>
              {`Waiting ${index + 1}`}
            </Tag>
          );
        }
      });

      _arr.push(_tags);
    } else {
      //   console.log("TIDAK ADA");
      return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
    }

    // console.log("Approval List: ", rec.approval_list);
    console.log("Approval List: ", _arr);

    if (_arr.length > 0) {
      setApprovalList(_arr);
    } else {
      setApprovalList([]);
    }
  };

  // Modal Confirm
  const showModalConfirm = (value) => {
    const _submit = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to ${
        _submit?.is_approve === 1 ? "Approve" : "Reject"
      } this Purchase Request?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleApprove(_submit);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Approve
  const handleApprove = async (val) => {
    const _submit = val;

    return await axios
      .put(
        `${baseurl}/inventory/receiving-approval`,
        {
          id_stock_incoming: dataPurchase?.id_stock_incoming,
          id_supplier: dataPurchase?.id_supplier,
          user_level: user_level,
          department_id: details[0]?.department_id,
          value: _submit?.is_approve,
          information: _submit?.information,
          updated_by: user_name,
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Success Approve/Rejects: ", response);

        masterSuccessNotification(response, {
          method: 5,
          source: "Purchase Request",
        });

        handleCancel();
        is_refresh(true);
      })
      .catch((error) => {
        console.log("Failed Approve/Rejects: ", error);

        masterFailedNotification(error, {
          method: 5,
          source: "Purchase Request",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  console.log(dataPurchase);

  return (
    <>
      <Modal
        className="approval-modal"
        key={"approval-modal"}
        title={
          <Row className="title-row">
            <IoCheckmarkDoneCircleSharp
              color="#1890ff"
              style={{ fontSize: 24 }}
            />
            <Typography style={{ marginLeft: 15 }}>
              {dataPurchase?.is_price_changed
                ? `Approve Stock Incoming with Price Change`
                : `Approve Stock Incoming without PO`}
            </Typography>
          </Row>
        }
        onCancel={handleCancel}
        closable
        open={modalOpen}
        width={1000}
        footer={null}
      >
        <Form
          className="approval-form"
          key="approval-form"
          name="approval-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{
            padding: "0px 36px",
          }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="pay-row" gutter={30}>
                <Col span={8} className="pay-col">
                  <Form.Item label="Document No." name="document_number">
                    <Input className="form-input" disabled />
                  </Form.Item>
                </Col>
                <Col span={8} className="req-date-col">
                  <Form.Item
                    label="Arrival Date"
                    name="created_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select an Arrival Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Arrival Date"
                      className="date"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="pay-col">
                  <Form.Item
                    label="Receiving"
                    name="is_direct"
                    onChange={handleDirect}
                  >
                    <Radio.Group className="radio-group">
                      <Radio value={1} disabled={true}>
                        Direct
                      </Radio>
                      <Radio value={0} disabled={true}>
                        In-Direct
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              {approvalList}

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              {/* <Row className="pay-row" gutter={30} justify="start">
                <Col span={24} className="pay-col">
                  <Form.Item
                    label="Remarks"
                    name="remarks"
                    // rules={[
                    //   {
                    //     required: isReject,
                    //     message: "Please, Input Rejection's Reason!",
                    //   },
                    // ]}
                  >
                    <Input.TextArea
                      // disabled={is_edit ? 1 : 0}
                      className="remarks-area"
                      placeholder="Request Purchase Remarks"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Article Inventory List`}
              </Divider>

              <ReceivingApprovalDetailTable
                article={details}
                getEditDetail={handleEditDetail}
                isUpdatePrice={dataPurchase?.is_price_changed}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row className="approve-row" justify="start">
                <Col span={24} className="app-col">
                  <Form.Item
                    label="Approve?"
                    name="is_approve"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Radio.Group
                      className="radio-group"
                      onChange={handleReject}
                    >
                      <Radio value={1}>{`Approve`}</Radio>
                      <Radio value={2}>{`Rejects`}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30} justify="start">
                <Col span={24} className="pay-col">
                  <Form.Item
                    label="Reject Remarks"
                    name="information"
                    rules={[
                      {
                        required: isReject,
                        message: "Please, Input Rejection's Reason!",
                      },
                    ]}
                    hidden={!isReject}
                  >
                    <Input.TextArea
                      // disabled={is_edit ? 1 : 0}
                      className="remarks-area"
                      placeholder="Rejections remarks"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  loading={isLoading}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleCancel}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Context
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Typography,
  Tag,
  Modal,
  Form,
  Empty,
  Result,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Functions
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CONST
const { confirm, info } = Modal;

// STYLE TEXT
const textStyles = {
  fontSize: 15,
  fontWeight: 750,
  margin: "5px 0px 0px",
  // color: "#40a9ff",
  // color: "#2f54eb",
  color: "#1890ff",
};

// CODE
export default function RoomChartTable(props) {
  // PROPS
  const {
    // Room and Charts
    dataRoom,
    dataChart,
    // Date and Days
    dateRange,
    totalDays,
    // Refresh State
    is_refresh,
    // Loading State
    is_loading,
  } = props;

  // TODAY's DATE
  const today = moment();

  // CONTEXT
  const { getRoomStatus, getRoomHistory } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [chart, setChart] = useState([]);
  // Room History
  const [room, setRoom] = useState(null);
  const [cellValues, setCellValues] = useState(null);
  // New Columns
  const [newColumns, setNewColumns] = useState([]);
  // Set Data Date Range
  const [date, setDate] = useState([moment(), moment().add(2, "days")]);
  const [days, setDays] = useState(3);
  // Boolean State
  // Modal
  const [open, setOpen] = useState(false);
  const [views, setViews] = useState(1);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM ANTD
  const [form] = Form.useForm();

  // Table Columns
  const columns = [
    {
      title: "Room Category",
      dataIndex: "room_category_code",
      key: "room_category_code",
      width: 125,
    },
    {
      title: "Room ID",
      dataIndex: "room_id",
      key: "room_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b, c, d, e) => {
        // console.log(" & ", c, d, e);
        return a.room_number - b.room_number;
      },
    },
    // {
    //   title: "Room No",
    //   dataIndex: "room_number",
    //   key: "room_number",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.room_number - b.room_number,
    // },
    // {
    //   title: "Occupants",
    //   dataIndex: "guest_name",
    //   key: "guest_name",
    // },
    // {
    //   title: "Occupancy",
    //   dataIndex: "occupancy_status",
    //   key: "occupancy_status",
    //   render: (occupancy, record) => {
    //     if (occupancy == true) {
    //       return "Occupied";
    //     } else {
    //       return "Unoccupied";
    //     }
    //   },
    // },
  ];

  // Fetch Data
  const fetchData = async (value) => {
    const _cellValues = value;
    const _room_no = value?.room_number;

    // console.log("CELL VALUES: ", cellValues);

    await getRoomHistory({
      reservation: "reservation",
      type: "get-reservation-history",
      reservation_id: _cellValues?.reservation_id || cellValues?.reservation_id,
      date: _cellValues?.date || cellValues?.date,
      room_number: _room_no > 0 ? _room_no : cellValues?.room_number,
      is_step:
        _cellValues?.is_step >= 0 ? _cellValues.is_step : cellValues?.is_step,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Results Room List History >> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0]
            ? response.data.msg[0]
            : response.data.msg;

          setRoom(_res);
        } else {
          setTimeout(() => {
            failedFetch(response);
            setLoading(false);
          }, 1250);
        }

        // setViews(2);
        // setOpen(true);
      },
      onReject: (error) => {
        failedFetch(error);
        setLoading(false);
        setViews(1);
        // setRoom()
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    let cleanUp = false;

    // console.log("Room Number List Use Effects --> ", dataRoom);
    // console.log("Room Chart List Use Effects --> ", dataChart);

    if (dataRoom.length > 0 && dataChart.length > 0 && !cleanUp) {
      // setData(dataRoom);
      // setChart(dataChart);
      handleMerge(dataRoom, dataChart);
    } else {
      // console.log("Room Number List Use Effects --> ", dataRoom);
      // console.log("Room Chart List Use Effects --> ", dataChart);
      setData(dataChart);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataRoom, dataChart]);

  // Use Effect Search
  useEffect(() => {
    let cleanUp = false;

    if (data.length > 0 && !cleanUp) {
      dynamicColumns(data, date);
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // DATE
  useEffect(() => {
    let cleanUp = false;

    if (dateRange?.length > 0 && totalDays && !cleanUp) {
      // console.log("Date Range ---> ", dateRange);
      // console.log("Days ---> ", totalDays);

      setDate(dateRange);
      setDays(totalDays);
    }

    return () => {
      cleanUp = true;
    };
  }, [dateRange, totalDays, data]);

  // NEW COLUMNS
  useEffect(() => {
    let cleanUp = false;

    // console.log("NEW COLUMNS=> ", columns);
    if (columns?.length > 2 && !cleanUp) {
      console.log("NEW COLUMNS USE EFFECTS => ", columns);

      setNewColumns(columns);
    }

    return () => {
      cleanUp = true;
    };
  }, [columns]);

  // FETCH DATA ROOM HISTORY
  useEffect(() => {
    let cleanUp = false;

    // console.log("NEW COLUMNS=> ", columns);
    if (room && views > 0 && !cleanUp) {
      console.log("Room History => ", room);
      console.log("SET VIEWS: ", views);
      // console.log("SELECTED CELLS => ", cellValues);

      // fetchData();
      if (loading == true) {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [room, views]);

  // HANDLE FETCH
  const handleFetch = (value) => {
    const _cellValues = value?.record_book;
    // const _room_no = value?.room_number;
    const _all_record = value?.record;
    const _res_id = _cellValues?.reservation_id.toLowerCase();

    console.log("ON CLICK = ", _cellValues);
    console.log("Reservation ID = ", _res_id);
    // console.log("Room Number = ", _room_no);
    // console.log("Record: ", _record);

    switch (_res_id) {
      case undefined:
      case null:
        setViews(1);
        break;

      case "Out Of Order":
      case "Out of Order":
      case "out of order":
      case " out of order":
        console.log("called: ", _res_id);
        setViews(3);
        break;

      case "Off-Market":
      case "Out Of Service":
      case "Out of Service":
      case "Out Of Service OM":
      case "Out of Service OM":
      case " out of service om":
      case "out of service om":
        console.log("called: ", _res_id);
        setViews(3);
        break;

      default:
        setViews(2);
        break;
    }
    // if (_res_id == undefined || _res_id == null) {
    //   console.log("Null: ", _res_id);
    //   setViews(1);
    // } else if (_res_id.includes("of")) {
    //   console.log("OoO/OM: ", _res_id);
    //   setViews(3);
    // } else {
    //   setViews(2);
    //   console.log("Res: ", _res_id);
    // }

    console.log("Views: ", views);

    if (_cellValues) {
      fetchData(_cellValues);
    } else {
      setLoading(true);
      getDataUnbooked(_all_record);
    }
  };

  // CREATE DYNAMICS COLUMNS
  const dynamicColumns = async (value, date) => {
    const _data = [];
    const _date = date;

    for (let i = 1; i <= totalDays; i++) {
      _data.push({
        id: i,
      });
    }

    // console.log("Data & Date : ", _data, _date);

    _data.map((items, index) => {
      let _number = 0;

      Object.keys(items).forEach((key) => {
        // if (key.indexOf("room_available_") == 0 && index == 0) {
        _number++;

        return (
          <span>
            {columns.push({
              title: moment(_date[0]).add(index, "days").format("YYYY-MM-DD"), //moment(dateRange[0]).format("DD-MM-YYYY"),
              dataIndex: moment(_date[0])
                .add(index, "days")
                .format("YYYY-MM-DD"), // "reservation_id" + "_" + parseInt(index + 1),
              key: moment(_date[0]).add(index, "days").format("YYYY-MM-DD"), //"reservation_id" + "_" + parseInt(index + 1),
              render: (book, record, index) => {
                let txt = "";
                let clr = "";
                let dgr = false;
                let backClr = "";
                let key = "";

                // console.log("Book ID => ", book);

                switch (book) {
                  case undefined:
                    txt = ` `;
                    clr = `default`;
                    dgr = false;
                    backClr = `var(--button-secondary, linear-gradient(262deg, #0BB7AF 0%, #3BE0D8 99.19%))`;
                    key = "available-btn";
                    break;

                  case null:
                    txt = ` `;
                    clr = `#FFFFFF`;
                    dgr = false;
                    backClr = `var(--button-secondary, linear-gradient(262deg, #0BB7AF 0%, #3BE0D8 99.19%))`;
                    key = "available-btn";
                    break;

                  case "Out Of Order":
                    txt = `OUT OF ORDER`;
                    clr = `#FFFFFF`;
                    dgr = false;
                    backClr = `var(--button-primary, linear-gradient(262deg, #00C4F2 2.77%, rgba(160, 0, 174, 0.40) 139.4%))`;
                    key = "ooo-btn";
                    break;

                  case "Out of Order":
                    txt = `OUT OF ORDER`;
                    clr = `#FFFFFF`;
                    dgr = false;
                    backClr = `var(--button-primary, linear-gradient(262deg, #00C4F2 2.77%, rgba(160, 0, 174, 0.40) 139.4%))`;
                    key = "ooo-btn";
                    break;

                  case "Off-Market":
                    txt = `OFF-MARKET`;
                    clr = `#000000`;
                    dgr = false;
                    backClr = `var(--button-tertiary-hover-1, linear-gradient(260deg, #6F7483 -91.97%, #CCD2DC 112.35%))`;
                    key = "off-market-btn";
                    break;

                  case "Out Of Service":
                    txt = `OUT OF SERVICE`;
                    clr = `#FFFFFF`;
                    key = "oos-btn";
                    dgr = false;
                    backClr = `var(--purple-gradient, linear-gradient(262deg, #61045F -60.55%, #AA076B 105.3%))`;
                    break;

                  default:
                    txt = book;
                    clr = `#FFFFFF`;
                    dgr = true;
                    backClr =
                      "linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)";
                    break;
                }

                return (
                  <Row className="btn-row" key={"btn-key-row"} justify="center">
                    <Button
                      className={key}
                      key={"booked-btn"}
                      // type={clr}
                      // danger={dgr}
                      // disabled={book ? false : true}
                      // color={book ? "volcano" : "geekblue"}
                      onClick={(e) => {
                        // console.log("e --> ", e);
                        console.log("Tag Clicked --> ", book);
                        console.log("Row Data --> ", record);
                        // console.log("Row Index --> ", index);
                        // console.log("Row _Row --> ", _row);
                        // console.log("Reservation ID --> ", record[book]);

                        setCellValues({
                          ...record[book],
                          room_number: record.room_number,
                        });

                        handleFetch({
                          record_book: record[book],
                          room_number: record.room_number,
                          record: record,
                        });
                        setOpen(true);
                      }}
                      style={{
                        minWidth: 75,
                        height: "100%",
                        background: backClr,
                        borderColor: "transparent",
                        color: clr,
                        // display: "flex",
                        borderRadius: 5,
                        // justifyContent: "center",
                      }}
                    >
                      {txt}
                    </Button>
                  </Row>
                );
              },
            })}
          </span>
        );
        // }
      });
    });
  };

  // Refresh Data
  const getRefresh = () => {
    is_refresh(true);
  };

  // HANDLE MERGE
  const handleMerge = (chart, room) => {
    const _chart = chart;
    const _room = room;

    // const result = arr1.map((item) => {
    //   const obj = arr2.find((o) => o.id === item.id);
    //   return { ...item, ...obj };
    // });

    let _newArr = [];

    let merged = _room.map((_vRoom) => {
      const obj = _chart.find(
        (_vChart) => _vChart.room_number === _vRoom.room_number
      );

      // console.log("Object ", obj);

      if (obj == undefined) {
        return _newArr.push(_vRoom);
      } else {
        return _newArr.push(obj);
      }
    });

    // console.log("ALL MERGED = ", merged);
    // console.log("New Array = ", _newArr);

    setData(_newArr);
  };

  // HANDLE UNBOOKED
  const getDataUnbooked = (val) => {
    const _room_no = val?.room_number;
    const _record = val;

    // console.log("Record ", _record);

    setRoom({
      reservation_id: "ROOM IS UNBOOKED",
      room_number: _room_no,
      room_category: _record.room_category_name,
      guest_name: "",
      // segment: "",
      departure: null,
      arrival: null,
      description: "Available to Book",
    });

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(false);

    setCellValues(null);
    setRoom(null);
  };

  // console.log(cellValues);

  return (
    <>
      <Row className="room-chart-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            className="row-refresh-btn"
            justify="end"
            gutter={[0, 30]}
            align="top"
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-date-available"
            >
              <Typography
                className="text-date"
                style={{
                  fontSize: 17,
                  fontWeight: 750,
                  color: "#0BB7AF",
                }}
              >
                {`Room Charts from ${moment(
                  date?.length > 0 ? date[0] : ""
                ).format("YYYY-MM-DD")} to ${moment(
                  date?.length > 0 ? date[1] : ""
                ).format("YYYY-MM-DD")}`}
              </Typography>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-refresh-btn"
            >
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={getRefresh}
                  style={{
                    margin: "0px 0px 30px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ width: "100%" }}>
            <Table
              className="room-chart-table"
              name="room-chart-table"
              key="room-chart-table"
              bordered
              loading={is_loading}
              columns={newColumns}
              dataSource={data.length > 0 ? data : []}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 750,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="room-chart-history-modal"
        title={
          <Row>
            <InfoCircleOutlined
              className="title-modal-icon"
              twoToneColor={"geekblue"}
              style={{
                fontSize: 24,
                color: "#3699FF",
                fontWeight: 750,
              }}
            />
            <Typography
              style={{ margin: "0px 0px 0px 15px" }}
            >{`ROOM HISTORY`}</Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleClose}
        width={800}
        footer={null}
      >
        {loading === true && open === true ? (
          // <Row className="loading-row" justify="center" align="middle">
          <Col
            span={24}
            className="loading-col"
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "15px 0px",
            }}
          >
            <LoadingOutlined
              className="loading-spinner"
              spin={true}
              style={{
                fontSize: 100,
                // textAlign: "center",
                color: "#3699FF",
                // display: "flex",
                // justifyContent: "center",
                // padding: "15px 0px",
              }}
            />

            <p>{`Data is being Load! Please, wait for a second!`}</p>
          </Col>
        ) : (
          // </Row>
          <Form
            className="modal-space"
            key="modal-space"
            name="modal-space"
            form={form}
            labelAlign="left"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            // labelWrap={16}
            style={{
              padding: 10,
            }}
            // align="start"
            // size="small"
            // direction="vertical"
          >
            {room ? (
              <>
                <Form.Item label="Reservation ID" style={{ margin: 0 }}>
                  <Typography className="reservation-id" style={textStyles}>
                    {`${room?.reservation_id ? room.reservation_id : "-"}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Guest Name"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="guest-name" style={textStyles}>
                    {`${
                      room?.guest_category == "INCOGNITO"
                        ? "INCOGNITO GUEST"
                        : room?.guest_name || "-"
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item label="Room" style={{ margin: 0 }}>
                  <Typography
                    className="room-category-and-number"
                    style={textStyles}
                  >
                    {`${room?.room_category ? room.room_category : "-"} - 
              ${room?.room_number > 0 ? room.room_number : "UNASSIGNED"}`}
                  </Typography>
                </Form.Item>

                {/* <Form.Item label="Date" style={{ margin: 0 }}>
                  <Typography className="room-date" style={textStyles}>
                    {`${
                      cellValues?.date
                        ? moment(cellValues.date).format("dddd, DD-MM-YYYY")
                        : "-"
                    }`}
                  </Typography>
                </Form.Item> */}

                <Form.Item
                  label="Check-In Status"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="check-in-date" style={textStyles}>
                    {`${
                      room?.is_step == 0
                        ? `Expected Arrival/Check-in at 15.00 on ${moment(
                            room?.arrival
                          ).format("DD-MM-YYYY")}`
                        : room?.is_step == 1
                        ? "Checked-In"
                        : room?.is_step == 2
                        ? "Checked-Out"
                        : "-"
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Night(s)"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="night" style={textStyles}>
                    {`${room?.night ? room.night : "-"}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Arrival Date"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="arrival-date" style={textStyles}>
                    {`${
                      room?.arrival
                        ? moment(room?.arrival).format("DD-MM-YYYY")
                        : " - "
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Check-In Time"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="check-in-date" style={textStyles}>
                    {`${
                      room?.checkin_date
                        ? moment(LocalizationDate(room.checkin_date)).format(
                            "HH:mm, DD-MM-YYYY"
                          )
                        : room?.arrival
                        ? moment(room?.arrival).format("DD-MM-YYYY")
                        : "-"
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Departure Date"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="depart-date" style={textStyles}>
                    {`${
                      room?.departure
                        ? moment(room.departure).format("DD-MM-YYYY")
                        : "-"
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Check-Out Time"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="check-out-date" style={textStyles}>
                    {`${
                      room?.checkout_date
                        ? moment(LocalizationDate(room.checkout_date)).format(
                            "HH:mm, DD-MM-YYYY"
                          )
                        : room?.departure
                        ? moment(room?.departure).format("DD-MM-YYYY")
                        : "-"
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Segment"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="segment" style={textStyles}>
                    {`${room?.segment ? room.segment : "-"}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Rate Code"
                  hidden={views == 2 ? false : true}
                  style={{ margin: 0 }}
                >
                  <Typography className="rate-code" style={textStyles}>
                    {`${room?.rate_code ? room.rate_code : "-"}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Descriptions"
                  hidden={views == 2 ? true : false}
                  style={{ margin: 0 }}
                >
                  <Typography className="reason" style={textStyles}>
                    {`${room?.description ? room.description : room?.reason}`}
                  </Typography>
                </Form.Item>
              </>
            ) : (
              <Result
                className="data-results"
                status={"error"}
                title={"Failed To Get Data!"}
                subTitle={
                  "Please, check your internet connections and try again later!"
                }
                extra={[
                  <Button type="default" key="console" onClick={fetchData}>
                    {`Refresh`}
                  </Button>,
                ]}
              >
                {`If it's still not work, Please, contact Support Center!`}
              </Result>
            )}
          </Form>
        )}

        <Divider
          className="divider-modal"
          style={{
            margin: "5px 0px",
          }}
        />

        <Row className="modal-row" justify="end">
          <Button
            type="default"
            className="close-btn"
            key={"close"}
            htmlType="submit"
            onClick={handleClose}
          >
            {`Close`}
          </Button>
        </Row>
      </Modal>
    </>
  );
}

// eslint-disable-next-line
// Import Type of Messages
import {
  ADMIN_UPDATE_FAIL,
  ADMIN_UPDATE_SUCCESS,
  BLOCK_FAIL,
  BLOCK_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_REGISTER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "../API/AuthActions/types";

// GET USERS
const user = sessionStorage.getItem("roles");
// console.log("Props isLoggin ? ", user);

// Initial Values
const initialState = user
  ? { isLodggedIn: true, user }
  : { isLodggedIn: false, user: null };

// CODE
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  // console.log("State --> ", state);
  // console.log("Type --> ", type);
  // console.log("Payload --> ", payload);

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        register_status: payload?.isRegister,
      };

    case REGISTER_FAIL:
      return {
        ...state,
        register_status: false,
      };

    case RESET_REGISTER:
      return {
        ...state,
        register_status: false,
        is_admin_update: false,
        block_status: false,
        is_update: false,
        is_delete: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLodggedIn: true,
        user: payload?.user.user.user_metadata.roles,
        access_token: payload?.user.access_token,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLodggedIn: false,
        user: null,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLodggedIn: false,
        user: null,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        isLodggedIn: true,
        // user: payload?.user.user.user_metadata.roles,
        // access_token: payload?.user.access_token,
      };

    case ADMIN_UPDATE_SUCCESS:
      return {
        ...state,
        is_admin_update: payload?.isAdminUpdate,
      };

    case ADMIN_UPDATE_FAIL:
      return {
        ...state,
        is_admin_update: payload?.isAdminUpdate,
      };

    case BLOCK_SUCCESS:
      return {
        ...state,
        block_status: true,
      };

    case BLOCK_FAIL:
      return {
        ...state,
        block_status: false,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        is_update: true,
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        is_update: false,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        is_delete: true,
      };

    case DELETE_USER_FAILED:
      return {
        ...state,
        is_delete: false,
      };

    default:
      return state;
  }
}

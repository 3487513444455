// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Context
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  List,
  Card,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { HiPrinter } from "react-icons/hi2";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import {
  FaFileDownload,
  FaConciergeBell,
  FaMoneyBillWave,
} from "react-icons/fa";

// Import Page Components
import ModalTableOrder from "../../../../Modals/Outlet/Order/Modal";
import TableOrderPayment from "../../../../Modals/Outlet/Order/Payment";
import TableCategoryCard from "../../../../Widgets/Outlet/CategoryCard/CategoryCard";
import TableListCard from "../../../../Widgets/Outlet/TableList/TableList";
import CreateOrderPage from "../../../../../Pages/Outlet/Order/CreateOrder/CreateOrder";
// import LoadingComponents from "../../../Reusable/Spinner/LoadingScreen";
// import PerTablePage from "./PerTable";
// import Spinner from "../../../Reusable/Spinner/Spinner";
// import WidgetTableOrder from "../../../Widgets/Outlet/TableOrder/WidgetOrder";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// Modals
const { confirm } = Modal;

// CODE
export default function POSOrder(props) {
  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // Props
  const {
    table_number,
    outlet_data,
    selections,
    is_roomService,
    is_search,
    searchKey,
    is_refresh,
  } = props;

  // CONTEXT
  const { getTableList } = useContext(MasterContext);
  const { getRoomStatus } = useContext(MainContext);

  // STATE MANAGEMENT
  // State Page
  const [stateOforder, setStateOforder] = useState(1); // 1 For Loaded, 2  for Order
  // Data Table
  const [data, setData] = useState([]);
  const [dataBulks, setDataBulks] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [tableSelected, setTableSelected] = useState({
    id: null,
    table_code: "No Table",
    seat: "0",
    table_id: null,
    status_table: 1,
    description: "No Table",
    created_date: "2023-11-28T06:27:54.912808",
    created_by: "SUPER_ADMIN",
    updated_date: "2023-11-29T07:59:21.272516",
    updated_by: "SUPER_ADMIN",
    deleted_date: null,
    deleted_by: null,
    is_deleted: false,
    table_category_id: 0,
    outlet_location_id: 0,
    table_category_code: "",
    outlet_location_code: "",
    hotel_id: null,
  });
  // Outlet
  const [selectedData, setSelectedData] = useState({
    shift: "UNSELECTED",
    locations: "UNSELECTED",
    locations_id: 0,
    shift_id: 0,
  });
  // Card Button & Categories
  const [cardButton, setCardButton] = useState([]);
  const [categories, setCategories] = useState({
    table_category_code: "",
    table_category_name: "",
    table_category_id: 0,
  });
  // Room Service
  const [isRoomService, setIsRoomService] = useState(false);
  // Loading State
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Boolean
  const [edit, setEdit] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  // Fetch Filter
  const fetchFilter = async (value) => {
    const _id = value;

    await getTableList({
      type: "table-by-category",
      table_category_id: _id > 0 ? _id : categories.table_category_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Outlet Table Filter: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setDataBulks(_res);
        } else {
          setData([]);
          setDataBulks([]);
        }

        setLoading(false);

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  // Fetch Room
  const fetchRoom = async (value) => {
    await getRoomStatus({
      status: "status",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ISI Room List >> ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            if (val?.is_deleted == false || val?.is_deleted == null) {
              return val;
            }
          });

          setBulks(filtered);

          if (value === true) {
            handleFilterImmediate(filtered);
          }
        } else {
          // setData([]);
          setBulks([]);
        }

        setLoading(false);
        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Room Service: ", is_roomService);
    if (is_roomService === true && !cleanUp) {
      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_roomService]);

  // Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Data Effects
  useEffect(() => {
    let cleanUp = false;

    if (outlet_data?.length > 0 && !cleanUp) {
      console.log("Data Outlet: ", outlet_data);

      setCardButton(outlet_data);
      setStateOforder(1);
    } else {
      setCardButton([]);
    }

    if (selections?.locations_id > 0 && !cleanUp) {
      console.log("Selected: ", selections);

      setSelectedData({
        locations: selections?.locations,
        shift: selections?.shift,
        locations_id: selections?.locations_id,
        shift_id: selections?.shift_id,
      });

      setData([]);
      fetchRoom();
      setStateOforder(1);
    } else {
      setSelectedData({
        locations: "",
        shift: "",
        locations_id: 0,
        shift_id: 0,
      });
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [selections, outlet_data, is_roomService]);

  // Handle Filter
  const handleFilter = (params) => {
    // const _data = value;
    const _code = params.toLowerCase();

    let _filtered = bulks.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Value: ", _filtered);

    setData(_filtered);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  // Handle Filter
  const handleFilterImmediate = (value) => {
    const _data = value;
    const _code = categories.table_category_code.toLowerCase();

    let _filtered = _data.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Value: ", _filtered);

    setData(_filtered);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle Refresh
  const handleRefresh = () => {
    const _val = true;
    setIsLoading(true);

    switch (isRoomService) {
      case true:
        fetchRoom(_val);
        break;

      case false:
        fetchFilter();
        break;

      default:
        break;
    }
  };

  // Refresh Categories
  const handleRefreshCat = () => {
    is_refresh(true);
  };

  const handleOpenPay = () => {
    setOpenPay(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // Handle Button Card
  const handleButtonCard = (value) => {
    const _data = value;
    setIsLoading(true);

    console.log("Table Cat: ", _data);

    setCategories(_data);

    if (isRoomService) {
      handleFilter(_data?.table_category_code);
    } else {
      fetchFilter(_data?.table_category_id);
    }
  };

  // HANDLE TABLE
  const handleTable = (val, ind) => {
    const _number = val;
    const _index = ind;

    console.log("Table Selected --> ", _number);
    // console.log("Table Index --> ", _index);

    setTableSelected(_number);
    table_number(_number);
    setStateOforder(2);

    // if (_number?.status_table == 1) {
    //   if (isChangeTable) {
    //     setOpenModalResvTable(true);
    //   }
    // } else {
    //   setStateOforder(2);
    // }

    // setTimeout(() => {
    //   setStateOforder(3);
    // }, 2000);
  };

  // ON RETURN
  const handleNavigateReturn = () => {
    navigate(`${pathname}`.substring(0, pathname.length - 12), {
      state: {},
    });
  };

  // ON CREATED
  const handleCreate = (value) => {
    if (value === true) {
      setStateOforder(1);
      handleRefresh();
    } else {
      setStateOforder(2);
    }
  };

  // Handle Search
  const handleSearch = () => {
    const _data = isRoomService ? bulks : dataBulks;

    // console.log("Search: ", searchKey);

    let _updatedList = _data.filter((item) => {
      // console.log("Items: ", item);

      if (isRoomService) {
        return (
          item?.room_category_code
            .toLowerCase()
            .indexOf(searchKey.toLowerCase()) !== -1 ||
          item?.room_category_name
            .toLowerCase()
            .indexOf(searchKey.toLowerCase()) !== -1
        );
      } else {
        return (
          item?.table_code.toLowerCase().indexOf(searchKey.toLowerCase()) !==
            -1 ||
          item?.table_id.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1
        );
      }
    });

    console.log("Filter Search: ", _updatedList);
    setData(_updatedList);
  };

  // Render Components
  return (
    <CreateOrderPage
      tableSelected={tableSelected}
      is_return={handleNavigateReturn}
      key={"per-order"}
      is_room_service={isRoomService}
      is_refresh={handleCreate}
    />
  );
}

import moment from "moment";

// CODE
export default function FilterEarlyCO(props) {
  // PROPS
  const { article_stay, article_room, isAbf, bf_id } = props;

  // Declare Variables
  let _newBill = [];
  const _today = moment().format("YYYY-MM-DD");

  console.log("About to Early: ", props);

  const newRoom = article_room.filter((items) => {
    let _date = moment(items.date).format("YYYY-MM-DD");
    let _newToday = moment(_today).subtract(1, "days").format("YYYY-MM-DD");

    // console.log(
    //   "Room: ",
    //   moment(items.date).format("YYYY-MM-DD"),
    //   " = ",
    //   moment(_today).isBefore(_date, "days")
    // );

    if (moment(_newToday).isBefore(_date, "days") == false) {
      return items;
    }
  });

  const newArticles = article_stay.filter((items) => {
    let _date = moment(items?.order_date).format("YYYY-MM-DD");
    let _newToday = _today;

    if (isAbf) {
      _newToday = _today;
    } else {
      _newToday = moment(_today).subtract(1, "days").format("YYYY-MM-DD");
    }

    // console.log(
    //   "Article: ",
    //   moment(items.date).format("YYYY-MM-DD"),
    //   " = ",
    //   moment(_today).isBefore(_date, "days")
    // );

    if (items.article_id == bf_id) {
      if (moment(_newToday).isBefore(_date, "days") == false) {
        return items;
      }
    } else {
      if (moment(items?.order_date).isBefore(_today, "days") == false) {
        return items;
      }
    }
  });

  //   if (bill?.length > 0) {
  //     const newBill = bill.filter((items) => {
  //       let _date = moment(items.date).format("YYYY-MM-DD");

  //       return moment(_today).isBefore(_date, "days");
  //     });

  //     console.log("New Bill: ", newBill);
  //   }

  console.log("Article Room: ", newRoom);
  console.log("Article Stay: ", newArticles);

  return { room: newRoom, article: newArticles };
}

// REACT COMPONENTS
import React from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../../../../Style/Global/Global";

// REACT ICONS
import { FaBalanceScale } from "react-icons/fa";
import { HiScale } from "react-icons/hi2";
import { CgDetailsMore } from "react-icons/cg";

export default function BalanceSheetReportPage() {
  const navigate = useNavigate();

  const move = (path) => {
    navigate(path);
  };
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <FaBalanceScale
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Balance Sheet Report`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("balance-sheet-main-report")}
                    // type="primary"
                    icon={<HiScale style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Main Balance Sheet`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("balance-sheet-detail-report")}
                    // type="primary"
                    icon={<CgDetailsMore style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Detail Balance Sheet`}
                    </Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

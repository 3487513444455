// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterIdCardType({ getIdCardType, card }) {
  // CONTEXT
  const { getMasterIdCardType } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [cardType, setCardType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = () => {
    getMasterIdCardType({
      idcard: "idcard-type",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response => ", response);

        let option = response.data.msg.map((dev) => ({
          value: dev.id_card_type,
          label: dev.id_card_type_id,
        }));

        setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // console.log(optionValue);
  // console.log(card);

  return (
    <>
      <Select
        key={optionValue.id_card_type_id}
        style={{ width: "100%" }}
        value={cardType != null ? cardType : card}
        onChange={(card) => {
          setCardType(card);
          console.log(card);

          getIdCardType(card);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
      />
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import moment from "moment";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";

// Import Page Components
import MasterIdCardType from "../../Reusable/Master/IdCardType/IdCardType";
import MasterTitle from "../../Reusable/Master/Title/Title";
import MasterPaymentType from "../../Reusable/Master/PaymentType/PaymentType";
import MasterCountry from "../../Reusable/Master/Country/Country";
import MasterSexType from "../../Reusable/Master/Sex/Sex";

// Import Notification
import {
  failedGuest,
  incompleteGuest,
  successGuest,
} from "../../Reusable/Notification/Notification";

// Modal
const { confirm } = Modal;

// CODE
export default function ModalCompanyOTA({
  openModal,
  closeModal,
  dataGuest,
  is_refresh,
}) {
  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  // Data Guest About to Edit
  const [edit, setEdit] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      console.log("DATA IN MODAL EDIT > ", edit);
      console.log("Modal State > ", open);

      setOpen(openModal);
      setEdit(dataGuest);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest]);

  // HANDLE SHOW
  useEffect(() => {
    if (open == true && edit != null) {
      setFieldForm(edit);
    }
  }, [open]);

  // };
  // HANDLE SELECTOR MASTER
  // Get ID Card Type
  const getIdCardType = (value) => {
    if (open) {
      edit.id_card_type = value;
    }

    form.setFieldsValue({
      id_card_type: value,
    });
  };
  // Get Title Type
  const getTitleType = (value) => {
    form.setFieldsValue({
      title: value,
    });
  };
  // Get Sex
  const getSexType = (value) => {
    form.setFieldsValue({
      sex: value,
    });
  };
  // Get Payment Type
  const getPaymentType = (value) => {
    form.setFieldsValue({
      payment_type: value,
    });
  };
  // Get Payment Type
  const getPayTypeId = (value) => {
    form.setFieldsValue({
      payment_type_id: value,
    });
  };
  // Get Country and Nationality
  const getCountry = (value) => {
    form.setFieldsValue({
      country: value,
      nationality: value,
    });
  };

  // SET FIELD FORMS
  const setFieldForm = async (value) => {
    const fieldVal = value;

    console.log(fieldVal);

    form.setFieldsValue({
      full_name: fieldVal?.full_name,
      first_name: fieldVal?.first_name,
      id_card_type: fieldVal?.id_card_type,
      id_card_number: fieldVal?.id_card_number,
      phone_number: fieldVal?.phone_number,
      birthdate: moment(fieldVal?.birthdate),
      nationality: fieldVal?.nationality,
      country: fieldVal?.country,
      province: fieldVal?.province,
      local_region: fieldVal?.local_region,
      city: fieldVal?.city,
      zip_code: fieldVal?.zip_code,
      address: fieldVal?.address,
      sex: fieldVal?.sex,
      email: fieldVal?.email,
      fax: fieldVal?.fax,
      main_segment: fieldVal?.main_segment,
      comment: fieldVal?.comment,
      payment_type: fieldVal?.payment_type,
      credit_limit: fieldVal?.credit_limit,
      master_comp: fieldVal?.master_comp,
    });
  };

  // ON FINISH
  const onFinish = async (value) => {
    const content = value;

    showModalConfirm(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = async (val) => {
    console.log(val);

    incompleteGuest(val);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = async (val) => {
    const dataModal = val;

    confirm({
      // icon: <ExclamationCircleTwoTone />,
      className: "modal-confirm",
      title: `Are you sure want to Update a Guest's Data of ${
        dataModal?.full_name.toUpperCase() || "UNKNOWN"
      }?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(dataModal);
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("EDITED > ", e);

    const id = edit?.id;
    const contentEdit = e;

    await axios
      .put(
        `${baseurl}/guest/card/`,
        {
          id: id,
          full_name: contentEdit?.full_name,
          first_name: contentEdit?.first_name,
          id_card_type: contentEdit?.id_card_type,
          id_card_number: contentEdit?.id_card_number,
          phone_number: contentEdit?.phone_number,
          birthdate: contentEdit?.birthdate,
          nationality: contentEdit?.nationality,
          country: contentEdit?.country,
          province: contentEdit.province,
          local_region: contentEdit?.local_region,
          city: contentEdit?.city,
          zip_code: contentEdit?.zip_code,
          address: contentEdit?.address,
          sex: contentEdit?.sex,
          email: contentEdit?.email,
          payment_type: contentEdit?.payment_type,
          master_comp: contentEdit?.master_comp,
          credit_limit: null,
          main_segment: null,
          fax: null,
          comment: null,
          // is_dummy: true,

          updated_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Update success --> ", response);

        successGuest(response, { method: 1 });
        handleCancel();

        is_refresh(true);
      })
      .catch((error) => {
        console.log("Update Failed --> ", error);

        failedGuest(error, { method: 1 });
      });
    // .finally(() => {
    // setAddGuestModal(false);
    // });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setEdit(null);

    form.resetFields();
  };

  // CONSOLE LOG
  // console.log("DATA IN MODAL EDIT > ", edit);
  // console.log("Modal State > ", open);

  return (
    <>
      <Modal
        className="edit-modal-guest"
        title={
          <>
            <Row>
              <FormOutlined style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add New Company`}
              </Typography>
            </Row>
          </>
        }
        // getContainer={false}
        // forceRender={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          key="guest-modal-form"
          name="guest-modal-form"
          className="guest-modal-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // initialValues={
          //   handleShow
          //   full_name: edit.full_name,
          //   first_name: edit.first_name,
          //   id_card_type: edit.id_card_type,
          //   id_card_number: edit.id_card_number,
          //   phone_number: edit.phone_number,
          //   birthdate: moment(edit.birthdate),
          //   nationality: edit.nationality,
          //   country: edit.country,
          //   province: edit.province,
          //   local_region: edit.local_region,
          //   city: edit.city,
          //   zip_code: edit.zip_code,
          //   address: edit.address,
          //   sex: edit.sex,
          //   email: edit.email,
          //   fax: edit.fax,
          //   main_segment: edit.main_segment,
          //   comment: edit.comment,
          //   payment_type: edit.payment_type,
          //   credit_limit: edit.credit_limit,
          //   master_comp: edit.master_comp,
          // }}
          layout="vertical"
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item
                label="ID Card Type"
                name="id_card_type"
                style={{ width: 205, marginRight: 30 }}
              >
                <MasterIdCardType
                  getIdCardType={getIdCardType}
                  card={edit?.id_card_type ? edit.id_card_type : ""}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="ID Card No"
                name={"id_card_number"}
                style={{ width: 205, marginRight: 30 }}
              >
                <Input
                  //   onChange={(e) => handleChange(e)}
                  placeholder="ID Card Number"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item>
                <Button type="primary" style={{ marginTop: 30 }}>
                  Find Data
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-guest-name">
            <Col span={8}>
              <Form.Item
                label="Full Name"
                name={"full_name"}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Guest's Full Name!",
                  },
                ]}
                style={{ width: 205, marginRight: 30 }}
              >
                <Input placeholder="Full Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="First Name"
                name={"first_name"}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Guest's First Name!",
                  },
                ]}
                style={{ width: 205, marginRight: 30 }}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Title" name={"title"} style={{ width: 205 }}>
                <MasterTitle
                  getTitleType={getTitleType}
                  title={
                    edit?.sex == "Male"
                      ? "Mr."
                      : edit?.sex == "Female"
                      ? "Ms."
                      : "Mrs."
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30}>
            <Col span={8}>
              <Form.Item
                label="Phone"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Phone Number!",
                    type: "number",
                    min: 10,
                  },
                ]}
              >
                <InputNumber
                  placeholder="Phone Number"
                  style={{ marginRight: 30, width: 205 }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Birth Date"
                name="birthdate"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Guest's Birthdate!",
                  },
                ]}
                style={{ marginRight: 30, width: 205 }}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Nationality"
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Guest's Nationality!",
                  },
                ]}
                style={{ width: 205 }}
              >
                <MasterCountry
                  getCountry={getCountry}
                  country={edit ? edit?.country : ""}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-guest-country">
            <Col span={8}>
              <Form.Item
                label="Country"
                name="country"
                style={{ marginRight: 30, width: 205 }}
              >
                <MasterCountry
                  getCountry={getCountry}
                  country={edit ? edit?.country : ""}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Province"
                name="province"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Province" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Local Region"
                name="local_region"
                style={{ width: 205 }}
              >
                <Input placeholder="Local Region" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-guest-address">
            <Col span={8}>
              <Form.Item
                label="City"
                name="city"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="City" />
              </Form.Item>

              <Form.Item
                label="Zip Code"
                name="zip_code"
                style={{ marginRight: 30, width: 205 }}
              >
                <InputNumber
                  placeholder="Zip Code"
                  type={"number"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message:
                      "Guest Address must be filled and cannot be empty!",
                  },
                ]}
                style={{ width: 440 }}
              >
                <Input.TextArea placeholder="Address" rows={5} />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px" }} />

          <Row gutter={30} className="row-guest-gender">
            <Col span={8}>
              <Form.Item
                label="Sex"
                name="sex"
                style={{ marginRight: 30, width: 205 }}
              >
                <MasterSexType
                  getSexType={getSexType}
                  sex={edit ? edit.sex : ""}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                  },
                ]}
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>

            {/* <Col span={8}>
              <Form.Item label="Fax" name="fax">
                <InputNumber
                  placeholder="Fax"
                  type={"number"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col> */}
          </Row>

          <Divider style={{ margin: "15px 0px" }} />

          {/* <Row gutter={30}>
            <Col span={8}>
              <Form.Item label="Main Segment" name="main_segment">
                <Input.TextArea placeholder="Main Segment" rows={3} />
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item label="Comment" name="comment">
                <Input.TextArea placeholder="Comment" rows={3} />
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={30} className="row-guest-pay">
            <Col span={8}>
              <Form.Item
                label="Payment Type"
                name="payment_type"
                style={{ marginRight: 30, width: 205 }}
              >
                <MasterPaymentType
                  getPaymentType={getPaymentType}
                  pay={edit ? edit.payment_type : ""}
                  getPayTypeId={getPayTypeId}
                />
              </Form.Item>
            </Col>

            {/* <Col span={8}>
              <Form.Item label="Credit Limit" name="credit_limit">
                <InputNumber
                  placeholder="Credit Limit"
                  type={"number"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col> */}

            <Col span={8}>
              <Form.Item
                label="Master Comp"
                name="master_comp"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Master Comp" />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px" }} />

          <Row
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-guest"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

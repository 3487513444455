// Import React Components
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Layout, Result, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

const { Paragraph, Text, Title } = Typography;

// CODE
export default function ResultFailed() {
  // USE NAVIGATION
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("State --> ", state);

  // HOME MOVE
  const move = (value) => {
    console.log(value);

    navigate("/channel-manager/dashboard");
  };
  const back = (value) => {
    console.log(value);

    navigate("/channel-manager/reservation-payment-gateway", {
      state: {
        data: state.data,
        article: state.article,
        guest: state.guest,
      },
    });
  };

  return (
    <>
      <Layout
        className="layout-process"
        style={{
          borderRadius: 5,
        }}
      >
        <Content
          className="content-main"
          style={{
            margin: "65px 30px 65px",
            padding: 24,
            minHeight: "50vh",
            borderRadius: 5,
          }}
        >
          <Result
            className="error-result"
            status="error"
            title="Submission Failed"
            subTitle="Please check your connection and try again later!"
            extra={[
              <Button type="primary" key="console" onClick={back}>
                Go Console
              </Button>,
              <Button key="buy" className="home-btn" onClick={move}>
                Buy Again
              </Button>,
            ]}
          >
            <div className="desc">
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  The content you submitted has the following error:
                </Text>
              </Paragraph>

              <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" />
                {`Code Error: ${state?.message?.stat || 400}`}
              </Paragraph>

              <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" />
                {`Code Error: ${state?.message?.statText || "UNKNOWN"}`}
              </Paragraph>

              <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" />
                {`Message Error: ${
                  state?.message?.data.detail ||
                  "FAILURE IN CREATES RESERVATION!"
                }`}
              </Paragraph>
            </div>
          </Result>
        </Content>
      </Layout>
    </>
  );
}

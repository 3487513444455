// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// Import Ant Design's Components
import { Select } from "antd";

// CODE
export default function MasterModules(props) {
  // PROPERTIES
  const { getModule, modules, isDisabled } = props;

  // STATE MANAGEMENT
  const [moduleID, setModuleID] = useState([]);
  const [moduleName, setModuleName] = useState([]);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/auth/module`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _filtered = _res.filter((val) => {
            return val.is_parent === true;
          });

          handleMap(_filtered);
        } else {
          setOptionValue([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
      });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (modules?.length > 0 && !cleanUp) {
      console.log("Parent Module: ", modules);
      setModuleID(modules);
    } else {
      setModuleName([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [modules]);

  useEffect(() => {
    let cleanUp = false;

    if (moduleID?.length > 0 && optionValue.length > 0 && !cleanUp) {
      console.log("EDIT!");
      handleEdit(moduleID, optionValue);
    } else {
      setModuleName([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [optionValue, moduleID]);

  // Handle Map
  const handleMap = (val) => {
    const _filtered = val;

    let optionFilter = _filtered.map((value) => ({
      id: value?.id,
      key: value?.id,
      value: value?.name,
      label: value?.name,
      code: value?.code,
      desc: value?.description,
      disabled: value?.is_deleted,
    }));

    setOptionValue(optionFilter);
    setModuleName([]);
  };

  // Handle Change
  const handleChange = (val, all) => {
    const _value = val;
    const _allKeys = all;

    console.log("INI CODE ", _allKeys);

    setModuleName(_value);
    getModule(_allKeys);
  };

  // Handle Change
  const handleClear = () => {
    setModuleName([]);
  };

  // Handle Edit
  const handleEdit = (val, all) => {
    const _value = val;
    const _allKeys = all;

    let _arr = [];

    let results = _value.filter(
      (item1) =>
        !_allKeys.some((item2) => {
          if (item2?.id === item1) {
            return _arr.push(item2);
          }
        })
    );

    // console.log("Results: ", results);
    console.log("Results: ", _arr);

    setModuleName(_arr);
  };

  return (
    <>
      <Select
        className="module-select"
        key="module-select"
        allowClear
        showSearch
        disabled={isDisabled}
        value={moduleName}
        placeholder="Select Modules"
        mode="multiple"
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

// REACT COMPONENTS
import React, { useState } from "react";

// ANTD COMPONENTS
import { Button, Col, Input, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { SearchOutlined } from "@ant-design/icons";

// Import React Icons
import { TbCheckupList } from "react-icons/tb";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import POReportTable from "../../../../../Components/Table/Reports/BackOffice/Purchasing/PurchaseOrder/Table";

// CODE
export default function POReportPage() {
  // STATE MANAGEMENT
  // DATA
  const [value, setValue] = useState(null);
  // Search
  const [search, setSearch] = useState(null);

  const handleSearch = (e) => {
    e.preventDefault();

    setValue(e.target.value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <TbCheckupList
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Purchase Order List Reports`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Col
                style={{
                  textAlign: "start",
                  margin: "22px 15px 22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`PO Number`}</Typography>

                <Input
                  allowClear
                  placeholder="Keyword"
                  onChange={handleSearch}
                  style={{
                    width: 300,
                  }}
                />
              </Col>

              <Col
                className="search-btn-col"
                style={{
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  type="primary"
                  className="search-btn"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Row>

            <POReportTable is_search={setSearch} searchKey={value} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

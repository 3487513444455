// Import React's Components
import React, { useState, useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

// Import Components
import MainLayout from "./Layout/MainLayout/MainLayout";
import LoginPage from "./Pages/Auth/Login/Login";
import ManagerMainPage from "./Pages/Manager/ChannelManager/Manager";
import ChangelogVersionPage from "./Pages/Changelog/Versions/Version";
import ProtectedRoute from "./Routes/Protected/ProtectedRoute";

// Import APP CSS
import "./App.css";

/* eslint-disable */

// CODE
function App() {
  // STATE MANAGAMENT
  // LOGGED IN
  // const isLoggedIn = props?.user?.isLodggedIn ? props?.user.isLodggedIn : false;
  const [tokenExist, setTokenExist] = useState(false);

  const [theme, setTheme] = useState(null);

  const resetTheme = () => {
    setTheme(null);
  };

  // STORE TOKEN
  const token = sessionStorage.getItem("auth-token");
  const userRoles = sessionStorage.getItem("roles");

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (token && !cleanUp) {
      // console.log("Token: ", token);
      setTokenExist(true);
    } else {
      setTokenExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [token]);

  // const themeClass = theme ? theme.toLowerCase() : "secondary";

  // console.log("APIS --> ", process.env.REACT_APP_BASEUR);
  // console.log("TOKEN --> ", userRoles);
  // console.log("IS LOGGIN ? --> ", props?.user.isLodggedIn);
  // console.log("IS LOGIN ?? ", tokenExist);
  // console.log("Props = ", props);
  // console.log("Users Roles --> ", roles);

  return (
    <div className="App">
      <BrowserRouter>
        {token ? (
          // {isLoggedIn ? (
          // <ProtectedRoute isSignedIn={isLoggedIn}>
          <MainLayout />
        ) : (
          // </ProtectedRoute>
          <>
            <Routes>
              <Route path="*" element={<LoginPage />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/home" element={<LoginPage />} />
              <Route path="/channel-manager/*" element={<ManagerMainPage />} />
            </Routes>
            <Outlet />
          </>
        )}
      </BrowserRouter>
      {/* <Sidebar /> */}
    </div>
  );
}

export default App;

// Import React's Components
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// API
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// Import Antd's Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// Import Antd's Icon
import { FaClockRotateLeft } from "react-icons/fa6";

// Import Page Components
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// CODE
export default function PriceHistoryModal(props) {
  // PROPS
  const { is_open, is_close, articles } = props;

  // STATE MANAGEMENT
  // Data
  const [dataOverview, setDataOverview] = useState(null);
  const [table, setTable] = useState([]);
  // Booleans
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Use Form
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await axios
      .post(
        `${baseurl}/article/price-management/price-history`,
        { id_article: articles?.id },
        {
          headers: { Authorization: `Bearer ${token || null}` },
        }
      )
      .then((response) => {
        console.log("History Price: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setTable(_res);
        } else {
          setTable([]);
        }
      })
      .catch((error) => {
        console.log("Failed: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    if (is_open && !cleanUp) {
      setOpen(true);

      if (articles && !cleanUp) {
        setDataOverview(articles);
        handleFormField(articles);
        fetchData();
      } else {
        setTable([]);
      }
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, articles]);

  // Columns
  const columns = [
    {
      title: "Updated Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (date) => {
        return moment(LocalizationDate(date)).format("HH:mm, DD MMM YYYY");
      },
    },
    {
      title: "Updated By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Suppliers",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
    },
  ];

  // Handle Form Field
  const handleFormField = (val) => {
    const record = val;

    console.log("Articles: ", record);

    form.setFieldsValue({
      article_name: record?.article_name,
      article_number: record?.article_number,
      department_name: record?.department_name,
      average_price: record?.average_price,
      is_dml: record?.is_dml,
    });
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);
    setOpen(false);

    setDataOverview(null);
    setTable([]);
  };

  return (
    <>
      <Modal
        className="quotation-modals"
        key="quotation-modals"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FaClockRotateLeft
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Price Changes Overview`}</Typography>
            </Row>
          </>
        }
        open={open}
        closable
        centered
        width={"50%"}
        onCancel={handleClose}
        // onOk={handleClose}
        bodyStyle={{
          padding: 30,
        }}
        footer={null}
      >
        <Divider
          orientation="left"
          orientationMargin={0}
          className="divider-form"
          style={{ margin: "0px 0px 10px", borderColor: "#EBEDF3" }}
        >
          {`Article Details`}
        </Divider>

        <Row className="title-row">
          <Col span={24} className="main-col">
            <Form
              form={form}
              className="title-form"
              layout="horizontal"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
            >
              <Row className="article-row" gutter={30}>
                <Col span={12} className="article-col">
                  <Form.Item name="article_name" label="Article's Name">
                    <Input />
                  </Form.Item>

                  <Form.Item name="article_number" label="Article's Number">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="article-col">
                  <Form.Item name="department_name" label="Department">
                    <Input />
                  </Form.Item>

                  <Form.Item name="average_price" label="Average Price">
                    <InputNumber
                      className="avg-price"
                      style={{
                        width: "100%",
                      }}
                      formatter={(price) => {
                        return `${price > 0 ? price : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                className="chckbx-row"
                justify="end"
                style={{ width: "100%" }}
              >
                <Col span={16} className="article-col"></Col>

                <Col span={8} className="article-col">
                  <Form.Item
                    name="is_dml"
                    // label="Daily Market List"
                    valuePropName="checked"
                  >
                    <Checkbox
                      style={{
                        width: "100%",
                      }}
                    >{`Daily Market List`}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Divider
          orientation="left"
          orientationMargin={0}
          className="divider-form"
          style={{ margin: "0px 0px 10px", borderColor: "#EBEDF3" }}
        >
          {`Price Change's History`}
        </Divider>

        <Row className="table-row">
          <Table
            className="price-overview-table"
            key="price-overview-table"
            name="price-overview-table"
            columns={columns}
            loading={isLoading}
            dataSource={table}
            bordered
            scroll={{
              x: true,
            }}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            rowKey={(record) =>
              record.quotation_number ? record.quotation_number : record.key
            }
          />
        </Row>

        <Row className="btn-row" justify="end">
          <Button type="default" className="cancel-btn" onClick={handleClose}>
            {`Close`}
          </Button>
        </Row>
      </Modal>
    </>
  );
}

// Import React's Components
import React, { useState } from "react";

// Import Ant Design Components
import { Col, Input, Layout, Row, Space, Typography, Button, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GoPasskeyFill } from "react-icons/go";
import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";

// Import Page Components
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";
import AuthTabs from "../../../Components/Tabs/Auth/AuthTabs";

// CODE
export default function UserManagementPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState(null);

  const handleSearch = (val) => {
    val.preventDefault();

    const _value = val.target.value;

    setValue(_value);
  };

  // On Change Tabs
  const onChangeTabs = (value) => {
    console.log(value);
  };
  const onChangeChildren = (value) => {
    console.log(value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="users-main-layout">
            <Row className="users-title" justify="start" style={mainBody}>
              <GoPasskeyFill
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`User's Role Management & Permissions`}
              </Typography>
            </Row>

            <Row
              className="space-component"
              justify="start"
              style={{ margin: 15 }}
            />

            <AuthTabs />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

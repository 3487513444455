// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { baseurl } from "../../../../../../API/Config/Api";
import { token } from "../../../../../../API/Global/Payload";

// CONTEXT
import { Context as FinanceContext } from "../../../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Tooltip,
  Tag,
  Modal,
  Typography,
  Input,
  Select,
  InputNumber,
  DatePicker,
} from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons/lib/icons";

// Import Functions
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// Import Global Style
import { mainBody } from "../../../../../../Style/Global/Global";

// CODE
export default function HistoryTrialBalanceTable() {
  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getTrialBalance } = useContext(FinanceContext);

  // COLUMNS
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "COA",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
      //   render: (_, record, index) => {
      //     return index + 1;
      //   },
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      //   render: (_, record, index) => {
      //     return index + 1;
      //   },
    },
    {
      title: `Debit ( ${currency} )`,
      dataIndex: "debit",
      key: "debit",
      render: (debit) => {
        return `${debit > 0 ? debit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Credit ( ${currency} )`,
      dataIndex: "credit",
      key: "credit",
      render: (credit) => {
        return `${credit > 0 ? credit : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [monthPicked, setMonthPicked] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // FETCH DATA
  const fetchData = async (value) => {
    // await getTrialBalance({
    //   type: "trial-balance-history",
    //   onAwait: () => {
    setLoading(true);
    //   },
    //   onSuccess: (response) => {
    //     console.log("Balance Sheet --> ", response);

    //     setData(response.data?.msg);
    //     setLoading(false);
    //   },
    //   onReject: (error) => {
    //     console.log("Err --> ", error);
    //     setLoading(false);
    //   },
    // });

    await axios
      .post(
        `${baseurl}/accounting/trial-balance-history`,
        {
          date: moment(value ? value : monthPicked).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Response: ", response);

        console.log(typeof response?.data);
        if (typeof response?.data == "object") {
          const _res = response.data;

          let filtered = _res.filter((value) => {
            if (value?.chart_of_account != "TOTAL") {
              return value;
            }
          });

          console.log(filtered);

          setData(filtered);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });

    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);
  };

  // USE EFFECT
  //   useEffect(() => {
  //     fetchData();
  //   }, []);

  // Return
  const move = () => {
    const path = "/report/accounting/monthly-accounting-report";
    // console.log(pathname);
    // console.log(search);

    navigate(`${path}`);
  };

  // HANDLE MONTH
  const handleMonth = (val) => {
    const _month = val;
    console.log("MONTH: ", _month);

    setMonthPicked(_month);

    fetchData(_month);
  };

  return (
    <>
      <Row className="account-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="space-between" className="row-table">
            {/* <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Add COA (Chart of Accounts)`}
            </Button> */}

            <Col
              className="title-col"
              xxl={12}
              xl={12}
              md={12}
              lg={24}
              sm={24}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/*   <Typography 
            //     className="title-text"
            //     style={{
            //       fontWeight: 750,
            //       fontSize: 20,
            //       color: "#3699FF",
            //     }}
            //   >
            //     {`Trial Balance MTD`}
           </Typography> */}
              {`Select a Month`}
              <DatePicker.MonthPicker
                placeholder="Select a Month!"
                allowClear
                onChange={handleMonth}
                style={{
                  minWidth: 150,
                  maxWidth: 250,
                }}
              />
            </Col>

            <Col
              className="title-col"
              xxl={12}
              xl={12}
              md={12}
              lg={24}
              sm={24}
              xs={24}
            >
              <Row
                className="btn-row"
                justify="end"
                gutter={[0, 15]}
                align="top"
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={move}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="trial-balance-table"
              name="trial-balance-table"
              key="trial-balance-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              //     {
              //     defaultPageSize: 5,
              //     pageSizeOptions: [10, 20, 50, 100],
              //     showSizeChanger: true,
              //     showTotal: (total, range) =>
              //       `Showing ${range[0]}-${range[1]} of ${total} entries`,
              //   }}
              //   scroll={{
              //     x: 2000,
              //   }}
              rowKey={(record) =>
                record.account ? record.account : record.chart_of_account
              }
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              summary={(pageData) => {
                let totalDebit = 0;
                let totalCredit = 0;

                pageData.forEach(({ debit, credit }) => {
                  totalDebit += debit;
                  totalCredit += credit;
                });

                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={3} colSpan={3}>
                          <Typography
                            className="total-text"
                            style={{
                              fontWeight: 750,
                              fontSize: 17,
                            }}
                          >
                            TOTAL
                          </Typography>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={4}>
                          <Typography
                            className="total-text"
                            style={{
                              fontWeight: 750,
                              fontSize: 17,
                            }}
                          >
                            {`${currency} ${totalDebit}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Typography>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={4}>
                          <Typography
                            className="total-text"
                            style={{
                              fontWeight: 750,
                              fontSize: 17,
                            }}
                          >
                            {`${currency} ${totalCredit}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Typography>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

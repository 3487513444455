// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterSalesType({ getSalesType, sales_type, disabled }) {
  // CONTEXT
  const { getMasterTable } = useContext(Context);

  // STATE MANAGEMENT
  // Data
  const [salesType, setSalesType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async (value) => {
    await getMasterTable({
      outlet: "master",
      type: "sales-type",
      onAwait: () => {},
      onSuccess: async (response) => {
        console.log("Type of Sales: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleFilter(_res);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (sales_type && !cleanUp) {
      console.log("Sales Type: ", sales_type);

      setSalesType(sales_type);
    } else {
      setSalesType(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [sales_type]);

  // Handle Filter
  const handleFilter = (value) => {
    const _data = value;

    let _mapped = _data.map((val) => ({
      label: val?.status_code,
      value: val?.status_id,
      code: val?.status_code,
      id: val?.id,
    }));

    // console.log("Map results: ", _mapped);
    setOptionValue(_mapped);
    getSalesType({
      value: null,
      id: null,
      // key: _allKeys?.key,
      label: null,
      code: null,
    });
    // handleChange(_mapped[0]?.value, _mapped[0]);
  };

  // Handle Change
  const handleChange = (val, code) => {
    const _value = val;
    const _allKeys = code;

    // console.log("Values: ", _value);
    // console.log("All Keys: ", _allKeys);

    setSalesType(_value);
    getSalesType({
      value: _value,
      id: _allKeys?.id,
      // key: _allKeys?.key,
      label: _allKeys?.label,
      code: _allKeys?.code,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setSalesType(null);
  };

  return (
    <>
      <Select
        className="sales-type-select"
        key="sales-type-select"
        placeholder="Sales Type"
        allowClear
        showSearch
        disabled={disabled}
        value={salesType}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Tag } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

// GLOBAL API
import { department_id } from "../../../../../../API/Global/Payload";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

export default function MinimumOnHandTable(props) {
  // PROPS
  const { is_search, mainGroupId, articleNumber } = props;

  //CONTEXT
  const { getInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [mainGroupId, articleNumber]);

  // USEEFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // GET DATA FUNCTION
  const fetchData = async () => {
    // console.log("Main Group ID > ", id);
    setIsLoading(true);

    await getInventoryReport({
      type: `minimum-onhand/${department_id}`,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
            };
          });

          setData(filter);
          setBulks(filter);
        } else {
          setData([]);
          setBulks([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Minimum On Hand");
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const _articleNumber = articleNumber ? articleNumber : null;
    const _mainId = mainGroupId ? mainGroupId : null;

    console.log("Article Number: ", _articleNumber);
    console.log("Main ID: ", _mainId);

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        const id = item?.article_main_id;
        const number = item?.article_number;
        // console.log("ITEM => ", item)
        return id === _mainId || number.indexOf(_articleNumber) !== -1;
      });

      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report";

    navigate(`${path}`);
  };

  // Params
  const currency = CurrencySymbols().code;

  // TABLE COLUMNS
  const columns = [
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "Main Group Name",
      dataIndex: "article_main_name",
      key: "article_main_name",
    },
    {
      title: `Unit Price ${currency}`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (price, record) => {
        return price > 0 ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Gross ${currency}`,
      dataIndex: "gross",
      key: "gross",
      render: (gross, record) => {
        return gross > 0 ? `${gross}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: `Tax ${currency}`,
      dataIndex: "tax",
      key: "tax",
      render: (tax, record) => {
        return tax > 0 ? `${tax}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Service ${currency}`,
      dataIndex: "service",
      key: "service",
      render: (service, record) => {
        return service > 0
          ? `${service}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: `Average Price ${currency}`,
      dataIndex: "average_price",
      key: "average_price",
      render: (avg, record) => {
        return avg > 0 ? `${avg}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Qty Received",
      dataIndex: "quantity_received",
      key: "quantity_received",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "DML",
      dataIndex: "is_dml",
      key: "is_dml",
      render: (dml, record) => {
        return dml ? <Tag>DML</Tag> : <Tag color="red">Not DML</Tag>;
      },
    },
  ];

  return (
    <>
      <Row
        className="minimum-onhand-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="minimum-onhand-table"
              key="minimum-onhand-table"
              name="minimum-onhand-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1600,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import Page Components
import React from "react";
import { useNavigate } from "react-router-dom";

// Import React Components
import { Button, Result, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

// CODE
export default function UnAuthorized() {
  // USE NAVIGATE
  const navigate = useNavigate();

  // MOVE HOME
  const move = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <Result
        className="unauthorized-results"
        status="403"
        title={
          <>
            <Typography
              className="title-text"
              style={{
                color: "#f5222d",
                fontSize: 25,
                fontWeight: 750,
              }}
            >
              UNAUTHORIZED!
            </Typography>

            <WarningOutlined
              className="warning-icon"
              style={{
                fontSize: 50,
                color: "#f5222d",
              }}
            />
          </>
        }
        subTitle={
          <>
            <Typography className="text-forbid">FORBIDDEN!</Typography>

            <Typography className="text-subtitle">
              Sorry, you are Not Authorized to access this page!
            </Typography>
          </>
        }
        extra={
          <Button className="next-btn" type="primary" onClick={move}>
            Back Home
          </Button>
        }
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: 5,
        }}
      />
    </>
  );
}

// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

import { LocalizationDate } from "../../../../Reusable/Functions/Localizations/Localization";

// Import ANTD Component
import {
  Table,
  Tag,
} from "antd";

// Import React Icons Components
import { failedFetch } from "../../../../Reusable/Notification/Notification";

export default function TransferOrderTable(props) {
  // PROPS
  const { getSelectedHeader, orderId } = props;

  // Data
  const [data, setData] = useState();
  const [select, setSelect] = useState(null);

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  // FETCH Data Header
  const fetchDataHeader = async () => {
    await axios
      .get(`${baseurl}/pos/order-header`, {})
      .then((response) => {
        console.log("HEADER FOR SAVED BILL: ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (
              e.is_deleted == false &&
              e.is_paid == false &&
              e.bill_status != 2
            ) {
              return e;
            }
          });

          let resFilter = _res.filter((x) => {
            if(x.name?.slice(-1) == "1" || x.order_id_alias == orderId){
              return false
            } else {
              return true
            }
          });

          setData(resFilter);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchDataHeader();
  }, []);

  // Columns
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // Before
      render: (amount) => amount,
      // End Before
      // Modify
      // render: (text) => {
      //   return (text.substr(text.length - 3)) == "pay" ? text.substring(0, text.length - 3) : text
      // },
      // End Modify
    },
    {
      title: "Table",
      dataIndex: "table_id",
      key: "table_id",
      render: (amount) => amount,
    },
    // Modify
    {
      title: "Status",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        let color = "";
        let txt = "";

        switch ((text.substr(text.length - 1))) {
          case "1":
            color = "#1890ff";
            txt = "Close";
            break;

          default:
            color = "#87d068";
            txt = "Open";
            break;
        }

        return (
          <Tag color={color}>
            {txt ? txt.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
    // End Modify
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
      render: (amount) => amount,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (amount) => {
        return moment(LocalizationDate(amount)).format("YYYY-MM-DD, HH:mm");
      },
    },
    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      getSelectedHeader(selectedRows[0]);
    },
    getCheckboxProps: (record) => {
      return {
        // disabled: record.item_quantity == (0 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  return (
    <Table
      className="saved-bill-modal-table"
      name="saved-bill-modal-table"
      key="saved-bill-modal-table"
      columns={columns}
      dataSource={data}
      bordered
      pagination={{
        defaultPageSize: 5,
        pageSizeOptions: [10, 20, 50, 100],
        showSizeChanger: true,
        showTotal: (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total} entries`,
        onChange(current, pageSize) {
          setPage(current);
          setPaginationSize(pageSize);
        },
      }}
      scroll={{
        x: 800,
      }}
      rowSelection={{
        type: "radio",
        ...rowSelection,
      }}
      //   components={{
      //     body: {
      //       cell: EditableCell,
      //     },
      //   }}
      rowClassName={(record, index) => {
        return index % 2 === 0 ? "odd" : "even";
      }}
      rowKey={(record) => record.id}
    />
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

// Import React Icons
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  masterSuccessNotification,
  masterFailedNotification
} from "../../../Reusable/Notification/MasterNotif/Notification";

export default function CaptainModalJoinDestination(props) {
  // PROPS
  const { openModal, closeModal, options, getClear, selectedTable } = props;

  // STATE MANAGEMENT
  // Data
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  
  const [key, setKey] = useState(0);

  // USE FORM
  const [form] = Form.useForm();
  const { Text } = Typography;

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    console.log("options", options)
  },[options]);

  useEffect(() => {
    console.log("selected", selected);
  }, [selected]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  useEffect(() => {
    console.log("key", key);
  }, [key]);


  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);
    setSelected(null);
    
    form.resetFields();
  };
  
  // HANDLE CANCEL
  const handleRefresh = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);
    getClear();
    setSelected(null);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log("ABOUT TO JOIN ", e);
    
  };

  const onFinishFailed = (e) => {
    console.log("ERROR", e)
  };

  const handleSubmit = () => {
    console.log("handleSubmit", selected.order_id_alias)
    fetchOrder(selected.order_id_alias);
  }

  const fetchOrder = async (val) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        order_id_alias: val,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            
            return {
              ...x,
              serving_que: x.serving_order,
            }
          });

          console.log("_resMap", _resMap);
          handleData( val, _resMap)
        } else {
          null
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        masterFailedNotification(error, {
          method: 0,
          source: `fetchOrder`,
        });
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 100);
      });
  };

  const handleData = async (val, dataSelected) => {
    let bulksData = [];
    let filter = options.filter((x) => {
      if(x.order_id_alias == val){
        return false;
      } else {
        return true;
      }
    });

    console.log("filter", filter);

    for(let i = 0; i < filter.length; i++){
      await axios
        .post(`${baseurl}/pos/detail-order-id`, {
          order_id_alias: filter[i].order_id_alias,
        })
        .then((response) => {
          console.log("Bill Selected: ", response);

          if (response.data?.msg?.length > 0) {
            const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
              (e) => {
                if (
                  e.is_deleted == false &&
                  e.is_paid == false &&
                  e.bill_status == 0
                ) {
                  return e;
                }
              }
            );

            const map = _res.map((x) => {
              return {
                ...x,
                serving_que: x.serving_order,
              }
            });

            map.map((x) => bulksData.push(x));
            

            console.log("bulks", bulksData);

          } else {
            null
          }
        })
        .catch((error) => {
          console.log("Error tables: ", error);

          masterFailedNotification(error, {
            method: 0,
            source: `fetchOrder`,
          });
        })
        .finally(() => {
          setTimeout(() => setLoading(false), 100);
        });
    }
    
    handlePush(bulksData, dataSelected);
  };

  const handlePush = (bulksData, dataSelected) => {
    console.log("dataSelected", dataSelected, "bulks", bulksData);

    let matchedItems = [];
    let unmatchedItems = [];

    // Fungsi untuk menemukan apakah suatu item ada dalam array berdasarkan article_name
    const findItem = (array, article_id, serving_que, notes) => {
      return array.find(item => item.article_id == article_id && item.serving_que == serving_que && item.notes?.slice(-1) == notes?.slice(-1));
    }

    bulksData.forEach(item => {
      let selectedItem = findItem(dataSelected, item.article_id, item.serving_que, item.notes);
      
      if(selectedItem) {
        let matchedItem = findItem(matchedItems, item.article_id, item.serving_que, item.notes);
        if(matchedItem) {
          matchedItem.item_quantity += item.item_quantity
        } else {
          matchedItems.push({
            id: selectedItem.id,
            article_id: selectedItem.article_id,
            article_name: selectedItem.article_name,
            serving_que: selectedItem.serving_que,
            notes: selectedItem.notes,
            item_quantity: selectedItem.item_quantity + item.item_quantity,
            gross: selectedItem.gross,
            tax: selectedItem.tax,
            service: selectedItem.service,
            is_tax: selectedItem.is_tax,
            is_service: selectedItem.is_service
          })
        }
      } else {
        let unmatchedItem = findItem(unmatchedItems, item.article_id, item.serving_que, item.notes);
        if(unmatchedItem) {
          unmatchedItem.item_quantity += item.item_quantity
        } else {
          unmatchedItems.push({
            ...item,
          })
        }
      }
    });

    console.log("matchedItems", matchedItems, "unmatchedItems", unmatchedItems);
    handleMatched(matchedItems, unmatchedItems);
  };

  // HANDLE MATCHED
  const handleMatched = async (matchedItems, unmatchedItems) => {
    if(matchedItems.length > 0) {
      for (let i = 0; i < matchedItems.length; i++) {
        await axios
          .put(
            `${baseurl}/pos/order-detail`,
            {
              deleted_reason: "JOIN ITEM", //Available
              notes: matchedItems[i].notes ?? null, //Available
              id: matchedItems[i].id, //Available
              created_by: user_name, //Available
              item_quantity: matchedItems[i].item_quantity, //Available
              serving_order: matchedItems[i].serving_que, //Available
              total_price: matchedItems[i].gross * matchedItems[i].item_quantity, //Available
              tax: matchedItems[i].tax, //Available
              service: matchedItems[i].service, //Available
              is_tax: matchedItems[i].is_tax, //Available
              is_service: matchedItems[i].is_service, //Available
              gross: matchedItems[i].gross, //Available
              add_ons: matchedItems[i].notes ?? null, //Available
            },
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("Update Detail: ", response);
              let successCount = i + 1;

              if(successCount == matchedItems.length) {
                handleUnmatched(unmatchedItems);
              }
            } else {
              masterFailedNotification(response, {
                method: 1,
                source: `Captain Order Item`,
              });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            masterFailedNotification(error, {
              method: 1,
              source: `Captain Order Item`,
            });
          })
      }
    } else {
      handleUnmatched(unmatchedItems);
    }
  };

  const handleUnmatched = async (unmatchedItems) => {
    console.log("handleUnmatched");
    let bulks = [];

    if(unmatchedItems.length > 0) {
      for (let i = 0; i < unmatchedItems.length; i++) {
        bulks.push({
          id: unmatchedItems[i]?.article_id,
          article_id: unmatchedItems[i]?.article_id,
          article_sub_name: unmatchedItems[i]?.article_sub_name,
          article_sub_id: unmatchedItems[i]?.article_sub_id,
          article_name: unmatchedItems[i]?.article_name,
          unit_price: unmatchedItems[i]?.unit_price,
          item_quantity: unmatchedItems[i]?.item_quantity,
          total_price: unmatchedItems[i]?.gross * unmatchedItems[i]?.item_quantity,
          table_code: selected?.table_code,
          table_id: selected?.table_id,
          serving_order: unmatchedItems[i]?.serving_que,
          guest_name: selected?.name ?? "NON STAY GUEST",
          chart_of_account: unmatchedItems[i]?.chart_of_account,
          tax: unmatchedItems[i]?.tax,
          service: unmatchedItems[i]?.service,
          is_tax: true,
          is_service: true,
          order_step: 0,
          gross: unmatchedItems[i]?.gross,
          add_ons: unmatchedItems[i]?.notes || null,
          // notes: data[i]?.notes || null,
          notes: unmatchedItems[i]?.notes,
          payment_status: selected?.payment_status !== true ? 1 : 2,
          order_id_alias: selected?.order_id_alias,
          header_id: selected?.id,
          created_by: user_name,
        });
      }

      console.log("bulks", bulks);

      await axios
      .post(
        `${baseurl}/pos/order-detail`,
        {
          data: bulks.length > 0 ? bulks : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Add Detail: ", response);

        if (response.status === 200) {
            handleDeleteOrder();
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: `Captain Order Item`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: `Captain Order`,
        });
      })
    } else {
      handleDeleteOrder();
    }
  };

  const handleDeleteOrder = async () => {
    let filterDelete = options.filter((x) => {
      if(x.order_id_alias == selected.order_id_alias) {
        return false;
      } else {
        return true;
      };
    });

    for(let i = 0; i < filterDelete.length; i++) {
      let obj =
          {
            order_id_alias: filterDelete[i]?.order_id_alias,
            deleted_by: user_name,
            deleted_reason: "",
          };

      await axios
        .delete(`${baseurl}/pos/order-header`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("DEL POS Bill Header >> ", response);
            let successCount = i + 1;
            if(successCount == filterDelete.length) {
              masterSuccessNotification("", {
                method: 0,
                source: `Join Captain Order Item`,
              });
              selectedTable(selected);
              handleRefresh();
            }
          }
        })
        .catch((error) => {
          console.log("ERROR DEL POS Bill Header >> ", error);
          masterFailedNotification(error, {
            method: 2,
            source: `Join Captain Order Item`,
          });
        });
    }
    
  };

  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={
          <>
            <Row className="cancel-order-modal-row">
              <AiOutlineInfoCircle
                style={{
                  color: "#f5222d",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Join Destination`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={500}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={
            {
              // deleted_reason: "CANCEL ORDER",
            }
          }
          style={{ padding: "0px 36px" }}
        >
          <Row
            className="cancel-order-modal-form-serve-row"
            //   justify="center"
            //   gutter={[30, 30]}
            style={{ margin: "0px 40px 10px 0px" }}
          >
            <Text>{`Are you sure you want to join orders?`}</Text>
          </Row>

          <Row
            className="serve-row"
            justify="center"
            style={{ margin: "0px 40px" }}
          >
            <Col span={24} className="serve-col">
              <Form.Item
                label="Select Destination Order"
                name={"deleted_reason"}
                rules={[
                  {
                    required: true,
                    message: "Please, Select an Option!",
                  },
                ]}
              >
                <Select
                  //   defaultValue="CANCEL ORDER"
                  placeholder={"Select Option"}
                  //   style={{
                  //     width: 120,
                  //   }}
                  onChange={(e, record) => {setSelected(record)}}
                  options={options.map((x) => {
                    return {
                      ...x,
                      value: x.order_id_alias,
                      label: x.order_id_alias + " // " + x.name,
                    }
                  })}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="cancel-order-modal-form-divider-form"
            style={{ margin: "0px 0px 10px 0px" }}
          />

          <Row justify="end" align="middle">
            <Button
              // type="primary"
              // htmlType="submit"
              // key={"submit"}
              onClick={handleSubmit}
              loading={loading}
              className="submit-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Yes`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`No`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";

// Import Notifications
import {
  failedFetch,
  failedNonStay,
  successNonStay,
} from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// MODAL
const { confirm } = Modal;

// CODE
export default function NonstayTable({
  getSelectorValue,
  fetchToggleState,
  fetchToggleStateReverse,
}) {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 30,
      render: (_, record) => {
        return (
          <>
            <Row className="action-nonstay-table-btn" style={{ width: "100%" }}>
              <Button
                type="primary"
                onClick={() => {
                  handleMove(record);
                  // console.log(record);
                }}
                className="submit-btn"
                style={{
                  // backgroundColor: "#1BC5BD",
                  // borderColor: "#1BC5BD",
                  marginRight: 15,
                }}
              >
                <ArrowLeftOutlined />
                {`Move`}
              </Button>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation No",
      dataIndex: "reservation_id",
      // fixed: "left",
      width: 145,
      key: "reservation_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // fixed: "left",
      width: 200,
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (arrival, record) => {
        return moment(arrival).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.departure) - moment(b.departure),
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
      // filters: [
      //   {
      //     text: "DELUXE",
      //     value: "DELUXE",
      //   },
      //   {
      //     text: "STANDARD",
      //     value: "STANDARD",
      //   },
      //   {
      //     text: "SUPERIOR",
      //     value: "SUPERIOR",
      //   },
      //   {
      //     text: "SUITE",
      //     value: "SUITE",
      //   },
      //   {
      //     text: "EXECUTIVE",
      //     value: "EXECUTIVE",
      //   },
      // ],
      // onFilter: (value, record) => {
      //   // console.log("VALUE = ", value);
      //   return record.room_category === value;
      // },
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.roomno - b.roomno,
    },
    {
      title: "Nonstay Count",
      dataIndex: "nonstay_count",
      key: "nonstay_count",
      render: (count, record) => {
        // let grp = "";

        // switch (group) {
        //   case 0:
        //     grp = "Individual Guest";
        //     break;

        //   case 1:
        //     grp = "Group Leader Guest";
        //     break;

        //   case 2:
        //     grp = "Group Member Guest";
        //     break;

        //   default:
        //     break;
        // }

        return count > 0 ? count : "0";
      },
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
  ];

  // CONTEXT
  const { getReservation, getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  //const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [selectorValue, setSelectorValue] = useState(1);
  const [fetchToggle, setFetchToggle] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT FETCH
  useEffect(() => {
    let cleanUp = false;

    // fetchData();

    if (getSelectorValue && !cleanUp) {
      // console.log("Value --> ", getSelectorValue);

      setSelectorValue(getSelectorValue);
    }

    return () => {
      cleanUp = true;
    };
  }, [getSelectorValue]);

  useEffect(() => {
    let cleanUp = false;

    if (fetchToggleState && !cleanUp) {
      // console.log("Value --> ", fetchToggleState);

      fetchToggleStateReverse(fetchToggleState);
    }

    if (selectorValue && !cleanUp) {
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [fetchToggleState, selectorValue]);

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    switch (selectorValue) {
      case 1:
        await getReservation({
          reservation: "reservation",
          type: "stay",
          onAwait: () => {
            "on Await";
          },
          onSuccess: (response) => {
            console.log("ISI ARRIVALS => ", response);

            if (response?.data?.msg?.length > 0) {
              let filtered = response.data.msg.filter((filt) => {
                if (filt.is_nonstay == true) {
                  return filt;
                }
              });

              setData(filtered);
            }

            setLoading(false);
          },
          onReject: (error) => {
            setLoading(false);
            failedFetch(error);
            console.log("ERROR >>>> ", error);
          },
        });
        break;

      case 2:
        await getGuestInHouse({
          guestInHouse: "guest-in-house",
          type: "stay",
          onAwait: () => {},
          onSuccess: (response) => {
            console.log("ISI DEPARTURES => ", response);

            if (response?.data?.msg?.length > 0) {
              let filtered = response.data.msg.filter((filt) => {
                if (filt.is_nonstay == true) {
                  return filt;
                }
              });

              setData(filtered);
            }

            setLoading(false);
          },
          onReject: (error) => {
            console.log(error);
            setLoading(false);
            failedFetch(error);
          },
        });
        break;

      default:
        break;
    }
  };

  //   // HANDLE SELECTOR
  //   const handleChangeSelector = (e) => {
  //     setSelectorValue(e);
  //   };

  // HANDLE MOVE
  const handleMove = (e) => {
    const data = e;

    console.log("About to Reverse: ", data);

    confirm({
      title: `Do you want to Remove ${
        data?.reservation_id || "---"
      } from the NON-STAY Table and Returning it to ${
        selectorValue == 1 ? "Arrivals" : "Departures"
      } Table?`,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: "Confirm",
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },

      onOk() {
        // console.log("OK");
        handlePostData(e);
      },

      onCancel() {
        console.log("Cancel");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },
    });
  };

  // HANDLE REVERSE
  const handlePostData = async (e) => {
    // console.log("handle post is running...");

    switch (selectorValue) {
      case 1:
        await axios
          .put(
            `${baseurl}/reservation/nonstay-to-stay`,
            {
              reservation_id: e.reservation_id,
              // price_id: e.price_id,
              is_nonstay: false,
              restay_by: user_name,
            },
            { headers: { Authorization: `Bearer ${token ? token : ""}` } }
          )
          .then((response) => {
            console.log("Reverse NON-STAY >>> ", response);

            successNonStay(response, { method: 1 });

            handleCancel();
            fetchData();
            setFetchToggle(!fetchToggle);
          })
          .catch((error) => {
            console.log("POST NON-STAY >>>> ", error);
            failedNonStay(error, { method: 1 });
          });
        break;

      case 2:
        await axios
          .put(
            `${baseurl}/reservation/nonstay-to-stay`,
            {
              reservation_id: e.reservation_id,
              // price_id: e.price_id,
              is_nonstay: false,
              restay_by: user_name,
            },
            { headers: { Authorization: `Bearer ${token ? token : ""}` } }
          )
          .then((response) => {
            console.log("Reverse NON-STAY >>> ", response);

            successNonStay(response, { method: 1 });

            handleCancel();
            fetchData();
            setFetchToggle(!fetchToggle);
          })
          .catch((error) => {
            console.log("POST NON-STAY >>>> ", error);
            failedNonStay(error, { method: 1 });
          });
        break;

      default:
        break;
    }
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = () => {
    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete guest named .... from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
  };

  // console.log(getSelectorValue);

  return (
    <>
      <Row className="nonstay-main-table" justify="center" style={mainBody}>
        <Col span={24} className="col-nonstay" style={{ padding: 30 }}>
          <Row className="row-modal-btn" justify="end">
            <Col span={8} className="col-exp-btn">
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 750,
                }}
              >
                Sources: {`${selectorValue == 1 ? "ARRIVAL" : "DEPARTURE"}`}
              </Typography>
              {/*
              <Row justify="start">
                 <Button
                  className="export-btn"
                  type="primary"
                  onClick={<></>}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  Download Excel
                </Button> 
              </Row>
                */}
            </Col>

            <Col span={16}>
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="nonstay-table"
              key="nonstay-table"
              name="nonstay-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                // y: 270,
                x: 1500,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";

// ANTD COMPONENTS
import {
  FileExcelFilled,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Col, InputNumber, Row, Space, Table, Typography } from "antd";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import ArticleVendor from "../../../../../Reusable/Master/ArticleVendor/ArticleVendor";
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

export default function SlowMovingTable(props) {
  // PROPS
  const { mainGroupId, daysCount } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [searchKey, setSearchKey] = useState(0);
  const [article, setArticle] = useState(null);
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (mainGroupId && daysCount && !cleanUp) {
      fetchData(mainGroupId, daysCount);
    } else {
      setData([]);
      setBulks([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [mainGroupId, daysCount]);

  // GET DATA FUNCTION
  const fetchData = async (main_id, days_count) => {
    console.log("Main Group ID > ", main_id, " Days Count > ", days_count);
    setIsLoading(true);

    const bodyReq = {
      daycount: days_count,
      article_main_id: main_id,
    };

    await getByPostInventoryReport({
      // type: `slow-moving/${main_id}/${days_count}`,
      type: `slow-moving`,
      bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item) => {
            return {
              ...item,
              key: item.id,
              label: item.article_name,
              value: item.article_name,
            };
          });

          setData(filter);
          setBulks(filter);
        } else {
          setData([]);
          setBulks([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Slow Moving Stock");
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    // console.log("Bulks : ", bulks);
    // const query = searchKey ? searchKey : null;
    const name = article?.article_name
      ? article.article_name.toLowerCase()
      : null;

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        // const _number = item?.daycount;
        const _name = item?.article_name.toLowerCase();

        // return _number === query || _name.indexOf(name) !== -1;
        return _name.indexOf(name) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE SELECTED ARTICLE
  const handleGetSelectedArticle = (value) => {
    console.log("Selected Article : ", value);
    setArticle(value);
  };

  // Params
  const currency = CurrencySymbols().code;

  // TABLE COLUMNS
  const columns = [
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Mess Stock",
      dataIndex: "mess_stock",
      key: "mess_stock",
    },
    {
      title: "Delivery Stock",
      dataIndex: "delivery_stock",
      key: "delivery_stock",
    },
    {
      title: `Average Price ${currency}`,
      dataIndex: "average_price",
      key: "average_price",
      render: (avg, record) => {
        return avg > 0 ? `${avg}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Last Supplier",
      dataIndex: "last_supplier",
      key: "last_supplier",
    },
    {
      title: "Days Count",
      dataIndex: "daycount",
      key: "daycount",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
  ];
  return (
    <>
      <Row
        className="slow-moving-main-table"
        justify="center"
        style={{
          margin: "30px 0px",
          borderRadius: 5,
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col className="search-col" span={24}>
          <Space
            align="end"
            size={15}
            className="header-space"
            wrap={true}
            style={{
              padding: 30,
            }}
          >
            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
                marginRight: 15,
                width: 250,
              }}
            >
              <Typography
                style={{ marginBottom: 10 }}
              >{`Article Name`}</Typography>

              <ArticleVendor
                selectedArticle={handleGetSelectedArticle}
                article_name={null}
                is_disable={false}
              />
            </Col>

            <Col
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Button
                className="search-btn"
                type="primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
              >
                {`Search`}
              </Button>
            </Col>
          </Space>
        </Col>
      </Row>

      <Row className="slow-moving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() =>
                    mainGroupId && daysCount
                      ? fetchData(mainGroupId, daysCount)
                      : null
                  }
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="slow-moving-table"
              key="slow-moving-table"
              name="slow-moving-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1400,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

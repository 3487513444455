// Import React's Component
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Col, Layout, Row, Select, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BsJournalCheck } from "react-icons/bs";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import StockOpnameTable from "../../../../Components/Table/BackOffice/Inventory/StockOpname/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// NOTIFICATIONS
import { failedFetch } from "../../../../Components/Reusable/Notification/Notification";

// CODE
export default function StockOpnamePage() {
  // CONTEXT
  const { getWarehouseList } = useContext(MainContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [bulks, setBulks] = useState(data);
  const [options, setOptions] = useState(data);

  // Loadings
  const [loading, setLoading] = useState(false);

  // Set Refresh
  const [refresh, setRefresh] = useState(false);

  // Article Type
  const [articleType, setArticleType] = useState(null);
  const [element, setElement] = useState(null);

  // Handle Articles
  const handleArticle = (val) => {
    const _type = val;
    // console.log("ID Storage for SO Table >> ", _type);

    setArticleType(_type);

    if (_type != null) {
      setElement(<StockOpnameTable id_storage={_type} />);
      // console.log("setElement >> StockOpnameTable");
    } else {
      null;
      // console.log("setElement >> Failed!");
    }
  };

  // NAVIGATE
  const handleReturn = () => {
    const path = "/back-office/inventory";

    navigate(`${path}`);
  };

  // Fetch Data
  const fetchData = async () => {
    await getWarehouseList({
      type: "warehouse",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("STORAGE RESPONSE => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          let mapData = filtered.map((item) => {
            return {
              ...item,
              id_storage: item.id_warehouse,
              storage_name: item.warehouse_name
            }
          })
          setData(mapData);
          setBulks(mapData);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    setRefresh(false);
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let optionsData = data?.map((dt) => {
      return {
        label: dt.storage_name,
        value: dt.id_storage,
      };
    });

    setOptions(optionsData);

    console.log("DATA >> ", data);
  }, [data]);

  return (
    <Layout>
      <Content>
        <Col span={24} className="article-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <BsJournalCheck
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Stock Opname`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Storage`}</Typography>

                <Select
                  className="article-type-select"
                  key="article-type-select"
                  allowClear
                  showSearch
                  value={articleType}
                  onChange={handleArticle}
                  options={options}
                  placeholder="Select a Storage"
                  style={{
                    // minWidth: 150,
                    // maxWidth: 250,
                    width: 250,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {element}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Space,
  List,
  Table,
  Tabs,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import {
  FaFileDownload,
  FaConciergeBell,
  FaHamburger,
  FaPlus,
  FaMoneyBillWave,
} from "react-icons/fa";
import {
  MdEmojiFoodBeverage,
  MdLunchDining,
  MdBreakfastDining,
  MdOutlineRestaurant,
  MdOutlineDashboardCustomize,
} from "react-icons/md";
import TextArea from "antd/lib/input/TextArea";

// Import Page Components
import TablePerOrder from "../../../Table/Outlet/Order/PerOrder";
import PaymentTableOrder from "../../../Table/Outlet/Order/Payment";

// Import Page Components

// CODE
export default function TableOrderPayment({
  openModal,
  closeModal,
  handleFilter,
}) {
  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState();
  const [activeKey, setActiveKey] = React.useState("1");
  // Data Guest About to Edit
  const [edit, setEdit] = useState({
    // full_name: "",
    // first_name: "",
    // id_card_type: "",
    // id_card_number: 0,
    // phone_number: "",
    // birthdate: "",
    // nationality: "",
    // country: "",
    // province: "",
    // local_region: "",
    // city: "",
    // zip_code: 0,
    // address: "",
    // sex: "",
    // email: "",
    // fax: "",
    // main_segment: "",
    // comment: "",
    // payment_type: "",
    // credit_limit: 0,
    // master_comp: "",
    // is_dummy: true,
  });

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    setOpen(openModal);
    setFilterData(handleFilter);
    // setEdit(dataGuest);
  }, [openModal, handleFilter]);

  // HANDLE SHOW
  if (open) {
    form.setFieldsValue({
      //   full_name: edit.full_name,
      //   first_name: edit.first_name,
      //   id_card_type: edit.id_card_type,
      //   id_card_number: edit.id_card_number,
      //   phone_number: edit.phone_number,
      //   birthdate: moment(edit.birthdate),
      //   nationality: edit.nationality,
      //   country: edit.country,
      //   province: edit.province,
      //   local_region: edit.local_region,
      //   city: edit.city,
      //   zip_code: edit.zip_code,
      //   address: edit.address,
      //   sex: edit.sex,
      //   email: edit.email,
      //   fax: edit.fax,
      //   main_segment: edit.main_segment,
      //   comment: edit.comment,
      //   payment_type: edit.payment_type,
      //   credit_limit: edit.credit_limit,
      //   master_comp: edit.master_comp,
    });
  } else {
    form.resetFields();
  }

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    // e.preventDefault();
    console.log("EDITED > ", e);

    const id = edit.id;
    const contentEdit = e;

    axios
      .put(`http://192.168.88.239:12045/api/v1/guest/card/`, {
        // .put(`http://117.53.144.85:12045/api/v1/guest/card/`, {
        // id: id,
        // full_name: contentEdit.full_name,
        // first_name: contentEdit.first_name,
        // id_card_type: contentEdit.id_card_type,
        // id_card_number: contentEdit.id_card_number,
        // phone_number: contentEdit.phone_number,
        // birthdate: contentEdit.birthdate,
        // nationality: contentEdit.nationality,
        // country: contentEdit.country,
        // province: contentEdit.province,
        // local_region: contentEdit.local_region,
        // city: contentEdit.city,
        // zip_code: contentEdit.zip_code,
        // address: contentEdit.address,
        // sex: contentEdit.sex,
        // email: contentEdit.email,
        // fax: contentEdit.fax,
        // main_segment: contentEdit.main_segment,
        // comment: contentEdit.comment,
        // payment_type: contentEdit.payment_type,
        // credit_limit: contentEdit.credit_limit,
        // master_comp: contentEdit.master_comp,
        // is_dummy: true,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setAddGuestModal(false);
      });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    form.resetFields();
    setActiveKey("1");
  };

  // SET ACTIVE KEY TABS
  const onKeyChange = (key) => {
    // console.log(key);
    setActiveKey(key);
  };

  // CONSOLE LOG
  // console.log("DATA IN MODAL PAY > ", filterData);

  return (
    <>
      <Modal
        className="edit-modal-guest"
        title={
          <>
            <Row>
              <FaMoneyBillWave style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>Pay</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          onFinish={(e) => handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          name="guest-modal-form"
          className="guest-modal-form"
          layout="vertical"
          style={{ padding: "10px 60px 10px 60px" }}
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={activeKey}
            onChange={onKeyChange}
            items={[
              {
                key: "1",
                children: (
                  <>
                    <Row gutter={30}>
                      <Col span={24}>
                        <Form.Item label="Table" name={"table"}>
                          <Select
                            options={[
                              {
                                value: "1",
                                label: "T1",
                              },
                              {
                                value: "2",
                                label: "T2",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider style={{ margin: "15px 0px" }} />

                    <TablePerOrder handleFilter={filterData} />

                    <Row>
                      <Col span={24}>
                        <Row justify="end">
                          <Button
                            className="add-guest-btn"
                            type="primary"
                            onClick={() => onKeyChange("2")}
                            style={{
                              backgroundColor: "#1BC5BD",
                              borderColor: "#1BC5BD",
                              marginRight: 15,
                            }}
                          >
                            Process Bill
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ),
              },
              {
                key: "2",
                children: (
                  <>
                    <Row gutter={30}>
                      <Col span={24}>
                        <Form.Item label="Table" name={"table"}>
                          <Select
                            options={[
                              {
                                value: "1",
                                label: "T1",
                              },
                              {
                                value: "2",
                                label: "T2",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider style={{ margin: "15px 0px" }} />

                    <TablePerOrder handleFilter={filterData} />

                    <Divider style={{ margin: "15px 0px" }} />

                    <Row gutter={16}>
                      <Col span={8}>
                        <Row>
                          <Form.Item
                            label="Recipient"
                            name="recipient"
                            onChange={<></>}
                          >
                            <Input
                              placeholder="Choose an option"
                              // disabled
                              addonAfter={
                                <MdOutlineDashboardCustomize
                                  onClick={() => setOpen(true)}
                                />
                              }
                              onClick={() => setOpen(true)}
                              style={{
                                width: 205,
                              }}
                            />
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="PPN" name="ppn" onChange={<></>}>
                          <Input placeholder="Amount" disabled />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Entire Sale"
                          name="entire_sale"
                          onChange={<></>}
                        >
                          <Input placeholder="Amount" disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          label="Discount"
                          name="discount"
                          onChange={<></>}
                        >
                          <Input placeholder="Amount" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Amount Due"
                          name="amount_due"
                          onChange={<></>}
                        >
                          <Input placeholder="Amount" />
                        </Form.Item>
                      </Col>

                      <Col span={8}></Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <Form.Item label="Discount Reason / Notes" name="notes">
                          <Input.TextArea placeholder="Add Notes" rows={5} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider style={{ margin: "15px 0px" }} />

                    <Row gutter={16}>
                      <Col span={8}>
                        <Row>
                          <Form.Item label="Payment Type" name={"payment_type"}>
                            <Select
                              options={[
                                {
                                  value: "1",
                                  label: "T1",
                                },
                                {
                                  value: "2",
                                  label: "T2",
                                },
                              ]}
                              style={{ width: 200 }}
                            />
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Payment Type Detail"
                          name={"payment_type_detail"}
                        >
                          <Select
                            options={[
                              {
                                value: "1",
                                label: "T1",
                              },
                              {
                                value: "2",
                                label: "T2",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Transfer Ref"
                          name="transfer_ref"
                          onChange={<></>}
                        >
                          <Input placeholder="Amount" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <PaymentTableOrder />
                    </Row>

                    <Divider style={{ margin: "15px 0px" }} />

                    <Row gutter={16}>
                      <Col span={8}>
                        <Row>
                          <Form.Item label="Sub Total" name={"sub_total"}>
                            <Input placeholder="Amount" disable />
                          </Form.Item>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Total Bill" name={"total_bill"}>
                          <Input placeholder="Amount" disable />
                        </Form.Item>
                      </Col>

                      <Col span={8}></Col>
                    </Row>

                    <Divider style={{ margin: "15px 0px" }} />

                    <Row
                      justify="end"
                      align="middle"
                      style={{ padding: "15px 0px 30px 0px" }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        key={"submit"}
                        // onClick={onFinish}
                        className="submit-guest"
                        style={{
                          backgroundColor: "#1BC5BD",
                          borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        Submit
                      </Button>

                      <Button
                        className="cancel-btn"
                        type="default"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Row>
                  </>
                ),
              },
            ]}
          />
          {/* <Row gutter={30}>
            <Col span={24}>
              <Form.Item label="Table" name={"table"}>
                <Select
                  options={[
                    {
                      value: "1",
                      label: "T1",
                    },
                    {
                      value: "2",
                      label: "T2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px" }} />

          <TableOrderTable2 handleFilter={filterData} />

          <Row>
            <Col span={24}>
              <Row justify="end">
                <Button
                  className="add-guest-btn"
                  type="primary"
                  onClick={<></>}
                  style={{
                    backgroundColor: "#1BC5BD",
                    borderColor: "#1BC5BD",
                    marginRight: 15,
                  }}
                >
                  Process Bill
                </Button>
              </Row>
            </Col>
          </Row> */}
        </Form>
      </Modal>
    </>
  );
}

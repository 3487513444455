// Import React Components
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, useParams } from "react-router-dom";

// AUTH
import { roles } from "../../API/Global/Payload";

// AUTH REDUCERS
const mapStateToProps = (state) => {
  console.log(state);

  let response = state;
  let user = response.rootReducer.auth;
  // const {isLoggedIn, accessToken} = user
  return { user };
};

// USER ROLES
const userRoles = sessionStorage.getItem("roles")
  ? sessionStorage.getItem("roles")
  : null;

// console.log("Ini Roles ===>>>", userRoles);

// console.log(base64Payload);

// let token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjg4NDQ4MTIxLCJzdWIiOiJlYzkwOGNkNS0wNjYzLTQwMDktYjQyOS00M2NhYjRjNGUzOWQiLCJlbWFpbCI6ImFkbWlubG9va0BnbWFpbC5jb20iLCJwaG9uZSI6IiIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6ImVtYWlsIiwicHJvdmlkZXJzIjpbImVtYWlsIl19LCJ1c2VyX21ldGFkYXRhIjp7InJvbGVzIjoiUk9MRV9BRE1JTiIsInVzZXJfbmFtZSI6IkFETUlOIn0sInJvbGUiOiJhdXRoZW50aWNhdGVkIiwiYWFsIjoiYWFsMSIsImFtciI6W3sibWV0aG9kIjoicGFzc3dvcmQiLCJ0aW1lc3RhbXAiOjE2ODg0NDQ1MjF9XSwic2Vzc2lvbl9pZCI6IjM2YjRkOTA2LWVkN2QtNGVjYS1iMTAwLWMyNjRlOTI0NWI1NyJ9.cpAp3FsMEgI4kJ32s4uxp5_BxoGA7c2V7w1K-8K6Uyo";

// TOKENS
let user_routes = "";
const token = sessionStorage.getItem("auth-token") || "";
// console.log("Ini yg mau di decode ===>>>", token);

const role_payload = roles;

// return (base64Payload.toString());
// var base64Url = userRoles.split(".")[1];
if (token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  // console.log("Parse JSON --> ", JSON.parse(jsonPayload));
  // console.log("Un-parse JSON --> ", jsonPayload);

  var parsed = JSON.parse(jsonPayload);

  user_routes = parsed?.user_metadata?.roles;
}

// return(jsonPayload);
// console.log("Hasil parse --> ", role_payload);

// CODE
// PROTECTED SUPER ADMIN ROUTES
export const ProtectedSuperAdminRoute = () => {
  // console.log("super admin protected hit!");
  // console.log(userRoles.includes("DEVELOPER"));
  // await userRoles

  return user_routes?.includes("ROLE_SUPER_ADMIN") ? (
    // return userRoles.includes("DEVELOPER") ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

// PROTECTED ADMIN ROUTES
export const ProtectedAdminRoute = () => {
  console.log("admin protected hit!");
  // console.log(userRoles.includes("DEVELOPER"));
  // await userRoles

  return user_routes?.includes("ROLE_ADMIN") ? (
    // return userRoles.includes("DEVELOPER") ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

// PROTECTED MANAGER ROUTES
export const ProtectedManagerRoute = () => {
  return user_routes?.includes("ROLE_MANAGER") ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

// PROTECTED SUPERVISOR ROUTES
export const ProtectedSupervisorRoute = () => {
  return user_routes?.includes("ROLE_SUPERVISOR") ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

// PROTECTED STAFF ROUTES
export const ProtectedStaffRoute = () => {
  console.log("staff protected hit!");

  // return userRoles.includes("ROLE_STAFF") ? (
  return user_routes?.includes("ROLE_STAFF") ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

// PROTECTED AUTH ROUTES
export const ProtectedRoute = (properties) => {
  const params = useParams();

  useEffect(() => {
    let cleanUp = false;

    console.log("Props Dislogged in: ", properties);
    console.log("params: ", params);

    return () => {
      cleanUp = true;
    };
  }, [properties]);

  return properties?.user?.disLoggedIn === true ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

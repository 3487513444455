// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Alert, Button, Card, Col, Row, Typography } from "antd";

// REACT ICONS
import { TbDirectionSignFilled } from "react-icons/tb";

// GLOBAL API
import { department_id } from "../../../API/Global/Payload";

// NOTIFICATIONS
import { failedFetch } from "../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

export default function WidgetMinimumOnHand() {
  //CONTEXT
  const { getInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  // USEEFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // GET DATA FUNCTION
  const fetchData = async () => {
    // console.log("Main Group ID > ", id);

    await getInventoryReport({
      type: `minimum-onhand/${department_id}`,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
            };
          });

          setData(filter);
        } else {
          setData([]);
        }
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
      },
    });
  };

  // REDIRECT
  const handleRedirect = () => {
    const _path = "/report/back-office/inventory-report/minimum-on-hand";

    navigate(_path);
  };
  return (
    <>
      {data.length > 0 ? (
        <Card
          style={{ borderRadius: 12 }}
          className="todo-card"
          title={
            <>
              <Row gutter={30}>
                <Col span={24}>
                  <Typography
                    style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
                  >
                    {`Task List`}
                  </Typography>
                  <Typography
                    style={{ fontSize: 20, fontWeight: 750, color: "#FFFFFF" }}
                  >
                    {`Needs Attention!`}
                  </Typography>
                </Col>
              </Row>
            </>
          }
          headStyle={{
            background: `var(--volcano-gradient, linear-gradient(262deg, #610b00 -60%, #fa541c 100%))`,
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <Row
            className="row-approvals"
            style={{
              padding: 30,
              // background: `var(--red-gradient, linear-gradient(262deg, #FF005C 100%, #EE7E2D 100%))`,
            }}
          >
            <Col span={24} className="widget-col">
              <Row className="btn-row" gutter={[0, 15]}>
                <Col
                  className="title-col"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                >
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 750,
                    }}
                  >{`Minimum Stock On Hand`}</Typography>
                </Col>

                <Col
                  className="modal-col"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                >
                  <Row justify="end" gutter={[0, 15]}>
                    <Button
                      className="next-btn"
                      type="primary"
                      icon={
                        <TbDirectionSignFilled
                          className="return-icons"
                          style={{
                            margin: "0px 5px 0px 0px",
                          }}
                        />
                      }
                      onClick={handleRedirect}
                      style={{
                        margin: "0px 0px 0px 15px",
                      }}
                    >
                      {`Redirect`}
                    </Button>
                  </Row>
                </Col>
              </Row>

              <Row
                align="center"
                className="table-col"
                style={{
                  margin: "30px 0px 0px",
                }}
              >
                <Col span={12}>
                  <Alert
                    message="Informational Notes"
                    description="There are articles that have reached the minimum stock limit, please check!"
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      ) : null}
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";

// REDUX
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../../../API/AuthActions/authAction";

// Token
import { token } from "../../../../API/Global/Payload";

// Import Ant Design Components
import { Button, Col, Modal, Popover, Row, Tooltip, Typography } from "antd";

// Import React's Icons
import { DeleteFilled } from "@ant-design/icons";
import { TfiMenuAlt } from "react-icons/tfi";
import { ImBlocked } from "react-icons/im";
import { FiEdit } from "react-icons/fi";
import { GoPasskeyFill } from "react-icons/go";

// Import Components
import ModalAccessControl from "../../../Modals/Auth/AccessControl/Modal";
import ModalAuthBlock from "../../../Modals/Auth/Blocking/Modal";

// CONFIRM
const { confirm } = Modal;

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch Update --> ", dispatch);

  return {
    disDelete: (values) => dispatch(auth.deleteUser(values.id)),
    disReset: (val) => dispatch(auth.resetRegister(val)),
  };
};

// Maps to Props
const mapStateToProps = (state) => {
  let response = state;
  let delByAdmin = response?.rootReducer?.auth;

  // console.log("Return to Parents: ", blockByAdmin);

  return { delByAdmin };
};

// CODE
const AuthPopover = (props) => {
  // PROPS
  const { is_refresh, details } = props;

  // Open Popover
  const [open, setOpen] = useState(false);
  const [dataRecord, setDataRecord] = useState(null);
  // Open Modal
  const [editForm, setEditForm] = useState(false);
  const [blockForm, setBlockForm] = useState(false);
  // IS DELETED
  const [isDeleted, setIsDeleted] = useState(false);

  // Records
  const detail = details;

  // AUTH UPDATE BY ADMIN
  useEffect(() => {
    let cleanUp = false;

    if (props?.delByAdmin?.is_delete === true && isDeleted && !cleanUp) {
      console.log("Delete state: ", isDeleted);
      console.log("AUTH! Delete is Success: ", props);

      setIsDeleted(false);

      is_refresh(true);
      props.disReset(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [props?.delByAdmin?.is_delete]);

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;
    const _name = value?.user_metadata?.user_name;

    // console.log("About to Delete: ", _data);

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Delete an account with named ${
        _name?.toUpperCase() || " - - - "
      }? Once you confirm this Operations you can't Undo the Executions!`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
      },

      onCancel() {
        setIsDeleted(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Delete
  const handleDelete = async (value) => {
    const _submit = value;

    console.log("About to Delete: ", _submit);

    if (_submit) {
      props.disDelete({
        id: _submit?.id,
      });
    }
  };

  // Close Popover
  const handleClose = () => {
    setOpen(!open);
  };

  // Handle Refresh
  const handleRefresh = (value) => {
    console.log("Popover Refresh! DESTROY MODALS ", value);

    if (value === true) {
      is_refresh(true);
      setBlockForm(false);
      setEditForm(false);
    } else {
      is_refresh(false);
    }
  };

  // Handle Form
  const handleForm = (value) => {
    console.log("Popover please reset details: ", value);

    setEditForm(false);
    setDataRecord(null);
  };

  return (
    <>
      <Tooltip title="Option Menu" className="vendor-management-tooltip">
        <Popover
          className="auth-popover"
          name="auth-popover"
          key="auth-popover"
          placement="right"
          title={
            <Row className="main-row">
              <Col span={24} className="main-col">
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`Access Control & Permissions Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <GoPasskeyFill
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Manage Access`}
                </Button>
              </Row> */}

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FiEdit
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setEditForm(!editForm);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Edit User's Data`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <ImBlocked
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setBlockForm(true);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Block Users`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <DeleteFilled
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    setIsDeleted(true);
                    handleClose();
                    setDataRecord(detail);
                    showModalConfirm(detail);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Delete Users`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            style={{
              fontSize: 20,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              // marginLeft: 5,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <ModalAccessControl
        is_open={editForm}
        is_close={handleForm}
        userData={editForm ? dataRecord : null}
        is_refresh={handleRefresh}
      />

      <ModalAuthBlock
        is_open={blockForm}
        is_close={setBlockForm}
        userData={dataRecord}
        is_refresh={handleRefresh}
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPopover);

// Import React's Component
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { SearchOutlined } from "@ant-design/icons";
import { MdTripOrigin } from "react-icons/md";

// Import Page Components
import SourcesTable from "../../../Components/Table/Master/Sources/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function SourcePage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState("");
  // Selected Arrangement
  const [arrangement, setArrangement] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="source-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <MdTripOrigin
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />

              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Source List`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Keywords`}</Typography>

                  <Input
                    allowClear
                    placeholder="Source's Name"
                    onChange={handleSearch}
                    style={{
                      maxWidth: 300,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    type="primary"
                    onClick={search}
                    className="search-btn"
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>
          </Col>

          <SourcesTable is_search={setSearch} searchKey={value} />
        </Content>
      </Layout>
    </>
  );
}

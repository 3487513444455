import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";
import { Button, Col, Row, Table, Tag } from "antd";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { mainBody } from "../../../../../Style/Global/Global";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

export default function APListTable(props) {
  // PROPS
  const { date_range, is_search, searchKey, is_research } = props;

  // CONTEXT
  const { getAPPayment } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async (value) => {
    await getAPPayment({
      type: "ap-payment-list",
      start_date:
        value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null,
      end_date:
        value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Respones Headers: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (date_range && !cleanUp) {
      console.log("Selected: ", date_range);

      setDateRange(date_range);
    } else {
      setDateRange([]);
    }

    if (is_search === true && !cleanUp) {
      console.log("Search: ", is_search);

      fetchData(date_range);
      is_research(false);
    } else {
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [date_range, is_search]);

  // Columns
  const columns = [
    {
      title: "Document No.",
      dataIndex: "document_number",
      key: "document_number",
    },
    {
      title: "invoice No.",
      dataIndex: "invoice",
      key: "invoice",
      render: (invoice) => {
        return invoice ? invoice : "-";
      },
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (date) => {
        let _date = date ? moment(date).format("DD-MM-YYYY") : "-";

        return _date;
      },
    },
    // {
    //   title: "Received Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   render: (date) => {
    //     let _date = date ? moment(date).format("DD-MM-YYYY") : "-";

    //     return _date;
    //   },
    // },
    // {
    //   title: "Credit",
    //   dataIndex: "credit",
    //   key: "credit",
    //   render: (credit) => {
    //     return `${credit > 0 ? credit : 0}`.replace(
    //       /\B(?=(\d{3})+(?!\d))/g,
    //       ","
    //     );
    //   },
    // },
    {
      title: "Paid Amount",
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    // {
    //   title: "Final Balance",
    //   dataIndex: "final_balance",
    //   key: "final_balance",
    //   render: (amount) => {
    //     return `${amount > 0 ? amount : 0}`.replace(
    //       /\B(?=(\d{3})+(?!\d))/g,
    //       ","
    //     );
    //   },
    // },
    // {
    //   title: "Account Name",
    //   dataIndex: "account_name",
    //   key: "account_name",
    // },
    // {
    //   title: "Chart of Account",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    // },
    // {
    //   title: "Normal Balance",
    //   dataIndex: "normal_balance",
    //   key: "normal_balance",
    //   render: (balance) => {
    //     let type = "";
    //     let clr = "";
    //     switch (balance) {
    //       case 0:
    //         type = "DEBIT";
    //         clr = "geekblue";
    //         break;

    //       case 1:
    //         type = "CREDIT";
    //         clr = "green";
    //         break;

    //       default:
    //         break;
    //     }

    //     return (
    //       <Tag color={clr} key={type}>
    //         {type}
    //       </Tag>
    //     );
    //   },
    // },
  ];

  // Handle Refresh
  const handleRefresh = () => {
    fetchData(dateRange);
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/back-office/account-payable";

    navigate(`${path}`);
  };

  // Handle Pick
  const handleSearch = (val) => {
    console.log("Search Keywords: ", searchKey);

    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        const _name = item?.article_name.toLowerCase();

        return _name.indexOf(searchKey) !== -1;
      });

      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, `A/P Payment List`);
  };

  return (
    <>
      <Row className="ap-list-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="ap-list-table"
              name="ap-list-table"
              key="ap-list-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id ? record.id : record.department_id
              }
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

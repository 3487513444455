// REACT cOMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// GLOBAL API
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Tag, Tooltip } from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { LuFileInput } from "react-icons/lu";
import { FaRegEye } from "react-icons/fa";

// PAGE COMPONENTS
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";
import JournalRegisterModal from "../../../Modals/Accounting/JournalRegister/Modal";

// NOTIFICATIONS
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";

// MODAL
const { confirm } = Modal;

export default function JournalRegisterTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Context
  const { getJournalRegister } = useContext(FinanceContext);
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dataRegister, setDataRegister] = useState([]);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);

  // Boolean
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // Navigate
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // FETCH DATA
  const fetchData = async () => {
    // if (value) {
    await getJournalRegister({
      type: "journal/header-unregistered-list",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Respones Journal Unregistered: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    fetchData();
    handleClear();
  };

  // HANDLE CLEAR TABLE SELECTION
  const handleClear = () => {
    setSelectedRowArray([]);
    handlePick([]);
    setDataRegister();
  };

  // HANDLE PICK
  const handlePick = (val) => {
    const _arr = val;

    if (_arr?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/back-office/accounting";

    navigate(`${path}`);
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, `Journal Register`);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = () => {
    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Register ${dataRegister?.length} Journals?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleRegister();
      },

      onCancel() {
        setLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray(selectedRowKeys);
      handlePick(selectedRowKeys);
      setDataRegister(selectedRows);
    },
  };

  // HANDLE OPEN
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE CLOSE MODAL
  const handleClose = () => {
    setModalOpen(false);
    setDataDetail(null);
  };

  // HANDLE REGISTER
  const handleRegister = async () => {
    const _data = dataRegister?.map((item) => {
      return {
        id: item.id,
        created_by: user_name,
      };
    });

    await axios
      .put(
        `${baseurl}/accounting/journal/header-unregistered-list`,
        {
          data: _data,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("UPDATE  => ", response);

        masterSuccessNotification(response, {
          method: 1,
          source: "Journal Register",
        });

        fetchData();

        setLoading(false);
      })
      .catch((error) => {
        console.log("UPDATE  => ", error);

        masterFailedNotification(error, {
          method: 1,
          source: "Journal Register",
        });
        setLoading(false);
      });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.journal_alias.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 75,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-journal-register-table-btn"
              style={{ width: "100%" }}
              justify="center"
            >
              <Col span={12}>
                <Tooltip title="Show Detail" className="edit-tooltip">
                  <FaRegEye
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO DETAIL > ", record);
                      setDataDetail(record);
                      handleOpen();
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Journal Alias",
      dataIndex: "journal_alias",
      key: "journal_alias",
      render: (journal) => {
        return journal ? journal : "-";
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sortDirections: ["descend", "ascend"],
      render: (date, record) => {
        return date && date != "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Bill Alias",
      dataIndex: "bill_alias",
      key: "bill_alias",
      render: (bill) => {
        return bill ? bill : "-";
      },
    },
    {
      title: "Journal Type",
      dataIndex: "journal_type",
      key: "journal_type",

      render: (jtype, record) => {
        let type = "";
        let clr = "";

        switch (jtype) {
          case 1:
            type = "Transaction";
            clr = "green";
            break;

          case 2:
            type = "Payment";
            clr = "geekblue";
            break;

          case 3:
            type = "Deposit";
            clr = "orange";
            break;

          case 4:
            type = "Manuals";
            clr = "magenta";
            break;

          default:
            break;
        }

        return (
          <Tag color={clr} key={type}>
            {type}
          </Tag>
        );
      },
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (debit) => {
        return debit ? `${debit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (credit) => {
        return credit ? `${credit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Row
        className="journal-register-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  disabled={isDisabled}
                  onClick={showModalConfirm}
                  icon={
                    <LuFileInput
                      className="pay-icon"
                      style={{
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 15px 10px 0px",
                    // height: 40,
                    minWidth: 75,
                    borderColor: "transparent",
                  }}
                >
                  {`Register`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => handleRefresh()}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="journal-register-table"
              name="journal-register-table"
              key="journal-register-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              // onChange={onChange}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id ? record.id : record.department_id
              }
            />
          </Row>
        </Col>
      </Row>

      <JournalRegisterModal
        dataDetail={dataDetail}
        openModal={modalOpen}
        closeModal={handleClose}
        is_refresh={fetchData}
      />
    </>
  );
}

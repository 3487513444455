// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdRoomPreferences } from "react-icons/md";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import RoomTable from "../../../Components/Table/Master/Room/Table";
import MasterRoomCat from "../../../Components/Reusable/Master/RoomCat/RoomCat";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function RoomPage() {
  // STATE MANAGAMENT
  // Search Key
  const [value, setValue] = useState(null);
  // Search
  const [search, setSearch] = useState(null);
  const [category, setCategory] = useState({
    room_category_id: 0,
    room_category: "",
    room_category_code: "",
  });

  // Handle Change
  const handleSearch = (val) => {
    val.preventDefault();

    setValue(val.target.value);
  };

  // Handle Room Category
  const handleRoom = (val) => {
    const _data = val;
    console.log("Room Cat: ", _data);

    setCategory({
      room_category_id: _data?.cat_id,
      room_category: _data?.name,
      room_category_code: _data?.code,
    });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="room-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <MdRoomPreferences
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Room List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  placeholder="Room Name"
                  onChange={handleSearch}
                  allowClear
                  style={{ minWidth: 150 }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Room Category`}</Typography>

                <MasterRoomCat
                  onChangeRoomCat={handleRoom}
                  name={0}
                  cat={null}
                  options={[]}
                  disable={false}
                  is_fetch={true}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <RoomTable
            is_search={setSearch}
            searchKey={value}
            searchCat={category.room_category_id}
          />
        </Col>
      </Content>
    </Layout>
  );
}

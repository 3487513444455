// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// CODE
export default function ParametersAccounting(props) {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [params, setParams] = useState({});
  //   var date = "";

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "accounting",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Params Acc --> ", response);

        setParams(response?.data?.msg[0]);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Params Acc err --> ", error);
        // setLoading(false);
        // failedFetch(error);
      },
    });
  };

  // USER ROLES
  useEffect(() => {
    fetchData();
  }, []);

  return { params };
}

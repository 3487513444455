// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterArticleSub(props) {
  const {
    getArticleSub,
    articleSubName,
    getArticleMainId,
    getDepartmentId,
    addArticleState,
    isDisabled,
    is_forms,
  } = props;

  // CONTEXT
  const { getArticleMaster } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [articleSub, setArticleSub] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  // Bulks
  const [bulks, setBulks] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/article-sub-group");
  };

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getArticleMaster({
      types: "sub-group",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filterData = _res.filter((val) => {
            if (val.is_deleted === false || val.is_deleted === null) return val;
          });

          handleMap(filterData);
        } else {
          setBulks([]);
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // FETCH FILTERED
  const fetchFilter = (value) => {
    if (bulks.length > 0) {
      handleFilter({
        data: bulks,
        id: value,
      });
    } else {
      fetchData();
    }
  };

  // USE EFFECT
  useEffect(() => {
    // if (getArticleMainId > 0 && getDepartmentId > 0) {
    fetchData();
    // }
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if ((getArticleMainId > 0 || getDepartmentId > 0) && !cleanUp) {
      console.log("Article Filter by SUB: ", getArticleMainId, getDepartmentId);

      fetchFilter({
        dept_id: getDepartmentId,
        main_id: getArticleMainId,
      });
    }

    if (articleSubName && !cleanUp) {
      setArticleSub(articleSubName);
    } else {
      setArticleSub(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleSubName, getArticleMainId, getDepartmentId]);

  // Handle Mapping
  const handleMap = (value) => {
    const _filtered = value;

    let optionFilter = _filtered.map((value) => ({
      value: value?.name,
      label: value?.name,
      code: value?.id,
      id: value?.id,
      key: value?.id,
      dept: value?.department_name,
      dept_id: value?.department_id,
      main: value?.article_main_name,
      main_id: value?.article_main_id,
      desc: value?.description,
    }));

    setOptionValue(optionFilter);
    setArticleSub(null);
    setBulks(optionFilter);

    if (is_forms === true) {
      // console.log("Heres");
      setOptionValue(optionFilter);
    } else {
      if (getArticleMainId > 0 && getDepartmentId > 0) {
        handleFilter({
          data: optionFilter,
          id: {
            dept_id: getDepartmentId,
            main_id: getArticleMainId,
          },
        });
      }
    }
  };

  // Handle Filter
  const handleFilter = (value) => {
    const _arr = value?.data;
    const _id = value?.id;

    let _filter = _arr.filter((value) => {
      if (value?.main_id == _id.main_id && value?.dept_id == _id?.dept_id) {
        return value;
      }
    });

    // console.log("Data: ", _arr);
    // console.log("Data Filter: ", _filter);

    setOptionValue(_filter);
  };

  // Handle Change
  const handleChange = (e, allKeys) => {
    const _value = e;
    const _keys = allKeys;

    // console.log(e);
    console.log("INI CODE ", allKeys);

    setArticleSub(_value);

    getArticleSub({
      name: _value,
      code: _keys?.code,
      sub_id: _keys?.id,
    });
    //  if (onSet) onSet(deviceid);
  };

  // Handle Clear
  const handleClear = () => {
    setArticleSub(null);
  };

  // CONSOLE
  // console.log(optionValue);
  // console.log(getArticleMainId);

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={20} className="select-col">
          <Select
            key={optionValue.code}
            style={{ width: "100%" }}
            className="master-article-list"
            value={articleSub != null ? articleSub : articleSubName}
            showSearch
            allowClear
            disabled={isDisabled}
            placeholder="Sub Group"
            onClear={handleClear}
            onChange={handleChange}
            options={optionValue}
          />
        </Col>

        <Col span={4} className="select-col">
          <Tooltip
            className="redirect-tooltip"
            title={"Redirect to Master Sub"}
          >
            <Button
              className="master-btn"
              icon={
                <AppstoreFilled
                  onClick={move}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import React Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  TimePicker,
  Modal,
  Row,
  Select,
  Typography,
  Space,
  List,
  Table,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { MdOutlineHorizontalSplit } from "react-icons/md";
import { AiOutlineSave } from "react-icons/ai";

// Import Page Components
import SplitBillModalTable from "../../../Table/Outlet/Order/SplitBill/TableSplitBill";
import SavedBillModalTable from "../../../Table/Outlet/Order/SavedBill/TableSavedBill";

// Import Notifications
import {
  failedPOSReservation,
  successPOSReservation,
} from "../../../Reusable/Notification/Notification";

// Modal
const { confirm } = Modal;

// CODE
export default function ModalDetailTableAvailability(props) {
  // PROPS
  const { openModal, closeModal, selectedData, handleRefresh } = props;

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [headers, setHeaders] = useState([]);
  const [details, setDetails] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      form.setFieldsValue({
        name: selectedData.name,
        order_id_alias: selectedData.order_id_alias,
        phone_number: selectedData.phone_number,
        pax: selectedData.pax,
        table_id: selectedData.table_id,
        start_date: selectedData.start_date,
        end_date: selectedData.end_date,
      });
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log("ON FINISH >>>", e);
    // console.log("Start Date >>>", selectedData.start_date);
    // console.log("End Date >>>", selectedData.end_date);
    const isFromResvState = true;
    navigate("/f-and-b/outlet/table-order", {
      state: {
        isFromResvState: true,
        selectedData: JSON.parse(sessionStorage.getItem("selection")),
        outletData: JSON.parse(sessionStorage.getItem("outlet_data")),
        tableFromResv: {
          name: selectedData.name,
          order_id_alias: selectedData.order_id_alias,
          table_id: selectedData.table_id,
          table_code: selectedData.table_code,
          status_table: selectedData.status_table,
          start_date: moment(selectedData.start_date).format(),
          end_date: moment(selectedData.end_date).format(),
        },
      },
    });
    // showModalConfirm(e);
  };

  const onFinishFailed = (e) => {};

  // SHOW MODAL CANCEL CONFIRM
  const showModalCancelConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `POS Reservation Table Cancelation`,
      content: `Are you sure want to Cancel this POS Table Reservation ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleCancelReservation(_data);
      },

      onCancel() {
        handleCancel();
        // setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // CANCEL RESERVATION
  const handleCancelReservation = async () => {
    let obj = {
      order_id_alias: selectedData?.order_id_alias,
      deleted_by: user_name,
    };
    await axios
      .delete(`${baseurl}/pos/reservation`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("DEL POS Reservation Table >> ", response);
          successPOSReservation(response, { method: 2 });
          handleRefresh();
        } else {
          failedPOSReservation(response, { method: 2 });
        }
      })
      .catch((error) => {
        console.log("ERROR DEL POS Reservation Table >> ", error);
        failedPOSReservation(error, { method: 2 });
      });
  };

  return (
    <>
      <Modal
        className="saved-bill-modal"
        title={
          <>
            <Row className="saved-bill-modal-row">
              <FormOutlined
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`POS Detail Table Reservation`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          className="form-pos-add-table-resv"
          key="form-pos-add-table-resv"
          name="form-pos-add-table-resv"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ padding: "0px 36px 0px" }}
        >
          <Row gutter={[30, 30]} justify="start" className="row-outlet-loc">
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please, input the visitor name!",
                  },
                ]}
              >
                <Input className="name-input" placeholder="Name" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[30, 30]} justify="start" className="row-outlet-loc">
            <Col span={8}>
              <Form.Item
                label="Order ID"
                name="order_id_alias"
                rules={[
                  {
                    required: true,
                    message: "Please, input the Order ID!",
                  },
                ]}
              >
                <Input className="name-input" placeholder="Order ID" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Phone Number"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Options!",
                  },
                ]}
              >
                <Input placeholder="Phone No." disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Pax"
                name="pax"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Options!",
                  },
                ]}
              >
                <InputNumber placeholder="pax" min={1} disabled />
              </Form.Item>
            </Col>

            {/* <Col>
              <Form.Item label="Outlet ID" name="outlet_id" hidden>
                <Input placeholder="Outlet ID" disabled />
              </Form.Item>

              <Form.Item label="Outlet Loc" name="label_outlet_location" hidden>
                <Input placeholder="Outlet Loc" disabled />
              </Form.Item>
            </Col> */}
          </Row>

          <Row gutter={[30, 30]} justify="start" className="row-outlet-loc">
            <Col span={8}>
              <Form.Item
                label="Table"
                name="table_id"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Options!",
                  },
                ]}
              >
                <Input placeholder="Table ID" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Start Time"
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: "Please, input the start time!",
                  },
                ]}
              >
                <TimePicker
                  format={"HH"}
                  disabledTime={() => ({
                    ...{
                      disabledHours: () => [
                        moment(selectedData?.start_date).hours(),
                      ],
                      disabledMinutes: () => [0],
                      disabledSeconds: () => [0],
                    },
                  })}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="End Time"
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: "Please, input the end time!",
                  },
                ]}
              >
                <TimePicker format={"HH"} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[30, 30]} justify="start" className="row-outlet-loc">
            <Col span={24}>
              <Form.Item
                label="Remarks"
                name="remarks"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Choose an Options!",
                //   },
                // ]}
              >
                <Input.TextArea placeholder="Remarks" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="pos-add-table-resv-modal-form-divider-form"
            style={{ margin: "15px 0px" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              onClick={(e) => {
                // selectedTable(tableKeys);
                // handleCancel();
              }}
              loading={loading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Add to Table Order`}
            </Button>

            <Button
              className="delete-btn"
              type="danger"
              onClick={showModalCancelConfirm}
            >
              {`Cancel Table Reservation`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

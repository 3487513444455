// Import Type of Messages
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SET_MESSAGE,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  RESET_REGISTER,
  ADMIN_UPDATE_FAIL,
  ADMIN_UPDATE_SUCCESS,
  CLEAR_MESSAGE,
  RESET_MESSAGE,
  BLOCK_SUCCESS,
  BLOCK_FAIL,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
} from "./types";

// Import Auth Service
import authService from "../../Services/auth.service";

// Import Notification
import {
  failedLogin,
  failedRegister,
  successLogin,
  successRegister,
} from "../../Components/Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../Components/Reusable/Notification/MasterNotif/Notification";
// import authService from "../services/auth.service";

// REGISTER AUTH
export const register = (allBody) => async (dispatch) => {
  console.log("Register: ", allBody);

  return await authService
    .register(allBody)
    .then(
      (data) => {
        console.log("Return: ", data);

        if (data?.access_token) {
          dispatch({
            type: REGISTER_SUCCESS,
            payload: { isRegister: true },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.access_token,
          });

          successRegister(data, { method: 0 });

          return Promise.resolve(data);
        } else {
          dispatch({
            type: REGISTER_FAIL,
            payload: data?.error,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.error?.detail,
          });

          failedRegister(data?.error, { method: 0 });

          return Promise.reject(data?.error);
        }
      },
      (error) => {
        console.log("Error --> ", error);

        const message =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log("Message Error --> ", message);

        failedRegister(error, { method: 0 });

        dispatch({
          type: REGISTER_FAIL,
          payload: { isRegister: false },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(error);
      }
    )
    .catch((error) => {
      const message = error?.msg;

      console.log("Promise Error --> ", error);
      console.log("Message Error --> ", message);

      dispatch({
        type: REGISTER_FAIL,
        payload: { isRegister: false },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    });
};

// REGISTER AUTH RESET
export const resetRegister = (isRegister) => async (dispatch) => {
  console.log("Reset Sign Up: ", isRegister);

  return await authService
    .resetReg(isRegister)
    .then(
      (data) => {
        console.log("Return is Register: ", data);

        if (data === true) {
          dispatch({
            type: RESET_REGISTER,
            payload: { isRegister: false },
          });

          dispatch({
            type: RESET_MESSAGE,
            payload: "RESET AUTH",
          });

          return Promise.resolve(data);
        } else {
          dispatch({
            type: REGISTER_FAIL,
            payload: false,
          });

          dispatch({
            type: CLEAR_MESSAGE,
            payload: "RESET AUTH REGISTER FAILED",
          });

          return Promise.reject(data);
        }
      },
      (error) => {
        console.log("Error RESET --> ", error);

        const message = "FAILED RESET REGS";

        failedRegister(error, { method: 0 });

        dispatch({
          type: REGISTER_FAIL,
          payload: { isRegister: false },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(error);
      }
    )
    .catch((error) => {
      // const message = error?.msg;

      console.log("Promise Error --> ", error);
      // console.log("Message Error --> ", message);

      dispatch({
        type: REGISTER_FAIL,
        payload: { isRegister: false },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: error,
      });
    });
};

// LOGIN AUTH
export const login = (email, password, username) => async (dispatch) => {
  // console.log("Email --> ", email);
  // console.log("Pwd --> ", password);
  // console.log("Username --> ", username);
  // console.log("Dispatch --> ", dispatch);

  return await authService
    .login(email, password)
    .then(
      (data) => {
        console.log("Dispatch --> ", data);

        if (data?.access_token) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: data },
          });

          successLogin(data.access_token, { method: 0 });

          return Promise.resolve(data);
        } else {
          dispatch({
            type: LOGIN_FAIL,
            payload: { user: null },
          });

          failedLogin(data.error, { method: 0 });

          return Promise.reject(data.error);
        }
      },
      (error) => {
        console.log("Error --> ", error);

        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log("Message Error --> ", message);

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject(error);
      }
    )
    .catch((error) => {
      console.log("Promise --> ", error);
    });
};

// LOGOUT
export const logout = (value) => async (dispatch) => {
  console.log("I've called to Logut!", value);

  return await authService
    .logout(value)
    .then(
      (data) => {
        console.log("Response log Out: ", data);

        if (data?.access_token) {
          dispatch({
            type: LOGOUT_SUCCESS,
            payload: { user: null },
          });

          successLogin(data.access_token, { method: 1 });

          return Promise.resolve(data);
        } else {
          dispatch({
            type: LOGOUT_FAIL,
            // payload: {user: }
          });

          failedLogin(data.error, { method: 1 });

          return Promise.reject(data.error);
        }
      },
      (error) => {
        console.log("Response log Out Error: ", error);

        failedLogin(error, { method: 1 });

        dispatch({
          type: SET_MESSAGE,
          // payload:
        });
      }
    )
    .catch((error) => {
      console.log("Response log Out Error: ", error);

      // failedLogin(error, { method: 1 });

      dispatch({
        type: LOGOUT_FAIL,
      });
    });
};

// UPDATE ADMIN AUTH
export const updateByAdmin = (allBody) => async (dispatch) => {
  console.log("Update by Admin: ", allBody);

  return await authService
    .updateByAdmin(allBody)
    .then(
      (data) => {
        console.log("Return: ", data);

        if (data?.status === 200) {
          dispatch({
            type: ADMIN_UPDATE_SUCCESS,
            payload: { isAdminUpdate: true },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.data?.msg?.user?.id,
          });

          successRegister(data, { method: 1 });

          return Promise.resolve(data);
        } else {
          dispatch({
            type: ADMIN_UPDATE_FAIL,
            payload: data?.error,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.error?.detail,
          });

          failedRegister(data?.error, { method: 1 });

          return Promise.reject(data?.error);
        }
      },
      (error) => {
        console.log("Error --> ", error);

        const message =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log("Message Error --> ", message);

        failedRegister(error, { method: 1 });

        dispatch({
          type: ADMIN_UPDATE_FAIL,
          payload: { isAdminUpdate: false },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(error);
      }
    )
    .catch((error) => {
      const message = error?.msg;

      console.log("Promise Error --> ", error);
      console.log("Message Error --> ", message);

      // failedRegister(error, { method: 1 });

      dispatch({
        type: ADMIN_UPDATE_FAIL,
        payload: { isAdminUpdate: false },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    });
};

// UPDATE ADMIN AUTH
export const blockUser = (id, ban_duration, blocked_by) => async (dispatch) => {
  console.log("Block by Admin: ", id, ban_duration);

  return await authService
    .blockUsers(id, ban_duration, blocked_by)
    .then(
      (data) => {
        console.log("Return: ", data);

        if (data?.msg?.user) {
          dispatch({
            type: BLOCK_SUCCESS,
            payload: { block_status: true },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.msg?.user?.id,
          });

          masterSuccessNotification(data, {
            method: ban_duration > 0 ? 3 : 4,
            source: "User",
          });

          return Promise.resolve(data);
        } else {
          dispatch({
            type: BLOCK_FAIL,
            payload: { msg: data?.error, block_status: false },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.error?.detail,
          });

          masterFailedNotification(data?.error, {
            method: ban_duration > 0 ? 3 : 4,
            source: "User",
          });

          return Promise.reject(data?.error);
        }
      },
      (error) => {
        console.log("Error --> ", error);

        const message =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log("Message Error --> ", message);

        masterFailedNotification(error, {
          method: ban_duration > 0 ? 3 : 4,
          source: "User",
        });

        dispatch({
          type: BLOCK_FAIL,
          payload: { block_status: false },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(error);
      }
    )
    .catch((error) => {
      const message = error?.msg;

      console.log("Promise Error --> ", error);
      console.log("Message Error --> ", message);

      dispatch({
        type: BLOCK_FAIL,
        payload: { isAdminUpdate: false },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    });
};

// UPDATE ADMIN AUTH
export const deleteUser = (id) => async (dispatch) => {
  console.log("Delete by Admin: ", id);

  return await authService
    .deleteUser(id)
    .then(
      (data) => {
        console.log("Return: ", data);

        if (data?.status === 200) {
          dispatch({
            type: DELETE_USER_SUCCESS,
            payload: { block_status: true },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.msg?.user?.id,
          });

          masterSuccessNotification(data, {
            method: 2,
            source: "User",
          });

          return Promise.resolve(data);
        } else {
          dispatch({
            type: DELETE_USER_FAILED,
            payload: { msg: data?.error, block_status: false },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.error?.detail,
          });

          masterFailedNotification(data?.error, {
            method: 2,
            source: "User",
          });

          return Promise.reject(data?.error);
        }
      },
      (error) => {
        console.log("Error --> ", error);

        const message =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log("Message Error --> ", message);

        masterFailedNotification(error, {
          method: 2,
          source: "User",
        });

        dispatch({
          type: DELETE_USER_FAILED,
          payload: { block_status: false },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(error);
      }
    )
    .catch((error) => {
      const message = error?.msg;

      console.log("Promise Error --> ", error);
      console.log("Message Error --> ", message);

      dispatch({
        type: DELETE_USER_FAILED,
        payload: { is_delete: false },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    });
};

// UPDATE ADMIN AUTH
export const updateUser = (values) => async (dispatch) => {
  console.log("Update by Admin: ", values);

  return await authService
    .update(values)
    .then(
      (data) => {
        console.log("Return: ", data);

        if (data?.error) {
          dispatch({
            type: UPDATE_USER_FAIL,
            payload: data?.error,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.error?.detail,
          });

          masterFailedNotification(data?.error, {
            method: 1,
            source: "User Profiles",
          });

          return Promise.reject(data?.error);
        } else {
          dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: { is_update: true },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: data?.data?.msg?.user?.id,
          });

          masterSuccessNotification(data, {
            method: 1,
            source: "User Profiles",
          });

          return Promise.resolve(data);
        }
      },
      (error) => {
        console.log("Error --> ", error);

        const message =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log("Message Error --> ", message);

        masterFailedNotification(error, { method: 1, source: "User Profiles" });

        dispatch({
          type: UPDATE_USER_FAIL,
          payload: { is_update: false },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(error);
      }
    )
    .catch((error) => {
      const message = error?.msg;

      console.log("Promise Error --> ", error);
      console.log("Message Error --> ", message);

      // failedRegister(error, { method: 1 });

      dispatch({
        type: UPDATE_USER_FAIL,
        payload: { is_update: false },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    });
};

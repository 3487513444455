// REACT COMPONENTS
import React, { useState } from "react";

// ANTD COMPONENTS
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { SearchOutlined } from "@ant-design/icons";

// REACT ICONS
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// PAGE COMPONENTS
import JournalRegisterTable from "../../../Components/Table/Accounting/JournalRegister/Table";

export default function JournalRegisterPage() {
  const [search, setSearch] = useState();
  const [value, setValue] = useState(null);

  const handleSearch = (e) => {
    setValue(e.target.value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="journal-register-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <HiOutlineClipboardDocumentList
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Journal Register`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Journal Alias"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <JournalRegisterTable is_search={setSearch} searchKey={value} />
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// Import Ant Design's Components
import { Select } from "antd";

// CODE
export default function MasterApproval(props) {
  // PROPERTIES
  const { getApproval, approvals, roleLevel, isDisabled } = props;

  const [enums, setEnums] = useState(0);

  // STATE MANAGEMENT
  // Bulks
  const [bulks, setBulks] = useState([]);
  const [approval, setApproval] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/auth/permission-and-roles`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _filtered = _res.filter((val) => {
            return !val?.is_deleted;
          });

          handleMap(_filtered);
        } else {
          setOptionValue([]);
          setBulks([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
      });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (roleLevel > 0 && !cleanUp) {
      console.log("Roles Level: ", roleLevel);
      handleFilter(roleLevel);
    }

    return () => {
      cleanUp = true;
    };
  }, [roleLevel]);

  useEffect(() => {
    let cleanUp = false;

    if (approvals > 0 && optionValue.length > 0 && !cleanUp) {
      // console.log("Levels: ", approvals);
      setIsLoading(true);
      handleEdit(approvals, optionValue);
    } else {
      setApproval(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [approvals, optionValue]);

  // Handle Map
  const handleMap = (val) => {
    const _filtered = val;

    // console.log(_filtered);

    let optionFilter = _filtered.map((value) => ({
      id: value?.id,
      key: value?.id,
      value: `${value?.permission_level} (${value?.role})`,
      label: `Level ${value?.permission_level} (${value?.role})`,
      role: value?.role,
      level: value?.permission_level,
    }));

    setOptionValue(optionFilter);
    setApproval(null);
    setBulks(optionFilter);
  };

  // Map Filter Level
  const handleFilter = (lvl) => {
    if (bulks.length > 0) {
      const map = bulks.filter((val) => {
        if (val.level > lvl) {
          return val;
        }
      });

      // console.log("Mapped: ", map);
      setOptionValue(map);
    } else {
      setOptionValue([]);
    }

    setApproval(null);
  };

  // Handle Change
  const handleChange = (val, all) => {
    const _value = val;
    const _allKeys = all;

    // console.log("INI CODE ", _allKeys);

    setApproval(_value);
    getApproval({
      value: _allKeys?.value,
      id: _allKeys?.id,
      role: _allKeys?.role,
      level: _allKeys?.level,
    });
  };

  // Handle Change
  const handleClear = () => {
    setApproval(null);
  };

  // Handle Edit
  const handleEdit = (lvl, allKeys) => {
    let _filter = allKeys.filter((value) => {
      if (value?.level === lvl) {
        return value;
      }
    });

    console.log(enums);
    setEnums(enums + 1);

    if (enums === 0) {
      setApproval(_filter);
    } else {
      setApproval(null);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Select
        className="roles-select"
        key="roles-select"
        allowClear
        showSearch
        disabled={isDisabled}
        value={approval}
        bordered
        loading={isLoading}
        placeholder="Select Level"
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

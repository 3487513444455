// Import React's Components
import React from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React's Icons
import {
  FaCalendarCheck,
  FaCalendarPlus,
  FaCashRegister,
  FaConciergeBell,
} from "react-icons/fa";
import { GiCapitol, GiTheater } from "react-icons/gi";
import { BiCabinet } from "react-icons/bi";
import { TbReport } from "react-icons/tb";
import { SiAirtable } from "react-icons/si";
import { MdFastfood, MdFoodBank, MdOutlineTableBar } from "react-icons/md";

// Import Global Style
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../Style/Global/Global";
import { FaCheckToSlot } from "react-icons/fa6";

// CODE
export default function OutletPages() {
  // USENAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <FaConciergeBell
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Outlet Pages`}
              </Typography>
            </Row>

            <Row className="row-body" justify="start" style={mainBody}>
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("captain-order")}
                    // type="primary"
                    icon={<MdFoodBank style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <GiOpenBook style={globalIcon} /> */}
                    <Typography className="text-page" style={globalText}>
                      {`Captain Order`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("pos-cashier")}
                    // type="primary"
                    icon={<FaCashRegister style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <GiOpenBook style={globalIcon} /> */}
                    <Typography className="text-page" style={globalText}>
                      {`POS Cashier`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("table-availability")}
                    // type="primary"
                    icon={<MdOutlineTableBar style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <GiOpenBook style={globalIcon} /> */}
                    <Typography className="text-page" style={globalText}>
                      {`Table Reservation`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move(`kitchen-order`)}
                    icon={<MdFastfood style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Kitchen Order`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move(`close-cashier`)}
                    icon={<FaCheckToSlot style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Close Cashier`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("report-outlet")}
                    // type="primary"
                    icon={<TbReport style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Report Outlet`}</Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";
import { user_name, token } from "../../../../../API/Global/Payload";

import { LocalizationDate } from "../../../../Reusable/Functions/Localizations/Localization";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Input,
  DatePicker,
  Space,
  Button,
  Typography,
} from "antd";

// Import React Icons Components
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiCheckCircle, FiEdit, FiXCircle } from "react-icons/fi";
import { SearchOutlined } from "@ant-design/icons";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import POSReceiptBillFormTrx from "../../../../Forms/POSReceiptBill/POSReceiptBillTrx";
import { MdPrint } from "react-icons/md";

// CODE
export default function HistoryPaidBillModalTable(props) {
  // PROPS
  const { getSelectedHeader } = props;

  // Data
  const [data, setData] = useState();
  const [select, setSelect] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  // FETCH Data Header
  const fetchDataHeader = async () => {
    await axios
      .get(`${baseurl}/pos/order-header`, {})
      .then((response) => {
        console.log("HEADER FOR SAVED BILL: ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (e.is_deleted == false && e.is_paid == true) {
              return e;
            }
          });

          setData(_res);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchDataHeader();
  }, []);

  // Columns
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 50,
      render: (_, record) => {
        return (
          <Row className="action-guest-table-btn" style={{ width: "100%" }}>
            <Tooltip title="Print PO Form" className="edit-tooltip">
              <MdPrint
                className="edit-btn"
                onClick={() => {
                  console.log("ABOUT TO EDIT > ", record);
                  setOpenForm(true);
                  setSelectedPaymentId(record.payment_id);
                }}
                style={{
                  fontSize: 20,
                  color: "#1BC5BD",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (amount) => amount,
    },
    {
      title: "Table",
      dataIndex: "table_id",
      key: "table_id",
      render: (amount) => amount,
    },
    {
      title: "Payment ID",
      dataIndex: "payment_id",
      key: "payment_id",
      render: (amount) => amount,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (amount) => {
        return moment(LocalizationDate(amount)).format("YYYY-MM-DD, HH:mm");
      },
    },
    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      getSelectedHeader(selectedRows[0]);
    },
    getCheckboxProps: (record) => {
      return {
        // disabled: record.item_quantity == (0 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  return (
    <>
      <Table
        className="saved-bill-modal-table"
        name="saved-bill-modal-table"
        key="saved-bill-modal-table"
        columns={columns}
        dataSource={data}
        bordered
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} entries`,
          onChange(current, pageSize) {
            setPage(current);
            setPaginationSize(pageSize);
          },
        }}
        scroll={{
          x: 800,
        }}
        // rowSelection={{
        //   type: "radio",
        //   ...rowSelection,
        // }}
        //   components={{
        //     body: {
        //       cell: EditableCell,
        //     },
        //   }}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? "odd" : "even";
        }}
        rowKey={(record) => record.id}
      />
      <POSReceiptBillFormTrx
        openModal={openForm}
        closeModal={setOpenForm}
        payment_id={selectedPaymentId}
      />
    </>
  );
}

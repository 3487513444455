// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";
// import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterMainAccount(props) {
  // Props
  const { getCoaName, coa_name, is_header, disable } = props;

  // CONTEXT
  const { getMainAccount } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [coa, setCoa] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMainAccount({
      mainAccount: "coa",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response?.data?.msg;

          handleFilter(_response);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // setPaymentType(selectedPaymentType)
  }, []);

  // HANDLE FILTERED
  const handleFilter = (value) => {
    const _data = value;

    let filtered = _data.filter((filt) => {
      if (filt.is_deleted == false || filt.is_deleted == null) {
        if (is_header == true) {
          return filt?.level_account == "2";
        } else {
          return filt?.level_account == "3";
        }
      }
    });

    let option = filtered.map((dev) => ({
      value: `${dev.account_code} / ${dev.name}`,
      label: `${dev.account_code} / ${dev.name}`,
      code: dev.account_code,
      id: dev.id,
      desc: dev.name,
    }));

    // console.log("Mapped: ", option);
    // console.log(sortir);

    setOptionValue(option);
  };

  // Handle Change
  const handleChange = (e, opt) => {
    const _value = e;
    const _allKeys = opt;

    setCoa(_value);
    getCoaName({
      value: _value,
      code: _allKeys?.code,
      name: _allKeys?.desc,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setCoa(null);
  };

  // console.log(mainAccountCOA);

  return (
    <>
      <Select
        key={optionValue.id}
        style={{ width: "100%" }}
        value={coa != null ? coa : coa_name}
        showSearch
        allowClear
        disabled={disable}
        placeholder="Chart of Accounts"
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterTableCategory({
  getTableCat,
  outlet_loc,
  cat_value,
  // Is Disable
  is_disable,
}) {
  // CONTEXT
  const { getTableCategory } = useContext(Context);

  // STATE MANAGEMENT
  // Data
  const [tableCat, setTableCat] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async (value) => {
    await getTableCategory({
      type: "category-by-outlet",
      outlet_id: value?.locations_id || outlet_loc?.locations_id,
      onAwait: () => {},
      onSuccess: async (response) => {
        console.log("Outlet Location List: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleFilter(_res);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    if (outlet_loc.locations_id > 0 && !cleanUp) {
      setTableCat(null);
      fetchData(outlet_loc);
    } else {
      setTableCat(null);
      setOptionValue([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [outlet_loc]);

  useEffect(() => {
    let cleanUp = false;

    if (cat_value && !cleanUp) {
      console.log("Category: ", cat_value);

      if (optionValue.length > 0) {
        handleValue(cat_value);
      } else {
        setTableCat(null);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [cat_value]);

  // Handle Filter
  const handleFilter = (value) => {
    const _data = value;

    let _mapped = _data.map((val) => ({
      label: val?.table_category_name,
      value: val?.table_category_name,
      code: val?.table_category_code,
      id: val?.id,
    }));

    // console.log("Map results: ", _mapped);
    setOptionValue(_mapped);
  };

  // Handle Change
  const handleChange = (val, code) => {
    const _value = val;
    const _allKeys = code;

    // console.log("Values: ", _value);
    // console.log("All Keys: ", _allKeys);

    setTableCat(_value);
    getTableCat({
      value: _value,
      id: _allKeys?.id,
      // key: _allKeys?.key,
      label: _allKeys?.label,
      code: _allKeys?.code,
    });
  };

  // Handle Value
  const handleValue = (value) => {
    const _data = value;

    let _filtered = optionValue.filter((val) => {
      return val.value === _data;
    });

    console.log("Filtered Value Category: ", _filtered);

    if (_filtered.length > 0) {
      setTableCat(_data);
      getTableCat({
        value: _data,
        id: _filtered[0]?.id,
        label: _filtered[0]?.label,
        code: _filtered[0]?.code,
      });
    }
  };

  // Handle Clear
  const handleClear = () => {
    setTableCat(null);
  };

  return (
    <>
      <Select
        className="table-cat-select"
        key="table-cat-select"
        placeholder="Choose Table Category"
        allowClear
        showSearch
        disabled={is_disable}
        value={tableCat}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

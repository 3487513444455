// Import React Components
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
// import { Context as ManagerContext } from "../../../API/Context/ChannelManagerContext/CMContext";
import { Context as ManagerContext } from "../../../API/Context/ManagerContext/ManagerContext";

// Import Antd Components
import {
  Card,
  Col,
  Row,
  Form,
  Typography,
  DatePicker,
  InputNumber,
  Button,
  List,
  Divider,
  Skeleton,
  Collapse,
  Space,
  Tag,
  Alert,
  Modal,
  notification,
} from "antd";

// Import React Icons
import {
  PlusOutlined,
  DeleteOutlined,
  DeleteFilled,
  SwitcherFilled,
  SwitcherTwoTone,
  UpCircleOutlined,
  DownCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import {
  FaUser,
  FaRegSnowflake,
  FaWifi,
  FaCube,
  FaMale,
  FaTree,
  FaSwimmingPool,
  FaWater,
} from "react-icons/fa";
import { AiOutlineSync } from "react-icons/ai";
import { RiMoonFill } from "react-icons/ri";
import { TbChristmasTree } from "react-icons/tb";
import { GiPlatform } from "react-icons/gi";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// Const
const { Text, Title } = Typography;
const { Panel } = Collapse;
// const { warning } = Modal;
const { info, warning } = notification;

// Const Style
const marginLayout = {
  marginTop: 30,
};
const styleColor = {
  backgroundColor: "#fff",
  borderRadius: 10,
};
const tagStyles = {
  padding: "5px 10px",
  fontSize: 17,
  fontWeight: 750,
};

const data1 = [
  {
    room_category: `Deluxe Room`,
    room_category_detail:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    max_person: "3",
    air_conditioner: false,
    wifi: true,
    bestpick_price: "600000",
    specialoffer_price: "750000",
    discount: 10,
  },
  {
    room_category: `Executive Room`,
    room_category_detail:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    max_person: "4",
    air_conditioner: true,
    wifi: true,
    bestpick_price: "1250000",
    specialoffer_price: "1500000",
    discount: 15,
  },
  {
    room_category: `Deluxe Room`,
    room_category_detail:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    max_person: "3",
    air_conditioner: false,
    wifi: true,
    bestpick_price: "600000",
    specialoffer_price: "750000",
    discount: 10,
  },
  {
    room_category: `Executive Room`,
    room_category_detail:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    max_person: "4",
    air_conditioner: true,
    wifi: true,
    bestpick_price: "1250000",
    specialoffer_price: "1500000",
    discount: 15,
  },
];

// OCDE
export default function RoomManager({ props }) {
  // STATE
  const { state } = useLocation();

  const IconText = ({ icon, text, color }) => (
    <Space className="space-icon">
      {React.createElement(icon, { style: { color: color, fontSize: 20 } })}
      {text}
    </Space>
  );

  // console.log(
  //   "State --> " + state,
  //   "Hash --> " + hash,
  //   "Key --> " + key,
  //   "Pathname --> " + pathname,
  //   "Search --> " + search
  // );

  // CONTEXT
  const { getCMRoomAvailability, getManagerAvailable } =
    useContext(ManagerContext);

  // STATE MANAGEMENT
  // Data
  const [res, setRes] = useState(state?.data ? state.data : null);
  const [article, setArticle] = useState(state?.article ? state.article : []);
  const [guest, setGuest] = useState(state?.guest ? state.guest : null);
  // Room Available
  const [roomInfo, setRoomInfo] = useState(null);
  const [avail, setAvail] = useState(null);
  // Elements
  const [dataCategory, setDataCategory] = useState([]);
  const [dataPrice, setDataPrice] = useState([]);
  const [dataRoomAvl, setDataRoomAvl] = useState([]);
  const [roomCategoryPick, setRoomCategoryPick] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Use Navigate
  const navigate = useNavigate();
  // const location = useLocation();

  console.log("State --> ", state);

  // Fetch Data
  const fetchData = async (value) => {
    const submitData = value;
    setLoading(true);

    console.log(submitData);

    await getCMRoomAvailability({
      cmRoomAvl: "room/room-availability",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response CM Room Avl => ", response);

        // setDataRoomAvl(response.data.msg);
        setRoomInfo(response.data.msg);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Error Main-Group => ", error);
        // setLoading(false);
        // failedFetch(error);
        setView(!view);
      },
    });

    await getManagerAvailable({
      room: "room",
      avail: "cm_availability",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Avail --> ", response);

        console.log(res);

        let filtered = response.data.msg.filter((filt) => {
          var start = submitData?.arrival
            ? moment(submitData.arrival).format("YYYY-MM-DD")
            : moment(state?.data.arrival).format("YYYY-MM-DD");
          var end = submitData?.departure
            ? moment(submitData.departure).format("YYYY-MM-DD")
            : moment(state?.data.departure).format("YYYY-MM-DD");

          // console.log(start + " --- " + end);

          if (
            moment(filt.date_availability).isBetween(start, end, "days", "[)")
          ) {
            return filt;
          }
        });

        console.log(filtered);

        setAvail(filtered);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Error Main-Group => ", error);
        // setLoading(false);
        // failedFetch(error);
        setView(!view);
      },
    });
  };

  // USE EFFECT DATA
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT DATA
  useEffect(() => {
    if (roomInfo != null && avail != null) {
      handleAvail();
    }
    // else {
    //   handleEmpty()
    // }
  }, [roomInfo, avail]);

  // On Finish
  const onFinish = (value) => {
    const content = value;
    console.log("ON FIN --> ", content);

    setRes(content);
    fetchData(content);
  };

  // On Finish Failed
  const onFinishFailed = (err) => {
    console.log(err);
  };

  // Warning Message
  const warningMes = (err) => {
    warning({
      className: "warning-notif",
      message: (
        <div style={{ fontSize: 18, fontWeight: 750, color: "#f5222d" }}>
          You Haven't Pick Any Dates or Total Persons!
        </div>
      ),
      description:
        "Please, Choose Arrival, Departure or Total Persons to Proceed!",
      placement: "top",
      duration: 15,
      style: {
        fontWeight: "bold",
        backgroundColor: "#feffe6",
        color: "#000000",
        width: 500,
        borderRadius: 5,
      },
    });

    // warning({
    //   content: "Please, Choose Arrival, Departure or Total Persons to Proceed!",
    //   onCancel: () => {},
    //   onOk: () => {},
    // });
  };

  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // Date
  const [startDate, setStartDate] = useState(moment(date));
  const [endDate, setEndDate] = useState(moment(date));
  // Adult Value
  const [people, setPeople] = useState(
    state?.data?.person ? state.data.person : 0
  );
  // Night
  const [night, setNight] = useState(state?.data.night ? state.data.night : 0);

  // HANDLE START DATE AND END DATE
  // Get Start Date
  const handleFirstDate = (first) => {
    const start = moment(first.format()).toArray();
    setStartDate(start);
    // state.arrival = first;
    // console.log("start date", start);

    if (endDate) {
      var ngt = moment(endDate).diff(start, "days");

      setNight(ngt);

      form.setFieldsValue({
        night: ngt,
        room_qty: 1,
        // adult: 1,
        arrival: moment(start),
      });
      console.log("Start = ", ngt);
    }
  };

  // Get End Date
  const handleLastDate = (last) => {
    const end = moment(last.format()).toArray();
    // state.departure = last;
    setEndDate(end);
    // console.log("last date", end);

    var ngt = moment(end).diff(startDate, "days");
    setNight(ngt);

    // console.log(ngt);

    form.setFieldsValue({
      night: ngt,
      departure: moment(end),
      room_qty: 1,
      // adult: 1,
    });
  };
  // GET ADULT
  const getAdult = (value) => {
    // console.log(form.getFieldsValue("child"));

    var ppl = form.getFieldsValue("child");

    setPeople(ppl.child + value);

    form.setFieldsValue({
      // child: value,
      adult: value,
      // infant: value,
    });
  };
  // GET CHILD
  const getChild = (value) => {
    var ppl = form.getFieldsValue("adult");
    // console.log(ppl);

    setPeople(ppl.adult + value);

    form.setFieldsValue({
      child: value,
      // adult: value,
      // infant: value,
    });
  };
  // GET ADULT
  const getInfant = (value) => {
    // console.log(value);

    form.setFieldsValue({
      // child: value,
      // adult: value,
      infant: value,
    });
  };

  // HANDLE AVAIL
  const handleAvail = async () => {
    const room = avail;
    const info = roomInfo;

    // console.log(room);
    // console.log(info);

    const merge = [];

    for (let i = 0; i < info.length; i++) {
      merge.push({
        ...info[i],
        ...room.find(
          (itmInner) => itmInner.room_category == info[i].room_category_name
        ),
      });
    }

    // console.log(merge);
    setDataRoomAvl(merge);

    setLoading(false);
  };

  // MOVE
  const move = (value) => {
    const item = value;

    console.log("NAV --> ", item);

    if (res) {
      navigate("/channel-manager/reservation-article", {
        state: {
          data: {
            room_category_name: item.room_category_name,
            room_category_code: item.room_category_code,
            price: item.unit_price,
            room_qty: 1,
            adult: res.adult,
            total_child: res.total_child > 0 ? res.total_child : 0,
            infant: res.infant > 0 ? res.infant : 0,
            arrival: moment(res.arrival).format("YYYY-MM-DD"),
            departure: moment(res.departure).format("YYYY-MM-DD"),
            night: night,
            person: people,
            room_rate_id: [item.rate_code_id],
            rate_code: item.rate_code_name,
            price_id: item.id,
          },
          article: article,
          guest: guest,
        },
      });
    } else {
      warningMes(item);
    }
  };

  console.log(dataCategory);
  console.log(dataPrice);
  console.log(dataRoomAvl);
  console.log(roomCategoryPick);

  return (
    <>
      <Card title={<Title level={4}>Reservation Channel Manager</Title>}>
        <Typography>Select the rooms that suits for you.</Typography>

        {view == true ? (
          <Alert
            className="article-alert"
            key={"article-alert"}
            message={`Failed to load data! Please, check your internet or try again later!`}
            type="error"
            showIcon
            // style={{
            //   backgroundColor: "geekblue",
            // }}
          />
        ) : null}
      </Card>

      <Row gutter={[30, 30]} style={marginLayout}>
        <Col xxl={6} xl={6} lg={24} md={24} sm={24} xs={24}>
          <Row
            style={{
              backgroundColor: "#FFFFFF",
              minHeight: 450,
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Col span={24} style={{ padding: 30 }}>
              <Row>
                <Title level={4}>Select Date</Title>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#000000" }}
              />

              <Form
                name="reservation-dashboard-channel-manager-form"
                className="reservation-dashboard-channel-manager-form"
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                  arrival: state?.data.arrival
                    ? moment(state.data.arrival)
                    : null,
                  departure: state?.data.departure
                    ? moment(state.data.departure)
                    : null,
                  adult: state?.data.adult > 0 ? state.data.adult : 0,
                  total_child:
                    state?.data.total_child > 0 ? state.data.total_child : 0,
                  infant: state?.data.infant > 0 ? state.data.infant : 0,
                  night: state?.data.night > 0 ? state.data.night : 0,
                }}
                style={{ padding: "0px 0px 0px 0px" }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Arrival"
                      name="arrival"
                      rules={[
                        {
                          required: true,
                          message: "Please, Select Arrival Date!",
                        },
                      ]}
                      // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                    >
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        onChange={handleFirstDate}
                        disabledDate={disabledDate}
                        placeholder={"Start Date"}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Departure"
                      name="departure"
                      rules={[
                        {
                          required: true,
                          message: "Please, Select Departure Date!",
                        },
                        {
                          validator: async (_, departure) => {
                            var arrival = form.getFieldValue("arrival");

                            // console.log(
                            //   "ARRIVAL => ",
                            //   moment(arrival).add(1, "days")
                            // );
                            // console.log("DEPART => ", departure);
                            // console.log("DIFF", departure <= arrival);

                            if (arrival != null)
                              if (departure <= moment(arrival)) {
                                return Promise.reject(
                                  "Departure Date Must be Greater than Arrival Date!"
                                );
                              }
                          },
                        },
                      ]}
                      // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                    >
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        placeholder={"End Date"}
                        disabledDate={disabledDate}
                        onChange={handleLastDate}
                        style={{
                          minWidth: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[5, 25]}>
                  <Col xxl={8} xl={24}>
                    <Form.Item
                      label="Adult"
                      name="adult"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input Total Guest!",
                        },
                        {
                          validator: async (_, adult) => {
                            var qty = form.getFieldValue("room_qty");
                            // console.log("ARRIVAL => ", arrival);
                            // console.log("DEPART => ", departure);
                            // console.log("DIFF", (departure = arrival));

                            if (qty != null)
                              if (adult <= qty - 1) {
                                return Promise.reject(
                                  "Adult guest must be greater or equals to Room Quantity."
                                );
                              }
                          },
                        },
                      ]}
                      style={{ margin: "0px 30px 0px 0px" }}
                    >
                      <InputNumber
                        size="large"
                        controls={<PlusOutlined />}
                        onChange={getAdult}
                        placeholder="Total Adult"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={8} xl={24}>
                    <Form.Item
                      label="Child"
                      name="total_child"
                      style={{ margin: "0px 30px 0px 0px" }}
                    >
                      <InputNumber
                        size="large"
                        min={0}
                        max={10}
                        onChange={getChild}
                        placeholder="Total Child"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={8} xl={24}>
                    <Form.Item label="Infant" name="infant">
                      <InputNumber
                        size="large"
                        min={0}
                        max={10}
                        placeholder="Total Infant"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label="night" name="night" hidden={true}>
                  <InputNumber
                    size="large"
                    min={0}
                    max={100}
                    placeholder="Night"
                    // style={{ width: "100%" }}
                  />
                </Form.Item>

                <Row justify="start" gutter={[10, 10]} className="row-icon">
                  <Col
                    xxl={12}
                    xl={24}
                    lg={12}
                    md={12}
                    sm={12}
                    className="col-night"
                  >
                    <Row justify="space-between">
                      <Col span={12}>
                        <RiMoonFill
                          className="night-icon"
                          style={{
                            color: "#0050b3",
                            fontSize: 50,
                          }}
                        />
                      </Col>

                      <Col
                        span={12}
                        className="col-night"
                        style={{
                          backgroundColor: "#69c0ff",
                          borderRadius: 5,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          className="text-night"
                          style={{ color: "#FFFFFF" }}
                        >{`Total Night:`}</Typography>
                        <Typography
                          className="text-night"
                          style={{
                            color: "#FFFFFF",
                            fontSize: 30,
                            fontWeight: 750,
                          }}
                        >{`${night}`}</Typography>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xxl={12}
                    xl={24}
                    lg={12}
                    md={12}
                    sm={12}
                    className="col-person"
                  >
                    <Row justify="space-between">
                      <Col span={12}>
                        <FaMale
                          className="person-icon"
                          style={{
                            color: "#0050b3",
                            fontSize: 50,
                          }}
                        />
                      </Col>

                      <Col
                        span={12}
                        className="col-persons"
                        style={{
                          backgroundColor: "#69c0ff",
                          borderRadius: 5,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          className="text-night"
                          style={{
                            color: "#FFFFFF",
                          }}
                        >{`Persons:`}</Typography>
                        <Typography
                          className="text-night"
                          style={{
                            color: "#FFFFFF",
                            fontSize: 30,
                            fontWeight: 750,
                          }}
                        >{`${people}`}</Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  style={{ margin: "15px 0px", backgroundColor: "#000000" }}
                />

                <Row className="row-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    key={"submit"}
                    // onClick={onFinish}
                    className="submit-add-article-inventory"
                    style={{
                      backgroundColor: "#1BC5BD",
                      borderColor: "#1BC5BD",
                      width: "100%",
                    }}
                  >
                    Check Room Availability
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>

        <Col xxl={18} xl={18} lg={24} sm={24} xs={24}>
          <Row style={mainBody}>
            <Col span={24} style={{ padding: 30 }}>
              <Row>
                <Title level={4}>Select Rooms</Title>
              </Row>

              <Row justify="start" style={{ color: "#8c8c8c" }}>
                <Space size={[0, 8]} wrap>
                  <Tag
                    color="#87d068"
                    style={tagStyles}
                  >{`Room Quantity: 1`}</Tag>

                  <Tag color="#2db7f5" style={tagStyles}>{`Adult: ${
                    res?.adult || 0
                  }`}</Tag>

                  <Tag color="#2db7f5" style={tagStyles}>{`Child: ${
                    res?.total_child || 0
                  }`}</Tag>

                  <Tag color="#2db7f5" style={tagStyles}>{`Infant: ${
                    res?.infant || 0
                  }`}</Tag>

                  <Tag color="green" style={tagStyles}>
                    {`Check-In: ${moment(res?.arrival).format("DD-MM-YYYY")}` ||
                      ""}
                  </Tag>

                  <Tag color="volcano" style={tagStyles}>
                    {`Check-Out: ${moment(res?.departure).format(
                      "DD-MM-YYYY"
                    )}` || ""}
                  </Tag>

                  <Tag color="geekblue" style={tagStyles}>{`Night: ${
                    res?.night || 1
                  }`}</Tag>
                </Space>
              </Row>

              <Divider
                className="divider-form"
                style={{
                  backgroundColor: "#000000",
                  margin: "10px 0px",
                }}
              />

              <Row className="row-list">
                <Col span={24} className="col-list">
                  <List
                    className="room-list"
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                      onChange: (page) => {
                        console.log(page);
                      },
                      pageSize: 3,
                    }}
                    dataSource={dataRoomAvl}
                    // footer={
                    //   <div>
                    //     <b>ant design</b> footer part
                    //   </div>
                    // }
                    renderItem={(item) => (
                      <>
                        <List.Item
                          key={item.room_category_name}
                          actions={[
                            item.room_size ? (
                              <IconText
                                icon={FaCube}
                                text={`${item.room_size} m2`}
                                key="list-room-size"
                                color="#1890ff"
                              />
                            ) : null,
                            item.air_conditioner == true ? (
                              <IconText
                                icon={FaRegSnowflake}
                                text="Air Conditioner"
                                key="list-ac"
                                color="#1890ff"
                              />
                            ) : null,
                            item.free_wifi == true ? (
                              <IconText
                                icon={FaWifi}
                                text="Free Wifi"
                                key="list-wifi"
                                color="#1890ff"
                              />
                            ) : null,
                            item.balcony == true ? (
                              <IconText
                                icon={GiPlatform}
                                text="Balcony"
                                key="balcony"
                                color="#1890ff"
                              />
                            ) : null,
                            item.pool_view == true ? (
                              <IconText
                                icon={FaWater}
                                text="Pool View"
                                key="pool"
                                color="#1890ff"
                              />
                            ) : null,
                            item.garden_view == true ? (
                              <IconText
                                icon={FaTree}
                                text="Garden View"
                                key="garden"
                                color="#1890ff"
                              />
                            ) : null,
                          ]}
                          extra={
                            <img
                              style={{ maxWidth: 272 }}
                              width="100%"
                              alt="logo"
                              src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                            />
                          }
                        >
                          <Skeleton
                            loading={loading}
                            active
                            className="room-skeleton"
                          >
                            <List.Item.Meta
                              // avatar={<Avatar src={item.avatar} />}
                              className="content-list-meta"
                              title={`${item.room_category_name} ROOM`}
                              description={
                                <>
                                  <Row
                                    className="row-desc"
                                    justify="space-between"
                                    gutter={[30, 30]}
                                  >
                                    <Col
                                      className="col-desc"
                                      xl={{ span: 16 }}
                                      md={{ span: 12 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Typography>
                                        {item.description}
                                      </Typography>

                                      <Row
                                        justify="start"
                                        className="row-available"
                                        align="bottom"
                                        style={{
                                          margin: "30px 0px 0px",
                                        }}
                                      >
                                        <Typography
                                          className="text-avail"
                                          style={{
                                            // color: "#FFFFFF",
                                            fontWeight: 750,
                                            fontSize: 17,
                                            borderRadius: 5,
                                            border: "3px solid #092b00",
                                            // borderColor: "#092b00",
                                            backgroundColor: "#FFFFFF",
                                            padding: 5,
                                            margin: "0px 5px 0px 0px",
                                          }}
                                        >
                                          {item.cm_available}
                                        </Typography>

                                        <Tag
                                          key={item.id}
                                          className="avail-tag"
                                          color="#092b00"
                                          style={tagStyles}
                                        >{`Only ${item.cm_available} Room(s) Left Available`}</Tag>
                                      </Row>
                                    </Col>

                                    <Col
                                      xl={{ span: 8 }}
                                      md={{ span: 12 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Typography
                                        className="text-start-from"
                                        style={{ textAlign: "center" }}
                                      >
                                        Start from:
                                      </Typography>

                                      <Title
                                        level={3}
                                        style={{
                                          textAlign: "center",
                                          color: "#52c41a",
                                        }}
                                      >
                                        {`Rp. ${item.unit_price}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                      </Title>

                                      <Typography
                                        className="text-start-from"
                                        style={{ textAlign: "center" }}
                                      >
                                        For 1 Night (Tax Included)
                                      </Typography>
                                    </Col>
                                  </Row>
                                </>
                              }
                            />
                          </Skeleton>
                        </List.Item>

                        <Collapse
                          // defaultActiveKey={["1"]}
                          bordered={false}
                          // onChange={onChange}
                          className="room-collapse"
                        >
                          <Panel
                            header={
                              <Button type="primary">Detail & Book</Button>
                            }
                            key="1"
                          >
                            <List.Item>
                              <List.Item.Meta
                                // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                title={
                                  <Typography className="text-hover">
                                    Best Pick
                                  </Typography>
                                }
                                description={
                                  <Row
                                    gutter={[30, 30]}
                                    className="row-pick-deals"
                                  >
                                    <Col
                                      // span={12}
                                      className="col-desc"
                                      xl={{ span: 12 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      {item.description}

                                      {/* <Divider
                                        className="divider-vertical"
                                        type="vertical"
                                        style={{
                                          backgroundColor: "#000000",
                                        }}
                                      /> */}
                                    </Col>

                                    <Col
                                      // span={4}
                                      xl={{ span: 4 }}
                                      md={{ span: 8 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Row justify="center">
                                        Breakfast Included
                                      </Row>
                                    </Col>

                                    <Col
                                      // span={6}
                                      xl={{ span: 4 }}
                                      md={{ span: 8 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      {/* {item.discount != 0 ? (
                                        <>
                                          <Row justify="center">
                                            <Title level={3}>
                                              Rp.{" "}
                                              {` ${
                                                item.bestpick_price -
                                                item.bestpick_price *
                                                  (item.discount / 100)
                                              }`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                            </Title>
                                          </Row>
                                          <Row justify="center">
                                            <Text delete>
                                              Rp.{" "}
                                              {` ${item.bestpick_price}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                            </Text>
                                          </Row>
                                        </>
                                      ) : (
                                        <></>
                                      )} */}
                                      <Row justify="center">
                                        <Title level={3}>
                                          {`Rp. ${item.unit_price}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                        </Title>
                                      </Row>
                                    </Col>

                                    <Col
                                      // span={2}
                                      xl={{ span: 4 }}
                                      md={{ span: 8 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                    >
                                      <Row justify="center">
                                        <Button
                                          type="primary"
                                          className="submit-btn"
                                          style={{
                                            backgroundColor: "#1BC5BD",
                                            borderColor: "#1BC5BD",
                                            // width: "100%",
                                          }}
                                          onClick={() => move(item)}
                                        >
                                          Reserve
                                        </Button>
                                      </Row>
                                    </Col>
                                  </Row>
                                }
                                style={{
                                  border: "2px solid #1890ff",
                                  padding: 10,
                                  borderRadius: 5,
                                }}
                              />

                              {/* <Divider
                                className="divider-form"
                                style={{
                                  margin: 0,
                                  backgroundColor: "#000000",
                                }}
                              /> */}

                              {/*
                              <List.Item.Meta
                                // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                title={
                                  <a href="https://ant.design">Special Offer</a>
                                }
                                description={
                                  <Row gutter={[30, 30]}>
                                    <Col
                                      // span={12}
                                      xl={{ span: 12 }}
                                      md={{ span: 24 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                      style={{
                                        padding: 10,
                                        borderLeft: "1px #d9d9d9 solid",
                                      }}
                                    >
                                      Take Advantage of exceptional offers with
                                      benefits including: 2 pax of Lunch/Dinner,
                                      ...
                                    </Col>
                                    <Col
                                      // span={4}
                                      xl={{ span: 4 }}
                                      md={{ span: 8 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                      style={{
                                        padding: 10,
                                        borderLeft: "1px #d9d9d9 solid",
                                      }}
                                    >
                                      <Row justify="center">
                                        Breakfast Included
                                      </Row>
                                    </Col>
                                    <Col
                                      // span={6}
                                      xl={{ span: 4 }}
                                      md={{ span: 8 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                      style={{
                                        padding: 10,
                                        borderLeft: "1px #d9d9d9 solid",
                                      }}
                                    >
                                      <Row justify="center">
                                        <Title level={3}>
                                          Rp.{" "}
                                          {` ${
                                            item.specialoffer_price -
                                            item.specialoffer_price *
                                              (item.discount / 100)
                                          }`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                        </Title>
                                      </Row>
                                      <Row justify="center">
                                        <Text delete>
                                          Rp.{" "}
                                          {` ${item.specialoffer_price}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                        </Text>
                                      </Row>
                                    </Col>
                                    <Col
                                      // span={2}
                                      xl={{ span: 4 }}
                                      md={{ span: 8 }}
                                      sm={{ span: 24 }}
                                      xs={{ span: 24 }}
                                      style={{
                                        padding: 10,
                                        borderLeft: "1px #d9d9d9 solid",
                                      }}
                                    >
                                      <Row justify="center">
                                        <Button
                                          type="primary"
                                          style={{
                                            backgroundColor: "#1BC5BD",
                                            borderColor: "#1BC5BD",
                                            // width: "100%",
                                          }}
                                        >
                                          Reserve
                                        </Button>
                                      </Row>
                                    </Col>
                                  </Row>
                                }
                              />*/}
                            </List.Item>
                          </Panel>
                        </Collapse>

                        <Divider
                          className="divider-form"
                          style={{
                            backgroundColor: "#000000",
                            margin: "5px 0px",
                          }}
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

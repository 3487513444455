// REACT COMPONENTS
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Space, Typography, DatePicker, Form, } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaRightLeft } from "react-icons/fa6";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import StockItemTransformReportTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/OutgoingStock/StockItemTransform/Table";

export default function StockItemTransformReportPage() {
  // STATE
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // USE FORM
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/outgoing-stock/";

    navigate(`${path}`);
  };

  const handleDate = (value) => {
    console.log("handleDate value", value);
    if(value){
      setStartDate(value[0]);
      setEndDate(value[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="incoming-stock-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaRightLeft
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Stock Item Transform Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Date`}
                </Typography>

                <RangePicker
                  allowClear
                  className="date"
                  format="YYYY-MM-DD"
                  onChange={handleDate}
                  style={{
                    minWidth: 150,
                    maxWidth: 250,
                  }}
                />
              </Col>
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {startDate && endDate ? (
            <StockItemTransformReportTable
              _startDate={startDate}
              _endDate={endDate}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASEURL
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  DatePicker,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Master Notification
import {
  failedRatePeriod,
  masterIncomplete,
  successRatePeriod,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Confirm Modal
const { confirm } = Modal;

// CODE
export default function RatePeriodicTable({ getRoomCode }) {
  // Table Components
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);
                      form.setFieldsValue({
                        promo_name: record.promo_name,
                        price: record.price,
                        start_date: moment(record.start_date),
                        end_date: moment(record.end_date),
                        created_by: record.created_by,
                        created_date: record.created_date,
                        modified_by: record.updated_by,
                        modified_date: record.updated_date,
                      });
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Promo",
      dataIndex: "promo_name",
      key: "promo_name",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => {
        return moment(date).format("DD MMM YYYY");
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => {
        return moment(date).format("DD MMM YYYY");
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Params
  const currency = CurrencySymbols().code;

  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // CONTEXT
  const { getMasterRatePeriod } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [roomCode, setRoomCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = () => {
    setLoading(true);

    getMasterRatePeriod({
      rate: "rate-code",
      period: "periodic",
      room_cat_code: roomCode[0].room_category_code,
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("Periodic >> ", response);

        if (response.data !== null) {
          let filtered = response.data.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) {
              return val;
            }
          });
          setData(filtered);
        } else {
          setData(response.data);
        }

        // setFilteredList(filtered);
        setLoading(false);
        // console.log("ISI Room List >> ", filtered);
      },
      onReject: (error) => {
        failedFetch(error);
        setLoading(false);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    if (roomCode) fetchData();
  }, [roomCode]);

  // USE EFFECT SELECTOR
  useEffect(() => {
    setRoomCode(getRoomCode);
  }, [getRoomCode]);

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setEditData(null);
    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    // console.log(e);
    handleSubmit(e);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let deleteContent = record;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete promo ${deleteContent.promo_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    let contentSubmit = e;
    console.log("SUBMIT ==> ", contentSubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/room/rate-periodic`,
          {
            start_date: contentSubmit.start_date,
            end_date: contentSubmit.end_date,
            price: contentSubmit.price,
            room_category_code: roomCode[0].room_category_code,
            room_category_name: roomCode[0].room_category_name,
            promo_name: contentSubmit.promo_name,
            created_by: user_name,
            description: null,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("POST Period = ", response);
          successRatePeriod(response, { method: 0 });
          handleCancel();
        })
        .catch((error) => {
          console.log("POST Period", error);
          failedRatePeriod(error, { method: 0 });
        })
        .finally(() => {
          fetchData();
        });
    } else {
      await axios
        .put(
          `${baseurl}/room/rate-periodic`,
          {
            id: editData.id,
            start_date: contentSubmit.start_date,
            end_date: contentSubmit.end_date,
            price: contentSubmit.price,
            room_category_code: editData.room_category_code,
            room_category_name: editData.room_category_name,
            promo_name: contentSubmit.promo_name,
            updated_by: user_name,
            description: null,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("PUT Period = ", response);
          successRatePeriod(response, { method: 1 });
          handleCancel();
        })
        .catch((error) => {
          failedRatePeriod(error, { method: 1 });
          console.log("PUT Period", error);
        })
        .finally(() => {
          fetchData();
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (e) => {
    let deleteContent = e.id;
    console.log("Delete = ", deleteContent);

    var obj = {
      ["id"]: deleteContent,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/room/rate-periodic`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Rate => ", response);
        successRatePeriod(response, { method: 2 });
      })
      .catch((error) => {
        console.log("DEL Rate => ", error);
        failedRatePeriod(error, { method: 2 });
      })
      .finally(() => {
        fetchData();
      });
  };

  // console.log("Room Category = ", roomCode);

  return (
    <>
      <Row className="rate-period-main-table" justify="center" style={mainBody}>
        <Col
          span={24}
          style={{
            padding: 30,
            backgroundColor: "#FFFFFF",
            minHeight: "100%",
            borderRadius: 5,
          }}
        >
          <Row className="row-modal-btn" justify="start">
            <Col span={12}>
              <Typography
                key={"rate-code-desc"}
                style={{ fontWeight: 600, fontSize: 20, color: "#3F4254" }}
              >
                {`Room Category: ${
                  roomCode ? roomCode[0].room_category_name.toUpperCase() : "-"
                }`}
              </Typography>
            </Col>

            <Col className="col-modal-btn" span={12}>
              <Row justify="end">
                <Button
                  className="submit-btn"
                  type="primary"
                  disabled={roomCode ? false : true}
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: "#1BC5BD",
                    borderColor: "#1BC5BD",
                  }}
                >
                  Add Rate Periodic
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="rate-periodic-table"
              name="rate-periodic-table"
              key="rate-periodic-table"
              size="small"
              columns={columns}
              dataSource={data}
              loading={loading}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-rate-period-modal"
        title={
          edit == false ? (
            <Row className="row-modal-title">
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                Add Rate Periodic
              </Typography>
            </Row>
          ) : (
            <Row className="row-modal-title">
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                Edit Rate Periodic
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          className="room-period-modal-form"
          name="room-period-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-rate-period-date">
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: "Please, pick start date!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  placeholder={"Start Date"}
                  style={{ width: 205, marginRight: 30 }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="End Date"
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: "Please, pick end date!",
                  },
                  {
                    validator: async (_, departure) => {
                      var arrival = form.getFieldValue("arrival");
                      // console.log("ARRIVAL => ", arrival);
                      // console.log("DEPART => ", departure);
                      // console.log("DIFF", (departure = arrival));

                      if (arrival != null)
                        if (departure <= moment(arrival).add(1, "days")) {
                          return Promise.reject(
                            "Departure Date Must be Greater than Arrival Date."
                          );
                        }
                    },
                  },
                ]}
              >
                <DatePicker
                  placeholder={"End Date"}
                  disabledDate={disabledDate}
                  style={{ width: 205, marginRight: 30 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-promo-name">
            <Col span={8}>
              <Form.Item
                label="Promo Name"
                name="promo_name"
                rules={[
                  {
                    required: true,
                    message: "Please, input promo name!",
                  },
                ]}
                style={{ width: 205, marginRight: 30 }}
              >
                <Input placeholder="Promo Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please, input unit price!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Price"
                  addonBefore={currency}
                  formatter={(unit_price) => {
                    return `${unit_price}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{ width: 205 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-room-period">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-room-period">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 15px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              Submit
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

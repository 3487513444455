// Import React Components
// Import base URL
import baseAPI, { baseurl } from "../API/Config/Api";
import axios from "axios";

// Functions
import { AccessControlJSON } from "../Components/Reusable/Functions/AccessControl/AccessJson";
import { hotel_id } from "../API/Global/Payload";

// REGISTER CONTEXT
// eslint-disable-next-line
const register = async (allBody) => {
  console.log("About to Registers: ", allBody);

  // return await allBody.access_token;
  return await baseAPI
    .post("/auth/sign-up", {
      email: allBody?.email,
      phone: allBody?.phone_number,
      password: allBody?.password,
      options: {
        data: {
          roles: allBody?.roles,
          user_name: allBody?.user_name,
          department: allBody?.department?.name,
          module: allBody?.module?.length > 0 ? allBody.module : [6],
          approval_level: allBody?.user_level > 0 ? allBody.user_level + 1 : 10,
          hotel_id: hotel_id,
          dept_id: allBody?.department?.dept_id,
          dept_uuid: allBody?.department?.dept_uuid,
          user_level: allBody?.user_level > 0 ? allBody.user_level : 0,
          new_access_control: AccessControlJSON,
        },
      },
    })
    .then((response) => {
      console.log("Success Registers --> ", response);

      return response.data;
    })
    .catch((err) => {
      console.log("Failed Registers --> ", err);

      return { error: err?.response?.data || err?.message };
    });
};

// LOGIN CONTEXT
// eslint-disable-next-line
const login = async (email, password, username) => {
  console.log("Email --> ", email);
  console.log("Pwd --> ", password);
  console.log("Username --> ", username);

  return await baseAPI
    .post("/auth/sign-in", {
      email,
      password,
    })
    .then((response) => {
      console.log("Success Login --> ", response);

      // successLogin(response.data);

      if (response?.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
        const _token = response?.data?.access_token || "";
        const _roles = response?.data?.user?.user_metadata?.roles || "UNKNOWN";

        sessionStorage.setItem("auth-token", _token);
        sessionStorage.setItem("roles", _roles);
      }
      return response.data;
    })
    .catch((err) => {
      console.log("Failed Login --> ", err);

      return { error: err?.response?.data || err?.message };
    });
};

// LOGOUT CONTEXT
const logout = async (email) => {
  //   localStorage.removeItem();

  console.log("You are Here and About to Log Out!", email);
  // sessionStorage.clear();
  // navigate("/login");

  // setTimeout(() => {
  //   window.location.reload(false);
  // }, 500);
  return await axios
    .post(`${baseurl}/auth/sign-out`, {
      email,
    })
    .then((response) => {
      console.log("Success Log Out --> ", response);

      // successLogin(response.data, {});

      if (response?.status == 200) {
        sessionStorage.clear();
        localStorage.clear();
        sessionStorage.removeItem("par-1");
        sessionStorage.removeItem("par-2");
        sessionStorage.removeItem("par-3");
        // localStorage.setItem("user", JSON.stringify(response.data));
        // sessionStorage.setItem("auth-token", response.data.access_token);
        // sessionStorage.setItem("roles", response.data.user.user_metadata.roles);
      }

      return response?.data;
    })
    .catch((err) => {
      console.log("Failed Log Out --> ", err);

      return { error: err?.response?.data || err?.message };
    });
};

// Night Audit
const audit = async () => {
  return await axios
    .get(`${baseurl}/night-audit/audit-date`)
    .then((response) => {
      console.log("Response Audit: ", response);

      return response?.data?.msg[0]?.audit_date;
    })
    .catch((error) => {
      console.log("Error: ", error);

      return { error: error?.response.data || error?.message };
    });
};

// Reset Register
const resetReg = async (isRegister) => {
  return await isRegister;
};

// Update User by Admin
const updateByAdmin = async (allBody) => {
  // console.log("About to Update by Admins: ", allBody);

  return await baseAPI
    .put("/auth/admin/access-control", {
      id: allBody?.uid,
      new_roles: allBody?.roles,
      new_department: allBody?.department,
      new_module: allBody?.module?.length > 0 ? allBody.module : [6],
      new_approval_level: allBody?.approval_level,
      new_dept_id: allBody?.dept_id,
      new_dept_uuid: allBody?.dept_uuid,
      new_access_control:
        allBody?.access_control?.length > 0
          ? allBody.access_control
          : AccessControlJSON,
      new_sub_module: allBody?.sub_module,
      new_user_level: allBody?.user_level,
      new_grand_module: allBody?.grand_module,
      new_id_warehouse:
        allBody?.id_warehouse?.length > 0 ? allBody?.id_warehouse : [],
      new_is_warehouse_admin: allBody?.is_warehouse_admin ? true : false,
    })
    .then((response) => {
      console.log("Success Update by Admin --> ", response);

      return response;
    })
    .catch((err) => {
      console.log("Failed Update by Admin --> ", err);

      return { error: err?.response?.data || err?.message };
    });
};

// Blocking Users
const blockUsers = async (id, ban_duration, blocked_by) => {
  return await baseAPI
    .put(`/auth/admin/block`, {
      id: id,
      ban_duration: ban_duration > 0 ? `${ban_duration}h` : `${ban_duration}`,
      blocked_by: blocked_by,
    })
    .then((response) => {
      console.log("Ban Success: ", response);

      return response.data;
    })
    .catch((error) => {
      console.log("Ban Failed: ", error);

      return { error: error?.msg || error };
    });
};

// Update Users
const update = async (body) => {
  return await baseAPI
    .put(`/auth/update-user`, {
      email: body?.email,
      password: body?.password,
      new_password: body?.password,
      data: {
        new_user_name: body?.new_user_name,
      },
    })
    .then((response) => {
      console.log("Update Success: ", response);

      // if (response.data) {
      //   sessionStorage.setItem("auth-token", response?.data?.access_token);
      // }

      return response.data;
    })
    .catch((error) => {
      console.log("Update Failed: ", error);

      return { error: error?.msg || error };
    });
};

// Delete Users
const deleteUser = async (body) => {
  console.log("About to Delete: ", body);

  // let id = body;

  return await baseAPI
    .delete(`/auth/admin/delete`, {
      data: { id: body },
    })
    .then((response) => {
      console.log("Delete Success: ", response);

      return response;
    })
    .catch((error) => {
      console.log("Update Failed: ", error);

      return { error: error?.response?.data?.msg || error };
    });
};

// EXPORT FUNCTION
// eslint-disable-next-line
export default {
  register,
  login,
  logout,
  audit,
  resetReg,
  updateByAdmin,
  blockUsers,
  update,
  deleteUser,
};

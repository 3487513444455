// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as FinanceContext } from "../../../API/Context/FinanceContext/FinanceContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { BiSolidFilePdf } from "react-icons/bi";

// Import Functions
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import GroupingArticle from "../../Reusable/Functions/GroupingArticle/GroupingArticle";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { invoText, textForm } from "../../../Style/Global/Global";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function Invoice(props) {
  // PROPS
  const {
    is_open,
    is_close,
    data_guest,
    payment,
    data_table,
    totals,
    invoice,
  } = props;

  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const {
    getBillRoom,
    getBillStay,
    getBillDetail,
    getBillHeader,
    getMasterDetail,
    getMasterBillTrx,
    getGuest,
  } = useContext(MainContext);
  const { getMasterPrint } = useContext(MasterContext);

  // Use Ref
  const componentRef = useRef();
  const bill_type = FrontOfficeParams().bill_type;
  const accounting = AccountingParams();

  // const { getBillHeader, getBillDetail } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [guest, setGuest] = useState(null);
  // Bill
  const [headers, setHeaders] = useState(null);

  const [articleRoom, setArticleRoom] = useState([]);
  const [articleStay, setArticleStay] = useState([]);
  const [dataBill, setDataBill] = useState([]);
  // Tax & Service
  const [taxService, setTaxService] = useState({
    tax: 0,
    service: 0,
    subTotal: 0,
    total: 0,
  });
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);
  // USE FORM
  const [form] = Form.useForm();

  // FECTH DATA
  const fetchData = async (value) => {
    const _data = value;

    await getBillHeader({
      bill: "header-reservation",
      res_id: _data?.reservation_id
        ? _data.reservation_id
        : data?.reservation_id,
      price_id: _data?.price_id ? _data.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Bill Header Data => ", response);

        if (response.data?.msg?.length > 0) {
          const _data = response.data?.msg[0];

          setHeaders(_data);
        } else {
          setHeaders(null);
        }
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
      },
    });

    await getBillDetail({
      bill: "detail-reservation",
      res_id: _data?.reservation_id
        ? _data.reservation_id
        : data?.reservation_id,
      prc_id: _data?.price_id >= 0 ? _data.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Bill Detail Data is Leader/Individual => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          // setDataBill(
          //   handleFilter(_response);
        } else {
          setDataBill([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
      },
    });

    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Res Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let guest_name = _res.filter((value) => {
            if (value.id == _data?.guest_id) {
              return value;
            } else if (value.full_name.includes(_data.guest_name)) {
              return value;
            }
          });

          console.log("Guest --> ", guest_name);
          setGuest(guest_name?.length > 0 ? guest_name[0] : []);
        }
      },
      onReject: (error) => {
        console.log("Error => ", error);
      },
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      console.log("Data CO: ", data_guest);

      setOpen(true);
      setData(data_guest);
      // fetchData(data_guest);
      // fetchPrint();
      //   setFieldsForm(data_guest);
    }

    if (data_table?.length > 0 && !cleanUp) {
      console.log("Data Table: ", data_table);

      handleBill(data_table);
      // setDataBill(data_table);
    } else {
      setDataBill([]);
    }

    if (totals?.total > 0 && !cleanUp) {
      setTaxService({
        service: totals.service,
        tax: totals.tax,
        total: totals.grand,
        subTotal: totals.total,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, data_guest, data_table, totals]);

  // USE EFFECT TABLE DATA
  useEffect(() => {
    let cleanUp = false;

    if ((articleRoom.length > 0 || articleStay.length > 0) && !cleanUp) {
      console.log("About to Join: ", articleRoom, articleStay);

      setDataBill([...articleRoom, ...articleStay]);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleRoom, articleStay]);

  // FETCH PRINT FORM
  const fetchPrint = async () => {
    await getMasterPrint({
      reservation_id: data_guest?.reservation_id || data?.reservation_id,
      price_id: data_guest?.price_id > 0 ? data_guest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: data_guest[0] || data,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "item_quantity",
      width: 50,
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (total, record) => {
        return `${total > 0 ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // HANDLE FILTER
  const handleFilter = (value) => {
    const _res = value;
    var _merge = [];

    // Array Buckets
    let _all = [];

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // GROUP BY ARTICLE ID
    const _filtered = Object.groupBy(_res, (items) => items.article_id);

    console.log("Res Group By: ", _filtered);

    // FIND MAIN ARTICLES
    for (const key in _filtered) {
      const element = _filtered[key];
      // console.log("ELEMENTS: ", element);

      let _newObj = {};

      const res = element.forEach(function (_objects, _index) {
        for (let _keys in _objects) {
          const _article_names = _objects?.article_name.toLowerCase();
          // console.log("KEYS = ", _keys);
          // console.log("OBJECTS = ", _objects);
          // console.log("INDEX = ", _index);

          if (_keys.includes("article_name")) {
            // console.log(`This is Same: ${_newObj[_objects[_keys]]}`);
            // console.log(`With: ${[_objects[_keys]]}`);
            _newObj[_objects[_keys]] = _objects?.article_name;
            _newObj[_keys + "_" + (_index + 1)] = _objects[_keys];
          }

          if (_objects[_keys] != null) {
            if (_article_names.includes(_tax_names)) {
              _newObj["tax"] = _objects["price"];
            } else if (_article_names.includes(_service_names)) {
              _newObj["service"] = _objects["price"];
            } else {
              _newObj[_keys] = _objects[_keys];
              _newObj["unit_price"] = _objects["price"] / _objects["quantity"];
              _newObj["total_price"] = _objects["price"];
              _newObj["id"] = _objects["article_id"];
              _newObj["is_deleted"] = _objects["is_deleted"];
              _newObj["chart_of_account"] = _objects["chart_of_account"];
              _newObj["quantity"] = _objects["quantity"];
            }
          }
        }
      });

      // console.log("Res Reduces: ", res);
      // console.log("New Object: ", _newObj);

      _merge.push(_newObj);
    }

    if (_merge.length > 0) {
      let _new = _merge.map((items) => {
        return {
          ...items,
          total_price: items.total_price + items.tax + items.service,
        };
      });

      console.log("Total Price: ", _new);
      _all = _new;
    }

    // Grouping Data
    const _grouping = GroupingArticle(_all);
    // Showing Bill Type
    const _newArticle = BillArticleShow({
      article: _grouping,
      bill_type: bill_type,
    });

    console.log("Merge: ", _merge);
    console.log("Merge All: ", _all);
    console.log("Group By: ", _grouping);
    console.log("Filtered: ", _newArticle);

    setDataBill(_newArticle.article);
    setTaxService({
      service: _newArticle.total.service,
      tax: _newArticle.total.tax,
      total: _newArticle.total.total_price,
      subTotal: _newArticle.total.unit_price,
    });
  };

  // Handle Bill
  const handleBill = (val) => {
    const _arr = val;

    const _newArticle = BillArticleShow({
      article: _arr,
      bill_type: bill_type,
    });

    console.log("Filtered: ", _newArticle);

    setTaxService({
      service: _newArticle.total.service,
      tax: _newArticle.total.tax,
      total: _newArticle.total.total_price,
      subTotal: _newArticle.total.unit_price,
    });

    setDataBill(_newArticle.article);

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  // ON FINISH
  const onFinish = () => {
    handlePrint();
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    form.resetFields();

    setHeaders(null);

    setArticleRoom([]);
    setArticleStay([]);
    setData([]);
    setDataBill([]);

    setIsLoading(true);
    setLoading(false);

    setOpen(false);
    is_close(false);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    // PrintCount({
    //   data: data,
    //   is_update: true,
    //   counter_list: {
    //     bill: countPrint?.bill + 1,
    //     pre_master_bill: countPrint?.pre_master_bill,
    //     pre_bill: countPrint?.pre_bill,
    //     checkout_letter_history: countPrint?.checkout_letter_history,
    //     registration_form: countPrint?.registration_form,
    //     guarantee_letter: countPrint?.guarantee_letter,
    //     thankyou_letter: countPrint?.thankyou_letter,
    //     master_bill: countPrint?.master_bill,
    //     reservation_letter: countPrint?.reservation_letter,
    //   },
    // });

    handlePrintOut();
  };

  // PRINT OUT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice ${
      data?.bill_alias ? data.bill_alias : " - - - - - "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  return (
    <>
      <Modal
        className="res-bill-modal"
        title={
          <>
            <Row>
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Receipt Invoice`}
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          labelWrap={{
            span: 12,
          }}
          wrapperCol={{
            span: 16,
          }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          onFinish={onFinish}
          autoComplete="off"
          name="bill-form-modal"
          key="bill-form-modal"
          className="bill-form-modal"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{
                padding: "15px 30px 15px",
              }}
            >
              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 160,
                }}
              >
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Look Hotel`}
                  </Typography.Title>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Bandung, Jawa Barat`}
                  </Typography>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Tel: (+62) 88 - ---`}
                  </Typography>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Fax: (00---)`}
                  </Typography>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Email: look-support@hilook.support`}
                  </Typography>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="center"
                orientationMargin={0}
                style={{
                  margin: 0,
                  borderColor: "#d9d9d9",
                }}
              >
                <Typography.Title
                  level={2}
                  className="text-form-title"
                  underline
                >
                  {`INVOICE`}
                </Typography.Title>
              </Divider>

              <Row
                className="row-receipt-no"
                style={{
                  height: 65,
                  width: "100%",
                  margin: 0,
                }}
              >
                <Col span={12} className="col-receipt">
                  <Form.Item
                    label="Bill No."
                    className="receipt_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-bill-id" style={textForm}>
                      {headers?.bill_alias
                        ? headers.bill_alias
                        : data?.bill_master || ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Invoice No."
                    className="invoice_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-bill-id" style={textForm}>
                      {invoice ? invoice : " - - - - "}
                    </Typography>
                  </Form.Item>
                  {/* </Row>

              <Row
                className="row-date"
                style={{ height: 30, width: "100%", margin: 0 }}
              > */}
                  <Form.Item
                    label="Payment Date"
                    className="date"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-trx-date" style={textForm}>
                      {headers?.trx_bill_date
                        ? moment(
                            LocalizationDate(headers.trx_bill_date)
                          ).format("HH:mm, DD-MM-YYYY")
                        : moment().format("HH:mm, DD-MM-YYYY")}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col
                  span={12}
                  className="col-paid"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {headers?.is_paid ? (
                    <Image
                      className="paid"
                      src="/assets/logos/paid.jpg"
                      preview={false}
                      style={{
                        margin: 0,
                        width: 150,
                        height: 75,
                      }}
                    />
                  ) : (
                    <Image
                      className="paid"
                      src="/assets/logos/unpaid.jpg"
                      preview={false}
                      style={{
                        margin: 0,
                        width: 150,
                        height: 75,
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row className="row-payment-title" justify="start">
                <Col span={12}>
                  <Typography
                    className="text-payment"
                    style={{
                      margin: "30px 0px 0px",
                      fontSize: 14,
                      fontWeight: 750,
                      // color: "grey",
                    }}
                  >
                    {`Payment Data`}
                  </Typography>
                </Col>

                <Col span={12}>
                  <Typography
                    className="text-guest"
                    style={{
                      margin: "30px 0px 0px",
                      fontSize: 14,
                      fontWeight: 750,
                      // color: "grey",
                    }}
                  >
                    {`Guest Name`}
                  </Typography>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                  backgroundImage:
                    countPrint?.bill >= 1
                      ? "url(/assets/logos/copyopac.png)"
                      : null,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  backgroundPosition: "center",
                  // transform: "rotate(45deg)",
                }}
              >
                <Col span={12} className="col-pay-type">
                  <Form.Item
                    label="Payment"
                    className="payment"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {`${
                        payment?.payment_type
                          ? payment.payment_type.toUpperCase()
                          : ""
                      } - by ${
                        payment?.payment_details ? payment.payment_details : ""
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Res. No"
                    className="reservation_id"
                    style={{ margin: 0 }}
                    // labelCol={{ span: 5 }}
                    // wrapperCol={{ span: 19 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {`${data?.reservation_id ? data.reservation_id : ""}`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Guest Name"
                    className="guest_name"
                    style={{ margin: 0 }}
                    // labelCol={{ span:  }}
                    // wrapperCol={{ span: 20 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {`${
                        guest?.full_name
                          ? guest.full_name
                          : data?.full_name || ""
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Bill Receiver"
                    className="bill_receiver"
                    style={{ margin: 0 }}
                    // labelCol={{ span:  }}
                    // wrapperCol={{ span: 20 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {`${
                        guest?.full_name
                          ? guest.full_name
                          : data?.full_name || ""
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Typography
                  style={{
                    margin: 0,
                    fontSize: 14,
                    fontWeight: 750,
                  }}
                >
                  {`Buying List`}
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="transaction-table"
                  key="transaction-table"
                  name="transaction-table"
                  loading={loading}
                  columns={columns}
                  dataSource={dataBill}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) =>
                    record?.id > 0 ? record.id : record.article_id
                  }
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary fixed>
                          {bill_type ? (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={6}>
                                  <b>Subtotal</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${taxService?.subTotal}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={6}>
                                  <b>Service</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${taxService?.service}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={6}>
                                  <b>Tax</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${taxService?.tax}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          ) : null}

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={5} />

                            <Table.Summary.Cell index={5}>
                              <b style={{ fontSize: 15 }}>Grand Total</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={6} align="right">
                              <b style={{ fontSize: 15 }}>
                                {`${currency} ${
                                  taxService?.total > 0 ? taxService.total : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </b>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      </>
                    );
                  }}
                />
              </Row>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: please, keep this invoice as your payment's warranty!
              </Typography>

              {bill_type ? null : (
                <Typography className="text-notes" style={{ fontSize: 10 }}>
                  *notes: all prices shown are already include tax & service
                </Typography>
              )}

              <Row
                className="signature"
                justify="end"
                style={{ padding: "30px 0px 0px" }}
              >
                <Col span={12} />

                <Col
                  span={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {`Guest Signature`}

                  <div
                    className="box-sign"
                    style={{
                      border: "1px solid #000000",
                      borderRadius: 5,
                      height: 60,
                    }}
                  />

                  {`Sign at ${
                    data?.checkout_date
                      ? moment(LocalizationDate(data?.checkout_date)).format(
                          "HH:mm, DD-MM-YYYY"
                        )
                      : moment().format("DD-MM-YYYY")
                  }`}
                </Col>
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: 0, margin: "0px 30px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              disabled={dataBill.length > 0 ? false : true}
              htmlType="submit"
              icon={<PrinterOutlined />}
              style={{
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              disabled={dataBill.length > 0 ? false : true}
              icon={<SyncOutlined />}
              onClick={fetchData}
              style={{
                marginRight: 30,
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Input,
  DatePicker,
  Space,
  Button,
  Typography,
} from "antd";

// Import React Icons Components
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiCheckCircle, FiEdit, FiXCircle } from "react-icons/fi";
import { SearchOutlined } from "@ant-design/icons";

// CODE
export default function SplitBillModalTable(props) {
  // PROPS
  const { dataDetail, getSelectedDetail, params } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();

  const [select, setSelect] = useState(null);
  const [editingKey, setEditingKey] = useState("");

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedArticleId, setSelectedArticleId] = useState(0);

  // Boolean
  // Loadings, Modals, Loading
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalArticlePriceOpen, setModalArticlePriceOpen] = useState(false);

  // Use Reff
  const searchInput = useRef(null);

  // USE FORM
  const [form] = Form.useForm();

  // Tax Params
  const _tax = params?.tax_percentage / 100;

  // Fetch Data
  const fetchData = async () => {
    // await
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("Table Draft: ", params);

    if (dataDetail?.length > 0 && !cleanUp) {
      setData(dataDetail);
      setSelect(null);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail, params]);

  const isEditing = (record, index) => index === editingKey;

  // SET FIELDS EDIT
  const editData = (record, index) => {
    setEditingKey(index);

    form.setFieldsValue({
      quantity: "",
      id_supllier: "",
      id_supllier_article: "",
      ...record,
    });
  };

  //
  const save = async (e, x) => {
    let index = e;
    let artName = x;
    try {
      const row = await form.validateFields();
      const newData = [...data];
      let find = data.findIndex((v) => v.article_name == artName);
      // let compare = newData[index].unit_price !== row.unit_price;

      // const index = newData.findIndex((item) => key === item.key);
      if (find > -1) {
        // console.log("Test >>> Cond 1", find);
        const item = newData[find];
        newData.splice(find, 1, {
          ...item,
          ...row,
          // ...{ price_update: compare ? true : false },
        });
        console.log(newData);
        setData(newData);
        // dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        form.resetFields();
      } else {
        // console.log("Test >>> Cond 2");
        newData.push(row);
        setData(newData);
        // dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        form.resetFields();
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // Cancel Edit
  const cancel = () => {
    setEditingKey("");
    setEdit(false);
  };

  // Handle Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // Handle Reset
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // Handle Column Search
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex == "request_date" ? (
          <DatePicker
            ref={searchInput}
            format={"YYYY-MM-DD"}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
            }}
            onChange={(e) => {
              setSelectedKeys(e ? [moment(e).format("YYYY-MM-DD")] : []);
              console.log(e);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      //   fixed: "left",
      width: 70,
      render: (_, record, index) => {
        const editable = isEditing(record, index);

        return editable ? (
          <>
            <Row className="action-btn" gutter={20} style={{ width: "100%" }}>
              <Col span={12}>
                <FaCheckCircle
                  className="edit-btn"
                  onClick={() => {
                    save(index, record.article_name);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Col>

              <Col span={12}>
                <FaTimesCircle
                  className="delete-btn"
                  onClick={cancel}
                  style={{ fontSize: 20, color: "#F64E60", cursor: "pointer" }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Quantity" className="delete-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      // console.log("EDIT ON CLICK >>>");
                      if (edit != true) {
                        // console.log("IS EDITING QTY: ", editingKey);
                        console.log("Edit: ", record);
                        // console.log("Edit: ", edit);
                        editData(record, index);
                        setEdit(true);
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      render: (amount) => amount,
      ...getColumnSearchProps("article_name"),
    },
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
      width: 150,
      render: (amount) => amount,
    },
    {
      title: "Order Qty",
      dataIndex: "item_quantity" || "quantity",
      key: "item_quantity" || "quantity",
      editable: true,
      width: 100,
      render: (amount) => {
        return amount > 0
          ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: "Unit Price Gross (Rp)",
      dataIndex: "gross",
      key: "gross",
      width: 100,
      editable: true,
      render: (amount, record) => {
        return amount > 0
          ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: "Amount (Rp)",
      dataIndex: "total_price",
      key: "total_price",
      editable: true,
      render: (amount, record) => {
        return amount > 0
          ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: "Item Desc",
      dataIndex: "description",
      key: "description",
      render: (amount) => amount,
    },
    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // EDITABLE RENDER COMPONENTS
  const EditableCell = (props) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = props;
    const inputNode = (
      <InputNumber
        placeholder="input here..."
        disabled={dataIndex != ("item_quantity" || "quantity") ? true : false}
        min={0}
        max={
          dataIndex == ("item_quantity" || "quantity")
            ? record.item_quantity || record.quantity
            : null
        }
        onChange={(e) => {
          if (dataIndex == ("item_quantity" || "quantity")) {
            let x = form.getFieldsValue();
            form.setFieldsValue({
              total_price: e * x["gross"],
            });
          }
        }}
        className="edit-number"
      />
    );
    return (
      <td {...restProps}>
        {editing ? (
          <>
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          </>
        ) : (
          children
        )}
      </td>
    );
  };

  // Merged Columns when edit
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record, index),
      }),
    };
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      getSelectedDetail(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.item_quantity == (0 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  return (
    <>
      <Form
        className="table-form"
        form={form}
        component={false}
        initialValues={{
          ...({ item_quantity: 0 } || { quantity: 0 }),
          gross: 0,
          total_price: 0,
        }}
      >
        <Table
          className="split-bill-modal-table"
          name="split-bill-modal-table"
          key="split-bill-modal-table"
          columns={mergedColumns}
          dataSource={data}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
            onChange(current, pageSize) {
              setPage(current);
              setPaginationSize(pageSize);
            },
          }}
          scroll={{
            x: 1200,
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => record.article_id}
        />
      </Form>
    </>
  );
}

// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Result } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// CODE
export default function ResultError(props) {
  // PROPS
  const { source_res, is_loading, is_refresh } = props;

  // STATE MANAGEMENT
  // Data
  const [loading, setLoading] = useState(false);

  // Handle Refresh
  const handleRefresh = () => {
    if (loading === false) {
      setLoading(true);
      is_refresh(true);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Result
        className="error-result"
        status="error"
        title="Failed to Load!"
        subTitle="Data couldn't be loaded! Please, Refresh or Try again later!"
        extra={[
          <Button
            type="default"
            key="console"
            loading={loading}
            icon={<SyncOutlined />}
            onClick={handleRefresh}
          >
            {`Refresh`}
          </Button>,
        ]}
      ></Result>
    </>
  );
}

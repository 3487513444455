// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Divider, Radio, Table, Space, Button, Col, Row } from "antd";

// Import React Icons
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Components
import ModalReportOutlet from "../../../Modals/Outlet/Report/Modal";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

const dataSource = [
  {
    key: "1",
    date: "12/12/2023",
    id: "RES/1234/1234/1234",
    report_name: "Sold Total Item by Period Report",
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// const onChange = (pagination, filters, sorter, extra) => {
//     console.log('params', pagination, filters, sorter, extra);
// };

// CODE
export default function ReportOutletTable() {
  // Table's Column
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "Report Name",
      dataIndex: "report_name",
      key: "report_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.report_name.localeCompare(b.report_name),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Button
                  type="default"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);
                    setOpen(true);
                    //setDataEdit(record);
                  }}
                >
                  View
                </Button>
              </Col>

              <Col span={12}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);
                    //setOpen(!open);
                    //setDataEdit(record);
                  }}
                  style={{ backgroundColor: "#1BC5BD", borderColor: "#1BC5BD" }}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];
  // STATE MANAGEMENT
  const [selectionType, setSelectionType] = useState("checkbox");
  // const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Row className="lostfound-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: "30px" }}>
          <Row justify="end">
            <Button
              className="submit-btn"
              type="primary"
              //   onClick={move}
              icon={<PlusOutlined />}
            >
              {`Add Data`}
            </Button>

            <Button
              //onClick={}
              type="primary"
              style={{
                // marginBottom: 16,
                // backgroundColor: "grey",
                // borderColor: "gray",
                margin: "0px 30px 15px",
              }}
              icon={<FormOutlined />}
            >
              {`Edit`}
            </Button>

            <Button
              className="delete-btn"
              danger
              type="primary"
              icon={<DeleteFilled />}
              //   onClick={fetchRecords}
            >
              {`Delete`}
            </Button>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              loading={loading}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={dataSource}
              //onChange={onChange}
              pagination={{
                pageSize: 5,
              }}
              // scroll={{
              //     y: 270,
              // }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <ModalReportOutlet openModal={open} closeModal={setOpen} />
    </>
  );
}

// Import React Components
import React from "react";

let token = sessionStorage.getItem("auth-token") || "";

// return (base64Payload.toString());
// const base64Url = userRoles.split(".")[1];

let roles = "";
let user_name = "";
let email = "";
let department = "";
let department_id = 0;
let user_level = 0;
let approval_level = 0;
let module = [];
let sub_module = [];
let sub_children = [];
let id_warehouse = [];
let hotel_id = "";

if (token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const payload = JSON.parse(jsonPayload);

  roles = payload?.user_metadata?.roles;
  user_name = payload?.user_metadata?.user_name;
  email = payload?.email;
  department = payload?.user_metadata?.department;
  department_id = payload?.user_metadata?.dept_id || 0;
  user_level = payload?.user_metadata?.user_level;
  approval_level = payload?.user_metadata?.approval_level;
  module = payload?.user_metadata?.module || [];
  sub_module = payload?.user_metadata?.sub_module || [];
  sub_children = payload?.user_metadata?.grand_module || [];
  id_warehouse = payload?.user_metadata?.id_warehouse || [];
  hotel_id =
    payload?.user_metadata?.hotel_id || "f804084c-c236-457a-89d4-8b8d7ac8bc1d";

  // console.log("User Name Set >>>", user_name);
  // console.log("Roles >>>", roles);
  // console.log("Department >>>", department_id);
  // console.log("Parse JSON Payload = ", payload);
  // console.log("Un-parse JSON --> ", jsonPayload);
  // console.log("Email: ", email);
}

export {
  user_name,
  roles,
  department,
  department_id,
  token,
  email,
  user_level,
  approval_level,
  module,
  sub_module,
  sub_children,
  id_warehouse,
  hotel_id,
};

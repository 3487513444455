export function Decoder(token) {
  const params_token = token ? token : null;

  // console.log("Token: ", params_token);
  let jsonPayload = "";

  if (params_token) {
    let base64Url = params_token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  const payload = jsonPayload ? JSON.parse(jsonPayload) : "";

  // console.log("Decode results: ", payload);

  return payload;
}

// Import React Components
import React, { useState } from "react";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Button,
  Divider,
  Input,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import { FaConciergeBell } from "react-icons/fa";
import { BsCalculatorFill, BsDoorOpenFill } from "react-icons/bs";
import { MdLayers } from "react-icons/md";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";
import InputSkeleton from "../../../Components/Reusable/Skeleton/InputSkeleton";

// CODE
export default function OutletParams() {
  // STATE MANAGEMENT
  const [data, setData] = useState();
  // Loading
  const [loading, setLoading] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("ON FINISH >>> ", content);
    // showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaConciergeBell
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                OUTLET PARAMETERS
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-first"
                style={{ padding: 30 }}
              >
                {loading == true ? (
                  <>
                    <InputSkeleton is_loading={loading} />
                  </>
                ) : (
                  <Form
                    className="change-room-form"
                    name="change-room-form"
                    key="change-room-form"
                    autoComplete="false"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{ padding: "36px 36px 0px 36px" }}
                  >
                    <Row gutter={30} className="row-header">
                      <Form.Item
                        label="Deposit COA"
                        name="deposit_coa"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Chart of Accounts" />
                        {/* <MasterCOA
              coa_name={data?.deposit_coa ? data.deposit_coa : ""}
              getCoaName={getDepositCoa}
              is_all={true}
            /> */}
                      </Form.Item>

                      <Form.Item
                        label="Revenue COA"
                        name="revenue_coa"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Chart of Accounts" />
                        {/* <MasterCOA
              coa_name={data?.revenue_coa ? data.revenue_coa : ""}
              getCoaName={getRevenueCoa}
              is_all={true}
            /> */}
                      </Form.Item>

                      <Form.Item
                        label="Check-In SIGN BY"
                        name="check_in_sign_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="By" />
                      </Form.Item>

                      <Form.Item
                        label="Check-Out SIGN BY"
                        name="check_out_sign_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="By" />
                      </Form.Item>

                      <Form.Item
                        label="Registration SIGN BY"
                        name="registration_sign_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="By" />
                      </Form.Item>

                      <Form.Item
                        label="Last Update by"
                        name="updated_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated By" disabled />
                      </Form.Item>
                    </Row>

                    <Divider
                      className="divider-form"
                      style={{ margin: "15px 0px 10px" }}
                    />

                    <Row
                      justify="end"
                      align="middle"
                      // style={{ padding: "15px 0px 0px" }}
                      className="modal-btn-form"
                    >
                      <Button
                        className="submit-btn"
                        type="primary"
                        htmlType="submit"
                        style={{
                          backgroundColor: "#1BC5BD",
                          borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        Submit
                      </Button>

                      <Button
                        className="cancel-btn"
                        type="default"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Row>
                  </Form>
                )}
              </Col>

              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-second"
              ></Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

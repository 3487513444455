// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import StockArticleMovingForm from "../../../../../../Forms/StockArticleMoving/Form";

export default function StockArticleMovingReportTable(props) {
  // PROPS
  const { _month, _articleNumber, _fromStorage, _toStorage } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    begin_qty: 0,
    begin_price: 0,
    incoming_qty: 0,
    outgoing_qty: 0,
    ending_qty: 0,
    ending_price: 0
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (_month && _articleNumber && _fromStorage && _toStorage && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setBulks([]);
      setTotal({
        begin_qty: 0,
        begin_price: 0,
        incoming_qty: 0,
        outgoing_qty: 0,
        ending_qty: 0,
        ending_price: 0
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_month, _articleNumber, _fromStorage, _toStorage]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    let bodyReq = {
      month: moment(_month).format("YYYY-MM-DD"),
      article_number: _articleNumber.article_no,
      from_warehouse: _fromStorage.id_warehouse,
      to_warehouse: _toStorage.id_warehouse
    };

    await getByPostInventoryReport({
      type: `stock-moving`,
      bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
              begin_qty: item.begin_qty,
              begin_price: item.begin_price,
              incoming_qty: item.incoming_qty,
              outgoing_qty: item.outgoing_qty,
              ending_qty: item.ending_qty,
              ending_price: item.ending_price
            };
          });

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      begin_qty: prev.begin_qty + curr.begin_qty,
                      begin_price: prev.begin_price + curr.begin_price,
                      incoming_qty: prev.incoming_qty + curr.incoming_qty,
                      outgoing_qty: prev.outgoing_qty + curr.outgoing_qty,
                      ending_qty: prev.ending_qty + curr.ending_qty,
                      ending_price: prev.ending_price + curr.ending_price
                    };
                  },
                  { begin_qty: 0, begin_price: 0, incoming_qty: 0, outgoing_qty: 0, ending_qty: 0, ending_price: 0 }
                )
              : { begin_qty: 0, begin_price: 0, incoming_qty: 0, outgoing_qty: 0, ending_qty: 0, ending_price: 0 };

          setData(filter);
          setBulks(filter);
          setTotal(sumTotal);
        } else {
          setData([]);
          setBulks([]);
          setTotal({
            begin_qty: 0,
            begin_price: 0,
            incoming_qty: 0,
            outgoing_qty: 0,
            ending_qty: 0,
            ending_price: 0
          });
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Stock Article Moving Reports${
        _month ? " " + moment(_month).format("YYYY-MM") : ""
      }`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Average Price",
      dataIndex: "avg_price",
      key: "avg_price",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }
    },
    {
      title: "Begin Qty",
      dataIndex: "begin_qty",
      key: "begin_qty",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Begin Value",
      dataIndex: "begin_price",
      key: "begin_price",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Incoming Qty",
      dataIndex: "incoming_qty",
      key: "incoming_qty",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Outgoing Qty",
      dataIndex: "outgoing_qty",
      key: "outgoing_qty",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Ending Qty",
      dataIndex: "ending_qty",
      key: "ending_qty",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Ending Value",
      dataIndex: "ending_price",
      key: "ending_price",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ];

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total `} */}
                            {`${total.begin_qty ? total.begin_qty : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total 1`} */}
                            {`${total.begin_price ? total.begin_price.toFixed(0) : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total 2`} */}
                            {`${total.incoming_qty ? total.incoming_qty : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total 3`} */}
                            {`${total.outgoing_qty ? total.outgoing_qty : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total 4`} */}
                            {`${total.ending_qty ? total.ending_qty : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total 5`} */}
                            {`${total.ending_price ? total.ending_price.toFixed(0) : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <StockArticleMovingForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _articleNumber = {_articleNumber ? _articleNumber : null}
        _month = {_month ? _month : null}
        _fromStorage = {_fromStorage ? _fromStorage : null}
        _toStorage = {_toStorage ? _toStorage : null}
        _total = {total}
      />
    </>
  );
}

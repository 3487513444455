// Import Page Components
import React, { useContext } from "react";

// CONTEXT
import { Context as StateManagementContext } from "../../API/Context/StateManagementContext/StateManagementContext";

// Import Antd Components
import { Col, Row } from "antd";

// Import Page Components
import WidgetToDo from "../../Components/Widgets/Task/Approval";
import ParamSetter from "../../Components/Reusable/Functions/ParamSetter/ParamSetter";
import { user_level } from "../../API/Global/Payload";
import WidgetToDoTransferRequest from "../../Components/Widgets/Task/ApprovalTransferRequest";
import WidgetMinimumOnHand from "../../Components/Widgets/Task/MinimumOnHand";
import WidgetToDoStockIncomingWoPO from "../../Components/Widgets/Task/ApprovalStockIncomingWoPO";
import WidgetToDoChangePriceStockIncoming from "../../Components/Widgets/Task/ApprovalStockIncomingChangePrice";

// CODE
export default function TaskListPage(props) {
  // CONTEXT
  // const { addToStore, state } = useContext(StateManagementContext);

  // STATE MANAGEMENT
  const params = sessionStorage.getItem("par-1");
  const auth = sessionStorage.getItem("auth-token");

  if (!params && auth) {
    const params = ParamSetter();

    // console.log(params);
  }

  return (
    <>
      <Row
        justify="start"
        gutter={[30, 30]}
        className="list-row"
        style={{
          marginBottom: 30,
        }}
        // hidden={user_level > 2 ? false : true}
      >
        <Col span={24} style={{ height: "100%" }}>
          <WidgetToDo />
        </Col>

        <Col span={24} style={{ height: "100%" }}>
          <WidgetToDoChangePriceStockIncoming />
        </Col>

        <Col span={24} style={{ height: "100%" }}>
          <WidgetToDoStockIncomingWoPO />
        </Col>

        <Col span={24} style={{ height: "100%" }}>
          <WidgetToDoTransferRequest />
        </Col>

        <Col span={24} style={{ height: "100%" }}>
          <WidgetMinimumOnHand />
        </Col>
      </Row>
    </>
  );
}

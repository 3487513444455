// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import moment, { now } from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Modal,
  Form,
  Divider,
  InputNumber,
  DatePicker,
  Typography,
  Input,
  Tabs,
} from "antd";

// Import React Icons Components
import {
  ArrowRightOutlined,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterApprovalLevel from "../../../Reusable/Master/Approval/ApprovalLevel";

// Import Notification
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";
import PRDetailTable from "../../../Table/BackOffice/Purchasing/PurchaseDetail/Table";
import {
  incomplete,
  failedPO,
  successPO,
  failedFetch,
} from "../../../Reusable/Notification/Notification";
import MasterArticleInventory from "../../../Reusable/Master/ArticleInventory/ArticleInventory";
import PurchaseRequestList from "../../../Reusable/Inventory/Purchasing/PurchaseRequestList";
import PRDetailModalTable from "../../../Table/BackOffice/Purchasing/PurchaseRequestDetailModal/Table";
import PODetailModalTable from "../../../Table/BackOffice/Purchasing/PurchaseOrderDetailModal/Table";
import MasterSupplierByArticleId from "../../../Reusable/Master/Supplier/MasterSupplierByArtID";

// Modal
const { confirm } = Modal;

const { TextArea } = Input;

// CODE
export default function PurchaseOrderModalDraft(props) {
  // PROPS
  const {
    is_open,
    is_close,
    selectedData,
    is_refresh,
    is_edit,
    selected,
    params,
  } = props;

  // CONTEXT
  const { getPurchasing } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  const [dataPRDetail, setDataPRDetail] = useState([]);
  const [dataPODetail, setDataPODetail] = useState([]);

  const [dataSelectedPR, setDataSelectedPR] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);

  // Params
  const [ParamsAcc, setParamsAcc] = useState(null);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  const [nextState, setNextState] = useState(true);

  // Loadings
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tabKey, setTabKey] = useState("2");

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  // const fetchData = async (e) => {
  //   await getPurchasing({
  //     type: "purchase-order",
  //     onAwait: () => {
  //       setLoading(true);
  //     },
  //     onSuccess: (response) => {
  //       console.log("Draft PO: ", response);

  //       if (response?.data?.msg?.length > 0) {
  //         const _res = response.data.msg;

  //         let filtered = _res.filter((filt) => {
  //           return (
  //             !filt?.is_deleted && filt.po_number == selectedData.po_number
  //           );
  //         });

  //         console.log(filtered);

  //         form.setFieldsValue({
  //           start_contract_date: moment(filtered[0].start_contract_date),
  //           end_contract_date: moment(filtered[0].end_contract_date),
  //           id_currency: filtered[0].id_currency,
  //           delivery_count: filtered[0].delivery_count,
  //           delivery_date: moment(filtered[0].delivery_date),
  //           credit_term: filtered[0].credit_term,
  //           credit_term_date: moment(filtered[0].credit_term_date),
  //           description: filtered[0].description,
  //         });

  //         let details = filtered[0].details.map((e) => {
  //           e["_id_pr"] = null;
  //           e["id_po"] = filtered[0].id_po;
  //           e["id_article"] = e.id_article;
  //           e["id_pr_detail"] = e.id_pr_detail;
  //           e["id_po_detail"] = e.id_po_detail;
  //           e["article_name"] = e.article_name;
  //           e["remaining_quantity_dummy"] =
  //             filtered[0].pr_details[
  //               filtered[0].pr_details.findIndex(
  //                 (e) => e.id_article == e.id_article
  //               )
  //             ].quantity;
  //           e["order_date"] = filtered[0].order_date;
  //           e["id_currency"] = filtered[0].id_currency;
  //           e["description"] = filtered[0].description;
  //           e["id_supplier"] = filtered[0].id_supplier;
  //           e["id_supplier_article"] = null;
  //           e["supplier_name"] = filtered[0].supplier_name;
  //           e["quantity"] = e.quantity_order;
  //           e["delivery_count"] = filtered[0].delivery_count;
  //           e["delivery_date"] = filtered[0].delivery_date;
  //           e["start_contract_date"] = filtered[0].start_contract_date;
  //           e["end_contract_date"] = filtered[0].end_contract_date;
  //           e["credit_term"] = 1;
  //           e["credit_term_date"] = filtered[0].credit_term_date;
  //           e["unit_price"] = e.unit_price;
  //           e["total_price"] = e.total_price;
  //           return e;
  //         });

  //         let prDetails = filtered[0].pr_details
  //           .map((e) => {
  //             e["key"] = e.id_article;
  //             e["request_date"] = e.request_date;
  //             e["needed_date"] = e.needed_date;
  //             e["id_pr_detail"] = e.id_pr_detail;
  //             e["remaining_quantity_dummy"] = e.remaining_quantity;
  //             e["quantity"] = e.remaining_quantity;
  //             // if (details.find((x) => x.id_article === e.id_article)) {
  //             //   e["remaining_quantity"] =
  //             //     e.remaining_quantity -
  //             //     details
  //             //       .filter((x) => {
  //             //         if (x.id_article === e.id_article) {
  //             //           return x;
  //             //         }
  //             //       })
  //             //       .reduce(
  //             //         (accumulator, i) => accumulator + i.quantity_ready,
  //             //         0
  //             //       );
  //             // }
  //             return e;
  //           })
  //           .filter((x) => {
  //             if (x.remaining_quantity != 0) {
  //               return x;
  //             }
  //           });

  //         console.log(details);
  //         console.log(prDetails);
  //         if (e == 1 && dataPODetail.length == 0) {
  //           setDataPODetail(details);
  //         }
  //         setDataPRDetail([...prDetails]);
  //         //   setFilteredList(filtered);
  //       } else {
  //         //   setData([]);
  //         //   setFilteredList([]);
  //       }

  //       setLoading(false);
  //     },
  //     onReject: (error) => {
  //       console.log("Error = ", error);

  //       setLoading(false);
  //       failedFetch(error);
  //     },
  //   });
  // };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      // fetchData(1);
      form.setFieldsValue({
        start_contract_date: moment(selectedData?.start_contract_date),
        end_contract_date: moment(selectedData?.end_contract_date),
        id_currency: selectedData?.id_currency,
        delivery_count: selectedData?.delivery_count,
        delivery_date: moment(selectedData?.delivery_date),
        credit_term: selectedData?.credit_term,
        credit_term_date: moment(selectedData?.credit_term_date),
        description: selectedData?.description,
        created_by: selectedData?.created_by,
        created_date: selectedData?.created_date,
        updated_by: selectedData?.updated_by,
        updated_date: selectedData?.updated_date,
      });

      let x = selectedData?.pr_details.map((e) => {
        return {
          ...e,
          unit_price: e.price,
          amount: e.price * e.remaining_quantity,
          id_supplier: selectedData.id_supplier,
          supplier_name: selectedData.supplier_name,
          id_po: selectedData.id_po,
          order_date: selectedData.order_date,
          po_status: selectedData.po_status,
          // quantity: e.remaining_quantity,
        };
      });

      let y = selectedData.details.map((e) => {
        return {
          ...e,
          quantity: e.quantity_order,
          amount: e.total_price,
          id_supplier: selectedData?.id_supplier,
          supplier_name: selectedData?.supplier_name,
          id_po: selectedData?.id_po,
          order_date: selectedData?.order_date,
          //init remaining_quantity_dummy = (remaining_quantity in pr_details) + (quantity_order in details)
          remaining_quantity_dummy:
            selectedData?.pr_details[
              selectedData?.pr_details.findIndex((x) => {
                if (x.id_pr_detail == e.id_pr_detail) {
                  return e;
                }
              })
            ].remaining_quantity,
        };
      });

      setDataPRDetail(x);
      setDataPODetail(y);
    }
    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("Modal State: ", is_open);

    if (is_open == true && !cleanUp) {
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
      is_edit(false);
    }

    if (params && !cleanUp) {
      console.log("Draft Modals: ", params);

      setParamsAcc(params);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, params]);

  // useEffect(() => {
  //   let cleanUp = false;

  //   if (dataSelectedPR.length > 0) {
  //     setSelectedArticleId(dataSelectedPR[0].id_article);
  //     form.setFieldsValue({
  //       id_pr_detail: dataSelectedPR[0].id_pr_detail,
  //       id_article: dataSelectedPR[0].id_article,
  //       article_name: dataSelectedPR[0].article_name,
  //       quantity_request: dataSelectedPR[0].quantity,
  //     });
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [dataSelectedPR]);

  useEffect(() => {
    if (is_open == true && dataPRDetail.length > 0 && dataPODetail.length > 0) {
      console.log("DATA PR DETAIL USE EFFECT >>>", dataPRDetail);
      console.log("DATA PO DETAIL USE EFFECT >>>", dataPODetail);

      let arr = dataPRDetail.map((e) => {
        // Need fixed data of remaining quantity based on last fetch, so we make dummy for the realtime calculation
        e["remaining_quantity_dummy"] = e.remaining_quantity;
        // if (dataPODetail.find((x) => x.id_article === e.id_article)) {
        // get remaining quantity of article from another existing PO
        let remainingFromPO =
          dataPODetail[
            dataPODetail.findIndex((x) => x.id_article === e.id_article)
          ]?.quantity;
        console.log("fromPO >>>", remainingFromPO);
        let remainingFromOther = e.quantity - e.remaining_quantity;
        console.log("fromOther >>>", remainingFromOther);
        // set dummy from realtime calculation
        // z = (e.quantity - e.remaining_quantity) - remainingFromOther
        let z =
          e.quantity -
          (isNaN(remainingFromPO) ? 0 : remainingFromPO) -
          remainingFromOther;
        console.log("Z >>>", z);
        e["quantity"] = z;
        e["remaining_quantity_dummy"] = z;
        // }
        return e;
      });

      console.log("DATA ARR USE EFFECT >>>", arr);
      setDataPRDetail(arr);
    }
  }, [dataPODetail]);

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;

    form.setFieldsValue({});
  };

  // Handle Open
  const handleOpen = () => {
    // setIsDelete(false);

    setModalOpen(true);
  };

  // Handle Close
  const handleClose = () => {
    setData([]);
    setDataPRDetail([]);
    setDataPODetail([]);
    setSelectedArticleId(null);

    is_close(false);
    is_edit(false);
    // setIsDelete(true);
    setEdit(false);
    setModalOpen(false);

    setIsLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (val) => {
    const submit = val;

    console.log("Submit: ", submit);

    setIsLoading(true);
    showModalConfirm(submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // HANDLE PURCHASE REQUEST (PO)
  const getPurchaseRequest = (allKey) => {
    console.log(allKey);

    form.setFieldsValue({
      _id_pr: allKey[0].details[0].id_pr,
    });

    // setDataPRDetail(
    //   allKey.details
    //     .map((e) => {
    //       e["key"] = e.id_article;
    //       e["request_date"] = allKey.request_date;
    //       e["needed_date"] = allKey.needed_date;
    //       e["id_pr_detail"] = e.id_pr_detail;
    //       if (dataPODetail.find((x) => x.id_article === e.id_article)) {
    //         e["remaining_quantity"] =
    //           e.remaining_quantity -
    //           dataPODetail
    //             .filter((x) => {
    //               if (x.id_article === e.id_article) {
    //                 return x;
    //               }
    //             })
    //             .reduce((accumulator, i) => accumulator + i.quantity_ready, 0);
    //       }
    //       return e;
    //     })
    //     .filter((x) => {
    //       if (x.remaining_quantity != 0) {
    //         return x;
    //       }
    //     })
    // );

    // form.setFieldsValue({
    //   department: val,
    //   department_id: code,
    // });
  };

  // HANDLE SUPPLIER
  const getSupplier = (val, allKey) => {
    console.log(val);
    console.log(allKey.idSupplierArticle);

    form.setFieldsValue({
      id_supplier: val,
      id_supplier_article: allKey.idSupplierArticle,
      supplier_name: allKey.label,
    });
  };

  // Approval Level
  const getApproval = (val, code, coa) => {
    console.log(val, code, coa);

    form.setFieldsValue({
      approval_level: code,
    });
  };

  // Get Article Inventory
  const getArticle = (val, code, coa, allKeys) => {
    console.log(val, code, coa, allKeys);

    form.setFieldsValue({
      article_inventory: val,
      article_number: allKeys?.article_number,
      unit: allKeys?.unit,
      chart_of_account: allKeys?.coa,
    });
  };

  // Handle Add
  const handleAdd = async () => {
    let arr = dataPODetail;
    console.log("DATA SELECTED PR >>>", dataSelectedPR);

    if (dataPODetail.length > 0) {
      console.log("PO > 0");
      dataSelectedPR.forEach((e, idx) => {
        if (dataPODetail.some((x) => x.id_article == e.id_article)) {
          console.log("i.id_article == e.id_article");
          idx = dataPODetail.findIndex((val) => val.id_article == e.id_article);
          arr[idx].quantity = arr[idx].quantity += e.quantity;
          if (e.quantity > 0) {
            (arr[idx].quantity_update = true),
              (arr[idx].quantity_difference = e.quantity);
          }
        } else {
          console.log("i.id_article != e.id_article");
          arr.push({ ...e, quantity_order: e.quantity });
        }
      });
    } else {
      console.log("PO == 0");
      for (let e = 0; e < dataSelectedPR.length; e++) {
        arr.push({ ...dataSelectedPR[e] });
      }
    }

    console.log("HASIL CALCULATION >>>", arr);
    setDataPODetail([...arr]);
    setDataSelectedPR([]);

    console.log(dataPODetail);
    setSelectedArticleId(null);
    setTabKey("2");
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Create a Purchase Order ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDataEdit(_data);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const handleDataEdit = async (val) => {
    await form
      .validateFields()
      .then((values) => {
        console.log("Validates: ", values);

        let _validators = form.getFieldsValue(true);

        let datAssign = dataPODetail.map((e) => {
          return { ...e, ..._validators };
        });

        let supplierGrouping = Object.groupBy(
          datAssign,
          ({ id_supplier }) => id_supplier
        );
        console.log(supplierGrouping);
        console.log(Object.values(supplierGrouping)[0]);
        console.log(Object.values(supplierGrouping)[1]);

        let arr = [];
        for (let e = 0; e < Object.keys(supplierGrouping).length; e++) {
          let x = Object.values(supplierGrouping)[e];
          let arr_update = [];
          let arr_add = [];
          for (let i = 0; i < x.length; i++) {
            if ("id_po_detail" in x[i]) {
              arr_update.push({
                id_pr_detail: x[i].id_pr_detail,
                id_po_detail: x[i].id_po_detail,
                updated_by: user_name,
                quantity_ready: x[i].quantity,
                price: x[i].unit_price,
                price_update: x[i].price_update,
                is_ppn: x[i].is_ppn,
                description: x[i].description,
              });
            } else {
              arr_add.push({
                id_article: x[i].id_article,
                id_pr_detail: x[i].id_pr_detail,
                id_po_detail: x[i].id_po_detail,
                quantity_request: x[i].remaining_quantity,
                chart_of_account: "001-10-200",
                id_supplier_article: x[i].id_supplier_article,
                quantity_ready: x[i].quantity,
                price: x[i].unit_price,
                price_update: x[i].price_update,
                is_ppn: x[i].is_ppn,
                description: x[i].description,
              });
            }
          }
          let obj = {
            id_po: x[0].id_po,
            id_currency: x[0].id_currency,
            order_date: x[0].order_date,
            remarks: "Test",
            delivery_date: x[0].delivery_date,
            credit_term: x[0].credit_term,
            description: "Test",
            released: "UPDATE",
            po_status: val,
            start_contract_date: x[0].start_contract_date,
            end_contract_date: x[0].end_contract_date,
            credit_term_date: x[0].credit_term_date,
            // delivery_date: x[0].delivery_date,
            delivery_count: x[0].delivery_count,
            update: arr_update,
            add: arr_add,
            delete: [],
            // created_by: user_name,
          };
          arr.push(obj);
        }
        console.log(arr);
        handleSubmit(arr[0]);
      })
      .catch((errorInfo) => {
        console.log("Validator: ", errorInfo);
        setIsLoading(false);
        incomplete(errorInfo);
      });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const submit = value;
    console.log("SUBMITTED ==> ", submit);

    await axios
      .put(`${baseurl}/purchasing/purchase-order`, value, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Add Draft PO => ", response);
        successPO(response, { method: value.po_status == 1 ? 0 : 1 });
        // handleCancel();
        handleClose();
      })
      .catch((error) => {
        console.log("Add Draft PO => ", error);
        failedPO(error, { method: value.po_status == 1 ? 0 : 1 });
        setIsLoading(false);
      });
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <Button
            style={
              tabKey == "1"
                ? {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {" PR Article List"}
          </Button>
        </>
      ),
      children: (
        <>
          <Row
            style={{
              justifyContent: "space-between",
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <h3>{`Select Article From Purchase Request (PR)`}</h3>

            <Button
              className="next-btn"
              type="primary"
              icon={<ShoppingCartOutlined />}
              disabled={dataSelectedPR.length == 0 ? true : false}
              onClick={handleAdd}
            >{`Add to PO`}</Button>
          </Row>

          <Row className="pay-row" gutter={30}>
            <Col span={12} className="pay-col">
              <Form.Item
                label="Purchase Request"
                name="_id_pr"
                rules={[
                  {
                    required: true,
                    message: "Please, select a Purchase Request!",
                  },
                ]}
              >
                <PurchaseRequestList
                  getPRNumber={getPurchaseRequest}
                  pr={selectedData.pr_number}
                  is_open={modalOpen}
                />
              </Form.Item>

              <Form.Item label="PR Remarks" name="remarks" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <PRDetailModalTable
            dataDetail={dataPRDetail}
            getSelectedPRDetail={setDataSelectedPR}
            params={ParamsAcc}
            isEdit={() => {}}
            // dataEdit={null}
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <Button
            style={
              tabKey == "2"
                ? {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {"PO Detail List"}
          </Button>
        </>
      ),
      children: (
        <>
          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
          >
            {`Purchase Order (PO) Header`}
          </Divider>

          <Row className="info-row" gutter={30} justify="start">
            <Col span={8} className="qty-col">
              <Form.Item
                label="Start Contract Date"
                name="start_contract_date"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Start Contract Date term!",
                //   },
                // ]}
              >
                <DatePicker
                  disabled={nextState ? true : false}
                  format={"YYYY-MM-DD"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="unit-col">
              <Form.Item
                label="End Contract Date"
                name="end_contract_date"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input End Contract Date Date!",
                //   },
                // ]}
              >
                <DatePicker
                  disabled={nextState ? true : false}
                  format={"YYYY-MM-DD"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="unit-col">
              <Form.Item
                label="Currency"
                name="id_currency"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Currency!",
                  },
                ]}
              >
                <Select
                  disabled={nextState ? true : false}
                  // defaultValue="IDR"
                  // style={{
                  //   width: 120,
                  // }}
                  // onChange={}
                  options={[
                    {
                      value: 1,
                      label: "IDR",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="info-row" gutter={30} justify="start">
            <Col span={8} className="qty-col">
              <Form.Item
                label="Delivery Count"
                name="delivery_count"
                rules={[
                  {
                    required: true,
                    message: "Please, Input credit term!",
                  },
                ]}
              >
                <InputNumber
                  disabled={nextState ? true : false}
                  className="delivery_count"
                  placeholder="Count"
                  min={1}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="unit-col">
              <Form.Item
                label="Last Delivery Date"
                name="delivery_date"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Last Delivery Date!",
                  },
                ]}
              >
                <DatePicker
                  disabled={nextState ? true : false}
                  format={"YYYY-MM-DD"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="info-row" gutter={30} justify="start">
            <Col span={8} className="qty-col">
              <Form.Item
                label="Credit Term"
                name="credit_term"
                rules={[
                  {
                    required: true,
                    message: "Please, Input credit term!",
                  },
                ]}
              >
                <InputNumber
                  disabled={nextState ? true : false}
                  className="credit-term"
                  placeholder="credit-term"
                  min={1}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="unit-col">
              <Form.Item
                label="Last Credit Term Date"
                name="credit_term_date"
                rules={[
                  {
                    required: true,
                    message: "Please, Input First Credit Term Date!",
                  },
                ]}
              >
                <DatePicker
                  disabled={nextState ? true : false}
                  format={"YYYY-MM-DD"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="info-row" gutter={30} justify="start">
            <Col span={24} className="unit-col">
              <Form.Item
                label="Description"
                name="description"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Delivery Date!",
                //   },
                // ]}
              >
                <TextArea
                  rows={4}
                  // disabled={nextState ? true : false}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{
              margin: "10px 0px",
              borderColor: "#EBEDF3",
              fontSize: 17,
              fontWeight: 750,
            }}
          >{`Purchase Order (PO) Details`}</Divider>

          <PODetailModalTable
            dataDetail={dataPODetail}
            getAfterDelete={setDataPODetail}
            dataEdit={setDataPODetail}
            getAuthToken={() => {}}
            getAuthBy={() => {}}
            params_acc={params}
            isEdit={() => {}}
          />

          {/* <Divider
            className="divider-form"
            style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
          /> */}

          <Row justify="end" align="middle" className="modal-btn-form">
            <Button
              className="next-btn"
              type="primary"
              // htmlType="submit"
              disabled={dataPODetail.length == 0 ? true : false}
              loading={isLoading}
              onClick={() => {
                onFinish(2);
              }}
              style={{
                marginRight: 15,
              }}
            >
              {`Save As Draft`}
            </Button>

            <Button
              className="submit-btn"
              type="primary"
              // htmlType="submit"
              disabled={dataPODetail.length == 0 ? true : false}
              loading={isLoading}
              onClick={() => {
                onFinish(1);
              }}
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </>
      ),
    },
  ];

  // console.log(dataPRDetail);
  // console.log(selectedArticleId);
  console.log("LIST DATA PR >>>>>", dataPRDetail);
  console.log("LIST DATA PO >>>>>", dataPODetail);

  return (
    <>
      <Modal
        className="purchase-ord-modal"
        key="purchase-ord-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Purchase Order Draft`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        destroyOnClose={true}
        closable
        onCancel={handleClose}
        width={1200}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            id_currency: "IDR",
          }}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top" gutter={[50]}>
            <Col className="form-col" span={24}>
              <Tabs
                defaultActiveKey="1"
                activeKey={tabKey}
                items={items}
                onChange={(e) => {
                  setTabKey(e);
                }}
                type="card"
              />
              <Divider type="horizontal" style={{ borderColor: "#d9d9d9" }} />

              <Row className="info-row" gutter={30} justify="start">
                <Col span={8}>
                  <Form.Item label="Created By" name="created_by">
                    <Input disabled={true} placeholder="Created By" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Created Date" name="created_date">
                    <Input disabled={true} placeholder="Created Date" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Updated By" name="updated_by">
                    <Input disabled={true} placeholder="Updated By" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="info-row" gutter={30} justify="start">
                <Col span={8}>
                  <Form.Item label="Updated Date" name="updated_date">
                    <Input disabled={true} placeholder="Created Date" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../../../API/AuthActions/authAction";

// AUTH
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import { Button, Col, Form, Modal, Row, Table, Tag, Tooltip } from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaUnlockAlt } from "react-icons/fa";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Confirm
const { confirm } = Modal;

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch Update --> ", dispatch);

  return {
    disBlock: (values) =>
      dispatch(
        auth.blockUser(values.id, values.ban_duration, values.blocked_by)
      ),
    disReset: (val) => dispatch(auth.resetRegister(val)),
  };
};

// Maps to Props
const mapStateToProps = (state) => {
  let response = state;
  let blockByAdmin = response?.rootReducer?.auth;

  console.log("Return to Parents: ", blockByAdmin);

  return { blockByAdmin };
};

// CODE
const BlockTable = (props) => {
  // PROPS
  const { is_search, searchKey } = props;

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // ID Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    return await axios
      .get(`${baseurl}/auth/admin/list-users-blocked`, {
        headers: { Authorization: `Bearer ${token ? token : null}` },
      })
      .then((response) => {
        console.log("Block List: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setBulks(_res);
          setData(_res);
        } else {
          setData([]);
          setBulks([]);
        }
      })
      .catch((error) => {
        console.log("Failed: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effects
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // AUTH UPDATE BY ADMIN
  useEffect(() => {
    let cleanUp = false;

    if (props?.blockByAdmin?.block_status === true && !cleanUp) {
      console.log("AUTH! Un-Block is Success: ", props);
      props.disReset(true);

      fetchData();
    } else {
      setIsLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [props?.blockByAdmin?.block_status]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Tooltip title="Revoke Block" className="edit-tooltip">
                <FaUnlockAlt
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO REVOKE > ", record);

                    setDataEdit(record);
                    showModalConfirm(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#F64E60",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Row>
          </>
        );
      },
    },
    {
      title: "Username",
      dataIndex: "user_metadata",
      key: "user_name",
      render: (metas) => {
        return metas?.user_name;
      },
      // width: 250,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email?.length - b.email?.length,
    },
    {
      title: "Roles",
      dataIndex: "user_metadata",
      key: "roles",
      render: (role) => {
        const _role = role?.roles;
        let _name = "";
        let _color = "";

        switch (_role) {
          case "ROLE_STAFF":
            _name = "Staff";
            _color = "default";
            break;

          case "ROLE_SUPERVISOR":
            _name = "Supervisor";
            _color = "geekblue";
            break;

          case "ROLE_MANAGER":
            _name = "Manager";
            _color = "orange";
            break;

          case "ROLE_GENERAL_MANAGER":
            _name = "GM";
            _color = "magenta";
            break;

          case "ROLE_SUPER_ADMIN":
            _name = "Superadmin";
            _color = "success";
            break;

          case "ROLE_ADMIN":
            _name = "Admin";
            _color = "green";
            break;

          default:
            _name = "Unassigned";
            _color = "black";
            break;
        }

        return (
          <Tag className="roles-tag" key={role?.user_name} color={_color}>
            {_name}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "user_metadata",
      key: "status",
      render: (status) => {
        return (
          <Tag key={status?.user_name} color="error">
            {`Blocked`}
          </Tag>
        );
      },
    },
    {
      title: "Banned Until",
      dataIndex: "banned_until",
      key: "banned_until",
      render: (ban) => {
        return moment(ban).format("HH:mm:ss, DD-MM-YYYY");
      },
    },
  ];

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Revoke ${
        _data?.user_metadata?.user_name.toUpperCase() || " - - - "
      }'s Banned Status? Once the Operation is complete this user's will be able to access the systems.`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUnBlock(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Unbllock
  const handleUnBlock = (value) => {
    const _data = value;
    console.log("About to Revoke: ", _data);
    // console.log("Data Edit: ", dataEdit);

    if (_data) {
      props.disBlock({
        id: _data?.id,
        ban_duration: "none",
        blocked_by: user_name,
      });
    } else {
      setIsLoading(false);
    }
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/user-management";

    navigate(`${path}`);
  };

  // Handle Search
  const handleSearch = () => {
    const _values = searchKey ? searchKey.toLowerCase() : null;

    if (bulks.length > 0) {
      let _filtered = bulks.filter((val) => {
        const _name = val?.user_metadata?.user_name.toLowerCase();

        return _name.indexOf(_values) !== -1;
      });

      setData(_filtered);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="row-dept-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              {/* <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  //   onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row> */}
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="block-table"
              name="block-table"
              key="block-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id ? record.id : record.department_id
              }
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockTable);

// REACT COMPONENTS
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Col, Row, Select, Tooltip } from "antd";

// GLOBAL API
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// NOTIFICATIONS
import { masterFailedNotification } from "../../Notification/MasterNotif/Notification";

export default function WarehouseArticleList(props) {
  // PROPS
  const {
    selectedItem,
    dataWarehouse,
    article_name,
    setSelectedArticle = null,
    is_disable,
  } = props;

  // STATE
  const [optionValue, setOptionValue] = useState([]);
  const [warehouse, setWarehouse] = useState(null);
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataWarehouse && !cleanUp) {
      setWarehouse(dataWarehouse);
      fetchData(dataWarehouse.id_warehouse);
    } else {
      setWarehouse(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataWarehouse]);

  useEffect(() => {
    let cleanUp = false;

    if (article_name && !cleanUp) {
      setArticle(article_name);
      if (setSelectedArticle !== null) {
        const filter = optionValue.filter(
          (item) => item.article_name === article_name
        );
        setSelectedArticle(filter[0]);
      }
    } else {
      setArticle(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [article_name]);

  // FETCH WAREHOUSE ARTICLE LIST
  const fetchData = async (idWarehouse) => {
    await axios
      .post(
        `${baseurl}/inventory/warehouse-article-by-id-warehouse`,
        { id_warehouse: idWarehouse },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Warehouse Article By ID Article: ", response);
        if (response?.data.msg != null) {
          let filterData = response.data.msg.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          const filter = filterData.map((item) => {
            return {
              key: item.id_wa,
              label: item.article_name,
              value: item.article_name,
              id_wa: item?.id_wa,
              id_article: item?.id_article,
              article_stock: item?.article_stock,
              id_warehouse: item?.id_warehouse,
              last_supplier: item?.last_supplier,
              average_price: item?.average_price,
              mess_stock: item?.mess_stock,
              warehouse_name: item?.warehouse_name,
              warehouse_location: item?.warehouse_location,
              description: item?.description,
              warehouse_code: item?.warehouse_code,
              chart_of_account: item?.chart_of_account,
              article_name: item?.article_name,
              article_number: item?.article_number,
            };
          });

          setOptionValue(filter);
        } else {
          setOptionValue([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: "Warehouse Article",
        });
        setIsLoading(false);
      });
  };

  // Move to Page
  const move = () => {
    navigate("/back-office/inventory/warehouse");
  };

  // Handle Change
  const handleChange = (e, i) => {
    const _value = e;
    const _allKey = i;

    console.log("Allkeys: ", _allKey);

    // setWarehouse(_value);
    setArticle(_value);
    selectedItem({
      id_wa: _allKey?.id_wa,
      id_article: _allKey?.id_article,
      article_stock: _allKey?.article_stock,
      id_warehouse: _allKey?.id_warehouse,
      last_supplier: _allKey?.last_supplier,
      average_price: _allKey?.average_price,
      mess_stock: _allKey?.mess_stock,
      warehouse_name: _allKey?.warehouse_name,
      warehouse_location: _allKey?.warehouse_location,
      description: _allKey?.description,
      warehouse_code: _allKey?.warehouse_code,
      chart_of_account: _allKey?.chart_of_account,
      article_name: _allKey?.article_name,
      article_number: _allKey?.article_number,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setWarehouse(null);
    setArticle(null);
  };

  return (
    <Row className="master-row" align="top" justify="start" gutter={0}>
      <Col span={24} className="select-col">
        <Select
          className="warehouse-article"
          key={optionValue.key}
          style={{ width: "100%" }}
          value={article}
          placeholder="Article Name"
          showSearch
          allowClear
          disabled={is_disable}
          onChange={handleChange}
          onClear={handleClear}
          options={optionValue}
        />
      </Col>

      {/* <Col span={2} className="select-col">
        <Tooltip
          className="redirect-tooltip"
          title={"Redirect to Master Warehouse"}
        >
          <Button
            className="master-btn"
            icon={
              <AppstoreFilled
                onClick={move}
                style={{ fontSize: 20, color: "#3699FF" }}
              />
            }
          />
        </Tooltip>
      </Col> */}
    </Row>
  );
}

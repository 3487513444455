// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import {Context as MasterContext} from "../../../../../API/Context/MasterContext/MasterContext"

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Space,
  Typography,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaBook } from "react-icons/fa6";
import { SearchOutlined } from "@ant-design/icons";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";
import StandardRecipeTable from "../../../../../Components/Table/Reports/BackOffice/Inventory/StandardRecipeArchive/Table";

// CODE
export default function StandardRecipeArchivePage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  // Search
  const [search, setSearch] = useState();
  const [catName, setCatName] = useState(null);
  const [optionValue, setOptionValue] = useState();
  // MOVE
  const navigate = useNavigate();

  // CONTEXT
  const { getArticleSub } = useContext(MasterContext);

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await getArticleSub({
      subgroup:"sub-group",
      onAwait: () => {},
      onSuccess: (response) => {
        const res = response.data.msg;

        let filter = res.filter((x) => {
          if((x.is_deleted == null || x.is_deleted == false) && x.department_id == 5) return x;
        });

        let map = filter.map((x) => ({
          value: x.id,
          label: x.name,
        }));

        if(response?.data?.msg?.length > 0) {
          console.log("Category Search Data >> ", map);
          setOptionValue(map)
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    })
  }



  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;

    console.log("Search Value: ", value);

    setValue(value);
  };

  // Req Date
  const handleChange = (e) => {
    const value = e;
    // e.preventDefault();

    
    if(value == null){
      setCatName(null);
      console.log("Category Name: null");
    } else {
      setCatName(value);
      console.log("Category Name: ", value);
    }
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaBook
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Standard Recipe Archives`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 150,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Recipe's Name`}</Typography>

                  <Input
                    allowClear
                    placeholder="Search by Recipe's Name"
                    className="search-input"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                      maxWidth: 250,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 150,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Category`}</Typography>
                    <Select
                      // defaultValue="lucy"
                      allowClear
                      style={{
                        minWidth: 250,
                        maxWidth: 350,
                      }}
                      placeholder="Category Name"
                      value={catName}
                      options={optionValue}
                      onChange={handleChange}
                    />
                </Col>

                <Col
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <StandardRecipeTable
              is_search={setSearch}
              _searchRecipeName={value}
              _searchIdCategory={catName}
            />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

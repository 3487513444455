// CONTEXT
import CreateContext from "../CreateContext";

// BASE URL API
import baseAPI from "../../Config/Api";

// REDUCERS
const CMReducer = (state, action) => {
  if ((action.type = "getData")) {
    return {
      ...state,
      data: action.payload,
    };
  }
};

// CHANNEL MANAGER
// Get Room Category Data
const getCMCategoryInfo =
  (dispatch) =>
  async ({ cmCategoryInfo, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + cmCategoryInfo;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Room Category Price
const getCMCategoryPrice =
  (dispatch) =>
  async ({ cmCategoryPrice, type, onAwait, onSuccess, onReject }) => {
    let query = "/channel-manager" + "/" + cmCategoryPrice + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Room Availability
const getCMRoomAvailability =
  (dispatch) =>
  async ({ cmRoomAvl, onAwait, onSuccess, onReject }) => {
    let query = "/channel-manager" + "/" + cmRoomAvl;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Room Availability
const getManagerAvailable =
  (dispatch) =>
  async ({ room, avail, onAwait, onSuccess, onReject }) => {
    let query = "/channel-manager" + "/" + room + "/" + avail;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Reservation Article
const getCMResArticle =
  (dispatch) =>
  async ({ cmResArticle, onAwait, onSuccess, onReject }) => {
    let query = "/channel-manager/article" + "/" + cmResArticle;
    if (onAwait) onAwait();

    baseAPI
      .get(
        query
        // , { headers: accessToken }
      )
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// EXPORT CONTEXT and PROVIDER
export const { Provider, Context } = CreateContext(
  CMReducer,
  {
    // Channel Manager
    getCMCategoryInfo,
    // Price
    getCMCategoryPrice,
    // Room Avail
    getCMRoomAvailability,
    getManagerAvailable,
    // Article Manager
    getCMResArticle,
  },
  { data: [] }
);

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// API
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  authorized_by,
  authorized_dept,
  authorized_roles,
  authorized_token,
  handleAuthorization,
} from "../../../../API/Global/Permission";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";
import { token } from "../../../../API/Global/Payload";

// Params COA
import ParametersFrontOffice from "../../Parameters/ParamsFO";

// Import Ant Design Components
import {
  Col,
  Row,
  Select,
  Table,
  Space,
  Button,
  Modal,
  Typography,
  Form,
  Input,
  Result,
  Tooltip,
} from "antd";

// Import React Icons
import {
  AppstoreFilled,
  FormOutlined,
  MenuOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { FaExclamationTriangle } from "react-icons/fa";

// Import Functions
import { FrontOfficeParams } from "../../Functions/Parameters/ParamsFO";

// Notifications
import {
  failedRate,
  masterFailedNotification,
  successRate,
} from "../../Notification/MasterNotif/Notification";
import { failedPermit, successPermit } from "../../Notification/Notification";

// CODE
export default function MasterRateAudit(props) {
  // PROPS
  const {
    getRateCode,
    room_cat,
    is_permitted,
    is_find,
    old_rate,
    getOldRate,
    segment_id,
    is_render,
    is_rerender,
    rate_name,
    rateFinder,
  } = props;

  // CONTEXT
  const { getMasterRateCode, getMasterRateSegment } = useContext(MasterContext);
  //   const { requestPermissions } = useContext(MainContext);

  let auth_token = localStorage.getItem("permit");

  // PARAMS
  const params_coa = FrontOfficeParams();

  // STATE MANAGEMENT
  // Segment
  const [rate, setRate] = useState(null);
  const [bulks, setBulks] = useState([]);
  const [optionValue, setOptionValue] = useState([]);

  // UseForm
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async (val, sub) => {
    await axios
      .get(`${baseurl}/room/rate-code`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("ALL RATE: ", response);

        if (response?.data?.msg?.length > 0) {
          const filtered = response?.data?.msg;

          handleFilter(filtered);
          setBulks(filtered);

          if (is_find) {
            rateFinder(filtered);
          }
        } else {
          setBulks([]);
          setOptionValue([]);
        }
      })
      .catch((error) => {
        console.log("ERROR PERMIT RATE CODE: ", error);
        masterFailedNotification(error, { method: 8, source: "Rate Code" });
        setOptionValue([]);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_render == true && !cleanUp) {
      fetchData();
      is_rerender(false);
    }

    if (rate_name && !cleanUp) {
      console.log("Rate: ", rate_name);
      setRate(rate_name);
    } else {
      handleClear();
    }

    return () => {
      cleanUp = true;
    };
  }, [is_render, rate_name]);

  //   USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Old Rate: ", old_rate);

    if (
      (old_rate?.length > 0 || old_rate > 0) &&
      bulks.length > 0 &&
      !cleanUp
    ) {
      console.log("Old Rate: ", old_rate);
      handleOldrate(old_rate);
    }

    return () => {
      cleanUp = true;
    };
  }, [old_rate, bulks, room_cat, segment_id]);

  useEffect(() => {
    let cleanUp = false;

    if (room_cat > 0 && bulks.length > 0 && !cleanUp) {
      console.log("Room Category: ", room_cat, segment_id);
      handleFilter(bulks);
    }

    return () => {
      cleanUp = true;
    };
  }, [bulks, room_cat, segment_id]);

  // HANDLE FILTER
  const handleFilter = (val) => {
    const _res = val;

    const _filter = _res.filter((items) => {
      // console.log("items: ", items);
      if (
        items.room_category_id == room_cat &&
        items.main_segment_id == segment_id
      ) {
        // if (is_abf == true) {
        return items;
        // } else {
        //   return items.room_breakfast === 0;
        // }
      }
    });

    // Mapping to Option Value
    const option = _filter.map((dev) => ({
      value: dev?.rate_code,
      label: dev?.rate_code,
      // code: dev?.rate_code_id,
      id: dev?.id,
      main_segment_id: dev?.main_segment_id,
      main_segment_name: dev?.main_segment_name,
      main_segment_code: dev?.main_segment_code,
      room: dev?.room_category_name,
      room_category_id: dev?.room_category_id,
      nett: dev?.nett,
      service: dev?.service,
      tax: dev?.tax,
      gross: dev?.gross,
      description: dev?.description || null,
      room_occupancy: dev?.room_occupancy,
      room_breakfast: dev?.room_breakfast,
      key: dev?.id,
    }));

    console.log("PERMIT Rate Options --> ", option);

    setOptionValue(option);
  };

  // HANDLE FILTER BY ROOM CATEGORY
  const handleChange = (names, alls) => {
    const _names = names;
    const _allKeys = alls;

    setRate(_names);
    getRateCode({
      id: _allKeys?.id,
      main_segment_id: _allKeys?.main_segment_id,
      main_segment_name: _allKeys?.main_segment_name,
      main_segment_code: _allKeys?.main_segment_code,
      room: _allKeys?.room,
      room_category_id: _allKeys?.room_category_id,
      description: _allKeys?.description,
      nett: _allKeys?.nett,
      service: _allKeys?.service,
      tax: _allKeys?.tax,
      gross: _allKeys?.gross,
      label: _allKeys?.label,
      value: _allKeys?.value,
      room_occupancy: _allKeys?.room_occupancy,
      room_breakfast: _allKeys?.room_breakfast,
      key: _allKeys?.key,
    });
  };

  // Find Old Rate
  const handleOldrate = (val) => {
    const _old = bulks.filter((items) => {
      if (items.id == old_rate[2]) {
        return items;
      }
    });

    console.log("OLD: ", _old);

    getOldRate(_old.length > 0 ? _old[0] : null);
  };

  // Handle Clear
  const handleClear = () => {
    setRate(null);
  };

  // Handle Open
  const handleOpen = () => {
    // Mapping to Option Value
    let _newArr = [];

    const option = bulks.filter((dev) => {
      if (dev.room_category_id == room_cat)
        return _newArr.push({
          value: dev?.rate_code,
          label: dev?.rate_code,
          // code: dev?.rate_code_id,
          id: dev?.id,
          main_segment_id: dev?.main_segment_id,
          main_segment_name: dev?.main_segment_name,
          main_segment_code: dev?.main_segment_code,
          room: dev?.room_category_name,
          nett: dev?.nett,
          service: dev?.service,
          tax: dev?.tax,
          gross: dev?.gross,
          description: dev?.description || null,
          room_occupancy: dev?.room_occupancy,
          room_breakfast: dev?.room_breakfast,
        });
    });

    console.log("All Rate Options --> ", _newArr);

    setOptionValue(_newArr);
  };

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={20} className="select-col">
          <Select
            key={"rate-code-select"}
            className={"rate-code-select"}
            placeholder="New Rate Code"
            disabled={!is_permitted}
            value={rate}
            allowClear
            showSearch
            onChange={(names, alls) => {
              handleChange(names, alls);
            }}
            options={optionValue}
            onClear={handleClear}
            // style={{ minWidth: 205 }}
          />
        </Col>

        <Col span={4} className="select-col">
          <Tooltip className="redirect-tooltip" title={"Open All"}>
            <Button
              className="master-btn"
              disabled={!is_permitted}
              icon={
                <AppstoreFilled
                  onClick={handleOpen}
                  style={{ fontSize: 20, color: "#FFF" }}
                />
              }
              // type="primary"
              style={{
                background: `var(--button-secondary-1, linear-gradient(262deg, #0bb7af -53.49%, #3be0d8 201.92%))`,
              }}
            />
          </Tooltip>
        </Col>
      </Row>

      {/* <Tooltip className="rate-tooltip" title="Open All Rate Code">
          <Button
            className="submit-btn"
            type="primary"
            disabled={is_disable == true ? true : false}
            onClick={handleOpen}
          >{`New Rate`}</Button>
        </Tooltip> 
      </Space>
        */}

      {/* <Modal
        className="modal-permissions"
        title={
          <>
            <Row>
              <SafetyCertificateOutlined
                style={{ color: "#F64E60", fontSize: 24 }}
              />
              <Typography style={{ marginLeft: 15 }}>
                {`Authorization Requests`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={800}
      >
        <Form
          name="permissions-form"
          className="permissions-form"
          key="permissions-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 16 }}
          //   wrapperCol={{ span: 12 }}
          autoComplete="off"
          layout="vertical"
          // initialValues={{
          //   room_modal: initialForm?.length > 0 ? initialForm : null,
          // }}
        >
          {permitted ? (
            <>
              <Result
                className="permit-results"
                status={"success"}
                title={"Access Granted!"}
              />
            </>
          ) : (
            <>
              <Row
                className="email-row"
                gutter={30}
                align="middle"
                justify="center"
              >
                <Col
                  span={12}
                  className="content-col"
                  style={{
                    padding: 5,
                    border: "2px solid #F64E60",
                    borderRadius: 5,
                  }}
                >
                  <Row className="col-space" justify="center">
                    <FaExclamationTriangle
                      className="warning-icon"
                      style={{
                        fontSize: 50,
                        color: "#EE9D01",
                        margin: "0px 30px 0px",
                      }}
                    />
                  </Row>

                  <Row className="col-space" justify="center">
                    <Typography
                      className="permit-text"
                      style={{
                        fontWeight: 750,
                        fontSize: 18,
                        textAlign: "justify",
                        padding: "5px 10px",
                      }}
                    >
                      {`This action required Permission and Approval from above the Staff Roles to Proceed! Please, Contact your Superior to Make the Request!`}
                    </Typography>
                  </Row>
                </Col>

                <Col
                  span={12}
                  className="content-col"
                  style={{
                    padding: "0px 50px",
                  }}
                >
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    style={{
                      width: 250,
                    }}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                    style={{
                      width: 250,
                    }}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="modal-btn-row" gutter={30} justify="end">
                <Button
                  className="submit-btn"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{
                    margin: "0px 30px 0px 0px",
                    // backgroundColor: "#0BB7AF",
                    // borderColor: "#0BB7AF",
                  }}
                >
                  {`Confirm`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </>
          )}
        </Form>
      </Modal> */}
    </>
  );
}

// Import React's Component
import React, { useEffect, useState, useContext, useRef } from "react";
import moment from "moment";

// Import ANTD Component
import {
  Table,
  Form,
  Tooltip,
  Row,
  Col,
  Button,
  Input,
  InputNumber,
} from "antd";

// Import React Icons Components
import { ConsoleSqlOutlined, DeleteFilled } from "@ant-design/icons/lib/icons";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

// CODE
export default function StandardRecipeDetailTable(props) {
  // PROPS
  const { item, getDeleteDetail, getEditDetail, is_open, is_close } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [dataEdit, setDataEdit] = useState(null);

  // Boolean
  // const [edit, setEdit] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);

  // CONST EDITABLE CELL
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  // USE EFFECTS
  useEffect(
    () => {
      let cleanUp = false;
      console.log("Item: ", item);

      if (item?.length > 0 && !cleanUp) {

        setData(item);
      } else {
        setData([]);
      }

      if (editingKey !== "") {
        console.log("Estimated Price");
      }

      return () => {
        cleanUp = true;
      };
    }, [item], editingKey);

    useEffect(() => {
      setEditingKey("");
    },[is_close])

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 100,
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <FaCheckCircle
                  className="edit-btn"
                  onClick={() => {
                    // handleSave(record);
                    save(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Col>
              <Col>
                <FaTimesCircle
                  className="delete-btn"
                  onClick={cancel}
                  style={{ fontSize: 20, color: "#F64E60" }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              className="action-guest-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                {/* <Button
                className="next-btn"
                type="small"
                htmlType="submit"
              >{`Save`}</Button> */}
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    disabled={editingKey !== ""}
                    onClick={() => edit(record)}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      getDeleteDetail(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
    },
    {
      title: "Nett Qty",
      dataIndex: "nett_qty",
      key: "nett_qty",
      editable: true,
    },
    {
      title: "Lost Factor (%)",
      dataIndex: "lost_factor",
      key: "lost_factor",
      editable: true,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "R-Unit",
      dataIndex: "r_unit",
      key: "r_unit",
    },
    {
      title: "Average Price",
      dataIndex: "average_price",
      key: "average_price",
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;
        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;
        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
  ];

  // EDITABLE TABLE

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    let childNode = children;

    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: inputType === "number" ? true : false,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      children
    );

    return <td {...restProps}>{childNode}</td>;
  };

  const cancel = () => {
    setEditingKey("");
  };

  const edit = (record) => {
    form.setFieldsValue({
      nett_qty: "",
      lost_factor: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  // Handle Save on Edit
  const handleSave = async (row) => {
    console.log("ROW: ", row);
    const newData = [...data];

    const index = newData.findIndex(
      (item) => row.id_article === item.id_article
    );
    // console.log("Index: ", index);

    const item = newData[index];
    // console.log("Item: ", item);

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    console.log("New Data: ", newData);
    // setDataSource(newData);
    getEditDetail(newData);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      console.log("Check Key in save >> ", key);
      console.log("Check Row in save >> ", row);
      console.log("Check Data in save >> ", data);
      const index = newData.findIndex((item) => key === item);
      console.log("Check Index in save >> ", index);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
          cost: row.nett_qty * item.average_price,
        });
        getEditDetail(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        getEditDetail(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  // Comun Editable
  const columnz = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    // console.log("Col editable: ", col);

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={components}
          bordered
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          dataSource={data}
          columns={columnz}
          size="small"
          pagination={{
            onChange: cancel,
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
          }}
          scroll={{
            x: true,
          }}
          rowKey={(record) => {
            return record?.key;
          }}
        />
      </Form>
    </>
  );
}

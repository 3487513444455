// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import ExpiredOutgoingForm from "../../../../../../Forms/ExpiredOutgoing/Form";

export default function ExpiredOutgoingReportTable(props) {
  // PROPS
  const { _articleMain, _fromStorage, _toStorage } = props;

  //CONTEXT
  const { getInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    outgoing_qty: 0,
    amount: 0
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  const articles = [
    { date: "2024-06-10", from_storage: "Warehouse A", to_storage: "Warehouse B", document_no: "DOC123", article_no: "ART456", article_name: "Widget", average_price: 10.5, outgoing_qty: 100, amount: 1050, id: 1 },
    { date: "2024-06-11", from_storage: "Warehouse A", to_storage: "Warehouse C", document_no: "DOC124", article_no: "ART457", article_name: "Gadget", average_price: 15.0, outgoing_qty: 200, amount: 3000, id: 2 },
    { date: "2024-06-12", from_storage: "Warehouse B", to_storage: "Warehouse D", document_no: "DOC125", article_no: "ART458", article_name: "Doohickey", average_price: 7.5, outgoing_qty: 50, amount: 375, id: 3 },
    { date: "2024-06-13", from_storage: "Warehouse C", to_storage: "Warehouse A", document_no: "DOC126", article_no: "ART459", article_name: "Gizmo", average_price: 20.0, outgoing_qty: 150, amount: 3000, id: 4 },
    { date: "2024-06-14", from_storage: "Warehouse D", to_storage: "Warehouse B", document_no: "DOC127", article_no: "ART460", article_name: "Contraption", average_price: 12.5, outgoing_qty: 80, amount: 1000, id: 5 },
    { date: "2024-06-15", from_storage: "Warehouse A", to_storage: "Warehouse C", document_no: "DOC128", article_no: "ART461", article_name: "Machine", average_price: 30.0, outgoing_qty: 60, amount: 1800, id: 6 },
    { date: "2024-06-16", from_storage: "Warehouse B", to_storage: "Warehouse A", document_no: "DOC129", article_no: "ART462", article_name: "Apparatus", average_price: 25.0, outgoing_qty: 40, amount: 1000, id: 7 },
    { date: "2024-06-17", from_storage: "Warehouse C", to_storage: "Warehouse D", document_no: "DOC130", article_no: "ART463", article_name: "Instrument", average_price: 18.0, outgoing_qty: 70, amount: 1260, id: 8 },
    { date: "2024-06-18", from_storage: "Warehouse D", to_storage: "Warehouse C", document_no: "DOC131", article_no: "ART464", article_name: "Tool", average_price: 22.0, outgoing_qty: 90, amount: 1980, id: 9 },
    { date: "2024-06-19", from_storage: "Warehouse A", to_storage: "Warehouse B", document_no: "DOC132", article_no: "ART465", article_name: "Device", average_price: 28.0, outgoing_qty: 110, amount: 3080, id: 10 }
  ];

  useEffect(() => {
    let cleanUp = false;

    if (_articleMain && _fromStorage && _toStorage && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setBulks([]);
      setTotal({
        outgoing_qty: 0,
        amount: 0
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_articleMain, _fromStorage, _toStorage]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    await getInventoryReport({
      type: `transfer-expired/${_articleMain.main_id}/${_fromStorage.id_warehouse}/${_toStorage.id_warehouse}`,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        // if (response?.data?.msg?.length > 0) {
        //   const _data = response.data.msg;

        //   const filter = _data.map((item, index) => {
        //     return {
        //       ...item,
        //       key: index,
        //       label: item.article_name,
        //       value: item.article_name,
        //       outgoing_qty: item.outgoing_qty,
        //       amount: item.amount,
        //     };
        //   });

        //   const sumTotal =
        //     filter.length > 0
        //       ? filter.reduce(
        //           (prev, curr) => {
        //             return {
        //               outgoing_qty: prev.outgoing_qty + curr.outgoing_qty,
        //               amount: prev.amount + curr.amount
        //             };
        //           },
        //           { outgoing_qty: 0, amount: 0 }
        //         )
        //       : { outgoing_qty: 0, amount: 0 };

        //   setData(filter);
        //   setBulks(filter);
        //   setTotal(sumTotal);
        // } else {
        //   setData([]);
        //   setBulks([]);
        //   setTotal({
        //     outgoing_qty: 0,
        //     amount: 0
        //   });
        // }

        // setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Expired Outgoing Reports`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY") : "-";
      }
    },
    {
      title: "From Storage",
      dataIndex: "from_storage",
      key: "from_storage",
    },
    {
      title: "Document No",
      dataIndex: "document_no",
      key: "document_no",
    },
    {
      title: "Article Number",
      dataIndex: "article_no",
      key: "article_no",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Average Price",
      dataIndex: "average_price",
      key: "average_price",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }
    },
    {
      title: "Outgoing Qty",
      dataIndex: "outgoing_qty",
      key: "outgoing_qty",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      }
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason"
    },
  ];

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={5} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total `} */}
                            {`${total.outgoing_qty}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {/* {`Total 1`} */}
                            {total.amount ? `${total.amount.toFixed(0)}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," ) : 0}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <ExpiredOutgoingForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _articleMain = {_articleMain ? _articleMain : null}
        _fromStorage = {_fromStorage ? _fromStorage : null}
        _toStorage = {_toStorage ? _toStorage : null}
        _total = {total}
      />
    </>
  );
}

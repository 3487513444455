// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterDepartment(props) {
  // PROPS
  const { getDepartment, department_id, department_name, disabled } = props;

  // CONTEXT
  const { getArticleMaster } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [department, setDepartment] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/department");
  };

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getArticleMaster({
      types: "department",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Dept => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filterData = _res.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          let filtered = filterData.map((dept) => ({
            value: dept?.name,
            label: dept?.name,
            code: dept?.code,
            id: dept?.id,
            key: dept?.id,
            dept_uuid: dept?.id_dept,
            desc: dept?.description,
          }));

          // console.log("Department List == ", filtered);
          setOptionValue(filtered);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (department_name && !cleanUp) {
      // console.log("UE select dept >> ", department_name);
      setDepartment(department_name);
    } else {
      setDepartment(null);
    }

    if (optionValue.length > 0 && department_id && !cleanUp) {
      // console.log("UE select dept >> ", department_name);
      let dept = optionValue.filter((e) => {
        if (e.id == department_id) {
          return e;
        }
      });

      console.log(dept);

      setDepartment(dept[0]?.label);
      getDepartment({
        name: dept[0]?.label,
        code: dept[0]?.code,
        dept_id: dept[0]?.id,
        dept_uuid: dept[0]?.dept_uuid,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [department_name, department_id, optionValue]);

  // Handle Change
  const handleChange = (e, allKeys) => {
    const _value = e;
    const _keys = allKeys;

    setDepartment(_value);
    getDepartment({
      name: _value,
      code: _keys?.code,
      dept_id: _keys?.id,
      dept_uuid: _keys?.dept_uuid,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setDepartment(null);
  };

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={22} className="select-col">
          <Select
            className="master-dept"
            name="master-dept"
            key="master-dept"
            value={department}
            disabled={disabled}
            showSearch
            allowClear
            placeholder="Department"
            // suffixIcon={
            //   <Tooltip title="Create New Department" className="tooltip-dept">
            //     <AppstoreFilled
            //       onClick={move}
            //       style={{ fontSize: 20, color: "#3699FF" }}
            //     />
            //   </Tooltip>
            // }
            onChange={handleChange}
            onClear={handleClear}
            options={optionValue}
            style={{ width: "100%" }}
          />
        </Col>

        <Col span={2} className="select-col">
          <Tooltip
            className="redirect-tooltip"
            title={"Redirect to Master Dept"}
          >
            <Button
              className="master-btn"
              icon={
                <AppstoreFilled
                  onClick={move}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiBunkBeds } from "react-icons/gi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import RoomavailabilityTable from "../../../Components/Table/FrontOffice/RoomAvailability/Table";

// Import Global Styles
import { breakpoint, cardBody, cardHeader } from "../../../Style/Global/Global";
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// CODE
export default function RoomAvailabilityPage() {
  // GET TODAY
  const today = new Date(Date.now());

  // CONTEXT
  const { getMasterRoomCat, getMasterAvail, getMasterRoomAvail } =
    useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  // const [fieldsData, setFieldsData] = useState(null);
  const [roomTable, setRoomTable] = useState([]);
  const [average, setAverage] = useState([]);
  const [dataAvail, setDataAvail] = useState([]);
  // Date
  const [dateRange, setDateRange] = useState([]);
  const [days, setDays] = useState(3);
  // Total Count
  const [totalCat, setTotalRoomCat] = useState(0);
  // Set Search
  const [isSearch, setIsSearch] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  // Alert
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // FETCH ROOM
  const fetchRoom = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        // FILTER IS DELETED
        let filtered = response.data.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            return filt;
          }
        });

        // Length Count
        setTotalRoomCat(filtered.length);
        setIsLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setIsLoading(false);
        // setLoading(false);
      },
    });
  };

  // Update Room Availability
  const fetchRoomAvailibility = async () => {
    // console.log("Update --> ", dateRange);

    if (dateRange?.length > 0 || dateRange != null || dateRange != undefined) {
      // console.log("HIT", dateRange.length);

      setIsShow(false);

      await getMasterAvail({
        type: "category/availability",
        start_date:
          dateRange.length > 0
            ? moment(dateRange[0]).format("YYYY-MM-DD")
            : moment(today).format("YYYY-MM-DD"),
        end_date:
          dateRange.length > 0
            ? moment(dateRange[1]).format("YYYY-MM-DD")
            : moment(today).add(2, "day").format("YYYY-MM-DD"),
        onAwait: () => {},
        onSuccess: (response) => {
          console.log("Update Room Category Availability => ", response);

          if (response?.data?.msg?.length > 0) {
            const _res = response.data.msg;

            let filtered = _res.filter((val) => {
              if (!val.is_deleted) {
                return val;
              }
            });

            // console.log("Availability FILTERED => ", JSON.stringify(filtered));
            setRoomTable(filtered);
          } else {
            setRoomTable([]);
          }

          if (response?.data?.data_get_arr?.length > 0) {
            const _res = response.data.data_get_arr;
            setAverage(_res);
          } else {
            setAverage([]);
          }
        },
        onReject: (error) => {
          failedFetch(error);
          console.log("ERROR ==> ", error);
        },
      });
    } else {
      setIsShow(true);
    }
  };

  // Fetch Room Availability
  const fetchGetRoom = async () => {
    await getMasterRoomAvail({
      type: "category/availability",
      start_date:
        dateRange?.length > 0
          ? moment(dateRange[0]).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
      end_date:
        dateRange?.length > 0
          ? moment(dateRange[1]).format("YYYY-MM-DD")
          : moment(today).add(2, "day").format("YYYY-MM-DD"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Get Room Category Availability => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            // let aw =
            //   dateRange?.length > 0
            //     ? moment(dateRange[0]).format("YYYY-MM-DD")
            //     : moment(today).format("YYYY-MM-DD");
            // let akh =
            //   dateRange?.length > 0
            //     ? moment(dateRange[1]).format("YYYY-MM-DD")
            //     : moment(today).add(2, "day").format("YYYY-MM-DD");

            // console.log(aw, akh);

            // if (moment(val.date_availability).isBetween(aw, akh, "days", "[]")) {
            if (!val.is_deleted) {
              return val;
            }
          });

          // console.log("Availability FILTERED => ", JSON.stringify(filtered));
          setRoomTable(filtered);
        } else {
          setRoomTable([]);
        }
      },
      onReject: (error) => {
        console.log("ERROR ==> ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchRoom();
    fetchRoomAvailibility();
  }, []);

  // REFRESH EFFECTS
  useEffect(() => {
    if (refresh == true) {
      // console.log("Refresh???", refresh);
      fetchRoomAvailibility();
      setRefresh(false);
    }
  }, [refresh]);

  // On Change Date Range
  const getDateRange = async (value) => {
    const range = value;
    const _days =
      value?.length > 0 ? moment(value[1]).diff(value[0], "days") : 0;

    // console.log("Value >>>> ", range);
    console.log("Total Days >>>> ", _days);

    setIsSearch(false);
    setDateRange(range);

    setDays(_days + 1);
  };

  // Update Room Availability
  const updateAvailability = async (e) => {
    // console.log("Update --> ", e);

    fetchRoomAvailibility();
    setIsSearch(true);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="room-availability-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <GiBunkBeds
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Room Availability`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            // gutter={[30, 30]}
            style={cardBody}
          >
            <Space
              className="date-picker-space"
              align="end"
              size={15}
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Typography
                style={{ marginBottom: 10 }}
              >{`Date Range`}</Typography>

              <Input.Group>
                <DatePicker.RangePicker
                  allowClear
                  className="date-range-room-avail"
                  onChange={getDateRange}
                  // style={{
                  //   width: "100%",
                  // }}
                />
              </Input.Group>

              {/* <Col
              // span={8}
              xxl={8}
              xl={8}
              lg={8}
              md={10}
              sm={24}
              xs={24}
              className="col-search-btn"
              style={{
                margin: "54px 0px 22px 30px",
                fontSize: 14,
                fontWeight: 400,
              }}
            > */}
              <Button
                className="search-btn"
                type="primary"
                icon={<SearchOutlined />}
                onClick={updateAvailability}
              >
                {`Search`}
              </Button>
              {/* </Col> */}
            </Space>
          </Row>

          {isShow === true ? (
            <Alert
              className="date-avail-alert"
              showIcon
              type="error"
              description="Please, Select a Date Range to show Room Availability!"
              message="Date Range is Empty!"
              style={{
                margin: "0px 0px 15px",
                width: "100%",
              }}
            />
          ) : null}

          <RoomavailabilityTable
            onForm={false}
            dataRoom={roomTable}
            date_avail={isSearch == true ? dateRange : []}
            average={average}
            totalCat={totalCat}
            totalDays={days}
            is_refresh={setRefresh}
            data_avail={setDataAvail}
            is_load={setIsLoading}
          />
        </Col>
      </Content>
    </Layout>
  );
}

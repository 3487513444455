// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  DatePicker,
  Typography,
  Select,
  Input,
  Layout,
  Form,
  InputNumber,
  Result,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BsUiChecksGrid } from "react-icons/bs";
import { FaPlaneDeparture } from "react-icons/fa";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  FileSearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Page Components
import JoinBillTrx from "../../../Components/Forms/PreBill/JoinBillTrx";
import PreBillFormTrx from "../../../Components/Forms/PreBill/PreBillTrx";
import PreBillForm from "../../../Components/Forms/PreBill/PreBill";

// Import Functions
import { CurrencySymbols } from "../../../Components/Reusable/Functions/Currency/Currency";

// Master Components
import MasterPaymentTypeEdit from "../../../Components/Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterPaymentCardEdit from "../../../Components/Reusable/Master/PaymentCard/PaymentCardEdit";
import MasterPaymentType from "../../../Components/Reusable/Master/PaymentType/PaymentType";
import MasterPaymentCard from "../../../Components/Reusable/Master/PaymentCard/PaymentCard";
import MasterBillTrxForm from "../../../Components/Forms/PreBill/MasterBillTrx";

// IMPORT GLOBAL
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Notifications
import {
  masterFailedNotification,
  masterIncomplete,
} from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import {
  failedBill,
  failedCheckOut,
  failedFetch,
  successBill,
  successCheckOut,
} from "../../../Components/Reusable/Notification/Notification";

// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function CheckOutPage() {
  // CONTEXT
  const { getDeposit, getReservationLeader } = useContext(MainContext);

  // USE LOCATION
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // IS EARLY
  const [isEarly, setIsEarly] = useState(true);
  // Data
  const [dataArt, setDataArt] = useState(null);
  const [leaders, setLeaders] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  // Payment
  const [payCode, setPayCode] = useState({
    id: 0,
    name: "",
    coa: "",
  });
  const [cards, setCards] = useState({
    id: 0,
    name: "",
  });
  const [payment_status, setPayment_status] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  // Is Exist
  const [isExist, setIsExist] = useState(true);
  const [isDisable, setIsDisable] = useState(true);

  // FORM
  const [form] = Form.useForm();

  // Handle Early C/O
  const handleEarly = () => {
    const _path = "/front-office/expected-departure/early-check-out";

    console.log("Is Early: ", pathname.includes(_path));

    if (pathname.includes(_path)) {
      setIsEarly(true);
    } else {
      setIsEarly(false);
    }
  };

  // Fetch Data
  const fetchData = async () => {
    await getDeposit({
      type: "deposit-res-stay",
      res_id: leaders?.reservation_id
        ? leaders.reservation_id
        : state[0]?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Res Deposit: ", response);

        if (response?.data?.length > 0) {
          let _response = response.data;

          setDepositAmount(
            _response[0]?.deposit_amount > 0 ? _response[0].deposit_amount : 0
          );
        }
      },
      onReject: (error) => {
        console.log("ERROR: ", error);
        failedFetch(error);
      },
    });
    //     get by post deposit
    // key: {res_id}
    // method: post
    // api: "api/v1/reservation/deposit-res-stay"
  };

  const fetchLeaders = async () => {
    await getReservationLeader({
      leader: "get-leader",
      reservation_id: state[0]?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Leader Data --> ", response);

        if (response?.data?.msg?.length > 0) {
          let _value = response.data.msg[0];

          setLeaders(_value);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if ((totalAmount || depositAmount > 0) && !cleanUp) {
      console.log("Total Transactions: ", totalAmount);
      console.log("Total Deposit: ", depositAmount);
      setFormFields();

      // console.log("Currency: ", currency);
    }

    return () => {
      cleanUp = true;
    };
  }, [totalAmount, depositAmount]);

  useEffect(() => {
    let cleanUp = false;

    handleEarly();

    if (state?.length > 0 && !cleanUp) {
      setButtonLoading(true);
      setIsExist(true);
      fetchData();

      // console.log("Path: ", pathname);

      if (state.length > 1) {
        handleLeaders();
      } else {
        setLeaders(state[0]);
      }
    } else {
      console.log("NOT EXISTING: ", state);
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // Handle Leader
  const handleLeaders = () => {
    const lead = state.filter((val) => val.group === 1);

    console.log("Leaders: ", lead);

    if (lead?.length > 0) {
      setLeaders(lead[0]);
    } else {
      fetchLeaders();
    }
  };

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate("/front-office/expected-departure", { state: state });
  };

  // HANDLE SUCCESS
  const handleSuccess = (e) => {
    const pays = { ...e, is_early: isEarly };

    console.log("New State: ", pays);

    setTimeout(() => {
      setLoading(false);

      // handleSuccess(response);
      navigate(`${pathname}/success`, {
        state: { data: state, payment: pays },
      });
    }, 1000);
  };

  // Payment Type
  const getPaymentType = (value, code, name) => {
    console.log("Value: ", value, code, name);

    // console.log(payCode);
    setCards({
      id: value?.id,
      name: value?.name,
    });

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };

  // Payment Type ID
  const getPayTypeId = (value) => {
    console.log(value);
  };

  // Payment Details/Card
  const getPayCard = (value, code, acc) => {
    console.log(value, code, acc);
    // const fieldvalue = form.getFieldsValue();

    // console.log(fieldvalue);

    if (value?.id) {
      setPayCode({
        id: value?.id,
        name: value?.name,
        coa: value?.coa,
      });
    }

    form.setFieldsValue({
      payment_details: value?.name,
    });
  };

  // HANDLE CHANGE
  const handleChange = (val) => {
    // console.log("Value Change: ", val);

    let _val = val;
    let _total = totalAmount?.gross;
    let _depo = depositAmount;

    let _pay = _depo + _val;
    let _change = _pay - _total;

    // console.log("Change Payment: ", _change);

    form.setFieldsValue({
      change_amount: _change,
    });
  };

  // Loading
  const handleLoading = (value) => {
    setIsDisable(true);

    switch (value) {
      case true:
        setIsExist(false);
        break;

      case false:
        setIsExist(true);
        break;

      default:
        break;
    }

    setTimeout(() => {
      setIsDisable(false);
    }, 1250);
  };

  // ON FINISH
  const onFinish = async (e) => {
    // e.preventDefault();
    const _content = e;

    // console.log("LOCATION STATE => ", state);
    // console.log("Pathname => ", pathname);
    // console.log("COA >>> ", coa);
    console.log("DATA SUBMIT >>> ", _content);
    console.log("PAY CODE >>> ", payCode);
    console.log("PAY ID >>> ", cards);
    console.log("PAY STATUS >>> ", payment_status);

    let allBody = [];

    if (state?.length > 0) {
      for (let i = 0; i < state?.length; i++) {
        allBody.push({
          reservation_id: state[i]?.reservation_id
            ? state[i].reservation_id
            : "",
          price_id: state[i]?.price_id > 0 ? state[i].price_id : 0,
          payment_type: _content?.payment_type,
          payment_details: _content?.payment_details,

          created_by: user_name,
        });
      }
    }

    console.log("About to CO: ", allBody);

    setLoading(true);
    showModalConfirm(_content);
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = async (error) => {
    // console.log("LOCATION STATE => ", state);
    // console.log("Pathname => ", pathname);
    console.log("Data Incomplete >>> ", error?.values);

    masterIncomplete(error);
  };

  // HANDLE FINISH
  const setFormFields = async () => {
    // const _forms = 0;
    console.log("Set Total Amounts: ", totalAmount);
    console.log("Set Total Deposit: ", depositAmount);

    form.setFieldsValue({
      total_amount: totalAmount?.gross,
      deposit_amount: depositAmount,
      payment_type: leaders?.payment_type,
      payment_details: leaders?.payment_details,
    });

    if (totalAmount > 0) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  // SHOW MODAL CHECK-OUT
  const showModalConfirm = async (record) => {
    const _data = record;
    var bulks = [];

    console.log("Data Articles => ", dataArt);
    // console.log("Data State => ", state);

    // for (let i = 0; i < dataArt.length; i++) {
    //   reservation_id, price_id, created_by, cust_amount;
    // }

    confirm({
      className: "checkout-confirm",
      title: isEarly ? `Early Check-Out` : `Check-Out Confirmations`,
      icon: (
        <ExclamationCircleOutlined
          className="confirm-icon"
          style={{ color: "#1890ff" }}
        />
      ),
      content: `Are you sure all the Data with Reservation ID ${
        _data?.reservation_id || leaders?.reservation_id
      } is ready to Check-Out?`,
      okText: "Check-Out",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      okButtonProps: {
        type: "primary",
        className: "danger-btn",
        danger: true,
        // loading: loading,
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      onOk() {
        // return new Promise((resolve, reject) => {
        handleSubmit(_data);

        //   if (_data) {
        //     console.log("Resolves: ", resolve);
        //   } else {
        //     console.log("Rejects: ", reject);
        //   }
        // }).catch((err) => console.log("Oops errors Promise!", err));
        // console.log("Article deleted");
      },

      onCancel() {
        setLoading(false);
        // console.log("Confirmation Cancelled");
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _submitted = value;
    let _totalPay =
      _submitted?.payment_amount >= totalAmount
        ? totalAmount
        : _submitted?.payment_amount;

    console.log("About to CO: ", _submitted);

    let allBody = [];

    if (state?.length > 0) {
      for (let i = 0; i < state?.length; i++) {
        allBody.push({
          reservation_id: state[i]?.reservation_id
            ? state[i].reservation_id
            : "",
          price_id: state[i]?.price_id > 0 ? state[i].price_id : 0,
          payment_type: _submitted?.payment_type,
          payment_details: _submitted?.payment_details,

          // cust_amount: _totalPay,
          // coa: coa ? coa : null,

          created_by: user_name,
        });
      }
    }

    const secondBody = {
      reservation_id: leaders?.reservation_id ? leaders.reservation_id : null,
      created_by: user_name,
      cust_amount: _totalPay,
      coa: payCode.coa ? payCode.coa : null,
      price_id: state?.length > 1 ? null : state[0].price_id,
    };

    console.log("All Body C/O: ", allBody);
    console.log("All Second Body C/O: ", secondBody);

    if (isEarly) {
      await axios
        .post(
          `${baseurl}/guest/early-checkout`,
          { data: allBody },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (response) => {
          console.log("Response Early C/O = ", response);

          if (response.status === 200) {
            successCheckOut(response, { method: 1 });
            await handleCheckOut(_submitted);
            // setTimeout(() => {
            //   handleSuccess(response);
            //   // setLoading(false);
            // }, 1000);
          } else {
            failedCheckOut(response, { method: 1 });
          }
        })
        .catch((error) => {
          console.log("Failed to Check-Out = ", error);

          failedCheckOut(error, { method: 1 });
          // failedBill(error, { method: 0 });
          setLoading(false);
        });
    } else {
      await axios
        .post(
          `${baseurl}/guest/checkout-price-stay`,
          { data: allBody },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (response) => {
          console.log("Response Check-Out = ", response);

          if (response.status === 200) {
            successCheckOut(response, { method: 0 });
            await handleCheckOut(_submitted);
            // setTimeout(() => {
            //   handleSuccess(response);
            //   // setLoading(false);
            // }, 1000);
          } else {
            failedCheckOut(response, { method: 0 });
          }
        })
        .catch((error) => {
          console.log("Failed to Check-Out = ", error);

          failedCheckOut(error, { method: 0 });
          // failedBill(error, { method: 0 });
          setLoading(false);
        });
    }
  };

  // HANDLE JOURNAL
  const handleCheckOut = async (val) => {
    const _submit = val;
    let _totalPay = 0;

    if (depositAmount > 0) {
      // console.log("Handle CO: ", _submit);

      _totalPay =
        _submit?.payment_amount + depositAmount >= totalAmount?.gross
          ? _submit?.payment_amount - _submit?.change_amount
          : _submit?.payment_amount;
    } else {
      _totalPay =
        _submit?.payment_amount >= totalAmount?.gross
          ? totalAmount?.gross
          : _submit?.payment_amount;
    }

    // console.log("Total Pays: ", _totalPay);
    // console.log("Total Amounts: ", totalAmount);

    await axios
      .post(
        `${baseurl}/guest/checkout-cust-amount`,
        {
          reservation_id: leaders?.reservation_id
            ? leaders.reservation_id
            : null,
          created_by: user_name,
          cust_amount: _totalPay,
          coa: payCode.coa ? payCode.coa : null,
          price_id: state?.length > 1 ? null : state[0].price_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Response Check Out Cust Amount = ", response);
        // successCheckOut(response, { method: 0 });
        successBill(response, { method: 0 });

        setTimeout(() => {
          handleSuccess(_submit);
        }, 1000);
      })
      .catch((error) => {
        console.log("Failed to Check-Out Cust Amount = ", error);
        failedCheckOut(error, { method: isEarly ? 1 : 0 });
        setLoading(false);
      });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="expected-arrival-main-layout">
          <Row className="row-title" justify="start" style={mainBody}>
            <BsUiChecksGrid
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`${isEarly ? "Early " : ""}Check Out Page`}
            </Typography>
          </Row>

          <Row className="space" size="large" style={{ height: 30 }} />

          {isExist ? (
            <>
              <Row
                className="row-search-component"
                justify="start"
                gutter={[30, 30]}
                // style={mainBody}
              >
                <Col
                  className="bill-col"
                  xxl={16}
                  xl={16}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={0}
                >
                  <Row style={mainBody}>
                    {/* 
                HERE'S THE BILL
                <PreBillForm dataArticle={setDataArt} dataGuest={[]} is_group={} /> 
                */}
                    {state?.length > 1 ? (
                      <JoinBillTrx
                        is_group={true}
                        dataLeader={leaders}
                        dataArticle={setDataArt}
                        totalPayment={setTotalAmount}
                        load_state={handleLoading}
                        is_early={isEarly}
                      />
                    ) : (
                      <PreBillFormTrx
                        dataGuest={leaders}
                        is_checkOut={true}
                        totalPayment={setTotalAmount}
                        dataArticle={setDataArt}
                        load_state={handleLoading}
                        is_early={isEarly}
                      />
                    )}
                  </Row>
                </Col>

                <Col
                  className="btn-col"
                  xxl={8}
                  xl={8}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <Row
                    className="row-payment"
                    justify="center"
                    align="middle"
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 5,
                      // width: "100%",
                      // minHeight: "100%",
                    }}
                  >
                    <Typography.Title
                      level={4}
                      className="text-form-title"
                      style={{
                        height: 67,
                        display: "flex",
                        alignItems: "center",
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      {`PAYMENTS`}
                    </Typography.Title>

                    <Divider
                      className="divider-form"
                      style={{
                        backgroundColor: "#000000",
                        margin: 0,
                      }}
                    />

                    <Form
                      name="resv-form-modal"
                      className="resv-form-modal"
                      form={form}
                      labelCol={{ span: 8 }}
                      labelWrap={{ span: 16 }}
                      labelAlign="left"
                      //   onChange={handleChange}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout="horizontal"
                      style={{
                        padding: 15,
                        width: "100%",
                      }}
                    >
                      <Row
                        className="row-cashier"
                        // style={{
                        //   padding: "0px 30px 0px",
                        // }}
                      >
                        <Col span={24}>
                          <Form.Item
                            label="Total Amount"
                            name="total_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row justify="start">
                        <Col span={24}>
                          <Form.Item
                            label="Payment Amount"
                            name="payment_amount"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input the payment amount from customer!",
                              },
                            ]}
                            style={{
                              //   margin: "30px 0px 15px",
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              // disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                              onChange={handleChange}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Change Amount"
                            name="change_amount"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input the payment amount from customer!",
                            //   },
                            // ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Deposit Amount"
                            name="deposit_amount"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input the payment amount from customer!",
                            //   },
                            // ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Item
                          label="Payment Type"
                          name="payment_type"
                          rules={[
                            {
                              required: true,
                              message: "Please, Select the Payment Type!",
                            },
                          ]}
                          style={{ minWidth: "100%" }}
                        >
                          <MasterPaymentTypeEdit
                            getPaymentType={getPaymentType}
                            // getPayTypeId={setCards}
                            pay={leaders?.payment_type || null}
                          />
                        </Form.Item>
                      </Row>

                      <Row>
                        <Form.Item
                          label="Payment Details"
                          name="payment_details"
                          rules={[
                            {
                              required: true,
                              message: "Please, Select the Payment Details!",
                            },
                          ]}
                          style={{ minWidth: "100%" }}
                        >
                          <MasterPaymentCardEdit
                            getPayCard={getPayCard}
                            getPayTypeId={cards.id}
                            payCard={leaders?.payment_details || null}
                            getCode={setPayCode}
                          />
                        </Form.Item>
                      </Row>

                      <Divider
                        className="divider-form"
                        style={{
                          margin: "15px 0px",
                          backgroundColor: "#EBEDF3",
                        }}
                      />

                      <Row
                        justify="end"
                        align="middle"
                        className="row-form-btn"
                        style={{ padding: 15 }}
                        gutter={30}
                      >
                        {/* <Button
                      className="print-btn"
                      type="primary"
                      // htmlType="submit"
                      icon={<PrinterOutlined />}
                      // onClick={() => {
                      //   handlePrint();
                      //   if (counter == 0) {
                      //     myclick();
                      //   }
                      // }}
                      style={{
                        // backgroundColor: "#1BC5BD",
                        // borderColor: "#1BC5BD",
                        marginRight: 30,
                      }}
                    >
                      Print & Download
                    </Button> */}

                        <Button
                          className="check-out-btn"
                          type="primary"
                          danger
                          htmlType="submit"
                          loading={loading}
                          disabled={isDisable}
                          style={{
                            // backgroundColor: "#1BC5BD",
                            // borderColor: "#1BC5BD",
                            margin: "0px 30px 0px 0px",
                          }}
                        >
                          {`Check-Out`}
                        </Button>

                        <Button
                          className="cancel-btn"
                          type="default"
                          onClick={handleReturn}
                        >
                          {`Cancel`}
                        </Button>
                      </Row>
                    </Form>
                  </Row>
                </Col>
                {/* <MasterBillTrxForm  /> */}
              </Row>
            </>
          ) : (
            <>
              <Result
                status={"500"}
                title={"ERROR!"}
                subTitle={`Sorry, something went wrong!`}
                extra={[
                  <Row
                    className="extra-row"
                    key={"extra-row"}
                    justify="center"
                    gutter={30}
                  >
                    <Col className="return-col" span={24}>
                      <Space className="btn-space" size="large">
                        <Tooltip
                          className="return-tooltip"
                          title="Return to Expected Departure Page"
                          color="geekblue"
                          placement="right"
                        >
                          <Button
                            className="cancel-btn"
                            key="return-btn"
                            onClick={handleReturn}
                          >
                            {`Return`}
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>,
                ]}
                style={mainBody}
              >
                <>
                  <Space className="result-space" direction="vertical" size={5}>
                    <Paragraph>
                      <Text
                        strong
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {`The content you submitted may has the following error:`}
                      </Text>
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You are trying to reach this page without doing any process!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You're request is not valid!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`Please, check and modify the following information before resubmitting!`}
                    </Paragraph>
                  </Space>
                </>
              </Result>
            </>
          )}
        </Col>
      </Content>
    </Layout>
  );
}

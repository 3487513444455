// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// AUTH
import { roles, token, user_name } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Form,
  Input,
  DatePicker,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import { FaCashRegister, FaConciergeBell, FaUserFriends } from "react-icons/fa";
import { TiClipboard } from "react-icons/ti";
import {
  CloseCircleFilled,
  DeleteFilled,
  PlusOutlined,
  PrinterFilled,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";

// MODALS
import ModalArticleMice from "../../Modals/MICE/Modal";
import ModalCashierMice from "../../Modals/Cashier/MICE/Modal";

// Import Page Components

// Notification
import {
  failedAddReserv,
  failedNonStay,
  successAddReserv,
  successNonStay,
} from "../../Reusable/Notification/Notification";
import { TfiMenuAlt } from "react-icons/tfi";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function MicePopover({ details, is_refresh, is_history }) {
  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  // Modal State
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [article, setArticle] = useState(false);

  // Set Data
  // console.log("IN POP OVER = ", details);
  const detail = details;

  // USE FORM
  const [form] = Form.useForm();

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);
  };

  // DELETE MODAL
  const showDeleteModal = () => {
    console.log("INSIDE DELETE MODAL = ", detail);
    const contentDelete = detail;

    confirm({
      className: "delete-res-confirm",
      title: `Are you sure want to Cancel MICE Reservations of ${
        contentDelete?.guest_name
          ? contentDelete.guest_name.toUpperCase()
          : " ---- "
      } with Reservation ID ${
        contentDelete?.reservation_id ? contentDelete.reservation_id : " ---- "
      }?`,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(contentDelete);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
        zIndex: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (value) => {
    const contentDelete = value;

    console.log("ABOUT TO DELETE ", contentDelete);

    let obj = {
      ["reservation_id"]: contentDelete?.reservation_id,
      deleted_by: user_name,
    };

    await axios
      .delete(`${baseurl}/fnb/mice/reservation`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("DEL NONSTAY >> ", response);

        successNonStay(response, { method: 2 });
        is_refresh(true);
      })
      .catch((error) => {
        console.log("DEL NONSTAY >> ", error);
        failedNonStay(error, { method: 2 });
      });
  };

  // Handle Open
  const handleOpen = () => {
    setModal(true);
  };

  // Handle Cancel
  const handleCancel = () => {
    setModal(false);
    form.resetFields();
  };

  return (
    <>
      <Tooltip title="Option Menu" className="popover-tooltip">
        <Popover
          className="pop-over-reservation-mice"
          name="pop-over-reservation-mice"
          key="pop-over-reservation-mice"
          placement="right"
          title={
            <Row>
              {/* <Col span={4}>
                <UnorderedListOutlined
                  style={{
                    padding: "8px 8px 8px 0px",
                    fontSize: 14,
                    color: "#2f54eb",
                  }}
                />
              </Col> */}

              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    // color: "#2f54eb",
                    fontWeight: 600,
                  }}
                >
                  {`Reservation Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row justify="start" style={{ width: 210 }}>
                <Button
                  className="row-reserve-opt"
                  onClick={() => {
                    console.log("Records = ", detail);
                    setData(detail);

                    handleClose();
                    setModal(true);
                  }}
                  icon={
                    <FaCashRegister
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 210,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Cashier`}
                </Button>
              </Row>

              <Row
                justify="start"
                style={{ width: 210 }}
                hidden={is_history == true ? true : false}
              >
                <Button
                  className="row-reserve-opt"
                  onClick={() => {
                    //   console.log(e);
                    console.log("Records = ", detail);
                    setData(detail);

                    handleClose();
                    setArticle(!article);
                  }}
                  icon={
                    <FaConciergeBell
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 210,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Add Article`}
                </Button>
              </Row>

              <Row
                justify="start"
                style={{ width: 210 }}
                hidden={is_history == true ? true : false}
              >
                <Button
                  className="row-reserve-opt"
                  onClick={(e) => {
                    setData(detail);
                    // console.log(e);
                    //   console.log(detail);

                    showDeleteModal();
                    handleClose();
                  }}
                  icon={
                    <CloseCircleFilled
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 10px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 210,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Cancel Reservation`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            onClick={() => {
              // console.log("ABOUT TO MENU > ", detail);
              // showDeleteModal(record);
            }}
            style={{ fontSize: 20, color: "#3699FF", cursor: "pointer" }}
          />
        </Popover>
      </Tooltip>

      {/* MODAL CASHIER */}
      <ModalCashierMice
        openModal={modal}
        closeModal={setModal}
        dataCashier={modal == true ? data : null}
      />

      <ModalArticleMice
        openModal={article}
        closeModal={setArticle}
        dataArticle={article == true ? data : null}
      />
    </>
  );
}

// REACT COMPONNETS
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// REACT ICONS
import { TbFileTime } from "react-icons/tb";

// PAGE COMPONENTS
import APAgingTable from "../../../../Components/Table/BackOffice/AP/APAging/Table";

export default function APAgingPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [search, setSearch] = useState(null);
  const [date, setDate] = useState(null);

  const navigate = useNavigate();

  // HANDLE DATE
  const handleDate = (date) => {
    const _date = date !== null && date !== "Invalid date" ? date : null;
    setDate(_date);
  };

  // HANDLE INPUT
  const handleInput = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    if (date) {
      setDateValue(date);
    } else {
      setDateValue(null);
    }

    if (keyword) {
      setValue(keyword);
    } else {
      setValue(null);
    }
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="ooo-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <TbFileTime
              className="aging-icon"
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`A/P Aging`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Date`}</Typography>

                <DatePicker
                  onChange={handleDate}
                  placeholder="Date"
                  style={{ width: "100%" }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keyword`}</Typography>

                <Input
                  allowClear
                  placeholder="Supplier Name"
                  onChange={handleInput}
                  style={{
                    width: 250,
                  }}
                />
              </Col>

              <Col
                className="search-btn-col"
                style={{
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  type="primary"
                  className="search-btn"
                  icon={<SearchOutlined />}
                  onClick={handleSearch}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <APAgingTable
            searchKey={value}
            date={dateValue ? dateValue : null}
            is_search={setSearch}
          />
        </Col>
      </Content>
    </Layout>
  );
}

// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Input,
  DatePicker,
  Space,
  Button,
  Typography,
} from "antd";

// Import React Icons Components
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiCheckCircle, FiEdit, FiXCircle } from "react-icons/fi";
import { SearchOutlined } from "@ant-design/icons";

// Import Page Components
import ArticlePriceModal from "../../../../Modals/Purchasing/PurchaseOrder/ModalArticlePrice";
import MasterSupplierByArticleId from "../../../../Reusable/Master/Supplier/MasterSupplierByArtID";
import WarehouseList from "../../../../Reusable/Inventory/Receiving/WarehouseList";

// CODE
export default function PRDetailModalTable(props) {
  // PROPS
  const {
    dataDetail,
    dataEdit,
    getSelectedPRDetail,
    getSelectedSupplier,
    params,
    isEdit,
  } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();

  const [select, setSelect] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedArticleId, setSelectedArticleId] = useState(0);

  // Boolean
  // Loadings, Modals, Loading
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalArticlePriceOpen, setModalArticlePriceOpen] = useState(false);

  // Use Reff
  const searchInput = useRef(null);

  // USE FORM
  const [form] = Form.useForm();

  // Tax Params
  const _tax = params?.tax_percentage / 100;

  // Fetch Data
  const fetchData = async () => {
    // await
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("Table Draft: ", params);
    console.log("Data Detail: ", dataDetail);

    let newData = dataDetail.filter((e, idx) => {
      // for render the table data only. the removed data (remaining_quantity_dummy == 0) still inside the state
      if (e.remaining_quantity_dummy > 0) {
        return e;
      }
    });

    if (dataDetail?.length > 0 && !cleanUp) {
      setData(newData);
      setSelect(null);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail, params]);

  const isEditing = (record, index) => index === editingKey;

  // SET FIELDS EDIT
  const editData = (record, index) => {
    setEditingKey(index);

    form.setFieldsValue({
      quantity: "",
      id_supllier: "",
      id_supllier_article: "",
      ...record,
    });
  };

  //
  const save = async (e, x) => {
    let index = e;
    let artName = x;
    try {
      const row = await form.validateFields();
      const newData = [...data];
      let find = data.findIndex((v) => v.article_name == artName);
      // let compare = newData[index].unit_price !== row.unit_price;

      // const index = newData.findIndex((item) => key === item.key);
      if (find > -1) {
        console.log("Test >>> Cond 1", find);
        const item = newData[find];
        newData.splice(find, 1, {
          ...item,
          ...row,
          // ...{ price_update: compare ? true : false },
        });
        console.log(newData);
        setData(newData);
        // dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        isEdit(false);
        form.resetFields();
      } else {
        console.log("Test >>> Cond 2");
        newData.push(row);
        setData(newData);
        // dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        isEdit(false);
        form.resetFields();
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // Cancel Edit
  const cancel = () => {
    setEditingKey("");
    setEdit(false);
    isEdit(false);
  };

  // Get Suppliers
  const getSupplier = (value) => {
    console.log(value);

    let x = form.getFieldsValue();
    form.setFieldsValue({
      supplier_name: value[0].supplier_name,
      id_supplier: value[0].id_supplier,
      id_supplier_article: value[0].id_supplier_article,
      unit_price: value[0].price,
      quantity: x["quantity"],
      amount: value[0].price * x["quantity"],
    });
  };

  // Handle Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // Handle Reset
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // Handle Column Search
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex == "request_date" ? (
          <DatePicker
            ref={searchInput}
            format={"YYYY-MM-DD"}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
            }}
            onChange={(e) => {
              setSelectedKeys(e ? [moment(e).format("YYYY-MM-DD")] : []);
              console.log(e);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      //   fixed: "left",
      width: 70,
      render: (_, record, index) => {
        const editable = isEditing(record, index);

        return editable ? (
          <>
            <Row className="action-btn" gutter={20} style={{ width: "100%" }}>
              <Col span={12}>
                <FaCheckCircle
                  className="edit-btn"
                  onClick={() => {
                    save(index, record.article_name);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Col>

              <Col span={12}>
                <FaTimesCircle
                  className="delete-btn"
                  onClick={cancel}
                  style={{ fontSize: 20, color: "#F64E60", cursor: "pointer" }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Quantity" className="delete-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("EDIT ON CLICK >>>");
                      if (
                        edit != true &&
                        !select?.includes(record.id_article)
                      ) {
                        console.log("IS EDITING QTY: ", editingKey);
                        console.log("Edit: ", record);
                        console.log("Edit: ", edit);
                        editData(record, index);
                        setEdit(true);
                        isEdit(true);
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color:
                        edit != true && !select?.includes(record.id_article)
                          ? "#1BC5BD"
                          : "#d9d9d9",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      width: 150,
      render: (amount) => amount,
      ...getColumnSearchProps("article_name"),
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      editable: true,
      // render: (value, record, index) => {
      //   return record?.supplier[0].supplier_name;
      // },
      render: (amount) => amount,
    },
    {
      title: "Order Qty",
      dataIndex: "quantity",
      key: "quantity",
      editable: true,
      width: 100,
      render: (amount) => {
        return amount > 0
          ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: "Requested Qty Left",
      dataIndex: "remaining_quantity_dummy",
      key: "remaining_quantity_dummy",
      width: 100,
      render: (amount) => {
        return amount > 0
          ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: "Unit Price (Rp)",
      dataIndex: "unit_price",
      key: "unit_price",
      width: 100,
      editable: true,
      render: (amount, record) => {
        let _suppliers =
          record?.supplier?.length > 0 ? record.supplier[0] : null;
        let _ppn = _suppliers ? amount * _tax : 0;
        let _price = 0;
        let _is_ppn = _suppliers?.is_ppn === true ? true : false;

        switch (_is_ppn) {
          case true:
            // _price = _ppn + amount + "**";
            break;

          default:
            break;
        }

        return amount > 0
          ? `${_is_ppn ? amount + "**" : amount + "*"}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )
          : 0;
      },
    },
    {
      title: "Amount (Rp)",
      dataIndex: "amount",
      key: "amount",
      editable: true,
      render: (amount, record) => {
        let _suppliers =
          record?.supplier?.length > 0 ? record.supplier[0] : null;
        let _ppn = _suppliers ? amount * _tax : 0;
        let _price = amount + "*";
        let _is_ppn = _suppliers?.is_ppn === true ? true : false;

        switch (_is_ppn) {
          case true:
            _price = _ppn + amount + "**";
            break;

          default:
            break;
        }

        return amount > 0
          ? `${_is_ppn ? amount + "**" : amount + "*"}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )
          : 0;
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Item Desc",
      dataIndex: "description",
      key: "description",
      editable: true,
      render: (amount) => amount,
    },

    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // EDITABLE RENDER COMPONENTS
  const EditableCell = (props) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = props;
    const inputNode =
      inputType === "number" ? (
        <InputNumber
          placeholder="input here..."
          disabled={
            dataIndex == "unit_price" || dataIndex == "amount" ? true : false
          }
          min={0}
          max={dataIndex == "quantity" ? record.quantity : null}
          onChange={(e) => {
            if (dataIndex == "quantity") {
              let x = form.getFieldsValue();
              form.setFieldsValue({
                amount: e * x["unit_price"],
              });
            }
          }}
          className="edit-number"
        />
      ) : dataIndex == "supplier_name" ? (
        <Input
          onClick={() => {
            setModalArticlePriceOpen(true);
            setSelectedArticleId(record.id_article);
          }}
        />
      ) : (
        <Input placeholder="input here..." />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <>
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
            {dataIndex == "supplier_name" ? (
              <>
                <Form.Item label="Supplier ID" name="id_supplier" hidden>
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Supplier Article ID"
                  name="id_supplier_article"
                  hidden
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Price" name="unit_price" hidden>
                  <Input />
                </Form.Item>

                <Form.Item label="Amount" name="amount" hidden>
                  <Input />
                </Form.Item>
              </>
            ) : null}
          </>
        ) : (
          children
        )}
      </td>
    );
  };

  // Merged Columns when edit
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType:
          col.dataIndex === "supplier_name" || col.dataIndex === "description"
            ? "text"
            : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record, index),
      }),
    };
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      getSelectedPRDetail(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        disabled:
          edit == true ||
          record.quantity == (0 || null || undefined) ||
          record.supplier_name == ("" || null || undefined), //disable the first 4 rows only
      };
    },
  };

  console.log("SELECT >>>", select);

  return (
    <>
      <Form
        className="table-form"
        form={form}
        component={false}
        initialValues={{
          quantity: 0,
          unit_price: 0,
          amount: 0,
        }}
      >
        <Table
          className="pr-detail-modal-table"
          name="pr-detail-modal-table"
          key="pr-detail-modal-table"
          columns={mergedColumns}
          dataSource={data}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
            onChange(current, pageSize) {
              setPage(current);
              setPaginationSize(pageSize);
            },
          }}
          scroll={{
            x: 1200,
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => record.id_article}
        />

        <Typography className="text-notes" style={{ fontSize: 15 }}>
          {`* Prices are not subject to tax`}
        </Typography>
        <Typography className="text-notes" style={{ fontSize: 15 }}>
          {`** Prices are subject to VAT (PPN ${params?.tax_percentage}%)`}
        </Typography>
      </Form>

      <ArticlePriceModal
        is_open={modalArticlePriceOpen}
        is_close={setModalArticlePriceOpen}
        articleId={selectedArticleId}
        getSelectedSupplier={getSupplier}
        params_acc={params}
      />
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { Button, Col, Form, Row, Select, Table, Typography } from "antd";
import { ArrowRightOutlined, SyncOutlined } from "@ant-design/icons";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";
import {
  failedFetch,
  failedNonStay,
  successNonStay,
} from "../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// PARAMS
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../../API/Config/Api";

// PAGE COMPONENTS
import DynamicTables from "./DynamicTables";

export default function ListBillTable({
  returnArticleData,
  getArticleData,
  getGuestData,
  getBillReceiver,
  getReturnArticleData,
  is_search,
  searchKey,
  getType,
  is_refresh,
  // New
  type,
  source,
  bill,

  // New
  // returnArticleData,
  // getArticleData,
  // getReturnArticleData,
  // // getGuestData,
  // fetchToggleState,
  // fetchToggleStateReturn,
  // getSelectedBillData,
  // getSource,
  // getType,
  // // Search
  // is_search,
  // searchKey,
}) {
  // CONTEXT
  const { getReservation, getReservationBySource } = useContext(MainContext);

  // Data
  const [bulks, setBulks] = useState([]);
  const [data, setData] = useState([]);
  const [optionValue, setOptionValue] = useState([]);
  const [resId, setResId] = useState(null);
  const [detailData, setDetailData] = useState(null);
  // const [source, setSource] = useState(-1);
  // const [type, setType] = useState(-1);
  const [tablesData, setTablesData] = useState([]);
  // Key Reset Selector
  const [keySource, setKeySource] = useState(0);
  const [keyBill, setKeyBill] = useState(0);
  // Row Selection
  const [selectedRowArray, setSelectedRowArray] = useState({});
  const [selectedData, setSelectedData] = useState({});
  // Refresh
  const [loading, setLoading] = useState(false);
  // State for dynamic table
  const [dynamic, setDynamic] = useState([]);

  const [key, setKey] = useState(0);
  // USE FORM
  const [form] = Form.useForm();

  // Currency
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  useEffect(() => {
    console.log("Selected Data ->> ", selectedData);
  }, [selectedData]);

  // USE EFFECT FETCHING SELECTOR BILL DATA
  // useEffect(() => {
  //   let cleanUp = false;

  //   if ((source !== -1 || source?.length > 0) && type !== -1 && !cleanUp) {
  //     fetchData();
  //   } else {
  //     setOptionValue([]);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [source, type, resId]);

  // USE EFFECT FETCHING SELECTOR BILL DATA NEW
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (source >= 0 && type >= 0 && !cleanUp) {
  //     fetchData();
  //     getSource(source);
  //     getType(type);
  //   } else {
  //     setOptionValue([]);
  //   }
  // }, [source, type, resId]);

  // useEffect old selector
  // USE EFFECT FETCHING DETAIL DATA
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (type !== -1 && source !== -1 && resId && !cleanUp) {
  //     // fetchDetailData();
  //     handleRefresh();
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [type, source, resId, is_refresh]);

  // ================================== NEW =================================
  // useEffect for new selector
  useEffect(() => {
    let cleanUp = false;

    if (
      (source !== -1 || source?.length > 0) &&
      type !== -1 &&
      Object.keys(bill).length > 0 &&
      Object.values(bill).every((arr) => arr.length > 0) &&
      !cleanUp
    ) {
      const allValues = getAllValues(bill);
      const headerBill = flatBill(bill);

      setResId(allValues);
      setDetailData(headerBill);
      handleRefresh(allValues, headerBill);
    } else {
      setResId(null);
      setDetailData(null);
      handleRefresh();
    }

    return () => {
      cleanUp = true;
    };
  }, [source, type, bill, is_refresh]);
  // ==================================== NEW ===================================

  // USE EFFECT FETCHING DETAIL DATA NEW
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (resId && !cleanUp) {
  //     fetchDetailData();
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [resId, fetchToggleStateReturn]);

  // USEEFFECT RETURN ARTICLE DATA
  useEffect(() => {
    let cleanUp = false;

    if (returnArticleData && !cleanUp) {
      console.log("List Bill Receive Article ->> ", returnArticleData);

      const _returnData = addSelectedData(dynamic, returnArticleData); // new new
      console.log("Return Data ->> ", _returnData);

      setDynamic(_returnData);

      setKey(key + 1);

      // setData((state) => [...state, ...returnArticleData]); // option for check box
      // setData((state) => [...state, returnArticleData]); // option for button move action
    }

    return () => {
      cleanUp = true;
    };
  }, [returnArticleData]);

  //  FETCH DATA MASTER FOLIO BILL
  const fetchData = async () => {
    await getReservationBySource({
      reservation: "bill",
      type: "master-folio",
      // source: source,
      source: typeof source == "object" ? [...source] : [source],
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          console.log("Response Master Folio => ", _response);

          // Handle duplicate response
          const filterUniqueReservations = (data) => {
            const uniqueReservations = [];

            data.forEach((item) => {
              if (
                !uniqueReservations.some(
                  (reservation) =>
                    reservation.reservation_id === item.reservation_id
                )
              ) {
                uniqueReservations.push(item);
              }
            });

            return uniqueReservations;
          };

          const _filtered = filterUniqueReservations(_response);
          // const filterShowData = _filtered.filter((val) => val.is_show);

          const mappedOptions = _filtered.map((item) => {
            let _status = "";

            switch (item?.payment_status) {
              case 1:
                _status = "Charge To Room";
                break;
              case 2:
                _status = "Cash Basis";
                break;

              default:
                _status = "-";
                break;
            }

            return {
              label: `${item?.reservation_id} - ${item?.guest_name
                ?.replace(/\d+$/, "")
                .trim()} `,
              value: item?.reservation_id,
              reservation_id: item?.reservation_id,
              guest_id: item?.guest_id,
              guest_name: item?.guest_name,
              start_date: item?.start_date,
              end_date: item?.end_date,
              payment_status: _status,
              payment_details: item?.payment_detail,
              payment_type: item?.payment_type,
            };
          });

          setOptionValue(mappedOptions);
        } else {
          setOptionValue([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // FETCH DETAIL DATA
  const fetchDetailData = async (billValues, headerBill) => {
    setLoading(true);

    await axios
      .post(`${baseurl}/bill/master-folio-res`, {
        // reservation_id: typeof resId == "object" ? [...resId] : [resId],
        reservation_id: billValues,
        // source: source,
        source: typeof source == "object" ? [...source] : [source],
      })
      .then((response) => {
        console.log("response: ", response);
        if (response?.data?.msg) {
          const _res = response.data.msg;
          const filteredResponse = _res.filter((item) => item.is_show == false);
          // Check Join/Split
          // 1 == Split, 2 == join
          // if (type == 2) {
          //   // if join, article auto move to Bill Pay List Table
          //   setData([]);
          //   setBulks([]);
          //   getArticleData(handleManipulateAPIResponse(filteredResponse));
          // } else {
          //   setData(handleManipulateAPIResponse(filteredResponse));
          //   setBulks(handleManipulateAPIResponse(filteredResponse));
          //   getArticleData([]);
          // }

          const mappedResponse = handleManipulateAPIResponse(filteredResponse);
          // Grouping response by reservation
          const groupingResponse = groupDataByReservation(
            mappedResponse,
            headerBill
            // detailData
          );
          // first index move to Bill Pay List Table
          const firstIndex = groupingResponse[0];

          // TYPE 0 == NORMAL, 1 == SPLIT, 2 == JOIN
          if (type == 2 || type == 0) {
            getBillReceiver(firstIndex); // bill receiver
            getArticleData(firstIndex?.details); // firstIndex move to table pay list
            // Set sisa data untuk table Bill List
            setDynamic(groupingResponse.filter((val, i) => i !== 0));
          } else {
            getBillReceiver(firstIndex);
            setDynamic(groupingResponse);
          }
        } else {
          setDynamic([]);
          getBillReceiver([]);
          // setData([]);
          // setBulks([]);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // HANDLE MANIPULATE API RESPONSE
  const handleManipulateAPIResponse = (array) => {
    const result = [];

    array.forEach((item) => {
      const key = `${item.article_id}-${item.date}`;
      const existing = result.find((entry) => entry.key === key);

      if (existing) {
        existing.price += item.price;
      } else {
        result.push({
          key: key,
          id: generateUniqueKey(),
          article_id: item.article_id,
          article_name: item.article_name,
          reservation_id: item.reservation_id,
          price: item.price,
          quantity: item.quantity,
          date: item.date,
          is_show: item.is_show,
        });
      }
    });

    console.log(
      "Data ->> ",
      result.map(({ key, ...rest }) => rest)
    );

    return result.map(({ key, ...rest }) => rest);

    // setData(newResponse);
    // setBulks(newResponse);
  };

  // HANDLE GROUPING DATA API RESPONSE FOR CREATE TABLE
  const groupDataByReservation = (dataSource, header) => {
    // Iterasi melalui header dan tambahkan properti details
    console.log(
      "GROUPING DATA ->> ",
      header.map((item) => {
        const details = dataSource.filter(
          (data) => data.reservation_id === item.reservation_id
        );
        return {
          ...item,
          details: details,
        };
      })
    );

    return header.map((item) => {
      const details = dataSource.filter(
        (data) => data.reservation_id === item.reservation_id
      );

      // if (details?.length > 0) {
      return {
        ...item,
        details: details,
      };
      // }
    });
  };

  // GET ALL VALUE (RESERVATION ID)
  const getAllValues = (billData) => {
    let result = [];

    // Loop melalui setiap kategori di dalam billData
    for (let key in billData) {
      if (billData.hasOwnProperty(key)) {
        // Ambil array dari tiap kategori
        const categoryArray = billData[key];

        // Loop melalui setiap item dalam array dan ambil property 'value'
        categoryArray.forEach((item) => {
          result.push(item?.value);
        });
      }
    }

    return result;
  };

  // FLAT MAP BILL
  const flatBill = (billData) => {
    let result = [];

    // Loop melalui setiap kategori di dalam billData
    for (let key in billData) {
      if (billData.hasOwnProperty(key)) {
        // Gabungkan array dari setiap kategori ke dalam array result
        result = result.concat(billData[key]);
      }
    }

    return result;
  };

  // GENERATE UNIQUE KEY FOR OPTION VALUE DATA
  const generateUniqueKey = () => {
    const timestamp = Date.now();
    const randomNum = Math.random().toString(36).substring(2, 10);

    return `key_${timestamp}_${randomNum}`;
  };

  // HANDLE CHANGE SELECTOR
  const handleChange = (event, allKeys) => {
    console.log("Event ->> ", event);
    console.log("Allkeys ->> ", allKeys);

    // setSelectorValue(allKeys);

    // setData(allKeys?.detail);
    // setBulks(allKeys?.detail);
    getGuestData(allKeys);
    setDetailData(allKeys?.length > 0 ? allKeys : [allKeys]);
    setResId(event);
    getArticleData([]);
  };

  // HANDLE CHANGE BILL SELECTOR NEW
  // const handleChange = (event, allKeys) => {
  //   console.log("Event ->> ", event);
  //   console.log("Allkeys ->> ", allKeys);

  //   // setSelectorValue(allKeys);

  //   // setData(allKeys?.detail);
  //   // setBulks(allKeys?.detail);
  //   // getGuestData(allKeys);
  //   setDetailData(allKeys);
  //   setResId(event);
  //   getSelectedBillData(allKeys);
  //   // getArticleData([]);
  // };

  // HANDLE CLEAR SELECTOR BILL
  const handleClear = () => {
    setDynamic([]);

    setData([]);
    setBulks([]);
    getArticleData([]);
    setDetailData({
      guest_name: "",
      start_date: "",
      end_date: "",
      payment_type: "",
      payment_details: "",
      payment_status: "",
    });
  };

  // HANDLE CLEAR NEW
  // const handleClear = () => {
  //   setData([]);
  //   setBulks([]);
  //   // getArticleData([]);
  //   getSelectedBillData(null);
  //   setDetailData({
  //     guest_name: "-",
  //     start_date: "-",
  //     end_date: "-",
  //     payment_status: "-",
  //     payment_details: "-",
  //     payment_type: "-",
  //   });
  // };

  // HANDLE REFRESH
  const handleRefresh = (billValues, headerBill) => {
    getArticleData([]);
    getReturnArticleData(null);

    if (billValues && headerBill) {
      fetchDetailData(billValues, headerBill);
    } else {
      setDynamic([]);
      getBillReceiver([]);
    }
  };

  // ADD SELECTED DATA FROM TABLE BILL PAY LIST TO BILL LIST
  const addSelectedData = (sourceData, selectedData) => {
    // Loop melalui setiap elemen di selectedData
    selectedData.forEach((selectedItem) => {
      // Cari elemen di sourceData yang memiliki reservation_id yang sama dengan selectedItem
      const target = sourceData.find(
        (sourceItem) =>
          sourceItem.reservation_id === selectedItem.reservation_id
      );

      // Jika ditemukan elemen yang sesuai, tambahkan selectedItem ke dalam details-nya
      if (target) {
        target.details.push(selectedItem);
      }
    });

    return sourceData;
  };

  // REMOVE SELECTED DATA FROM TABE BILL LIST WHEN MOVE BTN CLICKED
  const removeSelectedData = (sourceData, selectedData) => {
    // Loop melalui setiap elemen di selectedData
    selectedData.forEach((selectedItem) => {
      // Loop melalui setiap elemen di sourceData
      sourceData.forEach((sourceItem) => {
        // Filter elemen details yang tidak ada di selectedData
        sourceItem.details = sourceItem.details.filter(
          (detail) => detail.id !== selectedItem.id
        );
      });
    });

    return sourceData;
  };

  // FLATMAP SELECTED DATA
  const mergeData = (data) => {
    const keys = Object.keys(data);
    const result = keys.flatMap((key) => data[key]);
    return result;
  };

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray((prev) => [...prev, ...selectedRowKeys]);
      setSelectedData((prev) => [...prev, ...selectedRows]);

      // handlePick(selectedRowKeys);
    },
  };

  // HANDLE MOVE DATA (CheckBox Option)
  const handlePick = () => {
    // let sourceData = data;

    // // Filter Source Data
    // const filteredData = sourceData.filter(
    //   (sourceItem) =>
    //     !selectedData.some((selectedItem) => selectedItem.id === sourceItem.id)
    // );

    // // Set Data on Table Bill List by FilteredData
    // setData(filteredData);
    // setBulks(filteredData);
    // // Set Data on Table Bill Pay List by Selected Data
    // getArticleData((state) => [...state, ...selectedData]);
    // // Reset Selected Data & Selected Row Array
    // setSelectedData([]);
    // setSelectedRowArray([]);

    //////////////////////////////// NEW FUNCTION /////////////////////////
    const _mappedData = mergeData(selectedData); // selected data from check box
    const sourceData = dynamic; // source data
    // filter Source Data
    const filteredData = removeSelectedData(sourceData, _mappedData);
    // // Set Data on Table Bill List by FilteredData
    setDynamic(filteredData);
    // // Set Data on Table Bill Pay List by Selected Data
    getArticleData((state) => [...state, ..._mappedData]);
    // // Reset Selected Data & Selected Row Array
    setSelectedData({});
    setSelectedRowArray({});
  };

  // HANDLE MOVE (Action Button Move Option)
  const handleMove = (record) => {
    const filtered = data.filter((item) => item.id !== record.id);

    setData(filtered);
    setBulks(filtered);
    // getReturnArticleData(filtered);
    getArticleData((state) => [...state, record]);
  };

  // TYPE OPTIONS
  const groupOptions = [
    {
      label: "INDIVIDUAL",
      value: 1,
    },
    {
      label: "GROUP",
      value: 2,
    },
  ];

  // TYPE OPTIONS
  const typeOptions = [
    {
      label: "NORMAL BILL",
      value: 0,
    },
    {
      label: "SPLIT BILL",
      value: 1,
    },
    {
      label: "JOIN BILL",
      value: 2,
    },
  ];

  // TYPE OPTIONS
  const sourceOptions = [
    {
      label: "RESERVATION",
      value: 1,
    },
    {
      label: "MICE",
      value: 2,
    },
    {
      label: "OUTLET",
      value: 3,
    },
  ];

  // HANDLE CHANGE SELECTOR SOURCE
  const handleChangeSource = (event, allKeys) => {
    console.log("Event Source ->> ", event);
    console.log("allKeys Source ->> ", allKeys);

    // setSource(event);

    if (resId) {
      handleClearSource();
    }
  };

  // HANDLE CLEAR SOURCE
  const handleClearSource = () => {
    handleClear();
    // change key for re-render bill
    setKeyBill(keyBill + 1);
    // reset bill selector
    setResId(null);
  };

  // HANDLE CHANGE TYPE
  const handleChangeType = (event, allKeys) => {
    // console.log("Event Type ->> ", event);
    // console.log("allKeys Type ->> ", allKeys);

    // setType(event);
    getType(event);

    if (source !== -1 && resId) {
      handleClearType();
    }
  };

  // HANDLE CLEAR TYPE
  const handleClearType = () => {
    handleClear();
    // change key for re-render
    setKeySource(keySource + 1);
    setKeyBill(keyBill + 1);
    // reset source and bill
    // setSource(-1);
    setResId(null);
  };

  // HANDLE DISABLED MOVE BUTTON
  const isButtonDisabled =
    Object.keys(selectedData).length === 0 ||
    Object.values(selectedData).every((arr) => arr.length === 0);

  return (
    <>
      <Row
        className="master-folio-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-master-folio" style={{ padding: 30 }}>
          {/* <Row
            className="row-selector-btn"
            justify="end"
            style={{ width: "100%" }}
          >
            <Col span={8}>
              Select Type:
              <Select
                style={{
                  width: "100%",
                }}
                onChange={handleChangeType}
                options={typeOptions}
                allowClear
                placeholder="Type"
                showSearch
                onClear={handleClearType}
              />
            </Col>

            <Col span={8} style={{ paddingLeft: "10px" }}>
              Select Source:
              <Select
                key={keySource} // key untuk paksa re-render
                style={{
                  width: "100%",
                }}
                mode={type == 2 ? "multiple" : null}
                onChange={handleChangeSource}
                options={sourceOptions}
                allowClear
                placeholder="Source"
                showSearch
                onClear={handleClearSource}
              />
            </Col>

            <Col span={8} style={{ paddingLeft: "10px" }}>
              Select Bill:
              <Select
                key={keyBill} // key untuk paksa re-render
                style={{
                  width: "100%",
                }}
                mode={type == 2 ? "multiple" : null}
                onChange={handleChange}
                options={optionValue}
                allowClear
                placeholder="Bill"
                showSearch
                onClear={handleClear}
              />
            </Col>
          </Row> */}

          {/* <Row
            className="row-modal-btn"
            // justify="end"
            style={{ width: "100%", marginTop: 10 }}
          >
            <Col span={8}>
              Select Type:
              <Select
                style={{
                  width: "100%",
                }}
                // onChange={handleChangeType}
                options={groupOptions}
                allowClear
                placeholder="Type"
                showSearch
                onClear={handleClearType}
              />
            </Col>
            <Col span={8}>
              Select Type:
              <Select
                style={{
                  width: "100%",
                }}
                onChange={handleChangeType}
                options={typeOptions}
                allowClear
                placeholder="Type"
                showSearch
                onClear={handleClearType}
              />
            </Col>

            <Col span={12}>
              Select Source:
              <Select
                key={keySource} // key untuk paksa re-render
                style={{
                  width: "100%",
                }}
                mode={type == 2 ? "multiple" : null}
                onChange={handleChangeSource}
                options={sourceOptions}
                allowClear
                placeholder="Source"
                showSearch
                onClear={handleClearSource}
              />
            </Col>

            <Col span={12} style={{ paddingLeft: "10px" }}>
              Select Bill:
              <Select
                key={keyBill} // key untuk paksa re-render
                style={{
                  width: "100%",
                }}
                mode={type == 2 ? "multiple" : null}
                onChange={handleChange}
                options={optionValue}
                allowClear
                placeholder="Bill"
                showSearch
                onClear={handleClear}
              />
            </Col>

            <Col span={8}>
              <Row className="row-modal-btn" justify="end">
                <Button
                  className="refresh-btn"
                  // onClick={handleRefresh}
                  onClick={() => (resId ? handleRefresh() : null)}
                  type="default"
                >{`Refresh`}</Button>
              </Row>
            </Col>
          </Row> */}

          {/* <Divider
            className="divider-form"
            style={{ margin: "15px 0px", backgroundColor: "#d9d9d9" }}
          /> */}

          {/* <Typography style={{ fontWeight: "bold" }}>Data Guest</Typography>

          <Row
            className="row-modal-btn"
            justify="end"
            style={{ width: "100%" }}
          >
            <Col span={12}>
              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Guest Name:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.guest_name?.replace(/\d+$/, "").trim() || "-"}
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Start Date:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.start_date &&
                    detailData?.start_date !== "Invalid date"
                      ? moment(detailData?.start_date).format("DD MMMM YYYY")
                      : "-"}
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>End Date:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.end_date &&
                    detailData?.end_date !== "Invalid date"
                      ? moment(detailData?.end_date).format("DD MMMM YYYY")
                      : "-"}
                  </Typography>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Payment Type:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.payment_type || "-"}
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography>Payment Details:</Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.payment_details || "-"}
                  </Typography>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12}>
                  <Typography style={{ paddingTop: "5px" }}>
                    Payment Status:
                  </Typography>
                </Col>
                <Col span={12} style={{ paddingTop: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {detailData?.payment_status || "-"}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px", backgroundColor: "#d9d9d9" }}
          /> */}

          <Row
            className="row-modal-btn"
            justify="end"
            // style={{ marginTop: 30 }}
          >
            <Col span={8} className="col-exp-btn">
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 750,
                }}
              >
                Bill List
              </Typography>
            </Col>

            <Col span={16}>
              <Row justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  // disabled={Object.keys(selectedData).length === 0}
                  disabled={isButtonDisabled}
                  onClick={handlePick}
                  // icon={
                  //   <PiFileArchiveFill
                  //     style={{
                  //       margin: "0px 5px 0px 0px",
                  //       fontSize: 20,
                  //     }}
                  //   />
                  // }
                  style={{
                    // display: "flex",
                    // padding: "5px 10px 10px 5px",
                    marginRight: "15px",
                    // minWidth: 100,
                    borderColor: "transparent",
                  }}
                >
                  {`Move`}
                  <ArrowRightOutlined />
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={() => (resId ? handleRefresh() : null)}
                  onClick={() => handleRefresh(resId, detailData)}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row
            className="row-table"
            style={{ marginTop: 15, width: "100%" }}
            key={key}
          >
            {/* {tablesData?.length > 0 ? tablesData : null} */}

            {dynamic?.length > 0 ? (
              <DynamicTables
                tableData={dynamic}
                isLoading={loading}
                getSelectedData={setSelectedData}
                selectedRows={selectedRowArray}
                setSelectedRows={setSelectedRowArray}
              />
            ) : null}

            {/* SINGLE TABLE */}
            {/* <Table
              className="master-folio-table"
              key="master-folio-table"
              name="master-folio-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              size="small"
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => record.id}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            /> */}
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_children } from "../../API/Global/Payload";

// Import Routes Components
import OutletPages from "../../Pages/FandB/Outlet/Outlet";
import ReportOutletPage from "../../Pages/Outlet/Report/ReportOutlet";
import PosCashierPage from "../../Pages/Outlet/Cashier/Cashier";
import NotFound from "../../Pages/NotFound/NotFound";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";
import CheckOutSuccessPage from "../../Pages/FrontOffice/CheckOut/Success";
import POSPaymentPage from "../../Pages/Outlet/Payment/POSPayment";
import POSPaymentSuccessPage from "../../Pages/Outlet/Payment/POSPaymentSuccess";
import TableAvailabilityPage from "../../Pages/Outlet/TableAvailability/TableAvailability";
import CaptainOrderPage from "../../Pages/Outlet/Order/Main/CaptainOrder";
import KitchenOrderPage from "../../Pages/Outlet/KitchenOrder/KitchenOrder"
import CloseCashierPage from "../../Pages/Outlet/CloseCashier/CloseCashier";
// import KitchenOrderPage from "../../Pages/Outlet/Kitchen/Kitchen"

// List Route
const grandRoute = [
  {
    path: "pos-cashier",
    name: "pos-cashier",
    id_modules: 59,
    element: <PosCashierPage />,
  },
  {
    path: "captain-order",
    name: "captain-order",
    id_modules: 60,
    element: <CaptainOrderPage />,
  },
  {
    path: "table-availability",
    name: "table-availability",
    id_modules: 105,
    element: <TableAvailabilityPage />,
  },
  {
    path: "kitchen-order",
    name: "kitchen-order",
    id_modules: 61,
    element: <KitchenOrderPage />,
  },
  {
    path: "report-outlet",
    name: "report-outlet",
    id_modules: 62,
    element: <ReportOutletPage />,
  },
  {
    path: "/pos-cashier/payment",
    name: "pos-payment",
    id_modules: 59,
    element: <POSPaymentPage />,
  },
  {
    path: "/table-order/payment",
    name: "pos-payment",
    id_modules: 60,
    element: <POSPaymentPage />,
  },
  {
    path: "/pos-cashier/payment/success",
    name: "pos-payment-result",
    id_modules: 59,
    element: <POSPaymentSuccessPage />,
  },
  {
    path: "/table-order/payment/success",
    name: "pos-payment-result",
    id_modules: 60,
    element: <POSPaymentSuccessPage />,
  },
  {
    path: "close-cashier",
    name: "close-cashier",
    id_modules: 59,
    element: <CloseCashierPage />,
  },
];

// CODE
export default function OutletRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<OutletPages />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}
      </Routes>
      <Outlet />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Components
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";
import MasterMainAccount from "../../../Reusable/Accounting/MainAccount/MasterCoa";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedPayType,
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
  successPayType,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Style
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function PaymentTypeTable(props) {
  // Props
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getMasterPaymentType } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  // Data Edit
  const [dataEdit, setDataEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterPaymentType({
      payment_type: "payment-type",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response.data.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);

                      setDataEdit(record);
                      setFieldsForm(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showConfirmModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "payment_type_name",
      key: "payment_type_name",
    },
    // {
    //   title: "Payment Type ID",
    //   dataIndex: "payment_type_id",
    //   key: "payment_type_id",
    //   width: 250,
    // },
    {
      title: "Code",
      dataIndex: "payment_type_code",
      key: "payment_type_code",
      width: 250,
    },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
      // width: 250,
    },
    {
      title: "Description",
      dataIndex: "payment_type_description",
      key: "payment_type_description",
    },
  ];

  // SET FIELDS FORM
  const setFieldsForm = (value) => {
    const record = value;

    form.setFieldsValue({
      payment_type_code: record?.payment_type_code,
      payment_type_name: record?.payment_type_name,
      payment_type_id: record?.payment_type_id,
      payment_type_description: record?.payment_type_description,
      departement_id: record?.departement_id,
      chart_of_account: record?.chart_of_account,

      created_by: record?.created_by,
      created_date: record?.created_date,
      modified_by: record?.updated_by,
      modified_date: record?.updated_date,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setDataEdit(null);

    setIsDelete(true);
    setIsLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log("ON FINISH: ", value);
    let content = value;
    setIsLoading(true);

    showConfirmModal(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log("FAILED: ", e);
    masterIncomplete(e);
  };

  // SHOW MODAL DELETE
  const showConfirmModal = (record) => {
    let _submitted = record;

    confirm({
      className: "modal-confirm",
      title:
        isDelete == true
          ? `Are you sure want to Delete ${_submitted.payment_type_name.toUpperCase()} from the table?`
          : `Are you sure want to ${
              edit == true ? "Update" : "add a new"
            } Payment Type of ${_submitted.payment_type_name.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete == true ? handleDelete(_submitted) : handleSubmit(_submitted);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // GET COA
  const getCoaName = (val) => {
    console.log(val);

    form.setFieldsValue({
      chart_of_account: val?.code,
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    console.log("ON OK ==> ", contentsubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/master/payment-type`,
          {
            payment_type_code: contentsubmit?.payment_type_code,
            payment_type_name: contentsubmit?.payment_type_name,
            payment_type_description: contentsubmit?.payment_type_description
              ? contentsubmit?.payment_type_description
              : null,
            // payment_type_id: contentsubmit?.payment_type_id,
            chart_of_account: contentsubmit?.chart_of_account,
            // departement_id: contentsubmit?.departement_id,
            is_transaction: "",
            liquiditas: "",

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("POST Pay Type => ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Payment Type",
          });

          form.resetFields();
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("POST Pay Type => ", error);

          masterFailedNotification(error, {
            method: 0,
            source: "Payment Type",
          });
        })
        .finally(() => {
          setLoading(false);
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/payment-type`,
          {
            payment_type_code: contentsubmit.payment_type_code,
            payment_type_name: contentsubmit.payment_type_name,
            payment_type_description:
              contentsubmit.payment_type_description || null,
            chart_of_account: contentsubmit.chart_of_account,

            updated_by: user_name,
            is_transaction: "",
            liquiditas: "",
            id: dataEdit?.id,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("UPDATE Pay Type => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Payment Type",
          });

          form.resetFields();
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("UPDATE Pay Type => ", error);
          masterFailedNotification(error, {
            method: 1,
            source: "Payment Type",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log(contentdelete);

    let obj = {
      ["id"]: contentdelete?.id > 0 ? contentdelete.id : 0,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/master/payment-type`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Pay Type => ", response);

        masterSuccessNotification(response, {
          method: 2,
          source: "Payment Type",
        });
        fetchData();
      })
      .catch((error) => {
        console.log("DEL Pay Type => ", error);

        masterFailedNotification(error, { method: 2, source: "Payment Type" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);

    downloadExcel(data, "Payment Type List");
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.payment_type_name.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="payment-type-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-modal-btn" gutter={[0, 15]} align="top">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="refresh-col"
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Payment Type`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="pay-type-table"
              key="pay-type-table"
              name="pay-type-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-pay-type-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Payment Type`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Payment Type`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="pay-type-modal-form"
          name="pay-type-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-pay-type-id">
            <Col span={12}>
              <Form.Item
                label="Payment Type Code"
                name="payment_type_code"
                rules={[
                  {
                    required: true,
                    message: "Please, input Payment Type Code!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Payment Type Code" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Payment Type Name"
                name="payment_type_name"
                rules={[
                  {
                    required: true,
                    message: "Please, input Payment Type Name!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Payment Type Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-type-coa">
            <Col span={16}>
              <Form.Item
                label="COA"
                name="chart_of_account"
                rules={[
                  {
                    required: true,
                    message: "Please, input COA!",
                  },
                ]}
                style={{
                  width: 440,
                  marginRight: 30,
                }}
              >
                <MasterMainAccount
                  coa_name={edit ? dataEdit?.chart_of_account : ""}
                  disable={false}
                  getCoaName={getCoaName}
                  is_header={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-pay-type-desc">
            <Col span={16}>
              <Form.Item label="Description" name="payment_type_description">
                <Input.TextArea
                  showCount
                  maxLength={200}
                  allowClear
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "10px 0px ", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-pay-type" gutter={30}>
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 10px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 15px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

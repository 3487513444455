// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// REACT ICONS
import { FiEdit } from "react-icons/fi";

// NOTIFICATIONS
import { failedFetch } from "../../Reusable/Notification/Notification";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

export default function GuestAccountBalanceModal(props) {
  // PROPS
  const { dataDetail, openModal, closeModal, is_refresh } = props;

  // CONTEXT
  const { getByPostNightAudit } = useContext(MainContext);

  // Data
  const [data, setData] = useState([]);
  // Boolean State
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Params
  const currency = CurrencySymbols().code;

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (dataDetail && !cleanUp) {
      console.log("Data Detail:", dataDetail);
      setFormFields(dataDetail);
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataDetail]);

  const setFormFields = (data) => {
    form.setFieldsValue({
      reservation_id: `${data?.reservation_id} - ${data?.room_category} - ${data?.room_number}`,
      guest_name: data?.guest_name,
      // room_category: data?.room_category,
      // room_number: data?.room_number,
      adult: data?.adult,
      child: data?.child,
      infant: data?.infant,
      deposit: data?.deposit,
    });
  };

  // FETCH DATA N/A GUEST ACCOUNT DETAIL
  const fetchData = () => {
    setLoading(true);

    getByPostNightAudit({
      type: "detail-guest-account-balance",
      reservation_id: dataDetail?.reservation_id,
      price_id: dataDetail?.price_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          // handleFilter(_response);
          setData(_response);
          // dataGuestAccount(_response);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // Handle Close
  const handleClose = () => {
    setOpen(false);
    closeModal();
    setIsLoading(false);

    form.resetFields();
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sortDirections: ["descend", "ascend"],
      render: (date, record) => {
        return date !== null || date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (qty) => {
        return qty ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Nett (${currency})`,
      dataIndex: "nett",
      key: "nett",
      render: (nett) => {
        return nett ? `${nett}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Services (${currency})`,
      dataIndex: "service",
      key: "service",
      render: (services) => {
        return services
          ? `${services}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: `Tax (${currency})`,
      dataIndex: "tax",
      key: "tax",
      render: (tax) => {
        return tax ? `${tax}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Gross (${currency})`,
      dataIndex: "gross",
      key: "gross",
      render: (gross) => {
        return gross ? `${gross}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ];

  return (
    <>
      <Modal
        className="guest-account-modal"
        key="guest-account-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Guest Account Balance Detail`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={open}
        closable
        onCancel={handleClose}
        width={1100}
      >
        <Form
          className="guest-account-form"
          key="guest-account-form"
          name="guest-account-form"
          layout="vertical"
          form={form}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="form-row" gutter={30}>
                <Col span={8} className="form-col">
                  <Form.Item label="Reservation" name="reservation_id">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Guest Name" name="guest_name">
                    <Input disabled />
                  </Form.Item>
                </Col>

                {/* <Col span={8} className="form-col">
                  <Form.Item label="Room Category" name="room_category">
                    <Input disabled />
                  </Form.Item>
                </Col> */}
                <Col span={8} className="form-col">
                  <Form.Item label="Deposit" name="deposit">
                    <InputNumber
                      addonBefore={`${currency}`}
                      style={{ width: "100%" }}
                      formatter={(deposit) => {
                        return `${deposit}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="form-row" gutter={30}>
                {/* <Col span={8} className="form-col">
                  <Form.Item label="Room Number" name="room_number">
                    <Input disabled />
                  </Form.Item>
                </Col> */}

                <Col span={8} className="form-col">
                  <Form.Item label="Adult" name="adult">
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(adult) => {
                        return `${adult}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Child" name="child">
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(child) => {
                        return `${child}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Infant" name="infant">
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(infant) => {
                        return `${infant}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row className="form-row" gutter={30}>
                <Col span={8} className="form-col">
                  <Form.Item label="Infant" name="infant">
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(infant) => {
                        return `${infant}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Deposit" name="deposit">
                    <InputNumber
                      addonBefore={`${currency}`}
                      style={{ width: "100%" }}
                      formatter={(deposit) => {
                        return `${deposit}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Divider
                className="divider-form"
                orientation="left"
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Detail`}
              </Divider>

              <Row className="table-row" justify="start">
                <Col span={24} className="table-col">
                  <Table
                    className="guest-account-table"
                    name="guest-account-table"
                    key="guest-account-table"
                    loading={loading}
                    bordered
                    columns={columns}
                    dataSource={data}
                    size="small"
                    pagination={
                      false
                      //   {
                      //   defaultPageSize: 5,
                      //   pageSizeOptions: [10, 20, 50, 100],
                      //   showSizeChanger: true,
                      //   showTotal: (total, range) =>
                      //     `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      // }
                    }
                    scroll={{
                      x: true,
                    }}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "odd" : "even";
                    }}
                    rowKey={(record) =>
                      record.id ? record.id : record.department_id
                    }
                    summary={() => {
                      return (
                        <>
                          <Table.Summary fixed>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={7}>
                                <b
                                  style={{ alignSelf: "end" }}
                                >{`TOTAL TRANSACTIONS`}</b>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell index={0}>
                                <b>
                                  {`${dataDetail?.total_trx}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </b>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </Table.Summary>
                        </>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { PrinterOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Row, Space, Typography } from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// GLOBAL STYLES
import { cardHeader, mainBody } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import PnLMainReportTable from "../../../../Table/Reports/Accounting/Monthly/ProfitnLoss/Main/Table";

export default function PnLMainReportLayout() {
  // STATE
  const [date, setDate] = useState(moment());
  const [isPrinted, setIsPrinted] = useState(false);

  // USE REF
  const componentRef = useRef();

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE DATE
  const handleDate = (e) => {
    setDate(e);
  };

  // HANDLE RETURN
  const move = () => {
    navigate(
      "/report/accounting/monthly-accounting-report/profit-n-loss-report"
    );
  };

  // HANDLE PRINT
  const handlePrint = (e) => {
    console.log("Print! ", e);

    setIsPrinted(true);

    setTimeout(() => {
      handlePrintOut();
    }, 50);

    setTimeout(() => {
      setIsPrinted(false);
    }, 1000);
  };

  // HANDLE REACT PRINT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: ` INCOME STATEMENT CONSOLIDATION REPORT - ${moment(
      date
    ).format("DD-MM-YYYY")}`,
    onPrintError: (error) => {
      console.log("Print Error: ", error);
    },
  });

  return (
    <>
      <Row className="row-body" justify="start" style={mainBody}>
        <Col span={24} className="pnl-main-layout">
          <Row
            className="row-search-component"
            justify="space-between"
            style={cardHeader}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Pick a Date`}
                </Typography>

                <DatePicker
                  picker="month"
                  className="date-pick-bsr"
                  allowClear
                  placeholder="Pick a Date"
                  onChange={handleDate}
                  style={{ width: 250 }}
                />
              </Col>
            </Space>

            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="print-btn"
                  type="primary"
                  icon={
                    <PrinterOutlined
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handlePrint}
                >
                  {`Print PnL Report`}
                </Button>
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={move}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Card
            ref={componentRef}
            className="pnl-card"
            style={{ padding: 20 }}
            bordered={false}
          >
            <PnLMainReportTable is_printed={isPrinted} dataDate={date} />
          </Card>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import moment from "moment";

// Import Ant Design Components
import { Table, Typography } from "antd";

// CODE
export default function FolioTable(props) {
  // PROPS
  const { dataTable, dataTotal, bill_type, isLoading, currency } = props;

  // STATE MANAGEMENT
  // Data
  const [billList, setBillList] = useState([]);
  const [subTotal, setSubTotal] = useState({
    total: 0,
    tax: 0,
    service: 0,
    grand: 0,
  });
  // BOOLEan
  const [loading, setLoading] = useState(true);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataTable.length > 0 && !cleanUp) {
      console.log("Bill lists: ", dataTable);

      setBillList(dataTable);
    } else {
      setBillList([]);
    }

    if (dataTotal.total > 0 && !cleanUp) {
      setSubTotal({ ...dataTotal });
    } else {
      setSubTotal({
        total: 0,
        tax: 0,
        service: 0,
        grand: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [billList, dataTotal]);

  // COLUMNS
  const columns = [
    {
      title: "No.",
      dataIndex: "",
      key: "numbers",
      render: (_, rec, index) => {
        return index + 1;
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Qty`,
      dataIndex: "quantity",
      key: "quantity",
      width: 50,
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      align: "right",
      dataIndex: "total_price",
      key: "total_price",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  return (
    <>
      <Table
        className="transaction-table"
        key="transaction-table"
        name="transaction-table"
        columns={columns}
        dataSource={billList}
        size="small"
        loading={isLoading}
        pagination={false}
        scroll={{
          x: false,
        }}
        rowKey={(record) => {
          return record?.id ? record.id : record.key;
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? "odd" : "even";
        }}
        style={{
          padding: 20,
        }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary fixed>
                {bill_type ? (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={5} />

                      <Table.Summary.Cell index={5}>
                        <b>Subtotal</b>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={6} align="right">
                        {`${currency} ${
                          subTotal.total > 0 ? subTotal.total : 0
                        }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={5} />

                      <Table.Summary.Cell index={5}>
                        <b>Service</b>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={6} align="right">
                        {`${currency} ${subTotal?.service}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={5} />

                      <Table.Summary.Cell index={5}>
                        <b>Tax</b>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={6} align="right">
                        {`${currency} ${subTotal?.tax}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                ) : null}

                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={5} />

                  <Table.Summary.Cell index={5}>
                    <b style={{ fontSize: 15 }}>Grand Total</b>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={6} align="right">
                    <b style={{ fontSize: 15 }}>
                      {`${currency} ${
                        subTotal.grand > 0 ? subTotal.grand : 0
                      }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            </>
          );
        }}
      />

      <Typography className="text-notes" style={{ fontSize: 10 }}>
        *notes: please, keep this invoice as your payment's warranty!
      </Typography>

      {bill_type ? null : (
        <Typography className="text-notes" style={{ fontSize: 10 }}>
          *notes: all prices shown are already include tax & service
        </Typography>
      )}
    </>
  );
}

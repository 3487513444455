// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdDoorSliding } from "react-icons/md";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import RateCodeTable from "../../../Components/Table/Master/RateCode/Table";
import RateRoomCatTable from "../../../Components/Table/Master/RateRoomCatTable/Table";
import RateRoomNoTable from "../../../Components/Table/Master/RateRoomNumber/Table";

// Import Master Components
import MasterSegment from "../../../Components/Reusable/Master/Segment/Segment";
import MasterSubSegment from "../../../Components/Reusable/Master/Segment/SubSegment";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// NOTIFICATIONS
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// CODE
export default function RateCodePage() {
  // CONTEXT
  const { getMasterRateCode, getMasterRateSegment } = useContext(MasterContext);

  // STATE MANAGAMENT
  // DATA
  const [data, setData] = useState([]);
  // Segment
  const [segmented, setSegmented] = useState(null);
  //   {
  //   main_id: 0,
  //   sub_id: 0,
  // });
  const [segmentation, setSegmentation] = useState(null);
  //   {
  //   main_segment: "",
  //   sub_segment: "",
  // });
  // Search Key
  const [value, setValue] = useState("");
  // Search
  const [search, setSearch] = useState(false);
  const [find, setFind] = useState(false);
  // Rate Room Category
  const [rateRoom, setRateRoom] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isRender, setIsRender] = useState(false);

  // FETCH DATA
  // const fetchData = async () => {
  //   console.log("Segment ID = ", segmented);
  //   console.log("Segment Name = ", segmentation);

  //   await getMasterRateSegment({
  //     segment_compl: "master-segment",
  //     segment: segmented?.main_id > 0 ? segmented.main_id : 0,
  //     // sub_segment_id: segmented?.sub_id > 0 ? segmented.sub_id : 0,
  //     onAwait: () => {
  //       setLoading(true);
  //       setSearch(true);
  //       setFind(false);
  //     },
  //     onSuccess: (response) => {
  //       console.log("Rate Code => ", response);

  //       if (response?.data?.msg?.length > 0) {
  //         let filtered = response.data.msg.filter((filt) => {
  //           if (filt.is_deleted == false || filt.is_deleted == null) {
  //             return filt;
  //           }
  //         });

  //         setData(filtered);
  //       } else {
  //         setData([]);
  //       }

  //       setLoading(false);
  //       setSearch(false);
  //     },
  //     onReject: (error) => {
  //       console.log("Error = ", error);

  //       setLoading(false);
  //       failedFetch(error);
  //       setSearch(false);
  //     },
  //   });
  // };

  // Handle Change
  const handleSearch = async (val) => {
    // val.preventDefault();
    // setValue(val.target.value);

    console.log("Search this segmentations: ", segmentation);

    setSearch(true);
    // setIsRender(true);
    // await fetchData();
  };

  // Handle Refresh
  const handleRefresh = async (val) => {
    const bool = val;

    console.log("Render --> ", bool);
    setRateRoom(null);
    setValue(null);
    setIsRender(bool);
  };

  // Get Rate Code
  const getRateCode = async (value, segment_name) => {
    const rate = {
      ...value[0],
      segmentName: segment_name,
    };

    console.log("To Room Category --> ", rate);

    setValue(rate);
    setFind(true);
  };

  // Get Rate Room Category
  const getRateRoom = (value) => {
    console.log(value);
    setRateRoom(value);
  };

  // Get Segment
  const getSegment = (val, id, code) => {
    console.log("ID --> ", val, id, code);
    console.log("Is Search --> ", search);

    let segm = val;
    let segm_id = id > 0 ? id : 0;
    let segm_code = code;
    // console.log("Segment --> ", segm);

    setSegmentation({
      main_segment_id: segm_id,
      main_segment_code: segm_code,
      main_segment_name: segm,
    });

    // setSegmentation({
    //   main_segment: segm,
    //   sub_segment: segmentation?.sub_segment || "",
    // });
  };

  // Get Sub Segment
  const getSubSegment = (val, id) => {
    let subSegm = val;
    let segm_id = id;
    // console.log("Sub Segment --> ", subSegm);
    // console.log("Sub Segment ID --> ", segm_id);

    setSegmented({
      sub_id: segm_id,
      main_id: segmented?.main_id || 0,
    });

    setSegmentation({
      sub_segment: subSegm,
      main_segment: segmentation?.main_segment || "",
    });
  };

  // console.log(segmented);
  // console.log(segmentation);

  return (
    <Layout>
      <Content>
        <Col span={24} className="room-rate-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <MdDoorSliding
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Rate Code List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                span={8}
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 150,
                  maxWidth: 440,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Main Segment`}</Typography>

                {/* <Input
                allowClear
                placeholder="Rate Code Name"
                onChange={handleSearch}
                style={{ width: 300 }}
              /> */}
                <MasterSegment getSegment={getSegment} />
              </Col>

              {/* 
            <Col
              span={8}
              style={{
                textAlign: "start",
                margin: "22px 30px",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>Sub Segment</Typography>

              <Input
                allowClear
                placeholder="Rate Code Name"
                onChange={handleSearch}
                style={{ width: 300 }}
              />
              <MasterSubSegment getSubSegment={getSubSegment} />
            </Col>
            */}

              <Col
                className="search-btn-col"
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  type="primary"
                  className="search-btn"
                  icon={<SearchOutlined />}
                  onClick={handleSearch}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          {/* <Row className="row-rate-table" style={{ margin: "0px 0px 30px" }}> */}
          <RateCodeTable
            getRateCode={getRateCode}
            is_fetch={setSearch}
            // dataRate={data?.length > 0 ? data : null}
            segmentation={search == true ? segmentation : null}
            // segmentId={segmented}
            // is_rerender={handleRefresh}
          />
          {/* </Row> */}

          {/* <Row
            // gutter={[30, 30]}
            className="row-rate-table"
          >
            <Col className="col-table" span={24}>
              <RateRoomCatTable
                getRateCode={find ? value : null}
                getRateRoom={getRateRoom}
                is_render={isRender}
                is_rerender={setIsRender}
              />
            </Col>
          </Row> */}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiOpenBook } from "react-icons/gi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import ArticleMainGroupTable from "../../../Components/Table/Master/ArticleMainGroup/Table";
import MasterDepartment from "../../../Components/Reusable/Master/Department/Department";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function ArticleMainGroupPage() {
  // STATE MANAGEMENT
  // Search Key
  const [value, setValue] = useState(null);
  const [dept, setDept] = useState({
    department_id: 0,
    department_name: "",
  });
  // Search
  const [search, setSearch] = useState("");

  // Handle Department
  const handleDept = (value) => {
    setDept({
      department_id: value?.dept_id,
      department_name: value?.name,
    });
  };

  // Handle Search
  const handleSearch = (e) => {
    e.preventDefault();

    setValue(e.target.value);
  };

  // console.log("Department Code ==>> ", deptCode);

  return (
    <Layout>
      <Content>
        <Col span={24} className="article-main-group-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <GiOpenBook
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Article Main-Group List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            // gutter={30}
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              {/* <Col
                // span={8}
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  width: 250,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Search by Department Name`}
                </Typography>

                <MasterDepartment
                  getDepartment={handleDept}
                  key={"master-search-dept"}
                />
              </Col> */}

              <Col
                // span={8}
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Name of Article Main-Group"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                span={4}
                style={{
                  // margin: "54px 30px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  type="primary"
                  className="search-btn"
                  icon={<SearchOutlined />}
                  onClick={search}
                  // style={{
                  //   width: 200,
                  // }}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <ArticleMainGroupTable
            searchKey={value}
            // deptKey={dept.department_id}
            is_search={setSearch}
          />
        </Col>
      </Content>
    </Layout>
  );
}

// REACT COMPONENTS
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Select, Space, Typography, DatePicker, Form, } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaKitchenSet } from "react-icons/fa6";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import MasterDepartment from "../../../../../../Components/Reusable/Master/Department/Department";
import InterKitchenTransferReportTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/OutgoingStock/InterKitchenTransfer/Table";

export default function InterKitchenTransferReportPage() {
  // STATE
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fromDept, setFromDept] = useState([null]);
  const [toDept, setToDept] = useState(null);
  const [type, setType] = useState(null);
  const [fromDeptElement, setFromDeptElement] = useState(null);
  const [toDeptElement, setToDeptElement] = useState(null);
  const [sortingElement, setSortingElement] = useState(null);

  // USE FORM
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  //USEEFFECT CHECK
  useEffect(() => { console.log("startDate", startDate); }, [startDate]);
  useEffect(() => { console.log("endDate", endDate); }, [endDate]);
  useEffect(() => { console.log("fromDept", fromDept); }, [fromDept]);
  useEffect(() => { console.log("toDept", toDept); }, [toDept]);
  useEffect(() => { console.log("type", type); }, [type]);

  //TYPE OPTION
  const options = [
    {
      label: "From Outlet",
      value: 1,
    },
    {
      label: "To Outlet",
      value: 2,
    },
  ];

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/outgoing-stock/";

    navigate(`${path}`);
  };

  const handleDate = (value) => {
    if(value){
      setStartDate(value[0]);
      setEndDate(value[1]);
      setFromDeptElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        <Typography style={{ marginBottom: 10 }}>
          {`From Dept`}
        </Typography>

        <MasterDepartment
          getDepartment={handleFromDept}
        />
      </Col>);
    } else {
      setStartDate(null);
      setEndDate(null);
      setFromDeptElement(null);
      setFromDept(null);
      setToDeptElement(null);
      setToDept(null);
      setSortingElement(null);
      setType(null);
    }
  };

  const handleFromDept = (value) => {
    if(value.dept_id){
      setFromDept(value);
      setToDeptElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
          marginLeft: 20
        }}
      >
        <Typography style={{ marginBottom: 10 }}>
          {`To Dept`}
        </Typography>

        <MasterDepartment
          getDepartment={handleToDept}
        />
      </Col>);
    } else {
      setFromDept(null);
      setToDeptElement(null);
      setToDept(null);
      setSortingElement(null);
      setType(null);
    }
  };

  const handleToDept = (value) => {
    if(value.dept_id){
      setToDept(value);
      setSortingElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
          marginLeft: 20
        }}
      >
        <Typography style={{ marginBottom: 10 }}>
          {`Sorting By`}
        </Typography>
        <Form name="sorting_by">
          <Select
            className="sorting_by"
            key="sorting_by"
            allowClear
            showSearch
            // value={filterValue}
            onChange={handleType}
            options={options}
            placeholder="Sorting By"
            style={{
              width: 200,
            }}
          />
        </Form>
      </Col>);
    } else {
      setToDept(null);
      setSortingElement(null);
      setType(null);
    }
  };

  const handleType = (value) => {
    if(value){
      setType(value);
    } else {
      setType(null);
    }
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="incoming-stock-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaKitchenSet
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Inter Kitchen Transfer Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Date`}
                </Typography>

                <RangePicker
                  allowClear
                  // placeholder="Date"
                  className="date"
                  format="YYYY-MM-DD"
                  onChange={handleDate}
                  style={{
                    minWidth: 150,
                    maxWidth: 250,
                  }}
                />
              </Col>
              {fromDeptElement}
              {toDeptElement}
              {sortingElement}
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {startDate && endDate && fromDept && toDept && type ? (
            <InterKitchenTransferReportTable
              _startDate={startDate}
              _endDate={endDate}
              _fromDept={fromDept}
              _toDept={toDept}
              _type={type}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

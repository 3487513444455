// Import React Components
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { SearchOutlined } from "@ant-design/icons";
import { FaBoxesPacking } from "react-icons/fa6";
import { BsBoxFill } from "react-icons/bs";

// Import Page Components
import PurchaseOrderTable from "../../../../Components/Table/BackOffice/Purchasing/PurchaseOrder/Table";
import MasterDepartment from "../../../../Components/Reusable/Master/Department/Department";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// CODE
export default function PurchaseOrderPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [dept, setDept] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // MOVE
  const navigate = useNavigate();

  // HANDLE DEPARTMENT
  const getDepartment = (val) => {
    console.log(val);

    let arr = [];
    if (val.dept_id != null || val.dept_id != undefined) {
      arr.push({ department_name: val?.name, department_id: val?.dept_id });

      setDept(arr);
    } else {
      setDept(null);
    }
  };

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    const val = e.target.value;

    console.log("Search Value: ", value);

    if (val == "") {
      setValue(null);
    } else {
      setValue(val);
    }
  };

  const handleSearchOrderDate = (e) => {
    // e.preventDefault();
    if (e) {
      setOrderDate(moment(e).format("YY-MM-DD"));
    } else {
      setOrderDate(null);
    }

    console.log("HANDLE SEARCH = ", e);
  };

  // useEffect(() => {
  //   search;
  // }, [value, search]);

  // console.log(value);
  // console.log(dept);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaBoxesPacking
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Purchase Order`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={20}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 150,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`PO Number`}</Typography>

                  <Input
                    allowClear
                    placeholder="Search Purchase Order"
                    className="search-input"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                      maxWidth: 250,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Order Date`}{" "}
                  </Typography>

                  <DatePicker
                    allowClear
                    placeholder="Pick a Date"
                    onChange={handleSearchOrderDate}
                    style={{ minWidth: 150 }}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                    minWidth: 225,
                    maxWidth: 500,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Search by Department`}
                  </Typography>

                  <MasterDepartment
                    getDepartment={getDepartment}
                    department_name={null}
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                    minWidth: 200,
                    maxWidth: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <PurchaseOrderTable
              is_search={setSearch}
              searchKey={value}
              searchDept={dept}
              searchOrderDate={orderDate}
            />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

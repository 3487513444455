// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Table, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

export default function PurchaseBookReportTable(props) {
  // PROPS
  const { id_article } = props;

  //CONTEXT
  const { getPurchasingList } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  // Date
  const [datePicked, setDatePicked] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // GET DATA FUNCTION
  const fetchData = async (articleId) => {
    console.log("Article ID > ", articleId);
    setLoading(true);

    await getPurchasingList({
      type: `purchase-book/${articleId}`,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          setData(_data);
          setBulks(_data);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USEEFFECT FETCHING DATA
  useEffect(() => {
    let cleanUp = false;

    console.log("1. ID ARTICLEE >> ", id_article);
    if (id_article && !cleanUp) {
      console.log("2. id_article DATA >>> ", id_article);
      fetchData(id_article);
    } else {
      setData([]);
      setBulks([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [id_article]);

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(
      data,
      `Purchase Book List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // HANDLE FILTER
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const _orderDate = moment(val.order_date).format("YYYY-MM-DD");

      return moment(_orderDate).isBetween(_start, _end, undefined, "[]");
    });

    console.log("Filtered Date Range: ", _filtered);
    setData(_filtered);
  };

  // Columns
  const columns = [
    {
      title: "Date",
      dataIndex: "received_date",
      key: "received_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Reference",
      dataIndex: "invoice",
      key: "invoice",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
    },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: "Price (Rp.)",
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.price - b.price,
      render: (price, record) => {
        return price > 0 ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    // {
    //   title: "Quantity Request",
    //   dataIndex: "quantity_request",
    //   key: "quantity_request",
    //   render: (qty, record) => {
    //     return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    //   },
    // },
    // {
    //   title: "Quantity Received",
    //   dataIndex: "quantity_received",
    //   key: "quantity_received",
    //   render: (qty, record) => {
    //     return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    //   },
    // },
    // {
    //   title: "Quantity Broken",
    //   dataIndex: "quantity_broken",
    //   key: "quantity_broken",
    //   render: (qty, record) => {
    //     return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    //   },
    // },
    {
      title: "Quantity",
      dataIndex: "quantity_total",
      key: "quantity_total",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Total Price (Rp.)",
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.total_price - b.total_price,
      render: (total, record) => {
        return total > 0 ? `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },

    {
      title: "Delivery Note",
      dataIndex: "delivery_note",
      key: "delivery_note",
    },
  ];
  return (
    <>
      <Row className="pb-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }} className="col-table">
          <Row justify="end" className="row-btn">
            <Col span={12} className="col-export">
              <Typography
                style={{ marginBottom: 10 }}
              >{`Pick Date`}</Typography>

              <DatePicker.RangePicker
                className="date-btn"
                allowClear
                onChange={handleDate}
                // placeholder=""
                style={{
                  width: 300,
                }}
              />
            </Col>

            <Col span={12} className="col-table-btn">
              <Row justify="end" className="row-table-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<FileExcelFilled />}
                  style={{
                    fontWeight: 500,
                    margin: "0px 15px",
                  }}
                >
                  {`Export to Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => id_article && fetchData(id_article)}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table-po-main-row" style={{ width: "100%" }}>
            <Table
              className="table-po"
              name="table-po"
              key="table-po"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              size="large"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              scroll={{
                // x: 1700,
                x: 1300,
              }}
              rowKey={(record) => record.id}
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Row>
        </Col>
      </Row>

      {/* <POModalDetail
      is_open={modalOpen}
      is_close={setModalOpen}
      selectedData={dataDetail}
      params={params}
    /> */}
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// Import React Components
import { Button, Col, Divider, Form, Modal, Row, Typography } from "antd";

// Import React Icons
import { MdHistory, MdOutlineHorizontalSplit } from "react-icons/md";

// Import Page Components
import HistoryPaidBillModalTable from "../../../Table/Outlet/Order/HistoryPaidBill/TableHistoryPaidBill";
import POSReceiptBillFormTrx from "../../../Forms/POSReceiptBill/POSReceiptBillTrx";
import SplitBillModalTable from "../../../Table/Outlet/Order/SplitBill/TableSplitBill";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CODE
export default function ModalHistoryPaidBill(props) {
  // PROPS
  const { openModal, closeModal, dataHeader, dataDetail, selectedTable } =
    props;

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [headers, setHeaders] = useState(null);
  const [details, setDetails] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH Data Detail
  const fetchDataDetail = async () => {
    await axios
      .get(`${baseurl}/pos/order-detail`, {
        headers: { Authorization: `Bearer ${token ? token : null}` },
      })
      .then((response) => {
        console.log("DETAIL FOR HISTORY PAID BILL: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filter = _res.filter((e) => {
            if (e.payment_id == headers?.payment_id) {
              return e;
            }
          });

          console.log("Filter: ", filter);

          setDetails(filter);
        } else {
          setDetails([]);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    if (dataHeader && !cleanUp) {
      console.log("About Bill Header: ", dataHeader);

      setHeaders(dataHeader);
    } else {
      setHeaders(null);
    }

    if (dataDetail && !cleanUp) {
      console.log("About Bill Detail: ", dataDetail);

      setDetails(dataDetail);
    } else {
      setDetails(null);
    }

    if (selectedTable && !cleanUp) {
      console.log("About Bill Detail: ", selectedTable);

      setTableKeys(selectedTable);
    } else {
      setTableKeys(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataHeader, dataDetail, tableKeys]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = async (e) => {
    console.log("OPEN FORM HISTORY PAID BILL >>>");
    if (headers) {
      await fetchDataDetail().then(() => {
        setOpenForm(true);
      });
    }
  };

  const onFinishFailed = (e) => {};

  // console.log(details);

  return (
    <>
      <Modal
        className="split-bill-modal"
        title={
          <>
            <Row className="split-bill-modal-row">
              <MdHistory
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`POS History Paid Bill`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Row
          className="history-paid-bill-modal-form-serve-row"
          justify="start"
          gutter={[30, 30]}
          style={{ padding: 20 }}
        >
          <HistoryPaidBillModalTable
            getSelectedHeader={(e) => {
              setHeaders(e);
            }}
          />
        </Row>
      </Modal>

      <POSReceiptBillFormTrx
        openModal={openForm}
        closeModal={setOpenForm}
        payment_id={headers?.payment_id}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// BASEAPI
import {
  user_name,
  token,
  user_level,
  department,
  department_id,
  roles,
} from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";

// Import ANTD Component
import { Button, Row, Col, Table, Tooltip, Modal, Form, Tag } from "antd";

// Import React Icons Components
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import PurchaseApprovalModal from "../../../../Modals/Purchasing/Approvals/Modal";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import PurchaseRequestModal from "../../../../Modals/Purchasing/PurchaseRequest/Modal";

// Import Notification
import {
  successArticlePR,
  failedArticlePR,
  failedFetch,
} from "../../../../Reusable/Notification/Notification";
import { masterIncomplete } from "../../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// MODALS
const { confirm } = Modal;

// CODE
export default function ApprovalRequestTable(props) {
  // PROPS
  const { searchKey, request_date, is_search } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // const [bulks, setBulks] = useState([]);
  const [dataApprove, setDataApprove] = useState(null);
  // Boolean
  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    let _body = {};

    if (user_level > 3) {
      _body = {
        user_level: user_level,
      };
    } else {
      _body = {
        id_department: department_id,
        user_level: user_level,
      };
    }

    await axios
      .post(`${baseurl}/approval/purchase-request`, _body, {
        headers: { Authorization: `Bearer ${token || ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setBulks(_res);
          setData(_res);
        } else {
          setData([]);
          setBulks([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, request_date]);

  // Handle Return
  const handleReturn = () => {
    const path = "/back-office/purchasing";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryName = searchKey ? searchKey.toLowerCase() : null;
    const queryDate =
      request_date != "Invalid date" && request_date != null
        ? moment(request_date).format("YYYY-MM-DD")
        : null;

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.pr_number ? item?.pr_number.toLowerCase() : null;
        const dep = moment(item?.request_date).format("YYYY-MM-DD");

        // console.log(isNaN(Date.parse(queryDate)));

        return _name.indexOf(queryName) !== -1;
      });

      // console.log("UpdateList => ", updatedList)
      setData(updatedList);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-btn" align="middle">
              <Col span={24} className="action-col">
                <Tooltip title="Approve Request" className="edit-tooltip">
                  <CheckCircleOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setDataApprove(record);
                    }}
                    style={{
                      fontSize: 30,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "PR No.",
      dataIndex: "pr_number",
      key: "pr_number",
      width: 125,
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Approval Status",
      dataIndex: "approved_list",
      key: "approved_list",
      width: 250,
      render: (approval) => {
        if (approval?.length > 0) {
          let _tags = approval.map((value, index) => {
            // console.log("Value: ", value);
            // console.log("Index: ", _id);

            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {`Approve ${index + 1}`}
                </Tag>
              );
            } else if (value === 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`Rejected ${index + 1}`}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          // console.log(_tags);

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
        }
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approved_date",
      key: "approved_date",
      width: 250,
      render: (date, record) => {
        if (date?.length > 0) {
          let _tags = date.map((value, index) => {
            // console.log("Value: ", value);
            // console.log("Index: ", _id);

            if (value !== "-") {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {value}
                </Tag>
              );
            } else {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`-`}
                </Tag>
              );
            }
          });

          // console.log(_tags);

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`-`}</Tag>;
        }
      },
    },
    {
      title: "Receiving",
      dataIndex: "is_direct",
      key: "is_direct",
      render: (direct, record) => {
        let txt = "";
        let color = "";

        switch (direct) {
          case true:
            txt = "Direct";
            break;

          case false:
            txt = "In-Direct";

            break;

          default:
            break;
        }

        return (
          <Tag color="volcano" key={record.pr_number}>
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Notes",
      dataIndex: "approval_name",
      key: "approval_name",
      render: (app, record) => {
        let color = "";
        let txt = "";

        switch (app) {
          case "Opex":
            color = "#1890ff";
            // txt = "Capital Expenditures"
            break;

          case "Capex":
            color = "#13c2c2";
            // txt = "Operational Expenditures"
            break;

          default:
            color = "default";
            // txt = "Operational Expenditures"
            break;
        }

        return (
          <Tag color={color} key={record?.pr_number}>
            {app ? app.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
  ];

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Purchase Request");
  };

  // Handle Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
    } else {
      fetchData();
    }
  };

  // Handle Close
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              {/* <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  // onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row> */}
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  // onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="purchase-req-table"
              name="purchase-req-table"
              key="purchase-req-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record.id_pr ? record.id_pr : record.pr_number;
              }}
            />
          </Row>
        </Col>
      </Row>

      <PurchaseApprovalModal
        is_open={modalOpen}
        is_close={handleClose}
        dataPurchase={dataApprove}
        is_refresh={handleRefresh}
      />
    </>
  );
}

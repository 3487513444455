// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Checkbox, Select } from "antd";

// Import React Icons

// CODE
export default function MasterComplimentType(props) {
  // PROPS
  const { getCompliment, compl, is_disable } = props;

  // CONTEXT
  const { getMasterCompliment } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [compliment, setCompliment] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  // Checkbox
  const [checked, setChecked] = useState(true);

  // Fetch Data
  const fetchData = async () => {
    await getMasterCompliment({
      compliment: "compliment-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Compliment Status => ", response);

        // Filter
        if (response?.data?.msg?.length > 0) {
          let filtered = response?.data?.msg?.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.compliment_type,
            label: dev?.compliment_type,
            account: dev?.account_name,
            id: dev?.id,
          }));

          console.log(option);

          // setLoading(false);
          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  // useEffect(() => {
  //   fetchData();
  //
  //   if (compl != "Room Only" && compl != null) {
  //     setChecked(false);
  //   } else {
  //     setChecked(true);
  //   }
  // }, [compl]);

  // Checked Room Only
  const onCheck = (value) => {
    // console.log("Fixed Rate ??? ", value);
    setChecked(!checked);

    setCompliment("Room Only");
    getCompliment("Room Only");
  };

  // console.log(compliment);
  // console.log(optionValue);

  return (
    <>
      <Select
        className="compliment-select"
        key={"compliment"}
        disabled={is_disable}
        allowClear
        showSearch
        value={compliment != null ? compliment : compl}
        onChange={(e, allKey) => {
          setCompliment(e, allKey);
          console.log(e);

          getCompliment(e, allKey?.id, allKey);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
        style={{ width: "100%" }}
      />

      {/* <Checkbox
        checked={checked}
        onChange={onCheck}
        className="room-only"
        style={{ margin: "10px 0px", fontSize: 15 }}
      >
        Room Only
      </Checkbox> */}
    </>
  );
}

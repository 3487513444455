// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import { SyncOutlined } from "@ant-design/icons";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CODE
export default function InitialAccountingTable() {
  // PARAMS
  const currency = CurrencySymbols().code;

  // Table Columns
  const columns = [
    {
      title: "No",
      dataIndex: "",
      key: "",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Chart of Accounts",
      dataIndex: "chart_of_account",
      key: "",
    },
    {
      title: "Client Type",
      dataIndex: "client_type",
      key: "client_type",
      render: (client, record) => {
        let client_id = "";
        let clr = "";

        switch (client) {
          case 0:
            client_id = "Guest";
            clr = "geekblue";
            break;

          case 1:
            client_id = "Supplier";
            clr = "green";
            break;

          default:
            client_id = "Unknown";
            clr = "black";
            break;
        }

        return (
          <Tag key={client} color={clr} className="client-tag">
            {client_id}
          </Tag>
        );
      },
    },
    {
      title: "Client ID",
      dataIndex: "client_id",
      key: "client_id",
    },
    {
      title: "Type",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return record?.credit > 0 ? (
          <Tag color="volcano">Debts</Tag>
        ) : (
          <Tag color="cyan">Receivables</Tag>
        );
      },
    },
    {
      title: `Debit ( ${currency} )`,
      dataIndex: "debit",
      key: "debit",
      render: (debit, record) => {
        return `${debit > 0 ? debit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Credit ( ${currency} )`,
      dataIndex: "credit",
      key: "credit",
      render: (credit, record) => {
        return `${credit > 0 ? credit : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "trx_date",
      key: "trx_date",
      render: (date, record) => {
        // console.log(moment.utc(date).local().format("HH:mm:ss, DD-MM-YYYY"));

        return moment.utc(date).local().format("HH:mm:ss, DD-MM-YYYY");
      },
    },
    // {
    //   title: "",
    //   dataIndex: "created_date",
    //   key: "created_date",
    // },
  ];

  // CONTEXT
  const { getInitialTable } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // ID
  const [editData, setEditData] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Fetch Data
  const fetchData = () => {
    setLoading(true);

    getInitialTable({
      type: "initial-accounting",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Initial Data => ", response);

        let filtered = response.data.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            return filt;
          }
        });

        setData(filtered);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });

    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000);
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={12}>
              {/* <Typography
                className="text-journal-header"
                style={{
                  fontSize: 15,
                  fontWeight: 750,
                }}
              >
                {`Journal Header`}
              </Typography> */}
            </Col>

            <Col className="col-btn" span={12}>
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="journal-header-cat"
              name="journal-header-cat"
              key="journal-header-cat"
              columns={columns}
              dataSource={data}
              bordered
              loading={loading}
              size="small"
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

import { configureStore } from "@reduxjs/toolkit";
// import second from ''
import rootReducer from "./reducers";

// CODE
const store = configureStore({
  reducer: {
    rootReducer: rootReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}),
});

export default store;

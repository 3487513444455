// Import React's Component
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiBed } from "react-icons/gi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import RoomChartTable from "../../../Components/Table/FrontOffice/RoomChart/Table";

// Import Notifications
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function RoomChartPage() {
  // GET TODAY
  const today = new Date(Date.now());

  // CONTEXT
  const { getMasterRoomChart, getMasterRoom } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [chart, setChart] = useState([]);
  const [value, setValue] = useState("");
  // Date
  const [dateRange, setDateRange] = useState([]);
  const [days, setDays] = useState(3);
  // Total Count
  const [length, setLength] = useState(0);
  // Set Search
  const [isSearch, setIsSearch] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  // Alert
  const [isShow, setIsShow] = useState(false);

  // Fetch Room List
  const fetchDataRoom = async () => {
    await getMasterRoom({
      main: "main-arrival",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Room List => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((val) => {
            if (!val.is_deleted) {
              return val;
            }
            // if (val.id == 1) return val;
          });

          // console.log("Filtered = ", filtered);

          setData(filtered);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error --> ", error);
        setLoading(false);
      },
    });
  };

  // Fetch Room Chart
  const fetchRoomChart = async () => {
    await getMasterRoomChart({
      type: "chart-history",
      start_date:
        dateRange.length > 0
          ? moment(dateRange[0]).format("YYYY-MM-DD")
          : moment(today).format("YYYY-MM-DD"),
      end_date:
        dateRange.length > 0
          ? moment(dateRange[1]).format("YYYY-MM-DD")
          : moment(today).add(2, "day").format("YYYY-MM-DD"),
      onAwait: () => {
        // setLoading()
      },
      onSuccess: (response) => {
        console.log("Response Room Chart => ", response);

        if (response?.data?.msg?.length > 0) {
          setChart(response.data.msg);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error --> ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchDataRoom();
    fetchRoomChart();
  }, []);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchDataRoom();
      fetchRoomChart();

      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // COMBINED DATA
  useEffect(() => {
    let cleanUp = false;

    if (data.length > 0 && chart.length >= 0 && !cleanUp) {
      handleCombine(data, chart);
      // let merge = handleMerge(data, chart);

      // console.log("Merge ", merge);
      // setValue(merge);
    }

    return () => {
      cleanUp = true;
    };
  }, [data, chart]);

  // HANDLE COMBINE
  const handleCombine = async (room, book) => {
    const _room = room;
    const _booked = book;
    const _keyPro = "room_number";

    // Array Merged Data
    let merged = [];
    let _arr = [];

    // const result = videos.map((v) => ({
    //   ...v,
    //   ...storeProducts.find((sp) => sp.product_id === v.product_id),
    // }));

    // const _objAssign = Object.assign(_room, _booked);
    // const objAssign = (arr1, arr2) => {
    //   return arr1.map((_value) => {
    //     const _res_id = arr2.filter(
    //       (nums) => nums["room_number"] === _value["room_number"]
    //     );

    //     console.log("Numbers = ", _res_id);

    //     _value.reservation_id = _res_id.map((num) => ({
    //       : num?.reservation_id ? num.reservation_id : undefined,
    //     }));

    //     return _value;
    //   });
    // };

    Object.entries(
      _booked.reduce(function (_record, _index) {
        (_record[_index[_keyPro]] = _record[_index[_keyPro]] || []).push(
          _index
          // _booked.find((_value) => _value.room_number === _index.room_number)
        );

        // console.log("Reduce Accumulation --> ", _record);
        // console.log("Reduce Initiate --> ", _index);
        // console.log("GA GO Functions --> ", go[ga[pro]]);
        // console.log("Index Booked ", _booked[_keyPro]);

        return _record;
      }, {})
    ).forEach(([_all, _mapped]) => {
      var _newObj = {};
      var formattedData = [];

      const groupedData = _mapped;

      // const groupedData = {
      //   1: [
      //     { x: "2021-02-05", y: 1 },
      //     { x: "2021-02-05", y: 1 },
      //     { x: "2021-02-06", y: 1 },
      //   ],
      //   2: [
      //     { x: "2021-02-05", y: 1 },
      //     { x: "2021-02-06", y: 1 },
      //     { x: "2021-02-07", y: 1 },
      //     { x: "2021-02-07", y: 1 },
      //     { x: "2021-02-07", y: 1 },
      //     { x: "2021-02-08", y: 1 },
      //   ],
      // };

      // console.log("ALL VALUES = ", _all);
      // console.log("VALUE  = ", _mapped);
      // console.log("INDEX = ", _id);
      // Object.keys(groupedData).map((keys) => {
      //   console.log(groupedData);
      //   console.log(keys);

      //   var totals = groupedData[keys].reduce(function (r, o) {
      //     // r[o.x] ? (r[o.x] += o.y) : (r[o.x] = o.y);
      //     r[o.date]
      //       ? (r[o.date] += o.reservation_id)
      //       : (r[o.date] = o.reservation_id);

      //     return r;
      //   }, {});

      //   formattedData.push({ name: keys, data: totals });
      // });

      _mapped.forEach(function (_object, _idx) {
        for (var _key in _object) {
          // console.log("Key = ", _key);
          // console.log("Data = ", _object);
          // console.log("INDEX = ", _idx);

          const { id, date, reservation_id, room_number, is_step } = _object;

          // console.log(id, date, reservation_id, room_number, is_step);

          if (_newObj["room_number_1"] == 0) {
            // console.log("UNSIGNED IS HIT");
            _newObj["room_id"] = "UNASSIGNED";
            _newObj["room_category_code"] = "---";
          } else if (_key == "date") {
            // console.log("DATE IS HIT");
            _newObj[date] = reservation_id;
            _newObj[reservation_id] = { ..._object };
          } else {
            // console.log("ELSE DEFAULT IS HIT");
            _newObj[_key + "_" + (_idx + 1)] = _object[_key];
          }
        }
      });

      // console.log("New Merged Objects => ", _newObj);
      // console.log("Format Data => ", formattedData);

      // if (_room) {
      _arr.push({
        ..._newObj,
        ..._room.find((_value) => {
          if (_value.room_number == _newObj.room_number_1) {
            return _value;
          }
        }),
      });
      // }
    });

    // console.log("Merge Results --> ", merged);
    // console.log("Array Merge --> ", _arr);

    // console.log("Room Chart --> ", _booked);
    // console.log("Object Assign --> ", objAssign(_room, _booked));

    setValue(_arr);
    // return _arr;
  };

  const handleMerge = (pRoom, pBook) => {
    const _book = pBook;
    const _room = pRoom;

    console.log("Room Chart => ", _book);
    // console.log("Room Booked => ", _book);

    var _arr = [];

    // var objEdited =
    // console.log("oRoom = ", oRoom);
    // console.log(
    //   `
    //   ${oRoom.room_number} != ${oBook.room_number}: ${
    //     oRoom.room_number != oBook.room_number
    //   } &&
    //     (${oRoom[oBook.date]?.length == 0} || ${
    //     oRoom[oBook.date] == undefined
    //   })`,
    //   oRoom.room_number != oBook.room_number &&
    //     (oRoom[oBook.date]?.length == 0 || oRoom[oBook.date] == undefined)
    // );

    _room.forEach((oRoom) => {
      // console.log("oRoom = ", oRoom);
      _book.forEach((oBook) => {
        // console.log("oBook = ", oBook);
        // console.log(
        //   `${oRoom.room_number}!=${oBook.room_number}:${
        //     oRoom.room_number != oBook.room_number
        //   }`
        // );
        // console.log(
        //   `${oRoom[oBook.date]?.length == 0} || ${
        //     oRoom[oBook.date] == undefined
        //   }`
        // );
        // console.log(
        //   (oRoom.room_number != oBook.room_number &&
        //     oRoom[oBook.date]?.length == 0) ||
        //     oRoom[oBook.date] == undefined
        // );

        // console.log(`${oRoom[oBook.date]} == ${oBook.reservation_id}`);
        // console.log("oRoom inside for each oBook = ", oRoom);

        return (
          // (oRoom[oBook.date] =
          //   oRoom.room_number != oBook.room_number &&
          //   (oRoom[oBook.date]?.length <= 0 || oRoom[oBook.date] == undefined)
          //     ? null
          //     : oBook?.reservation_id),
          (oRoom[oBook.date] =
            oRoom.room_number == oBook.room_number
              ? oBook?.reservation_id
              : null),
          (oRoom[oBook.reservation_id] = {
            room_number:
              oRoom.room_number != oBook.room_number &&
              (oRoom[oBook.date]?.length <= 0 || oRoom[oBook.date] == undefined)
                ? null
                : oBook?.room_number,
            is_step:
              oRoom.room_number != oBook.room_number &&
              (oRoom[oBook.date]?.length <= 0 || oRoom[oBook.date] == undefined)
                ? null
                : oBook?.is_step,
            reservation_id:
              oRoom.room_number != oBook.room_number &&
              (oRoom[oBook.date]?.length <= 0 || oRoom[oBook.date] == undefined)
                ? null
                : oBook?.reservation_id,
            date:
              oRoom.room_number != oBook.room_number &&
              (oRoom[oBook.date]?.length <= 0 || oRoom[oBook.date] == undefined)
                ? null
                : oBook?.date,
            id:
              oRoom.room_number != oBook.room_number &&
              (oRoom[oBook.date]?.length <= 0 || oRoom[oBook.date] == undefined)
                ? null
                : oBook?.id,
          })
        );
      });
    });

    // console.log("Return New Objects Edited = ", objEdited);
    // console.log("Return New Objects Edited = ", _room);
    // console.log("Return Array Pushed = ", _arr);

    // return _arr.push(objEdited);
    return _room;
  };

  // Handle Date Range
  const getDateRange = (value) => {
    const range = value;
    const _days =
      value?.length > 0 ? moment(value[1]).diff(value[0], "days") : 0;

    // console.log("Value >>>> ", range);
    // console.log("Total Days >>>> ", _days);

    setIsSearch(false);
    setDateRange(range);

    setDays(_days + 1);
  };

  // Handle Room Chart
  const getRoomChart = async () => {
    fetchDataRoom();
    fetchRoomChart();

    setIsSearch(true);
  };

  // TRIGGER MODIFY TABLE DATA
  const modifyData = () => {
    setLoading(true);
    //
    // let newData = transMart(dataRoom, "room_category");
    // let sum = dynamicSummaries(dataRoom, "date_availability");
    // let summaries = dynamicColumnsByDate(dataRoom);

    // console.log("Sum -> ", summaries);

    // setData(newData);
    // setTotal(summaries);
    // setDataAvail(newData);
    // data_avail(newData);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="room-chart-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <GiBed
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Room Chart`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Typography
                style={{ marginBottom: 10 }}
              >{`Date Range`}</Typography>

              {/* <Input.Group>
                <DatePicker.RangePicker  />
              </Input.Group> */}
              <DatePicker.RangePicker
                allowClear
                className="date-range"
                onChange={getDateRange}
                // style={{
                //   width: 300,
                // }}
              />

              <Button
                className="search-btn"
                type="primary"
                onClick={getRoomChart}
                icon={<SearchOutlined />}
              >
                {`Search`}
              </Button>
            </Space>
          </Row>

          <RoomChartTable
            dataRoom={value}
            // dataRoom={data}
            dataChart={data}
            dateRange={isSearch == true ? dateRange : []}
            is_refresh={setRefresh}
            totalDays={days}
            is_loading={loading}
          />
        </Col>
      </Content>
    </Layout>
  );
}

// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// GLOBAL API
import { sub_children } from "../../../API/Global/Payload";

// PAGE COMPONENTS
import UnAuthorized from "../../../Pages/Unauthorized/Unauthorized";
import APMainPage from "../../../Pages/BackOffice/AP/Main/APMainPage";
import APPaymentPage from "../../../Pages/BackOffice/AP/APPayment/APPayment";
import APAgingPage from "../../../Pages/BackOffice/AP/APAging/APAging";
import APListPage from "../../../Pages/BackOffice/AP/APList/APList";

// List Route
const grandRoute = [
  {
    path: "account-payable-payment",
    name: "ap-pay",
    id_modules: 109,
    element: <APPaymentPage />,
  },
  {
    path: "account-payable-aging",
    name: "ap-aging",
    id_modules: 108,
    element: <APAgingPage />,
  },
  {
    path: "account-payable-payment-list",
    name: "ap-list",
    id_modules: 110,
    element: <APListPage />,
  },
];

export default function AccountPayableRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    console.log("Protected Routes AP: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="*" element={<APMainPage />} />

        {protectedRoutes}
      </Routes>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
// import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as InventoryContext } from "../../../../../../API/Context/InventoryContext/InventoryContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaMoneyBillWave } from "react-icons/fa";

// Import Page Components
import ModalReceiving from "../../../../../Modals/Inventory/Receiving/Modal";

// Import Notification
import {
  failedFetch,
  failedSI,
  successSI,
} from "../../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../../Style/Global/Global";
import { FiEdit } from "react-icons/fi";
import ModalReceivingDraft from "../../../../../Modals/Inventory/Receiving/ModalDraft";
import { token, user_name } from "../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../API/Config/Api";
import PurchaseOrderForm from "../../../../../Forms/Purchase Order/PurchaseOrder";
import { MdPrint } from "react-icons/md";
import ReceivingForm from "../../../../../Forms/Receiving/Receiving";

import { LocalizationDate } from "../../../../../Reusable/Functions/Localizations/Localization";

// Modal Form
const { confirm } = Modal;

// CODE
export default function HeaderReceivingTable({
  searchDocNo,
  searchPONo,
  searchInvNo,
  is_search,
  dataDetail,
}) {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (_, record) => {
        switch (true) {
          case record.is_draft == false &&
            (record.approved_list?.length > 0
              ? record.approved_list[1] == 1
              : record.approved_list == null):
            // return <Tag color="#87d068">Closed</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip
                    title="Print Receiving Form"
                    className="edit-tooltip"
                  >
                    <MdPrint
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleOpenForm();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Delete Data" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      // disabled
                      // onClick={() => {
                      //   console.log("ABOUT TO DELETE > ", record);
                      //   showDeleteModal(record);
                      // }}
                      style={{ fontSize: 20, color: "#bfbfbf" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.is_draft == false &&
            record.approved_list[0] != 2 &&
            record.approved_list[1] != 2:
            // return <Tag color="#108ee9">Outstanding</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip
                    title="Print Receiving Form"
                    className="edit-tooltip"
                  >
                    <MdPrint
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleOpenForm();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Delete Data" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      disabled
                      // onClick={() => {
                      //   console.log("ABOUT TO DELETE > ", record);
                      //   showDeleteModal(record);
                      // }}
                      style={{ fontSize: 20, color: "#bfbfbf" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.is_draft == false && record.approved_list?.length > 0
            ? record.approved_list[0] == 2 || record.approved_list[1] == 2
            : null:
            // return <Tag color="red">REJECTED</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip
                    title="Print Receiving Form"
                    className="edit-tooltip"
                  >
                    <MdPrint
                      className="edit-btn"
                      disabled
                      // onClick={() => {
                      //   console.log("ABOUT TO EDIT > ", record);
                      //   handleOpenForm();
                      //   setDataEdit(record);
                      // }}
                      style={{
                        fontSize: 20,
                        color: "#bfbfbf",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Delete Data" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      disabled={false}
                      onClick={() => {
                        console.log("ABOUT TO DELETE > ", record);
                        showDeleteModal(record);
                      }}
                      style={{ fontSize: 20, color: "#F64E60" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.is_draft == true &&
            moment(moment().format("YYYY-MM-DD")).isAfter(
              record.po_last_delivery_date
            ):
            // return <Tag color="red">EXPIRED</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip
                    title="Print Receiving Form"
                    className="edit-tooltip"
                  >
                    <MdPrint
                      className="edit-btn"
                      disabled
                      // onClick={() => {
                      //   console.log("ABOUT TO EDIT > ", record);
                      //   handleOpenForm();
                      //   setDataEdit(record);
                      // }}
                      style={{
                        fontSize: 20,
                        color: "#bfbfbf",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Delete Data" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      disabled={false}
                      onClick={() => {
                        console.log("ABOUT TO DELETE > ", record);
                        showDeleteModal(record);
                      }}
                      style={{ fontSize: 20, color: "#F64E60" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.is_draft == true &&
            (record.approved_list?.length > 0
              ? record.approved_list[0] != 2 && record.approved_list[1] != 2
              : record.approved_list == null):
            // return <Tag color="#f50">Draft</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip title="Edit Data" className="edit-tooltip">
                    <FiEdit
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);

                        setEdit(true);
                        handleOpen();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Delete Data" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      disabled={true}
                      onClick={() => {
                        console.log("ABOUT TO DELETE > ", record);
                        showDeleteModal(record);
                      }}
                      style={{ fontSize: 20, color: "#F64E60" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          default:
            break;
        }
      },
    },

    {
      title: "Document No",
      dataIndex: "document_number",
      key: "document_number",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Receive Date",
      dataIndex: "created_date",
      key: "created_date",
      sortDirections: ["descend", "ascend"],
      render: (date, record) => {
        return moment(LocalizationDate(date)).format("DD-MM-YYYY, HH:mm");
      },
    },
    {
      title: "Receiving Status",
      dataIndex: "si_status",
      key: "si_status",
      sorter: (a, b) => a.id - b.id,
      render: (amount, record) => {
        let _tags = null;

        switch (record.si_status) {
          case 1:
            _tags = <Tag color="volcano">with PO & Non Direct</Tag>;
            break;

          case 2:
            _tags = <Tag color="magenta">w/o PO & Non Direct</Tag>;
            break;

          case 3:
            _tags = <Tag color="cyan">with PO & Direct</Tag>;
            break;

          case 4:
            _tags = <Tag color="purple">w/o PO & Direct</Tag>;
            break;

          case 5:
            _tags = <Tag color="green">DML & Non Direct</Tag>;
            break;

          case 6:
            _tags = <Tag color="gold">DML & Direct</Tag>;
            break;

          default:
            break;
        }

        return _tags;
      },
    },
    {
      title: "PO No",
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      key: "invoice",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Department",
      dataIndex: "dept_name",
      key: "dept_name",
    },
    {
      title: "Approval Status",
      dataIndex: "approved_list",
      key: "approved_list",
      render: (approval) => {
        if (approval?.length > 0) {
          let _tags = approval.map((value, index) => {
            // console.log("Value: ", value);
            // console.log("Index: ", _id);

            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {`Approve ${index + 1}`}
                </Tag>
              );
            } else if (value === 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`Rejected ${index + 1}`}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          // console.log(_tags);

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`w/o Approval`}</Tag>;
        }
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approved_date",
      key: "approved_date",
      render: (date, record) => {
        if (date?.length > 0) {
          let _tags = record?.approved_list.map((value, index) => {
            // console.log("Value: ", value);
            // console.log("Index: ", _id);

            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {moment(LocalizationDate(date[index])).format(
                    "DD-MM-YYYY, HH:mm"
                  )}
                </Tag>
              );
            } else if (value == 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {moment(LocalizationDate(date[index])).format(
                    "DD-MM-YYYY, HH:mm"
                  )}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          // console.log(_tags);

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`-`}</Tag>;
        }
      },
    },
    {
      title: "Doc Status",
      dataIndex: "is_draft",
      key: "is_draft",
      render: (val, record) => {
        switch (true) {
          case val == false &&
            (record.approved_list?.length > 0
              ? record.approved_list[1] == 1
              : record.approved_list == null):
            return <Tag color="#87d068">Closed</Tag>;

          case val == false &&
            record.approved_list[0] != 2 &&
            record.approved_list[1] != 2:
            return <Tag color="#108ee9">Outstanding</Tag>;

          case val == false &&
            (record.approved_list[0] == 2 || record.approved_list[1] == 2):
            return <Tag color="red">REJECTED</Tag>;

          case val == true &&
            moment(moment().format("YYYY-MM-DD")).isAfter(
              record.po_last_delivery_date
            ):
            return <Tag color="red">EXPIRED</Tag>;

          case val == true &&
            (record.approved_list?.length > 0
              ? record.approved_list[0] != 2 && record.approved_list[1] != 2
              : record.approved_list == null):
            return <Tag color="#f50">Draft</Tag>;

          default:
            break;
        }
      },
    },
  ];

  // CONTEXT
  const { getReceivingHead } = useContext(InventoryContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);

  const [bulks, setBulks] = useState([]);
  // ID
  const [dataEdit, setDataEdit] = useState([]);
  const [roomCode, setRoomCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [modalFormOpen, setModalFormOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async (e) => {
    await getReceivingHead({
      type: "/receiving",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Good Receipt Header Data => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (e == 0) {
              return !filt.is_deleted;
            } else {
              return !filt.is_deleted && filt.si_status == e;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData(0);
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchDocNo, searchPONo, searchInvNo]);

  // Handle Row Selection
  const rowSelection = {
    columnTitle: "Select",
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      dataDetail(
        // selectedRows?.length > 0 ? selectedRows[0].bill_alias : []
        selectedRows?.length > 0 ? selectedRows : []
      );
    },
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle Open Form
  const handleOpenForm = () => {
    // setIsDelete(false);

    setModalFormOpen(true);
  };
  // HANDLE REFRESH
  const handleRefresh = (value) => {
    if (value === true) {
      fetchData(0);
    }
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    form.resetFields();
    // setEditData(null);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let del = record;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to data from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log(value);
    let content = value;

    handleSubmit(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
    let contentSubmit = e;
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record.id_stock_incoming;

    console.log("contentDelete >>>>>>", contentdelete);
    var obj = {
      ["id_stock_incoming"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/inventory/receiving`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Del PO = ", response);

        successSI(response, { method: 2 });

        fetchData(0);
      })
      .catch((error) => {
        console.log("Del PO = ", error);

        failedSI(error, { method: 2 });
        // setIsLoading(false);
      });
    // .finally(() => {
    // });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryDocNo = searchDocNo ? searchDocNo.toLowerCase() : null;
    const queryPONo = searchPONo ? searchPONo.toLowerCase() : null;
    const queryInvNo = searchInvNo ? searchInvNo.toLowerCase() : null;
    console.log(queryDocNo);
    console.log(queryPONo);
    console.log(queryInvNo);

    if (data.length > 0) {
      console.log(data[0]?.invoice.toLowerCase());
      let updatedList = data.filter((value) => {
        const _doc = value?.document_number.toLowerCase();
        const _po = value?.po_number.toLowerCase();
        const _inv = value?.invoice.toLowerCase();

        if (queryDocNo && queryInvNo) {
          return (
            _doc.indexOf(queryDocNo) !== -1 && _inv.indexOf(queryInvNo) !== -1
          );
        }
        if (queryPONo && queryInvNo) {
          return (
            _po.indexOf(queryPONo) !== -1 && _inv.indexOf(queryInvNo) !== -1
          );
        }
        if (queryDocNo && queryPONo) {
          return (
            _po.indexOf(queryPONo) !== -1 && _doc.indexOf(queryDocNo) !== -1
          );
        }
        /////
        if (!queryDocNo && !queryPONo) {
          return _inv.indexOf(queryInvNo) !== -1;
        }
        if (!queryDocNo && !queryInvNo) {
          return _po.indexOf(queryPONo) !== -1;
        }
        if (!queryInvNo && !queryPONo) {
          return _doc.indexOf(queryDocNo) !== -1;
        }
        /////
        if (queryDocNo && queryPONo && queryInvNo) {
          return (
            _po.indexOf(queryPONo) !== -1 &&
            _doc.indexOf(queryDocNo) !== -1 &&
            _inv.indexOf(queryInvNo) !== -1
          );
        }
      });

      // Trigger render with updated values
      console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={8}>
              <Typography
                className="text-journal-header"
                style={{
                  fontSize: 15,
                  fontWeight: 750,
                }}
              >
                {`Stock Incoming List`}
              </Typography>
            </Col>

            <Col className="col-btn" span={16}>
              <Row
                justify="end"
                gutter={[0, 15]}
                align="top"
                className="btn-row"
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => {
                    fetchData(0);
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="submit-btn"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleOpen}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Add Stock Incoming`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row
            className="row-table-btn"
            justify="start"
            style={{ marginTop: 30, width: "100%" }}
          >
            <Typography
              className="text-journal-header"
              style={{
                fontSize: 12,
                // fontWeight: 750,
              }}
            >
              {`Filter By Status`}
            </Typography>
          </Row>
          <Row className="" justify="start">
            <Select
              onChange={(e) => {
                // setData(
                //   data.filter((x) => {
                //     if (x.si_status == e) {
                //       return x;
                //     }
                //   })
                // );
                fetchData(e);
              }}
              style={{
                width: 200,
              }}
              options={[
                {
                  value: 1,
                  label: "With PO & Non Direct",
                },
                {
                  value: 2,
                  label: "w/o PO & Non Direct",
                },
                {
                  value: 3,
                  label: "With PO & Direct",
                },
                {
                  value: 4,
                  label: "w/o PO & Direct",
                },
                {
                  value: 5,
                  label: "DML & Non Direct",
                },
                {
                  value: 6,
                  label: "DML & Direct",
                },
              ]}
            />
          </Row>

          <Row className="row-table" style={{ marginTop: 10, width: "100%" }}>
            <Table
              className="header-table"
              name="header-table"
              key="header-table"
              columns={columns}
              dataSource={data}
              bordered
              loading={loading}
              size="middle"
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1600,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) =>
                record.document_number ? record.document_number : record.key
              }
            />
          </Row>
        </Col>
      </Row>

      {edit == true ? (
        <ModalReceivingDraft
          openModal={open}
          closeModal={(e) => {
            setOpen(e);
            setEdit(false);
            fetchData(0);
          }}
          is_refresh={handleRefresh}
          selectedData={dataEdit}
        />
      ) : (
        <ModalReceiving
          openModal={open}
          closeModal={setOpen}
          is_refresh={handleRefresh}
        />
      )}

      <ReceivingForm
        openModal={modalFormOpen}
        closeModal={setModalFormOpen}
        dataReceiving={dataEdit}
      />
    </>
  );
}

// Import React's Components
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Antd Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function PurchaseRequestList(props) {
  // PROPS
  const { getPRNumber, pr, is_open } = props;

  // CONTEXT
  const { getPurchasing } = useContext(FinanceContext);
  // Data
  const [purchaseRequest, setPurchaseRequest] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/back-office/purchasing/purchase-request");
  };

  // Fetch Data
  const fetchData = () => {
    getPurchasing({
      type: "purchase-request",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Purchasing => ", response);

        if (response.data.msg?.length > 0) {
          let _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.pr_status == true) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.id_pr,
            label: `${dev.pr_number} / ${dev.name}`,
            pr_number: dev?.pr_number,
            request_date: dev?.request_date,
            needed_date: dev?.needed_date,
            remarks: dev?.remarks,
            details: dev?.details,
          }));

          // console.log(option);
          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    // console.log("Purchase ID ", pr);
    // console.log(optionValue);

    if (pr && !cleanUp) {
      let x = optionValue.filter((e) => {
        if (e.pr_number == pr) {
          return e;
        }
      });

      // console.log(x);

      if (optionValue.length > 0) {
        getPRNumber(x);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [optionValue]);

  // Handle Change
  const handleChange = (val, allKey) => {
    const _value = val;
    const _allKey = allKey;

    // console.log(_value);
    // console.log(_allKey);

    setPurchaseRequest(_value);
    getPRNumber(val, allKey);
  };

  // Handle Clear
  const handleClear = () => {
    setPurchaseRequest(null);
  };

  return (
    <>
      <Row>
        <Col span={22}>
          <Select
            className="purchase-req-selector"
            key="purchase-req-selector"
            name="purchase-req-selector"
            allowClear
            showSearch
            disabled={pr != null ? true : false}
            value={pr ? pr : purchaseRequest}
            // fieldNames={{
            //   label: "name",
            //   value: "name",
            //   code: "financial_statement_id",
            // }}
            onChange={handleChange}
            onClear={handleClear}
            options={optionValue}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Col>

        <Col span={2} className="select-col">
          <Tooltip
            className="redirect-tooltip"
            title={"Redirect to Purchase Request Page"}
          >
            <Button
              className="master-btn"
              icon={
                <AppstoreFilled
                  onClick={move}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useContext, useState, useEffect } from "react";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import { Select } from "antd";

// Import Ant Design Components

// CODE
export default function MasterBalance({ getSheet, _sheet }) {
  // CONTEXT
  const { getBalanceSheet, getTrialBalance } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [sheet, setSheet] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getTrialBalance({
      type: "trial-balance",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        let filtered = response.data?.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            return filt;
          }
        });

        let option = filtered?.map((dev) => ({
          value: dev.account_code,
          label: `${dev.account_code} / ${dev.account_name}`,
          code: dev.account_code,
          id: dev.id,
          desc: dev.account_name,

          balance: dev.initial_balance,
          debit: dev.debit,
          credit: dev.credit,

          updated_by: dev?.updated_by,
          date: dev?.updated_date,
          account_type: dev?.account_type_id,
          normal_balance: dev?.normal_balance,
          total_balance: dev?.total_balance,
        }));

        // let sortir = option.sort((a, b) => a.code - b.code);

        // console.log("Sortir --> ", option);
        // console.log(sortir);

        setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Select
        key={optionValue.id}
        style={{ width: "100%" }}
        value={sheet}
        showSearch
        allowClear
        onChange={(e, opt) => {
          console.log(e);
          //   console.log(opt);

          setSheet(e);

          // let code = optionValue.filter((cd) => {
          //   return cd.value == e;
          // });

          // console.log("COA No = ", code);

          getSheet(
            e,
            opt?.label,
            opt?.desc,
            opt?.date,
            opt?.updated_by,
            opt?.balance,
            opt?.debit,
            opt?.credit,
            opt?.id,
            opt?.normal_balance,
            opt?.account_type,
            opt?.total_balance
          );
          // getCoaNo(code[0].account_code);

          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
      />
    </>
  );
}

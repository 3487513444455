// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import Ant Design Components
import { Card, Col, List, Row, Typography } from "antd";

// Import React Icons
import { FaConciergeBell } from "react-icons/fa";

// OCDE
export default function TableListCard(props) {
  // Props
  const { dataSource, table_select, is_roomService, is_loading } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [isRoomService, setIsRoomService] = useState(false);
  // Table Selected
  const [tableSelected, setTableSelected] = useState(null);
  // Loading State
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(is_loading);

  const fetchData = async (e, v) => {
    const startDate = e;
    const searchKeyName = v;
    setLoading(true);
    await axios
      .post(`${baseurl}/pos/reservation-by-date`, {
        start_date: startDate ?? moment(),
        outlet_location_id: JSON.parse(sessionStorage.getItem("selection"))
          .locations_id,
      })
      .then((response) => {
        console.log("TABLE AVAILABILITY >>> ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            return e;
          });
          setData(_res);
        }
      })
      .catch((error) => {
        console.log("Error Table Availability >>> ", error);

        // failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("Room Service State: ", isRoomService);
    if (is_roomService === true && !cleanUp) {
      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    if (dataSource?.length > 0 && !cleanUp) {
      console.log("Data Source Categories: ", dataSource);
      setData(dataSource);
    }

    if (is_loading === true && !cleanUp) {
      console.log("Loading State: ", is_loading);
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_roomService, dataSource, is_loading]);

  // HANDLE TABLE
  const handleTable = (val, ind) => {
    const _number = val;
    const _index = ind;

    console.log("Table Selected --> ", _number);
    console.log("Table Index --> ", _index);

    setTableSelected(_number);
    table_select(_number, _index);
  };

  return (
    <>
      <List
        className="outlet-table-list"
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 6,
        }}
        loading={isLoading}
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item className="table-list" key={item?.id}>
            <Card
              className="card-table"
              key={item?.id}
              hoverable
              loading={isLoading}
              bodyStyle={{
                padding: 20,
              }}
              style={{
                background:
                  item?.status_table === 1 || item?.occupancy_status === false
                    ? "#bae7ff"
                    : "#ffa3a6",
                borderRadius: 15,
                cursor: item.status_table == 2 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if(item.status_table == 2) {
                  null;
                } else {
                  handleTable(item, index);
                }
                // setTableKey(item.table);
              }}
              //   onDoubleClick={() => {
              //     handleOpen();
              //   }}
            >
              <Row className="row-table" style={{ width: "100%" }}>
                <Col span={12} className="titletable-col">
                  <Typography.Title
                    level={4}
                    className="txt-h2"
                    style={{ marginBottom: 15 }}
                  >
                    {isRoomService
                      ? item?.room_category_name
                      : item?.table_code}
                  </Typography.Title>

                  {isRoomService ? item?.room_id : item?.table_id}

                  <Typography
                    className="tablestatus-txt"
                    style={{
                      marginBottom: 10,
                      fontWeight: 500,
                      color:
                        item?.status_table === 1 ||
                        item?.occupancy_status === false
                          ? "#7E8299"
                          : "#000000",
                    }}
                  >
                    {item?.status_table === 1 ||
                    item?.occupancy_status === false
                      ? "Empty"
                      : "Reserved"}
                  </Typography>
                </Col>

                <Col
                  span={12}
                  className="icontable-col"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    flexCirection: "column",
                    alignContent: "flex-end",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Row className="titletable-row">
                    <FaConciergeBell
                      className="bell-icons"
                      color={
                        item?.status_table === 1 ||
                        item?.occupancy_status === false
                          ? "#B5B5C3"
                          : "#FFFFFF"
                      }
                      size={50}
                      style={{ margin: 0 }}
                    />
                  </Row>

                  <Typography
                    className="seat-txt"
                    style={{
                      color:
                        item?.status_table === 1 ||
                        item?.occupancy_status === false
                          ? "#7E8299"
                          : "#000000",
                      fontWeight: 500,
                    }}
                  >
                    {isRoomService ? `` : `Seat : ${item?.seat || 0}`}
                  </Typography>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

// REACT COMPONENTS
import React, { useEffect, useState } from "react";

// ANTD COMPONENTS
import {
  FileExcelFilled,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";

// GLOBAL STYLE
import { mainBody } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { masterFailedNotification } from "../../../../Reusable/Notification/MasterNotif/Notification";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../../../API/Config/Api";
import { token } from "../../../../../API/Global/Payload";
import { failedFetch } from "../../../../Reusable/Notification/Notification";

export default function StockOnHandTable(props) {
  // PROPS
  const { article_number, id_warehouse, type } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [totalStock, setTotalStock] = useState(0);
  // Boolean
  const [isLoading, setIsLoading] = useState(false);
  // Type
  const [types, setTypes] = useState(0);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (article_number && !cleanUp) {
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [article_number]);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (id_warehouse && !cleanUp) {
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [id_warehouse]);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (type !== types && !cleanUp) {
      setTypes(type);
      setData([]);
      setBulks([]);
      setTotalStock(0);
    }

    return () => {
      cleanUp = true;
    };
  }, [type]);

  // FETCH DATA
  const fetchData = async () => {
    setIsLoading(true);

    let BodyReq = {};

    if (id_warehouse) {
      BodyReq = {
        id_warehouse: id_warehouse,
      };
    } else if (article_number) {
      BodyReq = {
        article_number: article_number,
      };
    }

    // console.log("Body Req >> ", BodyReq);

    await axios
      .post(`${baseurl}/inventory/stock-on-hand`, BodyReq, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Response Article By ID Article / ID Warehouse ", response);
        if (response?.data.msg != null) {
          let filterData = response.data.msg.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          const filter = filterData.map((item) => {
            return {
              key: item.id_wa,
              label: item.article_name,
              value: item.article_name,
              id_wa: item?.id_wa,
              id_article: item?.id_article,
              article_stock: item?.article_stock,
              id_warehouse: item?.id_warehouse,
              last_supplier: item?.last_supplier,
              average_price: item?.average_price,
              mess_stock: item?.mess_stock,
              warehouse_name: item?.warehouse_name,
              warehouse_location: item?.warehouse_location,
              description: item?.description,
              warehouse_code: item?.warehouse_code,
              chart_of_account: item?.chart_of_account,
              article_name: item?.article_name,
              article_number: item?.article_number,
            };
          });

          const amountStock =
            filter.length > 0
              ? filter.reduce((total, item) => total + item.article_stock, 0)
              : 0;

          setTotalStock(amountStock);
          setData(filter);
          setBulks(filter);
        } else {
          setData([]);
          setBulks([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setIsLoading(false);
      });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Stock On Hand");
  };

  // HANDLE INPUT
  const handleInput = (val) => {
    val.preventDefault();
    const queryKey = val?.target?.value ? val.target.value.toLowerCase() : null;

    setSearchKey(queryKey);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.article_name.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  const columns = [
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Stock On Hand",
      dataIndex: "article_stock",
      key: "article_stock",
      render: (stock, record) => {
        return `${stock}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Average Price (Rp.)",
      dataIndex: "average_price",
      key: "average_price",
      render: (price, record) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse_name",
      key: "warehouse_name",
    },
    {
      title: "Warehouse Location",
      dataIndex: "warehouse_location",
      key: "warehouse_location",
    },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: "Last Supplier",
      dataIndex: "last_supplier",
      key: "last_supplier",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Row
        className="article-main-table"
        justify="center"
        style={{
          margin: "30px 0px",
          borderRadius: 5,
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col className="search-col" span={24}>
          <Space
            align="end"
            size={15}
            className="header-space"
            wrap={true}
            style={{
              padding: 30,
            }}
          >
            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>{`Keywords`}</Typography>

              <Input
                placeholder="Article Name"
                allowClear
                onChange={handleInput}
                style={{ minWidth: 150 }}
              />
            </Col>

            <Col
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Button
                className="search-btn"
                type="primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
              >
                {`Search`}
              </Button>
            </Col>
          </Space>
        </Col>
      </Row>

      <Row
        className="article-inventory-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="art-inventory-table"
              key="art-inventory-table"
              name="art-inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1500,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              summary={
                type === 1
                  ? () => {
                      return (
                        <>
                          <Table.Summary fixed>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={1} />

                              <Table.Summary.Cell index={0}>
                                <b
                                  style={{ alignSelf: "end" }}
                                >{`Total Stock`}</b>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell index={4}>
                                <b>
                                  {data.length > 0
                                    ? `${totalStock}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    : 0}
                                  {/* {`${totalStock}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )} */}
                                </b>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </Table.Summary>
                        </>
                      );
                    }
                  : null
              }
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// API
import { baseurl } from "../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// Import Antd Designs Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";

// Import React Icons Components
import { TbMessageCircle2, TbMessageOff } from "react-icons/tb";
import { MdCancel } from "react-icons/md";

// Import Page Components

// Import Notifications
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import {
  failedAddReserv,
  successAddReserv,
} from "../../Reusable/Notification/Notification";
import { masterIncomplete } from "../../Reusable/Notification/MasterNotif/Notification";

const { confirm } = Modal;

// CODE
export default function CancellationModal(props) {
  // PROPS
  const { dataGuest, openModal, closeModal, is_finish, is_refresh } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (openModal && dataGuest && !cleanUp) {
      setOpen(true);

      console.log("About to Cancelled = ", dataGuest);

      setData(dataGuest);
      setFormFields(dataGuest);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataGuest, openModal]);

  // SET FORM FIELDS
  const setFormFields = (value) => {
    const _data = value;

    form.setFieldsValue({
      reservation_id: _data?.reservation_id || null,
      group: _data?.reservation_id == 0 ? "Individual Guest" : "Group Guest",
      guest_name:
        _data?.guest_category == "INCOGNITO" ? "PRIVATE" : _data?.guest_name,
      arrival: _data?.arrival
        ? moment(_data.arrival).format("DD-MM-YYYY")
        : null,
      departure: _data?.departure
        ? moment(_data.departure).format("DD-MM-YYYY")
        : null,
      night: _data?.night > 0 ? _data.night : null,

      created_by: _data?.created_by || null,
      created_at: _data?.created_at
        ? moment(LocalizationDate(_data.created_at)).format(
            "HH:mm:ss, DD-MM-YYYY"
          )
        : null,
    });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    closeModal(false);
    setOpen(false);

    setIsLoading(false);

    form.resetFields();
  };

  // HANDLE FINISH
  const onFinish = (e) => {
    const _submit = e;
    console.log("Submitted = ", _submit);

    showDeleteModal(_submit);
    setIsLoading(true);
  };

  // HANDLE FINISH FAILED
  const onFinishFailed = (e) => {
    const _submit = e;
    console.log("Submitted Failed = ", _submit);

    masterIncomplete(_submit);
  };

  // DELETE MODAL
  const showDeleteModal = (value) => {
    const _data = value;
    console.log("INSIDE DELETE MODAL = ", _data);

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to cancel the Reservation Number of ${
        _data?.reservation_id || "----"
      }?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        type: "primary",
        className: "submit-btn",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
        zIndex: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (value) => {
    const contentDelete = value;

    console.log("ABOUT TO DELETE ", contentDelete);

    let obj = {
      ["reservation_id"]: contentDelete?.reservation_id,
      ["prc_id"]: data?.price_id,
      ["reason"]: contentDelete?.reason,
      ["created_by"]: user_name,
    };

    // console.log("OBJ = ", obj);

    await axios
      .delete(`${baseurl}/reservation/stay`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);

        successAddReserv(obj, { method: 2 });
        is_refresh(true);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);

        failedAddReserv(obj, { method: 2 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div aria-modal="true" aria-hidden="false">
      <Modal
        className="cancel-res-modal"
        key="cancel-res-modal"
        title={
          <Row>
            <MdCancel style={{ fontSize: 24, color: "#f5222d" }} />
            <Typography
              style={{ marginLeft: 15 }}
            >{`Reservation Cancellations`}</Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          className="cancel-res-form"
          key="cancel-res-form"
          name="cancel-res-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            padding: "0px 15px",
          }}
        >
          <Row className="res-id-row" gutter={30}>
            <Col className="res-id-col" span={16}>
              <Form.Item label="Reservation ID" name="reservation_id">
                <Input placeholder="Reservation ID" disabled />
              </Form.Item>
            </Col>

            <Col className="group-col" span={8}>
              <Form.Item label="Group" name="group">
                <Input placeholder="Group" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row className="res-id-row" gutter={30}>
            <Col className="res-id-col" span={16}>
              <Form.Item label="Guest/Leader's Name" name="guest_name">
                <Input placeholder="Guest/Leader's Name" disabled />
              </Form.Item>
            </Col>

            <Col className="group-col" span={8}>
              <Form.Item label="Night" name="night">
                <Input placeholder="Night" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row className="date-row" gutter={30}>
            <Col className="arrival-col" span={8}>
              <Form.Item label="Arrival" name="arrival">
                <Input placeholder="Arrival" disabled />
              </Form.Item>
            </Col>

            <Col className="depart-col" span={8}>
              <Form.Item label="Departure" name="departure">
                <Input placeholder="Departure" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row className="reason-row">
            <Col className="res-id-col" span={16}>
              <Form.Item
                label="Remarks"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "Please, Type the Reason of Cancellations!",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Reason of Cancellations"
                  showCount
                  maxLength={200}
                  style={{
                    height: 100,
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "0px 0px 5px", backgroundColor: "#000000" }}
          />

          <Row className="creator-row" gutter={30}>
            <Col className="createdby-col" span={8}>
              <Form.Item label="Created By" name="created_by">
                <Input placeholder="Created By" disabled />
              </Form.Item>
            </Col>

            <Col className="group-col" span={8}>
              <Form.Item label="Created Date" name="created_at">
                <Input placeholder="Created Date" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row className="modal-btn-row" justify="end" gutter={30}>
            <Button
              className="danger-btn"
              htmlType="submit"
              type="primary"
              danger
              loading={isLoading}
              style={{
                margin: "0px 15px",
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              key="cancel-btn"
              onClick={handleCancel}
              type="default"
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

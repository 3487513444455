// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { HiTemplate } from "react-icons/hi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { TbBulb, TbClockExclamation } from "react-icons/tb";

// Import Page Components
import ReNightAuditSteps from "../../../Components/Steps/BackOffice/ReNightAudit/Steps";
import AuditDate from "../../../Components/Reusable/DateAudit/Date";
import NightAuditSteps from "../../../Components/Steps/BackOffice/NightAudit/Steps";
import BSTemplateTable from "../../../Components/Table/Master/BSTemplate/Table";

// Import Global Styles
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";

// CODE
export default function ReNightAuditPage() {
  // Audit Date
  const _date = AuditDate().audit;

  // STATE MANAGEMENT
  // Data Date
  const [dateAudit, setDateAudit] = useState(null);

  // Handle Dates
  const handleDate = (val) => {
    const _date = val;

    console.log("Dates: ", _date);

    setDateAudit(_date);
  };

  // Handle Disable
  const handleDisable = (current) => {
    return current > moment(_date).add(1, "days");
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="main-layout">
          <Row className="title" justify="start" style={cardHeader}>
            <TbClockExclamation
              style={{
                fontSize: 30,
                // height: 24,
                // width: 24,
                margin: "22px 30px",
                color: "#3699FF",
                // transform: "rotate(180deg)",
              }}
            />

            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Re-Night Audit`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                className="col-searh"
                style={{
                  textAlign: "start",
                  // margin: "22px 15px 30px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Choose Audit Date`}
                </Typography>

                <DatePicker
                  allowClear
                  className="date-search"
                  placeholder="Audit's Date"
                  onChange={handleDate}
                  disabledDate={handleDisable}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              {/* <Col
                style={{
                  textAlign: "start",
                  margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  Arrival Date
                </Typography>

                <Input.Group>
                  <DatePicker.RangePicker />
                </Input.Group>
              </Col> */}

              <Col
                className="col-search-btn"
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  //   onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <ReNightAuditSteps dates={dateAudit} />
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

// Import React Icons
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { TfiMenuAlt } from "react-icons/tfi";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Page Components

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

// CODE
export default function GeneralLedgersTable(props) {
  // PROPS
  const { is_search, queryKey } = props;

  // CONTEXT
  const { getBalanceSheet } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Set Selected
  const [selectedData, setSelectedData] = useState(null);
  // Data Type
  const [typeComp, setTypeComp] = useState(2);
  // Modal Open
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // USE FORM
  const [form] = Form.useForm();

  // COLUMNS
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "action",
    //   width: 100,
    //   align: "center",
    //   fixed: "left",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Row
    //           className="action-guest-table-btn"
    //           justify="center"
    //           style={{ width: "100%" }}
    //         >
    //           <CompanyPopover
    //             details={record}
    //             is_refresh={handleRefresh}
    //             typeComp={typeComp}
    //           />
    //         </Row>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
      //   fixed: "left",
      width: 150,
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Posting Date",
      dataIndex: "posting_date",
      key: "posting_date",
      render: (date) => {
        return date != null || date != "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Normal Balance",
      dataIndex: "normal_balance",
      key: "normal_balance",
      render: (balance, record) => {
        let blnc = "";
        let clr = "";

        switch (balance) {
          case 0:
            blnc = "DEBIT";
            clr = "geekblue";
            break;

          case 1:
            blnc = "CREDIT";
            clr = "green";
            break;

          default:
            blnc = "Unknown";
            clr = "grey";
            break;
        }

        return (
          <Tag color={clr} className="tag-coa" key={balance}>
            {blnc}
          </Tag>
        );
      },
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (price, record) => {
        // console.log(record);
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Final Balance",
      dataIndex: "final_balance",
      key: "final_balance",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
  ];

  // FETCH DATA
  const fetchData = async () => {
    await getBalanceSheet({
      type: "general-ledger",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response G/L => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECTS
  useEffect(() => {
    is_search(() => handleSearch);
  }, [queryKey]);

  // HANDLE COMPANY/OTA
  const handleChange = async (params, array) => {
    const _params = params;
    const _array = array?.length > 0 ? array : bulks;
    setTypeComp(_params);

    console.log("Array: ", _array);

    let _filtered = [];

    if (_array?.length > 0)
      switch (_params) {
        case 2:
          _filtered = _array.filter((value) => {
            return value?.is_ota === false || value?.is_ota === null;
          });
          break;

        case 3:
          _filtered = _array.filter((value) => {
            return value?.is_ota === true;
          });
          break;

        default:
          break;
      }

    console.log("Filtered IS_OTA: ", _filtered);
    setData(_filtered);
  };

  // Handle Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
    }
  };

  // Handle Refresh Button
  const handleRefreshButton = () => {
    fetchData();
  };

  // Handle Selected
  const getLedgers = (value) => {
    const _selected = value;
    console.log("Selceted: ", _selected);
  };

  // Handle Open
  const handleClose = (e) => {
    // setIsDelete(false);
    setOpen(false);
    setSelectedData(null);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const _start =
      queryKey?.length > 0 ? moment(queryKey[0]).format("YYYY-MM-DD") : null;
    const _end =
      queryKey?.length > 0 ? moment(queryKey[1]).format("YYYY-MM-DD") : null;

    console.log(_start, _end);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((value) => {
        const _date = moment(value?.posting_date).format("YYYY-MM-DD");

        return moment(_date).isBetween(_start, _end, "days", "[]");
      });

      // Trigger render with updated values
      // console.log("Bulks => ", bulks);
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/back-office/accounting";

    navigate(`${path}`);
  };

  return (
    <>
      <Row className="guest-card-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="end" className="row-export">
            {/* <Select
              className="guest-select"
              key="guest-select"
              defaultValue={2}
              style={{
                width: 205,
                marginRight: 15,
                fontWeight: 500,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 2,
                  label: "Company",
                },
                {
                  value: 3,
                  label: "OTA",
                },
              ]}
            /> */}

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={handleRefreshButton}
              style={{
                fontWeight: 500,
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={
                <IoReturnUpBackOutline
                  className="return-icons"
                  style={{
                    margin: "0px 5px 0px 0px",
                  }}
                />
              }
              onClick={handleReturn}
              style={{
                margin: "0px 0px 0px 15px",
              }}
            >
              {`Return`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 35, width: "100%" }}>
            <Table
              className="company-ota-table"
              key="company-ota-table"
              bordered
              loading={loading}
              dataSource={data}
              columns={columns}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Select, Table, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

export default function AnnualReceivingTable(props) {
  // PROPS
  const { id_warehouse, to_month } = props;

  //CONTEXT
  const { getInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dataType, setDataType] = useState(1);
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (id_warehouse && to_month && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setBulks([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [id_warehouse, to_month]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    const _to_month = moment(to_month).format("YYYY-MM-DD");

    await getInventoryReport({
      type: `annual-receiving/${id_warehouse}/${_to_month}`,
      // bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
            };
          });

          setData(filter);
          setBulks(filter);
        } else {
          setData([]);
          setBulks([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Annual Receiving List");
  };

  // HANDLE DATA TYPE
  const handleDataType = (value) => {
    setDataType(value);
  };

  // HANDLE DATA TYPE
  const handleKeyName = (month) => {
    switch (dataType) {
      case 1:
        return `qty_${month}`;
      case 2:
        return `avg_${month}`;
      case 3:
        return `amt_${month}`;
      default:
        break;
    }
  };

  // HANDLE TOTAL
  const handleKeyTotal = () => {
    switch (dataType) {
      case 1:
        return `total_qty`;
      case 2:
        return `total_avg`;
      case 3:
        return `total_amt`;
      default:
        break;
    }
  };

  // Params
  const currency = CurrencySymbols().code;

  // TABLE COLUMNS
  const columns = [
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },

    {
      title: "JAN",
      dataIndex: handleKeyName("jan"),
      key: handleKeyName("jan"),
      render: (jan, record) => {
        return jan > 0 ? `${jan}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "FEB",
      dataIndex: handleKeyName("feb"),
      key: handleKeyName("feb"),
      render: (feb, record) => {
        return feb > 0 ? `${feb}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MAR",
      dataIndex: handleKeyName("mar"),
      key: handleKeyName("mar"),
      render: (mar, record) => {
        return mar > 0 ? `${mar}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "APR",
      dataIndex: handleKeyName("apr"),
      key: handleKeyName("apr"),
      render: (apr, record) => {
        return apr > 0 ? `${apr}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MAY",
      dataIndex: handleKeyName("may"),
      key: handleKeyName("may"),
      render: (may, record) => {
        return may > 0 ? `${may}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "JUN",
      dataIndex: handleKeyName("jun"),
      key: handleKeyName("jun"),
      render: (jun, record) => {
        return jun > 0 ? `${jun}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "JUL",
      dataIndex: handleKeyName("jul"),
      key: handleKeyName("jul"),
      render: (jul, record) => {
        return jul > 0 ? `${jul}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "AUG",
      dataIndex: handleKeyName("aug"),
      key: handleKeyName("aug"),
      render: (aug, record) => {
        return aug > 0 ? `${aug}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "SEP",
      dataIndex: handleKeyName("sep"),
      key: handleKeyName("sep"),
      render: (sep, record) => {
        return sep > 0 ? `${sep}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "OCT",
      dataIndex: handleKeyName("oct"),
      key: handleKeyName("oct"),
      render: (oct, record) => {
        return oct > 0 ? `${oct}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "NOV",
      dataIndex: handleKeyName("nov"),
      key: handleKeyName("nov"),
      render: (nov, record) => {
        return nov > 0 ? `${nov}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "DEC",
      dataIndex: handleKeyName("des"),
      key: handleKeyName("des"),
      render: (dec, record) => {
        return dec > 0 ? `${dec}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: dataType !== 1 ? `TOTAL ${currency}` : "TOTAL",
      dataIndex: handleKeyTotal(),
      key: handleKeyTotal(),
      render: (total, record) => {
        return total > 0 ? `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ];

  // DATA TYPE OPTIONS
  const options = [
    {
      label: "Quantity",
      value: 1,
    },
    {
      label: "Average Price",
      value: 2,
    },
    {
      label: "Amount",
      value: 3,
    },
  ];

  return (
    <>
      <Row
        className="annual-receiving-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                    minWidth: 250,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Data Type`}
                  </Typography>

                  <Select
                    className="type-select"
                    key="type-select"
                    allowClear
                    showSearch
                    value={dataType}
                    onChange={handleDataType}
                    options={options}
                    placeholder="Data Type"
                    defaultValue={dataType}
                    style={{
                      width: "100%",
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() =>
                    id_warehouse && to_month ? fetchData() : null
                  }
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1200,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";

// Import Ant Design Components
import { Select } from "antd";

/// CODE
export default function MasterNumbers(props) {
  // PROPS
  const { getNumbers, existedNumb, pickedNumb } = props;

  // STATE MANAGEMENT
  // Data
  const [numberSelected, setNumberSelected] = useState(null);
  const [optionValues, setOptionValues] = useState([]);

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    if (pickedNumb?.length > 0 && !cleanUp) {
      setNumberSelected(existedNumb);
      handleFilter(pickedNumb);
    }

    return () => {
      cleanUp = true;
    };
  }, [pickedNumb, existedNumb]);

  // Handle Change
  const handleChange = (value, opt) => {
    const _selected = value;

    setNumberSelected(_selected);
    getNumbers(_selected);
  };

  // Handle Clear
  const handleClear = () => {
    setNumberSelected(null);
  };

  // Handle Filter
  const handleFilter = (val) => {
    const _existed = val;
    const _length = val?.length > 150 ? val.length : 150;
    // console.log("Selected Numbers: ", _existed);

    let arrBucket = [];

    for (let i = 0; i < _length; i++) {
      arrBucket.push({
        value: i + 1,
        label: i + 1,
      });
    }

    if (arrBucket.length > 0) {
      let _filtered = arrBucket.filter(({ value }) => {
        if (!_existed.includes(value)) {
          return value;
        }
      });

      // console.log("Bucket Numbers Filtered: ", _filtered);
      if (_filtered.length > 0) setOptionValues(_filtered);
    }
  };

  return (
    <>
      <Select
        className="number-select"
        key="number-select"
        allowClear
        showSearch
        placeholder="Select a number!"
        value={numberSelected}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValues}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

// Import React's Component
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUHT
import { token, user_name } from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Layout,
  Button,
  Col,
  Input,
  Row,
  Typography,
  Table,
  Form,
  Divider,
  InputNumber,
  Modal,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { FaBalanceScale } from "react-icons/fa";
import { RxInput } from "react-icons/rx";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Global Styles
import {
  cardBody,
  cardHeader,
  mainBody,
} from "../../../../Style/Global/Global";

// Import Page Components
import InputSkeleton from "../../../../Components/Reusable/Skeleton/InputSkeleton";
import MasterBalance from "../../../../Components/Reusable/Accounting/Balance/Balance";
import MasterCOA from "../../../../Components/Reusable/Accounting/MainAccount/MainAccount";

// Import Functions
import { CurrencySymbols } from "../../../../Components/Reusable/Functions/Currency/Currency";

// Import Notification
import {
  failedFetch,
  failedInitialBalance,
  successInitialBalance,
} from "../../../../Components/Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

const styleText = {
  // fontSize: 20,
  fontWeight: 750,
  color: "#187DE4",
};

// CODE
export default function InitialBalancePage() {
  const columns = [];

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  // SET VIEW
  const [view, setView] = useState(false);

  // Loading
  const [loading, setLoading] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  // if (data != null || data != undefined) {
  //   form.setFieldsValue({
  //     ...data,
  //   });
  // }
  // }, [data]);

  // FETCH DATA
  const fetchData = async () => {
    // await getParameters({
    //   params: "parameters",
    //   type: "fo",
    //   onAwait: () => {
    setLoading(true);
    setView(false);
    setData(null);
    //   },
    //   onSuccess: (response) => {
    //     console.log("Params FO --> ", response);
    //     setData(response?.data?.msg[0]);
    //     // setLoading(false);
    //   },
    //   onReject: (error) => {
    //     console.log("Params FO err --> ", error);
    //     // setLoading(false);
    // failedFetch(error);
    //   },
    // });

    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("ON FINISH >>> ", content);
    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;

    console.log("ON FINISH FAILED --> ", error);
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    setView(false);

    navigate("/back-office/accounting/start-up-accounting");
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const submitContent = value;

    console.log(submitContent);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to update Initial Balance?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(submitContent);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
      },
    });
  };

  // HANDLE UPDATE INITIAL BALANCE
  const handleUpdate = async (value) => {
    const content = value;

    console.log("Handle Update --> ", content);

    await axios
      .put(
        `${baseurl}/accounting/balance-sheet`,
        {
          account_type_id: data?.account_type,
          account_name: data?.account_name,
          account_code: content?.chart_of_account
            ? content.chart_of_account
            : data?.account_code,
          normal_balance: data?.normal_balance,
          debit: data?.debit,
          credit: data?.credit,
          initial_balance: content?.initial_balance,
          total_balance: data?.total_balance,
          id: data?.id,
          updated_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Res Update Initial Balance --> ", response);
        successInitialBalance(response);

        form.resetFields();
      })
      .catch((error) => {
        console.log("Err Initial Balance --> ", error);
        failedInitialBalance(error);
      })
      .finally(() => {
        // successInitialBalance();
        fetchData();
      });
  };

  // GET COA
  const getDepositCoa = async (value, index) => {
    console.log("Value --> ", value, "Index --> " + index);

    form.setFieldsValue({
      chart_of_account: value,
    });
  };
  // GET COA BALANCE SHEET
  const getBalance = async (
    value,
    code,
    name,
    update,
    date,
    balance,
    debit,
    credit,
    id,
    normal,
    accountType,
    total
  ) => {
    // console.log("Value --> ", value, "Label --> " + code, "Name --> ", name);
    // console.log("Update by --> ", update, "Date --> " + date);
    // console.log("Balance --> ", balance, debit, credit);
    // console.log("Id --> ", id, normal, accountType);

    setView(true);
    setData({
      id: id,
      account_name: name,
      account_code: value,
      balance: balance,
      debit: debit,
      credit: credit,
      normal_balance: normal,
      account_type: accountType,
      total_balance: total,
    });

    form.setFieldsValue({
      chart_of_account: value,
      updated_by: date ? date : "Unset",
      updated_date: update
        ? update
        : // moment(update).format("DD-MM-YYYY") +
          //   " at " +
          //   moment(update).format("HH:mm:ss")
          "Unset",
    });
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="init-balance-main-layout">
            <Row className="row-title" justify="start" style={mainBody}>
              <RiMoneyDollarCircleFill
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Initial Balance`}
              </Typography>
            </Row>

            {/* <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Col
                xxl={12}
                lg={12}
                sm={24}
                style={{
                  textAlign: "start",
                  margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  Chart of Account
                </Typography>

                <Row>
                  <Input
                    allowClear
                    placeholder="Chart Number"
                    //   onChange={handleSearch}
                    style={{ width: 300 }}
                  />

                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    //   onClick={search}
                    style={{
                      margin: "0px 0px 0px 30px",
                    }}
                  >
                    Search
                  </Button>
                </Row>
              </Col>

              <Col
                className="results-search"
                // xxl={8}
                // lg={12}
                // sm={24}
                style={{
                  textAlign: "start",
                  margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>Results</Typography>

                <Input placeholder="Results" />
              </Col>
            </Row> */}

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-first"
                style={{ padding: 30 }}
              >
                <Typography
                  className="title-text"
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                    padding: "0px 30px 0px",
                  }}
                >
                  {`Pick an Account Code `}
                </Typography>
                {loading == true ? (
                  <>
                    <InputSkeleton is_loading={loading} />
                  </>
                ) : (
                  <Form
                    className="initial-balance-form"
                    name="initial-balance-form"
                    key="initial-balance-form"
                    autoComplete="false"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{ padding: "36px 36px 0px 36px" }}
                  >
                    <Row gutter={30} className="row-header">
                      <Form.Item
                        label="Chart of Accounts's Header"
                        name="chart_of_account"
                        rules={[
                          {
                            required: true,
                            message: "Please, Choose a Chart of Account!",
                          },
                        ]}
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterBalance
                          // getSheet={data?.deposit_coa ? data.deposit_coa : ""}
                          getSheet={getBalance}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Initial Balance"
                        name="initial_balance"
                        rules={[
                          {
                            required: true,
                            message: "Please, Input Initial Balances!",
                          },
                        ]}
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputNumber
                          addonBefore={currency}
                          //   min={0}
                          placeholder="Initial Balance"
                          formatter={(balance) => {
                            return ` ${balance}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            );
                          }}
                          style={{
                            width: "100%",
                          }}
                          // disabled
                        />
                        {/* <MasterCOA
                          coa_name={data?.deposit_coa ? data.deposit_coa : ""}
                          getCoaName={getDepositCoa}
                          is_all={true}
                        /> */}
                      </Form.Item>

                      <Form.Item
                        label="Last Update by"
                        name="updated_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated By" disabled />
                      </Form.Item>

                      <Form.Item
                        label="Last Update Date"
                        name="updated_date"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated Date" disabled />
                      </Form.Item>
                    </Row>

                    <Divider
                      className="divider-form"
                      style={{ margin: "15px 0px 10px" }}
                    />

                    <Row
                      justify="end"
                      align="middle"
                      // style={{ padding: "15px 0px 0px" }}
                      className="modal-btn-form"
                    >
                      <Button
                        className="submit-btn"
                        type="primary"
                        htmlType="submit"
                        style={{
                          backgroundColor: "#1BC5BD",
                          borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        {`Submit`}
                      </Button>

                      <Button
                        className="cancel-btn"
                        type="default"
                        onClick={handleCancel}
                      >
                        {`Cancel`}
                      </Button>
                    </Row>
                  </Form>
                )}
              </Col>

              <Col
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={0}
                xs={0}
                className="col-second"
              >
                <Typography
                  className="title-text"
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                    padding: "30px 30px 0px",
                  }}
                >
                  {`Account's Details `}
                </Typography>

                <Row
                  className="row-edited"
                  justify="start"
                  style={{ padding: "30px 0px 30px 30px" }}
                >
                  {view == true ? (
                    <>
                      <Col span={8}>
                        <Typography>{`Account Code: `}</Typography>
                        <Typography>{`Account Name: `}</Typography>
                        <Typography>{`Normal Balance: `}</Typography>
                        <Typography>{`Account Type: `}</Typography>
                        <Typography>{`Last Balance: `}</Typography>
                        <Typography>{`Debit: `}</Typography>
                        <Typography>{`Credit: `}</Typography>
                      </Col>

                      <Col span={16}>
                        <Typography
                          className="text-details"
                          style={styleText}
                        >{`${
                          data?.account_code
                            ? data.account_code
                            : "Failed to Load!"
                        }`}</Typography>

                        <Typography
                          className="text-details"
                          style={styleText}
                        >{`${
                          data?.account_name
                            ? data.account_name
                            : "Failed to Load!"
                        }`}</Typography>

                        <Typography
                          className="text-details"
                          style={styleText}
                        >{`${
                          data?.normal_balance == "0"
                            ? "DEBIT"
                            : data?.normal_balance == "1"
                            ? "CREDIT"
                            : "Failed to Load!"
                        }`}</Typography>

                        <Typography
                          className="text-details"
                          style={styleText}
                        >{`${
                          data?.account_type == 1
                            ? "ACTIVA"
                            : data?.account_type == 2
                            ? "PASSIVA"
                            : data?.account_type == 3
                            ? "SALE"
                            : data?.account_type == 4
                            ? "COST"
                            : data?.account_type == 5
                            ? "EXPENSE"
                            : "Failed to Load!"
                        }`}</Typography>

                        <Typography
                          className="text-details"
                          style={styleText}
                        >{`${
                          data?.balance > 0
                            ? `${currency} ${data.balance}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            : "Failed to Load!"
                        }`}</Typography>

                        <Typography
                          className="text-details"
                          style={styleText}
                        >{`${
                          data?.debit > 0
                            ? `${currency} ${data.debit}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            : "Failed to Load!"
                        }`}</Typography>

                        <Typography
                          className="text-details"
                          style={styleText}
                        >{`${
                          data?.credit > 0
                            ? `${currency} ${data.credit}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            : "Failed to Load!"
                        }`}</Typography>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Master Notification
import {
  failedRateCat,
  masterIncomplete,
  successRateCat,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Master
import MasterRoomCat from "../../../Reusable/Master/RoomCat/RoomCat";
import { mainBody } from "../../../../Style/Global/Global";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Global Styles

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function RateRoomCatTable(props) {
  // PROPS
  const {
    // Data
    getRateCode,
    getRateRoom,
    // Set Render
    is_render,
    is_rerender,
  } = props;

  // CONTEXT
  const {
    getMasterRateRoomCat,
    getRoomCat,
    getMasterRoomCat,
    getMasterSegmentRoom,
  } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  // Rate
  const [rateCode, setRateCode] = useState(null);
  // Room Category
  const [optionValue, setOptionValue] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [isDeleted, setIsDeleted] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [totalRoomCat, setTotalRoomCat] = useState(0);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    // setLoading(true);

    await getMasterSegmentRoom({
      segment_compl: "master-segment-compliment",
      segment: rateCode?.main_segment_id,
      // sub_segment_id: rateCode?.sub_segment_id,
      rate_code: rateCode?.rate_code_id,
      onAwait: () => {
        setLoading(true);
        // ("on Await");
      },
      onSuccess: (response) => {
        console.log("Rate Room Category >> ", response);

        if (response?.data?.msg != null) {
          let filtered = response.data.msg.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) {
              return val;
            }
          });

          setData(filtered);
        } else {
          setData([]);
        }

        // setFilteredList(filtered);
        setLoading(false);
        // console.log("ISI Room List >> ", filtered);
      },
      onReject: (error) => {
        failedFetch(error);
        setLoading(false);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // FETCH ROOM
  const fetchRoomCat = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          // FILTER IS DELETED
          let filtered = response?.data?.msg?.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          // CREATE OPTION
          let option = filtered.map((dev) => ({
            value: dev?.room_category_name,
            label: dev?.room_category_name,
            code: dev?.room_category_code,
          }));

          // SET OPTION VALUE ROOM CAT
          setOptionValue(option);

          // Length Count
          setTotalRoomCat(filtered?.length);
        } else {
          setOptionValue([]);
          setTotalRoomCat(0);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let is_fetch = false;

    if (rateCode && !is_fetch) {
      console.log(rateCode);

      fetchData();
      fetchRoomCat();

      setFieldForms();
    }

    return () => {
      console.log("Cleaning Up useEffect...");
      is_fetch = true;
    };
  }, [rateCode]);

  // USE EFFECT SELECTOR
  useEffect(() => {
    let cleanUp = false;

    if (getRateCode && !cleanUp) {
      console.log("In Category --> ", getRateCode);

      setRateCode(getRateCode);
    }

    if (is_render == true && !cleanUp) {
      setData([]);
      setRateCode(null);
      is_rerender(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [getRateCode, is_render]);

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-room-rate-cat-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);

                      setFieldEdit(record);
                      setIsDeleted(false);
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      setIsDeleted(true);
                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
    },
    {
      title: "RO Price",
      dataIndex: "ro_price",
      key: "ro_price",
      render: (price, record) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "RBF Price",
      dataIndex: "rbf_price",
      key: "rbf_price",
      render: (price, record) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // SET FIELDS FORMS
  const setFieldForms = async () => {
    form.setFieldsValue({
      main_segment: rateCode?.segmentName?.main_segment
        ? rateCode.segmentName.main_segment
        : "Unknown",
      sub_segment: rateCode?.segmentName?.sub_segment
        ? rateCode.segmentName.sub_segment
        : "Not Selected",
      main_segment_id: rateCode?.main_segment_id
        ? rateCode.main_segment_id
        : "Unknown",
      sub_segment_id: rateCode?.sub_segment_id
        ? rateCode.sub_segment_id
        : "Not Selected",
      rate_code: rateCode?.rate_code ? rateCode.rate_code : "Unknown",
      compliment_type: "No Compliment",
    });
  };

  // SET FIELDS EDIT
  const setFieldEdit = (record) => {
    const fields = record;

    console.log("Set Edit --> ", fields);

    form.setFieldsValue({
      room_category_name: fields?.rate_code_name || null,
      rbf_price: fields?.rbf_price || 0,
      unit_price: fields?.ro_price || 0,
      created_by: fields?.created_by || null,
      created_date: fields?.created_date || null,
      updated_by: fields?.updated_by || null,
      updated_date: fields?.updated_date || null,
    });
  };

  // Get Room Category Code
  const onChangeRoomCat = (value, code) => {
    // console.log(value);
    console.log(code);
    form.setFieldsValue({
      room_category_name: code,
    });
  };
  const getRoomCode = (value, code) => {
    // console.log(value);
    console.log(code);

    if (edit) {
      editData.room_category_code = code;
    }

    form.setFieldsValue({
      room_category_code: code,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);

    setFieldForms();
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setIsDeleted(true);
    setEditData(null);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    const content = e;
    console.log(content);
    // handleSubmit(e);

    showModalConfirm(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    // console.log(e);
    masterIncomplete(e);
  };

  // HANDLE SELECT
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      getRateRoom(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // SHOW MODAL DELETE
  const showModalConfirm = async (record) => {
    const deleteContent = record;

    confirm({
      className: "delete-guest-confirm",
      title:
        isDeleted == true
          ? `Are you sure want to delete Rate Room Category ${deleteContent?.room_category_name.toUpperCase()} from the table?`
          : `Are you sure want to ${
              edit == true ? "Update a " : "Add a New  "
            } Rate Room Category ${deleteContent?.room_category_name.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: isDeleted == true ? "Delete" : "Confirm",
      okType: isDeleted == true ? "danger" : "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDeleted == true
          ? handleDelete(deleteContent)
          : handleSubmit(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (val) => {
    const contentSubmit = val;

    console.log("About to SUBMIT => ", contentSubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/room/rate/category`,
          {
            rate_code_id: rateCode?.rate_code_id,
            rate_code_name: rateCode?.rate_code,
            room_category_code: contentSubmit?.room_category_code,
            room_category_name: contentSubmit?.room_category_name,
            ro_price: contentSubmit?.unit_price || 0,

            main_segment_id:
              contentSubmit?.main_segment_id || rateCode?.main_segment_id,
            sub_segment_id:
              contentSubmit?.sub_segment_id || rateCode?.sub_segment_id,
            main_compliment_id: rateCode?.main_compliment_id,
            rbf_price: contentSubmit?.rbf_price || 0,

            description: null,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Add Rate RC => ", response);

          successRateCat(response, { method: 0 });
          handleCancel();
        })
        .catch((error) => {
          console.log("Add Rate RC => ", error);

          failedRateCat(error, { method: 0 });
        })
        .finally(() => {
          fetchData();
        });
    } else {
      await axios
        .put(
          `${baseurl}/room/rate/category`,
          {
            id: editData.id,
            rate_code_id: editData?.rate_code_id,
            rate_code_name: editData?.rate_code_name,
            room_category_code: editData?.room_category_code,
            room_category_name: contentSubmit.room_category_name,
            description: null,
            ro_price: contentSubmit?.unit_price || 0,

            main_segment_id:
              rateCode?.main_segment_id || editData?.main_segment_id,
            sub_segment_id:
              rateCode?.sub_segment_id || editData?.sub_segment_id,
            main_compliment_id:
              rateCode?.main_compliment_id || editData?.main_compliment_id,
            rbf_price: contentSubmit?.rbf_price || editData?.rbf_price,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Update Rate RC => ", response);
          successRateCat(response, { method: 1 });
          handleCancel();
        })
        .catch((error) => {
          console.log("Update Rate RC => ", error);
          failedRateCat(error, { method: 1 });
        })
        .finally(() => {
          fetchData();
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (value) => {
    const contentDelete = value.id;

    console.log("content delete >>", contentDelete);

    var obj = {
      ["id"]: contentDelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/room/rate/category`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Rate RC => ", response);
        successRateCat(response, { method: 2 });
      })
      .catch((error) => {
        console.log("DEL Rate RC => ", error);
        failedRateCat(error, { method: 2 });
      })
      .finally(() => {
        fetchData();
      });
  };

  // console.log("Di Room Cat => ", rateCode);
  // console.log("Modal BTN => ", !rateCode && data?.length >= length);

  return (
    <>
      <Row
        className="rate-room-cat-main-table"
        justify="center"
        style={mainBody}
      >
        <Col
          className="col-table"
          span={24}
          style={{
            padding: "30px 30px 15px",
          }}
        >
          <Row>
            <Col span={12}>
              <Row justify="start" align="top">
                <Typography
                  key={"rate-segment-desc"}
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "#3F4254",
                    padding: "0px 30px 0px 0px",
                  }}
                >
                  {`Segments: ${
                    rateCode?.segmentName?.main_segment
                      ? rateCode?.segmentName?.main_segment.toUpperCase()
                      : " - "
                  }`}
                </Typography>

                <Typography
                  key={"rate-code-desc"}
                  style={{ fontWeight: 600, fontSize: 20, color: "#3F4254" }}
                >
                  {`Rate Code: ${
                    rateCode?.rate_code
                      ? rateCode?.rate_code.toUpperCase()
                      : " - "
                  }`}
                </Typography>
              </Row>
            </Col>

            <Col span={12} className="col-modal-btn">
              <Row justify="end">
                <Button
                  className="submit-btn"
                  disabled={
                    rateCode == null || data?.length >= totalRoomCat
                      ? true
                      : false
                  }
                  onClick={handleOpen}
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: "#1BC5BD",
                    borderColor: "#1BC5BD",
                    marginRight: 15,
                  }}
                >
                  {`Add Room Category`}
                </Button>

                <Button
                  className="refresh-btn"
                  disabled={rateCode == null ? true : false}
                  onClick={fetchData}
                  type="default"
                  icon={<SyncOutlined />}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>

            <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
              <Table
                className="rate-room-cat-table"
                name="rate-room-cat-table"
                key="rate-room-cat-table"
                loading={loading}
                // rowSelection={{
                //   type: "radio",
                //   ...rowSelection,
                // }}
                bordered
                size="small"
                columns={columns}
                dataSource={data}
                pagination={{
                  defaultPageSize: 5,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} entries`,
                }}
                rowKey={(record) => (record.id ? record.id : record.key)}
                rowClassName={(record, index) => {
                  return index % 2 == 0 ? "odd" : "even";
                }}
              />
            </Row>
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-room-rate-cat-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                Add Room Category
              </Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                Edit Room Category
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          name="room-rate-cat-art-modal-form"
          className="room-rate-cat-art-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          onFinishFailed={onFinishFailed}
          style={{ padding: "15px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-room-segment">
            <Col span={12}>
              <Form.Item
                label="Main Segment"
                name="main_segment"
                style={{ marginRight: 30, minWidth: "100%" }}
              >
                <Input placeholder="Main Segment" disabled />
              </Form.Item>

              <Form.Item label="Main Segment ID" name="main_segment_id" hidden>
                <Input placeholder="Main Segment ID" disabled />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Form.Item
                label="Sub Segment"
                name="sub_segment"
                style={{ minWidth: "100%" }}
              >
                <Input placeholder="Sub Segment" disabled />
              </Form.Item>

              <Form.Item label="Sub Segment ID" name="sub_segment_id" hidden>
                <Input placeholder="Sub Segment ID" disabled />
              </Form.Item>
            </Col> */}
          </Row>

          <Row gutter={30}>
            <Col span={12}>
              <Form.Item
                label="Compliment Type"
                name="compliment_type"
                style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
              >
                <Input placeholder="Compliment Type" disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Rate Code"
                name="rate_code"
                style={{ minWidth: "100%" }}
              >
                <Input placeholder="Rate Code" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-room-rate-cat-id">
            <Col span={16}>
              <Form.Item
                label="Room Category"
                name="room_category_name"
                rules={[
                  {
                    required: true,
                    message: "Please, select a Room Category!",
                  },
                ]}
                style={{ marginRight: 30, width: 440 }}
              >
                {/* <Input placeholder="Room Category" /> */}
                <MasterRoomCat
                  onChangeRoomCat={onChangeRoomCat}
                  onChangeCode={getRoomCode}
                  options={optionValue}
                  // name={name}
                  disable={false}
                  cat={
                    editData?.room_category_name
                      ? editData.room_category_name
                      : null
                  }
                />
              </Form.Item>
            </Col>

            <Form.Item
              label="Room Category"
              name="room_category_code"
              hidden
              style={{ marginRight: 30, width: 440 }}
            >
              <Input placeholder="Room Category Code" />
            </Form.Item>
          </Row>

          <Row className="row-price" gutter={30}>
            <Col span={8}>
              <Form.Item
                label="Room Only Price"
                name="unit_price"
                rules={[
                  {
                    required: true,
                    message: "Please, input unit price!",
                  },
                ]}
              >
                <InputNumber
                  addonBefore={currency}
                  placeholder="Price"
                  formatter={(unit_price) => {
                    return `${unit_price}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{ width: 205 }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Room Breakfast Price"
                name="rbf_price"
                rules={[
                  {
                    required: true,
                    message: "Please, input unit price!",
                  },
                ]}
              >
                <InputNumber
                  addonBefore={currency}
                  placeholder="Price"
                  formatter={(unit_price) => {
                    return `${unit_price}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{ width: 205 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-room-rate-cat">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="updated_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-room-rate-cat">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              Submit
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import TransferSummaryForm from "../../../../../../Forms/TransferSummary/Form";

export default function TransferSummaryReportTable(props) {
  // PROPS
  const { _main, _fromStorage, _toStorage, _startDate, _endDate, _type } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [types, setTypes] = useState(null);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    qty_requested: 0,
    qty_received: 0,
    amount: 0,
    mtd_qty: 0,
    mtd_amt: 0,
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);
  const typeName = _type == 1 ? "Article" : _type == 2 ? "Storage" : null;

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (_main && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setTotal({
        qty_requested: 0,
        qty_received: 0,
        amount: 0,
        mtd_qty: 0,
        mtd_amt: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_main]);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (_fromStorage && _toStorage && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setTotal({
        qty_requested: 0,
        qty_received: 0,
        amount: 0,
        mtd_qty: 0,
        mtd_amt: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_fromStorage, _toStorage]);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (_type !== types && !cleanUp) {
      setTypes(_type);
      setData([]);
      setTotal({
        qty_requested: 0,
        qty_received: 0,
        amount: 0,
        mtd_qty: 0,
        mtd_amt: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_type]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    let bodyReq = {};

    if (_fromStorage && _toStorage) {
      bodyReq = {
        from_warehouse: _fromStorage.id_warehouse,
        to_warehouse: _toStorage.id_warehouse,
        from_date: moment(_startDate).format("YYYY-MM-DD"),
        to_date: moment(_endDate).format("YYYY-MM-DD")
      };
    } else if (_main) {
      bodyReq = {
        article_main_id: _main.main_id,
        from_date: moment(_startDate).format("YYYY-MM-DD"),
        to_date: moment(_endDate).format("YYYY-MM-DD")
      };
    }

    await getByPostInventoryReport({
      type: `transfer-summary`,
      bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
              amount: item.qty_received * item.avg_price,
            };
          });

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      qty_requested: prev.qty_requested + curr.qty_requested,
                      qty_received: prev.qty_received + curr.qty_received,
                      amount: prev.amount + curr.amount,
                      mtd_qty: prev.mtd_qty + curr.mtd_qty,
                      mtd_amt: prev.mtd_amt + curr.mtd_amt,
                    };
                  },
                  { qty_requested: 0, qty_received: 0, amount: 0, mtd_qty: 0, mtd_amt: 0 }
                )
              : { qty_requested: 0, qty_received: 0, amount: 0, mtd_qty: 0, mtd_amt: 0 };

          setData(filter);
          setTotal(sumTotal);
        } else {
          setData([]);
          setTotal({
            qty_requested: 0,
            qty_received: 0,
            amount: 0,
            mtd_qty: 0,
            mtd_amt: 0,
          });
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Transfer Summary Reports ${
        _startDate ? moment(_startDate).format("YYYY-MM-DD") : ""} - ${
          _endDate ? moment(_endDate).format("YYYY-MM-DD") : ""}`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "From Storage",
      dataIndex: "from_storage",
      key: "from_storage",
    },
    {
      title: "To Storage",
      dataIndex: "to_storage",
      key: "to_storage",
    },
    {
      title: "Article Main Group",
      dataIndex: "article_main_name",
      key: "article_main_name",
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Quantity Requested",
      dataIndex: "qty_requested",
      key: "qty_requested",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Received",
      dataIndex: "qty_received",
      key: "qty_received",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MTD Qty",
      dataIndex: "mtd_qty",
      key: "mtd_qty",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MTD Amount",
      dataIndex: "mtd_amt",
      key: "mtd_amt",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ].filter(item => !item.hidden);;

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.qty_requested}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.qty_received}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.amount.toFixed(0)}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.mtd_qty}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.mtd_amt.toFixed(0)}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <TransferSummaryForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _startDate = {_startDate}
        _endDate = {_endDate}
        _type = {_type}
        _main = {_main ? _main : null}
        _fromStorage = {_fromStorage ? _fromStorage : null}
        _toStorage = {_toStorage ? _toStorage : null}
        _total = {total}
      />
    </>
  );
}

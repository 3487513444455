// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import { token } from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tooltip,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";

// Import Page Components
// import ArrangementArticleTable from "../ArrangementArticle/Table";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedArrange,
  masterIncomplete,
  successArrange,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function ArrangementTable({
  // Selected Data Arrangement
  dataArrangement,
  searchKey,
  is_search,
}) {
  // Table's Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      // fixed: "left",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();

                      setEdit(true);
                      setDataEdit(record);

                      handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "arrangement_id",
    //   key: "arrangement_id",
    //   fixed: "left",
    //   width: 75,
    // sortDirections: ["descend", "ascend"],
    // sorter: (a, b) => a.id - b.id,
    // },
    {
      title: "Name",
      dataIndex: "arrangement_name",
      key: "arrangement_name",
      width: 200,
      // fixed: "left",
    },
    {
      title: "Code",
      dataIndex: "arrangement_code",
      key: "arrangement_code",
      width: 75,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ];

  // CONTEXT
  const { getMasterArrangement } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // ID Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterArrangement({
      arrange: "arrangement",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ARRANGEMENT => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          console.log(filtered);

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE CHECKBOX
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`);
      // console.log("selectedRows: ", selectedRows);

      dataArrangement(selectedRows);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === "Disabled User",
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };

  // Handle Field Forms
  const handleFormField = (value) => {
    const record = value;

    form.setFieldsValue({
      arrangement_id: record?.arrangement_id,
      arrangement_code: record?.arrangement_code,
      arrangement_name: record.arrangement_name,
      description: record?.description,
      id: record?.id,

      created_date: record?.created_date,
      created_by: record?.created_by,
      modified_date: record?.updated_date,
      modified_by: record?.updated_by,
      // deleted_by: null,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);

    setIsDelete(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setDataEdit(null);

    setIsDelete(true);
    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (record) => {
    const _submit = record;

    console.log("About to SUBMIT => ", _submit);

    confirm({
      className: "delete-guest-confirm",
      title: isDelete
        ? `Are you sure want to Delete Arrangement with named ${
            _submit?.arrangement_name.toUpperCase() || " --- "
          }?`
        : `Are you sure want to ${
            edit ? "Update " : "Add a New "
          } Arrangement with named ${
            _submit?.arrangement_name.toUpperCase() || " --- "
          }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(_submit) : handleSubmit(_submit);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    setIsLoading(true);

    showModalConfirm(content);
    // console.log("FINISH => ", value);

    // handleSubmit(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    // console.log(e);

    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    console.log("ON Submit Arrangement ==> ", contentsubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/master/arrangement`,
          {
            arrangement_id: contentsubmit?.arrangement_id,
            arrangement_code: contentsubmit?.arrangement_code,
            arrangement_name: contentsubmit?.arrangement_name,
            description: contentsubmit?.description,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log(response);

          successArrange(response, { method: 0 });
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log(error);

          failedArrange(error, { method: 0 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/arrangement`,
          {
            arrangement_id: contentsubmit?.arrangement_id,
            arrangement_code: contentsubmit.arrangement_code,
            arrangement_name: contentsubmit.arrangement_name,
            description: contentsubmit.description,

            id: dataEdit?.id,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log(response);

          successArrange(response, { method: 1 });
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log(error);
          failedArrange(error, { method: 1 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record.id;

    console.log(contentdelete);

    var obj = {
      ["id"]: contentdelete,
    };

    await axios
      .delete(`${baseurl}/master/arrangement`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log(response);

        successArrange(response, { method: 2 });
        fetchData();
      })
      .catch((error) => {
        failedArrange(error, { method: 2 });
        console.log(error);
      });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.arrangement_name.toLowerCase();
        // console.log("ITEM => ", item);

        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row className="arrangement-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row justify="star" className="row-modal-btn">
            {/* <Col span={12} className="col-exp-btn">
              <Button
                className="export-btn"
                type="primary"
                onClick={<></>}
                icon={<FileExcelFilled />}
                style={{
                  marginRight: 15,
                }}
              >
                Download Excel
              </Button>
            </Col> */}

            <Col span={24} className="col-modal-btn">
              <Row
                justify="end"
                className="row-modal-btn"
                gutter={[0, 15]}
                align="top"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Arrangement`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="arrangement-table"
              name="arrangement-table"
              key="arrangement-table"
              loading={loading}
              bordered
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              columns={columns}
              dataSource={data}
              size="small"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 750,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="arrangement-modal"
        key="arrangement-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Arrangement`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Arrangement`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          name="arrange-modal-form"
          className="arrange-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={[30, 30]} className="row-arrangement-id">
            <Col span={8}>
              <Form.Item
                label="Arrangement ID"
                name="arrangement_id"
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Arrangement ID" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Code"
                name="arrangement_code"
                rules={[
                  {
                    required: true,
                    message: "Please, input Arrangement Code!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Arrangement Code" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Name"
                name="arrangement_name"
                rules={[
                  {
                    required: true,
                    message: "Please, input Arrangement Name!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Arrangement Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-arrangement-desc">
            <Col span={16} className="desc-col">
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  showCount
                  maxLength={200}
                  allowClear
                  rows={4}
                  placeholder="Descriptions"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-arrangement">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-arrangement">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row className="row-modal-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";

// NOTIFICATIONS
import { failedFetch } from "../Notification/Notification";

// ANTD COMPONENTS
import { Button, Col, Row, Select } from "antd";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

export default function BillList({
  label,
  value,
  type,
  status,
  getBill,
  index,
  keyBill,
}) {
  // CONTEXT
  const { getReservationBySource } = useContext(MainContext);

  // State
  const [optionValue, setOptionValue] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  // Loading
  const [loading, setLoading] = useState(false);

  // USE EFFECT FETCHDATA
  useEffect(() => {
    fetchData();
  }, [value]);

  //  FETCH DATA MASTER FOLIO BILL
  const fetchData = async () => {
    await getReservationBySource({
      reservation: "bill",
      type: "master-folio",
      source: [value],
      // source: typeof source == "object" ? [...source] : [source],
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          console.log("Response Master Folio => ", _response);

          // Handle duplicate response
          const filterUniqueReservations = (data) => {
            const uniqueReservations = [];

            data.forEach((item) => {
              if (
                !uniqueReservations.some(
                  (reservation) =>
                    reservation.reservation_id === item.reservation_id
                )
              ) {
                uniqueReservations.push(item);
              }
            });

            return uniqueReservations;
          };

          const _filtered = filterUniqueReservations(_response);
          // const filterShowData = _filtered.filter((val) => val.is_show);

          const mappedOptions = _filtered.map((item) => {
            let _status = "";

            switch (item?.payment_status) {
              case 1:
                _status = "Charge To Room";
                break;
              case 2:
                _status = "Cash Basis";
                break;

              default:
                _status = "-";
                break;
            }

            return {
              label: `${item?.reservation_id} - ${item?.guest_name
                ?.replace(/\d+$/, "")
                .trim()} `,
              value: item?.reservation_id,
              reservation_id: item?.reservation_id,
              guest_id: item?.guest_id,
              guest_name: item?.guest_name,
              start_date: item?.start_date,
              end_date: item?.end_date,
              payment_status: _status,
              payment_details: item?.payment_details,
              payment_type: item?.payment_type,
            };
          });

          setOptionValue(mappedOptions);
        } else {
          setOptionValue([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // HANDLE CHANGE SELECTOR
  const handleChange = (value, allKeys) => {
    console.log("Bill List All Keys ->> ", allKeys);

    setSelectedValues((prev) => ({
      ...prev,
      [label]: allKeys, // Simpan hasil selector berdasarkan label category
    }));

    getBill((prev) => ({
      ...prev,
      [label]: allKeys?.length > 0 ? allKeys : [allKeys], // Simpan hasil selector berdasarkan label category
    }));

    // console.log("Selected Values ->> ", selectedValues);
    // getBill(selectedValues);
  };

  // HANDLE CLEAR
  const handleClear = () => {
    setSelectedValues({});
    getBill({});
  };
  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={24} className="select-col">
          <Select
            key={keyBill + index}
            className="bill-selector"
            style={{ width: "100%" }}
            placeholder="Bill"
            showSearch
            allowClear
            mode={
              status === 1 && type === 2
                ? "multiple"
                : status === 2 && type === 2 && value === 1
                ? null
                : type !== 2
                ? null
                : "multiple"
            }
            onChange={handleChange}
            // onClear={handleClear}
            options={optionValue}
          />
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL sTYLES
import { cardHeader, mainBody } from "../../../../Style/Global/Global";

// REACT ICONS
import { HiMiniChartBar } from "react-icons/hi2";
import { IoReturnUpBackOutline } from "react-icons/io5";

export default function MonthlyReportParams() {
  // USE NAVIGATE & LOCATIONS
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // HANDLE MOVE
  const move = (e) => {
    navigate(`${pathname}/${e}`);
  };

  // HANDE RETURN
  const moveReturn = () => {
    const _returnPath = "/configuration/parameters/general-report-params";
    console.log("Pathname: ", pathname);

    navigate(`${_returnPath}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="monthly-report-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <HiMiniChartBar
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Monthly Report Parameters & Settings`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row
              className="row-title"
              justify="start"
              align="middle"
              // gutter={[30, 30]}
              style={mainBody}
            >
              <Col
                className="col-second"
                span={24}
                style={{
                  padding: 30,
                  display: "flex",
                  gap: 5,
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}
              >
                <Row
                  className="return row"
                  justify="end"
                  align="top"
                  gutter={[15, 30]}
                >
                  <Button
                    type="default"
                    className="cancel-btn"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={moveReturn}
                  >{`Return`}</Button>
                </Row>

                <Card
                  className="masters-card-header"
                  bordered
                  style={{
                    margin: "30px 0px 0px",
                    border: "2px solid #f0f0f0",
                    borderRadius: 5,
                  }}
                >
                  <Card
                    className="card-link"
                    bordered
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          fontSize: 25,
                        }}
                      >{`Main Balance Sheet`}</Typography>
                    }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                    }}
                  >
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("top-balancesheet")}
                        >{`Go to Top Page`}</Button>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("main-balancesheet")}
                        >{`Go to Main Page`}</Button>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Headers`}
                      </Typography>
                    }
                    bordered
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() =>
                            move("report-master-balancesheet-headers")
                          }
                        >
                          {`Go to Master's Page`}
                        </Button>
                      </Col>

                      {/* <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="params-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                        >{`Go to Parameter's Page`}</Button>
                      </Col> */}
                    </Row>
                  </Card>

                  <Card
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Details`}
                      </Typography>
                    }
                    bordered
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() =>
                            move("report-master-balancesheet-details")
                          }
                        >
                          {`Go to Master's Page`}
                        </Button>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="params-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() =>
                            move("report-master-balancesheet-details-params")
                          }
                        >{`Go to Parameter's Page`}</Button>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    className="card-link"
                    bordered
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          fontSize: 25,
                        }}
                      >{`Main Profit & Loss`}</Typography>
                    }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      marginTop: 30,
                    }}
                  >
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("report-profit-and-loss-main")}
                        >{`Go to Page`}</Button>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Headers`}
                      </Typography>
                    }
                    bordered
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("report-profit-and-loss-header")}
                        >
                          {`Go to Master's Page`}
                        </Button>
                      </Col>

                      {/* <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="params-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                        >{`Go to Parameter's Page`}</Button>
                      </Col> */}
                    </Row>
                  </Card>

                  <Card
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Details`}
                      </Typography>
                    }
                    bordered
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("report-profit-and-loss-detail")}
                        >
                          {`Go to Master's Page`}
                        </Button>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="params-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("pnl-detail-params")}
                        >{`Go to Parameter's Page`}</Button>
                      </Col>
                    </Row>
                  </Card>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Typography,
  Image,
  Table,
} from "antd";

// Import React Icons
import { FormOutlined, PrinterOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

// Import Page Components
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ReceivingForm({
  // Modal State
  openModal,
  closeModal,
  // Data Receiving
  dataReceiving,
}) {
  // STATE MANAGEMENT

  // Use Ref
  const componentRef = useRef();

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity_received",
      key: "quantity_received",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Delivery Unit",
      dataIndex: "d-unit",
      key: "d-unit",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        return `Rp. ${price ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      render: (total, record) => {
        return `Rp. ${total ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // const { getBillHeader, getBillDetail } = useContext(FinanceContext);

  // Data
  const [data, setData] = useState(null);
  const [details, setDetails] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if ((openModal == true || dataReceiving?.length > 0) && !cleanUp) {
      setOpen(true);

      setData(dataReceiving);
      setDetails(dataReceiving?.details);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataReceiving]);

  // ON FINISH
  const onFinish = () => {
    handlePrint();
  };

  // SEQUENTIAL BUTTON SUBMIT
  const [counter, setCounter] = useState(0);
  const myclick = () => {
    setCounter((counter) => counter + 1);
    if (counter == 0);
    else if (counter == 1) {
      //   move();
      setCounter(0);
    }
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setCounter(0);
    setData([]);
    // setEdit(null);

    form.resetFields();
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    // PrintCount({
    //   data: data,
    //   is_update: true,
    //   counter_list: {
    //     bill: countPrint?.bill + 1,
    //     pre_master_bill: countPrint?.pre_master_bill,
    //     pre_bill: countPrint?.pre_bill,
    //     checkout_letter_history: countPrint?.checkout_letter_history,
    //     registration_form: countPrint?.registration_form,
    //     guarantee_letter: countPrint?.guarantee_letter,
    //     thankyou_letter: countPrint?.thankyou_letter,
    //     master_bill: countPrint?.master_bill,
    //     reservation_letter: countPrint?.reservation_letter,
    //   },
    // });

    handlePrintOut();
  };

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `PO Form ${
      data?.po_number ? data.po_number : " ---- "
    } (${moment().format("DD-MM-YYYY")})`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  // CONSOLE LOG
  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row>
              <FormOutlined style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                View Receiving Report (RR) Form
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          labelWrap={{
            span: 12,
          }}
          wrapperCol={{
            span: 16,
          }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          autoComplete="off"
          name="bill-form-modal"
          key="bill-form-modal"
          className="bill-form-modal"
          layout="horizontal"
        >
          <Content
            ref={componentRef}
            className="content-receipt-form"
            style={{
              padding: "30px 80px 30px 80px",
            }}
          >
            <Row gutter={30} className="row-header">
              <Col span={12} className="col-title-form">
                <Typography.Title level={2} className="text-form-title">
                  {`Receiving Report (RR) Form`}
                </Typography.Title>
              </Col>

              <Col span={12} align="middle" className="col-img-logo">
                <Image
                  src="/looklogo(color).png"
                  className="img-form-logo"
                  preview={false}
                  style={{
                    width: "25%",
                  }}
                />

                <Typography.Title level={4} className="text-form-id">
                  {`Look Hotel Bandung`}
                </Typography.Title>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-receipt-no"
              style={{
                height: 70,
                width: "100%",
                margin: 0,
              }}
            >
              <Col span={12} className="col-receipt">
                <Form.Item
                  label="Doc No."
                  className="receipt_number"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-bill-id" style={textForm}>
                    {data?.document_number ? data.document_number : ""}
                  </Typography>
                </Form.Item>
                <Form.Item
                  label="Received Date"
                  className="date"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-trx-date" style={textForm}>
                    {data?.created_date
                      ? moment(LocalizationDate(data.created_date)).format(
                          "DD-MM-YYYY, HH:mm"
                        )
                      : ""}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>

            <Row className="row-payment-title" justify="start">
              <Col span={12}>
                <Typography
                  className="text-payment"
                  style={{
                    margin: "30px 0px 0px",
                    fontSize: 14,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Receiving Report Details`}
                </Typography>
              </Col>
              <Col span={12}>
                <Typography
                  className="text-payment"
                  style={{
                    margin: "30px 0px 0px",
                    fontSize: 14,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Supplier Details`}
                </Typography>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-guest-details"
              style={{
                width: "100%",
                backgroundImage:
                  countPrint?.bill >= 1
                    ? "url(/assets/logos/copyopac.png)"
                    : null,
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
                backgroundPosition: "center",
                // transform: "rotate(45deg)",
              }}
            >
              <Col span={12} className="col-pay-type">
                <Form.Item
                  label="PO Number"
                  className="po_number"
                  style={{ margin: 0 }}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Typography className="text-payment-type" style={textForm}>
                    {`${data?.po_number ? data.po_number : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Store"
                  className="department_name"
                  style={{ margin: 0 }}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${data?.warehouse_name ? data.warehouse_name : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="PO Last Delivery Date"
                  className="delivery_date"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-trx-date" style={textForm}>
                    {data?.po_last_delivery_date
                      ? moment(
                          LocalizationDate(data.po_last_delivery_date)
                        ).format("DD-MM-YYYY")
                      : ""}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  className="Remarks"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${data?.remarks ? data.remarks : ""}`}
                  </Typography>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Supplier Name"
                  className="supplier_name"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.supplier_name ? data.supplier_name : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Supplier Address"
                  className="supplier_address"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.supplier_address ? data.supplier_address : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Contact Person"
                  className="contact_person"
                  style={{ margin: 0 }}
                  // labelCol={{ span:  }}
                  // wrapperCol={{ span: 20 }}
                >
                  <Typography className="text-guest-name" style={textForm}>
                    {`${
                      data?.contact_person_supplier
                        ? data.contact_person_supplier
                        : ""
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Email"
                  className="email"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.email_supplier ? data.email_supplier : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Phone"
                  className="phone"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.cp_phone_number ? data?.cp_phone_number : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Fax"
                  className="fax"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.telefax ? data?.telefax : ""}`}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="start">
              <Typography
                style={{
                  margin: 0,
                  fontSize: 14,
                  fontWeight: 750,
                }}
              >
                Buying List
              </Typography>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-table"
              justify="start"
              style={{ padding: "10px 0px" }}
            >
              <Table
                className="transaction-table"
                key="transaction-table"
                name="transaction-table"
                loading={loading}
                columns={columns}
                dataSource={details}
                bordered={true}
                size="small"
                style={{
                  padding: 5,
                  border: "1px solid #000000",
                }}
                pagination={false}
                rowKey={(record) => (record.id ? record.id : record.id_article)}
                summary={(pageData) => {
                  let totalPrice = 0;
                  let tax = 0;
                  let service = 0;

                  pageData.forEach((price) => {
                    totalPrice += price.price * price.quantity_received;
                    // tax = totalPrice * 0.11;
                    service = totalPrice * 0.1;
                  });

                  tax = (totalPrice + service) * 0.11;

                  return (
                    <>
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} />

                          <Table.Summary.Cell index={3}>
                            {/* <b>Subtotal</b> */}
                            <b>TOTAL</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            {`Rp. ${totalPrice}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        {/* <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} />

                          <Table.Summary.Cell index={3}>
                            <b>Service</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            {`Rp. ${service}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} />

                          <Table.Summary.Cell index={3}>
                            <b>Tax</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            {`Rp. ${tax}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row> */}

                        {/* <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} />

                          <Table.Summary.Cell index={3}>
                            <b style={{ fontSize: 12 }}>Total Payment</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            <b style={{ fontSize: 12 }}>
                              {`Rp. ${totalPrice + tax + service}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row> */}
                      </Table.Summary>
                    </>
                  );
                }}
              />
            </Row>

            <Typography className="text-notes" style={{ fontSize: 10 }}>
              *notes: please, keep this form as warranty!
            </Typography>

            <Row
              className="signature"
              justify="end"
              style={{ padding: "30px 0px 0px" }}
              gutter={[50, 0]}
            >
              <Col
                span={12}
                style={{
                  textAlign: "center",
                }}
              >
                {`Receiving Signature`}

                <div
                  className="box-sign"
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 5,
                    height: 60,
                  }}
                />

                {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
              </Col>

              <Col
                span={12}
                style={{
                  textAlign: "center",
                }}
              >
                {`Approval Signature`}

                <div
                  className="box-sign"
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 5,
                    height: 60,
                  }}
                />

                {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
              </Col>
            </Row>

            <Row
              className="desc"
              // justify="end"
              style={{ padding: "30px 0px 0px" }}
              gutter={[50, 0]}
            >
              <Col
                span={24}
                style={{
                  textAlign: "start",
                }}
              >
                {`Descriptions`}

                <div
                  className="desc"
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 5,
                    minHeight: 60,
                  }}
                />

                {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
              </Col>
            </Row>
          </Content>

          <>
            <Row
              justify="end"
              align="middle"
              className="row-form-btn"
              style={{ padding: "0px 0px 0px 0px" }}
            >
              <Divider
                className="divider-form"
                style={{ margin: "15px 0px" }}
              />

              <Button
                className="print-btn"
                type="primary"
                htmlType="submit"
                icon={<PrinterOutlined />}
                // onClick={() => {
                //   handlePrint();
                //   if (counter == 0) {
                //     myclick();
                //   }
                // }}
                style={{
                  // backgroundColor: "#1BC5BD",
                  // borderColor: "#1BC5BD",
                  marginRight: 30,
                }}
              >
                Print & Download
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Row>
          </>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function OptionRoomCategory(category) {
  // CONTEXT
  const { getMasterRoomCat } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [optionValue, setOptionValue] = useState([]);
  const [roomcat, setRoomcat] = useState([]);
  const [code, setCode] = useState(null);

  // Fetch Data
  const fetchData = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        console.log("Details: ", category);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          setOptionValue(filtered);
        } else {
          setOptionValue([]);
        }

        // let convert = filtered.filter((dev) => {
        //   if (convertRoomCat === dev?.room_category_name) {
        //     return dev;
        //   }
        // });

        // let optionConvert = convert.map((dev) => ({
        //   value: dev.room_category_name,
        //   label: dev.room_category_name,
        //   code: dev.room_category_code,
        // }));

        // console.log(optionConvert[0].code);
        // returnRoomCatCode(optionConvert[0].code);
        // console.log(option);
        // setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    // let cleanUp = false;

    // if (category && !false) {
    //   console.log(category);

    fetchData();
    // }

    // return () => {
    //   cleanUp = true;
    // };
  }, []);

  return { optionValue };
}

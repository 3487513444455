import moment from "moment";
import { user_name } from "../../../../API/Global/Payload";

// Functions
const ResBody = async (body) => {
  const _body = body?.data;
  const _params = body.params_bf;
  const roomList = body?.data?.room_price;
  const _lastId = body?.price_id;
  const _payment = body?.payment;
  const typeGroup = body?.group;
  const auth_by = body?.auth;
  const subSegment = body?.subSegment;
  const _arrange = body?.arrange;

  // New Price & Key
  let arr_key = [];
  let room_no = 0;

  // Room Price and Key Price ID
  if (_body?.rate_code != undefined) {
    arr_key.push(_body.rate_code);
    arr_key.push(_body.room_price[0]?.main_segment_id);
    arr_key.push(_body?.room_price[0]?.id);
  }

  // Room Number
  if (Array.isArray(_body.room_number)) {
    if (_body.room_number[0] == undefined) {
      room_no = 0;
    } else {
      room_no = _body.room_number[0];
    }
  } else {
    room_no = _body?.room_number ? _body.room_number : 0;
  }

  console.log("Body in Functions: ", _body);
  console.log("Parameters in Functions: ", _params);
  console.log("Type Group: ", typeGroup);
  console.log("Room List: ", roomList);
  console.log("Last Price ID res: ", _lastId);
  console.log("Room Rate ID: ", arr_key);

  let foreign_key = {
    payment_type_id: 0,
    payment_card_id: 0,
    room_category_id: 0,
    segment_id: 0,
    rate_code_id: 0,
    room_id: 0,
    sales_id: 0,
    source_id: 0,
    rate_code: "",
  };

  let allBody = [];

  switch (typeGroup) {
    case 1:
      for (let i = 0; i < _body?.room_price?.length; i++) {
        foreign_key = {
          segment_id:
            roomList[i]?.main_segment_id > 0
              ? roomList[i].main_segment_id
              : _arrange?.main_segment_id,
          payment_card_id:
            _payment?.card_id > 0
              ? _payment.card_id
              : _arrange?.payment_card_id,
          payment_type_id:
            _payment?.type_id > 0
              ? _payment.type_id
              : _arrange?.payment_type_id,
          rate_code: _body?.rate_code || "",
          rate_code_id:
            roomList[i]?.rate_code_id > 0 ? roomList[i]?.rate_code_id : 0,
          room_category_id:
            roomList?.length > 0 ? roomList[i].room_category_id : 0,
          room_id:
            roomList[i]?.room_number_id > 0 ? roomList[i].room_number_id : 0,
          sales_id:
            subSegment?.sales_id > 0 ? subSegment.sales_id : _arrange?.sales_id,
          source_id:
            subSegment?.source_id > 0
              ? subSegment.source_id
              : _arrange?.source_id,
        };
        // console.log("This is Actually Running!", i);
        allBody.push({
          reservation_id: _body?.res_id,
          arrival: _body?.arrival
            ? moment(_body.arrival).format("YYYY-MM-DD")
            : "",
          departure: _body?.departure
            ? moment(_body.departure).format("YYYY-MM-DD")
            : "",
          night: _body?.night ? parseInt(_body.night) : 0,
          room_category:
            roomList?.length > 0 ? roomList[i].room_category_id : 0,
          room_number:
            roomList[i]?.room_number > 0 ? roomList[i]?.room_number : 0,
          deposit: 0,
          room_qty: 1,
          adult: 1,
          child: _body?.child ? parseInt(_body.child) : 0,
          infant: _body?.infant ? parseInt(_body.infant) : 0,
          reservation_status: _body?.reservation_status
            ? _body.reservation_status
            : null,
          arrangement: foreign_key,
          guest_category: _body?.guest_category
            ? _body.guest_category
            : "REGULAR",
          rate_code: _body?.rate_code ? _body.rate_code : null,
          compliment_type: _body?.compliment_type ? _body.compliment_type : 2,
          currency: _body?.currency ? _body.currency : "IDR",
          // room_type: _body?.room_type ? _body.room_type : "",
          guest_name: _body?.guest_name
            ? `${_body.guest_name + " " + (_lastId + i + 1)}`
            : "",
          bill_receiver: _body?.bill_receiver ? _body.bill_receiver : "",
          bill_instruction: _body?.bill_instruction
            ? _body.bill_instruction
            : "",
          arrival_flight: _body?.arrival_flight ? _body.arrival_flight : "",
          eta: _body?.eta ? _body.eta : null,
          departure_flight: _body?.departure_flight
            ? _body.departure_flight
            : "",
          etd: _body?.etd ? _body.etd : null,
          group: 2,
          segment: _body?.segment ? _body.segment : null,
          source: _body?.source ? _body.source : null,
          letter: _body?.letter ? _body.letter : null,
          payment_type: _body?.payment_type ? _body.payment_type : null,
          payment_details: _body?.payment_details
            ? _body.payment_details
            : null,
          memo_room: _body?.memo_room ? _body.memo_room : null,
          group_name: _body?.group_name ? _body.group_name : null,
          room_price:
            _body.room_price[i]?.nett > 0
              ? _body.room_price[i].nett -
                _params?.unit_price * _body.room_price[i].room_breakfast
              : 0,
          room_rate_id: arr_key ? arr_key : [0],
          price_id:
            _lastId > 0 ? _lastId + i + 1 : _body?.room_price[i]?.id + 1,
          payment_status: _body?.payment_status || null,
          sales: _body?.sales ? _body.sales : null,
          is_dummy: _body?.is_dummy ? _body.is_dummy : true,
          is_group: true,
          guest_id: _body?.guest_id,
          created_by: auth_by ? auth_by : user_name,

          main_compliment_id: _body?.compliment_type
            ? _body.compliment_type
            : 2,
          main_segment_id:
            roomList[i]?.main_segment_id > 0
              ? roomList[i]?.main_segment_id
              : null,
          // subsegment_id: subSegment > 0 ? subSegment : null,

          article_sub_id: _params?.article_sub_id,
          article_sub_name: _params?.article_sub_name,
          article_id: _params?.id,
          article_name: _params?.article_name,
          unit_price: _params?.unit_price,
          item_quantity: _body?.room_price
            ? parseInt(_body?.room_price[0].room_breakfast)
            : 0,
          total_price:
            parseInt(_body?.room_price[0].room_breakfast) * _params?.gross,
          posting_type: null,
          total_posting: 1,
          start_date: _body?.arrival
            ? moment(_body.arrival).format("YYYY-MM-DD")
            : "",
          end_date: _body?.departure
            ? moment(_body.departure).format("YYYY-MM-DD")
            : "",
          coa_abf: _params?.chart_of_account,

          is_tax_abf: _params?.is_tax,
          tax_abf: _body?.compliment_type == 2 ? _params?.tax : 0,
          is_service_abf: _params?.is_service,
          service_abf: _body?.compliment_type == 2 ? _params?.service : 0,
          gross_abf: _body?.compliment_type == 2 ? _params?.gross : 0,

          gross_room:
            _body.room_price[0]?.gross > 0 && _body?.compliment_type == 2
              ? _body.room_price[0].gross -
                _params?.gross * _body?.room_price[0].room_breakfast
              : 0,
          is_tax_room: true,
          tax_room:
            _body.room_price[0]?.tax > 0 && _body?.compliment_type == 2
              ? _body.room_price[0].tax -
                _params?.tax * _body?.room_price[0].room_breakfast
              : 0,
          is_service_room: true,
          service_room:
            _body.room_price[0]?.service > 0 && _body?.compliment_type == 2
              ? _body.room_price[0].service -
                _params?.service * _body?.room_price[0].room_breakfast
              : 0,
        });
      }

      console.log("DATA ARRAY GROUP WITH ABF ==== ", allBody);
      break;

    default:
      for (let i = 0; i < _body?.room_price?.length; i++) {
        foreign_key = {
          segment_id:
            roomList[i]?.main_segment_id > 0
              ? roomList[i].main_segment_id
              : _arrange?.main_segment_id,
          payment_card_id:
            _payment?.card_id > 0
              ? _payment.card_id
              : _arrange?.payment_card_id,
          payment_type_id:
            _payment?.type_id > 0
              ? _payment.type_id
              : _arrange?.payment_type_id,
          rate_code: _body?.rate_code || "",
          rate_code_id:
            roomList[i]?.rate_code_id > 0 ? roomList[i]?.rate_code_id : 0,
          room_category_id:
            roomList?.length > 0 ? roomList[i].room_category_id : 0,
          room_id:
            roomList[i]?.room_number_id > 0 ? roomList[i].room_number_id : 0,
          sales_id:
            subSegment?.sales_id > 0 ? subSegment.sales_id : _arrange?.sales_id,
          source_id:
            subSegment?.source_id > 0
              ? subSegment.source_id
              : _arrange?.source_id,
        };
        // console.log("This is Actually Running!", i);
        allBody.push({
          reservation_id: _body?.res_id,
          arrival: _body?.arrival
            ? moment(_body.arrival).format("YYYY-MM-DD")
            : "",
          departure: _body?.departure
            ? moment(_body.departure).format("YYYY-MM-DD")
            : "",
          night: _body?.night ? parseInt(_body.night) : 0,
          room_category:
            roomList?.length > 0 ? roomList[i].room_category_id : 0,
          room_number: roomList[i]?.room_number ? roomList[i]?.room_number : 0,
          deposit: 0,
          room_qty: 1,
          adult: 1,
          child: _body?.child ? parseInt(_body.child) : 0,
          infant: _body?.infant ? parseInt(_body.infant) : 0,
          reservation_status: _body?.reservation_status
            ? _body.reservation_status
            : "",
          arrangement: foreign_key,
          guest_category: _body?.guest_category
            ? _body.guest_category
            : "REGULAR",
          rate_code: _body?.rate_code ? _body.rate_code : null,
          compliment_type: _body?.compliment_type ? _body.compliment_type : 2,
          currency: _body?.currency ? _body.currency : "IDR",
          // room_type: _body?.room_type ? _body.room_type : "",
          guest_name: _body?.guest_name
            ? `${_body.guest_name + " " + (_lastId + i + 1)}`
            : "",
          bill_receiver: _body?.bill_receiver ? _body.bill_receiver : "",
          bill_instruction: _body?.bill_instruction
            ? _body.bill_instruction
            : null,
          arrival_flight: _body?.arrival_flight ? _body.arrival_flight : null,
          eta: _body?.eta ? _body.eta : null,
          departure_flight: _body?.departure_flight
            ? _body.departure_flight
            : null,
          etd: _body?.etd ? _body.etd : null,
          group: 2,
          segment: _body?.segment ? _body.segment : null,
          source: _body?.source ? _body.source : null,
          letter: _body?.letter ? _body.letter : null,
          // deposit: _body.deposit ? parseInt(_body.deposit) : 100000,
          payment_type: _body?.payment_type ? _body.payment_type : null,
          payment_details: _body?.payment_details
            ? _body.payment_details
            : null,
          memo_room: _body?.memo_room ? _body.memo_room : null,
          group_name: _body?.group_name ? _body.group_name : null,
          room_price:
            _body.room_price[i]?.nett > 0 ? _body.room_price[i].nett : 0,
          room_rate_id: arr_key ? arr_key : [0],
          price_id: _lastId > 0 ? _lastId + i + 1 : _body?.room_price[i]?.id,
          payment_status: _body?.payment_status || null,
          sales: _body?.sales ? _body.sales : null,
          is_dummy: _body?.is_dummy ? _body.is_dummy : true,
          is_group: true,
          guest_id: _body?.guest_id,
          created_by: auth_by ? auth_by : user_name,

          main_compliment_id: _body?.compliment_type
            ? _body.compliment_type
            : 2,
          main_segment_id:
            roomList[i]?.main_segment_id > 0
              ? roomList[i]?.main_segment_id
              : null,
          // subsegment_id: subSegment > 0 ? subSegment : null,

          gross_room:
            _body.room_price[0]?.gross > 0 && _body?.compliment_type == 2
              ? _body.room_price[0].gross
              : 0,
          is_tax_room: true,
          tax_room:
            _body.room_price[0]?.tax > 0 && _body?.compliment_type == 2
              ? _body.room_price[0].tax
              : 0,
          is_service_room: true,
          service_room:
            _body.room_price[0]?.service > 0 && _body?.compliment_type == 2
              ? _body.room_price[0].service
              : 0,
        });
      }

      console.log("DATA ARRAY GROUP ROOM ONLY ==== ", allBody);
      break;
  }

  return { data: allBody };
};

export default ResBody;

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";

// ANTD COMPONENTS
import { PlusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { Alert, Col, Divider, Modal, Row, Typography } from "antd";

// IMPORT PAYLOAD
import { id_warehouse, user_name, department_id } from "../../../../API/Global/Payload";

// PAGE COMPONENTS
import TransferToOtherStorageForm from "../../../Forms/StockTransferRequest/TransferToOtherStorage";
import RequisitionConsumedForm from "../../../Forms/StockTransferRequest/RequisitionConsumed";
import InterKitchenTransferForm from "../../../Forms/StockTransferRequest/InterKitchenTransfer";
import StockItemTransformForm from "../../../Forms/StockTransferRequest/StockItemTransform";
import StockTransferRequestList from "../../../Reusable/Inventory/StockTransferRequest/StockTransferRequestList";

// CONTEXT
import { Context as InventoryContext } from "../../../../API/Context/InventoryContext/InventoryContext";
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext"

// CODE
export default function ModalStockTransferRequest(props) {
  const { openModal, closeModal, dataStock, is_edit, set_edit, is_refresh } =
    props;

  // CONTEXT
  const { getStockTransferRequestType } = useContext(InventoryContext);
  const { getWarehouseList } = useContext(MainContext);
  const { getArticleMaster } = useContext(MasterContext);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // TRANSFER/REQUEST TYPE
  const [transferRequestType, setTransferRequestType] = useState(null);
  const [element, setElement] = useState(null);

  // DATA
  const [storage, setStorage] = useState(null);
  const [department, setDepartment] = useState(null);

  // USE EFFECTS OPEN MODAL
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(true);
      fetchStorage();
      fetchDepartment();
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // USE EFFECT EDIT
  useEffect(() => {
    let cleanUp = false;

    if (is_edit && !cleanUp && dataStock) {
      fetchDataTansferRequest();
    }

    return () => {
      cleanUp = true;
    };
  }, [is_edit, dataStock]);

  // FETCH TRANSFER/REQUEST LIST
  const fetchDataTansferRequest = async () => {
    await getStockTransferRequestType({
      type: "transfer-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("RESPONSE TRANSFER/REQUEST TYPE=> ", response);

        if (response?.data.msg != null) {
          const filter = response.data.msg.map((item) => {
            return {
              key: item.id,
              label: item.name,
              value: item.name,
              id: item.id,
              name: item.name,
            };
          });

          filterTransferRequestType(filter);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // FETCH STORAGE USER
  const fetchStorage = async () => {
    await getWarehouseList({
      type:"warehouse",
      onAwait:() => {},
      onSuccess:(response) => {
        console.log("RESPONSE STORAGE USER >", response?.data.msg);
        console.log("ID STORAGE USER >", id_warehouse);
        console.log("USER NAME >", user_name);

        if(response?.data.msg.length > 0) {
          const filter = response?.data.msg.filter((x) => x.id_warehouse == id_warehouse);
          console.log("RESPONSE STORAGE USER (FILTER) >", {warehouse_name: filter[0]?.warehouse_name, id_warehouse: filter[0]?.id_warehouse});
          setStorage({warehouse_name: filter[0]?.warehouse_name, id_warehouse: filter[0]?.id_warehouse});
        } else {
          setStorage(null);
        }
      },
      onReject:(error) => {
        console.log(error);
      },
    });
  };

  //FETCH DEPARTMENT USER
  const fetchDepartment = async () => {
    await getArticleMaster({
      types:"department",
      onWait: () => {},
      onSuccess: (response) => {
        if(response?.data.msg.length > 0) {
          const filter = response?.data.msg.filter((x) => x.id == department_id);
          console.log("Department Name >", filter[0].name);

          setDepartment({name: filter[0].name, dept_id: filter[0].id});
        } else {
          setDepartment(null);
        }
      },
      onReject: (error) => {
        console.log(error)
      }
    })
  };

  // Filter Type
  const filterTransferRequestType = (data) => {
    const _list = data.filter((item) => item.value === dataStock.transfer_type);

    handleType(_list[0].id);
  };

  // HANDLE TRANSFER/REQUEST TYPE
  const handleType = (val) => {
    const _type = val;
    console.log("Type: ", _type);

    if (_type == 1)
      return setElement(
        <TransferToOtherStorageForm
          is_edit={is_edit}
          is_loading={isLoading}
          onCancel={handleCancel}
          transferRequestType={_type}
          is_refresh={is_refresh}
          dataStock={dataStock}
          storage={storage}
          dept={department}
        />
      );

    if (_type == 2)
      return setElement(
        <RequisitionConsumedForm
          is_edit={is_edit}
          is_loading={isLoading}
          onCancel={handleCancel}
          dept={department}
        />
      );

    if (_type == 3)
      return setElement(
        <InterKitchenTransferForm
          is_edit={is_edit}
          is_loading={isLoading}
          onCancel={handleCancel}
        />
      );

    if (_type == 4)
      return setElement(
        <StockItemTransformForm
          is_edit={is_edit}
          is_loading={isLoading}
          onCancel={handleCancel}
        />
      );

    return setElement(null);
  };

  // HANDLE SELECTED Type
  const handleSelectedType = (value) => {
    const _type = value?.id;

    console.log("SELECTED TYPE >>> ", value);

    setTransferRequestType(value);
    handleType(_type);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    closeModal(false);
    setOpen(false);

    set_edit(false);

    setIsLoading(false);

    setElement(null);
    setTransferRequestType(null);
  };

  return (
    <Modal
      className="add-edit-transfer-request-modal"
      title={
        is_edit == false ? (
          <Row>
            <PlusCircleTwoTone style={{ fontSize: 24 }} />
            <Typography
              style={{ marginLeft: 15 }}
            >{`Add Stock Transfer/Request`}</Typography>
          </Row>
        ) : (
          <Row>
            <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography
              style={{ marginLeft: 15 }}
            >{`Edit Stock Transfer/Request`}</Typography>
          </Row>
        )
      }
      centered
      open={open}
      onCancel={handleCancel}
      width={900}
      bodyStyle={{
        borderRadius: 30,
      }}
      footer={null}
    >
      <Row
        gutter={30}
        className="row-transfer-request"
        style={{ padding: "0px 36px" }}
      >
        <Col span={12}>
          <Typography
            style={{ marginBottom: 10 }}
          >{`Select Transfer/Request Type`}</Typography>

          <StockTransferRequestList
            selectedItem={handleSelectedType}
            transferRequestType={transferRequestType?.name}
            is_disable={is_edit ? true : false}
          />
        </Col>
      </Row>

      <Row gutter={30} justify="center" style={{ padding: "0px 36px" }}>
        <Col span={24}>
          <Divider
            className="form-divider"
            style={{ margin: "15px 0 30px", background: "#EBEDF3" }}
          />
        </Col>
      </Row>

      {element ? (
        element
      ) : (
        <>
          <Row gutter={30} justify="center" style={{ padding: "0px 36px" }}>
            <Col span={12}>
              <Alert
                message="Informational Notes"
                description="Select Transfer/Request Type First!."
                type="info"
                showIcon
              />
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}

// REACT COMPONENTS
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// ANTD COMPONENTS
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

export default function MasterVendor(props) {
  // PROPS
  const { selectedSupplier, supplier_name, is_disable } = props;

  // CONTEXT
  const { getMasterSupplier } = useContext(MasterContext);

  // STATE
  const [optionValue, setOptionValue] = useState([]);
  const [supplier, setSupplier] = useState(null);

  const navigate = useNavigate();

  // FETCH SUPPLIER LIST
  const fetchData = async () => {
    await getMasterSupplier({
      supplier: "suppliers",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("RESPONSE SUPPLIER ALL=> ", response);

        if (response?.data.msg != null) {
          let filterData = response.data.msg.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          const filter = filterData.map((item) => {
            return {
              ...item,
              key: item.id_supplier,
              value: item.id_supplier,
              label: item.supplier_name,
            };
          });

          setOptionValue(filter);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (supplier_name && !cleanUp) {
      setSupplier(supplier_name);
    } else {
      setSupplier(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [supplier_name]);

  // Move to Page
  const move = () => {
    navigate("/configuration/master/article-list");
  };

  // Handle Change
  const handleChange = (e, i) => {
    const _value = e;
    const _allKey = i;

    console.log("Allkeys: ", _allKey);

    setSupplier(_value);
    selectedSupplier(_allKey);
  };

  // Handle Clear
  const handleClear = () => {
    setSupplier(null);
  };

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={22} className="select-col">
          <Select
            className="supplier-select"
            key={optionValue.key}
            style={{ width: "100%" }}
            value={supplier}
            placeholder="Supplier Name"
            showSearch
            allowClear
            disabled={is_disable}
            onChange={handleChange}
            onClear={handleClear}
            options={optionValue}
          />
        </Col>

        <Col span={2} className="select-col">
          <Tooltip
            className="redirect-tooltip"
            title={"Redirect to Master Supplier"}
          >
            <Button
              className="master-btn"
              icon={
                <AppstoreFilled
                  onClick={move}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// Context
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// BASEAPI
import { handleAuthorization } from "../../../API/Global/Permission";
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Tooltip,
} from "antd";

// Import React Icons
import { TfiMenuAlt } from "react-icons/tfi";
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  FaConciergeBell,
  FaUserFriends,
  FaCashRegister,
  FaShoppingCart,
} from "react-icons/fa";
import {
  MdOutlineBedroomChild,
  MdOutlineReplayCircleFilled,
} from "react-icons/md";
import {
  CheckCircleFilled,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";
import { RiFileListFill } from "react-icons/ri";

// Notification
import {
  failedAddReserv,
  failedFetch,
  successAddReserv,
} from "../../Reusable/Notification/Notification";

// Import Page Components
import BillReceipt from "../../Forms/Bill/BillReceipt";
import MasterBill from "../../Forms/Bill/MasterBill";
import ModalCheckOutForm from "../../Forms/CheckOut/Form";
import ModalPermission from "../../Modals/Permissions/Modal";
import ModalReCheckIn from "../../Modals/Recheckin/Modals";

import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import ModalGuestInHouseEdit from "../../Modals/GuestInHouse/Modal";
import ModalArticle from "../../Modals/Article/Stay/Modal";
import ModalChangeRoom from "../../Modals/ChangeRoom/Modal";
// import ModalCheckOutForm from "../../Forms/CheckOut/Form";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function HistoryGuestPopover(props) {
  // PROPS
  const { details, is_refresh } = props;

  // CONTEXT
  const { getMasterBillTrx } = useContext(MainContext);

  // PARAMS
  const params = FrontOfficeParams().show_co;

  // STATE MANAGEMENT
  // Popover
  const [open, setOpen] = useState(false);
  // Modals
  // const [article, setArticle] = useState(false);
  // const [openEdit, setOpenEdit] = useState(false);
  const [reActivate, setReActivate] = useState(false);
  const [bill, setBill] = useState(false);
  const [room, setRoom] = useState(false);
  const [master, setMaster] = useState(false);
  // Data
  const [recordData, setRecordData] = useState(null);
  const [dataBill, setDataBill] = useState([]);

  const [permission, setPermission] = useState(false);
  const [permitLoad, setPermitLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // Modals
  const [openForm, setOpenForm] = useState(false);

  // Set Data
  // console.log("IN POP OVER = ", details);
  const detail = details;

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);
  };

  // Handle Cancel
  const handleCancel = () => {
    setReActivate(!reActivate);
    setPermitLoad(true);
  };

  // HANDLE GROUP
  const handleGroup = async (value) => {
    const _data = value;

    await getMasterBillTrx({
      bill: "bill",
      type: "article-master-bill-guest",
      res_id: _data?.reservation_id
        ? _data.reservation_id
        : _data?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Master Articles => ", response);

        // let arr = [];
        if (response?.data?.length > 0) {
          handleFilter(response.data);
        } else {
          setDataBill([]);
        }
      },
      onReject: (error) => {
        console.log("Failed Master Trx: ", error);
        failedFetch(error);
      },
    });
  };

  // HANDLE FILTER
  const handleFilter = (values) => {
    const _response = values;

    let id = 1;
    let _newRoom = [];
    let _articleRoom = [];
    var _articles = [];

    // for (let i = 0; i < state?.length; i++) {
    // console.log("Loop ", i);

    let _filteredRoom = _response[0].get_article_room_master_bill_guest;

    let newRoom = _filteredRoom.reduce((acc, init) => {
      let _room = init.room_number;

      if (_room !== init?.room_number) {
        return _room;
      }
    });

    let counts = _filteredRoom.filter(
      (v, i, a) =>
        a.findIndex((value) => value.room_number === v.room_number) === i
    );

    // let newRoom = _filteredRoom.map((e) => e.room_number);

    // console.log("New Room = ", newRoom);
    console.log("New Room = ", counts);

    // setArticle(_newRoom[0]);
    // setArticle(_articles[0]);
    setRoom(counts);
  };

  // HANDLE PERMIT
  const handlePermit = async (value) => {
    const _record = value;

    console.log("Value Authorized: ", _record);
    // console.log("Group: ", isGroup);

    if (
      _record?.auth_token &&
      _record?.auth_roles == "ROLE_SUPERVISOR" &&
      _record?.auth_dept.toLowerCase().includes("front")
    ) {
      setReActivate(true);
    } else {
      setPermission(true);
    }
  };

  // console.log(openForm);

  // HANDLE SUCCESS
  const handleSuccess = async (value) => {
    const _record = value;

    console.log("Value Success: ", _record);
    // console.log("Group: ", isGroup);

    if (_record == true) {
      localStorage.clear();
      handleAuthorization("");

      is_refresh(true);
      setIsSuccess(true);
      setPermission(false);
    }
  };

  return (
    <>
      <Tooltip title="Option Menu" className="history-guest-tooltip">
        <Popover
          className="pop-over-history-guest"
          name="pop-over-history-guest"
          key="pop-over-history-guest"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    // color: "#2f54eb",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`History Guest Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            //   detail.group == 2 ? (
            <>
              {detail.group === 1 ? (
                <>
                  <Row justify="start" style={{ width: 250 }}>
                    <Button
                      className="row-reserve-opt"
                      icon={
                        <FaShoppingCart
                          style={{
                            color: "#1BC5BD",
                            fontSize: 20,
                            margin: "0px 22px 0px 15px",
                          }}
                        />
                      }
                      style={{
                        display: "flex",
                        padding: "5px 0px 10px",
                        width: 250,
                        height: 40,
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        handleClose();
                        setMaster(!master);
                        setRecordData(detail);
                      }}
                    >
                      {`Print Master Bill`}
                    </Button>
                  </Row>

                  <Row justify="start" style={{ width: 250 }}>
                    <Button
                      className="row-reserve-opt"
                      icon={
                        <FaCashRegister
                          style={{
                            color: "#1BC5BD",
                            fontSize: 20,
                            margin: "0px 22px 0px 15px",
                            borderColor: "transparent",
                          }}
                        />
                      }
                      style={{
                        display: "flex",
                        padding: "5px 0px 10px",
                        width: 250,
                        height: 40,
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        handleClose();
                        setBill(!bill);
                        setRecordData([detail]);

                        // console.log(detail);
                      }}
                    >
                      {`Print Bill`}
                    </Button>
                  </Row>

                  <Row justify="start" style={{ width: 250 }}>
                    <Button
                      className="row-reserve-opt"
                      // type="danger"
                      // htmlType="submit"
                      icon={
                        <RiFileListFill
                          style={{
                            color: "#1BC5BD",
                            fontSize: 20,
                            margin: "0px 22px 0px 15px",
                          }}
                        />
                      }
                      key={"submit"}
                      onClick={() => {
                        handleGroup(detail);

                        handleClose();
                        setRecordData(detail);

                        // setTimeout(() => {
                        setOpenForm(!openForm);
                        // }, 2000);
                      }}
                      style={{
                        display: "flex",
                        padding: "5px 0px 10px",
                        width: 250,
                        height: 40,
                        borderColor: "transparent",
                      }}
                    >
                      {`Print Thank You Form`}
                    </Button>

                    <Button
                      hidden={params ? false : true}
                      className="row-reserve-opt"
                      icon={
                        <MdOutlineReplayCircleFilled
                          style={{
                            color: "#F64E60",
                            fontSize: 20,
                            margin: "0px 22px 0px 15px",
                            borderColor: "transparent",
                          }}
                        />
                      }
                      style={{
                        display: "flex",
                        padding: "5px 0px 10px",
                        width: 250,
                        height: 40,
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        console.log(detail);

                        handleClose();
                        // setBill(!bill);
                        setRecordData(detail);
                        setPermission(!permission);
                      }}
                    >
                      {`Re-Check-In C/O Guest`}
                    </Button>
                  </Row>
                </>
              ) : (
                <>
                  <Row justify="start" style={{ width: 250 }}>
                    <Button
                      className="row-reserve-opt"
                      icon={
                        <FaCashRegister
                          style={{
                            color: "#1BC5BD",
                            fontSize: 20,
                            margin: "0px 22px 0px 15px",
                            borderColor: "transparent",
                          }}
                        />
                      }
                      style={{
                        display: "flex",
                        padding: "5px 0px 10px",
                        width: 250,
                        height: 40,
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        handleClose();
                        setBill(!bill);
                        setRecordData([detail]);

                        // console.log(detail);
                      }}
                    >
                      {`Print Bill`}
                    </Button>

                    <Button
                      className="row-reserve-opt"
                      // type="danger"
                      // htmlType="submit"
                      icon={
                        <RiFileListFill
                          style={{
                            color: "#1BC5BD",
                            fontSize: 20,
                            margin: "0px 22px 0px 15px",
                          }}
                        />
                      }
                      key={"submit"}
                      onClick={() => {
                        handleClose();
                        setRecordData(detail);
                        setRoom([detail]);

                        // setTimeout(() => {
                        //   setOpenForm(true);
                        // }, 2000);
                        setOpenForm(!openForm);
                      }}
                      style={{
                        display: "flex",
                        padding: "5px 0px 10px",
                        width: 250,
                        height: 40,
                        borderColor: "transparent",
                      }}
                    >
                      {`Print Thank You Form`}
                    </Button>

                    <Button
                      hidden={params ? false : true}
                      className="row-reserve-opt"
                      icon={
                        <MdOutlineReplayCircleFilled
                          style={{
                            color: "#F64E60",
                            fontSize: 20,
                            margin: "0px 22px 0px 15px",
                            borderColor: "transparent",
                          }}
                        />
                      }
                      style={{
                        display: "flex",
                        padding: "5px 0px 10px",
                        width: 250,
                        height: 40,
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        console.log(detail);

                        handleClose();
                        setRecordData(detail);
                        setPermission(!permission);
                      }}
                    >
                      {`Re-Check-In C/O Guest`}
                    </Button>
                  </Row>
                </>
              )}
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            style={{
              fontSize: 20,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              marginLeft: 15,
            }}
          />
        </Popover>
      </Tooltip>

      <ModalCheckOutForm
        openModal={openForm}
        closeModal={setOpenForm}
        dataGuest={openForm == true ? recordData : null}
        amount={0}
        dataLead={room?.length > 0 ? room : null}
        // is_refresh={is_refresh}
        is_early={false}
        is_checkOut={true}
      />

      <MasterBill
        openModal={master}
        closeModal={setMaster}
        dataGuest={master == true ? recordData : []}
        is_history={true}
      />

      <BillReceipt
        openModal={bill}
        closeModal={setBill}
        dataGuest={bill == true ? recordData : []}
        is_history={true}
      />

      <ModalPermission
        openModal={permission}
        closeModal={setPermission}
        is_success={isSuccess}
        is_refresh={setRefresh}
        dataSubmit={recordData}
        roomStatus={3}
        is_deleted={false}
        is_authorized={handlePermit}
        is_loading={permitLoad}
        is_loadingFalse={setPermitLoad}
      />

      <ModalReCheckIn
        is_open={reActivate}
        is_close={handleCancel}
        data_guest={reActivate ? recordData : null}
        is_success={handleSuccess}
      />
    </>
  );
}

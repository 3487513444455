// Import React Components
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// Pay Load AUTH
import { baseurl } from "../../../../../../API/Config/Api";
import {
  authorized_token,
  authorized_by,
  handleAuthorization,
} from "../../../../../../API/Global/Permission";

// CONTEXT
// import { Context as FinanceContext } from "../../../../../../API/Context/FinanceContext/FinanceContext";
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import {
  SyncOutlined,
  FormOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PiTextboxFill } from "react-icons/pi";

// Import Page Components
import ModalAuthorized from "../../../../../Modals/Permissions/ModalCancel";

// Import Master Components
import MasterNumbers from "../../../../../Reusable/Master/Numbers/Numbers";
import MasterBudget from "../../../../../Reusable/Master/Budget/Budget";
import MasterSegment from "../../../../../Reusable/Master/Segment/Segment";
import MasterRateCodeMultiple from "../../../../../Reusable/Master/RateCode/MasterRate";
import MasterSegmentations from "../../../../../Reusable/Accounting/Report/Master/Segmentation/Segmentation";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../../../../Reusable/Notification/Notification";
import {
  failedParams,
  successParams,
} from "../../../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Body
import { mainBody } from "../../../../../../Style/Global/Global";

// MODAL
const { confirm } = Modal;

// CODE
export default function SegmentParamsTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // Use Locations & Navigate
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [orderViewList, setOrderViewList] = useState([]);
  // Is Edit
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showStatus, setShowStatus] = useState("No Show");
  // Segments & Rate Code
  const [segmentGroup, setSegmentGroup] = useState([]);
  const [rateGroup, setRateGroup] = useState([]);
  // Modal State
  const [permitted, setPermitted] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Modals
  const [open, setOpen] = useState(false);

  // Use Forms
  const [form] = Form.useForm();

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      render: (_, record) => (
        <Row
          className="action-table-btn"
          justify="center"
          style={{ width: "100%" }}
        >
          <Tooltip title={"Edit Data"} className="edit-tooltip">
            <Button
              className="edit-btn"
              shape="circle"
              size="small"
              // disabled={permitted}
              icon={
                <FiEdit
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);

                    // setOpen(true);
                    // setIsDelete(false)
                    setIsEdit(true);
                    setEditData(record);

                    handleOpen();

                    setFormFields(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    // cursor: "pointer",
                  }}
                />
              }
              style={{
                borderColor: "transparent",
                background: "inherit",
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Segment Group",
      dataIndex: "header_name",
      key: "header_name",
      width: 150,
    },
    {
      title: "Label Name",
      dataIndex: "label_name",
      key: "label_name",
      width: 200,
    },
    {
      title: "Segment Headers",
      dataIndex: "segment",
      key: "segment",
      width: 300,
      render: (header, record) => {
        let _segment_id = header?.length > 0 ? header : [];

        return _segment_id.join(", ");
      },
    },
    {
      title: "Rate Code Group",
      dataIndex: "group",
      key: "group",
      render: (segment, record) => {
        let _segment_id = segment?.length > 0 ? segment : [];

        return _segment_id.join(", ");
      },
    },
    {
      title: "Budget Account",
      dataIndex: "budget_coa",
      key: "budget_coa",
      width: 200,
    },
    {
      title: "Budget Status",
      dataIndex: "status_budget",
      key: "status_budget",
      align: "center",
      width: 150,
      render: (stats) => {
        let clr = "";
        let txt = "";

        switch (stats) {
          case 0:
            txt = "Proposed";
            clr = "processing";
            break;

          case 1:
            txt = "Approved";
            clr = "#b7eb8f";
            break;

          case 2:
            txt = "Rejected";
            clr = "red";
            break;

          case 3:
            txt = "Subject To Revise";
            clr = "warning";
            break;

          case 4:
            txt = "Inactive";
            clr = "default";
            break;

          case 5:
            txt = "Active";
            clr = "success";
            break;

          default:
            break;
        }

        return stats != null ? (
          <Tag key={stats} color={clr}>
            {txt}
          </Tag>
        ) : null;
      },
    },
    {
      title: "Budget Years",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 150,
      render: (year) => {
        return year ? moment(year).format("YYYY") : null;
      },
    },
    {
      title: "Order View",
      dataIndex: "order_view",
      key: "order_view",
      align: "center",
      width: 125,
    },
    {
      title: "Show in Report",
      dataIndex: "is_show",
      key: "is_show",
      align: "center",
      width: 150,
      render: (bool, record) => {
        let txt = "";
        let clr = "";

        switch (bool) {
          case true:
            txt = "Show";
            clr = "green";
            break;

          default:
            txt = "No Show";
            clr = "volcano";
            break;
        }

        return (
          <Tag
            className="show-tag"
            color={clr}
            key={"tag-key"}
            style={{
              fontSize: 14,
              fontWeight: 500,
              // margin: "0px 0px 0px 15px",
            }}
          >
            {txt}
          </Tag>
        );
      },
    },
  ];

  // Fetch Data
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "dsr-segment",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
          handleOrderNumber(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });

    handleSuccess();
  };

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (authorized_token && !cleanUp) {
      handleAuthorization("");
      localStorage.clear();
    }

    return () => {
      cleanUp = true;
    };
  }, []);

  // Use Effects
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Handle Order View
  const handleOrderNumber = (value) => {
    const _arr = value;

    let _numbered = _arr.map((val) => val.order_view);

    console.log("Picked Numbers: ", _numbered);
    setOrderViewList(_numbered);
  };

  // SET FORM FIELDS
  const setFormFields = (record) => {
    const _label = record;

    if (_label.is_show === true) {
      setShowStatus("Show");
    }

    setSegmentGroup(_label?.main_segment_id);

    form.setFieldsValue({
      ..._label,
      is_show: _label?.is_show,
      budget_label: _label.budget_coa,
      segment: _label?.segment,
      main_segment_id: _label?.main_segment_id,
      group_id: _label?.rate_code_id,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const _submit = value;

    console.log("Submit Data: ", _submit);

    setIsLoading(true);
    showModal(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _submit = value;

    incomplete(value);
    console.log("Submit Data Failed: ", _submit.value);
  };

  // Handle Open
  const handleOpen = () => {
    if (!permitted) {
      setOpen(true);
    } else {
      setModalOpen(true);
    }
  };

  // HANDLE MASTER SEGMENT
  const getMasterSegment = (value) => {
    const content = value;

    console.log("Value: ", content);

    form.setFieldsValue({
      header_name: content?.value,
      header_id: content?.id,
      //   budget_label: content.value,
    });
  };

  // HANDLE BUDGET
  const getBudget = (value) => {
    const content = value;
    console.log("Value: ", content);

    form.setFieldsValue({
      budget_coa: content?.account_code,
      budget_label: content?.value,
      budget_id: content?.budget_id,
    });
  };

  // HANDLE SEGMENT
  const getSegment = (value, segmentId, code) => {
    const content = value;
    const segId = segmentId;

    console.log("Value: ", content);
    console.log("IDs: ", segId);

    setSegmentGroup(segId);

    form.setFieldsValue({
      segment: content,
      main_segment_id: segId,
      //   budget_label: content.value,
    });
  };

  // HANDLE RATE CODE
  const getRateCode = (value, rateId) => {
    const content = value;
    const _rateId = rateId;

    console.log("Value: ", content);
    console.log("IDs: ", _rateId);

    setRateGroup(content);

    form.setFieldsValue({
      group: content,
      group_id: _rateId,
      //   budget_label: content.value,
    });
  };

  // Order View
  const getOrderView = (val) => {
    const _numb = val;

    form.setFieldsValue({
      order_view: _numb,
    });
  };

  // Move
  const move = (e) => {
    const path =
      "/configuration/parameters/general-report-params/report-params";
    const _authorized = localStorage.getItem("permit");

    if (_authorized || authorized_token) {
      handleSuccess();
    }

    navigate(`${path}`);
  };

  // Handle Permissions
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setPermitted(false);
        setOpen(true);
        setModalOpen(false);
        break;

      default:
        break;
    }
  };

  // Handle Showing
  const handleShow = (e) => {
    const value = e.target.checked;
    // console.log("Key: ", e.target.checked);

    switch (value) {
      case true:
        setShowStatus("Show");
        break;

      default:
        setShowStatus("No Show");
        break;
    }
    // setShowStatus(e);
  };

  // Modal Confirm
  const showModal = (val) => {
    const _submitted = val;

    confirm({
      className: "modal-confirm",
      title: (
        <>
          {`Are you sure you want Update label parameters of: `}
          <mark>{_submitted.segment}</mark> {` to: `}
          <mark>{_submitted.label_name}</mark>
          {` for Budget Code `}
          <mark>{_submitted.budget_coa}</mark>?
        </>
      ),
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_submitted);
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
      },
      cancelButtonProps: {
        className: "cancel-btn",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Update
  const handleSubmit = (value) => {
    const submitData = value;

    switch (isEdit) {
      case true:
        handleUpdate(submitData);
        break;

      case false:
        handleCreate(submitData);
        break;

      default:
        break;
    }
  };

  // HANDLE UPDATE
  const handleUpdate = async (value) => {
    const submitData = value;

    await axios
      .put(
        `${baseurl}/parameters/dsr-segment`,
        {
          label_name: submitData?.label_name || editData?.label_name,
          budget_coa: submitData?.budget_coa || editData?.budget_coa,
          segment: submitData?.segment || editData?.segment,
          header_id: submitData?.header_id || editData?.header_id,
          group: submitData?.group || editData?.group,
          is_show: submitData?.is_show || editData?.is_show,
          order_view: submitData?.order_view || editData?.order_view,
          header_name: submitData?.header_name || editData?.header_name,
          rate_code_id: submitData?.group_id || editData?.rate_code_id,
          main_segment_id:
            submitData?.main_segment_id || editData?.main_segment_id,
          budget_id: submitData?.budget_id,

          updated_by: authorized_by ? authorized_by : authorized_by,
          id: editData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${authorized_token}`,
          },
        }
      )
      .then((response) => {
        console.log("Update Label Success: ", response);
        successParams(response, { method: 6 });

        fetchData();
        handleClose();
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error Label: ", error);
        failedParams(error, { method: 6 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CREATE
  const handleCreate = async (value) => {
    const submitData = value;

    await axios
      .post(
        `${baseurl}/parameters/dsr-segment`,
        {
          label_name: submitData?.label_name || null,
          budget_coa: submitData?.budget_coa || null,
          segment: submitData?.segment || null,
          total_label: submitData?.label_name || null,
          group: submitData?.group || null,
          is_show: submitData?.is_show || null,
          order_view: submitData?.order_view || null,
          header_name: submitData?.header_name || null,
          rate_code_id: submitData?.group_id || null,
          main_segment_id: submitData?.main_segment_id || null,
          budget_id:
            submitData?.budget_id > 0
              ? submitData.budget_id
              : editData?.budget_id,

          created_by: authorized_by ? authorized_by : authorized_by,
        },
        {
          headers: {
            Authorization: `Bearer ${authorized_token}`,
          },
        }
      )
      .then((response) => {
        console.log("Create Label Success: ", response);
        successParams(response, { method: 6 });

        fetchData();
        handleClose();
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error Label: ", error);
        failedParams(error, { method: 6 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setEditData(null);
    setShowStatus("No Show");

    form.resetFields();
  };

  // Handle Success
  const handleSuccess = () => {
    // console.log("Successssss");
    setPermitted(true);
    setModalOpen(false);

    localStorage.clear();
    handleAuthorization("");
  };

  // Handle Search
  const handleSearch = () => {
    const _keys = searchKey ? searchKey.toLowerCase() : null;

    if (bulks.length > 0) {
      let filtered = bulks.filter((items) => {
        const _names = items?.label_name ? items.label_name.toLowerCase() : "";

        return _names.indexOf(_keys) != -1;
      });

      setData(filtered);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="account-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="end" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              {/* <Row justify="end" className="row-table"> 
               <Button
                className="print-btn"
                type="primary"
                onClick={handleOpen}
                icon={
                  <FiEdit
                    className="edit-icons"
                    style={{
                      margin: "0px 5px 0px 5px",
                    }}
                  />
                }
                style={{
                  marginRight: 15,
                }}
              >
                {`Enable Edit`}
              </Button> */}

              <Button
                className="submit-btn"
                type="primary"
                onClick={handleOpen}
                icon={
                  <PlusOutlined
                    className="next-icons"
                    style={{
                      margin: "0px 5px 0px 5px",
                    }}
                  />
                }
                style={{
                  marginRight: 15,
                }}
              >
                {`Add New Details`}
              </Button>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="segment-params-table"
              name="segment-params-table"
              key="segment-params-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2500,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="label-modal"
        key="label-modal"
        title={
          <>
            <Row className="title-row">
              <PiTextboxFill style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography
                className="modal-title-text"
                style={{ margin: "0px 0px 0px 15px" }}
              >
                {isEdit ? `Edit Data` : `Add New Data`}
              </Typography>
            </Row>
          </>
        }
        open={open}
        centered
        closable={true}
        onCancel={handleClose}
        width={800}
        footer={null}
      >
        <Form
          className="label-form"
          name="label-form"
          key="label-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Row
            className="main-row"
            justify="center"
            style={{ width: "100%", padding: "15px 30px 0px" }}
          >
            <Col span={24} className="main-col">
              <Row className="source-row" gutter={30}>
                <Col className="source-col" span={8}>
                  <Form.Item
                    label="Segmentation's Header"
                    name="header_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input a Total Label's Name!",
                      },
                    ]}
                  >
                    <MasterSegmentations
                      getMasterSegment={getMasterSegment}
                      master_segment={
                        editData?.header_name ? editData.header_name : null
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Header ID" name="header_id" hidden>
                    <Input className="hidden-input" hidden />
                  </Form.Item>
                </Col>

                <Col className="source-col" span={16}>
                  <Form.Item
                    label="Label Header's Name"
                    name="label_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input a Label Header's Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Label Name" className="label-name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="source-row" gutter={30}>
                <Col className="source-col" span={24}>
                  <Form.Item
                    label="Segment's"
                    name="segment"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose any Segments!",
                      },
                    ]}
                  >
                    <MasterSegment
                      getSegment={getSegment}
                      is_fromSalesman={true}
                      sgmnt={isEdit ? editData?.segment : null}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Segment Group ID's"
                    name="main_segment_id"
                    hidden
                  >
                    <Input className="hidden-input" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="source-row" gutter={30}>
                <Col className="source-col" span={24}>
                  <Form.Item
                    label="Rate Code"
                    name="group"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, Choose any Rate Codes!",
                    //   },
                    // ]}
                  >
                    <MasterRateCodeMultiple
                      getRateCode={getRateCode}
                      segment_id={segmentGroup}
                      rate_code={isEdit ? editData?.group : null}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Rate Code Groups ID's"
                    name="group_id"
                    hidden
                  >
                    <Input className="hidden-input" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="bduget-row" gutter={30}>
                <Col className="source-col" span={16}>
                  <Form.Item
                    label="Budget Account Code"
                    name="budget_label"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, Select a Code!",
                    //   },
                    // ]}
                  >
                    <MasterBudget
                      fromParams={"LABEL_PARAMS"}
                      budget={open ? editData?.budget_coa : null}
                      getBudgetData={getBudget}
                    />
                  </Form.Item>

                  <Form.Item label="Budget Code" name="budget_coa" hidden>
                    <Input
                      placeholder="Label Name"
                      className="label-name"
                      hidden
                    />
                  </Form.Item>

                  <Form.Item label="Budget ID" name="budget_id" hidden>
                    <Input
                      placeholder="Label Name"
                      className="label-name"
                      hidden
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="orderview-row" gutter={30}>
                <Col className="orderview-col" span={8}>
                  <Form.Item
                    label="Order View"
                    name="order_view"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, Input a Number!",
                    //   },
                    // ]}
                  >
                    <MasterNumbers
                      existedNumb={open ? editData?.order_view : 0}
                      getNumbers={getOrderView}
                      pickedNumb={
                        orderViewList?.length > 0 ? orderViewList : []
                      }
                    />
                  </Form.Item>
                </Col>

                <Col className="source-col" span={8}>
                  <Form.Item
                    label="Showing Status"
                    name="is_show"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Checkbox className="show-check" onChange={handleShow}>
                      {showStatus}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "10px 0px", background: "#EBEDF3" }}
              />

              {isEdit ? (
                <>
                  <Row className="creation-row" gutter={30}>
                    <Col className="createsby-col" span={8}>
                      <Form.Item label="Created By" name="created_by">
                        <Input
                          placeholder="Created By"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col className="createdat-col" span={8}>
                      <Form.Item label="Created Date" name="created_date">
                        <Input
                          placeholder="Created Date"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="modified-row" gutter={30}>
                    <Col className="source-col" span={8}>
                      <Form.Item label="Updated By" name="updated_by">
                        <Input
                          placeholder="Updated By"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col className="source-col" span={8}>
                      <Form.Item label="Updated Date" name="updated_date">
                        <Input
                          placeholder="Updated Date"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    className="form-divider"
                    style={{ margin: "0px 0px 10px", background: "#EBEDF3" }}
                  />
                </>
              ) : null}

              <Row
                justify="end"
                align="middle"
                // style={{ padding: "10px 0px 0px" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  key={"submit"}
                  loading={isLoading}
                  className="submit-btn"
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>

      <ModalAuthorized
        openModal={modalOpen}
        closeModal={setModalOpen}
        is_permitted={handlePermit}
        params={1}
      />
    </>
  );
}

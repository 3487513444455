// Import React's Component
import React, { useState, useEffect, useContext } from "react";

// Import Context
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Table
import CaptainArticleMenuPage from "../../../../../Pages/Outlet/Order/ArticleMenu/CaptainArticleMenu";
import PerTablePage from "../../Order/PerTableCaptain";

// Import Ant Design Components
import {
  Space,
  Col,
  Row,
  Modal,
  Form,
  Button,
} from "antd";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import { FileOutlined } from "@ant-design/icons";

// CODE
export default function CaptainOrderTable(props) {

  // Props
  const {
    table_number,
    outlet_data,
    selections,
    is_roomService,
    is_search,
    searchKey,
    is_refresh,
    is_room_service,
  } = props;

  // CONTEXT
  const { getTableList } = useContext(MasterContext);
  const { getRoomStatus } = useContext(MainContext);

  // STATE MANAGEMENT
  // State Page
  const [stateOforder, setStateOforder] = useState(1); // 1 For Loaded, 2  for Order
  // Data Table
  const [data, setData] = useState([]);
  // const [dataAssign, setDataAssign] = useState([]);
  const [dataBulks, setDataBulks] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [articleList, setArticleList] = useState(null);
  // Outlet
  const [selectedData, setSelectedData] = useState({
    shift: "UNSELECTED",
    locations: "UNSELECTED",
    locations_id: 0,
    shift_id: 0,
  });
  // Card Button & Categories
  const [cardButton, setCardButton] = useState([]);
  const [categories, setCategories] = useState({
    table_category_code: "",
    table_category_name: "",
    table_category_id: 0,
  });
  // Room Service
  const [isRoomService, setIsRoomService] = useState(false);
  // Loading State
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Boolean
  const [edit, setEdit] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [newOrder, setNewOrder] = useState(false);
  const [menuDisabled, setMenuDisabled] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  // Fetch Filter
  const fetchFilter = async (value) => {
    const _id = value;

    await getTableList({
      type: "table-by-category",
      table_category_id: _id > 0 ? _id : categories.table_category_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Outlet Table Filter: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setDataBulks(_res);
        } else {
          setData([]);
          setDataBulks([]);
        }

        setLoading(false);

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  // Fetch Room
  const fetchRoom = async (value) => {
    await getRoomStatus({
      status: "status",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ISI Room List >> ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            if (val?.is_deleted == false || val?.is_deleted == null) {
              return val;
            }
          });

          setBulks(filtered);

          if (value === true) {
            handleFilterImmediate(filtered);
          }
        } else {
          // setData([]);
          setBulks([]);
        }

        setLoading(false);
        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_room_service === true && !cleanUp) {
      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_room_service]);

  // Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Data Effects
  useEffect(() => {
    let cleanUp = false;

    if (outlet_data?.length > 0 && !cleanUp) {
      console.log("Data Outlet: ", outlet_data);

      setCardButton(outlet_data);
      setStateOforder(1);
    } else {
      setCardButton([]);
    }

    if (selections?.locations_id > 0 && !cleanUp) {
      console.log("Selected: ", selections);

      setSelectedData({
        locations: selections?.locations,
        shift: selections?.shift,
        locations_id: selections?.locations_id,
        shift_id: selections?.shift_id,
      });

      setData([]);
      fetchRoom();
      setStateOforder(1);
    } else {
      setSelectedData({
        locations: "",
        shift: "",
        locations_id: 0,
        shift_id: 0,
      });
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [selections, outlet_data, is_roomService]);

  // Handle Filter
  const handleFilter = (params) => {
    // const _data = value;
    const _code = params.toLowerCase();

    let _filtered = bulks.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Value: ", _filtered);

    setData(_filtered);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  // Handle Filter
  const handleFilterImmediate = (value) => {
    const _data = value;
    const _code = categories.table_category_code.toLowerCase();

    let _filtered = _data.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Value: ", _filtered);

    setData(_filtered);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle Refresh
  const handleRefresh = () => {
    const _val = true;
    setIsLoading(true);

    switch (isRoomService) {
      case true:
        fetchRoom(_val);
        break;

      case false:
        fetchFilter();
        break;

      default:
        break;
    }
  };

  // Refresh Categories
  const handleRefreshCat = () => {
    is_refresh(true);
  };

  const handleOpenPay = () => {
    setOpenPay(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // Handle Button Card
  const handleButtonCard = (value) => {
    const _data = value;
    setIsLoading(true);

    console.log("Table Cat: ", _data);

    setCategories(_data);

    if (isRoomService) {
      handleFilter(_data?.table_category_code);
    } else {
      fetchFilter(_data?.table_category_id);
    }
  };

  // ON CREATED
  const handleCreate = (value) => {
    if (value === true) {
      setStateOforder(1);
      handleRefresh();
    } else {
      setStateOforder(2);
    }
  };

  // Handle Search
  const handleSearch = () => {
    const _data = isRoomService ? bulks : dataBulks;

    // console.log("Search: ", searchKey);

    let _updatedList = _data.filter((item) => {
      // console.log("Items: ", item);

      if (isRoomService) {
        return (
          item?.room_category_code
            .toLowerCase()
            .indexOf(searchKey.toLowerCase()) !== -1 ||
          item?.room_category_name
            .toLowerCase()
            .indexOf(searchKey.toLowerCase()) !== -1
        );
      } else {
        return (
          item?.table_code.toLowerCase().indexOf(searchKey.toLowerCase()) !==
            -1 ||
          item?.table_id.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1
        );
      }
    });

    console.log("Filter Search: ", _updatedList);
    setData(_updatedList);
  };

  // Handle Article
  const handleArticle = (val) => {
    const _data = val;
    // let articleBefore = articleList.length > 0 ? articleBefore : [];
    console.log("handleArticle on CaptainOrderTable", _data);

    setArticleList(_data);
  };

  // Handle Menu Disabled
  const handleMenuDisabled = (val) => {
    console.log("handleMenuDisabled", val);
    setMenuDisabled(val);
  };
  

  // Render Components
  return (
    <Col
        span={24}
        className="main-space"
        align="start"
        style={{ width: "100%" }}
      >
        <Row
          className="mainspace-row"
          align="start"
          justify="space-evenly"
          gutter={[30, 30]}
        >
          <Col
            className="article-list-col"
            xxl={{
              span: 14,
              order: 1,
            }}
            xl={{
              span: 12,
              order: 1,
            }}
            lg={{
              span: 12,
              order: 1,
            }}
            md={{
              span: 24,
              order: 1,
            }}
            sm={{
              span: 24,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 1,
            }}
          >
            <CaptainArticleMenuPage
              articleData={handleArticle}
              menuDisabled={menuDisabled}
            />
          </Col>

          <Col
            className="order-col"
            xxl={{
              span: 10,
              order: 2,
            }}
            xl={{
              span: 12,
              order: 2,
            }}
            lg={{
              span: 12,
              order: 2,
            }}
            md={{
              span: 24,
              order: 2,
            }}
            sm={{
              span: 24,
              order: 2,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Row
              justify="end"
              className="row-btn"
              style={{
                padding: 30,
                background: "#FFFFFF",
                borderRadius: 5,
              }}
            >
              <Col span={12} className="ordertable-col">
                <Space
                  className="title-spacetxt"
                  align="center"
                  size={"large"}
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  {`Order Detail`}
                </Space>
              </Col>
              <Col span={12} className="ordertable-new-col">
                <Row justify="end">
                  <Button
                    type="primary"
                    icon={
                      <FileOutlined
                        className="pay-icon"
                        style={{
                          fontSize: 15,
                          margin: "0px 0px 0px",
                        }}
                      />
                    }
                    // disabled={newOrder ? true : false}
                    onClick={() => {
                      // setSaveState(true);
                      setNewOrder(true);
                      console.log("truee")
                    }}
                    // loading={isLoading}
                    // className="submit-btn"
                    style={{
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    {`New Captain Order`}
                  </Button>
                </Row>
              </Col>
            </Row>

            <Row className="table-order-row">
              <PerTablePage
                articleItems={articleList}
                is_room_service={isRoomService}
                is_created={handleCreate}
                // data_from_resv={data_from_resv}
                // tableAssign={setDataAssign}
                getNewOrder={setNewOrder}
                isNewOrder={newOrder}
                menuDisabled={handleMenuDisabled}
              />
            </Row>
          </Col>
        </Row>
      </Col>
  );
}

// Import React Components
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Typography,
  Image,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  FormOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";

// CODE
export default function RecipeForm(props) {
  // PROPS
  const { openModal, closeModal, dataView } = props;

  // USE REF
  const componentRef = useRef();

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
    },
    {
      title: "Nett Qty",
      dataIndex: "nett_qty",
      key: "nett_qty",
    },
    {
      title: "Lost Factor (%)",
      dataIndex: "lost_factor",
      key: "lost_factor",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "R-Unit",
      dataIndex: "r_unit",
      key: "r_unit",
    },
    {
      title: `Average Price`,
      dataIndex: "average_price",
      key: "average_price",
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;
        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
    {
      title: `Total Cost`,
      dataIndex: "cost",
      key: "cost",
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;

        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataViewDetail, setDataViewDetail] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    console.log("DATA VIEW >> ", dataView);
    console.log("DATA DETAIL >> ", dataView?.details);
    setLoading(true);
    if (openModal == true) {
      setOpen(!open);
    }

    if (dataView) {
      setData(dataView);
      fetchDetail();
    }

    setLoading(false);

    // setTimeout(() => {
    setIsLoading(false);
    // }, 1250);
  }, [openModal, dataView]);

  const fetchDetail = async () => {
    let detail = dataView?.details.map((x) => ({
      ...x,
      item_name: x.article_name,
      nett_qty: x.nett_qty,
      lost_factor: x.lost_factor,
      content: x.content,
      r_unit: x.recipe_unit,
      cost: x.cost,
      average_price: x.cost / x.nett_qty,
    }));
    setDataViewDetail(detail);
  };

  // ON FINISH
  const onFinish = async () => {
    handlePrint();
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setData([]);
    setDataViewDetail([]);
    // setEdit(null);

    setIsLoading(true);
    form.resetFields();
  };

  // HANDLE REACT PRINT
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Standard Recipe ${
      data?.recipe_name ? data?.recipe_name : " ---- "
    }`,
    // onafterprint: () => alert("Print Success"),
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row>
              <FormOutlined style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Recipe`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="resv-form-modal"
          className="resv-form-modal"
          form={form}
          labelCol={{ span: 8 }}
          labelWrap={{ span: 16 }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{ padding: "30px 80px 30px 80px" }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Recipe Detail`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    src="/looklogo(color).png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Look Hotel Bandung`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-receipt-no"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Col span={12}>
                  <Form.Item
                    label="Recipe's Number"
                    className="recipe_number"
                    style={{ width: "100%" }}
                  >
                    <Typography style={textForm}>
                      {("000" + data?.recipe_number).slice(-4)}
                    </Typography>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Update Date"
                    className="update_date"
                    style={{ width: "100%" }}
                  >
                    <Typography style={textForm}>
                      {data?.updated_date ? moment(data?.updated_date).format("DD-MM-YYYY") : "-"}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row
                className="row-date"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="Created Date"
                  className="date"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Typography style={textForm}>
                    {moment(data?.created_date).format("DD-MM-YYYY")}
                  </Typography>
                </Form.Item>
              </Row>

              <Row className="row-titles" justify="start">
                <Col span={12}>
                  <Typography
                    className="text-payment"
                    style={{
                      margin: "15px 0px 0px",
                      fontSize: 12,
                      fontWeight: 750,
                      // color: "grey",
                    }}
                  >
                    {`Header Data`}
                  </Typography>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                  // height: 40,
                }}
              >
                <Col span={12}>
                  <Form.Item
                    label="Recipe's Name"
                    className="recipe_name"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {data?.recipe_name}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Category"
                    className="category"
                    style={{ margin: 0 }}
                  >
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {data?.sub_article_name}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Portion"
                    className="portion"
                  >
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {data?.portion}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="col-res-id">
                  <Form.Item
                    label="Recipe's Type"
                    className="guest_name"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {data?.recipe_type == 1
                        ? "RECIPE UNDER RECIPE"
                        : "BASIC RECIPE"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Description"
                    className="arrival"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {data?.description}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Typography
                  style={{
                    margin: "15px 0px 0px",
                    fontSize: 14,
                    fontWeight: 500,
                    color: "grey",
                  }}
                >
                  Item List
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="transaction-table"
                  key="transaction-table"
                  name="transaction-table"
                  // loading={loading}
                  columns={columns}
                  dataSource={dataViewDetail}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "odd" : "even";
                  }}
                  pagination={false}
                  rowKey={(record) => (record.id ? record.id : record.key)}
                  row
                  summary={() => {
                    return (
                      <>
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={6} />

                            <Table.Summary.Cell index={3}>
                              <b>Subtotal</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={4}>
                              {`${data?.cost}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={6} />

                            <Table.Summary.Cell index={3}>
                              <b>Portion</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={4}>
                              {`${dataView?.portion}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={6} />

                            <Table.Summary.Cell index={3}>
                              <b style={{ fontSize: 12 }}>Grand Total</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={4}>
                              <b style={{ fontSize: 12 }}>
                                {`${data?.total_cost}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </b>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      </>
                    );
                  }}
                />
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              // htmlType="submit"
              icon={<PrinterOutlined />}
              onClick={() => {
                handlePrint();
              }}
              style={{
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// AUTH
import { sub_children } from "../../../API/Global/Payload";

// Page Components
import MainPurchasingPage from "../../../Pages/BackOffice/Purchasing/Main/Purchasing";
import PurchaseRequestPage from "../../../Pages/BackOffice/Purchasing/PurchaseRequest/PurchaseRequestPage";
import VendorManagementPage from "../../../Pages/BackOffice/Purchasing/VendorManagement/VendorManagement";
import PurchaseOrderPage from "../../../Pages/BackOffice/Purchasing/PurchaseOrder/PurchaseOrderPage";
import RequestApprovalPage from "../../../Pages/BackOffice/Purchasing/PurchaseApproval/PurchaseRequestPage";
import UnAuthorized from "../../../Pages/Unauthorized/Unauthorized";
import DailyMarketListPage from "../../../Pages/BackOffice/Purchasing/DailyMarketList/DailyMarketListPage";
import QuotationMainPage from "../../../Pages/BackOffice/Purchasing/PriceManagement/PriceManagement";

import NotFound from "../../../Pages/NotFound/NotFound";

// List Route
const grandRoute = [
  {
    path: "purchase-request",
    name: "purchase-request",
    id_modules: 47,
    element: <PurchaseRequestPage />,
  },
  {
    path: "daily-market-list",
    name: "daily-market-list",
    id_modules: 101,
    element: <DailyMarketListPage />,
  },
  {
    path: "purchase-order",
    name: "purchase-order",
    id_modules: 48,
    element: <PurchaseOrderPage />,
  },
  {
    path: "approval-request",
    name: "approval-request",
    id_modules: 49,
    element: <RequestApprovalPage />,
  },
  {
    path: "vendor-management",
    name: "vendor-management",
    id_modules: 50,
    element: <VendorManagementPage />,
  },
  {
    path: "price-management",
    name: "price-management",
    id_modules: 51,
    element: <QuotationMainPage />,
  },
];

// CODE
export default function PurchasingRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes Purchasing: ", _mods);
    // console.log("Protected Arr: ", proRoute);

    setProtectedRoutes(proRoute);
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<MainPurchasingPage />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}
      </Routes>
    </>
  );
}

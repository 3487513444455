// Import React Components
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

// BASE URL
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import { Button, Col, Modal, Row, Table, Tag } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// Import Notification
import {
  failedClosing,
  failedFetch,
  successClosing,
} from "../../../Reusable/Notification/Notification";
import { mainBody, rowPrimary } from "../../../../Style/Global/Global";

// React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { HiMiniArchiveBoxArrowDown } from "react-icons/hi2";
import { PiFileArchiveFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Modals
const { confirm } = Modal;

// CODE
export default function ClosingMonthTable(props) {
  // Props
  const { yearMonth } = props;

  // CONTEXT
  const { getByPostClosingPeriod } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // DATA
  const [data, setData] = useState([]);
  const [dataRegister, setDataRegister] = useState(null);
  const [total, setTotal] = useState({
    credit: 0,
    debit: 0,
    credit_final_balance: 0,
    debit_final_balance: 0,
    credit_initial_balance: 0,
    debit_initial_balance: 0,
  });
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Navigate
  const navigate = useNavigate();

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (yearMonth && !cleanUp) {
      console.log("Month & Year: ", yearMonth);
      fetchData();
      fetchDataRegister();
    } else {
      setData([]);
      setTotal({
        credit: 0,
        debit: 0,
        credit_final_balance: 0,
        debit_final_balance: 0,
        credit_initial_balance: 0,
        debit_initial_balance: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [yearMonth]);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    await getByPostClosingPeriod({
      type: "closing-date-history",
      date: yearMonth ? yearMonth : null,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Respones Close Period: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _data = _res.filter(
            (item) => item.account_type_id !== 98 && item.account_type_id !== 99
          );

          let _total = {
            credit: 0,
            debit: 0,
            credit_final_balance: 0,
            debit_final_balance: 0,
            credit_initial_balance: 0,
            debit_initial_balance: 0,
          };

          const filterTotal = _res.filter((item) => {
            if (item.account_type_id === 98)
              _total = {
                ..._total,
                credit: item.credit,
                credit_final_balance: item.final_balance,
                credit_initial_balance: item.initial_balance,
              };

            if (item.account_type_id === 99)
              _total = {
                ..._total,
                debit: item.debit,
                debit_final_balance: item.final_balance,
                debit_initial_balance: item.initial_balance,
              };
          });

          console.log("Filter Total: ", filterTotal);

          setData(_data);
          setTotal(_total);
        } else {
          setData([]);
          setTotal({
            credit: 0,
            debit: 0,
            credit_final_balance: 0,
            debit_final_balance: 0,
            credit_initial_balance: 0,
            debit_initial_balance: 0,
          });
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });

    // await axios
    //   .get(`${baseurl}/accounting/pre-closing-date`)
    //   .then((response) => {
    //     console.log(response);

    //     if (response?.data?.msg?.length > 0) {
    //       const _res = response.data.msg;

    //       const _data = _res.filter(
    //         (item) => item.account_type_id !== 98 && item.account_type_id !== 99
    //       );

    //       let _total = {
    //         credit: 0,
    //         debit: 0,
    //         credit_account_code: "",
    //         debit_account_code: "",
    //         credit_final_balance: 0,
    //         debit_final_balance: 0,
    //         credit_initial_balance: 0,
    //         debit_initial_balance: 0,
    //         credit_normal_balance: 0,
    //         debit_normal_balance: 1,
    //       };

    //       const filterTotal = _res.filter((item) => {
    //         if (item.account_type_id === 98)
    //           _total = {
    //             ..._total,
    //             credit_account_code: item.account_code,
    //             credit: item.credit,
    //             credit_final_balance: item.final_balance,
    //             credit_initial_balance: item.initial_balance,
    //             credit_normal_balance: item.normal_balance,
    //           };

    //         if (item.account_type_id === 99)
    //           _total = {
    //             ..._total,
    //             debit_account_code: item.account_code,
    //             debit: item.debit,
    //             debit_final_balance: item.final_balance,
    //             debit_initial_balance: item.initial_balance,
    //             debit_normal_balance: item.normal_balance,
    //           };
    //       });

    //       console.log("Filter Total: ", filterTotal);

    //       setData(_data);
    //       setTotal(_total);
    //     } else {
    //       setData([]);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     failedFetch(error);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  // FETCH DATA REGISTER
  const fetchDataRegister = async () => {
    // setLoading(true);

    await axios
      .get(`${baseurl}/accounting/journal/header-unregistered-list`)
      .then((response) => {
        console.log("response: ", response);
        if (response?.data?.msg) {
          const _res = response.data.msg;

          setDataRegister(_res);
        } else {
          setDataRegister(null);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      });
    // .finally(() => {
    //   setLoading(false);
    // });
  };

  // SHOW MODAL
  const showModalConfirm = () => {
    setIsLoading(true);

    console.log("REgister Data: ", dataRegister);
    confirm({
      className: "modal-confirm",
      title: `${
        dataRegister
          ? "There are Journals that have not been Registered!. "
          : ""
      } Are you sure want to Close this Month Period?`,
      // icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit();
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseurl}/accounting/closing-date`,
        {
          created_by: user_name,
          date: yearMonth,
        },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Success CLOSING: ", response);

        successClosing();
      })
      .catch((error) => {
        console.log("Error CLOSING: ", error);
        failedClosing(error);
      })
      .finally(() => {
        setLoading(false);
        setIsLoading(false);
      });
  };

  // HANDLE RETURN
  const handleReturn = () => {
    navigate("/back-office/accounting");
  };

  // Columns
  const columns = [
    {
      title: "Account Code",
      dataIndex: "account_code",
      key: "account_code",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Initial Balance",
      dataIndex: "initial_balance",
      key: "initial_balance",
      align: "right",
      render: (credit) => {
        return `${credit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      align: "right",
      render: (credit) => {
        return `${credit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      align: "right",
      render: (credit) => {
        return `${credit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Final Balance",
      dataIndex: "final_balance",
      key: "final_balance",
      align: "right",
      render: (credit) => {
        return `${credit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Normal Balance",
      dataIndex: "normal_balance",
      key: "normal_balance",
      render: (balance) => {
        let type = "";
        let clr = "";
        switch (balance) {
          case 0:
            type = "DEBIT";
            clr = "geekblue";
            break;

          case 1:
            type = "CREDIT";
            clr = "green";
            break;

          default:
            break;
        }

        return (
          <Tag color={clr} key={type}>
            {type}
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <Row className="table-row" justify="start" align="top" style={mainBody}>
        <Col span={24} className="table-col" style={{ padding: "30px" }}>
          <Row
            className="btn-row"
            justify="end"
            gutter={[0, 15]}
            style={{ marginBottom: "30px" }}
          >
            <Button
              className="submit-btn"
              onClick={showModalConfirm}
              type="primary"
              loading={isLoading}
              disabled={
                yearMonth &&
                data.length > 0 &&
                data.some((item) => !item.is_closing_date) &&
                total.debit - total.credit == 0
                  ? false
                  : true
              }
              icon={
                <PiFileArchiveFill
                  style={{
                    margin: "0px 5px 0px 0px",
                    fontSize: 20,
                  }}
                />
              }
              style={{
                display: "flex",
                padding: "5px 10px 10px 5px",
                marginRight: "15px",
                minWidth: 75,
                borderColor: "transparent",
              }}
            >
              {`Close Period`}
            </Button>

            <Button
              className="refresh-btn"
              onClick={() => yearMonth && fetchData()}
              type="default"
              icon={<SyncOutlined />}
            >
              {`Refresh`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={
                <IoReturnUpBackOutline
                  className="return-icons"
                  style={{
                    margin: "0px 5px 0px 0px",
                  }}
                />
              }
              onClick={handleReturn}
              style={{
                margin: "0px 0px 0px 15px",
              }}
            >
              {`Return`}
            </Button>
          </Row>

          <Row className="btn-row">
            <Table
              className="closing-table"
              key="closing-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.account_code)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                        <Table.Summary.Cell index={0} colSpan={2}>
                          <b>{"TOTAL DEBIT"}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <b>
                            {`${total?.debit_initial_balance}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} rowSpan={2} align="right">
                          <b>
                            {`${total?.debit}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} rowSpan={2} align="right">
                          <b>
                            {`${total?.credit}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <b>
                            {`${total?.debit_final_balance}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            <Tag color="geekblue">DEBIT</Tag>
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                        <Table.Summary.Cell index={0} colSpan={2}>
                          <b>{"TOTAL CREDIT"}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <b>
                            {`${total?.credit_initial_balance}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <b>
                            {`${total?.credit_final_balance}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            <Tag color="green">CREDIT</Tag>
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

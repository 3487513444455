// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import TransferValidationForm from "../../../../../../Forms/TransferValidation/Form";

export default function TransferValidationReportTable(props) {
  // PROPS
  const { _main, _storage, _type, _date } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    quantity_received: 0,
    total_price: 0,
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if ((_date && _type && _main && !cleanUp) || (_date && _type && _storage && !cleanUp)) {
      fetchData();
    } else {
      setData([]);
      setBulks([]);
      setTotal({
        quantity_received: 0,
        total_price: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_date, _type, _main, _storage]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    let bodyReq = {};

    if (_storage) {
      bodyReq = {
        id_warehouse: _storage.id_warehouse,
        date: moment(_date).format("YYYY-MM-DD")
      };
    } else if (_main) {
      bodyReq = {
        article_main_id: _main.main_id,
        date: moment(_date).format("YYYY-MM-DD")
      };
    }

    await getByPostInventoryReport({
      type: `transfer-validation`,
      bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
              quantity_received: item.quantity_received,
              total_price: item.total_price,
            };
          });

          const queryDate = _date != "Invalid date" && _date != null ? moment(_date).format("YYYY-MM-DD") : null;

          let updatedList = filter.filter((item) => {
            const dep = moment(item?.received_date).format("YYYY-MM-DD");
    
            if (queryDate == null) {
              return item;
            } else {
              return moment(dep).isSame(queryDate);
            }
          });

          const sumTotal =
            updatedList.length > 0
              ? updatedList.reduce(
                  (prev, curr) => {
                    return {
                      quantity_received: prev.quantity_received + curr.quantity_received,
                      total_price: prev.total_price + curr.total_price,
                    };
                  },
                  { quantity_received: 0, total_price: 0 }
                )
              : { quantity_received: 0, total_price: 0 };

          setData(updatedList);
          setBulks(updatedList);
          setTotal(sumTotal);
        } else {
          setData([]);
          setBulks([]);
          setTotal({
            quantity_received: 0,
            total_price_received: 0,
          });
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Transfer Validation Reports${
        _date
          ? " " + moment(_date).format("YYYY-MM-DD")
          : ""
      }`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Received Date",
      dataIndex: "received_date",
      key: "received_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.received_date).unix() - moment(b.received_date).unix(),
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY") : "-";
      },
    },
    {
      title: "Document Number",
      dataIndex: "document_number",
      key: "document_number",
    },
    {
      title: "From Storage",
      dataIndex: "from_storage",
      key: "from_storage",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.from_storage.localeCompare(b.from_storage),
    },
    {
      title: "To Storage",
      dataIndex: "to_storage",
      key: "to_storage",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.to_storage.localeCompare(b.to_storage),
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Unit",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Content",
      dataIndex: "delivery_content",
      key: "delivery_content",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Average Price",
      dataIndex: "avg_price",
      key: "avg_price",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity_received",
      key: "quantity_received",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ];

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  // style={{
                  //   margin: "0px 0px 0px 15px",
                  // }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={8} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.quantity_received ? total.quantity_received : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.total_price ? total.total_price : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <TransferValidationForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _dateFilter = {_date}
        _main = {_main ? _main : null}
        _storage = {_storage ? _storage : null}
        _type = {_type}
      />
    </>
  );
}

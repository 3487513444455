// Import React's Components
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// API
import { baseurl } from "../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// Import Antd's Components
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Comment,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";

// Import React's Icon
import { PiArrowArcLeftBold } from "react-icons/pi";

// Import Functions
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

// Import Notifications
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";

const { confirm } = Modal;

// CODE
export default function ModalReactiveReservations(props) {
  // PROPS
  const { is_open, is_close, data_guest, is_success, is_cancelled } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [cateGuest, setCateGuest] = useState({
    gst: "UNASSIGNED",
    clr: "PURPLE",
  });
  // Bool
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Form
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (data_guest && !cleanUp) {
      console.log("Data about to Reactivate: ", data_guest);

      setData(data_guest);
      handleFormFields(data_guest);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, data_guest]);

  // Set Form Fields
  const handleFormFields = (record) => {
    const _data = record;

    switch (_data?.guest_category) {
      case "VIP":
        setCateGuest({
          gst: "VIP",

          clr: "geekblue",
        });
        break;

      case "VVIP":
        setCateGuest({
          gst: "VVIP",
          clr: "green",
        });
        break;

      case "INCOGNITO":
        setCateGuest({
          gst: "INCOGNITO",

          clr: "default",
        });
        break;

      case "RED ALERT":
        setCateGuest({
          gst: "RED ALERT",

          clr: "magenta",
        });
        break;

      case "REGULAR":
        setCateGuest({
          gst: "REGULAR",

          clr: "gold",
        });
        break;

      case "":
        setCateGuest({
          gst: "UNASSIGNED",
          clr: "purple",
        });
        break;

      case null:
        setCateGuest({
          gst: "UNASSIGNED",
          clr: "purple",
        });
        break;

      default:
        break;
    }

    form.setFieldsValue({
      ..._data,
      arrival: moment(_data?.arrival).format("DD-MM-YYYY"),
      departure: moment(_data?.departure).format("DD-MM-YYYY"),
      checkin_date: moment(LocalizationDate(_data?.checkin_date)).format(
        "HH:mm:ss, DD-MM-YYYY"
      ),
      is_group: _data?.group == 1 ? true : false,
    });
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    const dataModal = val;

    confirm({
      // icon: <ExclamationCircleTwoTone />,
      className: "modal-confirm",
      title: `Are you sure want to Re-Activate a Reservations of ${dataModal?.reservation_id} and Remove it from Guest In-House?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(dataModal);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // On Finish
  const onFinish = (val) => {
    const _data = val;

    console.log("Finish: ", _data);

    showModalConfirm(_data);
    setIsLoading(true);
  };

  // On Finish Failed
  const onFinishFailed = (val) => {
    const _data = val.values;
    console.log("Failed: ", _data);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    // e.preventDefault();
    console.log("EDITED > ", e);
    let field = form.getFieldsValue();

    console.log("GET FIELDS VALUE : ", field);

    // const id = edit.id;
    const contentEdit = e;

    await axios
      .post(
        `${baseurl}/reservation/stay-re-active`,
        {
          price_id: data?.price_id,
          reason: contentEdit?.reason_activation,
          reservation_id: contentEdit?.reservation_id,
          re_activated_canceled: is_cancelled === true ? true : false,
          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Re-Activation Reservations: ", response);

        if (response.status === 200) {
          masterSuccessNotification(response, {
            method: 9,
            source: "Re-Activation Reservations",
          });

          is_success(true);
          handleCancel();
        }
      })
      .catch((error) => {
        console.log(error);
        masterFailedNotification(error, {
          method: 9,
          source: "Re-Activation Reservations",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle Cancel
  const handleCancel = () => {
    form.resetFields();

    setData(null);

    is_close(false);
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <div aria-modal="true" aria-hidden="false">
      <Modal
        className="modal-reactivate"
        key="modal-reactivate"
        title={
          <>
            <Row>
              <PiArrowArcLeftBold
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography style={{ marginLeft: 15, fontSize: 20 }}>
                {`Cancel Reservations (Re-Activate Reservations) - ${
                  is_cancelled ? "Cancelled Reservations" : "Guest In-House"
                }`}
              </Typography>
            </Row>
          </>
        }
        open={open}
        closable
        onCancel={handleCancel}
        centered
        footer={null}
        width={1000}
        style={{
          padding: "0px 36px",
        }}
      >
        <Form
          className="reactivate-form"
          name="reactivate-form"
          key="reactivate-form"
          autoComplete="false"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            is_group: data_guest?.group == 1 ? true : false,
            //   is_service: false,
          }}
          layout="vertical"
          labelAlign="left"
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-header">
            <Col span={24} className="col-guest-name">
              <Alert
                message="Reactivate Reservations is only applicable to a data Guest In-House which hasn't been Audit!"
                type="info"
                showIcon
                style={{
                  margin: "0px 0px 15px",
                }}
              />

              <Space
                align="center"
                size={30}
                className="header-space"
                wrap={true}
                style={{
                  padding: "15px 0px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tag
                  className="rb/ro"
                  color={data?.is_abf == true ? "green" : "geekblue"}
                  style={{ fontSize: 18, padding: "5px 10px" }}
                >
                  {data?.is_abf == true ? "Room Breakfast" : "Room Only"}
                </Tag>

                <Tag
                  className="rb/ro"
                  color={data?.is_no_show == true ? "#f5222d" : "#52c41a"}
                  style={{ fontSize: 18, padding: "5px 10px" }}
                >
                  {data?.is_no_show == true ? "NO-SHOW" : "SHOWING"}
                </Tag>

                <Tag
                  className="category"
                  color={cateGuest.clr}
                  style={{ fontSize: 18, fontWeight: 600, padding: "5px 10px" }}
                >
                  {cateGuest.gst}
                </Tag>

                <Tag
                  className="pay-status"
                  color={data?.payment_status == 1 ? "#ff7875" : "#2db7f5"}
                  style={{ fontSize: 18, fontWeight: 600, padding: "5px 10px" }}
                >
                  {data?.payment_status == 1 ? "Cash Basis" : "Charge to Room"}
                </Tag>

                <Tag
                  className="pay-status"
                  color={data?.group == 0 ? "error" : "purple"}
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    padding: "5px 10px",
                  }}
                >
                  {data?.group == 0
                    ? "Individual Guest"
                    : data?.group == 1
                    ? "Group Guest (Leader)"
                    : "Group Guest (Members)"}
                </Tag>

                <Tag
                  className="comply-status"
                  color={
                    data?.compliment_type == 2
                      ? "#a0d911"
                      : data?.compliment_type == 1
                      ? "#85a5ff"
                      : "#ff85c0"
                  }
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    padding: "5px 10px",
                  }}
                >
                  {data?.compliment_type == 2
                    ? "No Compliment"
                    : data?.compliment_type == 1
                    ? "House Uses"
                    : "Compliment"}
                </Tag>

                <Tag
                  className="is-audit"
                  color={data?.is_no_show == true ? "error" : "processing"}
                  icon={
                    data?.is_audit == true ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )
                  }
                  style={{ fontSize: 18, padding: "5px 10px" }}
                >
                  {data?.is_audit == true ? "Audited" : "Not Audited"}
                </Tag>
              </Space>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px" }}
              />

              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 75,
                }}
              >
                <Col span={8} className="col-guest-name">
                  <Form.Item label="Guest Name" name="guest_name">
                    <Input placeholder="Guest's Name" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-res-id">
                  <Form.Item label="Reservation ID" name="reservation_id">
                    <Input placeholder="Reservation ID" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-room-cat">
                  <Form.Item label="Room Category" name="room_category">
                    <Input placeholder="Room Category" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 75,
                }}
              >
                <Col span={8} className="col-room">
                  <Form.Item label="Room Number" name="room_number">
                    <InputNumber
                      placeholder="Room Number"
                      disabled
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-res-id">
                  <Form.Item label="Arrivals" name="arrival">
                    <Input placeholder="Arrival" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-room-cat">
                  <Form.Item label="Departure" name="departure">
                    <Input placeholder="Departure" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 75,
                }}
              >
                <Col span={8} className="col-details">
                  <Form.Item
                    label="Reservation Status"
                    name="reservation_status"
                  >
                    <Input placeholder="Reservation Status" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-res-id">
                  <Form.Item label="Check-In By" name="checkin_by">
                    <Input placeholder="Arrival" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-room-cat">
                  <Form.Item label="Check-In Time" name="checkin_date">
                    <Input placeholder="Departure" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0pxpx" }}
              />

              {is_cancelled ? (
                <Row gutter={30} className="row-header">
                  <Col span={16} className="col-reason">
                    <Form.Item label="Reason of Cancellations" name="reason">
                      <Input.TextArea
                        rows={2}
                        className="reason"
                        placeholder="Reason of Cancellations"
                        allowClear
                        showCount
                        disabled
                        maxLength={150}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}

              <Row gutter={30} className="row-header" justify="end">
                <Col span={16} className="col-reason">
                  <Form.Item
                    label="Reason of Reactivation"
                    name="reason_activation"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input the Reason!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={3}
                      className="reason"
                      placeholder="Reason of Cancel Check-In / Re-Activate Reservations"
                      allowClear
                      showCount
                      maxLength={150}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-checkbox">
                  <Form.Item
                    // label="Reservation Status"
                    name="is_group"
                    valuePropName="checked"
                    style={{
                      margin: "25px 0px 0px",
                    }}
                  >
                    <Checkbox disabled>{`Includes Member?`}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px" }}
              />

              <Row
                justify="end"
                align="middle"
                // style={{ padding: "15px 0px 0px" }}
                className="modal-btn-form"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={data?.is_audit == true ? true : false}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleCancel}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

// REACT ICONS
import { FiEdit } from "react-icons/fi";

// PAGE COMPONENTS
import PurchaseRequestList from "../../../../Reusable/Inventory/Purchasing/PurchaseRequestList";
import POTableDetailModal from "../../../../Table/Reports/BackOffice/Purchasing/PurchaseOrderDetailModal/Table";

export default function POModalDetail(props) {
  // PROPS
  const { is_open, is_close, selectedData, params } = props;

  // STATE
  const [dataPRDetail, setDataPRDetail] = useState([]);
  const [dataPODetail, setDataPODetail] = useState([]);
  // Params
  const [ParamsAcc, setParamsAcc] = useState(null);
  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  const [nextState, setNextState] = useState(true);
  // Loadings
  const [isLoading, setIsLoading] = useState(false);
  const [tabKey, setTabKey] = useState("2");

  // USE FORM
  const [form] = Form.useForm();

  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      handleFormFields(selectedData);

      let x = selectedData?.pr_details.map((e) => {
        return {
          ...e,
          unit_price: e.price,
          total_price: e.total_price,
          id_supplier: selectedData.id_supplier,
          supplier_name: selectedData.supplier_name,
          id_po: selectedData.id_po,
          order_date: selectedData.order_date,
        };
      });

      let y = selectedData?.details.map((e) => {
        return {
          ...e,
          quantity: e.quantity_order,
          total_price: e.total_price,
          id_supplier: selectedData?.id_supplier,
          supplier_name: selectedData?.supplier_name,
          id_po: selectedData?.id_po,
          order_date: selectedData?.order_date,
        };
      });

      setDataPRDetail(x);
      setDataPODetail(y);
    }
    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("Modal State: ", is_open);

    if (is_open == true && !cleanUp) {
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
    }

    if (params && !cleanUp) {
      console.log("Draft Modals: ", params);

      setParamsAcc(params);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, params]);

  // HANDLE OPEN
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE SET FORM FIELDS
  const handleFormFields = (selectedData) => {
    form.setFieldsValue({
      start_contract_date: selectedData?.start_contract_date
        ? moment(selectedData?.start_contract_date)
        : null,
      end_contract_date: selectedData?.end_contract_date
        ? moment(selectedData?.end_contract_date)
        : null,
      id_currency: selectedData?.id_currency,
      delivery_count: selectedData?.delivery_count,
      delivery_date: selectedData?.delivery_date
        ? moment(selectedData?.delivery_date)
        : null,
      credit_term: selectedData?.credit_term,
      credit_term_date: selectedData?.credit_term_date
        ? moment(selectedData?.credit_term_date)
        : null,
      description: selectedData?.description,
      created_by: selectedData?.created_by,
      created_date: selectedData?.created_date
        ? moment(selectedData?.created_date).format("DD-MM-YYYY, h:mm:ss")
        : "-",
      updated_by: selectedData?.updated_by ? selectedData?.updated_by : "-",
      updated_date: selectedData?.updated_date
        ? moment(selectedData?.updated_date).format("DD-MM-YYYY, h:mm:ss")
        : "-",
      _id_pr: selectedData?.details[0]?.id_pr,
    });
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setDataPRDetail([]);
    setDataPODetail([]);

    is_close(false);
    setModalOpen(false);

    setIsLoading(false);

    form.resetFields();
  };

  // HANDLE PURCHASE REQUEST (PO)
  const getPurchaseRequest = (allKey) => {
    console.log("All Keys >>> ", allKey);

    form.setFieldsValue({
      _id_pr: allKey[0]?.details[0]?.id_pr,
    });
  };

  return (
    <>
      <Modal
        className="po-modal"
        key="po-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Purchase Order Outstanding Detail`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        destroyOnClose={true}
        closable
        onCancel={handleClose}
        width={1200}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="vertical"
          form={form}
          initialValues={{
            id_currency: "IDR",
            _id_pr: selectedData?.details[0]?.id_pr,
          }}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top" gutter={[50]}>
            <Col className="form-col" span={24}>
              <>
                <Row className="info-row" gutter={30} justify="start">
                  <Col span={8} className="pay-col">
                    <Form.Item label="Purchase Request" name="_id_pr">
                      <PurchaseRequestList
                        getPRNumber={getPurchaseRequest}
                        pr={selectedData?.pr_number}
                        is_open={modalOpen}
                      />
                    </Form.Item>

                    <Form.Item label="PR Remarks" name="remarks" hidden>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={8} className="qty-col">
                    <Form.Item
                      label="Start Contract Date"
                      name="start_contract_date"
                    >
                      <DatePicker
                        disabled={nextState ? true : false}
                        format={"YYYY-MM-DD"}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} className="unit-col">
                    <Form.Item
                      label="End Contract Date"
                      name="end_contract_date"
                    >
                      <DatePicker
                        disabled
                        format={"YYYY-MM-DD"}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="info-row" gutter={30} justify="start">
                  <Col span={8} className="unit-col">
                    <Form.Item label="Currency" name="id_currency">
                      <Select
                        disabled
                        options={[
                          {
                            value: 1,
                            label: "IDR",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} className="qty-col">
                    <Form.Item label="Delivery Count" name="delivery_count">
                      <InputNumber
                        disabled
                        className="delivery_count"
                        placeholder="Count"
                        min={1}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} className="unit-col">
                    <Form.Item label="Last Delivery Date" name="delivery_date">
                      <DatePicker
                        disabled={nextState ? true : false}
                        format={"YYYY-MM-DD"}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="info-row" gutter={30} justify="start">
                  <Col span={8} className="qty-col">
                    <Form.Item label="Credit Term" name="credit_term">
                      <InputNumber
                        disabled={nextState ? true : false}
                        className="credit-term"
                        placeholder="credit-term"
                        min={1}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} className="unit-col">
                    <Form.Item
                      label="Last Credit Term Date"
                      name="credit_term_date"
                    >
                      <DatePicker
                        disabled={nextState ? true : false}
                        format={"YYYY-MM-DD"}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="info-row" gutter={30} justify="start">
                  <Col span={24} className="unit-col">
                    <Form.Item label="Description" name="description">
                      <TextArea
                        rows={2}
                        disabled
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  orientation="left"
                  orientationMargin={0}
                  style={{
                    margin: "10px 0px",
                    borderColor: "#EBEDF3",
                    fontSize: 17,
                    fontWeight: 750,
                  }}
                >{`Buying List`}</Divider>

                <POTableDetailModal
                  dataDetail={dataPODetail}
                  params_acc={params}
                />

                <Divider
                  className="divider-form"
                  style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
                />
              </>
              <Divider type="horizontal" style={{ borderColor: "#d9d9d9" }} />

              <Row className="info-row" gutter={30} justify="start">
                <Col span={8}>
                  <Form.Item label="Created By" name="created_by">
                    <Input disabled={true} placeholder="Created By" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Created Date" name="created_date">
                    <Input disabled={true} placeholder="Created Date" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Updated By" name="updated_by">
                    <Input disabled={true} placeholder="Updated By" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="info-row" gutter={30} justify="start">
                <Col span={8}>
                  <Form.Item label="Updated Date" name="updated_date">
                    <Input disabled={true} placeholder="Created Date" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

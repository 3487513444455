// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// GLOBAL STYLES
import { mainBody } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

export default function APAgingTable(props) {
  // PROPS
  const { searchKey, date, is_search } = props;

  // CONTEXT
  const { getByPostAccounting } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // const [searchKey, setSearchKey] = useState(null);
  const [total, setTotal] = useState({
    paid: 0,
    credit: 0,
    end_balance: 0,
    first_option: 0,
    second_option: 0,
    third_option: 0,
    fourth_option: 0,
  });

  // Loading
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // FETCH DATA
  const fetchData = async () => {
    await getByPostAccounting({
      type: "ap-aging",
      date: date ? date : null,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Respones AGING A/P: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const sumTotal =
            _res.length > 0
              ? _res.reduce(
                  (prev, curr) => {
                    return {
                      paid: prev.paid + curr.paid,
                      credit: prev.credit + curr.credit,
                      end_balance: prev.end_balance + curr.ending_balance,
                      first_option: prev.first_option + curr.first_option,
                      second_option: prev.second_option + curr.second_option,
                      third_option: prev.third_option + curr.third_option,
                      fourth_option: prev.fourth_option + curr.fourth_option,
                    };
                  },
                  {
                    paid: 0,
                    credit: 0,
                    end_balance: 0,
                    first_option: 0,
                    second_option: 0,
                    third_option: 0,
                    fourth_option: 0,
                  }
                )
              : {
                  paid: 0,
                  credit: 0,
                  end_balance: 0,
                  first_option: 0,
                  second_option: 0,
                  third_option: 0,
                  fourth_option: 0,
                };

          setData(_res);
          setBulks(_res);
          setTotal(sumTotal);
        } else {
          setData([]);
          setBulks([]);
          setTotal({
            paid: 0,
            credit: 0,
            end_balance: 0,
            first_option: 0,
            second_option: 0,
            third_option: 0,
            fourth_option: 0,
          });
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USEEFFECT FETCHING DATA
  useEffect(() => {
    fetchData();
  }, []);

  // USEEFFECT
  useEffect(() => {
    const executeSearch = async () => {
      if (date) {
        await fetchData();
      }
      if (searchKey) {
        handleSearch();
      }
    };

    if (date || searchKey) {
      executeSearch();
    } else {
      fetchData();
    }
  }, [date, searchKey]);

  // Columns
  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    // {
    //   title: "Credit Limit",
    //   dataIndex: "credit_limit",
    //   key: "credit_limit",
    //   render: (amount) => {
    //     return `${amount > 0 ? amount : 0}`.replace(
    //       /\B(?=(\d{3})+(?!\d))/g,
    //       ","
    //     );
    //   },
    // },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Debit",
      dataIndex: "credit",
      key: "credit",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "End Balance",
      dataIndex: "ending_balance",
      key: "ending_balance",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "0-30 Days",
      dataIndex: "first_option",
      key: "first_option",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "31-60 Days",
      dataIndex: "second_option",
      key: "second_option",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "61-90 Days",
      dataIndex: "third_option",
      key: "third_option",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "> 90 Days",
      dataIndex: "fourth_option",
      key: "fourth_option",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "is_company",
    //   key: "is_company",
    //   render: (status) => {
    //     return status ? "Company" : "Individu";
    //   },
    // },
  ];

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/back-office/account-payable";
    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const _query = searchKey ? searchKey.toLowerCase() : null;

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.supplier_name.toLowerCase();

        console.log("Query: ", _query, _name);

        return _name.indexOf(searchKey) !== -1;
      });

      const updateTotal =
        updatedList.length > 0
          ? updatedList.reduce(
              (prev, curr) => {
                return {
                  paid: prev.paid + curr.paid,
                  credit: prev.credit + curr.credit,
                  end_balance: prev.end_balance + curr.ending_balance,
                  first_option: prev.first_option + curr.first_option,
                  second_option: prev.second_option + curr.second_option,
                  third_option: prev.third_option + curr.third_option,
                  fourth_option: prev.fourth_option + curr.fourth_option,
                };
              },
              {
                paid: 0,
                credit: 0,
                end_balance: 0,
                first_option: 0,
                second_option: 0,
                third_option: 0,
                fourth_option: 0,
              }
            )
          : {
              paid: 0,
              credit: 0,
              end_balance: 0,
              first_option: 0,
              second_option: 0,
              third_option: 0,
              fourth_option: 0,
            };

      setData(updatedList);
      setTotal(updateTotal);
    } else {
      setData([]);
      setTotal({
        paid: 0,
        credit: 0,
        end_balance: 0,
        first_option: 0,
        second_option: 0,
        third_option: 0,
        fourth_option: 0,
      });
    }
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, `A/P Aging`);
  };

  return (
    <>
      <Row className="ap-aging-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => fetchData()}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="ap-aging-table"
              name="ap-aging-table"
              key="ap-aging-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.row_number ? record.row_number : record.id
              }
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.paid}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.credit}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.end_balance}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.first_option}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.second_option}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.third_option}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.fourth_option}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import React, { useState } from "react";

// ANTD COMPONENTS
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Typography,
} from "antd";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { TbDevicesCancel } from "react-icons/tb";

// PAGE COMPONENTS
import WarehouseList from "../../../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";
import MasterArticleMain from "../../../../../../Components/Reusable/Master/ArticleMain/ArticleMain";
import CancelledReceivingTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/CancelledReceiving/Table";
import { useNavigate } from "react-router-dom";
import { IoReturnUpBackOutline } from "react-icons/io5";
import MasterVendor from "../../../../../../Components/Reusable/Master/Vendor/MasterVendor";

export default function CancelledReceivingReportPage() {
  // STATE
  const [articleMain, setArticleMain] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [element, setElement] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/incoming-stock";

    navigate(`${path}`);
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticleMain = (value) => {
    setArticleMain(value);
    setSupplier(null);
  };

  // HANDLE SELECTED SUPPLIER
  const handleSelectedSupplier = (value) => {
    setSupplier(value);
    setArticleMain(null);
  };

  // HANDLE FILTER
  const handleFilter = (val) => {
    const _type = val;

    setFilterValue(_type);

    if (_type == 1) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
          }}
        >
          <Typography
            style={{ marginBottom: 10 }}
          >{`Article Main Group`}</Typography>

          <MasterArticleMain
            getArticleMain={handleSelectedArticleMain}
            mainName={null}
            isDisabled={false}
          />
        </Col>
      );
    } else if (_type == 2) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
            margin: "0px 5px 0px 0px",
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`Supplier`}</Typography>

          <MasterVendor
            selectedSupplier={handleSelectedSupplier}
            supplier_name={null}
            is_disable={false}
          />
        </Col>
      );
    } else {
      null;
    }
  };

  const options = [
    {
      label: "Article Main Group",
      value: 1,
    },
    {
      label: "Supplier",
      value: 2,
    },
  ];

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <TbDevicesCancel
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Cancelled Receiving List Reports`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Filter By`}
                  </Typography>

                  <Select
                    className="type-select"
                    key="type-select"
                    allowClear
                    showSearch
                    value={filterValue}
                    onChange={handleFilter}
                    options={options}
                    placeholder="Filter By"
                    style={{
                      width: 250,
                    }}
                  />
                </Col>

                {element}

                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleReturn}
                  >
                    {`Return`}
                  </Button>
                </Col>
              </Space>
            </Row>

            {articleMain || supplier ? (
              <CancelledReceivingTable
                id_main={articleMain?.main_id}
                id_supplier={supplier?.id_supplier}
                type={filterValue}
              />
            ) : null}
          </Col>
        </Content>
      </Layout>
    </>
  );
}

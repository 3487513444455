// Import React's Component
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_module } from "../../API/Global/Payload";

// Import Page Components
import MasterRoutes from "../Master/Routes";
import ParamsRoutes from "../Parameters/Routes";
import UserControlRoutes from "../User/Routes";
import NotFound from "../../Pages/NotFound/NotFound";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";

// Sub Route
const subRoute = [
  {
    path: "master/*",
    name: "master",
    id_modules: 44,
    element: <MasterRoutes />,
  },
  {
    path: "parameters/*",
    name: "parameters",
    id_modules: 45,
    element: <ParamsRoutes />,
  },
  {
    path: "/user-management/*",
    name: "user-management",
    id_modules: 46,
    element: <UserControlRoutes />,
  },
];

// CODE
export default function ConfigRoutes(props) {
  // console.log("Routes Config: ", props);

  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_module?.length > 0 ? sub_module : []; // values.module;

    const proRoute = subRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes Config: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}
      </Routes>
      <Outlet />
    </>
  );
}

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { HiTemplate } from "react-icons/hi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { TbBulb } from "react-icons/tb";

// Import Page Components
import NightAuditSteps from "../../../Components/Steps/BackOffice/NightAudit/Steps";
import BSTemplateTable from "../../../Components/Table/Master/BSTemplate/Table";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";

// CODE
export default function NightAuditPage() {
  return (
    <Layout>
      <Content>
        <Col span={24} className="bill-template-main-layout">
          <Row className="bill-template-title" justify="start" style={mainBody}>
            <TbBulb
              style={{
                fontSize: 30,
                // height: 24,
                // width: 24,
                margin: "22px 30px",
                color: "#3699FF",
                transform: "rotate(180deg)",
              }}
            />

            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              Night Audit
            </Typography>
          </Row>

          <NightAuditSteps />
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Notifications
import { failedFetch } from "../Notification/Notification";

// CODE
export default function AuditDate(props) {
  // CONTEXT
  const { getDateAudit } = useContext(MainContext);

  // STATE MANAGEMENT
  const [audit, setAudit] = useState();
  //   var date = "";

  // FETCH DATA
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/night-audit/audit-date`)
      .then((response) => {
        // console.log("RES Date: ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response?.data?.msg[0];
          setAudit(_response.audit_date);
        }
      })
      .catch((error) => {
        console.log("Error get Date Audit: ", error);
        failedFetch(error);
      });
    // getDateAudit({
    //   runNA: "night-audit",
    //   type: "night-audit",
    //   onAwait: () => {
    //     // setLoading(true);
    //   },
    //   onSuccess: (response) => {
    //     // console.log("Date Audit --> ", response);
    //     // setLoading(false);
    //     setAudit(response?.data?.msg[0]?.audit_date);
    //     // date = response?.data?.msg[0]?.audit_date;
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //     // setLoading(false);
    //   },
    // });
  };

  // USER ROLES
  useEffect(() => {
    fetchData();
  }, []);

  return { audit };
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import InterKitchenTransferForm from "../../../../../../Forms/InterKitchenTransfer/Form";

export default function InterKitchenTransferReportTable(props) {
  // PROPS
  const { _startDate, _endDate, _fromDept, _toDept, _type } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    food_cost: 0,
    beverage_cost: 0
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if ( _startDate && _endDate && _fromDept && _toDept && _type && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setTotal({
        food_cost: 0,
        beverage_cost: 0
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_startDate, _endDate, _fromDept, _toDept, _type]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    let bodyReq = {};

    bodyReq = {
      from_date: moment(_startDate).format("YYYY-MM-DD"),
      to_date: moment(_endDate).format("YYYY-MM-DD")
    };

    await getByPostInventoryReport({
      type: `transfer-summary`,
      bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name
              // food_cost:,
              // beverage_cost:
            };
          });

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      food_cost: prev.food_cost + curr.food_cost,
                      beverage_cost: prev.beverage_cost + curr.beverage_cost,
                    };
                  },
                  { food_cost: 0, beverage_cost: 0 }
                )
              : { food_cost: 0, beverage_cost: 0 };

          setData(filter);
          setTotal(sumTotal);
        } else {
          setData([]);
          setTotal({
            food_cost: 0,
            beverage_cost: 0
          });
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Inter Kitchen Transfer Reports ${
        _startDate ? moment(_startDate).format("YYYY-MM-DD") : ""} - ${
          _endDate ? moment(_endDate).format("YYYY-MM-DD") : ""}`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY") : "-";
      }
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Transfer From",
      dataIndex: "transfer_from",
      key: "transfer_from",
    },
    {
      title: "Transfer To",
      dataIndex: "transfer_to",
      key: "transfer_to",
    },
    {
      title: "Food Cost",
      dataIndex: "food_cost",
      key: "food_cost",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Beverage Cost",
      dataIndex: "beverage_cost",
      key: "beverage_cost",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    }
  ].filter(item => !item.hidden);

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.food_cost ? total.food_cost.toFixed(0) : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.beverage_cost ? total.beverage_cost.toFixed(0) : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <InterKitchenTransferForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _startDate = {_startDate}
        _endDate = {_endDate}
        _fromDept = {_fromDept ? _fromDept : null}
        _toDept = {_toDept ? _toDept : null}
        _type = {_type}
      />
    </>
  );
}

// Import React Components
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as CMContext } from "../../../API/Context/ManagerContext/ManagerContext";

// Import Antd Components
import {
  Card,
  Col,
  Row,
  Form,
  Typography,
  DatePicker,
  InputNumber,
  Button,
  Divider,
  Space,
  Collapse,
  Select,
  Table,
  Tooltip,
  Image,
  Modal,
  Input,
  Upload,
  message,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  PlusCircleTwoTone,
  SyncOutlined,
  InboxOutlined,
  CheckSquareTwoTone,
} from "@ant-design/icons";
import {
  FaUser,
  FaRegSnowflake,
  FaWifi,
  FaCube,
  FaMale,
  FaTree,
  FaSwimmingPool,
  FaWater,
} from "react-icons/fa";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";

const { Title } = Typography;
const { Panel } = Collapse;
const { Dragger } = Upload;

const marginLayout = {
  marginTop: 30,
};

// OCDE
export default function MasterManager() {
  // Collapse List Data
  const collapseMainMenu = [
    {
      title: "Dashboard",
      key: "Dashboard",
      index: 1,
    },
    {
      title: "Room Availability",
      key: "RoomAvailability",
      index: 2,
    },
    {
      title: "Reservation Article",
      key: "ReservationArticle",
      index: 3,
    },
  ];

  // Table Columns
  const columnsDashboard = [
    {
      title: "Action",
      dataIndex: "action",
      width: 60,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      // setEditData(record);
                      form.setFieldsValue({
                        // payment_card_id: record.payment_card_id,
                        // code: record.code,
                        // name: record.name,
                        // description: record.description,
                        // chart_of_account: record.chart_of_account,
                        // payment_type_id: record.payment_type_id,
                        // payment_type_name: record.payment_type_name,
                        // created_by: record.created_by,
                        // created_date: record.created_date,
                        // modified_by: record.updated_by,
                        // modified_date: record.updated_date,
                      });
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24}>
                <Image width={200} src="/assets/img/foto-dashboard-2.jpg" />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      // width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];
  const columnsRoomAvailability = [
    {
      title: "Action",
      dataIndex: "action",
      width: 60,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);
                      form.setFieldsValue({
                        room_category_id: record.room_category_id,
                        room_category_code: record.room_category_code,
                        room_category_name: record.room_category_name,
                        air_conditioner: record.air_conditioner,
                        balcony: record.balcony,
                        garden_view: record.garden_view,
                        free_wifi: record.free_wifi,
                        safety_deposite_box: record.safety_deposite_box,
                        desk: record.desk,
                        bath: record.bath,
                        hairdryer: record.hairdryer,
                        shower: record.shower,
                        telephone: record.telephone,
                        minibar: record.minibar,
                        tea_coffee_maker: record.tea_coffee_maker,
                        alarm_clock: record.alarm_clock,
                        towels: record.towels,
                        linen: record.linen,
                        pool_view: record.pool_view,
                        cable_channels: record.cable_channels,
                        tv: record.tv,
                        dinning_area: record.dinning_area,
                        satelite_channels: record.satelite_channels,
                        updated_by: record.updated_by,
                        images: record.images,
                        description: record.description,
                        room_size: record.room_size,
                      });
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24}>
                <Image width={200} src="/assets/img/foto-dashboard-2.jpg" />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
      // width: 200,
      render: (catg, _) => {
        switch (catg) {
          case "DELUXE":
            return (
              <Tag color="orange" style={{ fontSize: 18, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          case "SUPERIOR":
            return (
              <Tag color="purple" style={{ fontSize: 18, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          case "EXECUTIVE":
            return (
              <Tag color="geekblue" style={{ fontSize: 18, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          case "SUITE":
            return (
              <Tag color="red" style={{ fontSize: 18, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          case "STANDARD":
            return (
              <Tag color="green" style={{ fontSize: 18, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          default:
        }
      },
    },
    {
      title: "Room Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: (
        <>
          <Row justify="center">
            <FaCube style={{ color: "#1890ff" }} />
          </Row>
          <Row justify="center">
            <p>Room Size</p>
          </Row>
        </>
      ),
      dataIndex: "room_size",
      key: "room_size",
      render: (roomSize, _) => {
        return (
          <Row justify="center">
            <Title level={5}>
              {roomSize} m<sup>2</sup>
            </Title>
          </Row>
        );
      },
    },
    {
      title: (
        <>
          <Row justify="center">
            <FaRegSnowflake style={{ color: "#1890ff" }} />
          </Row>
          <Row justify="center">
            <p>Air Conditioner</p>
          </Row>
        </>
      ),
      dataIndex: "air_conditioner",
      key: "air_conditioner",
      render: (_, record) => {
        return record.air_conditioner == true ? (
          <Row justify="center">
            <Checkbox checked={true} />
          </Row>
        ) : (
          <Row justify="center">
            <Checkbox checked={false} />
          </Row>
        );
      },
    },
    {
      title: (
        <>
          <Row justify="center">
            <FaWifi style={{ color: "#1890ff" }} />
          </Row>
          <Row justify="center">
            <p>Free Wifi</p>
          </Row>
        </>
      ),
      dataIndex: "free_wifi",
      key: "free_wifi",
      render: (_, record) => {
        return record.free_wifi == true ? (
          <Row justify="center">
            <Checkbox checked={true} />
          </Row>
        ) : (
          <Row justify="center">
            <Checkbox checked={false} />
          </Row>
        );
      },
    },
    {
      title: (
        <>
          <Row justify="center">
            <FaTree style={{ color: "#1890ff" }} />
          </Row>
          <Row justify="center">
            <p>Garden View</p>
          </Row>
        </>
      ),
      dataIndex: "garden_view",
      key: "garden_view",
      render: (_, record) => {
        return record.garden_view == true ? (
          <Row justify="center">
            <Checkbox checked={true} />
          </Row>
        ) : (
          <Row justify="center">
            <Checkbox checked={false} />
          </Row>
        );
      },
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
    },
    {
      title: "Child",
      dataIndex: "child",
      key: "child",
    },
    {
      title: "Infant",
      dataIndex: "infant",
      key: "infant",
    },
    {
      title: "Package Title",
      dataIndex: "package_title",
      key: "package_title",
    },
    {
      title: "Package Description",
      dataIndex: "package_description",
      key: "package_description",
    },
    {
      title: "Package Room Price",
      dataIndex: "unit_price",
      key: "unit_price",
    },
    {
      title: "Package Refundable",
      dataIndex: "package_refund",
      key: "package_refund",
    },
    {
      title: "Package Refundable Due Date",
      dataIndex: "package_refund_date",
      key: "package_refund_date",
    },
  ];
  const columnsReservationArticle = [
    {
      title: "Action",
      dataIndex: "action",
      width: 60,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);
                      form.setFieldsValue({
                        package_name: record.package_name,
                        package_code: record.package_code,
                        total_price: record.total_price,
                        total_taxes: record.total_taxes,
                        description: record.description,
                        images: record.images,
                        category: record.category,
                      });
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24}>
                <Image width={200} src="/assets/img/foto-dashboard-1.jpg" />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Category",
      dataIndex: "category",
      key: "category",
      // width: 200,
      render: (catg, _) => {
        switch (catg) {
          case "Food & Beverages":
            return (
              <Tag color="orange" style={{ fontSize: 14, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          case "Transportation Arrangements":
            return (
              <Tag color="purple" style={{ fontSize: 14, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          case "Other Services":
            return (
              <Tag color="geekblue" style={{ fontSize: 14, fontWeight: 600 }}>
                {catg}
              </Tag>
            );
          default:
        }
      },
    },
    {
      title: "Package Name",
      dataIndex: "package_name",
      key: "package_name",
      // width: 200,
      render: (packageName, _) => {
        return <Title level={5}>{packageName}</Title>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Unit Price (Exclude Tax)",
      dataIndex: "total_price",
      key: "total_price",
      width: 150,
      render: (price, record) => {
        return (
          <Title level={5}>
            {`Rp. ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Title>
        );
      },
    },
    {
      title: "Tax",
      dataIndex: "total_taxes",
      key: "total_taxes",
      width: 150,
      render: (taxValue, record) => {
        return (
          <Title level={5}>
            {`Rp. ${taxValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Title>
        );
      },
    },
  ];

  // Upload Drag & Drop
  const dnd = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // USE NAVIGATE & LOCATION
  const navigate = useNavigate();
  const { state } = useLocation();

  // CONTEXT
  const { getCMCategoryInfo, getCMResArticle } = useContext(CMContext);

  // USE FORM
  const [form] = Form.useForm();

  // STATE MANAGEMENT
  const [editData, setEditData] = useState(null);
  const [titleName, setTitleName] = useState(null);
  const [dataDashboard, setDataDashboard] = useState(null);
  const [dataRoomAvailability, setDataRoomAvailability] = useState(null);
  const [dataReservationArticle, setDataReservationArticle] = useState(null);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const { confirm } = Modal;

  // Loading
  const [loading, setLoading] = useState(false);

  // Fetch Data Room Availability
  const fetchDataRoomAvl = async () => {
    setLoading(true);

    await getCMCategoryInfo({
      cmCategoryInfo: "category-info",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response CM Room Category Info => ", response);

        setDataRoomAvailability(response.data.msg);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error Room Category Info => ", error);
        // setLoading(false);
        // failedFetch(error);
      },
    });
  };
  // Fetch Data Room Article
  const fetchDataResArticle = async () => {
    setLoading(true);

    await getCMResArticle({
      cmResArticle: "package",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response CM Reservation Article => ", response);

        setDataReservationArticle(response.data.msg);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error CM Reservation Article => ", error);
        // setLoading(false);
        // failedFetch(error);
      },
    });
  };

  // USE EFFECT DATA
  useEffect(() => {
    fetchDataRoomAvl();
    fetchDataResArticle();
  }, []);

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    // setId(null);
    form.resetFields();
  };

  // SHOW MODAL EDIT
  const showEditModal = (e) => {
    confirm({
      className: "delete-pay-card-confirm",
      title: `Are you sure want to edit from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(e);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    // console.log(value);

    showEditModal(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    // masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = (value) => {
    const contentsubmit = value;
    console.log("ON OK ==> ", contentsubmit);

    if (edit == true) {
      switch (titleName) {
        case "Dashboard":
        case "Room Availability":
          axios
            .put(`${baseurl}/room/category-info`, {
              room_category_id: editData.room_category_id
                ? editData.room_category_id
                : "",
              room_category_code: editData.room_category_code
                ? editData.room_category_code
                : "",
              room_category_name: contentsubmit.room_category_name
                ? contentsubmit.room_category_name
                : "",
              air_conditioner: contentsubmit.air_conditioner
                ? contentsubmit.air_conditioner
                : "",
              balcony: contentsubmit.balcony ? contentsubmit.balcony : false,
              garden_view: contentsubmit.garden_view
                ? contentsubmit.garden_view
                : false,
              free_wifi: contentsubmit.free_wifi
                ? contentsubmit.free_wifi
                : false,
              safety_deposite_box: contentsubmit.safety_deposite_box
                ? contentsubmit.safety_deposite_box
                : false,
              desk: contentsubmit.desk ? contentsubmit.desk : false,
              bath: contentsubmit.bath ? contentsubmit.bath : false,
              hairdryer: contentsubmit.hairdryer
                ? contentsubmit.hairdryer
                : false,
              shower: contentsubmit.shower ? contentsubmit.shower : false,
              telephone: contentsubmit.telephone
                ? contentsubmit.telephone
                : false,
              minibar: contentsubmit.minibar ? contentsubmit.minibar : false,
              tea_coffee_maker: contentsubmit.tea_coffee_maker
                ? contentsubmit.tea_coffee_maker
                : false,
              alarm_clock: contentsubmit.alarm_clock
                ? contentsubmit.alarm_clock
                : false,
              towels: contentsubmit.towels ? contentsubmit.towels : false,
              linen: contentsubmit.linen ? contentsubmit.linen : false,
              pool_view: contentsubmit.pool_view
                ? contentsubmit.pool_view
                : false,
              cable_channels: contentsubmit.cable_channels
                ? contentsubmit.cable_channels
                : false,
              tv: contentsubmit.tv ? contentsubmit.tv : false,
              dinning_area: contentsubmit.dinning_area
                ? contentsubmit.dinning_area
                : false,
              satelite_channels: contentsubmit.satelite_channels
                ? contentsubmit.satelite_channels
                : false,
              updated_by: "DEV",
              id: editData.id ? editData.id : "",
              images: contentsubmit.images ? contentsubmit.images : "",
              description: contentsubmit.description
                ? contentsubmit.description
                : "",
              room_size: contentsubmit.room_size ? contentsubmit.room_size : "",
            })
            .then((response) => {
              console.log("UPDATE Pay Card = ", response);
              // successPayCard(response, { method: 1 });
              handleCancel();
            })
            .catch((error) => {
              console.log("UPDATE Pay Card = ", error);
              // failedPayCard(error, { method: 1 });
            })
            .finally(() => {
              fetchDataRoomAvl();
            });
          break;
        case "Reservation Article":
          axios
            .put(`${baseurl}/channel-manager/article/package`, {
              package_name: contentsubmit.package_name
                ? contentsubmit.package_name
                : "",
              package_code: editData.package_code ? editData.package_code : "",
              total_price: contentsubmit.total_price
                ? contentsubmit.total_price
                : "",
              total_taxes: contentsubmit.total_taxes
                ? contentsubmit.total_taxes
                : "",
              description: contentsubmit.description
                ? contentsubmit.description
                : "",
              images: contentsubmit.images ? contentsubmit.images : "",
              category: contentsubmit.category ? contentsubmit.category : "",
              updated_by: "DEV",
              id: editData.id ? editData.id : "",
            })
            .then((response) => {
              console.log("UPDATE Pay Card = ", response);
              // successPayCard(response, { method: 1 });
              handleCancel();
            })
            .catch((error) => {
              console.log("UPDATE Pay Card = ", error);
              // failedPayCard(error, { method: 1 });
            })
            .finally(() => {
              fetchDataResArticle();
            });
          break;
        default:
      }
    }
  };

  const onChangeCollapse = (value, key, index) => {
    console.log(value, key, index);
    setTitleName(value);
  };

  console.log(titleName);

  return (
    <>
      <Card title={<Title level={4}>Master Channel Manager</Title>}>
        <Typography>
          Configure the data that you want to serve for the costumers.
        </Typography>
      </Card>
      <Row gutter={[30, 30]} style={marginLayout}>
        <Col lg={24} sm={24} xs={24}>
          <Row style={mainBody}>
            <Col span={24} style={{ padding: 30 }}>
              <Row>
                <Title level={4}>Data Configuration</Title>
              </Row>
              <Divider style={{ backgroundColor: "#d9d9d9" }}></Divider>
              <Row>
                <Col span={24}>
                  <Collapse
                    accordion
                    size="large"
                    bordered={false}
                    onChange={onChangeCollapse}
                  >
                    {collapseMainMenu.map((item) => {
                      return (
                        <Panel
                          header={<Title level={5}>{item.title}</Title>}
                          key={item.title.toString()}
                        >
                          <Row className="row-modal-btn">
                            <Col span={24} className="col-modal-btn">
                              <Row justify="end" className="row-open-btn">
                                <Button
                                  className="refresh-btn"
                                  type="default"
                                  icon={<SyncOutlined />}
                                  onClick={() => {
                                    fetchDataRoomAvl();
                                    fetchDataResArticle();
                                  }}
                                  style={{ marginRight: 15 }}
                                >
                                  Refresh
                                </Button>
                              </Row>
                            </Col>
                          </Row>

                          <Row style={{ padding: 15 }}>
                            <Table
                              className="pay-card-table"
                              key="pay-card-table"
                              name="pay-card-table"
                              loading={loading}
                              bordered
                              columns={eval(`columns${item.key}`)}
                              dataSource={eval(`data${item.key}`)}
                              pagination={{
                                pageSizeOptions: [5, 10, 25, 50, 100],
                                defaultPageSize: 5,
                                showSizeChanger: true,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              }}
                              scroll={
                                item.key == "Dashboard"
                                  ? {
                                      x: 1000,
                                    }
                                  : item.key == "RoomAvailability"
                                  ? { x: 2500 }
                                  : item.key == "ReservationArticle"
                                  ? { x: 1500 }
                                  : { x: 1500 }
                              }
                              rowClassName={(record, index) => {
                                return index % 2 === 0 ? "odd" : "even";
                              }}
                              rowKey={(record) =>
                                record.id ? record.id : record.key
                              }
                            />
                          </Row>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        className="add-edit-pay-card-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                Add {titleName} Data
              </Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                Edit {titleName} Data
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="pay-card-modal-form"
          className="pay-card-modal-form"
          layout="vertical"
          form={form}
          onFinish={(e) => onFinish(e)}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px 0px 36px" }}
        >
          {titleName == "Dashboard" ? (
            <>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please, add the title!",
                      },
                    ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      // style={{
                      //   height: 100,
                      //   width: 440,
                      // }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please, add a description!",
                      },
                    ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      style={{
                        height: 150,
                      }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Upload Image"
                    name="image"
                    rules={[
                      {
                        required: true,
                        message: "Please, upload an image!",
                      },
                    ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Dragger {...dnd}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </p> */}
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ margin: "15px 0px 30px" }} />
            </>
          ) : (
            <></>
          )}

          {titleName == "Room Availability" ? (
            <>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Room Category"
                    name="room_category_name"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add room category!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      // style={{
                      //   height: 100,
                      //   width: 440,
                      // }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Room Description"
                    name="description"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add a description!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      style={{
                        height: 150,
                      }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={6}>
                  <Form.Item
                    label="Room Size"
                    name="room_size"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add room area!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <InputNumber
                      placeholder="Room Size"
                      addonAfter={"m2"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Divider type="vertical" style={{ height: 400 }} />
                <Col span={4}>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="air_conditioner"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Air Conditioner</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="balcony"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Balcony</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="garden_view"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Garden View</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="free_wifi"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Free Wifi</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 100 }}>
                    <Form.Item
                      name="safety_deposite_box"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Safety Deposit Box</Checkbox>
                    </Form.Item>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="desk"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Desk</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="bath"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Bath</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="hairdryer"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Hair Dryer</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="shower"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Shower</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="telephone"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Telephone</Checkbox>
                    </Form.Item>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="minibar"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Mini Bar</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="tea_coffee_maker"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Tea/Coffee Maker</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="alarm_clock"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Alarm Clock</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="towels"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Towels</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="linen"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Linen</Checkbox>
                    </Form.Item>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="pool_view"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Pool View</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="cable_channels"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Cable Channels</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="tv"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>TV</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="dinning_area"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Dinning Area</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row style={{ height: 80 }}>
                    <Form.Item
                      name="satelite_channels"
                      valuePropName="checked"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, add AC status!",
                      //   },
                      // ]}
                      // style={{ width: 440, marginRight: 30 }}
                    >
                      <Checkbox>Satelite Channels</Checkbox>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Adult"
                    name="adult"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add max adult!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <InputNumber
                      placeholder="Adult"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Child"
                    name="child"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add max child!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <InputNumber
                      placeholder="Child"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Infant"
                    name="infant"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add max infant!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <InputNumber
                      placeholder="Infant"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider></Divider>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Package Title"
                    name="package_title"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add package title!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      // style={{
                      //   height: 100,
                      //   width: 440,
                      // }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Package Description"
                    name="package_description"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add package description!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      // style={{
                      //   height: 100,
                      //   width: 440,
                      // }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Package Room Price"
                    name="package_room_price"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add package room price!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <InputNumber
                      placeholder="Price"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Package Refundable"
                    name="package_refundable"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add refundable status!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Select
                      defaultValue={false}
                      style={{
                        width: "100%",
                      }}
                      // onChange={handleChange}
                      options={[
                        {
                          value: true,
                          label: "Yes",
                        },
                        {
                          value: false,
                          label: "No",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Package Refund Due Date"
                    name="package_refund_duedate"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, add package refund duedate!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <DatePicker placeholder="date" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider></Divider>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Upload Image"
                    name="images"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, upload an image!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Dragger {...dnd}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </p> */}
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ margin: "15px 0px 30px" }} />
            </>
          ) : (
            <></>
          )}

          {titleName == "Reservation Article" ? (
            <>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Package Name"
                    name="package_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, add the title!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      // style={{
                      //   height: 100,
                      //   width: 440,
                      // }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please, add a description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      style={{
                        height: 150,
                      }}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Article Category"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please, input an article category!",
                      },
                    ]}
                  >
                    <Select
                      defaultValue="food&beverages"
                      style={{
                        width: "100%",
                      }}
                      // onChange={handleChange}
                      options={[
                        {
                          value: "food&beverages",
                          label: "Food & Baverages",
                        },
                        {
                          value: "otherservices",
                          label: "Other Services",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Unit Price (Exclude Tax)"
                    name="total_price"
                    rules={[
                      {
                        required: true,
                        message: "Please, input an unit price!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Unit Price"
                      addonBefore="Rp"
                      formatter={(deposit) => {
                        return ` ${deposit}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      style={{ marginRight: 30, width: 205 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Tax"
                    name="total_taxes"
                    rules={[
                      {
                        required: true,
                        message: "Please, input the tax!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Tax"
                      addonBefore="Rp"
                      formatter={(deposit) => {
                        return ` ${deposit}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      style={{ marginRight: 30, width: 205 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30} className="row-pay-card-pay-type">
                <Col span={24}>
                  <Form.Item
                    label="Upload Image"
                    name="images"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, upload an image!",
                    //   },
                    // ]}
                    // style={{ width: 440, marginRight: 30 }}
                  >
                    <Dragger {...dnd}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      {/* <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </p> */}
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Divider style={{ margin: "15px 0px 30px" }} />
            </>
          ) : (
            <></>
          )}

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-pay-card">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-pay-card">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="pay-card-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Space size="middle">
              <Button
                type="primary"
                htmlType="submit"
                key={"submit"}
                // onClick={onFinish}
                className="submit-btn"
                style={{
                  backgroundColor: "#1BC5BD",
                  borderColor: "#1BC5BD",
                  // marginRight: 30,
                }}
              >
                Submit
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

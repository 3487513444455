// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// Context
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Modal,
  Form,
} from "antd";

// Import React Icons
import {
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import ModalTableOrder from "../../../../Modals/Outlet/Order/Modal";
import TableOrderPayment from "../../../../Modals/Outlet/Order/Payment";
import TableCategoryCard from "../../../../Widgets/Outlet/CategoryCard/CategoryCard";
import TableListCard from "../../../../Widgets/Outlet/TableList/TableList";
import CreateOrderPage from "../../../../../Pages/Outlet/Order/CreateOrder/CreateOrder";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";
import ModalReservationTable from "../../../../Modals/Outlet/Order/ReservationTableModal";

// Modals
const { confirm } = Modal;

// CODE
export default function CaptainOrderTable(props) {
  // Props
  const {
    table_number,
    outlet_data,
    selections,
    is_roomService,
    is_search,
    searchKeyTableNo,
    searchKeyName,
    is_refresh,
    is_from_resv,
    table_from_resv,
    isChangeTable,
  } = props;

  // CONTEXT
  const { getTableList } = useContext(MasterContext);
  const { getRoomStatus } = useContext(MainContext);

  // STATE MANAGEMENT
  // State Page
  const [stateOforder, setStateOforder] = useState(1); // 1 For Loaded, 2  for Order
  // Data Table
  const [data, setData] = useState([]);
  const [dataBulks, setDataBulks] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [tableSelected, setTableSelected] = useState(null);
  const [tableResv, setTableResv] = useState(null);
  // Outlet
  const [selectedData, setSelectedData] = useState({
    shift: "UNSELECTED",
    locations: "UNSELECTED",
    locations_id: 0,
    shift_id: 0,
  });
  // Card Button & Categories
  const [cardButton, setCardButton] = useState([]);
  const [categories, setCategories] = useState({
    table_category_code: "",
    table_category_name: "All",
    table_category_id: 0,
    id: 0,
  });
  // Room Service
  const [isRoomService, setIsRoomService] = useState(false);
  // Loading State
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Boolean
  const [edit, setEdit] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [openModalResvTable, setOpenModalResvTable] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  // Fetch Filter
  const fetchFilter = async (value) => {
    const _id = value;

    await getTableList({
      type: "table-by-category",
      table_category_id: _id > 0 ? _id : categories.table_category_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Outlet Table Filter: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setDataBulks(_res);
        } else {
          setData([]);
          setDataBulks([]);
        }

        setLoading(false);

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  const fetchAll = async () => {
    setLoading(true);
    await axios
      .post(`${baseurl}/pos/table-by-outlet`, {
        outlet_id: selectedData?.locations_id,
      })
      .then((response) => {
        console.log("ALL TABLE >>> ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            return e;
          });
          setData(_res);
          setDataBulks(_res);
        }
      })
      .catch((error) => {
        console.log("Error All Table >>> ", error);

        // failedFetch(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Fetch Room
  const fetchRoom = async (value) => {
    await getRoomStatus({
      status: "status",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ISI Room List >> ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            if (val?.is_deleted == false || val?.is_deleted == null) {
              return val;
            }
          });

          setBulks(filtered);

          if (value === true) {
            handleFilterImmediate(filtered);
          }
        } else {
          // setData([]);
          setBulks([]);
        }

        setLoading(false);
        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Room Service: ", is_roomService);
    if (is_roomService === true && !cleanUp) {
      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_roomService]);

  // Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKeyTableNo, searchKeyName]);

  // Data Effects
  useEffect(() => {
    let cleanUp = false;

    if (outlet_data?.length > 0 && !cleanUp) {
      console.log("Data Outlet: ", outlet_data);

      setCardButton(outlet_data);
      setStateOforder(1);
    } else {
      setCardButton([]);
    }

    if (selections?.locations_id > 0 && !cleanUp) {
      console.log("Selected: ", selections);

      setSelectedData({
        locations: selections?.locations,
        shift: selections?.shift,
        locations_id: selections?.locations_id,
        shift_id: selections?.shift_id,
      });

      setData([]);
      fetchRoom();
      setStateOforder(1);
      fetchAll();
    } else {
      setSelectedData({
        locations: "",
        shift: "",
        locations_id: 0,
        shift_id: 0,
      });
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [selections, outlet_data, is_roomService]);

  useEffect(() => {
    if (is_from_resv == true) {
      setStateOforder(2);
      setTableResv(table_from_resv);
      setTableSelected({
        id: 46,
        table_code: table_from_resv?.table_code,
        seat: null,
        table_id: table_from_resv?.table_id,
        status_table: table_from_resv?.status_table,
        description: null,
        created_date: null,
        created_by: null,
        updated_date: null,
        updated_by: null,
        deleted_date: null,
        deleted_by: null,
        is_deleted: false,
        table_category_id: 0,
        outlet_location_id: 0,
        table_category_code: null,
        outlet_location_code: null,
        hotel_id: null,
      });
    }
  }, [is_from_resv]);

  useEffect(() => {
    console.log("TABLE RSV DATA >>>", tableResv);

    // const func = () => {
    //   setTableSelected({
    //     ...tableSelected,
    //     ...{ status_table: 2 },
    //   });
    //   setStateOforder(2);
    // };

    // if (tableResv[0]?.order_id_alias != null) {
    //   setStateOforder(2);
    // }
    if (tableResv != null) {
      setStateOforder(2);
    }
  }, [tableResv]);

  // Handle Filter
  const handleFilter = (params) => {
    // const _data = value;
    const _code = params.toLowerCase();

    let _filtered = bulks.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Handle Filter >>>", _filtered);

    setData(_filtered);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  // Handle Filter
  const handleFilterImmediate = (value) => {
    const _data = value;
    const _code = categories.table_category_code.toLowerCase();

    let _filtered = _data.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Handle Filter Immediate >>>", _filtered);

    setData(_filtered);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle Refresh
  const handleRefresh = () => {
    const _val = true;
    setIsLoading(true);

    switch (isRoomService) {
      case true:
        fetchRoom(_val);
        break;

      case false:
        if (categories?.table_category_id != 0) {
          fetchFilter(categories?.table_category_id);
        } else {
          fetchAll();
        }
        break;

      default:
        break;
    }
  };

  // Refresh Categories
  const handleRefreshCat = () => {
    is_refresh(true);
  };

  const handleOpenPay = () => {
    setOpenPay(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // Handle Button Card
  const handleButtonCard = (value) => {
    const _data = value;
    setIsLoading(true);

    console.log("Table Category: ", _data);

    setCategories(_data);

    if (isRoomService) {
      handleFilter(_data?.table_category_code);
    } else {
      if (_data?.table_category_id != 0) {
        fetchFilter(_data?.table_category_id);
      } else {
        fetchAll();
      }
    }
  };

  // HANDLE TABLE
  const handleTable = (val, ind) => {
    const _number = val;
    const _index = ind;

    console.log("Table Selected --> ", _number);
    // console.log("Table Index --> ", _index);

    setTableSelected(_number);
    table_number(_number);
    if (_number?.status_table == 1) {
      if (isChangeTable != true) {
        setOpenModalResvTable(true);
      }
    } else {
      setStateOforder(2);
    }

    // setTimeout(() => {
    //   setStateOforder(3);
    // }, 2000);
  };

  // ON RETURN
  const handleReturn = (value) => {
    if (value === true) {
      setStateOforder(1);
      setTableResv(null);
    } else {
      setStateOforder(2);
    }
  };

  // ON CREATED
  const handleCreate = (value) => {
    if (value === true) {
      setStateOforder(1);
      handleRefresh();
    } else {
      setStateOforder(2);
    }
  };

  // Handle Search
  const handleSearch = () => {
    const _data = isRoomService ? bulks : dataBulks;

    // console.log("Search Table No: ", searchKeyTableNo);
    // console.log("Search Name: ", searchKeyName);
    // console.log("Data to Search: ", _data);

    let _updatedList = _data.filter((item) => {
      // console.log("Items: ", item);
      const tableNo =
        item?.table_code.toLowerCase() || item?.table_id.toLowerCase();
      const name = item?.res_time;

      if (isRoomService) {
        return (
          item?.room_category_code
            .toLowerCase()
            .indexOf(searchKeyTableNo.toLowerCase()) !== -1 ||
          item?.room_category_name
            .toLowerCase()
            .indexOf(searchKeyTableNo.toLowerCase()) !== -1
        );
      } else {
        if (!searchKeyTableNo && !searchKeyName) {
          return item;
        }
        if (searchKeyTableNo && !searchKeyName) {
          return tableNo.indexOf(searchKeyTableNo.toLowerCase()) !== -1;
        }
        if (!searchKeyTableNo && searchKeyName) {
          for (let i = 0; i < name.length; i++) {
            if (
              (name[i]?.name ?? "")
                .toLowerCase()
                .indexOf(searchKeyName.toLowerCase()) !== -1
            ) {
              return item;
            }
          }
        }
        if (searchKeyTableNo && searchKeyName) {
          for (let i = 0; i < name.length; i++) {
            if (
              (name[i]?.name ?? "")
                .toLowerCase()
                .indexOf(searchKeyName.toLowerCase()) !== -1 &&
              tableNo.indexOf(searchKeyTableNo.toLowerCase()) !== -1
            ) {
              return item;
            }
          }
        }
      }
    });

    console.log("Filter Search: ", _updatedList);
    setData(_updatedList);
  };

  // console.log("TEST DATA BULKS >>>", dataBulks);

  // Render Components
  if (stateOforder === 2) {
    return (
      <CreateOrderPage
        tableSelected={tableSelected}
        is_return={handleReturn}
        key={"per-order"}
        is_room_service={isRoomService}
        is_refresh={handleCreate}
        data_from_resv={tableResv}
      />
    );
  }

  return (
    <>
      <TableCategoryCard
        dataSource={cardButton}
        selectedCategory={handleButtonCard}
        selectedData={categories}
        is_roomService={isRoomService}
        is_loading={isLoading}
        is_render={handleRefreshCat}
      />

      <Row className="row-table-order" style={mainBody}>
        <Col span={24} className="main-table-col">
          <Row
            className="row-table-order"
            justify="end"
            style={{ padding: 30 }}
          >
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={handleRefresh}
            >
              {`Refresh`}
            </Button>
            {/* 
            </Row>
            </Col> */}
          </Row>

          <Row
            className="outlet-row"
            align="middle"
            style={{ padding: "0px 30px 30px", width: "100%" }}
          >
            <TableListCard
              dataSource={data}
              table_select={handleTable}
              is_roomService={isRoomService}
              is_loading={isLoading}
            />
          </Row>
        </Col>
      </Row>

      <ModalTableOrder
        handleFilter={tableSelected}
        openModal={open}
        closeModal={setOpen}
        // dataTableOrder={dataEdit}
      />

      <TableOrderPayment
        handleFilter={tableSelected}
        openModal={openPay}
        closeModal={setOpenPay}
        // dataTableOrder={dataEdit}
      />

      <ModalReservationTable
        openModal={openModalResvTable}
        closeModal={setOpenModalResvTable}
        selectedTable={tableSelected}
        selectedTableResv={(e) => {
          setTableResv(e);
        }}
      />
    </>
  );
}

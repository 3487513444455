// Import React Components
import React, { useContext, useState, useEffect } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterReportHeader({ getMasterHeader, detail_name }) {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [headName, setHeadName] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "source-header-dsr",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let _mapped = filtered.map((val) => ({
            label: val?.label,
            value: val?.label,
            id: val?.id,
          }));

          console.log("Filtered: ", filtered);
          console.log("Mapped: ", _mapped);
          setOptionValue(_mapped);
        }
      },
      onReject: (error) => {
        console.log(error);
        // failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (detail_name && !cleanUp) {
      console.log("Detail Selected: ", detail_name);
      setHeadName(detail_name);
    }

    return () => {
      cleanUp = true;
    };
  }, [detail_name]);

  // Handle Change
  const handleChange = (val, allKey) => {
    const _val = val;
    const _option = allKey;

    console.log(_option);

    setHeadName(_val);
    getMasterHeader({
      value: _option.value,
      id: _option.id,
    });
  };

  // HANDLE CLEAR
  const handleClear = () => {
    setHeadName(null);
  };

  return (
    <>
      <Select
        className="master-header-select"
        key="master-header-select"
        placeholder="Master's Detail"
        allowClear
        showSearch
        options={optionValue}
        onChange={handleChange}
        onClear={handleClear}
        value={headName}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

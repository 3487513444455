// Import React Components
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";

// Import React Icons
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { TfiMenuAlt } from "react-icons/tfi";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../../../Reusable/Notification/Notification";

// Import Page Components
import CompanyPopover from "../../../../Popover/Accounting/Ledgers/Popover";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// CODE
export default function GeneralCompanyLedgersTable(props) {
  // PROPS
  const { is_search, queryKey } = props;

  // CONTEXT
  const { state } = useContext(FinanceContext);
  const { getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Set Selected
  const [selectedData, setSelectedData] = useState(null);
  // Data Type
  const [typeComp, setTypeComp] = useState(2);
  // Modal Open
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 100,
      align: "center",
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-guest-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <CompanyPopover
                details={record}
                is_refresh={handleRefresh}
                typeComp={typeComp}
              />
            </Row>
          </>
        );
      },
    },
    {
      title: typeComp == 2 ? "Company Name" : "Travel Agent Name",
      dataIndex: "company_name",
      key: "company_name",
      //   fixed: "left",
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      key: "contact_person",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Credit Account",
      dataIndex: "credit_account_no",
      key: "credit_account_no",
    },
    {
      title: "Credit Limit",
      dataIndex: "credit_limit",
      key: "credit_limit",
      render: (price, record) => {
        // console.log(record);
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Allow Ledger",
      dataIndex: "allow_ledger",
      key: "allow_ledger",
      align: "center",
      width: 100,
      render: (bool) => {
        return bool === true ? (
          <CheckCircleFilled
            className="allowed-ledger"
            style={{
              color: "#52c41a",
              //   padding: "0px 0px 0px 30px",
              fontSize: 20,
            }}
          />
        ) : (
          <CloseCircleFilled
            className="disallowed-ledger"
            style={{
              color: "#f5222d",
              //   padding: "0px 0px 0px 25px",
              fontSize: 20,
            }}
          />
        );
      },
    },
    {
      title: "Email",
      dataIndex: "company_email",
      key: "company_email",
      width: 200,
    },
  ];

  // FETCH DATA
  const fetchData = async () => {
    await getGuest({
      guest: "guest",
      type: "company-and-ota",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          // setData(filtered);
          setBulks(filtered);
          handleChange(typeComp, filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECTS
  useEffect(() => {
    is_search(() => handleSearch);
  }, [queryKey]);

  // HANDLE COMPANY/OTA
  const handleChange = async (params, array) => {
    const _params = params;
    const _array = array?.length > 0 ? array : bulks;
    setTypeComp(_params);

    console.log("Array: ", _array);

    let _filtered = [];

    if (_array?.length > 0)
      switch (_params) {
        case 2:
          _filtered = _array.filter((value) => {
            return value?.is_ota === false || value?.is_ota === null;
          });
          break;

        case 3:
          _filtered = _array.filter((value) => {
            return value?.is_ota === true;
          });
          break;

        default:
          break;
      }

    console.log("Filtered IS_OTA: ", _filtered);
    setData(_filtered);
  };

  // Handle Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
    }
  };

  // Handle Refresh Button
  const handleRefreshButton = () => {
    fetchData();
  };

  // Handle Selected
  const getLedgers = (value) => {
    const _selected = value;
    console.log("Selceted: ", _selected);
  };

  // Handle Open
  const handleClose = (e) => {
    // setIsDelete(false);
    setOpen(false);
    setSelectedData(null);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = queryKey ? queryKey.toLowerCase() : null;
    // console.log(query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((value) => {
        const _name = value?.company_name.toLowerCase();

        return _name.indexOf(query) !== -1;
      });

      // Trigger render with updated values
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="guest-card-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="end" className="row-export">
            <Select
              className="guest-select"
              key="guest-select"
              defaultValue={2}
              style={{
                width: 205,
                marginRight: 15,
                fontWeight: 500,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 2,
                  label: "Company",
                },
                {
                  value: 3,
                  label: "OTA",
                },
              ]}
            />

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={handleRefreshButton}
              style={{
                fontWeight: 500,
              }}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 35, width: "100%" }}>
            <Table
              className="company-ota-table"
              key="company-ota-table"
              bordered
              loading={loading}
              dataSource={data}
              columns={columns}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1750,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Popover, Row, Col, Tooltip, Typography } from "antd";

// Import React Icons
import { TfiMenuAlt } from "react-icons/tfi";
import { MdAddBox, MdFormatListBulletedAdd } from "react-icons/md";
import { BiSolidBookAdd } from "react-icons/bi";
import { FaConciergeBell } from "react-icons/fa";
import { DeleteFilled } from "@ant-design/icons";
import { TbClipboardCheck } from "react-icons/tb";

// Import Page Components
import CompLedgerModal from "../../../Modals/Accounting/Ledgers/Modal";
import AddCreditModal from "../../../Modals/Accounting/CreditLimit/Modal";

// CODE
export default function CompanyPopover(props) {
  // PROPS
  const { details, is_refresh, typeComp } = props;

  // STATE MANAGEMENT
  // Open Popover
  const [open, setOpen] = useState(false);
  const [dataRecord, setDataRecord] = useState([]);
  // Edit State
  const [isEdit, setIsEdit] = useState(false);
  // Open Modal
  const [compModal, setCompModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  // Open Form
  const [cancelled, setCancelled] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const location = useLocation();

  // Set Data
  // console.log("IN POP OVER = ", details);
  const detail = details;

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Tooltip title="Option Menu" className="company-ledger-tooltip">
        <Popover
          className="popover-company-ledger"
          name="popover-company-ledger"
          key="popover-company-ledger"
          placement="right"
          title={
            <Row className="popover-row">
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`Company's Account Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row
                className="add-article-row"
                justify="start"
                style={{ width: 210 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdAddBox
                      className="popover-icon"
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  onClick={() => {
                    if (detail?.credit_account_no) {
                      setIsEdit(true);
                    } else {
                      setIsEdit(false);
                    }

                    handleClose();
                    setDataRecord(detail);
                    setCompModal(true);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {detail?.credit_account_no
                    ? `Edit Credit Account`
                    : `Create's Credit Account`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 210 }}>
                <Button
                  className="row-reserve-opt"
                  onClick={(e) => {
                    if (detail?.credit_account_no) {
                      setIsEdit(true);
                    } else {
                      setIsEdit(false);
                    }

                    handleClose();
                    setDataRecord(detail);
                    setCreditModal(true);
                  }}
                  icon={
                    <MdFormatListBulletedAdd
                      className="popover-icon"
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 210,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Credit Limit`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{
            margin: 0,
            padding: 0,
            // borderRadius: 20,
          }}
        >
          <TfiMenuAlt
            className="menu-btn"
            // onClick={(e) => {
            //   console.log("ABOUT TO MENU > ", e);
            // showDeleteModal(record);
            // }}
            style={{
              fontSize: 20,
              color: "#3699FF",
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <CompLedgerModal
        modalOpen={compModal}
        closeModal={setCompModal}
        dataComp={compModal ? dataRecord : null}
        typeComp={typeComp}
        is_edit={isEdit}
        is_refresh={is_refresh}
      />

      <AddCreditModal
        modalOpen={creditModal}
        closeModal={setCreditModal}
        dataComp={creditModal ? dataRecord : null}
        typeComp={typeComp}
        is_edit={isEdit}
      />
    </>
  );
}

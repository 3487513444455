// Import React Components
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// Import Page Components
import AccountingReportsPage from "../../../Pages/Accounting/Reports/Main/Report";
import DailySalesReport from "../../../Pages/Accounting/Reports/DSR/HeaderReport";
import DailyDetailReport from "../../../Pages/Accounting/Reports/DSR/DetailReport";
import SegmentStatisticReport from "../../../Pages/Accounting/Reports/Segment/SegmentReport";
import DailyReportAccounting from "../../../Pages/Accounting/Reports/Daily/DailyReport";
import MonthlyReportAccounting from "../../../Pages/Accounting/Reports/Monthly/MonthlyReport";
// import BalanceSheetPage from "../../../Pages/Accounting/BalanceSheet/BalanceSheet";
// import ProfitnLostPage from "../../../Pages/Accounting/PnL/ProfitNLost";
// import TrialBalancePage from "../../../Pages/Accounting/TrialBalance/TrialBalance";
import HistoryTrialBalancePage from "../../../Pages/Accounting/Reports/Monthly/History/TrialBalance/HistoryTrialBalance";
import MonthlyClosingPage from "../../../Pages/Accounting/Closing/Monthly/MonthlyClosing";
import NotFound from "../../../Pages/NotFound/NotFound";
import BalanceSheetPage from "../../../Pages/Accounting/Reports/Monthly/History/BalanceSheet/BalanceSheet";
import TrialBalancePage from "../../../Pages/Accounting/Reports/Monthly/History/TrialBalance/TrialBalance";
import ProfitnLostPage from "../../../Pages/Accounting/Reports/Monthly/History/ProfitnLoss/ProfitNLost";
import BalanceSheetReportPage from "../../../Pages/Accounting/Reports/Monthly/History/BalanceSheet/Main/Main";
import BalanceSheetMainPage from "../../../Pages/Accounting/Reports/Monthly/History/BalanceSheet/BalanceSheetMain/BalanceSheetMain";
import BalanceSheetDetailPage from "../../../Pages/Accounting/Reports/Monthly/History/BalanceSheet/BalanceSheetDetail/BalanceSheetDetail";
import PnLReportPage from "../../../Pages/Accounting/Reports/Monthly/History/ProfitnLoss/Main/Main";
import PnLDetailReport from "../../../Pages/Accounting/Reports/Monthly/History/ProfitnLoss/ProfitnLossDetail/ProfitnLossDetail";
import PnLMainReport from "../../../Pages/Accounting/Reports/Monthly/History/ProfitnLoss/ProfitnLossMain/ProfitnLossMain";

// CODE
export default function AccountingReportRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AccountingReportsPage />} />
        <Route path="*" element={<NotFound />} />

        <Route
          path="/daily-accounting-report"
          element={<DailyReportAccounting />}
        />
        <Route
          path="/monthly-accounting-report"
          element={<MonthlyReportAccounting />}
        />

        <Route path="/closing" element={<MonthlyClosingPage />} />

        <Route
          path="/daily-accounting-report/headers-daily-report"
          element={<DailySalesReport />}
        />
        {/* <Route
          path="/daily-accounting-report/headers-daily-report"
          element={<DailySalesReportHistory />}
        /> */}
        <Route
          path="/daily-accounting-report/details-daily-report"
          element={<DailyDetailReport />}
        />
        <Route
          path="/daily-accounting-report/segment-statistics"
          element={<SegmentStatisticReport />}
        />

        {/* <Route
          path="/monthly-accounting-report/balance-sheet-report"
          element={<BalanceSheetPage />}
        /> */}
        <Route
          path="/monthly-accounting-report/balance-sheet-report"
          element={<BalanceSheetReportPage />}
        />

        <Route
          path="/monthly-accounting-report/balance-sheet-report/balance-sheet-main-report"
          element={<BalanceSheetMainPage />}
        />

        <Route
          path="/monthly-accounting-report/balance-sheet-report/balance-sheet-detail-report"
          element={<BalanceSheetDetailPage />}
        />

        {/* <Route
          path="monthly-accounting-report/profit-n-loss-report"
          element={<ProfitnLostPage />}
        /> */}
        <Route
          path="monthly-accounting-report/profit-n-loss-report"
          element={<PnLReportPage />}
        />

        <Route
          path="monthly-accounting-report/profit-n-loss-report/profit-n-loss-main-report"
          element={<PnLMainReport />}
        />

        <Route
          path="monthly-accounting-report/profit-n-loss-report/profit-n-loss-detail-report"
          element={<PnLDetailReport />}
        />

        <Route
          path="monthly-accounting-report/trial-balance-report"
          element={<TrialBalancePage />}
        />
        {/* <Route
          path="/monthly-accounting-report/balance-sheet-report"
          element={<BalanceSheetPage />}
        />
        <Route
          path="monthly-accounting-report/profit-n-loss-report"
          element={<ProfitnLostPage />}
        /> */}
        <Route
          path="monthly-accounting-report/trial-balance-history"
          element={<HistoryTrialBalancePage />}
        />
      </Routes>
      <Outlet />
    </>
  );
}

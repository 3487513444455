// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// GLOBAL API
import { token, user_name } from "../../../../API/Global/Payload";
import { baseurl } from "../../../../API/Config/Api";

// ANTD COMPONENTS
import { CaretRightOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

// REACT ICONS
import { FiEdit } from "react-icons/fi";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// NOTIFICATIONS
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// PARAMS
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// MODAL
const { confirm } = Modal;

export default function JournalRegisterModal(props) {
  // PROPS
  const { dataDetail, openModal, closeModal, is_refresh } = props;

  // CONTEXT
  const { getByPostJournalRegister } = useContext(FinanceContext);

  // Data
  const [data, setData] = useState([]);
  // Boolean State
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Params
  const currency = CurrencySymbols().code;

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (dataDetail && !cleanUp) {
      console.log("Data Detail:", dataDetail);
      setFormFields(dataDetail);
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataDetail]);

  const setFormFields = (data) => {
    form.setFieldsValue({
      journal_alias: data.journal_alias,
      bill_alias: data.bill_alias,
      journal_name: data.journal_name,
      date: moment(data.date).format("YYYY-MM-DD"),
      debit: data.debit,
      credit: data.credit,
      description: data.description,
    });
  };

  // FETCH DATA
  const fetchData = async () => {
    await getByPostJournalRegister({
      type: "journal/detail-unregistered-list",
      id: dataDetail?.id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Respones Journal Unregistered Detail: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Handle Close
  const handleClose = () => {
    setOpen(false);
    closeModal();
    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = () => {
    setLoading(true);

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Register?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleRegister();
      },

      onCancel() {
        setLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE REGISTER
  const handleRegister = async () => {
    const _data = [
      {
        id: dataDetail?.id,
        created_by: user_name,
      },
    ];

    await axios
      .put(
        `${baseurl}/accounting/journal/header-unregistered-list`,
        {
          data: _data,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("UPDATE  => ", response);

        masterSuccessNotification(response, {
          method: 1,
          source: "Journal Register",
        });

        handleClose();
        is_refresh();

        setLoading(false);
      })
      .catch((error) => {
        console.log("UPDATE  => ", error);

        masterFailedNotification(error, {
          method: 1,
          source: "Journal Register",
        });
        setLoading(false);
      });
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Journal Alias",
      dataIndex: "journal_alias",
      key: "journal_alias",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sortDirections: ["descend", "ascend"],
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Bill Alias",
      dataIndex: "bill_alias",
      key: "bill_alias",
    },

    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (debit) => {
        return debit ? `${debit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (credit) => {
        return credit ? `${credit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) => {
        return quantity
          ? `${quantity}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
  ];
  return (
    <>
      <Modal
        className="ar-pay-modal"
        key="ar-pay-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Unregistered Journal Detail`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={open}
        closable
        onCancel={handleClose}
        width={1100}
      >
        <Form
          className="journal-register-form"
          key="journal-register-form"
          name="journal-register-form"
          layout="vertical"
          form={form}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="form-row" gutter={30}>
                <Col span={8} className="form-col">
                  <Form.Item label="Journal Alias" name="journal_alias">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Journal Type" name="journal_name">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Date" name="date">
                    <Input style={{ width: "100%" }} disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="form-row" gutter={30}>
                <Col span={8} className="form-col">
                  <Form.Item label="Bill Alias" name="bill_alias">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Debit" name="debit">
                    <InputNumber
                      addonBefore={`${currency}`}
                      style={{ width: "100%" }}
                      formatter={(debit) => {
                        return `${debit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="form-col">
                  <Form.Item label="Credit" name="credit">
                    <InputNumber
                      addonBefore={`${currency}`}
                      style={{ width: "100%" }}
                      formatter={(credit) => {
                        return `${credit}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="form-row" gutter={30}>
                <Col span={24} className="form-col">
                  <Form.Item label="Description" name="description">
                    <TextArea disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Journal Detail`}
              </Divider>

              <Row className="table-row" justify="start">
                <Col span={24} className="table-col">
                  <Table
                    className="journal-register-table"
                    name="journal-register-table"
                    key="journal-register-table"
                    loading={loading}
                    bordered
                    columns={columns}
                    dataSource={data}
                    pagination={{
                      defaultPageSize: 5,
                      pageSizeOptions: [10, 20, 50, 100],
                      showSizeChanger: true,
                      showTotal: (total, range) =>
                        `Showing ${range[0]}-${range[1]} of ${total} entries`,
                    }}
                    scroll={{
                      x: 1400,
                    }}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "odd" : "even";
                    }}
                    rowKey={(record) =>
                      record.id ? record.id : record.department_id
                    }
                  />
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Cancel`}
                </Button>

                <Button
                  className="submit-btn"
                  type="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={showModalConfirm}
                >
                  {`Register`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

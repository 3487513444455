// REACT COMPONENTS
import React from "react";
import { useNavigate } from "react-router-dom";

// GLOBAL STYLES
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../Style/Global/Global";

// ANTD COMPONENTS
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { FaBookReader, FaClipboardList } from "react-icons/fa";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import {
  HiArchiveBox,
  HiArchiveBoxArrowDown,
  HiArchiveBoxXMark,
} from "react-icons/hi2";
import { TbCheckupList, TbShoppingCartCancel } from "react-icons/tb";
import { FaBoxesPacking, FaDeleteLeft } from "react-icons/fa6";

export default function PurchasingReportPage() {
  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <BiSolidPurchaseTagAlt
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Purchasing Reports`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              {/* <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchasing-list")}
                    // type="primary"
                    icon={<FaClipboardList style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Purchasing List`}
                    </Typography>
                  </Button>
                </Card>
              </Col> */}

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-request")}
                    // type="primary"
                    icon={<HiArchiveBoxArrowDown style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Request`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-request-cancelled")}
                    // type="primary"
                    icon={<HiArchiveBoxXMark style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Request Cancelled`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-request-expired")}
                    // type="primary"
                    icon={<HiArchiveBox style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Request Expired`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-order")}
                    // type="primary"
                    icon={<TbCheckupList style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Order List`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-order-cancelled")}
                    // type="primary"
                    icon={<TbShoppingCartCancel style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Order Cancelled`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-order-expired")}
                    // type="primary"
                    icon={<FaDeleteLeft style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Order Expired`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-order-outstanding")}
                    // type="primary"
                    icon={<FaBoxesPacking style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Order Outstanding`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("purchase-book")}
                    // type="primary"
                    icon={<FaBookReader style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Purchase Book`}</Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// REACT COMPONENTS
import axios from "axios";
import { useContext, useEffect, useState, } from "react";
// Update
import { useNavigate } from "react-router-dom";
// End update
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  // Update
  Select,
  Tooltip,
  // End Update
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

// GLOBAL API
import { baseurl } from "../../../API/Config/Api";
import { token, user_name, user_level } from "../../../API/Global/Payload";

// PAGE COMPONENTS
import StockTransferRequestDetailTable from "../../Table/BackOffice/Inventory/TransferRequest/StockTransferRequestDetail/Table";
import MasterDepartment from "../../Reusable/Master/Department/Department";
import WarehouseList from "../../Reusable/Inventory/Warehouse/WarehouseList";
import WarehouseArticleList from "../../Reusable/Inventory/Warehouse/WarehouseArticleList";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";
import {
  ArticleIncomplete,
  failedFetch,
} from "../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as InventoryContext } from "../../../API/Context/InventoryContext/InventoryContext";
// Update
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
// End Update

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// Modal
const { confirm } = Modal;

// CODE
export default function TransferToOtherStorageForm(props) {
  // PROPS
  const {
    is_edit,
    is_loading,
    is_refresh,
    onCancel,
    transferRequestType,
    dataStock,
    // Update
    storage,
    dept,
    // End Update
  } = props;

  // PARAMS
  const currency = CurrencySymbols().code;

  // Update
  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/department");
  };
  // End Update

  // STATE MANAGEMENT
  // Data
  const [department, setDepartment] = useState(null);
  const [initialWarehouse, setInitialWarehouse] = useState(null);
  const [destinationWarehouse, setDestinationWarehouse] = useState(null);
  const [article, setArticle] = useState(null);
  const [articleTable, setArticleTable] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [countArticleData, setCountArticleData] = useState(0);
  const [warehouseList, setWarehouseList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [dataEditArticle, setDataEditArticle] = useState(null);

  const [selected, setSelected] = useState([]);

  // Update
  const [optionValue, setOptionValue] = useState(null);
  const [depts, setDepts] = useState(null);
  // End Update

  const [form] = Form.useForm();

  // CONTEXT
  const { getWarehouseList } = useContext(MainContext);
  const { getStockTransferRequestList } = useContext(InventoryContext);
  // Update
  const { getArticleMaster } = useContext(MasterContext);
  // End Update

  // Update
  useEffect(() => { fetchArticleMasterData(); }, []);
  useEffect(() => {
    if(dept && !is_edit) {
      setDepartment(dept);
      setDepts(dept.name);
      form.setFieldsValue({
        department_name: dept.name,
      });
    }
  }, [dept]);

  useEffect(() => {
    console.log("storage > TransferToOtherStorage", storage)
    if(storage && !is_edit) {
      setDestinationWarehouse(storage);
      form.setFieldsValue({
        destination_warehouse: storage.warehouse_name,
      });
    }
  }, [storage]);

  // FETCH GUEST DATA
  const fetchArticleMasterData = async () => {
    await getArticleMaster({
      types: "department",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response Dept => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filterData = _res.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          let filtered = filterData.map((dept) => ({
            value: dept?.name,
            label: dept?.name,
            code: dept?.code,
            id: dept?.id,
            key: dept?.id,
            dept_uuid: dept?.id_dept,
            desc: dept?.description,
          }));

          // console.log("Department List == ", filtered);
          setOptionValue(filtered);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // Handle Clear
  const handleClear = () => {
    setDepartment(null);
  };

  // End Update

  // USE EFFECT EDIT
  useEffect(() => {
    let cleanUp = false;

    if (is_edit && !cleanUp && dataStock) {
      console.log("DATA STOCK >> ", dataStock);
      setFormFields(dataStock);
      setArticleTable(dataStock?.details);
      setDataEdit(dataStock);
      setDepartment(null);
      setDepts(dataStock?.department_name);
      form.setFieldsValue({
        department_name: dataStock?.department_name
        });

      fetchDataWarehouse();
    }

    return () => {
      cleanUp = true;
    };
  }, [is_edit, dataStock]);

  // USE EFFECT EDIT ARTICLE
  useEffect(() => {
    let cleanUp = false;

    if (dataEditArticle && !cleanUp) {
      console.log("Data Edit Article == ", dataEditArticle);
      setArticleFormFields(dataEditArticle);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataEditArticle]);

  // USE EFFECT REFRESH DETAIL DATA
  useEffect(() => {
    let cleanUp = false;

    if (refresh && !cleanUp) {
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // USE EFFECT SET POSTING DATE TO TODAY
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (!is_edit && !cleanUp) {
  //     form.setFieldsValue({
  //       posting_date: moment(),
  //     });
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [is_edit]);

  // FETCH DATA
  const fetchData = async () => {
    await getStockTransferRequestList({
      type: "transfer-request-stocks",
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("TRANSFER/REQUEST RESPONSE => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          handleFilter(filtered);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setIsLoading(false);
        failedFetch(error);
      },
    });

    setRefresh(false);
  };

  // Update
  // Handle Change
  const handleChange = (e, allKeys) => {
    const _value = e;
    const _keys = allKeys;

    setDepartment(_value);
    handleDepartment({
      name: _value,
      code: _keys?.code,
      dept_id: _keys?.id,
      dept_uuid: _keys?.dept_uuid,
    });
  };
  // End Update

  const handleFilter = (response) => {
    const _filter = response?.filter(
      (item) => item.id_warehouse_transfer === dataStock.id_warehouse_transfer
    );
    console.log("_FILTER >> ", _filter[0].details);

    setArticleTable(_filter[0]?.details);
  };

  // FETCH DATA WAREHOUSE
  const fetchDataWarehouse = async () => {
    await getWarehouseList({
      type: "warehouse",
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("WAREHOUSE RESPONSE => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          setWarehouseList(filtered);

          filterInitialWarehouse(filtered);
          filterDestinationWarehouse(filtered);
        } else {
          setWarehouseList([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setIsLoading(false);
        failedFetch(error);
      },
    });
  };

  // FILTER INITIAL WAREHOUSE
  const filterInitialWarehouse = (data) => {
    console.log("DATA FILTER", data);

    const warehouse = data.filter(
      (item) => item.warehouse_name === dataStock.initial_warehouse
    );

    // console.log("FILT INITIAL WAREHOUSE >> ", warehouse);

    setInitialWarehouse({
      warehouse_name: warehouse[0]?.warehouse_name,
      id_warehouse: warehouse[0]?.id_warehouse,
    });
  };

  // FILTER DESTINATION WAREHOUSE
  const filterDestinationWarehouse = (data) => {
    const warehouse = data.filter(
      (item) => item.warehouse_name === dataStock.destination_warehouse
    );

    // console.log("FILT DESTINATION WAREHOUSE >> ", warehouse);

    setDestinationWarehouse({
      warehouse_name: warehouse[0]?.warehouse_name,
      id_warehouse: warehouse[0]?.id_warehouse,
    });
  };

  // SET FORM FIELD ON EDIT HEADER
  const setFormFields = (data) => {
    console.log("DATA >> ", data);
    form.setFieldsValue({
      department_name: data?.department_name ? data.department_name : null,
      needed_date: data?.needed_date ? moment(data.needed_date) : null,
      description: data?.description ? data.description : "",
      initial_warehouse: data?.initial_warehouse
        ? data.initial_warehouse
        : null,
      destination_warehouse: data?.destination_warehouse
        ? data.destination_warehouse
        : null,

      created_by: data?.created_by ? data.created_by : null,
      created_date: data?.created_date
        ? moment(data.created_date).format("YYYY-MM-DD")
        : null,
      updated_by: data?.updated_by ? data.updated_by : null,
      updated_date: data?.updated_date
        ? moment(data.updated_date).format("YYYY-MM-DD")
        : null,
    });
  };

  // SET FORM FIELD ON EDIT ARTICLE
  const setArticleFormFields = (data) => {
    form.setFieldsValue({
      article_name: data?.article_name,
      article_number: data?.article_number || "-",
      delivery_stock: data?.delivery_stock || 0,
      price: data?.price || 0,
      qty: data?.quantity_requested || 0,
      article_description: data?.description,
    });
  };

  // ON FINISH
  const onFinish = (data) => {
    console.log("DATAe>>> ", data);

    // check item in article table
    const findIndexItem = articleTable.findIndex((item) => {
      return item.article_name === article.article_name;
    });

    if (findIndexItem !== -1) {
      // if item found, return the alert notif 'item is exists'
      ArticleIncomplete(article, { method: 1 });
    } else {
      if (is_edit) {
        const bodyAddDetail = {
          id_warehouse_transfer: dataEdit?.id_warehouse_transfer,
          id_article: article?.id_article,
          quantity: data?.qty > 0 ? data.qty : 0,
          price: data?.price > 0 ? data.price : 0,
          description: data?.article_description,
          created_by: user_name,
        };

        handleAddDetailArticle(bodyAddDetail);
      } else {
        setArticleTable([
          ...(articleTable.length > 0 ? articleTable : []),
          {
            id_article: article?.id_article,
            article_name: article?.article_name,
            article_number: article?.article_number || null,
            delivery_stock: article?.article_stock || 0,
            quantity_requested: data?.qty > 0 ? data.qty : 0,
            price: data?.price > 0 ? data.price : 0,
            description: data?.article_description,
            total_price: data?.qty * data?.price || 0,
          },
        ]);

        setCountArticleData((state) => state + 1);
      }
    }

    console.log("ARTICLE TABLE >> ", articleTable);

    form.resetFields([
      "article_name",
      "article_number",
      "delivery_stock",
      "price",
      "qty",
      "article_description",
    ]);
  };

  // HANDLE ADD DETAIL ARTICLE
  const handleAddDetailArticle = async (value) => {
    const contentSubmit = value;

    await axios
      .post(
        `${baseurl}/inventory/transfer-request-stocks-detail`,
        contentSubmit,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Add Detail Article: ", response);
        setRefresh(true);
        is_refresh();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, { method: 0, source: "Article" });
        setIsLoading(false);
      });
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const submit = value;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
    setIsLoading(false);
  };

  // HANDLE FINISH
  const handleFinish = (value) => {
    console.log("VAL >>> ", value);
    console.log("DATA EDIT >>>", dataEdit);

    if (is_edit) {
      const bodyHeader = {
        id_warehouse_transfer: dataEdit?.id_warehouse_transfer,
        initial_warehouse: initialWarehouse?.id_warehouse,
        destination_warehouse: destinationWarehouse?.id_warehouse,
        needed_date: form.getFieldValue("needed_date")
          ? moment(form.getFieldValue("needed_date")).format("YYYY-MM-DD")
          : moment(value?.needed_date).format("YYYY-MM-DD"),
        description: form.getFieldValue("description")
          ? form.getFieldValue("description")
          : value?.description,
        updated_by: user_name,
      };

      handleSubmit(bodyHeader);
    } else {
      let bodyDetail = [];

      for (
        let i = articleTable?.length - countArticleData;
        i < articleTable?.length;
        i++
      ) {
        console.log("Article Table ID: ", articleTable[i], countArticleData); // , articleTable[i].id

        bodyDetail.push({
          id_article: articleTable[i].id_article,
          quantity: articleTable[i].quantity_requested,
          price: articleTable[i].price ? articleTable[i].price : 0,
          description: articleTable[i].description,
        });
      }

      let bodyRequest = {
        id_department: department?.dept_id ? department.dept_id : null,
        initial_warehouse: initialWarehouse?.id_warehouse,
        destination_warehouse: destinationWarehouse?.id_warehouse,
        description: value?.description,
        created_by: user_name,
        id_transfer_type: transferRequestType ? transferRequestType : 1,
        needed_date: value?.needed_date,
        details: bodyDetail,
      };

      handleSubmit(bodyRequest);
    }
  };

  // SHOW CONFIRM MODAL
  const showModalConfirm = () => {
    let editContent = dataEdit;
    let submitContent = form.getFieldsValue();
    let editArticleContent = dataEditArticle;

    console.log("Data About to Confirm =>>> ", submitContent);
    console.log("Data About to EDIT =>>> ", editContent);

    confirm({
      className: "article-confirm",
      title: `Are you sure want to Edit Transfer/Request Data?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        is_edit
          ? edit
            ? handleUpdateArticle(editArticleContent)
            : handleFinish(editContent)
          : edit
          ? handleUpdateArticle(editArticleContent)
          : handleFinish(submitContent);
      },
      onCancel() {
        setIsLoading(false);
      },
      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE UPDATE ARTICLE
  const handleUpdateArticle = async (value) => {
    const contentUpdate = value;

    if (contentUpdate?.id_warehouse_transfer_detail || is_edit) {
      const itemFilter = articleTable.map((item) =>
        item.article_name === selectedArticle.article_name
          ? {
              ...item,
              quantity_requested: form.getFieldValue("qty"),
              description: form.getFieldValue("article_description"),
              total_price: contentUpdate?.price * form.getFieldValue("qty"),
            }
          : item
      );

      // console.log("ITEM FILTER >>> ", itemFilter);

      setArticleTable(itemFilter);

      const bodyReqUpdate = {
        id_warehouse_transfer_detail:
          contentUpdate?.id_warehouse_transfer_detail,
        id_article: selectedArticle?.id_article,
        quantity: form.getFieldValue("qty"),
        description: form.getFieldValue("article_description"),
        updated_by: user_name,
      };

      await axios
        .put(
          `${baseurl}/inventory/transfer-request-stocks-detail`,
          bodyReqUpdate,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("Response Edit Article: ", response);
          masterSuccessNotification(response, { method: 1, source: "Article" });
          handleCancelUpdate();
          is_refresh();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, { method: 1, source: "Article" });
          setIsLoading(false);
        });
    } else {
      const itemFilter = articleTable.map((item) =>
        item.article_name === selectedArticle.article_name
          ? {
              ...item,
              quantity_requested: form.getFieldValue("qty"),
              total_price: contentUpdate?.price * form.getFieldValue("qty"),
              description: form.getFieldValue("article_description"),
            }
          : item
      );

      // console.log("ITEM FILTER >>> ", itemFilter);

      setArticleTable(itemFilter);
      masterSuccessNotification(dataEditArticle, {
        method: 1,
        source: "Article",
      });

      handleCancelUpdate();
    }
  };

  // ON SUBMIT
  const onSubmit = () => {
    const error = {
      errorFields: [
        {
          errors: ["Please, Add an Articles!"],
        },
      ],
    };

    setIsLoading(true);
    // console.log(articleTable);

    if (articleTable.length > 0) {
      showModalConfirm();
    } else {
      masterIncomplete(error);
      setIsLoading(false);
    }
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;
    console.log("KONTEN SUBMIT >>>>>", contentSubmit);

    if (!is_edit) {
      await axios
        .post(`${baseurl}/inventory/transfer-request-stocks`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Add Transfer/Request: ", response);
          masterSuccessNotification(response, {
            method: 0,
            source: "Transfer/Request",
          });
          onCancel();
          is_refresh();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 0,
            source: "Transfer/Request",
          });
          setIsLoading(false);
        });
    } else {
      await axios
        .put(`${baseurl}/inventory/transfer-request-stocks`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Edit Article: ", response);
          masterSuccessNotification(response, {
            method: 1,
            source: "Transfer/Request",
          });
          onCancel();
          is_refresh();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 1,
            source: "Transfer/Request",
          });
          setIsLoading(false);
        });
    }
  };

  // HANDLE DEPARTMENT
  const handleDepartment = (value) => {
    console.log("DEPARTMENT >> ", value);
    setDepartment(value);

    form.setFieldsValue({
      department_name: value?.name ? value.name : "",
    });
  };

  // HANDLE INITIAL WAREHOUSE
  const handleInitialWarehouse = (value) => {
    console.log("INITIAL WAREHOUSE >> ", value);
    setInitialWarehouse(value);

    form.setFieldsValue({
      initial_warehouse: value?.warehouse_name ? value.warehouse_name : null,
    });
  };

  // HANDLE DESTINATION WAREHOUSE
  const handleDestinationWarehouse = (value) => {
    console.log("DESTINATION WAREHOUSE >> ", value);
    setDestinationWarehouse(value);

    form.setFieldsValue({
      destination_warehouse: value?.warehouse_name
        ? value.warehouse_name
        : null,
    });
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticle = (value) => {
    console.log("SELECTED ARTICLE >> ", value);
    setArticle(value);

    form.setFieldsValue({
      article_name: value?.article_name ? value.article_name : null,
      article_number: value?.article_number ? value.article_number : null,
      delivery_stock: value?.article_stock
        ? `${value.article_stock}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0,
      price: value?.average_price ? Math.round(value.average_price) : 0,
    });
  };

  // HANDLE CANCEL UPDATE
  const handleCancelUpdate = () => {
    setSelected([]);
    setEdit(false);
    setDataEditArticle([]);
    setSelectedArticle(null);
  };

  return (
    <>
      <Form
        name="add-transfer-request-modal-form"
        className="add-transfer-request-modal-form"
        autoComplete="off"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ padding: "0px 36px" }}
      >
        <Row gutter={30} className="row-transfer-request">
          <Col span={12}>
            <Form.Item
              label="Department"
              name="department_name"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input Department!",
                },
              ]}
            > 
            
              {/* Berfore update
              <MasterDepartment
                getDepartment={handleDepartment}
                department_name={is_edit ? dataEdit?.department_name : null}
                disabled={is_edit ? true : false}
              />
              End Before Update*/}
              {/* Update */}
              <Row className="master-row" align="top" justify="start" gutter={0}>
                <Col span={24} className="select-col">
                  <Select
                    className="master-dept"
                    name="master-dept"
                    key="master-dept"
                    value={depts}
                    disabled={true}
                    showSearch
                    allowClear
                    placeholder="Department"
                    onChange={handleChange}
                    onClear={handleClear}
                    options={optionValue}
                    style={{ width: "100%" }}
                  />
                </Col>

                {/* Before Update
                <Col span={2} className="select-col">
                  <Tooltip
                    className="redirect-tooltip"
                    title={"Redirect to Master Dept"}
                  >
                    <Button
                      className="master-btn"
                      icon={
                        <AppstoreFilled
                          onClick={move}
                          style={{ fontSize: 20, color: "#3699FF" }}
                        />
                      }
                    />
                  </Tooltip>
                </Col>
                End Before Update*/}
              </Row>
              {/* End Update */}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Needed Date"
              name="needed_date"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input Needed Date!",
                },
              ]}
            >
              <DatePicker
                placeholder="Needed Date"
                allowClear
                // defaultValue={moment()}
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30} className="row-transfer-request">
          <Col span={12}>
            <Form.Item
              label="From Store"
              name="initial_warehouse"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input From Store!",
                },
              ]}
            >
              <WarehouseList
                selectedItem={handleInitialWarehouse}
                warehouse_name={is_edit ? dataEdit?.initial_warehouse : null}
                is_disable={false}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="To Store"
              name="destination_warehouse"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input To Storage!",
                },
              ]}
            >
              <WarehouseList
                selectedItem={handleDestinationWarehouse}
                // warehouse_name={ is_edit ? dataEdit?.destination_warehouse : null }
                warehouse_name={ is_edit ? dataEdit?.destination_warehouse : destinationWarehouse?.warehouse_name }
                is_disable={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30} className="row-transfer-request">
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              style={{ width: "100%" }}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please, Input Description!",
              //   },
              // ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>

        <Divider
          className="divider-form"
          orientation="left"
          orientationMargin={0}
          style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
        >
          {`Select Articles`}
        </Divider>

        <Row className="article-row" gutter={30} justify="start">
          <Col span={8} className="article-col">
            <Form.Item
              label="Article"
              name="article_name"
              rules={[
                {
                  required: true,
                  message: "Please, Select an Article!",
                },
              ]}
            >
              <WarehouseArticleList
                selectedItem={handleSelectedArticle}
                dataWarehouse={initialWarehouse}
                setSelectedArticle={setSelectedArticle}
                article_name={edit ? dataEditArticle?.article_name : null}
                is_disable={edit ? true : false}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Article Number" name="article_number">
              <Input disabled placeholder="Article Number" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Stock On Hand" name="delivery_stock">
              <Input disabled placeholder="Stock On Hand" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col span={8}>
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please, Input Price!",
                },
              ]}
            >
              <InputNumber
                min={0}
                placeholder="Price"
                style={{ width: "100%" }}
                addonBefore={currency}
                formatter={(price) =>
                  ` ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                disabled={true}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Quantity"
              name="qty"
              rules={[
                {
                  required: true,
                  message: "Please, Input Quantity!",
                },
              ]}
            >
              <InputNumber
                placeholder="Quantity"
                min={0}
                max={form.getFieldValue("delivery_stock")}
                formatter={(qty) =>
                  ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Description" name="article_description">
              <Input placeholder="description" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="article-row" justify="end">
          <Button
            className="next-btn"
            type="primary"
            icon={<PlusOutlined />}
            htmlType="submit"
            hidden={edit ? true : false}
            disabled={edit ? true : false}
          >{`Add Article Inventory`}</Button>

          <Button
            className="next-btn"
            type="primary"
            hidden={edit ? false : true}
            style={{ marginRight: "10px" }}
            onClick={showModalConfirm}
          >{`Update`}</Button>

          <Button
            className="cancel-btn"
            type="default"
            hidden={edit ? false : true}
            onClick={handleCancelUpdate}
          >{`Cancel`}</Button>
        </Row>

        <Divider
          className="divider-form"
          orientation="left"
          orientationMargin={0}
          style={{ margin: "15px 0", borderColor: "#EBEDF3" }}
        ></Divider>

        <StockTransferRequestDetailTable
          article={articleTable}
          setArticle={setArticleTable}
          is_refresh={is_refresh}
          editArticle={setDataEditArticle}
          is_edit={setEdit}
          is_loading={isLoading}
          is_selected={setSelected}
          is_reset={selected}
          count={setCountArticleData}
        />

        <Divider
          className="form-divider"
          style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
        />

        {is_edit == true ? (
          <>
            <Row gutter={30} className="created-date-transfer-request">
              <Col span={8}>
                <Form.Item
                  label="Created By"
                  name="created_by"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Created Date"
                  name="created_date"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Updated By"
                  name="updated_by"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mod-date-transfer-request">
              <Col span={8}>
                <Form.Item
                  label="Updated Date"
                  name="updated_date"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />
          </>
        ) : null}

        <Row className="row-modal-btn" justify="end" align="middle">
          <Button
            type="primary"
            // htmlType="submit"
            onClick={onSubmit}
            key={"submit"}
            loading={is_loading}
            className="submit-btn"
            style={{
              marginRight: 15,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={onCancel}>
            {`Cancel`}
          </Button>
        </Row>
      </Form>
    </>
  );
}

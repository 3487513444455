// Import React's Component
import React, { useEffect, useState } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../API/Config/Api";

// Import ANTD Component
import { Table, Form, Col, Tooltip, Modal, Row } from "antd";
import { DeleteFilled } from "@ant-design/icons";

// API
import { token, user_name } from "../../../../API/Global/Payload";

// Import Page Components
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// NOTIFICATION
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

const { confirm } = Modal;

// CODE
export default function VendorArticleTable(props) {
  // PROPS
  const {
    article,
    set_article,
    is_search,
    searchKey,
    is_refresh,
    is_edited,
    editArticle,
    is_loading,
    is_reset,
    is_view,
    count,
    params_tax,
  } = props;

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [dataSource, setDataSource] = useState([]);
  const [filteredList, setFilteredList] = useState(dataSource);
  const [select, setSelect] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);

  // Tax PPN
  const _tax = params_tax.tax_percentage / 100;

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("Article: ", article);

    if (article?.length > 0 && !cleanUp) {
      handleFilterType();
    } else {
      setDataSource([]);
      setFilteredList([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [article]);

  // USEEFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  useEffect(() => {
    setSelect([]);
  }, [is_reset]);

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    if (filteredList?.length > 0) {
      let updatedList = filteredList.filter((item) => {
        const _name = item?.article_name.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setDataSource(updatedList);
    } else {
      setDataSource([]);
    }
  };

  // HANDLE FILTER ARTICLE TYPE == 2
  const handleFilterType = () => {
    const _filter = article.filter((item) => item.article_type == 2);

    setDataSource(_filter);
    setFilteredList(_filter);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 75,
      fixed: "left",
      hide: is_view,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      hide: false,
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
      hide: false,
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      hide: false,
    },
    {
      title: "VAT Price",
      dataIndex: "is_ppn",
      key: "is_ppn",
      hide: false,
      render: (ppn, record) => {
        let _ppn = record?.price * _tax;
        let _prc = record?.price;

        switch (ppn) {
          case true:
            _prc = record?.price + _ppn;
            break;

          default:
            break;
        }

        return `${_prc}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ].filter((x) => x.hide === false);

  // SHOW MODAL DELETE
  const showDeleteModal = (value) => {
    let deleteContent = value;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to Removes Article ${deleteContent?.article_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // handleCancel();
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const onDelete = async (contentDelete) => {
    let obj = {
      ["id_supplier"]: contentDelete?.id_supplier,
      ["id_supplier_article"]: contentDelete?.id_supplier_article,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/supplier-management-systems/supplier-article`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Delete => ", response);
        masterSuccessNotification(response, {
          method: 2,
          source: "Article",
        });
        is_view ? is_refresh(true) : is_refresh(false);
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Article" });
        console.log("Delete => ", error);
      });
  };

  // HANDLE DELETE
  const handleDelete = async (e) => {
    const contentDelete = e;

    // console.log("CONTENT DELETE >>> ", contentDelete);
    if (contentDelete.id_supplier && contentDelete.id_supplier_article) {
      if (!is_view) {
        const filterArticle = article.filter(
          (item) => item.id !== contentDelete.id
        );

        set_article(filterArticle);
        onDelete(contentDelete);
      } else {
        onDelete(contentDelete);
      }
    } else {
      const filterArticle = article.filter(
        (item) => item.id !== contentDelete.id
      );

      console.log("FILTERED DELETE >>>> ", filterArticle);
      set_article(filterArticle);
      count((state) => (state > 0 ? state - 1 : 0));
      masterSuccessNotification(contentDelete, {
        method: 2,
        source: "Article",
      });
    }
  };

  // Handle Selection Table
  const handleSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);

      setSelect(selectedRowKeys);
      setDataEdit(selectedRows[0]);
      editArticle(selectedRows[0]);
      is_edited(true);
    },
    columnTitle: "Edit",
  };

  return (
    <>
      <Table
        className="pr-detail-table"
        name="pr-detail-table"
        key="pr-detail-table"
        rowSelection={is_view !== true && { type: "radio", ...handleSelection }}
        columns={columns}
        dataSource={dataSource}
        loading={is_loading}
        bordered={true}
        size="middle"
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} entries`,
        }}
        scroll={{
          x: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? "odd" : "even";
        }}
        rowKey={(record) => (record.id ? record.id : record.key)}
      />
    </>
  );
}

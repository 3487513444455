// Import React Components
import React, { useEffect, useState } from "react";

// Import Ant Design Components
import { Button, Col, Popover, Row, Tooltip, Typography } from "antd";

// Import React Icons
import { PiArrowArcLeftBold } from "react-icons/pi";
import { TfiMenuAlt } from "react-icons/tfi";

// Import Page Components
import ModalReactiveReservations from "../../../Modals/ReactiveReservation/Modal";
import ModalPermission from "../../../Modals/Permissions/Modal";
import { handleAuthorization } from "../../../../API/Global/Permission";

// CODE
export default function PopoverCancelReservations(props) {
  // Props
  const { details, is_refresh } = props;

  // STATE MANAGEMENT
  // Popover
  const [open, setOpen] = useState(false);
  const [recordData, setRecordData] = useState(null);
  // Boolean
  const [reActivate, setReActivate] = useState(false);
  const [permission, setPermission] = useState(false);
  const [permitLoad, setPermitLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const detail = details;

  // USE EFFECT FETCH
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      setRefresh(false);
      setIsSuccess(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // Handle Close
  const handleClose = () => {
    setOpen(!open);
  };

  // Handle Cancel
  const handleCancel = () => {
    setReActivate(!reActivate);
    setPermitLoad(true);
  };

  // HANDLE PERMIT
  const handlePermit = async (value) => {
    const _record = value;

    console.log("Value Authorized: ", _record);
    // console.log("Group: ", isGroup);

    if (
      _record?.auth_token &&
      _record?.auth_roles == "ROLE_SUPERVISOR" &&
      _record?.auth_dept.toLowerCase().includes("front")
    ) {
      setReActivate(true);
    } else {
      setPermission(true);
    }
  };

  // HANDLE SUCCESS
  const handleSuccess = async (value) => {
    const _record = value;

    console.log("Value Success: ", _record);
    // console.log("Group: ", isGroup);

    if (_record == true) {
      localStorage.clear();
      handleAuthorization("");

      is_refresh(true);
      setIsSuccess(true);
      setPermission(false);
    }
  };

  return (
    <>
      <Tooltip title="Show Options" className="edit-tooltip">
        <Popover
          className="pop-over-cancel"
          name="pop-over-cancel"
          key="pop-over-cancel"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    // color: "#2f54eb",
                    fontWeight: 600,
                  }}
                >
                  {`Cancelled Reservations Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row
                justify="start"
                hidden={detail?.group > 1 ? true : false}
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <PiArrowArcLeftBold
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();
                    setRecordData(detail);
                    setPermission(true);

                    console.log("About Reactivate: ", detail);
                  }}
                >
                  {`Re-Activate Reservations`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            style={{
              fontSize: 25,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              marginLeft: 10,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <ModalPermission
        openModal={permission}
        closeModal={setPermission}
        is_success={isSuccess}
        is_refresh={setRefresh}
        dataSubmit={recordData}
        roomStatus={3}
        is_deleted={false}
        is_authorized={handlePermit}
        is_loading={permitLoad}
        is_loadingFalse={setPermitLoad}
      />

      <ModalReactiveReservations
        is_open={reActivate}
        is_close={handleCancel}
        data_guest={reActivate ? recordData : null}
        is_success={handleSuccess}
        is_cancelled={true}
      />
    </>
  );
}

import { Decoder } from "../../../../API/Global/Decoder";

// CODE
export const AccountingParams = () => {
  // Get Params
  let params = sessionStorage.getItem("par-1");

  const _decode = Decoder(params);

  // console.log("Params Accounting: ", _decode);

  //   return { code: _decode?.currency_code, name: _decode?.currency };
  return _decode;
};

import React from "react";
// CODE
export function RateCodeFinder(props) {
  // PROPS
  const { allRate, rate_id } = props;

  const _result = allRate.filter((items) => {
    return items.id == rate_id;
  });

  return _result[0];
}

// Import React Components
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Typography,
  Image,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  FormOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
// import ModalCheckOutForm from "../CheckOut/Form";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";

// CODE
export default function AnnualOutgoingForm(props) {
  // PROPS
  const { _openModal, _closeModal, _dataView, _year, _type, _main } = props;

  // USE REF
  const componentRef = useRef();

  // TABLE COLUMNS
  const columns = [
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "JAN",
      dataIndex: "jan",
      key: "jan",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "FEB",
      dataIndex: "feb",
      key: "feb",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MAR",
      dataIndex: "mar",
      key: "mar",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "APR",
      dataIndex: "apr",
      key: "apr",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MAY",
      dataIndex: "may",
      key: "may",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "JUN",
      dataIndex: "jun",
      key: "jun",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "JUL",
      dataIndex: "jul",
      key: "jul",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "AUG",
      dataIndex: "aug",
      key: "aug",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "SEP",
      dataIndex: "sep",
      key: "sep",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "OCT",
      dataIndex: "oct",
      key: "oct",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "NOV",
      dataIndex: "nov",
      key: "nov",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "DEC",
      dataIndex: "dec",
      key: "dec",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ].filter(item => !item.hidden);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    console.log("DATA VIEW >> ", _dataView);
    console.log("DATA DETAIL >> ", _dataView?.details);
    setLoading(true);
    if (_openModal == true) {
      setOpen(!open);
    }

    if (_dataView) {
      setData(_dataView);
    }

    setLoading(false);

    setIsLoading(false);
  }, [_openModal, _dataView]);

  // ON FINISH
  const onFinish = async () => {
    handlePrint();
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    _closeModal(false);
    setData([]);
    // setEdit(null);

    setIsLoading(true);
    form.resetFields();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:  `Annual Outgoing Reports ${
      _year ? moment(_year).format("YYYY") : ""}`,
    // onafterprint: () => alert("Print Success"),
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row>
              <FormOutlined style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Annual Outgoing`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="resv-form-modal"
          className="resv-form-modal"
          form={form}
          labelCol={{ span: 8 }}
          labelWrap={{ span: 16 }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{ padding: "30px 80px 30px 80px" }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Annual Outgoing Reports`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    src="/looklogo(color).png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Look Hotel Bandung`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                  // height: 40,
                }}
              >
                <Col span={12}>
                  <Form.Item
                    label="Year"
                    className="year"
                    style={{ margin: 0 }}
                    // labelCol={{ span: 5 }}
                    // wrapperCol={{ span: 19 }}
                  >
                    <Typography className="year" style={textForm}>
                      {moment(_year).format("YYYY")}
                    </Typography>
                  </Form.Item>
                  <Form.Item
                    label={"Article Main Group"}
                    className="article_main_group"
                    style={{ margin: 0 }}
                    // labelCol={{ span: 5 }}
                    // wrapperCol={{ span: 19 }}
                  >
                    <Typography
                      className="article-main-group"
                      style={textForm}
                    >
                      {_main.name}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="col-res-id">
                  <Form.Item
                    label="Type"
                    className="type"
                    style={{ margin: 0 }}
                    // labelCol={{ span: 5 }}
                    // wrapperCol={{ span: 19 }}
                  >
                    <Typography className="type" style={textForm}>
                      {_type.label}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Typography
                  style={{
                    margin: "15px 0px 0px",
                    fontSize: 14,
                    fontWeight: 500,
                    color: "grey",
                  }}
                >
                  Item List
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="transaction-table"
                  key="transaction-table"
                  name="transaction-table"
                  // loading={loading}
                  columns={columns}
                  dataSource={data}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  rowClassName={(_, index) => {
                    return index % 2 === 0 ? "odd" : "even";
                  }}
                  pagination={false}
                  rowKey={(record) => (record.id ? record.id : record.key)}
                  row
                />
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              // htmlType="submit"
              icon={<PrinterOutlined />}
              onClick={() => {
                handlePrint();
              }}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

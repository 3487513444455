// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// API
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  authorized_by,
  authorized_dept,
  authorized_roles,
  authorized_token,
  handleAuthorization,
} from "../../../../API/Global/Permission";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Params COA
import ParametersFrontOffice from "../../Parameters/ParamsFO";

// Import Ant Design Components
import {
  Col,
  Row,
  Select,
  Table,
  Space,
  Button,
  Modal,
  Typography,
  Form,
  Input,
  Result,
  Tooltip,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  MenuOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { FaExclamationTriangle } from "react-icons/fa";

// Import Functions
import { FrontOfficeParams } from "../../Functions/Parameters/ParamsFO";

// Notifications
import {
  failedRate,
  successRate,
} from "../../Notification/MasterNotif/Notification";
import { failedPermit, successPermit } from "../../Notification/Notification";

// CODE
export default function MasterRateCode(props) {
  // PROPS
  const {
    // GET RATE
    getRateCode,
    rate_code,
    // Segment
    segment_id,
    allSegment,
    segment_name,
    // sub_segment_id,
    // Room Category
    qty,
    roomCat,
    // Rendering
    is_render,
    is_rerender,
    is_disable,
    // Permissions
    is_permitted,
    is_company,
    getOptions,
    is_change,
  } = props;

  // CONTEXT
  const { getMasterRateCode, getMasterRateSegment } = useContext(MasterContext);
  const { requestPermissions } = useContext(MainContext);

  let auth_token = localStorage.getItem("permit");

  // PARAMS
  const params_coa = FrontOfficeParams();

  // STATE MANAGEMENT
  // Segment
  const [category, setCategory] = useState([]);
  const [segment, setSegment] = useState(null);
  const [segmentName, setSegmentName] = useState(null);
  const [rate, setRate] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  // Room Set
  const [quantity, setQuantity] = useState(0);
  // Checkbox
  const [checked, setChecked] = useState(true);
  // Company Rate Id
  const [isCompany, setIsCompany] = useState(false);
  const [companySegmentId, setCompanySegmentId] = useState([]);
  const [companyRateId, setCompanyRateId] = useState([]);
  const [open, setOpen] = useState(false);
  // Re-Render
  const [render, setRender] = useState(false);
  const [permitted, setPermitted] = useState(false);
  const [loading, setLoading] = useState(false);

  // UseForm
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async (val, sub) => {
    // console.log(val);
    // getMasterRateCode({
    await getMasterRateSegment({
      segment_compl: "master-segment",
      segment: val || segment,
      // sub_segment_id: val?.sub_segment_id || segment?.sub_segment_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Rate Status => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response?.data?.msg?.filter((filt) => {
            return !filt.is_deleted;
          });

          // Mapping to Option Value
          let option = filtered.map((dev) => ({
            value: dev?.rate_code,
            label: dev?.rate_code,
            // code: dev?.rate_code_id,
            key: dev?.id,
            id: dev?.id,
            main_segment_id: dev?.main_segment_id,
            main_segment_name: dev?.main_segment_name,
            main_segment_code: dev?.main_segment_code,
            room: dev?.room_category_name,
            category_id: dev?.room_category_id,
            // rate_code: "STD0101",
            description: dev?.description || null,
          }));

          console.log("Rate Options --> ", option);

          // Filtering by Room Category
          handleFilter(option, qty);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // GET PERMISSIONS
  const fetchDataAll = async (tkn) => {
    const _token = tkn;

    // console.log("Token All: ", _token);

    await axios
      .get(`${baseurl}/room/rate-code`, {
        headers: {
          Authorization: `Bearer ${_token.authorized_token || _token}`,
        },
      })
      .then((response) => {
        console.log("ALL RATE: ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response?.data?.msg;

          // Mapping to Option Value
          let option = filtered.map((dev) => ({
            value: dev?.rate_code,
            label: dev?.rate_code,
            // code: dev?.rate_code_id,
            id: dev?.id,
            main_segment_id: dev?.main_segment_id,
            main_segment_name: dev?.main_segment_name,
            main_segment_code: dev?.main_segment_code,
            room: dev?.room_category_name,
            category_id: dev?.room_category_id,
            // rate_code: "STD0101",
            description: dev?.description || null,
          }));

          console.log("PERMIT Rate Options --> ", option);

          setOptionValue(option);
          successRate(response, { method: 3 });
        } else {
          setOptionValue([]);
        }
      })
      .catch((error) => {
        console.log("ERROR PERMIT RATE CODE: ", error);
        failedRate(error, { method: 3 });
        setOptionValue([]);
      });
  };

  // USE EFFECT
  // useEffect(() => {
  //   console.log("optionValue on RateCode", optionValue);
  //   if (optionValue.length > 0) {
  //     getOptions(optionValue);
  //   } else {
  //     getOptions([]);
  //   }
  // }, [optionValue]);

  useEffect(() => {
    let cleanUp = false;

    if (allSegment?.length > 0 && !cleanUp) {
      console.log("Company Segment ID: ", allSegment);

      setCompanySegmentId(allSegment);
      setIsCompany(true);
    } else {
      console.log("COMPANY TURN TO FALSE: ", allSegment);

      setCompanySegmentId([]);
      setIsCompany(false);
    }

    if (segment_name && !cleanUp) {
      console.log("Segment Name: ", segment_name);
      setSegmentName(segment_name);
    } else {
      setSegmentName(null);
    }

    if (roomCat?.length > 0 && !cleanUp) {
      console.log("Room Category choosed: ", roomCat);

      setCategory(roomCat);
    } else {
      setCategory([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [allSegment, segment_name, roomCat]);

  useEffect(() => {
    let cleanUp = false;
    // console.log(segment_id, sub_segment_id);
    // console.log("Permit Effects: ", permitted);

    if ((permitted == true || is_permitted === true) && !cleanUp) {
      console.log(is_permitted);
      fetchDataAll(authorized_token);
    } else {
      if (segment_id > 0) {
        // console.log("Segment ID: ", segment_id);
        // console.log("This Component is Re-rendering Total QTY: ", qty);
        // console.log(segment_id, sub_segment_id);

        setQuantity(qty);
        setSegment(segment_id);
        fetchData(segment_id);
      } else {
        setSegment(null);
        setOptionValue([]);
      }
      setRate(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [segment_id, segment_name, qty, permitted, is_permitted]);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_render == true && !cleanUp) {
      // if (optionValue?.length > 0) {
      console.log("This Component is Re-renderin: ", is_render);
      fetchData();
      // }

      setRate(null);
      setRender(true);
      is_rerender(false);
    }

    if (is_company?.length > 0 && !cleanUp) {
      console.log("Rate Code ID Company: ", is_company);

      setCompanyRateId(is_company);
    } else {
      setCompanyRateId([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_render, render, is_company]);

  useEffect(() => {
    let cleanUp = false;

    if (!cleanUp) {
      console.log("IS COMP: ", isCompany);
    }

    return () => {
      cleanUp = true;
    };
  }, [isCompany]);

  // HANDLE FILTER
  const handleFilter = (val) => {
    const _option = val;
    const _qty = qty;
    const _max = params_coa?.group_count > 0 ? params_coa.group_count : 0;
    const _room = roomCat?.length > 0 ? roomCat : category;

    // console.log("Params FO: ", _max);
    // console.log("Total Room: ", _qty);
    console.log("Room Category: ", _room);
    console.log("Company ID: ", companyRateId, is_company);

    if (_qty >= _max) {
      if (companyRateId?.length > 0) {
        handleCompany(_option);
      } else {
        console.log(
          "This is called because you have Permissions or Parameters is True!"
        );

        handleNotCompany(_option);
        // setOptionValue(_option);
      }
    } else {
      if (_room?.length > 0 && _option.length > 0) {
        let _newValue = _option.filter((val) => {
          // console.log("Room Category rate = ", val.category_id);

          return _room.find(
            ({ room_category_id }) => room_category_id === val.category_id
          );
        });

        if (companyRateId?.length > 0) {
          handleCompany(_newValue);
        } else {
          console.log("Room Category rate = ", _newValue);
          console.log("Segment Comp ID = ", allSegment, companySegmentId);

          if (isCompany === true) {
            handleCompany(_newValue);
          } else {
            handleNotCompany(_newValue);
            // setOptionValue([]);
          }
        }
      } else {
        console.log(isCompany);
        if (isCompany === true) {
          handleCompany(_option);
        } else {
          console.log("This is not supposed to be called!", _room, _option);
          setOptionValue([]);
        }
      }
    }
  };

  // HANDLE PERMIT
  const handlePermit = async (val) => {
    const _submit = val;

    await requestPermissions({
      auth: "auth",
      permit: "permissions-supervisor-fo",
      email: _submit.email,
      password: _submit.password,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Permit: ", response);

        if (response?.data?.msg == "Access Granted") {
          // setCredentials(response?.data?.data);

          localStorage.setItem(
            "permit",
            response?.data?.data?.session?.access_token
          );

          let _proceed = handleAuthorization(
            response?.data?.data?.session?.access_token
          );

          successPermit(response);
          console.log("PROCEED: ", _proceed);

          if (
            _proceed.authorized_token &&
            _proceed.authorized_dept.toLocaleLowerCase().includes("front") &&
            (_proceed.authorized_roles == "ROLE_SUPERVISOR" ||
              _proceed.authorized_roles == "ROLE_MANAGER")
          ) {
            fetchDataAll(_proceed);
            setPermitted(true);
          }
        } else {
          failedPermit(response);
        }

        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Permit Err: ", error);

        failedPermit(error);
        setLoading(false);
      },
    });
  };

  // HANDLE OPEN
  const handleOpen = (val) => {
    console.log("OPEN!!!");

    setOpen(true);

    let token = handleAuthorization(auth_token);

    console.log("Token: ", authorized_token);
    console.log("By: ", authorized_by);
    console.log("Dept: ", authorized_dept);
    console.log("Roles: ", authorized_roles);

    if (
      token &&
      authorized_dept == "FRONT_OFFICE" &&
      (authorized_roles == "ROLE_SUPERVISOR" ||
        authorized_roles == "ROLE_MANAGER")
    ) {
      setPermitted(true);
      // is_permit(true);
    }
  };

  // Handle Close
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    // setPermitted(false);
  };

  // ON FINISH
  const onFinish = async (val) => {
    const value = val;

    console.log("ON FINISH --> ", value);
    handlePermit(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = async (val) => {
    const value = val;

    console.log("ON FINISH FAILED --> ", value);
  };

  // HANDLE COMPANY RATE
  const handleCompany = (arr) => {
    const _bucket = arr;
    const _segmentCompany = allSegment;
    const _segmentSelected = segment_id;

    console.log("Segment Comp ID = ", _segmentCompany, companySegmentId);
    console.log("Segment ID selected: ", segment, segment_id);

    if (_segmentCompany?.length > 0) {
      let _existed = _segmentCompany.filter((val) => {
        return val === _segmentSelected;
      });

      console.log("Existed Company ID: ", _existed);

      if (_existed?.length > 0) {
        let _companyRate = _bucket.filter((value) => {
          return companyRateId.includes(value?.id);
        });

        console.log("Rate Comp = ", _companyRate);
        setOptionValue(_companyRate);
      } else {
        console.log("Outside of company segments: ", _bucket);
        // setOptionValue(_bucket);
        handleNotCompany(_bucket);
      }
    } else {
      setOptionValue([]);
    }
  };

  // HANDLE NOT COMPANY
  const handleNotCompany = (arr) => {
    const _data = arr;
    const _segment_name = segment_name || "";

    console.log("Segment is not Company: ", _segment_name);
    console.log("Data is not Company: ", _data);
    console.log(`${_segment_name.toLowerCase().indexOf("corp")} <=/=== -1/0`);

    if (_segment_name.toLowerCase().indexOf("corp") === 0) {
      console.log("ITS CORP!");
      // setOptionValue(_data);
      setOptionValue([]);
    } else if (_segment_name.toLowerCase().indexOf("gov") === 0) {
      console.log("ITS GOV!");
      setOptionValue([]);
    }
    // else if (_segment_name.toLowerCase().indexOf("online") === 0) {
    //   console.log("ITS TRAVEL AGENT!");
    //   setOptionValue([]);
    // }
    else {
      // setOptionValue(_data);
      handleFilterCategory(_data);
    }
  };

  // HANDLE FILTER BY ROOM CATEGORY
  const handleFilterCategory = (val) => {
    const _room = val;

    console.log("Filter by Room Category: ", _room);
    console.log("Filter by Room Category (ROOM CATE): ", roomCat);

    let _newRoom = _room.filter((value) => {
      return roomCat.find(
        ({ room_category_id }) => room_category_id === value.category_id
      );
    });

    console.log("New Room: ", _newRoom);

    setOptionValue(_newRoom);
  };

  return (
    <>
      <Space
        className="rate-row"
        align="start"
        size={0}
        // style={{ width: "100%" }}
      >
        {/* <Col className="col" */}
        <Select
          key={"rate-code-select"}
          className={"rate-code-select"}
          disabled={is_disable == true ? true : false}
          value={rate ? rate : rate_code}
          allowClear
          showSearch
          onChange={(e, allKey) => {
            setRate(e);

            console.log(e, allKey);

            // let code = optionValue.filter((opt) => {
            //   return opt.value == e;
            // });

            // console.log("RATE Code ID = ", code);

            getRateCode(e, allKey);
            //  if (onSet) onSet(deviceid);
          }}
          options={optionValue}
          style={{ width: "100%" }}
        />

        <Tooltip className="rate-tooltip" title="Open All Rate Code">
          <Button
            className="submit-btn"
            type="primary"
            disabled={is_disable == true ? true : false}
            onClick={handleOpen}
          >
            {`Rate`}
          </Button>
        </Tooltip>
      </Space>

      <Modal
        className="modal-permissions"
        title={
          <>
            <Row>
              <SafetyCertificateOutlined
                style={{ color: "#F64E60", fontSize: 24 }}
              />
              <Typography style={{ marginLeft: 15 }}>
                {`Authorization Requests`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={800}
      >
        <Form
          name="permissions-form"
          className="permissions-form"
          key="permissions-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 16 }}
          //   wrapperCol={{ span: 12 }}
          autoComplete="off"
          layout="vertical"
          // initialValues={{
          //   room_modal: initialForm?.length > 0 ? initialForm : null,
          // }}
        >
          {permitted ? (
            <>
              <Result
                className="permit-results"
                status={"success"}
                title={"Access Granted!"}
              />
            </>
          ) : (
            <>
              <Row
                className="email-row"
                gutter={30}
                align="middle"
                justify="center"
              >
                <Col
                  span={12}
                  className="content-col"
                  style={{
                    padding: 5,
                    border: "2px solid #F64E60",
                    borderRadius: 5,
                  }}
                >
                  <Row className="col-space" justify="center">
                    <FaExclamationTriangle
                      className="warning-icon"
                      style={{
                        fontSize: 50,
                        color: "#EE9D01",
                        margin: "0px 30px 0px",
                      }}
                    />
                  </Row>

                  <Row className="col-space" justify="center">
                    <Typography
                      className="permit-text"
                      style={{
                        fontWeight: 750,
                        fontSize: 18,
                        textAlign: "justify",
                        padding: "5px 10px",
                      }}
                    >
                      {`This action required Permission and Approval from above the Staff Roles to Proceed! Please, Contact your Superior to Make the Request!`}
                    </Typography>
                  </Row>
                </Col>

                <Col
                  span={12}
                  className="content-col"
                  style={{
                    padding: "0px 50px",
                  }}
                >
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    style={{
                      width: 250,
                    }}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                    style={{
                      width: 250,
                    }}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="modal-btn-row" gutter={30} justify="end">
                <Button
                  className="submit-btn"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{
                    margin: "0px 30px 0px 0px",
                    // backgroundColor: "#0BB7AF",
                    // borderColor: "#0BB7AF",
                  }}
                >
                  {`Confirm`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}

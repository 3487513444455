// Import React Components
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// AUTH
import { sub_children } from "../../../API/Global/Payload";

// Page Components
import ARPaymentPage from "../../../Pages/BackOffice/AR/ARPayment/ARPayment";
import ARAgingPage from "../../../Pages/BackOffice/AR/ARAging/ARAging";
import ARPaymentListPage from "../../../Pages/BackOffice/AR/ARList/ARList";

import UnAuthorized from "../../../Pages/Unauthorized/Unauthorized";
import NotFound from "../../../Pages/NotFound/NotFound";

import MainARPage from "../../../Pages/BackOffice/AR/Main/ARMainPage";

// List Route
const grandRoute = [
  {
    path: "account-receivable-payment",
    name: "ar-pay",
    id_modules: 97,
    element: <ARPaymentPage />,
  },
  {
    path: "account-receivable-aging",
    name: "ar-aging",
    id_modules: 98,
    element: <ARAgingPage />,
  },
  {
    path: "account-receivable-payment-list",
    name: "ar-list",
    id_modules: 99,
    element: <ARPaymentListPage />,
  },
];

// CODE
export default function AccountReceivablesRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    console.log("Protected Routes AR: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="*" element={<MainARPage />} />

        {protectedRoutes}
      </Routes>
    </>
  );
}

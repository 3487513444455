// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Col, Row, Space } from "antd";

// Import Ant Design Pie Charts
import { Pie, measureTextWidth } from "@ant-design/plots";

// Import Icons
import { FaMoneyBillWave } from "react-icons/fa";
import { RiBarChartGroupedFill } from "react-icons/ri";
import { CreditCardFilled } from "@ant-design/icons";
import { GiTakeMyMoney } from "react-icons/gi";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Styling
const styleNumber = {
  color: "#464E5F",
  fontSize: 16,
  fontWeight: 500,
};
const styleText = {
  color: "#B5B5C3",
  fontSize: 12,
  fontWeight: 500,
};

// CODE
export default function PiePayment() {
  // CONTEXT
  const { getDashboard } = useContext(MainContext);

  // DATA
  // const data = [
  //   {
  //     type: "Cash",
  //     value: 79750000,
  //   },
  //   {
  //     type: "Debit & Credit",
  //     value: 80000000,
  //   },
  //   {
  //     type: "City Ledger",
  //     value: 110000000,
  //   },
  //   {
  //     type: "AR - Clearence",
  //     value: 10000000,
  //   },
  // ];

  // STATE MANAGEMENT
  const [data, setData] = useState([]);

  // FETCH DATA
  const fetchData = async () => {
    await getDashboard({
      type: "dashboard",
      category: "pie-chart",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Chart --> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([
            {
              actual_today: 0,
              payment_type_id: 1,
              description: "Debit",
            },
            {
              actual_today: 0,
              payment_type_id: 2,
              description: "Cash",
            },
            {
              actual_today: 0,
              payment_type_id: 3,
              description: "Credit Card",
            },
            {
              actual_today: 0,
              payment_type_id: 22,
              description: "City Ledger",
            },
          ]);
        }
      },
      onReject: (error) => {
        console.log("Dashboard Rev => ", error);

        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  const config = {
    legend: false,
    appendPadding: 10,
    data,
    angleField: "actual_today",
    colorField: "description",
    // color: [
    //   `var(--button-secondary-1, linear-gradient(262deg, #0BB7AF -53.49%, #3BE0D8 201.92%))`,
    //   "#52c41a",
    //   "#f5222d",
    //   "#8A50FC",
    // ],
    color: ["#00C4F2", "#0BB7AF", "#f5222d", "#61045F"],
    radius: 1,
    innerRadius: 0.75,
    meta: {
      actual_today: {
        formatter: (val) =>
          `${val > 0 ? val : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      content: null,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    statistic: {
      title: {
        style: {
          fontSize: 18,
        },
      },
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: 24,
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
    state: {
      active: {
        animate: { duration: 100, easing: "easeLinear" },
        style: {
          lineWidth: 0,
          stroke: "#FFF",
        },
      },
    },
  };

  return (
    <>
      <Col
        className="pie-col"
        span={24}
        style={{
          padding: "0px 40px",
          height: 300,
        }}
      >
        <Pie className="pie-charts" {...config} />
      </Col>

      <Row
        className="layout-row"
        justify="start"
        align="middle"
        gutter={[30, 30]}
        style={{
          padding: "20px 30px",
        }}
      >
        <Col span={12} className="layout-col">
          <Row
            justify="start"
            className="row-legend"
            gutter={30}
            style={{ padding: "30px 0px" }}
          >
            <Col
              span={8}
              style={{
                borderRadius: 6,
                display: "flex",
                backgroundColor: "#E1F0FF",
                maxHeight: 44,
                // margin: "0px 30px 0px 0px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <FaMoneyBillWave
                style={{
                  color: "#3699FF",
                  fontSize: 30,
                }}
              />
            </Col>

            <Col span={16}>
              <Row style={styleNumber}>
                {`${
                  data[0]?.actual_today > 0 ? data[0]?.actual_today : 0
                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Row>
              <Row style={styleText}>{data[0]?.description}</Row>
            </Col>
          </Row>

          <Row
            justify="start"
            className="row-legend"
            gutter={30}
            // style={{ padding: "30px 30px 0px" }}
          >
            <Col
              span={8}
              style={{
                borderRadius: 6,
                display: "flex",
                backgroundColor: "#D7F9EF",
                maxHeight: 44,
                // margin: "0px 30px 0px 0px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <CreditCardFilled
                style={{
                  color: "#0BB783",
                  fontSize: 24,
                }}
              />
            </Col>

            <Col span={16}>
              <Row style={styleNumber}>
                {`${data[1]?.actual_today || 0}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}
              </Row>
              <Row style={styleText}>{data[1]?.description}</Row>
            </Col>
          </Row>
        </Col>

        <Col span={12} className="layout-col">
          <Row
            justify="start"
            className="row-legend"
            gutter={30}
            style={{ padding: "30px 0px" }}
          >
            <Col
              span={8}
              style={{
                borderRadius: 6,
                display: "flex",
                backgroundColor: "#FFE2E5",
                maxHeight: 44,
                // margin: "0px 30px 0px 0px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <RiBarChartGroupedFill
                style={{
                  color: "#F64E60",
                  fontSize: 24,
                }}
              />
            </Col>

            <Col span={16}>
              <Row style={styleNumber}>
                {`${data[2]?.actual_today || 0}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}
              </Row>
              <Row style={styleText}>{data[2]?.description}</Row>
            </Col>
          </Row>

          <Row
            justify="start"
            className="row-legend"
            gutter={30}
            // style={{ padding: "30px 30px 0px" }}
          >
            <Col
              span={8}
              style={{
                borderRadius: 6,
                display: "flex",
                backgroundColor: "#EEE5FF",
                maxHeight: 44,
                // margin: "0px 30px 0px 0px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <GiTakeMyMoney
                style={{
                  color: "#8A50FC",
                  fontSize: 24,
                }}
              />
            </Col>

            <Col span={16}>
              <Row style={styleNumber}>
                {`${data[3]?.actual_today || 0}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}
              </Row>
              <Row style={styleText}>{data[3]?.description}</Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { sub_children } from "../../../../API/Global/Payload";

// Import Ant Design Components
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { IoIosPricetags } from "react-icons/io";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import { BsBoxFill } from "react-icons/bs";
import { GiFruitBowl } from "react-icons/gi";
import { FaBoxesPacking, FaBuildingUser, FaCheckToSlot } from "react-icons/fa6";
import { MdOutlinePlaylistAddCircle } from "react-icons/md";
import { TbFileTime } from "react-icons/tb";

// Import Page Components

// Import Global Styles
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../Style/Global/Global";

// List
const listCard = [
  {
    path: "purchase-request",
    title: "Purchase Request",
    id_modules: 47,
    icon: <BsBoxFill style={globalIcon} />,
  },
  {
    path: "daily-market-list",
    title: "Daily Market List (DML)",
    id_modules: 99,
    icon: <GiFruitBowl style={globalIcon} />,
  },
  {
    path: "approval-request",
    title: "Request Approval",
    id_modules: 49,
    icon: <FaCheckToSlot style={globalIcon} />,
  },
  {
    path: "purchase-order",
    title: "Purchase Order",
    id_modules: 48,
    icon: <FaBoxesPacking style={globalIcon} />,
  },
  {
    path: "vendor-management",
    title: "Vendor Management",
    id_modules: 50,
    icon: <FaBuildingUser style={globalIcon} />,
  },
  {
    path: "price-management",
    title: "Price Management",
    id_modules: 51,
    icon: <IoIosPricetags style={globalIcon} />,
  },
];

// CODE
export default function MainPurchasingPage() {
  // STATE MANAGEMENT
  // List Items
  const [listItems, setlistItems] = useState([]);

  // USE NAVIGATE
  const navigate = useNavigate();

  // HANDLE MOVE
  const move = (value) => {
    // console.log("move --> ", value);

    navigate(`${value}`);
  };

  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const grandModule = sub_children?.length > 0 ? sub_children : [];

    const list = listCard.map((record) => {
      if (grandModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      } else {
        return null;
      }
    });

    // console.log("Grand Children Modules: ", sub_children);
    // console.log("Filtered: ", list);

    setlistItems(list);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <BiSolidPurchaseTagAlt
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Purchasing`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={mainBody}
            >
              {listItems}
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

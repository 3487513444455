// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import AnnualOutgoingForm from "../../../../../../Forms/AnnualOutgoing/Form";

export default function AnnualReportTable(props) {
  // PROPS
  const { _main, _year, _type } = props;

  //CONTEXT
  const { getInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (_main && _year && _type && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setTotal({
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_main, _year, _type]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    await getInventoryReport({
      type: `annual-transfer/${moment(_year).format("YYYY-MM-DD")}/${_main.main_id}`,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
              jan: _type.value == 1 ? item.qty_jan : _type.value == 2 ? item.avg_jan : _type.value == 3 ? item.amt_jan : 0,
              feb: _type.value == 1 ? item.qty_feb : _type.value == 2 ? item.avg_feb : _type.value == 3 ? item.amt_feb : 0,
              mar: _type.value == 1 ? item.qty_mar : _type.value == 2 ? item.avg_mar : _type.value == 3 ? item.amt_mar : 0,
              apr: _type.value == 1 ? item.qty_apr : _type.value == 2 ? item.avg_apr : _type.value == 3 ? item.amt_apr : 0,
              may: _type.value == 1 ? item.qty_may : _type.value == 2 ? item.avg_may : _type.value == 3 ? item.amt_may : 0,
              jun: _type.value == 1 ? item.qty_jun : _type.value == 2 ? item.avg_jun : _type.value == 3 ? item.amt_jun : 0,
              jul: _type.value == 1 ? item.qty_jul : _type.value == 2 ? item.avg_jul : _type.value == 3 ? item.amt_jul : 0,
              aug: _type.value == 1 ? item.qty_aug : _type.value == 2 ? item.avg_aug : _type.value == 3 ? item.amt_aug : 0,
              sep: _type.value == 1 ? item.qty_sep : _type.value == 2 ? item.avg_sep : _type.value == 3 ? item.amt_sep : 0,
              oct: _type.value == 1 ? item.qty_oct : _type.value == 2 ? item.avg_oct : _type.value == 3 ? item.amt_oct : 0,
              nov: _type.value == 1 ? item.qty_nov : _type.value == 2 ? item.avg_nov : _type.value == 3 ? item.amt_nov : 0,
              dec: _type.value == 1 ? item.qty_des : _type.value == 2 ? item.avg_des : _type.value == 3 ? item.amt_des : 0
            };
          });

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      jan: prev.jan + curr.jan,
                      feb: prev.feb + curr.feb,
                      mar: prev.mar + curr.mar,
                      apr: prev.apr + curr.apr,
                      may: prev.may + curr.may,
                      jun: prev.jun + curr.jun,
                      jul: prev.jul + curr.jul,
                      aug: prev.aug + curr.aug,
                      sep: prev.sep + curr.sep,
                      oct: prev.oct + curr.oct,
                      nov: prev.nov + curr.nov,
                      dec: prev.dec + curr.dec
                    };
                  },
                  { jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0, jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0 }
                )
              : { jan: 0, feb: 0, mar: 0, apr: 0, may: 0, jun: 0, jul: 0, aug: 0, sep: 0, oct: 0, nov: 0, dec: 0 };

          setData(filter);
          setTotal(sumTotal);
        } else {
          setData([]);
          setTotal({
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0
          });
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Annual Outgoing Reports ${
        _year ? moment(_year).format("YYYY") : ""}`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "JAN",
      dataIndex: _type.value == 1 ? "qty_jan" : _type.value == 2 ? "avg_jan" : _type.value == 3 ? "amt_jan" : "",
      key: _type.value == 1 ? "qty_jan" : _type.value == 2 ? "avg_jan" : _type.value == 3 ? "amt_jan" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "FEB",
      dataIndex: _type.value == 1 ? "qty_feb" : _type.value == 2 ? "avg_feb" : _type.value == 3 ? "amt_feb" : "",
      key: _type.value == 1 ? "qty_feb" : _type.value == 2 ? "avg_feb" : _type.value == 3 ? "amt_feb" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MAR",
      dataIndex: _type.value == 1 ? "qty_mar" : _type.value == 2 ? "avg_mar" : _type.value == 3 ? "amt_mar" : "",
      key: _type.value == 1 ? "qty_mar" : _type.value == 2 ? "avg_mar" : _type.value == 3 ? "amt_mar" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "APR",
      dataIndex: _type.value == 1 ? "qty_apr" : _type.value == 2 ? "avg_apr" : _type.value == 3 ? "amt_apr" : "",
      key: _type.value == 1 ? "qty_apr" : _type.value == 2 ? "avg_apr" : _type.value == 3 ? "amt_apr" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MAY",
      dataIndex: _type.value == 1 ? "qty_may" : _type.value == 2 ? "avg_may" : _type.value == 3 ? "amt_may" : "",
      key: _type.value == 1 ? "qty_may" : _type.value == 2 ? "avg_may" : _type.value == 3 ? "amt_may" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "JUN",
      dataIndex: _type.value == 1 ? "qty_jun" : _type.value == 2 ? "avg_jun" : _type.value == 3 ? "amt_jun" : "",
      key: _type.value == 1 ? "qty_jun" : _type.value == 2 ? "avg_jun" : _type.value == 3 ? "amt_jun" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "JUL",
      dataIndex: _type.value == 1 ? "qty_jul" : _type.value == 2 ? "avg_jul" : _type.value == 3 ? "amt_jul" : "",
      key: _type.value == 1 ? "qty_jul" : _type.value == 2 ? "avg_jul" : _type.value == 3 ? "amt_jul" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "AUG",
      dataIndex: _type.value == 1 ? "qty_aug" : _type.value == 2 ? "avg_aug" : _type.value == 3 ? "amt_aug" : "",
      key: _type.value == 1 ? "qty_aug" : _type.value == 2 ? "avg_aug" : _type.value == 3 ? "amt_aug" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "SEP",
      dataIndex: _type.value == 1 ? "qty_sep" : _type.value == 2 ? "avg_sep" : _type.value == 3 ? "amt_sep" : "",
      key: _type.value == 1 ? "qty_sep" : _type.value == 2 ? "avg_sep" : _type.value == 3 ? "amt_sep" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "OCT",
      dataIndex: _type.value == 1 ? "qty_oct" : _type.value == 2 ? "avg_oct" : _type.value == 3 ? "amt_oct" : "",
      key: _type.value == 1 ? "qty_oct" : _type.value == 2 ? "avg_oct" : _type.value == 3 ? "amt_oct" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "NOV",
      dataIndex: _type.value == 1 ? "qty_nov" : _type.value == 2 ? "avg_nov" : _type.value == 3 ? "amt_nov" : "",
      key: _type.value == 1 ? "qty_nov" : _type.value == 2 ? "avg_nov" : _type.value == 3 ? "amt_nov" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "DEC",
      dataIndex: _type.value == 1 ? "qty_des" : _type.value == 2 ? "avg_des" : _type.value == 3 ? "amt_des" : "",
      key: _type.value == 1 ? "qty_des" : _type.value == 2 ? "avg_des" : _type.value == 3 ? "amt_des" : "",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ].filter(item => !item.hidden);

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={1} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}> <b> {`${total.jan ? total.jan : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.feb ? total.feb : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.mar ? total.mar : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.apr ? total.apr : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.may ? total.may : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.jun ? total.jun : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.jul ? total.jul : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.aug ? total.aug : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.sep ? total.sep : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.oct ? total.oct : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.nov ? total.nov : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}> <b> {`${total.dec ? total.dec : 0}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," )} </b> </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <AnnualOutgoingForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _year = {_year}
        _main = {_main ? _main : null}
        _type = {_type}
        _total = {total}
      />
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// AUTH
import { sub_children, sub_module } from "../../../API/Global/Payload";

// Import Ant Design Components
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { PiBookOpenTextDuotone } from "react-icons/pi";
import { IoIosPricetags } from "react-icons/io";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import {
  BsBoxFill,
  BsCoin,
  BsFillJournalBookmarkFill,
  BsJournalCheck,
} from "react-icons/bs";
import { FaBoxesPacking, FaBuildingUser, FaCheckToSlot } from "react-icons/fa6";
import {
  MdAccountBalanceWallet,
  MdOutlinePlaylistAddCircle,
} from "react-icons/md";
import { TbFileDollar, TbFileTime } from "react-icons/tb";
import { GiBookmark } from "react-icons/gi";

// Import Page Components

// Import Global Styles
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../Style/Global/Global";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";

// List
const listCard = [
  {
    path: "create-journal",
    title: "Journal",
    id_modules: 29,
    icon: <BsFillJournalBookmarkFill style={globalIcon} />,
  },
  {
    path: "journal-list",
    title: "Jurnal List",
    id_modules: 30,
    icon: <GiBookmark style={globalIcon} />,
  },
  {
    path: "general-ledgers",
    title: "General Ledgers",
    id_modules: 31,
    icon: <PiBookOpenTextDuotone style={globalIcon} />,
  },
  {
    path: "chart-of-account",
    title: "Chart of Account",
    id_modules: 32,
    icon: <MdAccountBalanceWallet style={globalIcon} />,
  },
  {
    path: "closing",
    title: "Closing",
    id_modules: 33,
    icon: <BsJournalCheck style={globalIcon} />,
  },
  {
    path: "accounting-ledgers",
    title: "Company Ledgers",
    id_modules: 35,
    icon: <TbFileDollar style={globalIcon} />,
  },
  {
    path: "start-up-accounting",
    title: "Start-Up Accounting",
    id_modules: 36,
    icon: <BsCoin style={globalIcon} />,
  },
  {
    path: "journal-register",
    title: "Journal Register",
    id_modules: 106,
    icon: <HiOutlineClipboardDocumentList style={globalIcon} />,
  },
];

// CODE
export default function MainAccountingPage() {
  // STATE MANAGEMENT
  // List Items
  const [listItems, setlistItems] = useState([]);

  // USE NAVIGATE
  const navigate = useNavigate();

  // HANDLE MOVE
  const move = (value) => {
    // console.log("move --> ", value);

    navigate(`${value}`);
  };

  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const grandModule = sub_children?.length > 0 ? sub_children : [];

    const list = listCard.map((record) => {
      if (grandModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      } else {
        return null;
      }
    });

    // console.log("Grand Children Modules: ", sub_children);
    // console.log("Filtered: ", list);

    setlistItems(list);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <BiSolidPurchaseTagAlt
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Accounting`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={mainBody}
            >
              {listItems}
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

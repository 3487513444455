// React Components
import React from "react";

// Import Antd Components
import { Button, Empty } from "antd";

// CODE
export default function EmptyTableComponents(props) {
  // Props
  const { is_refresh } = props;

  // Handle Refresh
  const handleRefresh = () => {
    is_refresh(true);
  };

  return (
    // CODE
    <>
      <Empty
        className="table-empty"
        description={
          "Data Empty or Failed to Load! Please, Check Your Internet Again or Try Again Later!"
        }
        style={{
          padding: 30,
          border: "1px solid black",
          borderRadius: 10,
        }}
      >
        <Button
          className="refresh-btn"
          type="default"
          onClick={handleRefresh}
          key="refresh-btn-empty"
        >{`Refresh`}</Button>
      </Empty>
    </>
  );
}

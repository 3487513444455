/* eslint-disable import/no-anonymous-default-export */
// Import Redux
import { combineReducers } from "redux";

// import auth from "./authenticate";
import auth from "./auth";
import message from "./message";
import nightaudit from "./nightaudit";

// CODE COMBINE REDUCERS
// eslint-disable-next-line import/no-anonymous-default-export
export default combineReducers({
  auth,
  message,
  nightaudit,
});

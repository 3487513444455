// REACT COMPONENTS
import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// GLOBAL API
import { user_name } from "../../../API/Global/Payload";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { PrinterOutlined } from "@ant-design/icons";
import { BsPrinter } from "react-icons/bs";

// GLOBAL STYLE
import { textForm } from "../../../Style/Global/Global";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// NOTIFICATION
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// CODE
export default function VendorArticleForm(props) {
  // PROPS
  const { openModal, closeModal, dataVendor, is_saved, params } = props;

  // Params
  const currency = CurrencySymbols().code;

  // USE REF
  const componentRef = useRef();

  //CONTEXT
  const { getArticleVendorList } = useContext(MainContext);

  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  // Data
  const [data, setData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  // Loading
  const [isLoad, setIsLoad] = useState(false);

  // Tax
  const _tax = params?.tax_percentage / 100;

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(openModal);
      fetchData();
    }

    if (dataVendor && !cleanUp) {
      // console.log("Data Vendor -->>> ", dataVendor);
      setData(dataVendor);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataVendor, is_saved]);

  // FETCH DATA
  const fetchData = async () => {
    // console.log("DATA VENDORR >>>>> ", dataVendor);
    await getArticleVendorList({
      article_vendor: "supplier-article-list",
      id_supplier: dataVendor?.id_supplier,
      onAwait: () => {
        setIsLoad(true);
      },
      onSuccess: (response) => {
        // console.log("RESPONSE SUPPLIER ARTICLE LIST>> ", response);

        if (response?.data.msg != null) {
          let filtered = response.data.msg.filter((val) => {
            if (val.is_deleted == null || val.is_deleted == false) {
              return val;
            }
          });

          setDataSource(filtered);
        } else {
          setDataSource([]);
        }
        setIsLoad(false);
      },
      onReject: (error) => {
        console.log("Error >> ", error);
        failedFetch(error);
        setIsLoad(false);
      },
    });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);

    setData(null);
    setDataSource([]);

    setIsLoad(false);

    form.resetFields();
  };

  // HANDLE REACT PRINT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Article Vendor Form ${data?.supplier_name}`,
    onAfterPrint: () => alert("Print Success"),
  });

  const onFinish = () => {};
  const onFinishFailed = () => {};

  // Table Columns
  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      render: (_, record) => dataSource.indexOf(record) + 1,
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        let _price = price;
        let _ppn = record?.is_ppn ? _price + `**` : _price + "*";

        return `${_ppn}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "VAT Price",
      dataIndex: "is_ppn",
      key: "is_ppn",
      render: (ppn, record) => {
        let _ppn = record?.price * _tax;
        let _prc = record?.price;

        switch (ppn) {
          case true:
            _prc = record?.price + _ppn;
            break;

          default:
            break;
        }

        return `${_prc}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  return (
    <>
      <Modal
        className="res-form-modal"
        name="res-form-modal"
        key="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <BsPrinter style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography
                className="text-modal-title"
                style={{ marginLeft: 15 }}
              >
                {`Form List Article Vendor`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="article-form"
          key="article-form"
          className="article-form"
          form={form}
          labelCol={{ span: 10 }}
          labelWrap={{ span: 14 }}
          labelAlign="left"
          autoComplete="off"
          layout="horizontal"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // style={{ padding: "30px 80px 30px 80px" }}
          style={{
            padding: 30,
          }}
        >
          <Content className="content-reservation-form" ref={componentRef}>
            <Row gutter={30} className="row-header">
              <Col span={12} className="col-title-form">
                <Typography.Title level={2} className="text-form-title">
                  {`Article Vendor`}
                </Typography.Title>
              </Col>

              <Col span={12} align="middle" className="col-img-logo">
                <Image
                  src="/looklogo(color).png"
                  className="img-form-logo"
                  preview={false}
                  style={{
                    width: "25%",
                  }}
                />

                <Typography.Title level={4} className="text-form-id">
                  {`Look Hotel Bandung`}
                </Typography.Title>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#EBEDF3" }}
            />

            <Row
              gutter={30}
              className="row-vendor-article"
              style={{ width: "100%", fontWeight: 500 }}
            >
              <Col span={12} className="col-vendor-article-details">
                <Row
                  style={{ width: "100%", height: 22 }}
                  className="row-supplier-code"
                >
                  <Form.Item
                    label="Supplier Code"
                    className="supplier-code"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography className="supplier-code-text" style={textForm}>
                      {data?.supplier_code ? data.supplier_code : "-"}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  className="row-supplier-name"
                  style={{ width: "100%", height: 22 }}
                >
                  <Form.Item
                    label="Supplier Name"
                    className="supplier-name"
                    style={{ width: "100%" }}
                  >
                    <Typography className="supplier-name-text" style={textForm}>
                      {data?.supplier_name ? data.supplier_name : "-"}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  className="row-supplier-address"
                  style={{ width: "100%", maxHeight: 70 }}
                >
                  <Form.Item
                    label="Supplier Address"
                    className="supplier-address"
                    style={{ width: "100%" }}
                  >
                    <Typography
                      className="supplier-address-text"
                      style={textForm}
                    >
                      {data?.supplier_address ? data.supplier_address : "-"}
                    </Typography>
                  </Form.Item>
                </Row>
              </Col>

              <Col span={12} className="col-vendor-article-details">
                <Row
                  className="row-contact-person"
                  style={{ width: "100%", height: 22 }}
                >
                  <Form.Item
                    label="Contact Person"
                    className="contact-person"
                    style={{ width: "100%" }}
                  >
                    <Typography
                      className="contact-person-text"
                      style={textForm}
                    >
                      {data?.contact_person_supplier
                        ? data.contact_person_supplier
                        : "-"}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  className="row-phone-number"
                  style={{ width: "100%", height: 22 }}
                >
                  <Form.Item
                    label="Phone Number"
                    className="phone-number"
                    style={{ width: "100%" }}
                  >
                    <Typography className="phone-number-text" style={textForm}>
                      {data?.cp_phone_number ? data.cp_phone_number : "-"}
                    </Typography>
                  </Form.Item>
                </Row>

                <Row
                  className="row-email"
                  style={{ width: "100%", height: 22 }}
                >
                  <Form.Item
                    label="Email"
                    className="email"
                    style={{ width: "100%" }}
                  >
                    <Typography className="email-text" style={textForm}>
                      {data?.email_supplier ? data.email_supplier : "-"}
                    </Typography>
                  </Form.Item>
                </Row>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              orientation="left"
              orientationMargin={0}
              style={{
                margin: "0px 0px 10px",
                borderColor: "#EBEDF3",
                fontSize: 20,
                fontWeight: 750,
              }}
            >
              {`Article List`}
            </Divider>

            <Row
              className="row-remarks"
              style={{
                width: "100%",
              }}
            >
              <Col
                span={24}
                className="col-remarks"
                style={{
                  width: "100%",
                }}
              >
                <Table
                  className="pr-detail-table"
                  name="pr-detail-table"
                  key="pr-detail-table"
                  loading={isLoad}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  size="small"
                  bordered
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "odd" : "even";
                  }}
                  rowKey={(record) => (record.id ? record.id : record.key)}
                />
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "15px 0px", backgroundColor: "#EBEDF3" }}
            />

            <Row className="row-by" style={{ width: "100%", height: 30 }}>
              <Col span={24}>
                <Typography className="text-roles" style={{ fontSize: 10 }}>
                  {`* Prices are not subject to VAT`}
                </Typography>
                <Typography className="text-roles" style={{ fontSize: 10 }}>
                  {`** Prices are subject to VAT (PPN ${params?.tax_percentage}%)`}
                </Typography>
              </Col>
            </Row>

            <Row className="row-by" style={{ width: "100%", height: 25 }}>
              <Form.Item
                className="printed-by"
                label="Printed by"
                labelCol={{ span: 5 }}
                style={{ width: "100%", height: 25 }}
              >
                <Typography className="text-roles" style={textForm}>
                  {user_name ? user_name : "unknown"}
                </Typography>
              </Form.Item>
            </Row>

            <Row className="row-by" style={{ width: "100%", height: 25 }}>
              <Form.Item
                label="Printed Date"
                className="date_time"
                labelCol={{ span: 5 }}
                style={{ width: "100%", margin: 0 }}
              >
                <Typography className="text-date" style={textForm}>
                  {moment().format("DD-MM-YYYY HH:MM:ss")}
                </Typography>
              </Form.Item>
            </Row>
          </Content>

          <Row
            className="row-res-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 0px" }}
          >
            <Divider
              className="divider-form"
              style={{ margin: "15px 0px", backgroundColor: "#EBEDF3" }}
            />

            <Button
              className="print-btn"
              icon={<PrinterOutlined />}
              type="primary"
              onClick={handlePrintOut}
              style={{
                marginRight: 30,
              }}
            >
              {`Save & Download`}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              loading={isLoad}
              hidden={is_saved == true ? true : false}
              className="submit-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

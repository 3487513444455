// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Row, Table, Tag, Tooltip } from "antd";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  SyncOutlined,
} from "@ant-design/icons";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// GLOBAL API
import {
  id_warehouse,
  is_store_admin,
  token,
  user_level,
} from "../../../../../../API/Global/Payload";
import axios from "axios";
import { baseurl } from "../../../../../../API/Config/Api";

// PAGE COMPONENTS
import StockTransferRequestModal from "../../../../../Modals/Inventory/StockTransferRequestApproval/Modal";

export default function StockTransferRequestApprovalTable(props) {
  // PROPS
  const { is_search, searchKey, posting_date } = props;

  // STATE
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataApprove, setDataApprove] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, posting_date]);

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    let bodyReq = {};

    if (user_level > 2) {
      bodyReq = {
        id_warehouse: id_warehouse,
        user_level: user_level,
        // is_warehouse_admin: true,
      };
    }

    await axios
      .post(`${baseurl}/approval/transfer-request`, bodyReq, {
        headers: { Authorization: `Bearer ${token || ""}` },
      })
      .then((response) => {
        console.log("Approval T/R Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // HANDLE RETURN TO MENU INVENTORY
  const handleReturn = () => {
    const path = "/back-office/inventory";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryName = searchKey ? searchKey.toLowerCase() : null;
    const queryDate =
      posting_date != "Invalid date" && posting_date != null
        ? moment(posting_date).format("YYYY-MM-DD")
        : null;

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _docNumber = item?.document_number
          ? item?.document_number.toLowerCase()
          : null;
        const _postDate = moment(item?.created_date).format("YYYY-MM-DD");

        return (
          _docNumber.indexOf(queryName) !== -1 ||
          moment(_postDate).isSame(queryDate)
        );
      });

      // console.log("UpdateList => ", updatedList)
      setData(updatedList);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  // HANDLE OPEN
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setModalOpen(false);
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    fetchData();
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-btn" align="middle">
              <Col span={24} className="action-col">
                <Tooltip title="Approve Request" className="edit-tooltip">
                  <CheckCircleOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("DATA APPROVAL > ", record);

                      handleOpen();
                      setDataApprove(record);
                    }}
                    style={{
                      fontSize: 30,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Document Number",
      dataIndex: "document_number",
      key: "document_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.document_number.localeCompare(b.document_number),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.department_name.localeCompare(b.department_name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Type of Moving",
      dataIndex: "transfer_type",
      key: "transfer_type",
    },
    {
      title: "Posting Date",
      dataIndex: "created_date",
      key: "created_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
      render: (date) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.needed_date.localeCompare(b.needed_date),
      render: (date) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "From Store",
      dataIndex: "initial_warehouse",
      key: "initial_warehouse",
    },
    {
      title: "To Store",
      dataIndex: "destination_warehouse",
      key: "destination_warehouse",
    },

    {
      title: "Approval Status",
      dataIndex: "approved_list",
      key: "approved_list",
      width: 350,
      render: (approval) => {
        if (approval?.length > 0) {
          let _tags = approval.map((value, index) => {
            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {`Approve ${index + 1}`}
                </Tag>
              );
            } else if (value === 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`Rejected ${index + 1}`}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
        }
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approved_date",
      key: "approved_date",
      width: 250,
      render: (date, record) => {
        if (date?.length > 0) {
          let _tags = date.map((value, index) => {
            if (value !== "-") {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {moment(value).format("DD-MM-YYYY, h:mm:ss a")}
                </Tag>
              );
            } else {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`-`}
                </Tag>
              );
            }
          });

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`-`}</Tag>;
        }
      },
    },
  ];

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              {/* <Row justify="start">
              <Button
                className="export-btn"
                type="primary"
                onClick={handleExport}
                // onClick={handleExport}
                icon={<FileExcelFilled />}
                style={{
                  marginRight: 15,
                }}
              >
                {`Download Excel`}
              </Button>
            </Row> */}
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="transfer-req-table"
              name="transfer-req-table"
              key="transfer-req-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record.id_warehouse_transfer
                  ? record.id_warehouse_transfer
                  : record.document_number;
              }}
            />
          </Row>
        </Col>
      </Row>

      <StockTransferRequestModal
        is_open={modalOpen}
        is_close={handleClose}
        dataApproval={dataApprove}
        is_refresh={handleRefresh}
      />
    </>
  );
}

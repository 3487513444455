// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterServeQue({ getServeQue, servingQue }) {
  // CONTEXT
  const { getMasterTable } = useContext(Context);

  // STATE MANAGEMENT
  // Data
  const [serveQue, setServeQue] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterTable({
      outlet: "master",
      type: "serving-order",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Serve Que: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleFilter(_res);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log("Error List: ", error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (servingQue && !cleanUp) {
      setServeQue(servingQue);
    }

    return () => {
      cleanUp = true;
    };
  }, [servingQue]);

  // Handle Filter
  const handleFilter = (value) => {
    const _data = value;

    let _mapped = _data.map((val) => ({
      label: val?.status_code,
      value: val?.status_id,
      code: val?.status_code,
      id: val?.id,
    }));

    // console.log("Map results: ", _mapped);
    setOptionValue(_mapped);
  };

  // Handle Change
  const handleChange = (val, code) => {
    const _value = val;
    const _allKeys = code;

    // console.log("Values: ", _value);
    // console.log("All Keys: ", _allKeys);

    setServeQue(_value);
    getServeQue({
      value: _value,
      id: _allKeys?.id,
      // key: _allKeys?.key,
      label: _allKeys?.label,
      code: _allKeys?.code,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setServeQue(null);
  };

  return (
    <>
      <Select
        className="outlet-loc-select"
        key="outlet-loc-select"
        placeholder="Choose Serving Que"
        allowClear
        showSearch
        value={serveQue}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

// Import React Components
import React, { useState } from "react";
import moment from "moment";

// Import Ant Design Components
import {
  Col,
  Row,
  Layout,
  Divider,
  Input,
  DatePicker,
  Typography,
  Button,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { SearchOutlined } from "@ant-design/icons";
import { FaPlaneArrival } from "react-icons/fa";

// Import Page Components
import ExpectedArrivalTable from "../../../Components/Table/FrontOffice/ExpectedArrival/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function ExpectedArrivalPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState("");
  const [date, setDate] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  // HANDLE DATE
  const handleDate = (value) => {
    // e.preventDefault();
    setDate(value);

    // console.log("HANDLE Date = ", value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="expected-arrival-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaPlaneArrival
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Arrival Guest List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                className="col-search-comp"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Reservation Name`}
                </Typography>

                <Input
                  allowClear
                  placeholder="Guest's Name"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  className="text-search"
                  style={{ marginBottom: 10 }}
                >
                  {`Arrival Date`}
                </Typography>

                <Input.Group>
                  <DatePicker
                    className="check-in-date-picker"
                    onChange={handleDate}
                    style={{ width: 205 }}
                  />
                </Input.Group>
              </Col>

              <Col
                className="col-search-btn"
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <ExpectedArrivalTable
            is_search={setSearch}
            searchKey={value}
            searchDate={date}
          />
        </Col>
      </Content>
    </Layout>
  );
}

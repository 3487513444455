// Import Reacts
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";
import { Select } from "antd";

// CODE
export default function MasterWarehouseAdmin(props) {
  // PROPS
  const {
    selectedItem,
    warehouse_name,
    is_disable,
    is_multiple,
    store_id,
    department_id,
  } = props;

  // CONTEXT
  const { getWarehouseList } = useContext(MainContext);

  // STATE
  // Bulks
  const [bulks, setBulks] = useState([]);
  const [optionValue, setOptionValue] = useState([]);
  const [warehouse, setWarehouse] = useState(is_multiple === true ? [] : null);
  const [loading, setLoading] = useState(false);

  // FETCH WAREHOUSE LIST
  const fetchData = async () => {
    await getWarehouseList({
      type: "warehouse-admin",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Wh with users: ", response);

        if (response?.data?.length > 0) {
          const _res = response.data;

          const filterData = _res.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          handleFilter(filterData);
        } else {
          setOptionValue([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Err: ", error);
        setLoading(false);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Warehouse: ", store_id);
    console.log("Dept ID: ", department_id);

    if (bulks.length > 0 && store_id?.length > 0 && !cleanUp) {
      handleName();
    }

    if (bulks.length > 0 && department_id && !cleanUp) {
      handleOption({
        bulk: bulks,
        department_id: department_id,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [warehouse_name, store_id, bulks, department_id]);

  // Handle Filter
  const handleFilter = (value) => {
    const _data = value;

    // let data = {
    //   id: 1,
    //   id_warehouse: "8c8944fb-9448-4bfd-b46c-222708365204",
    //   warehouse_name: "GUDANG 2",
    //   warehouse_location: "Bandung",
    //   description: "SEMUA BARANG",
    //   warehouse_code: "GD2",
    //   chart_of_account: "120-01-100",
    //   is_kitchen: false,
    //   id_department: 1,
    //   email_user: ["syahrul.moch@gmail.com"],
    //   id_user: ["593bd6f2-6dee-4fc6-8d3c-130c161181b1"],
    //   dept_id: [41],
    //   dept_uuid: ["d45579c1-5f4c-4cfe-8888-02a277e516a9"],
    //   user_name: ["syahrul"],
    //   hotel_id: null,
    // };

    const filter = _data.map((item) => {
      return {
        key: item.id,
        label: item.warehouse_name,
        value: item.warehouse_name,
        id_warehouse: item.id_warehouse,
        warehouse_name: item.warehouse_name,
        user_list: item?.id_user,
        dept_list: item?.dept_id,
      };
    });

    setBulks(filter);
  };

  // Handle Options
  const handleOption = (value) => {
    const _filter = bulks.map((items) => {
      if (items.dept_list[0] == null) {
        return {
          disabled: false,
          ...items,
        };
      } else if (items.dept_list.includes(department_id)) {
        return {
          disabled: false,
          ...items,
        };
      } else {
        return { disabled: true, ...items };
      }
    });

    console.log("Filter by Dept: ", _filter);

    setOptionValue(_filter);
  };

  // Handle Name
  const handleName = () => {
    const _setter = bulks.filter((items) => {
      let _id = items?.id_warehouse;

      return store_id.includes(_id);
    });

    console.log("Store selected: ", _setter);

    setWarehouse(_setter);
  };

  // Handle Change
  const handleChange = (e, i) => {
    const _value = e;
    const _allKey = i;

    console.log("Allkeys: ", _allKey);

    setWarehouse(_value);

    if (is_multiple) {
      selectedItem(
        _allKey.map((val) => {
          return val?.id_warehouse;
        })
      );
    } else {
      selectedItem({
        id_warehouse: _allKey?.id_warehouse,
        warehouse_name: _allKey?.warehouse_name,
      });
    }
  };

  // Handle Clear
  const handleClear = () => {
    setWarehouse(is_multiple === true ? [] : null);
  };

  return (
    <>
      <Select
        className="master-warehouse"
        key={"warehouse-"}
        value={warehouse}
        placeholder="Store Name"
        showSearch
        allowClear
        mode={is_multiple ? "multiple" : null}
        disabled={is_disable}
        loading={loading}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{ width: "100%" }}
      />
    </>
  );
}

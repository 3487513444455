// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterCurrency({ getCurrency, pay, disable }) {
  // CONTEXT
  const { getMasterCurrency } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [currency, setCurrency] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterCurrency({
      currency: "currency",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Currency => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev.currency,
            label: dev.unit_text,
            symbol: dev.symbol,
            id: dev?.id,
          }));

          console.log(option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  const handleClear = () => {
    setCurrency(null);
  };

  //   console.log(first);

  return (
    <>
      <Select
        key={"currency-select"}
        className={"currency-select"}
        value={currency ? currency : pay}
        showSearch
        allowClear
        disabled={disable}
        onClear={handleClear}
        onChange={(e, allKeys) => {
          setCurrency(e);
          console.log("Currency: ", e, allKeys?.symbol);

          getCurrency(e, allKeys?.symbol, allKeys?.id);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
        style={{ width: "100%" }}
      />
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// Import Ant Design's Components
import { Select } from "antd";

// CODE
export default function MasterRoles(props) {
  // PROPERTIES
  const { getRole, roles, is_disable } = props;

  // STATE MANAGEMENT
  const [roleName, setRoleName] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/auth/user-roles`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _filtered = _res.filter((val) => {
            return !val?.is_deleted;
          });

          handleMap(_filtered);
        } else {
          setOptionValue([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
      });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (roles && optionValue.length > 0 && !cleanUp) {
      setIsLoading(true);
      handleEdit(roles, optionValue);
    } else {
      setRoleName(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [roles, optionValue]);

  // Handle Map
  const handleMap = (val) => {
    const _filtered = val;

    // console.log(_filtered);

    let optionFilter = _filtered.map((value) => ({
      id: value?.id,
      key: value?.id,
      value: value?.description,
      label: value?.description,
      role: value?.roles,
      level: value?.permision_level,
    }));

    setOptionValue(optionFilter);
    setRoleName(null);
  };

  // Handle Change
  const handleChange = (val, all) => {
    const _value = val;
    const _allKeys = all;

    console.log("INI CODE ", _allKeys);

    setRoleName(_value);
    getRole({
      value: _allKeys?.value,
      id: _allKeys?.id,
      role: _allKeys?.role,
      level: _allKeys?.level,
    });
  };

  // Handle Edit
  const handleEdit = (names, allKey) => {
    let _names = allKey.filter((val) => {
      return val?.role === names;
    });

    setRoleName(_names);

    setIsLoading(false);
  };

  // Handle Change
  const handleClear = () => {
    setRoleName(null);
  };

  return (
    <>
      <Select
        className="roles-select"
        key="roles-select"
        allowClear
        showSearch
        disabled={is_disable}
        value={roleName}
        placeholder="Select Roles"
        loading={isLoading}
        bordered
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

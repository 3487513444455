// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_module } from "../../API/Global/Payload";

// Import Page Components
import BudgetMainPage from "../../Pages/BackOffice/Budget/Main/BudgetMain";
import BudgetPage from "../../Pages/BackOffice/Budget/List/Budget";
import ApprovalBudgetPage from "../../Pages/BackOffice/Budget/Approval/Approval";
import PurchasingRoutes from "./Purchasing/Routes";
import AccountReceivablesRoutes from "./AR/Routes";
import InventoryRoutes from "./Inventory/Routes";
import AccountingRoutes from "../BackOffice/Accounting/Routes";

import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";
import NotFound from "../../Pages/NotFound/NotFound";
import AccountPayableRoutes from "./AP/Routes";

// SUB ROUTES
const subRoute = [
  {
    path: "budget",
    name: "budget",
    id_modules: 25,
    element: <BudgetMainPage />,
  },
  {
    path: "budget/budget-list",
    name: "budget",
    id_modules: 25,
    element: <BudgetPage />,
  },
  {
    path: "budget/budget-approval",
    name: "budget",
    id_modules: 25,
    element: <ApprovalBudgetPage />,
  },
  {
    path: "accounting/*",
    name: "budget",
    id_modules: 4,
    element: <AccountingRoutes />,
  },
  {
    path: "account-receivable/*",
    name: "account-receivable",
    id_modules: 26,
    element: <AccountReceivablesRoutes />,
  },
  {
    path: "account-payable/*",
    name: "account-payable",
    id_modules: 107,
    element: <AccountPayableRoutes />,
  },
  {
    path: "purchasing/*",
    name: "purchasing",
    id_modules: 27,
    element: <PurchasingRoutes />,
  },
  {
    path: "inventory/*",
    name: "inventory",
    id_modules: 28,
    element: <InventoryRoutes />,
  },
];

// CODE
export default function BackOfficeRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_module?.length > 0 ? sub_module : []; // values.module;

    const proRoute = subRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="*" element={<UnAuthorized />} />

        {protectedRoutes}
      </Routes>
      <Outlet />
    </>
  );
}

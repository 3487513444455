// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
// import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as InventoryContext } from "../../../../../../API/Context/InventoryContext/InventoryContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaMoneyBillWave } from "react-icons/fa";

// Import Notification
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../../Style/Global/Global";

// Modal Form
const { confirm } = Modal;

// CODE
export default function DetailReceivingTable({
  searchKey,
  is_search,
  dataDetail,
}) {
  // Table Columns
  const columns = [
    /*
    {
      title: "Action",
      dataIndex: "action",
      width: 60,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-journal-header-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={24} className="col-details">
                <Tooltip title="See Detail" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      dataDetail(record.reservation_id);
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    */
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (_, e, idx) => {
        return idx + 1;
      },
    },
    // {
    //   title: "Article ID",
    //   dataIndex: "id_article",
    //   key: "id_article",
    //   render: (amount) => amount,
    // },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      width: 150,
      render: (amount) => amount,
    },
    {
      title: "To Warehouse/Store",
      dataIndex: "warehouse_name",
      key: "warehouse_name",
      editable: true,
      width: 150,
      render: (amount) => amount,
    },
    // {
    //   title: "Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   render: (date) => {
    //     return moment(date).format("DD-MM-YYYY");
    //   },
    // },
    // {
    //   title: "COA",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    //   //   render: (date) => {
    //   //     return moment(date).format("DD-MM-YYYY");
    //   //   },
    // },
    // {
    //   title: "Unit",
    //   dataIndex: "unit",
    //   key: "unit",
    // },
    {
      title: "Received Qty",
      dataIndex: "quantity_received",
      key: "quantity_received",
      editable: true,
      width: 100,
      render: (amount) => amount,
    },
    {
      title: "Order Qty",
      dataIndex: "quantity_request",
      key: "quantity_request",
      width: 100,
      render: (amount) => amount,
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "unit_price",
      editable: true,
      render: (amount) => {
        return `Rp. ${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => {
        return `Rp. ${
          record.price *
          (record.quantity_received != (null || undefined)
            ? record.quantity_received
            : 0)
        }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "D-Unit",
      dataIndex: "d-unit",
      key: "d-unit",
      render: (amount) => amount,
    },
    {
      title: "S-Unit",
      dataIndex: "s_unit",
      key: "s_unit",
      render: (amount, record) =>
        data[0].is_draft == true
          ? "-"
          : record.quantity_request - record.si_quantity_remaining,
    },
  ];

  // CONTEXT
  const { getReceivingHead } = useContext(InventoryContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [selectData, setSelectData] = useState(null);
  // ID
  const [editData, setEditData] = useState(null);
  const [roomCode, setRoomCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getReceivingHead({
      type: "/receiving",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Good Receipt Detail Data => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            // if (filt.journal_type === 4) {
            return !filt.is_deleted && filt.details;
            // }
          });

          setData(filtered);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    let cleanUp = false;

    if (dataDetail && !cleanUp) {
      console.log("Details: ", dataDetail);

      if (dataDetail && !cleanUp) {
        setData(dataDetail);
        // fetchData(dataDetail);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail]);

  // Use Effect Search
  // useEffect(() => {
  //   // is_search(() => handleSearch);
  // }, [searchKey]);

  // Handle Row Selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      // setRateCode(selectedRows);
      // getRateCode(selectedRows);
      dataDetail(
        // selectedRows?.length > 0 ? selectedRows[0].bill_alias : []
        selectedRows?.length > 0 ? selectedRows[0] : []
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    form.resetFields();
    setEditData(null);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let del = record;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete room ${record.room_category_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log(value);
    let content = value;

    handleSubmit(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
    let contentSubmit = e;

    // if (edit == false) {
    //   axios
    //     .post(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       created_by: "DEVELOPER",
    //       created_date: moment().format("YYYY-MM-DD"),
    //       // updated_by: "17",
    //       // deleted_by: "NULL",
    //       // is_deleted: null,
    //       // deleted_date: null,
    //       // updated_date: null,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       successRoomCat(response, { method: 0 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       failedRoomCat(error, { method: 0 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // } else {
    //   axios
    //     .put(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       id: editData.id,
    //       created_by: editData.created_by,
    //       created_date: editData.created_date,
    //       updated_by: "DEVELOPER",
    //       updated_date: moment().format("YYYY-MM-DD"),
    //       // is_deleted: false,
    //       // created_by: "DEVELOPER",
    //       // chart_of_account: contentsubmit.chart_of_account,
    //       // departement_id: contentsubmit.departement_id,
    //       // is_transaction: "",
    //       // liquiditas: "",
    //     })
    //     .then((response) => {
    //       console.log("Add Resp => ", response);
    //       successRoomCat(response, { method: 1 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log("Error Add => ", error);
    //       failedRoomCat(error, { method: 1 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // }
  };

  // HANDLE DELETE
  const handleDelete = (record) => {
    const contentdelete = record.id;

    console.log("contentDelete >>>>>>", contentdelete);

    // var obj = {
    //   ["id"]: contentdelete,
    //   ["deleted_by"]: "DEVELOPER",
    // };

    // axios
    //   .delete(`${baseurl}/room/category`, {
    //     data: obj,
    //   })
    //   .then((response) => {
    //     console.log("Del Room Cat => ", response);
    //     successRoomCat(response, { method: 2 });
    //   })
    //   .catch((error) => {
    //     failedRoomCat(error, { method: 2 });
    //     console.log("Del Room => ", error);
    //   })
    //   .finally(() => {
    //     fetchData();
    //   });
  };

  // HANDLE SEARCH
  //   const handleSearch = () => {
  //     const query = searchKey;
  //     // console.log("Type = ", query);

  //     var updatedList = [...data];
  //     // console.log("Update List = ", updatedList);

  //     updatedList = updatedList.filter((item) => {
  //       // console.log("ITEM => ", item)
  //       return (
  //         item.room_category_name.toLowerCase().indexOf(query.toLowerCase()) !==
  //         -1
  //       );
  //     });
  //     // Trigger render with updated values
  //     setData(updatedList);
  //     // console.log("FILTERED => ", updatedList);
  //   };

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            className="row-table-btn"
            justify="start"
            style={{ marginBottom: "10px" }}
          >
            <Col className="col-text" span={24}>
              <Typography
                className="text-journal-header"
                style={{
                  fontSize: 15,
                  fontWeight: 750,
                }}
              >
                {`Stock Incoming Details`}
              </Typography>
            </Col>
          </Row>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={24}>
              <Typography
                className="text-journal-header"
                style={{
                  fontSize: 12,
                  // fontWeight: 750,
                }}
              >
                {`Doc No.:  ${data[0]?.document_number || " --- "}`}
              </Typography>
            </Col>
          </Row>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={24}>
              <Typography
                className="text-journal-header"
                style={{
                  fontSize: 12,
                  // fontWeight: 750,
                }}
              >
                {`Receipt/Arrival Date:  ${
                  data[0]?.details[0]?.received_date || " --- "
                }`}
              </Typography>
            </Col>
          </Row>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={24}>
              <Typography
                className="text-journal-header"
                style={{
                  fontSize: 12,
                  // fontWeight: 750,
                }}
              >
                {`Receipt By:  ${data[0]?.details[0]?.received_by || " --- "}`}
              </Typography>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="header-table"
              name="header-table"
              key="header-table"
              columns={columns}
              dataSource={data[0]?.details}
              bordered
              loading={loading}
              size="middle"
              // rowSelection={{
              //   type: "radio",
              //   ...rowSelection,
              // }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1200,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) => (record.id_sid ? record.id_sid : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterSexType({ getSexType, sex }) {
  // CONTEXT
  const { getMasterSex } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [sexType, setSexType] = useState([]);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = () => {
    getMasterSex({
      sex: "sex",
      onAwait: () => {},
      onSuccess: (response) => {
        //console.log("Response => ", response);

        let option = response.data.msg.map((dev) => ({
          value: dev.sex,
          label: dev.sex,
        }));

        setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // setSexType(selectedSexType)
  }, []);

  //   console.log(first);

  return (
    <>
      <Select
        key={optionValue.sex}
        style={{ width: "100%" }}
        value={sex ? sex : sexType}
        onChange={(e) => {
          setSexType(e);
          console.log(e);

          getSexType(e);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
      />
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";

// AUTH
import { roles, token } from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import React Icons
import { DeleteFilled } from "@ant-design/icons";
import { Modal, Row, Table, Tooltip } from "antd";

// Import
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import {
  successArticleMice,
  failedArticleMice,
} from "../../../Reusable/Notification/Notification";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function MiceArticleTable(props) {
  const {
    article,
    newArticle,
    editArticle,
    is_loading,
    is_edited,
    is_cashier,
    is_refresh,
    is_deleted,
    is_reset,
  } = props;

  // PARAMS
  const currency = CurrencySymbols().code;

  // Table's Column
  const columnsCashier = [
    {
      title: "No.",
      dataIndex: "",
      key: "",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article",
      dataIndex: "article_name",
      key: "article_name",
      width: 250,
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price, record) => {
        return `${unit_price > 0 ? unit_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
    },
    {
      title: `Total ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      render: (total, record) => {
        // const total_price = parseInt(record.qty) * parseInt(record.unit_price);

        return `${total > 0 ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      // fixed: "left",
      align: "center",
      width: 75,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Tooltip title="Delete Article" className="del-tooltip">
                <DeleteFilled
                  className="delete-btn"
                  onClick={() => {
                    // console.log("ABOUT TO DEL > ", record);
                    //   setOpen(true);
                    //   setEdit(true);
                    //   setId(record.id);
                    showDeleteModal(record);
                  }}
                  style={{ fontSize: 20, color: "#F64E60" }}
                />
              </Tooltip>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article",
      dataIndex: "article_name",
      key: "article_name",
      width: 250,
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price, record) => {
        return `${unit_price > 0 ? unit_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
    },
    {
      title: `Total ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      render: (total, record) => {
        // const total_price = parseInt(record.qty) * parseInt(record.unit_price);

        return `${total > 0 ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // Columns
  const [column, setColumn] = useState(columns);
  // Select
  const [select, setSelect] = useState([]);

  // ROW SELECTION
  const handleSelection = {
    columnTitle: "Edit",
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);

      setDataEdit(selectedRows[0]);
      editArticle(selectedRows[0]);
      is_edited(true);
      // is_reset([]);
      setSelect(selectedRowKeys);
      // console.log(dataEdit);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // USE EFFECT
  useEffect(() => {
    // editArticle(dataEdit);
    let cleanUp = false;

    if (article?.length > 0 && !cleanUp) {
      console.log("Data Article --> ", article);
      setData(article);

      if (is_cashier == true) {
        setColumn(columnsCashier);
      } else {
        setColumn(columns);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [article]);

  // USE EFFECT SELECT
  useEffect(() => {
    setSelect([]);
  }, [is_reset]);

  // DELETE MODAL
  const showDeleteModal = (del) => {
    const contentDelete = del;

    console.log("INSIDE DELETE MODAL = ", contentDelete);

    confirm({
      className: "delete-mice-confirm",
      title: `Are you sure want to Delete Article ${
        contentDelete?.article_name ? contentDelete.article_name : " ---- "
      } of Reservation ${
        contentDelete?.reservation_id ? contentDelete.reservation_id : " ---- "
      }?`,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(contentDelete);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
        zIndex: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (value) => {
    const contentDelete = value;

    console.log("ABOUT TO DELETE ", contentDelete);

    let obj = {
      ["reservation_id"]: contentDelete?.reservation_id,
      ["id"]: contentDelete?.id,
      ["deleted_by"]: roles,
    };

    if (contentDelete.id != null) {
      await axios
        .delete(`${baseurl}/fnb/mice/article`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("DEL NONSTAY >> ", response);

          successArticleMice(response, { method: 2 });
          is_refresh(true);
        })
        .catch((error) => {
          console.log("DEL NONSTAY >> ", error);

          failedArticleMice(error, { method: 2 });
        });
    } else {
      let newData = data.filter((e) => {
        return e.key != contentDelete.key;
      });

      setData(newData);
      is_deleted(newData);
    }
  };

  // console.log("PROPS ADD ARTICLE => ", article);
  // console.log("Article table => ", data);

  return (
    <>
      <Table
        className="article-mice-table"
        name="article-mice-table"
        key="article-mice-table"
        columns={column}
        dataSource={data}
        loading={is_loading}
        bordered={true}
        rowSelection={
          is_cashier == false
            ? {
                type: "radio",
                ...handleSelection,
              }
            : false
        }
        size="small"
        pagination={{
          defaultPageSize: 5,
          // pageSizeOptions: [10, 20, 50, 100],
          // showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} entries`,
        }}
        scroll={{
          // x: 800,
          y: 500,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? "odd" : "even";
        }}
        rowKey={(record) => (record.id ? record.id : record.key)}
      />
    </>
  );
}

// Import React Components
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  InputNumber,
  Layout,
  Menu,
  Row,
  Typography,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { MdInbox } from "react-icons/md";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import HeaderReceivingTable from "../../../../Components/Table/BackOffice/Inventory/Receiving/Headers/Table";
import DetailReceivingTable from "../../../../Components/Table/BackOffice/Inventory/Receiving/Details/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

export default function ReceivingPage() {
  // Navigate
  const navigate = useNavigate();
  // STATE MANAGEMENT
  const [docNo, setDocNo] = useState("");
  const [PONo, setPONo] = useState("");
  const [invNo, setInvNo] = useState("");
  // Search
  const [search, setSearch] = useState();
  // Rate
  const [dataDetail, setDataDetail] = useState();

  // HANDLE SEARCH
  const handleSearchDoc = (e) => {
    e.preventDefault();
    setDocNo(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };
  const handleSearchPO = (e) => {
    e.preventDefault();
    setPONo(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };
  const handleSearchInv = (e) => {
    e.preventDefault();
    setInvNo(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  // Handle Head
  const handleHeader = (value) => {
    console.log(value);

    setDataDetail(value);
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/back-office/inventory";

    navigate(`${path}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <MdInbox
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Stock Incoming`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Document No.`}</Typography>

                  <Input
                    allowClear
                    onChange={handleSearchDoc}
                    placeholder="Document No."
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`PO No.`}</Typography>

                  <Input
                    allowClear
                    onChange={handleSearchPO}
                    placeholder="PO No."
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Invoice No.`}</Typography>

                  <Input
                    allowClear
                    onChange={handleSearchInv}
                    placeholder="Invoice No."
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={search}
                  >
                    {`Search`}
                  </Button>
                </Col>
                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleReturn}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Return`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <Row gutter={[30, 30]} className="row-rate-table">
              <Col
                className="col-header"
                // span={12}
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <HeaderReceivingTable
                  dataDetail={handleHeader}
                  is_search={setSearch}
                  searchDocNo={docNo}
                  searchPONo={PONo}
                  searchInvNo={invNo}
                />
              </Col>

              <Col
                // span={12}
                className="col-details"
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <DetailReceivingTable dataDetail={dataDetail} />
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

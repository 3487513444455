// REACT COMPONENTS
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Select, Space, Typography, DatePicker, Form, } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaRegFileLines } from "react-icons/fa6";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import WarehouseList from "../../../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";
import MasterArticleMain from "../../../../../../Components/Reusable/Master/ArticleMain/ArticleMain";
import RequisitionSummaryReportTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/OutgoingStock/RequisitionSummary/Table";

export default function RequisitionSummaryReportPage() {
  // STATE
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [articleMain, setArticleMain] = useState(null);
  const [fromStorage, setFromStorage] = useState(null);
  const [toStorage, setToStorage] = useState(null);
  const [filterElement, setFilterElement] = useState(null);
  const [element, setElement] = useState(null);
  const [element2, setElement2] = useState(null);

  // USE FORM
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/outgoing-stock/";

    navigate(`${path}`);
  };

  const handleDate = (value) => {
    console.log("handleDate value", value);
    if(value){
      setStartDate(value[0]);
      setEndDate(value[1]);
      setFilterElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        <Typography style={{ marginBottom: 10 }}>
          {`Filter By`}
        </Typography>
        <Form name="filter">
          <Select
            className="type-select"
            key="type-select"
            allowClear
            showSearch
            // value={filterValue}
            onChange={handleFilter}
            options={options}
            placeholder="Filter By"
            style={{
              width: 200,
            }}
          />
        </Form>
      </Col>)
    } else {
      setStartDate(null);
      setEndDate(null);
      setFilterElement(null);
      setFilterValue(null);
      setElement(null);
      setArticleMain(null);
      setFromStorage(null);
      setElement2(null);
      setToStorage(null);
    }
  };

  // HANDLE FILTER
  const handleFilter = (value) => {
    if (value == 1) {
      setFilterValue(value);
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
          }}
        >
          <Typography
            style={{ marginBottom: 10 }}
          >{`Article Main Group`}</Typography>

          <MasterArticleMain
            getArticleMain={handleSelectedArticleMain}
            mainName={null}
            isDisabled={false}
          />
        </Col>
      );
      setElement2(null);
      setToStorage(null);
      setFromStorage(null);
    } else if (value == 2) {
      setFilterValue(value);
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 275,
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`From Storage`}</Typography>

          <WarehouseList
            selectedItem={handleSelectedFromStorage}
            warehouse_name={null}
            is_disable={false}
            />
        </Col>
      );
      setArticleMain(null);
    } else {
      setFilterValue(null);
      setElement(null);
      setFromStorage(null);
      setArticleMain(null);
      setElement2(null);
      setToStorage(null);
    }
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticleMain = (value) => {
    if(value.main_id){
      setArticleMain(value);
    } else {
      setArticleMain(null);
    };
  };

  // HANDLE SELECTED STORAGE
  const handleSelectedFromStorage = (value) => {
    if(value.id_warehouse){
      setFromStorage(value);
      setElement2(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 270,
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`To Storage`}</Typography>

          <WarehouseList
            selectedItem={handleSelectedToStorage}
            warehouse_name={null}
            is_disable={false}
            />
        </Col>
      );
    } else {
      setFromStorage(null);
      setElement2(null);
      setToStorage(null);
    };
  };

  const handleSelectedToStorage = (value) => {
    if(value.id_warehouse){
      setToStorage(value);
    } else {
      setToStorage(null);
    };
  };

  const options = [
    {
      label: "Article Main Group",
      value: 1,
    },
    {
      label: "Storage",
      value: 2,
    },
  ];

  return (
    <Layout>
      <Content>
        <Col span={24} className="incoming-stock-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaRegFileLines
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Requisition Summary Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Date`}
                </Typography>

                <RangePicker
                  allowClear
                  // placeholder="Date"
                  className="date"
                  format="YYYY-MM-DD"
                  onChange={handleDate}
                  style={{
                    minWidth: 150,
                    maxWidth: 250,
                  }}
                />
              </Col>

              {filterElement}

              {element}

              {element2}

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {(startDate && endDate && filterValue && articleMain) || (startDate && endDate && filterValue && fromStorage != null && toStorage != null) ? (
            <RequisitionSummaryReportTable
              _startDate={startDate}
              _endDate={endDate}
              _type={filterValue}
              _main={articleMain}
              _fromStorage={fromStorage}
              _toStorage={toStorage}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

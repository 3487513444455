// CONTEXT
import CreateContext from "../CreateContext";

// BASE URL API
import baseAPI from "../../Config/Api";

// REDUCERS
const financeReducer = (state, action) => {
  if ((action.type = "getData")) {
    return {
      ...state,
      data: action.payload,
    };
  }
};

// ACCOUNTING
// General Context
const getAccounting =
  (dispatch) =>
  async ({ type, date, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

const getByPostAccounting =
  (dispatch) =>
  async ({ type, date, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

const getPurchasing =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/purchasing" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Main Account (COA)
const getMainAccount =
  (dispatch) =>
  async ({ mainAccount, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + mainAccount;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Bill Headers
const getBillHeader =
  (dispatch) =>
  async ({ billHeader, onAwait, onSuccess, onReject }) => {
    let query = "/bill" + "/" + billHeader;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Bill Details
const getBillDetail =
  (dispatch) =>
  async ({ billDetail, res_id, onAwait, onSuccess, onReject }) => {
    let query = "/bill" + "/" + billDetail;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { res_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Master Account Type
const masterAccountType =
  (dispatch) =>
  async ({ account, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + account;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Master Financial Statement
const masterFinancialState =
  (dispatch) =>
  async ({ account, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + account;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// JOURNALS
// Get Journal Headers
const getJournalHead =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/journal" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Journal Details
const getJournalDetails =
  (dispatch) =>
  async ({ type, bill_as, journal_as, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/journal" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { bill_as, journal_as })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Balance Sheet
const getBalanceSheet =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Trial Balance
const getTrialBalance =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Initial Table
const getInitialTable =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Daily Sales Report (DSR)
// Get DSR
const getDSR =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/accounting/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get DSR HISTORY
const getDailyHistory =
  (dispatch) =>
  async ({ type, date, onAwait, onSuccess, onReject }) => {
    let query = "/accounting/history/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Budget Back Office
const getBudget =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Budget Back Office
const getLedger =
  (dispatch) =>
  async ({ type, master_comp_id, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { master_comp_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET A/R
const getARList =
  (dispatch) =>
  async ({ type, coa, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { coa })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET A/R Payment List
const getARPayment =
  (dispatch) =>
  async ({ type, start_date, end_date, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { start_date, end_date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET A/P
const getAPList =
  (dispatch) =>
  async ({ type, coa, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { coa })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET A/P Payment List
const getAPPayment =
  (dispatch) =>
  async ({ type, start_date, end_date, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { start_date, end_date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET Journal Register
const getJournalRegister =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      // .post(query, { coa })
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET by POST Journal Register
const getByPostJournalRegister =
  (dispatch) =>
  async ({ type, id, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET by POST CLOSING PERIOD
const getByPostClosingPeriod =
  (dispatch) =>
  async ({ type, date, onAwait, onSuccess, onReject }) => {
    let query = "/accounting" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// EXPORT CONTEXT and PROVIDER
export const { Provider, Context } = CreateContext(
  financeReducer,
  {
    // MAIN
    getAccounting,
    getByPostAccounting,

    // Purchasing
    getPurchasing,

    // A/R
    getARList,
    getARPayment,

    // A/P
    getAPList,
    getAPPayment,

    // Accounting
    getBillHeader,
    getBillDetail,
    getMainAccount,

    // Master Finance
    masterAccountType,
    masterFinancialState,

    // JOURNALS
    getJournalHead,
    getJournalDetails,
    getJournalRegister,
    getByPostJournalRegister,

    // BALANCE SHEET
    getBalanceSheet,
    getTrialBalance,

    // INITIAL ACCOUNTING
    getInitialTable,

    // REPORTS
    getDSR,
    getDailyHistory,

    // BUDGET
    getBudget,

    // LEDGERS
    getLedger,

    // CLOSING
    getByPostClosingPeriod,
  },
  { data: [] }
);

// Import React's Component
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

// BASE API
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Button, Col, Form, Modal, Row, Table, Tag } from "antd";

// Import React Icons
import { SyncOutlined } from "@ant-design/icons/lib/icons";

// Import Notifications
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";
import {
  failedFetch,
  failedRoomStatus,
  successRoomStatus,
} from "../../../Reusable/Notification/Notification";

// Import Master Components
// Modal Confirm
const { confirm } = Modal;

// CODE
export default function RoomQueueTable({ is_refresh, searchKey }) {
  // Table's Column
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   fixed: "left",
    //   width: 200,
    //   render: (_, record) => (
    //     <Tooltip title="Change Status Room" className="status-tooltip">
    //       <Button
    //         type="primary"
    //         className="edit-btn"
    //         icon={<FormOutlined />}
    //         style={{
    //           backgroundColor: "#13c2c2",
    //           borderColor: "#13c2c2",
    //           // marginRight: 15,
    //         }}
    //         onClick={() => {
    //           setOpen(!open);
    //           setDataStatus(record);

    //           form.setFieldsValue({
    //             room_number: record.room_number,
    //             room_status_id: record.room_status_id,
    //           });
    //         }}
    //       >
    //         Change Status
    //       </Button>
    //     </Tooltip>
    //   ),
    // },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      //   fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
      //   fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Guest",
      dataIndex: "guest_name",
      key: "guest_name",
    },
    {
      title: "Room Status",
      dataIndex: "room_status_id",
      key: "room_status_id",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_status_id.localeCompare(b.room_status_id),
      render: (room_status, record) => {
        let status = "";
        let clr = "";

        switch (room_status) {
          case 1:
            status = "Vacant Clean Unchecked";
            clr = "#1890ff";
            break;
          case 2:
            status = "Vacant Clean Checked";
            clr = "#87d068";
            break;
          case 3:
            status = "Occupied Dirty";
            clr = "#f5222d";
            break;
          case 4:
            status = "Occupied Clean";
            clr = "#13c2c2";
            break;
          case 5:
            status = "Vacant Dirty";
            clr = "#fa8c16";
            break;
          case 6:
            status = "Out of Order";
            clr = "black";
            break;
          case 7:
            status = "Out of Service";
            clr = "brown";
            break;

          default:
            break;
        }

        return (
          <Tag color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Arrival Date",
      dataIndex: "arrival",
      key: "arrival",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
  ];

  // CONTEXT
  const { state, getRoomQueue } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [guest, setGuest] = useState([]);
  // Data Status
  const [dataStatus, setDataStatus] = useState(null);
  const [status, setStatus] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getRoomQueue({
      roomQueue: "housekeeping",
      type: "room-status-id",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          handleKeys(response.data.msg);
        }
        // setData(response.data.msg);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // GET STATUS
  const getStatus = (value) => {
    setStatus(value);

    form.setFieldsValue({
      room_status_id: value,
    });
  };

  // HANDLE KEYS
  const handleKeys = (value) => {
    let _arr = value;
    let id = 1;

    let _newData = _arr.map((val) => {
      return { ...val, id: id++ };
    });

    console.log("DATA with Keys: ", _newData);
    setData(_newData);
    setLoading(false);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    const dataModal = val;

    confirm({
      // icon: <ExclamationCircleTwoTone />,
      className: "modal-confirm",
      title: `Are you sure want to change Room Status of Room Number ${dataModal?.room_number}?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(dataModal);
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log("ON FINISH >>> ", value);

    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("ON FINISH FAILED >>> ", error);

    masterIncomplete(error);
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(!open);
    setStatus(null);
    setDataStatus(null);

    form.resetFields();
  };

  // HADNLE SUBMIT
  const handleSubmit = (value) => {
    const contentSubmit = value;

    console.log("Submit >>> ", value);

    axios
      .put(`${baseurl}/room/status`, {
        room_status_id: contentSubmit?.room_status_id
          ? contentSubmit.room_status_id
          : dataStatus.room_status_id,
        occupancy_status: contentSubmit?.occupancy_status
          ? contentSubmit.occupancy_status
          : dataStatus.occupancy_status,
        i_d: dataStatus?.id ? dataStatus.id : 0,
        description: contentSubmit?.description
          ? contentSubmit.description
          : dataStatus.description,
      })
      .then((response) => {
        console.log("Update Status >> ", response);
        successRoomStatus(response);
        handleCancel();
        // fetchData();
      })
      .catch((error) => {
        console.log(error);
        failedRoomStatus(error);
      });
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    fetchData();
    is_refresh(true);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey.toString();
    // console.log(query);

    var updatedList = [...data];
    // console.log("Update List = ", updatedList);

    updatedList = updatedList.filter((item) => {
      // if (item.room_number == query) {
      const filter = item.room_number.toString();

      // console.log(filter.indexOf(query) !== -1);
      return filter.indexOf(query) !== -1;
    });
    // Trigger render with updated values
    setData(updatedList);
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="room-qeueu-main-table"
        justify="center"
        style={{ backgroundColor: "#FFFFFF", minHeight: "100%", width: "100%" }}
      >
        <Col span={24} className="col-table">
          <Row justify="end">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={handleRefresh}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="room-qeueu-table"
              name="room-qeueu-table"
              key="room-qeueu-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record?.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

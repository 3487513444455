// Import React's Component
import React, { useEffect, useState, useContext, useRef } from "react";
import moment from "moment";

// Import ANTD Component
import {
  Table,
  Form,
  Tooltip,
  Row,
  Col,
  Button,
  Input,
  InputNumber,
} from "antd";

// Import React Icons Components
import { DeleteFilled } from "@ant-design/icons/lib/icons";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

// CODE
export default function ReceivingApprovalDetailTable(props) {
  // PROPS
  const { article, getEditDetail, isUpdatePrice } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  // Boolean
  // Loadings
  const [loading, setLoading] = useState(false);

  // CONST EDITABLE CELL
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.id_pr_detail === editingKey;

  // USE EFFECTS
  useEffect(
    () => {
      let cleanUp = false;

      if (article?.length > 0 && !cleanUp) {
        console.log("Article: ", article);

        setData(article);
      } else {
        setData([]);
      }

      if (editingKey !== "") {
        console.log("Estimated Price");
      }

      return () => {
        cleanUp = true;
      };
    },
    [article],
    editingKey
  );

  // Columns
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "action",
    //   width: 100,
    //   align: "center",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <>
    //         <Row className="action-btn" style={{ width: "100%" }}>
    //           <Col span={12}>
    //             <FaCheckCircle
    //               className="edit-btn"
    //               onClick={() => {
    //                 // handleSave(record);
    //                 save(record);
    //               }}
    //               style={{
    //                 fontSize: 20,
    //                 color: "#1BC5BD",
    //                 cursor: "pointer",
    //               }}
    //             />
    //           </Col>

    //           <Col>
    //             <FaTimesCircle
    //               className="delete-btn"
    //               onClick={cancel}
    //               style={{ fontSize: 20, color: "#F64E60", cursor: "pointer" }}
    //             />
    //           </Col>
    //         </Row>
    //       </>
    //     ) : (
    //       <>
    //         <Row
    //           className="action-guest-table-btn"
    //           justify="center"
    //           style={{ width: "100%" }}
    //         >
    //           <Col span={12}>
    //             <Tooltip title="Edit Data" className="edit-tooltip">
    //               <FiEdit
    //                 className="edit-btn"
    //                 disabled={editingKey !== ""}
    //                 onClick={() => edit(record)}
    //                 style={{
    //                   fontSize: 20,
    //                   color: "#1BC5BD",
    //                   cursor: "pointer",
    //                 }}
    //               />
    //             </Tooltip>
    //           </Col>
    //         </Row>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Article Number",
    //   dataIndex: "article_number",
    //   key: "article_number",
    // },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    // {
    //   title: "Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   render: (date) => {
    //     return moment(date).format("DD-MM-YYYY");
    //   },
    // },
    // {
    //   title: "COA",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    //   //   render: (date) => {
    //   //     return moment(date).format("DD-MM-YYYY");
    //   //   },
    // },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      editable: true,
      // render: (qty) => {
      //   return (
      //     <Tooltip
      //       className="edit-tooltip"
      //       popupVisible={true}
      //       title={"Edit Quantity"}
      //     >
      //       {`${qty > 0 ? qty : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      //     </Tooltip>
      //   );
      // },
      render: (qty) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
    // isUpdatePrice == true
    //   ? {
    //       title: "Last Price (Per Unit)",
    //       dataIndex: "supplier_article_price",
    //       key: "supplier_article_price",
    //       render: (estimated) => {
    //         let est = estimated > 0 ? estimated : 0;

    //         return est > 0
    //           ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //           : "-";
    //       },
    //     }
    //   : {},
    // isUpdatePrice == true
    //   ? {
    //       title: "New Price (Per Unit)",
    //       dataIndex: "price",
    //       key: "price",
    //       render: (estimated) => {
    //         let est = estimated > 0 ? estimated : 0;

    //         return est > 0
    //           ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //           : "-";
    //       },
    //     }
    //   : {},
    {
      title: "Last Price (Per Unit)",
      dataIndex: "supplier_article_price",
      key: "supplier_article_price",
      hidden: isUpdatePrice != true ? true : false,
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;

        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
    {
      title: "New Price (Per Unit)",
      dataIndex: "price",
      key: "price",
      hidden: isUpdatePrice != true ? true : false,
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;

        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "price",
      hidden: isUpdatePrice == true ? true : false,
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;

        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
    {
      title: "Amount",
      dataIndex: "total_price",
      key: "total_price",
      render: (e, record) => {
        return `${record.quantity * record.price}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "D-Unit",
      dataIndex: "d-unit",
      key: "d-unit",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
    },
  ].filter((item) => !item.hidden);

  // EDITABLE TABLE

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    let childNode = children;

    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      children
    );

    return <td {...restProps}>{childNode}</td>;
  };

  const cancel = () => {
    setEditingKey("");
  };

  const edit = (record) => {
    console.log("key: ", record);

    form.setFieldsValue({
      quantity: "",
      description: "",
      ...record,
    });
    setEditingKey(record.id_pr_detail);
  };

  // Handle Save on Edit
  const handleSave = async (row) => {
    console.log("ROW: ", row);
    const newData = [...data];

    const index = newData.findIndex(
      (item) => row.id_article === item.id_article
    );
    // console.log("Index: ", index);

    const item = newData[index];
    // console.log("Item: ", item);

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    console.log("New Data: ", newData);
    // setDataSource(newData);
    getEditDetail(newData);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];

      console.log("Check Key in save >> ", key);
      console.log("Check Row in save >> ", row);
      console.log("Check Data in save >> ", data);

      const index = newData.findIndex((item) => {
        // console.log(item);
        return key.id_pr_detail === item.id_pr_detail;
      });
      console.log("Check Index in save >> ", index);

      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
          est_price:
            row.quantity * (item?.average_price > 0 ? item.average_price : 0),
          quantity: row.quantity,
          description: row.description,
        });

        // console.log("Edited: ", newData);
        setData(newData);
        getEditDetail({ ...item, ...row });
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        getEditDetail(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  // Comun Editable
  const columnz = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    // console.log("Col editable: ", col);

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={components}
          bordered
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          dataSource={data}
          columns={columnz}
          size="small"
          pagination={{
            onChange: cancel,
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
          }}
          scroll={{
            x: true,
          }}
          rowKey={(record) => {
            return record?.id_article ? record.id_article : record.article_name;
          }}
        />
      </Form>
    </>
  );
}

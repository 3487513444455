// Import React's Components
import React, { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import { Button, Col, Row, Table, Typography } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

// AUTH
import { baseurl } from "../../../../../API/Config/Api";
import { token, user_name } from "../../../../../API/Global/Payload";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CODE
export default function UserShiftAuditTable(props) {
  // PROPS
  const { dataStats, dateAudit } = props;

  // CONTEXT
  const { getMasterPaymentType } = useContext(MasterContext);

  // COLUMN
  const columns = [
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
      width: 250,
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      width: 300,
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
      width: 200,
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Input By",
      dataIndex: "checkin_by",
      key: "checkin_by",
      width: 300,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 300,
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Descriptions",
      dataIndex: "description_statistic",
      key: "description_statistic",
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [date_audit, setDate_Audit] = useState(null);
  // Payment Type
  const [paymentType, setPaymentType] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [columnWidth, setColumnWidth] = useState(100);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isPrint, setIsPrint] = useState(false);

  // Fetch Data Night Audit Room Rate
  const fetchData = async () => {
    // setLoading(true);

    await axios
      .get(`${baseurl}/night-audit/sales-report-by-user`, {
        headers: { Authorization: `Bearer ${token || ""}` },
      })
      .then((response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          setData(_response);
          dataStats(_response);
        } else {
          dataStats([]);
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
      });
    // .finally(() => {
    //   setLoading(false);
    // });

    await getMasterPaymentType({
      payment_type: "payment-card",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Payment Type: ", response);

        if (response.data.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          // console.log("Response Payment Type: ", filtered);

          setPaymentType(filtered);
        } else {
          setPaymentType([]);
        }

        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Error Payment Type: ", error);

        // setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Fetch Data Re-Night Audit
  const fetchReAudit = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseurl}/re-night-audit/sales-report-by-user`,
        {
          date: moment(dateAudit).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          setData(_response);
          dataStats(_response);
          setTotalPayment(_response?.length);
        } else {
          setTotalPayment(0);
          dataStats([]);
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dateAudit != date_audit && !cleanUp) {
      setDate_Audit(dateAudit);
      fetchReAudit();
    } else {
      if (date_audit) {
        setData([]);
        dataStats([]);
      } else {
        fetchData();
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [dateAudit]);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (paymentType.length > 0 && data.length > 0 && !cleanUp) {
      modifyData();
    }

    return () => {
      cleanUp = true;
    };
  }, [paymentType, data]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (data.length > 0 && paymentType.length > 0 && !cleanUp) {
      dynamicColumns();
    }

    return () => {
      cleanUp = true;
    };
  }, [paymentType, data]);

  // NEW COLUMNS
  useEffect(() => {
    let cleanUp = false;

    if (columns?.length > 6 && newColumns.length == 0 && !cleanUp) {
      console.log("NEW COLUMNS USE EFFECTS => ", columns);
      setNewColumns(columns);
      setColumnWidth(columns.length * columnWidth);
    }

    return () => {
      cleanUp = true;
    };
  }, [columns]);

  useEffect(() => {
    let cleanUp = false;

    if (isPrint && !cleanUp) {
      printOut();
    }

    return () => {
      cleanUp = true;
    };
  }, [isPrint]);

  // Handle Columns
  const dynamicColumns = async () => {
    // console.log("Pay: ", paymentType);

    paymentType.map((items, index) => {
      // Object.keys(items).forEach((key) => {
      //   if (key.indexOf("id") > 0 && index != _number) {
      // console.log("Dynamics Columns: ", items);
      // console.log("Dynamics Keys: ", key);
      // console.log("Dynamics Index: ", index);

      // _number = index;

      return (
        <span>
          {columns.push({
            title: items.name, //items["date_availability_" + _number], // moment(dateRange[0]).format("DD-MM-YYYY"),
            dataIndex: items.id, // key,
            key: items.id, //key,
            width: 300,
            align: "right",
            render: (record, recordAll, index) => {
              // console.log("Record click: ", record);

              return `${record > 0 ? record : 0}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );
            },
          })}
        </span>
      );
      //   }
      // });
    });
  };

  // TABLE DATA TRANSFORMATION
  const transMart = (arr, pro) => {
    let lao = [];

    // Group By
    let _merge = [];

    const _group = data.map((items) => {
      let keys = paymentType.find((ids) => {
        if (ids.id == items?.payment_card_id) {
          return ids.id;
        }
      });

      // console.log("IDS: ", keys);

      // if (keys == items?.payment_card_id) {
      return {
        ...items,
        [keys.id]: items?.amount > 0 ? items.amount : 0,
      };
      // }
    });

    console.log("Group By --> ", _group);
    console.log("Array Merge --> ", _merge);

    setTimeout(() => {
      setLoading(false);
    }, 750);

    return _group;
  };

  // TRIGGER MODIFY TABLE DATA
  const modifyData = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    let newData = transMart(paymentType, "id");

    console.log("Transmart -> ", newData);

    setNewData(newData);
  };

  // Handle Print
  const componentRef = useRef();
  const pageStyle = "@media print{@page {size: landscape}}";

  const handlePrint = () => {
    // printOut();
    setIsPrint(true);
  };

  const printOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${moment().format("YYYY-MM-DD")} Sales Report`,
    onAfterPrint: () => {
      setIsPrint(false);
    },
    pageStyle: pageStyle,
  });

  return (
    <>
      <Row justify="end" style={{ marginTop: 15, width: "100%" }}>
        <Col>
          <Button
            icon={<PrinterOutlined />}
            className="next-btn"
            type="primary"
            onClick={handlePrint}
          >{`Download`}</Button>
        </Col>
      </Row>

      <Row
        ref={componentRef}
        style={{ marginTop: 15, padding: 20, width: "100%" }}
      >
        <Typography.Title level={5}>
          {isPrint
            ? `Statistics Sales by User - Night Audit ${moment().format(
                "DD-MM-YYYY"
              )}`
            : null}
        </Typography.Title>

        <Table
          className="na-stats-table"
          key="na-stats-table"
          name="na-stats-table"
          loading={loading}
          columns={newColumns}
          dataSource={newData}
          bordered
          size="small"
          pagination={
            false
            //     {
            //     pageSizeOptions: [5, 10, 25, 50, 100],
            //     defaultPageSize: 5,
            //     showSizeChanger: true,
            //     showTotal: (total, range) =>
            //       `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            //   }
          }
          scroll={{
            x: columnWidth,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) =>
            record?.row_number > 0 ? record.row_number : record.id
          }
          style={{
            border: "1px solid #000000",
          }}
        />
      </Row>
    </>
  );
}

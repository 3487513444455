// Import Type of Messages
import { SET_DATE } from "../API/AuthActions/types";

// Initial States
const initialState = {
  date: null,
};

// CODE
// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  // console.log("Reducers Night-Audit: ", type);
  // console.log("Payload: ", payload);

  switch (type) {
    case SET_DATE:
      return { ...state, date: payload?.date };

    default:
      return state;
  }
}

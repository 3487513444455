// REACT COMPONENTS
import React, { useState } from "react";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import ArticleVendor from "../../../../../Components/Reusable/Master/ArticleVendor/ArticleVendor";
import PurchaseBookReportTable from "../../../../../Components/Table/Reports/BackOffice/Purchasing/PurchaseBook/Table";

// REACT ICONS
import { FaBookReader } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoReturnUpBackOutline } from "react-icons/io5";

export default function PurchaseBookReportPage() {
  // STATE MANAGEMENT
  // DATA
  const [article, setArticle] = useState(null);

  // USE NAVIGATE
  const navigate = useNavigate();

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticle = (value) => {
    console.log("Article Value: ", value);
    setArticle(value);
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/purchasing-report";

    navigate(`${path}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaBookReader
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Purchase Book Reports`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Col
                style={{
                  textAlign: "start",
                  margin: "22px 25px 22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  width: 250,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Select Article`}</Typography>

                <ArticleVendor
                  selectedArticle={handleSelectedArticle}
                  article_name={
                    article?.article_name ? article.article_name : null
                  }
                  is_disable={false}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                  width: 250,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Row>

            {article ? (
              <PurchaseBookReportTable id_article={article?.article_id} />
            ) : null}
          </Col>
        </Content>
      </Layout>
    </>
  );
}

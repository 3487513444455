// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
  Select,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaWarehouse } from "react-icons/fa";
import {
  MdFoodBank,
  MdOutlineTableBar,
  MdTableRestaurant,
} from "react-icons/md";
import { SiAirtable } from "react-icons/si";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import OrderTable from "../../../Components/Table/Outlet/Order/Main/Table";
import ModalOutletLocation from "../../../Components/Modals/Outlet/OutletLocation/Location/Modal";

// Import Global Border
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";
import { IoReturnUpBackOutline } from "react-icons/io5";
import TableAvailabilityTable from "../../../Components/Table/Outlet/TableAvailability/Table";

// CODE
export default function TableAvailabilityPage() {
  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // STATE MANAGEMENT
  // Search
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState(null);
  // Status
  const [isRoomService, setIsRoomService] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedData, setSelectedData] = useState({
    shift: "UNSELECTED",
    locations: "UNSELECTED",
    locations_id: 0,
    shift_id: 0,
  });
  // Data
  const [data, setData] = useState(null);
  const [tableNumber, setTableNumber] = useState(null);
  // Modal State
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECTS
  useEffect(() => {
    sessionStorage.setItem("selection", JSON.stringify(selectedData));
  }, [selectedData]);

  useEffect(() => {
    sessionStorage.setItem("outlet_data", JSON.stringify(data));
  }, [data]);

  // Handle Select
  const handleOpen = () => {
    // console.log("PLEASE OPEN!", open);
    setOpen(true);
  };

  // Handle Search
  const handleSearch = (e) => {
    e.preventDefault();
    // console.log("PLEASE OPEN!", open);
    setValue(e.target.value);
  };

  // Handle Select
  const handleSelect = (value) => {
    const _selected = value;

    console.log("Selected Data: ", _selected);

    if (_selected?.outlet_location && _selected?.outlet_id > 0) {
      setSelectedData({
        locations: _selected.label_outlet_location,
        shift: _selected.label_shift,
        locations_id: _selected?.outlet_id,
        shift_id: _selected?.shift,
      });

      setIsSelected(true);
    } else {
      setIsSelected(false);
    }

    // setOpen(false);
  };

  const handleNavigateReturn = () => {
    navigate("/f-and-b/outlet", {
      state: {},
    });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="table-order-main-layout">
          <Row
            className="table-order-title"
            align="middle"
            justify="start"
            style={cardHeader}
          >
            <MdOutlineTableBar
              className="icons-head"
              style={{
                height: 24,
                width: 24,
                margin: "10px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "10px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Table Availability`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: "10px 30px 15px",
              }}
            >
              <Col
                className="location-col"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Location`}</Typography>

                <Button
                  className="submit-btn"
                  onClick={handleOpen}
                  type="primary"
                  style={{
                    width: 205,
                  }}
                >
                  {selectedData.locations}
                </Button>
              </Col>

              <Col
                className="shift-col"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Shift`}</Typography>

                <Button
                  className="submit-btn"
                  onClick={handleOpen}
                  type="primary"
                  style={{
                    width: 205,
                  }}
                >
                  {selectedData.shift}
                </Button>
              </Col>

              <Col
                className="btn-col"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleNavigateReturn}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: "10px 30px 15px",
              }}
            >
              {/* <Col
                className="name-col"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 300,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Reservation Created Date`}</Typography>

                <Input
                  placeholder="Date"
                  allowClear
                  minLength={0}
                  //   onChange={handleSearch}
                  style={{
                    width: "100%",
                  }}
                />
              </Col> */}

              <Col
                className="name-col"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 300,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Name`}</Typography>

                <Input
                  placeholder="Name"
                  allowClear
                  minLength={0}
                  onChange={handleSearch}
                  style={{
                    width: "100%",
                  }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="next-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          {isSelected === true ? (
            <TableAvailabilityTable
              getSelectedOutlet={selectedData}
              searchKeyName={value}
              is_search={setSearch}
            />
          ) : null}
        </Col>

        <ModalOutletLocation
          closeModal={setOpen}
          openModal={open}
          getLocs={handleSelect}
          getData={setData}
          is_roomService={setIsRoomService}
        />
      </Content>
    </Layout>
  );
}

// Import React
import React from "react";

// Functions to Grouping Article Name to be one Row
// CODE
export default function GroupingArticle(props) {
  // PROPS
  const dataArticle = props;
  console.log("PROPS: ", props);

  const grouping = dataArticle.reduce((acc, cur) => {
    // console.log("Accumulations: ", acc);
    // console.log("Currents: ", cur);

    const item =
      acc?.length > 0 &&
      acc.find(
        ({ article_name, unit_price }) =>
          article_name == cur.article_name && unit_price == cur.unit_price
      );

    // console.log("Items: ", item);

    if (item) {
      item.price += cur.price;
      item.quantity += cur.quantity;
      item.service += cur.service;
      item.tax += cur.tax;

      if (item?.total_price > 0) {
        item.total_price += cur.total_price;
      }
    } else {
      acc.push(cur);
    }

    return acc;
  }, []);

  return grouping;
}

// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";
import { user_name, token } from "../../../../../API/Global/Payload";

import { LocalizationDate } from "../../../../Reusable/Functions/Localizations/Localization";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Input,
  DatePicker,
  Space,
  Button,
  Typography,
} from "antd";

// Import React Icons Components
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiCheckCircle, FiEdit, FiXCircle } from "react-icons/fi";
import { SearchOutlined } from "@ant-design/icons";

export default function ReservationTableModalTable(props) {
  // PROPS
  const { getResvData, getSelectedTableResv } = props;

  // Data
  const [data, setData] = useState([]);
  const [select, setSelect] = useState(null);

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  useEffect(() => {
    setData(getResvData);
  }, []);

  // Columns
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "action",
    //   //   fixed: "left",
    //   width: 70,
    //   render: (_, record, index) => {
    //     const editable = isEditing(record, index);

    //     return editable ? (
    //       <>
    //         <Row className="action-btn" gutter={20} style={{ width: "100%" }}>
    //           <Col span={12}>
    //             <FaCheckCircle
    //               className="edit-btn"
    //               onClick={() => {
    //                 save(index, record.article_name);
    //               }}
    //               style={{
    //                 fontSize: 20,
    //                 color: "#1BC5BD",
    //                 cursor: "pointer",
    //               }}
    //             />
    //           </Col>

    //           <Col span={12}>
    //             <FaTimesCircle
    //               className="delete-btn"
    //               onClick={cancel}
    //               style={{ fontSize: 20, color: "#F64E60", cursor: "pointer" }}
    //             />
    //           </Col>
    //         </Row>
    //       </>
    //     ) : (
    //       <>
    //         <Row className="action-btn" style={{ width: "100%" }}>
    //           <Col span={12}>
    //             <Tooltip title="Edit Quantity" className="delete-tooltip">
    //               <FiEdit
    //                 className="edit-btn"
    //                 onClick={() => {
    //                   console.log("EDIT ON CLICK >>>");
    //                   if (edit != true) {
    //                     console.log("IS EDITING QTY: ", editingKey);
    //                     console.log("Edit: ", record);
    //                     console.log("Edit: ", edit);
    //                     editData(record, index);
    //                     setEdit(true);
    //                   }
    //                 }}
    //                 style={{
    //                   fontSize: 20,
    //                   color: "#1BC5BD",
    //                   cursor: "pointer",
    //                 }}
    //               />
    //             </Tooltip>
    //           </Col>
    //         </Row>
    //       </>
    //     );
    //   },
    // },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Table",
      dataIndex: "table_code",
      key: "table_code",
      render: (amount) => amount,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (amount) => amount,
    },
    {
      title: "Time (hour)",
      dataIndex: "hour",
      key: "hour",
      render: (amount) => {
        return `${amount < 10 ? "0" : ""}${amount}.00`;
      },
    },
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
      render: (amount) => amount ?? "-",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (amount) => amount ?? "-",
    },
    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      getSelectedTableResv(selectedRows[0]);
    },
    getCheckboxProps: (record) => {
      return {
        disabled:
          record.hour < moment().hours() ||
          record.status_order == (2 || 3 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  return (
    <Table
      className="saved-bill-modal-table"
      name="saved-bill-modal-table"
      key="saved-bill-modal-table"
      columns={columns}
      dataSource={data.filter((e) => e.hour >= moment().hours())}
      bordered
      pagination={{
        defaultPageSize: 5,
        pageSizeOptions: [10, 20, 50, 100],
        showSizeChanger: true,
        showTotal: (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total} entries`,
        onChange(current, pageSize) {
          setPage(current);
          setPaginationSize(pageSize);
        },
      }}
      scroll={{
        x: 800,
      }}
      rowSelection={{
        type: "radio",
        ...rowSelection,
      }}
      //   components={{
      //     body: {
      //       cell: EditableCell,
      //     },
      //   }}
      rowClassName={(record, index) => {
        return index % 2 === 0 ? "odd" : "even";
      }}
      rowKey={(record) => record.hour}
    />
  );
}

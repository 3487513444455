// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, ConfigProvider, List } from "antd";
import EmptyListComponents from "../../../../Reusable/Empty/EmptyList";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CODE
export default function MainCategoryList(props) {
  // PROPS
  const { main_data, main_selected } = props;

  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Loading
  const [loading, setLoading] = useState(true);

  // Fetch Data
  const fetchData = async () => {
    await getArticleList({
      list: "main-group",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Article Main => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _filtered = _res.filter((filt) => {
            // if (!filt.is_deleted) {
            return filt.is_show_pos === true; // || filt?.department_id === 106;
            // }
          });

          // console.log("Response Article => ", _filtered);
          setData(_filtered);
          // setBulks(filtered);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS\
  useEffect(() => {
    fetchData();
  }, []);

  // Handle Pick
  const handlePick = (value) => {
    const _main = value;
    // console.log("Values: ", _main);

    main_selected(_main);
  };

  // Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
    }
  };

  return (
    <>
      <ConfigProvider
        key="provider-list"
        renderEmpty={() => {
          return <EmptyListComponents is_refresh={handleRefresh} />;
        }}
      >
        <List
          className="maingroup-list"
          key={"maingroup-list"}
          itemLayout="vertical"
          dataSource={data}
          loading={loading}
          renderItem={(item, index) => (
            <List.Item key={item?.id} className="items-list-article">
              <Button
                type="primary"
                block
                key={item?.id}
                onClick={() => {
                  handlePick(item);
                }}
                style={{
                  padding: 5,
                  borderRadius: 4,
                  background: "#5EADFF",
                  whiteSpace: "normal",
                  height: "auto",
                }}
              >
                {item?.name}
              </Button>
            </List.Item>
          )}
          style={{
            border: "1px solid #E4E6EF",
            borderRadius: 4,
            padding: 10,
            width:"100%",
          }}
        />
      </ConfigProvider>
    </>
  );
}

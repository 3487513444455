//BASE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";

//AUTH
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const RESET_REGISTER = "RESET_REGISTER";

export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
export const ADMIN_UPDATE_FAIL = "ADMIN_UPDATE_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const BLOCK_SUCCESS = "BLOCK_SUCCESS";
export const BLOCK_FAIL = "BLOCK_FAIL";

export const UNBLOCK_SUCCESS = "UNBLOCK_SUCCESS";
export const UNBLOCK_FAIL = "UNBLOCK_FAIL";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

//NIGHT AUDIT
export const SET_DATE = "SET_DATE";

// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import moment, { now } from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Modal,
  Form,
  Divider,
  InputNumber,
  DatePicker,
  Typography,
  Input,
  Tabs,
  Alert,
} from "antd";

// Import React Icons Components
import {
  ArrowRightOutlined,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import PurchaseRequestList from "../../../Reusable/Inventory/Purchasing/PurchaseRequestList";
import PRDetailModalTable from "../../../Table/BackOffice/Purchasing/PurchaseRequestDetailModal/Table";
import PODetailModalTable from "../../../Table/BackOffice/Purchasing/PurchaseOrderDetailModal/Table";
import ArticlePriceModal from "./ModalArticlePrice";
import PRHeaderModalTable from "../../../Table/BackOffice/Purchasing/PurchaseRequestDetailModal/TableHeader";
//
import MasterSupplierByArticleId from "../../../Reusable/Master/Supplier/MasterSupplierByArtID";
import MasterArticleInventory from "../../../Reusable/Master/ArticleInventory/ArticleInventory";
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterApprovalLevel from "../../../Reusable/Master/Approval/ApprovalLevel";
import PRDetailTable from "../../../Table/BackOffice/Purchasing/PurchaseDetail/Table";

// Import Notification
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";
import {
  incomplete,
  failedPO,
  successPO,
} from "../../../Reusable/Notification/Notification";

// Modal
const { confirm } = Modal;
const { TextArea } = Input;

// CODE
export default function PurchaseOrderModal(props) {
  // PROPS
  const { is_open, is_close, is_refresh, is_edit, selected, params } = props;

  // CONTEXT
  const { getMasterSupplierByArticleId } = useContext(MasterContext);

  const { Title, Text } = Typography;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  const [dataPRDetail, setDataPRDetail] = useState([]);
  const [dataPODetail, setDataPODetail] = useState([]);
  const [dataPRDetailFirst, setDataPRDetailFirst] = useState([]);

  const [dataSelectedPR, setDataSelectedPR] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [selectedSupplierData, setSelectedSupplierData] = useState([]);
  const [selectedPRNumber, setSelectedPRNumber] = useState([]);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  const [modalArticlePriceOpen, setModalArticlePriceOpen] = useState(false);

  const [nextState, setNextState] = useState(false);

  const [authToken, setAuthToken] = useState("");
  const [authBy, setAuthBy] = useState(null);
  const [tabKey, setTabKey] = useState("1");

  // Boolean
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAble, setIsAble] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("Modal State: ", is_open);

    if (is_open == true && !cleanUp) {
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
      is_edit(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  useEffect(() => {
    let cleanUp = false;

    if (dataSelectedPR.length > 0) {
      // setSelectedArticleId(dataSelectedPR[0].id_article);
      // form.setFieldsValue({
      //   id_pr_detail: dataSelectedPR[0].id_pr_detail,
      //   id_article: dataSelectedPR[0].id_article,
      //   article_name: dataSelectedPR[0].article_name,
      //   quantity_request: dataSelectedPR[0].quantity,
      // });
    }

    if (selectedPRNumber?.length > 0 && !cleanUp) {
      console.log("Selected PR: ", selectedPRNumber);

      switch (selectedPRNumber[0]?.is_dml) {
        case true:
          setIsAble(2);
          break;

        case false:
          setIsAble(1);
          break;

        default:
          break;
      }
    } else {
      setIsAble(0);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataSelectedPR, selectedPRNumber]);

  useEffect(() => {
    let cleanUp = false;

    if (selectedArticleId != null && !cleanUp) {
      setSelectedSupplierData([]);
      // setModalArticlePriceOpen(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [selectedArticleId]);

  useEffect(() => {
    if (is_open == true) {
      let arr = dataPRDetail.map((e) => {
        // Need fixed data of remaining quantity based on last fetch, so we make dummy for the realtime calculation
        e["remaining_quantity_dummy"] = e.remaining_quantity;
        if (dataPODetail.find((x) => x.id_article === e.id_article)) {
          // set dummy from realtime calculation
          // z = (e.quantity - e.remaining_quantity)
          let z =
            e.remaining_quantity -
            (dataPODetail.length > 0
              ? dataPODetail
                  .filter((x) => {
                    if (x.id_article === e.id_article) {
                      return x;
                    }
                  })
                  // get the sum of Article id in PO based selected Article Id on Selected in PR
                  .reduce((accumulator, i) => accumulator + i.quantity, 0)
              : 0);
          e["quantity"] = z;
          e["remaining_quantity_dummy"] = z;
        } else {
          e["quantity"] = e.remaining_quantity;
        }
        return e;
      });
      setDataPRDetail(arr);
    }
  }, [dataPODetail]);

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;

    form.setFieldsValue({});
  };

  // Handle Open
  const handleOpen = () => {
    // setIsDelete(false);

    setModalOpen(true);
  };

  // Handle Close
  const handleClose = () => {
    setData([]);
    setDataPRDetail([]);
    setDataPODetail([]);
    setSelectedSupplierData([]);
    setSelectedArticleId(null);
    setNextState(false);
    setSelectedPRNumber([]);
    setTabKey("1");

    is_close(false);
    is_edit(false);
    // setIsDelete(true);
    setEdit(false);
    setModalOpen(false);

    setIsEdit(false);

    setIsLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (val) => {
    const submit = val;

    console.log("Submit: ", submit);

    setIsLoading(true);
    showModalConfirm(submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // HANDLE PURCHASE REQUEST (PO)
  const getPurchaseRequest = (allKey) => {
    console.log(allKey);

    setSelectedPRNumber(allKey);

    form.setFieldsValue({
      _id_pr: allKey[0].id_pr,
      description: allKey[0].remarks,
      delivery_date: moment(allKey[0].needed_date).subtract(1, "days"),
      credit_term_date: moment(allKey[0].needed_date),
    });

    let x = allKey[0].details.map((e) => {
      e["key"] = e.id_article;
      e["request_date"] = allKey[0].request_date;
      e["needed_date"] = allKey[0].needed_date;
      e["id_pr_detail"] = e.id_pr_detail;
      e["pr_number"] = e.pr_number;
      e["quantity"] = e.remaining_quantity;
      e["remaining_quantity_dummy"] = e.remaining_quantity;
      e["is_dml"] = allKey[0].is_dml;
      e["id_supplier"] = e.supplier[0]?.id_supplier;
      e["supplier_name"] = e.supplier[0]?.supplier_name;
      e["id_supplier_article"] = e.supplier[0]?.id_supplier_article;
      e["unit_price"] = e.supplier[0]?.price;
      e["amount"] = e.supplier[0]?.price * e.remaining_quantity;
      return e;
    });

    console.log("X: ", x);

    setDataPRDetail(x);
    setDataPRDetailFirst(x);
  };

  const getData = (second) => {};

  // Handle Add
  const handleAdd = async () => {
    let arr = dataPODetail;

    if (dataPODetail.length > 0) {
      console.log("PO > 0");

      dataSelectedPR.forEach((e, idx) => {
        // find some data who has same id_article
        if (dataPODetail.some((x) => x.id_article == e.id_article)) {
          console.log("i.id_article == e.id_article");
          // find the array index of the data who has same id_article
          idx = dataPODetail.findIndex((val) => val.id_article == e.id_article);
          // summ the quantity from dataPODetail and dataSelectedPR
          arr[idx].quantity = arr[idx].quantity += e.quantity;
        } else {
          // if not find some data who has same id_article, push the data straight away
          console.log("i.id_article != e.id_article");
          arr.push({ ...e });
        }
      });
    } else {
      console.log("PO == 0");
      for (let e = 0; e < dataSelectedPR.length; e++) {
        arr.push({ ...dataSelectedPR[e] });
      }
    }

    console.log("HASIL CALCULATION >>>", arr);
    setDataPODetail([...arr]);
    setDataSelectedPR([]);
    setSelectedArticleId(null);
    setTabKey("3");
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Create a Purchase Order ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDataAssignPostPO(_data);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Supplier to Article
  const handleDataAssignPostPO = async (val) => {
    await form
      .validateFields()
      .then((values) => {
        console.log("Validates: ", values);

        let _validators = form.getFieldsValue(true);

        let datAssign = dataPODetail.map((e) => {
          return { ...e, ..._validators, description: e.description };
        });

        let supplierGrouping = Object.groupBy(
          datAssign,
          ({ id_supplier }) => id_supplier
        );

        let arr = [];
        for (let e = 0; e < Object.keys(supplierGrouping).length; e++) {
          let x = Object.values(supplierGrouping)[e];
          let arr_x = [];
          console.log(x);
          for (let i = 0; i < x.length; i++) {
            arr_x.push({
              id_article: x[i].id_article,
              id_pr_detail: x[i].id_pr_detail,
              id_supplier_article: x[i].id_supplier_article,
              quantity_request: x[i].remaining_quantity,
              chart_of_account: "001-10-200",
              // id_supplier_article: x[i].id_supplier_article,
              description: x[i].description,
              quantity_ready: x[i].quantity,
              price: x[i].unit_price,
              price_update: x[i].price_update,
              is_ppn: x[i].is_ppn,
            });
          }
          let obj = {
            _id_pr: x[0]._id_pr,
            order_date: moment(),
            needed_date: moment(x[0].needed_date).format("YYYY-MM-DD"),
            id_supplier: x[0].id_supplier,
            id_currency: x[0].id_currency,
            description: _validators.description,
            remarks: x[0].remarks,
            delivery_count: x[0].delivery_count,
            delivery_date: moment(x[0].delivery_date).format("YYYY-MM-DD"),
            credit_term: x[0].credit_term,
            credit_term_date: moment(x[0].credit_term_date).format(
              "YYYY-MM-DD"
            ),
            start_contract_date: x[0].start_contract_date,
            end_contract_date: x[0].end_contract_date,
            released: "UPDATE",
            po_status: val,
            is_dml: x[0].is_dml,
            details: arr_x,
            created_by: authBy != null ? authBy : user_name,
          };
          arr.push(obj);
        }
        console.log("DATA FOR SUBMIT >>>", arr);
        handleSubmit(arr);
      })
      .catch((errorInfo) => {
        console.log("Validator: ", errorInfo);
        setIsLoading(false);
        incomplete(errorInfo);
      });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const submit = value;
    await axios
      .post(`${baseurl}/purchasing/purchase-order`, value, {
        headers: {
          Authorization: `Bearer ${authToken ? authToken : token || ""}`,
        },
      })
      .then((response) => {
        console.log("Post PO => ", response);
        successPO(response, { method: value.po_status == 1 ? 0 : 1 });

        handleClose();
      })
      .catch((error) => {
        console.log("Error Post PO => ", error);

        failedPO(error, { method: value.po_status == 1 ? 0 : 1 });
        setIsLoading(false);
      });
  };

  const items = [
    {
      key: "1",
      disabled: isAble == 2 ? true : false,
      label: (
        <>
          <Button
            style={
              tabKey == "1"
                ? {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {" PR Article List"}
          </Button>
        </>
      ),
      children: (
        <>
          <Row
            className="pay-row"
            gutter={30}
            style={{
              padding: "0px 0px 15px",
            }}
          >
            <Col span={24} className="pay-col">
              {dataPRDetail.length > 0 ? (
                <>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <h3>{`Select Article From Purchase Request (PR)`}</h3>

                    <Button
                      className="next-btn"
                      type="primary"
                      icon={<ShoppingCartOutlined />}
                      disabled={
                        dataSelectedPR.length == 0 || isEdit == true
                          ? true
                          : false
                      }
                      onClick={handleAdd}
                    >{`Add to PO`}</Button>
                  </Row>

                  <Row className="pay-row" gutter={30}>
                    <Col span={12} className="pay-col">
                      <Form.Item
                        label="Purchase Request"
                        name="_id_pr"
                        rules={[
                          {
                            required: true,
                            message: "Please, select a Purchase Request!",
                          },
                        ]}
                      >
                        <PurchaseRequestList
                          getPRNumber={getData}
                          pr={selectedPRNumber[0].pr_number}
                          is_open={modalOpen}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <PRDetailModalTable
                    dataDetail={dataPRDetail}
                    getSelectedPRDetail={setDataSelectedPR}
                    params={params}
                    isEdit={setIsEdit}
                  />
                </>
              ) : (
                <>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <h3>{`Select Purchase Request (PR)`}</h3>
                  </Row>

                  <PRHeaderModalTable
                    getSelectedPR={getPurchaseRequest}
                    is_dml={false}
                  />
                </>
              )}
            </Col>
          </Row>

          <Form.Item label="PR Remarks" name="remarks" hidden>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      key: "2",
      disabled: isAble == 1 ? true : false,
      label: (
        <>
          <Button
            style={
              tabKey == "2"
                ? {
                    // height: 35,
                    width: 170,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 170,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {" Daily Market List (DML)"}
          </Button>
        </>
      ),
      children: (
        <>
          <Row className="pay-row" gutter={30}>
            <Col span={24} className="pay-col">
              {dataPRDetail.length > 0 ? (
                <>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <h3>{`Select Article From Daily Market List (DML)`}</h3>

                    <Button
                      className="next-btn"
                      type="primary"
                      icon={<ShoppingCartOutlined />}
                      disabled={dataSelectedPR.length == 0 ? true : false}
                      onClick={handleAdd}
                    >{`Add to PO`}</Button>
                  </Row>

                  <Row className="pay-row" gutter={30}>
                    <Col span={12} className="pay-col">
                      <Form.Item
                        label="Purchase Request"
                        name="_id_pr"
                        rules={[
                          {
                            required: true,
                            message: "Please, select a Purchase Request!",
                          },
                        ]}
                      >
                        <PurchaseRequestList
                          getPRNumber={getData}
                          pr={selectedPRNumber[0].pr_number}
                          is_open={modalOpen}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <PRDetailModalTable
                    dataDetail={dataPRDetail}
                    getSelectedPRDetail={setDataSelectedPR}
                    // dataEdit={null}
                  />
                </>
              ) : (
                <>
                  <Row
                    style={{
                      justifyContent: "space-between",
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <h3>{`Select Daily Market List (DML)`}</h3>
                  </Row>

                  <PRHeaderModalTable
                    getSelectedPR={getPurchaseRequest}
                    is_dml={true}
                  />
                </>
              )}
            </Col>
          </Row>

          <Form.Item label="PR Remarks" name="remarks" hidden>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <Button
            style={
              tabKey == "3"
                ? {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {"PO Detail List"}
          </Button>
        </>
      ),
      children: (
        <>
          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
          >
            {`Purchase Order (PO) Header`}
          </Divider>
          <Row className="info-row" gutter={30} justify="start">
            <Col span={8} className="qty-col">
              <Form.Item
                label="Start Contract Date"
                name="start_contract_date"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Start Contract Date term!",
                //   },
                // ]}
              >
                <DatePicker
                  disabled={nextState ? true : false}
                  format={"YYYY-MM-DD"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className="unit-col">
              <Form.Item
                label="End Contract Date"
                name="end_contract_date"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input End Contract Date Date!",
                //   },
                // ]}
              >
                <DatePicker
                  disabled={nextState ? true : false}
                  format={"YYYY-MM-DD"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className="unit-col">
              <Form.Item
                label="Currency"
                name="id_currency"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Currency!",
                  },
                ]}
              >
                <Select
                  disabled={nextState ? true : false}
                  // defaultValue="IDR"
                  // style={{
                  //   width: 120,
                  // }}
                  // onChange={}
                  options={[
                    {
                      value: 1,
                      label: "IDR",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="info-row" gutter={30} justify="start">
            <Col span={16}>
              <Row className="info-row" gutter={30} justify="start">
                <Col span={12} className="qty-col">
                  <Form.Item
                    label="Delivery Count"
                    name="delivery_count"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input credit term!",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={nextState ? true : false}
                      className="delivery_count"
                      placeholder="Count"
                      min={1}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="unit-col">
                  <Form.Item
                    label="Last Delivery Date"
                    name="delivery_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Last Delivery Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={nextState ? true : false}
                      disabledDate={(day) => {
                        let current = moment().format("YYYY-MM-DD");
                        return day < moment(current, "YYYY-MM-DD");
                      }}
                      format={"YYYY-MM-DD"}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="info-row" gutter={30} justify="start">
                <Col span={12} className="qty-col">
                  <Form.Item
                    label="Credit Term (Day)"
                    name="credit_term"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input credit term!",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={nextState ? true : false}
                      className="credit-term"
                      placeholder="credit-term"
                      min={1}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="unit-col">
                  <Form.Item
                    label="Last Credit Term Date"
                    name="credit_term_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input First Credit Term Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={nextState ? true : false}
                      disabledDate={(day) => {
                        let current = moment().format("YYYY-MM-DD");
                        return day < moment(current, "YYYY-MM-DD");
                      }}
                      format={"YYYY-MM-DD"}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Alert
                message="Selected Purchase Request (PR)"
                description={
                  <>
                    <Row>
                      <Text strong>PR No. :</Text>
                    </Row>

                    <Row>
                      <Text italic>
                        {selectedPRNumber[0]?.pr_number
                          ? selectedPRNumber[0]?.pr_number
                          : "-"}
                      </Text>
                    </Row>

                    <Row>
                      <Text strong>Dept. Name :</Text>
                    </Row>

                    <Row>
                      <Text italic>
                        {selectedPRNumber[0]?.name
                          ? selectedPRNumber[0]?.name
                          : "-"}
                      </Text>
                    </Row>

                    <Row>
                      <Text strong>Requested Date :</Text>
                    </Row>

                    <Row>
                      <Text italic>
                        {selectedPRNumber[0]?.request_date
                          ? moment(selectedPRNumber[0]?.request_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}
                      </Text>
                    </Row>

                    <Row>
                      <Text strong>Needed Date :</Text>
                    </Row>

                    <Row>
                      <Text italic>
                        {selectedPRNumber[0]?.needed_date
                          ? moment(selectedPRNumber[0]?.needed_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}
                      </Text>
                    </Row>
                  </>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>

          <Row className="info-row" gutter={30} justify="start">
            <Col span={24} className="unit-col">
              <Form.Item
                label="PO Notes"
                name="description"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Delivery Date!",
                //   },
                // ]}
              >
                <TextArea
                  rows={2}
                  allowClear
                  showCount
                  placeholder="Purchase Order Description"
                  // disabled={nextState ? true : false}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{
              margin: "10px 0px",
              borderColor: "#EBEDF3",
              fontSize: 17,
              fontWeight: 750,
            }}
          >{`Purchase Order (PO) Details`}</Divider>

          <PODetailModalTable
            dataDetail={dataPODetail}
            getAfterDelete={setDataPODetail}
            dataEdit={setDataPODetail}
            getAuthToken={(e) => {
              console.log("AUTH TOKEN >>>", e);
              setAuthToken(e);
            }}
            getAuthBy={(e) => {
              console.log("AUTH BY >>>", e);
              setAuthBy(e);
            }}
            params_acc={params}
            isEdit={setIsEdit}
          />

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
          />

          <Row justify="end" align="middle" className="modal-btn-form">
            <Button
              className="next-btn"
              type="primary"
              // htmlType="submit"
              disabled={
                dataPODetail.length == 0 || isEdit == true ? true : false
              }
              loading={isLoading}
              onClick={() => {
                onFinish(2);
              }}
              style={{
                marginRight: 15,
              }}
            >
              {`Save As Draft`}
            </Button>

            <Button
              className="submit-btn"
              type="primary"
              // htmlType="submit"
              disabled={
                dataPODetail.length == 0 || isEdit == true ? true : false
              }
              loading={isLoading}
              onClick={() => {
                onFinish(1);
              }}
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        className="purchase-ord-modal"
        key="purchase-ord-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Purchase Order`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        closable
        destroyOnClose={true}
        onCancel={handleClose}
        width={1200}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ id_currency: 1 }}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top" gutter={[50]}>
            <Col className="form-col" span={24}>
              <Tabs
                defaultActiveKey="1"
                activeKey={tabKey}
                items={items}
                onChange={(e) => {
                  setTabKey(e);
                }}
                type="card"
              />
            </Col>
          </Row>
        </Form>
      </Modal>

      <ArticlePriceModal
        is_open={modalArticlePriceOpen}
        is_close={setModalArticlePriceOpen}
        articleId={selectedArticleId}
        getSelectedSupplier={setSelectedSupplierData}
      />
    </>
  );
}

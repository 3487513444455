// Import React's Component
import React, { useEffect, useContext, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// BASEAPI
import { user_name, token } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Input,
  Space,
  Button,
  DatePicker,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

// Import React Icons Components

// CODE
export default function PRHeaderModalTable(props) {
  // PROPS
  const { dataDetail, dataEdit, getSelectedPR, getSelectedSupplier, is_dml } =
    props;

  // CONTEXT
  const { getPurchasing } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();

  const [select, setSelect] = useState(null);
  const [editingKey, setEditingKey] = useState("");

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  // Boolean
  const [edit, setEdit] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);

  //   const [selectedArticleId, setSelectedArticleId] = useState(0);

  //   const [modalArticlePriceOpen, setModalArticlePriceOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getPurchasing({
      type: "purchase-request",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const resp = response.data.msg;

          const _res = resp.filter((e) => {
            if (e?.approved_list != null) {
              if (
                e?.pr_status == true &&
                moment().isSameOrBefore(moment(e?.needed_date)) &&
                e?.approved_list[e?.approved_list?.length - 1] == 1 &&
                e?.is_from_receiving == false &&
                e?.details.reduce((acc, e) => acc + e.remaining_quantity, 0) > 0
              ) {
                if (is_dml) {
                  return e.is_dml == true;
                } else {
                  return e.is_dml == false;
                }
              }
            }
          });

          console.log("Res: ", _res);

          setData(_res);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // failedFetch(error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    setSelect(null);
    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex == "request_date" ? (
          <DatePicker
            ref={searchInput}
            format={"YYYY-MM-DD"}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
            }}
            onChange={(e) => {
              setSelectedKeys(e ? [moment(e).format("YYYY-MM-DD")] : []);
              console.log(e);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  // Columns
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "PR No.",
      dataIndex: "pr_number",
      key: "pr_number",
      ...getColumnSearchProps("pr_number"),
    },
    {
      title: "Department",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
      ...getColumnSearchProps("request_date"),
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    // {
    //   title: "Approval Level",
    //   dataIndex: "approval_level",
    //   key: "approval_level",
    // },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ].filter((item) => !item.hidden);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    // selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      getSelectedPR(selectedRows);
    },
    getCheckboxProps: (record) => {
      if (select) {
        return {
          disabled: record.pr_number != select,
        };
      }
    },
  };

  return (
    <>
      <Form
        form={form}
        component={false}
        initialValues={{
          quantity: 0,
          unit_price: 0,
          amount: 0,
        }}
      >
        <Table
          className="pr-detail-modal-table"
          name="pr-detail-modal-table"
          key="pr-detail-modal-table"
          columns={columns}
          dataSource={data}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
            onChange(current, pageSize) {
              setPage(current);
              setPaginationSize(pageSize);
            },
          }}
          scroll={{
            x: 1000,
          }}
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => record.pr_number}
        />
      </Form>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import {
  user_name,
  token,
} from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Table,
  Button,
  Col,
  Row,
  Form,
  Modal,
  Typography,
  Input,
  InputNumber,
  Tooltip,
  Select,
  Tabs,
} from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  PlusCircleTwoTone,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Pop Over
import MicePopover from "../../../Popover/MICE/Popover";

// Import Notification
import {
  failedFetch,
  failedNonStay,
  successNonStay,
} from "../../../Reusable/Notification/Notification";
import { 
  masterSuccessNotification,
  masterIncomplete,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import PackagesRoomTable from "./PackagesRoom/Table";
import PackagesServiceTable from "./PackagesService/Table";

// CONST
const { confirm } = Modal;

// CODE
export default function MICEPackagesTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getReservationMice } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  // Data Edit
  const [dataEdit, setDataEdit] = useState(null);
  // MODAL
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // Tabs
  const [tabKey, setTabKey] = useState("1");
  // Specified Form Value
  const [cat, setCat] = useState(null);
  const [number, setNumber] = useState(null);
  const [avgPrice, setAvgPrice] = useState(null);
  // Key Number
  const roomKey = 1;
  const [roomLastKey, setRoomLastKey] = useState(0);
  const serviceKey = 1;
  const [serviceLastKey, setServiceLastKey] = useState(0);

  // USE NAVIGATIONS
  const navigate = useNavigate();

  // Cancel
  const move = async () => {
    navigate(`/f-and-b/mice`);
  };

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT REFRESH
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  useEffect(() => {
    console.log("roomData", roomData);
  }, [roomData]);

  // Fetch Data
  const fetchData = async () => {
    await getReservationMice({
      mice: "mice",
      type: "reservation",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Non Stay Response = ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  const miceHotelPackages = [
    {
      package_number: 1,
      package_name: "Business Conference Package",
      package_price: 5000,
      venue: "Conference Hall A",
      capacity: 200,
      duration: "3 Days",
      room_category: ["Deluxe", "Executive Suite"],
      services: ["Wi-Fi", "Audio-Visual Equipment", "Catering"],
      description: "A premium package for business conferences, including all essential services and amenities."
    },
    {
      package_number: 2,
      package_name: "Incentive Travel Package",
      package_price: 7000,
      venue: "Incentive Suite B",
      capacity: 100,
      duration: "5 Days",
      room_category: ["Suite", "Ocean View"],
      services: ["Spa Access", "All-Inclusive Meals", "Guided Tours"],
      description: "An incentive travel package with luxury services to reward your top performers."
    },
    {
      package_number: 3,
      package_name: "Corporate Retreat Package",
      package_price: 4000,
      venue: "Incentive Villa C",
      capacity: 50,
      duration: "4 Days",
      room_category: ["Executive Suite", "Private Villa"],
      services: ["Team-Building Activities", "Outdoor Excursions", "Private Dining"],
      description: "A corporate retreat package designed to foster team building and relaxation in a scenic environment."
    },
    {
      package_number: 4,
      package_name: "Exhibition Event Package",
      package_price: 6000,
      venue: "Exhibition Hall D",
      capacity: 300,
      duration: "2 Days",
      room_category: ["Premium", "Deluxe"],
      services: ["Exhibition Hall", "Event Management", "Security"],
      description: "A specialized package for large exhibitions with full event support and venue management."
    },
    {
      package_number: 5,
      package_name: "Luxury Corporate Package",
      package_price: 9000,
      venue: "Conference Room E",
      capacity: 250,
      duration: "5 Days",
      room_category: ["Presidential Suite", "Luxury Suite"],
      services: ["Private Jet Service", "Helicopter Transfers", "Personal Chef"],
      description: "A luxury corporate package designed for high-end clients, featuring premium services and facilities."
    },
    {
      package_number: 6,
      package_name: "Training Workshop Package",
      package_price: 3500,
      venue: "Meeting Room F",
      capacity: 150,
      duration: "3 Days",
      room_category: ["Standard", "Deluxe"],
      services: ["Whiteboard", "Projector", "Conference Rooms"],
      description: "A package designed for workshops and training events with all necessary equipment and room setups."
    },
    {
      package_number: 7,
      package_name: "Exclusive VIP Event Package",
      package_price: 12000,
      venue: "VIP Suite G",
      capacity: 75,
      duration: "2 Days",
      room_category: ["VIP Suite", "Luxury Suite"],
      services: ["Private Security", "Personal Concierge", "Luxury Transportation"],
      description: "A package tailored for exclusive VIP events, featuring top-notch services and security."
    },
    {
      package_number: 8,
      package_name: "Hybrid Conference Package",
      package_price: 4500,
      venue: "Conference Hall H",
      capacity: 300,
      duration: "3 Days",
      room_category: ["Business Suite", "Deluxe"],
      services: ["Live Streaming", "Interactive Q&A Tools", "Virtual Reality Equipment"],
      description: "A hybrid event package for blending in-person and virtual attendees with advanced technical support."
    },
    {
      package_number: 9,
      package_name: "Annual General Meeting Package",
      package_price: 6000,
      venue: "Meeting Room I",
      capacity: 350,
      duration: "2 Days",
      room_category: ["Superior", "Executive Suite"],
      services: ["Conference Rooms", "AV Support", "Catering"],
      description: "A package designed for annual general meetings, with conference halls and full AV support."
    },
    {
      package_number: 10,
      package_name: "Luxury Incentive Package",
      package_price: 10000,
      venue: "Incentive Villa J",
      capacity: 50,
      duration: "7 Days",
      room_category: ["Ocean Villa", "Luxury Suite"],
      services: ["Private Yacht", "Snorkeling Tours", "Personal Butler"],
      description: "A high-end incentive package for rewarding top performers with luxury services in a tropical paradise."
    },
    {
      package_number: 11,
      package_name: "Product Launch Package",
      package_price: 8000,
      venue: "Exhibition Hall K",
      capacity: 400,
      duration: "1 Day",
      room_category: ["Premium", "Business Suite"],
      services: ["Press Coverage", "Social Media Management", "Stage Setup"],
      description: "A package designed for product launches with full event coordination and media coverage."
    },
    {
      package_number: 12,
      package_name: "Team Building Adventure Package",
      package_price: 5000,
      venue: "Incentive Lodge L",
      capacity: 120,
      duration: "4 Days",
      room_category: ["Lodge", "Deluxe"],
      services: ["Outdoor Activities", "Ropes Courses", "Leadership Workshops"],
      description: "An adventure package for team-building exercises, featuring outdoor activities and workshops."
    },
    {
      package_number: 13,
      package_name: "Exclusive Leadership Summit Package",
      package_price: 15000,
      venue: "Conference Hall M",
      capacity: 80,
      duration: "3 Days",
      room_category: ["Presidential Suite", "Executive Suite"],
      services: ["Private Networking Sessions", "High-Level Security", "Luxury Dining"],
      description: "An exclusive package for high-level leadership summits, offering top-tier services and security."
    },
    {
      package_number: 14,
      package_name: "Educational Conference Package",
      package_price: 3000,
      venue: "Conference Hall N",
      capacity: 250,
      duration: "2 Days",
      room_category: ["Standard", "Deluxe"],
      services: ["Lecture Halls", "Projector Screens", "Catering"],
      description: "A budget-friendly package for educational conferences, including lecture halls and basic services."
    },
    {
      package_number: 15,
      package_name: "Trade Show Package",
      package_price: 7500,
      venue: "Exhibition Hall O",
      capacity: 500,
      duration: "3 Days",
      room_category: ["Premium", "Superior"],
      services: ["Exhibition Booths", "AV Equipment", "Security"],
      description: "A package specifically designed for trade shows with exhibition halls and logistical support."
    },
    {
      package_number: 16,
      package_name: "Wellness Retreat Package",
      package_price: 6000,
      venue: "Incentive Spa P",
      capacity: 30,
      duration: "7 Days",
      room_category: ["Private Villa", "Ocean Villa"],
      services: ["Yoga Classes", "Spa Treatments", "Healthy Dining"],
      description: "A wellness retreat package designed to promote relaxation and well-being in a serene environment."
    },
    {
      package_number: 17,
      package_name: "Startup Accelerator Program Package",
      package_price: 4000,
      venue: "Meeting Room Q",
      capacity: 100,
      duration: "5 Days",
      room_category: ["Business Suite", "Deluxe"],
      services: ["Mentorship Sessions", "Investor Pitches", "Networking Events"],
      description: "A package designed for startup accelerators, featuring mentorship, networking, and pitch sessions."
    },
    {
      package_number: 18,
      package_name: "Government Summit Package",
      package_price: 10000,
      venue: "Conference Hall R",
      capacity: 300,
      duration: "3 Days",
      room_category: ["VIP Suite", "Presidential Suite"],
      services: ["Diplomatic Security", "Press Coverage", "Translation Services"],
      description: "A package designed for government summits, featuring high-level security and media coordination."
    },
    {
      package_number: 19,
      package_name: "Creative Industry Workshop Package",
      package_price: 4500,
      venue: "Meeting Room S",
      capacity: 150,
      duration: "3 Days",
      room_category: ["Boutique", "Executive Suite"],
      services: ["Creative Workshops", "Art Exhibitions", "Networking Dinners"],
      description: "A package focused on the creative industry, offering workshops and networking opportunities."
    },
    {
      package_number: 20,
      package_name: "International Business Conference Package",
      package_price: 11000,
      venue: "Conference Hall T",
      capacity: 600,
      duration: "4 Days",
      room_category: ["Executive Suite", "Business Suite"],
      services: ["Business Center", "Translation Services", "Event Coordination"],
      description: "A package for international business conferences, offering comprehensive services and facilities."
    }
  ];

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-non-stay-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(!open);
                      setEdit(!edit);
                      setDataEdit(record);

                      handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <MicePopover
                  // onClick={() => {
                  //   return record;
                  // }}
                  is_history={false}
                  details={record}
                  is_refresh={setRefresh}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Package Number",
      dataIndex: "package_number",
      key: "package_number",
      // fixed: "left",
      width: 150,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Package Name",
      dataIndex: "package_name",
      key: "package_name",
      // fixed: "left",
      width: 250,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    },
    {
      title: "Price",
      dataIndex: "package_price",
      key: "package_price",
      render: (text) => {
        return `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ",000"
      }
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
    },
    {
      title: "Capacity (person)",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "Room",
      dataIndex: "room_category",
      key: "room_category",
      render: (text) => {
        return text.join(", ")
      }
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      render: (text) => {
        return text.join(", ")
      }
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // Handle Form Fields
  const handleFormField = (value) => {
    const record = value;

    form.setFieldsValue({
      event_start: moment(record.event_start),
      event_end: moment(record.event_end),
      currency: record.currency ? record.currency : null,
      guest_name: record.guest_name ? record.guest_name : null,
      bill_receiver: record.bill_receiver ? record.bill_receiver : null,
      bill_instruction: record.bill_instruction ? record.bill_instruction : null,
      payment_type: record.payment_type ? record.payment_type : null,
      payment_details: record.payment_detail ? record.payment_detail : null,
      payment_status: record.payment_status ? record.payment_status : 1,
      description: record.description ? record.description : null,
      deposit: record.deposit ? record.deposit : 0,
      reservation_id: record.reservation_id,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;
    console.log(content);

    setIsLoading(true);
    showModalConfirm(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const content = value;
    console.log("FAILED  >> ", content?.values);

    masterIncomplete(content);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setDataEdit(null);
    setIsLoading(false);
    setCat(null);
    setNumber(null);
    setAvgPrice(null);
    setTabKey("1");
    setRoomData([]);
    setRoomLastKey(0);
    setServiceData([]);
    setServiceLastKey(0);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const content = value;

    console.log("Submit => ", content);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to ${
        edit ? "Update" : "Add a New"
      } MICE Reservations with named ${
        content?.guest_name.toUpperCase() || " --- "
      }?`,
      // icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    console.log("handleSubmit value", value);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.guest_name.toLowerCase();

        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  // console.log("Is EDIT ??? ", edit);
  // console.log(payment_status);

  const handleCat = (value, record) => {
    console.log("handleCat > value", value);
    console.log("handleCat > record", record);
    if(record?.code){
      setCat(record.code);
      form.setFieldsValue({
        code: record.code,
        room_category_name: record.label,
      });
    } else {
      setCat(null);
    }
  };

  const handleServicesChange = (value, record) => {
    console.log("handleServicesChange > value", value);
    console.log("handleServicesChange > record", record);
    if(record?.article_number){
      setNumber(record.article_number);
      form.setFieldsValue({
        article_number: record.article_number,
        service_name: record.label,
      });
    } else {
      setNumber(null);
    }

    if(record?.average_price){
      setAvgPrice(record.average_price);
      form.setFieldsValue({
        average_price: record.average_price,
      });
    } else {
      setAvgPrice(null);
    }
  };

  const handleServiceDelete = (value) => {
    console.log("handleServiceDelete>value:", value);
    showModalDelete(value);
  };

  const handleRoomDelete = (value) => {
    console.log("handleServiceDelete>value:", value);
    showModalDelete(value);
  };

  // Modal Delete
  const showModalDelete = (val) => {
    console.log("showModalDelete>Delete: ", val);

    confirm({
      className: "modals-confirm",
      title: val?.service_name ? `Are you sure want to Delete ${ val?.service_name.toUpperCase() || " ----- " } Service for this MICE Package?` :
             `Are you sure want to Delete ${ val?.room_category_name.toUpperCase() || " ----- " } Room for this MICE Package?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        if(val?.service_name){
          serviceDelete(val);
        } else {
          roomDelete(val);
        }
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Delete
  const serviceDelete = async (val) => {
    let _deleted = serviceData.filter((value) => {
      console.log("value, val >> ", value.article_name, val.article_name);
      return value.article_name != val.article_name;
    });

    // let _deletedEdit = tableDetail?.filter((value) => {
    //   console.log("value, val >> ", value.id_article, val.id_article);
    //   return value.id_article != val.id_article;
    // });

    // console.log("VAL >> ", val);
    // console.log("DATA >> ", dataEdit);
    // console.log("DetailLength", dataEdit?.details?.length);

    // if (edit === false) {
      setServiceData(_deleted);
      masterSuccessNotification(null, {
        method: 2,
        source: "Service MICE Package",
      });
    // } else {
    //   setTableDetail(_deletedEdit);

    //   let obj = {
    //     id_pr_detail: val.id_pr_detail,
    //     deleted_by: user_name,
    //   };

    //   await axios
    //     .delete(`${baseurl}/purchasing/purchase-request-detail`, {
    //       data: obj,
    //       headers: { Authorization: `Bearer ${token ? token : ""}` },
    //     })
    //     .then((response) => {
    //       console.log("DEL Article => ", response);

    //       masterSuccessNotification(response, {
    //         method: 2,
    //         source: "Article Purchase Request",
    //       });
    //       setTableDetail(_deletedEdit);
    //       is_refresh(false);
    //     })
    //     .catch((error) => {
    //       masterFailedNotification(error, {
    //         method: 2,
    //         source: "Article Purchase Request",
    //       });
    //       console.log("DEL Article => ", error);
    //     });
    // }
  };
  
  const roomDelete = async (val) => {
    let _deleted = roomData.filter((value) => {
      console.log("value, val >> ", value.room_category, val.room_category);
      return value.room_category != val.room_category;
    });

    setRoomData(_deleted);
    masterSuccessNotification(null, {
      method: 2,
      source: "Room MICE Package",
    });
  };

  const handleRoomEdit = (val) => {
    console.log("Edit data: ", val);
    setRoomData(val);
  };

  const handleServiceEdit = (val) => {
    console.log("Edit data: ", val);
    setServiceData(val)
  };

  const items = [
    {
      key: "1",
      // disabled: isAble == 2 ? true : false,
      label: (
        <>
          <Button
            style={
              tabKey == "1"
                ? {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {"Header"}
          </Button>
        </>
      ),
      children: (
        <>
          <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={12}>
              <Form.Item
                label="Package Name"
                name="package_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Package Name!",
                  },
                ]}
              >
                <Input placeholder="Package Name"/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Package's Price!",
                  },
                ]}
              >
                <InputNumber
                  className="package-price"
                  placeholder="Package Price"
                  style={{
                    width: "100%",
                  }}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={12} className="venue-col">
              <Form.Item
                label="Venue"
                name="venue"
                rules={[
                  {
                    required: true,
                    message: "Please, insert The Vanue!",
                  },
                ]}
              >
                <Select
                  placeholder="Venue"
                  style={{
                    width: "100%",
                  }}
                  // onChange={handleChange}
                  options={[
                    {label:"Conference Hall A", value:1},
                    {label:"Incentive Suite B", value:2},
                    {label:"Incentive Villa C", value:3},
                    {label:"Exhibition Hall D", value:4},
                    {label:"Conference Room E", value:5},
                    {label:"Meeting Room F", value:6},
                    {label:"VIP Suite G", value: 7}
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12} className="capacity-col">
              <Form.Item
                label="Capacity (Person)"
                name="capacity"
                rules={[
                  {
                    required: true,
                    message: "Please, Insert Capacity!",
                  },
                ]}
              >
                <InputNumber
                  className="capacity"
                  placeholder="Capacity (Person)"
                  style={{
                    width: "100%",
                  }}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-mice-desc" gutter={30}>
            <Col span={24} className="desc-col">
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  className="area-desc"
                  showCount
                  allowClear
                  maxLength={200}
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      // disabled: isAble == 1 ? true : false,
      label: (
        <>
          <Button
            style={
              tabKey == "2"
                ? {
                    // height: 35,
                    width: 170,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 170,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {"Rooms"}
          </Button>
        </>
      ),
      children: (
        <>
         <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={8}>
              <Form.Item
                label="Room Category"
                name="room_category"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Room Category!",
                  },
                ]}
              >
                <Select
                  placeholder="Room Category"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleCat}
                  options={[
                    { label: "Executive Suite", value: 1, code: "ES", room_category: "Executive Suite" },
                    { label: "Deluxe", value: 2, code: "DE", room_category: "Deluxe" },
                    { label: "Suite", value: 3, code: "SU", room_category: "Suite" },
                    { label: "Ocean View", value: 4, code: "OV", room_category: "Ocean View" },
                    { label: "Private Villa", value: 5, code: "PV", room_category: "Private Villa" },
                    { label: "Premium", value: 6, code: "PR", room_category: "Premium" },
                    { label: "Presidential Suite", value: 7, code: "PS", room_category: "Presidential Suite" },
                    { label: "Standard", value: 8, code: "ST", room_category: "Standard" },
                    { label: "VIP Suite", value: 9, code: "VS", room_category: "VIP Suite" },
                    { label: "Luxury Suite", value: 10, code: "LS", room_category: "Luxury Suite" }
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="room_category_name"
                name="room_category_name"
                hidden="true"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Code"
                name="code"
              >
                <Input disabled value={cat} placeholder="Code" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Room Quantity"
                name="room_quantity"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Room Qty!",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  placeholder="Input Room Qty!"
                  // defaultValue={1}
                  // max={capacity ? capacity : 1}
                  style={{
                    width: "100%",
                    // margin: "0px 30px 0px 0px",
                  }}
                  onChange={(value) => {
                    // getRoomQty(name, value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="article-row" justify="end">
          <Button
            className="next-btn"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              form
                .validateFields(['room_category', 'room_category_name', 'code', 'room_quantity'])
                .then((values) => {
                  console.log('Room fields are valid:', values);

                  let room = {
                    room_category: values.room_category,
                    room_category_name: values.room_category_name,
                    code: values.code,
                    room_quantity: values.room_quantity,
                    key: roomLastKey + roomKey,
                  }
                  
                  if(roomData.length > 0) {
                    console.log("ada data")
                    // Cek apakah article sudah tersedia (cek menggunakan id_article)
                    if (roomData.some((item) => item.room_category === values?.room_category)) {
                      let addNew = roomData.map((x) => {
                        if (x.room_category === values?.room_category) {
                          return {
                            ...x,
                            room_quantity: x.room_quantity + values?.room_quantity,
                          };
                        } else {
                          return x;
                        }
                      });

                      setRoomData(addNew);

                      form.resetFields([
                        "room_category",
                        "room_category_name",
                        "code",
                        "room_quantity",
                      ]);
                    } else {
                      console.log("Mode >> Nambah Data Baru");
                      setRoomData([...(roomData?.length > 0 ? roomData : []), room]);

                      form.resetFields([
                        "room_category",
                        "room_category_name",
                        "code",
                        "room_quantity",
                      ]);
  
                      setRoomLastKey(roomLastKey + 1);
                    }
                  } else {
                    console.log("tidak ada data");
                    setRoomData([room]);

                    form.resetFields([
                      "room_category",
                      "room_category_name",
                      "code",
                      "room_quantity",
                    ]);

                    setRoomLastKey(roomLastKey + 1);
                  }
                })
                .catch((errorInfo) => {
                  console.log('Room fields validation failed:', errorInfo);
                });
            }}
          >
            {`Add Room`}
          </Button>
          </Row>

          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
          >
            {`Room List`}
          </Divider>
          
          <PackagesRoomTable
            // article={edit ? tableDetail : data}
            article={roomData}
            // getDeleteDetail={handleAboutDelete}
            getDeleteDetail={handleRoomDelete}
            // getEditDetail={handleEditDetail}
            getEditDetail={handleRoomEdit}
          />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <Button
            style={
              tabKey == "3"
                ? {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {"Services"}
          </Button>
        </>
      ),
      children: (
        <>
          <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={12}>
              <Form.Item
                label="Article Name"
                name="article_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Article Name!",
                  },
                ]}
              >
                <Select
                  placeholder="Article Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleServicesChange}
                  options = {[
                    { label: "Wi-Fi", value: 1, article_number: "WF", article_name: "Wi-Fi", average_price: 50 },
                    { label: "Audio-Visual Equipment", value: 2, article_number: "AVE", article_name: "Audio-Visual Equipment", average_price: 200 },
                    { label: "Catering", value: 3, article_number: "CT", article_name: "Catering", average_price: 150 },
                    { label: "Spa Access", value: 4, article_number: "SA", article_name: "Spa Access", average_price: 100 },
                    { label: "All-Inclusive Meals", value: 5, article_number: "AIM", article_name: "All-Inclusive Meals", average_price: 300 },
                    { label: "Guided Tours", value: 6, article_number: "GT", article_name: "Guided Tours", average_price: 120 },
                    { label: "Team-Building Activities", value: 7, article_number: "TBA", article_name: "Team-Building Activities", average_price: 250 },
                    { label: "Outdoor Excursions", value: 8, article_number: "OE", article_name: "Outdoor Excursions", average_price: 180 },
                    { label: "Private Dining", value: 9, article_number: "PD", article_name: "Private Dining", average_price: 500 },
                    { label: "Event Management", value: 10, article_number: "EM", article_name: "Event Management", average_price: 600 },
                    { label: "Security", value: 11, article_number: "SC", article_name: "Security", average_price: 100 },
                    { label: "Private Jet Service", value: 12, article_number: "PJS", article_name: "Private Jet Service", average_price: 5000 },
                    { label: "Helicopter Transfers", value: 13, article_number: "HT", article_name: "Helicopter Transfers", average_price: 3000 },
                    { label: "Personal Chef", value: 14, article_number: "PC", article_name: "Personal Chef", average_price: 400 },
                    { label: "Whiteboard", value: 15, article_number: "WB", article_name: "Whiteboard", average_price: 40 },
                    { label: "Projector", value: 16, article_number: "PJ", article_name: "Projector", average_price: 150 },
                    { label: "Conference Rooms", value: 17, article_number: "CR", article_name: "Conference Rooms", average_price: 1000 },
                    { label: "Private Security", value: 18, article_number: "PS", article_name: "Private Security", average_price: 300 },
                    { label: "Personal Concierge", value: 19, article_number: "PCG", article_name: "Personal Concierge", average_price: 200 },
                    { label: "Luxury Transportation", value: 20, article_number: "LT", article_name: "Luxury Transportation", average_price: 1500 }
                  ]}
                />
              </Form.Item>
              <Form.Item name="service_name" hidden="true" > <Input /> </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Service Qty!",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  placeholder="Please, Input Quantity!"
                  // defaultValue={1}
                  // max={capacity ? capacity : 1}
                  style={{
                    width: "100%",
                    // margin: "0px 30px 0px 0px",
                  }}
                  onChange={(value) => {
                    // getRoomQty(name, value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={12}>
              <Form.Item
                label="Article Number"
                name="article_number"
              >
                <Input disabled value={number} placeholder="Article Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Average Price" name="average_price">
                <InputNumber
                  disabled
                  className="avgprice-input"
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  placeholder="Average Price"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

          </Row>

          <Row className="article-row" justify="end">
          <Button
            className="next-btn"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              form
                .validateFields(['article_name', 'article_number', 'quantity', 'service_name', 'average_price'])
                .then((values) => {
                  console.log('Service fields are valid:', values);
                  // Add service logic here
                  let service = {
                    key: serviceLastKey + serviceKey,
                    article_name: values.article_name,
                    article_number: values.article_number,
                    quantity: values.quantity,
                    service_name: values.service_name,
                    average_price: values.average_price
                  }

                  if(serviceData.length > 0) {
                    console.log("ada data")
                    // Cek apakah article sudah tersedia (cek menggunakan id_article)
                    if (serviceData.some((item) => item.article_name === values?.article_name)) {
                      let addNew = serviceData.map((x) => {
                        if (x.article_name === values?.article_name) {
                          return {
                            ...x,
                            quantity: x.quantity + values?.quantity,
                          };
                        } else {
                          return x;
                        }
                      });

                      setRoomData(addNew);

                      form.resetFields([
                        'article_name',
                        'article_number',
                        'quantity',
                        'service_name',
                        'average_price'
                      ]);

                    } else {
                      console.log("Mode >> Nambah Data Baru");
                      setServiceData([...(serviceData?.length > 0 ? serviceData : []), service]);

                      form.resetFields([
                        'article_name',
                        'article_number',
                        'quantity',
                        'service_name',
                        'average_price'
                      ]);
  
                      setServiceLastKey(serviceLastKey + 1);
                    }
                  } else {
                    console.log("tidak ada data");
                    setServiceData([service]);

                    form.resetFields([
                      'article_name',
                      'article_number',
                      'quantity',
                      'service_name',
                      'average_price'
                    ]);

                    setServiceLastKey(serviceLastKey + 1);
                  }
                })
                .catch((errorInfo) => {
                  console.log('Service fields validation failed:', errorInfo);
                });
            }}
            >
            {`Add Service`}
            </Button>
          </Row>

          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
          >
            {`Service List`}
          </Divider>
          
          <PackagesServiceTable
            // article={edit ? tableDetail : data}
            article={serviceData}
            // getDeleteDetail={handleAboutDelete}
            getDeleteDetail={handleServiceDelete}
            // getEditDetail={handleEditDetail}
            getEditDetail={handleServiceEdit}
          />
        </>
      ),
    },
  ];


  return (
    <>
      <Row className="mice-guest-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: "30px" }} className="col-table">
          <Row justify="end" className="row-add-btn">
            <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
              style={{
                marginRight: 15,
              }}
            >
              {`Add MICE Package`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
              style={{
                marginRight: 15,
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              icon={
                <IoReturnUpBackOutline
                  className="return-icons"
                  style={{
                    margin: "0px 5px 0px 0px",
                  }}
                />
              }
              onClick={move}
            >
              {`Return`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="non-stay-table"
              name="non-stay-table"
              key="non-stay-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={miceHotelPackages}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      {/* MODAL */}
      <Modal
        className="mice-modal"
        name="mice-modal"
        key="mice-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add MICE Package`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit MICE Package`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 15,
        }}
        footer={null}
      >
        <Form
          name="mice-modal-form"
          className="mice-modal-form"
          key="mice-modal-form"
          // title=""
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={tabKey}
            items={items}
            onChange={(e) => {
              setTabKey(e);
            }}
            type="card"
          />

          <Divider
            className="divider-form"
            style={{ background: "#EBEDF3", margin: "0px 0px 15px" }}
          />

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              // htmlType="submit"
              // key={"submit"}
              loading={isLoading}
              className="submit-btn"
              style={{ marginRight: 15 }}
              onClick={() => {
                form
                  .validateFields(['package_name', 'price', 'venue', 'capacity', 'description'])
                  .then((values) => {
                    console.log('Header fields are valid:', values);
                    // Submit logic here
                  })
                  .catch((errorInfo) => {
                    console.log('Header fields validation failed:', errorInfo);
                  });
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
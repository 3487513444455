// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// UATH & BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Page Components
import MasterTableCategory from "../../../Reusable/Outlet/Master/Category/TableCategory";
import MasterTableStatus from "../../../Reusable/Outlet/Master/TableStatus/TableStatus";
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../../Reusable/Notification/Notification";
import {
  failedOutlet,
  successOutlet,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Improt Global Style
import { mainBody } from "../../../../Style/Global/Global";

// CONST
const { confirm } = Modal;

// CODE
export default function TableListTable({
  is_search,
  searchKey,
  outlet_location,
  table_cat,
}) {
  // Navigate
  const navigate = useNavigate();

  // CONTEXT
  const { getMasterTable, getTableList, getMasterRoom } =
    useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // OUTLET
  const [outletLocation, setOutletLocation] = useState({
    locations: "",
    locations_code: "",
    locations_id: 0,
  });
  const [tableCat, setTableCat] = useState({
    categories: "",
    categories_code: "",
    categories_id: 0,
  });
  const [columnType, setColumnType] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  // Delete
  const [isDelete, setIsDelete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="table-row" gutter={30} justify="center">
              <Col span={12} className="col-edit">
                <Tooltip className="edit-tooltip" title="Edit Data">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setOpen(true);
                      setEdit(true);
                      setDataEdit(record);

                      handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-del">
                <Tooltip className="edit-tooltip" title="Delete Data">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showConfirmModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Table Name",
      dataIndex: "table_code",
      key: "table_code",
    },
    {
      title: "Table Code",
      dataIndex: "table_id",
      key: "table_id",
    },
    {
      title: "Status",
      dataIndex: "status_table",
      key: "status_table",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Total Seat",
      dataIndex: "seat",
      key: "seat",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) =>
      //   a.table_category_code.localeCompare(b.table_category_code),
    },
    {
      title: "Table Category",
      dataIndex: "table_category_code",
      key: "table_category_code",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) =>
      //   a.table_category_desc.localeCompare(b.table_category_desc),
    },
    {
      title: "Locations",
      dataIndex: "outlet_location_code",
      key: "outlet_location_code",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) =>
      //   a.table_category_desc.localeCompare(b.table_category_desc),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ];

  // Table Columns Room
  const columnRoom = [
    {
      title: "Room ID",
      dataIndex: "room_id",
      key: "room_id",
    },
    {
      title: "Room Name",
      dataIndex: "room_name",
      key: "room_name",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Category",
      dataIndex: "room_category_code",
      key: "room_category_code",
    },
  ];

  // Fetch Data
  const fetchData = async () => {
    const _names = outlet_location?.locations || "";

    console.log("Fetch Data Locations: ", _names);

    if (!_names.toLowerCase().includes("room")) {
      await getMasterTable({
        outlet: "master",
        type: "table",
        onAwait: () => {
          setLoading(true);
        },
        onSuccess: (response) => {
          console.log("Outlet Table List: ", response);

          if (response?.data?.msg?.length > 0) {
            const _res = response.data.msg;

            // setData(_res);
            // setBulks(_res);
            handleFilter(_res);
          } else {
            setData([]);
            setBulks([]);
          }

          setLoading(false);
        },
        onReject: (error) => {
          console.log("Error: ", error);
          failedFetch(error);
          setLoading(false);
        },
      });
    } else {
      setData([]);
      setBulks([]);
    }
  };

  // Fetch Filter
  const fetchFilter = async (value) => {
    await getTableList({
      type: "table-by-category",
      table_category_id: value?.categories_id || tableCat.categories_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Outlet Table Filter: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Fetch Room
  const fetchRoom = async () => {
    await getMasterRoom({
      main: "main",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ISI Room List >> ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            if (val?.is_deleted == false || val?.is_deleted == null) {
              return val;
            }
            // if (val.id == 1) return val;
          });

          // let sortir = filtered.sort((a, b) => a.room_number - b.room_number);

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        // setFilteredList(filtered);
        // console.log("ISI Room List >> ", filtered);
        setLoading(false);
      },
      onReject: (error) => {
        failedFetch(error);
        setLoading(false);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (table_cat.categories_id > 0 && !cleanUp) {
      // console.log("Table Cat: ", table_cat);

      setTableCat({
        categories: table_cat?.categories,
        categories_id: table_cat?.categories_id,
        categories_code: table_cat?.categories_code,
      });

      fetchFilter(table_cat);
      handleCategoryValue(table_cat);
    } else {
      // console.log("This is Else: ", table_cat, tableCat);
      // handleSelect();
      setTableCat({
        categories: "",
        categories_code: "",
        categories_id: 0,
      });
    }

    if (outlet_location.locations_id > 0 && !cleanUp) {
      console.log("Outlet: ", outlet_location, outletLocation);

      if (outlet_location?.locations_id !== outletLocation.locations_id) {
        setOutletLocation({
          locations: outlet_location?.locations,
          locations_id: outlet_location?.locations_id,
          locations_code: outlet_location?.locations_code,
        });

        handleSelect(outlet_location);
        handleHeaderValue(outlet_location);
      }
    } else {
      // console.log("This is Else: ", outlet_location, outletLocation);
      setOutletLocation({
        locations: "",
        locations_code: "",
        locations_id: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [outlet_location, table_cat]);

  // Search Effects
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Handle Select
  const handleSelect = (value) => {
    const _location = value?.locations.toLowerCase();

    if (_location.includes("room")) {
      setColumnType(2);
      fetchRoom();
      setIsDisabled(true);
    } else {
      setColumnType(1);
      handleRefresh();
      setIsDisabled(false);
    }
  };

  // Handle Filter
  const handleFilter = (val) => {
    const _data = val;

    let _filtered = _data.filter((value) => {
      return value?.outlet_location_id === outlet_location.locations_id;
    });

    // console.log("Filtered: ", _filtered);
    setBulks(_filtered);
    setData(_filtered);
  };

  // Handle Refresh
  const handleRefresh = () => {
    console.log("Handle Refresh: ", table_cat);

    if (table_cat.categories_id > 0) {
      fetchFilter();
    } else {
      fetchData();
    }
  };

  // Set Fields Header
  const handleHeaderValue = (value) => {
    console.log("Value Outlet: ", value);

    form.setFieldsValue({
      outlet_location_code: value?.locations_code,
      outlet_location_id: value?.locations_id,
      outlet_location: value?.locations,
    });
  };

  // Set Category Value
  const handleCategoryValue = (value) => {
    console.log("Value Categories: ", value);

    form.setFieldsValue({
      table_category_code: value?.categories_code,
      table_category_id: value?.categories_id,
    });
  };

  // Handle Form Fields
  const handleFormField = (value) => {
    const _value = value;
    console.log("Value Edit: ", _value);

    form.setFieldsValue({
      id: _value?.id,
      table_code: _value?.table_code,
      seat: _value?.seat,
      table_id: _value?.table_id,
      status_table: _value?.status_table,
      description: _value?.description,

      table_category_code: _value?.table_category_code,
      table_category_id: _value?.table_category_id,

      created_date: _value?.created_date,
      created_by: _value?.created_by,
      updated_date: _value?.updated_date,
      updated_by: _value?.updated_by,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setIsDelete(false);
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setIsDelete(true);
    setIsLoading(false);

    form.resetFields([
      "description",
      "table_code",
      "table_id",
      "seat",
      "status_table",

      "updated_by",
      "created_by",
      "updated_date",
      "created_date",
    ]);
  };

  // ON FINISH
  const onFinish = (val) => {
    const _value = val;
    setIsLoading(true);

    console.log("On Finish: ", _value);
    showConfirmModal(_value);
  };
  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _value = val;

    console.log("On Finish Failed: ", _value.values);
    incomplete(_value);
  };

  // Get Table Category
  const getTableCat = (value) => {
    console.log("Table category: ", value);

    form.setFieldsValue({
      table_category_code: value?.code,
      table_category_id: value?.id,
    });
  };

  // Get Table Category
  const getTableStatus = (value) => {
    console.log("Table Status: ", value);

    form.setFieldsValue({
      status_table: value?.label,
      status_id: value?.value,
    });
  };

  // SHOW MODAL DELETE
  const showConfirmModal = (value) => {
    const _data = value;

    confirm({
      className: "delete-guest-confirm",
      title: isDelete
        ? `Are you sure want to Delete a data Table named ${
            _data?.table_code || ""
          } from the table?`
        : `Are you sure want to ${edit ? "Update " : "Add New "} Table named ${
            _data?.table_code || " - "
          }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(_data) : handleSubmit(_data);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const handleSubmit = (e) => {
    // console.log("ON OK ==> ", e);

    switch (edit) {
      case false:
        handleCreate(e);
        break;

      case true:
        handleUpdate(e);
        break;

      default:
        break;
    }
  };

  // Handle Create
  const handleCreate = async (val) => {
    const _data = val;

    console.log("Creates ==> ", _data);

    await axios
      .post(
        `${baseurl}/pos/master/table`,
        {
          table_code: _data?.table_code,
          table_id: _data?.table_id,
          seat: _data?.seat || null,
          status_table: _data?.status_id,
          description: _data?.description,

          table_category_id: _data?.table_category_id || tableCat.categories_id,
          table_category_code:
            _data?.table_category_code || tableCat.categories,
          outlet_location_id:
            _data?.outlet_location_id || outletLocation.locations_id,
          outlet_location_code:
            _data?.outlet_location_code || outletLocation.locations,

          created_by: user_name,
        },
        { headers: `Bearers ${token || ""}` }
      )
      .then((response) => {
        console.log("Res Creates: ", response);

        if (response.status === 200) {
          handleRefresh();

          successOutlet(response, { method: 1, params: 3 });
          handleCancel();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 1, params: 3 });
        setIsLoading(false);
      });
  };

  // Handle Update
  const handleUpdate = async (val) => {
    const _data = val;

    console.log("Update ==> ", _data);
    console.log("Edit Data ==> ", dataEdit);

    await axios
      .put(
        `${baseurl}/pos/master/table`,
        {
          id: dataEdit?.id,

          table_code: _data?.table_code || dataEdit?.table_code,
          table_id: _data?.table_id || dataEdit?.table_id,
          seat: _data?.seat || dataEdit?.seat,
          status_table: _data?.status_id || dataEdit?.status_table,
          description: _data?.description || dataEdit?.description,

          table_category_id:
            _data?.table_category_id || dataEdit?.categories_id,
          table_category_code:
            _data?.table_category_code || dataEdit?.categories,
          outlet_location_id:
            _data?.outlet_location_id || dataEdit?.locations_id,
          outlet_location_code:
            _data?.outlet_location_code || dataEdit?.locations,

          updated_by: user_name,
        },
        { headers: `Bearers ${token || ""}` }
      )
      .then((response) => {
        console.log("Res Update: ", response);

        if (response.status === 200) {
          handleRefresh();

          successOutlet(response, { method: 2, params: 3 });
          handleCancel();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 2, params: 3 });
        setIsLoading(false);
      });
  };

  // Handle Delete
  const handleDelete = async (e) => {
    const _delete = e;

    const obj = {
      ["id"]: _delete?.id,
      ["deleted_by"]: user_name,
    };

    console.log("ON Delete ==> ", obj);

    await axios
      .delete(`${baseurl}/pos/master/table`, {
        data: obj,
        headers: `Bearers ${token || ""}`,
      })
      .then((response) => {
        console.log("Res Update: ", response);

        if (response.status === 200) {
          successOutlet(response, { method: 3, params: 3 });
          handleCancel();

          fetchData();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 3, params: 3 });
        setIsLoading(false);
      });
  };

  // Handle Export
  const handleExport = () => {
    downloadExcel(data, `${outletLocation.locations || " - "} Table List`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey;

    // console.log("Type = ", query);
    // console.log("Bulk = ", bulks);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item);

        if (columnType === 1) {
          return (
            item?.table_category_name
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1
          );
        } else {
          return (
            item?.room_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );
        }
      });

      // Trigger render with updated values
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  return (
    <>
      <Row className="master-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Space
            className="table-info-row"
            direction="horizontal"
            align="center"
            size={30}
            wrap
          >
            <Typography.Text className="text-outlet" strong>
              {`Outlet: `}
            </Typography.Text>

            <Typography.Title level={4} className="txt-loc" type="success">
              {outletLocation.locations || " - "}
            </Typography.Title>

            <Typography.Text className="text-outlet" strong>
              {`Table Category: `}
            </Typography.Text>

            <Typography.Title level={4} className="txt-loc" type="success">
              {tableCat.categories || " - "}
            </Typography.Title>
          </Space>

          <Row className="row-modal-btn" gutter={[0, 15]}>
            <Col
              sm={24}
              xl={12}
              xxl={12}
              lg={12}
              md={24}
              xs={24}
              className="col-exp-btn"
            >
              <Row justify="start" className="row-exp-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  disabled={isDisabled}
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  // style={{
                  //   margin: "0px 0px 0px 15px",
                  // }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              sm={24}
              xl={12}
              xxl={12}
              lg={12}
              md={24}
              xs={24}
              className="col-modal-btn"
            >
              <Row justify="end" className="row-modal-btn" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  disabled={isDisabled}
                  icon={<PlusOutlined />}
                >
                  {`Add Data Table`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row
            className="row-table"
            style={{ margin: "30px 0px", width: "100%" }}
          >
            <Table
              className="master-table"
              key="master-table"
              name="master-table"
              loading={loading}
              bordered
              columns={columnType > 1 ? columnRoom : columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              scroll={{
                x: 1250,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-table-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography
              // style={{ marginLeft: 15 }}
              >{`Add Data Table`}</Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography
              // style={{ marginLeft: 15 }}
              >
                {`Edit Data Table`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="master-modal-form"
          className="master-modal-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="main-form-row" align="middle" justify="start">
            <Col span={24} className="main-form-col">
              <Row gutter={30} className="row-table-id">
                <Col span={16}>
                  <Form.Item label="Outlet Location" name="outlet_location">
                    <Input placeholder="Outlet Location" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Outlet Code" name="outlet_location_code">
                    <Input placeholder="Outlet Code" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Status Table"
                    name="outlet_location_id"
                    hidden
                  >
                    <Input placeholder="Outlet ID" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="row-table-id">
                <Col span={8}>
                  <Form.Item
                    label="Table Name"
                    name="table_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input a Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Table Name" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Table Code"
                    name="table_id"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input a Code/ID!",
                      },
                    ]}
                  >
                    <Input placeholder="Table Code" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Status Table" name="status_table">
                    <MasterTableStatus
                      getTabStatus={getTableStatus}
                      is_disable={false}
                      table_status={edit ? dataEdit?.status_table : 0}
                    />
                  </Form.Item>

                  <Form.Item label="Status ID" name="status_id" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="row-table-id">
                <Col span={8}>
                  <Form.Item label="Total Seat" name="seat">
                    <InputNumber
                      className="seat-total"
                      min={0}
                      placeholder="Total Seat"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Table Category"
                    name="table_category_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    {edit ? (
                      <Input disabled className="edit-master-input" />
                    ) : (
                      <MasterTableCategory
                        getTableCat={getTableCat}
                        outlet_loc={outletLocation}
                        cat_value={tableCat?.categories || null}
                        is_disable={
                          tableCat?.categories_id > 0 || edit === true
                            ? true
                            : false
                        }
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    label="Table Category ID"
                    name="table_category_id"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="row-table-desc">
                <Col span={16}>
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      showCount
                      maxLength={200}
                      rows={4}
                      placeholder="Descriptions"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "10px 0px ", background: "#EBEDF3" }}
              />

              {edit == true ? (
                <>
                  <Row gutter={30} className="created_date-table">
                    <Col span={8}>
                      <Form.Item
                        label="Created By"
                        name="created_by"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Created By" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Created Date"
                        name="created_date"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Created Date" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Updated By"
                        name="updated_by"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Updated by" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="mod-date-table">
                    <Col span={8}>
                      <Form.Item
                        label="Updated Date"
                        name="updated_date"
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Updated Date" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    className="form-divider"
                    style={{ margin: "10px 0px ", background: "#EBEDF3" }}
                  />
                </>
              ) : null}
            </Col>
          </Row>

          <Row className="row-table-submit-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

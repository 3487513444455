// Import React Components
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../../API/AuthActions/authAction";

import { handleAuthorization } from "../../../API/Global/Permission";

// Import Ant Design Components
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { LockOutlined, SmileTwoTone } from "@ant-design/icons";

// Import Parameters
// Import Success Login Notification
import {
  failedLogin,
  successLogin,
} from "../../../Components/Reusable/Notification/Notification";
import axios from "axios";
import { baseurl } from "../../../API/Config/Api";

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch --> ", dispatch);

  return {
    disLogin: (values) => dispatch(auth.login(values.email, values.password)),
  };
};

const mapStateToProps = (state) => {
  let response = state;
  let user = response.rootReducer.auth;

  console.log(response.rootReducer.auth);

  return { user };
};

// CODE
const LoginPage = (props) => {
  // STATE MANAGEMENT
  const [auth, setAuth] = useState({
    username: "",
    password: "",
  });
  // LOGGED IN
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState(null);

  // USE NAVIGATE
  const navigate = useNavigate();
  const token = sessionStorage.getItem("auth-token");

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/version/web-changelog`)
      .then((response) => {
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];

          console.log("Res: ", _res);

          setVersion(_res.version);
        } else {
          setVersion(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch Data Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  // ON FINISH
  const onFinish = (values) => {
    console.log("Success:", values);

    // handleLogin(values);
    if (values?.email && values?.password) {
      handleAuth(values);
    } else {
      // successLogin(values);
      failedLogin("Email or Password is Empty", { method: 0 });
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);

    failedLogin(errorInfo, { method: 0 });
  };

  // HANDLE LOGIN
  const handleLogin = async (e) => {
    console.log(e);

    const admin = {
      email: "adminlook@gmail.com",
      password: "adminlook",
    };

    const user = {
      email: "userlook@gmail.com",
      password: "userlook",
    };

    if (e.username == admin.email && e.password == admin.password) {
      const token = "QU5QUktFWUFETUlOQUNDRVNTVE9LRU4=";
      const admin = true;
      sessionStorage.setItem("auth-token", token);
      sessionStorage.setItem("admin", admin);
      sessionStorage.setItem("roles", ["DEVELOPER"]);

      successLogin(e);
      setIsLoggedIn(true);

      navigate("/dashboard");
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 500);
    } else if (e.username == user.email && e.password == user.password) {
      const token = "QU5QUktFWVVTRVJBQ0NFU1NUT0tFTg==";
      const user = false;
      sessionStorage.setItem("auth-token", token);
      sessionStorage.setItem("staff", user);
      sessionStorage.setItem("roles", ["USER"]);

      successLogin(e);
      setIsLoggedIn(true);

      navigate("/dashboard");
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 500);
    } else {
      failedLogin(e);
      setIsLoggedIn(false);
      console.log("Error Login => ", e);
    }
  };

  // HANDLE AUTH
  const handleAuth = (values) => {
    setLoading(true);

    if (values) {
      console.log("Ini value didalam handel Login: ", values);

      props.disLogin(values);
    } else {
      setLoading(false);
    }
  };

  // USE EFFECT
  useEffect(() => {
    // setLoading(false);

    if (props?.user?.isLodggedIn === true) {
      console.log("Login redirect to dashboard: ", props);

      // const par_1 = Encoder(acc);
      // const par_2 = Encoder(back);
      // const par_3 = Encoder(front);

      // sessionStorage.setItem("par-1", par_1);
      // sessionStorage.setItem("par-2", par_2);
      // sessionStorage.setItem("par-3", par_3);

      // console.log("Login with level user: ", user_level);

      if (handleAuthorization(props?.user?.access_token).user_level > 2) {
        navigate("/task-list");
      } else {
        navigate("/dashboard");
      }

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } else {
      setLoading(false);
    }
  }, [props]);

  // console.log(isLoggedIn);
  // console.log("URL --> ", url, url2);
  // console.log("Props Login --> ", props);

  return (
    <>
      <Layout className="layout-login">
        <Content className="content-login">
          <Row
            className="row-login"
            style={{
              minHeight: "100vh",
              // fontFamily: "Roboto",
            }}
          >
            <Col
              className="col-logo"
              // span={8}
              xxl={8}
              xl={8}
              lg={8}
              md={8}
              sm={0}
              xs={0}
              style={{
                background:
                  "linear-gradient(237deg, #00C4F2 -4.42%, rgba(160, 0, 174, 0.80) 104.52%)",
              }}
            >
              <Row
                className="row-logo"
                justify="center"
                align="middle"
                style={{ padding: "185px 0px" }}
              >
                <Col
                  span={24}
                  className="img-col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Image
                    className="img-logo"
                    src="/looklogo.png"
                    preview={false}
                    alt="img-logo"
                    //   height={"500%"}
                    style={{
                      // height: 60,
                      width: 255,
                    }}
                  />

                  <Typography
                    className="subtitle-text"
                    style={{
                      color: "#FFFFFF",
                      fontWeight: 600,
                      fontSize: 32,
                      padding: "45px 10px 0px",
                      textAlign: "center",
                    }}
                  >
                    {`Welcome to Look System`}
                  </Typography>

                  <Typography
                    className="subtitle-text"
                    style={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      padding: "30px 0px 0px",
                      fontSize: 20,
                      textAlign: "center",
                    }}
                  >
                    {`Integrated Efficiency, Elevated Experiences:`}
                  </Typography>

                  <Typography
                    className="subtitle-text"
                    style={{
                      color: "#FFFFFF",
                      fontWeight: 400,
                      // padding: "30px 0px 0px",
                      fontSize: 20,
                      textAlign: "center",
                    }}
                  >
                    {`Your Business Solutions`}
                  </Typography>
                </Col>
              </Row>

              {/* <Row className="row-img-background">
                <Image
                  className="img-background"
                  src="/assets/logos/building.png"
                  preview={false}
                />
              </Row> */}

              <Row
                className="ver-row"
                align="bottom"
                style={{
                  position: "absolute",
                  bottom: 25,
                  width: "100%",
                }}
              >
                <Col
                  span={24}
                  className="ver-col"
                  style={{
                    width: "100%",
                  }}
                >
                  <Row className="ver-col-row" justify="center" align="middle">
                    <Typography
                      className="ver-text"
                      style={{
                        color: "#FFFFFF",
                        textAlign: "center",
                        // fontFamily: "Roboto",
                        fontSize: 14,
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        margin: "0px 0px 20px",
                      }}
                    >
                      {`2023 © Copyright Look System Ver ${version || ""}`}
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col
              // span={16}
              className="col-login"
              xxl={16}
              xl={16}
              lg={16}
              md={16}
              sm={24}
              xs={24}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#EDEFF7",
                flexDirection: "column",
                alignContent: "center",
                width: "100%",
                minHeight: 710,
                backgroundImage: "url(/background.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "right 0px bottom -200px",
              }}
            >
              {/* <Row
                className="row-greetings"
                justify="start"
                align="middle"
                style={{
                  display: "flex",
                  textAlign: "start",
                }}
              > 
                <Typography
                  className="text-greetings"
                  style={{
                    color: "#3F4254",
                    fontSize: 26,
                    fontWeight: 600,
                    paddingRight: 10,
                  }}
                >
                  Welcome to
                </Typography>

                <Typography
                  className="text-hotel-name"
                  style={{
                    color: "#1BC5BD",
                    fontSize: 26,
                    fontWeight: 600,
                  }}
                >
                  Sign In
                </Typography>
              </Row>

              <Row className="row-hotel-slogan">
                <Typography
                  className="text-slogan"
                  style={{
                    color: "#888C9F",
                    textAlign: "start",
                    fontSize: 18,
                    fontWeight: 400,
                  }}
                >
                  Lorem Ipsum Dolor Sit Amet
                </Typography>
                </Row> */}

              <Row
                justify="center"
                align="middle"
                className="row-form-login"
                style={{
                  display: "flex",
                  placeContent: "center flex-start",
                  alignItems: "flex-start",
                  textAlign: "start",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography
                  className="text-hotel-name"
                  style={{
                    color: "#3F4254",
                    fontSize: 32,
                    fontWeight: 600,
                    fontStyle: "normal",
                    padding: 0,
                  }}
                >
                  {`Sign In`}
                </Typography>

                <Typography
                  className="text-hotel-name"
                  style={{
                    color: "#888C9F",
                    fontSize: 18,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    padding: "0px 0px 60px",
                  }}
                >
                  {`Please input your valid email and password!`}
                </Typography>

                <Form
                  className="login-form"
                  key="login-form"
                  name="login-form"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  autoComplete="off"
                  // style={{
                  //   backgroundImage: "/background.png",
                  // }}
                >
                  <Row className="row-login-form">
                    <Col span={24}>
                      <Form.Item
                        className="form-item-login"
                        label={
                          <Typography className="form-item-login">
                            Email
                          </Typography>
                        }
                        name={"email"}
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Please, Input your Email!",
                            warningOnly: true,
                          },
                        ]}
                        style={{
                          fontSize: 16,
                          width: 440,
                          fontWeight: 600,
                        }}
                      >
                        <Input
                          placeholder="Email"
                          size="large"
                          bordered={false}
                          style={{
                            height: 64,
                            fontSize: 14,
                            backgroundColor: "#FFF",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="row-login-form">
                    <Col span={24}>
                      <Form.Item
                        className="form-item-login"
                        label={
                          <Typography className="form-item-login">
                            Password
                          </Typography>
                        }
                        name={"password"}
                        rules={[
                          {
                            required: true,
                            message: "Please, Input your Password!",
                            warningOnly: true,
                          },
                        ]}
                        style={{ fontSize: 16, width: 440, fontWeight: 600 }}
                      >
                        <Input.Password
                          bordered={false}
                          placeholder="Password"
                          size="large"
                          style={{
                            height: 64,
                            fontSize: 14,
                            backgroundColor: "#FFF",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* <Form.Item
                    className="sign-btn-form"
                    style={{
                      textAlign: "start",
                      justifyContent: "center",
                    }}
                  > */}
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="next-btn"
                    loading={loading}
                    style={{
                      height: 38,
                      minWidth: 77,
                    }}
                  >
                    {`Sign In`}
                  </Button>
                  {/* </Form.Item> */}
                </Form>
              </Row>

              <Row
                className="row-t&c"
                align="bottom"
                style={{
                  position: "absolute",
                  bottom: 25,
                }}
              >
                <Col span={24} className="col-t&c">
                  <Row
                    className="row-contact"
                    align="middle"
                    justify="center"
                    style={{
                      padding: "0px 0px 20px",
                    }}
                  >
                    <Space className="foot-space" size={"large"}>
                      <Typography.Link
                        // href=""
                        // level={5}
                        style={{
                          color: "#00C4F2",
                          fontWeight: 600,
                          fontSize: 17,
                        }}
                      >
                        Terms
                      </Typography.Link>

                      <Typography.Link
                        // href=""
                        // level={5}
                        style={{
                          color: "#00C4F2",
                          fontWeight: 600,
                          fontSize: 17,
                        }}
                      >
                        Contact Us
                      </Typography.Link>

                      <Typography.Link
                        // href=""
                        // level={5}
                        style={{
                          color: "#00C4F2",
                          fontWeight: 600,
                          fontSize: 17,
                        }}
                      >
                        About Us
                      </Typography.Link>
                    </Space>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

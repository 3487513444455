// Import React Components
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// Import Ant Design Components
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  Typography,
  Image,
  Divider,
  Table,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import POSPreBillFormTrx from "../../../Forms/POSPreBill/POSPreBillTrx";

// CODE
export default function ModalPrintPreBill({
  openModal,
  closeModal,
  header,
  detail,
}) {
  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);

  // Modal State
  const [open, setOpen] = useState(false);
  const [dataHeader, setDataHeader] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  // USE REF
  const componentRef = useRef();

  // FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(openModal);
    }
    if (!cleanUp) {
      setDataHeader(header);
      setDataDetail(detail);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, header, detail]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);

    form.resetFields();
    // setEdit(null);
  };

  // ON FINISH
  const pageStyle = "@media print{@page {size: landscape}}";
  const onFinish = () => {
    // console.log("CLEAN");

    handlePrint();
  };

  // HANDLE REACT PRINT
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // {
    //   return <GuestReportList ref={componentRef} />;
    // },
    documentTitle: `POS Pre-Bill ${dataHeader?.order_id_alias}`,
    onafterprint: () => alert("Print Success"),

    // pageStyle: pageStyle,
  });

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row>
              <FormOutlined style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View POS Pre-Bill`}
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Content
          className="content-print-form"
          ref={componentRef}
          style={{
            //   border: "solid 1px",
            padding: 30,
          }}
        >
          <POSPreBillFormTrx
            totalPayment={0}
            dataArticle={dataDetail.map((e) => {
              return {
                ...e,
                name: dataHeader.name ?? "NON STAY GUEST",
                payment_status: dataHeader.payment_status,
                sales_type: dataHeader.sales_type,
              };
            })}
          />
        </Content>
      </Modal>

      {/* <GuestReportList ref={componentRef} /> */}
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH & TOKEN
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Page Components
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notification
import {
  failedFetch,
  incomplete,
} from "../../../Reusable/Notification/Notification";
import {
  failedOutlet,
  successOutlet,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

const { confirm } = Modal;

// CODE
export default function TableCategoryTable({
  // Search
  is_search,
  searchKey,
  // Locations
  outlet_loc,
}) {
  // Navigate
  const navigate = useNavigate();

  // CONTEXT
  const { getTableCategory, getMasterRoom } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // Location state
  const [outletLocation, setOutletLocation] = useState({
    locations: "",
    locations_id: 0,
  });
  const [columnType, setColumnType] = useState(1);
  // Loading
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async (value) => {
    await getTableCategory({
      type: "category-by-outlet",
      outlet_id: value?.locations_id || outletLocation?.locations_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: async (response) => {
        console.log("Outlet Location List: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }

        // handleSuccess();
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Fetch Room
  const fetchRoom = async () => {
    await getMasterRoom({
      main: "main",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ISI Room List >> ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            return !val?.is_deleted;

            // if (val.id == 1) return val;
          });

          // let sortir = filtered.sort((a, b) => a.room_number - b.room_number);

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        // setFilteredList(filtered);
        // console.log("ISI Room List >> ", filtered);
        setLoading(false);
      },
      onReject: (error) => {
        failedFetch(error);
        setLoading(false);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Outlet Location: ", outlet_loc);

    if (outlet_loc.locations_id > 0 && !cleanUp) {
      setOutletLocation({
        locations: outlet_loc?.locations,
        locations_id: outlet_loc?.locations_id,
      });

      handleHeaderValue(outlet_loc);

      handleSelect(outlet_loc);
    } else {
      setOutletLocation({
        locations: "",
        locations_id: 0,
      });

      setData([]);
      setBulks([]);
      setIsDisabled(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [outlet_loc]);

  // USE EFFECTS
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Handle Select
  const handleSelect = (value) => {
    const _location = value?.locations.toLowerCase();

    console.log("loc: ", _location);

    if (_location.includes("room")) {
      setColumnType(2);
      fetchRoom();
      setIsDisabled(true);
    } else {
      setColumnType(1);
      fetchData(value);
      setIsDisabled(false);
    }
  };

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12} className="edit-col">
                <Tooltip className="edit-tooltip" title="Edit Data">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);

                      setDataEdit(record);
                      handleFormValue(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="delete-col">
                <Tooltip className="edit-tooltip" title="Delete Data">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "table_category_name",
      key: "table_category_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Code",
      dataIndex: "table_category_code",
      key: "table_category_code",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.created_by.localeCompare(b.created_by),
    },
    {
      title: "Outlet Location",
      dataIndex: "outlet_name",
      key: "outlet_name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const columnRoom = [
    {
      title: "Room ID",
      dataIndex: "room_id",
      key: "room_id",
    },
    {
      title: "Room Name",
      dataIndex: "room_name",
      key: "room_name",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Category",
      dataIndex: "room_category_code",
      key: "room_category_code",
    },
  ];

  // Set Fields Header
  const handleHeaderValue = (value) => {
    // console.log("Headers: ", value);

    form.setFieldsValue({
      outlet_name: value?.locations,
      outlet_id: value?.locations_id,
    });
  };

  // Set Form Fields
  const handleFormValue = (value) => {
    const _rec = value;

    form.setFieldsValue({
      table_category_name: _rec?.table_category_name || null,
      table_category_code: _rec?.table_category_code || null,

      outlet_name: _rec?.outlet_name || null,
      outlet_id: _rec?.outlet_id || null,
      description: _rec?.description || null,

      created_by: _rec?.created_by,
      created_date: _rec?.created_date,
      updated_by: _rec?.updated_by,
      updated_date: _rec?.updated_date,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setIsDelete(false);
    setOpen(true);
  };

  // Handle Refresh
  const handleRefresh = () => {
    switch (columnType) {
      case 1:
        fetchData();
        break;

      case 2:
        fetchRoom();
        break;

      default:
        break;
    }
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setIsDelete(true);
    setOpen(false);
    setEdit(false);
    setIsLoading(false);

    form.resetFields([
      "table_category_name",
      "table_category_code",
      "description",
      "updated_by",
      "created_by",
      "updated_date",
      "created_date",
    ]);
  };

  // ON FINISH
  const onFinish = (value) => {
    const _data = value;
    console.log("Finish: ", _data);

    setIsLoading(true);
    showModalConfirm(_data);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _value = val;

    console.log("On Finish Failed: ", _value.values);
    incomplete(_value);
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const _submit = value;

    confirm({
      className: "confirm-modal",
      title: isDelete
        ? `Are you sure want to Delete ${
            _submit?.table_category_name || "Unknown"
          } from the table?`
        : `Are you sure want to ${
            edit ? "Update " : "Add New "
          } Table Category named ${_submit?.table_category_name || "Unknown"}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(_submit) : handleSubmit(_submit);
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Submit
  const handleSubmit = (val) => {
    // console.log("ON OK ==> ", val);

    switch (edit) {
      case true:
        handleUpdate(val);
        break;

      case false:
        handleCreate(val);
        break;

      default:
        break;
    }
  };

  // Handle Create
  const handleCreate = async (val) => {
    const _data = val;

    console.log("Creates ==> ", _data);

    await axios
      .post(
        `${baseurl}/pos/master/table-category`,
        {
          table_category_name: _data?.table_category_name,
          table_category_code: _data?.table_category_code,
          outlet_id: outletLocation.locations_id || _data?.outlet_id,
          outlet_name: outletLocation.locations || _data?.outlet_name,
          description: _data?.description,

          created_by: user_name,
        },
        { headers: `Bearers ${token || ""}` }
      )
      .then((response) => {
        console.log("Res Creates: ", response);

        if (response.status === 200) {
          fetchData();

          successOutlet(response, { method: 1, params: 2 });
          handleCancel();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 1, params: 2 });
        setIsLoading(false);
      });
  };

  // Handle Update
  const handleUpdate = async (val) => {
    const _data = val;

    console.log("Update ==> ", _data);

    await axios
      .put(
        `${baseurl}/pos/master/table-category`,
        {
          id: dataEdit?.id,
          table_category_name:
            _data?.table_category_name || dataEdit?.table_category_name,
          table_category_code:
            _data?.table_category_code || dataEdit?.table_category_code,
          outlet_id: outletLocation.locations_id || dataEdit?.outlet_id,
          outlet_name: outletLocation.locations || dataEdit?.outlet_name,
          description: _data?.description,

          updated_by: user_name,
        },
        { headers: `Bearers ${token || ""}` }
      )
      .then((response) => {
        console.log("Res Update: ", response);

        if (response.status === 200) {
          fetchData();

          successOutlet(response, { method: 2, params: 2 });
          handleCancel();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 2, params: 2 });
        setIsLoading(false);
      });
  };

  // Handle Delete
  const handleDelete = async (e) => {
    const _delete = e;

    const obj = {
      ["id"]: _delete?.id,
      ["deleted_by"]: user_name,
    };

    console.log("ON Delete ==> ", obj);

    await axios
      .delete(`${baseurl}/pos/master/table-category`, {
        data: obj,
        headers: `Bearers ${token || ""}`,
      })
      .then((response) => {
        console.log("Res Update: ", response);

        if (response.status === 200) {
          successOutlet(response, { method: 3, params: 2 });
          handleCancel();

          fetchData();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 3, params: 2 });
        setIsLoading(false);
      });
  };

  // Handle Export
  const handleExport = () => {
    downloadExcel(
      data,
      `Table Category ${outletLocation.locations || " - "} List`
    );
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    // console.log("Type = ", query);
    // console.log("Bulk = ", bulks);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item);

        if (columnType === 1) {
          const _name = item?.table_category_name.toLowerCase();

          return _name.indexOf(query) !== -1;
        } else {
          const _name = item?.table_category_name.toLowerCase();

          return _name.indexOf(query) !== -1;
        }
      });

      // Trigger render with updated values
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row
        className="payment-card-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-modal-btn" gutter={[0, 15]}>
            <Col
              sm={24}
              xl={12}
              xxl={12}
              lg={12}
              md={24}
              xs={24}
              className="col-exp-btn"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  disabled={outletLocation?.locations_id > 0 ? false : true}
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="col-btn"
              sm={24}
              xl={12}
              xxl={12}
              lg={12}
              md={24}
              xs={24}
            >
              <Row justify="end" align="top" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  disabled={isDisabled}
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Table Category`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="table-cat-table"
              key="table-cat-table"
              name="table-cat-table"
              loading={loading}
              // rowSelection={{
              //   type: selectionType,
              //   ...rowSelection,
              // }}
              columns={columnType > 1 ? columnRoom : columns}
              dataSource={data}
              bordered
              //onChange={onChange}
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => {
                return record.id ? record.id : record.key;
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-table-cat-modal"
        title={
          edit == false ? (
            <Row className="row-modal-title">
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Table Category`}
              </Typography>
            </Row>
          ) : (
            <Row className="row-modal-title">
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Table Category`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="table-cat-modal-form"
          name="table-cat-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="main-form-row" align="middle" justify="start">
            <Col span={24} className="main-form-col">
              <Row className="loc-table-row" gutter={30}>
                <Col span={16}>
                  <Form.Item label="Outlet Location" name="outlet_name">
                    <Input placeholder="Outlet Location" disabled />
                  </Form.Item>

                  <Form.Item hidden label="Outlet ID" name="outlet_id">
                    <Input placeholder="Outlet ID" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="row-table-cat-id" gutter={30}>
                <Col span={8}>
                  <Form.Item
                    label="Table Category Name"
                    name="table_category_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input a Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Table Category" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Table Category Code"
                    name="table_category_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input a Code!",
                      },
                    ]}
                  >
                    <Input placeholder="Table Category Code" />
                  </Form.Item>
                </Col>
              </Row>

              <Col span={16}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea
                    className="desc-input"
                    showCount
                    maxLength={200}
                    rows={4}
                    placeholder="Descriptions"
                  />
                </Form.Item>
              </Col>

              <Divider
                className="form-divider"
                style={{ margin: "10px 0px ", background: "#EBEDF3" }}
              />

              {edit == true ? (
                <>
                  <Row gutter={30} className="created_date-table-cat">
                    <Col span={8}>
                      <Form.Item
                        label="Created By"
                        name="created_by"
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Created By" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Created Date"
                        name="created_date"
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Created Date" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Updated By"
                        name="updated_by"
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Updated By" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="mod-date-table-cat">
                    <Col span={8}>
                      <Form.Item
                        label="Updated Date"
                        name="updated_date"
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Updated Date" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    className="form-divider"
                    style={{ margin: "0px 0px 5px", background: "#EBEDF3" }}
                  />
                </>
              ) : null}
            </Col>
          </Row>

          <Row className="table-cat-modal-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import {
  Button,
  Col,
  DatePicker,
  Layout,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { HiOutlinePencilSquare } from "react-icons/hi2";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import WarehouseList from "../../../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";
import ReceivingSummaryTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/ReceivingSummary/Table";
import ArticleVendor from "../../../../../../Components/Reusable/Master/ArticleVendor/ArticleVendor";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";

export default function ReceivingSummaryReportPage() {
  const [article, setArticle] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [element, setElement] = useState(null);
  const [date, setDate] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [value, setValue] = useState(null);
  // SEARCH
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };
  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/incoming-stock";

    navigate(`${path}`);
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticle = (value) => {
    setArticle(value);
    setWarehouse(null);
  };

  // HANDLE SELECTED WAREHOUSE
  const handleSelectedWarehouse = (value) => {
    setWarehouse(value);
    setArticle(null);
  };

  // HANDLE FILTER
  const handleFilter = (val) => {
    const _type = val;

    setFilterValue(_type);

    if (_type == 1) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`Article Name`}</Typography>

          <ArticleVendor
            selectedArticle={handleSelectedArticle}
            article_name={null}
            is_disable={false}
          />
        </Col>
      );
    } else if (_type == 2) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`Warehouse`}</Typography>

          <WarehouseList
            selectedItem={handleSelectedWarehouse}
            warehouse_name={null}
            is_disable={false}
          />
        </Col>
      );
    } else {
      null;
    }
  };

  const options = [
    {
      label: "Article Name",
      value: 1,
    },
    {
      label: "Warehouse",
      value: 2,
    },
  ];

  // HANDE REQUEST DATE
  const handleReceivedDate = (e) => {
    const _date = e ? moment(e).format("YYYY-MM-DD") : null;
    setDate(_date);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="receiving-summary-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <HiOutlinePencilSquare
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Receiving Summary Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  width: 200,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Received Date`}</Typography>

                <DatePicker
                  allowClear
                  placeholder="Received Date"
                  className="tr-datepicker"
                  onChange={handleReceivedDate}
                  style={{
                    width: "100%",
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {date ? <ReceivingSummaryTable receivedDate={date} /> : null}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// Import React Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Space,
  List,
  Table,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { MdOutlineHorizontalSplit } from "react-icons/md";
import SplitBillModalTable from "../../../Table/Outlet/Order/SplitBill/TableSplitBill";
import { AiOutlineSave } from "react-icons/ai";
import SavedBillModalTable from "../../../Table/Outlet/Order/SavedBill/TableSavedBill";
import OrderTable from "../../../Table/Outlet/Order/Main/Table";

// Modal
const { confirm } = Modal;

export default function ModalChangeTable(props) {
  // PROPS
  const { openModal, closeModal, selectedTable, isChangeTable } = props;

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [headers, setHeaders] = useState([]);
  const [details, setDetails] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState([]);
  const [selection, setSelection] = useState(null);
  const [outletData, setOutletData] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
      setSelection(JSON.parse(sessionStorage.getItem("selection")));
      setOutletData(JSON.parse(sessionStorage.getItem("outlet_data")));
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // SHOW MODAL
  const showConfirmChangeTableModal = (value) => {
    const _data = value;

    confirm({
      className: "confirm",
      title: `POS Order CHANGE TABLE Confirmation`,
      content: `Are you sure you want to change table to ${(_data?.table_code).toUpperCase()}`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        selectedTable(_data);
        handleCancel();
        Modal.destroyAll();
      },

      onCancel() {
        // setIsLoading(false);
        Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    navigate(`${pathname}/payment`, {
      state: {
        headerData: headers,
        detailData: selectedDetail,
        selectedTable: tableKeys,
        is_split: true,
      },
    });
  };

  const onFinishFailed = (e) => {};

  return (
    <>
      <Modal
        className="change-table-modal"
        title={
          <>
            <Row className="change-table-modal-row">
              <AiOutlineSave
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`POS Change Table`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        {/* <Row
          className="change-table-modal-form-serve-row"
          justify="start"
          gutter={[30, 30]}
          style={{ padding: 20 }}
        ></Row> */}

        <OrderTable
          searchKey={null}
          is_search={() => {}}
          table_number={(e) => {
            showConfirmChangeTableModal(e);
          }}
          selections={selection}
          outlet_data={outletData}
          is_roomService={null}
          isChangeTable={true}
        />

        {/* <Divider
          className="change-table-modal-form-divider-form"
          style={{ margin: "15px 0px" }}
        />

        <Row justify="end" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            key={"submit"}
            onClick={(e) => {
              selectedTable(tableKeys);
              handleCancel();
            }}
            loading={loading}
            className="submit-btn"
            style={{
              // backgroundColor: "#1BC5BD",
              // borderColor: "#1BC5BD",
              marginRight: 30,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={handleCancel}>
            {`Cancel`}
          </Button>
        </Row> */}
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Modal,
  Typography,
  Input,
  TextArea,
  DatePicker,
  InputNumber,
  Tooltip,
  Checkbox,
} from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { TiEdit } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Pop Over
import MicePopover from "../../../Popover/MICE/Popover";

// Master Components
import MasterGuest from "../../../Reusable/Master/Guest/Guest";
import MasterPaymentType from "../../../Reusable/Master/PaymentType/PaymentType";
import MasterPaymentCard from "../../../Reusable/Master/PaymentCard/PaymentCard";
import MasterCurrency from "../../../Reusable/Master/Currency/Currency";
import MasterArticleMain from "../../../Reusable/Master/ArticleMain/ArticleMain";
import MasterArticleSub from "../../../Reusable/Master/ArticleSub/ArticleSub";
import MasterArticleList from "../../../Reusable/Master/ArticleList/ArticleList";
import MasterReservationStatus from "../../../Reusable/Master/ResStatus/ResStatus";
import NonStayTable from "../Article/Table";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notification
import {
  failedFetch,
  failedNonStay,
  successNonStay,
} from "../../../Reusable/Notification/Notification";
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// CONST
const { confirm } = Modal;

// CODE
export default function MICEReservationsTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Params
  const currency = CurrencySymbols().code;

  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // CONTEXT
  const { getReservationMice } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Payment
  const [payment_status, setPayment_status] = useState(1);
  const [payCode, setPayCode] = useState({
    name: "",
    id: 0,
  });
  const [payId, setPayId] = useState(null);
  // Guest ID
  const [guest, setGuest] = useState({
    value: "",
    label: "a",
    nationality: "",
    id: 0,
    birthdate: "",
    rate_code: [],
    rate_id: [],
    segment_name: [],
    segment_id: [],
    company_name: "",
    company_id: 0,
    is_allow: false,
    credit_limit: 0,
  });
  // Data Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Master Data
  const [articlePrice, setArticlePrice] = useState({
    nett: 0,
    tax: 0,
    service: 0,
    gross: 0,
  });
  // MODAL
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // USE NAVIGATIONS
  const navigate = useNavigate();

  // Cancel
  const move = async () => {
    navigate(`/f-and-b/mice`);
  };

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataEdit && !cleanUp) {
      console.log("edit is running...");
      setPayment_status(dataEdit.payment_status ? dataEdit.payment_status : 1);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataEdit]);

  // USE EFFECT REFRESH
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Fetch Data
  const fetchData = async () => {
    await getReservationMice({
      mice: "mice",
      type: "reservation",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Non Stay Response = ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-non-stay-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(!open);
                      setEdit(!edit);
                      setDataEdit(record);

                      handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <MicePopover
                  // onClick={() => {
                  //   return record;
                  // }}
                  is_history={false}
                  details={record}
                  is_refresh={setRefresh}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation Number",
      dataIndex: "reservation_id",
      key: "reservation_id",
      // fixed: "left",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // fixed: "left",
      width: 250,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    },
    {
      title: "Event Start",
      dataIndex: "event_start",
      key: "event_start",
      render: (date, record) => {
        return moment(date).format("DD MMM YYYY");
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.event_start.localeCompare(b.event_start),
    },
    {
      title: "Event End",
      dataIndex: "event_end",
      key: "event_end",
      render: (date, record) => {
        return moment(date).format("DD MMM YYYY");
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.event_end.localeCompare(b.event_end),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // Handle Form Fields
  const handleFormField = (value) => {
    const record = value;

    form.setFieldsValue({
      event_start: moment(record.event_start),
      event_end: moment(record.event_end),
      currency: record.currency ? record.currency : null,
      guest_name: record.guest_name ? record.guest_name : null,
      bill_receiver: record.bill_receiver ? record.bill_receiver : null,
      bill_instruction: record.bill_instruction
        ? record.bill_instruction
        : null,
      payment_type: record.payment_type ? record.payment_type : null,
      payment_details: record.payment_detail ? record.payment_detail : null,
      payment_status: record.payment_status ? record.payment_status : 1,
      description: record.description ? record.description : null,
      deposit: record.deposit ? record.deposit : 0,

      reservation_id: record.reservation_id,
    });
  };

  // GUEST NAME, BILL RECEICER & BILL INSTRUCTION
  const getGuestName = (value, code) => {
    const _guest = value;
    console.log("Guest: ", _guest);

    setGuest(_guest);

    form.setFieldsValue({
      guest_name: _guest?.value,
      bill_instruction: _guest?.value,
      bill_receiver: _guest?.value,
    });
  };

  // Payment Type
  const getPaymentType = (value, code, name) => {
    console.log(value, code, name);

    // console.log(payCode);
    setPayCode({
      id: value?.id,
      name: value?.name,
    });

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };

  // Payment Type ID
  const getPayTypeId = (value) => {
    console.log(value);
  };

  // Payment Details/Card
  const getPayCard = (value, code, name) => {
    console.log(value, code, name);
    const fieldvalue = form.getFieldsValue();

    // console.log(fieldvalue);

    // setPayCode(code);

    form.setFieldsValue({
      payment_details: value,
    });
  };

  // Get Currency
  const getCurrency = (value) => {
    if (edit == true) {
      dataEdit.currency = value;
    }

    form.setFieldsValue({
      currency: value,
    });
  };

  const getResStatus = (value) => {
    if (edit == true) {
      dataEdit.reservation_status = value;
    }
    form.setFieldsValue({
      reservation_status: value,
    });
  };

  // RADIO STATUS PAYMENTS
  const onRadioChange = async (e) => {
    console.log("radio checked", e.target.value);

    setPayment_status(e.target.value);

    form.setFieldsValue({
      payment_status: e.target.value,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;
    console.log(content);

    setIsLoading(true);
    showModalConfirm(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const content = value;
    console.log("FAILED  >> ", content?.values);

    masterIncomplete(content);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setDataEdit(null);
    setIsLoading(false);

    setGuest({
      value: "",
      label: "",
      nationality: "",
      id: 0,
      birthdate: "",
      rate_code: [],
      rate_id: [],
      segment_name: [],
      segment_id: [],
      company_name: "",
      company_id: 0,
      is_allow: false,
      credit_limit: 0,
    });

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const content = value;

    console.log("Submit => ", content);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to ${
        edit ? "Update" : "Add a New"
      } MICE Reservations with named ${
        content?.guest_name.toUpperCase() || " --- "
      }?`,
      // icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("SUBMIT >> ", contentSubmit);
    console.log("Guest: ", guest);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/fnb/mice/reservation`,
          {
            guest_id: guest.guest_id,
            guest_name: contentSubmit?.guest_name
              ? contentSubmit.guest_name
              : null,
            event_start: contentSubmit?.event_start
              ? contentSubmit.event_start
              : moment(),
            event_end: contentSubmit?.event_end
              ? contentSubmit.event_end
              : moment(),
            description: contentSubmit?.description
              ? contentSubmit.description
              : null,
            bill_receiver: contentSubmit?.bill_receiver
              ? contentSubmit.bill_receiver
              : null,
            bill_instruction: contentSubmit?.bill_instruction
              ? contentSubmit.bill_instruction
              : null,
            payment_type: contentSubmit?.payment_type
              ? contentSubmit.payment_type
              : null,
            payment_details: contentSubmit?.payment_details
              ? contentSubmit.payment_details
              : null,
            payment_status: contentSubmit?.payment_status
              ? contentSubmit.payment_status
              : 1,
            currency: contentSubmit?.currency ? contentSubmit.currency : null,
            // deposit: contentSubmit?.deposit
            //   ? parseInt(contentSubmit.deposit)
            //   : 0,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("POST NON-STAY >>> ", response);
          successNonStay(response, { method: 0 });
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("POST NON-STAY >>>> ", error);
          failedNonStay(error, { method: 0 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/fnb/mice/reservation`,
          {
            reservation_id: dataEdit.reservation_id,
            // guest_id: guest,
            guest_name: contentSubmit?.guest_name
              ? contentSubmit.guest_name
              : dataEdit.guest_name,
            event_start: contentSubmit?.event_start
              ? moment(contentSubmit.event_start).format("YYYY-MM-DD")
              : moment(dataEdit.event_start),
            event_end: contentSubmit?.event_end
              ? moment(contentSubmit.event_end).format("YYYY-MM-DD")
              : moment(dataEdit.event_end),
            description: contentSubmit?.description
              ? contentSubmit.description
              : dataEdit.description,
            bill_receiver: contentSubmit?.bill_receiver
              ? contentSubmit.bill_receiver
              : dataEdit.bill_receiver,
            bill_instruction: contentSubmit?.bill_instruction
              ? contentSubmit.bill_instruction
              : dataEdit?.bill_instruction,
            payment_type: contentSubmit?.payment_type
              ? contentSubmit.payment_type
              : dataEdit.payment_type,
            payment_details: contentSubmit?.payment_details
              ? contentSubmit.payment_details
              : dataEdit.payment_details,
            payment_status: contentSubmit?.payment_status
              ? contentSubmit.payment_status
              : 1,
            currency: contentSubmit?.currency
              ? contentSubmit.currency
              : dataEdit.currency,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("UPDATE MICE: ", response);
          successNonStay(response, { method: 1 });
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("POST NON-STAY >>>> ", error);
          failedNonStay(error, { method: 1 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.guest_name.toLowerCase();

        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  // console.log("Is EDIT ??? ", edit);
  // console.log(payment_status);

  return (
    <>
      <Row className="mice-guest-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: "30px" }} className="col-table">
          <Row justify="end" className="row-add-btn">
            <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
              style={{
                marginRight: 15,
              }}
            >
              {`Add MICE Reservation`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
              style={{
                marginRight: 15,
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              icon={
                <IoReturnUpBackOutline
                  className="return-icons"
                  style={{
                    margin: "0px 5px 0px 0px",
                  }}
                />
              }
              onClick={move}
            >
              {`Return`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="non-stay-table"
              name="non-stay-table"
              key="non-stay-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      {/* MODAL */}
      <Modal
        className="mice-modal"
        name="mice-modal"
        key="mice-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add MICE Reservation`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit MICE Reservation`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 15,
        }}
        footer={null}
      >
        <Form
          name="mice-modal-form"
          className="mice-modal-form"
          key="mice-modal-form"
          // title=""
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={16}>
              <Form.Item
                label="Reservation ID"
                name="reservation_id"
                hidden={!edit}
              >
                <Input
                  className="res-input"
                  placeholder="Reservation ID"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={16}>
              <Form.Item
                label="Guest Name"
                name="guest_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Guest Name!",
                  },
                ]}
              >
                <MasterGuest
                  getGuestName={getGuestName}
                  name={edit ? dataEdit.guest_name : null}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-mice-id" justify="start">
            <Col span={8} className="event-col">
              <Form.Item
                label="Event Start"
                name="event_start"
                rules={[
                  {
                    required: true,
                    message: "Please, insert the event start!",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD"}
                  disabledDate={disabledDate}
                  placeholder={"Start Date"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="event-col">
              <Form.Item
                label="Event End"
                name="event_end"
                rules={[
                  {
                    required: true,
                    message: "Please, insert the event end!",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD"}
                  disabledDate={disabledDate}
                  placeholder={"End Date"}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            {/* <Col span={8}>
              <Form.Item label="Reservation Status" name="reservation_status">
                <MasterReservationStatus
                  getResStatus={getResStatus}
                  res={edit ? dataEdit.reservation_status : ""}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Departure"
                name="departure"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Departure Date!",
                  },
                  {
                    validator: async (_, departure) => {
                      var arrival = form.getFieldValue("arrival");
                      // console.log("ARRIVAL => ", arrival);
                      // console.log("DEPART => ", departure);
                      // console.log("DIFF", (departure = arrival));

                      if (arrival != null)
                        if (departure <= moment(arrival).add(1, "days")) {
                          return Promise.reject(
                            "Departure Date Must be Greater than Arrival Date."
                          );
                        }
                    },
                  },
                ]}
                style={{ margin: "0px 30px 0px 0px", width: 205 }}
              >
                <DatePicker
                  format={"YYYY-MM-DD"}
                  // onChange={handleFirstDate}
                  disabledDate={disabledDate}
                  placeholder={"Start Date"}
                  style={{
                    width: 205,
                  }}
                />
              </Form.Item>
                </Col> */}
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-mice-desc">
            <Col span={8} className="pay-col">
              <Form.Item
                label="Payment Type"
                name="payment_type"
                rules={[
                  {
                    required: true,
                    message: "Please, Select the Payment Type!",
                  },
                ]}
              >
                <MasterPaymentType
                  getPaymentType={getPaymentType}
                  // getPayTypeId={setPayId}
                  pay={edit ? dataEdit.payment_type : null}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="pay-col">
              <Form.Item
                label="Payment Details"
                name="payment_details"
                rules={[
                  {
                    required: true,
                    message: "Please, Select the Payment Details!",
                  },
                ]}
                style={{ minWidth: "100%" }}
              >
                <MasterPaymentCard
                  getPayCard={getPayCard}
                  getPayTypeId={payId}
                  // getCode={}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="currency-col">
              <Form.Item label="Currency" name="currency">
                <MasterCurrency
                  getCurrency={getCurrency}
                  pay={edit ? dataEdit.currency : null}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-article-data">
            <Col span={12} className="bill-col">
              <Form.Item label="Bill Instruction" name="bill_instruction">
                <Input placeholder="Bill Instruction" />
              </Form.Item>
            </Col>

            <Col span={12} className="bill-col">
              <Form.Item label="Bill Receiver" name="bill_receiver">
                <Input placeholder="Bill Receiver" />
              </Form.Item>
            </Col>
          </Row>

          {/* <Divider style={{ margin: "0px 0px 15px" }} /> */}

          {/* <Row gutter={30} className="row-mice-pay">
            <Col span={8}>
              <Form.Item label="Deposit" name="deposit">
                <InputNumber
                  addonBefore={currency}
                  style={{
                    width: 205,
                  }}
                  formatter={(deposit) => {
                    return `${deposit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  placeholder="Deposit"
                />
              </Form.Item>
            </Col>

            <Col span={8}></Col>

            <Col span={8}></Col>
          </Row> */}

          {/* <Row gutter={30} className="row-mice-pay">
            <Col span={8}></Col>

            <Col span={8}></Col>

            <Col span={8}></Col>
          </Row> */}

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          <Row className="row-mice-desc" gutter={30}>
            <Col span={16} className="desc-col">
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  className="area-desc"
                  showCount
                  allowClear
                  maxLength={200}
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>

            <Col span={8} className="pay-col">
              <Form.Item
                label="Payment Status"
                name="payment_status"
                rules={[
                  {
                    required: true,
                    message: "Please, Select a Payment Status!",
                  },
                ]}
                style={{
                  minWidth: "100%",
                }}
              >
                <Radio.Group
                  className="radio-group"
                  onChange={onRadioChange}
                  value={payment_status}
                >
                  <Space direction="vertical" className="bill-space">
                    <Radio value={1}>{`Cash Basis`}</Radio>
                    <Radio value={2}>{`Master Bill Active`}</Radio>
                    {/* <Radio value={3}>{`Deposit`}</Radio> */}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* {edit == true ? (
            <>
              <Row justify="end">
                <Button
                  type="primary"
                  className="add-article-mice-btn"
                  icon={<PlusOutlined />}
                  onClick={addArticle}
                >
                  Add Article
                </Button>
              </Row>

              <Divider style={{ margin: "15px 0px 15px" }} />

              <Row gutter={30} className="created-date-mice">
                <NonStayTable editArticle={editArticle} newArticle={article} />
              </Row>
            </>
          ) : (
            <></>
          )} */}

          <Divider
            className="divider-form"
            style={{ background: "#EBEDF3", margin: "0px 0px 15px" }}
          />

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

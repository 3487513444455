import { Decoder } from "../../../../API/Global/Decoder";

// CODE
export const TaxesParameter = () => {
  // Get Params
  let params = sessionStorage.getItem("par-1");

  const _decode = Decoder(params);

  //   console.log("Taxes: ", _decode);
  return {
    tax_percentage: _decode?.tax_percentage,
    service_percentage: _decode?.service_percentage,
  };
};

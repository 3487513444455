// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiOpenBook } from "react-icons/gi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import ArticleSubGroupTable from "../../../Components/Table/Master/ArticleSubGroup/Table";
import MasterDepartment from "../../../Components/Reusable/Master/Department/Department";
import MasterArticleMain from "../../../Components/Reusable/Master/ArticleMain/ArticleMain";

// Import Global Style
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function ArticleSubGroupPage() {
  // STATE MANAGEMENT
  // Search Value
  const [value, setValue] = useState(null);
  const [dept, setDept] = useState({
    department_name: "",
    department_id: 0,
  });
  const [mainGroup, setMainGroup] = useState({
    article_main_name: "",
    article_main_id: 0,
  });
  // Search
  const [search, setSearch] = useState(null);

  // Handle Department
  const handleDept = (val) => {
    console.log("Department Code: ", val);

    setDept({
      department_name: val?.name,
      department_id: val?.dept_id,
    });
  };

  // Handle Main Group
  const handleMain = (val) => {
    console.log("Main Code: ", val);

    setMainGroup({
      article_main_name: val?.name,
      article_main_id: val?.main_id,
    });
  };

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="article-sub-group-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <GiOpenBook
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Article Sub-Group List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 225,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Search by Department`}
                </Typography>

                <MasterDepartment
                  getDepartment={handleDept}
                  department_name={null}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 225,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Search by Main-Group`}
                </Typography>

                <MasterArticleMain
                  getArticleMain={handleMain}
                  getDepartmentId={dept.department_id}
                  mainName={null}
                  addArticleState={false}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Keywords`}
                </Typography>

                <Input
                  placeholder="Name of Sub-Group"
                  onChange={handleSearch}
                  allowClear
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 30px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <ArticleSubGroupTable
            searchKey={value}
            deptKey={dept.department_id}
            mainKey={mainGroup.article_main_id}
            is_search={setSearch}
          />
        </Col>
      </Content>
    </Layout>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  FileExcelOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Table's Column
const dataSource = [
  {
    key: "1",
    payment_type: "Debit",
    name: "bukopin-IDR",
    desc: "BUKOPIN",
    coa: "200-01-030",
  },
];

const { TextArea } = Input;
const { confirm } = Modal;

const onChangeSelect = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};
const onChangeTextArea = (e) => {
  console.log("Change:", e.target.value);
};

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// const onChange = (pagination, filters, sorter, extra) => {
//     console.log('params', pagination, filters, sorter, extra);
// };

// CODE
export default function ArticleSummaryTable() {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <>
          <Row className="action-art-sum-btn" style={{ width: "100%" }}>
            <Col span={12}>
              <FormOutlined
                className="edit-guest-btn"
                onClick={() => {
                  console.log("ABOUT TO EDIT > ", record);
                  setOpen(true);
                  setEdit(true);
                  // setDataEdit(record);
                }}
                style={{ fontSize: 20, color: "#3699FF" }}
              />
            </Col>

            <Col span={12}>
              <DeleteFilled
                className="delete-art-sum-btn"
                onClick={() => {
                  console.log("ABOUT TO DELETE > ", record);
                  showDeleteModal(record);
                }}
                style={{ fontSize: 20, color: "#F64E60" }}
              />
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Article No",
      dataIndex: "article_no",
      key: "article_no",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_no.localeCompare(b.article_no),
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Departement Name",
      dataIndex: "dept_name",
      key: "dept_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.dept_name.localeCompare(b.dept_name),
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ];

  // CONTEXT
  const { state } = useContext(MasterContext);

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // STATE MANAGEMENT
  // Data
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = () => {
    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete guest named .... from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const onCreate = (values) => {
    console.log("Received values of form: ", values);
    // setOpen(false);
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
  };

  // useEffect(() => {
  //     fetchRecords()
  // }, [])

  // const fetchRecords = () => {
  //     setLoading(true)
  //     fetch(`http://192.168.88.103:3100/supabase/reservation`).then(res => {
  //         res.json().then(response => {
  //             setDataSource(response.msg)
  //             setLoading(false)
  //             //console.log(response)
  //         }).catch((error) => { console.log(error) });
  //     })
  // }

  // console.log("OPEN = ", open);
  // console.log("EDIT = ", edit);

  return (
    <>
      <Row
        className="art-sum-main-table"
        justify="center"
        style={{ backgroundColor: "#FFFFFF", minHeight: "100%" }}
      >
        <Col span={24} style={{ padding: "30px" }}>
          <Row>
            <Col span={4}>
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  // onClick={}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  Download Excel
                </Button>
              </Row>
            </Col>

            <Col span={20}>
              <Row justify="end">
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: "#1BC5BD",
                    borderColor: "#1BC5BD",
                    marginRight: 15,
                  }}
                >
                  Add Article Summary
                </Button>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={fetchRecords}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              loading={loading}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={dataSource}
              //onChange={onChange}
              pagination={{
                pageSize: 5,
              }}
              // scroll={{
              //     y: 270,
              // }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-article-sum-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                Add Article Summary
              </Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                Edit Article Summary
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="art-sum-modal-form"
          name="art-sum-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          // onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-art-sum-id">
            <Col span={8}>
              <Form.Item
                label="Article Number"
                name="article-no"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Amount" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Article Name"
                name="article-name"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Amount" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Department"
                name="department"
                style={{ width: 205 }}
              >
                <Select
                  showSearch
                  placeholder="Select an Option"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "laundry",
                      label: "Laundry",
                    },
                    {
                      value: "no laundry",
                      label: "No Laundry",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-art-sum-desc">
            <Col span={16}>
              <Form.Item label="Description" name="description">
                <TextArea
                  showCount
                  maxLength={200}
                  style={{
                    height: 100,
                    width: 440,
                  }}
                  onChange={onChangeTextArea}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-art-sum">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-art-sum">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="art-sum-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              Submit
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";

// ANTD COMPONENTS
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

// REACT ICONS
import { FaWpforms } from "react-icons/fa";

// COMPONENTS PAGE
import VendorArticleTable from "../../../Table/BackOffice/Article/Table";

// GLOBAL STYLES
import { cardBody } from "../../../../Style/Global/Global";

// NOTIFICATION
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

export default function ModalVendorArticleView(props) {
  const { is_open, is_close, dataVendor, params } = props;

  //CONTEXT
  const { getArticleVendorList } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [articleTable, setArticleTable] = useState([]);

  // SEARCH
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState();
  const [checked, setChecked] = useState(false);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);

  // Loadings
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // Refresh
  const [refresh, setRefresh] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      // console.log("data Vendor: ", dataVendor);
      setData(dataVendor);
      setChecked(dataVendor?.is_ppn === true ? true : false);

      handleOpen();
      fetchData();
    } else {
      setModalOpen(false);
      is_close(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, dataVendor]);

  // USE EFFECT DELETE
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    // console.log("DATA VENDORR >>>>> ", dataVendor);
    await getArticleVendorList({
      article_vendor: "supplier-article-list",
      id_supplier: dataVendor?.id_supplier
        ? dataVendor.id_supplier
        : data?.id_supplier,
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("RESPONSE SUPPLIER ARTICLE LIST>> ", response);

        if (response?.data.msg != null) {
          let filtered = response.data.msg.filter((val) => {
            if (val.is_deleted == null || val.is_deleted == false) {
              return val;
            }
          });

          setArticleTable(filtered);
        } else {
          setArticleTable([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error >> ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    is_close(false);
    setModalOpen(false);

    setIsLoading(false);
    setData([]);

    form.resetFields();
  };

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    console.log(checked);
  };

  return (
    <>
      <Modal
        className="vendor-management-modal"
        key="vendor-management-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FaWpforms
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Vendor Management Article `}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={1000}
      >
        <Descriptions
          className="supplier-desc"
          bordered
          // labelStyle={{
          //   fontSize: 17,
          //   fontWeight: 750,
          // }}
        >
          <Descriptions.Item
            label="Supplier's Name"
            contentStyle={{
              fontSize: 17,
              fontWeight: 750,
            }}
          >
            {dataVendor?.supplier_name}
          </Descriptions.Item>

          <Descriptions.Item
            label="Contact Person"
            contentStyle={{
              fontSize: 17,
              fontWeight: 750,
            }}
          >
            {dataVendor?.contact_person_supplier}
          </Descriptions.Item>

          <Descriptions.Item>
            <Checkbox
              checked={checked}
              contentStyle={{
                fontWeight: 750,
                fontSize: 17,
              }}
            >
              {dataVendor?.is_ppn ? "VAT's Subject" : "Not VAT's Subject"}
            </Checkbox>
          </Descriptions.Item>
        </Descriptions>

        <Row
          className="row-search-component"
          justify="start"
          style={cardBody}
          // wrap={true}
        >
          <Space align="end" size={15} className="header-space" wrap={true}>
            <Col
              style={{
                textAlign: "start",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography
                style={{ marginBottom: 10 }}
              >{`Search Article`}</Typography>

              <Input
                allowClear
                placeholder="Article Name"
                onChange={handleSearch}
                style={{
                  minWidth: 150,
                }}
              />
            </Col>

            <Col
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Button
                className="search-btn"
                type="primary"
                onClick={search}
                icon={<SearchOutlined />}
              >
                {`Search`}
              </Button>
            </Col>
          </Space>
        </Row>

        <VendorArticleTable
          article={articleTable}
          is_search={setSearch}
          searchKey={value}
          is_refresh={setRefresh}
          is_view={true}
          is_loading={loading}
          params_tax={params}
        />
      </Modal>
    </>
  );
}

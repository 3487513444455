// Import React's Components
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
// import "moment/locale/id";

// AUTH
import { department, email, roles, user_name } from "../../API/Global/Payload";
import { baseurl } from "../../API/Config/Api";

// Import's Auth Components
import * as auth from "../../API/AuthActions/authAction";

// Import Ant Design Components
import {
  Avatar,
  Badge,
  Button,
  Col,
  Descriptions,
  Image,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { DoubleRightOutlined, LogoutOutlined } from "@ant-design/icons";
import { GoSignOut } from "react-icons/go";
import { MdLogout, MdOutlineNotificationsActive } from "react-icons/md";

// Import CSS
import "./Header.css";

// NIGHT AUDIT DATE
import AuditDate from "../../Components/Reusable/DateAudit/Date";

// Import Page Components
import ProgressLoading from "../Loading/ProgressLoad";

// Import Notifications
import { failedLogin } from "../../Components/Reusable/Notification/Notification";

// CONST
const { Text } = Typography;

// Auth Logout
const mapDispatchToProps = (dispatch) => {
  // console.log("DISPATCH: ", dispatch);

  return {
    disLogout: (value) => dispatch(auth.logout(value)),
  };
};

// User's Reducers
const mapStateToProps = (state) => {
  // console.log(state);

  let response = state;
  let user = response.rootReducer.auth;
  let dateChangeNA = response.rootReducer?.nightaudit?.date;

  // const {isLoggedIn, accessToken} = user
  return { user, dateChangeNA };
};

// CODE
const HeaderComponents = (props) => {
  // Date Now
  const today = moment();

  const _date = AuditDate().audit;

  // USE LOCATIONS
  const { pathname, state } = useLocation();

  // STATE MANAGEMENT
  // Date
  // USERS
  const [users, setUsers] = useState("");
  const [dept, setDept] = useState("");
  const [avatar, setAvatar] = useState("X");
  const [roleName, setRoleName] = useState("");
  const [dateNow, setDateNow] = useState(_date);

  // USE NAVIGATE
  const navigate = useNavigate();

  // USER ROLES
  useEffect(() => {
    let cleanUp = false;

    if (props?.user?.user && !cleanUp) {
      const userRoles = props?.user?.user;
      const departm = department;

      // console.log("user roles : ", userRoles);
      // console.log("Department : ", departm);

      handleHeaders(userRoles, department);
    }

    return () => {
      cleanUp = true;
    };
  }, [props, department]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (pathname && state?.length > 0 && !cleanUp) {
      // console.log("State: ", state);
      // console.log("Path: ", pathname);
      let _reqPath = "/back-office/night-audit/success";

      if (pathname.includes(_reqPath)) {
        // console.log("THE DATE MUST'VE BE CHANGED TO THIS! ", state[0]);
        setDateNow(state[0]);
      } else {
        // console.log("From Global ==> ", _date);
        setDateNow(_date);
      }
    } else {
      // console.log("From Global ==> ", _date);
      setDateNow(_date);
    }

    return () => {
      cleanUp = true;
    };
  }, [pathname, _date]);

  // USE EFFECT LOGOUT
  useEffect(() => {
    let cleanUp = false;

    if (props?.user?.isLodggedIn === false && !cleanUp) {
      console.log("Log Out redirect to Login Page!");
      navigate("/login");

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    }

    return () => {
      cleanUp = true;
    };
  }, [props]);

  // FETCH AUDIT
  const fetchAudit = async () => {
    console.log("PROPS: ", props);

    await axios
      .get(`${baseurl}/night-audit/audit-date`)
      .then((response) => {
        console.log("RES Date: ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response?.data?.msg[0];

          setDateNow(_response.audit_date);
        } else {
          setDateNow(_date);
        }
      })
      .catch((error) => {
        console.log("Error get Date Audit: ", error);
        // failedFetch(error);
      });
  };

  // Handle Map Avatar & User Name
  const handleHeaders = (role, deptmnt) => {
    const user_roles = role;

    setUsers(user_name);

    switch (user_roles) {
      case "ROLE_SUPER_ADMIN":
        setAvatar("G");
        setRoleName("Super-Admin");
        break;

      case "ROLE_ADMIN":
        setAvatar("A");
        setRoleName("Admin");
        break;

      case "ROLE_STAFF":
        setAvatar("S");
        setRoleName("Staff");
        break;

      case "ROLE_SUPERVISOR":
        setAvatar("SV");
        setRoleName("SV/HOD");
        break;

      case "ROLE_COST_CONTROL":
        setAvatar("CC");
        setRoleName("Cost Control");
        break;

      case "ROLE_FINANCE_LEADER":
        setAvatar("HoF");
        setRoleName("Head of Finance");
        break;

      case "ROLE_MANAGER":
        setAvatar("M");
        setRoleName("Manager");
        break;

      case "ROLE_GENERAL_MANAGER":
        setAvatar("GM");
        setRoleName("Gen. Manager");
        break;

      case "ROLE_OWNER":
        setAvatar("O");
        setRoleName("Owner/Director");
        break;

      default:
        setAvatar("?");
        setRoleName("?");
        break;
    }

    if (department) {
      setDept(department.toUpperCase());
    } else {
      setDept("?");
    }
  };

  // Handle Navigate
  const handleMove = async (val) => {
    const _flag = val;
    const _user = "/configuration/user-management/user-profile";
    const _task = "/task-list";

    switch (_flag) {
      case 1:
        navigate(`${_user}`);
        break;

      case 2:
        navigate(`${_task}`);
        break;

      default:
        break;
    }
  };

  // HANDLE LOG OUT
  const handleLogOut = (e) => {
    console.log("Log Out: ", e);

    if (email) {
      props.disLogout(email);
    } else {
      failedLogin("Email is Empty!", { method: 1 });
    }
  };

  return (
    <>
      <Row
        className="row-header"
        justify={"space-between"}
        align="middle"
        // gutter={[30, 30]}
        // style={{ padding: "0px 30px" }}
      >
        <div
          className="element-date"
          style={{
            padding: "0px 0px 0px 30px",
          }}
        >
          <Button
            // disabled
            onClick={fetchAudit}
            className="audit-date"
            style={{
              display: "flex",
              verticalAlign: "center",
              alignItems: "center",
              backgroundColor: "#F3F6F9",
              cursor: "pointer",
              border: "0px solid #FFF",
              //   fontSize: 30,
              fontWeight: 500,
            }}
          >
            <span className="date-title">{`Date: `}</span>
            <span className="date-now">
              {props.dateChangeNA
                ? moment(props.dateChangeNA).format("DD MMM YYYY")
                : moment(dateNow).format("DD MMM YYYY")}
            </span>
          </Button>
        </div>

        {/* Log in / Log out */}
        <div
          className="element-user"
          style={{
            padding: "10px 30px 0px 0px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Tooltip
            className="tooltip-btn"
            title={"My Task List"}
            placement="bottom"
            style={{
              marginRight: 10,
            }}
          >
            <Badge
              dot
              className="notif-badge"
              // color={"volcano"}
              key={"list-badges"}
            >
              <Avatar
                className="task-avtr"
                // gap={10}
                onClick={() => {
                  handleMove(2);
                }}
                icon={
                  <MdOutlineNotificationsActive
                    className="task-icon"
                    style={{
                      cursor: "pointer",
                      fontSize: 20,
                      color: "#00C4F2",
                      margin: "5px 0px 0px",
                    }}
                  />
                }
                style={{
                  backgroundColor: "#f0f0f0",
                }}
              />
            </Badge>
          </Tooltip>

          <Tooltip
            className="btn-classname"
            title={
              <>
                <Descriptions
                  title={
                    <Text
                      style={{
                        color: "#FFFFFF",
                      }}
                    >
                      {`User's Info`}
                    </Text>
                  }
                  layout="horizontal"
                  bordered={true}
                  column={1}
                  size="small"
                >
                  <Descriptions.Item label="User Name">
                    {user_name}
                  </Descriptions.Item>

                  <Descriptions.Item label="Department">
                    {dept}
                  </Descriptions.Item>

                  <Descriptions.Item label="Roles">
                    {roleName}
                  </Descriptions.Item>
                </Descriptions>
              </>
            }
            color="#2db7f5" // "#108ee9" // "#bae7ff"
          >
            <Button
              className="button-logged"
              onClick={(e) => {
                console.log("Headers is Clicked: ", e);

                handleMove(1);
                //   sessionStorage.clear();
                //   navigate("/login");

                //   const time = setTimeout(() => {
                //     window.location.reload(false);
                //   }, 100);

                //   return () => clearTimeout(time);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 40,
                backgroundColor: "#F3F6F9",
                border: "0px solid #FFF",
              }}
            >
              <Row
                className="space-header"
                gutter={10}
                // justify="center"
                // align="middle"
                // size="small"
                // direction="horizontal"
              >
                <Col className="user-col">
                  <Row
                    className="user-row"
                    justify="center"
                    align="bottom"
                    style={{
                      margin: "7px 0px 0px",
                    }}
                  >
                    <Typography
                      key={"text-hi"}
                      className="element-user-text-hi"
                      style={{
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {`Hi, `}
                    </Typography>

                    <Typography
                      key={"text-user-name"}
                      className="element-user-text-name"
                      style={{
                        fontSize: 12,
                        fontWeight: 500,
                        // paddingTop: 2,
                        // paddingLeft: "0.5rem",
                        // color: "#7E8299",
                      }}
                    >
                      {users ? users : ""}
                    </Typography>
                  </Row>
                </Col>

                <Col className="avatar-col">
                  <Badge className="user-badge">
                    <Avatar
                      shape="square"
                      className="avatar-username"
                      style={{
                        color: "#1BC5BD",
                        fontWeight: "bolder",
                        fontSize: "20px",
                        backgroundColor: "#C9F7F5",
                        // height: 27,
                        margin: "5px 0px 0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {avatar}
                    </Avatar>
                  </Badge>
                </Col>
              </Row>
            </Button>
          </Tooltip>

          <Tooltip className="logout-tooltip" title="Sign Out?">
            <MdLogout
              className="logout-icon"
              onClick={() => {
                handleLogOut("LOGOUT");
                // sessionStorage.clear();
                // navigate("/login");

                // const time = setTimeout(() => {
                //   window.location.reload(false);
                // }, 100);

                // return () => clearTimeout(time);
              }}
              style={{
                color: "#00C4F2",
                // color: `var(--button-primary, linear-gradient(262deg, #00C4F2 2.77%, rgba(160, 0, 174, 0.40) 139.4%))`,
                fontSize: 22,
                margin: "0px 0px 0px 15px",
              }}
            />
          </Tooltip>
        </div>

        <ProgressLoading />
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponents);

// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, user_name, token } from "../../../API/Global/Payload";
import {
  authorized_by,
  handleAuthorization,
} from "../../../API/Global/Permission";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Button,
  Divider,
  Input,
  Modal,
  InputNumber,
  Checkbox,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import { FiEdit } from "react-icons/fi";
import { FormOutlined } from "@ant-design/icons";
import { BsCalculatorFill } from "react-icons/bs";

// Import Master Components
import MasterCOA from "../../../Components/Reusable/Accounting/MainAccount/MainAccount";
import MasterCurrency from "../../../Components/Reusable/Master/Currency/Currency";
import ModalAuthorized from "../../../Components/Modals/Permissions/ModalCancel";
import InputSkeleton from "../../../Components/Reusable/Skeleton/InputSkeleton";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Functions
import { LocalizationDate } from "../../../Components/Reusable/Functions/Localizations/Localization";
import { Encoder } from "../../../API/Global/Encoder";

// Import Notification
import {
  failedParams,
  successParams,
} from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function AccountingParams() {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [isServiceTaxed, setIsServiceTaxed] = useState(false);
  const [isSegment, setIsSegment] = useState(false);
  // Loading
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // Modals
  const [open, setOpen] = useState(false);
  const [permitted, setPermitted] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    handleSuccess();
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (data && !cleanUp) {
      setFormFields(data);
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "accounting",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Params Acc --> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];
          setData(_res);
        } else {
          setData(null);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Params Acc err --> ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    navigate("/configuration/parameters");
    localStorage.clear();
  };

  // SET FORMS
  const setFormFields = (value) => {
    const _data = value;

    console.log("SET FORMS: ", _data);

    setIsServiceTaxed(_data?.is_service_taxed == true ? true : false);
    setIsSegment(_data?.is_drr_segment_count == true ? true : false);

    form.setFieldsValue({
      ..._data,
      currency: _data?.currency,
      currency_code: _data?.currency_code,
      updated_date: moment(LocalizationDate(_data?.updated_date)).format(
        "HH:mm:ss, DD-MM-YYYY"
      ),
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("ON FINISH >>> ", content);
    setIsLoading(true);
    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const submitContent = value;

    console.log(submitContent);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to update the parameters?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(submitContent);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
      },
    });
  };

  // HANDLE UPDATE
  const handleUpdate = async (value) => {
    const content = value;

    // setLoading(true);

    console.log(content);

    await axios
      .put(
        `${baseurl}/parameters/accounting`,
        {
          id: content?.id ? content.id : data?.id || null,
          journal_header_id: content?.journal_header_id
            ? content.journal_header_id
            : data?.journal_header_id || null,
          journal_details_id: content?.journal_details_id
            ? content.journal_details_id
            : data?.journal_details_id || null,
          journal_code_revenue: content?.journal_code_revenue
            ? content.journal_code_revenue
            : data?.journal_code_revenue || null,
          journal_code_deposit: content?.journal_code_deposit
            ? content.journal_code_deposit
            : data?.journal_code_deposit || null,
          journal_code_income: content?.journal_code_income
            ? content.journal_code_income
            : data?.journal_code_income || null,
          journal_code_expense: content?.journal_code_expense
            ? content.journal_code_expense
            : data?.journal_code_expense || null,
          journal_code_liability: content?.journal_code_liability
            ? content.journal_code_liability
            : data?.journal_code_liability || null,
          receivable_name_code: content?.receivable_name_code
            ? content.receivable_name_code
            : data?.receivable_name_code || null,
          credit_name_code: content?.credit_name_code
            ? content.credit_name_code
            : data?.credit_name_code || null,
          income_name_code: content?.income_name_code
            ? content.income_name_code
            : data?.income_name_code || null,
          expense_name_code: content?.expense_name_code
            ? content.expense_name_code
            : data?.expense_name_code || null,
          deposit_name_code: content?.deposit_name_code
            ? content.deposit_name_code
            : data?.deposit_name_code || null,
          liability_name_code: content?.liability_name_code
            ? content.liability_name_code
            : data?.liability_name_code || null,
          deposit_coa: content?.deposit_coa
            ? content.deposit_coa
            : data?.deposit_coa || null,
          revenue_coa: content?.revenue_coa
            ? content.revenue_coa
            : data?.revenue_coa || null,
          liability_coa: content?.liability_coa
            ? content.liability_coa
            : data?.liability_coa || null,
          cash_deposit: content?.cash_deposit
            ? content.cash_deposit
            : data?.cash_deposit || null,
          receivable_sales: content?.receivable_sales
            ? content.receivable_sales
            : data?.receivable_sales || null,
          cash_sales: content?.cash_sales
            ? content.cash_sales
            : data?.cash_sales || null,
          coa_tax: content?.coa_tax ? content.coa_tax : data?.coa_tax || null,
          coa_service: content?.coa_service
            ? content.coa_service
            : data?.coa_service || null,
          tax_percentage:
            content?.tax_percentage > 0
              ? content.tax_percentage
              : data?.tax_percentage || null,
          currency: content?.currency
            ? content.currency
            : data?.currency || null,
          service_percentage:
            content?.service_percentage > 0
              ? content.service_percentage
              : data?.service_percentage || null,
          currency_code: content?.currency_code
            ? content.currency_code
            : data?.currency_code || null,
          tax_code: content?.tax_code
            ? content.tax_code
            : data?.tax_code || null,
          service_code: content?.service_code
            ? content.service_code
            : data?.service_code || null,
          ap_name_code: content?.ap_name_code
            ? content.ap_name_code
            : data?.ap_name_code || null,
          coa_ap: content?.coa_ap ? content.coa_ap : data?.coa_ap || null,
          others_coa: content?.others_coa
            ? content.others_coa
            : data?.others_coa || null,
          others_name: content?.others_name
            ? content.others_name
            : data?.others_name || null,
          is_service_taxed: isServiceTaxed,
          coefficient_tax_service:
            content?.coefficient_tax_service > 0
              ? content.coefficient_tax_service
              : data?.coefficient_tax_service,
          is_drr_segment_count: isSegment,
          abf_plus_one_day: content?.abf_plus_one_day,

          updated_by: authorized_by || user_name,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Acc --> ", response);

        successParams(response, { method: 3 });

        handleSuccess(content);

        fetchData();
      })
      .catch((error) => {
        console.log("Error --> ", error);
        failedParams(error, { method: 3 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // DEPOSIT COA
  const getDepositCoa = (value) => {
    console.log("Value --> ", value);

    form.setFieldsValue({
      deposit_coa: value?.code,
    });
  };

  // REVENUE COA
  const getRevenueCoa = (value) => {
    console.log(value);

    form.setFieldsValue({
      revenue_coa: value?.code,
    });
  };
  // LIABILITY COA
  const getLiabilityCoa = (value) => {
    console.log(value);

    form.setFieldsValue({
      liability_coa: value?.code,
    });
  };
  // CASH DEPOSIT COA
  const getCashDeposit = (value) => {
    console.log(value);

    form.setFieldsValue({
      cash_deposit: value?.code,
    });
  };
  // RECEIVABLES COA
  const getReceiveCoa = (value) => {
    console.log(value);

    form.setFieldsValue({
      receivable_sales: value?.code,
    });
  };
  // CASH SALES COA
  const getSalesCoa = (value) => {
    console.log(value);

    form.setFieldsValue({
      cash_sales: value?.code,
    });
  };

  // TAX COA
  const getTaxCoa = (value) => {
    console.log(value);

    form.setFieldsValue({
      coa_tax: value?.code,
    });
  };
  // TAX SERVICE
  const getServiceCoa = (value) => {
    console.log(value);

    form.setFieldsValue({
      coa_service: value?.code,
    });
  };

  // AP COA
  const getPayableCoa = (value) => {
    console.log("A/P COA: ", value);

    form.setFieldsValue({
      coa_ap: value?.code,
    });
  };

  // OTHERS COA
  const getOthersCoa = (value) => {
    console.log("Others COA: ", value);

    form.setFieldsValue({
      others_coa: value?.code,
    });
  };

  // Currency
  const getCurrency = (value, symb) => {
    console.log("Value: ", value, symb);

    form.setFieldsValue({
      currency: value,
      currency_code: symb,
    });
  };

  // Handle Tax
  const handleTax = (val) => {
    const _coefficient = form.getFieldValue("is_service_taxed");

    form.setFieldsValue({
      tax_percentage: val,
    });

    handleCoeeficient(_coefficient);
  };

  // Handle Service
  const handleService = (val) => {
    const _coefficient = form.getFieldValue("is_service_taxed");

    form.setFieldsValue({
      service_percentage: val,
    });

    handleCoeeficient(_coefficient);
  };

  // Handle Taxed
  const handleCheck = (val) => {
    const _check = val.target.checked;

    setIsServiceTaxed(_check);

    form.setFieldsValue({
      is_service_taxed: _check,
    });

    handleCoeeficient(_check);
  };

  // Handle Segment
  const handleSegment = (val) => {
    const _check = val;

    console.log(_check);

    setIsSegment(_check);

    form.setFieldsValue({
      is_drr_segment_count: _check,
    });
  };

  // Handle Segment
  const handleAbf = (val) => {
    const _check = val;

    console.log("ABF one Day: ", _check);

    // setIsSegment(_check);

    form.setFieldsValue({
      abf_plus_one_day: _check,
    });
  };

  // Handle Coefficient
  const handleCoeeficient = (val) => {
    const service = form.getFieldValue("service_percentage");
    const tax = form.getFieldValue("tax_percentage");

    let _ser = 1 * (service / 100);
    let _tax = (1 + _ser) * (tax / 100);
    let _tax_withoutser = 1 * (tax / 100);

    let coefficient_wotax = 1 + _ser + _tax;
    let coefficient_tax = 1 + _ser + _tax_withoutser;

    let coefficient_1 = coefficient_wotax; //.toFixed(3);
    let coefficient_2 = coefficient_tax; //.toFixed(3);

    // NETT COEFFICIENT
    console.log("Is tax: ", val);
    console.log("Coefficient with Tax: ", coefficient_1);
    console.log("Coefficient w/o Tax: ", coefficient_2);

    if (val === true) {
      form.setFieldsValue({
        coefficient_tax_service: coefficient_1,
      });
    } else {
      form.setFieldsValue({
        coefficient_tax_service: coefficient_2,
      });
    }
  };

  // HANDLE EDIT
  const handleEdit = () => {
    if (disabled) {
      if (permitted) {
        setDisabled(false);
      } else {
        setOpen(true);
      }
    } else {
      setDisabled(true);
    }
  };

  // HANDLE PERMISSION
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setDisabled(false);
        setPermitted(true);
        break;

      default:
        setPermitted(false);
        setDisabled(true);
        break;
    }
  };

  // HANDLE CANCEL
  const handleSuccess = (value) => {
    setDisabled(true);

    localStorage.clear();
    handleAuthorization("");

    // console.log("CONTENT GENERATED: ", value);
    if (value) {
      // Update Params
      const jwt = Encoder(value);
      // console.log("JWT GENERATED: ", jwt);

      sessionStorage.setItem("par-1", jwt);
    }
  };

  // CONSOLE
  // console.log(data);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <BsCalculatorFill
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`ACCOUNTING PARAMETERS`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-first"
                style={{ padding: 30 }}
              >
                {loading == true ? (
                  <>
                    <InputSkeleton is_loading={loading} />
                  </>
                ) : (
                  <Form
                    className="parameters-form"
                    name="parameters-form"
                    key="parameters-form"
                    autoComplete="false"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{ padding: "36px 36px 0px 36px" }}
                  >
                    <Row gutter={30} className="row-header">
                      <Form.Item
                        label="Deposit Main Account"
                        name="deposit_coa"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Chart of Accounts" /> */}
                        <MasterCOA
                          coa_name={data?.deposit_coa ? data.deposit_coa : ""}
                          getCoaName={getDepositCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Revenue Main Account"
                        name="revenue_coa"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Chart of Accounts" /> */}
                        <MasterCOA
                          coa_name={data?.revenue_coa ? data.revenue_coa : ""}
                          getCoaName={getRevenueCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Liability Main Account"
                        name="liability_coa"
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Chart of Accounts" /> */}
                        <MasterCOA
                          coa_name={
                            data?.liability_coa ? data.liability_coa : ""
                          }
                          getCoaName={getLiabilityCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Cash Deposit Main Account"
                        name="cash_deposit"
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Cash Deposit" /> */}
                        <MasterCOA
                          coa_name={data?.cash_deposit ? data.cash_deposit : ""}
                          getCoaName={getCashDeposit}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Cash Sales Main Account"
                        name="cash_sales"
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Cash Sales" /> */}
                        <MasterCOA
                          coa_name={data?.cash_sales ? data.cash_sales : ""}
                          getCoaName={getSalesCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Receivable Sales Main Account"
                        name="receivable_sales"
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Receivable Sales" /> */}
                        <MasterCOA
                          coa_name={
                            data?.receivable_sales ? data.receivable_sales : ""
                          }
                          getCoaName={getReceiveCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Tax Main Account"
                        name="coa_tax"
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Receivable Sales" /> */}
                        <MasterCOA
                          coa_name={data?.coa_tax ? data.coa_tax : ""}
                          getCoaName={getTaxCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Service Main Account"
                        name="coa_service"
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Input placeholder="Receivable Sales" /> */}
                        <MasterCOA
                          coa_name={data?.coa_service ? data.coa_service : ""}
                          getCoaName={getServiceCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="A/P Main Account"
                        name="coa_ap"
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterCOA
                          coa_name={data?.coa_ap ? data.coa_ap : ""}
                          getCoaName={getPayableCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Other's Revenue Account"
                        name="others_coa"
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterCOA
                          coa_name={data?.others_coa ? data.others_coa : null}
                          getCoaName={getOthersCoa}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Row
                        className="currency-row"
                        // gutter={30}
                        style={{ width: "100%" }}
                      >
                        <Col span={16} className="currency-col">
                          <Form.Item
                            label="Currency"
                            name="currency"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <MasterCurrency
                              getCurrency={getCurrency}
                              pay={data?.currency ? data.currency : null}
                              disable={disabled}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8} className="symbol-col">
                          <Form.Item
                            name="currency_code"
                            wrapperCol={{ span: 24 }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Input placeholder="Currency Symbols" disabled />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item
                        label="Tax Percentage (%)"
                        name="tax_percentage"
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputNumber
                          disabled={disabled}
                          placeholder="Tax %"
                          onChange={handleTax}
                          addonAfter={"%"}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Service Percentage (%)"
                        name="service_percentage"
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputNumber
                          disabled={disabled}
                          placeholder="Service %"
                          onChange={handleService}
                          addonAfter={"%"}
                        />
                      </Form.Item>

                      <Row
                        className="sertax-row"
                        // gutter={30}
                        style={{ width: "100%" }}
                      >
                        <Col span={16} className="sertax-col">
                          <Form.Item
                            label="Service is Subject of Tax?"
                            name="is_service_taxed"
                            valuePropName="checked"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                            style={{
                              width: "100%",
                            }}
                          >
                            <Checkbox
                              disabled={disabled}
                              onChange={handleCheck}
                            >
                              {isServiceTaxed
                                ? `Service Taxed`
                                : `Service is not Taxed`}
                            </Checkbox>
                          </Form.Item>
                        </Col>

                        <Col span={8} className="coefficient-col">
                          <Form.Item
                            label="Coefficient"
                            name="coefficient_tax_service"
                            wrapperCol={{ span: 24 }}
                          >
                            <InputNumber
                              disabled
                              min={0}
                              placeholder="Coefficient Tax & Service"
                              step={"0.0001"}
                              precision={3}
                              stringMode
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item
                        label="Daily Report Count by?"
                        name="is_drr_segment_count"
                        // valuePropName="checked"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Select
                          className="option-segment"
                          placeholder="Select an Options!"
                          allowClear
                          value={isSegment}
                          onChange={handleSegment}
                          disabled={disabled}
                          options={[
                            {
                              value: 1,
                              label: "Count by Segment",
                            },
                            {
                              value: 0,
                              label: "Count by Rate Code",
                            },
                          ]}
                        />

                        {/* <Checkbox disabled={disabled} onChange={handleSegment}>
                          {isSegment
                            ? `Count by Segment`
                            : `Count by Rate Code`}
                        </Checkbox> */}
                      </Form.Item>

                      <Form.Item
                        label="Audit Room Breakfast on?"
                        name="abf_plus_one_day"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Select
                          className="option-abf"
                          placeholder="Select an Options!"
                          allowClear
                          // value={isAbf}
                          onChange={handleAbf}
                          disabled={disabled}
                          options={[
                            {
                              value: false,
                              label:
                                "Audit with Room Date ( Audit Breakfast is same with Room Date )",
                            },
                            {
                              value: true,
                              label:
                                "Audit on Room Date + 1 Day ( Audit is based on The Day when Breakfast is served )",
                            },
                          ]}
                        />
                      </Form.Item>

                      {/* <Form.Item
                        label="Journal Code Deposit"
                        name="journal_code_deposit"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Journal Code Deposit"
                        /> */}
                      {/* <MasterCOA
                          coa_name={
                            data?.liability_coa ? data.liability_coa : ""
                          }
                          getCoaName={getLiabilityCoa}
                          is_all={true}
                        /> 
                      </Form.Item>
                        */}

                      {/* <Form.Item
                        label="Journal Code Revenue"
                        name="journal_code_revenue"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Journal Code Revenue"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Journal Code Income"
                        name="journal_code_income"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Journal Code Income"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Journal Code Expense"
                        name="journal_code_expense"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Journal Code Expense"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Journal Code Liabilities"
                        name="journal_code_liability"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Journal Code Liabilities"
                        />
                      </Form.Item> */}

                      <Form.Item
                        label="Deposit Name Code"
                        name="deposit_name_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Deposit Name Code"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Income Name Code"
                        name="income_name_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Income Name Code"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Expense Name Code"
                        name="expense_name_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Income Name Code"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Revenue's Name Code"
                        name="credit_name_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Revenue's Name Code"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Receivable's Name Code"
                        name="receivable_name_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Receivable's Name Code"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Liability's Name Code"
                        name="liability_name_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Liability's Name Code"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Tax's Code Name"
                        name="tax_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Tax's Code Name"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Service's Code Name"
                        name="service_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Service's Code Name"
                        />
                      </Form.Item>

                      <Form.Item
                        label="A/P Code Name"
                        name="ap_name_code"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Account Payable's Code ID"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Others Revenue Code Name"
                        name="others_name"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input
                          disabled={disabled}
                          placeholder="Other's Revenue Code ID"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Last Updated On"
                        name="updated_date"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated On" />
                      </Form.Item>

                      <Form.Item
                        label="Last Update by"
                        name="updated_by"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated By" disabled />
                      </Form.Item>
                    </Row>

                    <Divider
                      className="divider-form"
                      style={{ margin: "15px 0px 10px" }}
                    />

                    <Row
                      justify="end"
                      align="middle"
                      // style={{ padding: "15px 0px 0px" }}
                      className="modal-btn-form"
                    >
                      <Button
                        className="edit-form-btn"
                        type="primary"
                        onClick={handleEdit}
                        icon={
                          <FiEdit
                            style={{
                              margin: "0px 5px 0px 0px",
                            }}
                          />
                        }
                        style={{
                          // backgroundColor: "#1BC5BD",
                          // borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        {`${disabled ? "Edit" : "Cancel Editing"}`}
                      </Button>

                      <Button
                        className="submit-btn"
                        type="primary"
                        disabled={disabled}
                        loading={isLoading}
                        htmlType="submit"
                        style={{
                          backgroundColor: "#1BC5BD",
                          borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        {`Submit`}
                      </Button>

                      <Button
                        className="cancel-btn"
                        type="default"
                        onClick={handleCancel}
                      >
                        {`Cancel`}
                      </Button>
                    </Row>
                  </Form>
                )}
              </Col>

              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-second"
              >
                {null}
              </Col>
            </Row>
          </Col>

          <ModalAuthorized
            openModal={open}
            closeModal={setOpen}
            params={1}
            is_permitted={handlePermit}
          />
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Card, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiReceiveMoney } from "react-icons/gi";
import { MdOutlinePlaylistAddCircle } from "react-icons/md";
import { TbFileTime } from "react-icons/tb";
import { GrDocumentTime } from "react-icons/gr";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

// Import Page Components
import MasterPaymentCardEdit from "../../../../Components/Reusable/Master/PaymentCard/PaymentCardEdit";
import MasterPaymentCard from "../../../../Components/Reusable/Master/PaymentCard/PaymentCard";
import ARPaymentTable from "../../../../Components/Table/BackOffice/AR/ARPayment/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// CODE
export default function ARPaymentPage() {
  // STATE MANAGEMENT
  const [data, setData] = useState({
    payment: "",
    code: 0,
    coa: "",
  });
  // Search
  const [search, setSearch] = useState(false);

  // MOVE
  const navigate = useNavigate();

  // Handle Payment
  const handlePayment = (e, code, coa) => {
    const value = e;

    console.log("Payment Card: ", value);
    console.log("Code: ", code);
    console.log("COA: ", coa);

    // setData({
    //   payment: value,
    //   code: code,
    //   coa: coa,
    // });
    setData({
      payment: value?.name,
      code: value?.code,
      coa: value?.coa,
    });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    setSearch(true);
  };

  // HANDLE MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  // console.log(room);

  return (
    <Layout>
      <Content>
        <Col span={24} className="ooo-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <MdOutlinePlaylistAddCircle
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`A/R Payment`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              {/* <Col
                style={{
                  textAlign: "start",
                  margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  maxWidth: 250,
                  minWidth: 150,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Payment Details`}</Typography>

                <MasterPaymentCardEdit getPayCard={handlePayment} />
                <MasterPaymentCard getPayCard={handlePayment} />
              </Col>

              <Col
                style={{
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={handleSearch}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col> */}
            </Space>
          </Row>

          <ARPaymentTable
            selected={data}
            is_search={search}
            is_research={setSearch}
          />
        </Col>
      </Content>
    </Layout>
  );
}

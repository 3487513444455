// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as InventoryContext } from "../../../../../../API/Context/InventoryContext/InventoryContext";

// BASEAPI
import {
  user_name,
  token,
  user_level,
  department,
  department_id,
} from "../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../API/Config/Api";

// Import ANTD Component
import { Button, Row, Col, Table, Tooltip, Modal, Form, Tag } from "antd";

// Import React Icons Components
import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import StandardRecipeArchivePopover from "../../../../../Popover/StandardRecipeArchive/Popover";
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notification
import {
  successArticlePR,
  failedArticlePR,
  failedFetch,
} from "../../../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
  masterIncomplete,
} from "../../../../../Reusable/Notification/MasterNotif/Notification";

// Import moda
import StandardRecipeModal from "../../../../../../Components/Modals/Inventory/StandardRecipe/Modal";

// Import Global Styles
import { mainBody } from "../../../../../../Style/Global/Global";

// MODALS
const { confirm } = Modal;

// CODE
export default function StandardRecipeArchiveTable(props) {
  // PROPS
  const { is_search, _searchRecipeName, _searchIdCategory } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [edit, setEdit] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);
  const [dataEditDetail, setDataEditDetail] = useState([]);
  
  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);

  // DataSource
  let dataSource = [
    {
        key: 1,
        recipe_number: 101,
        recipe_name: "Spaghetti Bolognese",
        sub_article_name: "Main Course",
        portion: 4,
        total_cost: 300000, // IDR
        cost: 75000 // IDR
    },
    {
        key: 2,
        recipe_number: 102,
        recipe_name: "Caesar Salad",
        sub_article_name: "Salad",
        portion: 2,
        total_cost: 100000, // IDR
        cost: 50000 // IDR
    },
    {
        key: 3,
        recipe_number: 103,
        recipe_name: "Chicken Curry",
        sub_article_name: "Main Course",
        portion: 4,
        total_cost: 240000, // IDR
        cost: 60000 // IDR
    },
    {
        key: 4,
        recipe_number: 104,
        recipe_name: "Chocolate Cake",
        sub_article_name: "Dessert",
        portion: 8,
        total_cost: 160000, // IDR
        cost: 20000 // IDR
    },
    {
        key: 5,
        recipe_number: 105,
        recipe_name: "Grilled Salmon",
        sub_article_name: "Main Course",
        portion: 3,
        total_cost: 450000, // IDR
        cost: 150000 // IDR
    },
    {
        key: 6,
        recipe_number: 106,
        recipe_name: "Greek Salad",
        sub_article_name: "Salad",
        portion: 4,
        total_cost: 120000, // IDR
        cost: 30000 // IDR
    },
    {
        key: 7,
        recipe_number: 107,
        recipe_name: "Beef Stroganoff",
        sub_article_name: "Main Course",
        portion: 4,
        total_cost: 350000, // IDR
        cost: 87500 // IDR
    },
    {
        key: 8,
        recipe_number: 108,
        recipe_name: "Pancakes",
        sub_article_name: "Breakfast",
        portion: 6,
        total_cost: 90000, // IDR
        cost: 15000 // IDR
    },
    {
        key: 9,
        recipe_number: 109,
        recipe_name: "Vegetable Stir Fry",
        sub_article_name: "Main Course",
        portion: 4,
        total_cost: 100000, // IDR
        cost: 25000 // IDR
    },
    {
        key: 10,
        recipe_number: 110,
        recipe_name: "Mango Smoothie",
        sub_article_name: "Beverage",
        portion: 2,
        total_cost: 50000, // IDR
        cost: 25000 // IDR
    }
];

  // CONTEXT
  const { getStandardRecipe } = useContext(InventoryContext);

  // Navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    await getStandardRecipe({
      type: "standard-recipe-archived",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filter = _res.filter((x) => {
            if (x.is_deleted == null || x.is_deleted == false) return x;
          })

          const sort = filter.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));

          setBulks(sort);
          setData(sort);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // const fetchData = async () => {
  //   setData(dataSource);
  //   setBulks(dataSource);
  // }

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("DataDetail >> ", dataEdit)
  },[dataEdit])

  useEffect(() => {
    if (refresh == true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  // useEffect(() => {
  //   console.log("Data Bulks >> ", bulks);
  //   // setData(dataSource);
  // }, [bulks]);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [_searchRecipeName, _searchIdCategory]);

  // Handle Return
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryName = _searchRecipeName ? _searchRecipeName.toLowerCase() : null;
    const queryId = _searchIdCategory != null ? _searchIdCategory : null;

    if(queryName == null && queryId == null) {
      fetchData();
    } else {
      if (bulks.length > 0) {
        let filter = bulks.filter((item) => {
          const _name = item?.recipe_name ? item?.recipe_name.toLowerCase() : null;
          const _id = item?.article_sub_id ? item?.article_sub_id : null;

          if(queryName == null && queryId != null) {

            return _id == queryId;
            
          } else if (queryName != null && queryId == null) {
            
            return _name.indexOf(queryName) !== -1;
            
          } else if (queryName != null && queryId != null) {
            
            return _name.indexOf(queryName) !== -1 && _id == queryId;

          } else { return item }

        });
        setData(filter)
      } else {
        console.log("BULKS KOSONG!")
        setData([]);
      }
    };

    setLoading(false);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24} className="col-menu">
                <Tooltip title="Option" className="option-tooltip">
                  <StandardRecipeArchivePopover
                    dataHeader = {record}
                    refresh = {handleRefresh}
                  />
                </Tooltip>
              </Col>
            </Row>
            {/* <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      // console.log("ABOUT TO EDIT > ", record);

                      setEdit(true);
                      handleOpen();
                      setDataEdit(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row> */}
          </>
        );
      },
    },
    {
      title: "Recipe No.",
      dataIndex: "recipe_number",
      key: "recipe_number",
      width: 125,
      render: (recipe_number) => {
        return ("000" + recipe_number).slice(-4)
      }
    },
    {
      title: "Recipe's Name",
      dataIndex: "recipe_name",
      key: "recipe_name",
      width: 175,
    },
    {
      title: "Category Name",
      dataIndex: "sub_article_name",
      key: "sub_article_name",
    },
    {
      title: "Portion",
      dataIndex: "portion",
      key: "portion",
    },
    {
      title: "Recipe Cost",
      dataIndex: "total_cost",
      key: "total_cost",
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;

        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
    {
      title: "Cost /Portion",
      dataIndex: "cost",
      key: "cost",
      render: (estimated) => {
        let est = estimated > 0 ? estimated : 0;

        return est > 0 ? `${est}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
      },
    },
  ];

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);
  };

  // Handle Open
  const handleEdit = (val) => {
    setEdit(true);
    handleOpen();
    setDataEdit(val);
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Standard Recipe Archives");
  };

  // Handle Refresh
  const handleRefresh = () => {
      fetchData();
  };

  // Handle Close
  const handleClose = () => {
    setModalOpen(false);
    setEdit(false);
  };

  // Handle GoToStandardRecipe
  const handleGoToStandardRecipe = () => {
    navigate('/back-office/inventory/standard-recipe')
  };

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={12}
              xl={12}
              lg={12}
              md={18}
              sm={18}
              xs={24}
            >
              <Row justify="start">
                <Col>
                  <Button
                    className="export-btn"
                    type="primary"
                    onClick={handleExport}
                    // onClick={handleExport}
                    icon={<FileExcelFilled />}
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {`Download Excel`}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="export-btn"
                    type="primary"
                    onClick={handleGoToStandardRecipe}
                    icon={<ArrowRightOutlined />}
                    style={{
                      // marginRight: 15,
                    }}
                  >
                    {`Go to Standard Recipe`}
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={12}
              xl={12}
              lg={12}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  // onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="purchase-req-table"
              name="purchase-req-table"
              key="purchase-req-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1200,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record.id
                  ? record.id
                  : record.id;
              }}
            />
          </Row>
        </Col>
      </Row>
      <StandardRecipeModal
        is_open={modalOpen}
        is_close={handleClose}
        is_refresh={handleRefresh}
        is_edit={edit}
        selectedData={edit ? dataEdit : null}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";

// Redux Night Audit
import * as na from "../../../../../API/AuthActions/auditAction";

// AUTH
import { roles, department, user_name } from "../../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Modal,
  Form,
  Steps,
  Progress,
  Alert,
  Spin,
} from "antd";

// Import Master Components
import ComplimentaryAuditTable from "../../../../Table/BackOffice/NightAudit/Compliment/Table";
import OutstandingAuditTable from "../../../../Table/BackOffice/NightAudit/Bill/Table";
import CountryAuditTable from "../../../../Table/BackOffice/NightAudit/Nation/Table";
import InHouseAuditTable from "../../../../Table/BackOffice/NightAudit/InHouse/Table";
import ArrivalAuditTable from "../../../../Table/BackOffice/NightAudit/Arrival/Table";
import DepartureAuditTable from "../../../../Table/BackOffice/NightAudit/Departure/Table";
import RoomRateAuditTable from "../../../../Table/BackOffice/NightAudit/RoomRate/Table";
import GuestAccountAuditTable from "../../../../Table/BackOffice/NightAudit/GuestAccount/Table";
import UserShiftAuditTable from "../../../../Table/BackOffice/NightAudit/UserShift/Table";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";
import PaidOrderTable from "../../../../Table/Outlet/CloseCashier/PaidOrder/Table";
import UnpaidOrderTable from "../../../../Table/Outlet/CloseCashier/UnpaidOrder/Table";

// CONST
const { Step } = Steps;
const { confirm } = Modal;

// Dispatch Props
const mapDispatchToProps = (dispatch) => {
  console.log("DISPATCH: ", dispatch, na);

  return {
    setDateAfterNA: (value) => dispatch(na.setDate(value)),
  };
};

// User's Reducers
const mapStateToProps = (state) => {
  console.log("Dipatch To Props: ", state);

  let response = state;
  let naDate = response.rootReducer.nightaudit;

  // const {isLoggedIn, accessToken} = user
  return { naDate };
};

// CODE
const CloseCashierSteps = (props) => {
  // USE LOCATION & Navigate
  const { pathname, state } = useLocation();

  const navigate = useNavigate();

  // CONTEXT
  const { postRunNA } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data Table
  const [dataSales, setDataSales] = useState([]);
  const [roomRate, setRoomRate] = useState([]);
  const [compliment, setCompliment] = useState([]);
  const [outstanding, setOutstanding] = useState([]);
  const [guestAccount, setGuestAccount] = useState([]);
  const [dataCountry, setDataCountry] = useState([]);
  const [inHouse, setInHouse] = useState([]);
  const [arrival, setArrival] = useState([]);
  const [depart, setDepart] = useState([]);
  // New Audit Date
  const [newDate, setNewDate] = useState(null);
  // Steps Counter
  const [stepsCounter, setStepsCounter] = useState(1);
  const [stepsLast, setStepsLast] = useState(1);
  // Error State
  const [is_ErrorAlert, setIs_ErrorAlert] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Progress Bar
  const [percent, setPercent] = useState(0);
  const [barState, setBarState] = useState(false);
  const [progressResult, setProgressResult] = useState("normal");

  // new state progress bar
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  // USE FORM
  const [form] = Form.useForm();

  // POST
  // Post Run Night Audit
  const postDataRunNA = async () => {
    if (arrival?.length == 0 && depart?.length == 0) {
      setLoadingProgress(true);

      await postRunNA({
        runNA: "night-audit",
        type: "audit-date",
        created_by: user_name,
        onAwait: () => {},
        onSuccess: (response) => {
          console.log("Run Night Audit => ", response);

          setProgressResult("success");
          setIs_ErrorAlert(false);

          if (response?.data?.posted) {
            let _res = response.data.posted;

            setNewDate(_res);
            let changeDate = moment(_res).add(1, "days").format();

            console.log("Change Date: ", changeDate);
            props.setDateAfterNA(changeDate);
          } else {
            setProgressResult("exception");
            setIs_ErrorAlert(true);
          }

          setLoadingProgress(false);
        },
        onReject: (error) => {
          setProgressResult("exception");
          setIs_ErrorAlert(true);
          setErrorCode(error);
          setLoadingProgress(false);
          console.log("Failed to Night Audit: ", error);
          // setLoading(false);
        },
      });
    } else {
      setProgressResult("exception");
      setIs_ErrorAlert(true);
      setLoadingProgress(false);
      setBarState(true);
    }
  };

  // USE EFFECTS
  useEffect(() => {
    if (barState == true) {
      // if (stepsLast == 8 && percent <= 100) {
      if (stepsLast == 10 && progress <= 100) {
        console.log("Processing Status: ", percent);

        // const currentTimer = setInterval(() => {
        //   // console.log("Naik --> ", percent);
        //   setPercent((prev) => prev + 20);
        // }, 1000);

        // Fungsi untuk mengupdate progres setiap 50ms saat loading
        let interval;

        if (loadingProgress) {
          interval = setInterval(() => {
            setProgress((prevProgress) => {
              if (prevProgress < 100) {
                return prevProgress + 1;
              }
              return prevProgress;
            });
          }, 50);
        } else {
          setProgress(100);
          clearInterval(interval);

          if (is_ErrorAlert === false) {
            // setTimeout(() => {
            setLoading(true);
            // setLoading(false);
            handleAuditSuccess(newDate);
            // window.location.reload(false);
            // }, 2000);
          }
        }

        return () => clearInterval(interval);

        // console.log(interval);
        // if (percent == 100) {
        //   clearInterval(currentTimer);

        //   if (is_ErrorAlert === false) {
        //     setTimeout(() => {
        //       setLoading(true);
        //       // setLoading(false);
        //       handleAuditSuccess(newDate);
        //       // window.location.reload(false);
        //     }, 2000);
        //   }
        // }

        // console.log(percent);
        // return () => clearInterval(currentTimer);
      }
    }
    // }, [stepsLast, percent, barState, loadingProgress]);
  }, [stepsLast, barState, loadingProgress, newDate]);

  useEffect(() => {
    let cleanUp = false;

    console.log("Error Alert: ", is_ErrorAlert);
    console.log("Bar status: ", barState);

    if (is_ErrorAlert && !cleanUp) {
      setBarState(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_ErrorAlert, barState]);

  // SHOW MODAL NA
  const showRunningModal = (record) => {
    let del = record;

    confirm({
      className: "delete-pay-card-confirm",
      title: `Are you sure want to Execute the Night Audit Process ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        setBarState(true);
        postDataRunNA();
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE CLICK
  const handleClick = () => {
    if (stepsCounter === 10) {
      showRunningModal();
    } else {
      counter(1);
      if (stepsLast == stepsCounter) {
        setStepsLast(stepsLast + 1);
      }
    }
  };

  // STEPS
  // Steps Counter
  const counter = (e) => {
    const _steps = e;
    setStepsCounter(stepsCounter + _steps);
  };

  // STEPS STATUS
  const statusSteps = (e, data) => {
    const _steps = e;
    const _arr = data;

    // console.log("status steps => ", _steps);

    let _stats = "";

    switch (_steps) {
      case 8:
      case 9:
        if (_arr?.length > 0) {
          _stats = "error";
        } else if (stepsLast == _steps) {
          _stats = "process";
        } else {
          _stats = "finish";
        }
        break;

      default:
        if (stepsLast == _steps) {
          _stats = "process";
        } else if (stepsLast > _steps) {
          _stats = "finish";
        } else {
          _stats = "wait";
        }
        break;
    }

    // eval(`data${e}`)?.length > 0 && (_steps == 6 || _steps == 7)
    //   ? "error"
    //   : stepsLast == _steps
    //   ? "process"
    //   : stepsLast > _steps
    //   ? "finish"
    //   : "wait";

    return _stats;
  };

  // Button Steps
  const descSteps = (e, arr) => {
    const _steps = e;
    const _arr = arr;

    console.log("Desc Steps --> ", _steps);
    // console.log("Counter Steps --> ", stepsCounter);
    // console.log("Data --> ", eval(`data${e}`));

    return stepsCounter == _steps ? (
      <>
        <Row justify="end" style={{ margin: "30px 0px 0px" }}>
          {_steps > 1 ? (
            <Button
              className={`next-btn`}
              type="primary"
              disabled={
                _arr?.length > 0 && stepsLast < stepsCounter ? true : false
              }
              onClick={() => {
                counter(-1);
              }}
            >
              {`Previous`}
            </Button>
          ) : null}

          <Button
            type="primary"
            className={`next-btn`}
            disabled={
              _arr?.length > 0 && stepsLast < stepsCounter ? true : false
            }
            onClick={() => {
              counter(1);

              if (stepsLast == stepsCounter) {
                setStepsLast(stepsLast + 1);
              }
            }}
            style={{ marginLeft: 15 }}
          >
            {`Next`}
          </Button>
        </Row>
      </>
    ) : (
      descriptionSteps(e, _arr)
    );
  };

  // STEPS LAST
  const otherSteps = (e, arr) => {
    const _steps = e;
    const _arr = arr;
    // console.log("Other Steps --> ", e);
    // console.log("Counter Steps --> ", stepsCounter);

    return stepsCounter == _steps ? (
      <>
        <Row justify="end" style={{ margin: "0px 15px 0px 0px" }}>
          {stepsCounter != 1 ? (
            <Button
              type="primary"
              className="next-btn"
              disabled={
                _arr?.length > 0 && stepsLast < stepsCounter ? true : false
              }
              onClick={() => {
                counter(-1);
              }}
              loading={barState && progress !== 100 ? true : false}
            >
              {`Previous`}
            </Button>
          ) : null}

          <Button
            type="primary"
            className={stepsCounter === 10 ? `submit-btn` : `next-btn`}
            loading={barState && progress !== 100 ? true : false}
            // loading={barState && percent !== 100 ? true : false}
            disabled={barState === true ? true : false}
            onClick={handleClick}
            style={{ marginLeft: 15 }}
          >
            {stepsCounter === 3 ? "Close Cashier" : "Next"}
          </Button>
        </Row>

        {barState === true ? progressBar() : null}

        {/* {is_ErrorAlert === true && percent == 100
          ? errorAlert(true)
          : is_ErrorAlert === false && percent == 100
          ? successAlert()
          : null} */}

        {is_ErrorAlert === true && progress == 100
          ? errorAlert(true)
          : is_ErrorAlert === false && progress == 100
          ? successAlert()
          : null}
      </>
    ) : (
      descriptionSteps(e, _arr)
    );
  };

  // DESC STEPS
  const descriptionSteps = (e, arr) => {
    const _steps = e;
    const _arr = arr;
    let _txt = "";

    if (_arr?.length > 0) {
      _txt = "Cross Check Again";
    } else if (stepsLast > _steps) {
      _txt = "Finished";
    } else {
      _txt = "Waiting...";
    }

    return _txt;
  };

  // PROGRESS BAR
  const progressBar = () => {
    console.log(progressResult);

    return (
      <Row className="progress-row" style={{ margin: "30px 0px 30px 0px" }}>
        {/* <Progress percent={percent} status={progressResult} /> */}
        <Progress percent={progress} status={progressResult} />
      </Row>
    );
  };

  // Error Night Audit
  const errorAlert = (e) => {
    let runAgain = e;
    let err = "";

    console.log("Run Twice: ", runAgain);

    if (arrival?.length > 0 && depart?.length > 0) {
      err = "There are some data in Arrival & Departure!";
    } else if (arrival?.length > 0) {
      err = "There are some data in Arrival!";
    } else if (depart?.length > 0) {
      err = "There are some data in Departure!";
    } else if (runAgain === true && errorCode === null) {
      err =
        "Its already audited today and this cannot be executed more than once a day!";
    } else {
      err = errorCode?.message;
    }

    return (
      <Alert
        className="audit-error-alert"
        message={<b>{`Error!`}</b>}
        description={`Night Audit is Failed! ${err} \n Please, Check the Data Again and Try to Run Night Audit Again Later!`}
        type="error"
        showIcon
      />
    );
  };

  // Success Alert Night Audit
  const successAlert = () => {
    return (
      <>
        <Spin className="loading-spin" spinning={loading}>
          <Alert
            className="success-results"
            message="Success"
            description="Night Audit is Run Successfully! Wait a second you are being redirected!"
            type="success"
            showIcon
          />
        </Spin>
      </>
    );
  };

  const handleAuditSuccess = async (value) => {
    const _record = [value];

    if (value) {
      console.log("Navigate => ", pathname, state);
      console.log("Record => ", _record);

      setTimeout(() => {
        setLoading(false);

        navigate(`${pathname}/success`, {
          state: _record,
        });
      }, 2000);
    } else {
      setIs_ErrorAlert(true);
    }
  };

  // console.log("Last Steps", stepsLast);
  // console.log("Steps Count", stepsCounter);
  // console.log("Percent --> ", percent);
  // console.log("Steps Status ? ", statusSteps(6));

  // console.log("", progressResult);

  return (
    <>
      <Row
        className="row-night-audit"
        justify="center"
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100%",
          borderRadius: 5,
          width: "100%",
          marginTop: 30,
        }}
      >
        <Col className="col-night-audit" span={24} style={{ padding: "60px" }}>
          <Steps
            className="nigh-audit-steps"
            direction="vertical"
            current={stepsCounter}
            percent={99}
            // status="process"
            // onChange={(e) => setStepsCounter(e)}
          >
            <Step
              title="Paid Order"
              status={statusSteps(1, dataSales)}
              disabled={stepsLast >= 1 || dataSales?.length > 0 ? false : true}
              description={
                stepsCounter == 1 ? (
                  <>
                    <PaidOrderTable
                      dataRoomRate={(e) => {
                        // console.log("This is Data 1: ", e);
                        setRoomRate(e);
                      }}
                    />
                    {descSteps(1, dataSales)}
                  </>
                ) : null
              }
            />

            <Step
              title="Unpaid Order"
              status={statusSteps(2, roomRate)}
              disabled={stepsLast >= 2 || roomRate?.length > 0 ? false : true}
              // description={descSteps(1)}
              description={
                stepsCounter == 2 ? (
                  <>
                    <UnpaidOrderTable
                      dataRoomRate={(e) => {
                        // console.log("This is Data 1: ", e);
                        setRoomRate(e);
                      }}
                    />
                    {descSteps(2, roomRate)}
                  </>
                ) : null
              }
            />

            <Step
              title="Closing Cashier"
              status={statusSteps(3)}
              disabled={stepsLast >= 3 ? false : true}
              description={otherSteps(3)}
            />
          </Steps>
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseCashierSteps);

// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_children, sub_module } from "../../../API/Global/Payload";

// Import Pages
import NotFound from "../../../Pages/NotFound/NotFound";
import InventoryPage from "../../../Pages/BackOffice/Inventory/Main/Inventory";
import WarehousePage from "../../../Pages/BackOffice/Inventory/Warehouse/Warehouse";
import StockTransferRequestPage from "../../../Pages/BackOffice/Inventory/StockTransferRequest/StockTransferRequest";
import StockOnHandPage from "../../../Pages/BackOffice/Inventory/StockOnHand/StockOnHand";
import ReceivingPage from "../../../Pages/BackOffice/Inventory/Receiving/Receiving";
import ReceivingApprovalPage from "../../../Pages/BackOffice/Inventory/ReceivingApproval/ReceivingPage";
import StockTransferRequestApprovalPage from "../../../Pages/BackOffice/Inventory/StockTransferRequestApproval/StockTransferRequestApproval";
import StockTransferRequestReceivingPage from "../../../Pages/BackOffice/Inventory/StockTransferRequestReceiving/StockTransferRequestReceiving";
import StockOpnamePage from "../../../Pages/BackOffice/Inventory/StockOpname/StockOpname";
import StandardRecipePage from "../../../Pages/BackOffice/Inventory/StandardRecipe/StandardRecipe";

import UnAuthorized from "../../../Pages/Unauthorized/Unauthorized";

// List Route
const grandRoute = [
  {
    path: "receiving/*",
    name: "receiving",
    id_modules: 52,
    element: <ReceivingPage />,
  },
  {
    path: "approval-receiving/*",
    name: "approval-receiving",
    id_modules: 100,
    element: <ReceivingApprovalPage />,
  },
  {
    path: "warehouse/*",
    name: "warehouse",
    id_modules: 53,
    element: <WarehousePage />,
  },
  {
    path: "stock-opname/*",
    name: "stock-opname",
    id_modules: 55,
    element: <StockOpnamePage />,
  },
  {
    path: "transfer-request/*",
    name: "transfer-request",
    id_modules: 54,
    element: <StockTransferRequestPage />,
  },
  {
    path: "stock-on-hand/*",
    name: "stock-on-hand",
    id_modules: 56,
    element: <StockOnHandPage />,
  },
  {
    path: "transfer-request-approval/*",
    name: "transfer-request-approval",
    id_modules: 103,
    element: <StockTransferRequestApprovalPage />,
  },
  {
    path: "transfer-request-receiving/*",
    name: "transfer-request-receiving",
    id_modules: 104,
    element: <StockTransferRequestReceivingPage />,
  },
  {
    path: "standard-recipe/*",
    name: "standard-recipe",
    id_modules: 105,
    element: <StandardRecipePage />,
  },
];

// CODE
export default function InventoryRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<InventoryPage />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}
      </Routes>
      <Outlet />
    </>
  );
}

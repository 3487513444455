// Import React Components
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";

// AUTHORIZED
import {
  authorized_by,
  authorized_dept,
  authorized_roles,
  authorized_token,
  handleAuthorization,
} from "../../../../API/Global/Permission";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Col,
  Row,
  Select,
  Table,
  Space,
  Button,
  Modal,
  Typography,
  Form,
  Input,
  Result,
} from "antd";
import {
  FormOutlined,
  MenuOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { FaExclamationTriangle } from "react-icons/fa";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import {
  failedPermit,
  successPermit,
} from "../../../Reusable/Notification/Notification";
import axios from "axios";
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";
import ParametersAccounting from "../../../Reusable/Parameters/ParamsAcc";

// CODE
export default function EditRoomRateTable(props) {
  // Properties
  const { dataGuest, setRoomPrice, getPrice, night } = props;

  // Params
  const currency = CurrencySymbols().code;
  const accounting = ParametersAccounting().params;

  // CONTEXT
  const { getArticleRoom, getPreBillArticle } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  // Selected Source
  const [mutSource, setMutSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [articleRoom, setArticleRoom] = useState([]);
  const [articleList, setArticleList] = useState([]);

  // UseForm
  const [form] = Form.useForm();

  const fetchStayBeforeUpdate = async () => {
    const getDataGuest = dataGuest;
    console.log("HASIL DATA GUEST >>>", getDataGuest);

    await axios
      .post(
        `${baseurl}/reservation/stay-before-update`,
        {
          reservation_id: getDataGuest?.reservation_id
            ? getDataGuest.reservation_id
            : "",
          price_id: getDataGuest?.price_id ? getDataGuest.price_id : 0,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("STAY BEFORE UPDATE = ", response);

        const _res = response?.data?.msg;

        if (_res?.a_article_room?.length > 0) {
          let _articleRoom = _res.a_article_room.map((value) => {
            return { ...value, total_price: value?.gross };
          });

          setArticleRoom(_articleRoom);
        }

        if (_res?.a_article_stay?.length > 0) {
          let _articleList = _res.a_article_stay;
          setArticleList(_articleList);
        }

        // setRoomList([_res[0]]);
        // }
      })
      .catch((error) => {
        console.log("ERORR = ", error);
      });
  };

  // HANDLE FETCH
  // const fetchData = async () => {
  //   const getDataGuest = dataGuest;
  //   console.log("HASIL DATA GUEST >>>", getDataGuest);

  //   await getArticleRoom({
  //     articleRoomPrice: "reservation",
  //     type: "article-room-single",
  //     res_id: getDataGuest?.reservation_id ? getDataGuest.reservation_id : "",
  //     prc_id: getDataGuest?.price_id ? getDataGuest.price_id : 0,
  //     onAwait: () => {},
  //     onSuccess: (response) => {
  //       console.log("Article Room => ", response);

  //       if (response?.data?.length > 0) {
  //         let _response = response?.data;
  //         let _mapped = _response.map((value) => {
  //           return { ...value, total_price: value?.gross };
  //         });

  //         console.log("MAPPED: ", _mapped);
  //         console.log("MAPPED: ", _mapped);
  //         setArticleRoom(_mapped);
  //       }
  //     },
  //     onReject: (error) => {
  //       console.log(error);
  //       // setLoading(false);
  //     },
  //   });

  //   await getPreBillArticle({
  //     articleStay: "bill",
  //     type: "article-res-stay-guest",
  //     res_id: getDataGuest?.reservation_id ? getDataGuest.reservation_id : "",
  //     prc_id: getDataGuest?.price_id ? getDataGuest.price_id : 0,
  //     onAwait: () => {},
  //     onSuccess: (response) => {
  //       console.log("Response Article List => ", response);

  //       if (response?.data?.length > 0 || response?.data) {
  //         setArticleList(response?.data);
  //       }
  //     },
  //     onReject: (error) => {
  //       console.log(error);
  //     },
  //   });
  // };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (dataGuest && !cleanUp) {
      console.log("Table Price Edit: ", dataGuest);

      // fetchData();
      fetchStayBeforeUpdate();
    }

    return () => {
      cleanUp = true;
    };
  }, [dataGuest]);

  useEffect(() => {
    let cleanUp = false;

    console.log("Article List ->> ", articleList);
    console.log("Article Room ->> ", articleRoom);
    if ((articleList?.length > 0 || articleRoom?.length > 0) && !cleanUp) {
      console.log("Table Price Edit: ", dataGuest);

      handleJoin();
    }

    return () => {
      cleanUp = true;
    };
  }, [articleList, articleRoom]);

  // HANDLE JOIN
  const handleJoin = () => {
    console.log(articleRoom);
    // const room = articleRoom[0]?.gross ? articleRoom[0]?.gross : 0;
    // const article = articleList[0]?.total_price
    //   ? articleList[0]?.total_price
    //   : 0;

    let totalRoom = articleRoom.reduce((prev, curr) => prev + curr.gross, 0);
    let totalArticle = articleList.reduce(
      (prev, curr) => prev + curr.total_price,
      0
    );

    // let arr = [];

    let data = [
      {
        date: dataGuest?.arrival ? dataGuest?.arrival : "",
        room_category_name: articleRoom[0]?.room_category_name
          ? articleRoom[0].room_category_name
          : dataGuest?.room_category || "",
        price: totalRoom || totalArticle > 0 ? totalRoom + totalArticle : 0,
        // room_details: dataGuest?.is_abf == true ? "Breakfast" : "Room Only",
        room_details: articleList?.length > 0 ? "Breakfast" : "Room Only",
        main_segment_id: articleRoom[0]?.main_segment_id
          ? articleRoom[0]?.main_segment_id
          : 0,
        id: dataGuest?.price_id,
        total_price: totalRoom + totalArticle || 0,
        departure: dataGuest?.departure ? dataGuest.departure : "",
      },
    ];

    console.log("HASIL JOIN >>>", data);
    // arr.push(data);

    // if (arr.length > 0) {
    setMutSource(data);
    // setRoomPrice(data);
    getPrice(data);
    // }
  };

  // HANDLE MANIPULATE DATA FOR NESTED TABLE
  const handleManipulateData = (room, article) => {
    console.log("Room ->> ", room);
    console.log("Article ->> ", article);
    console.log("Accounting Params ->> ", accounting);

    const abf_plus_one_day = accounting?.abf_plus_one_day ? 1 : 0;

    return room.map((room) => {
      let room_details = "Room Only";
      let total_price = room.gross;
      for (let stay of article) {
        if (
          moment(room.date, "YYYY-MM-DD")
            .clone()
            .add(abf_plus_one_day, "days")
            .isSame(moment(stay.order_date, "YYYY-MM-DD"))
        ) {
          room_details = "Breakfast";
          total_price = room.gross + stay.gross;
          break;
        }
      }

      return {
        ...room,
        room_details,
        total_price,
      };
    });
  };

  // HANDLE MANIPULATE MUT SOURCE
  // const handleManipulateMutSource = (_mutSource) => {
  //   return _mutSource.map((item, index) => {
  //     let arrDetails = [];
  //     let startDate = moment(item.date, "DD-MM-YYYY");

  //     // for (let i = 0; i < night; i++) {
  //       // let currentDate = startDate.clone().add(i, "days"); // tambah hari
  //       //  let formattedDate = currentDate.format("DD-MM-YYYY");

  //       arrDetails.push({
  //         // key: i,
  //         date: currentDate,

  //       });
  //     // }

  //     return {
  //       ...item,
  //       details: articleRoom,
  //     };
  //   });
  // };

  // Columns
  const columns = [
    {
      title: "Arrival",
      dataIndex: "date",
      key: "date",
      width: 200,
      fixed: "left",
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      width: 200,
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
      width: 200,
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      width: 200,
      align: "right",
      render: (price, record) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Room Details",
      dataIndex: "room_details",
      key: "room_details",
      align: "center",
      // render: (_, record) => {
      //   return "Room Only";
      // },
    },
  ];

  const nestedColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 200,
      fixed: "left",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
      width: 200,
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      width: 200,
      align: "right",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Room Details",
      key: "room_details",
      dataIndex: "room_details",
      align: "center",
    },
    {
      title: "Rate Code",
      key: "room_rate_id",
      dataIndex: "room_rate_id",
      render: (rate) => {
        let _rate = rate?.length > 0 ? rate[0] : null;

        return _rate;
      },
    },
  ];
  // console.log("PRICE = ", selectedRates);
  // console.log("mut data: ", mutSource);
  // console.log("DATA => ", mutSource);
  // console.log("Token => ", authorized_token);

  return (
    <>
      <Row gutter={30}>
        <Col span={24} style={{ marginRight: 30 }}>
          <Table
            className="price-edit-table"
            name="price-edit-table"
            key="price-edit-table"
            dataSource={mutSource}
            columns={columns}
            size="middle"
            expandable={{
              // rowExpandable: (record) => true,
              expandedRowRender: (record) => {
                // console.log(record.details);
                return (
                  <Table
                    columns={nestedColumns}
                    // dataSource={articleRoom.length > 0 ? articleRoom : []}
                    dataSource={handleManipulateData(articleRoom, articleList)}
                    pagination={false}
                    size="small"
                    rowKey={(record) => {
                      return record.id ? record.id : record.key;
                    }}
                    scroll={{
                      x: true,
                    }}
                  />
                );
              },
            }}
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            rowKey={(record) => {
              return record.id ? record.id : record.key;
            }}
            scroll={{
              x: true,
            }}
          />
        </Col>
      </Row>
    </>
  );
}

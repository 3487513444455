// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// AUTH
import AuditDate from "../../../../Reusable/DateAudit/Date";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Collapse,
  Table,
  Typography,
  Card,
  Divider,
  DatePicker,
} from "antd";

// Import Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PrinterOutlined } from "@ant-design/icons";
import { MdPrint } from "react-icons/md";

// Import Page Components
import HeaderDSRTable from "../../../../Table/Reports/Accounting/DSR/Headers/Table";
// import DetailDSRTable from "../../../Table/Reports/Accounting/DSR/Details/Table";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";
import SegmentStatisticTable from "../../../../Table/Reports/Accounting/DSR/Segment/Table";

// Const
const { Panel } = Collapse;
const { Text, Link, Title } = Typography;

// CODE
export default function SegmentReportLayout() {
  // USE LOCATION & NAVIGATE
  const navigate = useNavigate();

  // USE REF
  const componentRef = useRef();

  // Date Now
  const date = new Date(Date.now());
  const _date = AuditDate().audit;
  const today = moment(date).format("DD MMM YYYY");

  // STATE MANAGEMENT
  // Data
  const [dateNow, setDateNow] = useState(null);
  // State
  const [isPrinted, setIsPrinted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // HANDLE PRINT
  const handlePrint = (e) => {
    console.log("Print! ", e);

    setIsPrinted(true);
    setIsLoading(true);

    setTimeout(() => {
      handlePrintOut();
    }, 50);

    setTimeout(() => {
      setIsPrinted(false);
      setIsLoading(false);
    }, 1000);
  };

  // HANDLE REACT PRINT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Daily Sales Report (DSR) - ${_date}`,
    onPrintError: (error) => {
      console.log("Print Error: ", error);
    },
  });

  // Return
  const move = () => {
    const path = "/report/accounting/daily-accounting-report";
    // console.log(pathname);
    // console.log(search);

    navigate(`${path}`);
  };

  // HANDLE DATE
  const handleDate = (value) => {
    const _date = value;

    setDateNow(_date);
  };

  // CONSOLE
  //   console.log("Data Room Occ --> ", dataRoom);
  //   console.log("Data Room Available --> ", dataAvail);
  //   console.log("Data Average --> ", dataAvrg);

  return (
    <>
      <Row
        // gutter={[30, 30]}
        className="row-body"
        justify="start"
        style={mainBody}
      >
        <Col
          span={24}
          style={{
            padding: "30px 0px",
          }}
        >
          <Row className="print-row" justify="end" align="bottom">
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="date-col"
              style={{
                padding: "0px 30px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {`Pick a Date`}
              <DatePicker
                className="date-pick-drr"
                allowClear
                placeholder="Pick a Date"
                onChange={handleDate}
                style={{
                  minWidth: 150,
                  maxWidth: 250,
                }}
              />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="print-col"
            >
              <Row
                className="btn-row"
                gutter={[0, 15]}
                align="bottom"
                justify="end"
              >
                <Button
                  type="primary"
                  className="print-btn"
                  icon={<PrinterOutlined />}
                  onClick={handlePrint}
                >
                  {`Print DSR`}
                </Button>

                <Button
                  type="default"
                  className="cancel-btn"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={move}
                  style={{
                    margin: "0px 30px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Divider
            className="report-divider"
            style={{ margin: "15px 0px 0px", background: "#EBEDF3" }}
          />

          <Card
            ref={componentRef}
            className="dsr-card"
            style={{ padding: 20 }}
            bordered={false}
          >
            <SegmentStatisticTable is_printed={isPrinted} dataDate={dateNow} />

            <Divider
              className="report-divider"
              style={{ margin: "15px 0px", background: "#EBEDF3" }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_children } from "../../../API/Global/Payload";

// Import Page Components
import MainAccountingPage from "../../../Pages/Accounting/Main/MainAccounting";
import MainAccountPage from "../../../Pages/Accounting/MainAccount/MainAccount";
import JournalListPage from "../../../Pages/Accounting/Journal/Journal";
import CreateJournalPage from "../../../Pages/Accounting/CreateJournal/CreateJournal";
import MonthlyClosingPage from "../../../Pages/Accounting/Closing/Monthly/MonthlyClosing";
import CompanyLedgersPage from "../../../Pages/Accounting/Ledgers/Company/Ledgers";
import GeneralLedgersPage from "../../../Pages/Accounting/GeneralLedgers/GeneralLedgers";

import StartUpAccountingPage from "../../../Pages/Accounting/StartUp/StartUp";
import InitialBalancePage from "../../../Pages/Accounting/InitialSettings/InitialBalance/InitialBalance";
import InitialDebtsPage from "../../../Pages/Accounting/InitialSettings/InitialDebts/InitialDebts";
import InitialReceivablesPage from "../../../Pages/Accounting/InitialSettings/InitialReceivables/InitialReceivables";
import InitialTablePage from "../../../Pages/Accounting/InitialSettings/InitialTable/InitialTable";
import AccountingReportRoutes from "../../Report/Accounting/Routes";

import NotFound from "../../../Pages/NotFound/NotFound";
import UnAuthorized from "../../../Pages/Unauthorized/Unauthorized";

import BalanceSheetPage from "../../../Pages/Accounting/BalanceSheet/BalanceSheet";
import ProfitnLostPage from "../../../Pages/Accounting/PnL/ProfitNLost";
import AccountingReportsPage from "../../../Pages/Accounting/Reports/Main/Report";
// import ReportsPage from "../../Pages/Accounting/Reports/Report";
import TrialBalancePage from "../../../Pages/Accounting/TrialBalance/TrialBalance";
import DailySalesReport from "../../../Pages/Accounting/Reports/DSR/HeaderReport";
import JournalPage from "../../../Pages/Accounting/Journal/Journal";
import JournalRegisterPage from "../../../Pages/Accounting/JournalRegister/JournalRegister";

// List Route
const grandRoute = [
  {
    path: "create-journal",
    name: "Journal",
    id_modules: 29,
    element: <CreateJournalPage />,
  },
  {
    path: "journal-list",
    name: "Jurnal List",
    id_modules: 30,
    element: <JournalListPage />,
  },
  {
    path: "general-ledgers",
    name: "General Ledgers",
    id_modules: 31,
    element: <GeneralLedgersPage />,
  },
  {
    path: "chart-of-account",
    name: "Chart of Account",
    id_modules: 32,
    element: <MainAccountPage />,
  },
  {
    path: "closing",
    name: "Closing",
    id_modules: 33,
    element: <MonthlyClosingPage />,
  },
  {
    path: "accounting-ledgers",
    name: "Company Ledgers",
    id_modules: 35,
    element: <CompanyLedgersPage />,
  },
  {
    path: "start-up-accounting",
    name: "Start-Up Accounting",
    id_modules: 36,
    element: <StartUpAccountingPage />,
  },
  {
    path: "/start-up-accounting/initial-accounting-table",
    name: "Initial Tables",
    id_modules: 36,
    element: <InitialTablePage />,
  },
  {
    path: "/start-up-accounting/initial-balance-settings",
    name: "Initial Balance",
    id_modules: 36,
    element: <InitialBalancePage />,
  },
  // {
  //   path: "/start-up-accounting/initial-accounting-table",
  //   name: "Initial Tables",
  //   id_modules: 36,
  //   element: <InitialBalancePage />,
  // },
  {
    path: "/start-up-accounting/initial-debts-settings",
    name: "Initial Debts",
    id_modules: 36,
    element: <InitialDebtsPage />,
  },
  {
    path: "/start-up-accounting/initial-receivables-settings",
    name: "Initial Receivables",
    id_modules: 36,
    element: <InitialReceivablesPage />,
  },
  {
    path: "journal-register",
    name: "Journal Register",
    id_modules: 106,
    element: <JournalRegisterPage />,
  },
];
// CODE
export default function AccountingRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    console.log("Protected Routes AR: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<MainAccountingPage />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}

        {/* <Route path="/journal-list" element={<JournalListPage />} />
        <Route path="/general-ledgers" element={<GeneralLedgersPage />} />
        <Route path="/create-journal" element={<CreateJournalPage />} />

        <Route path="/closing" element={<MonthlyClosingPage />} />

        <Route path="/chart-of-account" element={<MainAccountPage />} />
        <Route path="/accounting-ledgers" element={<CompanyLedgersPage />} />

        <Route
          path="/start-up-accounting"
          element={<StartUpAccountingPage />}
        />
        <Route
          path="/start-up-accounting/initial-accounting-table"
          element={<InitialTablePage />}
        />
        <Route
          path="/start-up-accounting/initial-balance-settings"
          element={<InitialBalancePage />}
        />
        <Route
          path="/start-up-accounting/initial-debts-settings"
          element={<InitialDebtsPage />}
        />
        <Route
          path="/start-up-accounting/initial-receivables-settings"
          element={<InitialReceivablesPage />}
        /> */}
        {/* <Route path="/trial-balance" element={<TrialBalancePage />} /> */}
        {/* <Route path="/profit-and-lost" element={<ProfitnLostPage />} /> */}
      </Routes>
      <Outlet />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import { roles, token, user_name } from "../../../../API/Global/Payload";

// BASE API
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Tooltip,
  Tag,
  Modal,
  Typography,
  Input,
  Select,
  InputNumber,
  Checkbox,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  PlusCircleTwoTone,
  MinusCircleOutlined,
  FileExcelFilled,
} from "@ant-design/icons/lib/icons";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterCurrency from "../../../Reusable/Master/Currency/Currency";
import MasterAccountType from "../../../Reusable/Accounting/AccountType/AccountType";
import MasterFinancial from "../../../Reusable/Accounting/Financial/Financial";

// Import Functions
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Style
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function MainAccountTable(props) {
  // PROPS
  const { is_search, searchCoaNo, searchCoaName } = props;

  // CONTEXT
  const { state, getMainAccount } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);

  const [editData, setEditData] = useState(null);
  // Data Status
  const [dataStatus, setDataStatus] = useState(null);
  const [status, setStatus] = useState(null);
  // Account State
  const [levelAccount, setLevelAccount] = useState(1);
  const [headerAccount, setHeaderAccount] = useState();
  // Dept Code
  const [deptCode, setDeptCode] = useState(null);
  const [financeCode, setFinanceCode] = useState(null);
  const [accountCode, setAccountCode] = useState(null);
  // Currency Code
  const [currencyCode, setCurrencyCode] = useState(null);
  // Modal State
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // USE FORM
  const [form] = Form.useForm();

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      render: (_, record) => (
        <Row className="action-table-btn" style={{ width: "100%" }}>
          <Col span={12} className="col-edit-btn">
            <Tooltip title="Edit Data" className="edit-tooltip">
              <FiEdit
                className="edit-btn"
                onClick={() => {
                  console.log("ABOUT TO EDIT > ", record);

                  setIsDelete(false);
                  setOpen(true);
                  setEdit(true);
                  setEditData(record);

                  setFormFields(record);
                }}
                style={{
                  fontSize: 20,
                  color: "#1BC5BD",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Col>

          <Col span={12} className="col-del-btn">
            <Tooltip title="Delete Data" className="del-tooltip">
              <DeleteFilled
                className="delete-btn"
                onClick={() => {
                  console.log("ABOUT TO DELETE > ", record);
                  showModalConfirm(record);
                }}
                style={{
                  fontSize: 20,
                  color: "#F64E60",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
    {
      title: "Account Code",
      dataIndex: "account_code",
      key: "account_code",
      width: 150,
      fixed: "left",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.account_code - b.account_code,
    },
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.account_name - b.account_name,
    },
    {
      title: "Account Header",
      dataIndex: "header_account",
      key: "header_account",
      // width: 100,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
    },
    {
      title: "Account Type",
      // dataIndex: "account_type_name",
      // key: "account_type_name",
      dataIndex: "account_type_name",
      key: "account_type_name",
      // width: 100,
      align: "center",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
      render: (acc, record) => {
        const _type = record?.account_type_id || 0;
        let color = "";
        let account = "";

        switch (_type) {
          case 1:
            color = "geekblue";
            account = "Activa";
            break;

          case 2:
            color = "green";
            account = "Pasiva";
            break;

          case 3:
            color = "gold";
            account = "Sale";
            break;

          case 4:
            color = "purple";
            account = "Cost";
            break;

          case 5:
            color = "red";
            account = "Expense";
            break;

          default:
            color = "grey";
            account = "Unknown";
            break;
        }

        return (
          <Tag key={acc} color={color}>
            {acc}
          </Tag>
        );
      },
    },
    {
      title: "Financial Statement",
      dataIndex: "financial_statement_name",
      key: "financial_statement_name",
      // width: 200,
      align: "center",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
      render: (fin, record) => {
        let color = "";
        let statement = "";
        const _type = record?.financial_statement_id || 0;

        switch (_type) {
          case 1:
            color = "#13c2c2";
            statement = "Current Assets";
            break;

          case 2:
            color = "#52c41a";
            statement = "Fixed Assets";
            break;

          case 3:
            color = "default";
            statement = "Other Assets";
            break;

          case 4:
            color = "brown";
            statement = `Accumulation \n Depreciation`;
            break;

          case 5:
            color = "#f50";
            statement = "Current Liabilities";
            break;

          case 6:
            color = "#2db7f5";
            statement = "Long Term Debt";
            break;

          case 7:
            color = "#eb2f96";
            statement = "Capital";
            break;

          case 8:
            color = "#ffc53d";
            statement = "Revenue";
            break;

          case 9:
            color = "#722ed1";
            statement = "Cost";
            break;

          case 10:
            color = "#f5222d";
            statement = "Expense";
            break;

          case 11:
            color = "#fa8c16";
            statement = "Non Operating Exp / Inc";
            break;

          case 12:
            color = "#A9907E";
            statement = "Statistic Account";
            break;

          case 13:
            color = "#675D50";
            statement = "Retained Earning";
            break;

          default:
            color = "grey";
            statement = "Unknown";

            break;
        }

        return (
          <Tag key={fin} color={color}>
            {fin}
          </Tag>
        );
      },
    },
    {
      title: "Normal Balance",
      dataIndex: "normal_balance",
      key: "normal_balance",
      align: "center",
      // width: 100,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
      render: (balance, record) => {
        let blnc = "";
        let clr = "";

        switch (balance) {
          case 0:
            blnc = "DEBIT";
            clr = "geekblue";
            break;

          case 1:
            blnc = "CREDIT";
            clr = "green";
            break;

          default:
            blnc = "Unknown";
            clr = "grey";
            break;
        }

        return (
          <Tag color={clr} className="tag-coa" key={balance}>
            {blnc}
          </Tag>
        );
      },
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      // width: 100,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
    },
    {
      title: "Level Account",
      dataIndex: "level_account",
      key: "level_account",
      // width: 100,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
    },
    {
      title: "Header Detail",
      dataIndex: "header_or_detail",
      key: "header_or_detail",
      render: (header, record) => {
        let head = "";
        let clr = "";

        switch (header) {
          case 0:
            head = "HEADER";
            clr = "volcano";
            break;

          case 1:
            head = "DETAILS";
            clr = "cyan";
            break;

          default:
            break;
        }

        return (
          <Tag color={clr} className="tag-coa" key={header}>
            {head}
          </Tag>
        );
      },
      // width: 100,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // width: 300,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.description.localeCompare(b.description),
    },
  ];

  // Options Value
  const optionsAccount = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
  ];

  const optionsBalance = [
    {
      value: 0,
      label: "Debit",
    },
    {
      value: 1,
      label: "Credit",
    },
  ];

  // Fetch Data
  const fetchData = async () => {
    await getMainAccount({
      mainAccount: "coa",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          // setData(sort);
          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchCoaNo, searchCoaName]);

  // HEADER ACCOUNT
  useEffect(() => {
    form.setFieldsValue({
      header_account: headerAccount,
    });
  }, [headerAccount]);

  // USE EFFECT
  useEffect(() => {
    setLevelAccount(editData ? editData.level_account : "");
  }, [edit]);

  // Set Level Accoun
  const setLevel = (e) => {
    console.log("Level Account => ", e);

    setLevelAccount(e);
  };

  // SET FORM FIELDS
  const setFormFields = (value) => {
    const _rec = value;

    form.setFieldsValue({
      level_account: _rec.level_account,
      account_code: [
        {
          account_code_1: _rec.account_code.substring(0, 3),
          account_code_2: _rec.account_code.substring(4, 6),
          account_code_3: _rec.account_code.substring(7, 10),
        },
      ],
      name: _rec?.name,
      description: _rec?.description,
      header_account: _rec?.header_account,
      account_type_name: _rec?.account_type_name,
      account_type_id: _rec?.account_type_id,
      financial_statement_name: _rec?.financial_statement_name,
      financial_statement_id: _rec?.financial_statement_id,
      normal_balance: _rec?.normal_balance,
      header_or_detail: _rec.level_account === "1" ? "Header" : "Detail",
      account_balance: _rec?.account_balance,
      department_name: _rec?.department_name,
      department_id: _rec?.department_id,
      currency_name: _rec?.currency_name,
      currency_id: _rec?.currency_id,
      required_guest_id: _rec?.required_guest_id || false,

      created_by: _rec.created_by,
      created_date: _rec.created_date,
      updated_by: _rec.updated_by,
      updated_date: _rec.updated_date,
    });
  };

  // GET DEPARTMENT REUSABLE DROPDOWN
  const getDepartment = (value) => {
    console.log("Dept: ", value);

    if (editData) {
      editData.department_name = value?.name;
    }

    form.setFieldsValue({
      department_name: value?.name,
      department_id: value?.dept_id,
    });
  };
  // GET CURRENCY REUSABLE DROPDOWN
  const getCurrency = (value, code, id) => {
    console.log(value, code, id);

    form.setFieldsValue({
      currency_name: value,
      currency_id: id,
    });
  };
  const getCurrencyCode = (value) => {
    console.log("CODE: ", value);

    setCurrencyCode(value);
  };
  // Get Account Type
  const getAccountType = (value) => {
    const _val = value;
    console.log("Acc Type => ", value);

    form.setFieldsValue({
      account_type_name: _val?.value,
      account_type_id: _val?.id,
    });
  };

  // Get Financial State
  const getFinancial = (value) => {
    const _val = value;
    console.log("Finance >>> ", value);

    form.setFieldsValue({
      financial_statement_name: _val?.value,
      financial_statement_id: _val?.id,
    });
  };

  // HANDLE CHECK
  const handleCheck = (value) => {
    const check = value.target.checked;
    console.log(value);

    form.setFieldsValue({
      required_guest_id: check,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setIsDelete(false);
    setOpen(true);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    const dataModal = val;

    confirm({
      // icon: <ExclamationCircleTwoTone />,
      className: "modal-confirm",
      title:
        isDelete == false
          ? `Are you sure want to ${
              edit ? "Update" : "Add a New"
            } Chart of Account with named ${
              dataModal?.name.toUpperCase() || " - - - - - "
            }?`
          : `Are you sure want to Delete COA named ${
              dataModal?.name.toUpperCase() || " - - - - - "
            }?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(dataModal) : handleSubmit(dataModal);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log("ON FINISH >>> ", value);

    showModalConfirm(value);
    setIsLoading(true);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("ON FINISH FAILED >>> ", error.values);

    masterIncomplete(error);
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(!open);
    setEdit(false);
    setIsDelete(true);

    setIsLoading(false);

    // setStatus(null);
    // setDataStatus(null);
    setAccountCode(null);
    setCurrencyCode(null);
    setFinanceCode(null);
    setDeptCode(null);
    setEditData(null);
    setLevelAccount(1);

    form.resetFields();
  };

  // HADNLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("SUBMITTED >>> ", contentSubmit);

    let acc_code = "";
    let head = "";

    if (levelAccount == 1) {
      acc_code = contentSubmit.account_code[0].account_code_1;
      head = contentSubmit.account_code[0].account_code_1
        .toString()
        .substring(0, 1);
    } else if (levelAccount == 2) {
      head = contentSubmit.account_code[0].account_code_1
        .toString()
        .substring(0, 1);
      acc_code = contentSubmit.account_code[0].account_code_1;
    } else {
      head = contentSubmit.account_code[0].account_code_1
        .toString()
        .substring(0, 4);

      acc_code =
        contentSubmit.account_code[0].account_code_1 +
        "-" +
        contentSubmit.account_code[0].account_code_2 +
        "-" +
        contentSubmit.account_code[0].account_code_3;
    }

    console.log("Arr Code >>> ", acc_code);
    console.log("HEAD >>> ", head);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/accounting/coa`,
          {
            account_code: acc_code,
            name: contentSubmit?.name ? contentSubmit.name : null,
            description: contentSubmit.description
              ? contentSubmit.description
              : null,
            header_account: head,
            account_type_id:
              accountCode != null ? accountCode : contentSubmit.account_type_id,
            // account_type_name: contentSubmit.account_type_name
            //   ? contentSubmit.account_type_name
            //   : null,
            financial_statement_id: contentSubmit.financial_statement_id
              ? contentSubmit.financial_statement_id
              : null,
            // financial_statement_name: contentSubmit.financial_statement_name
            //   ? contentSubmit.financial_statement_name
            //   : null,
            normal_balance:
              contentSubmit.normal_balance >= 0
                ? contentSubmit.normal_balance
                : null,
            level_account: contentSubmit.level_account
              ? contentSubmit.level_account
              : null,
            header_or_detail: levelAccount == 1 ? 0 : 1,
            account_balance: contentSubmit?.account_balance || 0,
            department_id: contentSubmit?.department_id || null,
            // department_name: contentSubmit.department_name
            //   ? contentSubmit.department_name
            //   : null,
            currency_id: contentSubmit?.currency_id
              ? contentSubmit.currency_id
              : null,
            currency_name: contentSubmit?.currency_name
              ? contentSubmit.currency_name
              : null,

            required_guest_id: contentSubmit?.required_guest_id || false,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("POST Main = ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Chart of Accounts",
          });
          // form.resetFields();
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("POST Main = ", error);
          masterFailedNotification(error, {
            method: 0,
            source: "Chart of Accounts",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/accounting/coa`,
          {
            id: editData?.id ? editData.id : editData.id,
            account_code: acc_code,
            name: contentSubmit?.name ? contentSubmit.name : editData?.name,
            description: contentSubmit?.description
              ? contentSubmit.description
              : editData?.description,
            header_account: head ? head : editData.header_account,
            account_type_id: contentSubmit?.account_type_id
              ? contentSubmit.account_type_id
              : editData.account_type_id,
            // account_type_name: contentSubmit?.account_type_name
            //   ? contentSubmit.account_type_name
            //   : editData.account_type_name,
            financial_statement_id: contentSubmit?.financial_statement_id
              ? contentSubmit.financial_statement_id
              : editData?.financial_statement_id,
            // financial_statement_name: contentSubmit?.financial_statement_name
            //   ? contentSubmit.financial_statement_name
            //   : editData.financial_statement_name,
            normal_balance:
              contentSubmit?.normal_balance >= 0
                ? contentSubmit.normal_balance
                : editData.normal_balance,
            level_account: contentSubmit?.level_account
              ? contentSubmit.level_account
              : editData.levelAccount,
            header_or_detail:
              contentSubmit?.header_or_detail == "Header" ? 0 : 1,
            account_balance: contentSubmit?.account_balance
              ? contentSubmit.account_balance
              : editData.account_balance,
            department_id: contentSubmit?.department_id
              ? contentSubmit.department_id
              : editData.department_id,
            // department_name: contentSubmit.department_name
            //   ? contentSubmit.department_name
            //   : editData?.department_name,
            currency_id: contentSubmit?.currency_id
              ? contentSubmit.currency_id
              : editData?.currency_id,
            currency_name: contentSubmit?.currency_name
              ? contentSubmit.currency_name
              : editData.currency_name,

            required_guest_id: contentSubmit?.required_guest_id || false,
            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("PUT Main-Group = ", response);
          masterSuccessNotification(response, {
            method: 1,
            source: "Chart of Accounts",
          });
          // form.resetFields();
          fetchData();
          handleCancel();
        })
        .catch((error) => {
          console.log("PUT Main-Group = ", error);
          masterFailedNotification(error, {
            method: 1,
            source: "Chart of Accounts",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (e) => {
    const contentdelete = e;

    // console.log(contentdelete);

    const obj = {
      ["id"]: contentdelete.id,
      ["deleted_by"]: roles,
      ["level_account"]: contentdelete?.level_account,
    };

    await axios
      .delete(`${baseurl}/accounting/coa`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Delete Res => ", response);
        masterSuccessNotification(response, {
          method: 2,
          source: "Chart of Accounts",
        });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, {
          method: 2,
          source: "Chart of Accounts",
        });
        console.log(error);
      });
    // .finally(() => {
    // });
  };

  // Handle Return
  const handleReturn = () => {
    const _path = "/back-office/accounting";

    navigate(_path);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryCoaNo = searchCoaNo ? searchCoaNo.toLowerCase() : null;
    const queryCoaName = searchCoaName ? searchCoaName.toLowerCase() : null;
    // console.log(query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((value) => {
        const _code = value?.account_code.toLowerCase();
        const _name = value?.name.toLowerCase();

        if (!queryCoaName) {
          // console.log("queryCoaNo");

          return _code.indexOf(queryCoaNo) !== -1;
        }
        if (!queryCoaNo) {
          // console.log("queryCoaName");

          return _name.indexOf(queryCoaName) !== -1;
        }
        if (queryCoaNo && queryCoaName) {
          // console.log("all");

          return (
            _code.indexOf(queryCoaNo) !== -1 &&
            _name.indexOf(queryCoaName) !== -1
          );
        }
      });

      // Trigger render with updated values
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Export
  const handleExport = () => {
    downloadExcel(data, "Chart of Accounts");
  };

  // CONSOLE LOG
  // if (edit == true) {
  //   console.log("Data Edit => ", editData);
  //   console.log(
  //     "SUB STRING ",
  //     editData.account_code.substring(0, 3),
  //     editData.account_code.substring(4, 6),
  //     editData.account_code.substring(7, 10)
  //   );
  // }
  // console.log("CODE >>>", levelAccount);
  //   console.log("DEPT CODE >>>", deptCode);

  return (
    <>
      <Row className="account-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row
            className="row-modal-btn"
            justify="start"
            align="top"
            gutter={[0, 15]}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-exp-btn"
            >
              <Row justify="start" className="row-exp-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-modal-btn"
            >
              <Row justify="end" className="row-open-btn" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Chart of Account`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="main-account-table"
              name="main-account-table"
              key="main-account-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="main-account-modal"
        key="main-account-modal"
        name="main-account-modal"
        title={
          edit == false ? (
            <Row className="row-modal-title">
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Chart of Account (COA)`}
              </Typography>
            </Row>
          ) : (
            <Row className="row-modal-title">
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Chart of Account (COA)`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="main-account-modal-form"
          className="main-account-modal-form"
          key="main-account-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            account_code: [""],
          }}
          // (account_code_1 = 0),
          // (account_code_2 = 0),
          // (account_code_3 = 0),
        >
          <Row className="form-row" justify="center" align="middle">
            <Col span={24} className="form-col" style={{ padding: "0px 36px" }}>
              <Row
                className="row-account"
                gutter={30}
                // justify="end"
              >
                <Col span={12} className="col-lvl-acc">
                  <Form.Item
                    label="Level Account"
                    name="level_account"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose the Level Account!",
                        // max:  1,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      value={
                        edit == true ? editData.level_account : levelAccount
                      }
                      options={optionsAccount}
                      onChange={setLevel}
                      disabled={edit}
                    />
                  </Form.Item>
                </Col>

                <Col
                  span={12}
                  className="col-account-number"
                  style={{
                    width: "100%",
                  }}
                >
                  <Form.Item
                    label="Account Code"
                    name="account_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Account Code Number!",
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Form.List
                      key={"account-code"}
                      // label="Account Code"
                      name="account_code"
                      rules={[
                        {
                          validator: async (_, codes) => {
                            if (!codes || codes == 0) {
                              return Promise.reject(
                                new Error("Account Code is required!")
                              );
                            }
                          },
                        },
                        // {
                        //   required: true,
                        //   message: "Account Code is required!",
                        // },
                      ]}
                    >
                      {(fields, { add, remove }, { errors, warnings }) => (
                        <>
                          {/* <Typography>{`Account Code:`}</Typography> */}
                          {fields.map(({ key, name, ...fieldGroup }) => (
                            <Row
                              gutter={30}
                              justify="end"
                              className={`row-account-code-${key}`}
                              key={`row-account-code-${name}`}
                              align="middle"
                            >
                              <Col span={8} className="col-account-code-1">
                                <Form.Item
                                  {...fieldGroup}
                                  // label="Account Code"
                                  name={[name, "account_code_1"]}
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Account Code is required!",
                                  //   },
                                  // ]}
                                  style={{
                                    width: "100%",
                                    // margin: "0px 30px 0px 0px",
                                  }}
                                >
                                  <InputNumber
                                    placeholder="Level 1/2"
                                    controls={true}
                                    max={levelAccount == 1 ? 9 : 999}
                                    min={0}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={8} className="col-account-code-2">
                                <Form.Item
                                  {...fieldGroup}
                                  name={[name, "account_code_2"]}
                                  //dependencies={[name, "room_category_hide"]}
                                  rules={
                                    levelAccount == 3
                                      ? [
                                          {
                                            required: true,
                                            message:
                                              "Account Code is required!",
                                          },
                                          {
                                            type: "number",
                                            validator: async (_, value) => {
                                              const is_no = value % 1;
                                              // console.log(is_no);

                                              // if (is_no.match(/^[0-9]+$/)) {
                                              if (
                                                is_no != null &&
                                                is_no != NaN &&
                                                is_no != 0
                                              ) {
                                                return Promise.reject(
                                                  "Please, do not input Alphabet!"
                                                );
                                              }
                                            },
                                          },
                                        ]
                                      : [{}]
                                  }
                                  style={{
                                    width: "100%",
                                    // margin: "0px 30px 0px 0px",
                                  }}
                                >
                                  <Input
                                    placeholder="..."
                                    controls={true}
                                    maxLength={2}
                                    max={99}
                                    min={0}
                                    disabled={levelAccount == 3 ? false : true}
                                    // style={{ width: "60px" }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={8} className="col-account-code-3">
                                <Form.Item
                                  {...fieldGroup}
                                  name={[name, "account_code_3"]}
                                  //dependencies={[name, "room_category_hide"]}
                                  rules={
                                    levelAccount == 3
                                      ? [
                                          {
                                            required: true,
                                            message:
                                              "Account Code is required!",
                                          },
                                          {
                                            type: "number",
                                            validator: async (_, value) => {
                                              const is_no = value % 1;
                                              // console.log(is_no);

                                              // if (is_no.match(/^[0-9]+$/)) {
                                              if (
                                                is_no != null &&
                                                is_no != NaN &&
                                                is_no != 0
                                              ) {
                                                return Promise.reject(
                                                  "Please, do not input Alphabet!"
                                                );
                                              }
                                            },
                                          },
                                        ]
                                      : [{}]
                                  }
                                  style={{
                                    width: "100%",
                                    // margin: "0px 30px 0px 0px",
                                  }}
                                >
                                  <Input
                                    placeholder="Level 3"
                                    controls={true}
                                    maxLength={3}
                                    max={999}
                                    min={0}
                                    disabled={levelAccount == 3 ? false : true}
                                    // style={{ width: "60px" }}
                                  />
                                </Form.Item>
                              </Col>

                              <Form.ErrorList errors={errors} />
                              {/* 
                        <MinusCircleOutlined
                          onClick={() => remove(fieldGroup.name)}
                        /> */}
                            </Row>
                          ))}
                          {/* <Form.Item>
                      <Button type="primary" onClick={add}>
                        ADD
                      </Button>
                    </Form.Item> */}
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="row-account-number">
                <Col span={12} className="col-account-name">
                  <Form.Item
                    label="Account Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Account Name is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Account Name" />
                  </Form.Item>
                </Col>

                <Col span={6} className="col-header-acc">
                  <Form.Item label="Header Account" name="header_account">
                    <Input
                      placeholder="Header Account"
                      maxLength={
                        levelAccount == 1
                          ? "1"
                          : levelAccount == 2
                          ? "3"
                          : levelAccount == 3
                          ? "3"
                          : 3
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Header or Detail" name="header_or_detail">
                    <Input placeholder="Header or Detail" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{
                  margin: "5px 0px",
                  background: "#EBEDF3",
                }}
              />

              <Row gutter={30} className="financial-row">
                <Col span={8} className="col-acc-type">
                  <Form.Item label="Account Type" name="account_type_name">
                    <MasterAccountType
                      getAccountType={getAccountType}
                      account_type={
                        edit == true ? editData?.account_type_name : null
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Account Type ID"
                    name="account_type_id"
                    hidden
                  >
                    <Input placeholder="Account Type ID" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Financial Statement"
                    name="financial_statement_name"
                  >
                    <MasterFinancial
                      getFinancial={getFinancial}
                      financial_statement={
                        edit == true ? editData?.financial_statement_name : null
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Financial Statement ID"
                    name="financial_statement_id"
                    hidden
                  >
                    <Input placeholder="Financial" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Department" name="department_name">
                    <MasterDepartment
                      getDepartment={getDepartment}
                      department_name={
                        edit == true ? editData.department_name : null
                      }
                      //   dept_code={setDeptCode}
                    />
                  </Form.Item>

                  <Form.Item
                    className="dept"
                    label="Department ID"
                    name="department_id"
                    hidden
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={30}
                className="row-balance"
                justify="start"
                align="bottom"
              >
                <Col span={8} className="col-balance">
                  <Form.Item label="Normal Balance" name="normal_balance">
                    <Select
                      style={{ width: "100%" }}
                      value={edit == true ? editData?.normal_balance : null}
                      options={optionsBalance}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Currency" name="currency_name">
                    <MasterCurrency
                      getCurrency={getCurrency}
                      getCurrencyCode={getCurrencyCode}
                      pay={
                        editData?.currency_name ? editData.currency_name : null
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Currency" name="currency_id" hidden>
                    <InputNumber />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="checkbox-form"
                    // label="Required"
                    name="required_guest_id"
                    valuePropName="checked"
                  >
                    <Checkbox
                      className="checkbox-form"
                      // checked={}
                      // value={edit ? editData?.required_guest_id : false}
                      onChange={handleCheck}
                    >{`Required Guest ID`}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start" align="middle" gutter={30}>
                <Col span={16}>
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      placeholder="Description"
                      rows={4}
                      showCount
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{
                  margin: "10px 0px",
                  background: "#EBEDF3",
                }}
              />

              {edit == true ? (
                <>
                  <Row gutter={30} className="created-row">
                    <Col span={8}>
                      <Form.Item label="Created By" name="created_by">
                        <Input placeholder="Created By" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Created Date" name="created_date">
                        <Input placeholder="Created Date" disabled />
                      </Form.Item>
                    </Col>
                    {/* </Row>

                  <Row gutter={30} className="updated-row"> */}
                    <Col span={8}>
                      <Form.Item label="Updated By" name="updated_by">
                        <Input placeholder="Updated By" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Updated Date" name="updated_date">
                        <Input placeholder="Updated Date" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    className="form-divider"
                    style={{
                      margin: "0px 0px 15px",
                      background: "#EBEDF3",
                    }}
                  />
                </>
              ) : null}
            </Col>
          </Row>

          <Row
            gutter={30}
            className="row-modal-btn"
            justify="end"
            align="middle"
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              className="submit-btn"
              loading={isLoading}
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../../API/Context/MainContext/MainContext";

// FORM
import RequisitionSummaryForm from "../../../../../../Forms/RequisitionSummary/Form";

export default function RequisitionSummaryReportTable(props) {
  // PROPS
  const { _main, _fromStorage, _toStorage, _startDate, _endDate, _type } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [total, setTotal] = useState({
    quantity: 0,
    avg_price: 0,
    amount: 0,
    mtd_qty: 0,
    mtd_avg_price: 0,
    mtd_amt: 0,
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // DATA DUMMY
  const items = [
    { cost_allocation: "Marketing", from_storage: "Warehouse 1", article_number: "A12345", article_name: "Product XYZ", quantity: 100, avg_price: 25.50, amount: 2550, mtd_qty: 500, mtd_avg_price: 24.75, mtd_amt: 12375 },
    { cost_allocation: "Sales", from_storage: "Warehouse 2", article_number: "B67890", article_name: "Product ABC", quantity: 200, avg_price: 15.00, amount: 3000, mtd_qty: 1000, mtd_avg_price: 14.80, mtd_amt: 14800 },
    { cost_allocation: "R&D", from_storage: "Warehouse 3", article_number: "C11223", article_name: "Product LMN", quantity: 150, avg_price: 30.00, amount: 4500, mtd_qty: 700, mtd_avg_price: 29.50, mtd_amt: 20650 },
    { cost_allocation: "Operations", from_storage: "Warehouse 4", article_number: "D44556", article_name: "Product OPQ", quantity: 120, avg_price: 22.75, amount: 2730, mtd_qty: 600, mtd_avg_price: 22.50, mtd_amt: 13500 },
    { cost_allocation: "HR", from_storage: "Warehouse 5", article_number: "E77889", article_name: "Product RST", quantity: 180, avg_price: 18.25, amount: 3285, mtd_qty: 850, mtd_avg_price: 18.00, mtd_amt: 15300 },
    { cost_allocation: "IT", from_storage: "Warehouse 6", article_number: "F99001", article_name: "Product UVW", quantity: 210, avg_price: 27.50, amount: 5775, mtd_qty: 900, mtd_avg_price: 27.00, mtd_amt: 24300 },
    { cost_allocation: "Logistics", from_storage: "Warehouse 7", article_number: "G22334", article_name: "Product XYZ2", quantity: 130, avg_price: 20.00, amount: 2600, mtd_qty: 550, mtd_avg_price: 19.75, mtd_amt: 10862.5 },
    { cost_allocation: "Procurement", from_storage: "Warehouse 8", article_number: "H55667", article_name: "Product ABC2", quantity: 170, avg_price: 14.50, amount: 2465, mtd_qty: 780, mtd_avg_price: 14.25, mtd_amt: 11115 },
    { cost_allocation: "Finance", from_storage: "Warehouse 9", article_number: "I88990", article_name: "Product LMN2", quantity: 160, avg_price: 31.00, amount: 4960, mtd_qty: 720, mtd_avg_price: 30.75, mtd_amt: 22140 },
    { cost_allocation: "Admin", from_storage: "Warehouse 10", article_number: "J11223", article_name: "Product OPQ2", quantity: 140, avg_price: 26.00, amount: 3640, mtd_qty: 650, mtd_avg_price: 25.75, mtd_amt: 16737.5 }
  ];

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if ((_startDate && _endDate && _type && _fromStorage && _toStorage && !cleanUp) ||  (_startDate && _endDate && _type && _main && !cleanUp)) {
      fetchData();
    } else {
      setData([]);
      setTotal({
        quantity: 0,
        avg_price: 0,
        amount: 0,
        mtd_qty: 0,
        mtd_avg_price: 0,
        mtd_amt: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [_startDate, _endDate, _type, _fromStorage, _toStorage, _main]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    let bodyReq = {};

    if (_fromStorage && _toStorage) {
      bodyReq = {
        from_warehouse: _fromStorage.id_warehouse,
        to_warehouse: _toStorage.id_warehouse,
        from_date: moment(_startDate).format("YYYY-MM-DD"),
        to_date: moment(_endDate).format("YYYY-MM-DD")
      };
    } else if (_main) {
      bodyReq = {
        article_main_id: _main.main_id,
        from_date: moment(_startDate).format("YYYY-MM-DD"),
        to_date: moment(_endDate).format("YYYY-MM-DD")
      };
    }

    // await getByPostInventoryReport({
    //   type: `transfer-summary`,
    //   bodyReq: bodyReq,
    //   onAwait: () => {
    //     "On Await";
    //   },
    //   onSuccess: (response) => {
        // console.log("ISI >>>> ", response);
        console.log("ISI >>>> ", items);

        // if (response?.data?.msg?.length > 0) {
        if (items.length > 0) {
          // const _data = response.data.msg;
          const _data = items;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
            };
          });

          setData(filter);

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      quantity: prev.quantity + curr.quantity,
                      avg_price: prev.avg_price + curr.avg_price,
                      amount: prev.amount + curr.amount,
                      mtd_qty: prev.mtd_qty + curr.mtd_qty,
                      mtd_avg_price: prev.mtd_avg_price + curr.mtd_avg_price,
                      mtd_amt: prev.mtd_amt + curr.mtd_amt,
                    };
                  },
                  { quantity: 0, avg_price: 0, amount: 0, mtd_qty: 0, mtd_avg_price: 0, mtd_amt: 0, }
                )
              : { quantity: 0, avg_price: 0, amount: 0, mtd_qty: 0, mtd_avg_price: 0, mtd_amt: 0 };

          
          setTotal(sumTotal);
        } else {
          setData([]);
          setTotal({
            quantity: 0,
            avg_price: 0,
            amount: 0,
            mtd_qty: 0,
            mtd_avg_price: 0,
            mtd_amt: 0,
          });
        }

        setIsLoading(false);
    //   },
    //   onReject: (error) => {
    //     console.log("ERROR >>>> ", error);

    //     failedFetch(error);
    //     setIsLoading(false);
    //   },
    // });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Requisition Summary Reports ${
        _startDate ? moment(_startDate).format("YYYY-MM-DD") : ""} - ${
          _endDate ? moment(_endDate).format("YYYY-MM-DD") : ""}`
    );
  };

  // HANDLE EXPORT
  const handlePrint = () => {
    setView(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Cost Allocation",
      dataIndex: "cost_allocation",
      key: "cost_allocation",
    },
    {
      title: "From Storage",
      dataIndex: "from_storage",
      key: "from_storage",
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Avg-Price",
      dataIndex: "avg_price",
      key: "avg_price",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MTD Qty",
      dataIndex: "mtd_qty",
      key: "mtd_qty",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MTD Avg-Price",
      dataIndex: "mtd_avg_price",
      key: "mtd_avg_price",
      render: (text) => {
        return text > 0 ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "MTD Amount",
      dataIndex: "mtd_amt",
      key: "mtd_amt",
      render: (text) => {
        return text > 0 ? `${text.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ].filter(item => !item.hidden);

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              // dataSource={items}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={3} />

                        <Table.Summary.Cell index={0}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.quantity ? total.quantity : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.avg_price ? total.avg_price : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.amount ? total.amount.toFixed(0) : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.mtd_qty ? total.mtd_qty : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.mtd_avg_price ? total.mtd_avg_price.toFixed(0) : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.mtd_amt ? total.mtd_amt.toFixed(0) : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
      <RequisitionSummaryForm
        _openModal={view}
        _closeModal={setView}
        _dataView={view == true ? data : ""}
        _startDate = {_startDate}
        _endDate = {_endDate}
        _type = {_type}
        _main = {_main ? _main : null}
        _fromStorage = {_fromStorage ? _fromStorage : null}
        _toStorage = {_toStorage ? _toStorage : null}
        _total = {total}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Modal,
  Form,
  Divider,
  InputNumber,
  DatePicker,
  Typography,
  Input,
  Radio,
} from "antd";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import {
  approval_level,
  department,
  token,
  user_level,
  user_name,
} from "../../../../API/Global/Payload";

// Import React Icons Components
import {
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterApprovalType from "../../../Reusable/Master/Approval/ApprovalType";
import PRDetailTable from "../../../Table/BackOffice/Purchasing/PurchaseDetail/Table";
import MasterArticleInventory from "../../../Reusable/Master/ArticleInventory/ArticleInventory";

// Import Notification
import { incomplete } from "../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
  masterIncomplete,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import MasterArticleDailyMarket from "../../../Reusable/Master/ArticleDailyMarket/ArticleDailyMarket";

// Modal
const { confirm } = Modal;

// CODE
export default function DailyMarketListModal(props) {
  // PROPS
  const { is_open, is_close, is_refresh, is_edit, selected } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [tableDetail, setTableDetail] = useState([]);
  // Key Tables
  const [lastKey, setLastKey] = useState(0);
  const [key, setKey] = useState(1);
  // ESTIMATED PRICE
  const [estimated, setEstimated] = [];
  // STATE
  const [add, setAdd] = useState([]);
  const [update, setUpdate] = useState([]);
  // Department
  const [dept, setDept] = useState({
    name: "",
    code: "",
    dept_id: 0,
    dept_uuid: "",
  });
  // Approval Type
  const [appType, setAppType] = useState({});

  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  // Loadings
  const [edit, setEdit] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("Real Data: ", data);
  }, [data]);

  useEffect(() => {
    console.log("Real Data Edit: ", dataEdit);
  }, [dataEdit]);

  useEffect(() => {
    console.log("Real Table Detail: ", tableDetail);
  }, [tableDetail]);

  useEffect(() => {
    console.log("Real Table Add: ", add);
  }, [add]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      // console.log("Modal State: ", is_open);
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
      form.setFieldsValue({
        request_date: moment(),
      });
    }

    if (is_edit == true && !cleanUp) {
      console.log("Edit Data: ", selected);
      setEdit(true);

      if (selected) {
        let newTableDetail = selected?.details.map((dt, index) => {
          return {
            id_pr_detail: dt.id_pr_detail,
            id_article: dt.id_article,
            quantity: dt.quantity,
            description: dt.description,
            article_name: dt.article_name,
            unit: dt.unit,
            average_price: dt.average_price,
            est_price: dt.quantity * dt.average_price,
            key: index + 1,
          };
        });
        handleFormField(selected);
        setDataEdit(selected);
        setTableDetail(newTableDetail);

        setDept({
          code: "",
          dept_id: selected?.id_department,
          dept_uuid: selected?.dept_uuid,
        });
      } else {
        setDataEdit(null);
        setTableDetail([]);
        setDept({
          name: "",
          code: "",
          dept_id: 0,
          dept_uuid: "",
        });
      }
    } else {
      setEdit(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, is_edit, selected]);

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;
    console.log("Handle Form: ", _record);

    form.setFieldsValue({
      is_direct: _record?.is_direct == true ? 1 : 0,
      request_date: _record?.request_date ? moment(_record.request_date) : null,
      needed_date: _record?.needed_date ? moment(_record.needed_date) : null,
      remarks: _record?.remarks ? _record?.remarks : null,
      department_id: _record?.id_department,
      department: _record?.name,
      approval_type: _record?.approval_flow > 0 ? _record?.approval_flow : 0,
    });

    // let _last_keys = _record.details.
  };

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);

    // console.log("USERS METADATA: ", approval_level, department, user_level);
  };

  // Handle Cancel
  const handleCancel = () => {
    setIsCreate(false);
    setIsDraft(false);
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);

    setEdit(false);
    setDataEdit(null);
    setTableDetail(null);
    setAdd([]);
    setUpdate([]);
    setModalOpen(false);
    setIsCreate(false);
    setIsDraft(false);
    setLastKey(0);

    setData([]);
    setDept({
      name: "",
      code: "",
      dept_id: 0,
      dept_uuid: "",
    });

    form.resetFields();
  };

  // ON FINISH EDIT
  const onFinishEdit = (e) => {
    const _submit = e;

    // let _added_items = [];
    // let _updated_items = [];

    // let _finded = tableDetail.filter((record) => {
    //   console.log("Records: ", record);

    //   if (record?.id_pr_detail) {
    //     _updated_items.push({
    //       id_pr_detail: record.id_pr_detail,
    //       quantity: record.quantity,
    //     });
    //   }
    // });

    // let _find = data.filter((record) => {
    //   console.log("Records: ", record);
    //   if (record?.key > 0) {
    //     _added_items.push({
    //       id_article: record?.id_article,
    //       quantity: record?.quantity,
    //     });
    //   }
    // });

    // console.log("Added Items: ", _added_items);
    // console.log("Updated Items: ", _updated_items);

    // // setAdd(_added_items);
    // // setUpdate(_updated_items);

    let _new_article = {
      id_pr_detail: null,
      id_article: _submit?.id_article,
      quantity: _submit?.quantity,
      description: _submit?.description,
      article_name: _submit?.article_inventory,
      unit: _submit?.unit,
      average_price: _submit?.average_price,
      est_price: _submit?.quantity * _submit?.average_price,
      key: tableDetail.length + 1,
    };

    // Cek apakah jumlah data lebih dari 0?
    if (tableDetail?.length > 0) {
      console.log("OnFinishEdit >> Sudah Ada Data");
      // console.log("Data Ada", data);
      // console.log("Data ID", _submit?.id_article);

      // Cek apakah article sudah tersedia (cek menggunakan id_article)
      if (tableDetail.some((item) => item.id_article === _submit?.id_article)) {
        console.log("OnFinishEdit >> Sudah Ada Data >> Article sama ada");
        let _new = tableDetail.map((a) => {
          if (a.id_article === _submit?.id_article) {
            if (_submit?.description == null) {
              return {
                ...a,
                quantity: a.quantity + _submit?.quantity,
                est_price: (a.quantity + _submit?.quantity) * a.average_price,
              };
            } else {
              if (a.description == null || a.description == "undefined") {
                return {
                  ...a,
                  quantity: a.quantity + _submit?.quantity,
                  est_price: (a.quantity + _submit?.quantity) * a.average_price,
                  description: _submit?.description,
                };
              } else {
                return {
                  ...a,
                  quantity: a.quantity + _submit?.quantity,
                  est_price: (a.quantity + _submit?.quantity) * a.average_price,
                  description: a.description + ", " + _submit?.description,
                };
              }
            }
          } else {
            // No changes
            // console.log("NO CHANGES >> ", a);
            return a;
          }
        });

        // // console.log("New Article: ", _new);
        setTableDetail(_new);
        // // setAdd(_new);

        form.resetFields([
          "article_inventory",
          "quantity",
          "unit",
          "article_number",
          "chart_of_account",
          "content",
          "average_price",
          "description",
          //   "article_id",
        ]);
      } else {
        console.log("OnFinishEdit >> Sudah Ada Data >> Article sama blm ada");
        setTableDetail([
          ...(tableDetail?.length > 0 ? tableDetail : []),
          _new_article,
        ]);

        form.resetFields([
          "article_inventory",
          "quantity",
          "unit",
          "article_number",
          "chart_of_account",
          "content",
          "average_price",
          "description",
          //   "article_id",
        ]);

        setLastKey(lastKey + 1);
      }

      // console.log("INDEX OF >> ", data);
    } else {
      console.log("OnFinishEdit >> Tidak Ada Data");

      setTableDetail([_new_article]);

      form.resetFields([
        "article_inventory",
        "quantity",
        "unit",
        "article_number",
        "chart_of_account",
        "content",
        "average_price",
        "description",
        //   "article_id",
      ]);
    }

    console.log("PROCESS ON FINISH SUCCESS", e);
    console.log("tableDetail Length: ", tableDetail.length);
  };

  const onFinish = (e) => {
    const _submit = e;

    let _new_article = {
      key: lastKey + key,
      department_id: _submit?.department_id,
      request_date: moment(_submit?.request_date),
      needed_date: moment(_submit?.needed_date),
      remarks: _submit?.remarks,
      created_by: user_name,
      approval_flow: _submit?.approval_type,
      information: "",
      is_direct: _submit?.is_direct,
      current_approval_level: user_level + 1,
      id_article: _submit?.id_article,
      quantity: _submit?.quantity,
      description: _submit?.description,

      department: _submit?.department,
      article_name: _submit?.article_inventory,
      unit: _submit?.unit,
      article_number: _submit?.article_number,
      chart_of_account: _submit?.chart_of_account,
      average_price: _submit?.average_price,
      est_price: _submit?.quantity * _submit?.average_price,
    };

    // Cek apakah jumlah data lebih dari 0?
    if (data?.length > 0) {
      console.log("Data Ada", data);
      console.log("Data ID", _submit?.id_article);

      // Cek apakah article sudah tersedia (cek menggunakan id_article)
      if (data.some((item) => item.id_article === _submit?.id_article)) {
        let _new = data.map((a) => {
          if (a.id_article === _submit?.id_article) {
            if (_submit?.description == null) {
              return {
                ...a,
                quantity: a.quantity + _submit?.quantity,
                est_price: (a.quantity + _submit?.quantity) * a.average_price,
              };
            } else {
              if (a.description == null || a.description == "undefined") {
                return {
                  ...a,
                  quantity: a.quantity + _submit?.quantity,
                  est_price: (a.quantity + _submit?.quantity) * a.average_price,
                  description: _submit?.description,
                };
              } else {
                return {
                  ...a,
                  quantity: a.quantity + _submit?.quantity,
                  est_price: (a.quantity + _submit?.quantity) * a.average_price,
                  description: a.description + ", " + _submit?.description,
                };
              }
            }
          } else {
            // No changes
            // console.log("NO CHANGES >> ", a);
            return a;
          }
        });

        // console.log("New Article: ", _new);
        setData(_new);
        // setAdd(_new);

        form.resetFields([
          "article_inventory",
          "quantity",
          "unit",
          "article_number",
          "chart_of_account",
          "content",
          "average_price",
          "description",
          //   "article_id",
        ]);
      } else {
        console.log("Mode >> Nambah Data Baru");
        setData([...(data?.length > 0 ? data : []), _new_article]);

        form.resetFields([
          "article_inventory",
          "quantity",
          "unit",
          "article_number",
          "chart_of_account",
          "content",
          "average_price",
          "description",
          //   "article_id",
        ]);

        setLastKey(lastKey + 1);
      }

      // console.log("INDEX OF >> ", data);
    } else {
      console.log("Mode >> Add Data Pertama");

      setData([...(data?.length > 0 ? data : []), _new_article]);

      form.resetFields([
        "article_inventory",
        "quantity",
        "unit",
        "article_number",
        "chart_of_account",
        "content",
        "average_price",
        "description",
        //   "article_id",
      ]);

      setLastKey(lastKey + 1);
    }

    // console.log("INI DATANYA >> ", data);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // Handle Add
  const handleAdd = async () => {
    let _newArticle = [];

    await form
      .validateFields()
      .then((values) => {
        console.log("Validates: ", values);

        let _validators = form.getFieldsValue([
          "article_inventory",
          "id_article",
          "quantity",
          "unit",
          "article_number",
          "chart_of_account",
        ]);

        _newArticle.push(_validators);
      })
      .catch((errorInfo) => {
        console.log("Validator: ", errorInfo);

        incomplete(errorInfo);
      });

    // console.log(_newArticle);

    if (_newArticle.length > 0) {
      let _newArr = _newArticle.map((val) => ({
        ...val,
        quantity: val?.quantity > 0 ? val.quantity : 0,
        article_name: val?.article_inventory,
        unit: "Pieces",
        id: 1,
      }));

      console.log("New Array: ", _newArr);
      setData(_newArr);

      form.resetFields([
        "article_inventory",
        "id_article",
        "quantity",
        "unit",
        "article_number",
        "chart_of_account",
      ]);
    } else {
      setData([]);
    }
  };

  // ON SAVE AS DRAFT
  const onSave = () => {
    setIsDraft(true);

    const error = {
      errorFields: [
        {
          errors: ["Please, Add an Articles!"],
        },
      ],
    };

    if (edit == false) {
      console.log("DATA ONSAVE >> ", data);

      if (data.length > 0) {
        showModalConfirm(data, false);
      } else {
        masterIncomplete(error);
        setIsCreate(false);
        setIsDraft(false);
      }
    } else {
      console.log("Data ONSAVE EDIT: ", data);
      showModalConfirm(data, false);
    }
  };

  // ON FINISH SUBMIT
  const onSubmit = () => {
    setIsCreate(true);

    const error = {
      errorFields: [
        {
          errors: ["Please, Add an Articles!"],
        },
      ],
    };

    console.log("DATA ONSUBMIT >> ", data);

    if (edit == false) {
      if (data.length > 0) {
        showModalConfirm(data, true);
      } else {
        masterIncomplete(error);
        setIsCreate(false);
        setIsDraft(false);
      }
    } else {
      console.log("Data ONSAVE EDIT: ", data);
      showModalConfirm(data, true);
    }
  };

  // HANDLE DEPARTMENT
  const getDepartment = (val) => {
    const _value = val;
    console.log("Dept: ", _value);

    setDept({
      code: _value?.code,
      dept_id: _value?.dept_id,
      dept_uuid: _value?.dept_uuid,
      name: _value?.name,
    });

    form.setFieldsValue({
      department: _value?.name,
      department_id: _value?.dept_id,
    });
  };

  const getDeptCode = (code) => {
    console.log(code);
  };

  // HANDLE APPROVAL TYPE
  const getApprovalType = (val) => {
    const _value = val;
    console.log("ID Approval Type: ", _value);

    setAppType({
      id: _value?.id,
    });

    form.setFieldsValue({
      approval_type: _value?.id,
    });
  };

  // Get Article Inventory
  const getArticle = (val) => {
    const allKeys = val;
    console.log("ARTICLE INVENTORY: ", val);

    // setKey(coa);

    form.setFieldsValue({
      article_inventory: allKeys?.value,
      article_number: allKeys?.article_number,
      unit: allKeys?.unit,
      content: allKeys?.content,
      chart_of_account: allKeys?.coa,
      id_article: allKeys?.id,
      average_price: allKeys?.average_price,
    });
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value, status) => {
    const _data = value;
    const _status = status;

    let submitEdit = form.getFieldsValue([
      "request_date",
      "needed_date",
      "is_direct",
      "remarks",
    ]);

    // console.log("SHOW MODAL CONFIRM STAGE >> ", _data);
    // console.log("submitEdit: ", submitEdit, _status);

    console.log("Update: ", update);
    console.log("Add: ", add);

    confirm({
      className: "modals-confirm",
      title:
        _status == true
          ? `Are you sure want to Submit and Create a Purchase Request?`
          : `Are you sure want to Save a Purchase Request as Draft?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        if (edit == false) {
          handleFinish(data, _status);
        } else {
          handleFinish(submitEdit, _status);
        }
      },

      onCancel() {
        setIsDraft(false);
        setIsCreate(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Finish
  const handleFinish = async (value, status) => {
    let filtered = value;
    let _status = status;

    let added_items = [];
    let updated_items = [];

    // let _finded = tableDetail.filter((record) => {
    //   console.log("Records: ", record);

    //   if (record?.id_pr_detail) {
    //     _updated_items.push({
    //       id_pr_detail: record.id_pr_detail,
    //       quantity: record.quantity,
    //     });
    //   }
    // });

    // let _find = data.filter((record) => {
    //   console.log("Records: ", record);
    //   if (record?.key > 0) {
    //     _added_items.push({
    //       id_article: record?.id_article,
    //       quantity: record?.quantity,
    //     });
    //   }
    // });

    // console.log("Added Items: ", add);
    // console.log("Updated Items: ", update);

    // setAdd(_added_items)
    // setAdd(_added_items)

    if (edit === false) {
      // Create
      let article = [];

      let allBody = {
        department_id: filtered[0]?.department_id,
        request_date: moment(filtered[0]?.request_date).format("YYYY-MM-DD"),
        needed_date: moment(filtered[0]?.needed_date).format("YYYY-MM-DD"),
        remarks: filtered[0]?.remarks ? filtered[0]?.remarks : null,
        created_by: user_name,
        approval_flow: filtered[0]?.approval_flow,
        information: "",
        is_direct: filtered[0]?.is_direct,
        pr_status: _status,
        current_approval_level: filtered[0]?.current_approval_level,
        is_dml: true,
      };
      filtered.map((dt) => {
        article.push({
          id_article: dt.id_article,
          quantity: dt.quantity,
          description: dt.description,
        });
      });

      allBody.article = article;

      console.log("ALL BODY >> ", allBody);
      handleSubmit(allBody);
    } else {
      // EDIT
      // setTimeout(() => {
      let allBody = {
        id_pr: dataEdit?.id_pr,
        request_date: moment(filtered?.request_date).format("YYYY-MM-DD"),
        needed_date: moment(filtered?.needed_date).format("YYYY-MM-DD"),
        is_direct: filtered?.is_direct,
        pr_status: _status,
        updated_by: user_name,
        remarks: filtered?.remarks,
        // add: add,
        // update: update,
        is_dml: true,
      };

      tableDetail
        .filter((a) => a.id_pr_detail == null)
        .map((dt) => {
          added_items.push({
            id_article: dt.id_article,
            quantity: dt.quantity,
            description: dt.description,
          });
        });

      allBody.add = added_items;

      tableDetail
        .filter((a) => a.id_pr_detail != null)
        .map((dt) => {
          updated_items.push({
            id_pr_detail: dt.id_pr_detail,
            quantity: dt.quantity,
            description: dt.description,
          });
        });

      allBody.update = updated_items;
      console.log("ALL BODY EDIT >> ", allBody);

      handleSubmit(allBody);
      // }, 1500);
    }
  };

  // Handle Direct
  const handleDirect = (val) => {
    const value = val.target.value;
    // e.preventDefault();

    console.log("Direct: ", value);
  };

  // Handle Delete
  const handleAboutDelete = (val) => {
    // console.log("Data: ", data);

    showModalDelete(val);
  };

  // Modal Delete
  const showModalDelete = (val) => {
    console.log("Delete: ", val);

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Delete an Article ${
        val?.article_name.toUpperCase() || " ----- "
      } for this Purchase Request?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(val);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Delete
  const handleEditDetail = (val) => {
    console.log("Edit data: ", val);
    if (edit) {
      setTableDetail(val);
    } else {
      setData(val);
    }
    // setUpdate(val);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("Data About to PUT/POST: ", contentSubmit);

    if (edit == false) {
      await axios
        .post(`${baseurl}/purchasing/purchase-request`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Add Article Purchase Request: ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Purchase Request",
          });
          handleClose();
          is_refresh(true);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 0,
            source: "Purchase Request",
          });
        })
        .finally(() => {
          handleCancel();
        });
    } else {
      await axios
        .put(`${baseurl}/purchasing/purchase-request`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Update Article Purchase Request: ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Purchase Request",
          });
          handleClose();
          is_refresh(true);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 1,
            source: "Purchase Request",
          });
        })
        .finally(() => {
          handleCancel();
        });
    }
  };

  // Handle Delete
  const handleDelete = async (val) => {
    let _deleted = data.filter((value) => {
      console.log("value, val >> ", value.id_article, val.id_article);
      return value.id_article != val.id_article;
    });

    let _deletedEdit = tableDetail?.filter((value) => {
      console.log("value, val >> ", value.id_article, val.id_article);
      return value.id_article != val.id_article;
    });

    console.log("VAL >> ", val);
    console.log("DATA >> ", dataEdit);
    console.log("DetailLength", dataEdit?.details?.length);

    if (edit === false) {
      setData(_deleted);
      masterSuccessNotification(null, {
        method: 2,
        source: "Article Purchase Request",
      });
    } else {
      setTableDetail(_deletedEdit);

      let obj = {
        id_pr_detail: val.id_pr_detail,
        deleted_by: user_name,
      };

      await axios
        .delete(`${baseurl}/purchasing/purchase-request-detail`, {
          data: obj,
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        })
        .then((response) => {
          console.log("DEL Article => ", response);

          masterSuccessNotification(response, {
            method: 2,
            source: "Article Purchase Request",
          });
          setTableDetail(_deletedEdit);
          is_refresh(false);
        })
        .catch((error) => {
          masterFailedNotification(error, {
            method: 2,
            source: "Article Purchase Request",
          });
          console.log("DEL Article => ", error);
        });
    }
  };

  return (
    <>
      <Modal
        className="purchase-req-modal"
        key="purchase-req-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Daily Market List Request`}</Typography>
            </Row>
          </>
        }
        centered
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={1000}
      >
        <Form
          className="purchase-req-form"
          key="purchase-req-form"
          name="purchase-req-form"
          layout="vertical"
          form={form}
          onFinish={edit ? onFinishEdit : onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="pay-row" gutter={30}>
                <Col span={12} className="pay-col">
                  <Form.Item
                    label="Department"
                    name="department"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Department!",
                      },
                    ]}
                  >
                    <MasterDepartment
                      getDepartment={getDepartment}
                      department_name={edit ? dataEdit?.name : null}
                      disabled={edit ? true : false}
                    />
                  </Form.Item>

                  <Form.Item label="Department ID" name="department_id" hidden>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="pay-col">
                  <Form.Item
                    label="Receiving"
                    name="is_direct"
                    onChange={handleDirect}
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Department!",
                      },
                    ]}
                  >
                    <Radio.Group className="radio-group">
                      <Radio value={1}>Direct</Radio>
                      <Radio value={0}>In-Direct</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30}>
                <Col span={8} className="req-date-col">
                  <Form.Item
                    label="Request Date"
                    name="request_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select a Request Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Request Date"
                      className="date"
                      // defaultValue={moment()}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="need-date-col">
                  <Form.Item
                    label="Needed Date"
                    name="needed_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select a Request Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="date"
                      placeholder="Needed Date"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="need-date-col">
                  <Form.Item
                    label="Budget Allocation"
                    name="approval_type"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select an Approval Flow!",
                      },
                    ]}
                  >
                    <MasterApprovalType
                      approvalTypeEdit={edit ? dataEdit?.approval_flow : null}
                      ApprovalType={getApprovalType}
                      disabled={edit ? true : false}
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={8} className="comm-col">
                  <Form.Item
                    label="Approval Level"
                    name="approval_level"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select the Level of Approval!",
                      },
                    ]}
                  >
                    <MasterApprovalLevel
                      approvals={is_edit ? selected?.approval_level : null}
                      getApprovalLevel={getApproval}
                    />
                  </Form.Item>
                </Col> */}
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row className="pay-row" gutter={30} justify="start">
                <Col span={24} className="pay-col">
                  <Form.Item label="Remarks" name="remarks">
                    <Input.TextArea
                      // disabled={is_edit ? 1 : 0}
                      className="remarks-area"
                      placeholder="Request Purchase Remarks"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Select Articles`}
              </Divider>

              <Row className="article-row" gutter={30} justify="start">
                <Col span={8} className="article-col">
                  <Form.Item
                    label="Article"
                    name="article_inventory"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select an Article Inventory!",
                      },
                    ]}
                  >
                    <MasterArticleDailyMarket
                      getArticle={getArticle}
                      departmentid={
                        edit ? dataEdit?.id_department : dept.dept_id
                      }
                      disable={dept.dept_id > 0 ? false : true}
                    />
                  </Form.Item>

                  <Form.Item name="id_article" hidden>
                    <Input
                      disabled
                      className="id-input"
                      placeholder="Article's id"
                    />
                  </Form.Item>
                </Col>

                <Col span={4} className="unit-col">
                  <Form.Item label="D-Unit" name="unit">
                    <Input
                      disabled
                      className="unit-input"
                      placeholder="Article's Unit"
                    />
                  </Form.Item>
                </Col>

                <Col span={4} className="content-col">
                  <Form.Item label="Content" name="content">
                    <Input
                      disabled
                      className="content-input"
                      placeholder="Article's Content"
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="info-col">
                  <Form.Item label="Article Number" name="article_number">
                    <Input disabled placeholder="Article Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="info-row" gutter={30} justify="start">
                <Col span={8} className="unit-col">
                  <Form.Item label="Chart of Account" name="chart_of_account">
                    <Input
                      disabled
                      className="coa-input"
                      placeholder="Chart of Account"
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="avgprice-col">
                  <Form.Item label="Average Price" name="average_price">
                    <InputNumber
                      disabled
                      className="avgprice-input"
                      formatter={(e) => {
                        return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      placeholder="Average Price"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="qty-col">
                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input number of Quantity!",
                      },
                    ]}
                  >
                    <InputNumber
                      className="quantity-input"
                      placeholder="Quantity"
                      min={0}
                      formatter={(qty) =>
                        ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row className="pay-row" gutter={30} justify="start">
                <Col span={24} className="pay-col">
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      // disabled={is_edit ? 1 : 0}
                      className="description-area"
                      placeholder="Article Daily Market List Description"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="article-row" justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                >{`Add Article`}</Button>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Article Daily Market List`}
              </Divider>

              <PRDetailTable
                article={edit ? tableDetail : data}
                getDeleteDetail={handleAboutDelete}
                getEditDetail={handleEditDetail}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={onSave}
                  disabled={isCreate}
                  loading={isDraft}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Save as Draft`}
                </Button>

                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={onSubmit}
                  disabled={isDraft}
                  loading={isCreate}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import Page Components
import React from "react";
import { useNavigate } from "react-router-dom";

// Import React Components
import { Button, Result, Row, Typography } from "antd";
import {
  CloseCircleOutlined,
  FrownOutlined,
  HomeOutlined,
} from "@ant-design/icons";

const { Paragraph, Text } = Typography;

// CODE
export default function NotFound() {
  // USE NAVIGATE
  const navigate = useNavigate();

  // Navigate Home
  const move = (e) => {
    navigate("/dashboard");
  };

  return (
    <>
      <Result
        className="not-found-results"
        status="404"
        title={
          <>
            <Row
              gutter={30}
              justify="center"
              className="404-row"
              align="middle"
            >
              <Typography
                className="text"
                style={{
                  color: "#237804",
                  fontSize: 25,
                  fontWeight: 750,
                  margin: "0px 5px 0px",
                }}
              >
                {`NOT FOUND!`}
              </Typography>

              <FrownOutlined
                className="frown-icon"
                style={{
                  fontSize: 30,
                  color: "#237804",
                }}
              />
            </Row>
          </>
        }
        subTitle={
          <Typography
            className="text"
            style={{
              color: "#000000",
              fontSize: 25,
              fontWeight: 750,
              margin: "0px 5px 0px",
            }}
          >
            {`Sorry, the page you are trying to visited is not exist!`}
          </Typography>
        }
        extra={
          <Button
            className="next-btn"
            type="primary"
            icon={<HomeOutlined />}
            onClick={move}
          >
            {`Back Home`}
          </Button>
        }
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: 5,
        }}
      />

      {/* </Result> */}
    </>
  );
}

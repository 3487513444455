// Import React's Component
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../../../API/Config/Api";
import { user_name, token } from "../../../../../../API/Global/Payload";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Col,
  Tooltip,
  Modal,
  Popconfirm,
  InputNumber,
  Input,
  Typography,
} from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { FiCheckCircle, FiEdit, FiXCircle } from "react-icons/fi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import {
  failedPO,
  failedSI,
  successPO,
  successSI,
} from "../../../../../Reusable/Notification/Notification";

// Modal
const { confirm } = Modal;

// Import React Icons Components
// CODE
export default function ReceivingDetailModalTable(props) {
  // PROPS
  const { dataDetail, dataEdit, getAfterDelete, is_edit, orderType } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  // Selected Data & Keys
  const [select, setSelect] = useState(null);
  const [editingKey, setEditingKey] = useState("");

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);
  // Boolean
  const [edit, setEdit] = useState(false);
  // Loadings
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  const isEditing = (record, index) => index === editingKey;

  // SET FIELDS EDIT
  const editData = (record, index) => {
    console.log("EDIT DATA RECORD >>>", record);
    console.log("EDIT DATA INDEX >>>", index);
    setEditingKey(index);

    form.setFieldsValue({
      quantity_ready: 0,
      ...record,
    });
  };

  const save = async (e, x) => {
    let index = e;
    let artID = x;
    try {
      const row = await form.validateFields();
      const newData = [...data];
      let find = data.findIndex((v) => v.id_article == artID);
      let compare = newData[index].price !== row.price;
      // let compare_quantity =
      //   newData[index].quantity_received !== row.quantity_received;
      console.log(newData);
      // const index = newData.findIndex((item) => key === item.key);
      if (find > -1) {
        const item = newData[find];
        newData.splice(find, 1, {
          ...item,
          ...row,
          ...{
            price_update: compare ? true : false,
            // quantity_update: compare_quantity ? true : false,
            // quantity_difference:
            //   row.quantity_received - newData[index].quantity_received,
          },
        });
        setData(newData);
        dataEdit(newData);
        setEditingKey("");
        setEdit(false);
      } else {
        newData.push(row);
        setData(newData);
        dataEdit(newData);
        setEditingKey("");
        setEdit(false);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    setEditingKey("");
    setEdit(false);
  };

  // Fetch Data
  const fetchData = async () => {
    // await
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("Article: ", dataDetail);

    if (dataDetail?.length > 0 && !cleanUp) {
      setData(dataDetail);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail]);

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      //   fixed: "left",
      width: 70,
      render: (_, record, index) => {
        const editable = isEditing(record, index);
        return editable ? (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <FaCheckCircle
                  className="edit-btn"
                  onClick={() => {
                    save(index, record.id_article);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Col>
              <Col>
                <FaTimesCircle
                  className="delete-btn"
                  onClick={cancel}
                  style={{ fontSize: 20, color: "#F64E60" }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Quantity" className="delete-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      if (edit != true) {
                        console.log("IS EDITING QTY: ", editingKey);
                        console.log("Edit: ", record);
                        console.log("Edit: ", edit);
                        editData(record, index);
                        setEdit(true);
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      if (edit != true) {
                        console.log("ABOUT TO DELETE > ", record);
                        if ("id_sid" in record) {
                          showModalConfirm(record, index);
                        } else {
                          let arr = [];
                          console.log("NO ID_PO_DETAIL >>>");
                          console.log("data >>>", data);
                          console.log("index >>>", index);
                          // let afterDeleted = data.splice(index, 1);
                          // arr.push(afterDeleted);
                          // console.log("After Delete Data >>>", arr);
                          // setData(afterDeleted);

                          let deleteData = data.filter((value, i) => {
                            // is_loading(true);
                            if (i != index) {
                              return value;
                            }
                          });
                          console.log("After Delete Data >>>", deleteData);
                          setData(deleteData);
                          getAfterDelete(deleteData);
                        }
                      }
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    // {
    //   title: "Article ID",
    //   dataIndex: "id_article",
    //   key: "id_article",
    //   render: (amount) => amount,
    // },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      width: 150,
      render: (amount) => amount,
    },
    // {
    //   title: "Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   render: (date) => {
    //     return moment(date).format("DD-MM-YYYY");
    //   },
    // },
    // {
    //   title: "COA",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    //   //   render: (date) => {
    //   //     return moment(date).format("DD-MM-YYYY");
    //   //   },
    // },
    // {
    //   title: "Unit",
    //   dataIndex: "unit",
    //   key: "unit",
    // },
    {
      title: "Received Qty",
      dataIndex: "quantity_received",
      key: "quantity_received",
      editable: true,
      width: 100,
      render: (amount) => amount,
    },
    {
      title: "Order Qty",
      dataIndex: "remaining_quantity_dummy",
      key: "remaining_quantity_dummy",
      width: 100,
      render: (amount) => amount,
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "price",
      editable: true,
      render: (amount) => {
        return `Rp. ${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      editable: true,
      render: (amount, record) => {
        return `Rp. ${
          record.price *
          (record.quantity_received != (null || undefined)
            ? record.quantity_received
            : 0)
        }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "D-Unit",
      dataIndex: "d-unit",
      key: "d-unit",
      render: (amount) => amount,
    },
    {
      title: "S-Unit Qty",
      dataIndex: "si_quantity_remaining",
      key: "si_quantity_remaining",
      render: (amount, record) =>
        amount == record.quantity_request
          ? 0
          : record.quantity_request - amount,
    },
    {
      title: "Item Desc",
      dataIndex: "description",
      key: "description",
      render: (amount) => amount,
    },
    {
      title: "PR Detail ID",
      dataIndex: "id_pr_detail",
      key: "id_pr_detail",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // EDITABLE RENDER COMPONENTS
  const EditableCell = (props) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = props;
    const inputNode =
      inputType === "number" ? (
        <InputNumber
          placeholder="input here..."
          disabled={
            dataIndex == "amount" || (dataIndex == "price" && orderType == 2)
              ? true
              : false
          }
          min={0}
          max={
            dataIndex == "quantity_received"
              ? record.si_quantity_remaining
              : null
          }
          onChange={(e) => {
            console.log(e);
            if (dataIndex == "quantity_received") {
              let x = form.getFieldsValue();
              console.log(x);
              form.setFieldsValue({
                amount: e * x["price"],
              });
            }
          }}
          className="edit-number"
        />
      ) : (
        <Input
          onClick={() => {
            // setModalArticlePriceOpen(true);
            // setSelectedArticleId(record.id_article);
          }}
        />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <>
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
            {dataIndex == "warehouse_name" ? (
              <>
                <Form.Item
                  label="Warehouse ID"
                  name="id_warehouse"
                  hidden
                ></Form.Item>
                <Form.Item label="Price" name="price" hidden></Form.Item>
                <Form.Item label="Amount" name="amount" hidden></Form.Item>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          children
        )}
      </td>
    );
  };

  // Merged Columns when edit
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType: col.dataIndex === "warehouse_name" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record, index),
      }),
    };
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      // getSelectedPRDetail(selectedRows);
      setEdit(true);
      dataEdit(selectedRows[0]);
      is_edit(true);
    },
    getCheckboxProps: (record) => {
      console.log(record);
      return {
        disabled: record.quantity_delivery == (0 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value, idx) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Delete this Purchase Order Details ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
        let deleteData = data.filter((value, i) => {
          // is_loading(true);
          if (i != idx) {
            return value;
          }
        });
        console.log("After Delete Data >>>", deleteData);
        setData(deleteData);
        getAfterDelete(deleteData);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record.id_sid;

    console.log(contentdelete);

    var obj = {
      ["id_sid"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/inventory/receiving-detail`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Del PO Details = ", response);

        successSI(response, { method: 2 });
        fetchData();
      })
      .catch((error) => {
        console.log("Del PO Details = ", error);
        failedSI(error, { method: 2 });
        setIsLoading(false);
      });
    // .finally(() => {
    // });
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          className="pr-detail-table"
          name="pr-detail-table"
          key="pr-detail-table"
          columns={mergedColumns}
          dataSource={data}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
            onChange(current, pageSize) {
              setPage(current);
              setPaginationSize(pageSize);
            },
          }}
          scroll={{
            x: 1200,
          }}
          // rowSelection={{
          //   type: "radio",
          //   ...rowSelection,
          // }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => record.id_po_detail || record.id || record.id_sid}
        />
      </Form>
    </>
  );
}

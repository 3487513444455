// Import React's Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Tag,
} from "antd";

// Import React Icons
import {
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Notification
import { failedFetch } from "../../../../Components/Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { LocalizationDate } from "../../../../Components/Reusable/Functions/Localizations/Localization";

// CODE
export default function KitchenHeaderTable({
  searchKey,
  is_search,
  selectedData,
  is_refresh,
  is_created
}) {

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataHeader, setDataHeader] = useState([]);
  const [bulks, setBulks] = useState([]);
  // ID
  const [editData, setEditData] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  // USE FORM
  const [form] = Form.useForm();

  const dataArticles = [
    {
      key: "1",
      order_id_alias: "ORD001",
      order_time: "2024-09-24T12:30:00",
      name: "John Doe",
      sales_type: "Dine-in",
      table_id: "T1",
      status: "0",
      items: [
        {
          article_name: "Pizza Margherita",
          item_quantity: 2,
          notes: "Extra cheese",
          kitchen_bar: "0",
        },
        {
          article_name: "Coca-Cola",
          item_quantity: 1,
          notes: "No ice",
          kitchen_bar: "0",
        },
      ],
    },
    {
      key: "2",
      order_id_alias: "ORD002",
      order_time: "2024-09-24T14:15:00",
      name: "Jane Smith",
      sales_type: "Takeaway",
      table_id: "N/A",
      status: "1",
      items: [
        {
          article_name: "Chicken Burger",
          item_quantity: 1,
          notes: "Spicy",
          kitchen_bar: "0",
        },
        {
          article_name: "Lemonade",
          item_quantity: 2,
          notes: "",
          kitchen_bar: "Bar",
        },
      ],
    },
    {
      key: "3",
      order_id_alias: "ORD003",
      order_time: "2024-09-24T15:45:00",
      name: "Mike Johnson",
      sales_type: "Dine-in",
      table_id: "T3",
      status: "2",
      items: [
        {
          article_name: "Steak",
          item_quantity: 1,
          notes: "Medium rare",
          kitchen_bar: "Kitchen",
        },
        {
          article_name: "Red Wine",
          item_quantity: 1,
          notes: "Glass",
          kitchen_bar: "Bar",
        },
      ],
    },
    // Add more orders and items as needed
  ];
  

  // Table Columns
  const columnsArticle = [
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
      render: (text) => text,
    },
    {
      title: "Order Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (text) => {
        return moment(LocalizationDate(text)).format("YYYY-MM-DD, HH:mm");
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text,
    },
    {
      title: "Sales Type",
      dataIndex: "sales_type",
      key: "sales_type",
      render: (text, record) => {
        const salesTypeMap = {
          "1": `Dine-In (${record.table_id})`,
          "2": "Take Away",
          "3": "Online Order",
        };
        
        return salesTypeMap[text] || "-";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        let color = "";
        let txt = "";

        if (record?.details?.some((x) => x.notes?.slice(-1) === "0")){
          color = "#2db7f5";
          txt = "Pending";
        } else if (record?.details?.some((x) => x.notes?.slice(-1) === "2")) {
          color = "#FFD300";
          txt = "In Progress";
        } else if (record?.details?.every((x) => x.notes?.slice(-1) === "4")) {
          color = "#87d068";
          txt = "Completed";
        } else {
          color = "#d9d9d9";
          txt = "-";
        }

        // switch (text) {
        //   case "0":
        //     color = "#2db7f5";
        //     txt = "Pending";
        //     break;
        //   case "1":
        //     color = "#FFD300";
        //     txt = "In Progress";
        //     break;
        //   case "2":
        //     color = "#87d068";
        //     txt = "Completed";
        //     break;

        //   default:
        //     color = "#d9d9d9";
        //     txt = "-";
        //     break;
        // }

        return (
          <Tag color={color}>
            {txt ? txt.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
  ].filter((item) => !item.hidden);

  // FETCH Data Header
  const fetchDataHeader = async () => {
    await axios
      .get(`${baseurl}/pos/order-header`, {})
      .then((response) => {
        console.log("HEADER FOR SAVED BILL: ", response);
        console.log("response.data?.msg?.length", response.data?.msg?.length);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (
              e.is_deleted == false &&
              e.is_paid == false &&
              e.bill_status != 2
            ) {
              return e;
            }
          });

          console.log("fetchDataHeader>_res:", _res);

          setData(_res);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  const fetchOrder = async (record) => {
    for (let z = 0; z < record.length; z++){
      await axios
        .post(`${baseurl}/pos/detail-order-id`, {
          // order_id_alias: tableKey?.order_id_alias,
          order_id_alias: record[z]?.order_id_alias,
        })
        .then((response) => {
          // console.log("Bill Selected: ", response);
  
          if (response.data?.msg?.length > 0) {
            const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
              (e) => {
                if (
                  e.is_deleted == false &&
                  e.is_paid == false &&
                  e.bill_status == 0
                ) {
                  return e;
                }
              }
            );
  
            const _resMap = _res.map((x, i) => {
              return {
                ...x,
                serving_que: x.serving_order,
                key: i,
              }
            });
            // console.log("_resMap", _resMap)
            
            data.forEach(order => {
              if(order.order_id_alias == record[z].order_id_alias){
                order.details = _resMap;
              }
            })
            
            // console.log("DATA>", data);

          }
        })
        .catch((error) => {
          console.log("Error tables: ", error);
  
          failedFetch(error);
        })
        .finally(() => {
          if(z == (record.length - 1)){
            setLoading(false);
          }
        });
    }
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchDataHeader();
    setLoading(true)
  }, []);

  useEffect(() => {
    console.log("DATA>", data);
    if(data.length > 0){
      fetchOrder(data);
    }
  },[data]);

  useEffect(() => {
    fetchDataHeader();
    setLoading(true);
  }, [is_created]);



  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Handle Row Selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      // setRateCode(selectedRows);
      // getRateCode(selectedRows);
      selectedData(
        selectedRows?.length > 0 ? selectedRows[0] : []
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    form.resetFields();
    setEditData(null);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? moment(searchKey).format("YYYY-MM-DD") : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _date = moment(item?.date).format("YYYY-MM-DD");
        // console.log("ITEM => ", _date);

        return moment(_date).isSame(query);
      });

      // Trigger render with updated values
      console.log("Filtered by Date: ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  const handleRefresh = () => {
    fetchDataHeader();
    setLoading(true);
    is_refresh();
  }

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={12}>
              <Space
                className="title-spacetxt"
                align="center"
                size={"large"}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {`Order List`}
              </Space>
            </Col>

            <Col className="col-btn" span={12}>
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="order-list-table"
              name="order-list-table"
              key="order-list-table"
              columns={columnsArticle}
              dataSource={data}
              // dataSource={dataArticles}
              bordered
              // loading={loading}
              size="medium"
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React's Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import { Select } from "antd";

// CODE
export default function MasterSalesman(props) {
  // PROPS
  const { salesman, segmentId, getSalesman } = props;

  // CONTEXT
  const { getMasterSalesman } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [value, setValue] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // FETCH DATA
  const fetchData = async () => {
    await getMasterSalesman({
      sales: "sales",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Res Sales: ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let _response = response.data.msg;

          let filtered = _response.filter((filt) => {
            return !filt.is_deleted;
          });

          let option = filtered.map((dev) => ({
            value: dev.sales_name,
            label: dev.sales_name,
            code: dev.sales_code,
            sales_id: dev?.id,
            segment:
              dev?.main_segment_name.length > 0
                ? dev.main_segment_name
                : [dev?.main_segment_name],
            segment_id:
              dev?.main_segment_id.length > 0
                ? dev.main_segment_id
                : [dev?.main_segment_id],
            segment_code:
              dev?.main_segment_code.length > 0
                ? dev.main_segment_code
                : [dev?.main_segment_code],
          }));

          // console.log("Sales = ", salesman);

          setOptionValue(option);

          if (salesman > 0) {
            // console.log("Sales = ", salesman);
            handleSalesId(salesman, option);
          }
        }
      },
      onReject: (error) => {
        console.log("Err: ", error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (segmentId > 0 && optionValue.length > 0 && !cleanUp) {
      console.log("Segment ID: ", segmentId);
      console.log("Salesman: ", salesman);

      handleFilter(segmentId);
    } else {
      setValue(null);
    }

    if (salesman && !cleanUp) {
      setValue(salesman);
    } else {
      setValue(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [segmentId, salesman]);

  // HANDLE FILTER
  const handleFilter = (id) => {
    const _id = id;
    let _bucketArr = [];

    let _sales = optionValue.filter((val) => {
      if (val?.segment_id?.length > 0) {
        for (let i = 0; i < val?.segment_id?.length; i++) {
          if (val?.segment_id[i] == _id) return _bucketArr.push(val);
        }
      }
    });

    // console.log("Salesman List => ", _sales);
    // console.log("Salesman Bucket Array: ", _bucketArr);

    if (_sales?.length > 0) {
      setValue(_sales[0]?.value);
      getSalesman(_sales[0]?.value, _bucketArr[0]);
    }
  };

  // SALES ID HANDLER
  const handleSalesId = (value, arr) => {
    const _id = value;
    const _arr = arr;

    let _salesName = _arr.filter((val) => {
      if (val.sales_id === _id) {
        return val;
      }
    });

    // console.log("Sales Name by ID: ", _salesName);

    if (_salesName?.length > 0) {
      setValue(_salesName[0]?.value);
    }
  };

  // Handle Clear
  const handleClear = () => {
    setValue(null);
  };

  return (
    <>
      <Select
        className="salesman-selector"
        key={"sales-select"}
        placeholder={"Select a Salesman!"}
        value={value}
        allowClear
        showSearch
        onClear={handleClear}
        onChange={(e, opt) => {
          setValue(e);
          console.log("Select Sales = ", e);
          console.log("Sales = ", opt);

          getSalesman(e, opt);
        }}
        style={{
          width: "100%",
        }}
        options={optionValue}
      />
    </>
  );
}

import {
  CheckCircleFilled,
  CloseCircleFilled,
  FileExcelFilled,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { cardBody, mainBody } from "../../../../../../Style/Global/Global";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
// Import Notification
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// Import Global Styles
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";
import { IoReturnUpBackOutline } from "react-icons/io5";

export default function PRCancelledReportTable(props) {
  // PROPS
  const { type } = props;

  // CONTEXT
  const { getPurchasingList } = useContext(MainContext);
  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  // Search
  const [searchKey, setSearchKey] = useState(null);
  // Date
  const [datePicked, setDatePicked] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();

  // GET DATA FUNCTION
  const fetchData = async (type) => {
    setLoading(true);

    await getPurchasingList({
      type: "purchase-request-cancelled",
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("Response >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          let filter = [];
          if (type === 1) {
            filter = _data.filter((item) => {
              return item.is_dml === false;
            });
          } else if (type === 2) {
            filter = _data.filter((item) => {
              return item.is_dml === true;
            });
          }

          setData(filter);
          setBulks(filter);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    let cleanUp = false;

    if (type && !cleanUp) {
      fetchData(type);
    }

    return () => {
      cleanUp = true;
    };
  }, [type]);

  // USE EFFECT Fetching Data
  useEffect(() => {
    // fetchData(type);

    if (refresh == true) {
      fetchData(type);
      setRefresh(false);
    }
  }, [refresh]);

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(
      data,
      `Purchase Request List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // Handle Filter
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const _reqDate = moment(val.request_date).format("YYYY-MM-DD");

      return moment(_reqDate).isBetween(_start, _end, undefined, "[]");
    });

    console.log("Request Date Filtered: ", _filtered);
    setData(_filtered);
  };

  // HANDLE INPUT
  const handleInput = (val) => {
    val.preventDefault();
    const queryKey = val?.target?.value ? val.target.value.toLowerCase() : null;

    setSearchKey(queryKey);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    console.log("Searching = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        return (
          item.pr_number.toLowerCase().indexOf(query?.toLowerCase()) !== -1
        );
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Columns
  const columns = [
    {
      title: "PR No.",
      dataIndex: "pr_number",
      key: "pr_number",
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Budget Allocation",
      dataIndex: "approval_name",
      key: "approval_name",
      render: (app, record) => {
        let color = "";

        switch (app) {
          case "Opex":
            color = "#1890ff";
            // txt = "Capital Expenditures"
            break;

          case "Capex":
            color = "#13c2c2";
            // txt = "Operational Expenditures"
            break;

          default:
            color = "default";
            break;
        }

        return (
          <Tag color={color} key={record?.pr_number}>
            {app ? app.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
    {
      title: "Approval",
      dataIndex: "approved_list",
      key: "approved_list",
      width: 250,
      render: (approval) => {
        if (approval?.length > 0) {
          let _tags = approval.map((value, index) => {
            // console.log("Value: ", value);
            // console.log("Index: ", _id);

            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {`Approve ${index + 1}`}
                </Tag>
              );
            } else if (value === 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`Rejected ${index + 1}`}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          // console.log(_tags);

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
        }
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
    },
    {
      title: "Approval Remarks",
      dataIndex: "information",
      key: "information",
    },
    {
      title: "Status",
      dataIndex: "pr_status",
      key: "pr_status",
      width: 100,
      render: (pr_status) => {
        return pr_status == true ? (
          <Tag color="#87d068" key={1}>{`Requested`}</Tag>
        ) : (
          <Tag color="grey" key={2}>{`Draft`}</Tag>
        );
      },
    },
  ];

  return (
    <>
      <Row className="row-search-component" justify="start" style={cardBody}>
        <Col
          style={{
            textAlign: "start",
            margin: "22px 15px 22px 30px",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`PR Number`}</Typography>

          <Input
            allowClear
            placeholder="Keyword"
            onChange={handleInput}
            style={{
              width: 300,
            }}
          />
        </Col>

        <Col
          className="search-btn-col"
          style={{
            margin: "54px 0px 0px",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          <Button
            type="primary"
            className="search-btn"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            {`Search`}
          </Button>
        </Col>
      </Row>

      <Row className="purchasing-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }} className="col-table">
          <Row justify="end" className="row-btn">
            <Col span={12} className="col-export">
              <Typography
                style={{ marginBottom: 10 }}
              >{`Pick Date (Request Date)`}</Typography>

              <DatePicker.RangePicker
                className="date-btn"
                allowClear
                onChange={handleDate}
                style={{
                  width: 300,
                }}
              />
            </Col>

            <Col span={12} className="col-table-btn">
              <Row justify="end" className="row-table-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<FileExcelFilled />}
                  style={{
                    fontWeight: 500,
                    margin: "0px 15px",
                  }}
                >
                  {`Export to Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => fetchData(type)}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table-main-row" style={{ width: "100%" }}>
            <Table
              className="table-purchase"
              name="table-purchase"
              key="table-purchase"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              size="large"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              scroll={{
                x: 1300,
              }}
              rowKey={(record) => (record.id ? record.id : record.id_pr)}
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React's Component
import React, { useEffect, useState } from "react";
import moment from "moment";

import { user_name, token } from "../../../../../../API/Global/Payload";

// Import ANTD Component
import { Table, Form, Row, Col, Tooltip, InputNumber, Input } from "antd";
import { FiCheckCircle, FiEdit, FiXCircle } from "react-icons/fi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { DeleteFilled } from "@ant-design/icons";
import WarehouseList from "../../../../../Reusable/Inventory/Receiving/WarehouseList";

// Import React Icons Components

// CODE
export default function PODetailModalTable(props) {
  // PROPS
  const { dataDetail, dataEdit, getSelectedPODetail, isEdit } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();

  // Selected Data & Keys
  const [select, setSelect] = useState(null);
  const [editingKey, setEditingKey] = useState("");

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  // Boolean
  const [edit, setEdit] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  const isEditing = (record, index) => index === editingKey;

  // SET FIELDS EDIT
  const editData = (record, index) => {
    console.log("EDIT DATA INDEX >>>", index);
    setEditingKey(index);

    form.setFieldsValue({
      quantity_ready: 0,
      ...record,
    });
  };

  const save = async (e, x) => {
    let index = e;
    let artName = x;
    try {
      const row = await form.validateFields();
      const newData = [...data];
      let find = data.findIndex((v) => v.article_name == artName);
      let compare = newData[index].unit_price !== row.unit_price;

      console.log(newData);
      // const index = newData.findIndex((item) => key === item.key);
      if (find > -1) {
        console.log("Test >>> Cond 1");
        const item = newData[find];
        newData.splice(find, 1, {
          ...item,
          ...row,
          ...{ price_update: compare ? true : false },
        });
        setData(newData);
        dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        isEdit(false);
      } else {
        console.log("Test >>> Cond 2");
        newData.push(row);
        setData(newData);
        dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        isEdit(false);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    setEditingKey("");
    setEdit(false);
    isEdit(false);
  };

  // Fetch Data
  const fetchData = async () => {
    // await
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("Data PO Detail: ", dataDetail);

    // let newData = dataDetail;
    let newData = dataDetail.filter((e, idx) => {
      // for render the table data only. the removed data (remaining_quantity_dummy == 0) still inside the state
      if (e.remaining_quantity_dummy !== 0) {
        return e;
      }
    });

    if (dataDetail?.length > 0 && !cleanUp) {
      setData(newData);
      setSelect(null);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail]);

  const getWarehouse = (value, allKey) => {
    console.log(value);
    console.log(allKey);

    form.setFieldsValue({
      warehouse_name: allKey.warehouse_name,
      id_warehouse: allKey.id_warehouse,
    });
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      //   fixed: "left",
      width: 70,
      render: (_, record, index) => {
        const editable = isEditing(record, index);
        return editable ? (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <FaCheckCircle
                  className="edit-btn"
                  onClick={() => {
                    save(index, record.article_name);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Col>
              <Col>
                <FaTimesCircle
                  className="delete-btn"
                  onClick={cancel}
                  style={{ fontSize: 20, color: "#F64E60" }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Quantity" className="delete-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("EDIT ON CLICK >>>");
                      if (
                        edit != true &&
                        !select?.includes(record.id_article)
                      ) {
                        console.log("IS EDITING QTY: ", editingKey);
                        console.log("Edit: ", record);
                        console.log("Edit: ", edit);
                        editData(record, index);
                        setEdit(true);
                        isEdit(true);
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color:
                        edit != true && !select?.includes(record.id_article)
                          ? "#1BC5BD"
                          : "#d9d9d9",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              {/* <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      if (edit != true) {
                        console.log("ABOUT TO DELETE > ", record);
                        if ("id_po_detail" in record) {
                          showModalConfirm(record, index);
                        } else {
                          let arr = [];
                          console.log("NO ID_PO_DETAIL >>>");
                          console.log("data >>>", data);
                          console.log("index >>>", index);
                          // let afterDeleted = data.splice(index, 1);
                          // arr.push(afterDeleted);
                          // console.log("After Delete Data >>>", arr);
                          // setData(afterDeleted);

                          let deleteData = data.filter((value, i) => {
                            // is_loading(true);
                            if (i != index) {
                              return value;
                            }
                          });
                          console.log("After Delete Data >>>", deleteData);
                          setData(deleteData);
                          getAfterDelete(deleteData);
                        }
                      }
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col> */}
            </Row>
          </>
        );
      },
    },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    // {
    //   title: "Article ID",
    //   dataIndex: "id_article",
    //   key: "id_article",
    //   render: (amount) => amount,
    // },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      width: 150,
      render: (amount) => amount,
    },
    // {
    //   title: "To Warehouse/Store",
    //   dataIndex: "warehouse_name",
    //   key: "warehouse_name",
    //   editable: true,
    //   width: 150,
    //   render: (amount) => amount,
    // },
    // {
    //   title: "Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   render: (date) => {
    //     return moment(date).format("DD-MM-YYYY");
    //   },
    // },
    // {
    //   title: "COA",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    //   //   render: (date) => {
    //   //     return moment(date).format("DD-MM-YYYY");
    //   //   },
    // },
    // {
    //   title: "Unit",
    //   dataIndex: "unit",
    //   key: "unit",
    // },
    {
      title: "Delivered Qty",
      dataIndex: "quantity_delivery",
      key: "quantity_delivery",
      editable: true,
      width: 100,
      render: (amount) => amount,
    },
    {
      title: "Order Qty",
      dataIndex: "remaining_quantity_dummy",
      key: "remaining_quantity_dummy",
      width: 100,
      render: (amount) => amount,
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      editable: true,
      render: (amount) => {
        return `Rp. ${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      editable: true,
      render: (amount, record) => {
        return `Rp. ${
          record.unit_price *
          (record.quantity_delivery != (null || undefined)
            ? record.quantity_delivery
            : 0)
        }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "D-Unit",
      dataIndex: "d-unit",
      key: "d-unit",
      render: (amount) => amount,
    },
    {
      title: "S-Unit Qty",
      dataIndex: "si_quantity_remaining",
      key: "si_quantity_remaining",
      render: (amount, record) =>
        amount == record.quantity_order ? 0 : record.quantity_order - amount,
    },
    {
      title: "Item Desc",
      dataIndex: "description",
      key: "description",
      render: (amount) => amount,
    },
    {
      title: "PR Detail ID",
      dataIndex: "id_pr_detail",
      key: "id_pr_detail",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // EDITABLE RENDER COMPONENTS
  const EditableCell = (props) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = props;
    const inputNode =
      inputType === "number" ? (
        <InputNumber
          placeholder="input here..."
          disabled={
            dataIndex == "unit_price" || dataIndex == "amount" ? true : false
          }
          min={0}
          max={
            dataIndex == "quantity_delivery"
              ? record.remaining_quantity_dummy
              : null
          }
          onChange={(e) => {
            if (dataIndex == "quantity_delivery") {
              let x = form.getFieldsValue();
              form.setFieldsValue({
                amount: e * x["unit_price"],
              });
            }
          }}
          className="edit-number"
        />
      ) : (
        <Input
          onClick={() => {
            // setModalArticlePriceOpen(true);
            // setSelectedArticleId(record.id_article);
          }}
        />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <>
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
            {dataIndex == "warehouse_name" ? (
              <>
                <Form.Item
                  label="Warehouse ID"
                  name="id_warehouse"
                  hidden
                ></Form.Item>
                <Form.Item label="Price" name="unit_price" hidden></Form.Item>
                <Form.Item label="Amount" name="amount" hidden></Form.Item>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          children
        )}
      </td>
    );
  };

  // Merged Columns when edit
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType: col.dataIndex === "warehouse_name" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record, index),
      }),
    };
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      let x = selectedRows.map((e) => {
        return {
          ...e,
          quantity_received: e.quantity_delivery,
          quantity_request: e.quantity_order,
          quantity_total: e.si_quantity_remaining,
          price: e.unit_price,
        };
      });
      getSelectedPODetail(x);
      // getSelectedPODetail(selectedRows);
      //   setEdit(true);
      //   is_edit(true);
    },
    getCheckboxProps: (record) => {
      console.log(record);
      return {
        disabled:
          edit == true || record.quantity_delivery == (0 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  console.log("EXECUTED PR DETAIL DATA >>>", data);

  return (
    <>
      <Form form={form} component={false}>
        <Table
          className="pr-detail-modal-table"
          name="pr-detail-modal-table"
          key="pr-detail-modal-table"
          columns={mergedColumns}
          dataSource={data}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
            onChange(current, pageSize) {
              setPage(current);
              setPaginationSize(pageSize);
            },
          }}
          scroll={{
            x: 1200,
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => (record.id ? record.id : record.key)}
        />
      </Form>
    </>
  );
}

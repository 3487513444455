// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token } from "../../../API/Global/Payload";

// Import Ant Design Components
import { Cascader, TreeSelect } from "antd";

// CONST
const { SHOW_CHILD } = Cascader;
const { SHOW_PARENT } = TreeSelect;

// CODE
export default function CascaderModule(props) {
  // PROPS
  const { getCascadeModule, selectedData, isDisabled } = props;

  // STATE MANAGEMENT
  // Data
  const [optionValue, setOptionValue] = useState([]);
  const [moduleAll, setModuleAll] = useState([]);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // FETCH DATA
  const fetchData = async () => {
    setIsLoading(true);

    await axios
      .get(`${baseurl}/auth/admin/module`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleMap(_res);
        } else {
          setOptionValue([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setIsLoading(false);
      });
  };

  // USE EFFECTs
  useEffect(() => {
    fetchData();
  }, []);

  // Handle Map
  const handleMap = (val) => {
    let _arr = val;

    console.log("About to map: ", _arr);

    let _filter = _arr.map((value) => ({
      label: value?.name,
      title: value?.name,
      value: value?.id,
      code: value?.code,
      id_module: value?.id_module,
      //   key: value?.id,
      id: value?.id,
      children: value?.p_module,
    }));

    console.log("Filter 1: ", _filter);

    if (_filter.length > 0) {
      handleFilterSub(_filter);
    } else {
      setOptionValue([]);
    }
  };

  // Handle Filter Sub-Module
  const handleFilterSub = (val) => {
    let _arr = val;

    let _arr_temp_1 = [];

    let _filter = _arr.map((record) => {
      let _subMod = record?.children?.length > 0 ? record?.children : [];

      console.log("About to map sub: ", _subMod);

      let _filtered = _subMod.map((_detail) => ({
        label: _detail?.name,
        title: _detail?.name,
        value: _detail?.id,
        code: _detail?.code,
        // key: _detail?.id,
        id_module: _detail?.id_module,
      }));

      console.log("Sub Module: ", _arr);
      console.log("Mapped2: ", _filtered);

      let _sorted = _filtered.sort((a, b) => a.id - b.id);

      console.log("Sorted: ", _sorted);
      _arr_temp_1.push({ ...record, children: _sorted });
    });

    console.log("Arr Temp 1: ", _arr_temp_1);
    // console.log("Arr Temp 2: ", _filter);

    if (_arr_temp_1?.length > 0) {
      setOptionValue(_arr_temp_1);
    } else {
      setOptionValue([]);
    }
  };

  // Handle Change
  const handleChange = (val, select, extras) => {
    console.log("On Change: ", val);
    console.log("Select: ", select);
    console.log("Extras: ", extras);

    setModuleAll(val);
    getCascadeModule(val);
  };

  // Handle Change
  const handleClear = (val) => {
    console.log("On Change", val);

    setModuleAll(null);
  };

  return (
    <>
      <TreeSelect
        className="module-cascade"
        key="module-cascade"
        placeholder="Select Module and Sub-Modules"
        allowClear
        showSearch
        multiple
        loading={isLoading}
        value={moduleAll}
        treeCheckable={true}
        // maxTagCount=""
        showCheckedStrategy={SHOW_PARENT}
        // options={optionValue}
        treeData={optionValue}
        onChange={handleChange}
        onClear={handleClear}
        // style={{
        //   width: "100%",
        // }}
      />
    </>
  );
}

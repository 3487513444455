// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../src/API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Form,
  Input,
  Tooltip,
} from "antd";

// Import React Icons
import { PiFastForwardCircleFill } from "react-icons/pi";
import { ImExit } from "react-icons/im";
import { TfiMenuAlt } from "react-icons/tfi";
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import { BsPersonCheckFill } from "react-icons/bs";
import {
  CheckCircleFilled,
  DeleteFilled,
  PlusOutlined,
  PrinterFilled,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";

// Notification
import {
  failedAddReserv,
  successAddReserv,
} from "../../Reusable/Notification/Notification";

// Import Page Components
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import ModalCheckOutForm from "../../Forms/CheckOut/Form";
import BillReceipt from "../../Forms/Bill/BillReceipt";
import PreBillForm from "../../Forms/PreBill/PreBill";
// import ModalCheckOutForm from "../../Modals/CheckOutForm/modal";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function DeparturePopover(props) {
  // PROPS
  const { details, is_refresh, disabled, time_disable } = props;

  // DATE TODAY
  const _today = moment().format("YYYY-MM-DD");
  // PARAMS
  const params = FrontOfficeParams().show_co;

  // Navigate & Routes
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  //CONTEXT
  const { getReservation, getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [recordData, setRecordData] = useState(null);
  // Data Article
  const [articleTotal, setArticleTotal] = useState([]);
  const [amountTotal, setAmountTotal] = useState(null);
  // Open Popover
  const [open, setOpen] = useState(false);
  // Modals
  const [openForm, setOpenForm] = useState(false);
  const [bill, setBill] = useState(false);
  //   const [details, setDetails] = useState()
  // State
  const [isCheckout, setIsCheckout] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Set Data
  const detail = details;

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);
    setArticleTotal([]);
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (open == true && !cleanUp) {
      // console.log("Disable Btn: ", disabled);

      form.setFieldsValue({
        reservation_id: details.reservation_id,
        room_number: details.room_number,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [open]);

  // Handle Check Out
  const handleCheckOut = async (value) => {
    const _record = [value];
    console.log("Navigate => ", pathname, state);
    console.log("Record => ", _record);

    const path = "guest-folio";

    navigate(`${path}`, {
      state: _record,
    });
  };

  // Handle Check Out
  const handleEarly = async (value) => {
    const _record = [value];
    console.log("Navigate => ", pathname, state);
    console.log("Record => ", _record);

    navigate(`${pathname}/early-check-out`, {
      state: _record,
    });
  };

  // Handle Disable
  const handleDisable = (value) => {
    let _able = true;
    // Departure Date
    const _record = moment(value?.departure).format("YYYY-MM-DD");
    // Check Departure is Before Today
    let _dis = moment(_today).isBefore(_record, "days");
    // Check Today is Not same as Arrival Date
    let _isToday = moment(_today).isSame(value?.arrival, "days");

    // console.log("Record => ", _record, " < ", _today, " = ", _dis);
    // console.log("Is Arrivals Today: ", _isToday);
    // console.log("Is Disable: ", _dis);
    // console.log("Is Disabled: ", disabled);

    // Check Able True
    if (disabled) {
      if (_dis && !_isToday) {
        _able = false;
      } else {
        _able = true;
      }
    } else {
      if (_dis && !_isToday) {
        _able = false;
      } else {
        _able = true;
      }
    }

    return _able;
  };

  // Disabel C/O
  const handleDisableCO = (value) => {
    let _able = true;
    // Check Departure
    const _record = moment(value?.departure).format("YYYY-MM-DD");
    // Check Departure must be same TODAY
    var _isToday = moment(_today).isSameOrAfter(_record, "days");

    // console.log("Record => ", _record, " < ", _today, " = ", _dis);
    // console.log("Is Arrivals Today: ", _isToday);

    if (time_disable) {
      // console.log("TAI");
      if (_isToday && disabled && value?.is_late_checkout == true) {
        var _isMore = moment(_today).isAfter(_record);

        if (disabled == true && _isMore == true) {
          _able = false;
        } else {
          _able = true;
        }
      } else {
        _able = true;
      }
    } else {
      // console.log("TAI 2: ", disabled);
      if (_isToday && disabled) {
        _able = false;
      } else {
        _able = true;
      }
    }

    // console.log(`Res ${value.reservation_id}`, _able);

    return _able;
  };

  return (
    <>
      <Tooltip title="Departure" className="departure-tooltip">
        <Popover
          className="pop-over-departure"
          name="pop-over-departure"
          key="pop-over-departure"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    type: "primary",
                    fontWeight: 400,
                  }}
                >
                  {`Departure Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row
                justify="start"
                //hidden
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  disabled={disabled}
                  icon={
                    <ImExit
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    console.log("About to C/O: ", detail);
                    // console.log(disabled);
                    setRecordData(detail);

                    setTimeout(() => {
                      handleCheckOut(detail);
                      handleClose();
                    }, 1000);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                    color: "#F64E60",
                  }}
                >
                  {`Check Out`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <PiFastForwardCircleFill
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  disabled={handleDisable(detail)}
                  onClick={() => {
                    console.log("About to Early C/O: ", detail);

                    setRecordData(detail);

                    setTimeout(() => {
                      handleEarly(detail);
                      handleClose();
                    }, 1000);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                    color: "#F64E60",
                  }}
                >
                  {`Early Check-Out`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{
            margin: 0,
            padding: 0,
            backgroundColor: "#ff7875",
          }}
        >
          <Button
            className="checkout-btn"
            // disabled={disabled}
            type="danger"
            icon={
              <TfiMenuAlt
                className="menu-btn"
                style={{
                  fontSize: 18,
                  color: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 5px 0px",
                  cursor: "pointer",
                }}
              />
            }
            onClick={() => {
              console.log("About to C/O: ", details);
            }}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              // padding: "0px 0px 5px 5px",
              minWidth: 125,
            }}
          >
            {`Check-Out`}
          </Button>
        </Popover>
      </Tooltip>
    </>
  );
}

import moment from "moment";
import * as XLSX from "xlsx";

// Download File as Excel
const downloadExcel = async (data, namefrom) => {
  const today = moment().format("MM-DD-YYYY");
  const dataExcels = data;
  const title = namefrom;

  console.log("Data: ", dataExcels);
  console.log("Names: ", title);

  const worksheet = XLSX.utils.json_to_sheet(dataExcels);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, `[${today}] ${title}.xlsx`);
};

export default downloadExcel;

// Import React's Components
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  Image,
  Menu,
  Rate,
  Row,
  Space,
  Typography,
} from "antd";

// Import React Icons
import {
  DoubleRightOutlined,
  DownOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

// Typography
const { Text } = Typography;

// Items
const items = [
  {
    key: "1",
    label: "Login",
  },
  {
    key: "2",
    label: "Reservations",
  },
  {
    key: "3",
    label: "Edit Reservations",
  },
];

const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: "Login",
      },
      {
        key: "2",
        label: "Reservations",
      },
      {
        key: "3",
        label: "Edit Reservations",
      },
    ]}
  />
);

// CODE
// eslint-disable-next-line
export default function HeaderManager() {
  const date = new Date(Date.now());

  // const today = moment(date).localeData("id-ID");
  const today = moment(date).format("DD MMMM YYYY");

  const navigate = useNavigate();
  // console.log(today);

  const onMenuClick = ({ key }) => {
    console.log(`Click on item ${key}`);
  };

  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Row
        className="row-header"
        justify={"space-between"}
        align="middle"
        // gutter={20}
        style={{ padding: "0px 50px", margin: 0, width: "100%" }}
      >
        <Col xxl={12} xl={12} lg={8} md={8} className="col-hotel-info">
          <Space
            className="space-header"
            // split={
            //   <Divider type="vertical" style={{ backgroundColor: "#FFFFFF" }} />
            // }
            // align="center"
            direction="horizontal"
            // wrap
          >
            {/* <Row className="row-hotel-info">
            <Col xxl={4} xl={5} lg={6} md={8} className="col-hotel-name"> */}
            <Typography
              className="text-hotel-name"
              style={{
                //   fontSize: 20,
                fontWeight: 750,
                color: "#FFFFFF",
              }}
            >{`LOOK Hotel`}</Typography>
            {/* </Col>

            <Col xxl={4} xl={5} lg={8} md={8} className="col-rate"> */}
            <Rate
              disabled
              defaultValue={6}
              className="hotel-rate"
              style={{ width: "100%", fontSize: 10 }}
            />
            {/* </Col>

            <Col xxl={16} xl={12} lg={12} md={8} className="col-hotel-address"> */}
            <Typography
              className="text-address"
              style={{
                //   fontSize: 12,
                color: "#FFFFFF",
                overflow: "hidden",
              }}
            >{`Jl. Sidomukti, Bandung City, West Java`}</Typography>
            {/* </Col>
          </Row> */}
          </Space>
        </Col>

        {/* <Col span={4} className="col-space" /> */}

        <Col
          xxl={12}
          xl={12}
          lg={16}
          md={16}
          className="col-user-interface"
          style={{ display: "flex", justifyContent: "end", width: "100%" }}
        >
          <Space
            size={"large"}
            className="header-space"
            split={
              <Divider type="vertical" style={{ backgroundColor: "#FFFFFF" }} />
            }
            // align="end"
            direction="horizontal"
            // wrap
          >
            {/* <Row className="row-user-interface" style={{ width: "100%" }}> */}
            {/* <Col
              className="col-home-btn"
              //   xxl={8}
              //   xl={8}
              //   lg={6}
              //   md={6}
              span={8}
              style={{
                border: "1px solid #FFFFFF",
                borderTopStyle: "hidden",
              }}
            > */}
            <Button
              className="home-manager-btn"
              type="primary"
              block
              icon={
                <HomeOutlined
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              }
              style={{
                //   fontSize: 25,
                fontWeight: 750,
                color: "#FFFFFF",
                border: "none",
                backgroundColor: "inherit",
                //   width: "100%",
                //   height: "100%",
              }}
            >
              HOME
            </Button>
            {/* </Col>

            <Col
              className="col-home-btn"
              //   xxl={8}
              //   xl={8}
              //   lg={6}
              //   md={6}
              span={8}
              style={{
                border: "1px solid #FFFFFF",
                borderTopStyle: "hidden",
              }}
            > */}
            <Typography
              className="text-currency"
              style={{
                //   padding: "0px 15px",
                textAlign: "center",
                margin: 0,
                //   fontSize: 20,
                fontWeight: 750,
                color: "#FFFFFF",
              }}
            >{`Rp Indonesian Rupiah`}</Typography>
            {/* </Col>

            <Col
              className="col-home-btn"
              //   xxl={8}
              //   xl={8}
              //   lg={6}
              //   md={6}
              span={8}
              style={{
                border: "1px solid #FFFFFF",
                borderTopStyle: "hidden",
                textAlign: "center",
              }}
            > */}
            <Dropdown
              icon={<DownOutlined />}
              trigger={["click"]}
              overlay={menu}
              //   selectable: true,
              // onClick: onMenuClick,
              // onClick={onMenuClick}
            >
              <a
                // eslint-disable-next-line
                href="/"
                onClick={handleClick}
              >
                <Space>
                  <Typography
                    className="text-options"
                    style={{
                      // fontSize: 20,
                      fontWeight: 750,
                      color: "#FFFFFF",
                    }}
                  >
                    Options
                  </Typography>

                  <DownOutlined style={{ color: "#FFFFFF" }} />
                </Space>
              </a>
            </Dropdown>
            {/* 
            </Col>
          </Row> */}
          </Space>
        </Col>
      </Row>
    </>
  );
}

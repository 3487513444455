// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Notification
import {
  failedSegment,
  masterIncomplete,
  successSegment,
} from "../../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Master Components
// import MasterPaymentType from "../../../Reusable/Master/PaymentType/PaymentType";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// CONST
const { confirm } = Modal;

// CODE
export default function SubSegmentTable({ is_search, searchKey }) {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 90,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);

                      form.setFieldsValue({ ...record });
                      setIsDeleted(false);
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      setIsDeleted(true);

                      showModalConfirmation(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Sub Segment's Name",
      dataIndex: "sub_segment_name",
      key: "sub_segment_name",
      //   width: 200,
    },
    {
      title: "Sub Segment's Code",
      dataIndex: "sub_segment_code",
      key: "sub_segment_code",
    },
    {
      title: "Description",
      dataIndex: "sub_segment_description",
      key: "sub_segment_description",
      width: 400,
    },
  ];

  // CONTEXT
  const { getMasterSubSegment } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  // Is Deleted
  const [isDeleted, setIsDeleted] = useState(true);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterSubSegment({
      subSegment: "rate-code/sub-segment",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          let filtered = _response.filter((filt) => {
            if (filt.isDeletedeted == false || filt.isDeletedeted == null) {
              return filt;
            }
          });
          setData(filtered);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setEditData(null);
    setIsDeleted(true);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirmation = async (record) => {
    let del = record;

    confirm({
      className: "delete-segment-confirm",
      title:
        isDeleted == true
          ? `Are you sure want to delete Sub Segment named ${del?.sub_segment_name?.toUpperCase()} from the table?`
          : `Are you sure want to ${
              edit == true ? "Update a " : "Add a New  "
            } Sub Segment named ${del?.sub_segment_name?.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: isDeleted == true ? "Delete" : "Confirm",
      okType: isDeleted == true ? "danger" : "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDeleted == true ? handleDelete(del) : handleSubmit(del);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    // console.log(value);
    let content = value;

    showModalConfirmation(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    console.log("ON OK ==> ", contentsubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/room/rate-code/sub-segment`,
          {
            sub_segment_code: contentsubmit?.sub_segment_code || "",
            sub_segment_name: contentsubmit?.sub_segment_name || "",
            sub_segment_description:
              contentsubmit?.sub_segment_description || "",
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("ADD Sub Segment = ", response);

          successSegment(response, { method: 0 });
          form.resetFields();
          handleCancel();
        })
        .catch((error) => {
          console.log("ADD Sub Segment = ", error);
          failedSegment(error, { method: 0 });
        })
        .finally(() => {
          fetchData();
        });
    } else {
      await axios
        .put(
          `${baseurl}/room/rate-code/sub-segment`,
          {
            sub_segment_code: contentsubmit?.sub_segment_code || "",
            sub_segment_name: contentsubmit?.sub_segment_name || "",
            sub_segment_description:
              contentsubmit?.sub_segment_description || "",
            updated_by: user_name,

            id: editData?.id || "",
            // updated_by: "DEVELOPER",
            // updated_date: contentsubmit.modified_date,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("UPDATE Sub Segment = ", response);
          successSegment(response, { method: 1 });

          handleCancel();
        })
        .catch((error) => {
          console.log("UPDATE Sub Segment = ", error);
          failedSegment(error, { method: 1 });
        })
        .finally(() => {
          fetchData();
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record?.id;

    console.log(contentdelete);

    var obj = {
      ["id"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/room/rate-code/sub-segment`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Sub Segment = ", response);
        successSegment(response, { method: 2 });
      })
      .catch((error) => {
        console.log("DEL Sub Segment = ", error);
        failedSegment(error, { method: 2 });
      })
      .finally(() => {
        fetchData();
      });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey;
    // console.log("Search Key = ", query);

    var updatedList = [...data];
    // console.log("Update List = ", updatedList);

    updatedList = updatedList.filter((item) => {
      // console.log("ITEM => ", item);
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setData(updatedList);
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="main-segment-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-table" style={{ padding: "30px" }}>
          <Row className="row-modal-btn">
            <Col span={4} className="col-exp-btn">
              {/* <Row justify="start" className="row-exp-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={<></>}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  Download Excel
                </Button>
              </Row> */}
            </Col>

            <Col span={20} className="col-modal-btn">
              <Row justify="end" className="row-open-btn">
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: "#1BC5BD",
                    borderColor: "#1BC5BD",
                    marginRight: 15,
                  }}
                >
                  Add Sub Segment
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="segment-table"
              key="segment-table"
              name="segment-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-segment-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                Add Sub Segment
              </Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                Edit Sub Segment
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="segment-modal-form"
          className="segment-modal-form"
          layout="vertical"
          form={form}
          onFinish={(e) => onFinish(e)}
          onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-segment-pay-type">
            <Col span={16}>
              <Form.Item
                label="Sub Segment's Name"
                name="sub_segment_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Segment's Name!",
                  },
                ]}
                style={{ width: "100%", marginRight: 30 }}
              >
                <Input placeholder="Segment's Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Sub Segment's Code"
                name="sub_segment_code"
                rules={[
                  {
                    required: true,
                    message: "Please, input a Segment's Code!",
                  },
                ]}
              >
                <Input
                  placeholder="Segment's Code"
                  style={{ marginRight: 30, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row className="row-desc-segment">
            <Col span={16}>
              <Form.Item label="Description" name="sub_segment_description">
                <Input.TextArea
                  showCount
                  maxLength={200}
                  style={{
                    height: 100,
                    width: 440,
                  }}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-segment">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="updated_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-segment">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="segment-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Space size="middle">
              <Button
                type="primary"
                htmlType="submit"
                key={"submit"}
                // onClick={onFinish}
                className="submit-btn"
                style={{
                  backgroundColor: "#1BC5BD",
                  borderColor: "#1BC5BD",
                  marginRight: 30,
                }}
              >
                Submit
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

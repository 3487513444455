// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Context
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  List,
  Card,
} from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { HiPrinter } from "react-icons/hi2";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import {
  FaFileDownload,
  FaConciergeBell,
  FaMoneyBillWave,
} from "react-icons/fa";

// Import Page Components
import ArticleMenuPage from "../ArticleMenu/ArticleMenu";
import PerTablePage from "../../../../Components/Table/Outlet/Order/PerTable";
import LoadingComponents from "../../../../Components/Reusable/Spinner/LoadingScreen";
import WidgetTableOrder from "../../../../Components/Widgets/Outlet/TableOrder/WidgetOrder";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import JournalHeaderTable from "../../../../Components/Table/Accounting/Journal/Headers/Table";
import POSHeaderTable from "../../Cashier/Headers/Table";
import PerTablePOS from "../../../../Components/Table/Outlet/Order/PerTablePOS";

// Modals
const { confirm } = Modal;

// CODE
export default function CreateOrderPage(props) {
  // Props
  const {
    tableSelected,
    is_return,
    is_room_service,
    is_refresh,
    data_from_resv,
  } = props;

  // CONTEXT
  const { state } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [articleList, setArticleList] = useState(null);
  // Loading
  const [loading, setLoading] = useState(true);
  const [isRoomService, setIsRoomService] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  // Additional
  const [search, setSearch] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;
    // fetchData();

    if (tableSelected && !cleanUp) {
      // console.log("Per-Table: ", tableSelected);

      setData(tableSelected);

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else {
      setData(null);
    }

    if (is_room_service === true && !cleanUp) {
      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [tableSelected, is_room_service]);

  // Handle Article
  const handleArticle = (val) => {
    const _data = val;
    // let articleBefore = articleList.length > 0 ? articleBefore : [];

    setArticleList(_data);
  };

  // Handle Created
  const handleCreated = (value) => {
    // console.log("Created: ", value);

    if (value === true) {
      is_refresh(true);
      setSelectedData([]);
    }
  };

  // SHOW MODAL
  const showConfirmModal = () => {
    confirm({
      className: "return-confirm",
      title: `Are you sure you want to return? All of Your transaction will not be saved! Please, finish your transaction first or just return to Table List Page!`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        is_refresh(true);
        is_return(true);
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const handleSelectedData = (value) => {
    console.log("handleSelectedData", value);
    setSelectedData(value);
  };

  // Loads;
  if (loading === true) {
    return <LoadingComponents />;
  }

  return (
    <>
      <Col
        span={24}
        className="main-space"
        align="start"
        style={{ width: "100%" }}
      >
        <Row
          className="mainspace-row"
          align="start"
          justify="space-evenly"
          gutter={[30, 30]}
        >
          <Col
            className="article-list-col"
            xxl={{
              span: 14,
              order: 1,
            }}
            xl={{
              span: 12,
              order: 1,
            }}
            lg={{
              span: 12,
              order: 1,
            }}
            md={{
              span: 24,
              order: 1,
            }}
            sm={{
              span: 24,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 1,
            }}
            // style={{
            //   background: "#FFFFFF",
            //   borderRadius: 5,
            // width: "100%",
            // }}
          >
            {/* <ArticleMenuPage articleData={handleArticle} /> */}
            <POSHeaderTable
                is_search={setSearch}
                // searchKey={value}
                searchKey={null}
                selectedData={handleSelectedData}
                is_refresh={is_refresh}
              />
          </Col>

          <Col
            className="order-col"
            xxl={{
              span: 10,
              order: 2,
            }}
            xl={{
              span: 12,
              order: 2,
            }}
            lg={{
              span: 12,
              order: 2,
            }}
            md={{
              span: 24,
              order: 2,
            }}
            sm={{
              span: 24,
              order: 2,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Row
              justify="end"
              className="row-btn"
              style={{
                padding: 30,
                background: "#FFFFFF",
                borderRadius: 5,
              }}
            >
              <Col span={24} className="ordertable-col">
                <Space
                  className="title-spacetxt"
                  align="center"
                  size={"large"}
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  {/* {isRoomService
                    ? tableSelected?.room_category_name +
                      " " +
                      tableSelected?.room_number
                    : data?.table_code} */}
                  {`Order Details`}
                </Space>
              </Col>
            </Row>

            <Row className="table-order-row">
              {/* old_version */}
              {/* <PerTablePage
                tableSelected={data}
                articleItems={articleList}
                is_room_service={isRoomService}
                is_created={handleCreated}
                data_from_resv={data_from_resv}
                tableAssign={setData}
                selectedData={selectedData}
              /> */}
              {/* end_old_version */}
              <PerTablePOS
                tableSelected={data}
                articleItems={articleList}
                is_room_service={isRoomService}
                is_created={handleCreated}
                data_from_resv={data_from_resv}
                tableAssign={setData}
                selectedData={selectedData}
              />
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}

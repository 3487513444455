// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { sub_children } from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiOpenBook } from "react-icons/gi";
import { AiFillAppstore, AiOutlineAppstore } from "react-icons/ai";
import {
  AppstoreFilled,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  RiBuilding2Fill,
  RiContactsBook2Fill,
  RiTableFill,
} from "react-icons/ri";
import { FaCreditCard, FaGlobeAsia, FaWarehouse } from "react-icons/fa";
import { HiTemplate } from "react-icons/hi";
import {
  MdConfirmationNumber,
  MdDoorSliding,
  MdOutlineBackupTable,
  MdOutlineGroups,
  MdOutlineTableBar,
  MdPayments,
  MdRoomPreferences,
  MdScale,
  MdScreenShare,
  MdTripOrigin,
} from "react-icons/md";
import { BsFillPersonBadgeFill, BsTable } from "react-icons/bs";
import { BiCategoryAlt, BiListPlus } from "react-icons/bi";
import { IoCalendar, IoMail } from "react-icons/io5";
import { FaShop } from "react-icons/fa6";

// Import Global Styles
import {
  cardBody,
  cardHeader,
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../Style/Global/Global";

// ROUTES
const listCard = [
  {
    path: "article-list",
    title: "Article List",
    id_modules: 68,
    icon: <GiOpenBook style={globalIcon} />,
  },
  {
    path: "article-main-group",
    title: "Article Main-Group",
    id_modules: 69,
    icon: <GiOpenBook style={globalIcon} />,
  },
  {
    path: "article-sub-group",
    title: "Article Sub-Group",
    id_modules: 70,
    icon: <GiOpenBook style={globalIcon} />,
  },
  {
    path: "country",
    title: "Country",
    id_modules: 71,
    icon: <FaGlobeAsia style={globalIcon} />,
  },
  {
    path: "department",
    title: "Department",
    id_modules: 72,
    icon: <RiBuilding2Fill style={globalIcon} />,
  },
  {
    path: "guest-card-file",
    title: "Guest List",
    id_modules: 73,
    icon: <RiContactsBook2Fill style={globalIcon} />,
  },
  {
    path: "outlet-location",
    title: "Outlet Location",
    id_modules: 74,
    icon: <FaShop style={globalIcon} />,
  },
  {
    path: "payment-card",
    title: "Payment Card",
    id_modules: 75,
    icon: <MdPayments style={globalIcon} />,
  },
  {
    path: "payment-type",
    title: "Payment Type",
    id_modules: 76,
    icon: <FaCreditCard style={globalIcon} />,
  },
  {
    path: "rate-code",
    title: "Rate Code",
    id_modules: 77,
    icon: <MdDoorSliding style={globalIcon} />,
  },
  {
    path: "room-availability",
    title: "Room Availability",
    id_modules: 78,
    icon: <MdConfirmationNumber style={globalIcon} />,
  },
  {
    path: "room-category",
    title: "Room Category",
    id_modules: 79,
    icon: <BiCategoryAlt style={globalIcon} />,
  },
  {
    path: "room",
    title: "Room List",
    id_modules: 80,
    icon: <MdRoomPreferences style={globalIcon} />,
  },
  {
    path: "salesman",
    title: "Salesman",
    id_modules: 81,
    icon: <BsFillPersonBadgeFill style={globalIcon} />,
  },
  {
    path: "main-segment",
    title: "Segmentations",
    id_modules: 82,
    icon: <MdOutlineGroups style={globalIcon} />,
  },
  {
    path: "sources",
    title: "Sources",
    id_modules: 83,
    icon: <MdTripOrigin style={globalIcon} />,
  },
  {
    path: "table-category",
    title: "Table Category",
    id_modules: 84,
    icon: <RiTableFill style={globalIcon} />,
  },
  {
    path: "table",
    title: "Table list",
    id_modules: 85,
    icon: <MdOutlineTableBar style={globalIcon} />,
  },
  {
    path: "unit-measurement",
    title: "Unit of Measurement",
    id_modules: 86,
    icon: <MdScale style={globalIcon} />,
  },
];

// CODE
export default function MasterPage(props) {
  // STATE MANAGEMENT
  // List Items
  const [listItems, setlistItems] = useState([]);

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const grandModule = sub_children?.length > 0 ? sub_children : [];

    const list = listCard.map((record) => {
      if (grandModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      } else {
        return null;
      }
    });

    // console.log("Grand Children Modules: ", sub_children);
    // console.log("Filtered: ", list);

    setlistItems(list);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="article-sales-main-layout">
          <Row
            className="row-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              margin: "0px 0px 30px",
              borderRadius: 5,
            }}
          >
            <AiFillAppstore
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Master Configuration`}
            </Typography>
          </Row>

          <Row
            // gutter={[30, 30]}
            className="row-body"
            justify="start"
            style={mainBody}
          >
            {listItems}

            {/* <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("allotment")}
                  // type="primary"
                  icon={<MdScreenShare style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Allotment`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("arrangement")}
                  // type="primary"
                  icon={<BiListPlus style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Arrangement`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("article-inventory")}
                  // type="primary"
                  icon={<GiOpenBook style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Article Inventory`}
                  </Typography>
                </Button>
              </Card>
            </Col>  

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("article-list")}
                  // type="primary"
                  icon={<GiOpenBook style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Article List`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("article-main-group")}
                  // type="primary"
                  icon={<GiOpenBook style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Article Main-Group`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`article-sub-group`)}
                  icon={<GiOpenBook style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Article Sub-Group`}</Typography>
                </Button>
              </Card>
            </Col>

             <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("bs-template")}
                  // type="primary"
                  icon={<HiTemplate style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`BS Template`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`compliment-article`)}
                  icon={<GiOpenBook style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Compliment Article`}</Typography>
                </Button>
              </Card>
            </Col> 

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("country")}
                  // type="primary"
                  icon={<FaGlobeAsia style={globalIcon} />}
                  style={linkStyle}
                >
           
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Country`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("department")}
                  // type="primary"
                  icon={<RiBuilding2Fill style={globalIcon} />}
                  style={linkStyle}
                >
                
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Department`}</Typography>
                </Button>
              </Card>
            </Col>

             <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`group-menu`)}
                  icon={<MdOutlineBackupTable style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Group Menu`}</Typography>
                </Button>
              </Card>
            </Col> 

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`guest-card-file`)}
                  icon={<RiContactsBook2Fill style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Guest List`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("letter")}
                  // type="primary"
                  icon={<IoMail style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Letter`}
                  </Typography>
                </Button>
              </Card>
            </Col> 

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("outlet-location")}
                  // type="primary"
                  icon={<FaShop style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Outlet Location`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`payment-card`)}
                  icon={<MdPayments style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Payment Card`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("payment-type")}
                  // type="primary"
                  icon={<FaCreditCard style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Payment Type`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("rate-code")}
                  // type="primary"
                  icon={<MdDoorSliding style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Rate Code`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("rate-periodic")}
                  // type="primary"
                  icon={<IoCalendar style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Rate Periodic`}</Typography>
                </Button>
              </Card>
            </Col> 

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("room-availability")}
                  // type="primary"
                  icon={<MdConfirmationNumber style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Room Availability`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`room-category`)}
                  icon={<BiCategoryAlt style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Room Category`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("room")}
                  // type="primary"
                  icon={<MdRoomPreferences style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Room List`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`salesman`)}
                  icon={<BsFillPersonBadgeFill style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Salesman`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`main-segment`)}
                  icon={<MdOutlineGroups style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Segmentations`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`sources`)}
                  icon={<MdTripOrigin style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Sources`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`table-category`)}
                  icon={<RiTableFill style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Table Category`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("table")}
                  // type="primary"
                  icon={<MdOutlineTableBar style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Table List`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("unit-measurement")}
                  // type="primary"
                  icon={<MdScale style={globalIcon} />}
                  style={linkStyle}
                >
                  
                  <Typography className="text-page" style={globalText}>
                    {`Unit of Measurements`}
                  </Typography>
                </Button>
              </Card> 
              </Col>
            */}
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterArticleMain(props) {
  // PROPS
  const {
    getArticleMain,
    mainName,
    getDepartmentId,
    addArticleState,
    isDisabled,
  } = props;

  // CONTEXT
  const { getArticleMaster } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [articleMain, setArticleMain] = useState(null);
  // Data
  const [optionValue, setOptionValue] = useState([]);
  const [bulks, setBulks] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/article-main-group");
  };

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getArticleMaster({
      types: "main-group",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          handleMap(filtered);
          // setBulks(filtered);
        } else {
          setBulks([]);
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // FETCH GUEST DATA
  const fetchFilter = async (dept) => {
    if (bulks.length > 0) {
      handleFilter({ data: bulks, id: dept });
    } else {
      fetchData();
    }
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT FILTERS
  useEffect(() => {
    let cleanUp = false;

    // if (getDepartmentId > 0 && !cleanUp) {
    //   console.log(getDepartmentId);
    //   fetchFilter(getDepartmentId);
    // }

    if (mainName && !cleanUp) {
      setArticleMain(mainName);
    } else {
      setArticleMain(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [getDepartmentId, mainName]);

  // Handle Mapping
  const handleMap = (value) => {
    const _filtered = value;

    let optionFilter = _filtered.map((value) => ({
      value: value?.name,
      label: value?.name,
      code: value?.id,
      id: value?.id,
      key: value?.id,
      dept: value?.department_name,
      dept_id: value?.department_id,
      desc: value?.description,
    }));

    setOptionValue(optionFilter);
    setArticleMain(null);
    setBulks(optionFilter);

    // if (getDepartmentId > 0) {
    //   handleFilter({
    //     data: optionFilter,
    //     id: getDepartmentId,
    //   });
    // }
  };

  // Handle Filter
  const handleFilter = (value) => {
    const _arr = value?.data;
    const _id = value?.id;

    let _filter = _arr.filter((value) => {
      if (value?.dept_id == _id) {
        return value;
      }
    });

    // console.log("Data: ", _arr);
    // console.log("Data Filter: ", _filter);

    setOptionValue(_filter);
  };

  // Handle Change
  const handleChange = (e, allKeys) => {
    const _value = e;
    const _keys = allKeys;

    // console.log(_value);
    // console.log("INI CODE ", _keys);

    setArticleMain(_value);
    getArticleMain({
      name: _value,
      code: _keys?.code,
      main_id: _keys?.id,
    });
    //  if (onSet) onSet(deviceid);
  };

  // Handle Clear
  const handleClear = () => {
    setArticleMain(null);
  };

  // console.log("Dept Name => ", getDepartmentId);
  // console.log("Article Main Name => ", mainName);

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={20} className="select-col">
          <Select
            key={optionValue.code}
            style={{ width: "100%" }}
            value={articleMain}
            showSearch
            allowClear
            disabled={isDisabled}
            placeholder="Main Group"
            onClear={handleClear}
            onChange={handleChange}
            options={optionValue}
          />
        </Col>

        <Col span={4} className="select-col">
          <Tooltip
            className="redirect-tooltip"
            title={"Redirect to Master Main"}
          >
            <Button
              className="master-btn"
              icon={
                <AppstoreFilled
                  onClick={move}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

import { io } from "socket.io-client";
// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  Layout,
  Row,
  Typography,
  Space,
  Input,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaCashRegister } from "react-icons/fa";

// Import Page Components
import ModalOutletLocation from "../../../Components/Modals/Outlet/OutletLocation/Location/Modal";

// Import Global Border
import { cardBody, cardHeader } from "../../../Style/Global/Global";
import POSOrder from "../../../Components/Table/Outlet/Cashier/Main/Table";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { MdFastfood } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";
import POSHeaderTable from "../Cashier/Headers/Table";
import PerTablePOS from "../../../Components/Table/Outlet/Order/PerTablePOS";
import KitchenHeaderTable from "../../../Components/Table/Outlet/KitchenOrder/KitchenOrderHeader";
import KitchenDetailsTable from "../../../Components/Table/Outlet/KitchenOrder/KitchenOrderDetails";

// CODE
export default function KitchenOrderPage() {
  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname } = useLocation();
  // STATE MANAGEMENT
  // Search
  const [value, setValue] = useState(null);
  // Status
  const [isRoomService, setIsRoomService] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [created, setCreated] = useState(null);
  // Data
  const [data, setData] = useState(null);
  // Modal State
  const [open, setOpen] = useState(true);

  // Handle Select
  const handleOpen = () => {
    // console.log("PLEASE OPEN!", open);
    setOpen(true);
  };

  // Handle Search
  const handleSearch = (e) => {
    e.preventDefault();
    // console.log("PLEASE OPEN!", open);
    setValue(e.target.value);
  };

  const handleNavigateReturn = () => {
    navigate(`${pathname}`.substring(0, pathname.length - 13), {
      state: {},
    });
  };

  const handleSelectedData = (value) => {
    console.log("handleSelectedData.value", value);
    setSelectedData(value);
  };

  const handleCreated = () => {
    setSelectedData([]);
    if(created == true){
      setCreated(false)
    } else {
      setCreated(true)
    }
  };

  const handleClear = () => {
    setSelectedData([]);
  };

  const handleThermalPrinterCheck = () => {
    const socket = io("https://printer-api.hilook.support/", {
      transports: ["websocket"],
      auth: {
        hotel_id: "abcdefg",
      },
      withCredentials: true,
    });

    const bodyToPrint =
      {
          "order_id_alias": "POS240901293",
          "date": "2024-10-07 16:32:04",
          "sales_type": "Take Away",
          "table_code": null,
          "printer_id": 4,
          "item": [
              {
                  "id": 3412,
                  "order_id": "masih pake order_id_alias",
                  "article_id": 112,
                  "article_sub_name": "Soft Drink",
                  "article_sub_id": 14,
                  "article_name": "BLUE OCEAN",
                  "unit_price": 22000,
                  "created_by": "SUPER ADMIN",
                  "created_date": "2024-09-24T14:15:08.473807+07:00",
                  "item_quantity": 1,
                  "total_price": 26862,
                  "table_code": null,
                  "serving_order": "1",
                  "guest_name": "Pandu",
                  "updated_date": null,
                  "updated_by": null,
                  "chart_of_account": "420-01-010",
                  "tax": 2662,
                  "service": 2200,
                  "is_service": true,
                  "is_tax": true,
                  "price_id": null,
                  "order_step": 0,
                  "step_date": "2024-09-24T14:15:08.473807",
                  "step_by": "SUPER ADMIN",
                  "is_deleted": false,
                  "deleted_date": null,
                  "deleted_by": null,
                  "notes": "0",
                  "is_audit": false,
                  "audit_at": null,
                  "gross": 26862,
                  "total_tax": 2662,
                  "total_service": 2200,
                  "add_ons": null,
                  "journal_alias": null,
                  "order_id_alias": "POS240901293",
                  "payment_status": 1,
                  "table_id": null,
                  "bill_status": 0,
                  "payment_date": null,
                  "payment_created_by": null,
                  "is_paid": false,
                  "order_id_linked_temp": null,
                  "payment_id": null,
                  "header_id": 1262,
                  "serving_que": "1",
                  "key": 0
              }
          ]
      };

    socket.connect();

    if(bodyToPrint){
      socket.emit("message", bodyToPrint);
    }

    // socket.on("recieve_message", setMessage);
    
    return () => {
      socket.disconnect();
    }
  }

  return (
    <Layout>
      <Content>
        <Col span={24} className="table-order-main-layout">
          <Row
            className="table-order-title"
            align="middle"
            justify="start"
            style={cardHeader}
          >
            <MdFastfood
              className="icons-head"
              style={{
                height: 24,
                width: 24,
                margin: "10px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "10px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Kitchen Order`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  className="text-search"
                  style={{ marginBottom: 10 }}
                >
                  {`Order ID`}
                </Typography>

                <Input
                  allowClear
                  className="input-search"
                  placeholder="Order ID"
                  // onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  // onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
              <Col
                className="btn-col"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleNavigateReturn}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
              <Col
                className="btn-col"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  onClick={handleThermalPrinterCheck}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Thermal Printer Check`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Col
            span={24}
            className="main-space"
            align="start"
            style={{ width: "100%" }}
          >
            <Row
              className="mainspace-row"
              align="start"
              justify="space-evenly"
              gutter={[30, 30]}
            >
              <Col
                className="article-list-col"
                xxl={{
                  span: 14,
                  order: 1,
                }}
                xl={{
                  span: 12,
                  order: 1,
                }}
                lg={{
                  span: 12,
                  order: 1,
                }}
                md={{
                  span: 24,
                  order: 1,
                }}
                sm={{
                  span: 24,
                  order: 1,
                }}
                xs={{
                  span: 24,
                  order: 1,
                }}
              >
                <KitchenHeaderTable
                    // searchKey={value}
                    searchKey={null}
                    selectedData={handleSelectedData}
                    is_refresh={handleClear}
                    is_created={created}
                    is_search={()=>{}}
                  />
              </Col>

              <Col
                className="order-col"
                xxl={{
                  span: 10,
                  order: 2,
                }}
                xl={{
                  span: 12,
                  order: 2,
                }}
                lg={{
                  span: 12,
                  order: 2,
                }}
                md={{
                  span: 24,
                  order: 2,
                }}
                sm={{
                  span: 24,
                  order: 2,
                }}
                xs={{
                  span: 24,
                  order: 2,
                }}
              >
                <Row
                  justify="end"
                  className="row-btn"
                  style={{
                    padding: 30,
                    background: "#FFFFFF",
                    borderRadius: 5,
                  }}
                >
                  <Col span={24} className="ordertable-col">
                    <Space
                      className="title-spacetxt"
                      align="center"
                      size={"large"}
                      style={{
                        fontSize: 20,
                        fontWeight: 500,
                      }}
                    >
                      {`Order Details`}
                    </Space>
                  </Col>
                </Row>

                <Row className="table-order-row">
                  <KitchenDetailsTable
                    tableSelected={data}
                    is_room_service={isRoomService}
                    tableAssign={setData}
                    selectedData={selectedData}
                    is_created={handleCreated}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Col>
      </Content>
    </Layout>
  );
}

import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { FcStatistics } from "react-icons/fc";
import ReportBSTopTable from "../../../../../Components/Table/Reports/Reports/Parameters/BalanceSheet/Top/Table";

export default function ReportBalanceSheetTopPage() {
  // STATE
  // DATA
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState(null);

  // HANDLE SEARCH
  const handleSearch = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="bsr-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FcStatistics
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Balance Sheet Top Report Parameter`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  className="col-searh"
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Keywords`}
                  </Typography>

                  <Input
                    allowClear
                    className="input-search"
                    placeholder="Keywords"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                    }}
                  />
                </Col>

                <Col
                  className="col-search-btn"
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <ReportBSTopTable is_search={setSearch} searchKey={value} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

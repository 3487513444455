// Import React Components
import React from "react";

// Import Ant Design Components
import { Tabs } from "antd";

const items = new Array(3).fill(null).map((_, i) => {
  const id = String(i + 1);

  return {
    label: `Tab Title ${id}`,
    key: id,
    children: (
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <p>Content of Tab Pane {id}</p>
        <p>Content of Tab Pane {id}</p>
        <p>Content of Tab Pane {id}</p>
      </div>
    ),
  };
});

// CODE
export default function AddReservation() {
  return (
    <>
      <Tabs type="card" items={items} />
    </>
  );
}

import moment from "moment";
import React from "react";

// Export Functions
export function LocalizationDate(data) {
  // Data Revecives
  const _date = new Date(data);
  var _newDate = "";

  let _getTimeZone = _date.getTimezoneOffset();

  // console.log("Data Receives --> ", data);
  // console.log("Data TIMEZONE --> ", _getTimeZone);
  // console.log("TIMEZONE --> ", _getTimeZone / 60);
  // console.log("Bool TIMEZONE --> ", _getTimeZone / 60 == -7);

  var _new_date = new Date(
    _date.getTime() - _date.getTimezoneOffset() * 60 * 1000
  );

  if (_getTimeZone / 60 != -7) {
    _newDate = _new_date;
  } else {
    _newDate = _date;
  }

  // console.log("Data Sent --> ", _newDate);

  return _newDate;
}

// Import React Components
import React, { useState } from "react";

// Import Parameters
import ParametersAccounting from "../../Parameters/ParamsAcc";
import ParametersBackOffice from "../../Parameters/ParamsBO";
import ParametersFrontOffice from "../../Parameters/ParamsFO";
import { Encoder } from "../../../../API/Global/Encoder";
import { useEffect } from "react";

// CODE
export default function ParamSetter() {
  // Parameters Accounting
  const acc = ParametersAccounting().params;
  const back = ParametersBackOffice().params;
  const front = ParametersFrontOffice().params;

  // Setter
  const [parameter, setParameter] = useState({
    accounting: "",
    front_office: "",
    back_office: "",
  });

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (acc?.id > 0 && back?.id > 0 && front?.id > 0 && !cleanUp) {
      console.log("Use effects run!");
      //   console.log("This is Called: ", acc);
      //   console.log("This is Called: ", back);
      //   console.log("This is Called: ", front);

      const par_1 = Encoder(acc);
      const par_2 = Encoder(back);
      const par_3 = Encoder(front);

      setParameter({
        accounting: acc,
        back_office: back,
        front_office: front,
      });

      sessionStorage.setItem("par-1", par_1);
      sessionStorage.setItem("par-2", par_2);
      sessionStorage.setItem("par-3", par_3);
    }

    return () => {
      cleanUp = true;
    };
  }, [acc, back, front]);

  return parameter;
}

import { Decoder } from "../../../../API/Global/Decoder";

// CODE
export const BackOfficeParams = () => {
  // Get Params
  let params = sessionStorage.getItem("par-2");

  const _decode = Decoder(params);

  console.log("Params Back-OFfice: ", _decode);

  //   return { code: _decode?.currency_code, name: _decode?.currency };
  return _decode;
};

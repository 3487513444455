// Import React's Component
import moment from "moment";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Typography,
  Modal,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  DownloadOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import TableBook from "./TableBook";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

const { Title } = Typography;

// STYLE TEXT
const textSum = {
  fontSize: 12,
  fontWeight: 750,
  textAlign: "right",
  display: "flex",
  justifyContent: "flex-end",
};

const dataSource = [
  {
    id: 90,
    date_availability_1: "2023-05-29",
    date_availability_2: "2023-05-30",
    date_availability_3: "2023-05-31",
    room_category: "SUPERIOR",
    room_available_1: 2,
    room_available_2: 2,
    room_available_3: 2,
    room_unavailable: 0,
    number_of_rooms: 2,
  },
  {
    id: 91,
    date_availability_1: "2023-05-29",
    date_availability_2: "2023-05-30",
    date_availability_3: "2023-05-31",
    room_category: "DELUXE",
    room_available_1: 14,
    room_available_2: 14,
    room_available_3: 14,
    room_unavailable: 0,
    number_of_rooms: 14,
  },
  {
    id: 92,
    date_availability_1: "2023-05-29",
    date_availability_2: "2023-05-30",
    date_availability_3: "2023-05-31",
    room_category: "SUITE",
    room_available_1: 10,
    room_available_2: 10,
    room_available_3: 10,
    room_unavailable: 0,
    number_of_rooms: 10,
  },
  {
    id: 93,
    date_availability_1: "2023-05-29",
    date_availability_2: "2023-05-30",
    date_availability_3: "2023-05-31",
    room_category: "EXECUTIVE",
    room_available_1: 0,
    room_available_2: 6,
    room_available_3: 6,
    room_unavailable: 0,
    number_of_rooms: 6,
  },
  {
    id: 94,
    date_availability_1: "2023-05-29",
    date_availability_2: "2023-05-30",
    date_availability_3: "2023-05-31",
    room_category: "STANDARD",
    room_available_1: 2,
    room_available_2: 2,
    room_available_3: 2,
    room_unavailable: 0,
    number_of_rooms: 2,
  },
];

// CODE
export default function RoomAvailabilityTable(props) {
  // PROPS
  const {
    // On Form or On Page?
    onForm,
    // Data Room
    dataRoom,
    average,
    // Total category
    totalCat,
    // Date Range
    date_avail,
    // Data Avail
    data_avail,
    // Total Days
    totalDays,
    // Is Refresh?
    is_refresh,
    is_load,
    is_search,
  } = props;

  // Create Columns
  // Table's Column
  const columns = [
    {
      title: "Room Category",
      dataIndex: "room_category_name_1",
      key: "room_category_name_1",
      // fixed: "left",
      width: 200,
      className: "room-category",
    },
    {
      title: "Total Room",
      dataIndex: "number_of_rooms_1",
      key: "number_of_rooms_1",
      // fixed: "left",
      width: 200,
      className: "number-rooms",
      render: (_, record) => {
        const _rows = [record];
        // console.log("Row Numbers: ", record);

        let selectedCap = _rows.reduce((acc, cur) => {
          Object.keys(cur).some((key) => {
            if (key.includes("number_of_rooms")) {
              if (acc < cur[key]) acc = cur[key];
            }
          });

          return acc;
        }, 0);

        return selectedCap;
      },
      // sortDirections: ["descend", "ascend"],
    },
  ];

  // CONTEXT
  const { getReservations } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataAvail, setDataAvail] = useState([]);
  const [fetchResult, setFetchResult] = useState([]);
  // Dynamic Columns
  const [newColumns, setNewColumns] = useState([]);
  // Dynamic Summaries
  const [total, setTotal] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [occupancies, setOccupancies] = useState([]);
  const [avail, setAvail] = useState([]);
  const [outOfOrder, setOutOfOrder] = useState([]);
  const [offMarket, setOffMarket] = useState([]);
  const [averageRate, setAverageRate] = useState([]);
  const [percentages, setPercentages] = useState([]);
  // Total Category
  const [totalRoomCat, setTotalRoomCat] = useState(3);
  const [selectedData, setSelectedData] = useState({
    date: "",
    room: "",
  });
  const [columnWidth, setColumnWidth] = useState(1250);
  const [isPrint, setIsPrint] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(null);

  // Date
  const [dateRange, setDateRange] = useState([
    moment(),
    moment().add(2, "days"),
  ]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);

  // Temporary Array
  let _avail = [];
  let _total = [];
  let _occ = [];

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    // fetchData();
    if (dataRoom?.length > 0 && !cleanUp) {
      // setData(dataRoom);
      // setTotalRoomCat(totalCat);
      modifyData();
      // dynamicSummaries(dataRoom, "date_availability");
    }

    return () => {
      cleanUp = true;
    };
  }, [dataRoom, totalCat]);

  useEffect(() => {
    let cleanUp = false;

    if (date_avail?.length > 0 && totalDays && !cleanUp) {
      // console.log("Date Range ---> ", date_avail);
      console.log("Days ---> ", totalDays);

      setDateRange(date_avail);
      setTotalRoomCat(totalDays);
    }

    return () => {
      cleanUp = true;
    };
  }, [date_avail, totalDays]);

  useEffect(() => {
    let cleanUp = false;

    // NEW COLUMNS
    if (data.length > 0 && !cleanUp) {
      dynamicColumns(data);
    }

    return () => {
      cleanUp = true;
    };
  }, [data, totalRoomCat]);

  useEffect(() => {
    let cleanUp = false;

    if (columns?.length > 2 && !cleanUp) {
      console.log("NEW COLUMNS USE EFFECTS => ", columns);
      setNewColumns(columns);
    }

    return () => {
      cleanUp = true;
    };
  }, [columns]);

  useEffect(() => {
    let cleanUp = false;

    if (isPrint && !cleanUp) {
      printOut();
    }

    return () => {
      cleanUp = true;
    };
  }, [isPrint]);

  useEffect(() => {
    let cleanUp = false;

    if (total.length > 0 && !cleanUp) {
      // console.log("Total Columns = ", total);

      rowSummaries(totalRoomCat);
    }

    return () => {
      cleanUp = true;
    };
  }, [total]);

  // CRETE TABLE
  const TableContainer = () => {
    // const { columns, data, loading } = props;

    // console.log("Columns = ", columns);
    // console.log("New Columns = ", newColumns);
    // console.log("Total Columns = ", total);
    // console.log("Data = ", total_availability);

    return (
      <>
        <Table
          title={onForm == true ? () => `Room Availability` : () => {}}
          className="room-avail-table"
          name="room-avail-table"
          key="room-avail-table"
          bordered
          size={isPrint == true ? "small" : "middle"}
          columns={newColumns}
          dataSource={data?.length > 0 ? data : dataAvail}
          loading={loading}
          pagination={false}
          scroll={{
            x: onForm == true ? "100%" : columnWidth,
            // onForm == true ? "100%" :
          }}
          rowClassName={(record, index) => {
            return index % 2 == 0 ? "even" : "odd";
          }}
          rowKey={(record) => {
            return record.id ? record.id : record.id_1;
          }}
          summary={(record) => {
            return (
              <>
                <Table.Summary fixed key="summary-tables" className="sum-table">
                  <Table.Summary.Row className="row-space" key={"row-space"}>
                    <Table.Summary.Cell
                      key="row-cell-space"
                      className="row-cell-space"
                      index={0}
                      colSpan={2}
                    />
                  </Table.Summary.Row>

                  <Table.Summary.Row
                    className="row-total-room"
                    key={"row-total-room"}
                  >
                    <Table.Summary.Cell
                      key="row-cell-room"
                      className="row-cell-room"
                      index={0}
                      colSpan={2}
                    >
                      <Typography.Text
                        className="text-total"
                        style={textSum}
                      >{`Total Room`}</Typography.Text>
                    </Table.Summary.Cell>

                    {summaries}
                  </Table.Summary.Row>

                  <Table.Summary.Row
                    className="row-total-occ"
                    key={"row-total-occ"}
                  >
                    <Table.Summary.Cell
                      className="row-cell-room"
                      key={"row-cell-room"}
                      index={0}
                      colSpan={2}
                    >
                      <Typography.Text
                        className="text-total"
                        style={textSum}
                      >{`Total OCC`}</Typography.Text>
                    </Table.Summary.Cell>

                    {occupancies}
                  </Table.Summary.Row>

                  <Table.Summary.Row
                    className="row-total-avail"
                    key={"row-total-avail"}
                  >
                    <Table.Summary.Cell
                      className="row-cell-room"
                      key={"row-cell-room"}
                      index={0}
                      colSpan={2}
                    >
                      <Typography.Text
                        className="text-total"
                        style={textSum}
                      >{`Total Available`}</Typography.Text>
                    </Table.Summary.Cell>

                    {avail}
                  </Table.Summary.Row>

                  <Table.Summary.Row
                    className="row-total-ooo"
                    key={"row-total-ooo"}
                  >
                    <Table.Summary.Cell
                      className="row-cell-room"
                      key={"row-cell-room"}
                      index={0}
                      colSpan={2}
                    >
                      <Typography.Text
                        className="text-total"
                        style={textSum}
                      >{`Total Out of Order (OoO)`}</Typography.Text>
                    </Table.Summary.Cell>

                    {outOfOrder}
                  </Table.Summary.Row>

                  <Table.Summary.Row
                    className="row-off-market"
                    key={"row-total-off"}
                  >
                    <Table.Summary.Cell
                      className="row-cell-room"
                      key={"row-cell-room"}
                      index={0}
                      colSpan={2}
                    >
                      <Typography.Text
                        className="text-total"
                        style={textSum}
                      >{`Total Off-Market (OM)`}</Typography.Text>
                    </Table.Summary.Cell>

                    {offMarket}
                  </Table.Summary.Row>

                  <Table.Summary.Row className="row-arr" key={"row-arr"}>
                    <Table.Summary.Cell
                      className="row-cell-room"
                      key={"row-cell-room"}
                      index={0}
                      colSpan={2}
                    >
                      <Typography.Text
                        className="text-total"
                        style={textSum}
                      >{`Average Room Rate (ARR)`}</Typography.Text>
                    </Table.Summary.Cell>
                    {/* {`$ */}
                    {averageRate}
                    {/* //`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                  </Table.Summary.Row>

                  <Table.Summary.Row
                    className="row-total-percentages"
                    key="row-total-percentages"
                  >
                    <Table.Summary.Cell
                      className="row-cell-room"
                      key={"row-cell-room"}
                      index={0}
                      colSpan={2}
                    >
                      <Typography.Text
                        className="text-total"
                        style={textSum}
                      >{`Total OCC ( % )`}</Typography.Text>
                    </Table.Summary.Cell>

                    {percentages || []}
                  </Table.Summary.Row>
                </Table.Summary>
              </>
            );
          }}
        />
      </>
    );
  };

  // TABLE DATA TRANSFORMATION
  const transMart = (arr, pro) => {
    // console.log("Arr about to Grouping --> ", arr);
    // console.log("Params --> ", pro);

    let lao = [];

    // Object.entries(
    //   arr.reduce(function (go, ga) {
    //     (go[ga[pro]] = go[ga[pro]] || []).push(ga);

    //     // console.log("GO Reduce by Room Category --> ", go);
    //     // console.log("GA Reduce by Room Category per Index --> ", ga);
    //     // console.log("GA GO Functions --> ", go[ga[pro]]);

    //     return go;
    //   }, {})
    // ).forEach(([_, am]) => {
    //   let roy = {};

    //   // console.log("Underscores = ", _);
    //   // console.log("AM  = ", am);

    //   am.forEach(function (mo, mi) {
    //     for (let mk in mo) {
    //       // console.log("MK --> Key = ", mk);
    //       // console.log("MO --> Data = ", mo);
    //       // console.log("MI --> INDEX = ", mi);

    //       roy[mk + "_" + (mi + 1)] = mo[mk];
    //     }
    //   });

    //   // console.log("Roy For Each Category => ", roy);

    //   lao.push(roy);
    // });

    // Group By
    let _merge = [];
    let _group = Object.groupBy(arr, (e) => e.room_category_id);

    for (const key in _group) {
      const element = _group[key];
      // console.log("ELEMENTS: ", element);

      let _newObj = {};

      const res = element.forEach(function (_objects, _index) {
        for (let _keys in _objects) {
          // console.log("KEYS = ", _keys);
          // console.log("OBJECTS = ", _objects);
          // console.log("INDEX = ", _index);

          if (_keys == "date_availability") {
            //   console.log(`This is Same: ${_newObj[_objects[_keys]]}`);
            //   console.log(`With: ${[_objects[_keys]]}`);

            _newObj[_objects[_keys]] = _objects?.room_available;
            _newObj[_keys + "_" + (_index + 1)] = _objects[_keys];
          }

          if (_objects[_keys] !== null) {
            _newObj[_keys + "_" + (_index + 1)] = _objects[_keys];
          }
        }
      });

      // console.log("Res Reduces: ", res);
      // console.log("New Object: ", _newObj);

      _merge.push(_newObj);
    }

    // console.log("Array Lao --> ", lao);
    console.log("Group By --> ", _group);
    console.log("Array Merge --> ", _merge);

    setTimeout(() => {
      setLoading(false);
      is_load(false);
    }, 750);

    return _merge;
  };

  // TRIGGER MODIFY TABLE DATA
  const modifyData = () => {
    setLoading(true);

    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);

    /*
    console.log("DATA TABLE >>> ", dataRoom);

    let mod = dataRoom.reduce((accumulator, initialValues) => {
      console.log("Accumulator => ", accumulator);
      console.log("initial Values => ", initialValues);

      let found = accumulator.find(
        (e) => e.room_category == initialValues.room_category
      );

      console.log("FOUND => ", found);

      if (found) {
        accumulator.push({
          ...found,
          date_2: initialValues.date_availability,
          room_2: initialValues.room_available,
          room_category: initialValues.room_category,
          room_unavailable_2: initialValues.room_unavailable,
        });
      } else {
        accumulator.push({
          ...initialValues,
          date_1: initialValues.date_availability,
          // date_2: accumulator.date_availability,
          room_1: initialValues.room_available,
          // room_2: accumulator.room_available,
          room_category: initialValues.room_category,
        });
      }

      setLoading(false);
      return accumulator;
    }, []);

    // mod.slice(0, 5);
    console.log("MOD => ", mod);
    setData(mod.slice(length));
    */

    let newData = transMart(dataRoom, "room_category_id");
    // let summaries = dynamicSummaries(dataRoom, "date_availability");
    let summaries = dynamicColumnsByDate(dataRoom);

    // console.log("Sum -> ", summaries);
    // console.log("Transmart -> ", newData);

    setData(newData);
    setTotal(summaries);
    setDataAvail(newData);
    data_avail(newData);

    let _width = 100;
    let _length = summaries?.length;
    let _newWidth = _width * _length;

    if (summaries?.length > 13) {
      setColumnWidth(_newWidth);
    } else {
      setColumnWidth(_newWidth);
    }
  };

  // CREATE DYNAMICS COLUMNS
  const dynamicColumns = async (value) => {
    const _data = value;
    const _arr = [];
    const _date = dateRange;
    let _newColumns = [];
    let _number = 0;

    for (let i = 1; i <= totalDays; i++) {
      _arr.push({
        id: i,
      });
    }

    // _arr.map((items, index) => {
    //   console.log("Date: ", _date);

    //   Object.keys(items).forEach((key) => {
    //     columns.push({
    //       title: moment(_date[0]).add(index, "days").format("YYYY-MM-DD"), //moment(dateRange[0]).format("DD-MM-YYYY"),
    //       dataIndex: moment(_date[0]).add(index, "days").format("YYYY-MM-DD"), // "reservation_id" + "_" + parseInt(index + 1),
    //       key: moment(_date[0]).add(index, "days").format("YYYY-MM-DD"),
    //       render: (record, recordAll, index) => {
    //         console.log("Record click: ", record);

    //         return onForm === false ? (
    //           <Button
    //             className="res-btn"
    //             key="res-btn"
    //             onClick={() => {
    //               const _dates = items["date_availability_" + key.slice(-1)];

    //               console.log("Room Category: ", recordAll, index);
    //               console.log("KEYS: ", key);
    //               console.log("Items date :", _dates);

    //               // console.log(
    //               //   "Items date :",
    //               //   items["date_availability_"]
    //               // ) + key.slice(-1);

    //               setSelectedData({
    //                 date: _dates,
    //                 room: recordAll?.room_category_name_1,
    //               });

    //               dataModal(_dates, recordAll?.room_category_name_1);

    //               setOpen(true);
    //             }}
    //           >
    //             {record}
    //           </Button>
    //         ) : (
    //           <>{record}</>
    //         );
    //       },
    //     });
    //   });
    // });

    // console.log("New Columns: ", _newColumns);
    // columns.push({ ...columns, ..._newColumns });

    _data.map((items, index) => {
      Object.keys(items).forEach((key) => {
        if (key.indexOf("room_available_") == 0 && index == 0) {
          // if (_number !== totalDays) {
          _number++;

          // console.log("Numbers: ", _number, " = ", totalDays);
          // console.log("Title name: ", moment(dateRange[0]).add(_number - 1, "days").format("DD-MM-YYYY"));

          return (
            <span>
              {columns.push({
                title: moment(dateRange[0])
                  .add(_number - 1, "days")
                  .format("DD-MM-YYYY"), //items["date_availability_" + _number], // moment(dateRange[0]).format("DD-MM-YYYY"),
                dataIndex: moment(dateRange[0])
                  .add(_number - 1, "days")
                  .format("YYYY-MM-DD"), // key,
                key: moment(dateRange[0])
                  .add(_number - 1, "days")
                  .format("YYYY-MM-DD"), //key,
                render: (record, recordAll, index) => {
                  // console.log("Record click: ", record);

                  return onForm === false ? (
                    <Button
                      className="res-btn"
                      key="res-btn"
                      onClick={() => {
                        const _dates =
                          items["date_availability_" + key.slice(-1)];

                        console.log("Room Category: ", recordAll, index);
                        console.log("KEYS: ", key);
                        console.log("Items date :", _dates);

                        // console.log(
                        //   "Items date :",
                        //   items["date_availability_"]
                        // ) + key.slice(-1);

                        setSelectedData({
                          date: _dates,
                          room: recordAll?.room_category_name_1,
                        });

                        dataModal(_dates, recordAll?.room_category_id_1);

                        setOpen(true);
                      }}
                    >
                      {record}
                    </Button>
                  ) : (
                    <>{record}</>
                  );
                },
              })}
            </span>
          );
        }
      });
    });
  };

  // DYNAMIC SUMMARIES
  const dynamicSummaries = (value, key) => {
    const _data = value;
    const _key = key;
    let _arr = [];

    // let total = 0;
    // let avail = 0;
    // let occ = 0;

    // console.log("Dynamic Summary --> ", JSON.stringify(_data));
    // console.log("Dynamic Summary Key --> ", _key);

    Object.entries(
      _data.reduce(function (_record, _index) {
        (_record[_index[_key]] = _record[_index[_key]] || []).push(_index);

        return _record;
      }, {})
    ).forEach(([_, _value]) => {
      console.log("VALUE --> ", _value);

      // Total Room
      let _objTotal = _value.reduce((_acc, _init) => {
        // console.log("Accumulation --> ", _acc);
        // console.log("Initial Value --> ", _init);

        return _acc + parseInt(_init.number_of_rooms);
      }, 0);

      let _objAvail = _value.reduce((_acc, _init) => {
        return _acc + parseInt(_init.room_available);
      }, 0);

      let _objOcc = _value.reduce((_acc, _init) => {
        return _acc + parseInt(_init.room_unavailable);
      }, 0);

      console.log("Object Total Room --> ", _objTotal);
      // console.log("Object Total Avail  --> ", _objAvail);
      // console.log("Object Total Occ --> ", _objOcc);

      _arr.push({
        total_room: _objTotal,
        occupied: _objOcc,
        available: _objAvail,
        orders: 0,
        id: _value[0].id,
        date: _value[0].date_availability,
      });
    });

    // _data.forEach((_record, _index) => {
    //   let _obj = {};

    //   console.log("Record Data = ", _record);
    //   console.log("Index of = ", _index);
    //   // console.log("Record Data = ", _record);

    //   for (let _key in _record) {
    //     console.log("KEY SUMMARY --> ", _key);

    //     // roy[mk + "_" + (mi + 1)] = mo[mk];

    //     // console.log(
    //     //   "FOR = ",
    //     //   (_obj["id" + "_" + (_index + 1)] = _record[_key])
    //     // );
    //     // if (_key.includes("number_of_rooms_")) {
    //     //   _obj[_key] = _record[_key];
    //     // }
    //     // if (_key.includes("date_availability_")) {
    //     //   _obj[_key] = _record[_key];
    //     // }
    //     // if (_key.includes("room_unavailable_")) {
    //     //   _obj[_key] = _record[_key];
    //     // }
    //   }

    //   console.log("OBJ For Each Sum => ", _obj);

    //   _arr.push(_obj);
    // });
    // _avail.push(`date_availability_` + i);
    // _total.push(`number_of_rooms_` + i);
    // _occ.push(`room_unavailable_` + i);

    console.log("ARRAY SUM --> ", _arr);
    // console.log("ARRAY SUM TOTAL --> ", total);

    return _arr;
  };

  // Dynamic Columns
  const dynamicColumnsByDate = (params) => {
    const _arr = params;
    // console.log("Data Room: ", _arr);
    // console.log("Data Room: ", dataRoom);

    return Object.values(
      _arr.reduce(
        (
          acc,
          {
            date_availability,
            room_available,
            room_unavailable,
            number_of_rooms,
            off_market,
            out_of_order,
          }
        ) => {
          acc[date_availability] = acc[date_availability] || {
            date_availability: date_availability,
            total_room: 0,
            occupied: 0,
            available: 0,
            orders: 0,
            market: 0,
            id: 0,
          };

          // console.log(
          //   "Total Rooms: ",
          //   acc[date_availability].date_availability,
          //   ": ",
          //   number_of_rooms,
          //   " /Total Rooms: ",
          //   acc[date_availability]
          // );

          acc[date_availability].total_room =
            acc[date_availability].total_room + number_of_rooms;
          acc[date_availability].occupied =
            acc[date_availability].occupied + room_unavailable;
          acc[date_availability].available =
            acc[date_availability].available + room_available;
          acc[date_availability].orders =
            acc[date_availability].orders + out_of_order;
          acc[date_availability].market =
            acc[date_availability].market + off_market;
          acc[date_availability].id =
            acc[date_availability].id || Object.keys(acc).length;

          return acc;
        },
        {}
      )
    );
  };

  // Row Summaries
  const rowSummaries = (value) => {
    const _day = value;

    let rowTotal = [];
    let rowAvail = [];
    let rowOcc = [];
    let rowOrder = [];
    let rowMarket = [];
    let rowAvrg = [];
    let rowPercent = [];

    // console.log("Days total --> ", _day);
    // console.log("Total --> ", total);

    if (total.length >= _day) {
      for (let i = 0; i < _day; i++) {
        let percentages =
          parseFloat(total[i]?.occupied / total[i].total_room) * 100;

        // console.log("New Row Summaries Percentages => ", percentages);
        // console.log("Loop ", total[i]);
        // console.log(
        //   "Loop Total: ",
        //   percentages,
        //   percentages.toFixed(0),
        //   percentages.toFixed(1),
        //   percentages.toFixed(2)
        // );

        // rowTotal.push(4 + i);
        rowTotal.push(
          <Table.Summary.Cell index={4 + i} key={total[i].id}>
            <Typography
              key={total[i].id}
              style={{
                fontWeight: 750,
              }}
            >
              {total[i]?.total_room || 0}
            </Typography>
          </Table.Summary.Cell>
        );

        rowAvail.push(
          <Table.Summary.Cell index={4 + i} key={total[i].id}>
            <Typography
              key={total[i].id}
              style={{
                fontWeight: 750,
              }}
            >
              {total[i]?.available || 0}
            </Typography>
          </Table.Summary.Cell>
        );

        rowOcc.push(
          <Table.Summary.Cell index={4 + i} key={total[i].id}>
            <Typography
              key={total[i].id}
              style={{
                fontWeight: 750,
              }}
            >
              {total[i]?.occupied || 0}
            </Typography>
          </Table.Summary.Cell>
        );

        rowOrder.push(
          <Table.Summary.Cell index={4 + i} key={total[i].id}>
            <Typography
              key={total[i].id}
              style={{
                fontWeight: 750,
              }}
            >
              {total[i]?.orders || 0}
            </Typography>
          </Table.Summary.Cell>
        );

        rowMarket.push(
          <Table.Summary.Cell index={4 + i} key={total[i].id}>
            <Typography
              key={total[i].id}
              style={{
                fontWeight: 750,
              }}
            >
              {total[i]?.market || 0}
            </Typography>
          </Table.Summary.Cell>
        );

        rowAvrg.push(
          <Table.Summary.Cell index={4 + i} key={total[i].id}>
            <Typography
              key={total[i].id}
              style={{
                fontWeight: 750,
              }}
            >
              {`${average?.length > 0 ? average[i] : 0}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}
            </Typography>
          </Table.Summary.Cell>
        );

        rowPercent.push(
          <Table.Summary.Cell index={4 + i} key={total[i].id}>
            <Typography
              key={total[i].id}
              style={{
                fontWeight: 750,
              }}
            >
              {/* {parseFloat(
                (total[i]?.occupied / total[i]?.total_room) * 100
              ).toFixed(1) || 0} */}
              {percentages > 0 ? percentages.toFixed(1) : "0.0"}
            </Typography>
          </Table.Summary.Cell>
        );
      }
    }

    // console.log("New Row Summaries Total => ", rowTotal);
    // console.log("New Row Summaries Avail => ", rowAvail);
    // console.log("New Row Summaries Occ => ", rowOcc);
    // console.log("New Row Summaries Order => ", rowOrder);
    // console.log("New Row Summaries Percentages => ", rowPercent);
    // console.log("New Row Summaries Percentages => ", percentages);

    setSummaries(rowTotal);
    setAvail(rowAvail);
    setOccupancies(rowOcc);
    setOutOfOrder(rowOrder);
    setOffMarket(rowMarket);
    setAverageRate(rowAvrg);
    setPercentages(rowPercent);
  };

  // Fetch Modal
  const dataModal = async (date, roomCatg) => {
    const _date = date;
    const _cat = roomCatg;

    await getReservations({
      category: "category",
      type: "availability-per-day",
      date: _date ? _date : date,
      room_category: _cat ? _cat : roomCatg,
      onAwait: () => {
        // setLoadingModal(true);
      },
      onSuccess: (response) => {
        console.log("Results Room Availability Per Day >> ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          setFetchResult(_response);
          // setLoadingModal(false);
        } else {
          console.log("THIS IS HIT!");
          setFetchResult([]);
        }

        // setOpen(true);
      },
      onReject: (error) => {
        failedFetch(error);
        // setLoadingModal(false);
        // setRoom()
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // GET REFRESH
  const getRefresh = () => {
    is_refresh(true);
    rowSummaries(totalRoomCat);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(false);

    setFetchResult([]);
  };

  // Handle Print
  const componentRef = useRef();

  const handlePrint = () => {
    setIsPrint(true);
  };

  const printOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Room Availability ${moment(
      dateRange?.length > 0 ? dateRange[0] : ""
    ).format("YYYY-MM-DD")} to ${moment(
      dateRange?.length > 0 ? dateRange[1] : ""
    ).format("YYYY-MM-DD")}`,
    onAfterPrint: () => {
      setIsPrint(false);
    },
  });

  // CONSOLE
  // console.log("DATA TABLE => ", data);
  // console.log("DATA TABLE AVAIL => ", dataAvail);
  // console.log("TOTAL CATEGORY > ", length);
  // console.log("Date ", dateRange);
  // console.log("Summaries --> ", summaries);
  // console.log("Total Days --> ", length);

  return (
    <>
      <Row
        className="room-availability-main-table"
        justify="center"
        style={mainBody}
      >
        <Col
          span={24}
          style={onForm == false ? { padding: "30px" } : { padding: 0 }}
        >
          {onForm == false && (
            <Row
              className="row-table-btn"
              gutter={[0, 30]}
              justify="end"
              align="top"
            >
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                className="col-date-available"
              >
                <Typography
                  className="text-date"
                  style={{
                    fontSize: 17,
                    fontWeight: 750,
                    color: "#0BB7AF",
                  }}
                >
                  {`Room Availability from ${moment(
                    dateRange?.length > 0 ? dateRange[0] : ""
                  ).format("YYYY-MM-DD")} to ${moment(
                    dateRange?.length > 0 ? dateRange[1] : ""
                  ).format("YYYY-MM-DD")}`}
                </Typography>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                className="col-refresh-btn"
              >
                <Row justify="end">
                  <Button
                    className="next-btn"
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handlePrint}
                    style={{
                      margin: "0px 15px 30px",
                    }}
                  >
                    {`Download`}
                  </Button>

                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={<SyncOutlined />}
                    onClick={getRefresh}
                    style={{
                      margin: "0px 0px 30px",
                    }}
                  >
                    {`Refresh`}
                  </Button>
                </Row>
              </Col>
            </Row>
          )}

          <Content ref={componentRef} style={{ width: "100%" }}>
            <Col flex={24}>
              {/* <Table
                title={onForm == true ? () => "Room Availability" : () => {}}
                className="room-avail-table"
                name="room-avail-table"
                key="room-avail-table"
                bordered
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                  pageSize: 5,
                }}
                // scroll={{
                //     y: 270,
                // }}
                rowKey={(record) => (record.id ? record.id : record.key)}
              /> */}

              {isPrint ? (
                <>
                  <Typography
                    className="text-date"
                    style={{
                      fontSize: 17,
                      fontWeight: 750,
                      color: "#0BB7AF",
                    }}
                  >
                    {`Room Availability from ${moment(
                      dateRange?.length > 0 ? dateRange[0] : ""
                    ).format("YYYY-MM-DD")} to ${moment(
                      dateRange?.length > 0 ? dateRange[1] : ""
                    ).format("YYYY-MM-DD")}`}
                  </Typography>
                </>
              ) : null}

              <TableContainer />
            </Col>
          </Content>
        </Col>
      </Row>

      <TableBook
        openModal={open}
        closeModal={setOpen}
        dataTable={open == true ? fetchResult : []}
        dataForm={selectedData}
      />
    </>
  );
}

// Import React Components
import React from "react";

// Import Antd Components
import { Grid } from "antd";

const { useBreakpoint } = Grid;

// CODE
export function Breakpoint(props) {
  // Create Global Breakpoint
  const screenSize = useBreakpoint();

  return screenSize;
}

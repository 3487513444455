// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// BASE API
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  notification,
  Checkbox,
  DatePicker,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { DeleteFilled } from "@ant-design/icons";

// Import Notifications
import {
  failedCreateJournal,
  incomplete,
  successCreateJournal,
} from "../../../Reusable/Notification/Notification";
import MasterCurrency from "../../../Reusable/Master/Currency/Currency";
import PurchaseOrderList from "../../../Reusable/Inventory/Receiving/PurchaseOrderList";

// MODAL CONFIRM
const { confirm } = Modal;
const { open } = notification;




// CODE
export default function ModalReceiving(props) {
  // Properties
  const { openModal, closeModal, is_refresh } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [keyCurrent, setKeyCurrent] = useState(1);
  // Journal Table

  // Boolean
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // Modal State
  const [editData, setEditData] = useState(null);
  const [edit, setEdit] = useState(false);

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Row className="action-table-btn" justify="center">
            <Col span={12} className="col-del-btn">
              <Tooltip title="Delete Data" className="del-tooltip">
                <DeleteFilled
                  className="delete-btn"
                  onClick={() => {
                    console.log("ABOUT TO DELETE > ", record);

                    showModalDelete(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#F64E60",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Article Id",
      dataIndex: "account_code",
      key: "account_code",
    },
    {
      title: "Article",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Order Qty",
      dataIndex: "credit",
      key: "credit",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Unit Price",
      dataIndex: "debit",
      key: "debit",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Order Amount",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Receipt Qty",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Receipt Amount",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Total Amount",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Deliv D Unit",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Last Arrival",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Delivery Note",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Notes",
    //   dataIndex: "notes",
    //   key: "notes",
    // },
  ];

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // ON FINISH
  const onFinish = (val) => {
    const _finish = val;
    // setIsLoading(true);

    console.log("ON FINISH: ", _finish);
    handleGenerateJournal(_finish);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Finish Failed: ", val.values);
    incomplete(error);
  };

  // Handle Finish
  const handleFinish = (value) => {
    //  let _debt = 0;
    //  let _credit = 0;
    //
    console.log("SUMM: ", data);
    console.log("ON FINISH VALUE: ", value);

    //  if (data.length > 0) {
    //    let _summaries = data.forEach(({ credit, debit }) => {
    //      // console.log("SUMM: ", credit);
    //      _debt += debit;
    //      _credit += credit;
    //    });
    //  } else {
    //    _debt = 0;
    //    _credit = 0;
    //  }
    //
    //  console.log("DEBT: ", _debt);
    //  console.log("CREDIT: ", _credit);
    //
    //  if (_debt - _credit !== 0) {
    //    journalIsNotBalance(1);
    //  } else if (data.length === 0) {
    //    journalIsNotBalance(2);
    //  } else {
    //    showModalConfirm();
    //  }
  };

  // Handle Cancel
  const handleCancel = () => {
    closeModal(false);
    setOpen(false);
    setData([]);

    setIsLoading(false);

    form.resetFields();
  };

  // Get Financial State
  const getPONumber = (value) => {
    const _val = value;
    console.log("PONumber >>> ", value);

    form.setFieldsValue({
      po_number: _val?.value,
      id_po: _val?.id,
      delivery_date: moment(_val?.dev_date),
    });
  };
  
  // COA GET
  const getCurrency = (val, code, id) => {
    console.log(val, code, id);

    form.setFieldsValue({
      account_code: val,
      id_currency: id,
    });
  };

  // Handle Debit/Credit
  const handleBalance = (val, code) => {
    console.log(val, code);
  };

  // HAndle Genereate Journal
  const handleGenerateJournal = (value) => {
    const _arr = value;
    let _newJournal = [];

    if (_arr.account_balance === 0) {
      _newJournal.push({
        account_name: _arr?.account_name,
        account_code: _arr?.account_code,
        debit: _arr?.amount,
        credit: 0,
        description: _arr?.description,
        notes: _arr?.notes,
        id: keyCurrent,
      });
    } else {
      _newJournal.push({
        account_name: _arr?.account_name,
        account_code: _arr?.account_code,
        debit: 0,
        credit: _arr?.amount,
        description: _arr?.description,
        notes: _arr?.notes,
        id: keyCurrent,
      });
    }

    setKeyCurrent(keyCurrent + 1);

    if (_newJournal.length > 0) {
      console.log("New Journal: ", _newJournal);
      if (data?.length > 0) {
        console.log("Data: ", data);
        setData([...data, ..._newJournal]);
      } else {
        console.log("Data: ", data);
        setData(_newJournal);
      }
    } else {
      setData([]);
    }

    setLoading(false);

    form.resetFields([
      "account_name",
      "account_code",
      "debit",
      "credit",
      "description",
      "notes",
      "amount",
      "account_balance",
    ]);
  };

  // MODAL CONFIRM
  const showModalConfirm = (value) => {
    setIsLoading(true);

    console.log("ON FINISH: ", value);

    confirm({
      className: "modal-confrim",
      title: "Are you sure you wanted to created a Good Receipt?",
      closable: true,
      centered: true,
      okText: "Confirm",
      width: 800,

      onCancel() {
        setIsLoading(false);
      },

      onOk() {
        handleSubmit(value);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "refresh-btn",
        type: "default",
      },
    });
  };

  // MODAL CONFIRM DELETE
  const showModalDelete = (value) => {
    const _content = value;

    confirm({
      className: "modal-confrim",
      title: `Are you sure you wanted to delete Journal with named ${
        _content?.account_name.toUpperCase() || " --- "
      }?`,
      closable: true,
      centered: true,
      okText: "Confirm",
      width: 800,

      onCancel() {
        setIsLoading(false);
      },

      onOk() {
        handleDelete(_content);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "refresh-btn",
        type: "default",
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _submit = value;
    let _array = [];

    console.log("HANDLE SUBMIT: ", _submit);

    //for (let i = 0; i < data?.length; i++) {
    //  _array.push({
    //    id_po: data[i]?.id_po,
    //    order_type: data[i]?.order_type,
    //    id_currency: data[i]?.id_currency,
    //    telefax: data[i]?.telefax,
    //    delivery_date: data[i]?.delivery_date,
    //    description: data[i]?.description,
    //    created_by: user_name,
    //  });
    //}

    // _submit.created_by = user_name;
    // _submit.delivery_date = moment(_submit?.delivery_date).format("YYYY-MM-DD");

    console.log("Array: ", _submit);

    await axios
      .post(
        `${baseurl}/stock-incoming`,
        {
          id_po: _submit?.po_number ? _submit.po_number : null,
          order_type: _submit?.order_type > 0 ? _submit.order_type : null,
          telefax: _submit?.telefax > 0 ? _submit.telefax : null,
          // id_currency: _submit?.id_currency > 0 ? _submit?.id_currency : null,
          id_currency: 1,
          delivery_date: moment(_submit?.delivery_date).format("YYYY-MM-DD"),
          description: _submit?.description,

          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token || ""}` } }
      )
      .then((response) => {
        console.log("Res Create Journal Manuals: ", response);

        successCreateJournal(response);
        is_refresh(true);

        handleCancel();
      })
      .catch((error) => {
        console.log("Failed Create Journal: ", error);

        failedCreateJournal(error);
        setIsLoading(false);
      });
  };

  // HANDLE DELETE
  const handleDelete = (value) => {
    const _submit = value;

    let _filtered = data.filter((value) => {
      return value.id !== _submit.id;
    });

    console.log("AFTER DELETE: ", _filtered);
    setData(_filtered);
  };

  return (
    <>
      <Modal
        className="receiving-modal"
        key="receiving-modal"
        title={
          <Row>
            <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography style={{ marginLeft: 15 }}>
              {`Add Good Receipt`}
            </Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={1000}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="receiving-form"
          key="receiving-form"
          name="receiving-form"
          form={form}
          onFinish={showModalConfirm}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{
            padding: "0px 36px",
          }}
        >
          <Row
            gutter={30}
            className="form-main-row"
            justify="start"
            align="top"
          >
            <Col span={24} className="form-main-col">
              <Row className="balance-row" gutter={30}>
                <Col span={8} className="coa-col">
                <Form.Item
                    label="Order Type"
                    name="order_type"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Select
                      className="order-type-select"
                      key="order-type-select"
                      allowClear
                      placeholder="Select an Option!"
                      onChange={handleBalance}
                      options={[
                        {
                          label: "With PO",
                          value: 1,
                        },
                        {
                          label: "Without PO",
                          value: 2,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="coa-col">
                  <Form.Item
                    label="PO Number"
                    name="po_number"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input PO Number!",
                        placeholder: "Input PO Number",
                      },
                    ]}
                  >
                    {/* <MasterCurrency getCurrency={getCurrency}/> */}
                    {/* <PurchaseOrderList getPurchaseOrderList={getPurchaseOrderList}/> */}
                    <PurchaseOrderList
                      getPONumber={getPONumber}
                      po={null}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="amount-col">
                  <Form.Item
                    label="Invoice"
                    name="invoice"
                    rules={[
                      {
                        required: false,
                        message: "Please, Input an Invoice!",
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  </Col>
              </Row>

              <Row className="balance-row" gutter={30}>
                <Col span={8} className="coa-col">
                  <Form.Item
                    label="Telefax"
                    name="telefax"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input an Amount!",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Input Telefax" style={{width: '100%',}} />
                  </Form.Item>
                </Col>

                <Col span={8} className="coa-col">
                  <Form.Item
                    label="Delivery Date"
                    name="delivery_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <DatePicker placeholder="Delivery Date" style={{width: '100%',}} allowClear disabled/>
                  </Form.Item>
                </Col>

                <Col span={8} className="amount-col">
                  {/*<Form.Item
                    label="Department"
                    name="account_balance"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Select
                      className="balance-select"
                      key="balance-select"
                      allowClear
                      placeholder="Select an Option!"
                      onChange={handleBalance}
                      options={[
                        {
                          label: "Debit",
                          value: 0,
                        },
                        {
                          label: "Credit",
                          value: 1,
                        },
                      ]}
                    />
                  </Form.Item>*/}
                </Col>
              </Row>

              <Row className="balance-row" gutter={30}>
                <Col span={8} className="coa-col">
                  {/*<Form.Item
                    label="Purchase Order"
                    name="account_balance"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Select
                      className="balance-select"
                      key="balance-select"
                      allowClear
                      placeholder="Select an Option!"
                      onChange={handleBalance}
                      options={[
                        {
                          label: "Debit",
                          value: 0,
                        },
                        {
                          label: "Credit",
                          value: 1,
                        },
                      ]}
                    />
                    </Form.Item>*/}
                </Col>

                <Col span={8} className="coa-col">
                  {/* <Form.Item
                    label="Currency"
                    name="id_currency"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <MasterCurrency getCurrency={getCurrency}/>
                  </Form.Item> */}
                </Col>

                <Col span={8} className="coa-col">
                  {/*<Form.Item
                    label="Partial Delivery"
                    name="account_balance"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Checkbox>Partial Delivery</Checkbox>
                  </Form.Item>*/}
                </Col>
              </Row>

              <Row className="balance-row" gutter={30}>
                <Col span={8} className="coa-col">
                  {/*<Form.Item
                    label="Order Date"
                    name="account_balance"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <DatePicker/>
                  </Form.Item>*/}
                </Col>

                <Col span={8} className="coa-col">
                {/*<Form.Item
                  label="Supplier"
                  name="account_balance"
                  rules={[
                    {
                      required: true,
                      message: "Please, Choose an Options!",
                    },
                  ]}
                >
                  <Select
                    className="balance-select"
                    key="balance-select"
                    allowClear
                    placeholder="Select an Option!"
                    onChange={handleBalance}
                    options={[
                      {
                        label: "Debit",
                        value: 0,
                      },
                      {
                        label: "Credit",
                        value: 1,
                      },
                    ]}
                  />
                </Form.Item>*/}
                </Col>

                <Col span={8} className="coa-col">
                  {/*<Form.Item
                    label="Released"
                    name="account_balance"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <DatePicker/>
                  </Form.Item>*/}
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row className="descr-row" gutter={30}>
                <Col span={16} className="coa-col">
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      placeholder="Description"
                      allowClear
                      showCount
                      rows={3}
                      maxLength={120}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              /> */}

              {/*<Row className="trans-row" gutter={30}>
                <Col span={16} className="coa-col">
                  <Form.Item label="Transaction Notes" name="notes" hidden>
                    <Input.TextArea
                      placeholder="Transaction Notes"
                      allowClear
                      showCount
                      rows={3}
                      maxLength={120}
                    />
                  </Form.Item>
                </Col>
            </Row>*/}

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              {/*<Row className="btn-row" justify="end" align="top">
                <Button className="next-btn" type="primary" htmlType="submit">
                  {`Add Article`}
                </Button>
              </Row>*/}

              <Divider
                className="form-divider"
                style={{ margin: "10px 0px", background: "#EBEDF3" }}
              />

              <Row className="table-row" align="top">
                <Col span={24} className="table-col">
                  <Form.Item label="Table Details" name="table_details">
                    <Table
                      className="create-table"
                      key="create-journal-tables"
                      bordered
                      dataSource={data}
                      columns={columns}
                      loading={loading}
                      size="middle"
                      pagination={{
                        defaultPageSize: 5,
                        pageSizeOptions: [10, 20, 50, 100],
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                          `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      }}
                      scroll={{
                        x: true,
                      }}
                      rowKey={(record) => {
                        return record.id;
                      }}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "odd" : "even";
                      }}
                    />
                  </Form.Item>
                </Col>
                    </Row>

              <Row className="btn-row" justify="end" align="top">
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  // onClick={handleFinish}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  onClick={handleCancel}
                >{`Cancel`}</Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Tooltip,
  Modal,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

import { failedFetch } from "../../../Reusable/Notification/Notification";
// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// Table's Column
const columns = [
  {
    title: "Action",
    dataIndex: "action",
    width: 150,
    fixed: "left",
    render: (_, record) => {
      return (
        <>
          <Tooltip title="Send Message" className="message-popover">
            <Button
              type="primary"
              onClick={() => {
                handleMessage(record);
              }}
              style={{
                backgroundColor: "#13c2c2",
                borderColor: "#13c2c2",
              }}
            >
              {`Send Message`}
            </Button>
          </Tooltip>
        </>
      );
    },
  },
  {
    title: "Full Name",
    dataIndex: "full_name",
    key: "full_name",
  },
  {
    title: "Birthdate",
    dataIndex: "birthdate",
    key: "birthdate",
    render: (date, record) => {
      // console.log(record);
      return moment(date).format("DD MMM YYYY");
    },
  },
  {
    title: "Mobile Phone",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
];

export const handleMessage = (value) => {
  const dataGuest = value;

  console.log("Data Guest => ", dataGuest);

  confirm({
    title: `Send Birthday Message`,
    icon: <QuestionCircleOutlined />,
    content: `Send Birthday message to ${dataGuest?.full_name.toUpperCase()}?`,
    okText: "Yes",
    okType: "primary",
    cancelText: "No",
    centered: true,
    width: 800,

    onOk() {
      console.log("OK");
    },
    onCancel() {
      console.log("Cancel");
    },

    okButtonProps: {
      className: "submit-btn",
      type: "primary",
    },

    cancelButtonProps: {
      className: "cancel-btn",
      type: "default",
    },
  });
};

// CODE
export default function BirthdayTable({ is_search, searchKey }) {
  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  const today = new Date(Date.now());

  // console.log("DATE NOW => ", moment(today).format("MM-DD"));

  // Fetch Data
  const fetchData = () => {
    setLoading(true);

    getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Birthday => ", response);

        let filtered = response.data.msg.filter((val) => {
          if (
            moment(val.birthdate).format("MM-DD") ==
            moment(today).format("MM-DD")
          ) {
            return val;
          }
        });

        setData(filtered);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey;
    // console.log("Type = ", query);

    var updatedList = [...data];
    // console.log("Update List = ", updatedList);

    updatedList = updatedList.filter((item) => {
      // console.log("ITEM => ", item)
      return item.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    setData(updatedList);
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="birthday-guest-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} style={{ padding: "30px" }}>
          <Row justify="end">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="birthday-table"
              name="birthday-table"
              key="birthday-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Button, Card, Col, Row, Space, Typography } from "antd";

// Import Icons
import { EllipsisOutlined } from "@ant-design/icons";
import { GiExitDoor } from "react-icons/gi";

// Import Page Components
import RoomQueueTable from "../../Table/HouseKeep/RoomMonitor/Table";
import { failedFetch } from "../../Reusable/Notification/Notification";

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 35,
  fontWeight: 500,
  textAlign: "center",
};

const styleText1 = {
  color: "#b3b3b3",
  fontSize: 18,
  fontWeight: 500,
  textAlign: "center",
  padding: "10px 0px 0px",
};

const styleText2 = {
  color: "#EE9D01",
  fontSize: 14,
  fontWeight: 500,
  textAlign: "center",
};

const cardItemStyle = {
  margin: "-60px 0px 0px 0px",
  borderRadius: 12,
  padding: 10,
  paddingLeft: 20,
  backgroundColor: "#FFFFFF",
  boxShadow: "5px 5px 5px #d9d9d9",
  width: "100%",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  minHeight: 200,
};

const cardBodyStyle = {
  display: "flex",
  justifyContent: "space-evenly",
};

export default function RoomOccupancyOverview() {
  // CONTEXT
  const { getHouseKeepingAct } = useContext(MainContext);

  let time = moment().format("dddd HH:mm a, Do MMMM YYYY");

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Loads
  const [loading, setLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await getHouseKeepingAct({
      activity: "housekeeping",
      type: "room-status-dashboard",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];

          setData(_res);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  // Handle Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
      time = moment().format("dddd HH:mm a, Do MMMM YYYY");
    }
  };

  return (
    <Card
      title={
        <>
          <Row gutter={30} style={{ minHeight: 22, padding: "0px 30px 50px" }}>
            <Col span={24}>
              <Typography
                style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
              >
                {`Room Occupancy Overview`}
              </Typography>
            </Col>
          </Row>
        </>
      }
      className="card-room-occ"
      headStyle={{
        background: `var(--purple-gradient, linear-gradient(262deg, #61045F -60.55%, #AA076B 105.3%))`,
        borderRadius: "12px 12px 0px 0px",
      }}
      bodyStyle={{
        backgroundColor: "#ffffff",
        borderRadius: 12,
      }}
      style={{
        borderRadius: 12,
        margin: "0px 30px 1px 0px",
      }}
    >
      <Row gutter={[20, 80]} className="row-card-contents">
        <Col
          xxl={{ span: 12 }}
          className="col-card-contents"
          style={{ width: "100%" }}
        >
          <Card
            bordered={false}
            className="card-contents"
            style={{
              margin: "-60px 0px 0px 0px",
              borderRadius: 12,
              backgroundColor: "#FFFFFF",
              boxShadow: "5px 5px 5px #d9d9d9",
              width: "100%",
              // height: 120,
            }}
          >
            <Row className="row-room-queue" gutter={[20, 20]}>
              <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                <Typography style={styleText1}>{`Room Queue`}</Typography>
              </Col>

              <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                <Row justify="end">
                  <Typography style={styleText2}>{time}</Typography>
                </Row>

                <Row justify="end">
                  <Typography style={styleText2}>{`Off Market : ${
                    data?.oos_unit || 0
                  }`}</Typography>
                </Row>
              </Col>

              <Col span={24}>
                <RoomQueueTable is_refresh={handleRefresh} />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xxl={{ span: 12 }} style={{ width: "100%" }}>
          <Row
            className="row-card-room-occ"
            gutter={[20, 80]}
            justify="space-evenly"
          >
            <Col
              className="col-occupied-rooms"
              lg={{ span: 8 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <Card
                className="card-occupied-rooms"
                bordered={false}
                bodyStyle={cardBodyStyle}
                style={cardItemStyle}
              >
                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    // minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.occ_unit || 0}
                  </Typography>
                  <Typography style={styleText2}>Rooms</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    // minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.occ_person || 0}
                  </Typography>
                  <Typography style={styleText2}>Persons</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    // minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.occ_percentage || 0}
                  </Typography>
                  <Typography style={styleText2}>%</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    width: "100%",
                  }}
                >
                  <Typography style={styleText1}>{`Occupied Room`}</Typography>
                </Card.Grid>
              </Card>
            </Col>

            <Col
              className="col-estimated-rooms"
              lg={{ span: 8 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <Card
                className="card-estimated-rooms"
                bordered={false}
                style={cardItemStyle}
                bodyStyle={cardBodyStyle}
              >
                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.occ_estimated_unit || 0}
                  </Typography>
                  <Typography style={styleText2}>Rooms</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.occ_estimated_person || 0}
                  </Typography>
                  <Typography style={styleText2}>Persons</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.occ_estimated_percentage || 0}
                  </Typography>
                  <Typography style={styleText2}>%</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={styleText1}
                  >{`Estimated Occupied`}</Typography>
                </Card.Grid>
              </Card>
            </Col>

            <Col
              className="col-complimentary-rooms"
              lg={{ span: 8 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <Card
                className="card-complimentary-rooms"
                bordered={false}
                style={cardItemStyle}
                bodyStyle={cardBodyStyle}
              >
                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.complimentary_unit || 0}
                  </Typography>
                  <Typography style={styleText2}>Rooms</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.complimentary_person || 0}
                  </Typography>
                  <Typography style={styleText2}>Persons</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.complimentary_percentage || 0}
                  </Typography>
                  <Typography style={styleText2}>%</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    width: "100%",
                  }}
                >
                  <Typography style={styleText1}>Complimentary Room</Typography>
                </Card.Grid>
              </Card>
            </Col>

            <Col
              className="col-house-use-rooms"
              lg={{ span: 8 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <Card
                className="card-house-use-rooms"
                bordered={false}
                style={cardItemStyle}
                bodyStyle={cardBodyStyle}
              >
                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.house_uses_unit || 0}
                  </Typography>
                  <Typography style={styleText2}>Rooms</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.house_uses_person || 0}
                  </Typography>
                  <Typography style={styleText2}>Persons</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.house_uses_percentage || 0}
                  </Typography>
                  <Typography style={styleText2}>%</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    width: "100%",
                  }}
                >
                  <Typography style={styleText1}>{`House Use Room`}</Typography>
                </Card.Grid>
              </Card>
            </Col>

            <Col
              className="col-ooo-rooms"
              lg={{ span: 8 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <Card
                className="card-ooo-rooms"
                bordered={false}
                style={cardItemStyle}
                bodyStyle={cardBodyStyle}
              >
                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.ooo_unit || 0}
                  </Typography>
                  <Typography style={styleText2}>Rooms</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.ooo_percentage || 0}
                  </Typography>
                  <Typography style={styleText2}>%</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={styleText1}
                  >{`Out of Orders Room`}</Typography>
                </Card.Grid>
              </Card>
            </Col>

            <Col
              className="col-inactive-rooms"
              lg={{ span: 8 }}
              md={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <Card
                className="card-inactive-rooms"
                bordered={false}
                style={cardItemStyle}
                bodyStyle={cardBodyStyle}
              >
                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.oos_unit || 0}
                  </Typography>
                  <Typography style={styleText2}>Rooms</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    minWidth: 50,
                  }}
                >
                  <Typography style={styleNumber}>
                    {data?.oos_percentage || 0}
                  </Typography>
                  <Typography style={styleText2}>%</Typography>
                </Card.Grid>

                <Card.Grid
                  style={{
                    padding: 0,
                    boxShadow: "none",
                    width: "100%",
                  }}
                >
                  <Typography style={styleText1}>Inactive Room</Typography>
                </Card.Grid>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

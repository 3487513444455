import React from "react";

// CODE
export function Encoder(token) {
  // const { token, params } = props;

  const data_token = token;

  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  const result = setJwtToken(header, data_token);

  // console.log("Results: ", result);

  return result;
}

const setJwtToken = (headers, payloads) => {
  const signatureKey = process.env.REACT_APP_SECRET_KEYS;

  // console.log("Data header: ", headers);
  console.log("Data payload: ", payloads);

  const base64Encode = (str) => {
    const utf8str = decodeURI(encodeURI(str));
    const b64 =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
    const len = str?.length;
    let dst = "";
    let i;

    for (i = 0; i <= len - 3; i += 3) {
      dst += b64.charAt(utf8str.charCodeAt(i) >>> 2);
      dst += b64.charAt(
        ((utf8str.charCodeAt(i) & 3) << 4) | (utf8str.charCodeAt(i + 1) >>> 4)
      );
      dst += b64.charAt(
        ((utf8str.charCodeAt(i + 1) & 15) << 2) |
          (utf8str.charCodeAt(i + 2) >>> 6)
      );
      dst += b64.charAt(utf8str.charCodeAt(i + 2) & 63);
    }

    if (len % 3 == 2) {
      dst += b64.charAt(utf8str.charCodeAt(i) >>> 2);
      dst += b64.charAt(
        ((utf8str.charCodeAt(i) & 3) << 4) | (utf8str.charCodeAt(i + 1) >>> 4)
      );
      dst += b64.charAt((utf8str.charCodeAt(i + 1) & 15) << 2);
    } else if (len % 3 == 1) {
      dst += b64.charAt(utf8str.charCodeAt(i) >>> 2);
      dst += b64.charAt((utf8str.charCodeAt(i) & 3) << 4);
    }

    return dst;
  };

  const header = JSON.stringify(headers);
  const payload = JSON.stringify(payloads);
  const signature = JSON.stringify(signatureKey);

  const token = `${base64Encode(header)}.${base64Encode(
    payload
  )}.${base64Encode(signature)}`;

  // const encodedSignature = btoa(signature);

  // console.log(encodedSignature);
  // console.log(token);

  return token;
};

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment, { invalid } from "moment";

// Context
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Tag,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Page Components
import HistoryGuestPopover from "../../../Popover/HistoryGuest/Popover";
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// CODE
export default function HistoryGuestTable(props) {
  // PROPS
  const { is_search, searchKey, searchDate } = props;

  // CONTEXT
  const { getHistoryGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await getHistoryGuest({
      guest: "guest",
      type: "checkout-stay",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response History => ", response);
        // let filtered = response.data.msg.filter((filt) => {
        //   if (filt.is_deleted == false || filt.is_deleted == null) {
        //     return filt;
        //   }
        // });

        setData(response?.data?.msg?.length > 0 ? response.data.msg : []);
        setBulks(response?.data?.msg?.length > 0 ? response.data.msg : []);

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Use Effect
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, searchDate]);

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24} className="col-menu">
                <Tooltip title="Option" className="option-tooltip">
                  <HistoryGuestPopover
                    details={record}
                    is_refresh={setRefresh}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      width: 150,
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
      render: (_, record) => {
        if (record.group == 2) {
          return "";
        } else {
          return record.reservation_id;
        }
      },
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      width: 200,
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Breakfast",
      dataIndex: "is_abf",
      key: "is_abf",
      align: "center",
      render: (abf) => {
        return (
          <Tag key={"abf-keytag"} color={abf === true ? `green` : `geekblue`}>
            {abf === true ? `RB` : `RO`}
          </Tag>
        );
      },
      // width: 150,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            className="guest-tag"
            key={gst}
            color={clr}
            style={{ fontSize: 18, fontWeight: 600 }}
          >
            {gst}
          </Tag>
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      // width: 100,
      sortDirections: ["descend", "ascend"],
      render: (arrive, record) => {
        return moment(arrive).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Check-In Time",
      dataIndex: "checkin_date",
      key: "checkin_date",
      width: 200,
      sortDirections: ["descend", "ascend"],
      render: (checkin, record) => {
        return moment(LocalizationDate(checkin)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
      sorter: (a, b) => moment(a.checkin_date) - moment(b.checkin_date),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      // width: 100,
      sortDirections: ["descend", "ascend"],
      render: (arrive, record) => {
        return moment(arrive).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Check-Out Time",
      dataIndex: "checkout_date",
      key: "checkout_date",
      width: 200,
      sortDirections: ["descend", "ascend"],
      render: (checkout, record) => {
        return moment(LocalizationDate(checkout)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
      sorter: (a, b) => moment(a.checkout_date) - moment(b.checkout_date),
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.adult.localeCompare(b.adult),
    },
    // {
    //   title: "Child",
    //   dataIndex: "child",
    //   key: "child",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.child.localeCompare(b.child),
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "infant",
    //   key: "infant",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.infant.localeCompare(b.infant),
    // },
    {
      title: "Room Rate",
      dataIndex: "rate_code",
      key: "rate_code",
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
  ];

  // HANDLE SEARCH
  const handleSearch = () => {
    setLoading(true);

    const query = searchKey ? searchKey.toLowerCase() : null;
    const depart = searchDate ? moment(searchDate).format("YYYY-MM-DD") : null;

    console.log("Type = ", query);
    console.log("Departure date = ", searchDate, " + ", depart);

    // var updatedList = [...data];
    // console.log("Update List = ", null + 1);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item)
        let _name = item?.guest_name.toLowerCase();

        return (
          item.guest_category != "INCOGNITO" && _name.indexOf(query) !== -1
        );

        // else if (!query && depart) {
        //   console.log("Case 2");

        //   return moment(dep).isSame(depart);
        // } else if (query && depart) {
        //   console.log("Case 3");

        //   return (
        //     item.guest_category != "INCOGNITO" &&
        //     _name.toLowerCase().indexOf(query) !== -1 &&
        //     moment(dep).isSame(depart)
        //   );
        // } else {
        //   // console.log("Case 4");

        //   return item;
        // }
      });

      console.log("FILTERED => ", updatedList);

      // Trigger render with updated values
      if (depart != "Invalid date" && depart != null) {
        handleDeparture(updatedList?.length > 0 ? updatedList : bulks, depart);
      } else {
        setData(updatedList);
        setLoading(false);
      }
    } else {
      setData([]);
      setLoading(false);
    }
  };

  // FILTER BY DATE
  const handleDeparture = (value, date) => {
    let _newData = value.filter((items) => {
      const dep = moment(items?.departure).format("YYYY-MM-DD");

      return moment(dep).isSame(date);
    });

    console.log("Date: ", _newData);

    setData(_newData);
    setLoading(false);
  };

  return (
    <>
      <Row
        className="history-guest-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row justify="end" className="row-refresh-btn">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="history-guest-table"
              name="history-guest-table"
              key="history-guest-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              scroll={{
                x: 2500,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

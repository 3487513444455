// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";
import { CgOpenCollective } from "react-icons/cg";

// CONST
const { confirm } = Modal;

// CODE
export default function MainSegmentTable(props) {
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getMasterSegment } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [editData, setEditData] = useState(null);
  const [dynamic, setDynamic] = useState(false);
  const [permission, setPermission] = useState(false);
  // Is Deleted
  const [isDeleted, setIsDeleted] = useState(true);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterSegment({
      segment: "main-segment",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Master Segments => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          let filtered = _response.filter((filt) => {
            if (filt.isDeletedeted == false || filt.isDeletedeted == null) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);
  };

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);

                      setFormFields(record);
                      // form.setFieldsValue({ ...record });
                      setIsDeleted(false);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      setIsDeleted(true);

                      showModalConfirmation(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#F64E60",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Segment's Name",
      dataIndex: "main_segment_name",
      key: "main_segment_name",
      //   width: 200,
    },
    {
      title: "Segment's Code",
      dataIndex: "main_segment_code",
      key: "main_segment_code",
    },
    {
      title: "Dynamic Rate",
      dataIndex: "is_dynamic",
      key: "is_dynamic",
      align: "center",
      render: (text) => {
        return (
          <Checkbox justify="center" checked={text ? true : false} disabled />
        );
      },
    },
    {
      title: "Permission",
      dataIndex: "is_authorized",
      key: "is_authorized",
      align: "center",
      render: (text) => {
        return <Checkbox checked={text ? true : false} disabled />;
      },
    },
    // {
    //   title: "Segment's Type",
    //   dataIndex: "segment_type",
    //   key: "segment_type",
    // },
    {
      title: "Description",
      dataIndex: "main_segment_description",
      key: "main_segment_description",
      width: 400,
    },
  ];

  // Set Form Fields
  const setFormFields = (value) => {
    const _record = value;
    setDynamic(_record?.is_dynamic);
    setPermission(_record?.is_authorized);

    form.setFieldsValue({
      main_segment_id: _record?.main_segment_id,
      main_segment_code: _record?.main_segment_code,
      main_segment_name: _record?.main_segment_name,
      main_segment_description: _record?.main_segment_description,
      segment_type: _record?.segment_type,

      created_by: _record?.created_by,
      created_date: _record?.created_date,
      updated_by: _record?.updated_by,
      updated_date: _record?.updated_date,
    });
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setIsLoading(false);

    setEditData(null);
    setIsDeleted(true);
    setDynamic(false);
    setPermission(false);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirmation = async (record) => {
    let del = record;

    confirm({
      className: "delete-segment-confirm",
      title:
        isDeleted == true
          ? `Are you sure want to delete Main Segment named ${del?.main_segment_name?.toUpperCase()} from the table?`
          : `Are you sure want to ${
              edit == true ? "Update" : "Add a New"
            } Main Segment named ${del?.main_segment_name?.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: isDeleted == true ? "Delete" : "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDeleted == true ? handleDelete(del) : handleSubmit(del);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    setIsLoading(true);
    // console.log(value);
    const content = value;

    showModalConfirmation(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    console.log("ON OK ==> ", contentsubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/master/main-segment`,
          {
            // main_segment_id: contentsubmit?.main_segment_id || null,
            main_segment_code: contentsubmit?.main_segment_code || null,
            main_segment_name: contentsubmit?.main_segment_name || null,
            main_segment_description:
              contentsubmit?.main_segment_description || null,
            is_dynamic: dynamic ? true : false,
            is_authorized: permission ? true : false,
            // segment_type: contentsubmit?.segment_type || null,

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("ADD Segment = ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Segments",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("ADD Segment = ", error);

          masterFailedNotification(error, { method: 0, source: "Segments" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/main-segment`,
          {
            main_segment_id:
              contentsubmit?.main_segment_id || editData?.main_segment_id,
            main_segment_code:
              contentsubmit?.main_segment_code || editData?.main_segment_code,
            main_segment_name:
              contentsubmit?.main_segment_name || editData?.main_segment_name,
            main_segment_description:
              contentsubmit?.main_segment_description ||
              editData?.main_segment_description,
            segment_type: contentsubmit?.segment_type || editData?.segment_type,
            is_dynamic: dynamic ? true : false,
            is_authorized: permission ? true : false,
            created_by: contentsubmit?.created_by || editData?.created_by,

            id: editData?.id || null,
            updated_by: user_name,
            // updated_by: "DEVELOPER",
            // updated_date: contentsubmit.modified_date,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("UPDATE Segment = ", response);
          masterSuccessNotification(response, {
            method: 1,
            source: "Segments",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("UPDATE Segment = ", error);

          masterFailedNotification(error, { method: 1, source: "Segments" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record?.id;

    console.log(contentdelete);

    let obj = {
      ["id"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/master/main-segment`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Segment = ", response);

        masterSuccessNotification(response, { method: 2, source: "Segments" });
      })
      .catch((error) => {
        console.log("DEL Segment = ", error);

        masterFailedNotification(error, { method: 2, source: "Segments" });
      })
      .finally(() => {
        fetchData();
      });
  };

  // Handle Export
  const handleExport = () => {
    downloadExcel(data, "Segment List");
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Search Key = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.main_segment_name.toLowerCase();
        // console.log("ITEM => ", item);

        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  const onChangeDynamic = (value) => {
    console.log("onChangeDynamic value", value.target.checked);

    setDynamic(value.target.checked);

    if (value.target.checked == false) {
      setPermission(false);
    }
  };

  const onChangePermission = (value) => {
    console.log("onChangePermission value", value.target.checked);
    setPermission(value.target.checked);
  };

  return (
    <>
      <Row
        className="main-segment-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-table" style={{ padding: "30px" }}>
          <Row className="row-modal-btn" align="top" gutter={[0, 15]}>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
              className="col-exp-btn"
            >
              <Row justify="start" className="row-exp-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
              className="col-modal-btn"
            >
              <Row justify="end" className="row-open-btn" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Main Segment`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="segment-table"
              key="segment-table"
              name="segment-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-segment-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Main Segments`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Main Segments`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="segment-modal-form"
          className="segment-modal-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-segment-pay-type">
            <Col span={16}>
              <Form.Item
                label="Segment's Name"
                name="main_segment_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Segment's Name!",
                  },
                ]}
                style={{ width: "100%", marginRight: 30 }}
              >
                <Input placeholder="Segment's Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Main Segment's Code"
                name="main_segment_code"
                rules={[
                  {
                    required: true,
                    message: "Please, input a Segment's Code!",
                  },
                ]}
              >
                <Input
                  placeholder="Segment's Code"
                  style={{ marginRight: 30, width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: 0, background: "#EBEDF3" }}
          />

          {/* 
          <Row gutter={30} className="row-segment-id">
            <Col span={8}>
              <Form.Item
                label="Main Segment's ID"
                name="main_segment_id"
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Payment Type ID"
                  style={{ marginRight: 30, width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Segment's Type"
                rules={[
                  {
                    required: true,
                    message: "Please, Select a Segment's Code!",
                  },
                ]}
                name="segment_type"
                style={{ marginRight: 30, width: "100%" }}
              >
                <Select
                  className="segment's-type-select"
                  allowClear
                  options={[
                    {
                      label: "FIT",
                      value: "FIT",
                    },
                    {
                      label: "GIT",
                      value: "GIT",
                    },
                  ]}
                />
              </Form.Item>
            </Col> 
          </Row>
            */}
          <Row
            className="row-desc-segment"
            gutter={30}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            align="middle"
          >
            <Col span={16}>
              <Form.Item
                label="Description"
                name="main_segment_description"
                rules={[
                  {
                    required: true,
                    message: "Please, write's a Description!",
                  },
                ]}
                // style={{ width: "100%", marginRight: 30 }}
              >
                <Input.TextArea
                  allowClear
                  showCount
                  maxLength={200}
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                // label="Description"
                name="dynamic"
                style={{ paddingTop: 20 }}
              >
                <Checkbox onChange={onChangeDynamic} checked={dynamic}>
                  Dynamic Rate Code
                </Checkbox>
              </Form.Item>
              <Form.Item
                // label="Description"
                name="permission"
              >
                <Checkbox
                  onChange={onChangePermission}
                  checked={permission}
                  disabled={dynamic ? false : true}
                >
                  Permission Needed
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "0px 0px 5px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-segment">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-segment">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row className="segment-modal-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              loading={isLoading}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

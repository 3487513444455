// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterPosting({ posting_type, getPostType }) {
  // CONTEXT
  const { getPostingType } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [postingType, setPostingType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    // navigate("/master/department");
  };

  // FETCH GUEST DATA
  const fetchData = () => {
    getPostingType({
      postingType: "posting-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Posting Type => ", response);

        let filterData = response.data.msg.filter((val) => {
          if (val.is_deleted == false || val.is_deleted == null) return val;
        });

        let filtered = filterData.map((postType) => ({
          key: postType.id,
          value: postType.posting_type,
          label: postType.posting_type,
        }));

        // console.log("Posting Type Filter == ", filtered);
        setOptionValue(filtered);
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (posting_type && !cleanUp) {
      setPostingType(posting_type);
    } else {
      setPostingType(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [posting_type]);

  // Handle Clear
  const handleClear = () => {
    setPostingType(null);
  };

  return (
    <>
      <Select
        className="master-post-type"
        name="master-post-type"
        key={optionValue}
        allowClear
        value={postingType}
        placeholder="Select Posting Type!"
        onChange={(e, allKey) => {
          setPostingType(e);
          // department_name = e;
          console.log(e);

          console.log("INI Posting Type ", allKey);

          // setCode(getDeptCode[0].code);

          getPostType(allKey?.value);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
        onClear={handleClear}
        style={{ width: "100%" }}
        // filterOption={false}
      />
    </>
  );
}

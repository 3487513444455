// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  InputNumber,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { SearchOutlined } from "@ant-design/icons";
import { BiAbacus } from "react-icons/bi";
import {
  MdAccountBalanceWallet,
  MdPublishedWithChanges,
  MdScreenShare,
} from "react-icons/md";
import { BsCoin } from "react-icons/bs";
import { HiMiniPresentationChartBar } from "react-icons/hi2";
import { HiPresentationChartBar } from "react-icons/hi";
import { GiOpenBook, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import {
  TbFileDollar,
  TbCurrencyDollar,
  TbCurrencyDollarAustralian,
  TbCurrencyDollarCanadian,
  TbCurrencyDollarSingapore,
} from "react-icons/tb";
import { FaBarsStaggered } from "react-icons/fa6";
import { RiFileChartFill } from "react-icons/ri";
import { FaBalanceScale, FaFileInvoiceDollar } from "react-icons/fa";

// Import Global Styles
import {
  cardHeader,
  cardBody,
  mainBody,
  globalText,
  gridStyle,
  globalIcon,
  linkStyle,
} from "../../../../Style/Global/Global";

// CODE
export default function MonthlyReportAccounting() {
  // STATE MANAGEMENT

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <FaFileInvoiceDollar
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Monthly Accounting Report`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("balance-sheet-report")}
                    // type="primary"
                    icon={<FaBalanceScale style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Balance Sheet Report`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("trial-balance-report")}
                    // type="primary"
                    icon={<BiAbacus style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Trial Balance`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("profit-n-loss-report")}
                    // type="primary"
                    icon={<MdPublishedWithChanges style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Profit and Loss Report`}</Typography>
                  </Button>
                </Card>
              </Col>

              {/* <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("balance-sheet-history")}
                    // type="primary"
                    icon={<FaBalanceScale style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Balance Sheet History`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("trial-balance-history")}
                    // type="primary"
                    icon={<BiAbacus style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Trial Balance History`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col
                className="section-col"
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
              >
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("profit-n-loss-history")}
                    // type="primary"
                    icon={<MdPublishedWithChanges style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Profit and Loss History`}</Typography>
                  </Button>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Space, Table, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

export default function PriceDiscrepancyTable(props) {
  // PROPS
  const { id_warehouse } = props;

  //CONTEXT
  const { getInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [datePicked, setDatePicked] = useState(null);
  const [types, setTypes] = useState(0);
  const [total, setTotal] = useState(0);
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (id_warehouse && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setBulks([]);
      setTotal(0);
    }

    return () => {
      cleanUp = true;
    };
  }, [id_warehouse]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    await getInventoryReport({
      type: `price-discrepancy/${id_warehouse}`,
      // bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
            };
          });

          console.log("DATA FILTER: ", filter);

          const sumTotal =
            filter.length > 0
              ? filter.reduce((prev, curr) => {
                  return prev + curr.total_price;
                }, 0)
              : 0;

          setData(filter);
          setBulks(filter);
          setTotal(sumTotal);
        } else {
          setData([]);
          setTotal(0);
          setBulks([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Price Discrepancy List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // HANDLE FILTER DATE
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const _orderDate = moment(val.received_date).format("YYYY-MM-DD");

      return moment(_orderDate).isBetween(_start, _end, undefined, "[]");
    });

    // update new total
    const updateTotal =
      _filtered.length > 0
        ? _filtered.reduce((prev, curr) => {
            return prev + curr.total_price;
          }, 0)
        : 0;

    console.log("Filtered Date Range: ", _filtered);
    setData(_filtered);
    setTotal(updateTotal);
  };

  // Params
  const currency = CurrencySymbols().code;

  // TABLE COLUMNS
  const columns = [
    {
      title: "PO Number",
      dataIndex: "po_number",
      key: "po_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.po_number.localeCompare(b.po_number),
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
    },
    {
      title: `Ordered Price (${currency})`,
      dataIndex: "ordered_price",
      key: "ordered_price",
      render: (price, record) => {
        return price > 0 ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Received Price (${currency})`,
      dataIndex: "received_price",
      key: "received_price",
      render: (price, record) => {
        return price > 0 ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Total Price (${currency})`,
      dataIndex: "total_price",
      key: "total_price",
      render: (total, record) => {
        return total > 0 ? `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Discrepancy (${currency})`,
      dataIndex: "discrepancy",
      key: "discrepancy",
      render: (disc, record) => {
        return disc > 0 ? `${disc}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (date, record) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Received Date",
      dataIndex: "received_date",
      key: "received_date",
      render: (date, record) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
  ];

  return (
    <>
      <Row
        className="price-discrepancy-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Pick Date (Received Date)`}</Typography>

                  <DatePicker.RangePicker
                    className="date-btn"
                    allowClear
                    onChange={handleDate}
                    style={{
                      width: 250,
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => id_warehouse && fetchData()}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1500,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={5}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

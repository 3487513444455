// REACT COMPONENTS
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { ExclamationCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import confirm from "antd/lib/modal/confirm";

// GLOBAL STYLES
import { textForm } from "../../../Style/Global/Global";

// REACT ICONS
import { BiSolidFilePdf } from "react-icons/bi";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// PAGE COMPONENTS
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import { useReactToPrint } from "react-to-print";
import { PreTotalPriceFunction } from "../../Reusable/Functions/TotalPrice/PreTotalPrice";
import { type } from "@testing-library/user-event/dist/type";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../API/Config/Api";
import { token } from "../../../API/Global/Payload";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";

export default function MasterFolioForm(props) {
  // PROPS
  const {
    openModal,
    closeModal,
    billData,
    guestData,
    billReceiver,
    typeData,
    is_refresh,
  } = props;

  // Use Ref
  const componentRef = useRef();

  // Currency
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [guest, setGuest] = useState({
    guest_name: "",
    reservation_id: "",
    payment_details: "",
    payment_type: "",
    payment_status: "",
    start_date: "",
    end_date: "",
  });
  const [totalPrice, setTotalPrice] = useState([
    {
      nett: 0,
      service: 0,
      tax: 0,
      gross: 0,
    },
  ]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(true);

  // navigate
  const navigate = useNavigate();

  // USE FORM
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("Bill Receiver ->> ", billReceiver);
  }, [billReceiver]);

  // USE EFFECT
  useEffect(() => {
    if (openModal == true) {
      setOpen(openModal);
    }

    if (billData) {
      console.log("Bill Data Master Folio ->> ", billData);
      const handletotalPrice = billData.map((item) => {
        return {
          ...item,
          total_price: item.price * item.quantity,
        };
      });
      setData(handletotalPrice);
      handleSummaryTotal(handletotalPrice);
    }

    setIsLoading(false);
  }, [openModal, billData]);

  // USE EFFECT CHECK TYPE & GUEST DATA
  useEffect(() => {
    let cleanUp = false;

    if (typeData === 2 && guestData && !cleanUp) {
      // if typeData == 2 (JOIN BILL) -> Merge Guest
      const mergeGuest = handleGuestData(guestData);
      setGuest(mergeGuest);
    } else if (guestData) {
      setGuest(guestData);
    }

    return () => {
      cleanUp = true;
    };
  }, [guestData, typeData]);

  // HANDLE GUEST DATA FOR JOIN BILL
  const handleGuestData = (arr) => {
    const _guest = arr.length > 0 ? arr : [arr];

    return _guest?.reduce(
      (acc, current) => {
        // Iteration for each property in current object
        for (let key in current) {
          if (key === "start_date" || key === "end_date") {
            const formattedDate = moment(current[key]).format("DD-MM-YYYY");
            if (!acc[key]) {
              acc[key] = [formattedDate];
            } else if (!acc[key].includes(formattedDate)) {
              acc[key].push(formattedDate);
            }
          } else if (key === "guest_name") {
            const formattedName = current[key].replace(/\d+$/, "").trim();
            if (!acc[key]) {
              acc[key] = [formattedName];
            } else if (!acc[key].includes(formattedName)) {
              acc[key].push(formattedName);
            }
          } else {
            if (!acc[key]) {
              // If acc hvnt key, create new array
              acc[key] = [current[key]];
            } else if (!acc[key].includes(current[key])) {
              // If acc have key, check data is exist
              acc[key].push(current[key]);
            }
          }
        }
        return acc;
      },
      {
        guest_name: "",
        reservation_id: "",
        payment_details: "",
        payment_type: "",
        payment_status: "",
        start_date: "",
        end_date: "",
      }
    );
  };

  // HANDLE SPLIT DATA FOR BODY REQ
  const splitData = (billData) => {
    let reservation_id = [];
    let article_id = [];

    billData.forEach((item) => {
      reservation_id.push(item.reservation_id);
      article_id.push(item.article_id);
    });

    return {
      reservation_id,
      article_id,
    };
  };

  // HANDLE SUBMIT
  const handleSubmit = () => {
    confirm({
      title: `Do you want to Submit Bill Data?`,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: "Confirm",
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },

      onOk() {
        data.length > 0 && handleUpdateBill(data);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },
    });
  };

  // HANDLE UPDATE BILL
  const handleUpdateBill = async (e) => {
    const contentSubmit = splitData(e);

    await axios
      .put(
        `${baseurl}/bill/master-folio`,
        {
          bill_status: typeData,
          reservation_id: contentSubmit?.reservation_id,
          article_id: contentSubmit?.article_id,
          // is_show: false,
          bill_receiver: billReceiver?.guest_id,
        },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Update Master Folio ->> ", response);

        // if (response.data.status == 200) {
        // closeModal(false);
        // setOpen(false);
        // is_refresh((state) => !state);
        masterSuccessNotification(response, {
          method: 1,
          source: "Master Folio",
        });
        // Move to FO Cashier
        handleNavigate(response.data.msg);
        // }
      })
      .catch((error) => {
        console.log("Failed Update Master Folio ->> ", error);
        masterFailedNotification(error, { method: 1, source: "Master Folio" });
      });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setData([]);
    // setEdit(null);

    setIsLoading(true);

    form.resetFields();
  };

  // HANDLE SUMMARY TOTAL
  const handleSummaryTotal = (billData) => {
    const _total = PreTotalPriceFunction(billData);

    setTotalPrice({
      nett: _total.nett,
      service: _total.service,
      tax: _total.tax,
      gross: _total.gross,
    });
  };

  // HANDLE PRINT OUT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Master Folio ${
      data[0]?.reservation_id ? data[0]?.reservation_id : " ---- "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
    // onafterprint: () => alert("Print Success"),
  });

  // REDIRECT TO CASHIER FO
  const handleNavigate = (state) => {
    navigate("/front-office/front-office-cashier", {
      state: state,
    });
  };

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return <p>{`${record.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>;
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>{`${record.total_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Master Folio`}
              </Typography>
            </Row>
          </>
        }
        getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="resv-form-modal"
          className="resv-form-modal"
          form={form}
          labelCol={{ span: 12 }}
          labelWrap={{ span: 12 }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          autoComplete="off"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{ padding: "30px 80px 30px 80px" }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Master Folio`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "20%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-receipt-no"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="No"
                  className="receipt_number"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  {}
                </Form.Item>
              </Row>

              <Row
                className="row-date"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="Date"
                  className="date"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Typography className="date-text" style={textForm}>
                    {moment().format("HH:mm, DD-MM-YYYY")}
                  </Typography>
                </Form.Item>
              </Row>

              <Row
                className="row-type"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="Type"
                  className="type"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Typography className="type-text" style={textForm}>
                    {typeData === 1
                      ? "SPLIT BILL"
                      : typeData === 2
                      ? "JOIN BILL"
                      : "NORMAL BILL"}
                  </Typography>
                </Form.Item>
              </Row>

              <Row className="row-titles" justify="start">
                <Col span={12}>
                  <Typography
                    className="text-payment"
                    style={{
                      margin: "15px 0px 0px",
                      fontSize: 12,
                      fontWeight: 750,
                    }}
                  >
                    {`Payment Data`}
                  </Typography>
                </Col>

                <Col span={12}>
                  <Typography
                    className="text-guest"
                    style={{
                      margin: "15px 0px 0px",
                      fontSize: 12,
                      fontWeight: 750,
                    }}
                  >
                    {`Data Guest`}
                  </Typography>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                }}
              >
                <Col span={12}>
                  <Form.Item
                    label="Payment Type"
                    className="payment_type"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {/* {guestData?.payment_type ? guestData?.payment_type : "-"} */}
                      {/* {typeof guest?.payment_type == "object"
                        ? guest?.payment_type?.join(", ")
                        : guest.payment_type || "-"} */}

                      {/* {typeof guest?.payment_type == "object"
                        ? guest?.payment_type?.some((item) => item !== null)
                          ? guest?.payment_type?.join(", ")
                          : "-"
                        : guest?.payment_type
                        ? guest?.payment_type
                        : "-"} */}

                      {billReceiver?.payment_type
                        ? billReceiver?.payment_type
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Payment Details"
                    className="payment_details"
                    style={{ margin: 0 }}
                  >
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {/* {guestData?.payment_details
                        ? guestData?.payment_details
                        : "-"} */}
                      {/* {typeof guest?.payment_details == "object"
                        ? guest?.payment_details?.join(", ")
                        : guest.payment_details || "-"} */}

                      {/* {typeof guest?.payment_details == "object"
                        ? guest?.payment_details?.some((item) => item !== null)
                          ? guest?.payment_details?.join(", ")
                          : "-"
                        : guest?.payment_details
                        ? guest?.payment_details
                        : "-"} */}

                      {billReceiver?.payment_details
                        ? billReceiver?.payment_details
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item label="Payment Status" className="payment_status">
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {/* {`${
                        guestData?.payment_status == 1
                          ? "Cash Basis"
                          : "Charge To Room"
                      }`} */}
                      {/* {typeof guest?.payment_status == "object"
                        ? guest?.payment_status?.join(", ")
                        : guest.payment_status || "-"} */}

                      {billReceiver?.payment_status
                        ? billReceiver?.payment_status
                        : "-"}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="col-res-id">
                  <Form.Item
                    label="Bill No"
                    className="reservation_id"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {/* {guestData?.reservation_id
                        ? guestData?.reservation_id
                        : "-"} */}
                      {/* {typeof guest?.reservation_id == "object"
                        ? guest?.reservation_id?.join(", ")
                        : guest.reservation_id || "-"} */}

                      {billReceiver?.reservation_id
                        ? billReceiver?.reservation_id
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Guest Name"
                    className="guest_name"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {/* {guestData?.guest_name
                        ? guestData?.guest_name?.replace(/\d+$/, "").trim()
                        : "-"} */}

                      {/* {typeof guest?.guest_name == "object"
                        ? guest?.guest_name?.join(", ")
                        : guest?.guest_name?.replace(/\d+$/, "").trim() || "-"} */}

                      {billReceiver?.guest_name
                        ? billReceiver?.guest_name?.replace(/\d+$/, "").trim()
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Start Date"
                    className="arrival"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {/* {guestData?.start_date &&
                      guestData?.start_date !== "Invalid date"
                        ? `${moment(guestData?.start_date).format(
                            "DD-MM-YYYY"
                          )}`
                        : "-"} */}

                      {/* {typeof guest?.start_date == "object"
                        ? guest?.start_date?.some(
                            (item) => item !== "Invalid date"
                          )
                          ? guest?.start_date?.join(", ")
                          : "-"
                        : guest?.start_date &&
                          guest?.start_date !== "Invalid date"
                        ? moment(guest?.start_date).format("DD-MM-YYYY")
                        : "-"} */}

                      {billReceiver?.start_date &&
                      billReceiver?.start_date !== "Invalid date"
                        ? `${moment(billReceiver?.start_date).format(
                            "DD-MM-YYYY"
                          )}`
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="End Date"
                    className="departure"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {/* {guestData?.end_date &&
                      guestData?.end_date !== "Invalid date"
                        ? `${moment(guestData?.end_date).format("DD-MM-YYYY")}`
                        : "-"} */}

                      {/* {typeof guest?.end_date == "object"
                        ? guest?.end_date?.some(
                            (item) => item !== "Invalid date"
                          )
                          ? guest?.end_date?.join(", ")
                          : "-"
                        : guest?.end_date && guest?.end_date !== "Invalid date"
                        ? moment(guest?.end_date).format("DD-MM-YYYY")
                        : "-"} */}

                      {billReceiver?.end_date &&
                      billReceiver?.end_date !== "Invalid date"
                        ? `${moment(billReceiver?.end_date).format(
                            "DD-MM-YYYY"
                          )}`
                        : "-"}
                    </Typography>
                  </Form.Item>

                  {/* <Form.Item
                    label="Night(s)"
                    className="night"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {"-"}
                    </Typography>
                  </Form.Item> */}
                </Col>
              </Row>

              <Row justify="start">
                <Typography style={textForm}>{`Buying List`}</Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="transaction-table"
                  key="transaction-table"
                  name="transaction-table"
                  loading={isLoading}
                  columns={columns}
                  dataSource={data}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) => (record?.id ? record.id : record.key)}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary fixed>
                          {bill_type ? (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} />

                                <Table.Summary.Cell index={4}>
                                  <b>Subtotal</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {`${currency} ${totalPrice.nett}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              {/* <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} />

                                <Table.Summary.Cell index={4}>
                                  <b>Service</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {totalPrice.service > 0
                                    ? `${currency} ${totalPrice.service}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    : 0}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={4} />

                                <Table.Summary.Cell index={4}>
                                  <b>Tax</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {totalPrice.tax > 0
                                    ? `${currency} ${totalPrice.tax}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    : 0}
                                </Table.Summary.Cell>
                              </Table.Summary.Row> */}
                            </>
                          ) : null}

                          {/* <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4} />

                            <Table.Summary.Cell index={4}>
                              <b>Grand Total</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={4} align="right">
                              <b>
                                {totalPrice.gross > 0
                                  ? `${currency} ${totalPrice.gross}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  : 0}
                              </b>
                            </Table.Summary.Cell>
                          </Table.Summary.Row> */}
                        </Table.Summary>
                      </>
                    );
                  }}
                />
              </Row>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: please, recheck your transactions again before paying!
              </Typography>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: all prices shown are already include tax & service
              </Typography>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              htmlType="submit"
              icon={<PrinterOutlined />}
              onClick={() => {
                handlePrintOut();
              }}
              style={{
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="submit-btn"
              type="default"
              style={{
                marginRight: 15,
                color: "white",
              }}
              onClick={handleSubmit}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

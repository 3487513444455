// Import Type of Messages
import { SET_DATE } from "./types";

// Import Auth Service
import authService from "../../Services/auth.service";

import { useNavigate } from "react-router-dom";
// import authService from "../services/auth.service";

// SET DATE AFTER NIGHT AUDIT
export const setDate = (date) => async (dispatch) => {
  console.log("SET DATE AFTER NA --> ", date);

  return await authService
    .audit()
    .then((data) => {
      console.log("Data Dispatch: ", data);

      dispatch({
        type: SET_DATE,
        payload: { date: data },
      });
    })
    .catch((error) => {
      console.log("Error: ", error);
    });
};

// LOGIN AUTH
// export const login = (email, password, username) => async (dispatch) => {
//   console.log("Email --> ", email);
//   console.log("Pwd --> ", password);
//   console.log("Username --> ", username);
//   // console.log("Dispatch --> ", dispatch);

//   return await authService
//     .login(email, password)
//     .then(
//       (data) => {
//         console.log("Dispatch --> ", data);

//         if (data?.access_token) {
//           dispatch({
//             type: LOGIN_SUCCESS,
//             payload: { user: data },
//           });

//           successLogin(data.access_token, { method: 0 });

//           return Promise.resolve(data);
//         } else {
//           dispatch({
//             type: LOGIN_FAIL,
//             payload: { user: null },
//           });

//           failedLogin(data.error, { method: 0 });

//           return Promise.reject(data.error);
//         }
//       },
//       (error) => {
//         console.log("Error --> ", error);

//         const message =
//           (error.response &&
//             error.response.data &&
//             error.response.data.message) ||
//           error.message ||
//           error.toString();

//         console.log("Message Error --> ", message);

//         dispatch({
//           type: SET_MESSAGE,
//           payload: message,
//         });
//         return Promise.reject(error);
//       }
//     )
//     .catch((error) => {
//       console.log("Promise --> ", error);
//     });
// };

// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// AUTH URL
import { baseurl } from "../../../API/Config/Api";
import { token } from "../../../API/Global/Payload";

// Import ANT Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

// Import React Icons Components
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { SyncOutlined } from "@ant-design/icons";
import { MdOutlineFormatAlignJustify } from "react-icons/md";

// Import Page Components
import FolioTable from "../../Table/MasterFolio/GuestFolio/Table";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";
import GroupingBill from "../../Reusable/Functions/GroupingArticle/GroupingBill";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../Reusable/Notification/Notification";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";

// CODE
export default function FOBillModal(props) {
  // PROPS
  const {
    selected,
    sources,
    is_open,
    is_close,
    selected_detail,
    selected_total,
  } = props;

  // PARAMS
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [details, setDetails] = useState(null);
  const [source, setSource] = useState(0);
  const [tagStatus, setTagStatus] = useState({
    txt: "UN-SELECTED",
    clr: "#f5222d",
  });
  const [subTotal, setSubTotal] = useState({
    total: 0,
    tax: 0,
    service: 0,
    grand: 0,
  });
  // Row Data
  const [selectedData, setSelectedData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // BOOLEAN
  const [open, setOpen] = useState({
    header: false,
    detail: false,
  });
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // FETCH DATA
  const fetchData = async (val) => {
    setLoading(true);

    await axios
      .get(`${baseurl}/guest/payment-reservation`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Fetch: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _mapping = _res.map((items, index) => {
            return { ...items, key: index * 1.39 + items?.total_price };
          });

          setData(_mapping);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Errors: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
        setSelectedRowKeys([]);
        setDetails([]);
        form.resetFields();
      });
  };

  // Fetch Details
  const fetchDetails = async (values) => {
    await axios
      .post(
        `${baseurl}/guest/payment-reservation-by-bill-master`,
        {
          bill_master: values?.bill_master,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Fetch Details: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleDataDetail(_res);
        } else {
          setDetails([]);
        }
      })
      .catch((error) => {
        console.log("Errors: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (sources > 0 && !cleanUp) {
      setSource(sources);
      // fetchData(sources);
    }

    if (is_open && !cleanUp) {
      fetchData();

      setOpen({
        header: true,
        detail: false,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [sources, is_open]);

  // COLUMNS
  const columns = [
    {
      title: "Details",
      dataIndex: "icons",
      key: "icons",
      align: "center",
      render: (_, record) => {
        return (
          <Tooltip
            title="Show Bill's Detail (Preview)"
            className="bill-popover"
          >
            <MdOutlineFormatAlignJustify
              className="icon-btn"
              key="icon-btn"
              onClick={() => {
                handleShowDetail(record);
              }}
              style={{
                fontSize: 20,
                color: "#3699FF",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Bill No.",
      dataIndex: "bill_master",
      key: "bill_master",
    },
    {
      title: "Res. ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      width: 200,
      render: (res) => {
        return res?.length > 0 ? res.join(", ") : res;
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: `Status`,
      dataIndex: "bill_status",
      key: "bill_status",
      align: "center",
      render: (status, record) => {
        let txt = "ERROR";
        let clr = "#f5222d";

        switch (status) {
          case 0:
            txt = "NORMAL BILL";
            clr = "#ffec3d";
            break;

          case 1:
            txt = "SPLIT BILL";
            clr = "#2f54eb";
            break;

          case 2:
            txt = "JOIN BILL";
            clr = "#52c41a";
            break;

          default:
            break;
        }

        return (
          <Tag
            color={clr}
            key={record?.key}
            style={{
              color: status == 0 ? "#434343" : "#fff",
              fontWeight: 700,
            }}
          >
            {txt}
          </Tag>
        );
      },
    },
    {
      title: `Guest (Bill Receiver)`,
      dataIndex: "full_name",
      key: "full_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.full_name
          ? a.full_name.localeCompare(b?.full_name ? b.full_name : null)
          : null,
    },
    {
      title: `Total ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      width: 150,
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Handle Form Fields
  const handleFormFields = (val) => {
    const _record = val ? val : null;

    console.log("Records: ", _record);

    if (_record) {
      form.setFieldsValue({
        bill_alias: _record?.bill_master,
        bill_receiver: _record?.full_name,
        reservation_id: _record?.reservation_id,
        total: _record?.total_price > 0 ? _record.total_price : 0,
        full_name: _record?.full_name,
      });
    } else {
      form.resetFields();
    }
  };

  // Handle Form Fields
  const handleFormDetail = (val) => {
    const _record = val ? val : null;

    if (_record) {
      form.setFieldsValue({
        bill_alias_detail: _record?.bill_master,
        reservation_id_detail: _record?.reservation_id,
        total_detail: _record?.total_price > 0 ? _record.total_price : 0,
        full_name_detail: _record?.full_name,
        bill_receiver_detail: _record?.full_name,
      });
    } else {
      form.resetFields();
    }
  };

  // Handle Row Selection
  const rowSelection = {
    onChange: (rowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${rowKeys}`);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowKeys(rowKeys);
      setSelectedData(selectedRows?.length > 0 ? selectedRows[0] : null);
      handleFormFields(selectedRows?.length > 0 ? selectedRows[0] : null);
      fetchDetails(selectedRows?.length > 0 ? selectedRows[0] : null);
    },
    hideSelectAll: true,
    selectedRowKeys: selectedRowKeys,
  };

  // Handle Show Details
  const handleShowDetail = (value) => {
    const _record = value;
    const status = value?.bill_status;

    handleFormDetail(_record);
    fetchDetails(_record);

    setOpen({
      header: true,
      detail: true,
    });

    switch (status) {
      case 0:
        setTagStatus({
          txt: "NORMAL BILL",
          clr: "#ffec3d",
        });
        break;

      case 1:
        setTagStatus({
          txt: "SPLIT BILL",
          clr: "#2f54eb",
        });
        break;

      case 2:
        setTagStatus({
          txt: "JOIN BILL",
          clr: "#52c41a",
        });
        break;

      default:
        setTagStatus({ txt: "ERROR" });
        break;
    }
  };

  // Handle Data Details
  const handleDataDetail = (value) => {
    const _arr = value;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    // const _grouping = G
    const _grouping = BillArticleShow({
      article: _billing,
      bill_type: bill_type,
    });

    console.log("Billing: ", _billing);
    console.log("Grouping Res: ", _grouping);

    // setDetails(_grouping.article);
    setDetails(_grouping.article);
    selected_detail(_grouping.article);
    // Return
    selected_total({
      grand: _grouping.total.total_price,
      service: _grouping.total.service,
      tax: _grouping.total.tax,
      total: _grouping.total.unit_price,
    });
    setSubTotal({
      grand: _grouping.total.total_price,
      service: _grouping.total.service,
      tax: _grouping.total.tax,
      total: _grouping.total.unit_price,
    });
  };

  // Handle Close
  const handleClose = () => {
    setLoading(true);
    setIsLoading(false);

    form.resetFields();

    setData([]);
    setSelectedData(null);
    setSelectedRowKeys([]);
    setSource(0);

    is_close(false);
    setOpen({
      header: false,
      detail: false,
    });
  };

  // Handle Choose
  const handleChoose = () => {
    setIsLoading(false);

    is_close(false);
    setOpen({
      header: false,
      detail: false,
    });
  };

  // Handle Choose
  const handleModalDetail = () => {
    setOpen({
      header: true,
      detail: false,
    });
  };

  // Handle Finish
  const handleSubmit = () => {
    setIsLoading(true);

    const error = {
      errorFields: [
        {
          errors: ["You haven't select any Data! Please, select a Data!"],
        },
      ],
    };

    if (selectedData) {
      console.log("SUBMIT: ", selectedData);
      selected(selectedData);

      handleChoose();
    } else {
      console.log("INCOMPLETE: ", selectedData);
      incomplete(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        className="bill-header"
        key="bill-header"
        title={
          <Row>
            <LiaFileInvoiceDollarSolid
              type="primary"
              style={{ fontSize: 24, color: "#3699FF" }}
            />
            <Typography.Title
              level={4}
              style={{ marginLeft: 15 }}
            >{`Bill List Modals`}</Typography.Title>
          </Row>
        }
        open={open.header}
        footer={null}
        centered
        closable
        onCancel={handleClose}
        bodyStyle={{
          padding: "0px 30px 15px",
        }}
        width={1000}
      >
        <Row
          className="row-table-btn"
          justify="space-between"
          style={{
            padding: 0,
          }}
        >
          <Col span={24}>
            <Form
              className="form-bill"
              key="form-bill"
              name="form-bill"
              form={form}
              labelWrap={true}
              labelAlign="left"
              labelCol={{
                span: 6,
                offset: 2,
              }}
              wrapperCol={{
                span: 18,
              }}
            >
              <Col span={24}>
                <Typography.Title level={4}>
                  Selected Bill Details Info:
                </Typography.Title>

                <Row
                  align="middle"
                  justify="start"
                  style={{
                    height: 30,
                  }}
                >
                  <Col span={12}>
                    <Form.Item name="bill_alias" label="Bill No.">
                      <Input
                        disabled
                        placeholder="Bill No."
                        bordered={false}
                        style={textForm}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="total" label="Total Trx">
                      <InputNumber
                        readOnly={true}
                        addonBefore={currency}
                        formatter={(price) => {
                          return `${price > 0 ? price : 0}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                        placeholder="Total Transactions"
                        bordered={false}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row align="top" justify="start" style={{ height: 60 }}>
                  <Col span={12}>
                    <Form.Item
                      name="full_name"
                      label="Guest Name"
                      style={{ margin: 0 }}
                    >
                      <Input
                        disabled
                        placeholder="Guest Name"
                        bordered={false}
                        style={textForm}
                      />
                    </Form.Item>

                    <Form.Item
                      name="bill_receiver"
                      label="Bill Receiver"
                      style={{ margin: 0 }}
                    >
                      <Input
                        disabled
                        placeholder="Bill Receiver"
                        bordered={false}
                        style={textForm}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item name="reservation_id" label="Reservation No.">
                      <Input.TextArea
                        disabled
                        placeholder="Reservation No."
                        bordered={false}
                        style={textForm}
                      />
                    </Form.Item>
                  </Col>

                  {/*
                    <Form.Item name="audit_at" label="Audit Date">
                      <Input
                        disabled
                        placeholder="Audit Date"
                        bordered={false}
                        style={textForm}
                        />
                        </Form.Item>

                        <Col span={8}>
                        <Form.Item
                        name="is_register"
                        valuePropName="checked"
                        style={{
                        margin: "0px 0px 0px 20px",
                        }}
                        >
                      <Checkbox
                      value={selectedData?.is_register}
                      >{`Register`}</Checkbox>
                      </Form.Item>
                      
                      <Form.Item name="register_date" label="Register Date">
                      <Input
                      disabled
                        placeholder="Register Date"
                        bordered={false}
                        style={textForm}
                        />
                    </Form.Item>
                    </Col>
                    
                  <Col span={8}>
                    <Form.Item name="reservation_id" label="Reservation No.">
                      <Input
                      disabled
                      placeholder="Reservation No."
                      bordered={false}
                        style={textForm}
                        />
                        </Form.Item>
                        </Col>
                        */}
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>

        <Divider
          className="divider-form"
          style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
        />

        <Row justify="space-between" align="middle">
          <Tag
            className="tags-type"
            color="geekblue"
            style={{
              fontSize: 20,
              padding: 8,
              margin: "0px 0px 15px",
              fontWeight: 750,
            }}
          >
            {`Select Bill(s) to: Payment`}
          </Tag>

          <Button
            className="refresh-btn"
            type="default"
            icon={<SyncOutlined />}
            onClick={fetchData}
          >
            {`Refresh`}
          </Button>
        </Row>

        <Row justify="start">
          <Table
            className="bill-list"
            name="bill-list"
            key="bill-list"
            columns={columns}
            dataSource={data}
            bordered={true}
            loading={loading}
            size="middle"
            scroll={{
              x: true,
            }}
            rowSelection={{
              type: "radio",
              ...rowSelection,
              columnTitle: "Select",
            }}
            rowKey={(record) => {
              return record?.key ? record.key : record?.id;
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            pagination={{
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
          />
        </Row>

        <Divider className="divider-form" style={{ margin: "5px 0px" }} />

        <Row
          className="row-modal-btn"
          justify="end"
          align="middle"
          style={{ padding: "15px 0px 0px" }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={selectedRowKeys.length > 0 ? false : true}
            loading={isLoading}
            className="submit-btn"
            style={{
              marginRight: 15,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={handleClose}>
            {`Cancel`}
          </Button>
        </Row>
      </Modal>

      <Modal
        className="bill-detail"
        key="bill-detail"
        title={
          <Row>
            <MdOutlineFormatAlignJustify
              type="primary"
              style={{ fontSize: 24, color: "#3699FF" }}
            />
            <Typography.Title
              level={4}
              style={{ marginLeft: 15 }}
            >{`Bill Details Modals`}</Typography.Title>
          </Row>
        }
        open={open.detail}
        footer={null}
        centered
        closable
        onCancel={handleModalDetail}
        bodyStyle={{
          padding: "0px 30px 15px",
        }}
        width={1000}
      >
        <Form
          className="form-bill"
          key="form-bill"
          name="form-bill"
          form={form}
          labelWrap={true}
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 2,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <Col span={24}>
            <Typography.Title level={4}>
              Selected Bill Details Info:
            </Typography.Title>

            <Row
              align="middle"
              justify="start"
              style={{
                height: 30,
              }}
            >
              <Col span={12}>
                <Form.Item name="bill_alias_detail" label="Bill No.">
                  <Input
                    disabled
                    placeholder="Bill No."
                    bordered={false}
                    style={textForm}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="total_detail" label="Total Trx">
                  <InputNumber
                    readOnly={true}
                    addonBefore={currency}
                    formatter={(price) => {
                      return `${price > 0 ? price : 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    placeholder="Total Transactions"
                    bordered={false}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" justify="start" style={{ height: 75 }}>
              <Col span={12}>
                <Form.Item
                  name="full_name_detail"
                  label="Guest Name"
                  style={{ margin: 0 }}
                >
                  <Input
                    disabled
                    placeholder="Guest Name"
                    bordered={false}
                    style={textForm}
                  />
                </Form.Item>

                <Form.Item
                  name="bill_receiver_detail"
                  label="Bill Receiver"
                  style={{ margin: 0 }}
                >
                  <Input
                    disabled
                    placeholder="Bill Receiver"
                    bordered={false}
                    style={textForm}
                  />
                </Form.Item>

                <Form.Item label="Bill Status">
                  <Tag
                    color={tagStatus.clr}
                    key="bill-tag"
                    style={{
                      color:
                        tagStatus.txt == "NORMAL BILL" ? "#434343" : "#fff",
                      fontWeight: 700,
                    }}
                  >
                    {tagStatus.txt}
                  </Tag>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="reservation_id_detail"
                  label="Reservation No."
                  style={{ margin: 0 }}
                >
                  <Input.TextArea
                    disabled
                    placeholder="Reservation No."
                    bordered={false}
                    style={textForm}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>

        <Divider
          className="divider-form"
          orientationMargin={0}
          orientation="left"
          style={{ margin: "15px 0px 5px", borderColor: "#d9d9d9" }}
        >
          {`Bill Detail List`}
        </Divider>

        <FolioTable
          dataTable={details}
          dataTotal={subTotal}
          bill_type={bill_type}
          isLoading={loading}
          currency={currency}
        />

        <Row justify="end">
          <Button
            className="cancel-btn"
            type="default"
            onClick={handleModalDetail}
          >
            {`Close`}
          </Button>
        </Row>
      </Modal>
    </>
  );
}

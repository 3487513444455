// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Divider, Radio, Table, Space, Button, Col, Row } from "antd";

// Import React Icons
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Components
// import ModalReportOutlet from "../../../Modals/Outlet/ReportOutlet/Modal";

const dataSource = [
  {
    key: "1",
    id: "RES/1234/1234/1234",
    keterangan: "1 Set Super Komputer dan Perlengkapannya",
    diterima: "1 Pcs",
    dipesan: "1 Pcs",
    harga_satuan: "5.000.000",
    diskon: "0%",
    sub_total: "5.000.000",
    pajak: "0%",
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// const onChange = (pagination, filters, sorter, extra) => {
//     console.log('params', pagination, filters, sorter, extra);
// };

// CODE
export default function ReportOutletPrint() {
  // Table's Column
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      key: "keterangan",
    },
    {
      title: "Diterima",
      dataIndex: "diterima",
      key: "diterima",
    },
    {
      title: "Dipesan",
      dataIndex: "dipesan",
      key: "dipesan",
    },
    {
      title: "Harga Satuan",
      dataIndex: "harga_satuan",
      key: "harga_satuan",
    },
    {
      title: "Diskon",
      dataIndex: "diskon",
      key: "diskon",
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      key: "sub_total",
    },
    {
      title: "Pajak",
      dataIndex: "pajak",
      key: "pajak",
    },
  ];
  // STATE MANAGEMENT
  const [selectionType, setSelectionType] = useState("checkbox");
  // const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Row
        justify="center"
        style={{ backgroundColor: "#FFFFFF", minHeight: "100%" }}
      >
        <Col span={24}>
          <Row style={{ marginTop: -20 }}>
            <Table
              className="table-modal-viewreport"
              loading={loading}
              columns={columns}
              dataSource={dataSource}
              //onChange={onChange}
              pagination={false}
              //     pageSize: 5,
              //   }}
              // scroll={{
              //     y: 270,
              // }}
              size="small"
              rowKey={(record) => (record.id ? record.id : record.key)}
              summary={() => (
                <Table.Summary fixed>
                  <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                    <Table.Summary.Cell index={0}>
                      <b>Salesman :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <b>N/A</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <b>Biaya Pengiriman :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <b>0.0</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <b>Total :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      <b>720.000</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                    <Table.Summary.Cell index={0}>
                      <b>Diinput Oleh :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <b>N/A</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <b>Diskon Akhir :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <b>0.0</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <b>Pembayaran :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      <b>720.000</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <b>Saldo :</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      <b>720.000</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

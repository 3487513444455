// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// AUTH
import { user_name, token } from "../../../../../API/Global/Payload";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Modal,
  Form,
  Divider,
  InputNumber,
  DatePicker,
  Typography,
  Input,
  Tag,
} from "antd";

// Import React Icons Components
import { IoReturnUpBackOutline } from "react-icons/io5";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { FaMoneyBillWave } from "react-icons/fa6";
import { MdPrint } from "react-icons/md";

// Import React Components
import PurchaseOrderModal from "../../../../Modals/Purchasing/PurchaseOrder/Modal";
import PurchaseOrderModalDraft from "../../../../Modals/Purchasing/PurchaseOrder/ModalDraft";
import PurchaseOrderForm from "../../../../Forms/Purchase Order/PurchaseOrder";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Functions
import { TaxesParameter } from "../../../../Reusable/Functions/Taxes/Taxes";
import { BackOfficeParams } from "../../../../Reusable/Functions/Parameters/ParamsBO";
import ParametersBackOffice from "../../../../Reusable/Parameters/ParamsBO";

// Import Notification
import { masterIncomplete } from "../../../../Reusable/Notification/MasterNotif/Notification";
import {
  failedFetch,
  failedPO,
  successPO,
} from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// MODALS
const { confirm } = Modal;

export default function PurchaseOrderTable(props) {
  // PROPS
  const { searchKey, searchDept, searchOrderDate, is_search } = props;

  // Navigate
  const navigate = useNavigate();

  // CONTEXT
  const { getPurchasing } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [dataEdit, setDataEdit] = useState(null);
  const [dataExport, setDataExport] = useState(null);

  // Boolean
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  const [modalFormOpen, setModalFormOpen] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Params
  const params = TaxesParameter();
  const params_bo = BackOfficeParams();

  // Fetch Data
  const fetchData = async () => {
    await getPurchasing({
      type: "purchase-order",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Get Purchasing: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          let exportData = filtered.map((e) => {
            let poStatus = "";

            switch (true) {
              case e.po_status == 1 &&
                moment(moment().format("YYYY-MM-DD")).isAfter(
                  e.delivery_date
                ) &&
                e.is_outstanding == false:
                poStatus = "Closed";
                break;
              case e.po_status == 1 &&
                moment(moment().format("YYYY-MM-DD")).isAfter(
                  e.delivery_date
                ) &&
                e.is_outstanding == true:
                poStatus = "EXPIRED";
                break;
              case e.po_status == 1 &&
                e.is_outstanding == true &&
                moment(moment().format("YYYY-MM-DD")).isSameOrBefore(
                  e.delivery_date
                ):
                poStatus = "Outstanding";
                break;
              case e.po_status == 2 &&
                moment(moment().format("YYYY-MM-DD")).isSameOrBefore(
                  e.delivery_date
                ):
                poStatus = "Draft";
                break;
              case e.po_status == 2 &&
                moment(moment().format("YYYY-MM-DD")).isAfter(e.delivery_date):
                poStatus = "EXPIRED";
                break;

              default:
                break;
            }
            return {
              po_number: e.po_number ?? "-",
              po_status: poStatus ?? "-",
              department_name: e.department_name ?? "-",
              pr_number: e.pr_number ?? "-",
              order_date: e.order_date ?? "-",
              delivery_date: e.delivery_date ?? "-",
              needed_date: e.needed_date ?? "-",
              credit_term_date: e.credit_term_date ?? "-",
              description: e.description ?? "-",
              details: e.details,
            };
          });

          console.log(exportData);

          setData(filtered);
          setDataExport(exportData);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error = ", error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    return () => {
      cleanUp = true;
    };
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, searchDept, searchOrderDate]);

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (_, record) => {
        switch (true) {
          case record.po_status == 1 &&
            moment(moment().format("YYYY-MM-DD")).isAfter(
              record.delivery_date
            ) &&
            record.is_outstanding == false:
            // return <Tag color="#87d068">Closed</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip title="Print PO Form" className="edit-tooltip">
                    <MdPrint
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleOpenForm();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Cancel PO" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      // onClick={() => {
                      //   console.log("ABOUT TO DELETE > ", record);

                      //   showModalConfirmDelete(record);
                      // }}
                      style={{ fontSize: 20, color: "#bfbfbf" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.po_status == 1 &&
            moment(moment().format("YYYY-MM-DD")).isAfter(
              record.delivery_date
            ) &&
            record.is_outstanding == true:
            // return <Tag color="#87d068">Expired</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip title="Print PO Form" className="edit-tooltip">
                    <MdPrint
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleOpenForm();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Cancel PO" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      onClick={() => {
                        console.log("ABOUT TO DELETE > ", record);

                        showModalConfirmDelete(record);
                      }}
                      style={{ fontSize: 20, color: "#F64E60" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.po_status == 1 &&
            record.is_outstanding == true &&
            moment(moment().format("YYYY-MM-DD")).isSameOrBefore(
              record.delivery_date
            ):
            // return <Tag color="#87d068">Outstanding</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip title="Print PO Form" className="edit-tooltip">
                    <MdPrint
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleOpenForm();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Cancel PO" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      // onClick={() => {
                      //   console.log("ABOUT TO DELETE > ", record);

                      //   showModalConfirmDelete(record);
                      // }}
                      style={{ fontSize: 20, color: "#bfbfbf" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.po_status == 1 &&
            record.is_outstanding == false &&
            moment(moment().format("YYYY-MM-DD")).isSameOrBefore(
              record.delivery_date
            ):
            // return <Tag color="#87d068">Outstanding</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip title="Print PO Form" className="edit-tooltip">
                    <MdPrint
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleOpenForm();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Cancel PO" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      // onClick={() => {
                      //   console.log("ABOUT TO DELETE > ", record);

                      //   showModalConfirmDelete(record);
                      // }}
                      style={{ fontSize: 20, color: "#bfbfbf" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.po_status == 2 &&
            moment(moment().format("YYYY-MM-DD")).isBefore(
              record.delivery_date
            ):
            // return <Tag color="#87d068">Draft</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip title="Edit Data" className="edit-tooltip">
                    <FiEdit
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleFormField(record);
                        setDataEdit(record);
                        setEdit(true);
                        handleOpen();
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Cancel PO" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      onClick={() => {
                        console.log("ABOUT TO DELETE > ", record);

                        showModalConfirmDelete(record);
                      }}
                      style={{ fontSize: 20, color: "#F64E60" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          case record.po_status == 2 &&
            moment(moment().format("YYYY-MM-DD")).isAfter(record.delivery_date):
            // return <Tag color="#87d068">Expired</Tag>;
            return (
              <Row className="action-guest-table-btn" style={{ width: "100%" }}>
                <Col span={12}>
                  <Tooltip title="Print PO Form" className="edit-tooltip">
                    <MdPrint
                      className="edit-btn"
                      onClick={() => {
                        console.log("ABOUT TO EDIT > ", record);
                        handleOpenForm();
                        setDataEdit(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Tooltip title="Cancel PO" className="delete-tooltip">
                    <DeleteFilled
                      className="delete-btn"
                      onClick={() => {
                        console.log("ABOUT TO DELETE > ", record);

                        showModalConfirmDelete(record);
                      }}
                      style={{ fontSize: 20, color: "#F64E60" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            );

          default:
            break;
        }
      },
    },
    {
      title: "PO No.",
      dataIndex: "po_number",
      key: "po_number",
    },
    {
      title: "PO Status",
      dataIndex: "po_status",
      key: "po_status",
      render: (val, record) => {
        if (val == 1) {
          if (
            moment(moment().format("YYYY-MM-DD")).isAfter(record.delivery_date)
          ) {
            if (record.is_outstanding == false) {
              return <Tag color="#87d068">Closed</Tag>;
            } else {
              return <Tag color="red">EXPIRED</Tag>;
            }
          } else {
            if (record.is_outstanding == false) {
              return <Tag color="#87d068">Closed</Tag>;
            } else {
              return <Tag color="#108ee9">Outstanding</Tag>;
            }
          }
        } else {
          if (
            moment(moment().format("YYYY-MM-DD")).isAfter(record.delivery_date)
          ) {
            return <Tag color="red">EXPIRED</Tag>;
          } else {
            return <Tag color="#f50">Draft</Tag>;
          }
        }
      },
    },
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
    },
    {
      title: "PR No.",
      dataIndex: "pr_number",
      key: "pr_number",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },

    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Credit Term Date",
      dataIndex: "credit_term_date",
      key: "credit_term_date",
      render: (date) => {
        return date ? moment(date).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;

    form.setFieldsValue({});
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/back-office/purchasing";

    navigate(`${path}`);
  };

  // Handle Open
  const handleOpen = () => {
    setIsDelete(false);

    setModalOpen(true);
  };
  // Handle Open Form
  const handleOpenForm = () => {
    setIsDelete(false);

    setModalFormOpen(true);
  };

  // Handle Close
  const handleClose = () => {
    setIsDelete(true);
    setEdit(false);
    setModalOpen(false);

    setIsLoading(false);

    form.resetFields();
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      dataExport,
      `List Purchase Order (PO) ${moment().format("YYYY-MM-DD")}`
    );
  };

  // ON FINISH
  const onFinish = (val) => {
    const submit = val;

    setIsLoading(true);
    showModalConfirm(submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    masterIncomplete(submit);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Create a Purchase Order for Department ${
        _data?.department.toUpperCase() || " ----- "
      }?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {},

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // SHOW MODAL CONFIRM DELETE
  const showModalConfirmDelete = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Cancel this Purchase Order?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentIdPO = record.id_po;

    var obj = {
      ["id_po"]: contentIdPO,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/purchasing/purchase-order`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Del PO = ", response);

        successPO(response, { method: 2 });
        fetchData();
      })
      .catch((error) => {
        console.log("Del PO = ", error);

        failedPO(error, { method: 2 });
        setIsLoading(false);
      });
    // .finally(() => {
    // });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    setLoading(true);

    console.log(searchKey);
    console.log(searchDept);
    console.log(searchOrderDate);

    const poNumberSearch = searchKey ? searchKey.toLowerCase() : null;
    const poDeptSearch =
      searchDept != null
        ? searchDept.length > 0
          ? searchDept[0].department_name.toLowerCase()
          : null
        : null;
    const poOrderDateSearch = searchOrderDate ? searchOrderDate : null;

    console.log(poNumberSearch);
    console.log(poDeptSearch);
    console.log(poOrderDateSearch);

    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        const poNumber = item?.po_number.toLowerCase();
        const poDept = item?.department_name.toLowerCase();
        const poOrderDate = item?.order_date;

        // console.log(poNumber);
        // console.log(poDept);
        // console.log(poOrderDate);

        if (!poDeptSearch && !poOrderDateSearch) {
          console.log("1");
          return poNumber.indexOf(poNumberSearch) !== -1;
        }
        if (!poNumberSearch && !poDeptSearch) {
          console.log("2");
          return poOrderDate.indexOf(poOrderDateSearch) !== -1;
        }
        if (!poNumberSearch && !poOrderDateSearch) {
          console.log("3");
          return poDept.indexOf(poDeptSearch) !== -1;
        }

        /////

        if (!poOrderDateSearch) {
          console.log("4");
          return (
            poNumber.indexOf(poNumberSearch) !== -1 &&
            poDept.indexOf(poDeptSearch) !== -1
          );
        }
        if (!poDeptSearch) {
          console.log("5");
          return (
            poNumber.indexOf(poNumberSearch) !== -1 &&
            poOrderDate.indexOf(poOrderDateSearch) !== -1
          );
        }
        if (!poNumberSearch) {
          console.log("6");
          return (
            poDept.indexOf(poDeptSearch) !== -1 &&
            poOrderDate.indexOf(poOrderDateSearch) !== -1
          );
        }

        ////
        if (poNumberSearch && poDeptSearch && poOrderDateSearch) {
          console.log("7");
          return (
            poNumber.indexOf(poNumberSearch) !== -1 &&
            poDept.indexOf(poDeptSearch) !== -1 &&
            poOrderDate.indexOf(poOrderDateSearch) !== -1
          );
        }

        // if (poNumberSearch != null && poDeptSearch == null) {
        //   console.log("No >>>");
        //   return poNumber.indexOf(poNumberSearch) !== -1;
        // }
        // if (poNumberSearch == null && poDeptSearch != null) {
        //   console.log("Dept >>>");
        //   return poDept.indexOf(poDeptSearch) !== -1;
        // }
        // if (poNumberSearch != null && poDeptSearch != null) {
        //   console.log("Keduanya >>>");
        //   return (
        //     poNumber.indexOf(poNumberSearch) !== -1 &&
        //     poDept.indexOf(poDeptSearch) !== -1
        //   );
        // }

        // return (
        //   (poNumber.indexOf(poNumberSearch) !== -1 &&
        //     poDept.indexOf(poDeptSearch) !== -1) ||
        //   poNumber.indexOf(poNumberSearch) !== -1 ||
        //   poDept.indexOf(poDeptSearch) !== -1
        // );
      });
      setData(updatedList);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={
                    <PlusOutlined
                      className="pay-icon"
                      style={{
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 15px 10px 5px",
                    // height: 40,
                    minWidth: 75,
                    borderColor: "transparent",
                  }}
                >
                  {`Add New PO`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="purchase-ord-table"
              name="purchase-ord-table"
              key="purchase-ord-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              // rowSelection={{
              //   type: "checkbox",
              //   ...rowSelection,
              // }}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id_po ? record.id_po : record.po_number
              }
            />
          </Row>
        </Col>
      </Row>

      {edit == true ? (
        <PurchaseOrderModalDraft
          is_open={modalOpen}
          is_close={(e) => {
            setModalOpen(e);
            fetchData();
          }}
          is_edit={setEdit}
          selectedData={dataEdit}
          params={params}
        />
      ) : (
        <PurchaseOrderModal
          is_open={modalOpen}
          is_close={(e) => {
            setModalOpen(e);
            fetchData();
          }}
          is_edit={setEdit}
          params={params}
        />
      )}

      <PurchaseOrderForm
        openModal={modalFormOpen}
        closeModal={setModalFormOpen}
        is_fromReceiving={false}
        dataPO={dataEdit}
        params={params}
        params_form={params_bo}
      />
    </>
  );
}

// Import React's Component
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";

// Import React Icons
import { FileExcelOutlined, SyncOutlined } from "@ant-design/icons/lib/icons";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Master Components
import { mainBody } from "../../../../../Style/Global/Global";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { LocalizationDate } from "../../../../Reusable/Functions/Localizations/Localization";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function RoomReckonTable({ is_search, searchKey }) {
  // CONTEXT
  const { getRoomReckoning } = useContext(MasterContext);

  // Table's Column
  const columns = [
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      //   fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
      //   fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Status",
      dataIndex: "room_status_id",
      key: "room_status_id",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_status_id.localeCompare(b.room_status_id),
      render: (room_status, record) => {
        let status = "";
        let clr = "";

        switch (room_status) {
          case 1:
            status = "Vacant Clean Unchecked";
            clr = "#1890ff";
            break;
          case 2:
            status = "Vacant Clean Checked";
            clr = "#87d068";
            break;
          case 3:
            status = "Occupied Dirty";
            clr = "#f5222d";
            break;
          case 4:
            status = "Occupied Clean";
            clr = "#13c2c2";
            break;
          case 5:
            status = "Vacant Dirty";
            clr = "#fa8c16";
            break;
          case 6:
            status = "Out of Order";
            clr = "black";
            break;
          case 7:
            status = "Out of Service";
            clr = "brown";
            break;
          case 8:
            status = "Occupied (No Luggage)";
            clr = "#fa541c";
            break;
          case 9:
            status = "NO SHOW";
            clr = "#135200";
            break;
          case 10:
            status = "OFF MARKET";
            clr = "#722ed1";
            break;

          default:
            status = "UNKNOWN";
            clr = "grey";
            break;
        }

        return status;
        //   <Tag color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
        //   { status }
        //   </Tag>
      },
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      key: "updated_by",
    },
    {
      title: "Date Updated",
      dataIndex: "updated_date",
      key: "updated_date",
      render: (date) => {
        let _date = date
          ? moment(LocalizationDate(date)).format("HH:mm, DD-MM-YYYY")
          : " - ";

        return _date;
      },
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [datePicked, setDatePicked] = useState(null);
  const [bulks, setBulks] = useState([]);
  // Data Status
  const [status, setStatus] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async (value) => {
    const _date = value;

    await getRoomReckoning({
      type: "room",
      status: "status-history",
      reckon: "room-reckon",
      date: _date ? _date : datePicked,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setBulks([]);
          setData([]);
        }
        // setData(response.data.msg);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  //   useEffect(() => {
  //     let cleanUp = false;

  //     if (date_selected && !cleanUp) {
  //       const _date = moment(date_selected).format("YYYY-MM-DD");
  //       console.log("Date: ", _date);

  //       setDatePicked(_date);
  //     }

  //     return () => {
  //       cleanUp = true;
  //     };
  //   }, [date_selected]);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE REFRESH
  const handleRefresh = () => {
    fetchData(datePicked);
  };

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(data, `Room Reckon ${datePicked}`);
  };

  // GET DATE
  const handleDate = (value) => {
    const _date = moment(value).format("YYYY-MM-DD");

    console.log("Date: ", _date);

    setDatePicked(_date);
    if (_date) {
      fetchData(_date);
    }
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toString() : "";
    console.log("value: ", query);

    var updatedList = [];
    // console.log("Update List = ", updatedList);

    if (bulks.length > 0) {
      updatedList = bulks.filter((item) => {
        return (
          item.updated_by.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      });
      // Trigger render with updated values
      setData(updatedList);
      // console.log("FILTERED => ", updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="room-reckon-main-table" justify="center" style={mainBody}>
        <Col span={24} className="col-table" style={{ padding: 30 }}>
          <Row justify="end" align="middle" className="btn-row">
            <Col span={12} className="date-col">
              <Typography
                style={{ marginBottom: 10 }}
              >{`Pick Date`}</Typography>

              <DatePicker
                allowClear
                placeholder="Date"
                onChange={handleDate}
                style={{
                  width: 300,
                }}
              />
            </Col>

            <Col
              span={12}
              className="refresh-row"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className="print-btn"
                type="primary"
                icon={<FileExcelOutlined />}
                onClick={handlePrint}
                style={{
                  margin: "0px 15px",
                }}
              >
                {`Export`}
              </Button>

              <Button
                className="refresh-btn"
                type="default"
                icon={<SyncOutlined />}
                onClick={handleRefresh}
              >
                {`Refresh`}
              </Button>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="room-qeueu-table"
              name="room-qeueu-table"
              key="room-qeueu-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              // scroll={{
              //     y: 270,
              // }}
              rowKey={(record) => (record.id ? record.id : record?.key)}
              //   rowClassName={(record, index) => {
              //     return index % 2 === 0 ? "odd" : "even";
              //   }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

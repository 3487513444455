import { io } from "socket.io-client";
// Import React's Component
import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Typography,
  Card,
  Tooltip,
  DatePicker,
  Tag,
  Checkbox,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  RightCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib/icons";
import { FiEdit } from "react-icons/fi";
import { MdFastfood, MdOutlineDashboardCustomize } from "react-icons/md";
import { FaKitchenSet } from "react-icons/fa6";

// Import Page Components
import MasterSalesType from "../../../Reusable/Outlet/Master/SalesType/SalesType";
import ModalGuestInHouse from "../../../Modals/Outlet/Guest/GuestInHouse";

// Import Notification
import {
  failedFetch,
  incomplete,
} from "../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import CaptainOrderPopover from "../../../Popover/POSOutlet/CaptainPopover";
import CaptainModalChangeTable from "../../../Modals/Outlet/Order/CaptainChangeTableModal";
import CaptainModalTableOrder from "../../../Modals/Outlet/Order/CaptainModal";
import CaptainModalCancelOrder from "../../../Modals/Outlet/Order/CaptainCancelModal";
import ModalTransferDestination from "../../../Modals/Outlet/Order/CaptainTransferDestination";
import ItemNewOrder from "../../../Modals/Outlet/Order/ItemNewOrder";

// Modal
const { confirm } = Modal;

// CODE
export default function PerTablePage(props) {
  // Props
  const {
    articleItems,
    is_room_service,
    is_created,
    data_from_resv,
    getNewOrder,
    isNewOrder,
    menuDisabled,
  } = props;

  // USE REF
  const componentRef = useRef();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [tableKey, setTableKey] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);
  const [key, setKey] = useState(0); //key digunakan untuk row key table
  const [table, setTable] = useState(null);
  const [transferData, setTransferData] = useState([]);
  const [chargeToRoomState, setChargeToRoomState] = useState(false);
  const [articleSub, setArticleSub] = useState([]);

  // Edit
  const [dataEdit, setDataEdit] = useState(null);
  const [guest, setGuest] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteHeader, setDeleteHeader] = useState(false);
  const [name, setName] = useState(null);
  const [salesType, setSalesType] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [newOrder, setNewOrder] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [orderStatus, setOrderStatus] = useState(0); // 0: New Order, 1: Open Order, 2: Close Order, 3: Transfer Item, 4: Add to New Order

  // Modal
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openModalChangeTable, setOpenModalChangeTable] = useState(false);
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false);
  const [modalDestinationTransfer, setModalDestinationTransfer] = useState(false);
  const [itemOrder, setItemOrder] = useState(false);

  // State
  const [submitState, setSubmitState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [closeState, setCloseState] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [transferState, setTransferState] = useState(false);
  const [addNewState, setAddNewState] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // socket.io
  const socket = io("https://printer-api.hilook.support/", {
    transports: ["websocket"],
    auth: {
      hotel_id: "abcdefg",
    },
    withCredentials: true,
  });

  // CONTEXT
  const { getArticleMaster } = useContext(MasterContext);

  // FETCH ID
  const fetchTable = async () => {
    await axios
      .post(`${baseurl}/pos/order-table`, {
        table_id: table?.table_id ,
      })
      .then((response) => {
        console.log("Table Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
            (e) => {
              if (e.is_deleted == false) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          });

          setKey(_res.length);

          setData(_resMap);
          setDataOrder(response.data.msg[0]);
          handleFormField(response.data.msg[0]);
          fetchArticleSub();
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 100);
      });
  };

  const fetchOrder = async (val) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        order_id_alias: tableKey?.order_id_alias || val,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.i_bill_detail_pos_temp.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          });
          console.log("_resMap", _resMap);

          setKey(_res.length);

          setData(_resMap);
          setDataOrder(response.data.msg[0]);
          handleFormField(response.data.msg[0]);
          fetchArticleSub();
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 100);
      });
  };

  // Fetch Data
  const fetchArticleSub = async () => {
    await getArticleMaster({
      types: "sub-group",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Sub Group => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setArticleSub(filtered);
        } else {
          setArticleSub([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  useEffect(() => {
    console.log("useEffect on PerTableCaptain", data);
  }, []);

  useEffect(() => {
    console.log("tableKey", tableKey)
    if(tableKey?.name?.substr(tableKey?.name?.length - 1) !== "1") {
      if(tableKey?.name){
        setOrderStatus(1);
      }
    } else {
      setOrderStatus(2);
    }

    if(newOrder == false) {
      if (table?.table_id) {
        fetchTable();
      } else {
        fetchOrder();
      }
    }

    // parsing cek pax
    if(tableKey?.name[tableKey?.name?.indexOf(",") - 1]) {
      form.setFieldsValue({
        pax: tableKey?.name[tableKey?.name?.indexOf(",") - 1],
      });
    } else {
      form.setFieldsValue({
        pax: null,
      });
    };

    // parsing cek pax
    if(tableKey?.name[tableKey?.name?.indexOf(",") - 2] == 1) {
      setGuest(tableKey?.name);
    } else {
      setGuest(null);
    };

    return () => {};
  }, [tableKey]);

  useEffect(() => {
    if(!transferState && tableKey?.name?.substr(tableKey?.name?.length - 1) !== "1") {
      menuDisabled(true);
    } else {
      menuDisabled(false);
    }
  }, [transferState, tableKey])

  // useEffect u/ cek nilai variable setiap nilainya berubah
  useEffect(() => { console.log("transferState:", transferState)},[transferState]);
  useEffect(() => { console.log("PerTableCaptain>newOrder:", newOrder)},[newOrder]);
  useEffect(() => { console.log("PerTableCaptain>tableKey:", tableKey)}, [tableKey]);
  useEffect(() => {
    console.log("PerTableCaptain>data:", data)
    console.log("PerTableCaptain>data: edit?", data.some((item) => (item.notes?.substr(item.notes.length - 1) === "1")))
  }, [data]);
  useEffect(() => { console.log("PerTableCaptain>name:", name)}, [name]);
  useEffect(() => { console.log("PerTableCaptain>transferData:", transferData)}, [transferData]);
  useEffect(() => { console.log("PerTableCaptain>orderStatus:", orderStatus)}, [orderStatus]);

  // useEffect u/ mengaktifkan fungsi Button New Captain Order
  useEffect(() => {
    if(data.length > 0 || name != null || salesType != null || tableKey){
      getNewOrder(false);
    }
  },[data, name, salesType, tableKey]);

  useEffect(() => {
    if(isNewOrder) {
      // setNewOrder(isNewOrder);
      handleClear();
    }
  },[isNewOrder]);

  useEffect(() => {
    let cleanUp = false;

    if (articleItems && !cleanUp) {
      console.log("Per Table: ", articleItems);

      setLoading(true);
      handleData(articleItems);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleItems]);

  useEffect(() => {
    form.setFieldsValue({ name: data_from_resv?.name });
  }, [data_from_resv]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex == "request_date" ? (
          <DatePicker
            ref={searchInput}
            format={"YYYY-MM-DD"}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
            }}
            onChange={(e) => {
              setSelectedKeys(e ? [moment(e).format("YYYY-MM-DD")] : []);
              // console.log(e);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      fixed: "left",
      width: 100,
      hidden: orderStatus == 2 || orderStatus == 3 || orderStatus == 4? true : false,
      render: (_, record, index) => {
        return (
          <>
            <Row className="action-btn" justify="center" gutter={[2,0]} style={{ width: "100%" }}>
              <Col>
                <Tooltip title="Edit Item" className="delete-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      if(orderStatus == 2) {
                        null;
                      } else {
                        console.log("ABOUT TO EDIT > ", record);
                        setOpen(true);
                        setDataEdit(record);
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                      // cursor: "not-allowed",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    disabled
                    className="delete-btn"
                    onClick={() => {
                      if(newOrder){
                        let deleteData = data.filter((value, i) => {
                          if (i != index) {
                            return value;
                          }
                        });
                        setData(deleteData);
                      } else {
                        if(orderStatus == 2) {  
                          null
                        } else {
                          console.log("record delete", record);
                          setDataEdit(record);
                          setModalDeleteOrder(true);
                        }
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#F64E60",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
              {/* <Col style={{display: record.notes?.substr(record.notes?.length - 1) === "0" ? "block" : "none"}}>
                <Tooltip title="Cook" className="cook-tooltip">
                  <FaKitchenSet
                    disabled
                    className="cook-btn"
                    onClick={() => {
                      if(newOrder){
                        null
                      } else {
                        if(record.notes?.substr(record.notes?.length - 1) === "0") {
                          handleOrdered(record)
                        } else {
                          null
                        }
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col> */}
              <Col  style={{display: record.notes?.substr(record.notes?.length - 1) === "2" ? "block" : "none"}}>
                <Tooltip title="Served" className="served-tooltip">
                  <MdFastfood
                    disabled
                    className="served-btn"
                    onClick={() => {
                      if(newOrder){
                        null
                      } else {
                        if(record.notes?.substr(record.notes?.length - 1) === "2") {
                          handleCooked(record)
                        } else {
                          null
                        }
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Items",
      dataIndex: "article_name",
      key: "article_name",
      ...getColumnSearchProps("article_name"),
    },
    {
      title: "Serving Que",
      dataIndex: "serving_que",
      key: "serving_que",
      editable: true,
      // render: (qty, record) => {
      //   return qty?.split(' ')[0] || "-";
      // },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      editable: true,
      // render: (qty, record) => {
      //   return record.notes?.substr(record.notes?.length - 1) === "1" ? "Cooked" : record.notes?.substr(record.notes?.length - 1) === "2" ? "Served" : "Ordered" ;
      // },
      render: (text, record) => {
        let color = "";
        let txt = "";

        switch (record.notes?.substr(record.notes?.length - 1)) {
          case "0":
            color = "#2db7f5";
            txt = "Ordered";
            break;
          case "1":
            color = "blue";
            txt = "Change to Cooked";
            break;
          case "2":
            color = "#108ee9";
            txt = "Cooked";
            break;
          case "3":
            color = "green";
            txt = "Change to Served";
            break;
          case "4":
            color = "#87d068";
            txt = "Served";
            break;

          default:
            color = "#d9d9d9";
            txt = "Listed";
            break;
        }

        return (
          <Tag color={color}>
            {txt ? txt.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
      render: (qty, record) => {
        return qty;
      },
    },
    {
      title: "Transfer Qty",
      dataIndex: "transfer_qty",
      key: "transfer_qty",
      editable: true,
      hidden: orderStatus == 3 ? false : true,
      render: (text, record) => {
        return <InputNumber onChange={((val) => handleTransferData(val, record.key))} defaultValue={0} min={0} max={record.item_quantity} />;
      },
    },
    {
      title: "Added Qty",
      dataIndex: "added_qty",
      key: "added_qty",
      editable: true,
      hidden: orderStatus == 4 ? false : true,
      render: (text, record) => {
        return <InputNumber onChange={((val) => handleAddedData(val, record.key))} defaultValue={0} min={0} max={record.item_quantity} />;
      },
    },
  ].filter(item => !item.hidden);

  // Handle Data
  const handleData = (value) => {
    let prev = value;
    
    let _prev = {
        ...prev,
        serving_que: prev.serving_id,
    };

    console.log("_prev", _prev);

    console.log("prev", prev);

    if (newOrder) {
      if (data.length > 0) {
        console.log("Data Per-Table Length: ", data);
  
        let double = data
         .map((x) => {
            if(x.article_id === _prev?.id && x.serving_id === _prev?.serving_id){
              if (_prev?.notes == null) {
                return {
                  ...x,
                  item_quantity: x?.item_quantity + _prev?.item_quantity,
                  article_id: _prev?.id
                };
              } else {
                if (x.notes == null || x.notes == "undefined") {
                  return {
                    ...x,
                    item_quantity: x.item_quantity + _prev?.item_quantity,
                    notes: _prev?.notes,
                  };
                } else {
                  return {
                    ...x,
                    item_quantity: x.item_quantity + _prev?.item_quantity,
                    notes: x.notes + ", " + _prev?.notes,
                  };
                }
              }
              
            } else {
              return x
            }
            
          });
  
        // console.log("DOUBLE: ", _double);
        // console.log("DOUBLE is NOT: ", _notDoubled);
  
        if (data.some((item) => item.article_id === _prev?.id && item.serving_id === _prev?.serving_id)) {
          console.log("Ada data yang sama")
          setData(double);
        } else {
          console.log("Tidak ada data yg sama")
          setData([...data, { ..._prev, article_id: _prev?.id, key: key }]);
          setKey(key+1);
        }
      } else {
        console.log("Data Pertama");
        setData(_prev ? [{ ..._prev, article_id: _prev?.id, key: key }] : []);
        setKey(key+1);
      }
    } else {
      setEditState(true);
      if (data.length > 0) {
        console.log("Data Per-Table Length Edit: ", data);
  
        let double = data
         .map((x) => {
            if(x.article_id === _prev?.id && x.serving_que == _prev?.serving_que && x.notes?.slice(-1) !== "0" && x.notes?.slice(-1) !== "1" && x.notes?.slice(-1) !== "2" && x.notes?.slice(-1) !== "3" && x.notes?.slice(-1) !== "4"){
              if (_prev?.notes == null) {
                return {
                  ...x,
                  item_quantity: x?.item_quantity + _prev?.item_quantity,
                  article_id: _prev?.id
                };
              } else {
                if (x.notes == null || x.notes == "undefined") {
                  return {
                    ...x,
                    item_quantity: x.item_quantity + _prev?.item_quantity,
                    notes: _prev?.notes,
                  };
                } else {
                  return {
                    ...x,
                    item_quantity: x.item_quantity + _prev?.item_quantity,
                    notes: x.notes + ", " + _prev?.notes,
                  };
                }
              }
              
            } else {
              return x
            }
            
          });
  
        // console.log("DOUBLE: ", _double);
        // console.log("DOUBLE is NOT: ", _notDoubled);
  
        if (data.some((item) => item.article_id === _prev?.id && item.serving_que == _prev?.serving_que  && item.notes?.slice(-1) !== "0" && item.notes?.slice(-1) !== "1" && item.notes?.slice(-1) !== "2" && item.notes?.slice(-1) !== "3" && item.notes?.slice(-1) !== "4")) {
          console.log("Ada data yang sama")
          setData(double);
        } else {
          console.log("Tidak ada data yg sama")
          setData([...data, { ..._prev, article_id: _prev?.id, key: key }]);
          setKey(key+1);
        }
      } else {
        console.log("Data Pertama");
        setData(_prev ? [{ ..._prev, article_id: _prev?.id, key: key }] : []);
        setKey(key+1);
      }
    }

    setLoading(false);
  };

  // console.log(data);

  // HANDLE EDIT
  const handleEdit = (value) => {
    console.log("Data Edited: ", value);

    let updateData = data.map((item) => {
        console.log("item.key", item.key);
        console.log("value", value);
      if(item.key === value.key) {
        return {
          ...item,
          item_quantity: value.item_quantity,
          serving_id: value.serving_id,
          serving_que: value.serving_id,
          notes: value.notes,
          deleted_reason: value.deleted_reason,
        }
      } else {
        return item
      }
    })
    console.log("update", updateData);
    setEditState(true);
    setData(updateData);

  };

  // HANDLE ORDERED
  const handleOrdered = (value) => {
    let updateData = data.map((item) => {
        console.log("item.key", item.key);
        console.log("value.key", value.key);
      if(item.key === value.key) {
        return {
          ...item,
          deleted_reason: "CHANGE TO COOKED",
          notes: value?.notes?.slice(0, -1) + "1",
        }
      } else {
        return item
      }
    })
    console.log("update", updateData);
    setEditState(true);
    setData(updateData);
  }

  // HANDLE COOKED
  const handleCooked = (value) => {
    let updateData = data.map((item) => {
        console.log("item.key", item.key);
        console.log("value.key", value.key);
      if(item.key === value.key) {
        return {
          ...item,
          deleted_reason: "ITEM SERVED",
          notes: value?.notes?.slice(0, -1) + "3",
        }
      } else {
        return item
      }
    })
    console.log("update", updateData);
    setEditState(true);
    setData(updateData);
  }

  // GET GUEST NAME
  const getGuestName = (value) => {
    const _guest = value;
    console.log("getGuestName > value", _guest);

    setGuest(_guest);
    if(_guest?.length > 0) {
      setName(_guest[0]?.guest_name);
    } else {
      setName(_guest.guest_name);
    }

    form.setFieldsValue({
      name: _guest?.length > 0 ? _guest[0]?.guest_name : _guest.guest_name,
      reservation_id: _guest?.length > 0 ? _guest[0]?.reservation_id : _guest.reservation_id,
      payment_status: _guest[0]?.payment_status == 1 ? false : true,
    });
  };

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;

    form.setFieldsValue({
      name: _record?.name,
      sales_type: _record?.sales_type,
      payment_status: _record?.payment_status != 1 ? true : false,
    });
  };

  // Handle Sales Type
  const getSalesType = (value) => {
    console.log("perTableCaptain > getSalesType > ", value);

    form.setFieldsValue({
      sales_type: value?.value ?? null,
    });

    if(value?.value) {
      setSalesType(value?.value);
    } else {
      setSalesType(null);
    }

    if (value?.value == 1) {
      setOpenModalChangeTable(true);
    } else {
      setTable(null);
    }

    if(orderStatus == 1){
      setEditState(true);
    }
  };

  // Open Modal Guest In House
  const handleGuestName = () => {
    setModalOpen(true);
  };

  // ON FINISH
  const onFinish = (value) => {
    const _data = value ?? form.getFieldsValue();
    setIsLoading(true);

    console.log("On Finish: ", _data);
    
    showConfirmModal(_data);
  };

  // SHOW CONFIRM MODAL, untuk menampilkan modal konfirmasi submit, update, atau close order
  const showConfirmModal = (value) => {
    const _data = value;

    console.log("tableKey showConfirmModal", tableKey);

    confirm({
      className: "confirm",
      title: `Captain Order Confirmation`,
      content: submitState ? `Are you sure want to Submit an Order for ${( _data?.name || " NON STAY GUEST " ).toUpperCase()} ${table?.table_code ? "with "+ table?.table_code.toUpperCase() : "" }`
              : updateState ? `Are you sure want to Update an Order for ${( _data?.name || " NON STAY GUEST " ).toUpperCase()} ${table?.table_code ? "with "+ table?.table_code.toUpperCase() : "" }`
              : closeState ? `Are you sure want to Close an Order for ${( _data?.name || " NON STAY GUEST " ).toUpperCase()} ${table?.table_code ? "with "+ table?.table_code.toUpperCase() : "" }`
              : openState ? `Are you sure want to Open Order for ${( _data?.name || " NON STAY GUEST " ).toUpperCase()} ${table?.table_code ? "with "+ table?.table_code.toUpperCase() : "" }`
              : "null",
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        if(submitState) {
          handleSubmit(_data);
        } else if(updateState) {
          handleUpdate(_data);
        } else if(closeState) {
          handleClose(_data);
        } else if(openState) {
          handleOpen(_data);
        } else {
          null;
        };
        setSubmitState(false);
        setUpdateState(false);
        setCloseState(false);
        setOpenState(false);
        Modal.destroyAll();
      },
      
      onCancel() {
        setIsLoading(false);
        setSubmitState(false);
        setUpdateState(false);
        setCloseState(false);
        setOpenState(false);
        Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _data = value;
    let _totalNett = 0;
    let _totalTax = 0;
    let _totalSer = 0;
    let _totalGross = 0;

    data.forEach(({ unit_price, tax, service, gross, item_quantity }) => {
      let _tGross = gross * item_quantity;
      let _tTax = tax * item_quantity;
      let _tSer = service * item_quantity;
      let _tNett = unit_price * item_quantity;

      _totalNett += _tNett;
      _totalSer += _tSer;
      _totalTax += _tTax;
      _totalGross += _tGross;
    });

    let source = guest == null ? 0 : 1;

    // console.log("About TO ORDER: ", _data);
    // console.log("TABLE SELECTED: ", tableKey);

    await axios
      .post(
        `${baseurl}/pos/order-header`,
        {
          name: _data?.name ? _data?.name + source + _data?.pax + "," : "NON STAY GUEST" + source + _data?.pax + ",",
          table_code: table?.table_code || "No Table",
          table_id: table?.table_id || null,
          payment_type: null,
          payment_details: null,
          payment_status: _data?.payment_status != true ? 1 : 2,
          total_nett: _totalNett,
          total_gross: _totalGross,
          total_tax: _totalTax,
          total_service: _totalSer,
          order_step: 1,
          is_open_bill: 1,
          sales_type: _data?.sales_type,
          order_id_alias: data_from_resv?.order_id_alias ?? null,
          reservation_id: _data?.reservation_id,
          created_by: user_name,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then(async (response) => {
        console.log("Add Headers: ", response);
        if (response.status === 200) {
          masterSuccessNotification(response, {
            method: 0,
            source: `Captain Order`,
          });
          await axios
            .get(`${baseurl}/pos/last-order-id-header`)
            .then((res) => {
              console.log("Res Last ID: ", res);

              
              setNewOrder(false);

              if (res?.data?.length > 0) {
                handleDetail(res.data[0], _data);
              }
            })
            .catch((error) => {
              console.log("Res Last Err: ", error);
            });
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: `Captain Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: `Captain Order`,
        });
      });
  };

  const handleUpdate = async (value) => {
    const _data = value;

    const obj = {
      id: data[0]?.header_id,
      name: _data?.name ?? "NON STAY GUEST",
      table_code: table?.table_code,
      table_id: table?.table_id,
      payment_type: null,
      payment_details: null,
      payment_status: _data?.payment_status != true ? 1 : 2,

      total_nett: data
        .map((e) => ({
          nett: e.unit_price * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.nett, 0),

      total_gross: data
        .map((e) => ({
          gross: e.gross * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.gross, 0),

      total_tax: data
        .map((e) => ({
          tax: e.tax * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.tax, 0),

      total_service: data
        .map((e) => ({
          service: e.service * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.service, 0),

      order_step: data[0]?.order_step,
      is_open_bill: null,
      sales_type: _data?.sales_type,
      updated_by: user_name,
      old_table_code: data[0]?.table_code,
      old_table_id: data[0]?.table_id,
      reservation_id: _data?.reservation_id,
    };
    console.log("handleUpdate > obj:", obj);

    await axios
      .put(
        `${baseurl}/pos/order-header`, obj,
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )

      .then(async (response) => {
        if (response.status === 200) {
          console.log("Edit Header: ", response);
          // masterSuccessNotification(response, {
          //   method: 1,
          //   source: `Captain Order`,
          // });
          handleDetail();
        } else {
          masterFailedNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 1,
          source: `Captain Order`,
        });
      })
  }

  // Modify
  const handleClose = async (value) => {
    const _data = value;

    const obj = {
      id: data[0]?.header_id,
      name: _data?.name + "1" ?? "NON STAY GUEST" + "1",
      table_code: table?.table_code,
      table_id: table?.table_id,
      payment_type: null,
      payment_details: null,
      payment_status: _data?.payment_status != true ? 1 : 2,

      total_nett: data
        .map((e) => ({
          nett: e.unit_price * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.nett, 0),

      total_gross: data
        .map((e) => ({
          gross: e.gross * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.gross, 0),

      total_tax: data
        .map((e) => ({
          tax: e.tax * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.tax, 0),

      total_service: data
        .map((e) => ({
          service: e.service * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.service, 0),

      order_step: data[0]?.order_step,
      is_open_bill: null,
      sales_type: _data?.sales_type,
      updated_by: user_name,
      old_table_code: data[0]?.table_code,
      old_table_id: data[0]?.table_id,
      reservation_id: _data?.reservation_id,
    };
    console.log("handleUpdate > obj:", obj);

    await axios
      .put(
        `${baseurl}/pos/order-header`, obj,
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )

      .then(async (response) => {
        if (response.status === 200) {
          console.log("Edit Header: ", response);
          masterSuccessNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
        } else {
          masterFailedNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 1,
          source: `Captain Order`,
        });
      })
      .finally(() => {
        handleClear();
      })
  };
  // End Modify

  // Before
  // const handleClose = (value) => {
  //   console.log("handleClose", value);
  //   setIsLoading(false);
  // }
  // End Before

  const handlePrintKitchenOrder = async (value) => {
    const _data = value;

    const obj = {
      id: data[0]?.header_id,
      name: _data?.name ?? "NON STAY GUEST",
      table_code: table?.table_code,
      table_id: table?.table_id,
      payment_type: null,
      payment_details: null,
      payment_status: _data?.payment_status != true ? 1 : 2,

      total_nett: data
        .map((e) => ({
          nett: e.unit_price * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.nett, 0),

      total_gross: data
        .map((e) => ({
          gross: e.gross * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.gross, 0),

      total_tax: data
        .map((e) => ({
          tax: e.tax * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.tax, 0),

      total_service: data
        .map((e) => ({
          service: e.service * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.service, 0),

      order_step: data[0]?.order_step,
      is_open_bill: null,
      sales_type: _data?.sales_type,
      updated_by: user_name,
      old_table_code: data[0]?.table_code,
      old_table_id: data[0]?.table_id,
      reservation_id: _data?.reservation_id,
    };
    console.log("handleUpdate > obj:", obj);

    await axios
      .put(
        `${baseurl}/pos/order-header`, obj,
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )

      .then(async (response) => {
        if (response.status === 200) {
          console.log("Edit Header: ", response);
          masterSuccessNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
          await axios
            .get(`${baseurl}/pos/last-order-id-header`)
            .then((res) => {
              console.log("Res Last ID: ", res);

              
              setNewOrder(false);

              if (res?.data?.length > 0) {
                handleUpdatePrintDetail(data);
              }
            })
            .catch((error) => {
              console.log("Res Last Err: ", error);
            });
        } else {
          masterFailedNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 1,
          source: `Captain Order`,
        });
      })
  }

  const handleOpen = async (value) => {
    const _data = value;

    const obj = {
      id: data[0]?.header_id,
      // name: _data?.name + "1" ?? "NON STAY GUEST" + "1",
      name: _data?.name?.length > 0 &&  _data?.name?.slice(-1) === "1" ? _data?.name?.slice(0, -1) : _data?.name,
      table_code: table?.table_code,
      table_id: table?.table_id,
      payment_type: null,
      payment_details: null,
      payment_status: _data?.payment_status != true ? 1 : 2,

      total_nett: data
        .map((e) => ({
          nett: e.unit_price * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.nett, 0),

      total_gross: data
        .map((e) => ({
          gross: e.gross * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.gross, 0),

      total_tax: data
        .map((e) => ({
          tax: e.tax * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.tax, 0),

      total_service: data
        .map((e) => ({
          service: e.service * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.service, 0),

      order_step: data[0]?.order_step,
      is_open_bill: null,
      sales_type: _data?.sales_type,
      updated_by: user_name,
      old_table_code: data[0]?.table_code,
      old_table_id: data[0]?.table_id,
      reservation_id: _data?.reservation_id,
    };
    console.log("handleUpdate > obj:", obj);

    await axios
      .put(
        `${baseurl}/pos/order-header`, obj,
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )

      .then(async (response) => {
        if (response.status === 200) {
          console.log("Edit Header: ", response);
          masterSuccessNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
        } else {
          masterFailedNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 1,
          source: `Captain Order`,
        });
      })
      .finally(() => {
        handleClear();
      })
  };

  // Handle Details
  const handleDetail = async (value, record) => {
    const _bill_id = value;
    let bulks = [];

    for (let i = 0; i < data.length; i++) {
      if (newOrder) {
        bulks.push({
          id: data[i]?.id,
          article_id: data[i]?.article_id,
          article_sub_name: data[i]?.article_sub_name,
          article_sub_id: data[i]?.article_sub_id,
          article_name: data[i]?.article_name,
          unit_price: data[i]?.unit_price,
          item_quantity: data[i]?.item_quantity,
          total_price: data[i]?.gross * data[i]?.item_quantity,
          table_code: table?.table_code,
          table_id: table?.table_id,
          serving_order: data[i]?.serving_que,
          guest_name: record?.name ?? "NON STAY GUEST",
          chart_of_account: data[i]?.chart_of_account,
          tax: data[i]?.tax,
          service: data[i]?.service,
          is_tax: true,
          is_service: true,
          order_step: 0,
          gross: data[i]?.gross,
          add_ons: data[i]?.notes || null,
          // notes: data[i]?.notes || null,
          notes: data[i]?.notes ? data[i]?.notes + "0" : "0",
          payment_status: record?.payment_status !== true ? 1 : 2,
          order_id_alias: _bill_id?.order_id_alias,
          header_id: _bill_id?.id,
          created_by: user_name,
        });
      } else {
        // Pengondisian, eksekusi jika tidak memiliki header dan tidak ada article_id sama-serving_que sama-berstatus Ordered
        if(!data[i]?.header_id && !data.some((item) => item.article_id == data[i]?.article_id && item.serving_que == data[i]?.serving_que && item.notes?.slice(-1) === "0")) {
          console.log("UPDATE PER ITEM", data.some((item) => item.article_id == data[i]?.article_id && item.serving_que == data[i]?.serving_que && item.notes?.slice(-1) === "0"));
          bulks.push({
            id: data[i]?.id,
            article_id: data[i]?.article_id,
            article_sub_name: data[i]?.article_sub_name,
            article_sub_id: data[i]?.article_sub_id,
            article_name: data[i]?.article_name,
            unit_price: data[i]?.unit_price,
            item_quantity: data[i]?.item_quantity,
            total_price: data[i]?.gross * data[i]?.item_quantity,
            table_code: table?.table_code,
            table_id: table?.table_id,
            serving_order: data[i]?.serving_que,
            guest_name: record?.name ?? "NON STAY GUEST",
            chart_of_account: data[i]?.chart_of_account,
            tax: data[i]?.tax,
            service: data[i]?.service,
            is_tax: true,
            is_service: true,
            order_step: 0,
            gross: data[i]?.gross,
            add_ons: data[i]?.notes || null,
            notes: data[i]?.notes ? data[i]?.notes + "0" : "0",
            payment_status: record?.payment_status !== true ? 1 : 2,
            order_id_alias: tableKey?.order_id_alias,
            header_id: tableKey?.id,
            created_by: user_name,
          });
        }
      }
    }

    // console.log("LAST RES ID: ", _bill_id);
    console.log("handleDetail > bulks:", bulks);

    await axios
      .post(
        `${baseurl}/pos/order-detail`,
        {
          data: bulks.length > 0 ? bulks : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Add Detail: ", response);

        if (response.status === 200) {
          if (newOrder) {
            masterSuccessNotification(response, {
              method: 0,
              source: `Captain Order Item`,
            });
            handleClear();
          } else {
            handleUpdateDetail(data);
          }
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: `Captain Order Item`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: `Captain Order Item`,
        });
      })
  };

  const handleUpdateDetail = async (e) => {
    let updateDetail = []
    let deleteDetail = []
    
    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Between Ordered And Listed
    // Untuk filter status Ordered
    const dataFilterOrdered = e.filter((x) => {
      if(x.notes?.slice(-1) == "0") {
        return true
      } else {
        return false
      }
    });

    // Untuk filter status Listed
    const dataFilterListed = e.filter((x) => {
      if(!x.header_id && isNaN(parseInt(x.notes?.slice(-1)))) {
        return true
      } else {
        return false
      }
    });

    console.log("dataFilterOrdered", dataFilterOrdered);
    console.log("dataFilterListed", dataFilterListed);
    
    // Untuk filter dataFilterOrdered yang memiliki data di dataFilterListed berstatus Listed dimana article_id sama, serving_que sama 
    const dataSpecified = dataFilterOrdered.filter((x) => {
      if(dataFilterListed.map(e => parseInt(e.article_id)).indexOf(parseInt(x.article_id)) > -1 && dataFilterListed.map(e => parseInt(e.serving_que)).indexOf(parseInt(x.serving_que)) > -1) {
        return true
      } else {
        return false
      }
    });
    
    console.log("dataSpecified", dataSpecified);

    // Untuk menambah item_quantity pada data Ordered jika memliki data lain berstatus Listed
    const dataSpecifiedMap = dataSpecified.map((x) => {
      const dataItem = dataFilterListed.filter((y) => {
        if(parseInt(x.serving_que) == parseInt(y.serving_que) && parseInt(x.article_id) == parseInt(y.article_id)){
          return true
        } else {
          return false
        }
      })[0];

      return {
        ...x,
        item_quantity: dataItem?.item_quantity ? x.item_quantity + dataItem.item_quantity : x.item_quantity,
        notes: dataItem?.notes ? x.notes + dataItem.notes + "0" : x.notes,
      }
    });

    console.log("dataSpecifiedMap", dataSpecifiedMap);

    if(dataSpecifiedMap.length > 0) {
      dataSpecifiedMap.map((x) => {
        updateDetail.push(x)
      });
    };

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Between Change to Cooked And Cooked
    // Untuk filter status Cooked
    const dataFilterCooked = e.filter((x) => {
      if(x.notes?.slice(-1) == "2") {
        return true
      } else {
        return false
      }
    });

    // Untuk filter status Change to Cooked
    const dataFilterChangeCooked = e.filter((x) => {
      if(x.notes?.slice(-1) == "1") {
        return true
      } else {
        return false
      }
    });

    console.log("dataFilterCooked", dataFilterCooked);
    console.log("dataFilterChangeCooked", dataFilterChangeCooked);

    // Data untuk Kitchen Order dan Bar Order
    const filterSubFood = articleSub.filter((x) => x.article_main_id == 2 );

    console.log("filterSubFood", filterSubFood);

    const filterSubBeverage = articleSub.filter((x) => x.article_main_id == 3 );

    console.log("filterSubBeverage", filterSubBeverage);

    const filterKitchenOrder = dataFilterChangeCooked.filter((x) => {
      if(filterSubFood.some((item) => (item.id === x.article_sub_id))){
        return true;
      } else {return false;}
    });

    console.log("filterKitchenOrder", filterKitchenOrder);
    
    const filterBarOrder = dataFilterChangeCooked.filter((x) => {
      if(filterSubBeverage.some((item) => (item.id === x.article_sub_id))){
        return true;
      } else {return false;}
    });
    
    console.log("filterBarOrder", filterBarOrder);
    
    if(filterKitchenOrder.length > 0) {
      let item = [];

      let sales_type= tableKey.sales_type == 1 ? "Dine-In" : tableKey.sales_type == 2 ? "Take Away" : tableKey.sales_type == 3 ? "Online Order" : "No Data";

      let bodyKitchenOrder = {
        order_id_alias : tableKey.order_id_alias,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        sales_type: sales_type,
        table_code : tableKey.table_code,
        printer_id: 1,
      };


      filterKitchenOrder.map((x) => {
        item.push(x)
      });

      bodyKitchenOrder.item = item;

      socket.emit("message", bodyKitchenOrder);

      // Result Body Kitchen Order
      console.log("bodyKitchenOrder", bodyKitchenOrder);
    }

    if(filterBarOrder.length > 0) {
      let item = [];

      let sales_type= tableKey.sales_type == 1 ? "Dine-In" : tableKey.sales_type == 2 ? "Take Away" : tableKey.sales_type == 3 ? "Online Order" : "No Data";

      let bodyBarOrder = {
        order_id_alias : tableKey.order_id_alias,
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        sales_type: sales_type,
        table_code : tableKey.table_code,
        printer_id: 3,
      };


      filterBarOrder.map((x) => {
        item.push(x)
      });

      bodyBarOrder.item = item;

      socket.emit("message", bodyBarOrder);

      // Result Body Kitchen Order
      console.log("bodyBarOrder", bodyBarOrder);
    }

    // Untuk filter data bertatus Cooked yang memiliki data lain berstatus Change to Cooked dimana article_id sama, serving_que sama 
    const dataSpecifiedCooked = dataFilterCooked.filter((x) => {
      if(dataFilterChangeCooked.map(e => parseInt(e.article_id)).indexOf(parseInt(x.article_id)) > -1 && dataFilterChangeCooked.map(e => parseInt(e.serving_que)).indexOf(parseInt(x.serving_que)) > -1) {
        return true
      } else {
        return false
      }
    });

    console.log("dataSpecifiedCooked", dataSpecifiedCooked);

    // Untuk menambah item_quantity pada data Cooked jika memliki data lain berstatus Change to Cooked
    const dataSpecifiedCookedMap = dataSpecifiedCooked.map((x) => {
      // Untuk mendapatkan item_quantity pada data berstatus Change to Cooked dimana article_id sama, serving_que sama 
      const dataItem = dataFilterChangeCooked.filter((y) => {
        if(parseInt(x.serving_que) == parseInt(y.serving_que) && parseInt(x.article_id) == parseInt(y.article_id)){
          return true
        } else {
          return false
        }
      })[0];

      return {
        ...x,
        item_quantity: dataItem?.item_quantity ? x.item_quantity + dataItem.item_quantity : x.item_quantity,
        notes: dataItem?.notes ? x.notes + dataItem.notes + "2" : x.notes,
      }
    });

    console.log("dataSpecifiedCookedMap", dataSpecifiedCookedMap);

    // Untuk filter data bertatus Change to Cooked yang memiliki data lain berstatus Cooked dimana article_id sama, serving_que sama. Tujuannya untuk menghapus item.
    const itemDeleteChangeCooked = dataFilterChangeCooked.filter((x) => {
      if(dataFilterCooked.map(e => parseInt(e.article_id)).indexOf(parseInt(x.article_id)) > -1 && dataFilterCooked.map(e => parseInt(e.serving_que)).indexOf(parseInt(x.serving_que)) > -1) {
        return true
      } else {
        return false
      }
    });

    console.log("itemDeleteChangeCooked", itemDeleteChangeCooked);

    if(dataSpecifiedCookedMap.length > 0) {
      dataSpecifiedCookedMap.map((x) => {
        updateDetail.push(x)
      });
    };

    if(itemDeleteChangeCooked.length > 0) {
      itemDeleteChangeCooked.map((x) => {
        deleteDetail.push(x)
      });
    };

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Between Change to Served And Served
    // Untuk filter status Served
    const dataFilterServed = e.filter((x) => {
      if(x.notes?.slice(-1) == "4") {
        return true
      } else {
        return false
      }
    });

    // Untuk filter status Change to Served
    const dataFilterChangeServed = e.filter((x) => {
      if(x.notes?.slice(-1) == "3") {
        return true
      } else {
        return false
      }
    });

    console.log("dataFilterServed", dataFilterServed);
    console.log("dataFilterChangeServed", dataFilterChangeServed);

    // Untuk filter data bertatus Cooked yang memiliki data lain berstatus Change to Cooked dimana article_id sama, serving_que sama 
    const dataSpecifiedServed = dataFilterServed.filter((x) => {
      if(dataFilterChangeServed.map(e => parseInt(e.article_id)).indexOf(parseInt(x.article_id)) > -1 && dataFilterChangeServed.map(e => parseInt(e.serving_que)).indexOf(parseInt(x.serving_que)) > -1) {
        return true
      } else {
        return false
      }
    });

    console.log("dataSpecifiedServed", dataSpecifiedServed);

    // Untuk menambah item_quantity pada data Cooked jika memliki data lain berstatus Change to Cooked
    const dataSpecifiedServedMap = dataSpecifiedServed.map((x) => {
      // Untuk mendapatkan item_quantity pada data berstatus Change to Cooked dimana article_id sama, serving_que sama 
      const dataItem = dataFilterChangeServed.filter((y) => {
        if(parseInt(x.serving_que) == parseInt(y.serving_que) && parseInt(x.article_id) == parseInt(y.article_id)){
          return true
        } else {
          return false
        }
      })[0];

      return {
        ...x,
        item_quantity: dataItem?.item_quantity ? x.item_quantity + dataItem.item_quantity : x.item_quantity,
        notes: dataItem?.notes ? x.notes + dataItem.notes + "4" : x.notes,
      }
    });

    console.log("dataSpecifiedServedMap", dataSpecifiedServedMap);

    // Untuk filter data bertatus Change to Cooked yang memiliki data lain berstatus Cooked dimana article_id sama, serving_que sama. Tujuannya untuk menghapus item.
    const itemDeleteChangeServed = dataFilterChangeServed.filter((x) => {
      if(dataFilterServed.map(e => parseInt(e.article_id)).indexOf(parseInt(x.article_id)) > -1 && dataFilterServed.map(e => parseInt(e.serving_que)).indexOf(parseInt(x.serving_que)) > -1) {
        return true
      } else {
        return false
      }
    });

    console.log("itemDeleteChangeServed", itemDeleteChangeServed);

    if(dataSpecifiedServedMap.length > 0) {
      dataSpecifiedServedMap.map((x) => {
        updateDetail.push(x)
      });
    };

    if(itemDeleteChangeServed.length > 0) {
      itemDeleteChangeServed.map((x) => {
        deleteDetail.push(x)
      });
    };

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Other Conditions
    const detailEdited = e.filter((x) => {
      const checkCooked = dataSpecifiedCookedMap.some(y => y.article_id === x.article_id && y.serving_que == x.serving_que && x.notes?.slice(-1) == "1");
      const checkServed = dataSpecifiedServedMap.some(y => y.article_id === x.article_id && y.serving_que == x.serving_que && x.notes?.slice(-1) == "3");
      
      if(x.header_id && x.deleted_reason && dataSpecifiedMap.map(z => parseInt(z.key)).indexOf(parseInt(x.key)) == -1 && !checkCooked && !checkServed) {
        return true
      } else {
        return false
      }
    });

    const detailEditedMap = detailEdited.map((x) => {
      if(x.notes?.slice(-1) === "1") {
        return {
          ...x,
          notes: x.notes + "2"
        }
      } else if(x.notes?.slice(-1) === "3") {
        return {
          ...x,
          notes: x.notes + "4"
        }
      } else {
        return x
      }
    });

    console.log("detailEditedMap", detailEditedMap);

    if(detailEditedMap.length > 0) {
      detailEditedMap.map((x) => {
        updateDetail.push(x)
      });
    };

    console.log("updateDetail", updateDetail);

    if(updateDetail.length > 0) {
      for (let i = 0; i < updateDetail.length; i++) {
        await axios
          .put(
            `${baseurl}/pos/order-detail`,
            {
              deleted_reason: updateDetail[i].deleted_reason, //Available
              notes: updateDetail[i].notes ?? null, //Available
              id: updateDetail[i].id,
              created_by: user_name,
              item_quantity: updateDetail[i].item_quantity,
              serving_order: updateDetail[i].serving_que,
              total_price: updateDetail[i].gross * updateDetail[i].item_quantity,
              tax: updateDetail[i].tax,
              service: updateDetail[i].service,
              is_tax: updateDetail[i].is_tax,
              is_service: updateDetail[i].is_service,
              gross: updateDetail[i].gross,
              add_ons: updateDetail[i].notes ?? null,
            },
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("Update Detail: ", response);
              // masterSuccessNotification(response, {
              //   method: 1,
              //   source: `Captain Order Item`,
              // });
              let successCount = i + 1;
              if(successCount == updateDetail.length) {
                handleDeleteDetail(deleteDetail);
              }
            } else {
              masterFailedNotification(response, {
                method: 1,
                source: `Captain Order Item`,
              });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            masterFailedNotification(error, {
              method: 1,
              source: `Captain Order Item`,
            });
          })
      }
    } else {
      masterSuccessNotification("", {
        method: 1,
        source: `Captain Order Item`,
      });
      handleClear();
    }

  };

  const handleDeleteDetail = async (e) => {
    if(e.length > 0){
      for (let i = 0; i < e.length; i++) {
        let obj =
          {
            id: e[i].id,
            order_id_alias: e[i].order_id_alias,
            deleted_by: user_name,
            deleted_reason: "OTHERS",
          };
        
        console.log("obj", obj)
        await axios
          .delete(`${baseurl}/pos/order-detail`, {
            data: obj,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("DEL POS Bill Detail >> ", response);
              let successCount = i + 1;
              if(successCount == e.length){
                masterSuccessNotification(response, {
                  method: 2,
                  source: `Captain Order Item`,
                });
              }
              // fetchTable();
            }
          })
          .catch((error) => {
            console.log("ERROR DEL POS Bill Detail >> ", error);
            masterFailedNotification(error, {
              method: 2,
              source: `Captain Order Item`,
            });
          });
      }
      handleClear();
    } else {
      masterSuccessNotification("", {
        method: 1,
        source: `Captain Order Item`,
      });
      handleClear();
    }
  };

  const handleUpdatePrintDetail = async (e) => {
    const updateDetail = e.filter((x) => {
      if(x.notes?.substr(x.notes?.length - 1) === "0") {
        return true
      } else {
        return false
      }
    });

    console.log("updateDetail handleUpdatePrint", updateDetail);
    
    for (let i = 0; i < updateDetail.length; i++) {
      await axios
          .put(
            `${baseurl}/pos/order-detail`,
            {
              deleted_reason: "ITEM PRINTED", //Available
              notes: updateDetail[i].notes == null ? "1" : updateDetail[i].notes + "1", //Available
              id: updateDetail[i].id,
              created_by: user_name,
              item_quantity: updateDetail[i].item_quantity,
              serving_order: updateDetail[i].serving_que,
              total_price: updateDetail[i].gross * updateDetail[i].item_quantity,
              tax: updateDetail[i].tax,
              service: updateDetail[i].service,
              is_tax: updateDetail[i].is_tax,
              is_service: updateDetail[i].is_service,
              gross: updateDetail[i].gross,
              add_ons: updateDetail[i].notes ?? null,
            },
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("Update Detail: ", response);
              masterSuccessNotification(response, {
                method: 1,
                source: `Captain Order Item`,
              });
            } else {
              masterFailedNotification(response, {
                method: 1,
                source: `Captain Order Item`,
              });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            masterFailedNotification(error, {
              method: 1,
              source: `Captain Order Item`,
            });
          })
    }
    handleClear();
  }

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _error = value;

    console.log("On Finish FAILED: ", _error);
    incomplete(_error);
  };

  console.log("tableKey >>>", tableKey);
  console.log("FORM >>>", form.getFieldsValue());

  // HANDLE NAME ONCHANGE
  const handleChangeName = (value) => {
    // console.log("handleChangeName", value.target.value);
    setGuest(null);
    form.setFieldsValue({
      payment_status: false
    })
    
    if(value.target.value) {
      setName(value.target.value);
    } else {
      setName(null);
    }
  };

  // HANDLE CLEAR
  const handleClear = () => {
    setLoading(true);
    setData([]);
    setDataEdit([]);
    setNewOrder(true);
    setTableKey(null);
    setDataOrder([]);
    setKey(0);
    setIsLoading(false);
    setDeleteHeader(false);
    setName(null);
    setSalesType(null);
    setTable(null);
    form.resetFields();
    setEditState(false);
    setSubmitState(false);
    setUpdateState(false);
    setCloseState(false)
    setOpenState(false);
    setOrderStatus(0);
    setAddNewState(false);
    setTransferState(false);
    setTransferData([]);
    setModalDestinationTransfer(false);
    setItemOrder(false);
    setRefresh(!refresh);
    setGuest(null);
    setTimeout(() => setLoading(false), 100);
  }

  // HANDLE TRANSFER DATA
  const handleTransferData = (transferQty, item) => {
    console.log("qtyTf", transferQty, "item", item);
    let transfer = data.map((x) => {
      if(x.key === item) {
        return {
          ...x,
          transfer_quantity: transferQty,
        }
      } else {
        return x
      }
    });
    setData(transfer);
  };

  // HANDLE TRANSFER DATA
  const handleAddedData = (addedQty, item) => {
    console.log("addedQty", addedQty, "item", item);
    let added = data.map((x) => {
      if(x.key === item) {
        return {
          ...x,
          added_quantity: addedQty,
        }
      } else {
        return x
      }
    });
    setData(added);
  };

  // handlePax
  const handlePax = () => {
    if(orderStatus == 1){
      setEditState(true);
    }
  };

  return (
    <>
      <Row className="row-pertable" style={mainBody}>
        <Col
          ref={componentRef}
          className="table-col"
          span={24}
          style={{ width: "100%", padding: "0px 30px 30px"}}
        >
          <Card
            className="table-card"
            bordered
            // loading={cardLoad}
            loading={loading}
            style={{
              width: "100%",
              // marginTop: 5,
            }}
            title={
              <Row justify="center" align="middle" className="title-row">
                <Col span={24} className="title-col">
                  <Typography
                    className="bill-txt"
                    key={"txt-bill"}
                    style={{ fontSize: 20, fontWeight: 500 }}
                  >
                    {data_from_resv?.order_id_alias ||
                      dataOrder?.order_id_alias ||
                      "New Order"}
                  </Typography>
                </Col>
              </Row>
            }
            headStyle={{
              textAlign: "center",
              background: "#e4e6ef",
            }}
            bodyStyle={{
              textAlign: "center",
              display: table?.table_id || table?.room_id ?  "block" : "none",
            }}
          >
            <Typography
              className="bill-txt"
              key={"txt-bill"}
              style={{ fontSize: 20 }}
            >
              {table?.table_id || table?.room_id || "No Table"}
            </Typography>
          </Card>
          <Card
            className="table-card"
            bordered
            // loading={cardLoad}
            style={{
              width: "100%",
              // marginTop: 5,
            }}
            bodyStyle={{
              textAlign: "center",
              display: dataOrder?.order_id_alias ? "block" : "none",
              // background: dataOrder?.name?.substr(dataOrder?.name.length - 1) === "1" ? "#1890ff" : "#87d068",
              background: orderStatus == 1 ? "#87d068" : "#1890ff",
              marginTop: 0,
            }}
          >
            <Typography
              className="bill-txt"
              key={"txt-bill"}
              style={{ fontSize: 20, fontWeight:500, color: "#ffffff" }}
            >
              { orderStatus == 1 ? "OPEN" : orderStatus == 2 ? "CLOSE" : orderStatus == 3 ? "TRANSFER ITEM" : orderStatus == 4 ? "ADD TO NEW ITEM" : "OTHERS"}
            </Typography>
          </Card>

          <Form
            form={form}
            className="order-form"
            key="order-form"
            name="order-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            style={{
              padding: "15px 0px 0px",
              margin: 0,
            }}
          >
            <Row className="mainmodal-row" justify="start">
              <Col className="mainmodal-col" span={24}>
                <Row className="name-row" justify="start" gutter={30}>
                  <Col span={9} className="name-col">
                    <Form.Item
                      label="Name"
                      name="name"
                    >
                      <Input
                        className="name-input"
                        placeholder="Name"
                        disabled={newOrder ? false : true}
                        onChange={handleChangeName}
                        addonAfter={
                          <Tooltip
                            className="modal-tooltip"
                            title="Find Guest In-House"
                          >
                            <MdOutlineDashboardCustomize
                              className="suffix-icons"
                              onClick={newOrder ? handleGuestName : null}
                              style={{
                                fontSize: 20,
                                color: "#3699FF",
                                marginTop: 3,
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                    <Form.Item label="" name="reservation_id" hidden />
                  </Col>

                  <Col span={9} className="sales-col">
                    <Form.Item
                      label="Sales Type"
                      name="sales_type"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input the Sales Type!",
                        },
                      ]}
                    >
                      <MasterSalesType
                        getSalesType={getSalesType}
                        sales_type={
                          {
                            1: "Dine-In",
                            2: "Take Away",
                            3: "Online Order",
                          }[form.getFieldsValue().sales_type] ?? null
                        }
                        disabled={orderStatus == 2 || orderStatus == 3 || orderStatus == 4 ? true : false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} className="pax-col">
                    <Form.Item
                      label="Pax"
                      name="pax"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input Pax Number!",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Pax"
                        onChange={handlePax}
                        max={9}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Row justify="end" align="bottom" style={{ marginTop: 35 }}>
                      <Space>
                        <Tooltip title="Option Menu" className="option-tooltip">
                          <CaptainOrderPopover
                            headers={{
                              ...form.getFieldsValue(),
                              payment_status:
                                form.getFieldsValue().payment_status != true
                                  ? 1
                                  : 2,
                            }}
                            details={data}
                            selectedTable={(e) => {
                              console.log("tableKey", e)
                              setLoading(true);
                              setNewOrder(false);
                              setEditState(false);
                              setTableKey(e);
                              setTable(e);
                              setTransferState(false)
                              setAddNewState(false)
                            }}
                            newOrder={newOrder}
                            editState={editState}
                            getTransferState={() => {setTransferState(true); setOrderStatus(3); setAddNewState(false); setTransferData([])}}
                            getAddNewState={() => {setAddNewState(true); setOrderStatus(4); setTransferState(false);}}
                            orderStatus = {orderStatus}
                            getClear = {handleClear}
                          />
                        </Tooltip>
                      </Space>
                    </Row>
                  </Col>
                </Row>
                <Row
                  className="pax-row"
                  justify="start"
                  align="top"
                  gutter={30}
                >
                  <Col span={9} className="check-col">
                    <Form.Item
                      label=""
                      name="payment_status"
                      valuePropName="checked"
                      
                    >
                      <Checkbox
                        className="pax-input"
                        placeholder="Total Pax"
                        min={0}
                        disabled
                        onChange={(e) => {
                          if (e == true) {
                            setChargeToRoomState(true);
                          }
                        }}
                        style={{
                          width: "100%",
                        }}
                      >
                        {`Charge To Room`}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={9} className="check-col">
                    {guest == null ? <Tag color="#2db7f5">Source: Walk In Guest</Tag> : <Tag color="#108ee9">Source: Guest In House</Tag>}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Form.Item
              name="article_list"
              label=""
              rules={[
                {
                  validator: async (keys) => {
                    form.setFieldsValue({
                      article_list: data,
                    });

                    if (data.length === 0) {
                      return Promise.reject("Please, Select a Menu!");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Table
                className="ordertable-tbl"
                name="ordertable-tbl"
                key="ordertable=tbl"
                loading={loading}
                columns={columns}
                dataSource={data}
                bordered
                size="medium"
                pagination={false}
                scroll={{
                  x: true,
                }}
                rowKey={(record) => record?.key}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
              />
            </Form.Item>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row className="row-modal-btn" justify="end" align="middle">
              <Button
                type="primary"
                htmlType="submit"
                key={"submit-save"}
                onClick={() => setSubmitState(true)}
                icon={
                  <RightCircleOutlined
                    className="pay-icon"
                    style={{
                      fontSize: 20,
                      margin: "0px 0px 0px",
                    }}
                  />
                }
                hidden={newOrder ? false : true}
                loading={isLoading}
                // className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Submit Order`}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                key={"update"}
                icon={
                  <RightCircleOutlined
                    className="update-icon"
                    style={{
                      fontSize: 20,
                      margin: "0px 0px 0px",
                    }}
                  />
                }
                onClick={() => setUpdateState(true)}
                hidden={newOrder == false && editState == true ? false : true}
                loading={isLoading}
                // className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Update Order`}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                key={"submit-close"}
                // hidden={newOrder == false && editState == false && data.every((item) => (item.notes?.substr(item.notes.length - 1) === "4")) && tableKey?.name?.substr(tableKey?.name?.length - 1) !== "1"? false : true}
                hidden={orderStatus == 1 && editState == false && data.every((item) => (item.notes?.substr(item.notes.length - 1) === "4")) ? false : true}
                onClick={() => setCloseState(true)}
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Close Order`}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                key={"submit-open"}
                hidden={orderStatus == 2 ? false : true}
                onClick={() => setOpenState(true)}
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Open Order`}
              </Button>

              <Button
                type="primary"
                hidden={orderStatus == 3 ? false : true}
                onClick={() => {
                  if(data.filter((x) => {
                    if(x.transfer_quantity > 0) {
                      return true
                    } else {
                      return false
                    }
                    }).length > 0) {
                      setModalDestinationTransfer(true);
                  } else {
                    incomplete({errorFields:[{errors: ["Please, set Transfer Quantity!"]}]});
                  }
                }
                }
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Transfer Item`}
              </Button>

              <Button
                type="primary"
                hidden={orderStatus == 4 ? false : true}
                onClick={() => {
                  if(data.filter((x) => {
                    if(x.added_quantity > 0) {
                      return true
                    } else {
                      return false
                    }
                    }).length > 0) {
                      setItemOrder(true);
                  } else {
                    incomplete({errorFields:[{errors: ["Please, set Added Quantity!"]}]});
                  }
                }
                }
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Add to New Order`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                disabled = {data.length > 0 ? false : true}
                onClick={(e) => {
                  if(newOrder) {
                    handleClear();
                  } else {
                    setDataEdit({
                      order_id_alias:
                        dataOrder?.order_id_alias
                    });
                    setModalDeleteOrder(true);
                    setDeleteHeader(true);
                  }
                }}
              >
                {`Cancel Order`}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>

      <CaptainModalTableOrder
        openModal={open}
        closeModal={setOpen}
        articleAll={data}
        articleItems={dataEdit}
        newOrder={newOrder}
        articleTable={handleEdit}
      />

      <CaptainModalCancelOrder
        openModal={modalDeleteOrder}
        closeModal={setModalDeleteOrder}
        deleteItem={dataEdit}
        isHeader={deleteHeader}
        is_refresh={(e) => {
          if (deleteHeader) {
            handleClear();
          } else {
            // fetchOrder();
            console.log("record dataEdit", dataEdit);
            let _deletedEdit = data.filter((value) => {
              if(value.article_id == dataEdit.article_id && value.serving_que == dataEdit.serving_que && value.notes.slice(-1) == dataEdit.notes.slice(-1)) {
                console.log("false article_id >> ", value.article_id, dataEdit.article_id);
                console.log("false serving_que >> ", value.serving_que, dataEdit.serving_que);
                return false
              } else {
                console.log("true article_id >> ", value.article_id, dataEdit.article_id);
                console.log("true serving_que >> ", value.serving_que, dataEdit.serving_que);
                return true
              };
            });
            setData(_deletedEdit);
          }
        }}
      />

      <ModalGuestInHouse
        openModal={modalOpen}
        closeModal={setModalOpen}
        getGuestName={getGuestName}
        refresh={refresh}
      />

      <CaptainModalChangeTable
        openModal={openModalChangeTable}
        closeModal={setOpenModalChangeTable}
        selectedTable={(e) => {
          // setChangeTableState(true);
          setTable(e);
        }}
      />

      <ModalTransferDestination
        openModal={modalDestinationTransfer}
        closeModal={handleClear}
        data={data}
        orderId={dataOrder?.order_id_alias}
      />

      <ItemNewOrder
        openModal={itemOrder}
        closeModal={setItemOrder}
        getClear={handleClear}
        data={data}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaCashRegister } from "react-icons/fa";
import { IoCalendar } from "react-icons/io5";
import { FiRefreshCw } from "react-icons/fi";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import JournalDetailTable from "../../../Components/Table/Accounting/Journal/Details/Table";
import JournalHeaderTable from "../../../Components/Table/Accounting/Journal/Headers/Table";
// import RoomCategoryTable from "../../../Components/Table/Master/RoomCategory/Table";
// import RatePeriodicTable from "../../../Components/Table/Master/RatePeriodic/Table";
// import PosCashierBillTable from "../../../Components/Table/Outlet/PosCashier/Bill";
// import PosCashierSplitBillTable from "../../../Components/Table/Outlet/PosCashier/SplitBill";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function JournalListPage() {
  // STATE MANAGAMENT
  // Search Key
  const [value, setValue] = useState("");
  // Search
  const [search, setSearch] = useState();
  // Rate
  const [dataDetail, setDataDetail] = useState();

  // Handle Change
  const handleSearch = (val) => {
    const _date = val;
    console.log("Date Picked: ", _date);

    setValue(_date);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="journal-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <BsFillJournalBookmarkFill
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Journal List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Date`}</Typography>

                <DatePicker
                  allowClear
                  placeholder="Pick a Date"
                  onChange={handleSearch}
                  style={{ minWidth: 150 }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Row gutter={[30, 30]} className="row-rate-table">
            <Col
              className="col-header"
              // span={12}
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <JournalHeaderTable
                is_search={setSearch}
                searchKey={value}
                dataDetail={setDataDetail}
              />
            </Col>

            <Col
              // span={12}
              className="col-details"
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <JournalDetailTable
                is_search={setSearch}
                searchKey={value}
                dataDetail={dataDetail}
              />
            </Col>
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useEffect, useState, useContext } from "react";

// Import Ant Design Components
import { Row, Col, Layout, Typography, Input } from "antd";
import { Content } from "antd/lib/layout/layout";
import { TbTable } from "react-icons/tb";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// Import Page Components
import InitialAccountingTable from "../../../../Components/Table/Accounting/Initial/Table";

// CODE
export default function InitialTablePage() {
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="initial-table-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <TbTable
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />

              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`INITIAL ACCOUNTING TABLE`}
              </Typography>
            </Row>

            <Row className="row-body" justify="start" style={cardBody}>
              <Col
                style={{
                  textAlign: "start",
                  margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Types`}</Typography>

                <Input
                  allowClear
                  placeholder="Types"
                  //   onChange={handleSearch}
                  style={{
                    width: 300,
                  }}
                />
              </Col>
            </Row>

            <InitialAccountingTable />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// Import Antd Components
import {
  Card,
  Col,
  Row,
  Form,
  Typography,
  DatePicker,
  InputNumber,
  Button,
  List,
  Divider,
  Skeleton,
} from "antd";

// Import React Icons
import {
  PlusOutlined,
  DeleteOutlined,
  DeleteFilled,
  SwitcherFilled,
  SwitcherTwoTone,
  UpCircleOutlined,
  DownCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";
import ManagerDashboard from "../Dashboard/Dashboard";

const { Title } = Typography;

const marginLayout = {
  marginTop: 30,
};
const styleColor = {
  backgroundColor: "#fff",
  borderRadius: 10,
};

// DATA LIST
const dataList = [
  {
    key: 1,
    title: "7 DAYS ADVANCE PURCHASE, GET 20% OFF ",
    description:
      "Good things come to those who book early. 7 days advance purchase, get 20% off",
    imageSource: "/assets/img/foto-dashboard-1.jpg",
  },
  {
    key: 2,
    title: "BEST PICKS",
    description:
      "2 pax of Lunch/Dinner bento box for first night only, Complimentary 1x Arrival OR Departure Airport Transfer (max 2 adult and 2 child below 12 years old /car), Free 1 dessert/snacks at hotel restaurant",
    imageSource: "/assets/img/foto-dashboard-2.jpg",
  },
  {
    key: 3,
    title: "SCHOOL HOLIDEALS",
    description:
      "20% OFF! Big savings for big memories, Including Breakfast for 2 persons, Valid for reservations via the hotel website and direct reservations via phone/email only (not valid for walk-ins)",
    imageSource: "/assets/img/foto-dashboard-3.jpg",
  },
];

// CODE
export default function ResManager({ props }) {
  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // USE NAVIGATE
  const navigate = useNavigate();

  // Date
  const [startDate, setStartDate] = useState(moment(date));
  const [endDate, setEndDate] = useState(moment(date));
  // Adult Value
  const [people, setPeople] = useState(0);
  // Data
  const [dataSet, setDataSet] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  /*
  useEffect(() => {
    // console.log("KONTOL => ", dataSet);

    if (dataSet) {
      navigate("/channel-manager/room-availability", {
        state: dataSet,
        //  {
        //   arrival: dataSet.arrival,
        //   departure: dataSet.departure,
        //   adult: dataSet.adult,
        //   total_child: dataSet.child,
        //   infant: dataSet.infant,
        // },
      });
    }
  }, [dataSet]); */

  // HANDLE START DATE AND END DATE
  // Get Start Date
  const handleFirstDate = (first) => {
    const start = moment(first.format()).toArray();
    setStartDate(start);
    // console.log("start date", start);

    if (endDate) {
      var ngt = moment(endDate).diff(start, "days");
      form.setFieldsValue({
        night: ngt,
        room_qty: 1,
        adult: 1,
      });
      console.log("Start = ", ngt);
    }
  };

  // Get End Date
  const handleLastDate = (last) => {
    const end = moment(last.format()).toArray();

    setEndDate(end);
    // console.log("last date", end);

    var ngt = moment(end).diff(startDate, "days");

    // console.log(ngt);

    form.setFieldsValue({
      night: ngt,
      room_qty: 1,
      adult: 1,
    });
  };
  // GET ADULT
  const getAdult = (value) => {
    // console.log(value);
    setPeople(value);

    form.setFieldsValue({
      // child: value,
      adult: value,
      // infant: value,
    });
  };
  // GET CHILD
  const getChild = (value) => {
    // console.log(value);

    form.setFieldsValue({
      child: value,
      // adult: value,
      // infant: value,
    });
  };
  // GET ADULT
  const getInfant = (value) => {
    // console.log(value);

    form.setFieldsValue({
      // child: value,
      // adult: value,
      infant: value,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("On Finish --> ", content);
    setDataSet(content);
    // move(content);
    navigate("/channel-manager/room-availability", {
      // state: value,
      replace: true,
      state: {
        data: {
          arrival: moment(content.arrival).format("YYYY-MM-DD"),
          departure: moment(content.departure).format("YYYY-MM-DD"),
          adult: content?.adult,
          total_child: content?.child || 0,
          infant: content?.infant || 0,
          person: content.adult + (content?.child > 0 ? content.child : 0),
          night: moment(content.departure).diff(content.arrival, "days"),
          is_manager: "Yes",
        },
        article: null,
        guest: null,
      },
    });
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    const failed = error;

    console.log(failed);
  };

  const move = (content) => {
    navigate("/channel-manager/room-availability", {
      state: content,
    });
  };

  console.log("PROPS", props);

  return (
    <>
      <Card
        title={
          "Official Website: Best Rate Guarantee Reservation Channel Manager"
        }
        style={{
          borderRadius: 5,
        }}
      >
        <Typography className="text-subcard">
          For reservation, please select dates and accomodations required!
        </Typography>
      </Card>

      <Row gutter={[30, 30]} style={marginLayout}>
        <Col
          xxl={{ span: 6, offset: 0 }}
          xl={{ span: 6, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 6 }}
          sm={24}
          xs={24}
        >
          <Row
            style={{
              backgroundColor: "#FFFFFF",
              minHeight: 450,
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Col span={24} style={{ padding: 30 }}>
              <Row>
                <Title level={4}>Select Date</Title>
              </Row>

              <Divider
                className="divider-form"
                style={{
                  backgroundColor: "#000000",
                  margin: "10px 0px",
                }}
              />

              <Form
                name="reservation-dashboard-channel-manager-form"
                className="reservation-dashboard-channel-manager-form"
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{ padding: "0px 0px 0px 0px" }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Arrival"
                      name="arrival"
                      rules={[
                        {
                          required: true,
                          message: "Please, Select Arrival Date!",
                        },
                      ]}
                      // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                    >
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        onChange={handleFirstDate}
                        disabledDate={disabledDate}
                        placeholder={"Start Date"}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Departure"
                      name="departure"
                      rules={[
                        {
                          required: true,
                          message: "Please, Select Departure Date!",
                        },
                        {
                          validator: async (_, departure) => {
                            var arrival = form.getFieldValue("arrival");
                            // console.log("ARRIVAL => ", arrival);
                            // console.log("DEPART => ", departure);
                            // console.log("DIFF", (departure = arrival));

                            if (arrival != null)
                              if (departure <= moment(arrival).add(1, "days")) {
                                return Promise.reject(
                                  "Departure Date Must be Greater than Arrival Date!"
                                );
                              }
                          },
                        },
                      ]}
                      // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                    >
                      <DatePicker
                        format={"YYYY-MM-DD"}
                        placeholder={"End Date"}
                        disabledDate={disabledDate}
                        onChange={handleLastDate}
                        style={{
                          minWidth: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="row-form" gutter={[5, 25]}>
                  <Col xxl={8} xl={24}>
                    <Form.Item
                      label="Adult"
                      name="adult"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input Total Guest!",
                        },
                        {
                          validator: async (_, adult) => {
                            var qty = form.getFieldValue("room_qty");
                            // console.log("ARRIVAL => ", arrival);
                            // console.log("DEPART => ", departure);
                            // console.log("DIFF", (departure = arrival));

                            if (qty != null)
                              if (adult <= qty - 1) {
                                return Promise.reject(
                                  "Adult guest must be greater or equals to Room Quantity."
                                );
                              }
                          },
                        },
                      ]}
                      style={{ margin: "0px 30px 0px 0px" }}
                    >
                      <InputNumber
                        size="large"
                        controls={<PlusOutlined />}
                        onChange={getAdult}
                        placeholder="Total Adult"
                        style={{ width: 90 }}
                      />
                      {/* <MasterInputAdult
                    getAdult={getAdult}
                    // adultValue={people}
                  /> */}
                    </Form.Item>
                  </Col>

                  <Col xxl={8} xl={24}>
                    <Form.Item
                      label="Child"
                      name="child"
                      style={{ margin: "0px 30px 0px 0px" }}
                    >
                      <InputNumber
                        size="large"
                        min={0}
                        max={10}
                        placeholder="Total Child"
                        style={{ width: 90 }}
                      />
                      {/* <MasterInputChild getChild={getChild} /> */}
                    </Form.Item>
                  </Col>

                  <Col xxl={8} xl={24}>
                    <Form.Item label="Infant" name="infant">
                      <InputNumber
                        size="large"
                        min={0}
                        max={10}
                        placeholder="Total Infant"
                        style={{ width: 90 }}
                      />
                      {/* <MasterInputInfant getInfant={getInfant} /> */}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Button
                    type="primary"
                    htmlType="submit"
                    key={"submit"}
                    // onClick={onFinish}
                    className="submit-btn"
                    style={{
                      backgroundColor: "#1BC5BD",
                      borderColor: "#1BC5BD",
                      width: "100%",
                    }}
                  >
                    Check Availability
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>

        <Col xxl={18} xl={18} lg={24} md={24} sm={24} xs={24}>
          <ManagerDashboard />
        </Col>
      </Row>
    </>
  );
}

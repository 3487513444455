// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

// AUTH
import { sub_module } from "../../../../API/Global/Payload";

// Import Page Components
import { Button, Card, Col, Layout, Row, Typography } from "antd";

// Import Icons
import { DollarCircleOutlined } from "@ant-design/icons";
import { MdOutlineApproval } from "react-icons/md";
import { Content } from "antd/lib/layout/layout";
import { BiSolidCoinStack } from "react-icons/bi";

// Global Styles
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../Style/Global/Global";

// CODE
export default function BudgetMainPage() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_module?.length > 0 ? sub_module : []; // values.module;

    // const proRoute = subRoute.map((value) => {
    // console.log("ID Mods: ", value);
    // console.log("ID Mods: ", _mods);

    //   if (_mods.includes(value.id_modules)) {
    //     return (
    //       <Route path={value.path} element={value.element} key={value.name} />
    //     );
    //   } else {
    //     return (
    //       <Route
    //         path={value.path}
    // element={<UnAuthorized />}
    //         key={value.name}
    //       />
    //     );
    //   }
    // });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    // setProtectedRoutes(proRoute);
  };

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="report-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <BiSolidCoinStack
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Budgets`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col
                xxl={8}
                xl={8}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="card-col"
                key="col-keys-list"
              >
                <Card
                  key="budget-cards"
                  className="card-link"
                  style={gridStyle}
                >
                  <Button
                    className="move-btn"
                    onClick={() => move("budget-list")}
                    icon={<DollarCircleOutlined style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Budget List`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col
                xxl={8}
                xl={8}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className="card-col"
                key="col-keys-approve"
              >
                <Card
                  key="budget-cards"
                  className="card-link"
                  style={gridStyle}
                >
                  <Button
                    className="move-btn"
                    onClick={() => move("budget-approval")}
                    icon={<MdOutlineApproval style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Budget Approval`}
                    </Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { BiMessageSquareMinus, BiMessageSquareAdd } from "react-icons/bi";
import { HiSquaresPlus } from "react-icons/hi2";

// Import Page Components
import TreeAktiva from "../../../Tree/BSTemplate/TreeAktiva";
import TreePassiva from "../../../Tree/BSTemplate/TreePassiva";
import { mainBody } from "../../../../Style/Global/Global";

// Import Global Styles

// Table's Column

const dataSource = [
  {
    key: "1",
    inventory_no: "res/123/123",
    inventory_name: "Kulkas",
    uom: "Pieces",
    dunit: "Pieces",
    content: "Pieces",
    runit: "Pieces",
    min_stock: "",
    actual_price: 11000,
    average_price: 12000,
    created_by: "adminlook",
    modified_by: "adminlook",
  },
];

const { TextArea } = Input;

const onChangeSelect = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};
const onChangeTextArea = (e) => {
  console.log("Change:", e.target.value);
};

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// const onChange = (pagination, filters, sorter, extra) => {
//     console.log('params', pagination, filters, sorter, extra);
// };

// CODE
export default function BSTemplateTable() {
  // CONTEXT
  const { state } = useContext(MasterContext);

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // STATE MANAGEMENT
  //const [open, setOpen] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  const onCreate = (values) => {
    console.log("Received values of form: ", values);
    // setOpen(false);
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleSubmit = (e) => {
    // setArticleInventoryModal(false);
    // setEditArticleInventoryModal(false);
    console.log("ON OK ==> ", e);
  };
  // useEffect(() => {
  //     fetchRecords()
  // }, [])

  // const fetchRecords = () => {
  //     setLoading(true)
  //     fetch(`http://192.168.88.103:3100/supabase/reservation`).then(res => {
  //         res.json().then(response => {
  //             setDataSource(response.msg)
  //             setLoading(false)
  //             //console.log(response)
  //         }).catch((error) => { console.log(error) });
  //     })
  // }

  return (
    <>
      <Row
        className="bs-template-main"
        justify="center"
        gutter={[30, 30]}
        style={{ marginTop: 30, height: "100%" }}
      >
        <Col
          className="col-layout"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{
            borderRadius: 5,
            // minHeight: 900,
          }}
        >
          <Row
            className="bill-template-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              marginBottom: 1,
              borderRadius: "5px 5px 0px 0px",
              // height: 500,
            }}
          >
            <BiMessageSquareAdd
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`AKTIVA`}
            </Typography>
          </Row>

          <TreeAktiva />
        </Col>

        <Col
          className="col-layout"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{
            borderRadius: 5,
            // minHeight: 900,
          }}
        >
          <Row
            className="bill-template-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              marginBottom: 1,
              borderRadius: "5px 5px 0px 0px",
              // height: 500,
            }}
          >
            <BiMessageSquareMinus
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`PASSIVA`}
            </Typography>
          </Row>

          <TreePassiva />
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// Import Page Components
import FOReportPage from "../../../Pages/Report/FrontOffice/Main/MainPage";
import CancelledPage from "../../../Pages/Report/FrontOffice/Cancelled/Cancelled";
import NoShowPage from "../../../Pages/Report/FrontOffice/NoShow/NoShow";
import NotFound from "../../../Pages/NotFound/NotFound";
import ReservationReportPage from "../../../Pages/Report/FrontOffice/Reservation/Reservation";
import HistoryReportPage from "../../../Pages/Report/FrontOffice/History/History";
import ArrivalReportPage from "../../../Pages/Report/FrontOffice/Arrival/Arrival";
import DepartureReportPage from "../../../Pages/Report/FrontOffice/Departure/Departure";
import InhouseReportPage from "../../../Pages/Report/FrontOffice/Inhouse/Inhouse";
import ReCheckInPage from "../../../Pages/Report/FrontOffice/ReCheckIn/ReCheckIn";
import ExtendedNightPage from "../../../Pages/Report/FrontOffice/Extended/Extended";
import EarlyCOPage from "../../../Pages/Report/FrontOffice/EarlyCO/EarlyCO";
import ReCheckOutPage from "../../../Pages/Report/FrontOffice/ReCheckOut/ReCheckOut";
// Pages
import UnAuthorized from "../../../Pages/Unauthorized/Unauthorized";

// CODE
export default function FOReportRoutes() {
  return (
    <>
      <Routes>
        {/* ebyqu */}
        <Route path="/" element={<FOReportPage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/reservation-list" element={<ReservationReportPage />} />
        <Route path="/arrival-list" element={<ArrivalReportPage />} />
        <Route path="/inhouse-list" element={<InhouseReportPage />} />
        <Route path="/departure-list" element={<DepartureReportPage />} />
        <Route path="/history-guest" element={<HistoryReportPage />} />
        <Route path="/cancel-reservation" element={<CancelledPage />} />
        <Route path="/noshow-reservation" element={<NoShowPage />} />
        <Route path="/extended-reservation" element={<ExtendedNightPage />} />
        <Route path="/early-check-out" element={<EarlyCOPage />} />
        <Route
          path="/recheck-in-check-out-guest"
          element={<ReCheckOutPage />}
        />
        <Route path="/reactivate-reservations" element={<ReCheckInPage />} />
      </Routes>
      <Outlet />
    </>
  );
}

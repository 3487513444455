// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Antd Components
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  CheckCircleFilled,
  CheckOutlined,
  LockOutlined,
  DeleteFilled,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import { FaCircle, FaCircleDot, FaDotCircle } from "react-icons/fa";

// Modal
const { confirm, info, warning } = Modal;
const { Text } = Typography;

// Options
const options = [
  {
    label: "Master Card",
    value: "Master Card",
    key: 1,
    source: "/assets/img/master.PNG",
    chart_of_account: "120-01-050",
  },
  {
    label: "American Express",
    value: "American Express",
    key: 2,
    source: "/assets/img/amex.PNG",
    chart_of_account: "120-01-060",
  },
  {
    label: "VISA",
    value: "Visa",
    key: 3,
    source: "/assets/img/visa.PNG",
    chart_of_account: "120-01-040",
  },
  {
    label: "JCB",
    value: "JCB",
    key: 4,
    source: "/assets/img/jcb.PNG",
    chart_of_account: "120-01-070",
  },
];

// Image Styles
const imgStyles = {
  width: 32,
  height: 20,
  marginRight: 10,
};
const styleText1 = {
  color: "#b3b3b3",
  fontSize: 14,
  fontWeight: 500,
};
const styleText2 = {
  color: "#EE9D01",
  fontSize: 18,
  fontWeight: 500,
};
const cardItemStyle = {
  margin: "0px 0px 0px 0px",
  borderRadius: 12,
  padding: 10,
  paddingLeft: 30,
  width: "100%",
  backgroundColor: "#fafafa",
  boxShadow: "5px 5px 5px #d9d9d9",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  minHeight: 150,
};

// CODE
export default function PaymentGateway() {
  // USE LOCATION
  const { state } = useLocation();

  console.log("State --> ", state);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(state?.data ? state.data : null);
  const [article, setArticle] = useState(state?.article ? state.article : []);
  const [guest, setGuest] = useState(state?.guest ? state.guest : null);
  const [total, setTotal] = useState(0);
  // CARD
  const [card, setCard] = useState(null);
  const [coa, setCoa] = useState(null);
  const [source, setSource] = useState("/assets/img/template.jpg");
  // Modal
  const [open, setOpen] = useState(false);
  const [modalProcess, setModalProcess] = useState(false);

  // Use Navigate
  const navigate = useNavigate();

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    setTotal(
      data.price + article.reduce((n, { total_price }) => n + total_price, 0)
    );
  }, []);

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("On Finish --> ", content);
    modalConfirm(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (err) => {
    const failed = err;
    console.log("On Finish Failed --> ", failed);
  };

  // HANDLE CARD TYPE
  const handleCard = (value, index, key) => {
    console.log(value, index.source, key);

    setCard(value);

    setSource(index.source);
    setCoa(index.chart_of_account);
  };

  // HANDLE CANCEL MODAL
  const handleCancel = () => {
    setOpen(false);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const content = value;

    await axios
      .post(`${baseurl}/channel-manager/guest/card`, {
        full_name: guest.full_name,
        first_name: guest.name,
        id_card_num: guest.id_card_number,
        email: guest.email,
        phone_number: guest.phone_number,
        is_cm: true,
        country: guest.country,
        province: guest?.province ? guest.province : "",
        city: guest?.city ? guest.city : "",
        address: guest.address,
        zip_code: guest?.zip_code ? guest.zip_code : null,
        fax: "",
        card_type: guest.id_card_type,
      })
      .then((res) => {
        if (res == null) {
        }
        console.log("Res --> ", res);

        handleReserve(res, content);

        // setTimeout(() => {
        //   navigate("/channel-manager/reservation-success", {
        //     state: {
        //       data: data,
        //       article: article,
        //       guest: guest,
        //       gateway: content,
        //       message: {
        //         data: res.data,
        //         stat: res.status,
        //         statText: res.statusText,
        //       },
        //     },
        //   });
        // }, 10000);
      })
      .catch((err) => {
        console.log("Error --> ", err);

        setTimeout(() => {
          setModalProcess(false);
          navigate("/channel-manager/reservation-failed", {
            state: {
              data: data,
              article: article,
              guest: guest,
              gateway: content,
              message: {
                data: err.data,
                stat: err.status,
                statText: err.statusText,
              },
            },
          });
        });
      }, 5000);
  };

  const handleReserve = async (val, subm) => {
    const res = val;
    const submitData = subm;

    console.log("Handle Res --> ", res);

    await axios
      .post(`${baseurl}/channel-manager/reservation/stay`, {
        guest_name: guest.full_name,
        room_category: data.room_category_name,
        arrival: moment(data.arrival),
        departure: moment(data.departure),
        night: data.night,
        room_qty: data.room_qty,
        adult: data.adult,
        child: data.total_child,
        infant: data.infant,
        reservation_status: "Guaranteed",
        arrangement: "ABF",
        rate_code: data.rate_code,
        compliment_type: "Room Only",
        letter: "",
        payment_type: "Credit Card",
        payment_details: submitData.card_type,
        memo_room: guest.memo_room,
        is_group: false,
        group_name: "",
        guest_category: "REGULAR",
        room_price: data.price,
        room_rate_id: data.room_rate_id,
        source: "Internet Booking",
        price_id: data.price_id,
        deposit: 0,
        guest_id: res.data.msg,
        // master_bill: false,
        // cash_basis: false,
        // currency: "IDR",
        // segment: "FIT-ONLINE BOOKING",
        // group: 0,
        created_by: "CHANNEL MANAGER",
      })
      .then((res) => {
        console.log("Res --> ", res);

        handlePayment(res, submitData);

        // setTimeout(() => {
        //   navigate("/channel-manager/reservation-success", {
        //     state: {
        //       data: data,
        //       article: article,
        //       guest: guest,
        //       gateway: submitData,
        //       message: {
        //         data: res.data,
        //         stat: res.status,
        //         statText: res.statusText,
        //       },
        //     },
        //   });
        // }, 10000);
      })
      .catch((err) => {
        console.log("Error --> ", err);

        setTimeout(() => {
          setModalProcess(false);
          navigate("/channel-manager/reservation-failed", {
            state: {
              data: data,
              article: article,
              guest: guest,
              gateway: submitData,
              message: {
                data: err.response.data,
                stat: err.response.status,
                statText: err.response.statusText,
              },
            },
          });
        });
      }, 10000);
  };

  const handlePayment = async (val, subm) => {
    const contentSubmit = subm;

    console.log("Res --> ", val);
    console.log("Value --> ", contentSubmit);

    axios
      .post(`${baseurl}/channel-manager/payment-gateway`, {
        card_type: contentSubmit?.card_type || "",
        card_number: contentSubmit?.card_number || 0,
        card_owner: contentSubmit?.card_owner || "",
        exp_date: contentSubmit?.exp_date ? contentSubmit.exp_date : 0,
        ccv_2: contentSubmit?.ccv_2 ? contentSubmit.ccv_2 : 0,
        is_agree: contentSubmit?.is_agree ? contentSubmit.is_agree : true,
        amount: data?.price ? data.price : 0,
        created_by: "CHANNEL MANAGER",
        chart_of_account: coa ? coa : "",
      })
      .then((res) => {
        console.log(res);

        setTimeout(() => {
          setModalProcess(false);
          navigate("/channel-manager/reservation-success", {
            state: {
              data: data,
              article: article,
              guest: guest,
              gateway: contentSubmit,
              message: {
                data: res.data,
                stat: res.status,
                statText: res.statusText,
              },
            },
          });
        }, 10000);
      })
      .catch((err) => {
        console.log("Error --> ", err);
        setTimeout(() => {
          setModalProcess(false);
          navigate("/channel-manager/reservation-failed", {
            state: {
              data: data,
              article: article,
              guest: guest,
              gateway: contentSubmit,
              message: {
                data: err.response.data,
                stat: err.response.status,
                statText: err.response.statusText,
              },
            },
          });
        });
      }, 10000);
  };

  // Modal Confirm
  const modalConfirm = (value) => {
    const content = value;

    confirm({
      className: "submit-confirm",
      title: `Are you sure that the data you are about to submit is correct?`,
      //   icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      open: false,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // move(content);
        showModalProcess(content);
        handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const showModalProcess = async (value) => {
    const content = value;
    setModalProcess(true);

    // confirm({
    //   className: "submit-confirm",
    //   title: `We are processing your Reservation Request! Please, wait until the process are done and do not close this modal!`,
    //   //   icon: <ExclamationCircleTwoTone twoToneColor="red" />,
    //   content: (
    //     <>
    //       <Loading3QuartersOutlined
    //         spin
    //         className="modal-spin"
    //         style={{
    //           textAlign: "center",
    //           fontSize: 50,
    //         }}
    //       />
    //     </>
    //   ),
    //   okText: "Confirm",
    //   okType: "primary",
    //   cancelText: "Cancel",
    //   centered: true,
    //   closable: false,

    //   // width: 750,
    //   bodyStyle: {
    //     padding: 30,
    //     borderRadius: 10,
    //   },
    // });
  };

  // NAVIGATE
  // Move Proceed
  /*
  const move = (val) => {
    const payment = val;
    console.log("Guest --> ", guest);

    navigate("/channel-manager/reservation-success", {
      state: { data: data, article: article, guest: guest, gateway: payment },
    });
  };
  */
  // Move Back
  const back = (val) => {
    console.log(val);

    navigate("/channel-manager/reservation-guest", {
      state: { data: data, article: article, guest: guest },
    });
  };

  return (
    <>
      <Layout style={{ backgroundColor: "#002766" }}>
        <Content>
          <Row
            className="gateway-card"
            style={{
              padding: "15px 10px",
              backgroundColor: "#FFFFFF",
              margin: "0px 0px 30px",
              borderRadius: 5,
              fontSize: 25,
              fontWeight: 750,
            }}
          >
            Payment Gateway for Guarantee Reservation
          </Row>

          <Row
            gutter={[30, 30]}
            // style={{ margin: "30px 0px 0px" }}
            className="row-main-layout"
          >
            <Col
              className="col-card-info"
              xxl={6}
              xl={8}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={24}
              xs={24}
            >
              <Row
                style={{
                  backgroundColor: "#FFFFFF",
                  minHeight: 450,
                  borderRadius: 5,
                  width: "100%",
                }}
              >
                <Col span={24} style={{ padding: 30 }}>
                  <Row>
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 17,
                        fontWeight: 750,
                        // color: "#52c41a",
                      }}
                    >
                      Your Reservation
                    </Typography>
                  </Row>

                  <Divider
                    className="divider-form"
                    style={{ margin: "15px 0px", backgroundColor: "#000000" }}
                  />

                  <Row>
                    <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                      <Card bordered={false} style={cardItemStyle}>
                        <Card.Grid
                          style={{
                            padding: 0,
                            boxShadow: "none",
                            minWidth: 150,
                            marginTop: 15,
                          }}
                        >
                          <Typography style={styleText2}>
                            {data?.arrival
                              ? moment(data.arrival).format("DD MMM YYYY")
                              : ""}
                          </Typography>
                          <Typography style={styleText1}>Check-In</Typography>
                        </Card.Grid>

                        <Card.Grid
                          style={{
                            padding: 0,
                            boxShadow: "none",
                            minWidth: 150,
                            marginTop: 15,
                          }}
                        >
                          <Typography style={styleText2}>
                            {data?.departure
                              ? moment(data.departure).format("DD MMM YYYY")
                              : ""}
                          </Typography>
                          <Typography style={styleText1}>Check-Out</Typography>
                        </Card.Grid>

                        <Card.Grid
                          style={{
                            padding: 0,
                            boxShadow: "none",
                            minWidth: 150,
                            marginTop: 15,
                          }}
                        >
                          <Typography style={styleText2}>
                            {data?.night ? data.night : ""}
                          </Typography>
                          <Typography style={styleText1}>Night</Typography>
                        </Card.Grid>

                        <Card.Grid
                          style={{
                            padding: 0,
                            boxShadow: "none",
                            minWidth: 150,
                            marginTop: 15,
                          }}
                        >
                          <Typography style={styleText2}>
                            {data?.person ? data.person : ""}
                          </Typography>
                          <Typography style={styleText1}>Person</Typography>
                        </Card.Grid>
                      </Card>
                    </Col>
                  </Row>

                  <Row justify="start" style={{ marginTop: 30 }}>
                    <Col span={24}>
                      <Button
                        type="primary"
                        className="back-btn"
                        onClick={back}
                      >
                        {`Back / Modifiy Data`}
                      </Button>
                    </Col>
                  </Row>

                  <Divider
                    className="divider-form"
                    style={{ margin: "5px 0px", backgroundColor: "#000000" }}
                  />

                  <Row>
                    <Typography
                      className="text-rate"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        // color: "#52c41a",
                      }}
                    >
                      Selected Rooms / Rates
                    </Typography>
                  </Row>

                  <Row style={{ marginBottom: 15 }}>
                    <Col span={12}>
                      <Row>
                        <Text>
                          {data.room_qty} {data.room_category_name} Room -{" "}
                          {data.adult} Adult,{" "}
                          {data.total_child > 0 ? (
                            `${data.total_child} Child, `
                          ) : (
                            <></>
                          )}
                          {data.infant > 0 ? `${data.infant} Infant,` : <></>}
                        </Text>
                      </Row>

                      <Row>
                        <Text>Best Picks - Include Breakfast</Text>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <Row justify="end">
                        <Typography
                          className="text-price"
                          style={{
                            fontSize: 20,
                            fontWeight: 750,
                            color: "#52c41a",
                          }}
                        >
                          {`Rp. ${data.price}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>

                  <Divider
                    className="divider-form"
                    style={{ margin: "5px 0px", backgroundColor: "#000000" }}
                  />

                  <Row>
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 18,
                        fontWeight: 750,
                        // color: "#52c41a",
                      }}
                    >
                      Selected Service
                    </Typography>
                  </Row>

                  {article.length > 0 ? null : (
                    <Alert
                      className="article-alert"
                      key={"article-alert"}
                      message={`Make your stay even better by selecting the additional services!`}
                      type="info"
                      showIcon
                      style={{
                        backgroundColor: "geekblue",
                      }}
                    />
                  )}

                  {article.map((e) => {
                    return (
                      <Row style={{ marginBottom: 15 }} key={e.key}>
                        <Col span={2}>
                          <DeleteFilled
                            onClick={() => {
                              let deleteArticle = article.filter((filt) => {
                                if (e.package_name != filt.package_name) {
                                  return filt;
                                }
                              });
                              console.log(deleteArticle);
                              setArticle(deleteArticle);
                            }}
                            style={{ fontSize: 20, color: "#F64E60" }}
                          />
                        </Col>

                        <Col span={12}>
                          <Row>
                            <Text>
                              {e.package_name} - x {e.package_qty}
                            </Text>
                          </Row>
                        </Col>

                        <Col span={10}>
                          <Row justify="end">
                            <Typography
                              className="text-price"
                              style={{
                                fontSize: 20,
                                fontWeight: 750,
                                color: "#52c41a",
                              }}
                            >
                              {`Rp. ${e.total_price}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Typography>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}

                  <Divider
                    className="divider-form"
                    style={{ margin: "15px 0px", backgroundColor: "#000000" }}
                  />

                  <Row style={{ marginBottom: 30 }}>
                    <Col span={12}>
                      <Row justify="start">
                        <Typography
                          className="text-price"
                          style={{
                            fontSize: 17,
                            fontWeight: 750,
                            // color: "#52c41a",
                          }}
                        >
                          Total Amount
                        </Typography>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <Row justify="end">
                        <Typography
                          className="text-price"
                          style={{
                            fontSize: 20,
                            fontWeight: 750,
                            color: "#52c41a",
                          }}
                        >
                          {`Rp. ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col
              className="col-card-payment"
              xxl={{ span: 18, offset: 0 }}
              xl={16}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={24}
              xs={24}
            >
              <Row
                className="row-main-gateway"
                style={{
                  margin: 0,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 5,
                  padding: 30,
                }}
              >
                <Col
                  xxl={16}
                  xl={16}
                  lg={16}
                  md={24}
                  sm={24}
                  xs={24}
                  className="col-main-gateway"
                >
                  <Row className="guarantee-reservation">
                    <Typography
                      className="text-row-title"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      Guarantee this reservation
                    </Typography>
                  </Row>

                  <Form
                    className="form-payment"
                    name="form-payment"
                    key="form-payment"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                      padding: 30,
                      border: "1px solid #d9d9d9",
                      borderRadius: 5,
                    }}
                  >
                    <Row
                      className="row-title-card"
                      //   gutter={30}
                      justify="space-evenly"
                      style={{
                        margin: "0px 0px 15px",
                      }}
                    >
                      <Col span={12}>
                        <Row className="row-pick" justify="start">
                          <FaDotCircle
                            style={{
                              color: "#95de64",
                              margin: "10px 15px 0px 0px",
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: 20,
                              fontWeight: 750,
                            }}
                          >
                            Credit Card
                          </Typography>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row
                          justify="end"
                          gutter={30}
                          className="row-image-list"
                        >
                          <Image
                            className="master-card"
                            src="/assets/logos/mastercard.png"
                            preview={false}
                            style={imgStyles}
                          />
                          <Image
                            className="visa"
                            src="/assets/logos/visa.png"
                            preview={false}
                            style={imgStyles}
                          />
                          <Image
                            className="jbc"
                            src="/assets/logos/jcb.png"
                            preview={false}
                            style={imgStyles}
                          />
                          <Image
                            className="amex"
                            src="/assets/logos/amex.png"
                            preview={false}
                            style={imgStyles}
                          />
                        </Row>
                      </Col>
                    </Row>

                    <Divider
                      className="divider-form"
                      style={{
                        margin: "10px 0px",
                        backgroundColor: "#000000",
                      }}
                    />

                    <Row
                      className="row-check"
                      style={{
                        fontSize: 15,
                        fontWeight: 750,
                        margin: "10px 0px",
                        padding: 10,
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      <Typography
                        className="text-card"
                        style={{
                          fontWeight: 750,
                          fontSize: 17,
                        }}
                      >{`CREDIT CARD INFORMATION`}</Typography>

                      <Typography
                        className="text-desc"
                        style={{ textAlign: "justify" }}
                      >
                        {`Credit Card information is required in order to guarantee your reservation. Your credit card will be not charged until your arrival or departure or if the cancellation deadline has passed. Please be sure to insert a correct Credit Card Number. If has been transmitted an invalid expiration date and/or an invalid number the reservation is automatically cancelled without notice! Please check carefully that the expiration date is still valid in coincidence of the cancellation policy time.`}
                      </Typography>

                      {/* <CheckOutlined
                    style={{
                      color: "#95de64",
                      fontSize: 30,
                      fontWeight: 750,
                      margin: "0px 10px 0px 0px",
                    }}
                  /> */}

                      <Typography
                        className="text-deposit"
                        style={{
                          fontWeight: 750,
                          fontSize: 17,
                          margin: "5px 10px 0px 0px",
                        }}
                      >
                        {`Deposit required:  `}
                      </Typography>

                      <Typography
                        className="text-deposit"
                        style={{
                          color: "#389e0d",
                          fontWeight: 750,
                          fontSize: 25,
                        }}
                      >
                        {`Rp ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Typography>
                    </Row>

                    <Divider
                      className="divider-form"
                      style={{
                        margin: "10px 0px",
                        backgroundColor: "#000000",
                      }}
                    />

                    <Row className="row-card">
                      <Form.Item
                        name="card_type"
                        label="Type"
                        rules={[
                          {
                            required: true,
                            message: "Please, select one of the payment cards!",
                          },
                        ]}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Select
                          className="card-type"
                          onChange={handleCard}
                          value={card}
                          options={options}
                        />
                      </Form.Item>
                    </Row>

                    <Row className="row-card-id" gutter={30}>
                      <Col span={12} className="col-card-no">
                        <Form.Item
                          name="card_no"
                          label="Card Number"
                          rules={[
                            {
                              required: true,
                              message: "Please, input credit card number!",
                            },
                          ]}
                        >
                          <InputNumber
                            className="card-number"
                            placeholder="Card Number"
                            maxLength={16}
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="col-card-owner">
                        <Form.Item
                          name="card_owner"
                          label="Card Owner"
                          rules={[
                            {
                              required: true,
                              message: "Please, input credit card owner!",
                            },
                          ]}
                        >
                          <Input
                            className="card-owner"
                            placeholder="Card Owner"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="row-card-date" gutter={30}>
                      <Col span={12} className="col-card-exp">
                        <Form.Item
                          name="exp_date"
                          label="Expired Date"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please, input credit card expired date!",
                            },
                          ]}
                        >
                          <InputNumber
                            className="expired-date"
                            placeholder="Expired Date (MM/YY)"
                            maxLength={5}
                            min={0}
                            formatter={(exp) => {
                              return `${exp}`.replace(
                                /\B(?=(\d{2})+(?!\d))/g,
                                "/"
                              );
                            }}
                            style={{
                              width: "100%",
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="col-ccv2">
                        <Form.Item
                          name="ccv2"
                          label="CCV2"
                          rules={[
                            {
                              required: true,
                              message: "Please, input credit card CCV2!",
                            },
                          ]}
                        >
                          <Input className="card-ccv2" placeholder="* CCV2" />
                        </Form.Item>

                        <Button
                          type="link"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          What is CCV2?
                        </Button>
                      </Col>
                    </Row>

                    <Row className="checkbox-terms">
                      <Form.Item
                        name="agree"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: async (_, check) => {
                              // console.log(check);

                              if (check == false || check == null) {
                                return Promise.reject(
                                  "Please check the privacy policy agreement to proceed!"
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Checkbox className="terms-check">
                          {`I read and accepted the booking and Cancellation Policies and Privacy Policy`}
                        </Checkbox>
                      </Form.Item>
                    </Row>

                    <Row gutter={30} justify="start" className="form-btn">
                      <Button
                        className="submit-payment-btn"
                        key="submit-payment-btn"
                        htmlType="submit"
                        style={{
                          backgroundColor: "#389e0d",
                          borderColor: "#389e0d",
                          color: "#FFFFFF",
                          width: "100%",
                          height: "100%",
                          fontWeight: 750,
                          fontSize: 25,
                          padding: 5,
                          margin: "30px 0px 0px",
                        }}
                      >
                        Book Now
                      </Button>
                    </Row>
                  </Form>
                </Col>

                <Col
                  //   span={8}
                  xxl={8}
                  xl={8}
                  lg={8}
                  md={0}
                  sm={0}
                  xs={0}
                  className="col-infograph"
                  style={{
                    padding: 30,
                  }}
                >
                  <Alert
                    className="encrypted-alert"
                    message="Encrypted Data"
                    description="All information is protected by encryption of data."
                    type="success"
                    showIcon
                    icon={<LockOutlined />}
                    style={{
                      margin: "0px 0px 60px",
                    }}
                  />

                  <Image
                    className="card-img"
                    src={source}
                    preview={false}
                    style={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Modal
            className="ccv2-info-modal"
            title={"WHAT IS THE CARD SECURITY CODE?"}
            open={open}
            onCancel={handleCancel}
            // onOk={handleCancel}
            centered
            // width={800}
            bodyStyle={{
              padding: 30,
            }}
            footer={null}
          >
            <Row
              className="row-content"
              style={{ margin: "10px 0px 30px", textAlign: "justify" }}
            >
              This is a security measure we require for all transactions. Since
              the card security code is a number is listed on your credit card,
              but is not stored anywhere, the only way to know the correct
              number for your credit card is to physically have possession of
              the card itself.
            </Row>

            <Row style={{ margin: "10px 0px 30px", textAlign: "justify" }}>
              <Col span={12}>
                <Image
                  className="master-ccv2"
                  src="/assets/img/master-diners.png"
                  preview={false}
                />
              </Col>

              <Col span={12}>
                <Typography
                  className="text-header"
                  style={{
                    fontWeight: 750,
                  }}
                >
                  Visa, MasterCard and Diners Club:
                </Typography>
                This number is printed on your MasterCard, Visa and Diners Club
                in the signature area of the back of the card. (it is the last 3
                digits after the credit card number). If you cannot read your
                card security code, please contact the issuing creditor.
              </Col>
            </Row>

            <Row style={{ margin: "10px 0px 30px", textAlign: "justify" }}>
              <Col span={12}>
                <Image
                  className="master-ccv2"
                  src="/assets/img/amexpress.png"
                  preview={false}
                />
              </Col>

              <Col span={12}>
                <Typography
                  className="text-header"
                  style={{
                    fontWeight: 750,
                  }}
                >
                  American Express:
                </Typography>
                American Express cards show the card security code (also
                referred to as the CID number) printed above the imprinted card
                number on the front of the card (on either the left or right).
              </Col>
            </Row>

            <Row
              justify="end"
              className="row-modal-btn"
              style={{
                margin: "30px 0px 0px",
              }}
            >
              <Button
                className="close-modal-btn"
                onClick={() => {
                  handleCancel();
                }}
              >
                CLOSE
              </Button>
            </Row>
          </Modal>
          <Modal
            title="Process Reservation"
            open={modalProcess}
            centered
            closable={false}
            footer={null}
          >
            <Row justify="center" style={{ padding: 15 }}>
              <Loading3QuartersOutlined
                spin
                className="modal-spin"
                style={{
                  color: "#1890ff",
                  textAlign: "center",
                  fontSize: 50,
                }}
              />
            </Row>
            <Text>
              We are processing your Reservation Request! Please, wait until the
              process are done and do not close this modal!
            </Text>
          </Modal>
        </Content>
      </Layout>
    </>
  );
}

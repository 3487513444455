// Import React Components
import React from "react";

let authorized_by = "";
let authorized_roles = "";
let authorized_dept = "";
let authorized_token = "";
let user_level = 0;

export function handleAuthorization(access_token) {
  //   console.log("Access Token --> ", access_token);

  if (!access_token) {
    access_token = localStorage.getItem("permit") || "";
  }

  //   console.log("Access Token --> ", access_token);

  authorized_token = access_token;

  if (access_token) {
    let base64Url = access_token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const payload = JSON.parse(jsonPayload);

    authorized_by = payload?.user_metadata?.user_name;
    authorized_roles = payload?.user_metadata?.roles;
    authorized_dept = payload?.user_metadata?.department;
    user_level = payload?.user_metadata?.user_level;

    // console.log("User Name Set >>>", authorized_by);
    // console.log("Roles >>>", authorized_roles);
    // console.log("Department >>>", authorized_dept);
    // console.log("Parse JSON --> ", payload);

    return {
      authorized_by,
      authorized_roles,
      authorized_dept,
      authorized_token,
      user_level,
    };
  } else {
    authorized_by = "";
    authorized_roles = "";
    authorized_dept = "";
    authorized_token = "";
    user_level = 0;
  }
}

export {
  authorized_by,
  authorized_roles,
  authorized_dept,
  authorized_token,
  user_level,
};

// Import React's Components
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React's Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { IoIosPricetags } from "react-icons/io";

// Import Page Components
import QuotationPopover from "../../../../Popover/PriceManagement/Popover";

// Import Functions
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// Options of Article Type
const options = [
  {
    label: "Article Sales",
    value: 1,
  },
  {
    label: "Article Inventory",
    value: 2,
  },
];

// CODE
export default function QuotationMainTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getArticleMaster } = useContext(MasterContext);

  // Use Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [articleType, setArticleType] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await getArticleMaster({
      types: "list",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // TABLE COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <Row className="popover-row" justify="center">
            <QuotationPopover details={record} />
          </Row>
        );
      },
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      // fixed: "left",
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Inventory Name",
      dataIndex: "article_name",
      key: "article_name",
      // fixed: "left",
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: 200,
    },
    // {
    //   title: "Main-Group",
    //   dataIndex: "article_main_name",
    //   key: "article_main_name",
    //   width: 200,
    // },
    // {
    //   title: "Sub-Group",
    //   dataIndex: "article_sub_name",
    //   key: "article_sub_name",
    //   width: 200,
    // },
    // {
    //   title: "COA",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    //   width: 150,
    //   render: (coa) => {
    //     return coa === "NULL" ? "  " : coa;
    //   },
    // },

    {
      title: "Min Stock",
      dataIndex: "min_stock",
      key: "min_stock",
    },
    {
      title: `Average Price ( ${currency} )`,
      dataIndex: "average_price",
      key: "average_price",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.average_price - b.average_price,
      render: (average_price, record) => {
        return `${average_price > 0 ? average_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
  ];

  // Handle Return
  const handleReturn = () => {
    const _path = "/back-office/purchasing";

    navigate(_path);
  };

  // Handle Articles
  const handleArticle = (val) => {
    const _type = val;
    console.log("Type: ", _type);

    setArticleType(_type);

    if (_type > 0) {
      let filter = bulks.filter((value) => {
        if (_type === value.article_type) {
          //   setElement(<ArticleListTable />);
          return value;
        }
      });

      setData(filter);
    } else {
      setData(bulks);
    }

    // console.log("Filtered: ", filter);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryKey = searchKey ? searchKey : null;

    // console.log("FILTERED => ", queryKey);

    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        const _name = item?.article_name.toLowerCase();

        return _name.indexOf(queryKey) !== -1;
      });

      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="mice-guest-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: "30px" }} className="col-table">
          <Row justify="end" className="row-add-btn">
            <Col className="col-btn" span={12}>
              {`Filter by Article Types`}

              <Row className="row-table-btn" gutter={[0, 15]}>
                <Select
                  className="article-type-select"
                  key="article-type-select"
                  allowClear
                  showSearch
                  value={articleType}
                  onChange={handleArticle}
                  options={options}
                  placeholder="Select an Article Types"
                  style={{
                    // minWidth: 150,
                    // maxWidth: 250,
                    width: 250,
                  }}
                />
              </Row>
            </Col>

            <Col className="col-btn" span={12}>
              <Row
                className="row-table-btn"
                justify="end"
                align="top"
                gutter={[0, 15]}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="non-stay-table"
              name="non-stay-table"
              key="non-stay-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import { useState } from "react";
import axios from "axios";

// ANTD COMPONENTS
import { Button, Col, Modal, Popover, Row, Tooltip, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";

// REACT ICONS
import { FaWpforms } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { TfiMenuAlt } from "react-icons/tfi";
import { BsPrinter } from "react-icons/bs";
import { MdOutlineWorkHistory } from "react-icons/md";

// GLOBAL API
import { token, user_name } from "../../../API/Global/Payload";
import { baseurl } from "../../../API/Config/Api";

// PAGES COMPONENTS
import ModalArticleVendorManagement from "../../Modals/Purchasing/VendorManagement/Modal";
import ModalVendorArticleView from "../../Modals/Article/Vendor/Modal";
import ModalSupplierTurnover from "../../Modals/Purchasing/SupplierTurnover/Modal";
import VendorArticleForm from "../../Forms/VendorArticle/Form";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";

// MODALS
const { confirm } = Modal;

// CODE
export default function VendorManagementPopover(props) {
  // PROPS
  const { details, is_refresh, params } = props;

  // Open Popover
  const [open, setOpen] = useState(false);
  const [dataRecord, setDataRecord] = useState([]);
  // Open Modal
  const [article, setArticle] = useState(false);
  const [articleView, setArticleView] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [supplierTurnover, setSupplierTurnover] = useState(false);

  const detail = details;

  const handleClose = () => {
    setOpen(!open);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "add-update-supplier-confirm",
      title: `Are you sure want to delete supplier named ${_data?.supplier_name.toUpperCase()} from the table?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
      },

      onCancel() {
        // setIsLoading(false);
        console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    var obj = {
      ["id_supplier"]: contentdelete.id_supplier,
      ["deleted_by"]: user_name,
    };

    axios
      .delete(`${baseurl}/master/suppliers`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        masterSuccessNotification(response, { method: 2, source: "Supplier" });
        is_refresh(true);
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Supplier" });
      });
  };

  return (
    <>
      <Tooltip title="Option Menu" className="vendor-management-tooltip">
        <Popover
          className="pop-over-vendor-management"
          name="pop-over-vendor-management"
          key="pop-over-vendor-management"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`Vendor Management Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaWpforms
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setArticleView(!articleView);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`View Article Vendor`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FiEdit
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setArticle(!article);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Add Article Inventory`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdOutlineWorkHistory
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setSupplierTurnover(!supplierTurnover);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Supplier Turnover`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <BsPrinter
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    setOpenForm(!openForm);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Print List Article Vendor`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <DeleteFilled
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 10px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                    setDataRecord(detail);
                    showModalConfirm(detail);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Delete Supplier`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            style={{
              fontSize: 20,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              // marginLeft: 5,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <ModalVendorArticleView
        is_open={articleView}
        is_close={setArticleView}
        dataVendor={articleView == true ? dataRecord : ""}
        params={params}
      />

      <ModalArticleVendorManagement
        is_open={article}
        is_close={setArticle}
        dataVendor={article == true ? dataRecord : ""}
        params={params}
      />

      <ModalSupplierTurnover
        is_open={supplierTurnover}
        is_close={setSupplierTurnover}
        dataVendor={supplierTurnover == true ? dataRecord : ""}
        params_acc={params}
      />

      <VendorArticleForm
        openModal={openForm}
        closeModal={setOpenForm}
        dataVendor={openForm == true ? dataRecord : ""}
        is_saved={true}
        params={params}
      />
    </>
  );
}

// Import Ant Design Notification Components
import { Col, Row, Space, notification } from "antd";

// Import React Icons
import { CheckOutlined, WarningOutlined } from "@ant-design/icons";
import { IoClose } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";

// Check Type
import { CheckType } from "../../Functions/RequestType/RequestType";

// GLobal Styles
import { failedMaster, successMaster } from "../../../../Style/Global/Global";

// UNAUTHORIZED TXT
const _relogin =
  "Your Token is Expired! Please, Re-Login to acquire a new one!";
const _unauthorized = "Unauthorized! Access Denied!";
const _general = "Please, Check Your Internet Connection or Try Again Later!";

// MASTER INCOMPLETE
const masterIncomplete = (error) => {
  console.log(error);

  notification.open({
    className: "notif-incomplete-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="incomplete-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`Data Incomplete!`}
            </Row>

            <Space
              className="incomplete-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Required Data can not be Empty!`}
              {error.errorFields[0]
                ? error.errorFields[0].errors[0]
                : "Please, Complete all the required Data!"}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "top",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#FFA800",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// NOTIF JOURNAL MANUAL
const journalIsNotBalance = (params) => {
  const _params = params;

  notification.open({
    className: "notif-isnotbalance-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="isnotbalance-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {_params === 1 ? `Journal Is Not Balance!` : `Journal is Empty!`}
            </Row>

            <Space
              className="incomplete-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {"Please, Check your Journal again!"}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "top",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#FFA800",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// NOTIFICATION ARTICLE MAIN-GROUP
// Notif Add Article Main Failed
const masterFailedNotification = (error, { method, source }) => {
  const auth = error?.response?.status;
  let type = CheckType(method);

  console.log("Source: ", type);
  console.log("Error: ", error);

  notification.open({
    className: "notif-failed-master",
    message: null,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <IoClose className="success-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={17}>
            <Row
              className="login-notif"
              style={{
                width: "100%",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {
                <div style={{ color: "#FFFFFF" }}>{`Failed to ${
                  type.slice(-1) == "ed" ? type.slice(-1) + "ing" : type
                } ${source}!`}</div>
              }
            </Row>

            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 12 }}
            >
              {`Status: ${error?.response?.status} - ${error?.response?.statusText}`}
            </Row>

            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 12 }}
            >
              {auth === 401
                ? _relogin
                : auth === 403
                ? _unauthorized
                : _general}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: failedMaster,
  });
};

// Notif Add Article Main Success
const masterSuccessNotification = (response, { method, source }) => {
  let type = CheckType(method);
  console.log("Source: ", source);

  notification.open({
    className: "notif-success-add-article",
    message: null, // <div style={{ color: "#ffffff" }}>{`SUCCESS!`}</div>,
    description: (
      <>
        <Row align="middle" className="success-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <BsCheckLg className="success-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={18}>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Success!`}
            </Row>

            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 15 }}
            >
              {`${source} is ${
                type.slice(-1) !== "d" ? type + "d" : type
              } Successfully!`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: successMaster,
  });
};

// MASTER NOTIFICATION
// Notif Add Arrangement Failed
const failedArrange = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-arrange",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Create New Arrangement!
        </div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Arrangement!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Arrangement Failed
const successArrange = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-arrange",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Arrangement is Created Successfully!"
        : type == 1
        ? "Arrangement is Updated Successfully!"
        : "Arrangement is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// SEGMENT NOTIFICATIONS
// Notif Add Arrangement Failed
const failedSegment = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-segment",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Create New Segment!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Segment!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Arrangement Failed
const successSegment = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-segment",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Segment is Created Successfully!"
        : type == 1
        ? "Segment is Updated Successfully!"
        : "Segment is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION COUNTRY
// Notif Add Country Failed
const failedCountry = (error, { method }) => {
  const type = method;
  const auth = error?.response?.status;

  notification.error({
    className: "notif-failed-add-country",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Country!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Country's Data!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description:
      auth === 401 ? _relogin : auth === 403 ? _unauthorized : _general,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Country Failed
const successCountry = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-country",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Country is Added Successfully!"
        : type == 1
        ? "Country is Updated Successfully!"
        : "Country is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION PAYMENT CARD
// Notif Add Pay Card Failed
const failedPayCard = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-pay-card",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Payment Card!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Payment Card's Data!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Pay Card Failed
const successPayCard = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-pay-card",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Payment Card is Added Successfully!"
        : type == 1
        ? "Payment Card is Updated Successfully!"
        : "Payment Card is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION PAYMENT TYPE
// Notif Add Pay Type Failed
const failedPayType = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-pay-type",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Payment Type!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Payment Type's Data!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Pay Card Failed
const successPayType = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-pay-type",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Payment Type is Added Successfully!"
        : type == 1
        ? "Payment Type is Updated Successfully!"
        : "Payment Type is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION SOURCES
// Notif Add Sources Failed
const failedSources = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-source-type",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Source!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Source's Data!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Sources Failed
const successSources = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-source-type",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Source is Added Successfully!"
        : type == 1
        ? "Source is Updated Successfully!"
        : "Source is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION SALESMAN
// Notif Add Salesman Failed
const failedSalesman = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-source-type",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a New Salesman!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Salesman's Data!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Sources Failed
const successSalesman = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-source-type",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Salesman is Added Successfully!"
        : type == 1
        ? "Salesman is Updated Successfully!"
        : "Salesman is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION Supplier
// Notif Add Supplier Failed
const failedSupplier = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-source-type",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a New Supplier!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Supplier's Data!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Supplier Failed
const successSupplier = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-source-type",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Supplier is Added Successfully!"
        : type == 1
        ? "Supplier is Updated Successfully!"
        : "Supplier is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION ARTICLE MAIN-GROUP
// Notif Add Article Main Failed
const failedArticleGrup = (error, { method, source }) => {
  const auth = error?.response?.status;
  let type = CheckType(method);
  let src = "";

  switch (source) {
    case 1:
      src = "Main";
      break;

    case 2:
      src = "Sub";
      break;

    default:
      break;
  }

  notification.open({
    className: "notif-failed-add-article",
    message: null,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <IoClose className="success-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={18}>
            <Row
              className="login-notif"
              style={{
                width: "100%",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {
                <div style={{ color: "#FFFFFF" }}>
                  {`Failed To ${type} Article ${src}!`}
                </div>
              }
            </Row>

            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 14 }}
            >
              {auth === 401
                ? _relogin
                : auth === 403
                ? _unauthorized
                : _general}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: failedMaster,
  });
};

// Notif Add Article Main Success
const successArticleGroup = (response, { method, source }) => {
  let type = CheckType(method);
  let src = "";

  switch (source) {
    case 1:
      src = "Main-Group";
      break;

    case 2:
      src = "Sub-Group";
      break;

    default:
      break;
  }
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  notification.open({
    className: "notif-success-add-article",
    message: null, // <div style={{ color: "#ffffff" }}>{`SUCCESS!`}</div>,
    description: (
      <>
        <Row align="middle" className="success-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <BsCheckLg className="success-icon" style={{ fontSize: 35 }} />
          </Col>

          <Col span={18}>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Success!`}
            </Row>

            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 15 }}
            >
              {`Article ${src} is ${type} Successfully!`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: successMaster,
  });
};

// NOTIFICATION ARTICLE LIST
// Notif Add Article List Failed
const failedArticleList = (error, { method, source }) => {
  const type = method;
  const auth = error?.response?.status;
  const src = source;

  notification.open({
    className: "notif-failed-add-article-list",
    message: null,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <IoClose className="success-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={18}>
            <Row
              className="login-notif"
              style={{
                width: "100%",
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {type == 0 ? (
                <div style={{ color: "#FFFFFF" }}>
                  {`Failed To Add a Article ${
                    src === 1 ? "Sales" : "Inventory"
                  }!`}
                </div>
              ) : type == 1 ? (
                <div style={{ color: "#FFFFFF" }}>
                  {`Failed To Update Article ${
                    src === 1 ? "Sales" : "Inventory"
                  }!`}
                </div>
              ) : (
                <div style={{ color: "#FFFFFF" }}>{`Failed To Delete!`}</div>
              )}
            </Row>

            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 14 }}
            >
              {auth === 401
                ? _relogin
                : auth === 403
                ? _unauthorized
                : _general}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: failedMaster,
  });
};

// Notif Add Article List Success
const successArticleList = (response, { method, source }) => {
  const type = method;
  const src = source;

  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  notification.open({
    className: "notif-success-add-article-list",
    message: null, // <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: (
      <>
        <Row align="middle" className="success-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <BsCheckLg className="success-icon" style={{ fontSize: 35 }} />
          </Col>

          <Col span={18}>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Success!`}
            </Row>

            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 15 }}
            >
              {type == 0
                ? `A New Article ${
                    src === 1 ? "Sales" : "Inventory"
                  } is Added Successfully!`
                : type == 1
                ? `Article ${
                    src === 1 ? "Sales" : "Inventory"
                  } is Updated Successfully!`
                : `Article ${
                    src === 1 ? "Sales" : "Inventory"
                  } is Deleted Successfully!`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: successMaster,
  });
};

// NOTIFICATION ROOM CATEGORY
// Notif Add Room Category Failed
const failedRoomCat = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-room-cat",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Room Category!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Room Category Data!
        </div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ) : type == 3 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add Room Availability!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Delete Room Availability!
        </div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Room Category Success
const successRoomCat = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-room-cat",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Room Category is Added Successfully!"
        : type == 1
        ? "Room Category is Updated Successfully!"
        : type == 2
        ? "Room Category is Deleted Successfully!"
        : type == 3
        ? "Room Availability is Added Successfully!"
        : "Room Availability is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION ROOM LIST
// Notif Add Room List Failed
const failedRoom = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-room",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Room!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Room Data!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Room List Success
const successRoom = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-room",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Room is Added Successfully!"
        : type == 1
        ? "Room is Updated Successfully!"
        : "Room is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION RATE CODE
// Notif Add Rate Code Failed
const failedRate = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-rate-code",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Rate Code!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Rate Code Data!</div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Get Rate by Authorization!
        </div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Rate Code Success
const successRate = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-rate-code",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Rate Code is Added Successfully!"
        : type == 1
        ? "Rate Code is Updated Successfully!"
        : type == 2
        ? "Rate Code is Deleted Successfully!"
        : "Rate Code is Changed Successfully by Authorizations!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION RATE ROOM CATEGORY
// Notif Add Rate Room Category Failed
const failedRateCat = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-rate-cat",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add a Rate Room Category!
        </div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Rate Room Category!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Rate Room Category Success
const successRateCat = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-rate-cat",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Rate Room Category is Added Successfully!"
        : type == 1
        ? "Rate Room Category is Updated Successfully!"
        : "Rate Room Category is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION RATE ROOM NUMBER
// Notif Add Rate Room Number Failed
const failedRateNo = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-rate-no",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add a Rate Room Number!
        </div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Rate Room Number!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Rate Room Number Success
const successRateNo = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-rate-cat",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Rate Room Number is Added Successfully!"
        : type == 1
        ? "Rate Room Number is Updated Successfully!"
        : "Rate Room Number is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION RATE PERIODIC
// Notif Add Rate Periodic Failed
const failedRatePeriod = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-rate-period",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add a Rate Periodic!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Rate Periodic!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Rate Room Number Success
const successRatePeriod = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-rate-cat",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Rate Periodic is Added Successfully!"
        : type == 1
        ? "Rate Periodic is Updated Successfully!"
        : "Rate Periodic is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATIONS MASTER OUTLET
// Failed Outlet
const failedOutlet = (error, { method, params }) => {
  const type = method;
  const _params = params;
  const auth = error?.response?.status;

  let from = "";
  let about = "";

  // console.log("Type: ", _params);

  switch (_params) {
    case 1:
      from = "Outlet Location";
      break;

    case 2:
      from = "Table Category";
      break;

    case 3:
      from = "Table";
      break;

    default:
      from = "Unknown";
      break;
  }

  switch (type) {
    case 1:
      about = "Add";
      break;

    case 2:
      about = "Update";
      break;

    case 3:
      about = "Delete";
      break;

    default:
      about = "Unknown";
      break;
  }

  notification.open({
    className: "notif-failed-outlet",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 14, fontWeight: 500 }}
            >
              {`${about} ${from} is Failed!`}
            </Row>

            <Space
              className="failed-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {auth === 401
                ? _relogin
                : auth === 403
                ? _unauthorized
                : _general}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      background: `linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)`,
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// Success Outlet
const successOutlet = (error, { method, params }) => {
  const type = method;
  const _params = params;

  let from = "";
  let about = "";

  // console.log("Type: ", _params);

  switch (_params) {
    case 1:
      from = "Outlet Location";
      break;

    case 2:
      from = "Table Category";
      break;

    case 3:
      from = "Table";
      break;

    default:
      from = "Unknown";
      break;
  }

  switch (type) {
    case 1:
      about = "Added";
      break;

    case 2:
      about = "Updated";
      break;

    case 3:
      about = "Deleted";
      break;

    default:
      about = "Unknown";
      break;
  }

  notification.open({
    className: "notif-success-outlet",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row align="middle" className="success-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <BsCheckLg className="success-icon" style={{ fontSize: 35 }} />
          </Col>

          <Col span={18}>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Success!`}
            </Row>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 15 }}
            >
              {`${from} is ${about} Successfully!`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose className="close-icon" style={{ color: "#FFF", fontSize: 20 }} />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      borderRadius: 5,
      height: 75,
      padding: "0px 20px",
      background: `#52c41a`,
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION DEPOSIT
// Notif Add Deposit Failed
const failedDeposit = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-add-deposit",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add Reservation Deposit!
        </div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Reservation Deposit!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Rate Room Number Success
const successDeposit = (response, { method }) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  const type = method;

  notification.success({
    className: "notif-success-add-rate-cat",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A Reservation Deposit is Added Successfully!"
        : type == 1
        ? "Reservation Deposit is Updated Successfully!"
        : "Reservation Deposit is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION PARAMETERS
// Front Office Parameters
// Notif Update Failed Params
const failedParams = (error, { method }) => {
  const type = method;
  var from = "";

  console.log("Type: ", type);

  switch (type) {
    case 1:
      from = "Front-Office";
      break;

    case 2:
      from = "Back-Office";
      break;

    case 3:
      from = "Accounting";
      break;

    case 4:
      from = "House Keeping";
      break;

    case 5:
      from = "Outlet";
      break;

    case 6:
      from = "General Report";
      break;

    case 7:
      from = "Shift";
      break;

    default:
      from = "Unknown";
      break;
  }

  notification.error({
    className: "notif-failed-params",
    message: (
      <div style={{ color: "#ffffff" }}>
        Failed To Update Parameters of {from}!
      </div>
    ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Rate Room Number Success
const successParams = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  console.log("Type: ", type);

  let from = "";

  switch (type) {
    case 1:
      from = "Front-Office";
      break;

    case 2:
      from = "Back-Office";
      break;

    case 3:
      from = "Accounting";
      break;

    case 4:
      from = "House Keeping";
      break;

    case 5:
      from = "Outlet";
      break;

    case 6:
      from = "General Report";
      break;

    case 7:
      from = "Shift";
      break;

    case 8:
      from = "Dashboard";
      break;

    default:
      from = "Unknown";
      break;
  }

  notification.success({
    className: "notif-success-params",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: `Parameters of ${from} is Added/Updated Successfully!`,
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

export {
  // Master Success
  masterFailedNotification,
  masterSuccessNotification,

  // Master Incomplete
  masterIncomplete,

  // JOURNAL NOT BALANCE
  journalIsNotBalance,

  // Master Arrangement
  failedArrange,
  successArrange,

  // Master Segment
  failedSegment,
  successSegment,

  // Master Sources
  failedSources,
  successSources,

  // Master Sources
  failedSalesman,
  successSalesman,

  // Master Supplier
  successSupplier,
  failedSupplier,

  // Master Country
  failedCountry,
  successCountry,

  // Master Payment Card
  failedPayCard,
  successPayCard,

  // Master Payment Type
  failedPayType,
  successPayType,

  // Master Article Main Group
  failedArticleGrup,
  successArticleGroup,

  // Master Article List
  failedArticleList,
  successArticleList,

  // Master Room Category
  failedRoomCat,
  successRoomCat,

  // Master Room
  failedRoom,
  successRoom,

  // Master Rate Code
  failedRate,
  successRate,

  // Master Rate Category
  failedRateCat,
  successRateCat,

  // Master Rate Number
  failedRateNo,
  successRateNo,

  // Master Rate Periodic
  failedRatePeriod,
  successRatePeriod,

  // MASTER OUTLET NOTIF
  failedOutlet,
  successOutlet,

  // Reservation Deposit
  failedDeposit,
  successDeposit,

  // PARAMETERS
  // Front Office
  failedParams,
  successParams,
};

// REACT COMPONENTS
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Select, Space, Typography, Form, } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaFileCircleCheck } from "react-icons/fa6";

// CONTEXT
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import WarehouseList from "../../../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";
import ExpiredOutgoingReportTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/OutgoingStock/ExpiredOutgoing/Table";

export default function StockRequisitionReportPage() {
  // STATE
  const [toStore, setToStore] = useState(null);
  const [element, setElement] = useState(null);
  const [articleNumber, setArticleNumber] = useState(null);
  const [filterElement, setFilterElement] = useState(null);
  const [fromStore, setFromStore] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // USE FORM
  const [form] = Form.useForm();

  // NAVIGATE
  const navigate = useNavigate();

  // CONTEXT
  const { getArticleInventory } = useContext(MasterContext);

  
  // USEEFFECT
  useEffect (() => {
    fetchArticleData()
  }, []);

  useEffect (() => {
    if(articleNumber !== null){
      setFilterElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
          minWidth: 250,
          marginRight: 10,
        }}
      >
        <Typography style={{ marginBottom: 10 }}>
          {`From Store`}
        </Typography>
        <Form name="fromStore">
          <WarehouseList
            selectedItem={handleFromStore}
            warehouse_name={null}
            is_disable={false}
          />
        </Form>
      </Col>)
    } else {
      setElement(null);
      setFilterElement(null);
      setToStore(null);
    }
  },[articleNumber]);
  
  // FETCH ARTICLE LIST
  const fetchArticleData = async () => {
    await getArticleInventory({
      inventory: "inventory",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response getArticleInventory >> ", response);

        if(response?.data?.msg?.length > 0) {
          const res = response.data.msg;

          let filter = res.filter((val) => {
            if (!val?.is_deleted) {
              return val;
            }
          });

          let map = filter.map((val) => ({
            ...val,
            label: val.article_no + " // " + val.article_name,
            value: val.article_no,
            key: val.id
          }))

          setOptionValue(map);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      }
    })
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/outgoing-stock/";
    
    navigate(`${path}`);
  };

  // HANDLE SELECTED WAREHOUSE
  const handleToStore = (value) => {
    if(value.id_warehouse){
      setToStore(value);
    } else {
      setToStore(null);
    };
  };

  // HANDLE FILTER
  const handleFromStore = (val) => {
    const _val = val;

    setFromStore(_val);
    form.setFieldsValue({
      fromStore: _val.id_warehouse,
    });
    
    if (_val) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`To Store`}</Typography>

          <WarehouseList
            selectedItem={handleToStore}
            warehouse_name={null}
            is_disable={false}
          />
        </Col>
      );
    } else {
      setElement(null);
      setToStore(null);
    }
  };

  const handleArticleNumber = (value, option) => {
    console.log("handleArticleNumber val >> ", value);
    if(value == null || value == "undefined"){
      setArticleNumber(null);
    } else {
      setArticleNumber(option);
    }
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="incoming-stock-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaFileCircleCheck
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Stock Requisition Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Article Number`}
                </Typography>

                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  placeholder="Select an Article Number!"
                  onChange={handleArticleNumber}
                  options={optionValue}
                />
              </Col>

              {filterElement}

              {element}

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          { toStore ? (
            <ExpiredOutgoingReportTable
            _articleNumber={articleNumber}
            _fromStore={fromStore}
            _toStore={toStore}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as ManagerContext } from "../../../API/Context/ManagerContext/ManagerContext";

// Import Antd Components
import {
  Card,
  Col,
  Row,
  Form,
  Typography,
  DatePicker,
  InputNumber,
  Button,
  List,
  Divider,
  Skeleton,
  Space,
  Steps,
  Collapse,
  Select,
  Alert,
} from "antd";

// Import React Icons
import {
  PlusOutlined,
  DeleteOutlined,
  DeleteFilled,
  SwitcherFilled,
  SwitcherTwoTone,
  UpCircleOutlined,
  DownCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// import { HotelPict } from "./assets/img/hotel-pict.jpg";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const marginLayout = {
  marginTop: 30,
};
const styleColor = {
  backgroundColor: "#fff",
  borderRadius: 10,
};

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 14,
  fontWeight: 500,
};
const styleText1 = {
  color: "#b3b3b3",
  fontSize: 14,
  fontWeight: 500,
};
const styleText2 = {
  color: "#EE9D01",
  fontSize: 18,
  fontWeight: 500,
};

const cardItemStyle = {
  margin: "0px 0px 0px 0px",
  borderRadius: 12,
  padding: 10,
  paddingLeft: 30,
  width: "100%",
  backgroundColor: "#fafafa",
  boxShadow: "5px 5px 5px #d9d9d9",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  minHeight: 150,
};

const data = [
  {
    category: "Food & Beverages ",
  },
  {
    category: "Other Additional ",
  },
];
const data1 = [
  {
    category: "Food & Beverages ",
    title: "BREAKFAST & BBQ",
    description:
      "Good things come to those who book early. 7 days advance purchase, get 20% off",
  },
  {
    category: "Food & Beverages ",
    title: "ITALIAN FOOD",
    description:
      "Good things come to those who book early. 7 days advance purchase, get 20% off",
  },
  {
    category: "Other Additional ",
    title: "LAUNDRY",
    description:
      "2 pax of Lunch/Dinner bento box for first night only, Complimentary 1x Arrival OR Departure Airport Transfer (max 2 adult and 2 child below 12 years old /car), Free 1 dessert/snacks at hotel restaurant",
  },
];
const val = [
  {
    package_name: "Airport Pick Up Service",
    package_code: "test",
    package_qty: 1,
    total_price: 2,
    total_taxes: 3,
  },
];

// OCDE
export default function ReservationArticleManager() {
  // USE NAVIGATE & LOCATION
  const navigate = useNavigate();
  const { state } = useLocation();

  // CONTEXT
  const { getCMResArticle } = useContext(ManagerContext);

  // USE FORM
  const [form] = Form.useForm();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(state?.data ? state?.data : null);
  //
  const [dataResArticle, setDataResArticle] = useState([]);
  const [articlePick, setArticlePick] = useState(
    state?.article ? state.article : []
  );
  const [guest, setGuest] = useState(state?.guest ? state.guest : null);
  //
  const [articlePickState, setArticlePickState] = useState(null);
  const [packQty, setPackQty] = useState("1");
  // Loading
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    await getCMResArticle({
      cmResArticle: "package",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response CM Res Article => ", response);

        // let filtered = response.data.msg.filter((filt) => {
        //   if (filt.is_deleted == false || filt.is_deleted == null) {
        //     return filt;
        //   }
        // });

        setDataResArticle(response.data.msg);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error Main-Group => ", error);
        setLoading(false);
        setView(true);
        // failedFetch(error);
      },
    });
  };

  // USE EFFECT DATA
  useEffect(() => {
    fetchData();
  }, []);

  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // Date
  const [startDate, setStartDate] = useState(moment(date));
  const [endDate, setEndDate] = useState(moment(date));
  // Adult Value
  const [people, setPeople] = useState(0);

  // HANDLE START DATE AND END DATE
  // Get Start Date
  const handleFirstDate = (first) => {
    const start = moment(first.format()).toArray();
    setStartDate(start);
    // console.log("start date", start);

    if (endDate) {
      var ngt = moment(endDate).diff(start, "days");
      form.setFieldsValue({
        night: ngt,
        room_qty: 1,
        adult: 1,
      });
      console.log("Start = ", ngt);
    }
  };

  // Get End Date
  const handleLastDate = (last) => {
    const end = moment(last.format()).toArray();

    setEndDate(end);
    // console.log("last date", end);

    var ngt = moment(end).diff(startDate, "days");

    // console.log(ngt);

    form.setFieldsValue({
      night: ngt,
      room_qty: 1,
      adult: 1,
    });
  };
  // GET ADULT
  const getAdult = (value) => {
    // console.log(value);
    setPeople(value);

    form.setFieldsValue({
      // child: value,
      adult: value,
      // infant: value,
    });
  };
  // GET CHILD
  const getChild = (value) => {
    // console.log(value);

    form.setFieldsValue({
      child: value,
      // adult: value,
      // infant: value,
    });
  };
  // GET ADULT
  const getInfant = (value) => {
    // console.log(value);

    form.setFieldsValue({
      // child: value,
      // adult: value,
      infant: value,
    });
  };

  const handleAddArticle = (x) => {
    console.log(x);
    const objIndex = articlePick.findIndex(
      (obj) => obj.package_name == x.package_name
    );
    console.log(objIndex);
    if (objIndex != -1) {
      articlePick[objIndex].package_qty = x.package_qty;
      setArticlePick([...articlePick]);
      // console.log(articlePick);
    } else {
      setArticlePick([...articlePick, x]);
    }
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);

    // handleSubmit(e);
  };

  // NAVIGATE
  // Reserve
  const move = (val) => {
    console.log(val);

    console.log("Data --> ", data);
    console.log("Articles --> ", articlePick);
    console.log("Articles State --> ", articlePickState);

    navigate("/channel-manager/reservation-guest", {
      state: { data: data, article: articlePick, guest: guest },
    });
  };
  // Back
  const back = (val) => {
    console.log("BACK --> ", data);

    navigate("/channel-manager/room-availability", {
      state: {
        data: data,
        // {
        //   room_category_name: data.room_category_name,
        //   room_category_code: data.room_category_name,
        //   price: data.price,
        //   room_qty: data.room_qty,
        //   adult: data.adult,
        //   total_child: data.total_child,
        //   infant: data.infant,
        //   arrival: moment(data.arrival).format("YYYY-MM-DD"),
        //   departure: moment(data.departure).format("YYYY-MM-DD"),
        //   night: data.night,
        //   person: data.person,
        //   room_rate_id: data.room_rate_id,
        //   rate_code: data.rate_code,
        //   price_id: data.price_id,
        // },
        article: articlePick,
        guest: guest,
      },
    });
  };

  console.log(state);
  console.log("Data --> ", data);
  console.log("Article --> ", articlePick);
  console.log(articlePickState);

  return (
    <>
      <Card
        title={
          <Typography
            className="text-title"
            style={{
              fontSize: 20,
              fontWeight: 750,
              // color: "#52c41a",
            }}
          >
            Reservation Channel Manager
          </Typography>
        }
      >
        <Typography>Select additional service that you want.</Typography>

        {view == true ? (
          <Alert
            className="article-alert"
            key={"article-alert"}
            message={`Failed to load data! Please, check your internet or try again later!`}
            type="error"
            showIcon
            // style={{
            //   backgroundColor: "geekblue",
            // }}
          />
        ) : null}
      </Card>

      <Row gutter={[30, 30]} style={marginLayout}>
        <Col
          lg={{ span: 6, offset: 0 }}
          md={{ span: 12, offset: 6 }}
          sm={24}
          xs={24}
        >
          <Row
            style={{
              backgroundColor: "#FFFFFF",
              minHeight: 450,
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Col span={24} style={{ padding: 30 }}>
              <Row>
                <Typography
                  className="text-price"
                  style={{
                    fontSize: 17,
                    fontWeight: 750,
                    // color: "#52c41a",
                  }}
                >
                  Your Reservation
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px", backgroundColor: "#000000" }}
              />

              <Row>
                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                  <Card bordered={false} style={cardItemStyle}>
                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.arrival
                          ? moment(data.arrival).format("DD MMM YYYY")
                          : ""}
                      </Typography>
                      <Typography style={styleText1}>Check-In</Typography>
                    </Card.Grid>

                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.departure
                          ? moment(data.departure).format("DD MMM YYYY")
                          : ""}
                      </Typography>
                      <Typography style={styleText1}>Check-Out</Typography>
                    </Card.Grid>

                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.night ? data.night : ""}
                      </Typography>
                      <Typography style={styleText1}>Night</Typography>
                    </Card.Grid>

                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.person ? data.person : ""}
                      </Typography>
                      <Typography style={styleText1}>Person</Typography>
                    </Card.Grid>
                  </Card>
                </Col>
              </Row>

              <Row justify="start" style={{ marginTop: 30 }}>
                <Col span={24}>
                  <Button type="primary" className="back-btn" onClick={back}>
                    {`Back / Modifiy Data`}
                  </Button>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#000000" }}
              />

              <Row>
                <Typography
                  className="text-rate"
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                    // color: "#52c41a",
                  }}
                >
                  Selected Rooms / Rates
                </Typography>
              </Row>

              <Row style={{ marginBottom: 15 }}>
                <Col span={12}>
                  <Row>
                    <Text>
                      {data.room_qty} {data.room_category_name} Room -{" "}
                      {data.adult} Adult,{" "}
                      {data.total_child > 0 ? (
                        `${data.total_child} Child, `
                      ) : (
                        <></>
                      )}
                      {data.infant > 0 ? `${data.infant} Infant,` : <></>}
                    </Text>
                  </Row>

                  <Row>
                    <Text>Best Picks - Include Breakfast</Text>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row justify="end">
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        color: "#52c41a",
                      }}
                    >
                      {`Rp. ${data.price}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </Typography>
                  </Row>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#000000" }}
              />

              <Row>
                <Typography
                  className="text-price"
                  style={{
                    fontSize: 18,
                    fontWeight: 750,
                    // color: "#52c41a",
                  }}
                >
                  Selected Service
                </Typography>
              </Row>

              {articlePick.length > 0 ? null : (
                <Alert
                  className="article-alert"
                  key={"article-alert"}
                  message={`Make your stay even better by selecting the additional services!`}
                  type="info"
                  showIcon
                  style={{
                    backgroundColor: "geekblue",
                  }}
                />
              )}

              {articlePick.map((e) => {
                return (
                  <Row style={{ marginBottom: 15 }} key={e.key}>
                    <Col span={2}>
                      <DeleteFilled
                        onClick={() => {
                          let deleteArticle = articlePick.filter((filt) => {
                            if (e.package_name != filt.package_name) {
                              return filt;
                            }
                          });
                          console.log(deleteArticle);
                          setArticlePick(deleteArticle);
                        }}
                        style={{ fontSize: 20, color: "#F64E60" }}
                      />
                    </Col>

                    <Col span={12}>
                      <Row>
                        <Text>
                          {e.package_name} - x {e.package_qty}
                        </Text>
                      </Row>
                    </Col>

                    <Col span={10}>
                      <Row justify="end">
                        <Typography
                          className="text-price"
                          style={{
                            fontSize: 20,
                            fontWeight: 750,
                            color: "#52c41a",
                          }}
                        >
                          {`Rp. ${e.total_price}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                );
              })}

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px", backgroundColor: "#000000" }}
              />

              <Row style={{ marginBottom: 30 }}>
                <Col span={12}>
                  <Row justify="start">
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 17,
                        fontWeight: 750,
                        // color: "#52c41a",
                      }}
                    >
                      Total Amount
                    </Typography>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row justify="end">
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        color: "#52c41a",
                      }}
                    >
                      {`Rp. ${
                        data.price +
                        articlePick.reduce(
                          (n, { total_price }) => n + total_price,
                          0
                        )
                      }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Typography>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#1BC5BD",
                      borderColor: "#1BC5BD",
                      width: "100%",
                    }}
                    onClick={() => move()}
                  >
                    RESERVE
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col lg={18} sm={24} xs={24} className="col-additional-article">
          <Row style={mainBody}>
            <Col span={24} style={{ padding: 30 }}>
              <Row>
                <Typography
                  className="text-price"
                  style={{
                    fontSize: 17,
                    fontWeight: 750,
                    // color: "#52c41a",
                  }}
                >
                  Select Additional Services
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#000000" }}
              />

              <Row>
                <Col span={24}>
                  <List
                    className="demo-loadmore-list"
                    // loading={initLoading}
                    itemLayout="horizontal"
                    // loadMore={loadMore}
                    dataSource={[
                      ...new Map(
                        dataResArticle.map((v) => [v.category, v])
                      ).values(),
                    ].map((e) => ({
                      category: e.category,
                    }))}
                    renderItem={(item) => (
                      <Collapse
                        // defaultActiveKey={["1"]}
                        size="large"
                        bordered={false}
                        // onChange={onChange}
                      >
                        <Panel
                          header={
                            <Typography
                              className="text-cat"
                              style={{
                                fontSize: 20,
                                fontWeight: 750,
                                // color: "#52c41a",
                              }}
                            >
                              {item.category}
                            </Typography>
                          }
                          key={item.id}
                        >
                          <List
                            className="demo-loadmore-list"
                            // loading={initLoading}
                            itemLayout="horizontal"
                            // loadMore={loadMore}
                            dataSource={dataResArticle.filter((e) => {
                              if (e.category == item.category) {
                                return e;
                              }
                            })}
                            renderItem={(value) => (
                              <List.Item
                                key={value.package_name}
                                // actions={[<a key="list-loadmore-more">more</a>]}
                                actions={[]}
                                // extra={
                                //   <img
                                //     style={{ maxWidth: 272 }}
                                //     width="100%"
                                //     alt="logo"
                                //     src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                //   />
                                // }
                              >
                                <Skeleton
                                  loading={loading}
                                  avatar
                                  title={false}
                                  active
                                >
                                  <List.Item.Meta
                                    // avatar={<Avatar src={value.picture.large} />}
                                    title={
                                      <Typography
                                        className="text-art-name"
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 750,
                                          // color: "#52c41a",
                                        }}
                                      >
                                        {value.package_name}
                                      </Typography>
                                    }
                                    description={
                                      <>
                                        <Row gutter={[30, 30]}>
                                          <Col
                                            md={{ span: 12 }}
                                            // md={{ span: 12 }}
                                            sm={{ span: 24 }}
                                            xs={{ span: 24 }}
                                          >
                                            {value.description}
                                          </Col>
                                          <Col
                                            md={{ span: 6 }}
                                            // md={{ span: 12 }}
                                            sm={{ span: 24 }}
                                            xs={{ span: 24 }}
                                          >
                                            {/* <Row justify="center">Start from</Row> */}
                                            {/* <Row justify="center">
                                            {item.discount == 0 ? (
                                              <></>
                                            ) : (
                                              <Text delete>
                                                Rp.{" "}
                                                {` ${item.bestpick_price}`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                              </Text>
                                            )}
                                          </Row>
                                          <Row justify="center">
                                            <Title level={3}>
                                              Rp.{" "}
                                              {` ${
                                                item.bestpick_price -
                                                item.bestpick_price *
                                                  (item.discount / 100)
                                              }`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                            </Title>
                                          </Row> */}
                                            <Row>
                                              <Typography
                                                className="text-price"
                                                style={{
                                                  fontSize: 20,
                                                  fontWeight: 750,
                                                  color: "#52c41a",
                                                }}
                                              >
                                                {`Rp. ${value.total_price}`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                              </Typography>
                                            </Row>

                                            <Row justify="center">
                                              Per Person
                                            </Row>
                                            <Row justify="center">
                                              (Tax Included)
                                            </Row>

                                            <Row
                                              justify="center"
                                              style={{ marginTop: 15 }}
                                            >
                                              <Select
                                                value={packQty}
                                                style={{
                                                  width: 120,
                                                }}
                                                onChange={(e) => {
                                                  setPackQty(e);
                                                }}
                                                options={[
                                                  {
                                                    value: "1",
                                                    label: `1 x (Rp. ${` ${
                                                      value.total_price * 1
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "2",
                                                    label: `2 x (Rp. ${` ${
                                                      value.total_price * 2
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "3",
                                                    label: `3 x (Rp. ${` ${
                                                      value.total_price * 3
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "4",
                                                    label: `4 x (Rp. ${` ${
                                                      value.total_price * 4
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "5",
                                                    label: `5 x (Rp. ${` ${
                                                      value.total_price * 5
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "6",
                                                    label: `6 x (Rp. ${` ${
                                                      value.total_price * 6
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "7",
                                                    label: `7 x (Rp. ${` ${
                                                      value.total_price * 7
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "8",
                                                    label: `8 x (Rp. ${` ${
                                                      value.total_price * 8
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "9",
                                                    label: `9 x (Rp. ${` ${
                                                      value.total_price * 9
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                  {
                                                    value: "10",
                                                    label: `10 x (Rp. ${` ${
                                                      value.total_price * 10
                                                    }`.replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )})`,
                                                  },
                                                ]}
                                              />
                                            </Row>

                                            <Row
                                              justify="center"
                                              style={{ marginTop: 15 }}
                                            >
                                              <Button
                                                type="primary"
                                                onClick={() => {
                                                  // setArticlePickState({
                                                  //   package_name:
                                                  //     value.package_name,
                                                  //   package_code:
                                                  //     value.package_code,
                                                  //   package_qty: packQty,
                                                  //   total_price:
                                                  //     value.total_price * packQty,
                                                  //   total_taxes:
                                                  //     value.total_taxes,
                                                  // });
                                                  handleAddArticle({
                                                    package_name:
                                                      value.package_name,
                                                    package_code:
                                                      value.package_code,
                                                    package_qty: packQty,
                                                    total_price:
                                                      value.total_price *
                                                      packQty,
                                                    total_taxes:
                                                      value.total_taxes,
                                                    key: value.id,
                                                  });
                                                }}
                                              >
                                                Add
                                              </Button>
                                            </Row>
                                          </Col>

                                          <Col
                                            md={{ span: 6 }}
                                            // md={{ span: 24 }}
                                            sm={{ span: 24 }}
                                            xs={{ span: 24 }}
                                          >
                                            <Row justify="center">
                                              <img
                                                style={{ maxWidth: 272 }}
                                                width="100%"
                                                alt="logo"
                                                // src={HotelPict}
                                              />
                                            </Row>
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                  />
                                </Skeleton>
                              </List.Item>
                            )}
                          />
                        </Panel>
                      </Collapse>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

import { useState, useEffect } from "react";
import { Button, Col, Divider, Form, Input, Row, Select, DatePicker, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";

import WarehouseList from "../../Reusable/Inventory/Warehouse/WarehouseList";
import WarehouseArticleList from "../../Reusable/Inventory/Warehouse/WarehouseArticleList";

import { PlusOutlined } from "@ant-design/icons";

import StockTransferRequestDetailTable from "../../Table/BackOffice/Inventory/TransferRequest/StockTransferRequestDetail/Table";

export default function RequisitionConsumedForm(props) {
  const { is_edit, is_loading, onCancel, dept } = props;
  const [depts, setDepts] = useState(null);
  const [neededDate, setNeededDate] = useState(null);
  const [description, setDescription] = useState(null);
  const [fromStorage, setFromStorage] = useState(null);
  const [article, setArticle] = useState(null);
  const [articleTable, setArticleTable] = useState([]);
  const [ArticleIncomplete, setArticleIncomplete] = useState(null);
  const [countArticleData, setCountArticleData] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {console.log("depts", depts)},[depts]);
  useEffect(() => {console.log("neededDate", neededDate)},[neededDate]);
  useEffect(() => {console.log("fromStorage", fromStorage)},[fromStorage]);
  useEffect(() => {console.log("description", description)},[description]);

  useEffect(() => {
    if(dept && !is_edit) {
      setDepts(dept?.name);
      form.setFieldsValue({
        department_name: dept?.name,
      });
    } else {
      setDepts(null);
      form.setFieldsValue({
        department_name: null,
      });
    }
  }, [dept]);
  
  // HANDLE DATE
  const handleDate = (value) => { setNeededDate(value); };
  
  // HANDLE INITIAL STORAGE
  const handleFromStorage = (value) => {
    setFromStorage(value);
    
    form.setFieldsValue({
      from_storage: value?.warehouse_name ? value.warehouse_name : null,
    });
  };
  
  // HANDLE DESCRIPTION
  const handleDescription = (value) => { setDescription(value?.currentTarget.value); };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticle = (value) => {
    console.log("SELECTED ARTICLE >> ", value);
    setArticle(value);

    form.setFieldsValue({
      article_name: value?.article_name ? value.article_name : null,
      article_number: value?.article_number ? value.article_number : null,
      delivery_stock: value?.article_stock
        ? `${value.article_stock}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0,
      price: value?.average_price ? Math.round(value.average_price) : 0,
    });
  };

  // ON FINISH
  const onFinish = (data) => {
    console.log("DATAe>>> ", data);

    // check item in article table
    const findIndexItem = articleTable.findIndex((item) => {
      return item.article_name === article.article_name;
    });

    if (findIndexItem !== -1) {
      // if item found, return the alert notif 'item is exists'
      ArticleIncomplete(article, { method: 1 });
    } else {
      setArticleTable([
        ...(articleTable.length > 0 ? articleTable : []),
        {
          id_article: article?.id_article,
          article_name: article?.article_name,
          article_number: article?.article_number || null,
          delivery_stock: article?.article_stock || 0,
          quantity_requested: data?.qty > 0 ? data.qty : 0,
          price: data?.price > 0 ? data.price : 0,
          description: data?.article_description,
          total_price: data?.qty * data?.price || 0,
        },
      ]);

      setCountArticleData((state) => state + 1);
    }

    console.log("ARTICLE TABLE >> ", articleTable);

    form.resetFields([
      "article_name",
      "article_number",
      "delivery_stock",
      "price",
      "qty",
      "article_description",
    ]);
  };

  return (
    <>
      <Form
        name="add-transfer-request-modal-form"
        className="add-transfer-request-modal-form"
        autoComplete="off"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        style={{ padding: "0px 36px" }}
      >
        <Row gutter={30} className="row-transfer-request">
          <Col span={12}>
            <Form.Item
              label="Department"
              name="department_name"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input Department!",
                },
              ]}
            > 
              <Row className="master-row" align="top" justify="start" gutter={0}>
                <Col span={24} className="select-col">
                  <Select
                    className="master-dept"
                    name="master-dept"
                    key="master-dept"
                    value={depts}
                    disabled={true}
                    placeholder="Department"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Needed Date"
              name="needed_date"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input Needed Date!",
                },
              ]}
            >
              <DatePicker
                placeholder="Needed Date"
                allowClear
                // defaultValue={moment()}
                format={"YYYY-MM-DD"}
                onChange={handleDate}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30} className="row-transfer-request">
          <Col span={12}>
            <Form.Item
              label="From Storage"
              name="from_storage"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input From Storage!",
                },
              ]}
            >
              <WarehouseList
                selectedItem={handleFromStorage}
                warehouse_name={null}
                is_disable={false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30} className="row-transfer-request">
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              style={{ width: "100%" }}
            >
              <TextArea placeholder="Description" onChange={handleDescription}/>
            </Form.Item>
          </Col>
        </Row>

        <Divider
          className="divider-form"
          orientation="left"
          orientationMargin={0}
          style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
        >
          {`Select Articles`}
        </Divider>

        <Row className="article-row" gutter={30} justify="start">
          <Col span={8} className="article-col">
            <Form.Item
              label="Article"
              name="article_name"
              rules={[
                {
                  required: true,
                  message: "Please, Select an Article!",
                },
              ]}
            >
              <WarehouseArticleList
                selectedItem={handleSelectedArticle}
                dataWarehouse={fromStorage}
                setSelectedArticle={null}
                // article_name={edit ? dataEditArticle?.article_name : null}
                is_disable={false}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Article Number" name="article_number">
              <Input disabled placeholder="Article Number" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Stock On Hand" name="delivery_stock">
              <Input disabled placeholder="Stock On Hand" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col span={8}>
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Please, Input Price!",
                },
              ]}
            >
              <InputNumber
                min={0}
                placeholder="Price"
                style={{ width: "100%" }}
                // addonBefore={currency}
                formatter={(price) =>
                  ` ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                disabled={true}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Quantity"
              name="qty"
              rules={[
                {
                  required: true,
                  message: "Please, Input Quantity!",
                },
              ]}
            >
              <InputNumber
                placeholder="Quantity"
                min={0}
                max={form.getFieldValue("delivery_stock")}
                formatter={(qty) =>
                  ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Description" name="article_description">
              <Input placeholder="description" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="article-row" justify="end">
          <Button
            className="next-btn"
            type="primary"
            icon={<PlusOutlined />}
            htmlType="submit"
            // hidden={edit ? true : false}
            hidden={false}
            // disabled={edit ? true : false}
            disabled={false}
          >{`Add Article Inventory`}</Button>

          <Button
            className="next-btn"
            type="primary"
            // hidden={edit ? false : true}
            hidden={true}
            style={{ marginRight: "10px" }}
            // onClick={showModalConfirm}
          >{`Update`}</Button>

          <Button
            className="cancel-btn"
            type="default"
            // hidden={edit ? false : true}
            hidden={true}
            // onClick={handleCancelUpdate}
          >{`Cancel`}</Button>
        </Row>

        <Divider
          className="divider-form"
          orientation="left"
          orientationMargin={0}
          style={{ margin: "15px 0", borderColor: "#EBEDF3" }}
        ></Divider>

        <StockTransferRequestDetailTable
          article={articleTable}
          setArticle={setArticleTable}
          // is_refresh={is_refresh}
          // editArticle={setDataEditArticle}
          // is_edit={setEdit}
          // is_loading={isLoading}
          // is_selected={setSelected}
          // is_reset={selected}
          count={setCountArticleData}
        />

        <Divider
          className="form-divider"
          style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
        />

        {is_edit == true ? (
          <>
            <Row gutter={30} className="created-date-transfer-request">
              <Col span={8}>
                <Form.Item
                  label="Created By"
                  name="created_by"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Created Date"
                  name="created_date"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Updated By"
                  name="updated_by"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mod-date-transfer-request">
              <Col span={8}>
                <Form.Item
                  label="Updated Date"
                  name="updated_date"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />
          </>
        ) : null}

        <Row className="row-modal-btn" justify="end" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            key={"submit"}
            loading={is_loading}
            className="submit-btn"
            style={{
              marginRight: 15,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={onCancel}>
            {`Cancel`}
          </Button>
        </Row>
      </Form>
    </>
  );
}

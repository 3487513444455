// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { TfiLayoutListPost } from "react-icons/tfi";
import { BsCardHeading } from "react-icons/bs";
import { FaBarsStaggered } from "react-icons/fa6";
import {
  BarChartOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// Import Page Components
import ReportMasterDetailTable from "../../../../../Components/Table/Reports/Reports/Master/Details/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";

// CODE
export default function ReportMasterDetailPage() {
  // Value
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState(null);

  const handleSearch = (val) => {
    const _val = val.target.value;

    setValue(_val);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="reservation-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <TfiLayoutListPost
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Master Report Detail's`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  className="col-searh"
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Keywords`}
                  </Typography>

                  <Input
                    allowClear
                    className="input-search"
                    placeholder="Keywords"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                    }}
                  />
                </Col>

                <Col
                  className="col-search-btn"
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <ReportMasterDetailTable is_search={setSearch} searchKey={value} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React's Component
import React, { useContext, useEffect, useState } from "react";

// Import Ant Design Components
import { Layout, Button, Col, Input, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaBalanceScale } from "react-icons/fa";
import { SearchOutlined } from "@ant-design/icons";
import { BiAbacus } from "react-icons/bi";

// Import Global Styles
import { mainBody } from "../../../../../../Style/Global/Global";
import HistoryTrialBalanceTable from "../../../../../../Components/Table/Reports/Accounting/Monthly/TrialBalance/TableHistory";

// Import Page Components

// CODE
export default function HistoryTrialBalancePage() {
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="trial-balance-main-layout">
            <Row className="row-title" justify="start" style={mainBody}>
              <BiAbacus
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Trial Balance History`}
              </Typography>
            </Row>

            <Row
              className="space-row"
              style={{
                padding: 30,
              }}
            />

            <HistoryTrialBalanceTable />

            {/* <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Col
                style={{
                  textAlign: "start",
                  margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  Chart of Account
                </Typography>

                <Input
                  allowClear
                  placeholder="Chart Number"
                  //   onChange={handleSearch}
                  style={{ width: 300 }}
                />
              </Col>

              <Col
                style={{
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  //   onClick={search}
                >
                  Search
                </Button>
              </Col>
            </Row> */}
          </Col>
        </Content>
      </Layout>
    </>
  );
}

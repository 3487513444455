// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Divider, Radio, Table, Space, Button, Col, Row, Tag } from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Table's Column
const columns = [
  {
    title: "Reservation ID",
    dataIndex: "reservation_id",
    key: "reservation_id",
    width: 150,
    // fixed: "left",
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.id - b.id,
    render: (_, record) => {
      if (record.group == 2) {
        return (record.reservation_id = "");
      } else {
        return record.reservation_id;
      }
    },
  },
  {
    title: "Guest Name",
    dataIndex: "guest_name",
    key: "guest_name",
    width: 200,
    // fixed: "left",
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    render: (guest, record) => {
      return record.guest_category == "INCOGNITO" ? (
        <Tag color="default">{`PRIVATE`}</Tag>
      ) : record.guest_category == "RED ALERT" ? (
        <Tag color="magenta">{guest}</Tag>
      ) : (
        guest
      );
    },
  },
  {
    title: "Room Category",
    dataIndex: "room_category",
    key: "room_category",
    // filters: [
    //   {
    //     text: "DELUXE",
    //     value: "DELUXE",
    //   },
    //   {
    //     text: "STANDARD",
    //     value: "STANDARD",
    //   },
    //   {
    //     text: "SUPERIOR",
    //     value: "SUPERIOR",
    //   },
    //   {
    //     text: "SUITE",
    //     value: "SUITE",
    //   },
    //   {
    //     text: "EXECUTIVE",
    //     value: "EXECUTIVE",
    //   },
    // ],
    // onFilter: (value, record) => {
    //   // console.log("VALUE = ", value);
    //   return record.room_category === value;
    // },
  },
  {
    title: "Room No",
    dataIndex: "room_number",
    key: "room_number",
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.room_number - b.room_number,
  },
  {
    title: "Guest Status",
    dataIndex: "guest_category",
    key: "guest_category",
    filters: [
      {
        text: "REGULAR",
        value: "REGULAR",
      },
      {
        text: "VVIP",
        value: "VVIP",
      },
      {
        text: "VIP",
        value: "VIP",
      },
      {
        text: "INCOGNITO",
        value: "INCOGNITO",
      },
      {
        text: "RED ALERT",
        value: "RED ALERT",
      },
      {
        text: "UNASSIGNED",
        value: "",
      },
    ],
    onFilter: (value, record) => {
      // console.log("VALUE = ", value);
      return record.guest_category === value;
    },
    render: (category, record) => {
      let gst = "";
      let clr = "";

      switch (category) {
        case "VIP":
          gst = "VIP";
          clr = "geekblue";
          break;

        case "VVIP":
          gst = "VVIP";
          clr = "green";
          break;

        case "INCOGNITO":
          gst = "INCOGNITO";
          clr = "default";
          break;

        case "RED ALERT":
          gst = "RED ALERT";
          clr = "magenta";
          break;

        case "REGULAR":
          gst = "REGULAR";
          clr = "gold";
          break;

        case "":
          gst = "UNASSIGNED";
          clr = "purple";
          break;

        case null:
          gst = "UNASSIGNED";
          clr = "purple";
          break;

        default:
          break;
      }

      return (
        <Tag color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
          {gst}
        </Tag>
      );
    },
  },
  {
    title: "Night",
    dataIndex: "night",
    key: "night",
    width: 100,
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.night - b.night,
  },
  {
    title: "Check-In Date",
    dataIndex: "checkin_at",
    key: "checkin_at",
    sortDirections: ["descend", "ascend"],
    render: (checkin, record) => {
      return moment(checkin).format("HH:mm:ss - DD MMM YYYY");
    },
    sorter: (a, b) => moment(a.checkin_at) - moment(b.checkin_at),
  },
  {
    title: "Expected Departure",
    dataIndex: "departure",
    key: "departure",
    width: 200,
    sortDirections: ["descend", "ascend"],
    render: (depart, record) => {
      return moment(depart).format("DD MMM YYYY");
    },
    sorter: (a, b) => moment(a.departure) - moment(b.departure),
  },
  {
    title: "Adult",
    dataIndex: "adult",
    key: "adult",
    width: 100,
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.adult.localeCompare(b.adult),
  },
  // {
  //   title: "Child",
  //   dataIndex: "child",
  //   key: "child",
  //   sortDirections: ["descend", "ascend"],
  //   sorter: (a, b) => a.child.localeCompare(b.child),
  // },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
  },
  {
    title: "Room Rate",
    dataIndex: "room_rate_id",
    key: "room_rate_id",
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
  },
  {
    title: "Memo Room",
    dataIndex: "memo_room",
    key: "memo_room",
  },
];

// CODE
export default function WalkInGuestTable({ searchKey, is_search }) {
  // CONTEXT
  const { getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Laoding
  const [loading, setLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await getGuestInHouse({
      guestInHouse: "guest-in-house",
      type: "stay",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response.data.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt?.source.includes("Walk")) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // is_search(() => handleSearch);
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    let updatedList = bulks.filter((item) => {
      const _name = item?.guest_name.toLowerCase();
      // console.log("ITEM => ", item)
      return (
        item.guest_category != "INCOGNITO" &&
        _name.toLowerCase().indexOf(query) !== -1
      );
    });
    // Trigger render with updated values
    setData(updatedList);
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="walkin-guest-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="table-btn" span={24} style={{ padding: "30px" }}>
          <Row justify="end" className="row-refresh-btn">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="walkin-guest-table"
              name="walkin-guest-table"
              key="walkin-guest-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              scroll={{
                x: 2000,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  InputNumber,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { SearchOutlined } from "@ant-design/icons";
import { MdAccountBalanceWallet, MdScreenShare } from "react-icons/md";
import { BsCoin } from "react-icons/bs";
import { BiListPlus } from "react-icons/bi";
import { GiOpenBook, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { TbTable } from "react-icons/tb";

// Import Global Styles
import {
  cardHeader,
  cardBody,
  mainBody,
  globalText,
  gridStyle,
  globalIcon,
  linkStyle,
} from "../../../Style/Global/Global";

// CODE
export default function StartUpAccountingPage() {
  // STATE MANAGEMENT

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="start-up-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <BsCoin
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`START-UP ACCOUNTING`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col className="col-table-initial" span={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("initial-accounting-table")}
                    // type="primary"
                    icon={<TbTable style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <GiOpenBook style={globalIcon} /> */}
                    <Typography className="text-page" style={globalText}>
                      {`Initial Accounting Tables`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("initial-balance-settings")}
                    // type="primary"
                    icon={<RiMoneyDollarCircleFill style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <GiOpenBook style={globalIcon} /> */}
                    <Typography className="text-page" style={globalText}>
                      {`Initial Balance`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("initial-debts-settings")}
                    // type="primary"
                    icon={<GiTakeMyMoney style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <RiBuilding2Fill style={globalIcon} /> */}
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Initial Debts/Liabilities`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("initial-receivables-settings")}
                    // type="primary"
                    icon={<GiReceiveMoney style={globalIcon} />}
                    style={linkStyle}
                  >
                    {/* <GiOpenBook style={globalIcon} /> */}
                    <Typography className="text-page" style={globalText}>
                      {`Initial Receivables`}
                    </Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Tag } from "antd";

// REACT ICONS
import { FaMoneyBillWave } from "react-icons/fa";
import { IoReturnUpBackOutline } from "react-icons/io5";

// GLOBAL STYLES
import { mainBody } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import ModalAPPayment from "../../../../Modals/Accounting/AP/Payments/Modal";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// NOTIFICATIONS
import { failedFetch } from "../../../../Reusable/Notification/Notification";

export default function APPaymentTable() {
  // CONTEXT
  const { getAPList } = useContext(FinanceContext);

  const [data, setData] = useState([]);
  const [details, setDetails] = useState([]);
  const [dataPayment, setDataPayment] = useState({
    payment: "",
    code: 0,
    coa: "",
  });

  const [detailsIsExist, setDetailsIsExist] = useState(false);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  // Loading
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // Navigate
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  // FETCH DATA
  const fetchData = async () => {
    // if (value) {
    await getAPList({
      type: "ap-header",
      // coa: value ? value : dataPayment.coa,
      coa: null,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Respones Headers: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });

    // await getARList({
    //   type: "ar-payment-details",
    //   // coa: value ? value : dataPayment.coa,
    //   onAwait: () => {
    //     setDetailsIsExist(false);
    //   },
    //   onSuccess: (response) => {
    //     console.log("Respones Details: ", response);

    //     if (response?.data?.msg?.length > 0) {
    //       const _res = response.data.msg;

    //       setDetails(_res);
    //     } else {
    //       setDetails([]);
    //     }

    //     setDetailsIsExist(true);
    //   },
    //   onReject: (error) => {
    //     console.log("Error Details: ", error);
    //     setLoading(false);
    //     failedFetch(error);
    //   },
    // });
    // }
  };

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray(selectedRowKeys);
      handlePick(selectedRowKeys);
      setDataPayment(selectedRows);
    },
  };

  // HANDLE PICK
  const handlePick = (val) => {
    const _arr = val;

    if (_arr?.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, `A/P Payment`);
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    console.log("Refresh: ", data);

    fetchData();
    handleClear();
  };

  // HANDLE CLEAR TABLE SELECTION
  const handleClear = () => {
    setSelectedRowArray([]);
    handlePick([]);
    setDataPayment({
      payment: "",
      code: 0,
      coa: "",
    });
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/back-office/account-payable";

    navigate(`${path}`);
  };

  // HANDLE OPEN
  const handleOpen = () => {
    setModalOpen(true);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Document No.",
      dataIndex: "document_number",
      key: "document_number",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice",
      key: "invoice",
      render: (invoice) => {
        return invoice ? invoice : "-";
      },
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "Received Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (date) => {
        let _date = date ? moment(date).format("DD-MM-YYYY") : "-";

        return _date;
      },
    },
    {
      title: "Debit",
      dataIndex: "credit",
      key: "credit",
      render: (credit) => {
        return `${credit > 0 ? credit : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Final Balance",
      dataIndex: "final_balance",
      key: "final_balance",
      render: (amount) => {
        return `${amount > 0 ? amount : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    // {
    //   title: "Account Name",
    //   dataIndex: "account_name",
    //   key: "account_name",
    // },
    // {
    //   title: "Chart of Account",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    // },
    // {
    //   title: "Normal Balance",
    //   dataIndex: "normal_balance",
    //   key: "normal_balance",
    //   render: (balance) => {
    //     let type = "";
    //     let clr = "";
    //     switch (balance) {
    //       case 0:
    //         type = "DEBIT";
    //         clr = "geekblue";
    //         break;

    //       case 1:
    //         type = "CREDIT";
    //         clr = "green";
    //         break;

    //       default:
    //         break;
    //     }

    //     return (
    //       <Tag color={clr} key={type}>
    //         {type}
    //       </Tag>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Row className="ap-pay-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  // onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  disabled={isDisabled}
                  onClick={handleOpen}
                  icon={
                    <FaMoneyBillWave
                      className="pay-icon"
                      style={{
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 15px 10px 5px",
                    // height: 40,
                    minWidth: 75,
                    borderColor: "transparent",
                  }}
                >
                  {`Pay`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={() => selected?.coa && handleRefresh()}
                  onClick={() => handleRefresh()}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="ap-pay-table"
              name="ap-pay-table"
              key="ap-pay-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id ? record.id : record.department_id
              }
            />
          </Row>
        </Col>
      </Row>

      <ModalAPPayment
        openModal={modalOpen}
        closeModal={setModalOpen}
        is_refresh={handleRefresh}
        ar_data={dataPayment}
      />
    </>
  );
}

// Import React's Components
import React, { createContext, useReducer } from "react";

// CONTEXT CODE
// eslint-disable-next-line
export default (reducer, actions, defaultValue) => {
  // Create Context
  const Context = createContext();

  // Create Provider
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions = {};

    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider
        value={{
          state,
          ...boundActions,
          // dispatch,
        }}
      >
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

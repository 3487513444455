// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  InputNumber,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { SearchOutlined } from "@ant-design/icons";
import { MdAccountBalanceWallet, MdScreenShare } from "react-icons/md";
import { BsCoin } from "react-icons/bs";
import { HiMiniPresentationChartBar } from "react-icons/hi2";
import { HiPresentationChartBar } from "react-icons/hi";
import { GiOpenBook, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import {
  TbFileDollar,
  TbCurrencyDollar,
  TbCurrencyDollarAustralian,
  TbCurrencyDollarCanadian,
  TbCurrencyDollarSingapore,
} from "react-icons/tb";
import { FaBarsStaggered } from "react-icons/fa6";
import { RiFileChartFill } from "react-icons/ri";
import { FaFileInvoiceDollar } from "react-icons/fa";

// Import Global Styles
import {
  cardHeader,
  cardBody,
  mainBody,
  globalText,
  gridStyle,
  globalIcon,
  linkStyle,
} from "../../../../Style/Global/Global";

// Meta
const { Meta } = Card;

// CODE
export default function AccountingReportsPage() {
  // STATE MANAGEMENT

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <FaFileInvoiceDollar
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Accounting Report`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col
                span={24}
                className="main-col-page"
                style={{
                  padding: "0px 30px 30px",
                }}
              >
                <Card
                  className="acc-card-header"
                  key="accouting-report-daily"
                  bordered
                  style={{
                    margin: "30px 0px 0px",
                    border: "2px solid #f0f0f0",
                    borderRadius: 5,
                  }}
                  title={"Daily Accounting Reports"}
                  // bodyStyle={{
                  //   padding: "30px 0px 0px",
                  // }}
                >
                  <Row
                    className="row-desc"
                    justify="start"
                    align="bottom"
                    style={{ width: "100%" }}
                  >
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      xs={24}
                      className="desc-col"
                    >
                      {`Accounting report group by Daily data and History.`}
                    </Col>

                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      xs={24}
                      className="btn-col"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        className="next-btn"
                        type="primary"
                        onClick={() => move("daily-accounting-report")}
                      >
                        {`Go to Page`}
                      </Button>
                    </Col>
                  </Row>
                </Card>

                <Card
                  className="acc-card-header"
                  key="accouting-report-monthly"
                  bordered
                  style={{
                    margin: "30px 0px 0px",
                    border: "2px solid #f0f0f0",
                    borderRadius: 5,
                  }}
                  title={"Monthly Accounting Report"}
                  // bodyStyle={{
                  //   padding: "30px 0px 0px",
                  // }}
                >
                  <Row
                    className="row-desc"
                    justify="start"
                    align="bottom"
                    style={{ width: "100%" }}
                  >
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      xs={24}
                      className="desc-col"
                    >
                      {`Accounting report group by Monthly data and History.`}
                    </Col>

                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      xs={24}
                      className="btn-col"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        className="next-btn"
                        type="primary"
                        onClick={() => move("monthly-accounting-report")}
                      >
                        {`Go to Page`}
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

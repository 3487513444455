// Import React Components
import React from "react";

// Import Antd Components
import { Card, Col, Layout, Row } from "antd";
import { Content, Header } from "antd/lib/layout/layout";

// Import Page Components
import HeaderManager from "../../../Layout/ChannelManager/Header/Header";
import FooterManager from "../../../Layout/ChannelManager/Footer/Footer";
import ManagerRoutes from "../../../Routes/ChannelManager/Routes";

// CODE
export default function ManagerMainPage() {
  return (
    <>
      <Layout>
        <Content>
          <Header className="header-manager">
            <HeaderManager />
          </Header>

          <Layout style={{ backgroundColor: "#002766" }}>
            <Content
              className="content-main"
              style={{
                margin: "45px 30px",
                padding: 24,
                minHeight: "80vh",
              }}
            >
              <Row className="row-main-layout">
                <Col span={24} className="col-main-layout">
                  <ManagerRoutes />
                  {/* <Card title={"Channel Manager Main Page"} /> */}
                </Col>
              </Row>
            </Content>
          </Layout>

          <FooterManager />
        </Content>
      </Layout>
    </>
  );
}

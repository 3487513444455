// Import React Components
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// Import Ant Design Components
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  Typography,
  Image,
  Divider,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BiSolidFilePdf } from "react-icons/bi";
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import GuestReportList from "../../PrintLayout/FrontOffice/GuestList";

// CODE
export default function DepartureForm(props) {
  // PROPS
  const { modalOpen, modalClose, dataDepart } = props;

  // Columns
  const columns = [
    // {
    //   title: "Reservation No",
    //   dataIndex: "reservation_id",
    //   // fixed: "left",
    //   width: 175,
    //   key: "reservation_id",
    // },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // ellipsis: true,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      // width: 100,
      render: (arrival, record) => {
        return moment(arrival).format("DD-MM-YYYY");
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      // width: 100,
      render: (depart, record) => {
        return moment(depart).format("DD-MM-YYYY");
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      // width: 50,
    },
    {
      title: "Room",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Segment",
      dataIndex: "segment",
      key: "segment",
      // ellipsis: true,
    },
    {
      title: "Rate Code",
      dataIndex: "room_rate_id",
      key: "room_rate_id",
      render: (rate) => {
        return rate?.length > 0 ? rate[0] : " - ";
      },
    },
    // {
    //   title: "Guest Type",
    //   dataIndex: "group",
    //   key: "group",
    //   render: (group, record) => {
    //     let grp = "";

    //     switch (group) {
    //       case 0:
    //         grp = "Individual Guest";
    //         break;

    //       case 1:
    //         grp = "Group Leader Guest";
    //         break;

    //       case 2:
    //         grp = "Group Member Guest";
    //         break;

    //       default:
    //         break;
    //     }

    //     return grp;
    //   },
    // },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);

  // Modal State
  const [open, setOpen] = useState(false);
  // USE REF
  const componentRef = useRef();

  // FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (modalOpen == true && !cleanUp) {
      setOpen(modalOpen);
    }
    if (dataDepart && !cleanUp) {
      console.log("About to Print => ", dataDepart);

      setData(dataDepart);
    }

    return () => {
      cleanUp = true;
    };
  }, [modalOpen, dataDepart]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    modalClose(false);

    form.resetFields();
    // setEdit(null);
  };

  // ON FINISH
  // const pageStyle = "@media print{@page {size: landscape}}";
  const onFinish = () => {
    // console.log("CLEAN");

    handlePrint();
  };

  // HANDLE REACT PRINT
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // {
    //   return <GuestReportList ref={componentRef} />;
    // },
    documentTitle: `Departure List ${data?.reservation_id}`,
    onafterprint: () => alert("Print Success"),

    // pageStyle: pageStyle,
  });

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row>
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Guest In House List`}
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: "100%",
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          name="checkout-form-modal"
          className="checkout-form-modal"
          key="checkout-form-modal"
          layout="horizontal"
        >
          <Content
            className="content-print-form"
            ref={componentRef}
            style={{
              //   border: "solid 1px",
              padding: 30,
            }}
          >
            <Row gutter={30} className="row-header">
              <Col span={12} className="col-title-form">
                <Typography.Title level={2} className="text-form-title">
                  Guest In House List
                </Typography.Title>
              </Col>

              <Col span={12} align="middle" className="col-img-logo">
                <Image
                  src="/looklogo(color).png"
                  className="img-form-logo"
                  preview={false}
                  style={{
                    width: "25%",
                  }}
                />

                <Typography.Title level={4} className="text-form-id">
                  Idea's Hotel Bandung
                </Typography.Title>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "grey" }}
            />

            <Typography
              className="text-date"
              style={{
                padding: "0px 0px 30px",
                fontWeight: 750,
              }}
            >
              Date: {`${moment().format("DD-MM-YYYY")}`}
            </Typography>

            <Table
              className="arrival-list-table"
              key="arrival-list-table"
              name="arrival-list-table"
              columns={columns}
              dataSource={data}
              bordered
              size="small"
              pagination={false}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-form" : "odd-form";
              }}
            />
          </Content>

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              icon={<PrinterOutlined />}
              type="primary"
              //   onClick={() => {
              //     handlePrint();
              //   }}
              htmlType="submit"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>

      {/* <GuestReportList ref={componentRef} /> */}
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterSource(props) {
  // PROPS
  const { getSource, srce } = props;

  // CONTEXT
  const { getMasterSource } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [source, setSource] = useState([]);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterSource({
      source: "source",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Source => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.source,
            label: dev?.source,
            id: dev?.id,
          }));

          console.log("Source = ", option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (srce && !cleanUp) {
      setSource(srce);
    } else {
      setSource(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [srce]);

  // Handle Change
  const handleChange = (value) => {
    const _names = value?.values;
    const _allKeys = value?.allKeys;

    setSource(_names);
    getSource({
      name: _names,
      source_id: _allKeys?.id,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setSource(null);
  };

  return (
    <>
      <Select
        key={"source-select"}
        className={"source-select"}
        style={{ width: "100%" }}
        placeholder="Select Source!"
        value={source}
        allowClear
        showSearch
        onClear={handleClear}
        onChange={(value, opt) => {
          handleChange({
            values: value,
            allKeys: opt,
          });
        }}
        options={optionValue}
      />
    </>
  );
}

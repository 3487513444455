// Import React Components
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  InputNumber,
  Button,
} from "antd";

// Import React Icons
import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// Import Master Components
import MasterArticleSub from "../../Reusable/Master/ArticleSub/ArticleSub";
import MasterArticleList from "../../Reusable/Master/ArticleList/ArticleList";
import MiceArticleTable from "../../Table/MICE/Article/Table";
import MasterArticleMain from "../../Reusable/Master/ArticleMain/ArticleMain";
import MasterDepartment from "../../Reusable/Master/Department/Department";
import MasterGuest from "../../Reusable/Master/Guest/Guest";

// Import Notification
import { masterIncomplete } from "../../Reusable/Notification/MasterNotif/Notification";
import {
  failedArticleMice,
  failedFetch,
  successArticleMice,
} from "../../Reusable/Notification/Notification";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function ModalArticleMice(props) {
  // PROPS
  const { openModal, closeModal, dataArticle } = props;

  // Params
  const currency = CurrencySymbols().code;

  // KEYS
  const [lastKey, setLastKey] = useState(0);
  const [key, setKey] = useState(0);

  // USE CONTEXT
  const { getArticleMice } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // Data Article Table
  const [articleTable, setArticleTable] = useState([]);
  // Master Data
  const [subGroup, setSubGroup] = useState({
    article_sub_name: "",
    article_sub_id: 0,
  });
  const [article, setArticle] = useState({
    article_name: "",
    article_id: 0,
    chart_of_account: "",
  });
  // Master Code
  const [articlePrice, setArticlePrice] = useState({
    nett: 0,
    service: 0,
    tax: 0,
    gross: 0,
  });
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Reset or Refresh
  const [refresh, setRefresh] = useState(false);
  const [select, setSelect] = useState([]);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      console.log("Data Mice --> ", dataArticle);

      setData(dataArticle);
      setOpen(!open);

      fetchData();

      setModalFields(dataArticle);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // USE EFFECT EDIT
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // FETCH DATA
  const fetchData = async () => {
    await getArticleMice({
      articleMice: "mice",
      type: "article-reservation",
      reservation_id: dataArticle?.reservation_id
        ? dataArticle.reservation_id
        : null,
      // price_id: dataArticle?.price_id ? dataArticle.price_id : 0,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response >> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            if (val.is_deleted == null || val.is_deleted == false) {
              return val;
            }
          });

          setArticleTable(filtered);
          form.setFieldValue("table_article", filtered);
        } else {
          setArticleTable([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error >> ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // SET FORM FIELDS
  const setFormFields = (val) => {
    const _dataEdit = val;

    form.setFieldsValue({
      qty: _dataEdit?.item_quantity,
      unit_price: _dataEdit?.unit_price,

      article_name: _dataEdit?.article_name,
      article_id: _dataEdit?.article_id,

      article_sub_id: _dataEdit?.article_sub_id,
      article_sub_name: _dataEdit?.article_sub_name,

      total_price: _dataEdit?.total_price,
    });
  };

  // Handle Edit
  const handleEdit = (value) => {
    const _data = value;

    console.log("About to Edit: ", _data);

    setDataEdit(_data);
    setSubGroup({
      article_sub_id: _data?.article_sub_id,
      article_sub_name: _data?.article_sub_name,
    });
    setFormFields(_data);
  };

  // Form modals
  const setModalFields = (val) => {
    const _modal = val;

    form.setFieldsValue({
      reservation_id: _modal?.reservation_id,
      guest_name: _modal?.guest_name,
    });
  };

  // Get Article Sub
  const getArticleSub = (value) => {
    console.log(value);

    setSubGroup({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });

    if (edit == true) {
      dataEdit.article_sub_name = value?.name;
      dataEdit.article_name = null;
      dataEdit.article_id = null;

      form.setFieldsValue({
        article_name: null,
      });
    }

    form.setFieldsValue({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });
  };

  // Get Article
  const getArticle = (value) => {
    console.log("Article Code --> ", value);

    setArticle({
      article_id: value?.article_id,
      article_name: value?.article_name,
      chart_of_account: value?.coa,
      tax: value?.tax,
      service: value?.service,
      nett: value?.price,
      gross: value?.gross,
    });

    setArticlePrice({
      gross: value?.gross,
      nett: value?.price,
      service: value?.service,
      tax: value?.tax,
    });

    if (edit == true) {
      dataEdit.article_name = value?.article_name;
      dataEdit.article_id = value?.article_id;
      dataEdit.unit_price = value?.price;
    }

    form.setFieldsValue({
      article_name: value?.article_name,
      article_id: value?.article_id,
      unit_price: value?.gross,
      chart_of_account: value?.coa,
      tax: value?.tax,
      service: value?.service,
      nett: value?.price,
      is_service: value?.service > 0 ? true : false,
      is_tax: value?.tax > 0 ? true : false,
    });
  };

  // Handle Total
  const handleTotal = (value) => {
    const _fields = form.getFieldValue("unit_price");
    let _total = _fields * value;

    // console.log("QTY: ", value, " * Price: ", _fields);
    // console.log("Total: ", _total);

    form.setFieldValue("total_price", _total);
  };

  // Handle Close
  const handleClose = () => {
    setOpen(!open);

    closeModal(false);
    setEdit(false);

    setSelect([]);
    setArticleTable([]);

    // Data
    setSubGroup({
      article_sub_name: "",
      article_sub_id: 0,
    });
    setArticle({
      article_name: "",
      article_id: 0,
      chart_of_account: "",
    });

    setDataEdit(null);
    setLastKey(0);
    setIsLoading(false);

    form.resetFields();
  };

  // HANDLE CONFIRMATION ADD
  const showModalConfirm = () => {
    setIsLoading(true);
    let submitContent = data;

    let submitEdit = form.getFieldsValue();

    console.log("MODAL CONFIRM >>> ", submitContent);
    console.log("MODAL Edit >>> ", submitEdit);

    console.log(articlePrice);

    confirm({
      className: "article-non-stay-confirm",
      title: `Are you sure want to ${
        edit ? "Update" : "Add New Article"
      } Article with guest named ${
        submitContent?.guest_name?.toUpperCase() || "Unknown"
      } ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleFinish(edit == false ? dataArticle : submitEdit);
        // console.log("Guest deleted");
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE FINISH CONFIRMATION
  const handleFinish = async (value) => {
    const content = value;

    console.log("FINISH Content >> ", content);
    console.log("Article Table >> ", articleTable);

    if (edit == true) {
      let editData = {
        reservation_id: content?.reservation_id
          ? content.reservation_id
          : dataEdit?.reservation_id,
        article_id: article.article_id
          ? article.article_id
          : dataEdit?.article_id,
        article_sub_name: content?.article_sub_name
          ? content.article_sub_name
          : dataEdit?.article_sub_name,
        article_sub_id:
          subGroup.article_sub_id > 0
            ? subGroup.article_sub_id
            : dataEdit.article_sub_id,
        article_name: content?.article_name
          ? content.article_name
          : dataEdit?.article_name,
        unit_price: content?.unit_price
          ? content.unit_price
          : dataEdit?.unit_price,
        item_quantity: content?.qty > 0 ? content.qty : dataEdit?.item_quantity,
        total_price:
          content?.unit_price > 0
            ? parseInt(content.unit_price * content?.qty)
            : dataEdit?.total_price,
        posting_type: content?.posting_type
          ? content.posting_type
          : dataEdit?.posting_type,
        total_posting: content?.total_posting
          ? content.total_posting
          : dataEdit?.total_posting,
        chart_of_account: article?.chart_of_account
          ? article.chart_of_account
          : dataEdit?.chart_of_account,
        start_date: data?.date_periodic
          ? moment(content.date_periodic[0]).format("YYYY-MM-DD")
          : dataEdit?.start_date,
        end_date: data?.date_periodic
          ? moment(content.date_periodic[1]).format("YYYY-MM-DD")
          : dataEdit?.end_date,
        tax: article?.tax ? article.tax : dataEdit?.tax,
        service: article?.service ? article.service : dataEdit?.service,
        is_service: content?.is_service
          ? content.is_service
          : dataEdit?.is_service,
        is_tax: content?.is_tax ? content.is_tax : dataEdit?.is_tax,
        // price_id: dataEdit?.price_id || 0,
        id: dataEdit?.id || 0,

        updated_by: roles,
      };

      handleSubmit(editData);
    } else {
      let filtered = articleTable.filter((value) => {
        if (value.id == null) return value;
      });

      console.log("Filtered --> ", filtered);

      let allBody = [];

      for (let i = 0; i < filtered.length; i++) {
        allBody.push({
          reservation_id: data?.reservation_id ? data?.reservation_id : "",
          article_id: filtered[i].article_id ? filtered[i].article_id : 0,
          article_sub_name: filtered[i].article_sub_name
            ? filtered[i].article_sub_name
            : "",
          article_sub_id: filtered[i].article_sub_id
            ? filtered[i].article_sub_id
            : 0,
          article_name: filtered[i].article_name
            ? filtered[i].article_name
            : "",
          unit_price: filtered[i].unit_price ? filtered[i].unit_price : 0,
          item_quantity: filtered[i].item_quantity
            ? filtered[i].item_quantity
            : 0,
          total_price: filtered[i].total_price ? filtered[i].total_price : 0,
          posting_type: filtered[i].posting_type
            ? filtered[i].posting_type
            : "",
          total_posting: filtered[i].total_posting
            ? filtered[i].total_posting
            : "",
          chart_of_account: filtered[i].chart_of_account
            ? filtered[i].chart_of_account
            : "",
          start_date: data?.event_start ? data.event_start : "",
          end_date: data?.event_end ? data.event_end : "",
          tax: filtered[i]?.tax ? filtered[i].tax : 0,
          service: filtered[i].service ? filtered[i].service : 0,
          is_service: filtered[i].is_service ? filtered[i].is_service : false,
          is_tax: filtered[i].is_tax ? filtered[i].is_tax : false,
          // price_id: data?.price_id ? data?.price_id : 0,

          created_by: user_name,
        });
      }
      handleSubmit(allBody);
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    // console.log("FAILED >> ", value);

    masterIncomplete(value);
  };

  // ADD ARTICLE ON FINISH
  const onFinish = (value) => {
    const newArticle = value;

    console.log("NEW ARTICLE >>> ", newArticle);
    console.log("ARTICLE: ", article);

    let newArr = [
      ...(articleTable ? articleTable : []),
      {
        guest_name: newArticle?.guest_name,
        reservation_id: newArticle?.reservation_id,
        article_sub_name: newArticle?.article_sub_name,
        article_sub_id: subGroup.article_sub_id,
        article_name: newArticle?.article_name,
        article_id: article.article_id,
        item_quantity: newArticle?.qty,
        unit_price: newArticle?.unit_price,
        total_price: parseInt(newArticle?.qty * newArticle?.unit_price),
        // id: qty *
        chart_of_account: article.chart_of_account,
        key: key + lastKey,

        tax: article?.tax > 0 ? article.tax : 0,
        service: article?.service > 0 ? article.service : 0,
        is_tax: article?.tax > 0 ? true : false,
        is_service: article?.service > 0 ? true : false,
      },
    ];

    setArticleTable(newArr);

    form.resetFields([
      "article_name",
      "article_sub_name",
      "qty",
      "unit_price",
      "total_price",
    ]);

    setLastKey(lastKey + 1);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    let contentSubmit = value;
    console.log("ON SUBMIT >> ", contentSubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/fnb/mice/article`,
          { data: contentSubmit },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("Response Add Article Non Stay: ", response);

          successArticleMice(response, { method: 0 });
          handleClose();
        })
        .catch((error) => {
          console.log("Error: ", error);
          failedArticleMice(error, { method: 0 });
          setIsLoading(false);
        });
    } else {
      await axios
        .put(`${baseurl}/fnb/mice/article`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Update Article Non Stay: ", response);

          successArticleMice(response, { method: 1 });
          handleClose();
          fetchData();
        })
        .catch((error) => {
          console.log("Error: ", error);
          setIsLoading(false);
          failedArticleMice(error, { method: 1 });
        });
    }
  };

  return (
    <>
      <Modal
        className="mice-modal"
        title={
          <Row>
            <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography style={{ marginLeft: 15 }}>{`MICE Article`}</Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleClose}
        width={800}
        bodyStyle={{
          borderRadius: 15,
        }}
        footer={null}
      >
        <Form
          name="article-modal-form"
          className="article-modal-form"
          key="article-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-nonstay-id" justify="start">
            <Col span={12} className="res-col">
              <Form.Item
                label="Reservation ID"
                name="reservation_id"
                style={{ width: "100%" }}
              >
                <Input disabled placeholder="Reservation ID" />
              </Form.Item>
            </Col>

            <Col span={12} className="guest-col">
              <Form.Item
                label="Guest Name"
                name="guest_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Guest Name!",
                  },
                ]}
              >
                <Input disabled placeholder="Guest's Name" />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-nonstay-desc">
            {/* <Col span={8}>
              <Form.Item
                label="Department"
                name="department_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Department!",
                  },
                ]}
                style={{
                  width: "100%",
                }}
              >
                <MasterDepartment
                  getDepartment={getDepartment}
                  department_name={edit ? dataEdit.department_name : ""}
                />
              </Form.Item>

              <Form.Item label="Department" name="department_id" hidden>
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Article Group"
                name="article_main_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Article Main!",
                  },
                ]}
                style={{
                  width: "100%",
                }}
              >
                <MasterArticleMain
                  getArticleMain={getArticleMain}
                  getDepartmentId={
                    edit == true
                      ? dataEdit?.department_name
                      : dept.department_id
                  }
                  mainName={edit ? dataEdit.article_main_name : ""}
                />
              </Form.Item>

              <Form.Item
                label="Article Main Group"
                name="article_main_id"
                hidden
              >
                <InputNumber />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item
                label="Article Sub-Group"
                name="article_sub_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Article Sub!",
                  },
                ]}
                style={{
                  width: "100%",
                }}
              >
                <MasterArticleSub
                  getArticleSub={getArticleSub}
                  getArticleMainId={0}
                  articleSubName={
                    dataEdit?.article_sub_name
                      ? dataEdit.article_sub_name
                      : null
                  }
                  isDisabled={edit === true ? true : false}
                />
              </Form.Item>

              <Form.Item label="Article Sub Group" name="article_sub_id" hidden>
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={12} className="art-col">
              <Form.Item
                label="Article"
                name="article_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Article!",
                  },
                ]}
              >
                <MasterArticleList
                  getArticle={getArticle}
                  getArticleSub={subGroup.article_sub_id}
                  is_FOparams={false}
                  articleName={
                    dataEdit?.article_name ? dataEdit.article_name : null
                  }
                  isDisabled={edit === true ? true : false}
                />
              </Form.Item>

              <Form.Item label="Article ID" name="article_id" hidden>
                <InputNumber />
              </Form.Item>

              <Form.Item label="Tax" name="tax" hidden>
                <InputNumber />
              </Form.Item>

              <Form.Item label="Article ID" name="service" hidden>
                <InputNumber />
              </Form.Item>

              <Form.Item
                label="Tax"
                name="is_tax"
                valuePropName="checked"
                hidden
              >
                <Checkbox>{`Includes Tax`}</Checkbox>
              </Form.Item>

              <Form.Item
                label="Service"
                name="is_service"
                valuePropName="checked"
                hidden
              >
                <Checkbox>{`Includes Service`}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-article-data">
            <Col span={8}>
              <Form.Item
                label="Quantity"
                name="qty"
                rules={[
                  {
                    required: true,
                    message: "Please, input article's quantity!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Quantity"
                  className="qty"
                  onChange={handleTotal}
                  min={0}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Item Price"
                name="unit_price"
                style={{ width: "100%" }}
              >
                <InputNumber
                  min={0}
                  addonBefore={currency}
                  formatter={(unit_price) => {
                    return `${unit_price}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  placeholder="Price"
                  disabled
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Total Price"
                name="total_price"
                style={{ width: "100%" }}
              >
                <InputNumber
                  min={0}
                  addonBefore={currency}
                  formatter={(unit_price) => {
                    return `${unit_price}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  placeholder="Total Price"
                  disabled
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          {/* {edit == true ? (
            <></>
          ) : ( */}
          <Row justify="end">
            <Button
              type="primary"
              className="next-btn"
              disabled={edit == true ? true : false}
              icon={<PlusOutlined />}
              // onClick={addArticle}
              htmlType="submit"
            >
              {`Add Article`}
            </Button>
          </Row>
          {/* )} */}

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px 15px", background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-article-nonstay-table">
            <p>*Choose for edit data</p>
            <Form.Item
              className="table-form"
              name="table_article"
              style={{ margin: "0px 0px 5px" }}
            >
              <MiceArticleTable
                article={open == true ? articleTable : []}
                newArticle={article}
                editArticle={handleEdit}
                is_loading={loading}
                is_edited={setEdit}
                is_cashier={false}
                is_refresh={setRefresh}
                is_deleted={setArticleTable}
                is_reset={select}
              />
            </Form.Item>
          </Row>

          <Divider
            className="form-divider"
            style={{ background: "#EBEDF3", margin: "0px 0px 15px" }}
          />

          <Row className="row-modal-btn" justify="end" align="middle">
            <Button
              type="primary"
              // htmlType="submit"
              onClick={showModalConfirm}
              key="submit"
              className="submit-btn"
              loading={isLoading}
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

import { React, useState, useContext, useEffect } from "react";
import moment from "moment";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Typography,
  Select,
  Checkbox,
} from "antd";

import MasterRateCode from "../../Reusable/Master/RateCode/RateCode";
// Import Master Notification
import {
  masterIncomplete,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import React's Icon
import { FiEdit } from "react-icons/fi";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

export default function ChangeRateCodeModal(props) {
  // PROPS
  const { is_open, is_close, selected, optionValue, getChange, night } = props;

  // CONTEXT
  const { getMasterRateSegment } = useContext(MasterContext);

  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);

  // Modal
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rows, setRows] = useState(null);
  const [isRender, setIsRender] = useState(true);

  // Button
  const [disable, setDisable] = useState(false);
  const [disableNight, setDisableNight] = useState(false);

  // Loading
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Modal Confirm
  const { confirm } = Modal;

  // Params
  const currency = CurrencySymbols().code;

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      // console.log("Modal State: ", is_open);
      console.log("Modal State: ", selected);
      console.log("Modal State: ", optionValue);

      handleOpen();
      fetchData();
    } else {
      setModalOpen(false);
      is_close(false);

      form.setFieldsValue({
        request_date: moment(),
      });
    }

    if (night === 1) {
      setDisable(true);
    } else {
      setDisable(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, optionValue, night]);

  // useEffect(() => {
  //   fetchData();
  // }, [optionValue]);

  // Handle Open
  const handleOpen = () => {
    // console.log("USERS METADATA: ", approval_level, department, user_level);
    setModalOpen(true);

    form.setFieldsValue({
      room_category: selected?.room_category_name,
    });
  };

  // CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    setIsRender(true);
    setButtonLoading(false);

    setDisable(false);
    setDisableNight(false);

    setModalOpen(false);
    setOpen(false);
    is_close(false);
  };

  // ON FINISH
  const onFinish = (e) => {
    setButtonLoading(true);

    const _content = e;
    let str = _content.rate_code;

    console.log(_content);
    // handleSubmit(e);

    _content.rate_code = str.toUpperCase();

    if (!_content.description) {
      _content.description = `${
        _content?.room_category_name || "GROUP"
      } ROOM for ${_content.room_occupancy} Adult(s) and ${
        _content.room_breakfast
      } Pax Breakfast(s)`;
    }

    showModalConfirm(_content);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = async (record) => {
    let changeContent = record;

    console.log("In Modal = ", changeContent);

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to Change Rate Code?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(changeContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setButtonLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    let mapData = {
      ...e,
      key_selected: selected?.key,
      parent_key: selected?.parent_key,
      room_category_id: selected?.room_category_id,
      main_segment_id: selected?.main_segment_id,
    };

    console.log("ON SUBMIT ==> ", mapData);

    masterSuccessNotification(mapData, {
      method: 1,
      source: "Rate Code",
    });

    getChange(mapData);
    handleCancel();
    // console.log("POST OR PUT ==> ", _obj);
  };

  // Fetch Data
  const fetchData = async () => {
    // if (optionValue?.length > 0) {
    await getMasterRateSegment({
      segment_compl: "master-segment",
      segment: selected?.main_segment_id,
      // sub_segment_id: segmentID?.sub_id > 0 ? segmentID?.sub_id : segmentId?.sub_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Rate Code => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
        setRows(null);
        // resetSearch(false);
      },
      onReject: (error) => {
        console.log("Error = ", error);

        setLoading(false);
        failedFetch(error);
        // resetSearch(false);
      },
    });
    // } else {
    //   setData([]);
    //   setBulks([]);
    // }
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // Handle Apply All
  const handleNight = (val) => {
    const _check = val.target.checked;

    setDisableNight(_check);
  };

  // Handle Night
  const handleApply = (val) => {
    const _check = val.target.checked;

    setDisable(_check);
  };

  // Handle Change Rate Code
  const handleChange = (value, alls) => {
    const allKeys = alls;
    console.log("Change Rate Code: ", value, allKeys);
    console.log("Data about to change: ", data);

    if (data.length > 0 && allKeys) {
      let filter = data.filter((x) => x.id == allKeys.id);

      console.log("filter handleChange", filter);

      form.setFieldsValue({
        id: filter[0]?.id,
        nett: filter[0]?.nett,
        service: filter[0]?.service,
        tax: filter[0]?.tax,
        gross: filter[0]?.gross,
        room_occupancy: filter[0]?.room_occupancy,
        room_breakfast: filter[0]?.room_breakfast,
        description: filter[0]?.description,
        rate_code: value,
      });
    } else {
      console.log("filter handleChange NONE");
      form.resetFields();
    }
  };

  return (
    <>
      <Modal
        className="add-edit-rate-code-modal"
        title={
          <Row>
            <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography
              style={{ marginLeft: 15 }}
            >{`Change Rate Code`}</Typography>
          </Row>
        }
        centered
        open={modalOpen}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          // name="add-edit-rate-code-modal-form"
          className="add-edit-rate-code-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            apply_all_category: false,
            apply_all_night: false,
          }}
          style={{ padding: "15px 36px 0px" }}
        >
          {/* <Row gutter={30} className="row-rate-code-id">
            <Col span={16}>
              <Form.Item
                label="Main Segment"
                name="main_segment_name"
                style={{
                  minWidth: "100%",
                }}
              >
                <Input placeholder="Main Segment Name" disabled />
              </Form.Item>

              <Form.Item
                label="Main Segment ID"
                name="main_segment_id"
                hidden={true}
              >
                <Input placeholder="Main Segment ID" disabled />
              </Form.Item>

              <Form.Item
                label="Main Segment Code"
                name="main_segment_code"
                hidden={true}
              >
                <Input placeholder="Main Segment Code" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Compliment Type"
                name="compliment_type"
                style={{
                  minWidth: "100%",
                }}
              >
                <Input placeholder="Compliment Type" disabled />
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={30} className="row-rate-code-id">
            <Col span={16}>
              <Form.Item
                label="Room Category"
                name="room_category"
                style={{
                  minWidth: "100%",
                }}
              >
                <Input placeholder="Room Category" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: 0, background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-rate-code-id">
            <Col className="rate-code-col" span={16}>
              <Form.Item
                label="Rate Code"
                name="rate_code"
                rules={[
                  {
                    required: true,
                    message: "Please, Input the Rate Code Name!",
                  },
                ]}
              >
                <MasterRateCode
                  getRateCode={handleChange}
                  segment_id={selected?.main_segment_id}
                  segment_name={selected?.main_segment_name}
                  roomCat={[selected]}
                  qty={1}
                  allSegment={
                    optionValue?.segment_id?.length > 0
                      ? optionValue.segment_id
                      : []
                  }
                  is_render={isRender}
                  is_rerender={setIsRender}
                  is_company={optionValue?.rate_id || []}
                  is_change={true}
                  // is_permitted={permitted}
                  // getOptions={getOptions}
                />
              </Form.Item>

              <Form.Item name="id" label="Rate Code ID" hidden={true}>
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="price-row">
            <Col className="nett-col" span={8}>
              <Form.Item
                label="Nett Price"
                name="nett"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Nett Price!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Nett Price"
                  name="nett"
                  addonBefore={currency}
                  disabled
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col className="service-col" span={8}>
              <Form.Item
                label="Service"
                name="service"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input the Percentages of Service!",
                //   },
                // ]}
              >
                <InputNumber
                  placeholder="Service"
                  min={0}
                  addonBefore={currency}
                  disabled
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col className="tax-col" span={8}>
              <Form.Item
                label="Tax"
                name="tax"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input the Percentages of Tax!",
                //   },
                // ]}
              >
                <InputNumber
                  min={0}
                  addonBefore={currency}
                  placeholder="Tax"
                  disabled
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="occ-row">
            <Col className="gross-col" span={8}>
              <Form.Item
                label="Gross"
                name="gross"
                rules={[
                  {
                    required: true,
                    message:
                      "Please, Input the Nett Price to Show the Gross Price!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Gross"
                  addonBefore={currency}
                  min={0}
                  disabled
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col className="occupants-col" span={8}>
              <Form.Item
                label="Occupants"
                name="room_occupancy"
                rules={[
                  {
                    required: true,
                    message: "Please, Input maximum Room Occupants!",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  placeholder="Room Occupancies"
                  style={{ width: "100%" }}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col className="bf-col" span={8}>
              <Form.Item
                label="Total Breakfast"
                name="room_breakfast"
                rules={[
                  {
                    required: true,
                    message: "Please, Input the Amounts of Breakfast!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Breakfast Counts"
                  min={0}
                  style={{ width: "100%" }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="descriptions-row" gutter={30}>
            <Col className="desc-col" span={16}>
              <Form.Item label="Descriptions" name="description">
                <Input.TextArea
                  placeholder="Rate Code Descriptions"
                  showCount
                  rows={3}
                  style={{ width: "100%", height: 100 }}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col className="desc-col" span={8}>
              <Form.Item
                name="apply_all_category"
                valuePropName="checked"
                style={{
                  marginTop: "25px",
                }}
              >
                <Checkbox onChange={handleApply} disabled={disableNight}>
                  {`Apply to Selected Room Category`}
                </Checkbox>
              </Form.Item>

              <Form.Item name="apply_all_night" valuePropName="checked">
                <Checkbox onChange={handleNight} disabled={disable}>
                  {`Apply to All Night`}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              disabled={buttonLoading}
              loading={buttonLoading}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

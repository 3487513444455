// Import React Components
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Import Ant Design Components
import { Progress } from "antd";

// CODE
export default function ProgressLoading() {
  // STATE MANAGEMENT
  // State
  const [percentage, setPercentage] = useState(0);
  // Routes
  const [pathLoc, setPathLoc] = useState("");
  // Show
  const [show, setShow] = useState(true);

  // Listening Routes Changes
  const location = useLocation();

  // USE EFFECTS
  useEffect(() => {
    // let cleanUp = false;

    if (percentage <= 100) {
      // console.log("Processing");

      const currentTimer = setInterval(() => {
        // console.log("Naik --> ", percent);
        setPercentage((prev) => prev + 10);
      }, 250);

      // console.log(interval);
      if (percentage == 100) {
        clearInterval(currentTimer);

        setTimeout(() => {
          setShow(false);
        }, 1000);
      }

      // console.log(percent);
      return () => clearInterval(currentTimer);
    }
  }, [percentage]);

  useEffect(() => {
    let cleanUp = false;

    if (pathLoc != location?.pathname) {
      if (percentage == 100) {
        setPathLoc(location?.pathname);

        setPercentage(0);
        setShow(true);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [location, percentage]);

  // Console Log
  // console.log("Loading location => ", location);

  return (
    <>
      {show == true ? (
        <Progress
          className="loading-progress"
          trailColor="grey"
          percent={percentage}
          showInfo={false}
          strokeLinecap="square"
          strokeWidth={4}
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#00C4F2",
          }}
        />
      ) : null}
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import axios from "axios";

// API
import { baseurl } from "../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React's Icons
import { MdOutlineChangeCircle } from "react-icons/md";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Import Master Components
import MasterRoomNumberCkIn from "../../Reusable/Master/RoomNumber/RoomCheckIn";
import ChangeRoomForm from "../../Forms/ChangeRoom/Form";
import MasterRoomCat from "../../Reusable/Master/RoomCat/RoomCat";
import MasterRateAudit from "../../Reusable/Master/RateCode/RateAudit";
import MasterRoomNumber from "../../Reusable/Master/RoomNumber/RoomNumber";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { TaxesParameter } from "../../Reusable/Functions/Taxes/Taxes";
import { Pricing } from "../../Reusable/Functions/Pricing/Pricing";
import { Grossing } from "../../Reusable/Functions/GrossBreakdown/Grossing";
import ParametersBreakfast from "../../Reusable/Parameters/ParamsBF";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// Master Notifications
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ModalChangeRoom(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    // Data
    dataGuest,
    // Refresh
    is_refresh,
  } = props;

  // DATE
  const today = Date(Date.now());
  const currency = CurrencySymbols().code;
  const accounting = AccountingParams();
  const percentages = TaxesParameter();
  const breakfast = FrontOfficeParams().article_id;
  const param_breakfast = ParametersBreakfast().breakfast;

  // CONTEXT
  const { getMasterRoomCat, getMasterRatePrice } = useContext(MasterContext);
  const { getMasterBillTrx } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [dataPrint, setDataPrint] = useState(null);
  // Room Cat
  const [roomCat, setRoomCat] = useState(null);
  const [newRoom, setNewRoom] = useState(null);
  const [newPrice, setNewPrice] = useState(null);
  const [coa, setCoa] = useState(null);
  const [show, setShow] = useState(false);
  const [oldRate, setOldRate] = useState(null);
  const [sameRate, setSameRate] = useState(null);
  const [newRate, setNewRate] = useState({
    value: "",
    label: "",
    id: 0,
    main_segment_id: 0,
    main_segment_name: "",
    main_segment_code: "",
    room: "",
    nett: 0,
    service: 0,
    tax: 0,
    gross: 0,
    description: null,
  });
  // Modal
  const [open, setOpen] = useState(false);
  const [changeRoom, setChangeRoom] = useState(false);
  const [select, setSelect] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isSame, setIsSame] = useState({
    same_rate: false,
    new_value: false,
  });
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isRender, setIsRender] = useState(false);

  // USEFORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    if (dataGuest) {
      console.log("DATA GUEST = ", dataGuest);

      // fetchDataCat();
      fetchListRoom();
      setData(dataGuest);

      setFieldForms(dataGuest);
      setIsRender(true);
    }

    setOpen(openModal);
  }, [openModal, dataGuest]);
  // Get Rate
  // useEffect(() => {
  //   if (roomCat && data) {
  //     fetchRate();
  //   }
  // }, [roomCat, data]);

  // On Open
  useEffect(() => {
    let cleanUp = false;

    if (data && roomCat && !cleanUp) {
      fetchRate();
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // COLUMNS
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: `Room Sales ( ${currency} )`,
      dataIndex: "gross",
      key: "gross",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Fetch Data
  const fetchDataCat = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        let filtered = response?.data.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            return filt;
          }
        });

        let convert = filtered.filter((dev) => {
          if (dev?.room_category_name == dataGuest.room_category) {
            return dev;
          }
        });

        let optionConvert = convert.map((dev) => ({
          value: dev?.room_category_name,
          label: dev?.room_category_name,
          code: dev?.room_category_code,
        }));

        console.log(optionConvert[0]?.code);
        setRoomCat(optionConvert[0]?.code);
        // console.log(option);
        // setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // FETCH RATE
  const fetchRate = async () => {
    console.log(data);
    console.log(roomCat);
    console.log(roomList);

    await axios
      .post(
        `${baseurl}/room/rate-code/category-res`,
        {
          room_category_id: roomCat,
        },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Rate Room Category >> ", response);

        if (response.data !== null) {
          setNewPrice(response.data[0].price);
        } else {
          setNewPrice(0);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, { method: 8, source: "Rate Code" });
      });

    // await getMasterRatePrice({
    //   rate: "rate-code",
    //   category: "category-res",
    //   // rate_code: data?.rate_code ? data.rate_code : "",
    //   room_cat_code: roomCat ? roomCat : 0,
    //   // segment: data?.,
    //   // sub_segment: ,
    //   onAwait: () => {
    //     "on Await";
    //   },
    //   onSuccess: (response) => {
    //     console.log("Rate Room Category >> ", response);

    //     if (response.data !== null) {
    //       setNewPrice(response.data[0].price);
    //     } else {
    //       setNewPrice(0);
    //     }
    //   },
    //   onReject: (error) => {
    //     console.log("ERROR >>>> ", error);
    //   },
    // });
  };

  // FETCH ROOM LIST
  const fetchListRoom = async () => {
    await getMasterBillTrx({
      bill: "bill",
      type: "article-master-bill-guest",
      res_id: dataGuest?.reservation_id
        ? dataGuest.reservation_id
        : data?.reservation_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Master => ", response);

        // let arr = [];
        if (response?.data?.length > 0) {
          const _res = response.data;

          handleFilter(_res);
        } else {
          setRoomList([]);
        }
        // arr.push(newRoom);
        //   console.log(newRoom);
        //   setArticleRoom(newRoom);
        //   console.log("TESTTTT >>>", response);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        setLoading(false);
      },
    });

    // await getArticleRoomGroup({
    //   article: "reservation",
    //   type: "article-room-group",
    //   reservation_id: dataGuest?.reservation_id
    //     ? dataGuest.reservation_id
    //     : data?.reservation_id,
    //   onAwait: () => {
    //     setLoading(true);
    //   },
    //   onSuccess: (response) => {
    //     console.log("Response Bill Room => ", response);

    //     if (response?.data?.msg?.length > 0) {
    //       const _res = response.data.msg;

    //       handleGetRoom(_res);
    //     } else {
    //       setRoomList([]);
    //     }
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //   },
    // });

    // await axios
    //   .post(
    //     `${baseurl}/reservation/article-room-single`,
    //     {
    //       res_id: data?.reservation_id
    //         ? data?.reservation_id
    //         : dataGuest?.reservation_id,
    //       prc_id: data?.price_id ? data.price_id : dataGuest.price_id,
    //     },
    //     { headers: { Authorization: `Bearer ${token}` } }
    //   )
    //   .then((response) => {
    //     console.log("DATA ROOM RATE = ", response);
    //     // if (state.type == 0) {
    //     //   setRoomList([response?.data[0]]);
    //     // } else {
    //     //   // setRoomList([]);
    //     // }
    //   })
    //   .catch((error) => {
    //     console.log("ERORR = ", error);
    //   });
  };

  // Handle Filter
  const handleFilter = (values) => {
    const _response = values;

    let id = 1;
    let _newRoom = [];
    let _articleRoom = [];
    let _articles = [];

    // for (let i = 0; i < state?.length; i++) {
    console.log("Loop ", _response);

    const _filtered = _response[0].get_article_room_master_bill_guest;
    const _filtered_article =
      _response[0]?.get_article_stay_master_bill_guest?.length > 0
        ? _response[0].get_article_stay_master_bill_guest
        : [];

    const _filteredRoom = _filtered.filter((items) => {
      return items.price_id == dataGuest.price_id;
    });

    const _filteredArt = _filtered_article.filter((items) => {
      return items.price_id == dataGuest.price_id;
    });

    console.log("Room Filtered ", _filteredRoom);
    console.log("Articles Filtered ", _filteredArt);

    console.log("Articles: ", {
      room: _filteredRoom,
      article: _filteredArt,
    });

    setRoomList(_filteredRoom); //.sort((a, b) => a.date.localeCompare(b.date)));
  };

  // SET FIELDS FORMS
  const setFieldForms = (value) => {
    const _data = value;

    form.setFieldsValue({
      reservation_id: _data?.reservation_id
        ? _data.reservation_id
        : dataGuest?.reservation_id,
      guest_name:
        _data?.guest_category == "INCOGNITO" ? "PRIVATE" : _data?.guest_name,
      room_category: _data?.room_category
        ? _data.room_category
        : dataGuest?.room_category,
      room_category_id:
        _data?.room_category_id > 0
          ? _data.room_category_id
          : dataGuest?.room_category_id,
      // room_number: data?.room_number
      //   ? data.room_number
      //   : dataGuest.room_number,
    });

    setRoomCat(_data?.arrangement?.room_category_id);
  };

  // GET NEW ROOM
  const getNewRoom = (value, e, i) => {
    console.log("New Room: ", value, e, i);

    // const number = value[0];

    // setCoa(number.coa);
    // setNewRoom(e);

    // form.setFieldsValue({
    //   new_room_number: number.number,
    //   room_id: number.value,
    // });
  };

  // Get New Room Number
  const getRoomNumber = (value) => {
    const _data = value;
    console.log("This: ", _data);

    setNewRoom(_data);

    form.setFieldsValue({
      new_room_number: _data?.number || 0,
      room_id: _data?.id || 0,
      chart_of_account: _data?.coa,
    });
  };

  // Handle Rate Code is Same
  const handleSame = (val) => {
    const _checked = val.target.checked;

    console.log("IS SAME: ", _checked);
    console.log("Selected Room: ", selectedRoom);

    setIsSame({
      same_rate: _checked,
      new_room_price: isSame.new_value,
    });

    if (_checked == false) {
      setNewRate({
        main_segment_name: null,
        description: null,
      });
    }

    form.setFieldsValue({
      is_checked: _checked,
      new_room_price:
        selectedRoom?.gross > 0 && _checked == true ? selectedRoom.gross : 0,
      rate_code: null,
    });
  };

  // Handle Input Rate Code
  const handleInputRate = (val) => {
    const _checked = val.target.checked;

    console.log("IS Input: ", _checked);
    console.log("Accounting: ", accounting);

    setIsSame({
      new_value: _checked,
      same_rate: isSame.same_rate,
    });

    setNewRate({
      main_segment_name: null,
      description: null,
    });

    form.setFieldsValue({
      is_input: _checked,
      nett: 0,
      service: 0,
      tax: 0,
      rate_code: null,
      room_breakfast: 0,
      room_occupancy: 1,
      new_room_price: 0,
    });
  };

  // CODE
  const getNewRoomCode = (value, number) => {
    console.log(value, number);

    // setNewRoom(value);
    // form.setFieldsValue({});
  };
  // COA
  const getRoomCoa = (value, code) => {
    console.log(value, code);

    setCoa(value);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const contentPrint = value;

    const newData = {
      data_submit: { ...contentPrint },
      data_guest: data,
      room_list: { ...roomList },
      old_category: show ? data.room_category : null,
      new_rate: isSame.same_rate ? selectedRoom : newRate,
      gross: selectedRoom?.gross,
      old_rate: selectedRoom,
      same_rate: sameRate,
    };

    console.log("PRINT THIS >>> ", newData);
    console.log("ABOUT TO PRINT >>> ", contentPrint);
    console.log("Room list >>> ", roomList);

    confirm({
      className: "change-room-confirm",
      title: `Are you sure the Data is Correct for ${
        contentPrint?.guest_name.toUpperCase() || "UNKNOWN"
      } to Change Room?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        setTimeout(() => {
          setChangeRoom(true);
        }, 1000);

        if (isSame.new_value == true || isSame.same_rate == true) {
          handleCreateNewRate(newData);
        } else {
          setDataPrint(newData);
        }
        // handleCancel();
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Change Category
  const onChangeRoom = (val) => {
    console.log(val);

    form.setFieldsValue({
      room_category: val?.name,
      room_category_id: val?.cat_id,
      room_category_code: val?.code,
      new_room_number: null,
      room_id: null,
    });

    setRoomCat(val?.cat_id);
  };

  // GET ROOM ASSIGNED
  const handleGetRoom = (value) => {
    const _data = value;

    let _filtered = _data.filter((value) => {
      return value.price_id === dataGuest.price_id;
    });

    console.log("Filtered: ", _filtered);
    setRoomList(_filtered);
    setLoading(false);
  };

  // Rate Code
  const getRateCode = (val) => {
    console.log("New Rate: ", val);

    setNewRate(val);

    form.setFieldsValue({
      rate_code: val.value || "",
      new_room_price: val.gross || 0,
    });
  };

  // Get Old Rate
  const getOldRate = (val) => {
    console.log("Old Rate: ", val);

    setSameRate(val);
  };

  // Handle Change
  const handleChange = () => {
    setShow(!show);

    setIsSame({
      new_value: false,
      same_rate: false,
    });

    if (show == false) {
      form.setFieldsValue({
        room_category: data?.room_category,
        room_category_id: data?.room_category_id,
        new_room_price: 0,
      });
    }

    setRoomCat(dataGuest?.room_category_id);

    form.setFieldsValue({
      is_same: false,
      is_input: false,
    });
  };

  // Handle New Rate Nett
  const handleNett = (val) => {
    const _nett = val;
    let tax = percentages.tax_percentage;
    let service = percentages.service_percentage;

    let _newPrice = Pricing({
      nett: _nett || 0,
      tax: tax,
      service: service,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient_tax_service,
    });

    console.log("New Prices: ", _newPrice);

    form.setFieldsValue({
      nett: _nett,
      service: _newPrice.services,
      tax: _newPrice.taxes,
      new_room_price: _newPrice.gross,
    });
  };

  // Handle New Rate Gross
  const handleGross = (val) => {
    const _gross = val;
    let tax = percentages.tax_percentage;
    let service = percentages.service_percentage;

    let _newPrice = Grossing({
      gross: _gross || 0,
      tax: tax,
      service: service,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient_tax_service,
    });

    console.log("New Prices: ", _newPrice);

    form.setFieldsValue({
      nett: _newPrice.nett,
      service: _newPrice.services,
      tax: _newPrice.taxes,
      new_room_price: _gross,
    });
  };

  // Handle New Rate
  const handleNewRate = (val) => {};

  // ON FINISH
  const onFinish = (value) => {
    const content = value;
    setIsLoading(true);

    console.log("ON FINISH >>> ", content);

    showModalConfirm({
      ...content,
      new_room_number_code: newRoom,
      new_price: newPrice,
      // chart_of_account: coa,
    });
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;
    console.log("Values Failed: ", error?.values);

    masterIncomplete(error);
  };

  // Handle Selection Table
  const handleSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);

      setSelect(selectedRowKeys);
      setSelectedRoom(selectedRows?.length > 0 ? selectedRows[0] : null);
      setRoomCat(
        selectedRows?.length > 0 ? selectedRows[0].room_category_id : 0
      );

      form.setFieldsValue({
        room_number:
          selectedRows?.length > 0
            ? selectedRows[0].room_number
            : data.room_number,
        date: selectedRows?.length > 0 ? selectedRows[0].date : moment(),
        is_same: false,
        is_input: false,
      });
    },
    getCheckboxProps: (record) => ({
      disabled:
        moment(record.date).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
          ? false
          : true,
      // Column configuration not to be checked
      // name: record.name,
    }),
  };

  // Handle Create New Rate
  const handleCreateRate = () => {
    // const _validates = form.submit((e) => {
    //   return e;
    // });
    const _validates = form
      .validateFields()
      .catch((error) => {
        console.log("Errors: ", error);
      })
      .then((res) => {
        console.log("Resolves: ", res);
      });

    const _new = form.getFieldsValue([
      "rate_code",
      "new_room_price",
      "nett",
      "service",
      "tax",
      "room_occupancy",
      "room_breakfast",
    ]);
    // const _error = {
    //   guest_name: "Muhammad Sumbul",
    //   reservation_id: "RES240700211",
    //   room_category: "EXECUTIVE",
    //   room_category_id: 2,
    //   room_number: 313,
    //   new_room_number: 401,
    //   room_id: 72,
    //   chart_of_account: "400-01-010",
    //   reason: "tes",
    //   date: "2024-07-28T00:00:00",
    //   rate_code: "EXE-NEWCATEGORY-INP-0101",
    //   new_room_price: "2",
    //   is_same: false,
    //   is_input: true,
    //   nett: 2,
    //   service: "0",
    //   tax: "0",
    //   room_occupancy: 2,
    //   room_breakfast: 2,
    // };
    const _errors = form.getFieldsError();

    console.log("Validates: ", _validates);
    console.log("Get Form Fields: ", _new);
    // console.log("Error: ", _error);
    // console.log("Errors: ", _errors);
    // console.log("Errors Validate: ", _isValidate);

    // setButtonLoading(true);
    // showModalCreate(_new);
  };

  // POST NEW RATE CODE
  const handleCreateNewRate = async (val) => {
    const contentSubmit = val;

    console.log("ON SUBMIT ==> ", contentSubmit);
    console.log("Same Rate ==> ", sameRate);
    // console.log("POST OR PUT ==> ", _obj);

    let desc = `${
      contentSubmit?.data_submit?.room_category_name || "GROUP"
    } ROOM for ${
      isSame.new_value
        ? contentSubmit?.data_submit?.room_occupancy
        : sameRate?.room_occupancy
    } Adult(s) and ${
      isSame.new_value
        ? contentSubmit?.data_submit?.room_breakfast
        : sameRate?.room_breakfast
    } Pax Breakfast(s)`;

    // const data_new =

    await axios
      .post(
        `${baseurl}/room/rate-code`,
        {
          rate_code: contentSubmit?.data_submit?.rate_code || null,
          main_segment_id: sameRate?.main_segment_id || null,
          main_segment_code: sameRate?.main_segment_code || null,
          main_segment_name: sameRate?.main_segment_name || null,
          description: desc,
          room_category_code:
            contentSubmit?.data_submit?.room_category_code || null,
          room_category_name: contentSubmit?.data_submit?.room_category || null,
          room_category_id:
            contentSubmit?.data_submit?.room_category_id || null,
          room_breakfast:
            isSame.same_rate == true
              ? sameRate.room_breakfast
              : contentSubmit?.data_submit?.room_breakfast,
          room_occupancy:
            isSame.same_rate == true
              ? sameRate.room_occupancy
              : contentSubmit?.data_submit?.room_occupancy,
          nett:
            isSame.same_rate == true
              ? sameRate.nett
              : parseInt(contentSubmit?.data_submit.nett),
          service:
            isSame.same_rate == true
              ? sameRate.service
              : parseInt(contentSubmit.data_submit.service),
          tax:
            isSame.same_rate == true
              ? sameRate.tax
              : parseInt(contentSubmit.data_submit.tax),
          gross:
            isSame.same_rate == true
              ? sameRate.gross
              : contentSubmit?.data_submit?.new_room_price,
          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Add Rate Code => ", response);

        if (response.data?.msg) {
          const _new = response.data.msg;

          setButtonLoading(false);
          masterSuccessNotification(response, {
            method: 0,
            source: "Rate Code",
          });

          setNewRate(_new);
          // setIsSuccess(true);
          setDataPrint({
            ...contentSubmit,
            new_rate: _new,
          });
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: "Rate Code",
          });
        }
      })
      .catch((error) => {
        console.log("Error Rate Code => ", error);

        masterFailedNotification(error, { method: 0, source: "Rate Code" });
        setButtonLoading(false);
      })
      .finally(() => {
        setButtonLoading(false);
      });

    // console.log("DATA NEW RATE: ", data_new);
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    setShow(false);

    setIsLoading(false);
    setButtonLoading(false);
    setData([]);
    setRoomCat(null);
    setNewRoom(null);
    setDataPrint(null);
    setSelect([]);
    setSelectedRoom(null);
    setOldRate(null);
    setNewRate({
      value: "",
      label: "",
      id: 0,
      main_segment_id: 0,
      main_segment_name: "",
      main_segment_code: "",
      room: "",
      nett: 0,
      service: 0,
      tax: 0,
      gross: 0,
      description: null,
    });
    setIsSuccess(false);
    setIsRender(false);

    setIsSame({
      new_value: false,
      same_rate: false,
    });

    setSameRate(null);

    setOpen(false);
    closeModal(false);
  };

  // Handle Close
  const handleClose = (val) => {
    console.log("handle close: ");

    setChangeRoom(false);
    setIsLoading(false);
  };

  return (
    <div aria-modal="true">
      <Modal
        className="change-room-modal"
        title={
          <>
            <Row>
              <MdOutlineChangeCircle
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography style={{ marginLeft: 15 }}>
                {`Change Room Form`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          className="change-room-form"
          name="change-room-form"
          key="change-room-form"
          autoComplete="false"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          labelAlign="left"
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-header">
            <Col span={8} className="col-guest-name">
              <Form.Item
                label="Guest Name"
                name="guest_name"
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <Input placeholder="Guest's Name" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-res-id">
              <Form.Item
                label="Reservation ID"
                name="reservation_id"
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <Input placeholder="Reservation ID" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-cat">
              <Form.Item
                label="Room Category"
                name="room_category"
                rules={[
                  {
                    required: show,
                    message: "Please, Choose the new Room Category to Proceed!",
                  },
                ]}
                style={{
                  width: "100%",
                }}
              >
                <MasterRoomCat
                  onChangeRoomCat={onChangeRoom}
                  cat={data?.room_category}
                  disable={!show}
                  is_fetch={true}
                />
              </Form.Item>

              <Form.Item
                label="Room Category ID"
                name="room_category_id"
                hidden
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                label="Room Category Code"
                name="room_category_code"
                hidden
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider className="divider-form" style={{ margin: "10px 0px" }} />

          <Row gutter={30} className="row-new-room">
            <Col span={8} className="col-current-room">
              <Form.Item
                label="Current Room"
                name="room_number"
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <Input placeholder="Current Room" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-current-room">
              <Form.Item
                label="New Room"
                name="new_room_number"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose the new Room to Proceed!",
                  },
                ]}
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <MasterRoomNumberCkIn
                  roomInfo={getNewRoom}
                  getRoomNumber={getRoomNumber}
                  isGroup={false}
                  getRoomCoa={getRoomCoa}
                  roomCode={roomCat ? roomCat : 0}
                  disable={select?.length > 0 ? false : true}
                />
              </Form.Item>

              <Form.Item label="Room ID" name="room_id" hidden>
                <Input placeholder="Room ID" />
              </Form.Item>

              <Form.Item
                label="Chart of Accounts"
                name="chart_of_account"
                hidden
              >
                <Input placeholder="COA" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Change Room Category">
                <Button
                  type="primary"
                  className="submit-btn"
                  onClick={handleChange}
                  disabled={select.length > 0 ? false : true}
                >
                  {show ? `Cancel` : `Change Room Category?`}
                </Button>
              </Form.Item>
            </Col>
          </Row>

          {show ? (
            <>
              <Divider
                className="divider-form"
                style={{ margin: "10px 0px" }}
              />

              <Row gutter={30} className="row-new-rate">
                <Col span={8} className="col-current-room">
                  <Form.Item
                    label="Change Rate"
                    name="rate_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select/Type a New Rate!",
                      },
                    ]}
                  >
                    {isSame.same_rate === true || isSame.new_value === true ? (
                      <Input
                        placeholder="Input New Rate Code Name!"
                        onChange={handleNewRate}
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      <MasterRateAudit
                        getRateCode={getRateCode}
                        getOldRate={getOldRate}
                        is_abf={open ? data?.is_abf : false}
                        old_rate={open ? selectedRoom?.room_rate_id : []}
                        segment_id={open ? selectedRoom?.main_segment_id : 0}
                        room_cat={open ? roomCat : 0}
                        is_permitted={show}
                        is_render={isRender}
                        is_rerender={setIsRender}
                        is_find={false}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="New Rate/Price"
                    name="new_room_price"
                    rules={[
                      {
                        required: isSame.new_value ? true : false,
                        message: "Please, Rate cannot be Empty!",
                        // validateTrigger:
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="New Rate/Price"
                      disabled={!isSame.new_value}
                      addonBefore={currency}
                      onChange={handleGross}
                      formatter={(prc) =>
                        `${prc > 0 ? prc : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label=""
                    name="is_same"
                    valuePropName="checked"
                    style={{
                      margin: "15px 0px ",
                    }}
                  >
                    <Checkbox onChange={handleSame} disabled={isSame.new_value}>
                      {`Same Rate`}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    label=""
                    name="is_input"
                    valuePropName="checked"
                    style={{
                      margin: "15px 0px ",
                    }}
                  >
                    <Checkbox
                      onChange={handleInputRate}
                      disabled={isSame.same_rate}
                    >
                      {`Input Rate`}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              {isSame.new_value ? (
                <>
                  <Row gutter={30} className="row-new-rate">
                    <Col span={8} className="col-current-room">
                      <Form.Item
                        label="Nett price"
                        name="nett"
                        rules={[
                          {
                            required: isSame.new_value === true ? true : false,
                            message: "Please, Input New Rate!",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Nett Price"
                          min={0}
                          addonBefore={currency}
                          onChange={handleNett}
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-current-room">
                      <Form.Item label="Service " name="service">
                        <InputNumber
                          placeholder="Service Price"
                          min={0}
                          disabled
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          addonBefore={currency}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-current-room">
                      <Form.Item label="Tax " name="tax">
                        <InputNumber
                          placeholder="Tax Price"
                          min={0}
                          addonBefore={currency}
                          disabled
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={30} className="row-new-rate">
                    <Col span={8} className="col-current-room">
                      <Form.Item
                        label="Occupants"
                        name="room_occupancy"
                        rules={[
                          {
                            required: isSame.new_value === true ? true : false,
                            message: "Please, Input New Rate!",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Room Occupants"
                          min={1}
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-current-room">
                      <Form.Item
                        label="Total Breakfast "
                        name="room_breakfast"
                        rules={[
                          {
                            required: isSame.new_value === true ? true : false,
                            message: "Please, Input New Rate!",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Breakfast Count"
                          min={0}
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : null}

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row className="rate-desc" style={{ width: "100%" }}>
                <Alert
                  className="desc-alert"
                  type="info"
                  closable={false}
                  showIcon
                  message={"New Segments & Rate Code Descriptions:"}
                  description={
                    <Typography
                      className="desc-text"
                      style={{ fontWeight: 500 }}
                    >
                      {`SEGMENTS: ${
                        newRate.main_segment_name
                          ? newRate.main_segment_name.toUpperCase()
                          : ""
                      } --- ${newRate.description || ""}`}
                    </Typography>
                  }
                  style={{ width: "100%" }}
                />
              </Row>
            </>
          ) : null}

          <Divider
            className="form-divider"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row className="row-reason">
            <Form.Item
              label="Reason"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason of Change Room Cannot be Empty!",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input.TextArea
                className="input-reason"
                maxLength={200}
                showCount
                placeholder="Reason of Change Room"
                style={{
                  minWidth: 440,
                  minHeight: "100%",
                }}
              />
            </Form.Item>
          </Row>

          <Row className="row-reason">
            <Form.Item label="Date" name="date" hidden>
              <Input
                className="date"
                // placeholder="Start "
              />
            </Form.Item>
          </Row>

          <Divider className="divider-form" style={{ margin: "5px 0px" }} />

          <Table
            title={() => "Room List: Choose Date"}
            className="change-room-table"
            key="change-room-table"
            name="change-room-table"
            columns={columns}
            dataSource={roomList}
            loading={loading}
            bordered
            size="small"
            rowSelection={{
              type: "radio",
              ...handleSelection,
            }}
            pagination={{
              defaultPageSize: 5,
              showTotal: (total, range) =>
                `Showing ${range[0]}- ${range[1]} of ${total} entries`,
            }}
            rowKey={(record) => {
              return record.id ? record.id : record.key;
            }}
            rowClassName={(record, index) => {
              return index % 2 == 0 ? "odd" : "even";
            }}
          />

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px 10px" }}
          />

          <Row
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 0px" }}
            className="modal-btn-form"
          >
            <Button
              className="submit-btn"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              // disabled={isSame.new_value || isSame.same_rate}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>

      <ChangeRoomForm
        openModal={changeRoom}
        closeModal={handleClose}
        dataGuest={changeRoom == true ? dataPrint : null}
        is_new_category={show}
        is_refresh={is_refresh}
        is_close={handleCancel}
      />
    </div>
  );
}

// Import Page Components
import React from "react";
import { useNavigate } from "react-router-dom";

// Import React Components
import { Button, Result, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// Global Styles
import { mainBody } from "../../Style/Global/Global";

// CODE
export default function InternalError() {
  // USE NAVIGATE
  const navigate = useNavigate();

  // MOVE HOME
  const move = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <Result
        className="internal-error-results"
        status="500"
        title={
          <>
            <Typography
              className="text"
              style={{
                color: "#f5222d",
                fontSize: 25,
                fontWeight: 750,
              }}
            >
              500! INTERNAL SERVER ERROR!
            </Typography>
          </>
        }
        subTitle="Sorry, something went wrong! Please, Check your connection and try again later!"
        extra={
          <Button
            className="reload-btn"
            type="primary"
            icon={<SyncOutlined />}
            onClick={() => {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }}
          >
            Reload
          </Button>
        }
        style={mainBody}
      />
    </>
  );
}

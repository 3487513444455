// Import React Components
import React, { useEffect, useState } from "react";

// Import ANTD Components
import { Select } from "antd";

// Value
const options = [
  {
    label: "Proposed",
    value: 0,
    is_reject: false,
  },
  {
    label: "Approve",
    value: 1,
    is_reject: true,
  },
  {
    label: "Reject",
    value: 2,
    is_reject: true,
  },
  {
    label: "Subject to Revise",
    value: 3,
    is_reject: true,
  },
  {
    label: "Inactive",
    value: 4,
    is_reject: false,
  },
  {
    label: "Active",
    value: 5,
    is_reject: false,
  },
];

// CODE
export default function MasterStatusBudget(props) {
  // PROPS
  const { getStatus, status, is_approve, is_disable } = props;

  // STATE MANAGEMENT
  // Data
  const [statusBudget, setStatusBudget] = useState(null);
  const [optionValue, setOptionsValue] = useState(null);

  useEffect(() => {
    handleFilter();
  }, [is_approve]);

  useEffect(() => {
    let cleanUp = false;

    if (status && !cleanUp) {
      setStatusBudget(status);
    } else {
      setStatusBudget(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [status]);

  // Handle Change
  const handleChange = (labels, allKeys) => {
    const _name = labels;
    const _allkey = allKeys;

    console.log(_name, _allkey);

    setStatusBudget(_allkey?.label);
    getStatus({
      label: _allkey?.label,
      value: _allkey?.value,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setStatusBudget(null);
  };

  // Handle Filter
  const handleFilter = () => {
    const _filter = options.filter((items) => {
      if (is_approve) {
        return items.is_reject;
      } else {
        return !items.is_reject;
      }
    });

    setOptionsValue(_filter);
  };

  return (
    <>
      <Select
        className="masters-budget-status"
        key="masters-budget-status"
        placeholder="Select an Options!"
        allowClear
        showSearch
        disabled={is_disable}
        value={statusBudget}
        onClear={handleClear}
        onChange={handleChange}
        options={optionValue}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

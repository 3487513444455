// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// CODE
export default function ParametersBreakfast(props) {
  // CONTEXT
  const { getParameters, getArticleList } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [breakfast, setBreakfast] = useState(null);
  //   var date = "";

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "fo",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: async (response) => {
        // console.log("Date Audit --> ", response);
        // setLoading(false);
        if (response?.data?.msg[0].article_id) {
          handleBreakfast(response?.data?.msg[0].article_id);
        }
        // date = response?.data?.msg[0]?.audit_date;
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  const handleBreakfast = async (id) => {
    const _id = id;
    // console.log("ID ARTICLES: ", _id);

    await getArticleList({
      list: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response => ", response);

        let filterData = response?.data?.msg.filter((val) => {
          return val.id == _id;
        });

        setBreakfast(filterData[0]);
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USER ROLES
  useEffect(() => {
    fetchData();
  }, []);

  return { breakfast };
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Modal,
  Typography,
  Select,
  Input,
  Tooltip,
  Tag,
  DatePicker,
  Popover,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons/lib/icons";

// Import Master Notifications
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import MasterRoomNumber from "../../../Reusable/Master/RoomNumber/RoomNumber";

// Import Global Styles
import {
  cardBody,
  cardHeader,
  mainBody,
} from "../../../../Style/Global/Global";
import { FiEdit } from "react-icons/fi";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function LostFoundTable(props) {
  // PROPERTIES
  const {
    // Search
    is_search,
    itemKey,
    roomKey,
    dateKey,
    // Refresh State
    is_refresh,
  } = props;

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      // fixed: "left",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-lost-found-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12} className="col-edit-btn">
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      setOpen(true);
                      setEdit(true);
                      setEditData(record);

                      handleFormFields(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-del-btn">
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Lost / Found",
      dataIndex: "status_type",
      key: "status_type",
      // fixed: "left",
      width: 250,
      sortDirections: ["descend", "ascend"],
      filters: [
        {
          text: "Lost",
          value: false,
        },
        {
          text: "Found",
          value: true,
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.status_type === value;
      },
      render: (stat, record) => {
        let status = "";
        let clr = "";

        switch (stat) {
          case false:
            status = "Lost";
            clr = "red";
            break;

          case true:
            status = "Found";
            clr = "green";
            break;

          case "":
            status = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            status = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Room No",
      dataIndex: "room_no",
      key: "room_no",
      // fixed: "left",
      width: 250,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_no.localeCompare(b.room_no),
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
      key: "item_description",
      // width: 300,
      ellipsis: true,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.item_description.localeCompare(b.item_description),
      render: (item) => {
        return (
          <Popover trigger="hover" content={<Tag color="default">{item}</Tag>}>
            <Typography.Paragraph
              ellipsis={true}
              style={{
                fontSize: 18,
                fontWeight: 400,
                textOverflow: "ellipsis",
                overflow: "hidden",
                margin: 0,
              }}
            >
              {item}
            </Typography.Paragraph>
          </Popover>
        );
      },
    },
    {
      title: "Reported Date",
      dataIndex: "reported_date",
      key: "reported_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reported_date.localeCompare(b.reported_date),
      render: (time, record) => {
        return moment(time, moment.ISO_8601)
          .add(7, "hours")
          .format("MMMM Do YYYY, HH:mm");
      },
    },

    {
      title: "Expired Date",
      dataIndex: "expired_date",
      key: "expired_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.expired_date.localeCompare(b.expired_date),
      render: (_, record) => {
        return moment(record.expired_date, moment.ISO_8601).format(
          "MMMM Do YYYY, HH:mm"
        );
      },
    },
  ];

  // CONTEXT
  const { state, getLostFound } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [filteredList, setFilteredList] = useState(data);
  // Edit
  const [edit, setEdit] = useState();
  const [editData, setEditData] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusType, setStatusType] = useState();

  // Modal
  const [open, setOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getLostFound({
      lostFound: "housekeeping",
      type: "lost-found",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        let filtered = response.data.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            return filt;
          }
        });

        // console.log(filtered[0].reported_date);
        setData(filtered);
        setFilteredList(filtered);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // fetchRes();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [itemKey, roomKey]);

  // Use Effect Date Picker
  useEffect(() => {
    let cleanUp = false;

    if (dateKey && !cleanUp) {
      handleDateFilter(dateKey);
    }

    return () => {
      cleanUp = true;
    };
  }, [dateKey]);

  // Handle Form Set Fields
  const handleFormFields = (value) => {
    const _record = value;

    form.setFieldsValue({
      status_type: _record?.status_type,
      room_no: _record?.room_no,
      item_description: _record?.item_description,
      expired_date: _record?.expired_date,
      remark: _record?.remark,
      reported_by: _record?.reported_by,
      reported_date: _record?.reported_date,
      phone_no: _record?.phone_no,
      reference_no: _record?.reference_no,
      found_by: _record?.found_by,
      location: _record?.location,
      submitted_to: _record?.submitted_to,

      claimed_by: _record?.claimed_by,
      claimed_date: moment(_record?.claimed_date),

      created_by: _record?.created_by,
      updated_by: _record?.updated_by,
      updated_date: _record?.updated_date,
    });
  };

  // HANDLE SELECTOR
  // Get Room Number
  const getRoomNumber = (value) => {
    const _number = value?.number?.length > 0 ? value.number[0] : value?.number;
    console.log("Room Numbers: ", value);

    form.setFieldsValue({
      room_no: _number,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    setIsLoading(true);
    console.log(content);

    showSubmitModal(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);

    masterIncomplete(e);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setEditData([]);

    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL SUBMIT
  const showSubmitModal = (e) => {
    const content = e;

    confirm({
      className: "submit-confirm",
      title:
        edit == true
          ? `Are you sure want to Update ${
              content.status_type == false ? "LOST" : "FOUND"
            } data of items named ${
              content?.item_description.toUpperCase() || "Unknown"
            }?`
          : `Are you sure want to submit data ${
              content.status_type == false ? "LOST" : "FOUND"
            } of items named ${
              content?.item_description.toUpperCase() || "Unknown"
            } into the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (e) => {
    const content = e;

    confirm({
      className: "delete-confirm",
      title: `Are you sure want to delete data ${
        content.status_type == false ? "LOST" : "FOUND"
      } of items named ${
        content?.item_description.toUpperCase() || "Unknown"
      }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(content);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    console.log("SUBMITTED ==> ", value);
    const submit = value;

    if (edit == true) {
      await axios
        .put(
          `${baseurl}/housekeeping/lost-found`,
          {
            id: editData.id ? editData.id : "",
            room_no: submit.room_no ? submit.room_no : editData.room_no,
            item_description: submit.item_description
              ? submit.item_description
              : editData.item_description,
            expired_date: submit.expired_date
              ? moment(submit.expired_date._d).format()
              : editData.expired_date,
            remark: submit.remark ? submit.remark : editData.remark,
            reported_by: submit.reported_by
              ? submit.reported_by
              : editData.reported_by,
            phone_no: submit.phone_no ? submit.phone_no : editData.phone_no,
            reference_no: submit.reference_no
              ? submit.reference_no
              : editData.reference_no,
            found_by: submit.found_by ? submit.found_by : editData.found_by,
            location: submit.location ? submit.location : editData.location,
            submitted_to: submit.submitted_to
              ? submit.submitted_to
              : editData.submitted_to,
            claimed_by: submit.claimed_by
              ? submit.claimed_by
              : editData.claimed_by,
            claimed_date: submit.claimed_date
              ? moment(submit.claimed_date._d).format()
              : editData.claimed_date,

            updated_by: roles,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("Update => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Lost & Found",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("Update => ", error);

          masterFailedNotification(error, {
            method: 1,
            source: "Lost & Found",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .post(
          `${baseurl}/housekeeping/lost-found`,
          {
            status_type: submit.status_type ? submit.status_type : false,
            room_no: submit.room_no ? submit.room_no : "",
            item_description: submit.item_description
              ? submit.item_description
              : "",
            expired_date: moment().add(30, "days").format(),
            remark: submit.remark ? submit.remark : "",
            phone_no: submit.phone_no ? submit.phone_no : "",
            reference_no: submit.reference_no ? submit.reference_no : "",
            found_by: submit.found_by ? submit.found_by : "",
            location: submit.location ? submit.location : "",
            submitted_to: submit.submitted_to ? submit.submitted_to : "",
            reported_by: submit.reported_by ? submit.reported_by : "",

            claimed_by: submit.claimed_by ? submit.claimed_by : "",
            claimed_date: submit.claimed_date
              ? moment(submit.claimed_date._d).format()
              : null,

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("Post => ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Lost & Found",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("Post => ", error);

          masterFailedNotification(error, {
            method: 0,
            source: "Lost & Found",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log(contentdelete);

    let obj = {
      ["id"]: contentdelete.id,
      ["deleted_by"]: roles,
    };

    await axios
      .delete(`${baseurl}/housekeeping/lost-found`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Delete => ", response);

        masterSuccessNotification(response, {
          method: 2,
          source: "Lost & Found",
        });
        fetchData();
      })
      .catch((error) => {
        console.log("Delete => ", error);
        masterFailedNotification(error, { method: 2, source: "Lost & Found" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle Filter Date
  const handleDateFilter = (value) => {
    const _datequery = value ? moment(value) : null;

    if (filteredList.length > 0) {
      let _dateList = filteredList.filter((value) => {
        const _dataReport = moment(value?.reported_date);

        console.log("Date is same or not: ", _datequery, _dataReport);

        return moment(_dataReport).isSame(_datequery);
      });

      console.log("Filter Date: ", _dateList);
      setData(_dateList);
    } else {
      setData([]);
    }
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryItem = itemKey ? itemKey.toLowerCase() : null;
    const queryRoom = roomKey ? roomKey : null;
    // console.log("Type = ", queryDate);

    if (data.length > 0) {
      let updatedList = data.filter((e) => {
        const _name = e?.item_description.toLowerCase();

        return _name.indexOf(queryItem) !== -1;
      });

      console.log("Update List: ", updatedList);

      if (queryRoom) {
        handleSearchRoom(
          updatedList.length > 0 ? updatedList : data,
          queryRoom
        );
      } else {
        setData(updatedList);
      }
    } else {
      setData([]);
    }
  };

  // Search Room
  const handleSearchRoom = (value, room) => {
    const _arr = value;

    let _filter = _arr.filter((value) => {
      const _room = value?.room_no.toLowerCase();

      return _room.indexOf(room) !== -1;
    });

    console.log("Search Room: ", _filter);
    setData(_filter);
  };

  // console.log(data);

  return (
    <>
      <Row className="lostfound-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            justify="end"
            className="row-modal-btn"
            gutter={[0, 30]}
            align="top"
          >
            <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
            >
              {`Add Data`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={() => {
                fetchData();
                // is_refresh(true);
              }}
              style={{
                margin: "0px 0px 0px 15px",
              }}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="lost-found-table"
              name="lost-found-table"
              key="lost-found-table"
              bordered
              loading={loading}
              columns={columns}
              size="middle"
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="lost-found-modal"
        title={
          edit == true ? (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Lost & Found`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Lost & Found`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="lost-found-modal-form"
          name="lost-found-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-status-type">
            <Col span={16} className="col-status">
              <Form.Item
                label="Status Type"
                name="status_type"
                rules={[
                  {
                    required: true,
                    message: "Please, select status type!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Select
                  // defaultValue={false}
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: false,
                      label: "Lost",
                    },
                    {
                      value: true,
                      label: "Found",
                    },
                  ]}
                  onChange={(e) => {
                    setStatusType(e);
                  }}
                  disabled={edit == true ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-no">
              <Form.Item
                label="Room No."
                name="room_no"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, input room number!",
                //   },
                // ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <MasterRoomNumber
                  getRoomNumber={getRoomNumber}
                  room_number={edit == true ? editData.room_no : 0}
                  isGroup={false}
                  is_disable={false}
                  rateRoomCatg={true}
                  // rateRoomCatg={rateRoom ? rateRoom[0].room_category_code : ""}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-item-desc">
            <Col span={24} className="col-item-desc">
              <Form.Item
                label="Item Description"
                name="item_description"
                rules={[
                  {
                    required: true,
                    message: "Please, input item!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input.TextArea placeholder="Item Description" rows={5} />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              {editData.status_type != true ? (
                <>
                  <Row gutter={30} className="row-edit-lost">
                    <Col span={16} className="col-remarks">
                      <Form.Item
                        label="Remarks"
                        name="remark"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <Input.TextArea placeholder="Remark" rows={6} />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-report">
                      <Row>
                        <Form.Item
                          label="Reported By"
                          name="reported_by"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please, input Payment Type Code!",
                          //   },
                          // ]}
                          style={{ marginRight: 30, width: "100%" }}
                        >
                          <Input placeholder="Reported By" />
                        </Form.Item>
                      </Row>

                      <Row gutter={30} className="row-phone">
                        <Form.Item
                          label="Phone Number"
                          name="phone_no"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please, input Payment Type Code!",
                          //   },
                          // ]}
                          style={{ marginRight: 30, width: "100%" }}
                        >
                          <Input placeholder="Phone Number" />
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>

                  <Divider
                    className="divider-form"
                    style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
                  />
                </>
              ) : (
                <>
                  <Row gutter={30} className="row-found-by">
                    <Col span={8} className="col-found">
                      <Form.Item
                        label="Found By"
                        name="found_by"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Found By" />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-found-loc">
                      <Form.Item
                        label="Location"
                        name="location"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Location" />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-found-submitted">
                      <Form.Item
                        label="Submitted To"
                        name="submitted_to"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Submitted To" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={30} className="row-found-claim">
                    <Col span={8} className="col-found-claimed">
                      <Form.Item
                        label="Claimed By"
                        name="claimed_by"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: 205 }}
                      >
                        <Input placeholder="Found By" />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-found-claimed-date">
                      <Form.Item
                        label="Claimed Date"
                        name="claimed_date"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider
                    className="divider-form"
                    style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {statusType != true ? (
                <>
                  <Row
                    justify="start"
                    align="top"
                    gutter={30}
                    className="row-lost-remarks"
                  >
                    <Col span={16} className="col-lost-remarks">
                      <Form.Item
                        label="Remark"
                        name="remark"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ width: "100%" }}
                      >
                        <Input.TextArea placeholder="Remark" rows={6} />
                      </Form.Item>
                    </Col>

                    <Col
                      span={8}
                      className="col-lost-report"
                      style={{
                        display: "flex !important",
                        justifyContent: "end",
                      }}
                    >
                      <Form.Item
                        label="Reported By"
                        name="reported_by"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                      >
                        <Input
                          placeholder="Reported By"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Phone Number"
                        name="phone_no"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                      >
                        <Input
                          placeholder="Phone Number"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row gutter={30} className="row-found-id">
                    <Col span={8} className="col-found-by">
                      <Form.Item
                        label="Found By"
                        name="found_by"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <Input placeholder="Found By" />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-found-loc">
                      <Form.Item
                        label="Location"
                        name="location"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <Input placeholder="Location" />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-found-submitted">
                      <Form.Item
                        label="Submitted To"
                        name="submitted_to"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <Input placeholder="Submitted To" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={30} className="row-found-claimed">
                    <Col span={8} className="col-found-claimed-by">
                      <Form.Item
                        label="Claimed By"
                        name="claimed_by"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <Input placeholder="Found By" />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-found-claimed-date">
                      <Form.Item
                        label="Claimed Date"
                        name="claimed_date"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <DatePicker
                          placeholder="Claimed Date"
                          format={"YYYY-MM-DD"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="row-lost-phone">
                      <Form.Item
                        label="Phone Number"
                        name="phone_no"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please, input Payment Type Code!",
                        //   },
                        // ]}
                        style={{ marginRight: 30, width: "100%" }}
                      >
                        <Input placeholder="Phone Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-lost-found">
                <Col span={8} className="col-created-by">
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Reported by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-reported-date">
                  <Form.Item
                    label="Reported Date"
                    name="reported_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Reported Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="col-updated-by">
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-lost-found">
                <Col span={8} className="col-updated-date">
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Divider style={{ margin: "0px 0px 15px" }} /> */}
            </>
          ) : null}

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px 30px" }}
          />

          <Row className="row-form-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

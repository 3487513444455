// REACT COMPONENTS
import { useState } from "react";
import axios from "axios";

// ANTD COMPONENTS
import { Button, Col, Modal, Popover, Row, Tooltip, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";

// REACT ICONS
import { TfiLayoutGrid2, TfiMenuAlt } from "react-icons/tfi";
import {
  AiOutlineCalculator,
  AiOutlineSave,
  AiOutlineSplitCells,
} from "react-icons/ai";
import {
  MdHistory,
  MdOutlineLocalPrintshop,
  MdOutlineHorizontalSplit,
} from "react-icons/md";
import { RiMergeCellsHorizontal } from "react-icons/ri";

// GLOBAL API
import { token, user_name } from "../../../API/Global/Payload";
import { baseurl } from "../../../API/Config/Api";

// PAGES COMPONENTS

import ModalArticleVendorManagement from "../../Modals/Purchasing/VendorManagement/Modal";
import ModalVendorArticleView from "../../Modals/Article/Vendor/Modal";
import VendorArticleForm from "../../Forms/VendorArticle/Form";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";
import ModalSplitBill from "../../Modals/Outlet/Order/SplitBillModal";
import ParametersAccounting from "../../Reusable/Parameters/ParamsAcc";
import ModalHistoryPaidBill from "../../Modals/Outlet/Order/HistoryPaidBillModal";
import ModalJoinBill from "../../Modals/Outlet/Order/JoinBillModal";
import ModalPrintPreBill from "../../Modals/Outlet/Order/PrintPreBillModal";
import ModalSavedBill from "../../Modals/Outlet/Order/SavedBillModal";

// MODALS
const { confirm } = Modal;

export default function POSOutletPopover(props) {
  // Props
  const { headers, details, is_refresh, is_save, selectedTable } = props;
  // Open Popover
  const [open, setOpen] = useState(false);
  const [dataRecord, setDataRecord] = useState([]);
  // Open Modal
  const [article, setArticle] = useState(false);
  const [articleView, setArticleView] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openModalSavedOrderList, setOpenModalSavedOrderList] = useState(false);
  const [openModalJoinBill, setOpenModalJoinBill] = useState(false);
  const [openModalSplitBill, setOpenModalSplitBill] = useState(false);
  const [openModalHistoryPaidBill, setOpenModalHistoryPaidBill] =
    useState(false);
  const [openModalPrintPreBill, setOpenModalPrintPreBill] = useState(false);

  const params = ParametersAccounting().params;

  const handleClose = () => {
    setOpen(!open);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "add-update-supplier-confirm",
      title: `Are you sure want to delete supplier named ${_data?.supplier_name.toUpperCase()} from the table?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
      },

      onCancel() {
        // setIsLoading(false);
        console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    var obj = {
      ["id_supplier"]: contentdelete.id_supplier,
      ["deleted_by"]: user_name,
    };

    axios
      .delete(`${baseurl}/master/suppliers`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        masterSuccessNotification(response, { method: 2, source: "Supplier" });
        is_refresh(true);
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Supplier" });
      });
  };

  const handleModalSavedOrderList = () => {
    setOpenModalSavedOrderList(true);
  };

  const handleModalJoinBill = () => {
    setOpenModalJoinBill(true);
  };

  const handleModalSplitBill = () => {
    setOpenModalSplitBill(true);
  };

  const handleModalHistoryPaidBill = () => {
    setOpenModalHistoryPaidBill(true);
  };

  const handleModalPrintPreBill = () => {
    setOpenModalPrintPreBill(true);
  };

  // console.log(details);

  return (
    <>
      <Tooltip title="Option Menu" className="vendor-management-tooltip">
        <Popover
          className="pop-over-vendor-management"
          name="pop-over-vendor-management"
          key="pop-over-vendor-management"
          placement="right"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  {`POS Cashier Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <AiOutlineCalculator
                      style={{
                        color: "#108ee9",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Calculator`}
                </Button>
              </Row> */}

              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  htmlType="submit"
                  key={"submit"}
                  icon={
                    <AiOutlineSave
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalSavedOrderList();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Saved Order List`}
                </Button>
              </Row> */}

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdHistory
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalHistoryPaidBill();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`History Paid Bill`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdOutlineLocalPrintshop
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalPrintPreBill();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Print Pre Bill`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <RiMergeCellsHorizontal
                      style={{
                        color: "#1890FF",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalJoinBill();
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Join Bill`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <AiOutlineSplitCells
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  onClick={() => {
                    handleModalSplitBill();
                    handleClose();
                  }}
                  disabled={details.some((e) => e.id === null) ? true : false}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Split Bill`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="primary-btn"
            style={{
              fontSize: 20,
              color: "1890FF",
              display: "flex",
              justifyContent: "center",
              marginRight: 15,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      {/* <ModalVendorArticleView
      {/* <ModalVendorArticleView
        is_open={articleView}
        is_close={setArticleView}
        dataVendor={articleView == true ? dataRecord : ""}
      />

      {/* <ModalArticleVendorManagement
        is_open={article}
        is_close={setArticle}
        dataVendor={article == true ? dataRecord : ""}
        params={}
      /> */}

      {/* <VendorArticleForm
        openModal={openForm}
        closeModal={setOpenForm}
        dataVendor={openForm == true ? dataRecord : ""}
        is_saved={true}
      /> */}
      <ModalJoinBill
        openModal={openModalJoinBill}
        closeModal={setOpenModalJoinBill}
      />
      <ModalSplitBill
        openModal={openModalSplitBill}
        closeModal={setOpenModalSplitBill}
        dataHeader={headers}
        dataDetail={details}
      />
      <ModalHistoryPaidBill
        openModal={openModalHistoryPaidBill}
        closeModal={setOpenModalHistoryPaidBill}
      />
      <ModalPrintPreBill
        openModal={openModalPrintPreBill}
        closeModal={setOpenModalPrintPreBill}
        header={headers}
        detail={details}
      />

      <ModalSavedBill
        openModal={openModalSavedOrderList}
        closeModal={setOpenModalSavedOrderList}
        selectedTable={selectedTable}
      />
    </>
  );
}

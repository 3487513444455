// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

import {
  FaConciergeBell,
} from "react-icons/fa";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";
import {
  failedPOSOrder,
  successPOSOrder,
} from "../../../Reusable/Notification/Notification";
import MasterCaptainServeQue from "../../../Reusable/Outlet/Master/ServeQue/CaptainServeQue";

// Import Page Components

// CODE
export default function CaptainModalTableOrder(props) {
  // PROPS
  const {
    openModal,
    closeModal,
    // Data
    articleAll,
    articleItems,
    articleTable,
    newOrder,
  } = props;

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [articleSelected, setArticleSelected] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState();
  // Edit
  const [dataEdit, setDataEdit] = useState(null);
  const [edit, setEdit] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    if (articleItems && !cleanUp) {
      console.log("About to Order: ", articleItems);

      setArticleSelected(articleItems);
      handleFormFields(articleItems);
    } else {
      setArticleSelected(null);
    }
    // setFilterData(handleFilter);
    // setEdit(dataGuest);

    return () => {
      cleanUp = true;
    };
  }, [openModal, articleItems]);

  // Handle SET FORMS FIELD
  const handleFormFields = (value) => {
    const _record = value;

    setDataEdit(_record);

    if (newOrder) {

      form.setFieldsValue({
        article_name: _record?.article_name,
        gross: _record?.gross,
        item_quantity: _record?.quantity || _record?.item_quantity,
        notes: _record?.notes,
        serving_que: _record?.serving_que,
        serving_id: _record?.serving_id,
      });
    } else {
      form.setFieldsValue({
        article_name: _record?.article_name,
        gross: _record?.gross,
        item_quantity: _record?.quantity || _record?.item_quantity,
        notes: _record?.notes,
        serving_que: _record?.serving_que,
        serving_id: _record?.serving_que,
      });
    }
  };

  // Handle Serve Que
  const getServeQue = (val) => {
    const _value = val;

    console.log("Serv Que: ", _value);

    form.setFieldsValue({
      serving_que: _value?.label,
      serving_id: _value?.value,
    });
  };

  // ON FINISH
  const onFinish = (val) => {
    setLoading(true);
    const _data = val;

    console.log("Data: ", _data);

    handleSubmit(_data);
    // articleTable({
    //   ...articleItems,
    //   ..._data,
    // });

    // handleCancel();
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _data = val;

    console.log("on Finish Failed: ", _data?.values);

    masterIncomplete(_data);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentEdit = e;
    console.log("EDITED > ", contentEdit);

    // if(newOrder) {
      articleTable({
        ...articleItems,
        ...contentEdit,
      });
    // } else {
    //   await axios
    //       .put(
    //         `${baseurl}/pos/order-detail`,
    //         {
    //           deleted_reason: e.deleted_reason,
    //           notes: e.notes ?? null,
    //           id: articleItems.id,
    //           created_by: user_name,
    //           item_quantity: e.item_quantity,
    //           serving_order: e.serving_id,
    //           total_price: e.gross * e.item_quantity,
    //           tax: articleItems.tax,
    //           service: articleItems.service,
    //           is_tax: articleItems.is_tax,
    //           is_service: articleItems.is_service,
    //           gross: e.gross,
    //           add_ons: articleItems.add_ons,
    //         },
    //         {
    //           headers: { Authorization: `Bearer ${token ? token : ""}` },
    //         }
    //       )
    //       .then((response) => {
    //         if (response.status === 200) {
    //           console.log("Update Detail: ", response);
    //           successPOSOrder(response, { method: 1 });
    //           is_refresh(true);
    //           // setTimeout(() => {
    //           //   is_created(true);
    //           // }, 1000);
    //         } else {
    //           failedPOSOrder(response, { method: 1 });
    //         }
    //       })
    //       .catch((error) => {
    //         console.log("Error: ", error);
    //         failedPOSOrder(error, { method: 1 });
    //       })
    //       .finally(() => {
    //         // setIsLoading(false);
    //       });
    // }
    handleCancel();
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  return (
    <>
      <Modal
        className="edit-modal-guest"
        title={
          <>
            <Row className="modaltitle-row">
              <FaConciergeBell style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>{`Item Details`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            item_quantity: 1,
            // deleted_reason: "CANCEL ORDER",
          }}
          name="order-modal"
          className="order-modal"
          layout="vertical"
          style={{ padding: "0px 30px" }}
        >
          <Row gutter={30} className="form-main-row">
            <Col span={24} className="form-main-col">
              <Row className="serve-row" justify="start" gutter={30}>
                <Col span={16} className="serve-col">
                  <Form.Item
                    label="Serving Que"
                    name={"serving_que"}
                    rules={[
                      {
                        required: true,
                        message: "Please, Select an Option!",
                      },
                    ]}
                  >
                    <MasterCaptainServeQue
                      getServeQue={getServeQue}
                      servingQue={dataEdit}
                      articleAll={articleAll}
                    />
                  </Form.Item>

                  <Form.Item label="Serving ID" name={"serving_id"} hidden>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8} className="qty-col">
                  <Form.Item
                    label="Items Qty"
                    name={"item_quantity"}
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Items Quantity!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Items Quantity"
                      defaultValue={1}
                      min={1}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="serve-row" justify="start" gutter={30}>
                <Col span={16} className="article-col">
                  <Form.Item label="Article Name" name={"article_name"}>
                    <Input placeholder="Article Name" disabled />
                  </Form.Item>

                  <Form.Item label="Article ID" name={"article_id"} hidden>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8} className="price-col">
                  <Form.Item label="Unit Price" name={"gross"}>
                    <InputNumber
                      placeholder="Price"
                      disabled
                      addonBefore={currency}
                      formatter={(val) => {
                        return `${val > 0 ? val : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="notes-row" justify="start">
                <Col className="note-col" span={16}>
                  <Form.Item label="Notes" name={"notes"}>
                    <Input.TextArea
                      placeholder="Order Notes (et. Well Done Steak)"
                      rows={4}
                      allowClear
                      maxLength={120}
                      showCount
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                {newOrder == false ? (
                  <Col className="note-col" span={8}>
                    <Form.Item
                      label="Edit Reason"
                      name={"deleted_reason"}
                      rules={[
                        {
                          required: true,
                          message: "Please, Select an Option!",
                        },
                      ]}
                    >
                      <Select
                        // defaultValue="CANCEL ORDER"
                        placeholder={"Select Option"}
                        //   style={{
                        //     width: 120,
                        //   }}
                        onChange={() => {}}
                        options={[
                          {
                            value: "CANCEL ORDER",
                            label: "CANCEL ORDER",
                          },
                          {
                            value: "WRONG POSTING",
                            label: "WRONG POSTING",
                          },
                          {
                            value: "WRONG QUANTITY",
                            label: "WRONG QUANTITY",
                          },
                          {
                            value: "OTHERS",
                            label: "OTHERS",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>

          <Divider className="divider-form" style={{ margin: "15px 0px" }} />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={loading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

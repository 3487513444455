// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// CODE
export default function ParametersBackOffice(props) {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [params, setParams] = useState({});

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "back-office",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];

          setParams(_res);
        }
      },
      onReject: (error) => {
        console.log("Failed params: ", error);
        // setLoading(false);
      },
    });
  };

  // USER ROLES
  useEffect(() => {
    fetchData();
  }, []);

  return { params };
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  Layout,
  Row,
  Typography,
  Space,
  Modal,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Notifications
import {
  failedFetch,
} from "../../../Components/Reusable/Notification/Notification";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import React Icons
import { FaCashRegister } from "react-icons/fa";

// Import Page Components
import ModalOutletLocation from "../../../Components/Modals/Outlet/OutletLocation/Location/Modal";

// Import Global Border
import { cardBody, cardHeader } from "../../../Style/Global/Global";
import POSOrder from "../../../Components/Table/Outlet/Cashier/Main/Table";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { ArrowRightOutlined } from "@ant-design/icons";

// Modal
const { confirm } = Modal;

// CODE
export default function POSCashierPage() {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);
  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname } = useLocation();
  // STATE MANAGEMENT
  // Search
  const [value, setValue] = useState(null);
  // Status
  const [isRoomService, setIsRoomService] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState({
    shift: "UNSELECTED",
    locations: "UNSELECTED",
    locations_id: 0,
    shift_id: 0,
  });
  const [outletLocation, setOutletLocation] = useState("UNSELECTED");
  const [shift, setShift] = useState({
    name: null,
    id: 0,
  });
  const [preShift, setPreShift] = useState({
    name: null,
    id: 0,
  });

  // Data
  const [data, setData] = useState(null);
  const [shiftData, setShiftData] = useState([]);
  // Modal State
  const [open, setOpen] = useState(false);

  // Data Dummy
  const closingData = [
    {
      outlet: "RESTAURANTS",
      dates: [
        {
          date: "2024-10-09",
          shifts: [
            { shiftNumber: 1, status: 1 },
            { shiftNumber: 2, status: 0 },
            { shiftNumber: 3, status: 1 }
          ]
        },
        {
          date: "2024-10-10",
          shifts: [
            { shiftNumber: 2, status: 1 },
            { shiftNumber: 1, status: 0 },
            { shiftNumber: 3, status: 1 },
          ]
        }
      ]
    },
    {
      outlet: "ROOM SERVICE",
      dates: [
        {
          date: "2024-10-09",
          shifts: [
            { shiftNumber: 1, status: 0 },
            { shiftNumber: 2, status: 1 },
            { shiftNumber: 3, status: 0 }
          ]
        },
        {
          date: "2024-10-10",
          shifts: [
            { shiftNumber: 1, status: 1 },
            { shiftNumber: 2, status: 0 },
            { shiftNumber: 3, status: 0 }
          ]
        }
      ]
    }
  ];

  // USE EFFECT //
  // useEffect Cashier Page is Open
  useEffect(() => {
    let cleanUp = false;
  
    const fetchData = async () => {
      await getParameters({
        params: "parameters",
        type: "shift",
        onAwait: () => {},
        onSuccess: (response) => {
          if (!cleanUp) {
            console.log("Shift --> ", response);
  
            if (response?.data?.msg?.length > 0) {
              let _res = response.data.msg;
              console.log("_res", _res);
              handleSetParameter(_res);
            }
          }
        },
        onReject: (error) => {
          console.log("Params Shift --> ", error);
          failedFetch(error);
        },
      });
    };
  
    fetchData();
  
    return () => {
      cleanUp = true;
    };
  }, []);

  const handleSetParameter = (val) => {
    let { currentShift, previousShift } = getCurrentAndPreviousShift(val);
    let currentOutlet = sessionStorage.getItem("pos_outlet_location"); 
    
    console.log("currentShift", currentShift);
    console.log("previousShift", previousShift);
    
    setShift({
      name: currentShift.shift_name,
      id: currentShift.id,
    });

    setPreShift({
      name: previousShift.shift_name,
      id: previousShift.id,
    });
    
    if (currentOutlet) {
      setOpen(false);
      setOutletLocation(currentOutlet);
      //cek close cashier
      handleCheckStatus(currentOutlet, moment().format("YYYY-MM-DD"), previousShift.id);
    } else {
      setOpen(true);
    }
  };

  // FUNGSI UNTUK MENDAPTKAN DATA SHIFT SAAT INI DAN SHIFT SEBELUMNYA
  function getCurrentAndPreviousShift(shifts) {
    const currentTime = moment(); // Get the current time
    let currentShift = null;
    let previousShift = null;

    // Sort shifts by start_at time (ignoring the date)
    const sortedShifts = shifts.sort((a, b) => {
        const startA = moment(a.start_at).format('HH:mm');
        const startB = moment(b.start_at).format('HH:mm');
        return startA.localeCompare(startB);
    });

    for (let i = 0; i < sortedShifts.length; i++) {
        const shift = sortedShifts[i];
        const startAt = moment(shift.start_at).set({
            year: currentTime.year(),
            month: currentTime.month(),
            date: currentTime.date()
        });
        const endAt = moment(shift.end_at).set({
            year: currentTime.year(),
            month: currentTime.month(),
            date: currentTime.date()
        });

        // Handle case where shift spans across midnight
        if (endAt.isBefore(startAt)) {
            if (currentTime.isBetween(startAt, moment().endOf('day')) || 
                currentTime.isBetween(moment().startOf('day'), endAt)) {
                currentShift = shift;
                previousShift = i === 0 ? sortedShifts[sortedShifts.length - 1] : sortedShifts[i - 1];
                break;
            }
        } else {
            if (currentTime.isBetween(startAt, endAt)) {
                currentShift = shift;
                previousShift = i === 0 ? sortedShifts[sortedShifts.length - 1] : sortedShifts[i - 1];
                break;
            }
        }
    }

    return { currentShift, previousShift };
}

  const handleCheckStatus = (outletName, targetDate, targetShiftNumber) => {
    const status = checkShiftStatus(outletName, targetDate, targetShiftNumber);
    console.log("status", status, "outletName", outletName, "targetDate", targetDate, "targetShiftNumber", targetShiftNumber);
    if (!status){
      setIsShow(false);
      showConfirmModal();
    } else {
      setIsShow(true);
    }
  };

  const checkShiftStatus = (outletName, targetDate, targetShiftNumber) => {
    //cek outlet
    const outlet = closingData.find(outlet => outlet.outlet === outletName);
    if (!outlet) return 0;

    //cek dates
    const date = outlet.dates.find(date => date.date === targetDate);
    if (!date) return 0;

    //cek shift
    const shift = date.shifts.find(shift => shift.shiftNumber === targetShiftNumber);
    if (!shift) return 0;

    return shift.status == 1 ? 1 : 0;
  };

  // Handle Select
  const handleOpen = () => {
    // console.log("PLEASE OPEN!", open);
    setOpen(true);
  };

  // Handle Select
  const handleSelect = (value, secondValue) => {
    const _selected = value;

    console.log("Selected Data: ", _selected);

    if (_selected?.outlet_location && _selected?.outlet_id > 0) {
      setSelectedData({
        locations: _selected.label_outlet_location,
        shift: _selected.label_shift,
        locations_id: _selected?.outlet_id,
        shift_id: _selected?.shift,
      });

      setOutletLocation(_selected.label_outlet_location);
      //cek close cashier
      handleCheckStatus(_selected.label_outlet_location, moment().format("YYYY-MM-DD"), preShift.id)

      setData(secondValue);
      sessionStorage.setItem("pos_outlet_location", _selected.label_outlet_location);

    }

    // setOpen(false);
  };

  const handleNavigateReturn = () => {
    navigate(`${pathname}`.substring(0, pathname.length - 12), {
      state: {},
    });
  };

  // SHOW CONFIRM MODAL, untuk menampilkan modal konfirmasi submit, update, atau close order
  const showConfirmModal = (value) => {
    const _data = value;


    confirm({
      className: "confirm",
      title: `Captain Order Confirmation`,
      content: `The previous shift period has not been closed. Would you like to close the previous shift before starting a new one?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        navigate("/f-and-b/outlet/close-cashier");
        Modal.destroyAll();
      },
      
      onCancel() {
        Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="table-order-main-layout">
          <Row
            className="table-order-title"
            align="middle"
            justify="start"
            style={cardHeader}
          >
            <FaCashRegister
              className="icons-head"
              style={{
                height: 24,
                width: 24,
                margin: "10px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "10px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`POS Cashier`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: "10px 30px 15px",
              }}
            >
              <Col
                className="location-col"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Location`}</Typography>

                <Button
                  className="submit-btn"
                  onClick={handleOpen}
                  type="primary"
                  style={{
                    width: 205,
                  }}
                  disabled
                >
                  {outletLocation}
                </Button>
              </Col>

              <Col
                className="shift-col"
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Shift`}</Typography>

                <Button
                  className="submit-btn"
                  onClick={handleOpen}
                  type="primary"
                  style={{
                    width: 205,
                  }}
                  disabled
                >
                  {shift.name}
                </Button>
              </Col>

              <Col
                className="btn-col"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleNavigateReturn}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
              <Col>
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={() => navigate("/f-and-b/outlet/close-cashier")}
                  icon={<ArrowRightOutlined />}
                  style={
                    {
                      // marginRight: 15,
                    }
                  }
                >
                  {`Go to Close Cashier`}
                </Button>
              </Col>
            </Space>
          </Row>

          {isShow ? (
            <POSOrder
              key={"pos-order"}
              searchKey={value}
              is_search={() => {}}
              table_number={() => {}}
              selections={selectedData}
              outlet_data={data}
              is_roomService={isRoomService}
              isChangeTable={false}
            />
          ) : null}
        </Col>

        <ModalOutletLocation
          closeModal={setOpen}
          openModal={open}
          getLocs={handleSelect}
          getData={setData}
          is_roomService={setIsRoomService}
        />
      </Content>
    </Layout>
  );
}

// CONTEXT
import CreateContext from "../CreateContext";

// BASE URL API
import baseAPI from "../../Config/Api";

// REDUCERS
const inventoryReducers = (state, action) => {
  if ((action.type = "getData")) {
    return {
      ...state,
      data: action.payload,
    };
  }
};

// RECEIVING
// Get Receiving Headers
const getReceivingHead =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/inventory" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Purchasing Order List
const getPurchaseOrderList =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

  //STOCK OPNAME
  // Get Stock Opname By Warehouse ID
  const getStockOpname =
    (dispatch) =>
    async ({ source, id_warehouse, onAwait, onSuccess, onReject }) => {
      let query = "/inventory" + "/" + source;
      if (onAwait) onAwait();

      baseAPI
        .post(query, { id_warehouse })
        .then((response) => {
          dispatch({ type: "getData", payload: response.data });
          if (onSuccess) onSuccess(response);
        })
        .catch((error) => {
          if (onReject) onReject(error);
        });
    };

  // Get Article Stock Opname By Warehouse ID
  const getArticleStockOpname =
    (dispatch) =>
    async ({ source, id_so, onAwait, onSuccess, onReject }) => {
      let query = "/inventory" + "/" + source;
      if (onAwait) onAwait();

      baseAPI
        .post(query, { id_so })
        .then((response) => {
          dispatch({ type: "getData", payload: response.data });
          if (onSuccess) onSuccess(response);
        })
        .catch((error) => {
          if (onReject) onReject(error);
        });
    };

  // Get After Closing Data Stock Opname By ID SO
  const getAfterClosing =
    (dispatch) =>
    async ({ source, id_so, onAwait, onSuccess, onReject }) => {
      let query = "/inventory" + "/" + source;
      if (onAwait) onAwait();

      baseAPI
        .post(query, { id_so })
        .then((response) => {
          dispatch({ type: "getData", payload: response.data });
          if (onSuccess) onSuccess(response);
        })
        .catch((error) => {
          if (onReject) onReject(error);
        });
    };

  // Get Article By Warehouse ID
  const getWarehouseArticle =
    (dispatch) =>
    async ({ source, id_warehouse, onAwait, onSuccess, onReject }) => {
      let query = "/inventory" + "/" + source;
      if (onAwait) onAwait();

      baseAPI
        .post(query, { id_warehouse })
        .then((response) => {
          dispatch({ type: "getData", payload: response.data });
          if (onSuccess) onSuccess(response);
        })
        .catch((error) => {
          if (onReject) onReject(error);
        });
    };

// Get Stock Transfer/Request List
const getStockTransferRequestList =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/inventory" + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Transfer/Request Type
const getStockTransferRequestType =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

  // Get Standard Recipe
  const getStandardRecipe =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/kitchen" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// EXPORT CONTEXT and PROVIDER
export const { Provider, Context } = CreateContext(
  inventoryReducers,
  {
    getReceivingHead,

    getPurchaseOrderList,
    
  },
  { data: [] }
);
// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
// export default function MasterSupplier({
//   getSupplier,
//   selectedArticleId,
//   supplier,
//   nextState,
// })
export default function MasterSupplier(props) {
  // Properties
  const { getSupplier, selectedArticleId, supplier, nextState } = props;
  // CONTEXT
  const { getMasterSupplier } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [supply, setSupply] = useState([]);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = () => {
    getMasterSupplier({
      supplier: "suppliers",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        let option = response.data.msg.map((dev) => {
          if (dev.is_deleted != true) {
            return {
              value: dev.id_supplier,
              label: dev.supplier_name,
              ...dev,
            };
          }
        });

        setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    setOptionValue([]);
    fetchData();
    // setSexType(selectedSexType)
  }, [selectedArticleId]);

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // setSexType(selectedSexType)
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("supplier ", supplier);
    console.log(optionValue);

    if (supplier && !cleanUp) {
      let x = optionValue.filter((e) => {
        if (e.label == supplier?.supplier_name) {
          return e;
        }
      });
      console.log(x);
      setSupply(x);
      if (optionValue.length > 0) {
        getSupplier(x[0]?.value, x[0]);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [supplier, optionValue]);

  console.log(selectedArticleId);

  return (
    <>
      <Select
        key={optionValue.value}
        style={{ width: "100%" }}
        value={supply}
        disabled={supplier != null || nextState == true ? true : false}
        onChange={(e, allKey) => {
          setSupply(e);
          console.log(e);

          getSupplier(e, allKey);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
      />
    </>
  );
}

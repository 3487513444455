// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// GLOBAL STYLES
import { textForm } from "../../../Style/Global/Global";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { failedFetch } from "../../Reusable/Notification/Notification";
import axios from "axios";
import { baseurl } from "../../../API/Config/Api";

const sourceData = [
  {
    id: "key_1728012978264_g8gtr1tq123",
    source: "MICE",
    article_id: 10,
    article_name: "SATE KAMBING",
    reservation_id: "MICE230800007",
    price: 22500,
    quantity: 150,
    date: "2023-08-25",
    is_show: false,
  },
  {
    id: "key_1728012978264_wbplqp9u321",
    source: "MICE",
    article_id: 11,
    article_name: "LYCHEE TEA",
    reservation_id: "MICE230800007",
    price: 18000,
    quantity: 150,
    date: "2023-08-25",
    is_show: false,
  },
  {
    id: "key_1728013067210_p0chbydx222",
    source: "POS",
    article_id: 353,
    article_name: "Ayam Betutu",
    reservation_id: "POS240100145",
    price: 18315,
    quantity: 1,
    date: "2024-01-16",
    is_show: false,
  },
  {
    id: "key_1728013067210_yw0p88t5111",
    source: "POS",
    article_id: 119,
    article_name: "BLACK Coffee  ",
    reservation_id: "POS240100145",
    price: 12210,
    quantity: 1,
    date: "2024-01-16",
    is_show: false,
  },
];

export default function GuestBillModal({
  openModal,
  closeModal,
  isJoin,
  getType,
  reservationData,
}) {
  // State
  const [data, setData] = useState(sourceData);
  const [open, setOpen] = useState(false);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [guestData, setGuestData] = useState(null);
  const currency = CurrencySymbols().code;

  useEffect(() => {
    let cleanUp = false;

    if (openModal && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    let cleanUp = false;

    if (reservationData && !cleanUp) {
      setGuestData(reservationData);
      fetchData(reservationData?.reservation_id);
    }

    return () => {
      cleanUp = true;
    };
  }, [reservationData]);

  const fetchData = async (resId) => {
    setLoading(true);

    await axios
      .post(`${baseurl}/guest/folio-by-reservation-id`, {
        reservation_id: resId,
      })
      .then((response) => {
        console.log("response guest bill list: ", response);
        if (response?.data?.msg) {
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    closeModal(false);
  };

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray(selectedRowKeys);
      // setSelectedData(selectedRows);
      // handlePick(selectedRowKeys);
    },
  };

  // HANDLE CHANGE JOIN CHECKBOX
  const onChange = (e) => {
    console.log(`Is Join ? = ${e.target.checked}`);
    let join = e.target.checked;
    isJoin(join);

    // if join, change bill type to JOIN BILL
    if (join) {
      getType(2);
    } else {
      getType(0);
    }
  };

  // Table Columns
  const columns = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Quantity`,
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
  ];

  return (
    <>
      <Modal
        className="guest-history-modals"
        key="guest-history-modals"
        title={
          <>
            <Space size={"large"} className="title-modal-row">
              <ExclamationCircleOutlined
                className="icon-title-modal"
                style={{
                  color: "#1890FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="title-modal-text"
                style={{
                  color: "#1890FF",
                  //   fontSize: 24,
                }}
              >
                {`Guest Bill List`}
              </Typography>
            </Space>
          </>
        }
        centered
        open={open}
        bodyStyle={{
          padding: 30,
        }}
        closable
        onCancel={handleClose}
        footer={null}
        width={"auto"}
      >
        <Form
          className="geust-form"
          key="geust-form"
          name="geust-form"
          labelAlign="left"
          labelCol={{
            span: 12,
          }}
          labelWrap={{
            span: 12,
          }}
          onFinish={handleClose}
          layout="horizontal"
          style={{
            minWidth: 800,
          }}
        >
          <Row className="identity-row">
            <Col span={24} className="identity-col">
              <Row className="ident-row" gutter={30}>
                <Col span={12} className="name-col">
                  <Form.Item
                    label="Guest Name"
                    className="name-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="guest-name-text" style={textForm}>
                      {guestData?.guest_name ? guestData?.guest_name : "-"}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="count-col">
                  <Form.Item
                    label="Nationality"
                    className="nat-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="guest-nation-text" style={textForm}>
                      "Jepun"
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                // label="Company Ledger Status"
                className="ledgerstatus-item"
                labelCol={{
                  span: 6,
                }}
                style={{ margin: "15px 0px" }}
              >
                <Alert
                  className="guest-alert"
                  // message="Alert!"
                  showIcon
                  description={"test"}
                  type={"error"}
                  style={{
                    textAlign: "center",
                  }}
                />
              </Form.Item>

              <Divider
                className="form-divider"
                orientation="left"
                style={{
                  margin: "10px 0px",
                  borderColor: "#EBEDF3",
                  fontWeight: "normal",
                  fontSize: 15,
                }}
              >
                {"Bill List"}
              </Divider>

              <Row
                className="visited-table"
                justify="center"
                gutter={30}
                style={{
                  padding: "0px 15px",
                }}
              >
                <Table
                  className="guest-history-table"
                  key="guest-history-table"
                  name="guest-history-table"
                  columns={columns}
                  dataSource={data}
                  bordered={true}
                  // loading={loading}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  size="medium"
                  pagination={false}
                  rowKey={(record) => (record?.id ? record.id : record.key)}
                />
              </Row>

              <Row
                justify="start"
                gutter={30}
                style={{
                  padding: "15px",
                }}
              >
                <Divider
                  className="divider-form"
                  // style={{ margin: "15px 0px" }}
                />

                <Checkbox onChange={onChange}>Join With Other Bill?</Checkbox>
              </Row>
            </Col>
          </Row>

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="submit-btn"
              type="default"
              style={{
                marginRight: 15,
                color: "white",
              }}
              // onClick={handleSubmit}
              onClick={() => closeModal(false)}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={() => closeModal(false)}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

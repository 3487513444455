// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { AppstoreFilled } from "@ant-design/icons";
import { Button, Col, Row, Select, Tooltip } from "antd";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

export default function WarehouseList(props) {
  // PROPS
  const { selectedItem, warehouse_name, is_disable, is_multiple, store_id } =
    props;

  // CONTEXT
  const { getWarehouseList } = useContext(MainContext);

  // STATE
  const [optionValue, setOptionValue] = useState([]);
  const [warehouse, setWarehouse] = useState(is_multiple === true ? [] : null);

  const navigate = useNavigate();

  // FETCH WAREHOUSE LIST
  const fetchData = async () => {
    await getWarehouseList({
      type: "warehouse",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("RESPONSE WAREHOUSE ALL=> ", response);

        if (response?.data.msg != null) {
          let filterData = response.data.msg.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          const filter = filterData.map((item) => {
            return {
              key: item.id,
              label: item.warehouse_name,
              value: item.warehouse_name,
              id_warehouse: item.id_warehouse,
              warehouse_name: item.warehouse_name,
            };
          });

          setOptionValue(filter);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (warehouse_name && !cleanUp) {
      console.log("Edit Warehouse: ", warehouse_name);
      setWarehouse(warehouse_name);
    } else {
      setWarehouse(is_multiple === true ? [] : null);
    }

    return () => {
      cleanUp = true;
    };
  }, [warehouse_name]);

  // ID Warehouse
  useEffect(() => {
    let cleanUp = false;

    if (store_id?.length > 0 && optionValue.length > 0 && !cleanUp) {
      handleName(store_id, optionValue);
    }

    // else {
    //   setWarehouse(is_multiple === true ? [] : null);
    // }

    return () => {
      cleanUp = true;
    };
  }, [store_id, optionValue]);

  // Move to Page
  const move = () => {
    navigate("/back-office/inventory/warehouse");
  };

  // Handle Name
  const handleName = (ids, option) => {
    let _names = option.filter((value) => {
      if (ids.includes(value.id_warehouse)) {
        return value.warehouse_name;
      }
    });

    // let _filtered = _names.map((val) => {
    //   return val.warehouse_name;
    // });

    console.log("Ware House Names: ", _names);

    if (_names) {
      setWarehouse(_names);
    } else {
      setWarehouse([]);
    }
  };

  // Handle Change
  const handleChange = (e, i) => {
    const _value = e;
    const _allKey = i;

    console.log("Allkeys: ", _allKey);

    setWarehouse(_value);

    if (is_multiple) {
      selectedItem(
        _allKey.map((val) => {
          return val?.id_warehouse;
        })
      );
    } else {
      selectedItem({
        id_warehouse: _allKey?.id_warehouse,
        warehouse_name: _allKey?.warehouse_name,
      });
    }
  };

  // Handle Clear
  const handleClear = () => {
    setWarehouse(is_multiple === true ? [] : null);
  };

  return (
    <Row className="master-row" align="top" justify="start" gutter={0}>
      <Col span={22} className="select-col">
        <Select
          className="master-warehouse"
          key={optionValue.key}
          value={warehouse}
          placeholder="Store Name"
          showSearch
          allowClear
          mode={is_multiple ? "multiple" : null}
          disabled={is_disable}
          onChange={handleChange}
          onClear={handleClear}
          options={optionValue}
          style={{ width: "100%" }}
        />
      </Col>

      <Col span={2} className="select-col">
        <Tooltip
          className="redirect-tooltip"
          title={"Redirect to Master Warehouse"}
        >
          <Button
            className="master-btn"
            icon={
              <AppstoreFilled
                onClick={move}
                style={{ fontSize: 20, color: "#3699FF" }}
              />
            }
          />
        </Tooltip>
      </Col>
    </Row>
  );
}

// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// AUTH
import {
  roles,
  department,
  token,
  module,
  sub_module,
  sub_children,
} from "../../API/Global/Payload";

// Import Ant Design Components
import { Button, Col, Grid, Image, Layout, Menu, Row, Space } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import Icon from "@ant-design/icons";

// Import Icons
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";

// Import CSS
import "./MainLayout.css";

// Import Page Layout Components
import HeaderComponents from "../Header/Header";
import FooterComponents from "../Footer/Footer";
import BreadCumbComponents from "../Breadcrumb/BreadCrumb";
import SkeletonLoad from "../Loading/SkeletonLoad";

// Import Routes
import MainRoutes from "../../Routes/Main/Routes";

// Menu Items
import { items, itemsModule } from "./MenuList";
import { collapseStyle, uncollapseStyle } from "../../Style/Global/Global";

// Root Sub Menu
const rootSubmenuKeys = ["sub1", "sub2", "sub3"];

// Layput Components
const { Header, Sider } = Layout;
const { useBreakpoint } = Grid;

// CODE
export default function MainLayout() {
  // Use Navigate & Location
  const navigate = useNavigate();
  const location = useLocation();
  const locName = JSON.parse(sessionStorage.getItem("locationState"));

  // STATE MANAGEMENT
  // State Sider
  const [listMenu, setListMenu] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const [openKeys, setOpenKeys] = useState(
    locName?.length > 0 ? locName : ["dashboard"]
  );
  const [lastElements, setLastElements] = useState(null);
  const [lastTitle, setLastTitle] = useState(null);

  // State Window
  const [loading, setLoading] = useState(true);
  // Path Location
  const [pathLoc, setPathLoc] = useState("");

  // SCREEN SIZE
  const screen = useBreakpoint();

  // console.log("LOCATION => ", location);
  // console.log("Menu Items => ", items);

  // const onOpenChange = (keys) => {
  //   setOpenSub(!openSub);

  //   console.log(keys);

  //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

  //   console.log(latestOpenKey);
  //   if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };

  useEffect(() => {
    if (screen.lg == false) {
      // console.log(screen);
      setCollapsed(true);
    }
  }, [screen]);

  // MOVE TO PAGE
  const move = (e) => {
    const loc = e;

    const _state = [
      loc.keyPath[1] ? loc.keyPath[1] : "",
      loc.keyPath[0] ? loc.keyPath[0] : loc.key,
    ];
    // let allItems = document.getElementsByClassName("text-items");

    let elements = loc.domEvent.target;
    var isOpen = elements.classList.contains("text-items");
    var isSubMenu = loc.keyPath.length;

    // console.log("All Items = ", allItems);
    // console.log("Use Location: ", location);
    // console.log("Session Storage Location: ", locName);
    // console.log("Locations = ", loc);
    //
    // console.log("To Sess Store = ", _state);
    // console.log("Menu Event: ", elements);
    // console.log("Open Attributes: ", isOpen);
    // console.log("Title or Submenu: ", isSubMenu);

    // item.props.children[0][1].props.className;

    // SET SELECTED KEYS
    setOpenKeys([
      loc.keyPath[1] ? loc.keyPath[1] : "",
      loc.keyPath[0] ? loc.keyPath[0] : loc.key,
    ]);
    // SET LAST ELEMENTS
    // setLastElements(elements);

    // if (isSubMenu > 1) {
    //   // let parent = elements.classList.contains("text-title");
    //   setLastTitle(e.item.props.children[0][1].props.className);
    //   // console.log("Last Title Stored: ", lastTitle);

    //   // console.log("Get Title: ", loc.domEvent.target.parentNode);
    //   // console.log("Get Title: ", parent);
    //   elements.setAttribute("class", isOpen ? "text-title" : "text-items");
    // }

    // if (lastElements) {
    //   console.log("Open Keys: ", openKeys);

    //   console.log(`${openKeys[1]} != ${loc.keyPath[0]}`);
    //   console.log("Open Keys Value: ", openKeys[1] != loc.keyPath[0]);

    //   if (openKeys[1] != loc.keyPath[0]) {
    //     // console.log("False");
    //     elements.setAttribute("class", isOpen ? "text-selected" : "text-items");
    //     lastElements.setAttribute("class", "text-items");
    //   } else {
    //     // console.log("True");
    //     elements.setAttribute("class", "text-selected");
    //   }
    // }

    sessionStorage.setItem("locationState", JSON.stringify(_state));

    navigate(
      `${
        loc.keyPath[1] ? loc.keyPath[1] + "/" + loc.keyPath[0] : loc.keyPath[0]
      }`
    );
  };

  // USE EFFECTS MENU ROLES
  useEffect(() => {
    let cleanUp = false;

    // if (items && !cleanUp) {
    if (itemsModule.length > 0 && !cleanUp) {
      // console.log("Item Modules: ", itemsModule);
      // console.log("Auth Modules: ", module);
      // console.log("Auth Sub-Modules: ", sub_module);
      // console.log("Auth Grand Children: ", sub_children);

      // let filterMenu = itemsModule.filter((filt) => {
      // if (filt?.role == roles && filt?.dept == department) {
      //   return filt;
      // }
      //   return module.includes(filt?.id_module);
      // });

      // console.log("Filtered: ", filterMenu);

      // setListMenu(filterMenu);
      handleMap({
        module: module,
        sub_mods: sub_module,
        item: itemsModule,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [items, itemsModule]);

  // USE EFFECTS SKELETON LOADING
  useEffect(() => {
    if (pathLoc != location?.pathname) {
      setLoading(true);

      // console.log("Main Layout -> ", location);

      if (loading == true) {
        setPathLoc(location?.pathname);

        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    }
  }, [location, loading]);

  // HANDLE COLLAPSIBLE
  const handleCollapse = (value) => {
    let element = document.getElementById("slider");
    let slider = document.getElementById("slider");
    var isOpen = slider.classList.contains("slide-in");

    let _headEl = document.getElementById("headers");
    let _head = document.getElementById("headers");
    var isOpenHead = _head.classList.contains("site-layout-background");

    // console.log("Value Collapsible: ", collapsed);
    // console.log("IS OPEN: ", isOpen);
    // console.log("IS OPEN HEADERS: ", isOpenHead);
    // console.log("Elements: ", element);
    // console.log("Sliders: ", slider);

    setTimeout(() => {
      setCollapsed(!collapsed);
    }, 150);

    // element.setAttribute("class", isOpen ? "slide-out" : "slide-in");
    _headEl.setAttribute(
      "class",
      isOpenHead ? "site-layout-background" : "site-layout-background-in"
    );
  };

  // Handle Map
  const handleMap = (settup) => {
    let arr = [];

    let filter = settup?.item.filter((mod) => {
      if (settup?.module.includes(mod?.id_module)) {
        return mod;
      }
    });

    if (filter?.length > 0) {
      let sub = filter.filter((subs) => {
        if (subs?.children?.length > 0 && settup?.sub_mods?.length > 0) {
          // console.log("Children: ", subs);

          let _sub = subs.children.filter((record) => {
            if (settup?.sub_mods.includes(record?.id_module)) {
              // console.log("Children's Sub: ", record);
              return record;
            }
          });

          // console.log("Selected SUBS: ", _sub);

          arr.push({ ...subs, children: _sub });
        } else {
          return arr.push({
            ...subs,
            children: subs?.children?.length > 0 ? [] : null,
          });
        }
      });

      // console.log("Filter Module: ", filter);
      // console.log("Filter Sub-Modules: ", sub);
      // console.log("Array Modules: ", arr);

      setListMenu(arr);
    }
  };

  // const onOpenChange = (e) => {
  //   console.log("on open change => ", e);
  // };
  // const onClick = (e) => {
  //   console.log("on click => ", e);
  // };
  // const onDeselect = (e) => {
  //   console.log("on deselect => ", e);
  // };
  // const onSelect = (e) => {
  //   console.log("on select => ", e);
  // };

  // console.log("Open Keys > ", openKeys);

  return (
    <Layout className="layout-main-page">
      <Sider
        id="slider"
        className="slide-in"
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={256}
        collapsedWidth={84}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "#1e1e2d",
          // overflowY: "auto",
          scrollBehavior: "smooth",
          // WebkitTransform: "translateX(100%)",
          // transform: "translateX(100%)",
          // animation: "slide 0.5s forwards",
          // WebkitAnimation: "slide 0.5s forwards",
          // WebkitAnimationDelay: "2s",
          // animationDelay: "0.5s",
        }}
      >
        <Row
          justify="space-between"
          className="row-header-btn"
          style={{
            height: 65,
            backgroundColor: "#1a1a27",
          }}
        >
          {!collapsed ? (
            <div
              className="space-sider"
              style={{
                height: 65,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#1a1a27",
              }}
            >
              <Image
                className="logo"
                name="logo"
                key="logo"
                alt="Look Logo"
                src={"/looklogo(color).png"}
                onClick={() => navigate("/dashboard")}
                preview={false}
                // width={56}
                // height={19}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // padding: "20px 30px",
                  // gap: "125px",
                }}
              />

              <Button
                className="collapsible-btn"
                size="large"
                icon={
                  <HiChevronDoubleLeft
                    className="button-antd"
                    style={{
                      color: "#FFFFFF",
                      fontSize: 30,
                      opacity: 0.3,
                      backgroundColor: "#1a1a27",
                    }}
                  />
                }
                onClick={handleCollapse}
                style={{
                  backgroundColor: "#1a1a27",
                  borderColor: "#1a1a27",
                  marginLeft: 50,
                }}
              />
            </div>
          ) : (
            <Row justify="center" className="row-header-btn">
              <Button
                className="collapsible-btn"
                size="large"
                icon={
                  <HiChevronDoubleRight
                    className="button-antd"
                    style={{
                      color: "#1890ff",
                      fontSize: 30,
                      backgroundColor: "#1a1a27",
                      // opacity: 0.3,
                    }}
                  />
                }
                onClick={handleCollapse}
                style={{
                  margin: "15px 15px 0px",
                  backgroundColor: "#1a1a27",
                  borderColor: "#1a1a27",
                }}
              />
            </Row>
          )}
        </Row>

        <Menu
          // theme="dark" // #1E1E2D
          mode="inline"
          items={listMenu}
          className="menu-sidebar"
          key="menu-sidebar"
          // inlineIndent={"0px 24px 0px"}
          // inlineCollapsed={collapsed}
          // selectedKeys={openKeys}
          // openKeys={"dashboard"}
          defaultSelectedKeys={openKeys}
          // onOpenChange={onOpenChange}
          // color="#ffffff"
          onClick={move}
          subMenuCloseDelay={0.2}
          subMenuOpenDelay={0.2}
          expandIcon={(expand) => (
            // console.log("IS ACTIVE ? ", isActive)
            <Icon
              className="expand-icon"
              component={expand.isOpen ? DownOutlined : RightOutlined}
              width="7px"
              height="4px"
            />
          )}
          style={{
            padding: "30px 0px 0px",
            fontWeight: 500,
            color: "#888c9f",
            backgroundColor: "#1e1e2d",
          }}
        />
      </Sider>

      <Layout
        className="site-layout"
        style={
          collapsed
            ? {
                marginLeft: 85,
              }
            : {
                marginLeft: 257,
              }
        }
      >
        <Header
          id="headers"
          className="site-layout-background"
          style={collapsed ? collapseStyle : uncollapseStyle}
        >
          <HeaderComponents />
        </Header>

        <Content
          className="content-main"
          style={{
            margin: "65px 30px 65px 30px",
            padding: 24,
            minHeight: "80vh",
            scrollBehavior: "smooth",
          }}
        >
          <Row className="row-main-layout">
            <Col span={24} className="col-main-layout">
              <Row
                className="breadcumb-link"
                justify="start"
                align="stretch"
                style={{ marginBottom: 30 }}
              >
                <BreadCumbComponents />
              </Row>

              {/* MAIN LAYOUT */}
              {/* <Row className="layout-page"> */}
              {loading == true ? (
                <SkeletonLoad is_loading={loading} />
              ) : (
                <MainRoutes
                  key={"main-routes"}
                  grand={sub_children}
                  mods={module}
                  submods={sub_module}
                />
              )}
              {/* </Row> */}
            </Col>
          </Row>
        </Content>

        <FooterComponents />
      </Layout>
    </Layout>
  );
}

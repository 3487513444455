// CONTEXT
import CreateContext from "../CreateContext";

// BASE URL API
import baseAPI from "../../Config/Api";

// REDUCERS
const masterReducer = (state, action) => {
  if ((action.type = "getData")) {
    return {
      ...state,
      data: action.payload,
    };
  }
};

// CONTEXT MASTER GET DATA
const getFetchMaster =
  (dispatch) =>
  async ({ master, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + master;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master ID Card Type
const getMasterIdCardType =
  (dispatch) =>
  async ({ idcard, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + idcard;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Title
const getMasterTitle =
  (dispatch) =>
  async ({ title, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + title;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Sex
const getMasterSex =
  (dispatch) =>
  async ({ sex, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + sex;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Countries
const getMasterCountry =
  (dispatch) =>
  async ({ country, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + country;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Payment Type
const getMasterPaymentType =
  (dispatch) =>
  async ({ payment_type, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + payment_type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Province
const getMasterProvinces =
  (dispatch) =>
  async ({ provinces, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + provinces;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Cities
const getMasterCity =
  (dispatch) =>
  async ({ city, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + city;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Currency
const getMasterCurrency =
  (dispatch) =>
  async ({ currency, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + currency;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Arrangement
const getMasterArrangement =
  (dispatch) =>
  async ({ arrange, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + arrange;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Payment Card
const getMasterPaymentCard =
  (dispatch) =>
  async ({ paymentCard, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + paymentCard;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Guest Status/Category
const getMasterGuestCat =
  (dispatch) =>
  async ({ category, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + category;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Reservation Status
const getMasterResStatus =
  (dispatch) =>
  async ({ status, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + status;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Rate Code
const getMasterRateCode =
  (dispatch) =>
  async ({ rate, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + rate;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Compliment Type
const getMasterCompliment =
  (dispatch) =>
  async ({ compliment, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + compliment;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Segment
const getMasterSegment =
  (dispatch) =>
  async ({ segment, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + segment;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Sub Segment
const getMasterSubSegment =
  (dispatch) =>
  async ({ subSegment, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + subSegment;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Source
const getMasterSource =
  (dispatch) =>
  async ({ source, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + source;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Salesman
const getMasterSalesman =
  (dispatch) =>
  async ({ sales, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + sales;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Group
const getMasterGroup =
  (dispatch) =>
  async ({ group, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + group;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Visit History
const getMasterVisit =
  (dispatch) =>
  async ({ type, guest_id, onAwait, onSuccess, onReject }) => {
    let query = "/guest" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { guest_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// MASTER ROOM AVAILABILITY
// Get Master Room Availability
const getMasterAvailability =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Update Master Availability
const getMasterAvail =
  (dispatch) =>
  async ({ type, start_date, end_date, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .put(query, { start_date, end_date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Availability
const getMasterRoomAvail =
  (dispatch) =>
  async ({ type, start_date, end_date, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { start_date, end_date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Master Room Chart
const getMasterRoomChart =
  (dispatch) =>
  async ({ type, start_date, end_date, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { start_date, end_date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// MASTER ARTICLES
const getArticleMaster =
  (dispatch) =>
  async ({ types, onAwait, onSuccess, onReject }) => {
    let query = "/article" + "/" + types;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Article Department
const getArticleDept =
  (dispatch) =>
  async ({ department, onAwait, onSuccess, onReject }) => {
    let query = "/article" + "/" + department;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Article Main Group
const getArticleMain =
  (dispatch) =>
  async ({ main, onAwait, onSuccess, onReject }) => {
    let query = "/article" + "/" + main;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Article Sub Group
const getArticleSub =
  (dispatch) =>
  async ({ subgroup, onAwait, onSuccess, onReject }) => {
    let query = "/article" + "/" + subgroup;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Article List
const getArticleList =
  (dispatch) =>
  async ({ list, onAwait, onSuccess, onReject }) => {
    let query = "/article" + "/" + list;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Article Inventory
const getArticleInventory =
  (dispatch) =>
  async ({ inventory, onAwait, onSuccess, onReject }) => {
    let query = "/article" + "/" + inventory;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// MASTER POSTING TYPE
// Get Posting Type
const getPostingType =
  (dispatch) =>
  async ({ postingType, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + postingType;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// MASTER ROOM
// Get Data Master Rate Code by Segment
const getMasterRateSegment =
  (dispatch) =>
  async ({
    segment_compl,
    segment,
    sub_segment_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/room/rate-code" + "/" + segment_compl;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { segment, sub_segment_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Rate Code by Segment
const getMasterRateMulti =
  (dispatch) =>
  async ({
    master,
    type,
    code,
    main_segment_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/" + master + "/" + type + "/" + code;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { main_segment_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Master Room Category
const getMasterRoomCat =
  (dispatch) =>
  async ({ roomcat, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + roomcat;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Room List
const getMasterRoom =
  (dispatch) =>
  async ({ main, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + main;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Rate Room Category
const getMasterRateRoomCat =
  (dispatch) =>
  async ({ rate, rate_code, category, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + rate + "/" + category;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { rate_code })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Rate Room Category Segment
const getMasterSegmentRoom =
  (dispatch) =>
  async ({
    segment_compl,
    segment,
    sub_segment_id,
    rate_code,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/room/rate-code" + "/" + segment_compl;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { segment, rate_code, sub_segment_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Rate Room Number
const getMasterRateRoomNo =
  (dispatch) =>
  async ({
    rate,
    number,
    rate_code,
    room_cat_code,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/room" + "/" + rate + "/" + number;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { rate_code, room_cat_code })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Rate Periodic
const getMasterRatePeriod =
  (dispatch) =>
  async ({ rate, period, onAwait, room_cat_code, onSuccess, onReject }) => {
    let query = "/room" + "/" + rate + "/" + period;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { room_cat_code })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Rate Periodic Price
const getMasterPricePeriod =
  (dispatch) =>
  async ({ rate, period, onAwait, room_cat_code, onSuccess, onReject }) => {
    let query = "/room" + "/" + rate + "/" + period;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { room_cat_code })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Rate Room Price
const getMasterRatePrice =
  (dispatch) =>
  async ({
    rate,
    category,
    rate_code,
    segment,
    sub_segment,
    room_cat_code,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/room" + "/" + rate + "/" + category;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { rate_code, room_cat_code, segment, sub_segment })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// OUTLET / POS
// Outlet Master API
const getMasterTable =
  (dispatch) =>
  async ({ outlet, type, onAwait, onSuccess, onReject }) => {
    let query = "/pos" + "/" + outlet + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Table Category
const getTableCategory =
  (dispatch) =>
  async ({ type, outlet_id, onAwait, onSuccess, onReject }) => {
    let query = "/pos" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { outlet_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Table List
const getTableList =
  (dispatch) =>
  async ({ type, table_category_id, onAwait, onSuccess, onReject }) => {
    let query = "/pos" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { table_category_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET MASTER ROOM STATUS
// Master Room Status
const getMasterRoomStatus =
  (dispatch) =>
  async ({ room_status, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + room_status;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// ROOM RECKONING room/status-history/room-reckon
const getRoomReckoning =
  (dispatch) =>
  async ({ type, status, reckon, date, onAwait, onSuccess, onReject }) => {
    let query = type + "/" + status + "/" + reckon;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { date })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET PRINT COUNT
const getMasterPrint =
  (dispatch) =>
  async ({ reservation_id, price_id, onAwait, onSuccess, onReject }) => {
    let query = "/count/print-reservation";
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { reservation_id, price_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// PARAMETERS
// Get Params Front-Office
const getParameters =
  (dispatch) =>
  async ({ params, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + params + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// SUPPLIER
// Get Supplier By Article ID
const getMasterSupplierByArticleId =
  (dispatch) =>
  async ({ supplier, id_article, onAwait, onSuccess, onReject }) => {
    let query = "/supplier-management-systems" + "/" + supplier;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { id_article })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };
// Get Master Supplier
const getMasterSupplier =
  (dispatch) =>
  async ({ supplier, onAwait, onSuccess, onReject }) => {
    let query = "/supplier-management-systems" + "/" + supplier;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Master Supplier Article List
const getMasterSupplierArticleList =
  (dispatch) =>
  async ({ supplier, id_supplier, onAwait, onSuccess, onReject }) => {
    let query = "/supplier-management-systems" + "/" + supplier;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { id_supplier })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Approval Type
const getApprovalType =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// EXPORT CONTEXT & PROVIDER
export const { Provider, Context } = CreateContext(
  masterReducer,
  {
    // MASTER CONTEXT
    // Form Add Guest
    getMasterIdCardType,
    getMasterTitle,
    getMasterSex,
    getMasterCountry,
    getMasterProvinces,
    getMasterCity,

    // Form Add Reservation
    getFetchMaster,
    getMasterCurrency,
    getMasterArrangement,
    getMasterPaymentType,
    getMasterPaymentCard,
    getMasterGuestCat,
    getMasterResStatus,
    getMasterRateCode,
    getMasterCompliment,
    getMasterSegment,
    getMasterSubSegment,
    getMasterSource,
    getMasterSalesman,
    getMasterGroup,
    getMasterVisit,

    // MASTER ROOM AVAILABILITY
    getMasterAvailability,
    getMasterAvail,
    getMasterRoomAvail,
    getMasterRoomChart,

    // MASTER ARTICLE
    getArticleMaster,
    getArticleDept,
    getArticleMain,
    getArticleSub,
    getArticleList,
    getArticleInventory,
    getPostingType,

    // MASTER ROOM & RATE
    getMasterRateSegment,
    getMasterRateMulti,
    getMasterSegmentRoom,
    getMasterRoomCat,
    getMasterRoom,
    getMasterRateRoomCat,
    getMasterRateRoomNo,
    getMasterRatePeriod,

    // MASTER ROOM PRICE
    getMasterPricePeriod,
    getMasterRatePrice,

    // OUTLET
    getMasterTable,
    getTableCategory,
    getTableList,

    // MASTER ROOM STATUS
    getMasterRoomStatus,
    getRoomReckoning,

    // MASTER SUPPLIER
    getMasterSupplierByArticleId,
    getMasterSupplier,
    getMasterSupplierArticleList,

    // PRICE MANAGEMENT

    // PRINT COUNT
    getMasterPrint,

    // PARAMETERS
    getParameters,

    // APPROVAL TYPE
    getApprovalType,
  },
  { data: [] }
);

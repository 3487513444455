// Import React Components
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
  Select,
  Space,
  DatePicker,
  Alert,
  Tooltip,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  MinusCircleOutlined,
  PlusOutlined,
  SwitcherTwoTone,
} from "@ant-design/icons";
import { TbLayersIntersect } from "react-icons/tb";

// Import Master Components
import MasterRoomCat from "../../Reusable/Master/RoomCat/RoomCat";
import RoomAvailabilityTable from "../../Table/FrontOffice/RoomAvailability/Table";
import MasterRoomNumber from "../../Reusable/Master/RoomNumber/RoomNumber";

// Code
export default function ModalRoomPick(props) {
  // PROPS
  const {
    setRoomCode,
    countNight,
    // Modal State
    modalOpen,
    modalClose,
    setRoom,
    // Data
    field,
    is_reset,
    is_rereset,
  } = props;

  // CONTEXT
  const { getMasterRoomCat, getMasterAvail, getMasterRoomAvail } =
    useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [fieldsData, setFieldsData] = useState(null);
  const [roomTable, setRoomTable] = useState([]);
  const [dataAvail, setDataAvail] = useState([]);
  const [fetchRoomData, setFetchRoomData] = useState([]);
  // Data Modals
  const [modalPick, setModalPick] = useState([]);
  // State Group
  const [groupState, setGroupState] = useState(false);
  // Date
  const [dateRange, setDateRange] = useState([]);
  const [days, setDays] = useState(0);
  // Option Value
  const [optionValue, setOptionValue] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Room Cat
  const [code, setCode] = useState(null);
  // Count
  const [isAlert, setIsAlert] = useState(false);
  const [count, setCount] = useState(0);
  const [totalRoomCat, setTotalRoomCat] = useState(0);
  // Loading
  const [isLoading, setIsLoading] = useState(true);

  // UseForm
  const [form] = Form.useForm();

  // FETCH ROOM
  const fetchRoom = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        // FILTER IS DELETED
        if (response?.data?.msg?.length > 0) {
          const _res = response?.data?.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          // CREATE OPTION
          let option = filtered.map((dev) => ({
            value: dev?.room_category_name,
            label: dev?.room_category_name,
            code: dev?.room_category_code,
            id: dev?.id,
          }));

          // SET OPTION VALUE ROOM CAT
          setOptionValue(option);
          setFetchRoomData(option);

          // Length Count
          setTotalRoomCat(filtered?.length);
          // setCount(filtered?.length);
        } else {
          setOptionValue([]);
          setTotalRoomCat(0);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // ROOM AVAILABILITY
  const fetchRoomAvailibility = async () => {
    await getMasterAvail({
      type: "category/availability",
      start_date: field?.arrival
        ? moment(field.arrival).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      end_date: field?.departure
        ? moment(field?.departure).subtract(1, "days").format("YYYY-MM-DD")
        : moment(field?.arrival).format("YYYY-MM-DD"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Update Room Category Availability => ", response);
        // fetchGetRoom();
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            return !val.is_deleted;
          });

          // console.log("Availability FILTERED => ", filtered);
          // setRoomTable(filtered);

          setRoomTable(filtered);
        } else {
          setRoomTable([]);
        }
      },
      onReject: (error) => {
        console.log("ERROR ==> ", error);

        setIsAlert(true);
      },
    });
  };

  // Get Room Availability
  const fetchGetRoom = async () => {
    await getMasterRoomAvail({
      type: "category/availability",
      start_date: field?.arrival
        ? moment(field.arrival).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      end_date:
        field?.night > 1
          ? moment(field.departure).subtract(1, "days").format("YYYY-MM-DD")
          : moment(field.arrival).format("YYYY-MM-DD"),
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Get Room Category Availability => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            return !val.is_deleted;
          });

          // console.log("Availability FILTERED => ", filtered);
          setRoomTable(filtered);
        } else {
          setRoomTable([]);
        }
        // setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR ==> ", error);
        // setIsLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    setOpen(modalOpen);

    if (field && !cleanUp) {
      console.log("DATA >>>> ", field);
      setFieldsData(field);
      setDateRange([field.arrival, field.departure]);
      setDays(field?.night);
    }

    if (count === 0) {
      fetchRoom();
    }
    // fetchRoomAvailibility();

    // form.resetFields();
    // let nightMare = [""];
    // for (let toy = 0; toy < countNight; toy++) nightMare.push("");
    // form.setFieldsValue({ room_modal: nightMare });
    if (is_reset == true && !cleanUp) {
      form.resetFields();
      is_rereset(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [field, is_reset]);

  useEffect(() => {
    let cleanUp = false;

    if (fieldsData?.arrival && fieldsData?.departure) {
      fetchRoomAvailibility();
    }

    if (fieldsData?.arrival && fieldsData?.departure) {
      // console.log(field, modalPick);
      setIsAlert(false);
    } else {
      setIsAlert(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [fieldsData?.arrival, fieldsData?.departure]);

  // Room Category
  const onChangeRoomCat = (value) => {
    const _values = value;
    const _index = value.form_index;

    console.log("Room Category: ", _index, _values);
    console.log("Avail >>> ", dataAvail);

    const fields = form.getFieldsValue();
    const { room_modal } = fields;

    console.log("Modals: ", room_modal);

    const selected = optionValue.filter((val) => {
      if (val.id != _values?.cat_id) {
        return val;
      }
    });

    const maxCap = dataAvail.filter((cat) => {
      if (cat.room_category_id_1 == _values.cat_id) {
        return cat;
      }
    });

    console.log("SELECTED => ", selected);
    console.log("Max Cap => ", maxCap);

    let selectedCap = maxCap.reduce((acc, cur) => {
      Object.keys(cur).some((key) => {
        if (key.includes("room_available_")) {
          if (acc > cur[key]) acc = cur[key];
        }
      });

      return acc;
    }, maxCap[0]?.room_available_1);

    Object.assign(room_modal[_index], {
      room_capacity: selectedCap,
      room_category_hide: value?.name,
      room_category: value?.name,
      room_code: value?.code,
      room_category_id: value?.cat_id,
      room_qty: 1,
    });

    form.setFieldsValue({ room_modal });
    roomRestrict({ room_modal: room_modal });
  };

  // Room Number
  const getRoomNumber = (value) => {
    const fields = form.getFieldsValue();
    const { room_modal } = fields;

    const _values = value;
    const _index = value?.form_index;

    console.log("Choose Room Number: ", _index, _values);

    Object.assign(room_modal[_index], {
      room_number: value?.room_id,
      room_no: value?.number,
      room_number_id: value?.id,
    });

    form.setFieldsValue({ room_modal });

    roomRestrict({ room_modal: room_modal });
  };

  // Room Qty
  const getRoomQty = (key, value) => {
    const fields = form.getFieldsValue();
    const { room_modal } = fields;

    Object.assign(room_modal[key], {
      room_qty: value,
    });

    form.setFieldsValue({ room_modal });

    // after all data received (wait for onChangeCat and onChangeCode Finish)
    roomRestrict({ room_modal: room_modal });
  };

  // On Finish
  const onFinish = async (value) => {
    const content = value?.room_modal?.length > 0 ? value.room_modal : [];
    console.log("Modal Room => ", content);

    // if (content?.length > 0) {
    //   let filter = content.filter((items) => {
    //     return !items.room_category;
    //   });

    //   console.log("Errors: ", filter);

    //   if (filter.length > 0) {
    //     console.log("Errors:");
    //   } else {
    setModalPick(content);
    setRoomCode(content);
    setRoom(content);

    handleCancel();
    //   }
    // } else {
    //   console.log("Errors:");
    // }
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    const fields = form.getFieldsValue();
    const { room_modal } = fields;

    setFieldsData(null);
    // setRoomTable([]);
    // setTotalRoomCat(0);
    // setCount(0);
    // form.resetFields();

    setIsLoading(true);

    if (room_modal?.length === 0) {
      setRoom([]);
      setRoomCode([]);
    }

    setOpen(false);
    modalClose(false);
  };

  const roomRestrict = async (e) => {
    const _rooms = e.room_modal;
    console.log("Restriction Rooms: ", _rooms);
    console.log("Data Rooms: ", fetchRoomData);
    // console.log(e.room_modal);

    setCode(_rooms);

    let arr = [];
    let qty = [];

    for (let i = 0; i < _rooms?.length; i++) {
      arr.push(_rooms[i]?.room_category_id);
      qty.push(_rooms[i]?.room_qty);
    }

    let filter = fetchRoomData.filter((val) => {
      // console.log(val);
      if (!arr.includes(val.id)) {
        return val;
      }
    });

    // console.log("Arr & QTY: ", arr);
    // console.log("Option Filtered >>>", filter);
    setOptionValue(filter);

    // Total Room
    let sum = qty.reduce(function (s, v) {
      return s + (v || 0);
    }, 0);

    console.log("SUM TOTAL ROOMS: ", sum);

    if (sum > 1) {
      setGroupState(true);
    } else {
      setGroupState(false);
    }
  };

  // CONSOLE
  // console.log(code);
  // console.log("is group ?", groupState);
  // console.log(optionValue);

  return (
    <>
      <Modal
        title={
          <>
            <Row className="title-row">
              <TbLayersIntersect
                className="title-icon"
                style={{ fontSize: 24, color: "#3699FF" }}
              />
              <Typography className="text-room-cat" style={{ marginLeft: 15 }}>
                {`Room Category Available`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        //   onOk={handleCancel}
        onCancel={handleCancel}
        width={1000}
        footer={null}
        // style={{ padding: 0 }}
      >
        <Form
          form={form}
          className="form-reserv-room-pick"
          onFinish={onFinish}
          layout="vertical"
          autoComplete="off"
          initialValues={{ room_category_hide: "DELUXE" }}
        >
          <Row className="row-item-select" style={{ padding: "10px 15px" }}>
            <Col span={24} className="col-item-select">
              <Form.List name="room_modal">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, ...restField }) => (
                        // {
                        //   console.log("KEY => ", key);
                        //   console.log("NAME => ", name);
                        //   console.log(...restField)
                        <Row
                          className={`room-modal-${key}`}
                          key={`${key}`}
                          gutter={30}
                        >
                          <Col span={6}>
                            <Form.Item
                              noStyle
                              name={[name, "room_category_hide"]}
                              hidden={true}
                            >
                              <Input disabled />
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              label="Room Category"
                              name={[name, "room_category"]}
                              //dependencies={[name, "room_category_hide"]}
                              style={{
                                width: "100%",
                                // margin: "0px 30px 0px 0px",
                              }}
                            >
                              <MasterRoomCat
                                onChangeRoomCat={onChangeRoomCat}
                                name={name}
                                disable={false}
                                options={optionValue}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item name={[name, "room_code"]}>
                              <Form.Item
                                {...restField}
                                label="Code"
                                name={[name, "room_code"]}
                              >
                                <Input disabled placeholder="Code" />
                              </Form.Item>
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              label="Room Number"
                              name={[name, "room_number"]}
                              // hasFeedback
                              // validateStatus="error"
                              // help="Room Number Choosed has reached its limit by Room Quantity!"
                              rules={[
                                // ({ getFieldValue }) => ({
                                {
                                  validator: async (_, value) => {
                                    const _value =
                                      code[name]?.room_number?.length > 0
                                        ? code[name]?.room_number?.length
                                        : 0;
                                    const _max =
                                      code[name]?.room_qty > 0
                                        ? code[name]?.room_qty
                                        : 0;

                                    // console.log(
                                    //   `Number of Max Room = ${code[name]?.room_qty} but Number of Room Picked =  ${code[name]?.room_number?.length}`
                                    // );

                                    console.log("Qty: ", _value, " > ", _max);

                                    // if (groupState) {
                                    // console.log("Is Group --> ", groupState);
                                    if (_max > 0) {
                                      if (_value > _max) {
                                        return Promise.reject(
                                          new Error(
                                            "Room Numbers are More than Room Quantity!"
                                          )
                                        );
                                      }
                                    } else {
                                      return Promise.reject(
                                        new Error(
                                          "Please, Choose a Room Category!"
                                        )
                                      );
                                    }
                                    // }
                                  },
                                },
                                // }),
                              ]}
                              style={{
                                width: "100%",
                                // margin: "0px 30px 0px 0px"
                              }}
                            >
                              {/* <Input placeholder="Room Number" /> */}
                              <MasterRoomNumber
                                name={name}
                                getRoomNumber={getRoomNumber}
                                room_category={
                                  code ? code[name]?.room_category_id : 0
                                }
                                isGroup={true}
                              />
                              {/* {console.log(
                                code ? code[name]?.room_number?.length : ""
                              )}
                              {console.log(code ? code[name]?.room_qty : "")} */}
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              label="Qty"
                              name={[name, "room_qty"]}
                              rules={[
                                // ({ getFieldValue }) => ({
                                {
                                  validator: async (_, value) => {
                                    const cap = code[name]?.room_capacity
                                      ? code[name].room_capacity
                                      : 0;
                                    // console.log(value, " > ", cap);
                                    // console.log(getFieldValue("room_qty"));

                                    if (value > cap)
                                      return Promise.reject(
                                        new Error(
                                          "Room Quantity has reached its limit by Room Category!"
                                        )
                                      );
                                  },
                                },
                                // }),
                              ]}
                            >
                              <InputNumber
                                min={1}
                                placeholder="Input Room Qty!"
                                // defaultValue={1}
                                // max={capacity ? capacity : 1}
                                style={{
                                  width: "100%",
                                  // margin: "0px 30px 0px 0px",
                                }}
                                onChange={(value) => {
                                  getRoomQty(name, value);
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Form.Item
                            // {...restField}
                            name={[name, "room_capacity"]}
                            hidden
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            name={[name, "room_no"]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            name={[name, "room_category_id"]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            noStyle
                            name={[name, "room_number_id"]}
                            hidden={true}
                          >
                            <Input />
                          </Form.Item>

                          <Col span={4}>
                            <Tooltip className="del-tip" title="Delete">
                              <DeleteFilled
                                color="#F64E60"
                                onClick={() => {
                                  setCount(count - 1);
                                  remove(name);
                                  // setGroupState(count > 0 ? true : false);
                                  const category = form.getFieldsValue();
                                  roomRestrict(category);
                                }}
                                style={{
                                  fontSize: 30,
                                  color: "#F64E60",
                                  margin: "30px 0px 0px",
                                }}
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      )
                      // }
                    )}

                    <Form.Item>
                      <Button
                        type="primary"
                        className="next-btn"
                        onClick={() => {
                          const category = form.getFieldsValue();

                          // console.log(add);
                          add();
                          setCount(count + 1);
                          roomRestrict(category);
                        }}
                        block
                        disabled={
                          count == totalRoomCat || isAlert == true
                            ? true
                            : false
                        }
                        loading={isLoading}
                        icon={<PlusOutlined />}
                        style={{ width: 200 }}
                      >
                        {`Add Rooms`}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>

            <Divider style={{ margin: "0px 0px 15px" }} />

            <Row
              key={"modal-room-btn"}
              className="modal-room-btn"
              justify="end"
              style={{ width: "100%" }}
            >
              <Form.Item>
                <Button
                  className="submit-btn"
                  htmlType="submit"
                  type="primary"
                  disabled={count > 0 ? false : true}
                  style={{
                    // color: "#FFFFFF",
                    backgroundColor: "#1BC5BD",
                    borderColor: "#1BC5BD",
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  onClick={handleCancel}
                  style={{ marginLeft: 15 }}
                >
                  {`Cancel`}
                </Button>
              </Form.Item>
            </Row>

            <Row justify="center" style={{ width: "100%" }}>
              <Col span={24}>
                {isAlert == true ? (
                  <Alert
                    message="Error"
                    description="Please, add the Arrival Date & Departure Date before choosing the Room Category!"
                    type="error"
                    showIcon
                    style={{
                      margin: "0px 0px 10px",
                    }}
                  />
                ) : (
                  <Typography
                    style={{
                      padding: "0px 5px 5px",
                      fontSize: 11,
                      fontWeight: 750,
                    }}
                  >
                    Date:
                    {`${dateRange[0]?.format(
                      "DD MMM YYYY"
                    )} - ${dateRange[1]?.format("DD MMM YYYY")}`}
                  </Typography>
                )}
              </Col>
            </Row>

            <RoomAvailabilityTable
              onForm={true}
              dataRoom={open == true ? roomTable : []}
              totalCat={totalRoomCat}
              date_avail={dateRange}
              data_avail={setDataAvail}
              totalDays={days}
              is_load={setIsLoading}
            />
          </Row>
        </Form>
      </Modal>
    </>
  );
}

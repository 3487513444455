// Import React Components
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { user_level } from "../../API/Global/Payload";

// Import Ant Design Components
import { Col, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Page Components
import RoomSoldCard from "../../Components/Widgets/Dashboard/RoomSold";
import OccupancyCard from "../../Components/Widgets/Dashboard/Occupancy";
import AverageRoomRateCard from "../../Components/Widgets/Dashboard/AverageRoom";
import PaymentCard from "../../Components/Widgets/Dashboard/Payment";
import RevenueCard from "../../Components/Widgets/Dashboard/Revenue";
import ParamSetter from "../../Components/Reusable/Functions/ParamSetter/ParamSetter";
import WidgetToDo from "../../Components/Widgets/Task/Approval";

// Import Gloval Styles
import "../../Style/Global/Global.css";

// STYLING
const style = {
  background: "#FFFFFF",
  marginRight: "30px",
  height: "100%",
};

// CODE
export default function DashboardPage() {
  const params = sessionStorage.getItem("par-1");
  const auth = sessionStorage.getItem("auth-token");

  if (!params && auth) {
    const params = ParamSetter();

    console.log(params);
  }

  return (
    <>
      <Row
        justify="space-evenly"
        gutter={[30, 30]}
        className="row-dashboard"
        style={{
          marginBottom: 30,
        }}
      >
        <Col
          className="room-col"
          xxl={8}
          // xxl={8}
          xl={12}
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          {/* <div style={style}>Menu 1</div> */}
          <RoomSoldCard />
        </Col>

        <Col
          className="occ-col"
          xxl={8}
          xl={12}
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          {/* <div style={style}>Menu 2</div> */}
          <OccupancyCard />
        </Col>

        <Col
          className="rate-col"
          xxl={8}
          xl={12}
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          <AverageRoomRateCard />
          {/* <Row
          justify="center"
          style={{
            backgroundColor: "#FFFFFF",
            marginBottom: 30,
            height: 500,
          }}
        > */}
          {/* Menu 3 */}
          {/* </Row> */}

          {/* <Row
          justify="center"
          style={{ backgroundColor: "#FFFFFF", height: 500 }}
        >
          Menu 4
        </Row> */}
        </Col>
      </Row>

      <Row justify="space-evenly" gutter={[30, 30]} className="row-dashboard">
        <Col xxl={16} md={24} sm={24} xs={24} style={{ height: "100%" }}>
          <RevenueCard />
        </Col>

        <Col xxl={8} md={24} style={{ height: "100%" }}>
          <PaymentCard />
        </Col>
      </Row>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  userName,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";

// Modal Confirm
const { confirm } = Modal;

const dataSource = [
  {
    key: "1",
    name: "John",
  },
];

// CODE
export default function ArrangementArticleTable({ dataArrangement }) {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-art-sum-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      // setDataEdit(record);
                      // form.setFieldsValue({})
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article",
      dataIndex: "article",
      key: "article",
      fixed: "left",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article.localeCompare(b.article),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.tax - b.tax,
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.service - b.service,
    },
  ];

  // CONTEXT
  const { state } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(true);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = () => {
    setLoading(true);

    setSelected(dataArrangement[0]);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataArrangement?.length > 0 && !cleanUp) {
      console.log("Arrangement: ", dataArrangement);
      fetchData();
    } else {
      setSelected(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataArrangement]);

  // Modal Open Set State
  const handleOpen = () => {
    console.log(selected);
    setOpen(true);
    setIsDelete(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setIsDelete(true);
    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const contentDelete = value;

    confirm({
      className: "delete-guest-confirm",
      title: isDelete
        ? `Are you sure want to delete article arrangement ${
            contentDelete?.arrangement_name.toUpperCase() || "......."
          } from the table?`
        : `Are you sure want to ${
            edit ? "Update " : "Add a New "
          } Article Arrangement named ${
            contentDelete?.arrangement_name.toUpperCase() || "......."
          }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);

    setIsLoading(true);

    showModalConfirm(e);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    const _incomplete = e;

    masterIncomplete(_incomplete);
  };

  // console.log("In Arrangement Article => ", dataArrangement);

  return (
    <>
      <Row
        className="arrangement-article-main-table"
        justify="center"
        style={mainBody}
      >
        <Col
          className="col-table"
          span={24}
          style={{
            padding: 30,
            backgroundColor: "#FFFFFF",
            minHeight: "100%",
          }}
        >
          <Row justify="start" gutter={[0, 15]} className="arr-row" align="top">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="arr-col"
            >
              <Typography
                key={"rate-code-desc"}
                style={{ fontWeight: 600, fontSize: 20, color: "#3699FF" }}
              >
                {`Arrangement: ${
                  dataArrangement?.length > 0
                    ? dataArrangement[0].arrangement_name.toUpperCase()
                    : " - "
                }`}
              </Typography>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="btn-col"
            >
              <Row justify="end">
                <Button
                  className="submit-btn"
                  type="primary"
                  icon={<PlusOutlined />}
                  disabled={selected === null ? true : false}
                  // style={{
                  //   margin: "0px 0px 0px 15px",
                  // }}
                  onClick={handleOpen}
                >
                  {`Add Article`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="arrangement-article-table"
              name="arrangement-article-table"
              key="arrangement-article-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              size="small"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="arrangement-modal"
        key="arrangement-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Arrangement Article`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Arrangement Article`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          name="arrangement-art-modal-form"
          className="arrangement-art-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-arrangement-id">
            <Col span={8}>
              <Form.Item
                label="Article Name"
                name="arrangement_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input a Code!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Article Code" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Price" name="arrangement_price">
                <InputNumber placeholder="Price" style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Tax" name="arrangement_tax">
                <InputNumber placeholder="Tax" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-arrangement-srv">
            <Form.Item label="Service" name="service">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-arrangement-art">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-arrangement-art">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row className="row-modal-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React from "react";

// Tree Item
import { dataPassiva } from "./TreeItem";

// Import Ant Design Components
import { Col, Row, Tree } from "antd";

// Import React Icons

export default function TreePassiva() {
  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  return (
    <>
      <Row
        className="row-passiva"
        justify="start"
        style={{
          width: "100%",
          minHeight: 900,
          borderRadius: "0px 0px 5px 5px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col
          span={24}
          className="col-passiva"
          style={{
            // padding: 30,
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          <Tree
            className="passiva-tree"
            showLine
            showIcon={false}
            defaultExpandedKeys={["0-0-0", "0-1-0", "0-1-3", "0-0-5", "0-2-0"]}
            onSelect={onSelect}
            treeData={dataPassiva}
            b
            style={{ padding: 30, width: "100%" }}
          />
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import axios from "axios";

// BASE API
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// CODE
export const PrintCount = (data) => {
  console.log("Data in Functions: ", data);

  const _data = data.data;
  const _update = data.is_update;
  const _list = data.counter_list;

  console.log("Counters: ", _list);

  let guarantee_letter =
    0 + (_list?.guarantee_letter > 0 ? _list.guarantee_letter : 0);
  let registration_form =
    0 + (_list?.registration_form > 0 ? _list.registration_form : 0);
  let pre_bill = 0 + (_list?.pre_bill > 0 ? _list.pre_bill : 0);
  let thankyou_letter =
    0 + (_list?.thankyou_letter > 0 ? _list.thankyou_letter : 0);
  let reservation_letter =
    0 + (_list?.reservation_letter > 0 ? _list.reservation_letter : 0);
  let checkout_letter_history =
    0 +
    (_list?.checkout_letter_history > 0 ? _list.checkout_letter_history : 0);
  let bill = 0 + (_list?.bill > 0 ? _list.bill : 0);
  let master_bill = 0 + (_list?.master_bill > 0 ? _list.master_bill : 0);
  let pre_master_bill =
    0 + (_list?.pre_master_bill > 0 ? _list.pre_master_bill : 0);

  // Handle Update
  const handleUpdate = async () => {
    await axios
      .post(
        `${baseurl}/count/print`,
        {
          reservation_id: _data.reservation_id,
          price_id: _data.price_id,
          guest_id: _data.guest_id,
          guarantee_letter: guarantee_letter,
          registration_form: registration_form,
          pre_bill: pre_bill,
          thankyou_letter: thankyou_letter,
          reservation_letter: reservation_letter,
          checkout_letter_history: checkout_letter_history,
          bill: bill,
          created_by: user_name,
          master_bill: master_bill,
          pre_master_bill: pre_master_bill,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Response Success Update Print Count: ", response);
      })
      .catch((error) => {
        console.log("Error Update Print Count: ", error);
      });
  };

  const handleCreate = async () => {
    await axios
      .post(
        `${baseurl}/count/print`,
        {
          reservation_id: _data.reservation_id,
          price_id: _data.price_id,
          guest_id: _data.guest_id,
          guarantee_letter: 0,
          registration_form: 0,
          pre_bill: 0,
          thankyou_letter: 0,
          reservation_letter: 0,
          checkout_letter_history: 0,
          bill: 0,
          master_bill: 0,
          pre_master_bill: 0,
          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Success Add Print Count: ", response);
      })
      .catch((error) => {
        console.log("Error Add Print Count: ", error);
      });
  };

  switch (_update) {
    case true:
      handleUpdate();
      break;

    default:
      handleCreate();
      break;
  }
};

// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import axios from "axios";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// REACT ICONS
import { MdOutlineWorkHistory } from "react-icons/md";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";

// GLOBAL API
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// Import Page Components
import ModalSupplierTurnoverArticle from "../SupplierTurnoverArticle/Modal";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../../Reusable/Notification/Notification";

export default function ModalSupplierTurnover(props) {
  // PROPS
  const { is_open, is_close, dataVendor, params_acc } = props;

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [detailArticle, setDetailArticle] = useState([]);

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  const [detail, setDetail] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Tax
  const _tax = params_acc?.tax_percentage / 100;

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open && !cleanUp) {
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  useEffect(() => {
    let cleanUp = false;

    if (dataVendor && !cleanUp) {
      console.log("DATA SUPPLIER > ", dataVendor);

      handleFormField(dataVendor);
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [dataVendor]);

  // FETCH DATA
  const fetchData = async () => {
    setIsLoading(true);

    await axios
      .post(
        `${baseurl}/supplier-managament-systems/supplier-turnover`,
        { id_supplier: dataVendor.id_supplier },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Supplier Turnover ", response);
        if (response?.data.msg != null) {
          let filterData = response.data.msg.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          setData(filterData);
          setBulks(filterData);
        } else {
          setData([]);
          setBulks([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setIsLoading(false);
      });
  };

  // HANDLE CLOSE MODAL
  const handleClose = () => {
    is_close(false);
    setModalOpen(false);

    setIsLoading(false);
    setData([]);
  };

  // HANDLE OPEN MODAL
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE CLICK DETAIL
  const handleClickDetail = (record) => {
    setDetail(true);
    setDetailArticle(record?.details);
  };

  // HANDLE FORM FIELD
  const handleFormField = (value) => {
    const _record = value;

    form.setFieldsValue({
      supplier_code: _record.supplier_code,
      supplier_name: _record.supplier_name,
    });
  };

  // Columns
  const columns = [
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
    },
    {
      title: "Purchase Order Number",
      dataIndex: "po_number",
      key: "po_number",
    },
    {
      title: "Purchase Request Number",
      dataIndex: "pr_number",
      key: "pr_number",
    },
    {
      title: `Turnover Nominal ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      render: (unit_price, record) => {
        return `${unit_price}*`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Articles",
      dataIndex: "details",
      key: "details",
      render: (_, articles) => {
        return (
          <Button
            className="next-btn"
            onClick={() => {
              console.log("Details: ", articles);
              handleClickDetail(articles);
            }}
            type="primary"
          >{`Detail`}</Button>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        className="vendor-management-modal"
        key="vendor-management-modal"
        title={
          <Row className="modal-title-row" justify="start" align="middle">
            <MdOutlineWorkHistory
              className="modal-icon"
              style={{
                color: "#3699FF",
                fontSize: 24,
              }}
            />
            <Typography
              className="modal-txt"
              style={{ marginLeft: 15 }}
            >{`Supplier Turnover`}</Typography>
          </Row>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={1000}
      >
        <Form
          className="vms-form"
          key="vms-form"
          name="vms-form"
          layout="vertical"
          form={form}
          style={{ padding: "0px 36px" }}
        >
          <Row className="info-row" gutter={30}>
            <Col span={12}>
              <Form.Item
                label="Supplier Code"
                name="supplier_code"
                rules={[
                  {
                    required: true,
                    message: "Please, select a Supplier Code!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Supplier Name"
                name="supplier_name"
                rules={[
                  {
                    required: true,
                    message: "Please, select a Supplier Name!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider
          className="divider-form"
          orientation="left"
          orientationMargin={0}
          style={{ margin: "5px 0px", backgroundColor: "#EBEDF3" }}
        />

        <Row className="table-main-row" justify="start" style={mainBody}>
          <Col span={24} style={{ padding: 30 }}>
            <Row style={{ width: "100%" }}>
              <Table
                className="vendor-management-table"
                name="vendor-management-table"
                key="vendor-management-table"
                loading={isLoading}
                bordered
                columns={columns}
                dataSource={data}
                pagination={{
                  defaultPageSize: 5,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} entries`,
                }}
                scroll={{
                  x: 750,
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
                rowKey={(record) => (record.id_po ? record.id_po : record.key)}
              />
            </Row>
          </Col>
        </Row>

        <Typography className="text-notes" style={{ fontSize: 12 }}>
          {`* Prices before VAT`}
        </Typography>
      </Modal>

      <ModalSupplierTurnoverArticle
        is_open={detail}
        is_close={setDetail}
        dataArticle={detailArticle}
        params_acc={params_acc}
      />
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import {
  CheckCircleFilled,
  CloseCircleFilled,
  FileExcelFilled,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Table, Tag, Tooltip } from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as InventoryContext } from "../../../../../../API/Context/InventoryContext/InventoryContext";

// PAGE COMPONENTS
import StockTransferRequestReceivingDetailModal from "../../../../../Modals/Inventory/StockTransferRequestReceivingDetail/Modal";
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

export default function StockTransferRequestReceivingTable(props) {
  // Props
  const { is_search, searchKey, postingDate } = props;

  // Context
  const { getStockTransferRequestList } = useContext(InventoryContext);

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);

  const [dataReceive, setDataReceive] = useState(null);
  const [modalDetailOpen, setModalDetailOpen] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT FETCH DATA
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, postingDate]);

  // FETCH DATA
  const fetchData = async () => {
    await getStockTransferRequestList({
      type: "transfer-request-stocks",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("TRANSFER/REQUEST RECEIVING RESPONSE => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return (
              !filt.is_deleted &&
              !filt.approved_list.includes(0) &&
              !filt.is_received
            );
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    setRefresh(false);
  };

  // HANDLE EXPORT EXCEL
  const handleExport = () => {
    downloadExcel(bulks, "Stock Transfer/Request Receiving List");
  };

  // HANDLE RETURN TO MENU INVENTORY
  const handleReturn = () => {
    const path = "/back-office/inventory";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    const date =
      postingDate != "Invalid date" && postingDate != null
        ? moment(postingDate).format("YYYY-MM-DD")
        : null;

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        const docNumber = item?.document_number?.toLowerCase();
        const postDate = moment(item?.created_date).format("YYYY-MM-DD");

        return docNumber.indexOf(query) !== -1 || moment(date).isSame(postDate);
      });

      // console.log(updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE OPEN
  const handleOpenDetail = () => {
    setModalDetailOpen(true);
  };

  // HANDLE CLOSE
  const handleCloseDetail = () => {
    setModalDetailOpen(false);
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    fetchData();
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      align: "center",
      width: 110,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              style={{ width: "100%" }}
              justify="center"
            >
              <>
                <Col span={12} className="col-edit">
                  <Tooltip className="edit-tooltip" title="Edit Receive Data">
                    <FiEdit
                      className="edit-btn"
                      onClick={() => {
                        handleOpenDetail();
                        setDataReceive(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
              </>
            </Row>
          </>
        );
      },
    },
    {
      title: "Document Number",
      dataIndex: "document_number",
      key: "document_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.document_number.localeCompare(b.document_number),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.department_name.localeCompare(b.department_name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Type of Moving",
      dataIndex: "transfer_type",
      key: "transfer_type",
    },
    {
      title: "Posting Date",
      dataIndex: "created_date",
      key: "created_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
      render: (date) => {
        return date !== null || date !== "Invalid date"
          ? moment(date).format("YYYY-MM-DD")
          : "-";
      },
    },
    {
      title: "From Store",
      dataIndex: "initial_warehouse",
      key: "initial_warehouse",
    },
    {
      title: "To Store",
      dataIndex: "destination_warehouse",
      key: "destination_warehouse",
    },
    {
      title: "Approval Status",
      dataIndex: "approved_list",
      key: "approved_list",
      width: 150,
      render: (approval) => {
        if (approval?.length > 0) {
          let _tags = approval.map((value, index) => {
            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {`Approve ${index + 1}`}
                </Tag>
              );
            } else if (value === 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`Rejected ${index + 1}`}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
        }
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approved_date",
      key: "approved_date",
      width: 250,
      render: (date, record) => {
        if (date?.length > 0) {
          let _tags = date.map((value, index) => {
            if (value !== "-") {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {moment(value).format("DD-MM-YYYY, h:mm:ss a")}
                </Tag>
              );
            } else {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`-`}
                </Tag>
              );
            }
          });

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`-`}</Tag>;
        }
      },
    },
    {
      title: "Receive Status",
      dataIndex: "is_received",
      key: "is_received",
      render: (is_received) => {
        return is_received ? (
          <Tag color="green">Received</Tag>
        ) : (
          <Tag color="red">Need to Receive</Tag>
        );
      },
    },
    {
      title: "Received By",
      dataIndex: "received_by",
      key: "received_by",
    },
  ];

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="transfer-request-table"
              name="transfer-request-table"
              key="transfer-request-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1850,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.document_number}
            />
          </Row>
        </Col>
      </Row>
      <StockTransferRequestReceivingDetailModal
        is_open={modalDetailOpen}
        is_close={handleCloseDetail}
        dataReceive={dataReceive}
        is_refresh={handleRefresh}
      />
    </>
  );
}

import { Button, Col, Row, Select, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Context as InventoryContext } from "../../../../API/Context/InventoryContext/InventoryContext";

// CODE
export default function StockTransferRequestList(props) {
  // PROPS
  const { selectedItem, transferRequestType, is_disable } = props;

  // CONTEXT
  const { getStockTransferRequestType } = useContext(InventoryContext);

  const [transferRequest, setTransferRequest] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (transferRequestType && !cleanUp) {
      setTransferRequest(transferRequestType);
    } else {
      setTransferRequest(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [transferRequestType]);

  // FETCH TRANSFER/REQUEST LIST
  const fetchData = async () => {
    await getStockTransferRequestType({
      type: "transfer-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("RESPONSE TRANSFER/REQUEST TYPE=> ", response);

        if (response?.data.msg != null) {
          const filter = response.data.msg.map((item) => {
            return {
              key: item.id,
              label: item.name,
              value: item.name,
              id: item.id,
              name: item.name,
            };
          });

          setOptionValue(filter);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // Handle Change
  const handleChange = (e, i) => {
    const _value = e;
    const _allKey = i;

    console.log("Allkeys: ", _allKey);

    setTransferRequest(_value);
    selectedItem({
      id: _allKey?.id,
      name: _allKey?.name,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setTransferRequest(null);
  };

  return (
    <Row className="master-row" align="top" justify="start" gutter={0}>
      <Col span={24} className="select-col">
        <Select
          className="master-transfer-request"
          key={optionValue.key}
          style={{ width: "100%" }}
          value={transferRequest}
          placeholder="Transfer/Request"
          showSearch
          allowClear
          disabled={is_disable}
          onChange={handleChange}
          onClear={handleClear}
          options={optionValue}
        />
      </Col>

      {/* <Col span={2} className="select-col">
        <Tooltip
          className="redirect-tooltip"
          title={"Redirect to Master Transfer/Request"}
        >
          <Button
            className="master-btn"
            icon={
              <AppstoreFilled
                onClick={move}
                style={{ fontSize: 20, color: "#3699FF" }}
              />
            }
          />
        </Tooltip>
      </Col> */}
    </Row>
  );
}

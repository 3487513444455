// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { Form, Table, Typography } from "antd";
import { PriceTax } from "../../../../../Reusable/Functions/PriceTax/PriceTax";

export default function POTableDetailModal(props) {
  // PROPS
  const { dataDetail, params_acc } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);
  const [totalPrice, setTotalPrice] = useState({
    sub_total: 0,
    vat_total: 0,
    total: 0,
  });

  // USE FORM
  const [form] = Form.useForm();

  // Tax
  const _tax = params_acc?.tax_percentage / 100;

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (dataDetail?.length > 0 && !cleanUp) {
      console.log("Data Detail PO : ", dataDetail);
      setData(dataDetail);
      handleTotal(dataDetail);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail]);

  // HANDLE TOTAL
  const handleTotal = (val) => {
    const _arr = val;

    console.log("ARR Article: ", _arr);
    console.log("Tax: ", _tax);
    let total_tax = PriceTax({
      arr_article: _arr,
      tax_percent: _tax,
    });

    console.log("Price: ", total_tax);

    setTotalPrice({
      sub_total: total_tax.sub_total,
      vat_total: total_tax.vat_total,
      total: total_tax.total,
    });
  };

  // Columns
  // const columns = [
  //   {
  //     title: "No",
  //     dataIndex: "no",
  //     key: "no",
  //     width: 60,
  //     render: (value, record, index) => (page - 1) * paginationSize + index + 1,
  //   },
  //   {
  //     title: "Article Name",
  //     dataIndex: "article_name",
  //     key: "article_name",
  //   },
  //   {
  //     title: "Supplier",
  //     dataIndex: "supplier_name",
  //     key: "supplier_name",
  //   },
  //   {
  //     title: "Qty",
  //     dataIndex: "quantity",
  //     key: "quantity",
  //     width: 100,
  //     editable: true,
  //     render: (qty) => {
  //       return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  //     },
  //   },
  //   // {
  //   //   title: "Requested Qty",
  //   //   dataIndex: "remaining_quantity_dummy",
  //   //   key: "remaining_quantity_dummy",
  //   //   width: 100,
  //   //   render: (amount) => {
  //   //     return amount > 0
  //   //       ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //   //       : 0;
  //   //   },
  //   // },
  //   {
  //     title: "Price (Rp)",
  //     dataIndex: "unit_price",
  //     key: "unit_price",
  //     width: 100,
  //     render: (amount, record) => {
  //       let _suppliers =
  //         record?.supplier?.length > 0 ? record.supplier[0] : null;
  //       let _ppn = _suppliers ? amount * _tax : 0;
  //       let _price = amount + "*";
  //       let _is_ppn = _suppliers?.is_ppn === true ? true : false;

  //       switch (_is_ppn) {
  //         case true:
  //           _price = _ppn + amount + "**";
  //           break;

  //         default:
  //           break;
  //       }

  //       return amount > 0
  //         ? `${_is_ppn ? amount + "**" : amount + "*"}`.replace(
  //             /\B(?=(\d{3})+(?!\d))/g,
  //             ","
  //           )
  //         : 0;
  //     },
  //   },
  //   {
  //     title: "Amount (Rp)",
  //     dataIndex: "total_price",
  //     key: "total_price",
  //     render: (amount, record) => {
  //       let _suppliers =
  //         record?.supplier?.length > 0 ? record.supplier[0] : null;
  //       let _ppn = _suppliers ? amount * _tax : 0;
  //       let _price = amount + "*";
  //       let _is_ppn = _suppliers?.is_ppn === true ? true : false;

  //       switch (_is_ppn) {
  //         case true:
  //           _price = _ppn + amount + "**";
  //           break;

  //         default:
  //           break;
  //       }

  //       return amount > 0
  //         ? `${_is_ppn ? amount + "**" : amount + "*"}`.replace(
  //             /\B(?=(\d{3})+(?!\d))/g,
  //             ","
  //           )
  //         : 0;
  //     },
  //   },
  //   // {
  //   //   title: "Delivery Date",
  //   //   dataIndex: "delivery_date",
  //   //   key: "delivery_date",
  //   //   render: (e) => moment(e).format("YYYY-MM-DD"),
  //   // },
  //   // {
  //   //   title: "Credit Term Date",
  //   //   dataIndex: "credit_term_date",
  //   //   key: "credit_term_date",
  //   //   render: (e) => moment(e).format("YYYY-MM-DD"),
  //   // },
  //   // {
  //   //   title: "Item Desc",
  //   //   dataIndex: "description",
  //   //   key: "description",
  //   // },
  // ];

  const column = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Qty Order",
      dataIndex: "quantity_order",
      key: "quantity_order",
    },
    {
      title: "Qty Remaining",
      dataIndex: "si_quantity_remaining",
      key: "si_quantity_remaining",
    },
    {
      title: "Delivery Unit",
      dataIndex: "d-unit",
      key: "d-unit",
    },
    {
      title: "Unit Price (Rp.)",
      dataIndex: "unit_price",
      key: "unit_price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        let _ppn = price * _tax;
        let _price = price + "*";
        let _is_ppn = record?.is_ppn ? "**" : "*";

        switch (record.is_ppn) {
          case true:
            _price = _ppn + price + "**";
            break;

          default:
            break;
        }

        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + _is_ppn;
      },
    },
    {
      title: "Total Price (Rp.)",
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      render: (total, record) => {
        let _ppn = total * _tax;
        let _price = total + "*";

        switch (record.is_ppn) {
          case true:
            _price = _ppn + total + "**";
            break;

          default:
            break;
        }

        return `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Item Desc",
      dataIndex: "description",
      key: "description",
      render: (desc, record) => (desc ? desc : "-"),
    },
  ];

  return (
    <>
      <Form form={form} component={false}>
        <Table
          className="pr-detail-table"
          name="pr-detail-table"
          key="pr-detail-table"
          columns={column}
          dataSource={data}
          bordered
          size="small"
          pagination={false}
          scroll={{
            x: true,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => record.id_article}
          summary={() => {
            return (
              <>
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={6} />

                    <Table.Summary.Cell index={3}>
                      <b style={{ fontSize: 12 }}>{`Sub-Total`}</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={4}>
                      <b style={{ fontSize: 12 }}>
                        {`Rp. ${totalPrice.sub_total}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={6} />

                    <Table.Summary.Cell index={3}>
                      <b style={{ fontSize: 12 }}>{`VAT`}</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={4}>
                      <b style={{ fontSize: 12 }}>
                        {`Rp. ${totalPrice.vat_total}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={6} />

                    <Table.Summary.Cell index={3}>
                      <b style={{ fontSize: 12 }}>{`Grand Total`}</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={4}>
                      <b style={{ fontSize: 12 }}>
                        {`Rp. ${totalPrice.total}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              </>
            );
          }}
        />

        <Typography className="text-notes" style={{ fontSize: 12 }}>
          {`* Prices are not subject to Tax`}
        </Typography>
        <Typography className="text-notes" style={{ fontSize: 12 }}>
          {`** Prices are subject to VAT (PPN ${params_acc?.tax_percentage}%)`}
        </Typography>
      </Form>
    </>
  );
}

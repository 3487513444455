// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import axios from "axios";

// ANTD COMPONENTS
import { PlusCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";

// PAGES COMPONENTS
import MasterCOA from "../../../Reusable/Accounting/MainAccount/MainAccount";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// GLOBAL API
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

const { confirm } = Modal;

export default function ModalWarehouse(props) {
  const {
    openModal,
    closeModal,
    dataWarehouse,
    is_edit,
    set_edit,
    is_refresh,
  } = props;

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS OPEN MODAL
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // USE EFFECT EDIT
  useEffect(() => {
    let cleanUp = false;

    if (is_edit === true && !cleanUp && dataWarehouse !== null) {
      setFormFields(dataWarehouse);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_edit, dataWarehouse]);

  // ON FINISH
  const onFinish = (e) => {
    const _submit = e;
    console.log(_submit);

    setIsLoading(true);
    showModalConfirm(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    console.log(value);
    masterIncomplete(value);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "add-update-warehouse-confirm",
      title: `Are you sure want to ${is_edit == true ? "Update" : "Add a New"}
      warehouse named ${_data?.warehouse_name.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    if (is_edit == false) {
      await axios
        .post(
          `${baseurl}/master/warehouse`,
          {
            warehouse_code: value?.warehouse_code ? value.warehouse_code : "",
            warehouse_name: value?.warehouse_name ? value.warehouse_name : "",
            warehouse_location: value?.warehouse_location
              ? value.warehouse_location
              : "",
            is_kitchen: value?.is_kitchen ? value.is_kitchen : false,
            description: value?.description ? value.description : "",
            chart_of_account: value?.chart_of_account
              ? value.chart_of_account
              : null,
            created_by: user_name,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then((response) => {
          masterSuccessNotification(response, {
            method: 0,
            source: "Warehouse",
          });

          handleCancel();
          is_refresh();
          setIsLoading(false);
        })
        .catch((error) => {
          masterFailedNotification(error, { method: 0, source: "Warehouse" });
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/warehouse`,
          {
            id_warehouse: dataWarehouse.id_warehouse,
            warehouse_code: value?.warehouse_code ? value.warehouse_code : "",
            warehouse_name: value?.warehouse_name ? value.warehouse_name : "",
            warehouse_location: value?.warehouse_location
              ? value.warehouse_location
              : "",
            is_kitchen: value?.is_kitchen ? value.is_kitchen : false,
            description: value?.description ? value.description : "",
            chart_of_account: value?.chart_of_account
              ? value.chart_of_account
              : null,
            updated_by: user_name,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then((response) => {
          console.log("UPDATE  => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Warehouse",
          });

          is_refresh();
          handleCancel();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("UPDATE  => ", error);

          masterFailedNotification(error, { method: 1, source: "Warehouse" });
          setIsLoading(false);
        });
    }
  };

  // CLOSE MODAL
  const handleCancel = () => {
    closeModal(false);
    setOpen(false);

    set_edit(false);

    setIsLoading(false);

    form.resetFields();
  };

  // GET SELECTED ITEM
  const getSelectedItem = (value) => {
    form.setFieldsValue({
      chart_of_account: value?.code,
    });
  };

  // Set fields
  const setFormFields = (value) => {
    form.setFieldsValue({
      warehouse_code: value?.warehouse_code ? value.warehouse_code : "",
      warehouse_name: value?.warehouse_name ? value.warehouse_name : "",
      warehouse_location: value?.warehouse_location
        ? value.warehouse_location
        : "",
      description: value?.description ? value.description : "",
      is_kitchen: value?.is_kitchen ? value.is_kitchen : false,
      chart_of_account: value?.chart_of_account ? value.chart_of_account : null,
      created_by: value?.created_by ? value.created_by : null,
      created_date: value?.created_date
        ? moment(value.created_date).format("DD MMM YYYY")
        : null,
      updated_by: value?.updated_by ? value.updated_by : null,
      updated_date: value?.updated_date
        ? moment(value.updated_date).format("DD MMM YYYY")
        : null,
    });
  };

  return (
    <Modal
      className="add-edit-warehouse-modal"
      title={
        is_edit == false ? (
          <Row>
            <PlusCircleTwoTone style={{ fontSize: 24 }} />
            <Typography
              style={{ marginLeft: 15 }}
            >{`Add Warehouse`}</Typography>
          </Row>
        ) : (
          <Row>
            <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography
              style={{ marginLeft: 15 }}
            >{`Edit Warehouse`}</Typography>
          </Row>
        )
      }
      centered
      open={open}
      onCancel={handleCancel}
      width={800}
      bodyStyle={{
        borderRadius: 30,
      }}
      footer={null}
    >
      <Form
        name="add-warehouse-modal-form"
        className="add-warehouse-modal-form"
        autoComplete="off"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ padding: "0px 36px" }}
      >
        <Row gutter={30} className="row-warehouse-id">
          <Col span={12}>
            <Form.Item
              label="Warehouse Code"
              name="warehouse_code"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input Warehouse Code!",
                },
              ]}
            >
              <Input placeholder="Warehouse Code" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Warehouse Name"
              name="warehouse_name"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input Warehouse Name!",
                },
              ]}
            >
              <Input placeholder="Warehouse Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={30} className="row-warehouse-location">
          <Col span={12}>
            <Form.Item
              label="Warehouse Location"
              name="warehouse_location"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please, Input Warehouse Location!",
                },
              ]}
            >
              <Input placeholder="Warehouse Location" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Kitchen Warehouse"
              valuePropName="checked"
              name={"is_kitchen"}
            >
              <Checkbox>Is Kitchen</Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Chart Of Account"
              name="chart_of_account"
              style={{ width: "100%" }}
              hidden
              // rules={[
              //   {
              //     required: true,
              //     message: "Please, Input Chart Of Account!",
              //   },
              // ]}
            >
              <MasterCOA
                coa_name={is_edit ? dataWarehouse?.chart_of_account : null}
                disable={false}
                getCoaName={getSelectedItem}
                is_all={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="row-warehouse-desc">
          <Col span={24} className="desc-col">
            <Form.Item label="Description" name="description">
              <Input.TextArea
                showCount
                allowClear
                maxLength={200}
                rows={4}
                placeholder="Description"
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider
          className="form-divider"
          style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
        />

        {is_edit == true ? (
          <>
            <Row gutter={30} className="created-date-warehouse">
              <Col span={8}>
                <Form.Item
                  label="Created By"
                  name="created_by"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Created Date"
                  name="created_date"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Updated By"
                  name="updated_by"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mod-date-warehouse">
              <Col span={8}>
                <Form.Item
                  label="Updated Date"
                  name="updated_date"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />
          </>
        ) : null}

        <Row className="row-modal-btn" justify="end" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            key={"submit"}
            loading={isLoading}
            className="submit-btn"
            style={{
              marginRight: 15,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={handleCancel}>
            {`Cancel`}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

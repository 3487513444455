// Import React Components
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";

// Import Page Report
import ReportOutletPrint from "../../../PrintLayout/Outlet/ReportLayout";
import { BsIntersect } from "react-icons/bs";

// Import Page Components

// CODE
export default function ModalReportOutlet({ openModal, closeModal }) {
  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  // Data Guest About to Edit
  //   const [edit, setEdit] = useState({
  //     full_name: "",
  //     first_name: "",
  //     id_card_type: "",
  //     id_card_number: 0,
  //     phone_number: "",
  //     birthdate: "",
  //     nationality: "",
  //     country: "",
  //     province: "",
  //     local_region: "",
  //     city: "",
  //     zip_code: 0,
  //     address: "",
  //     sex: "",
  //     email: "",
  //     fax: "",
  //     main_segment: "",
  //     comment: "",
  //     payment_type: "",
  //     credit_limit: 0,
  //     master_comp: "",
  //     is_dummy: true,
  //   });

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  // HANDLE CHANGE INPUT
  //   const handleChange = (event) => {
  //     event.preventDefault();
  //     let { id, value } = event.target;

  //     // console.log("HANDLE CHANGE===>: ", event.target);

  //     setEdit({ ...edit, [id]: value });
  //     // setEdit({ ...event.target });
  //   };

  // HANDLE SHOW
  //   if (open) {
  //     form.setFieldsValue({
  //       full_name: edit.full_name,
  //       first_name: edit.first_name,
  //       id_card_type: edit.id_card_type,
  //       id_card_number: edit.id_card_number,
  //       phone_number: edit.phone_number,
  //       birthdate: moment(edit.birthdate),
  //       nationality: edit.nationality,
  //       country: edit.country,
  //       province: edit.province,
  //       local_region: edit.local_region,
  //       city: edit.city,
  //       zip_code: edit.zip_code,
  //       address: edit.address,
  //       sex: edit.sex,
  //       email: edit.email,
  //       fax: edit.fax,
  //       main_segment: edit.main_segment,
  //       comment: edit.comment,
  //       payment_type: edit.payment_type,
  //       credit_limit: edit.credit_limit,
  //       master_comp: edit.master_comp,
  //     });
  //   } else {
  //     form.resetFields();
  //   }

  // HANDLE SUBMIT
  //   const handleSubmit = (e) => {
  //     // e.preventDefault();
  //     console.log("EDITED > ", e);

  //     const id = edit.id;
  //     const contentEdit = e;

  //     axios
  //       .put(`http://192.168.88.239:12045/api/v1/guest/card/`, {
  //         // .put(`http://117.53.144.85:12045/api/v1/guest/card/`, {
  //         id: id,
  //         full_name: contentEdit.full_name,
  //         first_name: contentEdit.first_name,
  //         id_card_type: contentEdit.id_card_type,
  //         id_card_number: contentEdit.id_card_number,
  //         phone_number: contentEdit.phone_number,
  //         birthdate: contentEdit.birthdate,
  //         nationality: contentEdit.nationality,
  //         country: contentEdit.country,
  //         province: contentEdit.province,
  //         local_region: contentEdit.local_region,
  //         city: contentEdit.city,
  //         zip_code: contentEdit.zip_code,
  //         address: contentEdit.address,
  //         sex: contentEdit.sex,
  //         email: contentEdit.email,
  //         fax: contentEdit.fax,
  //         main_segment: contentEdit.main_segment,
  //         comment: contentEdit.comment,
  //         payment_type: contentEdit.payment_type,
  //         credit_limit: contentEdit.credit_limit,
  //         master_comp: contentEdit.master_comp,
  //         is_dummy: true,
  //       })
  //       .then((response) => {
  //         console.log(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //       .finally(() => {
  //         // setAddGuestModal(false);
  //       });
  //   };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    form.resetFields();
  };

  // HANDLE REACT PRINT
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Print",
    onafterprint: () => alert("Print Success"),
  });
  // CONSOLE LOG
  //console.log("DATA IN MODAL EDIT > ", edit);

  return (
    <>
      <Modal
        className="edit-modal-guest"
        title={
          <>
            <Row>
              <BsIntersect style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>View Report</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          //   onFinish={(e) => handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          name="guest-modal-form"
          className="guest-modal-form"
          layout="horizontal"
        >
          <div ref={componentRef} style={{ padding: "40px" }}>
            <Row span={24} justify="start">
              <Typography style={{ marginBottom: 10, fontSize: 10 }}>
                <b>{moment().format("DD/MM/YYYY hh:mm")}</b>
              </Typography>
            </Row>
            <Row span={24} justify="center">
              <Typography style={{ fontSize: 15 }}>
                <b>Data Contoh</b>
              </Typography>
            </Row>
            <Row span={24} justify="center">
              <Typography style={{ marginBottom: 10, fontSize: 20 }}>
                <b>Penjualan - Perincian</b>
              </Typography>
            </Row>
            <Row span={24} justify="center">
              <Typography style={{ marginBottom: 10, fontSize: 10 }}>
                <b>Wednesday, January 1, 2023 - Friday, January 3, 2023</b>
              </Typography>
            </Row>
            <Divider />

            <Row gutter={30} style={{ maxHeight: 15 }}>
              <Col span={6}>
                <Row>
                  <Col span={12}>
                    <Row>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>Tanggal :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item name={"tanggal"}>
                        <Input
                          placeholder="Tanggal"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={12}>
                    <Row>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>Pelanggan :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item name={"pelanggan"}>
                        <Input
                          placeholder="Pelanggan"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={6}></Col>
              <Col span={6}>
                <Row>
                  <Col span={12}>
                    <Row>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>Mata Uang :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item name={"mata_uang"}>
                        <Input
                          placeholder="Mata Uang"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={30}>
              <Col span={6}>
                <Row style={{ maxHeight: 15 }}>
                  <Col span={12}>
                    <Row style={{ maxHeight: 15 }}>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>No. Ref :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ maxHeight: 15 }}>
                      <Form.Item name={"no_ref"}>
                        <Input
                          placeholder="No. Ref"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={6}>
                <Row style={{ maxHeight: 15 }}>
                  <Col span={12}>
                    <Row style={{ maxHeight: 15 }}>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>Gudang :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ maxHeight: 15 }}>
                      <Form.Item name={"gudang"}>
                        <Input
                          placeholder="Gudang"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ maxHeight: 15 }}>
                  <Col span={12}>
                    <Row style={{ maxHeight: 15 }}>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>Catatan :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ maxHeight: 15 }}>
                      <Form.Item name={"catatan"}>
                        <Input
                          placeholder="Catatan"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={6}>
                <Row>
                  <h4>Posting</h4>
                </Row>
              </Col>

              <Col span={6}>
                <Row style={{ maxHeight: 15 }}>
                  <Col span={12}>
                    <Row>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>Proyek :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item name={"proyek"}>
                        <Input
                          placeholder="Proyek"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Row>
                      <Typography style={{ marginTop: 10, fontSize: 10 }}>
                        <b>Departemen :</b>
                      </Typography>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Form.Item name={"departemen"}>
                        <Input
                          placeholder="Departemen"
                          bordered={false}
                          disabled
                          style={{ fontSize: 10 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <ReportOutletPrint />
              </Col>
            </Row>

            <Divider style={{ margin: "15px 0px" }} />
          </div>

          <Row
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              //   htmlType="submit"
              //   key={"submit"}
              onClick={handlePrint}
              className="submit-guest"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              Download
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

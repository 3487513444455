// Import React Components
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { user_name, token } from "../../../API/Global/Payload";
import { baseurl } from "../../../API/Config/Api";

// Import React's Component
import { Col, Divider, Layout, Row, Tag, Typography, Tree } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React's Icons
import { GiOpenBook } from "react-icons/gi";
import {
  MdLocalFireDepartment,
  MdFiberNew,
  MdOutlineSecurity,
  MdAddModerator,
  MdDeleteForever,
  MdAutoDelete,
} from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { TbReplace } from "react-icons/tb";

// Import Components
import { LocalizationDate } from "../../../Components/Reusable/Functions/Localizations/Localization";
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";
import { PiNumberThreeDuotone } from "react-icons/pi";

const { Title } = Typography;

// const dataTest = [];

const dataTest = [
  {
    id: 1,
    id_changelog: "clg1verUn",
    version: "Unreleased",
    id_version: "verUn",
    action: "added",
    id_action: "add",
    description: "Finance Module",
    created_date: "2024-05-01T00:00:00.000Z",
  },
  {
    id: 2,
    id_changelog: "clg1ver1",
    version: "1.0.0",
    id_version: "ver1",
    action: "added",
    id_action: "add",
    description: "Release Front Office Module",
    created_date: "2024-06-01T00:00:00.000Z",
  },
  {
    id: 3,
    id_changelog: "clg2ver1",
    version: "1.0.0",
    id_version: "ver1",
    action: "added",
    id_action: "add",
    description: "Release House Keeping Module",
    created_date: "2024-06-01T00:00:00.000Z",
  },
  {
    id: 4,
    id_changelog: "clg3ver1",
    version: "1.0.0",
    id_version: "ver1",
    action: "added",
    id_action: "add",
    description: "Release POS Module",
    created_date: "2024-06-01T00:00:00.000Z",
  },
  {
    id: 5,
    id_changelog: "clg2verUn",
    version: "Unreleased",
    id_version: "verUn",
    action: "removed",
    id_action: "remove",
    description: "Remove Approval Purchase Order",
    created_date: "2024-05-01T00:00:00.000Z",
  },
  {
    id: 6,
    id_changelog: "clg1ver2",
    version: "2.0.0-Alpha",
    id_version: "ver2",
    action: "fixed",
    id_action: "fix",
    description: "Fix Bugs",
    created_date: "2024-06-08T00:00:00.000Z",
  },
  {
    id: 7,
    id_changelog: "clg2ver2",
    version: "2.0.0-Alpha",
    id_version: "ver2",
    action: "security",
    id_action: "secure",
    description: "Secure Login With JWT Auth",
    created_date: "2024-06-08T00:00:00.000Z",
  },
  {
    id: 8,
    id_changelog: "clg3ver2",
    version: "2.0.0-Alpha",
    id_version: "ver2",
    action: "deprecated",
    id_action: "deprecate",
    description: "Approval Purchase Order is no longer used",
    created_date: "2024-06-08T00:00:00.000Z",
  },
  {
    id: 8,
    id_changelog: "clg4ver2",
    version: "2.0.0-Alpha",
    id_version: "ver2",
    action: "changed",
    id_action: "change",
    description: "Change Approval Purchase Order to Approval Receiving",
    created_date: "2024-06-08T00:00:00.000Z",
  },
];

// CODE
export default function ChangelogVersionPage() {
  // Data
  const [data, setData] = useState([]);
  const [version, setVersion] = useState(null);
  // Loadings
  const [loading, setLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    await axios
      .get(`${baseurl}/version/web-changelog`)
      .then((response) => {
        console.log("Versions: ", response);

        if (response?.data?.msg?.length > 0) {
          const resp = response.data.msg;

          const _res = resp.filter((e) => {
            if (e?.is_deleted != true) {
              return e;
            }
          });

          console.log("Res: ", _res);

          setData(_res);
          setVersion(_res[0]);
        } else {
          setData([]);
          setVersion("1.0.0");
        }
        setLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log(err);
        failedFetch(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });

    // await getPurchasing({
    //   type: "purchase-request",
    //   onAwait: () => {
    //     setLoading(true);
    //   },
    //   onSuccess: (response) => {
    //     console.log("Response => ", response);

    //     if (response?.data?.msg?.length > 0) {
    //       const resp = response.data.msg;

    //       const _res = resp.filter((e) => {
    //         if (e?.is_deleted != true) {
    //           return e
    //         }
    //       });

    //       console.log("Res: ", _res);

    //       setData(_res);
    //     } else {
    //       setData([]);
    //     }
    //     setLoading(false);
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //     setLoading(false);
    //     // failedFetch(error);
    //   },
    // });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Functions
  const result = Object.values(
    data?.reduce((acc, el, idx) => {
      let arr_x = [];

      acc[el?.id_version] = acc[el?.id_version] || {
        title:
          el?.version +
          ` => [${moment(LocalizationDate(el.created_date)).format(
            "DD-MM-YYYY"
          )}]`,
        key: el?.id_version,
        children: [],
      };

      // console.log("Childs: ", acc[el?.id_version].children);
      // console.log("Child If Results: ", el?.action);
      // console.log(
      //   !acc[el?.id_version].children.some((e) => {
      //     console.log("Title: ", e?.title);
      //   })
      // );

      if (
        !acc[el?.id_version].children.some((e) => e?.title == el?.action + ":")
      ) {
        acc[el?.id_version].children.push({
          title: el?.action + ":",
          key: el?.id_action + el?.id_version,
          children: [
            {
              title: el?.description,
              key: el?.id_changelog,
              icon: {
                Added: (
                  <MdFiberNew style={{ fontSize: 20, color: "#1890ff" }} />
                ),
                Fixed: (
                  <MdLocalFireDepartment
                    style={{ fontSize: 20, color: "#fa8c16" }}
                  />
                ),
                Changed: (
                  <TbReplace style={{ fontSize: 20, color: "#000000" }} />
                ),
                Deprecated: (
                  <MdAutoDelete style={{ fontSize: 20, color: "#ff4d4f" }} />
                ),
                Removed: (
                  <MdDeleteForever style={{ fontSize: 20, color: "#ff4d4f" }} />
                ),
                Security: (
                  <MdAddModerator style={{ fontSize: 20, color: "#87d068" }} />
                ),
              }[el?.action] ?? (
                <MdLocalFireDepartment
                  style={{ fontSize: 20, color: "#fa8c16" }}
                />
              ),
            },
          ],
        });
      } else {
        let ix = acc[el?.id_version].children.findIndex(
          (x) => x.key == el?.id_action + el?.id_version
        );
        console.log(ix);

        acc[el?.id_version].children[ix == -1 ? 0 : ix].children.push({
          title: el?.description,
          key: el?.id_changelog,
          action: el?.action,
          icon: {
            Added: <MdFiberNew style={{ fontSize: 20, color: "#1890ff" }} />,
            Fixed: (
              <MdLocalFireDepartment
                style={{ fontSize: 20, color: "#fa8c16" }}
              />
            ),
            Changed: <TbReplace style={{ fontSize: 20, color: "#000000" }} />,
            Deprecated: (
              <MdAutoDelete style={{ fontSize: 20, color: "#ff4d4f" }} />
            ),
            Removed: (
              <MdDeleteForever style={{ fontSize: 20, color: "#ff4d4f" }} />
            ),
            Security: (
              <MdAddModerator style={{ fontSize: 20, color: "#87d068" }} />
            ),
          }[el?.action] ?? (
            <MdLocalFireDepartment style={{ fontSize: 20, color: "#fa8c16" }} />
          ),
        });
      }
      return acc;
    }, {})
  )
    .sort()
    .reverse();
  result.some(
    (item, idx) =>
      item.key == "verUn" &&
      result.unshift(
        // remove the found item, in-place (by index with splice),
        // returns an array of a single item removed
        result.splice(idx, 1)[0]
      )
  );

  console.log("result:", result);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="changelog-layout">
            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                span={24}
                className="changelog-layout-col"
                style={{
                  padding: "15px 30px",
                }}
              >
                <Row className="list-row" justify="start">
                  <Col span={24} className="title-col">
                    <Title className="ver-title" style={{ margin: "22px 0px" }}>
                      {`Changelog`}
                    </Title>

                    <Row className="sub-title" justify="start">
                      <Tag className="ver-tag">{`Look `}</Tag>
                      {` follows `}
                      <a
                        href="http://semver.org/"
                        className="semantics-link"
                        style={{
                          padding: "0px 5px",
                        }}
                      >{` Semantic Versioning 2.0.0.`}</a>
                    </Row>

                    <Title
                      className="ver-title"
                      level={4}
                      id="release-schedule"
                      style={{ margin: "22px 0px 0px" }}
                    >
                      <a
                        aria-hidden="true"
                        href="#release-schedule"
                        tabIndex={-1}
                        className="hash-tag"
                        style={{
                          padding: "0px 3px ",
                          color: "#FFFFFF",
                        }}
                      >
                        #
                      </a>
                      {`Release Schedule`}
                    </Title>
                  </Col>
                </Row>

                <Row className="list-row" justify="start">
                  <Col span={24} className="title-col">
                    <ul
                      className="logs-list"
                      style={{
                        listStyleType: "circle",
                        padding: "20px 30px 0px",
                      }}
                    >
                      <li>
                        Weekly release: patch version at the end of every week
                        for routine bugfix (anytime for urgent bugfix).
                      </li>
                      <li>
                        Monthly release: minor version at the end of every month
                        for new features.
                      </li>
                      <li>
                        Major version release is not included in this schedule
                        for breaking change and new features.
                      </li>
                    </ul>
                  </Col>
                </Row>

                <Divider
                  className="page-divider"
                  style={{
                    background: "#EBEDF3",
                  }}
                />

                <Row className="list-row" justify="start">
                  <Col
                    span={24}
                    className="title-col"
                    style={{ margin: "0px 30px 22px" }}
                  >
                    <Row className="typo-title" style={{ margin: "0px -30px" }}>
                      <Title className="ver-title" level={2} id="1.0.0">
                        <a
                          aria-hidden="true"
                          href="#1.0.0"
                          tabIndex={-1}
                          className="hash-tag"
                          style={{
                            padding: "0px 3px ",
                            color: "#FFFFFF",
                          }}
                        >
                          #
                        </a>
                        {version?.version}
                      </Title>
                    </Row>

                    <Row
                      className="date-title"
                      style={{ padding: "10px 0px 30px" }}
                    >
                      <Tag className="date-release">
                        {version?.created_date
                          ? moment(version?.created_date).format("DD-MM-YYYY")
                          : "12-12-2023"}
                      </Tag>
                    </Row>

                    <p className="row-title">
                      {`🏆 Look Systems ${version?.version} is released! `}
                    </p>

                    <Tree
                      className="version-tree"
                      showIcon
                      showLine
                      // allowDrop={false}
                      titleRender={(record) => (
                        <span
                          key={record.key}
                          // onClick={() => toggleExpandNode(record.key)}
                          style={{}}
                        >
                          {record.title}
                        </span>
                      )}
                      autoExpandParent={true}
                      // defaultExpandAll={true}
                      // defaultSelectedKeys={[result[0]?.key]}
                      // switcherIcon={<GoDotFill />}
                      treeData={result}
                    />

                    {/* <ul
                      className="logs-list"
                      style={{
                        listStyleType: "circle",
                        // margin: "20px 30px",
                        padding: "10px 20px",
                      }}
                    >
                      <li>{`🔥 Modules Released:`}</li>
                      <ul>
                        <li>{`🆕 Dashboard`}</li>
                        <li>{`🆕 Front Office`}</li>
                        <li>{`🆕 House Keeping`}</li>
                        <li>{`🆕 Configuration:`}</li>
                        <ul
                          style={{
                            listStyleType: "circle",
                          }}
                        >
                          <li>{`🆕 Masters`}</li>
                          <li>{`🆕 Parameters`}</li>
                        </ul>
                      </ul>
                      <li>{`🔥 New Design`}</li>
                      <li>{`🔥 Back-Up Cloud`}</li>
                    </ul> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterWorkHour({
  getWorkHour,
  shiftName,
  // IS RENDER
  is_render,
  is_render_false,
}) {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [shift, setShift] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "shift",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Shift --> ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let _filter = _res.filter((value) => {
            return value.is_active === true;
          });

          handleFilter(_filter);
        }
      },
      onReject: (error) => {
        console.log("Params Shift --> ", error);
        // failedFetch(error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (shiftName && !cleanUp) {
      setShift(shiftName);
    } else {
      setShift(null);
    }

    if (is_render === true && !cleanUp) {
      setShift(null);
      is_render_false(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [shiftName, is_render]);

  // Handle Filter
  const handleFilter = (val) => {
    const _data = val;

    let _filtered = _data.map((value) => ({
      label: value?.shift_name,
      value: value?.id,
      code: value?.shift_id,
    }));

    // console.log("Filtered: ", _filtered);
    setOptionValue(_filtered);
  };

  // Handle Change
  const handleChange = (val, code) => {
    const _value = val;
    const _allKeys = code;

    // console.log("Values: ", _value);
    // console.log("All Keys: ", _allKeys);

    setShift(_value);
    getWorkHour({
      value: _value,
      id: _allKeys?.code,
      label: _allKeys?.label,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setShift(null);
  };

  return (
    <>
      <Select
        className="shift-select"
        key="shift-select"
        placeholder="Choose Work Hour"
        allowClear
        showSearch
        value={shift}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// Import React Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Space,
  List,
  Table,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { MdOutlineHorizontalSplit } from "react-icons/md";
import SplitBillModalTable from "../../../Table/Outlet/Order/SplitBill/TableSplitBill";
import { AiOutlineSave } from "react-icons/ai";
import { BiFoodMenu } from "react-icons/bi";
import SavedBillModalTable from "../../../Table/Outlet/Order/SavedBill/TableSavedBill";
import ReservationTableModalTable from "../../../Table/Outlet/Order/ReservationTable/TableResvTable";

export default function ModalReservationTable(props) {
  // PROPS
  const { openModal, closeModal, selectedTable, selectedTableResv } = props;

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [headers, setHeaders] = useState([]);
  const [details, setDetails] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    navigate(`${pathname}/payment`, {
      state: {
        headerData: headers,
        detailData: selectedDetail,
        selectedTable: tableKeys,
        is_split: true,
      },
    });
  };

  const onFinishFailed = (e) => {};

  return (
    <>
      <Modal
        className="saved-bill-modal"
        title={
          <>
            <Row className="saved-bill-modal-row">
              <BiFoodMenu
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`POS Reservation Table List`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Row
          className="saved-bill-modal-form-serve-row"
          justify="start"
          gutter={[30, 30]}
          style={{ padding: 20 }}
        >
          <ReservationTableModalTable
            getResvData={selectedTable?.res_time}
            getSelectedTableResv={(e) => {
              selectedTableResv(e);
              handleCancel();
            }}
          />
        </Row>

        {/* <Divider
          className="saved-bill-modal-form-divider-form"
          style={{ margin: "15px 0px" }}
        />

        <Row justify="end" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            key={"submit"}
            onClick={(e) => {
              selectedTable(tableKeys);
              handleCancel();
            }}
            loading={loading}
            className="submit-btn"
            style={{
              // backgroundColor: "#1BC5BD",
              // borderColor: "#1BC5BD",
              marginRight: 30,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={handleCancel}>
            {`Cancel`}
          </Button>
        </Row> */}
      </Modal>
    </>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// CODE
export default function ParametersFrontOffice(props) {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [params, setParams] = useState({});
  //   var date = "";

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "fo",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        // console.log("Date Audit --> ", response);
        // setLoading(false);
        if (response?.data?.msg?.length > 0) {
          setParams(response?.data?.msg[0]);
        }
        // date = response?.data?.msg[0]?.audit_date;
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USER ROLES
  useEffect(() => {
    fetchData();
  }, []);

  return { params };
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// Main Context
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";
import { handleAuthorization } from "../../../API/Global/Permission";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Tooltip,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";
import { FiEdit } from "react-icons/fi";
import { PiArrowArcLeftBold } from "react-icons/pi";
import { FaConciergeBell, FaUserFriends, FaCashRegister } from "react-icons/fa";
import { MdOutlineBedroomChild, MdOutlineMoreTime } from "react-icons/md";
import { TfiMenuAlt } from "react-icons/tfi";
import { HiMiniSquaresPlus } from "react-icons/hi2";
import { AiOutlineFileDone } from "react-icons/ai";

// Notification
import {
  failedAddReserv,
  failedFetch,
  successAddReserv,
} from "../../Reusable/Notification/Notification";

// Import Page Components
import ModalGuestInHouseEdit from "../../Modals/GuestInHouse/Modal";
import ModalArticle from "../../Modals/Article/Stay/Modal";
import ModalChangeRoom from "../../Modals/ChangeRoom/Modal";
import PreBillForm from "../../Forms/PreBill/PreBill";
import ModalLateCheckOut from "../../Modals/LateCheckOut/Modal";
import MasterBillTrxForm from "../../Forms/PreBill/MasterBillTrx";
import ModalPermission from "../../Modals/Permissions/Modal";
import RegistrationForm from "../../Forms/Registration/Form";
import RegistrationGroupForm from "../../Forms/RegistrationGroup/Form";
import ModalReactiveReservations from "../../Modals/ReactiveReservation/Modal";
import ExtendNightModal from "../../Modals/Extend/Modal";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function GuestInHousePopover(props) {
  // PROPS
  const { details, is_refresh } = props;

  // CONTEXT
  const { getMasterBillTrx } = useContext(MainContext);

  // PARAMS
  const params = FrontOfficeParams().show_ci;

  // STATE MANAGEMENT
  // Popover
  const [open, setOpen] = useState(false);
  const [isPermission, setIsPermission] = useState(0);
  // Modals
  const [article, setArticle] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [bill, setBill] = useState(false);
  const [room, setRoom] = useState(false);
  const [master, setMaster] = useState(false);
  const [late, setLate] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [reActivate, setReActivate] = useState(false);
  const [extend, setExtend] = useState(false);
  // Data
  const [recordData, setRecordData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [formData, setFormData] = useState(null);
  // AUTH & PERMISSION
  const [permission, setPermission] = useState(false);
  const [modalAuth, setModalAuth] = useState(false);
  const [permitLoad, setPermitLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // Articles
  const [articleStay, setArticleStay] = useState([]);
  const [articleRoom, setArticleRoom] = useState([]);

  // Set Data
  // console.log("IN POP OVER = ", details);
  const detail = details;

  // USE EFFECT FETCH
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      setRefresh(false);
      setIsSuccess(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // Handle Registration Letter
  const handleRegistration = async (value) => {
    const _data = value;

    await axios
      .post(`${baseurl}/form/front-office/registration`, {
        reservation_id: _data?.reservation_id,
      })
      .then((response) => {
        console.log("Response Form: ", response);

        if (response?.data?.data?.length > 0) {
          const _data = response.data.data[0];

          setFormData(_data);
        } else {
          setFormData(null);
        }
      })
      .catch((error) => {
        console.log("Response Err: ", error);
        failedFetch(error);
      })
      .finally(() => {
        if (_data?.group == 0) {
          setOpenForm(true);
        } else {
          setOpenGroup(true);
        }
      });
  };

  // HANDLE FILTER
  const handleFilter = (values) => {
    const _response = values?.results;
    const _data = values?.data;

    let id = 1;
    let _newRoom = [];
    let _articleRoom = [];
    let _articles = [];

    // for (let i = 0; i < state?.length; i++) {
    console.log("Loop ", _response);

    const _filteredRoom = _response[0].get_article_room_master_bill_guest;

    const newRoom = _filteredRoom.map((items) => ({
      ...items,
      total_price: items?.gross,
    }));

    if (_response[0]?.get_article_stay_master_bill_guest?.length > 0) {
      const _filteredArt = _response[0]?.get_article_stay_master_bill_guest;

      const newArticles = _filteredArt.map((items) => {
        return {
          ...items,
          total_price: items?.total_price > 0 ? items.total_price : 0,
        };
      });

      if (newArticles?.length > 0) {
        _articles.push(newArticles);
      }
    }

    _articleRoom.push(_filteredRoom);
    _newRoom.push(newRoom);
    // }

    console.log("New Articles = ", _articles);
    // console.log("Articles = ", _articleRoom);
    console.log("New Room = ", _newRoom);

    setArticleRoom(_newRoom[0]);
    setArticleStay(_articles[0]);

    if (_data?.group == 0) {
      setOpenForm(true);
    } else {
      setOpenGroup(true);
    }
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);
    setIsPermission(0);
  };

  // Handle Cancel
  const handleCancel = () => {
    setReActivate(false);
    setLate(false);
    setPermitLoad(true);
  };

  // HANDLE EARLY CHECK IN
  const handlePermit = async (value) => {
    const _record = value;

    console.log("Value Late C/O: ", _record);
    // console.log("Group: ", isGroup);

    if (
      _record?.auth_token &&
      _record?.auth_roles == "ROLE_SUPERVISOR" &&
      _record?.auth_dept.toLowerCase().includes("front")
    ) {
      if (isPermission == 1) {
        setLate(true);
      } else {
        setReActivate(true);
      }
    } else {
      setPermission(true);
    }
  };

  // HANDLE SUCCESS
  const handleSuccess = async (value) => {
    const _record = value;

    console.log("Value Success: ", _record);
    // console.log("Group: ", isGroup);

    if (_record == true) {
      localStorage.clear();
      handleAuthorization("");

      is_refresh(true);
      setIsSuccess(true);
      setPermission(false);
    }
  };

  // HANDLE HDIE
  const handleHide = (value) => {
    let hide = true;
    let isGroup = value?.group < 2 ? false : true;

    if (params == false) {
      hide = true;
    } else {
      if (isGroup) {
        hide = true;
      } else {
        hide = false;
      }
    }

    return hide;
  };

  return (
    <>
      <Tooltip title="Option Menu" className="guest-inhouse-tooltip">
        <Popover
          className="pop-over-guest-inhouse"
          name="pop-over-guest-inhouse"
          key="pop-over-guest-inhouse"
          placement="right"
          title={
            <Row>
              {/* <Col span={4}>
                <UnorderedListOutlined
                  className="popover-btn"
                  style={{
                    padding: "8px 8px 8px 0px",
                    fontSize: 14,
                    color: "#2f54eb",
                  }}
                />
              </Col> */}

              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    // color: "#2f54eb",
                    fontWeight: 600,
                  }}
                >
                  {`Guest In House Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            //   detail.group == 2 ? (
            <>
              <Row
                justify="start"
                hidden={detail?.group > 1 ? true : false}
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <AiOutlineFileDone
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();
                    handleRegistration(detail);
                    setRecordData(detail);
                  }}
                >
                  {`${
                    detail?.group > 0
                      ? "Group Registration Letter"
                      : "Registration Letter"
                  } `}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FiEdit
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    setRecordData(detail);
                    handleClose();
                    setOpenEdit(true);
                  }}
                >
                  {`Edit Data Guest`}
                </Button>
              </Row>

              <Row
                hidden={detail.group < 2 ? false : true}
                justify="start"
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <HiMiniSquaresPlus
                      style={{
                        color: "#faad14",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    minHeight: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();

                    setRecordData(detail);
                    setExtend(true);
                  }}
                >
                  {`Extend Night`}
                </Button>
              </Row>

              <Row
                justify="start"
                // hidden={detail?.payment_status == 2 ? false : true}
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaConciergeBell
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();
                    setArticle(!article);
                    setRecordData(detail);
                  }}
                >
                  {`Add Article`}
                </Button>
              </Row>

              <Row
                hidden={detail.group == 1 ? false : true}
                justify="start"
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaCashRegister
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();
                    setMaster(!master);
                    setRecordData(detail);
                  }}
                >
                  {`View Master Bill Transaction`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaCashRegister
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();
                    setBill(!bill);
                    setRecordData(detail);
                  }}
                >
                  {`View Bill Transaction`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdOutlineBedroomChild
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();
                    setRoom(!room);
                    setRecordData(detail);
                  }}
                >
                  {`Change Room`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <MdOutlineMoreTime
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();

                    // setLate(true);
                    setRecordData(detail);
                    setIsPermission(1);
                    setPermission(true);
                  }}
                >
                  {`Late Check-Out`}
                </Button>
              </Row>

              {/* <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <BsPersonVcardFill
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  // onClick={() => {
                  //   handleClose();

                  //   setLate(true);
                  //   setRecordData(detail);
                  // }}
                >
                  {`Assign Room Card`}
                </Button>
              </Row> */}

              <Row
                hidden={handleHide(detail)}
                justify="start"
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <PiArrowArcLeftBold
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    minHeight: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClose();

                    // setReActivate(true);
                    setRecordData(detail);
                    setIsPermission(2);
                    setPermission(true);
                  }}
                >
                  {`Cancel Check-In`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            style={{
              fontSize: 25,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              marginLeft: 10,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <ModalGuestInHouseEdit
        openModal={openEdit}
        closeModal={setOpenEdit}
        dataGuest={openEdit == true ? recordData : null}
        is_refresh={is_refresh}
      />

      <ModalArticle
        openModal={article}
        closeModal={setArticle}
        dataGuest={article == true ? recordData : null}
      />

      <MasterBillTrxForm
        openModal={master}
        closeModal={setMaster}
        dataGuest={master == true ? recordData : null}
      />

      <PreBillForm
        openModal={bill}
        closeModal={setBill}
        dataGuest={bill == true ? recordData : null}
        dataArticle={setTotalAmount}
      />

      <ModalChangeRoom
        openModal={room}
        closeModal={setRoom}
        dataGuest={room == true ? recordData : null}
        is_refresh={is_refresh}
      />

      <ModalLateCheckOut
        openModal={late}
        closeModal={handleCancel}
        dataGuest={late == true ? recordData : null}
        is_refresh={handleSuccess}
      />

      <ModalPermission
        openModal={permission}
        closeModal={setPermission}
        is_success={isSuccess}
        is_refresh={setRefresh}
        dataSubmit={modalAuth ? recordData : null}
        roomStatus={3}
        is_deleted={false}
        is_authorized={handlePermit}
        is_loading={permitLoad}
        is_loadingFalse={setPermitLoad}
      />

      <RegistrationForm
        openModal={openForm}
        closeModal={setOpenForm}
        dataRes={openForm == true ? recordData : null}
        dataGuest={openForm == true ? recordData : null}
        // dataRoom={openForm == true ? articleRoom : []}
        // dataArticle={openForm == true ? articleStay : []}
        is_refresh={is_refresh}
        is_checkin={false}
        dataForm={openForm == true ? formData : null}
      />

      <RegistrationGroupForm
        openModal={openGroup}
        closeModal={setOpenGroup}
        dataRes={openGroup == true ? [recordData] : []}
        dataGuest={openGroup == true ? recordData : null}
        // dataRoom={openGroup == true ? articleRoom : []}
        // totalArticle={openGroup == true ? articleStay : []}
        // dataCoa={openForm == true ? chartOfAcc : null}
        // noShow={noShow ? noShow : false}
        is_refresh={is_refresh}
        is_checkin={false}
        dataForm={openGroup == true ? formData : null}
      />

      <ModalReactiveReservations
        is_open={reActivate}
        is_close={handleCancel}
        data_guest={reActivate ? recordData : null}
        is_success={handleSuccess}
        is_cancelled={false}
      />

      <ExtendNightModal
        is_open={extend}
        is_close={setExtend}
        data_guest={extend ? recordData : null}
        is_success={handleSuccess}
      />
    </>
  );
}

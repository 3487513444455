// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { RiTableFill } from "react-icons/ri";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import TableCategoryTable from "../../../Components/Table/Master/TableCategory/Table";
import MasterOutletLocation from "../../../Components/Reusable/Outlet/Master/OutletLocation/OutletLocation";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function TableCategoryPage() {
  // STATE MANAGEMENT
  // Location of Outlet
  const [outletLocation, setOutletLocation] = useState({
    locations: "",
    locations_id: 0,
  });
  // Search Value
  const [value, setValue] = useState(null);
  // Set Search
  const [search, setSearch] = useState(null);

  // Handle Search
  const handleSearch = (e) => {
    const _val = e.target.value;

    setValue(_val);
  };

  // GET LOCATION
  const getOutletLoc = (value) => {
    const _loc = value;

    console.log("Outlet Location: ", _loc);

    setOutletLocation({
      locations: _loc?.value,
      locations_id: _loc?.id,
    });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="table-category-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <RiTableFill
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Table Category List Data`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 150,
                  width: "100%",
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <MasterOutletLocation getOutletLocation={getOutletLoc} />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  placeholder="Table Category Name/Room Name"
                  allowClear
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                    maxWidth: 300,
                  }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <TableCategoryTable
            is_search={setSearch}
            searchKey={value}
            outlet_loc={outletLocation}
          />
        </Col>
      </Content>
    </Layout>
  );
}

import { Decoder } from "../../../../API/Global/Decoder";

// CODE
export const CurrencySymbols = () => {
  // Get Params
  let params = sessionStorage.getItem("par-1");

  const _decode = Decoder(params);

  //   console.log(_decode);

  return { code: _decode?.currency_code, name: _decode?.currency };
};

// Import React Components
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Master Notification
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// AUTHORIZED
import {
  authorized_by,
  authorized_dept,
  authorized_roles,
  authorized_token,
  handleAuthorization,
} from "../../../../API/Global/Permission";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Col,
  Row,
  Select,
  Table,
  Space,
  Button,
  Modal,
  Typography,
  Form,
  Input,
  Result,
  Tooltip,
  Divider,
  InputNumber,
  Checkbox,
  notification,
} from "antd";
import {
  FormOutlined,
  MenuOutlined,
  PlusCircleFilled,
  PlusCircleTwoTone,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { FaExclamationTriangle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

// Import Master Components
import ChangeRateCodeModal from "../../../Modals/ChangeRateCode/Modal";
import MasterRoomCat from "../../../Reusable/Master/RoomCat/RoomCat";

// Import Functions
import { Grossing } from "../../../Reusable/Functions/GrossBreakdown/Grossing";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { TaxesParameter } from "../../../Reusable/Functions/Taxes/Taxes";
import { failedMaster } from "../../../../Style/Global/Global";
import { AccountingParams } from "../../../Reusable/Functions/Parameters/ParamsAcc";

// Import Notifications
import {
  failedPermit,
  successPermit,
} from "../../../Reusable/Notification/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { Pricing } from "../../../Reusable/Functions/Pricing/Pricing";

const { warning } = notification;

// CODE
export default function ReservationRoomRateTable(props) {
  // PROPS
  const {
    roomList,
    is_breakfast,
    setRoomPrice,
    getPrice,
    is_compliment,
    is_permit,
    night,
    is_dynamic,
    is_authorized,
    compliment,
    optionValue,
    getRefreshRate,
    getFinalRoomList,
    getFinalDetailRoomList,
    defaultRate,
    // PROPS IF FROM ADD MEMBER RESERVATIONS COMPONENT
    is_add_member = false,
    lastPriceId,
  } = props;

  // CONTEXT
  const { requestPermissions } = useContext(MainContext);
  const { getMasterRateSegment } = useContext(MasterContext);

  let auth_token = localStorage.getItem("permit");

  // Params
  const currency = CurrencySymbols().code;
  const taxes = TaxesParameter();
  const accounting = AccountingParams();

  // STATE MANAGEMENT
  // Data
  const [value, setValue] = useState([]);
  // Breakfast
  const [is_abf, setIs_abf] = useState(false);
  // Selected Source
  const [mutSource, setMutSource] = useState([]);
  // Credentials
  const [credentials, setCredentials] = useState(null);
  // Modal
  const [permitted, setPermitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalChangeOpen, setModalChangeOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  // Loading
  const [buttonLoading, setButtonLoading] = useState(false);
  // Checkbox
  const [apply, setApply] = useState(false);
  const [applyAll, setApplyAll] = useState(false);
  const [allNight, setAllNight] = useState(false);

  const [percentages, setPercentages] = useState({
    tax: taxes?.tax_percentage,
    service: taxes?.service_percentage,
  });

  // UseForm
  const [formAuth] = Form.useForm();
  const [formAdd] = Form.useForm();

  // Modal Confirm
  const { confirm } = Modal;

  // for (let i = 0; i < props.selectedPrice?.length; i++) {
  //   // const element = props.selectedPrice[i];
  //   r_price.push(props.selectedPrice[i]?.children[0]?.room_price);

  //   switch (props.selectedPrice[i]?.children[i]?.category) {
  //     case "DELUXE ":
  //       r_cat = "DLX";
  //       break;
  //     case "SUPERIOR":
  //       r_cat = "SUP";
  //       break;
  //     case "EXECUTIVE":
  //       r_cat = "EXE";
  //       break;
  //     case "SUITE":
  //       r_cat = "SUT";
  //       break;
  //     case "STANDARD":
  //       r_cat = "STD";
  //       break;

  //     default:
  //       break;
  //   }
  // }

  //console.log("Room Cat : ", r_cat);
  //console.log("Room Price : ", r_price);

  // useEffect(() => {
  //   setSelectedRates(props.selectedPrice);
  //   console.log("effect data malih");
  //   setMutSource(data);

  // if (mutSource.length < 1) {
  //   var slctd_s = [];
  //   for (let i = 0; i < data.length; i++) {
  //     for (let j = 0; j < data[i].children.length; j++) {
  //       let fltr = data[i].children[j];
  // if (fltr.category == r_cat && fltr.rate == r_price[i]) {
  //   slctd_s.push(fltr);
  //   setSelectedRates((p) => [...p, fltr.key]);
  // }
  //       //console.log("Hasil Filterku = ", slctd_s);
  //     }
  //   }
  // }

  //   return () => {
  //     setMutSource([]);
  //   };
  // }, [data]);

  useEffect(() => {
    let cleanUp = false;

    if (roomList?.length > 0 && !cleanUp) {
      setLoading(true);

      let is_abf = roomList[0]?.room_breakfast > 0 ? true : false;
      console.log("Room List --> ", roomList);
      console.log("Breakfast --> ", is_abf);
      console.log("Compliment Type --> ", is_compliment);

      setValue(roomList);
      handleRate({
        is_abf: is_abf,
        room_list: roomList,
        compliment_id: is_compliment.compliment_id,
        compliment_value: is_compliment.compliment_value,
      });
      setIs_abf(is_abf);

      formAdd.setFieldsValue({
        compliment_type: "No Compliment",
      });

      // handleRate(is_abf, roomList);
    }

    return () => {
      setMutSource([]);
      cleanUp = true;
    };
  }, [roomList, is_breakfast, is_add_member]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_compliment?.compliment_id > 0 && value.length > 0 && !cleanUp) {
      setLoading(true);

      console.log("Compliment: ", is_compliment);

      handleCompliment(is_compliment);

      formAdd.setFieldsValue({
        compliment_type: "No Compliment",
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [is_compliment, value]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (loading) {
      console.log("Authorized Token: ", auth_token);

      console.log("Token: ", authorized_token);
      console.log("By: ", authorized_by);
      console.log("Dept: ", authorized_dept);
      console.log("Roles: ", authorized_roles);

      if (
        auth_token &&
        authorized_dept.toLowerCase().includes("front") &&
        (authorized_roles == "ROLE_SUPERVISOR" ||
          authorized_roles == "ROLE_MANAGER")
      ) {
        setTimeout(() => {
          setLoading(false);
          setPermitted(true);
          // is_permit(true);
        }, 1500);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [auth_token, authorized_token, loading]);

  // ON FINISH
  const onFinish = async (val) => {
    const value = val;

    console.log("ON FINISH --> ", value);
    handlePermit(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = async (val) => {
    const value = val;

    console.log("ON FINISH FAILED --> ", value?.values);
  };

  // HANDLE RATE
  const handleRate = async (val) => {
    const _is_abf = val.is_abf;
    const _arr = val.room_list;
    const _comply = val.compliment_value;

    console.log("ABF: ", _is_abf);
    console.log("Array Price: ", _arr);
    console.log("Array comply: ", _comply);

    let _mutSource = [];

    if (_is_abf && _arr?.length > 0) {
      for (let i = 0; i < _arr?.length; i++) {
        let rate = {
          ..._arr[i],
          room_details: "Breakfast",
          price: _comply === true ? 0 : _arr[i]?.gross,
          gross: _comply === true ? 0 : _arr[i]?.gross,
          tax: _comply === true ? 0 : _arr[i]?.tax,
          service: _comply === true ? 0 : _arr[i]?.service,
          total_price: _comply === true ? 0 : _arr[i]?.gross * night,
          departure: _arr[i].date.clone().add(night, "days"),
        };

        // console.log("Re-create Breakfast -> ", rate);
        _mutSource.push(rate);
      }
    } else {
      for (let i = 0; i < _arr?.length; i++) {
        let rate = {
          ..._arr[i],
          room_details: "Room Only",
          price: _comply === true ? 0 : _arr[i]?.gross,
          gross: _comply === true ? 0 : _arr[i]?.gross,
          tax: _comply === true ? 0 : _arr[i]?.tax,
          service: _comply === true ? 0 : _arr[i]?.service,
          nett: _comply == true ? 0 : _arr[i]?.nett,
          total_price: _comply === true ? 0 : _arr[i]?.gross * night,
          departure: _arr[i].date.clone().add(night, "days"),
        };

        // console.log("Re-create Room Only -> ", rate);
        _mutSource.push(rate);
      }
      // setMutSource([rate]);
    }

    console.log("Re-create --> ", _mutSource);

    getPrice(_mutSource);
    // setMutSource(_mutSource);
    setMutSource(handleManipulateMutSource(_mutSource));

    setTimeout(() => {
      setLoading(false);
    }, 500);
    // setMutSource([rate]);
  };

  // HANDLE COMPLIMENT
  const handleCompliment = async (val) => {
    const content = val;
    // console.log("IS COMPLIMENT --> ", content);
    // console.log("Value --> ", value);
    // console.log("Is Breakfast --> ", is_abf);

    let _mutSource = [];

    if (content?.compliment_id === 2) {
      // console.log("NO COMPLIMENT !!!");

      for (let i = 0; i < value?.length; i++) {
        let rate = {
          ...value[i],
          price: value[i]?.gross > 0 ? value[i]?.gross : value[i]?.ro_price,
          main_compliment_id: content?.compliment_id,
          room_details: is_abf ? "Breakfast" : "Room Only",
          total_price:
            value[i]?.gross > 0
              ? value[i]?.gross * night
              : value[i]?.ro_price * night,
          departure: moment(value[i].date).clone().add(night, "days"),
        };

        _mutSource.push(rate);
      }
    } else {
      // console.log("House Uses or Compliment !!!");

      for (let i = 0; i < value?.length; i++) {
        let rate = {
          ...value[i],
          price: 0,
          gross: 0,
          tax: 0,
          service: 0,
          nett: 0,
          main_compliment_id: content?.compliment_id,
          room_details: is_abf ? "Breakfast" : "Room Only",
          total_price: 0,
          departure: moment(value[i].date).clone().add(night, "days"),
        };

        _mutSource.push(rate);
      }
    }

    console.log("After Refurbish --> ", _mutSource);
    console.log("NIGHT ->> ", night);

    getPrice(_mutSource);
    // setMutSource(_mutSource);
    setMutSource(handleManipulateMutSource(_mutSource));

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleManipulateMutSource = (_mutSource) => {
    const generateUniqueKey = () => {
      const timestamp = Date.now();
      const randomNum = Math.random().toString(36).substring(2, 10);

      return `key_${timestamp}_${randomNum}`;
    };

    const _updateMutSource = _mutSource.map((item, index) => {
      let arrDetails = [];
      let startDate = moment(item.date, "DD-MM-YYYY");
      let parent_key = index + 1;

      for (let i = 0; i < night; i++) {
        let currentDate = startDate.clone().add(i, "days"); // tambah hari
        //  let formattedDate = currentDate.format("DD-MM-YYYY");

        arrDetails.push({
          ...item,
          key: generateUniqueKey(),
          parent_key: parent_key,
          date: currentDate,
          room_number: item.room_no,
          price_id: is_add_member ? lastPriceId + index + 1 : index + 1,
          rate_code: defaultRate ? defaultRate?.value : item?.rate_code,
        });
      }

      return {
        ...item,
        price_id: is_add_member ? lastPriceId + index + 1 : index + 1,
        details: arrDetails,
        parent_key: parent_key,
      };
    });

    getFinalRoomList(_updateMutSource);
    handleFinalRoomDetails(_updateMutSource);

    return _updateMutSource;
  };

  // var slctd_s = [];
  // for (let i = 0; i < mutSource[0]?.children.length; i++) {
  //   // console.log("ORIGIN: ", mutSource[0].children.length);
  //   // for (let j = 0; j < mutSource[i].children.length; j++) {
  //   let fltr = mutSource[0].children[i];
  //   if (fltr.key == selectedRates) {
  //     slctd_s.push(fltr);
  //   }
  // }
  // // setSelectedRates(slctd_s);
  // console.log("Hasil Filterku = ", slctd_s);

  // CHECK BOX ROW SELECTION
  // const rowSelection = {
  //   // selectedRowKeys: selectedRates ? selectedRates : [],
  //   getCheckboxProps: (record) => ({
  //     disabled: record.disabled || record.key < 10000,
  //   }),
  //   onChange: (_, selectedRows) => {
  //     const night = props.qty || 1;
  //     // console.log("selectedRates: ", selectedRates);
  //     // console.log(`selectedRowKeys: ${_}`, "selectedRows: ", selectedRows);

  //     let selectedPars = selectedRows.reduce(
  //       (child, { parent_date: key }) => (
  //         (child[key] = (child[key] || 0) + 1), child
  //       ),
  //       {}
  //     );

  //     let selectedKeys = selectedRows.reduce(
  //       (child, { key: key }) => ((child[key] = (child[key] || 0) + 1), child),
  //       {}
  //     );

  //     /*
  //     let toy = data.map((parent) => {
  //       let newChildren = parent.children.map((ch) => {
  //         return {
  //           ...ch,
  //           disabled:
  //             Object.keys(selectedPars).includes(ch.parent_date.toString()) &&
  //             !Object.keys(selectedKeys).includes(ch.key.toString()) &&
  //             selectedPars[ch.parent_date] >= night,
  //         };
  //       });
  //       return { ...parent, children: newChildren };
  //     });

  //     props.getPrice(selectedRows);
  //     //console.log(selectedRows);
  //     setMutSource(toy);
  //     */
  //   },

  //   // onSelect: (selected) => {
  //   //   let newKey = [];
  //   //   newKey.push(selected.key);
  //   //   // let idx = toy.indexOf(selected.key);

  //   //   // if (idx >= 0) {
  //   //   //   toy.splice(idx, 1);
  //   //   // }

  //   //   console.log(
  //   //     "Selected : ",
  //   //     selected
  //   //     //  " TOY: ", toy, " IDX: ", idx
  //   //   );

  //   //   setSelectedRates(newKey);
  //   // },
  //   // selectedRowKeys: selectedRates,
  // };

  // HANDLE PERMIT
  const handlePermit = async (val) => {
    const _submit = val;

    await requestPermissions({
      auth: "auth",
      permit: "permissions-supervisor-fo",
      email: _submit.email,
      password: _submit.password,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Permit: ", response);

        if (response?.data?.msg == "Access Granted") {
          setCredentials(response?.data?.data);

          localStorage.setItem(
            "permit",
            response?.data?.data?.session?.access_token
          );

          let _proceed = handleAuthorization(
            response?.data?.data?.session?.access_token
          );

          successPermit(response);
          console.log("PROCEED: ", _proceed);

          if (
            _proceed.authorized_token &&
            _proceed.authorized_dept.toLocaleLowerCase().includes("front") &&
            (_proceed.authorized_roles == "ROLE_SUPERVISOR" ||
              _proceed.authorized_roles == "ROLE_MANAGER")
          ) {
            // fetchDataAll(_proceed);
            setPermitted(true);
            console.log("is_compl", is_compliment?.label);
          }
          setLoading(false);
        } else {
          failedPermit(response);
          setLoading(false);
        }

        // successPermit(response);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Permit Err: ", error);

        failedPermit(error);
        setLoading(false);
      },
    });
  };

  // Handle Open
  const handleOpen = (rec) => {
    const record = rec;
    const token = handleAuthorization(auth_token);

    setOpen(true);
    setSelected(record);

    // console.log("Token: ", authorized_token);
    // console.log("By: ", authorized_by);
    // console.log("Dept: ", authorized_dept);
    console.log("Roles: ", authorized_roles, is_authorized, permitted);

    if (is_authorized) {
      if (
        token &&
        authorized_dept.toLowerCase().includes("front") &&
        (authorized_roles == "ROLE_SUPERVISOR" ||
          authorized_roles == "ROLE_MANAGER")
      ) {
        setPermitted(true);
        // is_permit(true);
      } else {
        setPermitted(false);
      }
    } else {
      setPermitted(true);
    }

    handleFormFields(record);
  };

  // Handle Form Fields
  const handleFormFields = (val) => {
    const record = val;

    const comply =
      record?.main_compliment_id == 2
        ? "No Compliment"
        : record?.main_compliment_id == 1
        ? "Compliment"
        : "House Uses";

    console.log("record", record);

    formAdd.setFieldsValue({
      main_segment_name: record?.main_segment_name,
      main_segment_id: record?.main_segment_id,
      main_segment_code: record?.main_segment_code,
      compliment_type: comply,
      room_category_code: record?.room_category_code,
      room_category_name: record?.room_category_name,
      room_category_id: record?.room_category_id,
    });
  };

  // Columns
  const columns = [
    {
      title: "Arrival",
      dataIndex: "date",
      key: "date",
      width: 150,
      render: (date, record) => {
        // console.log("DATE: ", date);
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      width: 150,
      render: (date, record) => {
        // console.log("DATE: ", date);
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
      width: 150,
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      width: 150,
      align: "right",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Room Details",
      dataIndex: "room_details",
      key: "room_details",
      align: "center",
    },
  ];

  // Columns
  const nestedColumns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            {
              <Row
                className="action-table-btn"
                justify="center"
                style={{ width: "100%" }}
              >
                <Col span={12}>
                  <Tooltip title="Change Rate" className="change-tooltip">
                    <FiEdit
                      className="change-btn"
                      onClick={() => {
                        handleOpenChange(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>

                <Col span={12}>
                  <Tooltip title="Add Rate" className="add-tooltip">
                    <PlusCircleFilled
                      className="Add-btn"
                      onClick={() => {
                        console.log("ABOUT TO ADD > ", record);

                        if (is_dynamic) {
                          handleOpen(record);
                        } else {
                          NotificationDynamics();
                        }
                      }}
                      style={{
                        fontSize: 20,
                        color: "#3be0d8",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            }
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      render: (date, record) => {
        // console.log("DATE: ", date);
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
      width: 150,
    },
    {
      title: `Rate ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      width: 150,
      align: "right",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Room Details",
      dataIndex: "room_details",
      key: "room_details",
      align: "center",
    },
    {
      title: "Rate Code",
      dataIndex: "rate_code",
      key: "rate_code",
      width: 150,
      align: "center",
    },
  ];

  // Handle Open Change Rate Code
  const handleOpenChange = (value) => {
    console.log("handleOpenChange value", value);

    setSelected(value);

    if (is_dynamic) {
      setModalChangeOpen(true);
    } else {
      NotificationDynamics();
    }
  };

  // Handle Room Details
  const handleFinalRoomDetails = (datas) => {
    console.log("Data Final Mut Source ->> ", datas);
    const finalDetailRoom = datas.flatMap((data, index) => {
      return data.details;
    });

    console.log("Final Detail Room ->> ", finalDetailRoom);
    getFinalDetailRoomList(finalDetailRoom);
  };

  // Handle Change Rate Code
  const getChange = (val) => {
    console.log("value get change ->> ", val);
    console.log("mutsource data ->> ", mutSource);

    if (val.apply_all_night === true) {
      // Apply to All Night
      const updateMutSource = mutSource.map((item, index) => {
        let arrDetails = [];

        for (let i = 0; i < night; i++) {
          if (item.parent_key === val.parent_key) {
            arrDetails.push({
              ...item.details[i],
              room_details: val.room_breakfast > 0 ? "Breakfast" : "Room Only",
              rate_code_id: val.id,
              rate_code: val.rate_code,
              price: val.gross,
              gross: val.gross,
              tax: val.tax,
              service: val.service,
              nett: val.nett,
              room_rate_id: [val?.rate_code, val?.main_segment_id, val?.id],
              room_breakfast: val?.room_breakfast > 0 ? val.room_breakfast : 0,
            });
          } else {
            arrDetails.push({
              ...item.details[i],
            });
          }
        }

        console.log("TEEEESSSSS ->> ", arrDetails);

        return {
          ...item,
          total_price:
            arrDetails.length > 1
              ? arrDetails.reduce((prev, curr) => prev + curr.price, 0)
              : arrDetails[0].price,
          room_details: arrDetails.some(
            (detail) => detail.room_details === "Breakfast"
          )
            ? "Breakfast"
            : "Room Only",
          details: arrDetails,
        };
      });

      console.log("Change to All Night: ", updateMutSource);
      setMutSource(updateMutSource);
      getFinalRoomList(updateMutSource);
      handleFinalRoomDetails(updateMutSource);
    } else if (val.apply_all_category === true) {
      // Apply to All Category
      const updateMutSource = mutSource.map((item, index) => {
        let arrDetails = [];

        for (let i = 0; i < night; i++) {
          if (item?.room_category_id === val?.room_category_id) {
            arrDetails.push({
              ...item.details[i],
              room_details: val.room_breakfast > 0 ? "Breakfast" : "Room Only",
              rate_code_id: val.id,
              rate_code: val.rate_code,
              price: val.gross,
              gross: val.gross,
              tax: val.tax,
              service: val.service,
              nett: val.nett,
              room_rate_id: [val?.rate_code, val?.main_segment_id, val?.id],
              room_breakfast: val?.room_breakfast > 0 ? val.room_breakfast : 0,
            });
          } else {
            arrDetails.push({
              ...item.details[i],
            });
          }
        }

        console.log("TEEEESSSSS ->> ", arrDetails);

        return {
          ...item,
          total_price:
            arrDetails.length > 1
              ? arrDetails.reduce((prev, curr) => prev + curr.price, 0)
              : arrDetails[0].price,
          room_details: arrDetails.some(
            (detail) => detail.room_details === "Breakfast"
          )
            ? "Breakfast"
            : "Room Only",
          details: arrDetails,
        };
      });

      console.log("Change All Category: ", updateMutSource);
      setMutSource(updateMutSource);
      getFinalRoomList(updateMutSource);
      handleFinalRoomDetails(updateMutSource);
    } else {
      // Apply only To One Selected
      const updateMutSource = mutSource.map((item, index) => {
        let arrDetails = [];

        for (let i = 0; i < night; i++) {
          if (item.details[i].key === val.key_selected) {
            arrDetails.push({
              ...item.details[i],
              room_details: val.room_breakfast > 0 ? "Breakfast" : "Room Only",
              rate_code_id: val.id,
              rate_code: val.rate_code,
              price: val.gross,
              gross: val.gross,
              tax: val.tax,
              service: val.service,
              nett: val.nett,
              room_rate_id: [val?.rate_code, val?.main_segment_id, val?.id],
              room_breakfast: val?.room_breakfast > 0 ? val.room_breakfast : 0,
            });
          } else {
            arrDetails.push({
              ...item.details[i],
            });
          }
        }

        console.log("TEEEESSSSS ->> ", arrDetails);

        return {
          ...item,
          total_price:
            arrDetails.length > 1
              ? arrDetails.reduce((prev, curr) => prev + curr.price, 0)
              : arrDetails[0].price,
          room_details: arrDetails.some(
            (detail) => detail.room_details === "Breakfast"
          )
            ? "Breakfast"
            : "Room Only",
          details: arrDetails,
        };
      });

      console.log("UpdateMutSource", updateMutSource);
      setMutSource(updateMutSource);
      getFinalRoomList(updateMutSource);
      handleFinalRoomDetails(updateMutSource);
    }
  };

  // Handle New Rate
  const handleNewRate = (val) => {
    const newRate = val;

    console.log("New Rate: ", newRate);

    if (apply) {
      // IF Apply to Category is CHECKED
      const updateMutSource = mutSource.map((item, index) => {
        let arrDetails = [];

        for (let i = 0; i < night; i++) {
          if (item?.room_category_id === val.room_category_id) {
            arrDetails.push({
              ...item.details[i],
              room_details:
                newRate.room_breakfast > 0 ? "Breakfast" : "Room Only",
              rate_code_id: newRate.id,
              rate_code: newRate.rate_code,
              price: newRate.gross,
              gross: newRate.gross,
              tax: newRate.tax,
              service: newRate.service,
              nett: newRate.nett,
              room_rate_id: [
                newRate?.rate_code,
                newRate?.main_segment_id,
                newRate?.id,
              ],
              room_breakfast:
                newRate?.room_breakfast > 0 ? newRate.room_breakfast : 0,
            });
          } else {
            arrDetails.push({
              ...item.details[i],
            });
          }
        }

        console.log("Selected: ", arrDetails);

        return {
          ...item,
          total_price:
            arrDetails.length > 1
              ? arrDetails.reduce((prev, curr) => prev + curr.price, 0)
              : arrDetails[0].price,
          room_details: arrDetails.some(
            (detail) => detail.room_details === "Breakfast"
          )
            ? "Breakfast"
            : "Room Only",
          details: arrDetails,
        };
      });

      console.log("Apply to All Room Category: ", updateMutSource);
      setMutSource(updateMutSource);
      getFinalRoomList(updateMutSource);
      handleFinalRoomDetails(updateMutSource);
    } else if (applyAll) {
      // IF Apply to ALL is CHECKED
      const updateMutSource = mutSource.map((item, index) => {
        let arrDetails = [];

        for (let i = 0; i < night; i++) {
          // if (item?.room_category_id === newRate?.room_category_id) {
          arrDetails.push({
            ...item.details[i],
            room_details:
              newRate?.room_breakfast > 0 ? "Breakfast" : "Room Only",
            rate_code_id: newRate?.id,
            rate_code: newRate?.rate_code,
            price: newRate?.gross,
            gross: newRate?.gross,
            tax: newRate?.tax,
            service: newRate?.service,
            nett: newRate?.nett,
            room_rate_id: [
              newRate?.rate_code,
              newRate?.main_segment_id,
              newRate?.id,
            ],
            room_breakfast:
              newRate?.room_breakfast > 0 ? newRate.room_breakfast : 0,
          });
          // } else {
          //   arrDetails.push({
          //     ...item.details[i],
          //   });
          // }
        }

        console.log("Selected: ", arrDetails);

        return {
          ...item,
          total_price:
            arrDetails.length > 1
              ? arrDetails.reduce((prev, curr) => prev + curr.price, 0)
              : arrDetails[0].price,
          room_details: arrDetails.some(
            (detail) => detail.room_details === "Breakfast"
          )
            ? "Breakfast"
            : "Room Only",
          details: arrDetails,
        };
      });

      console.log("Create All: ", updateMutSource);
      setMutSource(updateMutSource);
      getFinalRoomList(updateMutSource);
      handleFinalRoomDetails(updateMutSource);
    } else if (allNight) {
      // IF Apply to All Night
      console.log("Selected: ", selected);

      const updateMutSource = mutSource.map((item, index) => {
        let arrDetails = [];

        for (let i = 0; i < night; i++) {
          if (item.parent_key === selected.parent_key) {
            arrDetails.push({
              ...item.details[i],
              room_details:
                newRate.room_breakfast > 0 ? "Breakfast" : "Room Only",
              rate_code_id: newRate.id,
              rate_code: newRate.rate_code,
              price: newRate.gross,
              gross: newRate.gross,
              tax: newRate.tax,
              service: newRate.service,
              nett: newRate.nett,
              room_rate_id: [
                newRate?.rate_code,
                newRate?.main_segment_id,
                newRate?.id,
              ],
              room_breakfast:
                newRate?.room_breakfast > 0 ? newRate.room_breakfast : 0,
            });
          } else {
            arrDetails.push({
              ...item.details[i],
            });
          }
        }

        console.log("Changes ->> ", arrDetails);

        return {
          ...item,
          total_price:
            arrDetails.length > 1
              ? arrDetails.reduce((prev, curr) => prev + curr.price, 0)
              : arrDetails[0].price,
          room_details: arrDetails.some(
            (detail) => detail.room_details === "Breakfast"
          )
            ? "Breakfast"
            : "Room Only",
          details: arrDetails,
        };
      });

      console.log("Apply to All Night: ", updateMutSource);

      setMutSource(updateMutSource);
      getFinalRoomList(updateMutSource);
      handleFinalRoomDetails(updateMutSource);
    } else {
      // IF Apply to Category or Apply to All not CHECKED
      console.log("Selected: ", selected);

      const updateMutSource = mutSource.map((item, index) => {
        let arrDetails = [];

        for (let i = 0; i < night; i++) {
          if (item.details[i]?.key === selected.key) {
            arrDetails.push({
              ...item.details[i],
              room_details:
                newRate.room_breakfast > 0 ? "Breakfast" : "Room Only",
              rate_code_id: newRate.id,
              rate_code: newRate.rate_code,
              price: newRate.gross,
              gross: newRate.gross,
              tax: newRate.tax,
              service: newRate.service,
              nett: newRate.nett,
              room_rate_id: [
                newRate?.rate_code,
                newRate?.main_segment_id,
                newRate?.id,
              ],
              room_breakfast:
                newRate?.room_breakfast > 0 ? newRate.room_breakfast : 0,
            });
          } else {
            arrDetails.push({
              ...item.details[i],
            });
          }
        }

        console.log("Changes ->> ", arrDetails);

        return {
          ...item,
          total_price:
            arrDetails.length > 1
              ? arrDetails.reduce((prev, curr) => prev + curr.price, 0)
              : arrDetails[0].price,
          room_details: arrDetails.some(
            (detail) => detail.room_details === "Breakfast"
          )
            ? "Breakfast"
            : "Room Only",
          details: arrDetails,
        };
      });

      console.log("Apply to Selected Row: ", updateMutSource);

      setMutSource(updateMutSource);
      getFinalRoomList(updateMutSource);
      handleFinalRoomDetails(updateMutSource);
    }
  };

  // Fetch Data
  const fetchDataRate = async (value) => {
    // console.log(segmentID);
    console.log("Value on Fetch Data", value);

    await getMasterRateSegment({
      segment_compl: "master-segment",
      segment: value?.main_segment_id > 0 ? value?.main_segment_id : null,
      onAwait: () => {
        setLoading(true);
        getRefreshRate(0);
      },
      onSuccess: (response) => {
        console.log("Rate Code => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let nameFiltered = filtered.filter((x) => {
            if (value?.rate_code === x.rate_code) {
              return x;
            }
          });

          let priceFiltered = nameFiltered.filter((x) => {
            if (
              value?.nett === x.nett &&
              value?.service === x.service &&
              value?.tax === x.tax &&
              value?.gross === x.gross
            ) {
              return x;
            }
          });
          if (apply) {
            let updateSelectedCatMutSource = mutSource.map((x) => {
              if (x.room_category_id === priceFiltered[0].room_category_id) {
                console.log("x.id", x.id, "selected", selected);
                return {
                  ...x,
                  rate_code_id: priceFiltered[0].id,
                  price: priceFiltered[0].gross,
                  gross: priceFiltered[0].gross,
                  tax: priceFiltered[0].tax,
                  service: priceFiltered[0].service,
                  nett: priceFiltered[0].nett,
                };
              } else {
                return x;
              }
            });
            console.log(
              `updateSelectedCatMutSource = ${updateSelectedCatMutSource}`
            );
            setMutSource(updateSelectedCatMutSource);
          } else if (applyAll) {
            let updateAllMutSource = mutSource.map((x) => {
              return {
                ...x,
                rate_code_id: priceFiltered[0].id,
                price: priceFiltered[0].gross,
                gross: priceFiltered[0].gross,
                tax: priceFiltered[0].tax,
                service: priceFiltered[0].service,
                nett: priceFiltered[0].nett,
              };
            });
            console.log(`updateAllMutSource = ${updateAllMutSource}`);

            setMutSource(updateAllMutSource);
          } else {
            let updateSelectedMutSource = mutSource.map((x) => {
              if (x.id === selected) {
                console.log("x.id", x.id, "selected", selected);
                return {
                  ...x,
                  rate_code_id: priceFiltered[0].id,
                  price: priceFiltered[0].gross,
                  gross: priceFiltered[0].gross,
                  tax: priceFiltered[0].tax,
                  service: priceFiltered[0].service,
                  nett: priceFiltered[0].nett,
                };
              } else {
                return x;
              }
            });
            console.log("UpdateSelectedMutSource", updateSelectedMutSource);

            setMutSource(updateSelectedMutSource);
          }

          // setData(filtered);
          console.log("priceFiltered", priceFiltered);
          getRefreshRate(value?.main_segment_id);
        } else {
          // setData([]);
          console.log("Failed priceFiltered");
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error = ", error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Get Room Category Code
  const onChangeRoomCat = (value, code) => {
    const _value = value;
    console.log("Room Code: ", _value);
    // console.log(code);

    formAdd.setFieldsValue({
      room_category_name: _value?.name,
      room_category_code: _value?.code,
      room_category_id: _value?.cat_id,
    });
  };

  // SET GROSS PRICE
  const setGross = async (netto) => {
    const _service = percentages.service;
    const _tax = percentages.tax;
    let _net = netto;
    let _gross = 0;

    // console.log("Nett Price = ", _net);

    let _srv = parseInt(_net * _service);
    let _taxs = parseInt((_net + _srv) * _tax);

    _gross = _srv + _taxs + _net;

    // console.log("Gross Price = ", _gross);

    let _newPrice = Pricing({
      nett: _net || 0,
      tax: percentages.tax || 0,
      service: percentages.service || 0,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient,
    });

    // console.log("Gross Price Results = ", _newPrice);

    formAdd.setFieldsValue({
      gross: _newPrice.gross,
      tax: _newPrice.taxes,
      service: _newPrice.services,
    });
  };

  // Handle Gross
  const handleGross = (val) => {
    const _gross = val;

    console.log("Gross: ", _gross);

    const _grossing = Grossing({
      gross: _gross,
      tax: percentages.tax,
      service: percentages.service,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient_tax_service,
    });

    console.log("Results: ", _grossing);

    // handleGrossing(_grossing.nett, isService, isTax);
    formAdd.setFieldsValue({
      nett: _grossing.nett,
      tax: _grossing.taxes,
      service: _grossing.services,
    });
  };

  // ON FINISH ADD RATE CODE
  const onFinishAdd = (e) => {
    setButtonLoading(true);

    const _content = e;
    let str = _content.rate_code;

    console.log(_content);
    // handleSubmit(e);

    _content.rate_code = str.toUpperCase();

    if (!_content.description) {
      _content.description = `${
        _content?.room_category_name || "GROUP"
      } ROOM for ${_content.room_occupancy} Adult(s) and ${
        _content.room_breakfast
      } Pax Breakfast(s)`;
    }

    showModalConfirm(_content);
  };

  // ON FINISH ADD RATE CODE
  const onFinishFailedAdd = (e) => {
    const _content = e?.values;
    console.log("FAILED: ", _content);
  };

  // SHOW MODAL DELETE
  const showModalConfirm = async (record) => {
    let addContent = record;

    console.log("In Modal = ", addContent);

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to Add a New Rate Code named ${addContent?.rate_code.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(addContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setButtonLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // NOTIFICATION DYNAMIC is FALSE
  const NotificationDynamics = () => {
    notification.open({
      message: null, // ,
      description: (
        <>
          <Row align="middle" className="login-notif" style={{ width: "100%" }}>
            <Col span={6}>
              <IoClose className="success-icon" style={{ fontSize: 50 }} />
            </Col>

            <Col span={17}>
              <Row
                className="dynamic-notif"
                style={{
                  width: "100%",
                  fontSize: 15,
                  fontWeight: 500,
                }}
              >
                {<div style={{ color: "#FFFFFF" }}>{"Rate Unchangeable!"}</div>}
              </Row>

              <Row
                className="failed-notif"
                style={{ width: "100%", fontSize: 12 }}
              >
                {`This Segments & Rate Code is can not be changed! `}
              </Row>

              <Row
                className="failed-notif"
                style={{ width: "100%", fontSize: 12 }}
              >
                {`Please, choose another Segments or Call Your Superior to change the Settings of the Segments!`}
              </Row>
            </Col>
          </Row>
        </>
      ),
      className: "dynamic-rate-notif",
      maxCount: 2,
      closeIcon: (
        <IoClose
          className="close-notif-icon"
          style={{ color: "#FFF", fontSize: 20 }}
        />
      ),
      placement: "top",
      duration: 30,
      style: failedMaster,
    });
  };

  // Handle Close
  const handleClose = () => {
    formAdd.resetFields();

    setApply(false);
    setApplyAll(false);
    setAllNight(false);
    setSelected(null);

    setOpen(false);
    // setPermitted(false);
  };

  // Handle Close
  const handleCloseChange = (val) => {
    setModalChangeOpen(false);
    setSelected(null);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentSubmit = e;

    console.log("ON SUBMIT ==> ", contentSubmit);
    // console.log("POST OR PUT ==> ", _obj);

    await axios
      .post(
        `${baseurl}/room/rate-code`,
        {
          rate_code: contentSubmit.rate_code || null,
          main_segment_id: contentSubmit.main_segment_id || null,
          main_segment_code: contentSubmit?.main_segment_code || null,
          main_segment_name: contentSubmit?.main_segment_name || null,
          description: contentSubmit.description || null,
          room_category_code: contentSubmit?.room_category_code || null,
          room_category_name: contentSubmit?.room_category_name || null,
          room_category_id: contentSubmit?.room_category_id || null,
          room_breakfast:
            contentSubmit?.room_breakfast > 0
              ? contentSubmit.room_breakfast
              : 0,
          room_occupancy:
            contentSubmit?.room_occupancy > 0
              ? contentSubmit.room_occupancy
              : 0,
          nett: contentSubmit?.nett > 0 ? contentSubmit.nett : 0,
          service: parseInt(contentSubmit.service),
          tax: parseInt(contentSubmit.tax),
          gross: contentSubmit?.gross > 0 ? contentSubmit.gross : 0,
          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Add Rate Code => ", response);

        if (response.data?.msg) {
          const _new = response.data.msg;

          setButtonLoading(false);
          masterSuccessNotification(response, {
            method: 0,
            source: "Rate Code",
          });

          handleClose();
          handleNewRate(_new);
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: "Rate Code",
          });
        }
      })
      .catch((error) => {
        console.log("Error Rate Code => ", error);

        masterFailedNotification(error, { method: 0, source: "Rate Code" });
        setButtonLoading(false);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  // Apply to CATEGORY
  const handleCheckApply = (e) => {
    console.log(`e handleCheckApply = ${e.target.checked}`);
    setApply(e.target.checked);
  };

  // APPLY to ALL
  const handleCheckApplyAll = (e) => {
    console.log(`e handleCheckApply = ${e.target.checked}`);
    setApplyAll(e.target.checked);
  };

  // APPLY to ALL Night
  const handleAllNight = (e) => {
    const _checked = e.target.checked;

    console.log(`Handle Night = ${_checked}`);

    setAllNight(_checked);
  };

  return (
    <>
      <Row gutter={30}>
        <Col span={24} style={{ marginRight: 30 }}>
          <Table
            className="price-table"
            name="price-table"
            key="price-table"
            dataSource={mutSource}
            columns={columns}
            size="middle"
            bordered={true}
            loading={loading}
            expandable={{
              // rowExpandable: (record) => true,
              // defaultExpandAllRows: true,
              expandedRowRender: (record) => {
                // console.log(record.details);
                return (
                  <Table
                    columns={nestedColumns}
                    dataSource={record?.details}
                    pagination={false}
                    size="small"
                    key={"price-table"}
                    rowKey={(record) => {
                      return record.key;
                    }}
                    rowClassName={(record, index) => {
                      return index % 2 == 0 ? "even-rate" : "odd-rate";
                    }}
                  />
                );
              },
            }}
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            rowKey={(record) => {
              return record.id ? record.id : record.key;
            }}
            rowClassName={(record, index) => {
              return index % 2 == 0 ? "odd" : "even";
            }}
          />
        </Col>
      </Row>

      <Modal
        className="modal-permissions"
        title={
          permitted ? (
            <>
              <Row>
                <PlusCircleTwoTone style={{ fontSize: 24 }} />
                <Typography
                  style={{ marginLeft: 15 }}
                >{`Add Rate Code`}</Typography>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <SafetyCertificateOutlined
                  style={{ color: "#F64E60", fontSize: 24 }}
                />
                <Typography style={{ marginLeft: 15 }}>
                  {`Authorization Requests`}
                </Typography>
              </Row>
            </>
          )
        }
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={800}
      >
        {permitted ? (
          <>
            {/* <Result
                className="permit-results"
                status={"success"}
                title={"Access Granted!"}
              /> */}
            <Form
              name="add-edit-rate-code-modal-form"
              className="add-edit-rate-code-modal-form"
              autoComplete="off"
              form={formAdd}
              layout="vertical"
              onFinish={onFinishAdd}
              initialValues={{
                nett: 0,
                room_occupancy: null,
                room_breakfast: null,
                apply: false,
                applyAll: false,
              }}
              onFinishFailed={onFinishFailedAdd}
              style={{ padding: "15px 36px 0px" }}
            >
              <Row gutter={30} className="row-rate-code-id">
                <Col span={16}>
                  <Form.Item
                    label="Main Segment"
                    name="main_segment_name"
                    style={{
                      minWidth: "100%",
                    }}
                  >
                    <Input placeholder="Main Segment Name" disabled />
                  </Form.Item>

                  <Form.Item
                    label="Main Segment ID"
                    name="main_segment_id"
                    hidden={true}
                  >
                    <Input placeholder="Main Segment ID" disabled />
                  </Form.Item>

                  <Form.Item
                    label="Main Segment Code"
                    name="main_segment_code"
                    hidden={true}
                  >
                    <Input placeholder="Main Segment Code" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Compliment Type"
                    name="compliment_type"
                    style={{
                      minWidth: "100%",
                    }}
                  >
                    <Input placeholder="Compliment Type" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row gutter={30} className="row-rate-code-id">
                <Col className="room-cat-col" span={8}>
                  <Form.Item label="Room Category" name="room_category_name">
                    <Input disabled placeholder="Room Category" />
                  </Form.Item>

                  <Form.Item
                    label="Room Category Code"
                    name="room_category_code"
                    hidden
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Room Category ID"
                    name="room_category_id"
                    hidden
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>

                <Col className="rate-code-col" span={16}>
                  <Form.Item
                    label="Rate Code"
                    name="rate_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input the Rate Code Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Rate Code Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="price-row">
                <Col className="nett-col" span={8}>
                  <Form.Item
                    label="Nett Price"
                    name="nett"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Nett Price!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Nett Price"
                      onChange={setGross}
                      min={0}
                      addonBefore={currency}
                      formatter={(price) => {
                        return `${price > 0 ? price : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col className="service-col" span={8}>
                  <Form.Item label="Service" name="service">
                    <InputNumber
                      placeholder="Service"
                      min={0}
                      addonBefore={currency}
                      disabled
                      formatter={(price) => {
                        return `${price > 0 ? price : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col className="tax-col" span={8}>
                  <Form.Item label="Tax" name="tax">
                    <InputNumber
                      min={0}
                      addonBefore={currency}
                      placeholder="Tax"
                      disabled
                      formatter={(price) => {
                        return `${price > 0 ? price : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="occ-row">
                <Col className="gross-col" span={8}>
                  <Form.Item
                    label="Gross"
                    name="gross"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please, Input the Nett Price to Show the Gross Price!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Gross"
                      addonBefore={currency}
                      onChange={handleGross}
                      min={0}
                      formatter={(price) => {
                        return `${price > 0 ? price : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col className="occupants-col" span={8}>
                  <Form.Item
                    label="Occupants"
                    name="room_occupancy"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input the Numbers of Room Occupant!",
                      },
                      {
                        validator: async (_, value) => {
                          const _bf = formAdd.getFieldValue("room_breakfast");

                          // console.log("Values: ", _, value);
                          // console.log("Values BF: ", _bf);

                          if (value < _bf) {
                            return Promise.reject(
                              new Error(
                                "The Numbers of Room Occupant can not be less than the total number of Breakfasts!"
                              )
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      placeholder="Room Occupancies"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col className="bf-col" span={8}>
                  <Form.Item
                    label="Total Breakfast"
                    name="room_breakfast"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input the Amounts of Breakfast!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Breakfast Counts"
                      min={0}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="descriptions-row" gutter={30}>
                <Col className="desc-col" span={16}>
                  <Form.Item label="Descriptions" name="description">
                    <Input.TextArea
                      placeholder="Rate Code Descriptions"
                      maxLength={300}
                      showCount
                      rows={3}
                      style={{ width: "100%", height: 100 }}
                    />
                  </Form.Item>
                </Col>

                <Col className="apply-col" span={8}>
                  <Form.Item
                    name="apply"
                    valuePropName="checked"
                    style={{
                      marginTop: "25px",
                    }}
                  >
                    <Checkbox
                      onChange={handleCheckApply}
                      disabled={applyAll || allNight}
                    >
                      {`Apply to Selected Room Category`}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="applyAll"
                    valuePropName="checked"
                    style={{
                      marginTop: "25px",
                    }}
                  >
                    <Checkbox
                      onChange={handleCheckApplyAll}
                      disabled={apply || allNight}
                    >
                      {`Apply to All Room`}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="apply_night"
                    valuePropName="checked"
                    style={{
                      marginTop: "25px",
                    }}
                  >
                    <Checkbox
                      onChange={handleAllNight}
                      disabled={night == 1 || apply || applyAll}
                    >
                      {`Apply to All Night`}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />
              <Row className="row-modal-btn" justify="end" align="middle">
                <Button
                  type="primary"
                  htmlType="submit"
                  key={"submit"}
                  disabled={buttonLoading}
                  loading={buttonLoading}
                  className="submit-btn"
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Form>
          </>
        ) : (
          <>
            <Form
              name="permissions-form"
              className="permissions-form"
              key="permissions-form"
              form={formAuth}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 16 }}
              //   wrapperCol={{ span: 12 }}
              autoComplete="off"
              layout="vertical"
              // initialValues={{
              //   room_modal: initialForm?.length > 0 ? initialForm : null,
              // }}
            >
              <Row
                className="email-row"
                gutter={30}
                align="middle"
                justify="center"
              >
                <Col
                  span={12}
                  className="content-col"
                  style={{
                    padding: 5,
                    border: "2px solid #F64E60",
                    borderRadius: 5,
                  }}
                >
                  <Row className="col-space" justify="center">
                    <FaExclamationTriangle
                      className="warning-icon"
                      style={{
                        fontSize: 50,
                        color: "#EE9D01",
                        margin: "0px 30px 0px",
                      }}
                    />
                  </Row>

                  <Row className="col-space" justify="center">
                    <Typography
                      className="permit-text"
                      style={{
                        fontWeight: 750,
                        fontSize: 18,
                        textAlign: "justify",
                        padding: "5px 10px",
                      }}
                    >
                      {`This action required Permission and Approval from above the Staff Roles to Proceed! Please, Contact your Superior to Make the Request!`}
                    </Typography>
                  </Row>
                </Col>

                <Col span={12} className="content-col">
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    style={{
                      width: 250,
                    }}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                    style={{
                      width: 250,
                    }}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="modal-btn-row" gutter={30} justify="end">
                <Button
                  className="submit-btn"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{
                    margin: "0px 30px 0px 0px",
                    backgroundColor: "#0BB7AF",
                    borderColor: "#0BB7AF",
                  }}
                >
                  {`Confirm`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Form>
          </>
        )}
      </Modal>

      <ChangeRateCodeModal
        is_open={modalChangeOpen}
        is_close={handleCloseChange}
        optionValue={optionValue}
        selected={selected}
        getChange={getChange}
        night={night}
      />
    </>
  );
}

// Import React's Components
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import {
  Alert,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Result,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";

// CODE
export default function ModalGuestHistory(props) {
  // PROPS
  const { is_open, is_close, guest_id } = props;

  // PARAMS
  const currency = CurrencySymbols().code;

  // Segment
  const [segmentation, setSegmentation] = useState(null);
  const { state } = useLocation();

  // CONTEXT
  const { getMasterVisit } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [dataRes, setDataRes] = useState([]);
  const [dataArticle, setDataArticle] = useState([]);
  // Guest ID
  const [guestData, setGuestData] = useState(null);
  // Visited
  const [visited, setVisited] = useState(null);
  // Boolean State
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // Error/Warning Alert
  const [messageLedger, setMessageLedger] = useState(false);

  // COLUMNS
  const columnRes = [
    {
      title: "No",
      dataIndex: "",
      key: "",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Room Cat",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => {
        return `${moment(record.arrival).format("DD MMM")} to ${moment(
          record.departure
        ).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Rate Code",
      dataIndex: "rate_code",
      key: "rate_code",
    },
    {
      title: "Breakfast",
      dataIndex: "is_abf",
      key: "is_abf",
      render: (abf, record) => {
        return (
          <Tag
            className="breakfast-arr"
            color={abf === true ? "success" : "error"}
          >
            {abf ? "Breakfast" : "Room Only"}
          </Tag>
        );
      },
    },
  ];

  const columnArticle = [
    {
      title: "No",
      dataIndex: "",
      key: "",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Total Ordered",
      dataIndex: "total_transactions",
      key: "total_transactions",
    },
  ];

  // FETCH DATA
  const fetchData = async () => {
    await getMasterVisit({
      //   type: "total-history",
      type: "history",
      guest_id: guest_id?.id || state?.id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Res: ", response);

        if (response?.data?.length > 0) {
          let _response = response.data;

          setDataRes(_response);
        } else {
          setDataRes([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("On Rejects: ", error);

        setIsError(true);
        failedFetch(error);
        setLoading(false);
      },
    });

    await getMasterVisit({
      type: "total-history",
      guest_id: guest_id?.id || state?.id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Visit: ", response);

        if (response?.data?.length > 0) {
          let _response = response.data;

          setVisited(_response[0]);
          // setMessageLedger(_response[0])
        } else {
          setVisited(null);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("On Rejects Visits: ", error);
        setLoading(false);
      },
    });

    await getMasterVisit({
      type: "article-history",
      guest_id: guest_id?.id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Article History: ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          handleArticleHistory(_response);
        } else {
          setVisited(null);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("On Rejects Visits: ", error);
        setLoading(false);
        setIsError(true);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (guest_id && !cleanUp) {
      console.log("Guest: ", guest_id);
      setGuestData(guest_id);

      fetchData();

      if (guest_id.is_allow === true) {
        setMessageLedger(true);
      } else {
        setMessageLedger(false);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [guest_id]);

  // USE EFFECT STATE/SEGMENT
  useEffect(() => {
    let cleanup = false;

    if (state && !cleanup) {
      setSegmentation(state?.state?.segment);
    }

    // console.log("STATE >>>>>", state);
    return () => {
      cleanup = true;
    };
  }, [state]);

  // Handle Article History
  const handleArticleHistory = (value) => {
    const _res = value;
    let _id = 1;

    let _mapped = _res.map((val) => ({
      id: _id++,
      ...val,
    }));

    console.log("Mapped: ", _mapped);

    setDataArticle(_mapped);
  };

  // Handle Close Modal
  const handleClose = () => {
    setOpen(false);
    is_close(false);

    setDataRes([]);
    setDataArticle([]);

    setGuestData(null);
  };

  return (
    <>
      <Modal
        className="guest-history-modals"
        key="guest-history-modals"
        title={
          <>
            <Space size={"large"} className="title-modal-row">
              <ExclamationCircleOutlined
                className="icon-title-modal"
                style={{
                  color: "#1890FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="title-modal-text"
                style={{
                  color: "#1890FF",
                  //   fontSize: 24,
                }}
              >
                {`Guest Visit History`}
              </Typography>
            </Space>
          </>
        }
        centered
        open={is_open}
        bodyStyle={{
          padding: 30,
        }}
        closable
        onCancel={handleClose}
        footer={null}
        width={"auto"}
      >
        <Form
          className="geust-form"
          key="geust-form"
          name="geust-form"
          labelAlign="left"
          labelCol={{
            span: 12,
          }}
          labelWrap={{
            span: 12,
          }}
          onFinish={handleClose}
          layout="horizontal"
          style={{
            minWidth: 800,
          }}
        >
          <Row className="identity-row">
            <Col span={24} className="identity-col">
              <Row className="ident-row" gutter={30}>
                <Col span={12} className="name-col">
                  <Form.Item
                    label="Name"
                    className="name-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="guest-name-text" style={textForm}>
                      {guestData?.value || ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Master Company"
                    className="mastercomp-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="master-comp-text" style={textForm}>
                      {guestData?.company_name || "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Segment"
                    className="segment-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="segment-text" style={textForm}>
                      {guestData?.segment_name?.length > 0
                        ? guestData.segment_name.join(", ")
                        : segmentation}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="count-col">
                  <Form.Item
                    label="Visited"
                    className="visit-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="guest-count-text" style={textForm}>
                      {visited?.visited > 0 ? visited?.visited : 0}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Birthday"
                    className="birthdate-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="guest-birth-text" style={textForm}>
                      {moment(guestData?.birthdate).format("DD MMM YYYY") || ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Nationality"
                    className="nat-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="guest-nation-text" style={textForm}>
                      {guestData?.nationality || ""}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Rate Code"
                className="ratecode-item"
                labelCol={{
                  span: 6,
                }}
                style={{ margin: 0 }}
              >
                <Typography className="rate-code-text" style={textForm}>
                  {guestData?.rate_code?.length > 0
                    ? guestData?.rate_code.join(", ")
                    : "-"}
                </Typography>
              </Form.Item>

              <Form.Item
                // label="Company Ledger Status"
                className="ledgerstatus-item"
                labelCol={{
                  span: 6,
                }}
                style={{ margin: "15px 0px" }}
              >
                <Alert
                  className="guest-alert"
                  // message="Alert!"
                  showIcon
                  description={
                    messageLedger
                      ? `Info! This guest has an Active/Approved Company Ledger with Credit Limit ${currency} ${guestData?.credit_limit}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )
                      : `Alert! This guest has no Active/Approved Company Ledger!`
                  }
                  type={messageLedger ? "info" : "error"}
                  style={{
                    textAlign: "center",
                  }}
                />
              </Form.Item>

              <Divider
                className="form-divider"
                orientation="left"
                style={{
                  margin: "10px 0px",
                  borderColor: "#EBEDF3",
                  fontWeight: "normal",
                  fontSize: 15,
                }}
              >
                {"Last Visited Data"}
              </Divider>

              <Row
                className="visited-table"
                justify="center"
                gutter={30}
                style={{
                  padding: "0px 15px",
                }}
              >
                {dataRes?.length > 0 ? (
                  <Table
                    className="guest-history-table"
                    key="guest-history-table"
                    name="guest-history-table"
                    columns={columnRes}
                    dataSource={dataRes}
                    bordered={true}
                    loading={loading}
                    size="small"
                    pagination={false}
                    rowKey={(record) =>
                      record?.id > 0 ? record.id : record.key
                    }
                  />
                ) : (
                  <Result
                    status={isError ? "error" : "info"}
                    className="result-history-guest"
                    title="No Data Found!"
                    subTitle={
                      isError
                        ? `Failed to get Data! Please, try again later!`
                        : `This is ${guestData?.value} First Visit!`
                    }
                  />
                )}
              </Row>

              <Divider
                className="form-divider"
                orientation="left"
                style={{
                  margin: "10px 0px 15px",
                  borderColor: "#EBEDF3",
                  fontWeight: "normal",
                  fontSize: 15,
                }}
              >
                {"Most Article Ordered"}
              </Divider>

              <Row
                className="article-table"
                justify="center"
                gutter={30}
                style={{
                  padding: "0px 15px 15px",
                }}
              >
                {dataArticle?.length > 0 ? (
                  <Table
                    className="article-history-table"
                    key="article-history-table"
                    name="article-history-table"
                    columns={columnArticle}
                    dataSource={dataArticle}
                    bordered={true}
                    loading={loading}
                    size="small"
                    pagination={false}
                    rowKey={(record) =>
                      record?.id > 0 ? record.id : record.key
                    }
                  />
                ) : (
                  <Result
                    status={isError ? "error" : "info"}
                    className="result-history-guest"
                    title="No Data Found!"
                    subTitle={
                      isError
                        ? `Failed to get Data! Please, try again later!`
                        : `This is ${guestData?.value} First Visit!`
                    }
                  />
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterReservationStatus({ getResStatus, res }) {
  // CONTEXT
  const { getMasterResStatus } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [status, setStatus] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterResStatus({
      status: "reservation-status",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Res Status => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let filtered = response.data.msg.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev.reservation_status,
            label: dev.reservation_status,
          }));

          console.log(option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  //   console.log(first);

  return (
    <>
      <Select
        key={"res-status-select"}
        className={"res-status-select"}
        value={status != null ? status : res}
        allowClear
        showSearch
        onChange={(e) => {
          setStatus(e);
          // console.log(e);

          getResStatus(e);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
        style={{ width: "100%" }}
      />
    </>
  );
}

// Import React's Components
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Antd Components
import { Select } from "antd";

// CODE
export default function MasterBudget(props) {
  // PROPS
  const { getBudgetData, budget, fromParams, is_disabled } = props;

  // CONTEXT
  const { getBudget } = useContext(FinanceContext);

  // State Management
  // Data
  const [budgetName, setBudgetName] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  // Filters Key
  const [headerFilter, setHeaderFilter] = useState(9);
  const [filtered, setFiltered] = useState([]);

  // FETCH DATA
  const fetchData = async () => {
    await getBudget({
      type: "budget",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let _filtered = _response.filter((filt) => {
            if (!filt.is_deleted && filt.status_budget == 5) {
              return filt;
            }
          });

          setFiltered(_filtered);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log("Failed Get Budget: ", error);
        // failedFetch(error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    if (fromParams && !cleanUp) {
      handleKey(fromParams);
      fetchData();
    }

    if (budget && !cleanUp) {
      console.log("Budget Name: ", budget);
      setBudgetName(budget);
    } else {
      setBudgetName(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [fromParams, budget]);

  useEffect(() => {
    let cleanUp = false;

    if (filtered.length > 0 && headerFilter >= 0 && !cleanUp) {
      handleFilter();
    }

    return () => {
      cleanUp = true;
    };
  }, [filtered, headerFilter]);

  // Headers Filter
  const handleKey = (value) => {
    const _keys = value;

    console.log("Keys: ", _keys);

    switch (_keys) {
      case "LABEL_PARAMS":
        setHeaderFilter(9);
        break;

      case "LABEL_REPORT":
        setHeaderFilter(0);
        break;

      case "LABEL_DETAIL":
        setHeaderFilter(1);
        break;

      default:
        break;
    }
  };

  // Handle On Change
  const handleChange = (value, allProps) => {
    const _label = value;
    const _allKey = allProps;

    console.log("Value: ", _label);
    console.log("KEYS: ", _allKey);

    setBudgetName(_label);

    getBudgetData({
      value: _allKey?.account_name,
      account_code: _allKey?.code,
      budget_id: _allKey?.budget_id,
    });
  };

  // Handle Filter
  const handleFilter = () => {
    const _res = filtered;

    console.log("Filter Key: ", headerFilter);

    let _filterByKeys = _res.filter((val) => {
      const _head = parseInt(val.account_code.slice(0, 1));

      if (headerFilter == 0) {
        return val.account_code?.length == 3;
      } else if (headerFilter == 1) {
        return val.account_code?.length > 3;
      } else {
        if (_head == headerFilter && val.account_code?.length > 3) {
          return val;
        }
      }
    });

    let _mapped = _filterByKeys.map((value) => ({
      label: `${value?.account_code} - ${value?.account_name} (${
        value?.year ? moment(value.year).format("YYYY") : "-"
      })`,
      value: `${value?.account_code} - ${value?.account_name} (${
        value?.year ? moment(value.year).format("YYYY") : "-"
      })`,
      // value: value?.id,
      code: value.account_code,
      budget_id: value?.id,
      keys: value?.id,
      account_name: value?.account_name,
    }));

    // console.log("Filter Head: ", _filterByKeys);
    // console.log("Mapped: ", _mapped);

    setOptionValue(_mapped);
  };

  // HANDLE CLEAR
  const handleClear = () => {
    setBudgetName(null);
  };

  return (
    <>
      <Select
        className="budget-selector"
        key="budget-selector"
        allowClear
        showSearch
        disabled={is_disabled}
        value={budgetName}
        options={optionValue}
        onChange={handleChange}
        onClear={handleClear}
        style={{
          width: "100%",
        }}
        placeholder="Choose COA Budget!"
      />
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import { roles, token } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  FaClipboardList,
  FaConciergeBell,
  FaEllipsisV,
  FaUserFriends,
} from "react-icons/fa";
import { TiClipboard } from "react-icons/ti";
import { TbClipboardCheck } from "react-icons/tb";
import { HiClipboardList } from "react-icons/hi";
import { HiEllipsisVertical } from "react-icons/hi2";
import { TfiMenuAlt } from "react-icons/tfi";
import {
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";

// Import Modal
import GuaranteeLetter from "../../Forms/GuaranteeLetter/Form";
import ReservationForm from "../../Forms/Reservation/Form";
import ReservationGroupForm from "../../Forms/Reservation/FormGroup";
import ModalAddArticle from "../../Modals/Article/Stay/Modal";

// Notification
import {
  failedAddReserv,
  successAddReserv,
} from "../../Reusable/Notification/Notification";
import CancellationModal from "../../Modals/Cancelations/Modal";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function ReservationPopover(props) {
  // PROPS
  const { details, is_refresh } = props;

  // STATE MANAGEMENT
  // Open Popover
  const [open, setOpen] = useState(false);
  const [dataRecord, setDataRecord] = useState([]);
  // Open Modal
  const [article, setArticle] = useState(false);
  // Open Form
  const [openResv, setOpenResv] = useState(false);
  const [openResGroup, setOpenResGroup] = useState(false);
  const [openGuarantee, setOpenGuarantee] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const location = useLocation();

  // Set Data
  // console.log("IN POP OVER = ", details);
  const detail = details;

  // ADD MEMBER
  const addMember = () => {
    navigate(`${location?.pathname}/add-member-group`, {
      state: { state: detail, type: 1 },
    });
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Tooltip title="Option Menu" className="reservation-tooltip">
        <Popover
          className="pop-over-reservation"
          name="pop-over-reservation"
          key="pop-over-reservation"
          placement="right"
          title={
            <Row>
              {/* <Col span={4}>
                <UnorderedListOutlined
                  className="popover-btn"
                  style={{
                    padding: "8px 8px 8px 0px",
                    fontSize: 14,
                    color: "#2f54eb",
                  }}
                />
              </Col> */}

              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  Reservation Option
                </Typography>
              </Col>
            </Row>
          }
          content={
            detail.group == 2 ? (
              <>
                <Row
                  className="add-article-row"
                  hidden={detail?.payment_status == 2 ? false : true}
                  justify="start"
                  style={{ width: 210 }}
                >
                  <Button
                    className="row-reserve-opt"
                    icon={
                      <FaConciergeBell
                        style={{
                          color: "#1BC5BD",
                          fontSize: 20,
                          margin: "0px 15px 0px",
                        }}
                      />
                    }
                    onClick={() => {
                      handleClose();
                      setDataRecord(detail);
                      setArticle(!article);
                    }}
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                      borderColor: "transparent",
                    }}
                  >
                    {`Add Article`}
                  </Button>
                </Row>

                {/*
                <Row justify="start" style={{ width: 210 }}>
                  <Button
                  className="row-reserve-opt"
                  icon={
                    <TiClipboard
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 210,
                    height: 40,
                  }}
                >
                  Room Revenue Detail
                </Button> */}

                {/* <Button
                    className="row-reserve-opt"
                    icon={
                      <TiClipboard
                        style={{
                          color: "#1BC5BD",
                          fontSize: 20,
                          margin: "0px 15px 0px",
                        }}
                      />
                    }
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                    }}
                    onClick={() => {
                      handleClose();
                      setDataRecord(detail);
                      setOpenResv(!openResv);
                    }}
                  >
                    {`Room Reservation Form`}
                  </Button> 
                </Row>
                  */}

                <Row
                  justify="start"
                  style={{ width: 210 }}
                  hidden={
                    detail?.reservation_status == "Guaranteed" ? true : false
                  }
                >
                  <Button
                    className="row-reserve-opt"
                    onClick={(e) => {
                      // console.log(e);
                      //   console.log(detail);
                      // showDeleteModal();
                      setCancelled(true);
                      setDataRecord(detail);
                      handleClose();
                    }}
                    icon={
                      <DeleteFilled
                        style={{
                          color: "#F64E60",
                          fontSize: 20,
                          margin: "0px 10px 0px 15px",
                        }}
                      />
                    }
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 210,
                      height: 40,
                      borderColor: "transparent",
                    }}
                  >
                    {`Cancel Reservation`}
                  </Button>
                </Row>
              </>
            ) : (
              <>
                <Row justify="start" style={{ width: 250 }}>
                  <Button
                    className="row-reserve-opt"
                    onClick={(e) => {
                      // console.log(e);
                      //   console.log(detail);
                      // showDeleteModal();
                      setDataRecord(detail);
                      addMember();
                    }}
                    icon={
                      <FaUserFriends
                        style={{
                          color: "#1BC5BD",
                          fontSize: 20,
                          margin: "0px 15px 0px",
                        }}
                      />
                    }
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                      borderColor: "transparent",
                    }}
                  >
                    {`Add Reservation Member`}
                  </Button>
                </Row>

                <Row
                  justify="start"
                  style={{ width: 250 }}
                  hidden={detail?.payment_status == 2 ? false : true}
                >
                  <Button
                    className="row-reserve-opt"
                    icon={
                      <FaConciergeBell
                        style={{
                          color: "#1BC5BD",
                          fontSize: 20,
                          margin: "0px 15px 0px",
                        }}
                      />
                    }
                    onClick={() => {
                      handleClose();
                      setDataRecord(detail);
                      setArticle(!article);
                    }}
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                      borderColor: "transparent",
                    }}
                  >
                    {`Add Article`}
                  </Button>
                </Row>

                <Row
                  justify="start"
                  hidden={detail?.group == 0 ? false : true}
                  style={{ width: 250 }}
                >
                  <Button
                    className="row-reserve-opt"
                    icon={
                      <TiClipboard
                        style={{
                          color: "#1BC5BD",
                          fontSize: 20,
                          margin: "0px 15px 0px",
                        }}
                      />
                    }
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                      borderColor: "transparent",
                    }}
                    onClick={() => {
                      handleClose();
                      setDataRecord(detail);
                      setOpenResv(true);
                    }}
                  >
                    {`Room Reservation Form`}
                  </Button>
                </Row>

                <Row
                  justify="start"
                  hidden={detail?.group == 1 ? false : true}
                  style={{ width: 250 }}
                >
                  <Button
                    className="row-reserve-opt"
                    icon={
                      <HiClipboardList
                        style={{
                          color: "#1BC5BD",
                          fontSize: 20,
                          margin: "0px 15px 0px",
                        }}
                      />
                    }
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                      borderColor: "transparent",
                    }}
                    onClick={() => {
                      handleClose();
                      setDataRecord(detail);
                      setOpenResGroup(true);
                    }}
                  >
                    {`Group Reservation Form`}
                  </Button>
                </Row>

                <Row justify="start" style={{ width: 250 }}>
                  <Button
                    className="row-reserve-opt"
                    icon={
                      <TbClipboardCheck
                        style={{
                          color: "#1BC5BD",
                          fontSize: 20,
                          margin: "0px 15px 0px",
                        }}
                      />
                    }
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                      borderColor: "transparent",
                    }}
                    onClick={() => {
                      handleClose();
                      setDataRecord(detail);
                      setOpenGuarantee(!openGuarantee);
                    }}
                  >
                    {`Guarantee Letter`}
                  </Button>
                </Row>

                <Row
                  justify="start"
                  style={{ width: 250 }}
                  hidden={
                    detail?.reservation_status == "Guaranteed" ? true : false
                  }
                >
                  <Button
                    className="row-reserve-opt"
                    onClick={(e) => {
                      // console.log(e);
                      //   console.log(detail);
                      // showDeleteModal();
                      setCancelled(true);
                      setDataRecord(detail);
                      handleClose();
                    }}
                    icon={
                      <DeleteFilled
                        style={{
                          color: "#F64E60",
                          fontSize: 20,
                          margin: "0px 10px 0px 15px",
                        }}
                      />
                    }
                    style={{
                      display: "flex",
                      padding: "5px 0px 10px",
                      width: 250,
                      height: 40,
                      borderColor: "transparent",
                    }}
                  >
                    {`Cancel Reservation`}
                  </Button>
                </Row>
              </>
            )
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{
            margin: 0,
            padding: 0,
            // borderRadius: 20,
          }}
        >
          <TfiMenuAlt
            className="menu-btn"
            // onClick={(e) => {
            //   console.log("ABOUT TO MENU > ", e);
            // showDeleteModal(record);
            // }}
            style={{ fontSize: 20, color: "#3699FF", cursor: "pointer" }}
          />
        </Popover>
      </Tooltip>

      <ModalAddArticle
        openModal={article}
        closeModal={setArticle}
        dataGuest={article == true ? dataRecord : ""}
      />

      <ReservationForm
        openModal={openResv}
        closeModal={setOpenResv}
        dataGuest={openResv == true ? dataRecord : ""}
        is_saved={true}
      />

      <ReservationGroupForm
        openModal={openResGroup}
        closeModal={setOpenResGroup}
        dataGuest={openResGroup == true ? dataRecord : ""}
        is_saved={true}
      />

      <GuaranteeLetter
        openModal={openGuarantee}
        closeModal={setOpenGuarantee}
        dataGuest={openGuarantee == true ? dataRecord : ""}
      />

      <CancellationModal
        dataGuest={cancelled == true ? dataRecord : null}
        openModal={cancelled}
        closeModal={setCancelled}
        is_refresh={is_refresh}
      />
    </>
  );
}

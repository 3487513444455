// Import React Components
import React, { useContext, useState, useEffect } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterSegmentations({
  getMasterSegment,
  master_segment,
}) {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [segmentName, setSegmentName] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "master-parameter",
      type: "segment-header",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let _mapped = filtered.map((val) => ({
            label: val?.header_name,
            value: val?.header_name,
            id: val?.id,
          }));

          //   console.log("Filtered: ", filtered);
          //   console.log("Mapped: ", _mapped);
          setOptionValue(_mapped);
        }
      },
      onReject: (error) => {
        console.log(error);
        // failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (master_segment && !cleanUp) {
      console.log("Segment Selected: ", master_segment);
      setSegmentName(master_segment);
    }

    return () => {
      cleanUp = true;
    };
  }, [master_segment]);

  // Handle Change
  const handleChange = (val, allKey) => {
    const _val = val;
    const _option = allKey;

    console.log(_option);

    setSegmentName(_val);
    getMasterSegment({
      value: _option.value,
      id: _option.id,
    });
  };

  // HANDLE CLEAR
  const handleClear = () => {
    setSegmentName(null);
  };

  return (
    <>
      <Select
        className="master-detail-select"
        key="master-detail-select"
        placeholder="Master's Detail"
        allowClear
        showSearch
        options={optionValue}
        onChange={handleChange}
        onClear={handleClear}
        value={segmentName}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

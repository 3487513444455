// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { HiMiniSquaresPlus } from "react-icons/hi2";
import { MdFreeCancellation, MdOutlinePersonOff } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";

// Import Page Components
import ExtendedTabke from "../../../../Components/Table/Reports/FrontOffice/Extended/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// CODE
export default function ExtendedNightPage() {
  // STATE MANAGEMENT
  // DATA
  const [value, setValue] = useState(null);
  // Search
  const [search, setSearch] = useState(null);

  const handleSearch = (e) => {
    e.preventDefault();

    setValue(e.target.value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <HiMiniSquaresPlus
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#faad14",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Extended Reservations`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              {" "}
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Guest Name`}</Typography>

                  <Input
                    allowClear
                    placeholder="Keyword"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                    }}
                  />
                </Col>

                <Col
                  className="search-btn-col"
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    type="primary"
                    className="search-btn"
                    icon={<SearchOutlined />}
                    onClick={search}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <ExtendedTabke searchKey={value} is_search={setSearch} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

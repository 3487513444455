// Import React Components
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// GLOBAL API
import {
  authorized_by,
  handleAuthorization,
} from "../../../API/Global/Permission";
import { baseurl } from "../../../API/Config/Api";
import { token, user_level } from "../../../API/Global/Payload";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Button,
  Divider,
  Input,
  Modal,
  InputNumber,
  DatePicker,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import { FormOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { VscLibrary } from "react-icons/vsc";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";

// PAGE COMPONENTS
import InputSkeleton from "../../../Components/Reusable/Skeleton/InputSkeleton";
import MasterCOA from "../../../Components/Reusable/Accounting/MainAccount/MainAccount";
import ModalAuthorized from "../../../Components/Modals/Permissions/ModalCancel";
import WarehouseList from "../../../Components/Reusable/Inventory/Warehouse/WarehouseList";

// Functions
import { LocalizationDate } from "../../../Components/Reusable/Functions/Localizations/Localization";
import { Encoder } from "../../../API/Global/Encoder";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// NOTIFICATIONS
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";
import {
  failedParams,
  successParams,
} from "../../../Components/Reusable/Notification/MasterNotif/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function BackOfficeParams() {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [hours, setHours] = useState(null);
  // Loading
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  // Modal
  const [open, setOpen] = useState(false);
  const [permitted, setPermitted] = useState(false);

  // Navigate
  const navigate = useNavigate();
  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    handleSuccess();
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (data && !cleanUp) {
      setFormFields(data);
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "back-office",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Params Back-Office --> ", response);
        setData(response?.data?.msg[0]);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Params Back-Office --> ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // SET FORMS
  const setFormFields = (value) => {
    const _data = value;

    let time = moment(LocalizationDate(_data.return_nonstay));

    console.log("SET FORMS: ", moment(time).toLocaleString());

    form.setFieldsValue({
      ..._data,
      return_nonstay: _data?.return_nonstay
        ? moment(LocalizationDate(_data.return_nonstay))
        : null,
      // moment(LocalizationDate(state?.state?.eta), "HH:mm").subtract(
      //   7,
      //   "hours"
      // )
      updated_date: moment(LocalizationDate(_data.updated_date)).format(
        "HH:mm:ss, DD-MM-YYYY"
      ),
    });
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    navigate("/configuration/parameters");
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("ON FINISH >>> ", content);
    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const submitContent = value;

    // console.log(submitContent);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to update the parameters?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(submitContent);
      },

      onCancel() {
        setLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
      },
    });
  };

  // HANDLE UPDATE
  const handleUpdate = async (value) => {
    const content = value;

    // setLoading(true);

    // console.log("Hours: ", hours, data?.return_nonstay);

    await axios
      .put(
        `${baseurl}/parameters/back-office`,
        {
          id: content?.id ? content.id : data?.id || null,
          return_nonstay: hours != null ? moment(hours) : data?.return_nonstay,
          id_warehouse: content?.id_warehouse
            ? content.id_warehouse
            : data?.id_warehouse || null,
          warehouse_name: content?.warehouse_name
            ? content.warehouse_name
            : data?.warehouse_name || null,
          coa_warehouse: content?.coa_warehouse
            ? content.coa_warehouse
            : data?.coa_warehouse || null,
          coa_ap_dept: content?.coa_ap_dept
            ? content?.coa_ap_dept
            : data?.coa_ap_dept || null,
          coa_cash: content?.coa_cash
            ? content.coa_cash
            : data?.coa_cash || null,
          validity_po_period: content?.validity_po_period
            ? content.validity_po_period
            : data?.validity_po_period || null,
          amount_in_words: content?.amount_in_words,
          updated_by: authorized_by,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Backoffice --> ", response);

        successParams(response, { method: 2 });

        handleSuccess(content);

        fetchData();
      })
      .catch((error) => {
        console.log("Error --> ", error);
        failedParams(error, { method: 2 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // HANDLE SUCCESS
  const handleSuccess = (value) => {
    setDisabled(true);

    localStorage.clear();
    handleAuthorization("");

    if (value) {
      // Update Params
      const jwt = Encoder(value);
      // console.log("JWT GENERATED: ", jwt);

      sessionStorage.setItem("par-2", jwt);
    }
  };

  // HANDLE EDIT
  const handleEdit = () => {
    if (disabled) {
      if (user_level === 9 || permitted) {
        setDisabled(false);
      } else {
        setOpen(true);
      }
    } else {
      handleSuccess();
    }
  };

  // HANDLE PERMISSION
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setDisabled(false);
        setPermitted(true);
        break;

      default:
        setDisabled(true);
        setPermitted(false);
        break;
    }
  };

  // HANDE GENERAL STORE
  const getWarehouse = (value) => {
    const _name = value?.warehouse_name ? value.warehouse_name : "";

    form.setFieldsValue({
      warehouse_name: _name,
    });
  };

  // HANDLE GENERAL STORE COA
  const getWarehouseCOA = (value) => {
    console.log(value);
    const _coa = value?.code ? value.code : null;

    form.setFieldsValue({
      warehouse_coa: _coa,
    });
  };

  // HANDLE COA AP DEPARTMENT
  const getCoaApDept = (value) => {
    const _coa = value?.code ? value.code : null;

    form.setFieldsValue({
      coa_ap_dept: _coa,
    });
  };

  // HANDLE COA CASH
  const getCOACash = (value) => {
    const _coa = value?.code ? value.code : null;

    form.setFieldsValue({
      coa_cash: _coa,
    });
  };

  // HANDLE TIME
  const handleTime = (val) => {
    const _time = val;
    console.log("Times: ", _time);

    setHours(_time?._d);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <VscLibrary
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                BACK-OFFICE PARAMETERS
              </Typography>
            </Row>
            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-first"
                style={{ padding: 30 }}
              >
                {loading == true ? (
                  <>
                    <InputSkeleton is_loading={loading} />
                  </>
                ) : (
                  <Form
                    className="parameters-form"
                    name="parameters-form"
                    key="parameters-form"
                    autoComplete="false"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{ padding: "36px 36px 0px 36px" }}
                  >
                    <Row gutter={30} className="row-header">
                      <Form.Item
                        label="General Store"
                        name="warehouse_name"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        <WarehouseList
                          selectedItem={getWarehouse}
                          warehouse_name={
                            data?.warehouse_name ? data.warehouse_name : null
                          }
                          is_disable={disabled}
                          is_multiple={false}
                        />
                      </Form.Item>

                      <Form.Item
                        label="General Store Account"
                        name="coa_warehouse"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterCOA
                          getCoaName={getWarehouseCOA}
                          coa_name={
                            data?.coa_warehouse ? data.coa_warehouse : null
                          }
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="AP Department Account"
                        name="coa_ap_dept"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterCOA
                          getCoaName={getCoaApDept}
                          coa_name={data?.coa_ap_dept ? data.coa_ap_dept : null}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Cash Account"
                        name="coa_cash"
                        labelWrap
                        style={{
                          width: "100%",
                        }}
                      >
                        <MasterCOA
                          getCoaName={getCOACash}
                          coa_name={data?.coa_cash ? data.coa_cash : null}
                          is_all={true}
                          disable={disabled}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Validity PO Period"
                        name="validity_po_period"
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputNumber
                          placeholder="Validity PO Period"
                          disabled={disabled}
                          addonAfter={"Days"}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Return Non Stay"
                        name="return_nonstay"
                        style={{
                          width: "100%",
                        }}
                      >
                        <DatePicker.TimePicker
                          placeholder="Return Non-Stay at"
                          disabled={disabled}
                          format={"HH:mm"}
                          allowClear
                          onChange={handleTime}
                          value={
                            data?.return_nonstay
                              ? moment(
                                  LocalizationDate(data?.return_nonstay)
                                ).subtract(7, "hours")
                              : null
                          }
                          // addonAfter={"Hours"}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Amount in Words"
                        name="amount_in_words"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Select
                          placeholder="Amount in Words (Terbilang)"
                          disabled={disabled}
                          options={[
                            {
                              label: "English",
                              value: "english",
                            },
                            {
                              label: "Indonesian",
                              value: "indonesia",
                            },
                          ]}
                          allowClear
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Last Updated On"
                        name="updated_date"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated On" disabled />
                      </Form.Item>

                      <Form.Item
                        label="Last Update by"
                        name="updated_by"
                        value={data?.updated_by}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Last Updated By" disabled />
                      </Form.Item>
                    </Row>

                    <Divider
                      className="divider-form"
                      style={{ margin: "15px 0px 10px" }}
                    />

                    <Row
                      justify="end"
                      align="middle"
                      className="modal-btn-form"
                    >
                      <Button
                        className="edit-form-btn"
                        type="primary"
                        onClick={handleEdit}
                        icon={
                          <FiEdit
                            style={{
                              margin: "0px 5px 0px 0px",
                            }}
                          />
                        }
                        style={{
                          marginRight: 30,
                        }}
                      >
                        {`${disabled ? "Edit" : "Cancel Editing"}`}
                      </Button>

                      <Button
                        className="submit-btn"
                        type="primary"
                        disabled={disabled}
                        htmlType="submit"
                        style={{
                          backgroundColor: "#1BC5BD",
                          borderColor: "#1BC5BD",
                          marginRight: 30,
                        }}
                      >
                        {`Submit`}
                      </Button>

                      <Button
                        className="cancel-btn"
                        type="default"
                        onClick={handleCancel}
                      >
                        {`Cancel`}
                      </Button>
                    </Row>
                  </Form>
                )}
              </Col>

              <Col
                xxl={16}
                xl={16}
                lg={16}
                md={24}
                sm={24}
                xs={24}
                className="col-second"
              ></Col>
            </Row>
          </Col>

          <ModalAuthorized
            openModal={open}
            closeModal={setOpen}
            params={1}
            is_permitted={handlePermit}
          />
        </Content>
      </Layout>
    </>
  );
}

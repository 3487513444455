// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FiEdit } from "react-icons/fi";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import MasterPaymentTypeEdit from "../../../Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterMainAccount from "../../../Reusable/Accounting/MainAccount/MasterCoa";
import MasterPaymentType from "../../../Reusable/Master/PaymentType/PaymentType";
import MasterCOA from "../../../Reusable/Accounting/MainAccount/MainAccount";

// Import Functions
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { editStyle, mainBody } from "../../../../Style/Global/Global";

// CONST
const { confirm } = Modal;

// CODE
export default function PaymentCardTable(props) {
  // Props
  const { is_search, searchKey, searchPay } = props;

  // CONTEXT
  const { getMasterPaymentCard } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [editData, setEditData] = useState(null);
  // Payment Card ID
  const [payCardId, setPayCardId] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterPaymentCard({
      paymentCard: "payment-card",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // USE EFFECT Search
  useEffect(() => {
    let cleanUp = false;

    if (searchPay?.id > 0 && !cleanUp) {
      handleFilter(searchPay);
    }

    return () => {
      cleanUp = true;
    };
  }, [searchPay]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);
                      setEditData(record);

                      setFieldsForm(record);
                    }}
                    style={editStyle}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showConfirmModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type_name",
      key: "payment_type_name",
      // width: 200,
    },
    {
      title: "COA",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 400,
    },
  ];

  // Get Payment Type Name
  const getPaymentType = (value) => {
    console.log(value);

    if (editData) {
      editData.payment_type_name = value?.name;
    }

    form.setFieldsValue({
      payment_type_name: value?.name,
      payment_type_id: value?.id,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setIsDelete(true);
    // setId(null);

    setIsLoading(false);

    setEditData(null);
    setPayCardId(null);
    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showConfirmModal = (record) => {
    let _data = record;

    confirm({
      className: "delete-pay-card-confirm",
      title: isDelete
        ? `Are you sure want to delete Payment Card ${_data?.name.toUpperCase()} from the table?`
        : `Are you sure want to ${
            edit ? "Update" : "Add a new"
          } Payment Card ${_data?.name.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(_data) : handleSubmit(_data);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    setIsLoading(true);
    console.log(value);
    const content = value;

    showConfirmModal(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // GET COA
  const getCoaName = (val) => {
    console.log(val);

    form.setFieldsValue({
      chart_of_account: val?.code,
    });
  };

  // SET FIELDS FORM
  const setFieldsForm = (value) => {
    const record = value;

    form.setFieldsValue({
      payment_card_id: record?.payment_card_id,
      code: record?.code,
      name: record?.name,
      description: record?.description,
      chart_of_account: record?.chart_of_account,
      payment_type_id: record?.payment_type_id,
      payment_type_name: record?.payment_type_name,

      created_by: record?.created_by,
      created_date: record?.created_date,
      modified_by: record?.updated_by,
      modified_date: record?.updated_date,
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;

    console.log("ON OK ==> ", contentsubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/master/payment-card`,
          {
            payment_card_id: contentsubmit.payment_card_id,
            code: contentsubmit.code,
            name: contentsubmit.name,
            description: contentsubmit.description || null,
            chart_of_account: contentsubmit.chart_of_account,
            payment_type_id: contentsubmit.payment_type_id,
            payment_type_name: contentsubmit.payment_type_name,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("ADD Pay Card = ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Payment Card",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("ADD Pay Card = ", error);

          masterFailedNotification(error, {
            method: 0,
            source: "Payment Card",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/payment-card`,
          {
            id: editData.id,
            payment_card_id: contentsubmit.payment_card_id,
            code: contentsubmit.code,
            name: contentsubmit.name,
            description: contentsubmit.description,
            chart_of_account: contentsubmit.chart_of_account,
            payment_type_id: contentsubmit.payment_type_id,
            payment_type_name: contentsubmit.payment_type_name,
            updated_by: user_name,
            // updated_by: "DEVELOPER",
            // updated_date: contentsubmit.modified_date,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("UPDATE Pay Card = ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Payment Card",
          });
          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("UPDATE Pay Card = ", error);

          masterFailedNotification(error, {
            method: 1,
            source: "Payment Card",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log("Delete ID: ", contentdelete);

    let obj = {
      ["id"]: contentdelete?.id > 0 ? contentdelete.id : 0,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/master/payment-card`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Pay Card = ", response);

        masterSuccessNotification(response, {
          method: 2,
          source: "Payment Card",
        });
        fetchData();
      })
      .catch((error) => {
        console.log("DEL Pay Card = ", error);
        masterFailedNotification(error, { method: 2, source: "Payment Card" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);

    downloadExcel(data, "Payment Card List");
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // Handle Filter
  const handleFilter = () => {
    const id = searchPay.id;
    console.log("FIlter is run: ", searchPay);

    if (bulks.length > 0) {
      let filter = bulks.filter((items) => {
        return items?.payment_type_id == id;
      });

      setData(filter);
    } else {
      setData([]);
    }
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Search Key = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.name.toLowerCase();
        // console.log("ITEM => ", item);

        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="payment-card-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-table" style={{ padding: "30px" }}>
          <Row
            className="row-modal-btn"
            justify="start"
            align="top"
            gutter={[0, 15]}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-exp-btn"
            >
              <Row justify="start" className="row-exp-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-modal-btn"
            >
              <Row justify="end" className="row-open-btn" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Payment Card`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="pay-card-table"
              key="pay-card-table"
              name="pay-card-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-pay-card-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Paymant Card`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Payment Card`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="pay-card-modal-form"
          className="pay-card-modal-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-pay-card-pay-type">
            <Col span={16}>
              <Form.Item
                label="Payment Type"
                name="payment_type_name"
                rules={[
                  {
                    required: true,
                    message: "Please, select a Payment Type!",
                  },
                ]}
                style={{ width: "100%", marginRight: 30 }}
              >
                {/* <Input placeholder="Payment Type" /> */}
                <MasterPaymentTypeEdit
                  pay={
                    editData?.payment_type_name
                      ? editData.payment_type_name
                      : null
                  }
                  getPaymentType={getPaymentType}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Payment Type ID" name="payment_type_id" hidden>
                <Input disabled placeholder="Payment Type ID" />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-pay-card-id">
            <Col span={12}>
              <Form.Item
                label="Payment Card Code"
                rules={[
                  {
                    required: true,
                    message: "Please, input a Payment Type Code!",
                  },
                ]}
                name="code"
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Payment Card Code" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Payment Card Name"
                rules={[
                  {
                    required: true,
                    message: "Please, input a Payment Type Name!",
                  },
                ]}
                name="name"
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Payment Card Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-coa-pay-card">
            <Col span={16}>
              <Form.Item
                label="COA"
                name="chart_of_account"
                rules={[
                  {
                    required: true,
                    message: "Please, input COA of Payment Type!",
                  },
                ]}
              >
                <MasterMainAccount
                  coa_name={edit ? editData?.chart_of_account : null}
                  disable={edit ? true : false}
                  getCoaName={getCoaName}
                  is_header={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-desc-pay-card">
            <Col span={16}>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  showCount
                  maxLength={200}
                  allowClear
                  rows={3}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          {edit === true ? (
            <>
              <Row gutter={30} className="created-date-pay-card">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-pay-card">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "10px 0px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row
            className="pay-card-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

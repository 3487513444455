// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Context
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
} from "antd";

// Import React Icons
import { DeleteFilled, EditOutlined, FormOutlined } from "@ant-design/icons";

// Import React Icons
import {
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaMoneyBillWave } from "react-icons/fa";

// import ModalTableOrderPay from "../../../Modals/Outlet/TableOrder/ModalPay";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// ROW SELECTIONS
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const dataSource = [
  {
    key: "1",
    payment_type: "Transfer Bank",
    payment_type_detail: "Bank Mandiri",
    amount: 2000000,
    table: "T1",
    payment_type_id: "123456",
    payment_detail_id: "123456",
  },
  {
    key: "2",
    payment_type: "Transfer Bank",
    payment_type_detail: "Bank BCA",
    amount: 4000000,
    table: "T2",
    payment_type_id: "123456",
    payment_detail_id: "123456",
  },
];

const { confirm } = Modal;

// ON SELECT
const onChangeSelect = (value) => {
  console.log(`selected ${value}`);
};

// ON SEARCH
const onSearch = (value) => {
  console.log("search:", value);
};

// export const contents = () => {
//   // USE FORM
//   const [form] = form.useForm();

//   // On Finish
//   const onFinish = (value) => {
//     console.log(value);
//   };

//   return (
//     <>
//       <Form
//         name="add-art-supplier-modal-form"
//         className="add-art-supplier-modal-form"
//         autoComplete="off"
//         form={form}
//         onFinish={(e) => onFinish(e)}
//       >
//         <Row justify="start" style={{ marginBottom: 30 }}>
//           <Col span={24}>
//             <Form.Item name="article_name">
//               <Select
//                 showSearch
//                 placeholder="Select an Option"
//                 optionFilterProp="children"
//                 onChange={onChangeSelect}
//                 onSearch={onSearch}
//                 filterOption={(input, option) =>
//                   (option?.label ?? "")
//                     .toLowerCase()
//                     .includes(input.toLowerCase())
//                 }
//                 options={[
//                   {
//                     value: "laundry",
//                     label: "Laundry",
//                   },
//                   {
//                     value: "no laundry",
//                     label: "No Laundry",
//                   },
//                 ]}
//               />
//             </Form.Item>
//           </Col>
//         </Row>

//         <Row justify="start" style={{ marginBottom: 15 }}>
//           <Col span={24}>
//             <Button
//               className="submit-btn"
//               //   onClick={handleSubmit}
//               htmlType="submit"
//               type="primary"
//               style={{
//                 backgroundColor: "#1BC5BD",
//                 borderColor: "#1BC5BD",
//               }}
//             >
//               Submit
//             </Button>
//           </Col>
//         </Row>
//       </Form>
//     </>
//   );
// };

// SHOW MODAL DELETE
export const showDeleteModal = (record) => {
  return confirm({
    className: "delete-guest-confirm",
    title: `Are you sure want to delete guest named .... from the table?`,
    // icon: <ExclamationCircleTwoTone />,
    okText: "Delete",
    okType: "danger",
    cancelText: "Cancel",
    centered: true,

    onOk() {
      // handleDelete(deleteContent);
      // console.log("Article deleted");
    },

    onCancel() {
      // console.log("Confirmation Cancelled");
    },

    okButtonProps: {
      className: "submit-btn",
      type: "primary",
    },

    cancelButtonProps: {
      className: "cancel-btn",
      type: "default",
    },

    width: 750,
    bodyStyle: {
      padding: 30,
      borderRadius: 10,
    },
  });
};

// CODE
export default function PaymentTableOrder(handleFilter) {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "",
      width: 100,
      render: (_, record) => {
        return (
          <Row className="action-guest-table-btn" style={{ width: "100%" }}>
            <Col span={24}>
              <DeleteFilled
                className="delete-guest-btn"
                onClick={() => {
                  console.log("ABOUT TO DELETE > ", record);
                  showDeleteModal(record);
                }}
                style={{ fontSize: 20, color: "#F64E60" }}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.payment_type.localeCompare(b.payment_type),
    },
    {
      title: "Payment Type Detail",
      dataIndex: "payment_type_detail",
      key: "payment_type_detail",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.payment_type_detail.localeCompare(b.payment_type_detail),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.amount.localeCompare(b.amount),
    },
    {
      title: "Table",
      dataIndex: "table",
      key: "table",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.table.localeCompare(b.table),
    },
    {
      title: "Payment Type ID",
      dataIndex: "payment_type_id",
      key: "payment_type_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.payment_type_id.localeCompare(b.payment_type_id),
    },
    {
      title: "Payment Detail ID",
      dataIndex: "payment_detail_id",
      key: "payment_detail_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.payment_detail_id.localeCompare(b.payment_detail_id),
    },
  ];

  // CONTEXT
  const { state } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [dataView, setDataView] = useState();

  const [dataEdit, setDataEdit] = useState(null);
  const [value, setValue] = useState();
  const [edit, setEdit] = useState();
  // Modal Open
  const [open, setOpen] = useState(false);

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    fetchData();
    setFilterData(handleFilter);
  }, [handleFilter]);

  //   const selected = parseInt(filterData.handleFilter);
  //   if (selected === null) {
  //     return this.setState({
  //       dataSource,
  //     });
  //   }
  //   const mejaMap = {
  //     1: "1",
  //     2: "2",
  //   };

  //   const selectedMeja = mejaMap[selected];

  //   const filteredEvents = dataSource.filter(
  //     ({ table }) => table === selectedMeja
  //   );

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  //console.log("vvvvvvvvvvvv", filteredEvents[0]);

  return (
    <>
      <Row style={mainBody}>
        <Table
          loading={loading}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={dataSource}
          //onChange={onChange}
          pagination={{
            pageSize: 5,
          }}
          scroll={{
            x: "100%",
          }}
          rowKey={(record) => (record.id ? record.id : record.key)}
        />
      </Row>
    </>
  );
}

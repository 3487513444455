// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { IoCalendar } from "react-icons/io5";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import RoomCategoryTable from "../../../Components/Table/Master/RoomCategory/Table";
import RatePeriodicTable from "../../../Components/Table/Master/RatePeriodic/Table";
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// Import Global Styles

// CODE
export default function RatePeriodicPage() {
  // STATE MANAGAMENT
  // Search Key
  const [value, setValue] = useState("");
  // Search
  const [search, setSearch] = useState();
  // Rate
  const [roomCode, setRoomCode] = useState(null);

  // Handle Change
  const handleSearch = (val) => {
    val.preventDefault();

    setValue(val.target.value);
  };

  // Get Room Category Code
  const getRoomCode = (value) => {
    // console.log(value);
    setRoomCode(value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="rate-periodic-main-layout">
          <Row
            className="rate-periodic-title"
            justify="start"
            style={cardHeader}
          >
            <IoCalendar
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              RATE PERIODIC
            </Typography>
          </Row>

          <Row
            className="rate-periodic-search-component"
            justify="start"
            style={cardBody}
          >
            <Col
              style={{
                textAlign: "start",
                margin: "22px 30px",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>Keywords</Typography>

              <Input
                allowClear
                placeholder="Room Category Name"
                onChange={handleSearch}
                style={{ width: 300 }}
              />
            </Col>

            <Col
              style={{
                margin: "54px 0px 0px",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Button
                className="search-btn"
                type="primary"
                icon={<SearchOutlined />}
                onClick={search}
              >
                {`Search`}
              </Button>
            </Col>
          </Row>

          <Row gutter={[30, 30]} className="row-rate-table">
            <Col
              className="col-table"
              span={12}
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <RoomCategoryTable
                is_search={setSearch}
                searchKey={value}
                on_modal={false}
                getRoomCode={getRoomCode}
              />
            </Col>

            <Col
              className="col-table"
              span={12}
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <RatePeriodicTable getRoomCode={roomCode} />
            </Col>
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

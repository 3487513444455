// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaCreditCard } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import {
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

// Import Page Components
import BudgetTable from "../../../../Components/Table/BackOffice/Budget/List/Table";
import PaymentCardTable from "../../../../Components/Table/Master/PaymentCard/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// CODE
export default function BudgetPage() {
  // STATE MANAGEMENT
  // Value Search
  const [value, setValue] = useState(null);
  const [yearNow, setYearNow] = useState(0);
  const [status, setStatus] = useState(null);
  // Search
  const [search, setSearch] = useState(null);

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  // HANDLE BUDGET YEAR
  const handleYear = (val) => {
    // e.preventDefault();
    console.log("HANDLE SEARCH = ", moment(val).year());
    // setValue(e.target.value);
    setYearNow(moment(val).year());
  };

  // Handle Change
  const handleChange = (e, opt) => {
    const _names = e;
    const _allKey = opt;

    setStatus(_allKey?.value);
  };

  // Handle Clear
  const handleClear = (e, opt) => {
    const _names = e;
    const _allKey = opt;

    setStatus(null);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="payment-card-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <DollarCircleOutlined
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
                fontSize: 32,
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Budget List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 15px 22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Budget Status`}</Typography>

                <Select
                  className="budget-select"
                  allowClear
                  onChange={handleChange}
                  placeholder="Select a Budget Status!"
                  onClear={handleClear}
                  value={status}
                  options={[
                    {
                      label: "Proposed",
                      value: 0,
                      is_reject: false,
                    },
                    {
                      label: "Approve",
                      value: 1,
                      is_reject: true,
                    },
                    {
                      label: "Reject",
                      value: 2,
                      is_reject: true,
                    },
                    {
                      label: "Subject to Revise",
                      value: 3,
                      is_reject: true,
                    },
                    {
                      label: "Inactive",
                      value: 4,
                      is_reject: false,
                    },
                    {
                      label: "Active",
                      value: 5,
                      is_reject: false,
                    },
                  ]}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 15px 22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Year`}</Typography>

                <DatePicker.YearPicker
                  className="budget-yearpick"
                  allowClear
                  onChange={handleYear}
                  // defaultValue={moment(year, "YYYY")}
                  format={"YYYY"}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 15px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Keywords"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <BudgetTable
            is_search={setSearch}
            searchKey={value}
            yearValue={yearNow}
            statusValue={status}
          />
        </Col>
      </Content>
    </Layout>
  );
}

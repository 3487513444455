// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import ParametersAccounting from "../../../../../Reusable/Parameters/ParamsAcc";
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import POModalDetail from "../../../../../Modals/Report/Purchasing/PurchaseOrder/ModalDetail";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

export default function POExpiredReportTable({ is_search, searchKey }) {
  //CONTEXT
  const { getPurchaseOrderList } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  // Date
  const [datePicked, setDatePicked] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);
  const params = ParametersAccounting().params;
  const [modalOpen, setModalOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);

  // USE NAVIGATE
  const navigate = useNavigate();

  // GET DATA FUNCTION
  const fetchData = async () => {
    setLoading(true);

    await getPurchaseOrderList({
      type: "purchase-order-expired",
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          setData(_data);
          setBulks(_data);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USEEFFECT FETCHING DATA
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT REFRESH
  useEffect(() => {
    if (refresh == true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(
      data,
      `Purchase Order Expired List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // USEEFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // HANDLE FILTER
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const _orderDate = moment(val.order_date).format("YYYY-MM-DD");

      return moment(_orderDate).isBetween(_start, _end, undefined, "[]");
    });

    console.log("Filtered Date Range: ", _filtered);
    setData(_filtered);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey : null;
    console.log("Searching = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        return (
          item.po_number.toLowerCase().indexOf(query?.toLowerCase()) !== -1
        );
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE OPEN
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/purchasing-report";

    navigate(`${path}`);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-po-table-btn"
              style={{ width: "100%" }}
              justify="center"
            >
              <Col span={12}>
                <Tooltip title="Detail Data" className="edit-tooltip">
                  <FaRegEye
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO DETAIL > ", record);
                      setDataDetail(record);
                      handleOpen();
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "PO No.",
      dataIndex: "po_number",
      key: "po_number",
    },
    {
      title: "PO Status",
      dataIndex: "po_status",
      key: "po_status",
      render: (val, record) => {
        if (val == 1) {
          return <Tag color="#108ee9">Released</Tag>;
        } else {
          if (
            record.end_contract_date
              ? moment(moment().format("YYYY-MM-DD")).isAfter(
                  record.end_contract_date
                )
              : true &&
                moment(moment().format("YYYY-MM-DD")).isAfter(
                  record.delivery_date
                )
          ) {
            return <Tag color="red">EXPIRED</Tag>;
          } else {
            return <Tag color="#f50">Draft</Tag>;
          }
        }
      },
    },
    {
      title: "Department Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PR No.",
      dataIndex: "pr_number",
      key: "pr_number",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },

    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Credit Term Date",
      dataIndex: "credit_term_date",
      key: "credit_term_date",
      render: (date) => {
        return date ? moment(date).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];
  return (
    <>
      <Row className="po-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }} className="col-table">
          <Row justify="end" className="row-btn">
            <Col span={12} className="col-export">
              <Typography
                style={{ marginBottom: 10 }}
              >{`Pick Date (Order Date)`}</Typography>

              <DatePicker.RangePicker
                className="date-btn"
                allowClear
                onChange={handleDate}
                // placeholder=""
                style={{
                  width: 300,
                }}
              />
            </Col>

            <Col span={12} className="col-table-btn">
              <Row justify="end" className="row-table-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<FileExcelFilled />}
                  style={{
                    fontWeight: 500,
                    margin: "0px 15px",
                  }}
                >
                  {`Export to Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table-po-main-row" style={{ width: "100%" }}>
            <Table
              className="table-po"
              name="table-po"
              key="table-po"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              size="large"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              scroll={{
                x: 1800,
              }}
              rowKey={(record) =>
                record.po_number ? record.po_number : record.id_po
              }
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Row>
        </Col>
      </Row>

      <POModalDetail
        is_open={modalOpen}
        is_close={setModalOpen}
        selectedData={dataDetail}
        params={params}
      />
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function ReservationId({ resv_id, getResvId }) {
  // CONTEXT
  const { getReservation } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [resvId, setResvId] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = () => {
    getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {},
      onSuccess: (response) => {
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.group <= 1) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev.id,
            label: dev.reservation_id,
            guest_name: dev.guest_name,
            reservation_id: dev.reservation_id,
            guest_status: dev?.guest_category,
            price_id: dev?.price_id,
          }));

          console.log(option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // console.log("Room no: ", room_number);
  //   console.log("Room no: ", rateRoomCatg);
  //   console.log(optionValue);

  return (
    <>
      <Select
        key={optionValue.reservation_id}
        style={{ width: "100%" }}
        value={resvId != null ? resvId : resv_id}
        // disabled={resvId ? true : false}
        showSearch
        onChange={(e, val) => {
          // console.log(e, val);

          let code = optionValue.filter((cd) => {
            return cd.value == e;
          });

          // console.log(
          //   "Reservation No. = ",
          //   code[0].value,
          //   code[0].reservation_id
          // );

          setResvId(e);

          getResvId(
            e,
            val.reservation_id,
            val?.guest_name,
            val?.price_id,
            val?.guest_status
          );
          // getPayTypeId(code[0].id);

          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
      />
    </>
  );
}

// Import's React Components
import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";
import { baseurl } from "../../../../API/Config/Api";

// Import's Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
} from "antd";

// Import's React Icons
import { FiEdit } from "react-icons/fi";

// Import Page Components
import CompanyLedgersTable from "../../../Table/Accounting/LedgerBalances/Company/Table";

// Import Notifications
import {
  failedGuest,
  incomplete,
  successGuest,
} from "../../../Reusable/Notification/Notification";

// MODALS
const { confirm } = Modal;

// CODE
export default function CompLedgerModal(props) {
  // PROPS
  const {
    // Modal State
    modalOpen,
    closeModal,
    // Data State
    dataComp,
    is_refresh,
    // Type Company
    typeComp,
    is_edit,
  } = props;

  // STATE MANAGEMENT
  // DATA
  const [dataCompany, setDataCompany] = useState(null);
  // State
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  // Set Selected
  const [selectedData, setSelectedData] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (modalOpen === true && !cleanUp) {
      console.log("IS EDIT: ", is_edit);
      setOpen(true);
      setIsEdit(is_edit);
    }

    if (dataComp && !cleanUp) {
      console.log("Company: ", dataComp);

      setDataCompany(dataComp);
      setFormFields(dataComp);
    }

    return () => {
      cleanUp = true;
    };
  }, [modalOpen]);

  // HANDLE EDIT
  const setFormFields = (value) => {
    const _record = value;

    form.setFieldsValue({
      contact_person: _record?.contact_person,
      company_name: _record?.company_name,
      cp_number: _record?.cp_number,
      country: _record?.country,
      province: _record?.province,
      company_phone: _record?.company_phone,
      city: _record?.city,
      zip_code: _record?.zip_code,
      address: _record?.address,
      company_email: _record?.company_email,
      fax: _record?.fax,
      main_segment_name:
        _record?.main_segment_name?.length > 0
          ? _record.main_segment_name.join(", ")
          : _record.main_segment_name,
      main_segment_code: _record?.main_segment_code,
      main_segment_id: _record?.main_segment_id,
      comment: _record?.comment,
      payment_type: _record?.payment_type,
      credit_limit: _record?.credit_limit,
      master_comp: _record?.master_comp,
      master_comp_id: _record?.master_comp_id,
      allow_ledger: _record?.allow_ledger,
      book_source: _record?.book_source,
      sales_id: _record?.sales_id,
      rate_code:
        _record?.rate_code?.length > 0
          ? _record.rate_code.join(", ")
          : _record.rate_code,
      rate_code_id: _record?.rate_code_id,
      expired_date: moment(_record?.expired_date),
      credit_account_no: _record?.credit_account_no,

      //   is_deleted: false,
      //   is_dummy: null,
      //   company_id: null,
      //   deleted_date: null,
      //   deleted_by: null,
      //   is_ota: false,
      //   created_by: "TES created_by",
      //   created_date: "2023-10-18T11:29:12.257141",
      //   updated_by: null,
      //   updated_date: null,
    });
  };

  // Handle Selected
  const getLedgers = (value) => {
    const _selected = value;
    console.log("Selected: ", _selected);
  };

  // ON FINISH
  const onFinish = (e) => {
    const values = e;
    console.log("SUBMITTED ==> ", values);
    // values.birthdate = moment(values["birthdate"]).format("YYYY-MM-DD");

    showModalConfirm(values);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("On Finish Failed", error?.values);

    incomplete(error);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    form.resetFields();

    closeModal(false);
    setOpen(false);
    setIsEdit(false);
  };

  // MODAL CONFIRM
  const showModalConfirm = (val) => {
    const _data = val;

    confirm({
      className: "modal-confirm",
      title: (
        <>
          {`Are you sure you want ${
            isEdit ? "Update " : "Add "
          } Credit Account ID/Number of ${_data?.company_name}?`}
          ?
        </>
      ),
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
      },
      cancelButtonProps: {
        className: "cancel-btn",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE UPDATE
  const handleSubmit = async (val) => {
    const _data = val;

    console.log("About to PUT: ", _data);

    await axios
      .put(
        `${baseurl}/guest/company-and-ota`,
        {
          allow_ledger: _data?.allow_ledger,
          credit_account_no:
            _data?.credit_account_no || dataCompany?.credit_account_no,

          id: dataCompany?.id,
          company_name: dataCompany?.company_name,
          company_email: dataCompany?.company_email,
          company_phone: dataCompany?.company_phone,
          country: dataCompany?.country,
          province: dataCompany?.province,
          city: dataCompany?.city,
          zip_code: dataCompany?.zip_code,
          address: dataCompany?.address,
          contact_person: dataCompany?.contact_person,
          cp_number: dataCompany?.cp_number,
          payment_type: dataCompany?.payment_type,
          master_comp: dataCompany?.master_comp,
          master_comp_id: dataCompany?.master_comp_id,
          main_segment_id: dataCompany?.main_segment_id,
          main_segment_code: dataCompany?.main_segment_code,
          main_segment_name: dataCompany?.main_segment_name,
          rate_code_id: dataCompany?.rate_code_id,
          rate_code: dataCompany?.rate_code,
          sales_id: dataCompany?.sales_id,
          credit_limit: dataCompany?.credit_limit,
          fax: dataCompany?.fax,
          comment: dataCompany?.comment,
          expired_date: moment(dataCompany?.expired_date).format("YYYY-MM-DD"),
          source: dataCompany?.source,
          is_dummy: dataCompany?.is_dummy || false,

          is_ota: dataCompany?.is_ota,
          updated_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Update Company/OTA --> ", response);

        successGuest(response, { method: 1, main_type: typeComp });

        handleClose();
        is_refresh(true);
      })
      .catch((error) => {
        console.log(error);
        failedGuest(error, { method: 1, main_type: typeComp });
      });
  };

  return (
    <>
      <Modal
        className="ledgers-modal"
        key="ledgers-modal"
        title={
          <>
            <Row>
              <FiEdit style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`${isEdit === true ? "Edit " : "Creates "} ${
                  typeComp === 2 ? `Company` : `Travel Agent`
                } Ledgers`}
              </Typography>
            </Row>
          </>
        }
        open={open}
        centered
        closable
        onCancel={handleClose}
        width={800}
        footer={null}
      >
        <Form
          className="ledgers-form"
          name="ledgers-form"
          key="ledgers-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            padding: "0px 36px",
          }}
        >
          <Col span={24} className="form-col">
            <Row gutter={30} className="row-comp-details">
              <Col span={12}>
                <Form.Item
                  label={typeComp === 2 ? "Company Name" : "Travel Agent Name"}
                  name={"company_name"}
                  style={{ width: "100%", marginRight: 30 }}
                >
                  <Input
                    disabled
                    placeholder={
                      typeComp === 2 ? "Company Name" : "Travel Agent Name"
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    typeComp === 2 ? "Company Email" : "Travel Agent Email"
                  }
                  name={"company_email"}
                  style={{ width: "100%", marginRight: 30 }}
                >
                  <Input
                    disabled
                    placeholder={
                      typeComp === 2 ? "Company Email" : "Travel Agent Email"
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="cp-row" justify="start">
              <Col span={8}>
                <Form.Item
                  label={
                    typeComp === 2 ? "Company Phone" : "Travel Agent Phone"
                  }
                  name={"company_phone"}
                >
                  <InputNumber
                    disabled
                    minLength={10}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Contact Person" name="contact_person">
                  <InputNumber
                    disabled
                    minLength={10}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="CP Phone" name={"cp_number"}>
                  <InputNumber
                    disabled
                    minLength={10}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="credit-row" gutter={30}>
              <Col span={16}>
                <Form.Item
                  label="Credit Account Number"
                  name="credit_account_no"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input a Credit Number!",
                      // validator: async (cred, message) => {
                      //   // console.log("Numbers: ", cred);
                      //   console.log("Message: ", message);

                      //   if (message) {
                      //     return Promise.reject(
                      //       new Error("Must Be")
                      //     );
                      //   }
                      // },
                    },
                  ]}
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input
                    placeholder="Credit Account Number"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="Credit Limit"
                  name="credit_limit"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <InputNumber
                    addonBefore={currency}
                    placeholder="Credit Limit"
                    min={0}
                    formatter={(price) => {
                      return `${price > 0 ? price : 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col> */}

              <Col span={8}>
                <Form.Item
                  label="Ledger Allowance"
                  name="allow_ledger"
                  valuePropName={"checked"}
                >
                  <Checkbox
                    className="ledger-checkbox"
                    // disabled
                  >{`Allow Ledger`}</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "5px 0px", background: "#EBEDF3" }}
            />

            <Row className="segmentations-row" gutter={30}>
              <Col span={24}>
                <Form.Item
                  label="Segment List"
                  name="main_segment_name"
                  style={{ marginRight: 30, width: "100%" }}
                >
                  <Input.TextArea
                    placeholder="Segment List"
                    style={{ width: "100%" }}
                    rows={3}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "5px 0px", background: "#EBEDF3" }}
            />

            <Row className="segmentations-row" gutter={30}>
              <Col span={24}>
                <Form.Item label="Rate Code List" name="rate_code">
                  <Input.TextArea
                    placeholder="Rate Code List"
                    rows={3}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Divider
            className="form-divider"
            style={{ margin: "15px 0px", background: "#EBEDF3" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { sub_children } from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiOpenBook } from "react-icons/gi";
import { IoOptions } from "react-icons/io5";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { PiUserCirclePlusFill } from "react-icons/pi";
import { FaUserLargeSlash } from "react-icons/fa6";
import {
  MdAdminPanelSettings,
  MdLayers,
  MdOutlineManageAccounts,
  MdOutlineSupervisedUserCircle,
} from "react-icons/md";
import {
  BsCalculatorFill,
  BsDoorOpenFill,
  BsPersonFillGear,
} from "react-icons/bs";
import { SlUser } from "react-icons/sl";
import { RiShieldUserFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
// import { PiUsersThreeFill } from "react-icons/pi";

// Import Global Styles
import {
  cardBody,
  cardHeader,
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../Style/Global/Global";

// ROUTES
const listCard = [
  {
    path: "access-control",
    title: "User's Access Control",
    id_modules: 94,
    icon: <BsPersonFillGear style={globalIcon} />,
  },
  {
    path: "registration",
    title: "Registration",
    id_modules: 95,
    icon: <PiUserCirclePlusFill style={globalIcon} />,
  },
  {
    path: "blocking",
    title: "Blocking",
    id_modules: 96,
    icon: <FaUserLargeSlash style={globalIcon} />,
  },
];

// CODE
export default function RolesPage() {
  // STATE MANAGEMENT
  // List Items
  const [listItems, setlistItems] = useState([]);

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const grandModule = sub_children?.length > 0 ? sub_children : [];

    const list = listCard.map((record) => {
      if (grandModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      } else {
        return null;
      }
    });

    // console.log("Grand Children Modules: ", sub_children);
    // console.log("Filtered: ", list);

    setlistItems(list);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="article-sales-main-layout">
          <Row
            className="row-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              margin: "0px 0px 30px",
              borderRadius: 5,
            }}
          >
            <MdOutlineManageAccounts
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`User Management & Access Control`}
            </Typography>
          </Row>

          <Row className="row-body" justify="start" style={mainBody}>
            {listItems}

            {/* <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("access-control")}
                  icon={<BsPersonFillGear style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`User's Access Control`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("registration")}
                  icon={<PiUserCirclePlusFill style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography className="text-page" style={globalText}>
                    {`Registration`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`blocking`)}
                  icon={<FaUserLargeSlash style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Blocking`}</Typography>
                </Button>
              </Card>
            </Col> */}

            {/* <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`roles-admin`)}
                  icon={<MdAdminPanelSettings style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Admin`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`roles-super-admin`)}
                  icon={<RiShieldUserFill style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Super Admin`}</Typography>
                </Button>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

// Import React's Components
import React, { useContext, useState, useEffect } from "react";

// CONTEXT
import { Context as FinanceContext } from "../../../../API/Context/FinanceContext/FinanceContext";

// Import Antd Components
import { Select } from "antd";

// CODE
export default function MasterFinancial({ getFinancial, financial_statement }) {
  // CONTEXT
  const { masterFinancialState } = useContext(FinanceContext);
  // Data
  const [finance, setFinance] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await masterFinancialState({
      account: "financial-statement",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Financial => ", response);

        if (response.data.msg?.length > 0) {
          let _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.name,
            label: dev?.name,
            statement_id: dev?.financial_statement_id,
            id: dev?.id,
          }));

          console.log(option);
          setOptionValue(option);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Financial ID ", financial_statement);

    if (financial_statement && !cleanUp) {
      setFinance(financial_statement);
    }

    return () => {
      cleanUp = true;
    };
  }, [financial_statement]);

  // Handle Change
  const handleChange = (val, allKey) => {
    const _value = val;
    const _allKey = allKey;

    console.log(_value, _allKey);

    setFinance(_value);
    getFinancial({
      value: _value,
      id: _allKey?.id,
      statement_id: _allKey?.statement_id,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setFinance(null);
  };

  return (
    <>
      <Select
        className="account-type-selector"
        key="account-type-selector"
        name="account-type-selector"
        allowClear
        showSearch
        value={finance}
        // fieldNames={{
        //   label: "name",
        //   value: "name",
        //   code: "financial_statement_id",
        // }}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

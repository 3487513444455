// REACT COMPONENTS
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import {
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// GLOBAL STYLE COMPONENTS
import { mainBody } from "../../../../../Style/Global/Global";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// GLOBAL API
import { token, user_name } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";

// PAGE COMPONENTS
import VendorManagementPopover from "../../../../Popover/VendorManagement/Popover";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notifications
import { TaxesParameter } from "../../../../Reusable/Functions/Taxes/Taxes";

// REACT ICONS
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// MODALS
const { confirm } = Modal;

// CODE
export default function VendorManagementTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getVendorList } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [bulks, setBulks] = useState(data);
  const [dataEdit, setDataEdit] = useState(null);

  // Boolean
  const [isDelete, setIsDelete] = useState(true);
  const [checked, setChecked] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);
  // Modal add/edit
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Parameters Accounting
  const params = TaxesParameter();

  // navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    await getVendorList({
      vendor: "suppliers",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("VENDOR RESPONSE => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    setRefresh(false);
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // ON FINISH
  const onFinish = (e) => {
    const _submit = e;
    console.log(_submit);

    setIsLoading(true);
    showModalConfirm(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    console.log(value);
    masterIncomplete(value);
  };

  const handleSubmit = async (value) => {
    const content = value;

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/master/suppliers`,
          {
            supplier_code: content?.supplier_code
              ? content.supplier_code
              : null,
            supplier_name: content?.supplier_name
              ? content.supplier_name
              : null,
            supplier_address: content?.supplier_address
              ? content.supplier_address
              : null,
            contact_person_supplier: content?.contact_person_supplier
              ? content.contact_person_supplier
              : null,
            email_supplier: content?.email_supplier
              ? content.email_supplier
              : null,
            cp_phone_number: content?.cp_phone_number
              ? content.cp_phone_number
              : null,
            telefax: content?.telefax ? content.telefax : null,
            npwp: content?.npwp ? content.npwp : null,
            bank_of_account: content?.bank_of_account
              ? content.bank_of_account
              : null,
            term_of_payment: content?.term_of_payment
              ? content.term_of_payment
              : 0,
            is_ppn: content?.is_ppn,
            created_by: user_name,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then((response) => {
          console.log("POST => ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Supplier",
          });

          handleCancel();
          fetchData();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("POST Supplier => ", error);
          masterFailedNotification(error, { method: 0, source: "Supplier" });
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/suppliers`,
          {
            supplier_code: content?.supplier_code ? content.supplier_code : "",
            supplier_name: content?.supplier_name ? content.supplier_name : "",
            supplier_address: content?.supplier_address
              ? content.supplier_address
              : "",
            contact_person_supplier: content?.contact_person_supplier
              ? content.contact_person_supplier
              : "",
            email_supplier: content?.email_supplier
              ? content.email_supplier
              : "",
            cp_phone_number: content?.cp_phone_number
              ? content.cp_phone_number
              : "",
            telefax: content?.telefax ? content.telefax : null,
            npwp: content?.npwp ? content.npwp : null,
            bank_of_account: content?.bank_of_account
              ? content.bank_of_account
              : null,
            term_of_payment: content?.term_of_payment
              ? content.term_of_payment
              : null,
            id_supplier: dataEdit.id_supplier,
            is_ppn: content?.is_ppn,
            updated_by: user_name,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then((response) => {
          console.log("UPDATE  => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Supplier",
          });

          fetchData();
          handleCancel();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("UPDATE  => ", error);

          masterFailedNotification(error, { method: 1, source: "Supplier" });
          setIsLoading(false);
        });
    }
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "add-update-supplier-confirm",
      title: `Are you sure want to ${edit == true ? "Update" : "Add a New"}
          supplier named ${_data?.supplier_name.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Refresh
  const handleRefresh = (value) => {
    console.log("Values: ", value);

    switch (value) {
      case true:
        setRefresh(true);
        fetchData();
        break;

      case false:
        setRefresh(false);
        break;

      default:
        break;
    }
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.supplier_name.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  // Set fields
  const setFormFields = (value) => {
    const record = value;

    setChecked(record?.is_ppn);

    form.setFieldsValue({
      supplier_code: record?.supplier_code ? record.supplier_code : "",
      supplier_name: record?.supplier_name ? record.supplier_name : "",
      supplier_address: record?.supplier_address ? record.supplier_address : "",
      contact_person_supplier: record?.contact_person_supplier
        ? record.contact_person_supplier
        : "",
      email_supplier: record?.email_supplier ? record.email_supplier : "",
      cp_phone_number: record?.cp_phone_number ? record.cp_phone_number : "",
      telefax: record?.telefax ? record.telefax : null,
      npwp: record?.npwp ? record.npwp : null,
      bank_of_account: record?.bank_of_account ? record.bank_of_account : null,
      term_of_payment: record?.term_of_payment ? record.term_of_payment : null,
      updated_by: record?.updated_by ? record.updated_by : "",
      updated_date: record?.updated_date
        ? moment(record.updated_date).format("DD MMM YYYY")
        : null,
      created_by: record?.created_by,
      is_ppn: record?.is_ppn,
      created_date: moment(record.created_date).format("DD MMM YYYY"),
    });
  };

  // Handle Check
  const handleCheck = (val) => {
    const _checked = val.target.checked;

    setChecked(_checked);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12} className="col-edit">
                <Tooltip className="edit-tooltip" title="Edit Data">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      // console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);

                      setDataEdit(record);
                      setFormFields(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-menu">
                <Tooltip title="Option" className="option-tooltip">
                  <VendorManagementPopover
                    details={record}
                    is_refresh={handleRefresh}
                    params={params}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Supplier Code",
      dataIndex: "supplier_code",
      key: "supplier_code",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
    },
    {
      title: "Supplier Address",
      dataIndex: "supplier_address",
      key: "supplier_address",
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person_supplier",
      key: "contact_person_supplier",
    },
    {
      title: "Email",
      dataIndex: "email_supplier",
      key: "email_supplier",
    },
    {
      title: "Phone Number",
      dataIndex: "cp_phone_number",
      key: "cp_phone_number",
    },
    {
      title: "Telefax",
      dataIndex: "telefax",
      key: "telefax",
      width: 200,
    },
    {
      title: "NPWP",
      dataIndex: "npwp",
      key: "npwp",
      width: 200,
    },
    {
      title: "Bank Account",
      dataIndex: "bank_of_account",
      key: "bank_of_account",
      width: 200,
    },
    {
      title: "Term of Payment",
      dataIndex: "term_of_payment",
      key: "term_of_payment",
      width: 200,
    },
    // {
    //   title: "Created By",
    //   dataIndex: "created_by",
    //   key: "created_by",
    // },
    // {
    //   title: "Created Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   sortDirections: ["descend", "ascend"],
    //   render: (created, record) => {
    //     return moment(created).format("DD MMM YYYY");
    //   },
    //   sorter: (a, b) => moment(a.created) - moment(b.created),
    // },
    // {
    //   title: "Updated By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    // },
    // {
    //   title: "Updated Date",
    //   dataIndex: "updated_date",
    //   key: "updated_date",
    //   sortDirections: ["descend", "ascend"],
    //   render: (updated, record) => {
    //     return updated ? moment(updated).format("DD MMM YYYY") : null;
    //   },
    //   sorter: (a, b) => moment(a.updated) - moment(b.updated),
    // },
  ];

  // Handle Open
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
  };

  // HANDLE EXPORT EXCEL
  const handleExport = () => {
    downloadExcel(bulks, "Supplier List");
  };

  // HANDLE RETURN TO MENU PURCHASE
  const handleReturn = () => {
    const path = "/back-office/purchasing";

    navigate(`${path}`);
  };

  // CLOSE MODAL EDIT/DELETE
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setIsDelete(true);
    setDataEdit(null);

    setLoading(false);

    form.resetFields();
  };

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Supplier`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="vendor-management-table"
              name="vendor-management-table"
              key="vendor-management-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-supplier-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Add Supplier`}</Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Edit Supplier`}</Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="add-supplier-modal-form"
          className="add-supplier-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-supplier-id">
            <Col span={12}>
              <Form.Item
                label="Supplier Code"
                name="supplier_code"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Supplier Code!",
                  },
                ]}
              >
                <Input placeholder="Supplier Code" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Supplier Name"
                name="supplier_name"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Supplier Name!",
                  },
                ]}
              >
                <Input placeholder="Supplier Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-supplier-address">
            <Col span={24} className="desc-col">
              <Form.Item
                label="Supplier Address"
                name="supplier_address"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Supplier Address!",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  allowClear
                  maxLength={200}
                  rows={4}
                  placeholder="Supplier Address"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-supplier-id">
            <Col span={12}>
              <Form.Item
                label="Contact Person"
                name="contact_person_supplier"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Contact Person!",
                  },
                ]}
              >
                <Input placeholder="Contact Person" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Email"
                name="email_supplier"
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Email!",
                //   },
                // ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-supplier-id">
            <Col span={12}>
              <Form.Item
                label="Phone Number"
                name="cp_phone_number"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Phone Number!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Phone Number"
                  minLength={6}
                  maxLength={15}
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Telefax"
                name="telefax"
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Telefax!",
                //   },
                // ]}
              >
                <InputNumber
                  placeholder="Telefax"
                  minLength={6}
                  maxLength={15}
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30}>
            <Col span={12}>
              <Form.Item
                label="NPWP"
                name="npwp"
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input NPWP!",
                //   },
                // ]}
              >
                <InputNumber
                  placeholder="NPWP"
                  minLength={6}
                  maxLength={20}
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Bank Account"
                name="bank_of_account"
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Bank Account!",
                //   },
                // ]}
              >
                <Input
                  placeholder="Bank Account"
                  // minLength={6}
                  // maxLength={15}
                  // min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-row">
            <Col span={12}>
              <Form.Item
                label="Term of Payment"
                name="term_of_payment"
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Term of Payment!",
                //   },
                // ]}
              >
                <InputNumber
                  placeholder="Term of Payment"
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="VAT's Status"
                name="is_ppn"
                valuePropName="checked"
              >
                <Checkbox className="ppn-checkbox" onChange={handleCheck}>
                  {checked
                    ? `Supplier is Object of Tax`
                    : `Supplier is not Object of Tax`}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-supplier">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Amount" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ width: "100%" }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-supplier">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ width: "100%" }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row className="row-modal-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

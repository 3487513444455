// Import React Components
import React, { useState, useEffect } from "react";

// Import Ant Design Components
import { Col, Layout, List, Row, Skeleton, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import { mainBody } from "../../Style/Global/Global";

// CODE
export default function SkeletonLoad({ is_loading }) {
  // STATE MANAGEMENT
  // State
  const [loading, setLoading] = useState(true);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_loading == false && !cleanUp) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_loading]);

  // CONSOLE LOG
  // console.log("Loading Skeleton => ", loading);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="skeleton-main-layout">
            <Row className="row-title" justify="start" style={mainBody}>
              <Skeleton
                className="title-skeleton"
                loading={loading}
                active
                avatar
                style={{
                  padding: 30,
                }}
              />
            </Row>

            <Row
              className="row-space-component"
              justify="start"
              style={{
                margin: "30px 0px 0px",
              }}
            />

            <Row className="row-content" justify="start" style={mainBody}>
              <Skeleton
                className="content-skeleton"
                loading={loading}
                active
                paragraph={{
                  className: "paragraph-skeleton",
                  rows: 7,
                  width: "100%",
                }}
                style={{
                  padding: 30,
                }}
              />

              {/* <div
                className="div-skeleton"
                style={{
                  padding: "0px 30px 30px",
                  width: "100%",
                }}
              >
                <Skeleton.Button
                  className="content-skeleton"
                  loading={loading}
                  size="small"
                  active
                  block
                />

                <Skeleton.Button
                  className="content-skeleton"
                  loading={loading}
                  size="small"
                  active
                  block
                  style={{
                    margin: "10px 0px 0px",
                  }}
                />

                <Skeleton.Button
                  className="content-skeleton"
                  loading={loading}
                  size="small"
                  active
                  block
                  style={{
                    margin: "10px 0px 0px",
                  }}
                />

                <Skeleton.Button
                  className="content-skeleton"
                  loading={loading}
                  active
                  block
                  style={{
                    margin: "10px 0px 0px",
                  }}
                />

                <Skeleton.Button
                  className="content-skeleton"
                  loading={loading}
                  size="small"
                  active
                  block
                  style={{
                    margin: "10px 0px 0px",
                  }}
                />
              </div> */}
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

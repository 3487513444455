import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";

export default function InterKitchenTransferForm(props) {
  const { is_edit, is_loading, onCancel } = props;

  const [form] = Form.useForm();
  return (
    <>
      <Form
        name="add-transfer-request-modal-form"
        className="add-transfer-request-modal-form"
        autoComplete="off"
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        style={{ padding: "0px 36px" }}
      >
        <Row gutter={30} className="row-transfer-request">
          <Col span={24}>
            <Typography style={{ fontSize: "20px", textAlign: "center" }}>
              Inter-Kitchen Transfer
            </Typography>
          </Col>
        </Row>

        <Divider
          className="form-divider"
          style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
        />

        {is_edit == true ? (
          <>
            <Row gutter={30} className="created-date-transfer-request">
              <Col span={8}>
                <Form.Item
                  label="Created By"
                  name="created_by"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Created Date"
                  name="created_date"
                  style={{ width: "100%" }}
                >
                  <Input placeholder="Amount" disabled />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Updated By"
                  name="updated_by"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row className="mod-date-transfer-request">
              <Col span={8}>
                <Form.Item
                  label="Updated Date"
                  name="updated_date"
                  style={{ width: "100%" }}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="form-divider"
              style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
            />
          </>
        ) : null}

        <Row className="row-modal-btn" justify="end" align="middle">
          <Button
            type="primary"
            htmlType="submit"
            key={"submit"}
            loading={is_loading}
            className="submit-btn"
            style={{
              marginRight: 15,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={onCancel}>
            {`Cancel`}
          </Button>
        </Row>
      </Form>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterSegment(props) {
  // PROPS
  const {
    // Return Data
    getSegment,
    sgmnt,
    // Rendering
    is_render,
    is_rerender,
    // Disabling
    is_disable,
    // Params
    is_fromSalesman,
    is_company,
  } = props;

  // CONTEXT
  const { getMasterSegment } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [segment, setSegment] = useState([]);
  const [optionValue, setOptionValue] = useState([]);
  // Segment Company ID
  const [companyID, setCompanyID] = useState([]);
  // Re-Render
  const [render, setRender] = useState(false);
  //

  // Fetch Data
  const fetchData = async () => {
    await getMasterSegment({
      segment: "main-segment",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let _response = response.data.msg;

          let filtered = _response.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.main_segment_name,
            label: dev?.main_segment_name,
            code: dev?.main_segment_code,
            segment_id: dev?.id,
            key: dev?.id,
            className: dev?.id,
            is_dynamic: dev?.is_dynamic,
            is_authorized: dev?.is_authorized,
          }));

          console.log("SEGMENT = ", option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (is_fromSalesman === true && !cleanUp) {
      console.log("Segmentations: ", sgmnt);

      if (sgmnt?.length > 0) {
        setSegment(sgmnt);
      }
    } else {
      if (sgmnt) {
        setSegment(sgmnt);
      } else {
        setSegment(null);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [sgmnt]);

  useEffect(() => {
    let cleanUp = false;

    if (is_render == true && !cleanUp) {
      console.log("This Component is Re-rendering...");
      console.log("Segment Company: ", is_company);

      if (is_company?.length > 0 && !cleanUp) {
        setCompanyID(is_company);
        handleFilter(is_company);
      } else {
        fetchData();
        setSegment(null);
      }

      setRender(false);
      is_rerender(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_render, render, is_company]);

  // HANDLE FILTER
  const handleFilter = (val) => {
    const _id = val;

    if (optionValue?.length > 0) {
      let _options = optionValue.filter(({ segment_id }) => {
        // console.log("CODE: ", segment_id);

        return _id.includes(segment_id);
      });

      console.log("Option Filtered: ", _options);

      if (_options?.length > 0) {
        setSegment(_options[0]?.value);
        getSegment(
          _options[0]?.value,
          _options[0]?.segment_id,
          _options[0]?.code,
          _options[0]?.is_dynamic,
          _options[0]?.is_authorized,
        );
      } else {
        setSegment(null);
      }
    }
  };

  // ON CLEAR
  const handleClear = () => {
    setSegment(null);
  };

  return (
    <>
      <Select
        key={"segment-key"}
        className="segment-select"
        disabled={is_disable == true ? true : false}
        mode={is_fromSalesman == true ? "multiple" : null}
        allowClear
        showSearch
        placeholder="Select Segment!"
        value={segment ? segment : sgmnt}
        onClear={handleClear}
        onChange={(value, allKey) => {
          setSegment(value);

          console.log("Value: ", value, allKey);
          // console.log("ARR: ", code, id);

          if (is_fromSalesman) {
            let id = allKey.map((val) => {
              return val?.segment_id;
            });

            let code = allKey.map((val) => {
              return val?.code;
            });

            let dynamic = allKey.map((val) => {
              return val?.is_dynamic;
            });

            let authorized = allKey.map((val) => {
              return val?.is_authorized;
            });

            getSegment(value, id, code, dynamic, authorized);
          } else {
            getSegment(value, allKey?.segment_id, allKey?.code, allKey?.is_dynamic, allKey?.is_authorized);
          }
        }}
        style={{ minWidth: "100%" }}
        options={optionValue}
      />
    </>
  );
}

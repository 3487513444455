// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";

import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notification
import {
  masterIncomplete,
  masterSuccessNotification,
  masterFailedNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Modal Form
const { confirm } = Modal;

// CODE
export default function RoomCategoryTable(props) {
  // PROPS
  const { searchKey, is_search, on_modal, getRoomCode } = props;

  // CONTEXT
  const { getMasterRoomCat, getMasterAvail } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [avail, setAvail] = useState([]);
  // ID
  const [editData, setEditData] = useState(null);
  const [roomCode, setRoomCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ROOM CATEGORY => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        setLoading(false);
      },
    });

    await getMasterAvail({
      type: "category/availability",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Update Room Category Availability => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            if (!val.is_deleted) {
              return val;
            }
          });

          console.log("Availability FILTERED => ", filtered);
          setAvail(filtered);
        } else {
          setAvail([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR ==> ", error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
    // fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-room-cat-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      // console.log("ABOUT TO EDIT > ", record);

                      setOpen(true);
                      setEdit(true);
                      setIsDelete(false);
                      setEditData(record);

                      setFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showModalDelete(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "room_category_code",
      key: "room_category_code",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Name",
      dataIndex: "room_category_name",
      key: "room_category_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total Room(s)",
      dataIndex: "number_of_rooms",
      key: "number_of_rooms",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Description",
      dataIndex: "room_category_description",
      key: "room_category_description",
      sortDirections: ["descend", "ascend"],
    },
  ];

  // Set Form Fields
  const setFormField = (val) => {
    const _record = val;

    form.setFieldsValue({
      room_category_code: _record.room_category_code,
      room_category_description: _record.room_category_description,
      room_category_id:
        _record?.room_category_id > 0 ? _record.room_category_id : 0,
      room_category_name: _record.room_category_name,
      number_of_rooms: _record?.number_of_rooms ? _record.number_of_rooms : 0,

      created_by: _record.created_by,
      created_date: _record.created_date,
      modified_by: _record.updated_by,
      modified_date: _record.updated_date,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setIsDelete(true);
    setEditData(null);

    form.resetFields();
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (record) => {
    const _content = record;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to ${
        edit == true ? "Update" : "Add a New"
      } Room Category with named ${
        _content?.room_category_name.toUpperCase() || "----"
      }?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
        // disabled
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // SHOW MODAL DELETE
  const showModalDelete = (record) => {
    const _content = record;
    let _disabled = false;

    if (avail.length > 0) {
      const _find = avail.filter((items) => {
        return items?.room_category_id == _content.id;
      });

      if (_find.length > 0) {
        _disabled = true;
      } else {
        _disabled = false;
      }
    } else {
      _disabled = true;
    }

    console.log("FOUND! ", _disabled);

    confirm({
      className: "delete-confirm",
      title: _disabled
        ? `Warning! You can not DELETE this Room Category '${
            _content?.room_category_name.toUpperCase() || "----"
          }' because its contained ROOM AVAILABILITY that are still used!`
        : `Are you sure want to Delete Room ${
            _content?.room_category_name.toUpperCase() || "----"
          } from the table?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
        disabled: _disabled,
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log("On Finish = ", value);
    let content = value;

    setIsLoading(true);

    // handleSubmit(content);
    showModalConfirm(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentSubmit = e;

    console.log("ON OK ==> ", contentSubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/room/category`,
          {
            room_category_code: contentSubmit?.room_category_code,
            room_category_name: contentSubmit?.room_category_name,
            room_category_description:
              contentSubmit?.room_category_description || null,
            number_of_rooms: 0,
            created_by: user_name,
            // created_date: moment().format("YYYY-MM-DD"),
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log(response);
          masterSuccessNotification(response, {
            method: 0,
            source: "Room Category",
          });

          fetchData();
          handleCancel();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);

          masterFailedNotification(error, {
            method: 0,
            source: "Room Category",
          });
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/room/category`,
          {
            // room_category_id: contentSubmit?.room_category_id || editData?.room_category_id,
            room_category_code:
              contentSubmit?.room_category_code || editData?.room_category_code,
            room_category_name:
              contentSubmit?.room_category_name || editData?.room_category_name,
            room_category_description:
              contentSubmit?.room_category_description ||
              editData?.room_category_description,
            id: editData?.id,
            number_of_rooms:
              contentSubmit?.number_of_rooms > 0
                ? contentSubmit?.number_of_rooms
                : editData?.number_of_rooms,
            created_by: editData?.created_by,
            // created_date: editData?.created_date,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Add Resp => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Room Category",
          });
          // form.resetFields();
          fetchData();
          setIsLoading(false);
          handleCancel();
        })
        .catch((error) => {
          console.log("Error Add => ", error);

          masterFailedNotification(error, {
            method: 1,
            source: "Room Category",
          });
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record.id;

    console.log("contentDelete >>>>>>", contentdelete);

    var obj = {
      ["id"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/room/category`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Del Room Cat => ", response);
        masterSuccessNotification(response, {
          method: 2,
          source: "Room Category",
        });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Room Category" });
        console.log("Del Room => ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      setRoomCode(selectedRows);
      getRoomCode(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // Handle Export
  const handleExport = () => {
    downloadExcel(data, "Room Category list");
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item)
        let _name = item.room_category_name.toLowerCase();
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row className="room-cat-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            className="row-modal-btn"
            gutter={[0, 30]}
            justify="end"
            align="bottom"
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="col-exp-btn"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="col-btn"
            >
              <Row
                justify="end"
                className="group-btn-row"
                align="bottom"
                gutter={[0, 15]}
              >
                {on_modal === true ? (
                  <Button
                    className="submit-btn"
                    type="primary"
                    onClick={handleOpen}
                    icon={<PlusOutlined />}
                  >
                    {`Add Room Category`}
                  </Button>
                ) : null}

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                {on_modal === true ? (
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleReturn}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Return`}
                  </Button>
                ) : null}
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="table-room-cat"
              name="table-room-cat"
              key="table-room-cat"
              columns={columns}
              dataSource={data}
              rowSelection={
                on_modal == false
                  ? {
                      type: "radio",
                      ...rowSelection,
                    }
                  : false
              }
              bordered
              loading={loading}
              size={on_modal == true ? "large" : "small"}
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={
                on_modal == true
                  ? {
                      x: 1000,
                    }
                  : {
                      x: 500,
                    }
              }
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-room-cat-modal"
        title={
          edit === false ? (
            <Row className="row-modal-title">
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Room Category`}
              </Typography>
            </Row>
          ) : (
            <Row className="row-modal-title">
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Room Category`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 15,
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          name="room-cat-modal-form"
          className="room-cat-modal-form"
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-room-cat-id" justify="start">
            <Col span={12}>
              <Form.Item
                label="Code"
                name="room_category_code"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Room Category Code!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Room Category Code" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Name"
                name="room_category_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Room Category Name!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Room Category Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Number of Room"
                name="number_of_rooms"
                hidden
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input Number of Rooms!",
                //   },
                // ]}
              >
                <InputNumber
                  placeholder="Total Rooms"
                  min={0}
                  style={{ width: "100%" }}
                  disabled={edit}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item label="Description" name="room_category_description">
              <Input.TextArea
                showCount
                maxLength={200}
                style={{
                  height: 100,
                  width: 440,
                }}
                placeholder="Room Description"
              />
            </Form.Item>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "15px 0px", background: "#EBEDF3" }}
          />

          {edit === true ? (
            <>
              <Row gutter={30} className="created_date-room-cat">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input disabled placeholder="Created by" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input disabled placeholder="Created Date" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input disabled placeholder="Modified by" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-room-cat">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input disabled placeholder="Modified Date" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../../API/Global/Payload";

// BASE API
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Tooltip,
  Tag,
  Modal,
  Typography,
  Input,
  DatePicker,
  InputNumber,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons/lib/icons";

// Import Notifications
import {
  failedFetch,
  failedRoomStatus,
  successRoomStatus,
} from "../../../Reusable/Notification/Notification";
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import MasterRoomStatus from "../../../Reusable/Master/RoomStatus/RoomStatus";
import MasterRoomNumber from "../../../Reusable/Master/RoomNumber/RoomNumber";
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import ModalPermission from "../../../Modals/Permissions/Modal";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function OffMarketTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Today
  const today = moment();

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      // fixed: "left",
      width: 150,
      render: (_, record) => {
        let _data = record;
        let _disable = false;
        let _date = moment(record?.date).add(record?.duration, "days");

        // console.log(record);

        if (moment(_date).isBefore(today)) {
          _disable = true;
        }

        return (
          <Tooltip title="Change Status Room" className="status-tooltip">
            {!_disable ? (
              <Button
                type="primary"
                className="submit-btn"
                icon={<FormOutlined />}
                style={
                  {
                    // backgroundColor: "#13c2c2",
                    // borderColor: "#13c2c2",
                    // marginRight: 15,
                  }
                }
                onClick={() => {
                  setIsCancel(true);
                  setOpen(true);

                  setEditData(record);
                  setDataStatus(record);

                  setFormFields(record);
                }}
              >
                {`Change Status`}
              </Button>
            ) : (
              <Button
                type="primary"
                className="next-btn"
                icon={<ExclamationCircleOutlined />}
                // style={{
                //   backgroundColor: "#13c2c2",
                //   borderColor: "#13c2c2",
                //   // marginRight: 15,
                // }}
                onClick={() => {
                  setIsCancel(true);
                  setOpen(true);

                  setEditData(record);
                  setDataStatus(record);
                  setDisabled(true);

                  setFormFields(record);
                }}
              >
                {`Details`}
              </Button>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Category Code",
      dataIndex: "room_category",
      key: "room_category",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
    },
    {
      title: "Date Started OM",
      dataIndex: "date",
      key: "date",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Durations (days)",
      dataIndex: "duration",
      key: "duration",
      render: (duration) => {
        return duration > 0 ? duration : 0;
      },
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
    },
    {
      title: "Expected Available",
      dataIndex: "",
      key: "expected",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
      render: (date, record) => {
        return moment(record.date)
          .add(record.duration, "days")
          .format("DD-MM-YYYY");
      },
    },
    {
      title: "Room Status",
      dataIndex: "reservation_id",
      key: "reservation_id",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_status_id.localeCompare(b.room_status_id),
      render: (room_status, record) => {
        let status = "";
        let clr = "";
        let _key = record.room_number;

        switch (room_status) {
          case "Off-Market":
            clr = "#722ed1";
            break;
          case "Out Of Service":
            clr = "brown";
            break;

          default:
            break;
        }

        return (
          <Tag key={_key} color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
            {room_status}
          </Tag>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_category_code - b.room_category_code,
    },
  ];

  // CONTEXT
  const { state, getRoomStatus, getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [isDeleted, setIsDeleted] = useState([]);
  const [editData, setEditData] = useState(null);
  const [modalData, setModalData] = useState(null);
  // Data Status
  const [dataStatus, setDataStatus] = useState(null);
  const [status, setStatus] = useState(null);
  // IS CANCEL
  const [isCancel, setIsCancel] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [inform, setInform] = useState(false);
  const [modalPermit, setModalPermit] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = () => {
    getRoomStatus({
      status: "out-of-market",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((value) => {
            return !value.is_deleted;
          });

          let _deleted = _response.filter((value) => {
            return value.is_deleted;
          });

          console.log("Deleted Row: ", _deleted);

          setData(filtered);
          setIsDeleted(_deleted);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error --> ", error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    // if (refresh && !cleanUp) {
    //   setRefresh(false);
    // }

    return () => {
      cleanUp = true;
    };
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (modalData && !cleanUp) {
      console.log("Modal Data: ", modalData);
      setModalPermit(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [modalData]);

  // SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // GET STATUS
  const getStatus = (value, index) => {
    setStatus(value, index);

    form.setFieldsValue({
      room_status_id: value,
      room_status: index,
    });
  };

  // GET ROOM NUMBER
  const getRoomNumber = (value) => {
    const _room = value;
    // setStatus(value);

    const _enum = _room?.number?.length > 0 ? _room.number[0] : _room?.number;
    const _key = _room?.info?.status > 0 ? _room.info.status : 0;
    const _info = _room?.info;

    let _status = "";

    console.log("Props Room Number =", _room);
    console.log("Room Informations =", _info);

    // Case
    switch (_key) {
      case 1:
        _status = "Vacant Clean Unchecked";
        break;

      case 2:
        _status = "Vacant Clean Checked";
        break;

      case 3:
        _status = "Occupied Dirty";

        break;
      case 4:
        _status = "Occupied Clean";
        break;

      case 5:
        _status = "Vacant Dirty";
        break;

      case 6:
        _status = "Out of Order";
        break;

      case 7:
        _status = "Out of Service";
        break;

      case 8:
        _status = "Occupied No Luggage";
        break;

      case 9:
        _status = "No Show";
        break;

      case 10:
        _status = "Off-Market";
        break;

      default:
        _status = "UNKNOWN";
        break;
    }

    console.log("Set Room = ", _enum);

    form.setFieldsValue({
      room_number: _enum,
      room_id: _room?.room_id,
      room_category_name: _room?.category,
      room_category_id: _room?.category_id,
      status: _status,
      occupants: _info?.occupants == null ? "No Occupants" : _info.occupants,
      occ_status: _info?.occ_status ? "Occupied" : "Unoccupied",
      updated_date: _info?.updated_date
        ? moment(LocalizationDate(_info.updated_date)).format(
            "HH:mm:ss, DD-MM-YYYY"
          )
        : null,
    });
  };

  // SET FORM FIELDS
  const setFormFields = (data) => {
    const _record = data;
    let _start = moment(data?.date).format("YYYY-MM-DD");
    let _end = moment(_record.date)
      .add(_record.duration, "days")
      .format("YYYY-MM-DD");

    console.log("Informations = ", _record);
    console.log("Start Date = ", _start);
    console.log("End Date = ", _end);
    console.log(isDeleted.length);

    if (isDeleted.length > 0) {
      let _dateDel = [];

      isDeleted.filter((val) => {
        let _date = moment(val.date).format("YYYY-MM-DD");
        console.log("Deleted Value: ", _date);

        if (moment(_date).isBetween(_start, _end, undefined, "[]")) {
          return _dateDel.push(val);
          // moment(_date).isBetween(_start, _end, undefined, "[]");
        }
      });

      console.log("Deleted Date: ", _dateDel);

      form.setFieldsValue({
        deleted_date:
          _dateDel?.length > 0
            ? moment(_dateDel[0].date).format("YYYY-MM-DD")
            : null,
      });
    }

    form.setFieldsValue({
      room_number: _record.room_number,
      room_status_id: _record.reservation_id,
      description: _record.description,
      reason: _record.description,
      night: _record.duration,
      date: _record?.date ? moment(_record.date) : null,
      start_cancel_date: moment(),
    });
  };
  // HANDLE OPEN
  const handleOpen = () => {
    setOpen(true);
  };

  // HANDLE CHANGE
  const onChange = () => {
    const _record = editData;

    form.setFieldsValue({
      room_number: _record.room_number,
      room_status_id: _record.reservation_id,
      reason: _record.description,
      night: _record.duration,
      date: _record?.date ? moment(_record.date) : null,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log("ON FINISH >>> ", value);
    setModalData(value);

    // showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("ON FINISH FAILED >>> ", error);

    masterIncomplete(error);
  };

  // HANDLE CANCEL
  const handleCancel = (val) => {
    console.log("Cancel True: ", val);
    if (val == true) {
      fetchData();
    }

    setOpen(false);
    setStatus(null);

    setIsCancel(false);
    setDataStatus(null);
    setDisabled(false);

    form.resetFields();
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey.toString();
    // console.log(query);

    var updatedList = [...data];
    // console.log("Update List = ", updatedList);

    updatedList = updatedList.filter((item) => {
      // if (item.room_number == query) {
      const filter = item.room_number.toString();

      // console.log(filter.indexOf(query) !== -1);
      return filter.indexOf(query) !== -1;
    });
    // Trigger render with updated values
    setData(updatedList);
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row className="offmarket-main-table" justify="center" style={mainBody}>
        <Col span={24} className="col-table" style={{ padding: 30 }}>
          <Row
            justify="end"
            className="row-refresh-btn"
            align="top"
            gutter={[0, 30]}
          >
            <Button
              className="modal-open-btn"
              type="primary"
              danger
              icon={<PlusOutlined />}
              onClick={handleOpen}
            >
              {`Add Off-Market`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
              style={{
                margin: "0px 0px 0px 15px",
              }}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="offmarket-table"
              name="offmarket-table"
              key="offmarket-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1500,
              }}
              rowKey={(record) => (record.id ? record.id : record.room_number)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="room-status-modal"
        title={
          <Row>
            <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography style={{ marginLeft: 15 }}>
              {`Out of Order Room Modals`}
            </Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="room-status-modal-form"
          className="room-status-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="space-row" justify="start" align="top">
            <Col span={16} className="form-col" style={{ width: "100%" }}>
              <Row gutter={30} className="row-room-id">
                <Col span={12} className="room-col">
                  <Form.Item
                    label="Room Number"
                    name="room_number"
                    rules={[
                      {
                        required: !isCancel,
                        message: "Please, Choose a Room Number!",
                      },
                    ]}
                  >
                    {isCancel ? (
                      <InputNumber
                        placeholder="Room Number"
                        onChange={onChange}
                        className="room-number"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      <MasterRoomNumber
                        getRoomNumber={getRoomNumber}
                        room_number={isCancel ? dataStatus?.room_number : 0}
                        isGroup={false}
                        is_disable={false}
                        rateRoomCatg={true}
                      />
                    )}
                  </Form.Item>

                  <Form.Item label="Room Number" name="room_id" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Room Category"
                    name="room_category_name"
                    hidden
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Room Category"
                    name="room_category_id"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="status-col">
                  <Form.Item
                    label="Room Status"
                    name="room_status_id"
                    rules={[
                      {
                        required: !isCancel,
                        message: "Please, Choose a Status Room!",
                      },
                    ]}
                  >
                    {isCancel ? (
                      <Input
                        className="room-status"
                        placeholder="Room Status"
                        onChange={onChange}
                      />
                    ) : (
                      <MasterRoomStatus
                        getStatus={getStatus}
                        status={isCancel ? dataStatus?.reservation_id : null}
                        is_order={2}
                      />
                    )}
                  </Form.Item>

                  <Form.Item label="Room Status" name="room_status" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="date-row">
                <Col className="date-col" span={12}>
                  <Form.Item
                    label="Start Date"
                    name="date"
                    rules={[
                      {
                        required: !isCancel,
                        message: "Please, Choose a Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Start Date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col className="night-col" span={12}>
                  <Form.Item
                    label="Durations"
                    name="night"
                    rules={[
                      {
                        required: !isCancel,
                        message: "Please, Input how long the Durations!",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Durations"
                      addonAfter={"Night(s)"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="row-desc">
                <Col span={24}>
                  <Form.Item
                    label="Description"
                    name="description"
                    hidden={isCancel}
                    rules={[
                      {
                        required: !isCancel,
                        message: "Please, Input Descriptions!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      className="desc"
                      placeholder="Input Descriptions of OFF-MARKET!"
                      maxLength={200}
                      showCount
                      style={{
                        width: 440,
                        height: 100,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {isCancel && disabled == false ? (
                <Row className="date-row" style={{ width: "100%" }}>
                  <Col span={16}>
                    <Form.Item
                      label="Cancel Date"
                      name="start_cancel_date"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, pick a Date!",
                      //   },
                      // ]}
                    >
                      <DatePicker
                        placeholder="Cancel from Date?"
                        disabled={true}
                        format={"YYYY-MM-DD"}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
            </Col>

            <Col
              span={8}
              className="info-col"
              hidden={!isCancel}
              style={{
                width: "100%",
              }}
            >
              <Form.Item label="Description" name="reason">
                <Input.TextArea
                  maxLength={200}
                  placeholder="OOO Descriptions"
                  showCount
                  style={{
                    width: "100%",
                    height: 120,
                  }}
                />
              </Form.Item>

              <Form.Item label="Deleted Date" name="deleted_date">
                <Input placeholder="Deleted at" disabled />
              </Form.Item>
            </Col>

            <Col
              span={8}
              className="info-col"
              hidden={isCancel}
              style={{
                border: "1px solid #000000",
                borderRadius: 5,
                minHeight: 320,
                backgroundColor: "#bae7ff",
                fontWeight: 750,
              }}
            >
              <Row
                className="info-row"
                style={{
                  fontSize: 20,
                  // color: "#ffffff",
                  margin: "0px 0px 15px",
                }}
              >{`Informations`}</Row>

              <Row className="current-info">{`Current Room Status: `}</Row>

              <Row className="status-row">
                <Form.Item name="status">
                  <Input placeholder="Current Room Status" />
                </Form.Item>
              </Row>

              <Row className="current-info">{`Current Occ Status: `}</Row>

              <Row className="current-info">
                <Form.Item name="occ_status">
                  <Input placeholder="Current Occ Status" />
                </Form.Item>
              </Row>

              <Row className="current-info">{`Current Occupants: `}</Row>

              <Row className="current-info">
                <Form.Item name="occupants">
                  <Input placeholder="Current Room Occupants" />
                </Form.Item>
              </Row>

              <Row className="current-info">{`Last Updated: `}</Row>

              <Row className="current-info">
                <Form.Item name="updated_date">
                  <Input placeholder="Last Updated" />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          <Row
            gutter={30}
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              disabled={disabled}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>

      <ModalPermission
        openModal={modalPermit}
        closeModal={setModalPermit}
        dataSubmit={modalPermit ? modalData : []}
        roomStatus={1}
        is_refresh={handleCancel}
        is_deleted={isCancel}
      />
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../API/AuthActions/authAction";

// AUTH
import {
  roles,
  department,
  token,
  sub_children,
  module,
} from "../../API/Global/Payload";

// Import Protected Routes
import {
  ProtectedAdminRoute,
  ProtectedStaffRoute,
  ProtectedManagerRoute,
  ProtectedSupervisorRoute,
  ProtectedSuperAdminRoute,
  ProtectedRoute,
} from "../Roles/Routes";

// Import Page Components
// AUTH
import LoginPage from "../../Pages/Auth/Login/Login";
// Main Routes
import DashboardPage from "../../Pages/Dashboard/Dashboard";
import TaskListPage from "../../Pages/TaskList/TaskList";
import BackOfficeRoutes from "../BackOffice/Routes";
import FrontOfficeRoutes from "../FrontOffice/Routes";
import HouseKeepingRoutes from "../HouseKeeping/Routes";
// import AccountingRoutes from "../Accounting/Routes";
import ConfigRoutes from "../Configuration/Routes";
import FandBRoutes from "../FandB/Routes";
import ReportRoutes from "../Report/Routes";
import ChangelogVersionPage from "../../Pages/Changelog/Versions/Version";
import UserProfilePage from "../../Pages/Auth/Profile/Profile";

import InventoryRoutes from "../BackOffice/Inventory/Routes";
import MasterRoutes from "../Master/Routes";
import OutletRoutes from "../Outlet/Routes";

// Error, Unauthorized & Internal Server Error
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";
import NotFound from "../../Pages/NotFound/NotFound";
import InternalError from "../../Pages/NotFound/InternalError";

// DISPATCH AUTH
const mapDispatchToProps = (dispatch) => {
  return {
    disLogin: (values) => dispatch(auth.login(values.email, values.password)),
  };
};

const mapStateToProps = (state) => {
  // console.log(state);

  let response = state;
  let user = response.rootReducer.auth;
  // const {isLoggedIn, accessToken} = user
  return { user };
};

// List Routes
const mainRoute = [
  {
    path: "/front-office/*",
    name: "front-office",
    id_modules: 1,
    element: <FrontOfficeRoutes />,
  },
  {
    path: "/house-keeping/*",
    name: "house-keeping",
    id_modules: 2,
    element: <HouseKeepingRoutes />,
  },
  {
    path: "/back-office/*",
    name: "back-office",
    id_modules: 3,
    element: <BackOfficeRoutes />,
  },
  {
    path: "/f-and-b/*",
    name: "f-and-b",
    id_modules: 5,
    element: <FandBRoutes />,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    id_modules: 6,
    element: <DashboardPage />,
  },
  {
    path: "/report/*",
    name: "report",
    id_modules: 7,
    element: <ReportRoutes />,
  },
  {
    path: "/configuration/*",
    name: "configuration",
    id_modules: 8,
    element: <ConfigRoutes />,
  },
];

// CODE
const MainRoutes = (props) => {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // USE EFFECT
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    const _mods = module?.length > 0 ? module : []; // values.module;

    const proRoute = mainRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route key={value.id_modules}>
            <Route path={value.path} element={value.element} key={value.name} />
          </Route>
        );
      } else {
        <Route path={value.path} element={<UnAuthorized />} key={value.name} />;
      }
    });

    // console.log("Protected Routes: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
    // return _arr;
  };

  return (
    <>
      <Routes key="main-locations">
        <Route path="/changelog-versions" element={<ChangelogVersionPage />} />

        <Route path="*" element={<NotFound />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />
        <Route path="/internal-server-error" element={<InternalError />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/task-list" element={<TaskListPage />} />

        <Route
          path="configuration/user-management/user-profile"
          element={<UserProfilePage />}
        />

        {protectedRoutes}
      </Routes>
      <Outlet />

      {/* {roles.includes("ROLE_SUPER_ADMIN") ? ( 
          <Route exact path="/" element={<ProtectedSuperAdminRoute />}>
          */}
      {/* <Route>
          <Route path="/front-office/*" element={<FrontOfficeRoutes />} />
        </Route>

        <Route>
          <Route path="/back-office/*" element={<BackOfficeRoutes />} />
        </Route>

        <Route>
          <Route path="/house-keeping/*" element={<HouseKeepingRoutes />} />
        </Route>

        <Route>
          <Route path="/f-and-b/*" element={<FandBRoutes />} />
        </Route>

        <Route>
          <Route path="/accounting/*" element={<AccountingRoutes />} />
        </Route>

        <Route>
          <Route path="/report/*" element={<ReportRoutes />} />
        </Route>

        <Route>
          <Route path="/configuration/*" element={<ConfigRoutes />} />
        </Route> */}
      {/* 
        </Route>
        ) : [
            "ROLE_STAFF",
            "ROLE_SUPERVISOR",
            "ROLE_MANAGER",
            "ROLE_ADMIN",
          ].some((e) => roles.includes(e)) ? (
          <Route
            exact
            path="/"
            element={
              roles == "ROLE_STAFF" ? (
                <ProtectedStaffRoute />
              ) : roles == "ROLE_SUPERVISOR" ? (
                <ProtectedSupervisorRoute />
              ) : roles == "ROLE_MANAGER" ? (
                <ProtectedManagerRoute />
              ) : roles == "ROLE_ADMIN" ? (
                <ProtectedAdminRoute />
              ) : (
                <Route path="/unauthorized" element={<UnAuthorized />} />
              )
            }
          >
            <Route path="/login" element={<LoginPage />} />

            <Route path="*" element={<UnAuthorized />} />
            <Route path="/unauthorized" element={<UnAuthorized />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/internal-server-error" element={<InternalError />} />
            <Route path="/dashboard" element={<DashboardPage />} />

            <Route>
              <Route path="/report/*" element={<ReportRoutes />} />
            </Route>

            {department.includes("FRONT_OFFICE") ? (
              <>
                <Route>
                  <Route
                    path="/front-office/*"
                    element={<FrontOfficeRoutes />}
                  />
                </Route>

                <Route>
                  <Route path="/configuration/*" element={<ConfigRoutes />} />
                </Route>
              </>
            ) : (
              <Route path="/unauthorized" element={<UnAuthorized />} />
            )}
            {department.includes("HOUSE_KEEPING") ? (
              <Route>
                <Route
                  path="/house-keeping/*"
                  element={<HouseKeepingRoutes />}
                />
              </Route>
            ) : (
              <Route path="/unauthorized" element={<UnAuthorized />} />
            )}
            {department.includes("BACK_OFFICE") ? (
              <Route>
                <Route>
                  <Route path="/back-office/*" element={<BackOfficeRoutes />} />
                </Route>
              </Route>
            ) : (
              <Route path="/unauthorized" element={<UnAuthorized />} />
            )}
            {department.includes("OUTLET") ? (
              <Route>
                <Route>
                  <Route path="/f-and-b/*" element={<FandBRoutes />} />
                </Route>
              </Route>
            ) : (
              <Route path="/unauthorized" element={<UnAuthorized />} />
            )}
            {department.includes("ACCOUNTING") ? (
              <Route>
                <Route>
                  <Route path="/accounting/*" element={<AccountingRoutes />} />
                </Route>
              </Route>
            ) : (
              <Route path="/unauthorized" element={<UnAuthorized />} />
            )}

            {department.includes("ALL") ? (
              <Route>
                <Route>
                  <Route path="/configuration/*" element={<ConfigRoutes />} />
                </Route>
              </Route>
            ) : (
              <Route path="/unauthorized" element={<UnAuthorized />} />
            )}
          </Route>
        ) : null} */}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);

// Import Ant Design Components
import {
  CarryOutOutlined,
  CheckOutlined,
  FormOutlined,
} from "@ant-design/icons";

// DATA TREE AKTIVA
const dataAktiva = [
  {
    title: "Current Assets",
    key: "0-0",
    icon: <CarryOutOutlined />,
    children: [
      {
        title: "Cash",
        key: "0-0-0",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "110 - Cash on Hand",
            key: "0-0-0-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "111 - Cash Clearance",
            key: "0-0-0-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "113 - Petty Cash",
            key: "0-0-0-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Bank",
        key: "0-0-1",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Bank 1",
            key: "0-0-1-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Bank 2",
            key: "0-0-1-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Bank 3",
            key: "0-0-1-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Cash and Bank",
        key: "0-0-2",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Total Cash and Bank 1",
            key: "0-0-2-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Total Cash and Bank 2",
            key: "0-0-2-1",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
          {
            title: "Total Cash and Bank 3",
            key: "0-0-2-2",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
        ],
      },
      {
        title: "Account Receiveable",
        key: "0-0-3",
        children: [
          {
            title: "Account Receiveable 1",
            key: "0-0-3-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Account Receiveable 2",
            key: "0-0-3-1",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
          {
            title: "Account Receiveable 3",
            key: "0-0-3-2",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
        ],
      },
      {
        title: "Inventories",
        key: "0-0-4",
        children: [
          {
            title: "Inventory 1",
            key: "0-0-4-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Inventory 2",
            key: "0-0-4-1",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
          {
            title: "Inventory 3",
            key: "0-0-4-2",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
        ],
      },
      {
        title: "Prepaid Expenses",
        key: "0-0-5",
        children: [
          {
            title: "Prepaid Expenses 1",
            key: "0-0-5-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Prepaid Expenses 2",
            key: "0-0-5-1",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
          {
            title: "Prepaid Expenses 3",
            key: "0-0-5-2",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
        ],
      },
      {
        title: "Other Current Asset",
        key: "0-0-6",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Other Current Asset 1",
            key: "0-0-6-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Other Current Asset 2",
            key: "0-0-6-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Other Current Asset 3",
            key: "0-0-6-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total AR & Inventory",
        key: "0-0-7",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Total AR & Inventory 1",
            key: "0-0-7-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Total AR & Inventory 2",
            key: "0-0-7-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Total AR & Inventory 3",
            key: "0-0-7-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Current Asset",
        key: "0-0-8",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Total Current Asset 1",
            key: "0-0-8-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Total Current Asset 2",
            key: "0-0-8-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Total Current Asset 3",
            key: "0-0-8-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
    ],
  },
  {
    title: "Fixed Assets",
    key: "0-1",
    icon: <CarryOutOutlined />,
    children: [
      {
        title: "Property & Equipment",
        key: "0-1-0",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "161 - Property & Equipment",
            key: "0-1-0-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Accumulated Depreciation Property & Equipment",
        key: "0-1-1",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Accumulated Depreciation Property & Equipment 1",
            key: "0-1-1-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Operating Equipment",
        key: "0-1-2",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Operating Equipment 1",
            key: "0-1-2-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Accumulated Depreciation Operating Equipment",
        key: "0-1-3",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Accumulated Depreciation Operating Equipment 1",
            key: "0-1-3-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Fixed Asset",
        key: "0-1-4",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "161 - Property and Equipment",
            key: "0-1-4-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "170 - Accumulated Depreciation Property & Equipment",
            key: "0-1-4-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "160 - Operating Equipment",
            key: "0-1-4-2",
            icon: <CarryOutOutlined />,
          },
          {
            title: "171 - Accumulated Depreciation Operating Equipment",
            key: "0-1-4-3",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Other Assets",
        key: "0-1-5",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Other Assets 1",
            key: "0-1-5-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Accumulated Depreciation Other Asset",
        key: "0-1-6",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Accumulated Depreciation Other Asset 1",
            key: "0-1-6-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Non Current Assets",
        key: "0-1-7",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Total Non Current Assets 1",
            key: "0-1-7-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Assets",
        key: "0-1-8",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Total Assets 1",
            key: "0-1-8-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
    ],
  },
];

// DATA TREE PASSIVA
const dataPassiva = [
  {
    title: "Current Liabilitas",
    key: "0-0",
    icon: <CarryOutOutlined />,
    children: [
      {
        title: "Account Payable Trade",
        key: "0-0-0",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "200 - Account Payable",
            key: "0-0-0-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Account Payable Tax",
        key: "0-0-1",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Account Payable Tax 1",
            key: "0-0-1-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Account Payable Tax 2",
            key: "0-0-1-1",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Accrued Expenses",
        key: "0-0-2",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Accrued Expenses 1",
            key: "0-0-2-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Accrued Expenses 2",
            key: "0-0-2-1",
            icon: <CarryOutOutlined />,
            // switcherIcon: <FormOutlined />,
          },
        ],
      },
      {
        title: "Guest Deposit",
        key: "0-0-3",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Guest Deposit 1",
            key: "0-0-3-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Guest Deposit 2",
            key: "0-0-3-1",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Other Liabilities",
        key: "0-0-4",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Other Liabilities 1",
            key: "0-0-4-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Other Liabilities 2",
            key: "0-0-4-1",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Current Liabilities",
        key: "0-0-5",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "200 - Account Payable",
            key: "0-0-5-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "210 - Tax Payable",
            key: "0-0-5-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "220 - Accrued Expenses",
            key: "0-0-5-2",
            icon: <CarryOutOutlined />,
          },
          {
            title: "230 - Guest Deposit",
            key: "0-0-5-3",
            icon: <CarryOutOutlined />,
          },
          {
            title: "240 - Others Liabilities",
            key: "0-0-5-4",
            icon: <CarryOutOutlined />,
          },
        ],
      },
    ],
  },
  {
    title: "Long Term Debt",
    key: "0-1",
    icon: <CarryOutOutlined />,
    children: [
      {
        title: "Bank Loan",
        key: "0-1-0",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "260-01-010 - Bank Loan BRI",
            key: "0-1-0-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Lessing (Long Term Portion)",
        key: "0-1-1",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "LTP 1",
            key: "0-1-1-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "LTP 2",
            key: "0-1-1-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "LTP 3",
            key: "0-1-1-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Affiliated Company Payable",
        key: "0-1-2",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Affiliated Company Payable 1",
            key: "0-1-2-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Affiliated Company Payable 2",
            key: "0-1-2-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Affiliated Company Payable 3",
            key: "0-1-2-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Long Term Debt",
        key: "0-1-3",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "260-01-010 - Bank Loan BRI",
            key: "0-1-3-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "260-01-020 - Lessing",
            key: "0-1-3-1",
            icon: <CarryOutOutlined />,
          },
          {
            title: "250 - Affiliated Company Payable",
            key: "0-1-3-2",
            icon: <CarryOutOutlined />,
          },
        ],
      },
    ],
  },
  {
    title: "Capital",
    key: "0-2",
    icon: <CarryOutOutlined />,
    children: [
      {
        title: "Owner Capital",
        key: "0-2-0",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "300-01-101 - Owner Capital",
            key: "0-2-0-0",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Owner Drawing",
        key: "0-2-1",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Owner Drawing 1",
            key: "0-2-1-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Owner Drawing 2",
            key: "0-2-1-1",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Additional Paid in Capital",
        key: "0-2-2",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Additional Paid in Capital 1",
            key: "0-2-2-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Additional Paid in Capital 2",
            key: "0-2-2-1",
            icon: <CarryOutOutlined />,
          },
        ],
      },
      {
        title: "Total Capital",
        key: "0-2-3",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "Total Capital 1",
            key: "0-2-3-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "Total Capital 2",
            key: "0-2-3-1",
            icon: <CarryOutOutlined />,
          },
        ],
      },
    ],
  },
  {
    title: "Retained Barning",
    key: "0-3",
    icon: <CarryOutOutlined />,
    children: [
      {
        title: "parent 2-0",
        key: "0-3-0",
        icon: <CarryOutOutlined />,
        children: [
          {
            title: "leaf",
            key: "0-3-0-0",
            icon: <CarryOutOutlined />,
          },
          {
            title: "leaf",
            key: "0-3-0-1",
            icon: <CarryOutOutlined />,
          },
        ],
      },
    ],
  },
];

// EXPORT TREE DATA
export { dataAktiva, dataPassiva };

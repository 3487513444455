// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterGuestCat({ getCategory, guest_status }) {
  // CONTEXT
  const { getMasterGuestCat } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [category, setCategory] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = () => {
    getMasterGuestCat({
      category: "guest-category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0 || response?.data != null) {
          let option = response.data.msg.map((dev) => ({
            value: dev.guest_category,
            label: dev.guest_category,
          }));

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Select
        key={optionValue.value}
        style={{ width: "100%" }}
        value={category != null ? category : guest_status}
        onChange={(value) => {
          setCategory(value);
          console.log(value);

          getCategory(value);
        }}
        options={optionValue}
      />
    </>
  );
}

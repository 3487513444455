// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// AUTH
import { user_name, token } from "../../../../../API/Global/Payload";

// Import ANTD Component
import {
  Table,
  Form,
  Row,
  Col,
  Tooltip,
  Modal,
  Popconfirm,
  InputNumber,
  Input,
  Typography,
  DatePicker,
  Space,
  Button,
} from "antd";

// Import React Icons Components
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import { FiCheckCircle, FiEdit, FiXCircle } from "react-icons/fi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

// Import Page Components
import ArticlePriceModal from "../../../../Modals/Purchasing/PurchaseOrder/ModalArticlePrice";
import ModalReceivingPermission from "../../../../Modals/Inventory/Receiving/ModalPermission";

// Import Notifications
import {
  failedPO,
  successPO,
} from "../../../../Reusable/Notification/Notification";

// Modal
const { confirm } = Modal;

// CODE
export default function PODetailModalTable({
  dataDetail,
  dataEdit,
  getAfterDelete,
  is_edit,
  getAuthToken,
  getAuthBy,
  params_acc,
  isEdit,
}) {
  // PROPS
  // const {
  //   dataDetail,
  //   dataEdit,
  //   getAfterDelete,
  //   is_edit,
  //   getAuthToken,
  //   getAuthBy,
  //   params_acc,
  // } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  // Selected Data & Keys
  const [select, setSelect] = useState(null);
  const [editingKey, setEditingKey] = useState("");

  const [selectedArticleId, setSelectedArticleId] = useState(0);

  const [modalArticlePriceOpen, setModalArticlePriceOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [modalPermissionOpen, setModalPermissionOpen] = useState(false);
  const [updatePriceState, setUpdatePriceState] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [authBy, setAuthBy] = useState(null);

  // Boolean
  const [edit, setEdit] = useState(false);
  // Loadings
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Tax
  const _tax = params_acc?.tax_percentage / 100;

  const isEditing = (record, index) => index === editingKey;

  // SET FIELDS EDIT
  const editData = (record, index) => {
    setEditingKey(index);

    form.setFieldsValue({
      quantity: "",
      id_supllier: "",
      id_supllier_article: "",
      ...record,
    });
  };

  const save = async (e, x) => {
    let index = e;
    let artName = x;
    try {
      const row = await form.validateFields();
      const newData = [...data];
      let find = data.findIndex((v) => v.article_name == artName);
      let compare = newData[index].unit_price !== row.unit_price;
      let compare_quantity = newData[index].quantity !== row.quantity;
      // const index = newData.findIndex((item) => key === item.key);
      if (find > -1) {
        const item = newData[e];
        newData.splice(find, 1, {
          ...item,
          ...row,
          ...{
            price_update: compare ? true : false,
            quantity_update: compare_quantity ? true : false,
            quantity_difference: row.quantity - newData[index].quantity,
          },
        });
        localStorage.clear();
        setData(newData);
        dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        isEdit(false);
        setAuthToken(null);
        setAuthBy(null);
        setUpdatePriceState(false);
      } else {
        newData.push(row);
        localStorage.clear();
        setData(newData);
        dataEdit(newData);
        setEditingKey("");
        setEdit(false);
        isEdit(false);
        setAuthToken(null);
        setAuthBy(null);
        setUpdatePriceState(false);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    localStorage.clear();
    setEditingKey("");
    setAuthToken(null);
    setAuthBy(null);
    setUpdatePriceState(false);
    setEdit(false);
    isEdit(false);
  };

  // Fetch Data
  const fetchData = async () => {
    // await
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;
    // console.log("CHECK DATA DETAIL >>>", dataDetail);
    if (dataDetail?.length > 0 && !cleanUp) {
      setData(dataDetail);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDetail]);

  useEffect(() => {
    let cleanUp = false;
    console.log("AUTH TOKEN >>>", authToken);
    if (authToken && authBy && !cleanUp) {
      setUpdatePriceState(true);
      getAuthToken(authToken);
      getAuthBy(authBy);
    }

    return () => {
      cleanUp = true;
    };
  }, [authBy]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex == "request_date" ? (
          <DatePicker
            ref={searchInput}
            format={"YYYY-MM-DD"}
            style={{
              width: "100%",
              marginBottom: 8,
              display: "block",
            }}
            onChange={(e) => {
              setSelectedKeys(e ? [moment(e).format("YYYY-MM-DD")] : []);
              console.log(e);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        )}

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      fixed: "left",
      width: 100,
      render: (_, record, index) => {
        const editable = isEditing(record, index);
        return editable ? (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <FaCheckCircle
                  className="edit-btn"
                  onClick={() => {
                    save(index, record.article_name);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Col>

              <Col>
                <FaTimesCircle
                  className="delete-btn"
                  onClick={cancel}
                  style={{ fontSize: 20, color: "#F64E60", cursor: "pointer" }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Quantity" className="delete-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      if (edit != true) {
                        console.log("ABOUT TO EDIT > ", record);
                        editData(record, index);
                        setEdit(true);
                        isEdit(true);
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      if (edit != true) {
                        console.log("ABOUT TO DELETE > ", record);
                        if ("id_po_detail" in record) {
                          showModalConfirm(record, index);
                        } else {
                          let deleteData = data.filter((value, i) => {
                            if (i != index) {
                              return value;
                            }
                          });
                          setData(deleteData);
                          getAfterDelete(deleteData);
                        }
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#F64E60",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      render: (amount) => amount,
      ...getColumnSearchProps("article_name"),
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      editable: true,
      render: (amount) => amount,
    },
    {
      title: "Ordered Quantity",
      dataIndex: "quantity",
      key: "quantity",
      // width: 100,
      editable: true,
      render: (amount) => {
        return amount > 0
          ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: "Requested Qty Left",
      dataIndex: "remaining_quantity_dummy",
      key: "remaining_quantity_dummy",
      width: 100,
      render: (amount) => {
        return amount > 0
          ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
    },
    {
      title: "Unit Price (Rp)",
      dataIndex: "unit_price",
      key: "price",
      // width: 100,
      editable: true,
      render: (amount, record) => {
        let _suppliers =
          record?.supplier?.length > 0 ? record.supplier[0] : null;
        let _ppn = _suppliers ? amount * _tax : 0;
        let _price = amount + "*";
        let _is_ppn = _suppliers?.is_ppn === true ? true : false;

        switch (_is_ppn) {
          case true:
            _price = _ppn + amount + "**";
            break;

          default:
            break;
        }

        return amount > 0
          ? `${_is_ppn ? amount + "**" : amount + "*"}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )
          : 0;
      },
    },
    {
      title: "Amount (Rp)",
      dataIndex: "amount",
      key: "amount",
      editable: true,
      render: (amount, record) => {
        let _suppliers =
          record?.supplier?.length > 0 ? record.supplier[0] : null;
        let _ppn = _suppliers ? amount * _tax : 0;
        let _price = amount + "*";
        let _is_ppn = _suppliers?.is_ppn === true ? true : false;

        switch (_is_ppn) {
          case true:
            _price = _ppn + amount + "**";
            break;

          default:
            break;
        }

        return amount > 0
          ? `${_is_ppn ? amount + "**" : amount + "*"}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )
          : 0;
      },
    },
    // {
    //   title: "Delivery Date",
    //   dataIndex: "delivery_date",
    //   key: "delivery_date",
    //   render: (e) => moment(e).format("YY-MM-DD"),
    // },
    // {
    //   title: "Credit Term Date",
    //   dataIndex: "credit_term_date",
    //   key: "credit_term_date",
    //   render: (e) => moment(e).format("YY-MM-DD"),
    // },
    {
      title: "Item Desc",
      dataIndex: "description",
      key: "description",
      editable: true,
      render: (e) => e,
    },
  ];

  // EDITABLE RENDER COMPONENTS
  const EditableCell = (props) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = props;

    // console.log(dataIndex);

    const inputNode =
      inputType === "number" && dataIndex !== "quantity" ? (
        <InputNumber
          placeholder="Input here..."
          id={dataIndex == "amount" ? "input-price-editable" : "amount-price"}
          disabled={
            dataIndex == "amount" || dataIndex == "unit_price" ? true : false
          }
          onFocus={(e) => {
            if (dataIndex == "unit_price" && updatePriceState == false) {
              setUpdatePriceState(true);
              setModalPermissionOpen(true);
            }
          }}
          onClick={(e) => {
            if (dataIndex == "unit_price" && updatePriceState == false) {
              setUpdatePriceState(true);
              setModalPermissionOpen(true);
            }
          }}
          min={0}
          max={null}
          className={dataIndex == "amount" ? "input-amount" : "edit-input"}
        />
      ) : dataIndex == "supplier_name" ? (
        <Input
          onClick={() => {
            setModalArticlePriceOpen(true);
            setSelectedArticleId(record.id_article);
          }}
        />
      ) : dataIndex === "quantity" ? (
        <InputNumber
          max={record?.remaining_quantity_dummy}
          id={"input-qty"}
          onChange={(e) => {
            // if (dataIndex == "quantity") {
            let x = form.getFieldsValue();
            form.setFieldsValue({
              amount: e * x["unit_price"],
            });
            // }
          }}
        />
      ) : (
        <Input />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <>
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
            {dataIndex == "supplier_name" ? (
              <>
                <Form.Item label="Supplier ID" name="id_supplier" hidden>
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Supplier Article ID"
                  name="id_supplier_article"
                  hidden
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Price" name="unit_price" hidden>
                  <InputNumber />
                </Form.Item>
              </>
            ) : null}
          </>
        ) : (
          children
        )}
      </td>
    );
  };

  // Merged Columns when edit
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType:
          col.dataIndex === "supplier_name" || col.dataIndex === "description"
            ? "text"
            : "number",

        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record, index),
      }),
    };
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      // getSelectedPRDetail(selectedRows);
      setEdit(true);
      dataEdit(selectedRows[0]);
      is_edit(true);
    },
    getCheckboxProps: (record) => ({
      // id_article: record.id_article,
    }),
  };

  const getSupplier = (value) => {
    let x = form.getFieldsValue();
    form.setFieldsValue({
      supplier_name: value[0].supplier_name,
      id_supplier: value[0].id_supplier,
      id_supplier_article: value[0].id_supplier_article,
      unit_price: value[0].price,
      quantity: x["quantity"],
      amount: value[0].price * x["quantity"],
    });
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value, idx) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Delete this Purchase Order Details ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
        let deleteData = data.filter((value, i) => {
          // is_loading(true);
          if (i != idx) {
            return value;
          }
        });
        setData(deleteData);
        getAfterDelete(deleteData);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record.id_po_detail;

    var obj = {
      ["id_po_detail"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/purchasing/purchase-order-detail`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Del PO Details = ", response);

        successPO(response, { method: 2 });
        fetchData();
      })
      .catch((error) => {
        console.log("Del PO Details = ", error);
        failedPO(error, { method: 2 });
        setIsLoading(false);
      });
    // .finally(() => {
    // });
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          className="pr-detail-table"
          name="pr-detail-table"
          key="pr-detail-table"
          columns={mergedColumns}
          dataSource={data}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
            onChange(current, pageSize) {
              setPage(current);
              setPaginationSize(pageSize);
            },
          }}
          scroll={{
            x: 1200,
          }}
          // rowSelection={{
          //   type: "radio",
          //   ...rowSelection,
          // }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => record.id_article}
        />

        <Typography className="text-notes" style={{ fontSize: 15 }}>
          {`* Prices are not subject to Tax`}
        </Typography>
        <Typography className="text-notes" style={{ fontSize: 15 }}>
          {`** Prices are subject to VAT (PPN ${params_acc?.tax_percentage}%)`}
        </Typography>
      </Form>

      <ArticlePriceModal
        is_open={modalArticlePriceOpen}
        is_close={setModalArticlePriceOpen}
        articleId={selectedArticleId}
        getSelectedSupplier={getSupplier}
        params_acc={params_acc}
      />

      <ModalReceivingPermission
        openModal={modalPermissionOpen}
        closeModal={(e) => {
          setModalPermissionOpen(e);
          setUpdatePriceState(false);
        }}
        is_authorized={(e, x) => {
          setAuthToken(e);
          setAuthBy(x);
        }}
      />
    </>
  );
}

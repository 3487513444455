// REACT COMPONENTS
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Space,
  Typography,
} from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// GLOBAL STYLES
import { cardHeader, mainBody } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import { useReactToPrint } from "react-to-print";
import PnLDetailReportTable from "../../../../Table/Reports/Accounting/Monthly/ProfitnLoss/Detail/Table";

// NOTIFICATIONS
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// GLOBAL API
import { token } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";
import axios from "axios";

export default function PnLDetailReportLayout() {
  // STATE
  const [options, setOptions] = useState([]);
  const [date, setDate] = useState(null);
  const [detail, setDetail] = useState(null);
  const [isPrinted, setIsPrinted] = useState(false);

  // USE REF
  const componentRef = useRef();

  // NAVIGATE
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  // FETCH DATA
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/parameter/accounting/pnl-header`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res PnL Header: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filtOptions = _res.map((item) => {
            return {
              key: item.id,
              label: item.description,
              value: item.description,
              group_id_pnl: item.id,
            };
          });

          console.log(filtOptions);
          setOptions(filtOptions);
        } else {
          setOptions([]);
        }
      })
      .catch((error) => {
        console.log("Res PnL Header Error: ", error);
        failedFetch(error);
      });
  };

  // HANDLE DATE
  const handleDate = (e) => {
    setDate(e);
  };

  // HANDLE RETURN
  const move = () => {
    navigate(
      "/report/accounting/monthly-accounting-report/profit-n-loss-report"
    );
  };

  // HANDLE SET DETAIL DATA
  const handleSetDetail = (e, i) => {
    console.log(e);
    console.log(i);
    setDetail(i);
  };

  // HANDLE PRINT
  const handlePrint = (e) => {
    console.log("Print! ", e);

    setIsPrinted(true);

    setTimeout(() => {
      handlePrintOut();
    }, 50);

    setTimeout(() => {
      setIsPrinted(false);
    }, 1000);
  };

  // HANDLE REACT PRINT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${detail?.value ? detail.value : "-"} REPORT - ${moment(
      date
    ).format("DD-MM-YYYY")}`,
    onPrintError: (error) => {
      console.log("Print Error: ", error);
    },
  });

  return (
    <>
      <Row
        // gutter={[30, 30]}
        className="row-body"
        justify="start"
        style={mainBody}
      >
        <Col span={24} className="pnl-main-layout">
          <Row
            className="row-search-component"
            justify="space-between"
            style={cardHeader}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Select PnL Header`}
                </Typography>

                <Select
                  className="type-select"
                  key="type-select"
                  allowClear
                  showSearch
                  value={detail?.value}
                  onChange={handleSetDetail}
                  options={options}
                  placeholder="PnL Header"
                  style={{
                    width: 250,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Pick a Date`}
                </Typography>

                <DatePicker
                  picker="month"
                  className="date-pick-bsr"
                  allowClear
                  placeholder="Pick a Date"
                  onChange={handleDate}
                  style={{ width: 250 }}
                />
              </Col>
            </Space>

            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="print-btn"
                  type="primary"
                  icon={
                    <PrinterOutlined
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handlePrint}
                >
                  {`Print PnL Detail`}
                </Button>
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={move}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Card
            ref={componentRef}
            className="pnl-card"
            style={{ padding: 20 }}
            bordered={false}
          >
            <PnLDetailReportTable
              is_printed={isPrinted}
              dataDate={date}
              dataDetail={detail}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Notification
import {
  masterIncomplete,
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Master
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function ArticleMainGroupTable(props) {
  // PROPS
  const { is_search, searchKey, deptKey } = props;

  // CONTEXT
  const { getArticleMaster } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [editData, setEditData] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Dept Code
  const [dept, setDept] = useState({
    department_id: 0,
    department: "",
  });
  // Modal
  const [isDelete, setIsDelete] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    await getArticleMaster({
      types: "main-group",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Main-Group => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error Main-Group => ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT DATA
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // USE EFFECT Search
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (deptKey > 0 && !cleanUp) {
  //     handleFilter(deptKey);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [deptKey]);

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip className="edit-tooltip" title="Edit Data">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);
                      setEditData(record);

                      handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip className="delete-tooltip" title="Delete Data">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 250,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    // {
    //   title: "Department",
    //   dataIndex: "department_name",
    //   key: "department_name",
    //   width: 250,
    // sortDirections: ["descend", "ascend"],
    // sorter: (a, b) => a.code.localeCompare(b.code),
    // },
    // {
    // title: "Dept ID",
    // dataIndex: "department_id",
    // key: "department_id",
    // sortDirections: ["descend", "ascend"],
    // sorter: (a, b) => a.department_id - b.department_id,
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ];

  const handleFormField = (value) => {
    const _rec = value;

    form.setFieldsValue({
      article_main_group_id: _rec.article_main_group_id,
      code: _rec.code,
      name: _rec.name,
      description: _rec.description,
      department_name: _rec?.department_name ? _rec.department_name : null,
      department_id: _rec?.department_id ? _rec.department_id : 0,
      is_show_pos: _rec?.is_show_pos,

      created_by: _rec.created_by,
      created_date: _rec.created_date,
      updated_by: _rec.updated_by,
      updated_date: _rec.updated_date,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setIsDelete(false);
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setIsDelete(true);

    setEditData(null);
    setDept({
      department: "",
      department_id: 0,
    });

    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (e) => {
    const content = e;

    confirm({
      className: "delete-guest-confirm",
      title: isDelete
        ? `Are you sure want to delete Article Main-Group ${
            content?.name.toUpperCase() || " - - - "
          } from the table?`
        : `Are you sure you wanted to ${
            edit ? "Update " : "Add a New "
          } Article Main Group named ${
            content?.name.toUpperCase() || " - - - "
          }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(content) : handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Article Main & Article Sub
  const getDepartment = (value) => {
    console.log("Find Dept: ", value);

    if (editData) {
      editData.department_name = value;
    }

    form.setFieldsValue({
      department_name: value?.name,
      department_id: value?.dept_id,
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log("FINISH: ", e);

    showModalConfirm(e);
    setIsLoading(true);
    // handleSubmit(e);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);

    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    // console.log(
    //   "HANDLE SUBMIT & CODE ==> ",
    //   JSON.stringify(contentsubmit) + " ++++ " + code
    // );

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/article/main-group`,
          {
            // article_main_group_id: contentsubmit.article_main_group_id
            //   ? contentsubmit.article_main_group_id
            //   : null,
            code: contentsubmit?.code,
            name: contentsubmit?.name,
            description: contentsubmit?.description || null,

            department_id: dept?.department_id ? dept.department_id : 0,
            department_name: dept?.department ? dept.department : null,
            is_main_group: 1,
            non_profit: 0,
            is_show_pos: contentsubmit?.is_show_pos,

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("POST Main-Group = ", response);
          masterSuccessNotification(response, {
            method: 0,
            source: "Article Main-Group",
          });

          form.resetFields();
          fetchData();
          handleCancel();
        })
        .catch((error) => {
          console.log("POST Main-Group = ", error);
          masterFailedNotification(error, {
            method: 0,
            source: "Article Main-Group",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/article/main-group`,
          {
            code: contentsubmit.code || editData?.code,
            name: contentsubmit.name || editData?.name,
            description: contentsubmit?.description || editData?.department_id,
            id: editData?.id,
            department_id: dept.department_id > 0 ? dept.department_id : 0,
            department_name: dept?.department ? dept.department : null,

            is_show_pos: contentsubmit?.is_show_pos,
            is_main_group: editData?.is_main_group,
            non_profit: editData.non_profit,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("PUT Main-Group = ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Article Main-Group",
          });

          form.resetFields();
          fetchData();
          handleCancel();
        })
        .catch((error) => {
          console.log("PUT Main-Group = ", error);

          masterFailedNotification(error, {
            method: 1,
            source: "Article Main-Group",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log(contentdelete);

    var obj = {
      ["id"]: contentdelete.id,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/article/main-group`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Delete Res => ", response);

        masterSuccessNotification(response, {
          method: 2,
          source: "Article Main-Group",
        });

        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, {
          method: 2,
          source: "Article Main-Group",
        });
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // console.log("VALUE CODE ", code);
  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);

    downloadExcel(data, "Article Main-Group List");
  };

  // Handle Filter
  const handleFilter = (value) => {
    const queryDept = value;

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("Third Conditions");
        return item.department_id === queryDept;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // NAVIGATE
  const handleReturn = () => {
    const path = "/configuration/master";

    // console.log("Pathname: ", pathname);

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryKey = searchKey ? searchKey.toLowerCase() : "";

    // console.log("Search Key = ", queryKey);
    // console.log("Search Key Department = ", queryDept);

    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        // console.log("ITEM => ", item)
        let _name = item?.name.toLowerCase();

        // console.log("Second Conditions");
        return _name.indexOf(queryKey) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
      // console.log("FILTERED => ", updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row
        className="art-main-group-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} style={{ padding: "30px" }}>
          <Row className="btn-row" justify="start" align="top" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row
                justify="end"
                align="top"
                className="btn-col"
                gutter={[0, 15]}
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Article Main-Group`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="art-main-group-table"
              key="art-main-group-table"
              name="art-main-group-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              // size="small"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.name)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-article-main-group-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Article Main-Group`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Article Main-Group`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          name="art-main-grp-modal-form"
          className="art-main-grp-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            is_show_pos: edit === true ? editData?.is_show_pos : false,
          }}
          style={{ padding: "0px 36px" }}
        >
          {/* <Row className="row-art-main-grp-dept">
            <Col span={16}>
              <Form.Item
                label="Department"
                name="department_name"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Select Article Department!",
                //   },
                // ]}
                style={{ width: "100%" }}
              >
                <MasterDepartment
                  getDepartment={getDepartment}
                  department_name={edit ? editData.department_name : null}
                />
              </Form.Item>

              <Form.Item
                label="Department"
                name="department_id"
                hidden
                // style={{ width: 440 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          /> */}

          <Row gutter={30} className="row-art-main-grp-id">
            {/* <Col span={8}>
              <Form.Item
                label="Article Main ID"
                name="article_main_group_id"
                rules={[
                  {
                    required: true,
                    message: "Please, input Article ID!",
                  },
                ]}
                style={{ width: '100%' }}
              >
                <Input placeholder="Article Main-Group ID" />
              </Form.Item>
            </Col> */}

            <Col span={8}>
              <Form.Item
                label="Article Main Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please, input Article Code!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Article Main-Group Code" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Article Main Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please, input Article Main-Group Name!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Article Main-Group Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-art-main-grp-desc">
            <Col span={16} className="desc-col">
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  className="desc-area"
                  allowClear
                  showCount
                  maxLength={200}
                  // onChange={onChangeTextArea}
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>

            <Col span={8} className="checkbox-col">
              <Form.Item
                label="Show In POS"
                name="is_show_pos"
                valuePropName="checked"
              >
                <Checkbox
                  className="pos-chckbx"
                  // type={''}
                >
                  {`Show`}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-art-main-grp">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-art-main-grp">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row justify="end" align="middle" className="group-modal-btn">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import { useState } from "react";

// ANTD COMPONENTS
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { SearchOutlined } from "@ant-design/icons";

// REACT ICONS COMPONENTS
import { FaBuildingUser } from "react-icons/fa6";

// GLOBAL STYLE
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// PAGE COMPONENTS
import VendorManagementTable from "../../../../Components/Table/BackOffice/Purchasing/VendorManagement/Table";

export default function VendorManagementPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  // SEARCH
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="ooo-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaBuildingUser
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Vendor Management`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Supplier Name"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <VendorManagementTable is_search={setSearch} searchKey={value} />
        </Col>
      </Content>
    </Layout>
  );
}

// Function to Generate Taxes, Services and Gross Price from Nett Price
// CODE
export function PriceTax(props) {
  // PROPS
  const { arr_article, tax_percent } = props;

  const _arr = arr_article;
  let _sub_total = 0;
  let _vat_total = 0;
  let _total = 0;

  let total = _arr.forEach(({ total_price, is_ppn }) => {
    let _ppn_price = total_price * tax_percent;

    _sub_total += total_price; // unit_price * quantity_order;

    switch (is_ppn) {
      case true:
        _vat_total += _ppn_price;
        _total += total_price + _ppn_price;
        break;

      case false:
        _vat_total += total_price;
        _total += total_price;
        break;

      default:
        break;
    }
  });

  return {
    sub_total: _sub_total,
    vat_total: _vat_total,
    total: _total,
  };
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Table, Tag, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// CODE
export default function ReceivingWithoutPOReportTable(props) {
  // PROPS
  const { id_main, id_warehouse, type } = props;

  //CONTEXT
  const { getByPostInventoryReport } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [datePicked, setDatePicked] = useState(null);
  const [types, setTypes] = useState(null);
  const [total, setTotal] = useState({
    quantity: 0,
    amount: 0,
  });
  // Boolean
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (id_main && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setBulks([]);
      setTotal({
        quantity: 0,
        amount: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [id_main]);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (id_warehouse && !cleanUp) {
      fetchData();
    } else {
      setData([]);
      setBulks([]);
      setTotal({
        quantity: 0,
        amount: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [id_warehouse]);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (type !== types && !cleanUp) {
      setTypes(type);
      setData([]);
      setBulks([]);
      setTotal({
        quantity: 0,
        amount: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [type]);

  // GET DATA FUNCTION
  const fetchData = async () => {
    setIsLoading(true);

    let bodyReq = {};

    if (id_warehouse) {
      bodyReq = {
        id_warehouse: id_warehouse,
      };
    } else if (id_main) {
      bodyReq = {
        article_main_id: id_main,
      };
    }

    await getByPostInventoryReport({
      type: `receiving-wopo`,
      bodyReq: bodyReq,
      onAwait: () => {
        "On Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
            };
          });

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      quantity: prev.quantity + curr.quantity_total,
                      amount: prev.amount + curr.total_price,
                    };
                  },
                  { quantity: 0, amount: 0 }
                )
              : { quantity: 0, amount: 0 };

          setData(filter);
          setBulks(filter);
          setTotal(sumTotal);
        } else {
          setData([]);
          setBulks([]);
          setTotal({
            quantity: 0,
            amount: 0,
          });
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setIsLoading(false);
      },
    });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(
      data,
      `Receiving Without PO/PR List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // HANDLE FILTER DATE
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const receivedDate = moment(val.received_date).format("YYYY-MM-DD");

      return moment(receivedDate).isBetween(_start, _end, undefined, "[]");
    });

    // update new total
    const updateTotal =
      _filtered.length > 0
        ? _filtered.reduce(
            (prev, curr) => {
              return {
                quantity: prev.quantity + curr.quantity_total,
                amount: prev.amount + curr.total_price,
              };
            },
            { quantity: 0, amount: 0 }
          )
        : { quantity: 0, amount: 0 };

    // console.log("Filtered Date Range: ", _filtered);
    setData(_filtered);
    setTotal(updateTotal);
  };

  // Params
  const currency = CurrencySymbols().code;

  // TABLE COLUMNS
  const columns = [
    {
      title: "Received Date",
      dataIndex: "received_date",
      key: "received_date",
      render: (date, record) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: `Price (${currency})`,
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return price > 0 ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Request",
      dataIndex: "quantity_request",
      key: "quantity_request",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Received",
      dataIndex: "quantity_received",
      key: "quantity_received",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Broken",
      dataIndex: "quantity_broken",
      key: "quantity_broken",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Total",
      dataIndex: "quantity_total",
      key: "quantity_total",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Total (${currency})`,
      dataIndex: "total_price",
      key: "total_price",
      render: (avg, record) => {
        return avg > 0 ? `${avg}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: "Receiving Status",
      dataIndex: "si_status",
      key: "si_status",
      sorter: (a, b) => a.id - b.id,
      render: (amount, record) => {
        let _tags = null;

        switch (record.si_status) {
          case 1:
            _tags = <Tag color="volcano">with PO & Non Direct</Tag>;
            break;

          case 2:
            _tags = <Tag color="magenta">w/o PO & Non Direct</Tag>;
            break;

          case 3:
            _tags = <Tag color="cyan">with PO & Direct</Tag>;
            break;

          case 4:
            _tags = <Tag color="purple">w/o PO & Direct</Tag>;
            break;

          case 5:
            _tags = <Tag color="green">DML & Non Direct</Tag>;
            break;

          case 6:
            _tags = <Tag color="gold">DML & Direct</Tag>;
            break;

          default:
            break;
        }

        return _tags;
      },
    },

    {
      title: "Delivery Note",
      dataIndex: "delivery_note",
      key: "delivery_note",
    },
  ];

  return (
    <>
      <Row className="receiving-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Pick Date (Received Date)`}</Typography>

                  <DatePicker.RangePicker
                    className="date-btn"
                    allowClear
                    onChange={handleDate}
                    style={{
                      width: 250,
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="inventory-table"
              key="inventory-table"
              name="inventory-table"
              bordered
              loading={isLoading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1800,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.key}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={8}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.quantity}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.amount}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

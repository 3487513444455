// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Antd Components
import {
  Card,
  Col,
  Row,
  Form,
  Typography,
  DatePicker,
  InputNumber,
  Button,
  List,
  Divider,
  Skeleton,
  Space,
  Steps,
  Collapse,
  Select,
  Input,
  Checkbox,
  Alert,
} from "antd";

// Import React Icons
import {
  PlusOutlined,
  DeleteOutlined,
  DeleteFilled,
  SwitcherFilled,
  SwitcherTwoTone,
  UpCircleOutlined,
  DownCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  LockOutlined,
  InfoCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";

// Import Page Components
import MasterCountry from "../../../Components/Reusable/Master/Country/Country";
import MasterIdCardType from "../../../Components/Reusable/Master/IdCardType/IdCardType";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const marginLayout = {
  marginTop: 30,
};
const styleColor = {
  backgroundColor: "#fff",
  borderRadius: 10,
};

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 14,
  fontWeight: 500,
};
const styleText1 = {
  color: "#b3b3b3",
  fontSize: 14,
  fontWeight: 500,
};
const styleText2 = {
  color: "#EE9D01",
  fontSize: 18,
  fontWeight: 500,
};

const cardItemStyle = {
  margin: "0px 0px 0px 0px",
  borderRadius: 12,
  padding: 10,
  paddingLeft: 30,
  width: "100%",
  backgroundColor: "#fafafa",
  boxShadow: "5px 5px 5px #d9d9d9",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  minHeight: 150,
};

const data = [
  {
    title: "Food & Beverages ",
  },
  {
    title: "Other Additional ",
  },
];
const data1 = [
  {
    title: "BREAKFAST & BBQ",
    description:
      "Good things come to those who book early. 7 days advance purchase, get 20% off",
  },
  {
    title: "ITALIAN FOOD",
    description:
      "2 pax of Lunch/Dinner bento box for first night only, Complimentary 1x Arrival OR Departure Airport Transfer (max 2 adult and 2 child below 12 years old /car), Free 1 dessert/snacks at hotel restaurant",
  },
  {
    title: "TRADITIONAL FOOD",
    description:
      "20% OFF! Big savings for big memories, Including Breakfast for 2 persons, Valid for reservations via the hotel website and direct reservations via phone/email only (not valid for walk-ins)",
  },
];

// CODE
export default function GuestInfoManager() {
  // STATE
  const { state } = useLocation();
  const navigate = useNavigate();

  console.log("State --> ", state);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(state?.data ? state.data : null);
  const [article, setArticle] = useState(state?.article ? state.article : []);
  const [guest, setGuest] = useState(state?.guest ? state.guest : null);
  // Total
  const [total, setTotal] = useState(0);
  // PRIVACY POLICY CHECKBOX STATE
  const [stateCheckBox, setStateCheckBox] = useState(false);

  // GET DATE
  const date = new Date(Date.now());
  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);
    return current <= moment(date);
  };

  // Date
  const [startDate, setStartDate] = useState(moment(date));
  const [endDate, setEndDate] = useState(moment(date));
  // Adult Value
  const [people, setPeople] = useState(0);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    setTotal(
      data.price + article.reduce((n, { total_price }) => n + total_price, 0)
    );
  }, []);

  // ON FINISH
  const onFinish = (value) => {
    const content = value;
    setGuest(content);

    console.log(content);

    move(content);
  };
  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    console.log("Failed => ", value);
  };

  // HANDLE START DATE AND END DATE
  // Get Start Date
  const handleFirstDate = (first) => {
    const start = moment(first.format()).toArray();
    setStartDate(start);
    // console.log("start date", start);

    if (endDate) {
      var ngt = moment(endDate).diff(start, "days");
      form.setFieldsValue({
        night: ngt,
        room_qty: 1,
        adult: 1,
      });
      console.log("Start = ", ngt);
    }
  };

  // Get End Date
  const handleLastDate = (last) => {
    const end = moment(last.format()).toArray();

    setEndDate(end);
    // console.log("last date", end);

    var ngt = moment(end).diff(startDate, "days");

    // console.log(ngt);

    form.setFieldsValue({
      night: ngt,
      room_qty: 1,
      adult: 1,
    });
  };
  // GET ADULT
  const getAdult = (value) => {
    // console.log(value);
    setPeople(value);

    form.setFieldsValue({
      // child: value,
      adult: value,
      // infant: value,
    });
  };
  // GET CHILD
  const getChild = (value) => {
    // console.log(value);

    form.setFieldsValue({
      child: value,
      // adult: value,
      // infant: value,
    });
  };
  // GET ADULT
  const getInfant = (value) => {
    // console.log(value);

    form.setFieldsValue({
      // child: value,
      // adult: value,
      infant: value,
    });
  };

  // GET COUNTRY
  const getCountry = (value) => {
    form.setFieldsValue({
      country: value,
    });
  };
  // GET ID CARD TYPE
  const getCardType = (value) => {
    form.setFieldsValue({
      id_card_type: value,
    });
  };

  // NAVIGATE
  // Move Proceed
  const move = (val) => {
    const value = val;
    console.log("Guest --> ", value);

    navigate("/channel-manager/reservation-payment-gateway", {
      state: { data: data, article: article, guest: value },
    });
  };
  // Move Back
  const back = (val) => {
    console.log(val);

    navigate("/channel-manager/reservation-article", {
      state: { data: data, article: article, guest: guest },
    });
  };

  console.log(stateCheckBox);

  return (
    <>
      <Card title={<Title level={4}>Reservation Channel Manager</Title>}>
        <Typography>Enter your details to continue the reservation.</Typography>
      </Card>

      <Row gutter={[30, 30]} style={marginLayout}>
        <Col
          className="col-card-reserve"
          xxl={6}
          xl={8}
          lg={{ span: 24, offset: 0 }}
          md={{ span: 24 }}
          sm={24}
          xs={24}
        >
          <Row
            className="row-main-reserve"
            style={{
              backgroundColor: "#FFFFFF",
              minHeight: 450,
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Col className="col-main-reserve" span={24} style={{ padding: 30 }}>
              <Row>
                <Typography
                  className="text-price"
                  style={{
                    fontSize: 17,
                    fontWeight: 750,
                    // color: "#52c41a",
                  }}
                >
                  Your Reservation
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#000000",
                }}
              />

              <Row>
                <Col lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }}>
                  <Card bordered={false} style={cardItemStyle}>
                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.arrival
                          ? moment(data.arrival).format("DD MMM YYYY")
                          : ""}
                      </Typography>
                      <Typography style={styleText1}>Check-In</Typography>
                    </Card.Grid>

                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.departure
                          ? moment(data.departure).format("DD MMM YYYY")
                          : ""}
                      </Typography>
                      <Typography style={styleText1}>Check-Out</Typography>
                    </Card.Grid>

                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.night ? data.night : ""}
                      </Typography>
                      <Typography style={styleText1}>Night</Typography>
                    </Card.Grid>

                    <Card.Grid
                      style={{
                        padding: 0,
                        boxShadow: "none",
                        minWidth: 150,
                        marginTop: 15,
                      }}
                    >
                      <Typography style={styleText2}>
                        {data?.person ? data.person : ""}
                      </Typography>
                      <Typography style={styleText1}>Person</Typography>
                    </Card.Grid>
                  </Card>
                </Col>
              </Row>

              <Row justify="start" style={{ marginTop: 30 }}>
                <Col span={24}>
                  <Button type="primary" className="back-btn" onClick={back}>
                    {`Back / Modifiy Data`}
                  </Button>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#000000" }}
              />

              <Row>
                <Typography
                  className="text-rate"
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                    // color: "#52c41a",
                  }}
                >
                  Selected Rooms / Rates
                </Typography>
              </Row>

              <Row style={{ marginBottom: 15 }}>
                <Col span={12}>
                  <Row>
                    <Typography
                      className="text-article-name"
                      style={{
                        // fontSize: 20,
                        fontWeight: 750,
                        color: "#40a9ff",
                      }}
                    >
                      {data.room_qty} {data.room_category_name} Room -{" "}
                      {data.adult} Adult,{" "}
                      {data.total_child > 0 ? (
                        `${data.total_child} Child, `
                      ) : (
                        <></>
                      )}
                      {data.infant > 0 ? `${data.infant} Infant,` : <></>}
                    </Typography>
                  </Row>

                  <Row>
                    <Text>Best Picks - Include Breakfast</Text>
                  </Row>
                </Col>

                <Col span={12}>
                  {/* <Row justify="end">
                    <Text delete>
                      Rp. {` ${2000000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Text>
                  </Row> */}

                  <Row justify="end">
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        color: "#52c41a",
                      }}
                    >
                      {`Rp. ${data.price}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </Typography>
                  </Row>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#000000" }}
              />

              <Row>
                <Typography
                  className="text-price"
                  style={{
                    fontSize: 18,
                    fontWeight: 750,
                    // color: "#52c41a",
                  }}
                >
                  Selected Service
                </Typography>
              </Row>

              {article.length > 0 ? null : (
                <Alert
                  className="article-alert"
                  key={"article-alert"}
                  message={`Make your stay even better by selecting the additional services!`}
                  type="info"
                  showIcon
                  style={{
                    backgroundColor: "geekblue",
                  }}
                />
              )}

              {article.map((e) => {
                return (
                  <Row style={{ marginBottom: 15 }} key={e.key}>
                    {/* <Col span={2}>
                      <DeleteFilled
                        onClick={() => {
                          let deleteArticle = article.filter((filt) => {
                            if (e.package_name != filt.package_name) {
                              return filt;
                            }
                          });
                          console.log(deleteArticle);
                          setArticle(deleteArticle);
                        }}
                        style={{ fontSize: 20, color: "#F64E60" }}
                      />
                    </Col> */}

                    <Col span={12}>
                      <Row>
                        <Typography
                          className="text-article-name"
                          style={{
                            // fontSize: 20,
                            // fontWeight: 750,
                            color: "#40a9ff",
                          }}
                        >
                          {e.package_name} - x {e.package_qty}
                        </Typography>
                      </Row>
                    </Col>

                    <Col span={12}>
                      <Row justify="end">
                        <Typography
                          className="text-price"
                          style={{
                            fontSize: 20,
                            fontWeight: 750,
                            color: "#52c41a",
                          }}
                        >
                          {`Rp. ${e.total_price}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                );
              })}

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#000000" }}
              />

              <Row style={{ marginBottom: 30 }}>
                <Col span={12}>
                  <Row justify="start">
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 17,
                        fontWeight: 750,
                        // color: "#52c41a",
                      }}
                    >
                      Total Amount
                    </Typography>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row justify="end">
                    <Typography
                      className="text-price"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        color: "#52c41a",
                      }}
                    >
                      {`Rp. ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </Typography>
                  </Row>
                </Col>
              </Row>
              {/* <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#1BC5BD",
                      borderColor: "#1BC5BD",
                      width: "100%",
                    }}
                  >
                    RESERVE
                  </Button>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Col>

        <Col
          className="col-card-guest"
          xxl={18}
          xl={16}
          lg={24}
          sm={24}
          xs={24}
        >
          <Row style={mainBody}>
            <Col span={24} style={{ padding: 30 }}>
              <Row>
                <Typography
                  className="text-row-title"
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                  }}
                >
                  Enter Your Details
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#000000",
                }}
              />

              <Row className="row-main-layout">
                <Col
                  className="col-form-guest"
                  // span={16}
                  xxl={16}
                  xl={16}
                  lg={16}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <Form
                    name="reservation-dashboard-channel-manager-form"
                    className="reservation-dashboard-channel-manager-form"
                    key="reservation-dashboard-channel-manager-form"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                      name: guest?.name ? guest.name : state?.guest?.name || "",
                      full_name: guest?.full_name
                        ? guest.full_name
                        : state?.guest?.full_name || "",
                      email: guest?.email
                        ? guest.email
                        : state?.guest?.email || "",
                      confirm_email: guest?.confirm_email
                        ? guest.confirm_email
                        : state?.guest?.confirm_email || "",
                      country: guest?.country
                        ? guest.country
                        : state?.guest?.country || "",
                      address: guest?.address
                        ? guest.address
                        : state?.guest?.address || "",
                      phone_number: guest?.phone_number
                        ? guest.phone_number
                        : state?.guest?.phone_number || null,
                      id_card_type: guest?.id_card_type
                        ? guest.id_card_type
                        : state?.guest?.id_card_type || "",
                      id_card_number: guest?.id_card_number
                        ? guest.id_card_number
                        : state?.guest?.id_card_number || "",
                      memo_room: guest?.memo_room
                        ? guest.memo_room
                        : state?.guest?.memo_room || "",
                      // "privacy-policy": true,
                    }}
                    // style={{
                    //   padding: 30,

                    //  }}
                  >
                    <Row
                      className="row-form"
                      // gutter={30}
                      justify="space-evenly"
                    >
                      <Col span={24}>
                        <Form.Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the name field!",
                            },
                          ]}
                          // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Full Name"
                          name="full_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the last name field!",
                            },
                          ]}
                          // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the email field!",
                            },
                          ]}
                          // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Confirm Email"
                          name="confirm_email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the email field!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("email") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "The two Emails that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                          // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                        >
                          <Input placeholder="Confirm Email" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="row-country" style={{ width: "100%" }}>
                      <Col span={24}>
                        <Form.Item
                          className="country"
                          label="Country"
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: "Please, input your Country!",
                            },
                          ]}
                        >
                          {/* <Input
                            className="input-country"
                            placeholder="Country"
                            style={{ width: "100%" }}
                          /> */}
                          <MasterCountry
                            getCountry={getCountry}
                            country={
                              state?.guest?.country ? state.guest.country : ""
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="row-address" style={{ width: "100%" }}>
                      <Col span={24}>
                        <Form.Item
                          className="address"
                          label="Address"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please, input your Address!",
                            },
                          ]}
                        >
                          <Input
                            className="input-address"
                            placeholder="Address"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="row-address-details" gutter={30}>
                      <Col span={8}>
                        <Form.Item
                          className="province"
                          label="Province"
                          name="province"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please, input your Address!",
                          //   },
                          // ]}
                        >
                          <Input placeholder="Province (Optional)" />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          className="city"
                          label="City"
                          name="city"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please, input your Address!",
                          //   },
                          // ]}
                        >
                          <Input placeholder="City (Optional)" />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          className="postal"
                          label="ZIP Code (Postal Code)"
                          name="zip_code"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please, input your Address!",
                          //   },
                          // ]}
                        >
                          <Input placeholder="ZIP Code / Postal Code (Optional)" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="row-phone" gutter={30}>
                      <Col span={24}>
                        <Form.Item
                          className="phone"
                          label="Phone Number"
                          name="phone_number"
                          rules={[
                            {
                              required: true,
                              message: "Please, input your phone number!",
                            },
                          ]}
                        >
                          <InputNumber
                            maxLength={15}
                            placeholder="Phone Number"
                            className="input-phone"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="row-phone" gutter={30}>
                      <Col span={12}>
                        <Form.Item
                          className="id-card-type"
                          label="ID Card Type"
                          name="id_card_type"
                          rules={[
                            {
                              required: true,
                              message: "Please, choose your ID Card Type!",
                            },
                          ]}
                        >
                          {/* <InputNumber
                            maxLength={15}
                            placeholder="ID Card Number"
                            className="input-phone"
                            style={{ width: "100%" }}
                          /> */}
                          <MasterIdCardType
                            getIdCardType={getCardType}
                            card={
                              state?.guest?.id_card_type
                                ? state.guest.id_card_type
                                : ""
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          className="id-number"
                          label="ID Card No."
                          name="id_card_number"
                          rules={[
                            {
                              required: true,
                              message: "Please, input your ID card number!",
                            },
                          ]}
                        >
                          <Input
                            // maxLength={20}
                            placeholder="ID Card Number"
                            className="input-id-card-no"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="row-memo">
                      <Col span={24}>
                        <Form.Item
                          className="memo"
                          label="Special Request"
                          name="memo_room"
                        >
                          <Input
                            // maxLength={15}
                            placeholder="Special Request"
                            className="memo-room"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Form.Item
                          //   label=""
                          name="privacy-policy"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: async (_, check) => {
                                // console.log(check);

                                if (check == false || check == null) {
                                  return Promise.reject(
                                    "Please check the privacy policy agreement to proceed!"
                                  );
                                }
                              },
                            },
                          ]}
                          // style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                        >
                          <Checkbox
                            value={stateCheckBox}
                            onChange={(e) => {
                              setStateCheckBox(e.target.checked);
                            }}
                          >
                            I have read information regarding the handling of my
                            personal data and i agree. (
                            {
                              <a href="https://www.google.com/">
                                Privacy & Policy
                              </a>
                            }
                            )
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Button
                        type="primary"
                        htmlType="submit"
                        key={"submit"}
                        style={{
                          backgroundColor: "#1BC5BD",
                          borderColor: "#1BC5BD",
                        }}
                      >
                        Continue
                      </Button>
                    </Row>
                  </Form>
                </Col>

                <Col
                  // span={8}
                  className="col-infograph"
                  xxl={8}
                  xl={8}
                  lg={8}
                  md={0}
                  sm={0}
                  xs={0}
                  style={{
                    padding: 30,
                  }}
                >
                  <Row
                    className="row-infograph"
                    // gutter={[0, 30]}
                    // justify="center"
                    align="bottom"
                  >
                    <Col span={24}>
                      <Row
                        justify="center"
                        style={{
                          padding: "0px 0px 30px",
                        }}
                      >
                        {/* <Typography
                        className="text-benefit"
                        style={{
                          textAlign: "center",
                        }}
                      > */}
                        The benefits of direct booking
                        {/* </Typography> */}
                      </Row>

                      <Row
                        className="check"
                        justify="start"
                        style={{ color: "#73d13d", margin: 0 }}
                      >
                        <Col span={8}>
                          <CheckOutlined
                            className="check-icon"
                            style={{ color: "#73d13d" }}
                          />
                        </Col>

                        <Col span={16}>Best Rate Guaranteed</Col>
                      </Row>

                      <Divider
                        className="divider-form"
                        dashed={true}
                        style={{
                          // backgroundColor: "#000000",
                          margin: "5px 0px",
                        }}
                      />

                      <Row
                        className="check"
                        justify="start"
                        style={{ color: "#73d13d", margin: 0 }}
                      >
                        <Col span={8}>
                          <CheckOutlined
                            className="check-icon"
                            style={{ color: "#73d13d" }}
                          />
                        </Col>

                        <Col span={16}>
                          Direct Reservation With Hotel, No Booking Fees
                          Exclusive
                        </Col>
                      </Row>

                      <Divider
                        className="divider-form"
                        dashed={true}
                        style={{
                          // backgroundColor: "#000000",
                          margin: "5px 0px",
                        }}
                      />

                      <Row
                        className="check"
                        justify="start"
                        style={{ color: "#73d13d", margin: 0 }}
                      >
                        <Col span={8}>
                          <CheckOutlined
                            className="check-icon"
                            style={{ color: "#73d13d" }}
                          />
                        </Col>

                        <Col span={16}>Offers & Benefits</Col>
                      </Row>

                      <Divider
                        className="divider-form"
                        dashed={true}
                        style={{
                          // backgroundColor: "#000000",
                          margin: "5px 0px",
                        }}
                      />
                    </Col>

                    <Alert
                      className="guarantee-alert"
                      message="Best Price Guaranteed"
                      description="You are booking at the best price guaranteed and at the best policy."
                      type="success"
                      showIcon
                      icon={<InfoCircleOutlined />}
                      style={{
                        margin: "250px 0px 30px",
                      }}
                    />
                    <Alert
                      className="booking-alert"
                      message="Book Now!"
                      description="10 other people are booking this hotel right now!"
                      type="error"
                      showIcon
                      icon={<IoIosPeople />}
                      // style={{
                      //   margin: "0px 0px 60px",
                      // }}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// API
import { baseurl } from "../../../../API/Config/Api";

// TOKEN
import { token } from "../../../../API/Global/Payload";

// Import Ant Design Components
import { Button, Col, Form, Modal, Row, Table, Tag, Tooltip } from "antd";

// Import React Icons
import { GoPasskeyFill } from "react-icons/go";
import { SyncOutlined } from "@ant-design/icons/lib/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import AuthPopover from "../../../Popover/Auth/AccessControl/Popover";
import ModalAccessControll from "../../../Modals/Auth/AccessControl/Modal";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import MasterDepartment from "../../../Reusable/Master/Department/Department";

// Confirm
const { confirm } = Modal;

// CODE
export default function AccessControlTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Navigate
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    await axios
      .get(`${baseurl}/auth/admin/list-users`, {
        headers: { Authorization: `Beaerer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("All Users List: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setBulks(_res);
          setData(_res);
        } else {
          setBulks([]);
          setData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
        setLoading(false);
      });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECTS SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => {
        return (
          <Row justify="center" gutter={10} className="popover-row">
            <Col span={12} className="columns-col">
              <Tooltip
                title="Manage User's Role & Permission"
                className="edit-tooltip"
              >
                <GoPasskeyFill
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);

                    handleTabs(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>

            <Col span={12} className="columns-col">
              <AuthPopover details={record} is_refresh={handleRefresh} />
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Username",
      dataIndex: "user_metadata",
      key: "user_name",
      render: (metadata) => {
        return metadata?.user_name;
      },
    },
    {
      title: "Department",
      dataIndex: "user_metadata",
      key: "department",
      render: (metadata) => {
        return metadata?.department;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Store Admin",
      dataIndex: "user_metadata",
      key: "store_admin",
      render: (metadata, record) => {
        let txt = "Un-Assigned";
        let clr = "black";

        switch (metadata?.is_warehouse_admin) {
          case true:
            txt = "Assigned";
            clr = "geekblue";
            break;

          default:
            break;
        }

        return (
          <Tag key={record?.id} color={clr}>
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "Roles",
      dataIndex: "user_metadata",
      key: "roles",
      render: (role) => {
        const _role = role?.roles;
        let _name = "";
        let _color = "";

        switch (_role) {
          case "ROLE_STAFF":
            _name = "Staff";
            _color = "#595959";
            break;

          case "ROLE_SUPERVISOR":
            _name = "Supervisor";
            _color = "#108ee9";
            break;

          case "ROLE_MANAGER":
            _name = "Dept. Head/Manager";
            _color = "#faad14";
            break;

          case "ROLE_GENERAL_MANAGER":
            _name = "General Manager";
            _color = "#2f54eb";
            break;

          case "ROLE_COST_CONTROL":
            _name = "Cost Control";
            _color = "#fa8c16";
            break;

          case "ROLE_FINANCE_LEADER":
            _name = "Head Finance";
            _color = "#fa541c";
            break;

          case "ROLE_OWNER":
            _name = "Owner";
            _color = "black";
            break;

          case "ROLE_SUPER_ADMIN":
            _name = "Superadmin";
            _color = "#52c41a";
            break;

          case "ROLE_ADMIN":
            _name = "Admin";
            _color = "#52c41a";
            break;

          default:
            _name = "Unassigned";
            _color = "black";
            break;
        }

        return (
          <Tag className="roles-tag" key={role.user_name} color={_color}>
            {_name}
          </Tag>
        );
      },
    },
  ];

  // Handle Refresh
  const handleRefresh = (value) => {
    console.log("Operations Success, please refresh!", value);

    if (value === true) {
      fetchData();
    }
  };

  // Handle Tabs
  const handleTabs = (value) => {
    console.log("About to Assign: ", value);
    const _path = "/manage-role";

    navigate(`${pathname + _path}`, { state: value });
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/user-management";

    navigate(`${path}`);
  };

  // Handle Search
  const handleSearch = () => {
    const _values = searchKey ? searchKey.toLowerCase() : null;

    if (data.length > 0) {
      let _filtered = data.filter((val) => {
        const _name = val?.user_metadata?.user_name.toLowerCase();

        return _name.indexOf(_values) !== -1;
      });

      setData(_filtered);
    } else {
      setData([]);
    }
  };

  // Filter By Department
  const getDepartment = (val) => {
    console.log("Department: ", val);

    const _values = val.dept_id;

    if (bulks.length > 0) {
      let _filtered = bulks.filter((val) => {
        const _id = val?.user_metadata?.dept_id;

        return _id === _values;
      });

      setData(_filtered);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="row-dept-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              {`Filter by Department`}
              <MasterDepartment getDepartment={getDepartment} />
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                {/* <Button
                  className="submit-btn"
                  type="primary"
                //   onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Department`}
                </Button> */}

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="users-table"
              name="users-table"
              key="users-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id ? record.id : record.department_id
              }
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, user_name, token } from "../../../API/Global/Payload";
import {
  authorized_by,
  authorized_token,
  handleAuthorization,
} from "../../../API/Global/Permission";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Button,
  Divider,
  Input,
  Modal,
  InputNumber,
  Checkbox,
  Table,
  Tooltip,
  DatePicker,
  Tag,
  TimePicker,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import { FiEdit } from "react-icons/fi";
import { BiIntersect } from "react-icons/bi";
import { FaBusinessTime } from "react-icons/fa";
import {
  DeleteFilled,
  FormOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { BsCalculatorFill } from "react-icons/bs";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Master Components
import { LocalizationDate } from "../../../Components/Reusable/Functions/Localizations/Localization";
import MasterCOA from "../../../Components/Reusable/Accounting/MainAccount/MainAccount";
import MasterCurrency from "../../../Components/Reusable/Master/Currency/Currency";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Page Components
import ModalAuthorized from "../../../Components/Modals/Permissions/ModalCancel";
import InputSkeleton from "../../../Components/Reusable/Skeleton/InputSkeleton";

// Import Notification
import {
  failedParams,
  successParams,
} from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import {
  failedFetch,
  incomplete,
} from "../../../Components/Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ShifParams() {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  // Edit
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showStatus, setShowStatus] = useState("In-Active");
  // Loading
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // Modals
  const [permitted, setPermitted] = useState(true);
  const [isDelete, setIsDelete] = useState(true);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const format = "HH:mm";

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (authorized_token && !cleanUp) {
      handleAuthorization("");
      localStorage.clear();
    }

    return () => {
      cleanUp = true;
    };
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (data && !cleanUp) {
      setFormFields(data);
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    await getParameters({
      params: "parameters",
      type: "shift",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Shift --> ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          setData(_res);
        }
        handleSuccess();
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Params Shift --> ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: 100,
      render: (_, record, index) => {
        return (
          <Row
            className="action-table-btn"
            justify="center"
            gutter={15}
            style={{ width: "100%" }}
          >
            <Col className="edit-col" span={12}>
              <Tooltip title={"Edit Data"} className="edit-tooltip">
                <Button
                  className="edit-btn"
                  shape="circle"
                  size="small"
                  //   disabled={permitted}
                  icon={
                    <FiEdit
                      className="edit-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("ABOUT TO EDIT > ", record);

                        setIsEdit(true);
                        setEditData(record);
                        handleOpen();

                        setFormFields(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#1BC5BD",
                        // cursor: "pointer",
                      }}
                    />
                  }
                  style={{
                    borderColor: "transparent",
                    background: "inherit",
                  }}
                />
              </Tooltip>
            </Col>

            <Col className="delete-col" span={12}>
              <Tooltip title={"Delete Data"} className="edit-tooltip">
                <Button
                  className="edit-btn"
                  shape="circle"
                  size="small"
                  //   disabled={permitted}
                  icon={
                    <DeleteFilled
                      className="delete-btn"
                      onClick={() => {
                        console.log("ABOUT TO DELETE > ", record);
                        console.log("IS Delete Record: ", isDelete);

                        setIsDelete(true);
                        setIsEdit(false);
                        setEditData(record);

                        handleOpenDelete(record);
                      }}
                      style={{
                        fontSize: 20,
                        color: "#F64E60",
                        // cursor: "pointer",
                      }}
                    />
                  }
                  style={{
                    borderColor: "transparent",
                    background: "inherit",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Shift",
      dataIndex: "shift_name",
      key: "shift_name",
    },
    {
      title: "Code",
      dataIndex: "shift_id",
      key: "shift_id",
    },
    {
      title: "Start Hour",
      dataIndex: "start_at",
      key: "start_at",
      render: (start) => {
        return moment(start).format("HH:mm");
      },
    },
    {
      title: "End Hour",
      dataIndex: "end_at",
      key: "end_at",
      render: (end) => {
        return moment(end).format("HH:mm");
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (status) => {
        let txt = "";
        let clr = "";

        switch (status) {
          case true:
            txt = "Active";
            clr = "green";
            break;

          default:
            txt = "In-Active";
            clr = "volcano";
            break;
        }

        return (
          <Tag
            className="show-tag"
            color={clr}
            key={"tag-key"}
            style={{
              fontSize: 14,
              fontWeight: 500,
              // margin: "0px 0px 0px 15px",
            }}
          >
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    navigate("/configuration/parameters");
  };

  const handleClose = () => {
    setShow(false);
    setIsDelete(true);
    setIsEdit(false);

    setEditData(null);

    form.resetFields();
  };

  // SET FORMS
  const setFormFields = (value) => {
    const _data = value;

    console.log("SET FORMS: ", _data);

    form.setFieldsValue({
      shift_id: _data?.shift_id || editData?.shift_id,
      shift_name: _data?.shift_name || editData?.shift_name,
      start_at: _data?.start_at
        ? moment(LocalizationDate(_data.start_at)).subtract(7, "hours")
        : null,
      end_at: _data?.end_at
        ? moment(LocalizationDate(_data.end_at)).subtract(7, "hours")
        : null,
      description: _data?.description || editData?.description,
      is_active: _data?.is_active,

      updated_by: _data?.updated_by,
      updated_date: _data?.updated_date,
      created_by: _data?.created_by,
      created_date: _data?.created_date,
      //   updated_date: moment(LocalizationDate(_data.updated_date)).format(
      //     "HH:mm:ss, DD-MM-YYYY"
      //   ),
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;

    console.log("ON FINISH >>> ", content);
    setIsLoading(true);
    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;
    console.log("Incomplete: ", error.values);

    incomplete(error);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const submitContent = value;

    console.log(submitContent);

    confirm({
      className: "modal-confirm",
      title: isDelete
        ? `Are you sure want to delete a Shift named ${
            submitContent?.shift_name || "- - - "
          }?`
        : `Are you sure want to ${
            isEdit ? "Update " : "Add new "
          } Shift named ${submitContent?.shift_name || "- - - "}?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        if (isEdit === true && isDelete === false) {
          handleUpdate(submitContent);
        } else if (isEdit === false && isDelete === false) {
          handleCreate(submitContent);
        } else {
          handleDelete(submitContent);
        }
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
      },
    });
  };

  // Change Time
  const onChangeStart = (value) => {
    console.log("Value --> ", value);

    form.setFieldsValue({
      start_at: value,
    });
  };

  // Change Time
  const onChangeEnd = (value) => {
    console.log("Value --> ", value);

    form.setFieldsValue({
      end_at: value,
    });
  };

  // Handle Showing
  const handleShow = (e) => {
    const value = e.target.checked;
    // console.log("Key: ", e.target.checked);

    switch (value) {
      case true:
        setShowStatus("Active");
        break;

      default:
        setShowStatus("In-Active");
        break;
    }
    // setShowStatus(e);
  };

  // HANDLE EDIT
  const handleOpen = () => {
    setIsDelete(false);

    if (permitted) {
      console.log("IS Permission Granted? ", permitted);
      setOpen(true);
    } else {
      setShow(true);
    }
  };

  // Handle Open Delete
  const handleOpenDelete = (value) => {
    const _data = value;
    console.log("IS Permission Granted? ", permitted);
    console.log("IS Delete: ", isDelete);

    if (permitted) {
      setOpen(true);
    } else {
      showModalConfirm(_data);
    }
  };

  // HANDLE PERMISSION
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);
    console.log("IS DELETE? ", isDelete);

    if (_permit) {
      if (isDelete === true) {
        showModalConfirm(editData);
        setOpen(false);
      } else {
        setShow(true);
        setPermitted(false);
        setOpen(false);
      }
    }
  };

  // HANDLE UPDATE
  const handleUpdate = async (value) => {
    const content = value;

    // setLoading(true);

    console.log(content);

    await axios
      .put(
        `${baseurl}/parameters/shift`,
        {
          id: editData?.id,

          shift_id: content?.shift_id || editData?.shift_id,
          shift_name: content?.shift_name || editData?.shift_name,
          start_at: content.start_at || editData?.start_at,
          end_at: content.end_at || editData?.end_at,
          description: content?.description || editData?.description,
          is_active: content?.is_active,

          updated_by: authorized_by,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Acc --> ", response);

        successParams(response, { method: 7 });

        handleSuccess();
        fetchData();
      })
      .catch((error) => {
        console.log("Error --> ", error);
        failedParams(error, { method: 7 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CREATE
  const handleCreate = async (value) => {
    const content = value;

    // setLoading(true);
    // console.log(content);

    await axios
      .post(
        `${baseurl}/parameters/shift`,
        {
          shift_id: content?.shift_id || null,
          shift_name: content?.shift_name || null,
          start_at: content.start_at,
          end_at: content.end_at,
          description: content?.description || null,
          is_active: content?.is_active,

          created_by: authorized_by,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Acc --> ", response);

        successParams(response, { method: 7 });

        handleSuccess();
        fetchData();
      })
      .catch((error) => {
        console.log("Error --> ", error);
        failedParams(error, { method: 7 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE Delete
  const handleDelete = async (value) => {
    const content = value;

    const _data = {
      ["id"]: content?.id,
      ["deleted_by"]: authorized_by,
    };

    await axios
      .delete(
        `${baseurl}/parameters/shift`,
        {
          data: _data,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Acc --> ", response);

        successParams(response, { method: 7 });

        handleSuccess();
        fetchData();
      })
      .catch((error) => {
        console.log("Error --> ", error);
        failedParams(error, { method: 7 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CANCEL
  const handleSuccess = () => {
    setShow(false);
    setOpen(false);

    localStorage.clear();
    handleAuthorization("");
  };

  // CONSOLE
  // console.log(data);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaBusinessTime
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Working Hours (Shift)`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col span={24} className="col-first" style={{ padding: 30 }}>
                <Row className="main-table-row" justify="end" align="middle">
                  <Button
                    className="submit-btn"
                    onClick={handleOpen}
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{
                      margin: "0px 15px 0px 0px",
                    }}
                  >{`Add New Shift`}</Button>

                  <Button
                    className="cancel-btn"
                    onClick={fetchData}
                    type="default"
                    icon={<SyncOutlined />}
                    style={{
                      margin: "0px 15px 0px 0px",
                    }}
                  >{`Refresh`}</Button>

                  <Button
                    className="cancel-btn"
                    onClick={handleCancel}
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                  >{`Return`}</Button>
                </Row>

                <Row
                  className="main-table-row"
                  style={{ margin: "30px 0px 0px" }}
                >
                  <Table
                    className="shift-table"
                    key="shift-table"
                    name="shift-table"
                    bordered
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    pagination={{
                      defaultPageSize: 5,
                      pageSizeOptions: [10, 20, 50, 100],
                      showSizeChanger: true,
                      showTotal: (total, range) =>
                        `Showing ${range[0]}-${range[1]} of ${total} entries`,
                    }}
                    rowKey={(record) => (record.id ? record.id : record.key)}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "odd" : "even";
                    }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>

          <ModalAuthorized
            openModal={open}
            closeModal={setOpen}
            params={1}
            is_permitted={handlePermit}
          />

          <Modal
            className="form-modal"
            title={
              <>
                <Row className="title-row">
                  <BiIntersect style={{ color: "#3699FF", fontSize: 24 }} />
                  <Typography
                    className="modal-title-text"
                    style={{ margin: "0px 0px 0px 15px" }}
                  >
                    {isEdit ? `Edit Data` : `Add New Data`}
                  </Typography>
                </Row>
              </>
            }
            centered
            open={show}
            onCancel={handleClose}
            width={800}
            footer={null}
            closable
          >
            <Form
              className="params-form"
              key="params-form"
              name="params-form"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              style={{
                padding: "0px 30px",
              }}
            >
              <Row className="main-form-row" justify="start">
                <Col className="main-form-col" span={24}>
                  <Row className="shift-id-row" justify="start" gutter={30}>
                    <Col className="shiftf-name-col" span={8}>
                      <Form.Item
                        name="shift_name"
                        label="Shift Name"
                        rules={[
                          {
                            required: true,
                            message: "Please, Input the Data!",
                          },
                        ]}
                      >
                        <Input placeholder="Work Hour's Name" />
                      </Form.Item>
                    </Col>

                    <Col className="shiftf-name-col" span={8}>
                      <Form.Item
                        name="shift_id"
                        label="Shift Code"
                        rules={[
                          {
                            required: true,
                            message: "Please, Input the Data!",
                          },
                        ]}
                      >
                        <Input placeholder="Work Hour's Code" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="time-row" justify="start" gutter={30}>
                    <Col className="shiftf-name-col" span={8}>
                      <Form.Item
                        name="start_at"
                        label="Start Hour"
                        rules={[
                          {
                            required: true,
                            message: "Please, Select an Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          name="start_hour"
                          allowClear
                          placeholder="Start Hour"
                          format={format}
                          value={
                            isEdit
                              ? moment(
                                  LocalizationDate(editData?.start_at),
                                  format
                                ).subtract(7, "hours")
                              : null
                          }
                          onChange={onChangeStart}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="shiftf-name-col" span={8}>
                      <Form.Item
                        name="end_at"
                        label="End Hour"
                        rules={[
                          {
                            required: true,
                            message: "Please, Select an Hour!",
                          },
                        ]}
                      >
                        <TimePicker
                          name="end_hour"
                          allowClear
                          placeholder="End Hour"
                          value={
                            isEdit
                              ? moment(editData?.end_at, format).subtract(
                                  7,
                                  "hours"
                                )
                              : null
                          }
                          onChange={onChangeEnd}
                          format={format}
                        />
                      </Form.Item>
                    </Col>

                    <Col className="source-col" span={8}>
                      <Form.Item
                        label="Active Status"
                        name="is_active"
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            message: "Please, Choose an Options!",
                          },
                        ]}
                      >
                        <Checkbox className="show-check" onChange={handleShow}>
                          {showStatus}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="description-row" justify="start" gutter={30}>
                    <Col className="description-col" span={16}>
                      <Form.Item name="description" label="Description">
                        <Input.TextArea
                          placeholder="Description"
                          allowClear
                          showCount
                          rows={3}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {isEdit ? (
                  <>
                    <Row className="creation-row" gutter={30}>
                      <Col className="createsby-col" span={8}>
                        <Form.Item label="Created By" name="created_by">
                          <Input
                            placeholder="Created By"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col className="createdat-col" span={8}>
                        <Form.Item label="Created Date" name="created_date">
                          <Input
                            placeholder="Created Date"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col className="source-col" span={8}>
                        <Form.Item label="Updated By" name="updated_by">
                          <Input
                            placeholder="Updated By"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col className="source-col" span={8}>
                        <Form.Item label="Updated Date" name="updated_date">
                          <Input
                            placeholder="Updated Date"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: 0, background: "#EBEDF3" }}
              />

              <Row
                justify="end"
                align="middle"
                style={{ padding: "10px 0px 0px" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  key={"submit"}
                  loading={isLoading}
                  className="submit-btn"
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Form>
          </Modal>
        </Content>
      </Layout>
    </>
  );
}

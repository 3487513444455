// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// Import Ant Design Components
import {
  Divider,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  Typography,
  Layout,
  Form,
  InputNumber,
  Result,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  FileSearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Page Components
import BillReceipt from "../../../Components/Forms/Bill/BillReceipt";

// IMPORT GLOBAL
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Notifications
// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function AuditSuccessPage() {
  // USE LOCATION & NAVIGATE
  const { state, pathname } = useLocation();

  const navigate = useNavigate();

  // Data
  const data = state;
  const today = moment();

  // STATE MANAGEMENT
  // Data
  // IS SUCCESS AUDIT
  const [isExist, setIsExist] = useState(true);
  // Re-Night Audit
  const [isReAudit, setIsReAudit] = useState(true);
  const [differentDays, setDifferentDays] = useState(0);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (data?.length > 0 && !cleanUp) {
      console.log("Data Night Audit dates: ", data);

      setIsExist(true);

      handleReAudit(data[0]);
    } else {
      setIsExist(false);
      setIsReAudit(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [data]);

  // HANDLE RE AUDIT
  const handleReAudit = (val) => {
    const isRedit = moment(val).isBefore(today);
    var _audit = moment(val);
    const diff = today.diff(_audit, "days");

    console.log("Today dates: ", today);
    console.log("IS RE AUDIT: ", isRedit);
    console.log("Differents Days: ", diff);

    setDifferentDays(diff);

    if (isRedit) {
      setIsReAudit(true);
    } else {
      setIsReAudit(false);
    }
  };

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate("/front-office/night-audit");
  };

  //   console.log("Data State: ", state);
  //   console.log("Data =>", data);
  //   console.log("dataLeads >>>", leaders);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="expected-arrival-main-layout">
            <Row
              className="row-search-component"
              justify="start"
              gutter={[30, 30]}
              // style={mainBody}
            >
              <Col
                className="bill-col"
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Row className="row-test" style={mainBody}>
                  <Result
                    status={isExist ? "success" : "500"}
                    title={
                      isExist
                        ? "Night Audit is Running Successfully!"
                        : "ERROR!"
                    }
                    subTitle={
                      isExist
                        ? `Audit Date: ${data}`
                        : `Sorry, something went wrong!`
                    }
                    extra={[
                      <Row
                        className="extra-row"
                        key={"extra-row"}
                        justify="center"
                        gutter={30}
                      >
                        {/* <Col className="print-col" span={24}>
                          <Space
                            className="success-space"
                            wrap={true}
                            size="middle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "50px 0px",
                            }}
                          >
                            <Typography.Text
                              strong
                              style={{
                                fontSize: 16,
                              }}
                            >
                              
                            </Typography.Text>
                          </Space>
                        </Col> */}

                        <Col className="return-col" span={24}>
                          <Space className="btn-space" size="large">
                            <Tooltip
                              className="return-tooltip"
                              title="Return to Night Audit Page"
                              color="geekblue"
                              placement="right"
                            >
                              <Button
                                className="cancel-btn"
                                key="return-btn"
                                onClick={handleReturn}
                              >
                                {`Return`}
                              </Button>
                            </Tooltip>
                          </Space>
                        </Col>
                      </Row>,
                    ]}
                    style={{
                      width: "100%",
                      padding: "100px 50px 15px",
                    }}
                  >
                    {isExist ? (
                      <>
                        <Paragraph>
                          <Text
                            strong
                            style={{
                              fontSize: 16,
                            }}
                          >
                            The content you submitted has some Notes:
                          </Text>
                        </Paragraph>

                        {differentDays > 0 ? (
                          <>
                            <Paragraph>
                              <CloseCircleOutlined
                                style={{
                                  color: "red",
                                  marginRight: 7,
                                }}
                              />
                              {`Night Audit is Outstanding about ${differentDays} day(s). Please, performs Night Audit until the System completes the Audit on all the Remaining Dates!`}
                            </Paragraph>

                            <Paragraph>
                              <CloseCircleOutlined
                                style={{
                                  color: "red",
                                  marginRight: 7,
                                }}
                              />
                              {`Return to Night Audit Page to Performs Night Audit again!`}
                            </Paragraph>

                            <Paragraph>
                              <CloseCircleOutlined
                                style={{
                                  color: "red",
                                  marginRight: 7,
                                }}
                              />
                              {`Please, Inform your Superiors to Confirm the Problems!`}
                            </Paragraph>
                          </>
                        ) : (
                          <>
                            <Paragraph>
                              <CheckCircleOutlined
                                style={{
                                  color: "green",
                                  marginRight: 7,
                                }}
                              />
                              {`Night Audit is not had any Outstanding Dates. You may Proceed!`}
                            </Paragraph>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Space
                          className="result-space"
                          direction="vertical"
                          size={5}
                        >
                          <Paragraph>
                            <Text
                              strong
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {`The content you submitted may has the following error:`}
                            </Text>
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You are trying to reach Success page without doing any Process!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You're request is not valid!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`Please, check and modify the following information before resubmitting!`}
                          </Paragraph>
                        </Space>
                      </>
                    )}
                  </Result>
                </Row>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// React Components
import React from "react";

// Import Antd Components
import { Button, Col, Empty, Typography } from "antd";

// CODE
export default function EmptyListComponents(props) {
  // Props
  const { is_refresh, is_image } = props;

  // Handle Refresh
  const handleRefresh = () => {
    is_refresh(true);
  };

  return (
    // CODE
    <>
      <Empty
        className="table-empty"
        description={`Empty`}
        image={false}
        style={{
          borderRadius: 10,
        }}
      >
        <Button
          className="refresh-btn"
          type="default"
          onClick={handleRefresh}
          key="refresh-btn-empty"
        >{`Refresh`}</Button>
      </Empty>
    </>
  );
}

// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { TbFileDollar } from "react-icons/tb";
import {
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  UndoOutlined,
} from "@ant-design/icons";

// Import Page Components
import GeneralCompanyLedgersTable from "../../../../Components/Table/Accounting/Ledgers/Company/Table";

// Import Global Styles
import { cardHeader, cardBody } from "../../../../Style/Global/Global";

// CODE
export default function CompanyLedgersPage() {
  // STATE MANAGEMENT
  // Data
  const [value, setValue] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();

    setValue(e.target.value);
  };

  // console.log(room);

  return (
    <Layout>
      <Content>
        <Col span={24} className="ooo-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <TbFileDollar
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`General Ledgers`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Company's Name"
                  onChange={handleSearch}
                  style={{ minWidth: 150 }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                  style={{ minWidth: 91 }}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <GeneralCompanyLedgersTable is_search={setSearch} queryKey={value} />
        </Col>
      </Content>
    </Layout>
  );
}

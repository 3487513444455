// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import {
  user_name,
  token,
  user_level,
  department,
  department_id,
} from "../../../API/Global/Payload";
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Tooltip,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import { FaArchive, FaFileAlt, FaTrash } from "react-icons/fa";
import { MdOutlineBedroomChild, MdOutlineMoreTime } from "react-icons/md";
import { TfiMenuAlt } from "react-icons/tfi";
import { BsPersonVcardFill } from "react-icons/bs";

// Notification
import {
  failedAddReserv,
  successAddReserv,
} from "../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
  masterIncomplete,
} from "../../Reusable/Notification/MasterNotif/Notification";

// Import Page Components
import RecipeForm from "../../Forms/StandardRecipe/Recipe";
import StandardRecipeModal from "../../../Components/Modals/Inventory/StandardRecipe/Modal";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function StandardRecipePopover(props) {
  // PROPS
  const { dataHeader, refresh, getDelete } = props;

  // STATE MANAGEMENT
  // Popover
  const [open, setOpen] = useState(false);
  // Modals
  const [openEdit, setOpenEdit] = useState(false);
  const [dataEditDetail, setDataEditDetail] = useState([]);
  const [article, setArticle] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [room, setRoom] = useState(false);
  const [master, setMaster] = useState(false);
  const [late, setLate] = useState(false);
  // Data
  const [recordData, setRecordData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);

  // Set Data
  // console.log("IN POP OVER = ", dataHeader);

  // HANDLE CLOSE
  const handleClosePopover = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setModalOpen(false);
    setEdit(false);
  };

  const handleRefresh = () => {
    refresh(true);
  };

  // SHOW MODAL DELETE
  const showArchiveModal = (value) => {
    const arc = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Archive a Standard Recipe for ${
        arc?.recipe_name.toUpperCase() || " ----- "
      }?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleArchive(arc);
        // console.log("Handle Delete >> ", del);
      },

      onCancel() {
        // setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleArchive = async (record) => {
    const contentArchive = record;

    console.log(contentArchive);

    var obj = {
      id: contentArchive?.id,
      archived_by: user_name,
    };

    return await axios
      .delete(`${baseurl}/kitchen/standard-recipe-archived`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("ARC Standard Recipe => ", response);

        masterSuccessNotification(response, {
          method: 1,
          source: `Standard Recipe Archive`,
        });
        handleRefresh();
      })
      .catch((error) => {
        masterFailedNotification(error, {
          method: 1,
          source: `Standard Recipe Archive`,
        });
        console.log("DEL Standard Recipe => ", error);
      });
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (value) => {
    const del = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Delete a Standard Recipe for ${
        del?.recipe_name.toUpperCase() || " ----- "
      }?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
        // console.log("Handle Delete >> ", del);
      },

      onCancel() {
        // setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log(contentdelete);

    var obj = {
      id: contentdelete?.id,
      deleted_by: user_name,
    };

    return await axios
      .delete(`${baseurl}/kitchen/standard-recipe`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Standard Recipe => ", response);

        masterSuccessNotification(response, {
          method: 2,
          source: `Standard Recipe`,
        });
        handleRefresh();
      })
      .catch((error) => {
        masterFailedNotification(error, {
          method: 2,
          source: `Standard Recipe`,
        });
        console.log("DEL Standard Recipe => ", error);
      });
  };

  return (
    <>
      <Tooltip title="Option Menu" className="guest-inhouse-tooltip">
        <Popover
          className="pop-over-guest-inhouse"
          name="pop-over-guest-inhouse"
          key="pop-over-guest-inhouse"
          placement="right"
          title={
            <Row>
              {/* <Col span={4}>
                <UnorderedListOutlined
                  className="popover-btn"
                  style={{
                    padding: "8px 8px 8px 0px",
                    fontSize: 14,
                    color: "#2f54eb",
                  }}
                />
              </Col> */}

              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#000000",
                    // color: "#2f54eb",
                    fontWeight: 600,
                  }}
                >
                  {`Options`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            //   detail.group == 2 ? (
            <>
              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaFileAlt
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClosePopover();
                    setView(true);
                    setRecordData(dataHeader);
                  }}
                >
                  {`View Recipe`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FiEdit
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClosePopover();
                    setEdit(true);
                    setModalOpen(true);
                    setDataEdit(dataHeader);
                  }}
                >
                  {`Edit Recipe`}
                </Button>
              </Row>

              <Row
                justify="start"
                // hidden={detail?.payment_status == 2 ? false : true}
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaArchive
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClosePopover();
                    showArchiveModal(dataHeader);
                  }}
                >
                  {`Archive Recipe`}
                </Button>
              </Row>

              <Row
                justify="start"
                // hidden={detail?.payment_status == 2 ? false : true}
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  icon={
                    <FaTrash
                      style={{
                        color: "#f64e5f",
                        fontSize: 20,
                        margin: "0px 22px 0px 15px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    handleClosePopover();
                    showDeleteModal(dataHeader);
                  }}
                >
                  {`Delete Recipe`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClosePopover}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <TfiMenuAlt
            className="menu-btn"
            // onClick={(e) => {
            //   console.log("ABOUT TO MENU > ", e);
            // // showDeleteModal(record);
            // }}
            style={{
              fontSize: 25,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              marginLeft: 10,
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <RecipeForm
        openModal={view}
        closeModal={setView}
        dataView={view == true ? recordData : ""}
      />

      <StandardRecipeModal
        is_open={modalOpen}
        is_close={handleClose}
        is_refresh={handleRefresh}
        is_edit={edit}
        selectedData={edit ? dataEdit : null}
      />
    </>
  );
}

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdDoorSliding, MdOutlineTableBar } from "react-icons/md";
import { BsTable } from "react-icons/bs";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import MasterOutletLocation from "../../../Components/Reusable/Outlet/Master/OutletLocation/OutletLocation";
import MasterTableCategory from "../../../Components/Reusable/Outlet/Master/Category/TableCategory";
import TableListTable from "../../../Components/Table/Master/Table/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function TablePage() {
  // STATE MANAGEMENT
  // Location of Outlet
  const [outletLocation, setOutletLocation] = useState({
    locations: "",
    locations_code: "",
    locations_id: 0,
  });
  const [tableCat, setTableCat] = useState({
    categories: "",
    categories_code: "",
    categories_id: 0,
  });
  // Search Value
  const [value, setValue] = useState(null);
  // Set Search
  const [search, setSearch] = useState(null);

  // Handle Search
  const handleSearch = (e) => {
    const _val = e.target.value;

    setValue(_val);
  };

  // GET LOCATION
  const getOutletLoc = (value) => {
    const _loc = value;

    // console.log("Outlet Location: ", _loc);

    setOutletLocation({
      locations: _loc?.value,
      locations_id: _loc?.id,
      locations_code: _loc?.code,
    });

    if (tableCat.categories_id > 0) {
      setTableCat({
        categories: "",
        categories_code: "",
        categories_id: 0,
      });
    }
  };

  // Get Category
  const getTableCategory = (value) => {
    const _tab = value;

    // console.log("Outlet Location: ", _tab);

    setTableCat({
      categories: _tab?.value,
      categories_id: _tab?.id,
      categories_code: _tab?.code,
    });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="table-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <MdOutlineTableBar
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`List Data Table`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 150,
                }}
              >
                <Typography
                // style={{ marginBottom: 10 }}
                >{`Outlet Location`}</Typography>

                <MasterOutletLocation getOutletLocation={getOutletLoc} />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  minWidth: 150,
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                // style={{ marginBottom: 10 }}
                >{`Table Category`}</Typography>

                <MasterTableCategory
                  getTableCat={getTableCategory}
                  outlet_loc={outletLocation}
                  cat_value={null}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                // style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  placeholder="Keywords"
                  onChange={handleSearch}
                  allowClear
                  style={{
                    minWidth: 150,
                    maxWidth: 300,
                  }}
                />
              </Col>

              <Col
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="next-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <TableListTable
            is_search={setSearch}
            searchKey={value}
            outlet_location={outletLocation}
            table_cat={tableCat}
          />
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// Import Page Components
import BOReportPage from "../../../Pages/Report/BackOffice/Main/MainPage";

import NotFound from "../../../Pages/NotFound/NotFound";
import PurchasingReportPage from "../../../Pages/Report/BackOffice/Purchasing/Purchasing";
import PurchasingListReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchasingList/PurchasingList";
import PRReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseRequest/PurchaseRequest";
import PRCancelledReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseRequestCancelled/PurchaseRequestCancelled";
import PRExpiredReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseRequestExpired/PurchaseRequestExpired";
import POReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseOrder/PurchaseOrder";
import POCancelledReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseOrderCancelled/PurchaseOrderCancelled";
import POExpiredReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseOrderExpired/PurchaseOrderExpired";
import POOutstandingReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseOrderOutStanding/PurchaseOrderOutstanding";
import PurchaseBookReportPage from "../../../Pages/Report/BackOffice/Purchasing/PurchaseBook/PurchaseBook";
import InventoryReportPage from "../../../Pages/Report/BackOffice/Inventory/Inventory";
import MinimumOnHandPage from "../../../Pages/Report/BackOffice/Inventory/MinimumOnHand/MinimumOnHand";
import SlowMovingPage from "../../../Pages/Report/BackOffice/Inventory/SlowMoving/SlowMoving";
import IncomingStockReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/IncomingStock";
import ReceivingReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/Receiving/Receiving";
import StockArticleIncomingReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/StockArticleIncoming/StockArticleIncoming";
import JournalIncomingStockReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/JournalIncomingStock/JournalIncomingStock";
import StockArticleReturnReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/StockArticleReturn/StockArticleReturn";
import CancelledReceivingReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/CancelledReceiving/CancelledReceiving";
import ReceivingSummaryReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/ReceivingSummary/ReceivingSummary";
import PriceDiscrepancyReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/PriceDiscrepancy/PriceDiscrepancy";
import AnnualReceivingReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/AnnualReceiving/AnnualReceiving";
import ReceivingWithoutPOReportPage from "../../../Pages/Report/BackOffice/Inventory/IncomingStock/ReceivingWithoutPO/ReceivingWithoutPO";
import OutgoingStockReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/OutgoingStock";
import StandardRecipeArchivePage from "../../../Pages/Report/BackOffice/Inventory/StandardRecipeArchive/StandardRecipeArchive";
import TransferSummaryReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/TransferSummary/TransferSummary";
import TransferValidationReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/TransferValidation/TransferValidation";
import SingleArticleMovingReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/SingleArticleMoving/SingleArticleMoving";
import StockArticleMovingReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/StockArticleMoving/StockArticleMoving";
import StockOutgoingReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/StockOutgoing/StockOutgoing";
import StockRequisitionReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/StockRequisition/StockRequisition";
import RequisitionSummaryReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/RequisitionSummary/RequisitionSummary";
import AnnualOutgoingReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/AnnualOutgoing/AnnualOutgoing";
import StockItemTransformReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/StockItemTransform/StockItemTransform";
import InterKitchenTransferReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/InterKitchenTransfer/InterKitchenTransfer";
import ExpiredOutgoingReportPage from "../../../Pages/Report/BackOffice/Inventory/OutgoingStock/ExpiredOutgoing/ExpiredOutgoing";

// CODE
export default function BOReportRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<BOReportPage />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/purchasing-report" element={<PurchasingReportPage />} />
        <Route
          path="/purchasing-report/purchasing-list"
          element={<PurchasingListReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-request"
          element={<PRReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-request-cancelled"
          element={<PRCancelledReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-request-expired"
          element={<PRExpiredReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-order"
          element={<POReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-order-cancelled"
          element={<POCancelledReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-order-expired"
          element={<POExpiredReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-order-outstanding"
          element={<POOutstandingReportPage />}
        />
        <Route
          path="/purchasing-report/purchase-book"
          element={<PurchaseBookReportPage />}
        />

        <Route path="/inventory-report" element={<InventoryReportPage />} />
        <Route
          path="/inventory-report/minimum-on-hand"
          element={<MinimumOnHandPage />}
        />
        <Route
          path="/inventory-report/slow-moving"
          element={<SlowMovingPage />}
        />

        <Route
          path="/inventory-report/incoming-stock"
          element={<IncomingStockReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/stock-article-incoming"
          element={<StockArticleIncomingReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/receiving"
          element={<ReceivingReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/receiving-without-po"
          element={<ReceivingWithoutPOReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/journal-incoming-stock"
          element={<JournalIncomingStockReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/stock-article-return"
          element={<StockArticleReturnReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/cancelled-receiving"
          element={<CancelledReceivingReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/receiving-summary"
          element={<ReceivingSummaryReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/price-discrepancy"
          element={<PriceDiscrepancyReportPage />}
        />
        <Route
          path="/inventory-report/incoming-stock/annual-receiving"
          element={<AnnualReceivingReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock"
          element={<OutgoingStockReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/transfer-summary"
          element={<TransferSummaryReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/transfer-validation"
          element={<TransferValidationReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/single-article-moving"
          element={<SingleArticleMovingReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/stock-article-moving"
          element={<StockArticleMovingReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/stock-outgoing"
          element={<StockOutgoingReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/expired-outgoing"
          element={<ExpiredOutgoingReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/stock-requisition"
          element={<StockRequisitionReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/requisition-summary"
          element={<RequisitionSummaryReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/annual-outgoing"
          element={<AnnualOutgoingReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/stock-item-transform"
          element={<StockItemTransformReportPage />}
        />

        <Route
          path="/inventory-report/outgoing-stock/inter-kitchen-transfer"
          element={<InterKitchenTransferReportPage />}
        />

        <Route
          path="/inventory-report/standard-recipe-archive"
          element={<StandardRecipeArchivePage />}
        />

        <Route
          path="/f-and-b-report"
          // element={< />}
        />
      </Routes>
      <Outlet />
    </>
  );
}

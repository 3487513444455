// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import { Button, Col, Input, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdScale } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";

// Import Page Components

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";
import UOMTable from "../../../Components/Table/Master/UOM/Table";

// CODE
export default function UOMPage() {
  // STATE MANAGEMENT
  // Value Search
  const [value, setValue] = useState(null);
  // Search
  const [search, setSearch] = useState(null);

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();

    let _data = e.target.value;

    setValue(_data);

    console.log("HANDLE SEARCH = ", _data);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <MdScale
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Unit of Measurement`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 150,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Keyword`}</Typography>

                  <Input
                    className="search-input"
                    placeholder="Unit's Name"
                    onChange={handleSearch}
                    allowClear
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <UOMTable is_search={setSearch} searchKey={value} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/InventoryContext/InventoryContext";

// AUTH
import {
  user_name,
  token,
} from "../../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Table,
  Col,
  Row,
  Modal,
  Form,
  Tooltip,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
} from "@ant-design/icons/lib/icons";

// Notification
import { masterSuccessNotification, masterFailedNotification } from "../../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";
import StockOpnameModal from "../../../../Modals/Inventory/StockOpname/Modal";

// Confirm Modal
const { confirm } = Modal;

// CODE
export default function StockOpnameCreateTable(props) {
  const { soDetail, status, is_open } = props;
  // CONTEXT
  const { getArticleStockOpname, getAfterClosing } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);

  const [storage, setStorage] = useState([]);
  // Data Edit
  const [editData, setEditData] = useState(null);


  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getArticleStockOpname({
      source: "stock-opname/by-id-so",
      id_so: soDetail.id_so,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Article Stock Opname => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return filt.is_deleted === false;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  const fetchClosingData = async () => {
    await getAfterClosing({
      source: "stock-opname/after-closing",
      id_so: soDetail.id_so,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Closing Stock Opname => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return filt.is_deleted === false;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    if(status == 3){
      fetchClosingData();
    } else{
      fetchData();
    }
  }, [soDetail.id_so, status]);

  useEffect(() => {
    console.log("ID Stock Opname >> ", soDetail.id_so);
    setStorage(storage);
  }, [soDetail.id_so]);

  useEffect(() => {
    console.log("Data Article >> ", data);
  }, [data]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 75,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" justify="center" style={{ width: "100%" }}>
              <Col span={12} className="col-edit">
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      // console.log("ABOUT TO EDIT > ", record);
                      if(status == 2){
                        handleOpen();
                        setEdit(true);
                        setEditData(record);
                      } else {
                        null;
                      }
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      width: 300,
      // fixed: "left",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_name - b.article_name,
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      width: 150,
      // fixed: "left",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Stock Quantity",
      dataIndex: "actual_stock_quantity",
      key: "actual_stock_quantity",
      width: 175,
      render: (stock) => {
        return `${stock > 0 ? stock : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.department_name - b.department_name,
      // render: (dept) => {
      //   return dept === null ? " - " : dept;
      // },
    },
    {
      title: "Actual Stock Quantity",
      dataIndex: "stock_quantity",
      key: "stock_quantity",
      width: 175,
      render: (stock) => {
        return `${stock > 0 ? stock : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.department_name - b.department_name,
      // render: (dept) => {
      //   return dept === "NULL" ? " - " : dept;
      // },
    },
    {
      title: "Stock In",
      dataIndex: "stock_in",
      key: "stock_in",
      width: 115,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.department_name - b.department_name,
      render: (stock_in) => {
        return stock_in == null ? " - " : stock_in;
      },
    },
    {
      title: "Stock Out",
      dataIndex: "stock_out",
      key: "stock_out",
      width: 115,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.department_name - b.department_name,
      render: (stock_out) => {
        return stock_out == null ? " - " : stock_out;
      },
    },
    {
      title: "Stock In Transfer",
      dataIndex: "stock_in_transfer",
      key: "stock_in_transfer",
      width: 115,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.department_name - b.department_name,
      render: (stock_in_transfer) => {
        return stock_in_transfer == null ? " - " : stock_in_transfer;
      },
    },
  ];


  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle Refresh
  const handleRefresh = () => {
    fetchData();
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setEditData(null);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (value) => {
    const del = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Delete a Stock Opname Artice?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log("contentDelete >>>>>>", contentdelete);

    let obj = {
      ["id"]: contentdelete?.id > 0 ? contentdelete.id : 0,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/inventory/stock-opname/article-stock`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Delete => ", response);
        masterSuccessNotification(response, { method: 2, source: "Stock Opname Article" });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Stock Opname Article" });
        console.log("Delete => ", error);
      });
  };

  return (
    <>
      <Row className="article-main-table" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "3px" }}>
          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
          >
            {`Article Stock Opname List`}
          </Divider>


          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="art-list-table"
              name="art-list-table"
              key="art-list-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 800,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <StockOpnameModal
        is_open = {open}
        is_close = {handleCancel}
        is_refresh = {handleRefresh}
        is_edit = {edit}
        articleFilter = {edit === true ? null : data}
        _dataEdit = {edit === true ? editData : null}
        soDetail = {soDetail}
      />
    </>
  );
}

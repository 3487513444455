// Import React Components
import React from "react";

// Import Antd Components
import { Col, Row, Typography } from "antd";
import { Footer } from "antd/lib/layout/layout";

// CODE
export default function FooterManager() {
  const date = new Date(Date.now());
  const year = date.getFullYear();

  return (
    <>
      <Footer
        style={{
          position: "sticky",
          textAlign: "center",
          backgroundColor: "#001529",
        }}
      >
        <Row justify="center">
          <Col>
            <Typography
              style={{
                color: "#B5B5C3",
                fontWeight: 750,
                marginRight: "0.5rem",
              }}
            >
              {`${year}`}
            </Typography>
          </Col>

          <Col>
            <Typography
              style={{ fontSize: 13, color: "#B5B5C3", fontWeight: 750 }}
            >
              {`© Look Ver 1.0`}
            </Typography>
          </Col>
        </Row>
      </Footer>
    </>
  );
}

// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import moment, { now } from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Modal,
  Form,
  Divider,
  InputNumber,
  DatePicker,
  Typography,
  Input,
} from "antd";

// Import React Icons Components
import {
  ArrowRightOutlined,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterApprovalLevel from "../../../Reusable/Master/Approval/ApprovalLevel";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notification
import { incomplete } from "../../../Reusable/Notification/Notification";
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";

import PRDetailTable from "../../../Table/BackOffice/Purchasing/PurchaseDetail/Table";
import MasterArticleInventory from "../../../Reusable/Master/ArticleInventory/ArticleInventory";
import PurchaseRequestList from "../../../Reusable/Inventory/Purchasing/PurchaseRequestList";
import PRDetailModalTable from "../../../Table/BackOffice/Purchasing/PurchaseRequestDetailModal/Table";
import PODetailModalTable from "../../../Table/BackOffice/Purchasing/PurchaseOrderDetailModal/Table";
import MasterSupplierByArticleId from "../../../Reusable/Master/Supplier/MasterSupplierByArtID";
import { user_name, token } from "../../../../API/Global/Payload";

// Modal
const { confirm } = Modal;

const { TextArea } = Input;

// CODE
export default function ArticlePriceModal(props) {
  // PROPS
  const {
    is_open,
    is_close,
    is_refresh,
    is_edit,
    articleId,
    getSelectedSupplier,
    params_acc,
  } = props;

  // CONTEXT
  const { getMasterSupplierByArticleId } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  const [dataPRDetail, setDataPRDetail] = useState([]);
  const [dataPODetail, setDataPODetail] = useState([]);

  const [dataSelectedPR, setDataSelectedPR] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);

  const [select, setSelect] = useState(null);
  const [selectData, setSelectData] = useState([]);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  // Loadings
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Tax
  const _tax = params_acc?.tax_percentage / 100;

  // Columns
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "action",
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Row className="action-guest-table-btn" style={{ width: "100%" }}>
    //           <Col span={12}>
    //             <Tooltip title="Edit Data" className="edit-tooltip">
    //               <FiEdit
    //                 className="edit-btn"
    //                 onClick={() => {
    //                   console.log("ABOUT TO EDIT > ", record);

    //                   handleFormField(record);

    //                   handleOpen();
    //                   setEdit(true);
    //                   setDataEdit(record);
    //                 }}
    //                 style={{
    //                   fontSize: 20,
    //                   color: "#1BC5BD",
    //                   cursor: "pointer",
    //                 }}
    //               />
    //             </Tooltip>
    //           </Col>

    //           <Col span={12}>
    //             <Tooltip title="Delete Data" className="delete-tooltip">
    //               <DeleteFilled
    //                 className="delete-btn"
    //                 onClick={() => {
    //                   console.log("ABOUT TO DELETE > ", record);

    //                   //   showModalConfirm(record);
    //                 }}
    //                 style={{ fontSize: 20, color: "#F64E60" }}
    //               />
    //             </Tooltip>
    //           </Col>
    //         </Row>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      render: (amount, record) => {
        return amount != (null || undefined)
          ? `${amount}${record?.is_ppn ? "**" : "*"}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )
          : 0;
      },
    },
    {
      title: "Price after VAT",
      dataIndex: "is_ppn",
      key: "is_ppn",
      render: (ppn, record) => {
        let _ppn = record?.price * _tax;
        let _prc = 0;

        switch (ppn) {
          case true:
            _prc = record?.ppn_price ? record.ppn_price : record.price + _ppn;
            break;

          default:
            break;
        }

        return ppn
          ? `${_prc}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "Without VAT";
      },
    },
    // {
    //   title: "Qty",
    //   dataIndex: "remaining_quantity",
    //   key: "remaining_quantity",
    // },
  ].filter((item) => !item.hidden);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      setSelectData(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      // getSelectedSupplier(selectedRows);
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      id_article: record.id_article,
    }),
  };

  // Fetch Data
  const fetchData = async () => {
    await getMasterSupplierByArticleId({
      supplier: "supplier-article-by-article-id",
      id_article: articleId,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        let option = response.data.msg.filter(
          (obj, index) =>
            response.data.msg.findIndex(
              (item) => item.id_supplier === obj.id_supplier
            ) === index
        );

        setData(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("Modal State: ", is_open);

    if (is_open == true && !cleanUp) {
      setSelectedArticleId(articleId);
      fetchData();
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
      //   is_edit(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // Handle Open
  const handleOpen = () => {
    // setIsDelete(false);

    setModalOpen(true);
  };

  // Handle Close
  const handleClose = () => {
    setData([]);
    setDataPRDetail([]);
    setDataPODetail([]);
    setSelectedArticleId(null);
    setSelect(null);

    is_close(false);
    // is_edit(false);
    // setIsDelete(true);
    setEdit(false);
    setModalOpen(false);

    setIsLoading(false);

    form.resetFields();
  };

  return (
    <>
      <Modal
        className="purchase-ord-modal"
        key="purchase-ord-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Supplier List By Article Selected`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        destroyOnClose={true}
        closable
        onCancel={handleClose}
        width={800}
      >
        <Table
          className="pr-detail-modal-table"
          name="pr-detail-modal-table"
          key="pr-detail-modal-table"
          columns={columns}
          dataSource={data}
          bordered
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
          }}
          //   scroll={{
          //     x: 800,
          //   }}
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => (record.id ? record.id : record.key)}
        />

        <Typography
          className="text-notes"
          style={{ fontSize: 10, color: "000000" }}
        >
          {`* Prices are not subject to VAT`}
        </Typography>
        <Typography
          className="text-notes"
          style={{ fontSize: 10, color: "000000" }}
        >
          {`** Prices are subject to VAT (PPN ${params_acc?.tax_percentage}%)`}
        </Typography>

        <Row
          style={{
            justifyContent: "end",
            margin: "15px 0px",
          }}
        >
          <Button
            className="next-btn"
            type="primary"
            icon={<ArrowRightOutlined />}
            disabled={selectData.length == 0 ? true : false}
            onClick={() => {
              getSelectedSupplier(selectData);
              handleClose();
            }}
          >{`Submit Supplier`}</Button>
        </Row>
      </Modal>
    </>
  );
}

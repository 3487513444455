// REACT COMPONENTS
import React from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { FaBoxesPacking, FaBoxesStacked, FaFileCircleCheck, FaKitchenSet, FaRegCalendar, FaRegFileLines, FaRightLeft, FaTruckMoving } from "react-icons/fa6";
import { TbDevicesCancel, TbListCheck, TbReportAnalytics, } from "react-icons/tb";
import { BsBoxSeam, } from "react-icons/bs";

// GLOBAL STYLES
import { globalIcon, globalText, gridStyle, linkStyle, mainBody, } from "../../../../../Style/Global/Global";

export default function OutgoingStockReportPage() {
  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <FaBoxesPacking
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Outgoing Stock Reports`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("transfer-summary")}
                    // type="primary"
                    icon={<TbReportAnalytics style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Transfer Summary`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("transfer-validation")}
                    // type="primary"
                    icon={<TbListCheck style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Transfer Validation`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("single-article-moving")}
                    // type="primary"
                    icon={<BsBoxSeam style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Single Article Moving`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("stock-article-moving")}
                    // type="primary"
                    icon={<FaBoxesStacked style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Stock Article Moving`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("stock-outgoing")}
                    // type="primary"
                    icon={<FaTruckMoving style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Stock Outgoing`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("expired-outgoing")}
                    // type="primary"
                    icon={<TbDevicesCancel style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Expired Outgoing`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("stock-requisition")}
                    // type="primary"
                    icon={<FaFileCircleCheck style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Stock Requisition`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("requisition-summary")}
                    // type="primary"
                    icon={<FaRegFileLines style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Requisition Summary`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("annual-outgoing")}
                    // type="primary"
                    icon={<FaRegCalendar style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Annual Outgoing`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("stock-item-transform")}
                    // type="primary"
                    icon={<FaRightLeft style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Stock Item Transform`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("inter-kitchen-transfer")}
                    // type="primary"
                    icon={<FaKitchenSet style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Inter Kitchen Transfer`}
                    </Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  department,
  roles,
  user_level,
  user_name,
} from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterRoomStatus(props) {
  // PROPS
  const { status, getStatus, is_order, status_id } = props;

  // CONTEXT
  const { getMasterRoomStatus } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [roomStatus, setRoomStatus] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  const [masterOptions, setMasterOptions] = useState([]);

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getMasterRoomStatus({
      room_status: "room-status",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Room Status => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          // setMasterOptions(_res);
          handleFilter(_res);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Error ", error);
        // failedFetch(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Update Status
  useEffect(() => {
    let cleanUp = false;

    // (status != roomStatus || roomStatus != null) &&
    // optionValue.length > 0 &&
    if (status && !cleanUp) {
      console.log("Status: ", status);
      console.log("Status ID: ", status_id);

      // handleStatus();
      setRoomStatus(status);
    } else {
      setRoomStatus(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [status, status_id]);

  // Update Status
  useEffect(() => {
    let cleanUp = false;

    if (status_id > 0 && masterOptions.length > 0 && !cleanUp) {
      console.log("Status ID: ", status_id);

      handleAuth(masterOptions);
    }

    return () => {
      cleanUp = true;
    };
  }, [status_id, masterOptions]);

  // HANDLE STATUS
  const handleStatus = () => {
    let _selected = masterOptions.filter((val) => {
      return val?.room_status_id == status;
    });

    console.log("Change Status: ", _selected);

    setRoomStatus(
      _selected?.length > 0 ? _selected[0]?.room_status : _selected?.room_status
    );
  };

  // HANDLE ROLES & PERMISSIONS
  const handleAuth = (value) => {
    const _array = value;

    let _newOptions = [];
    let _optionNew = [];

    const _role = roles;
    const _named = user_name;
    const _dept = department.toLowerCase();
    const _level = user_level;

    console.log("Roles: ", _role);
    console.log("User Name: ", _named);
    console.log("Department: ", _dept);
    console.log("User level: ", _level);

    if (_dept.includes("house")) {
      switch (_level) {
        case 2:
          _newOptions = [1, 2, 4, 9, 8];
          break;

        case 1:
          _newOptions = [1, 4, 9, 8];
          break;

        case 3:
          _newOptions = [1, 2, 4, 9, 8];
          break;

        case 4:
          _newOptions = [1, 2, 4, 9, 8];
          break;

        default:
          // _newOptions = [1, 2, 3, 4, 9, 8];
          setOptionValue(_array);
          break;
      }
    } else if (_dept.includes("all")) {
      setOptionValue(_array);
    } else {
      // console.log("Lari kesini ke else");
      setOptionValue([]);
    }

    console.log("Filters value: ", _newOptions);
    console.log("Filters value: ", _array);

    if (_newOptions.length > 0) {
      _newOptions.filter((val) => {
        // console.log("Value Option Value all: ", val);

        _array.forEach((opt) => {
          // console.log("Roles ID: ", opt);
          // console.log(`${val} == ${opt.value}`, val == opt.value);

          if (val == opt.value) {
            return _optionNew.push(opt);
          }
        });
      });

      console.log("FILTERED ROLES: ", _optionNew);
      // setOptionValue(_optionNew);

      handleOption(_optionNew);
    } else {
      handleOption(_array);
    }
  };

  // Handle Option
  const handleOption = (val) => {
    const _arr = val;

    console.log("Handle Option: ", _arr);

    if (status_id == 1) {
      const _finalOpt = _arr.filter((items) => {
        if (items?.value == 5 || items?.value == 2) {
          return items;
        }
      });

      setOptionValue(_finalOpt);
    } else if (status_id == 3) {
      const _finalOpt = _arr.filter((items) => {
        if (items?.value == 4 || items.value == 8 || items.value == 9) {
          return items;
        }
      });

      setOptionValue(_finalOpt);
    } else if (status_id == 4) {
      const _finalOpt = _arr.filter((items) => {
        if (items?.value == 3 || items?.value == 8) {
          return items;
        }
      });

      setOptionValue(_finalOpt);
    } else if (status_id == 5 || status_id == 2) {
      const _finalOpt = _arr.filter((items) => {
        if (items?.value < 3) {
          return items;
        }
      });

      setOptionValue(_finalOpt);
    } else if (status_id == 6 || status_id == 7) {
      const _finalOpt = _arr.filter((items) => {
        if (items?.value == 5) {
          return items;
        }
      });

      setOptionValue(_finalOpt);
    } else if (status_id == 8) {
      const _finalOpt = _arr.filter((items) => {
        if (items?.value > 2 && items?.value < 5) {
          return items;
        }
      });

      setOptionValue(_finalOpt);
    } else {
      const _finalOpt = _arr.filter((items) => {
        if (items?.value == status_id) {
          return items;
        }
      });

      setOptionValue(_finalOpt);
    }
  };

  // HANDLE FILTER
  const handleFilter = async (val) => {
    const _filter = val;
    // console.log("About to Filtered = ", _filter);

    // setMasterOptions(_filter);

    let filterData = _filter.filter((val) => {
      // console.log("VALUE = ", val);

      if (is_order == 1) {
        return val.room_status.includes("Order");
      } else if (is_order == 2) {
        return (
          val.room_status.includes("Service") || val.room_status.includes("Off")
        );
      } else {
        return val;
      }
    });

    let optionFilter = filterData.map((value) => ({
      key: value?.id,
      label: value?.room_status,
      value: value?.room_status_id,
    }));

    console.log("Filtered = ", optionFilter);
    setMasterOptions(optionFilter);
    // console.log(first);

    switch (is_order) {
      case 1:
      case 2:
        setOptionValue(optionFilter);
        break;

      default:
        handleAuth(optionFilter);
        break;
    }
  };

  // Handle Clear
  const handleClear = () => {
    setRoomStatus(null);
  };

  return (
    <>
      <Select
        className="master-room-status"
        name="master-room-status"
        key={optionValue}
        style={{ width: "100%" }}
        value={roomStatus}
        onChange={(e, opt) => {
          setRoomStatus(e);

          console.log("Room Status = ", e, opt);

          getStatus(e, opt?.label);

          //   let getPostTypeName = optionValue.filter((cd) => {
          //     return cd.value == e;
          //   });

          //   console.log("INI Posting Type ", getPostTypeName);

          // setCode(getDeptCode[0].code);

          //   getPostType(getPostTypeName[0].value);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
        // filterOption={false}
        onClear={handleClear}
        allowClear={true}
      />
    </>
  );
}

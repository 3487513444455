// Import React Components
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  TimePicker,
  Modal,
  Row,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import {
  DeleteFilled,
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import {
  MdOutlineDashboardCustomize,
  MdOutlineHorizontalSplit,
} from "react-icons/md";
import { AiOutlineSave } from "react-icons/ai";

// Import Page Components
import ModalGuestCard from "../Guest/GuestCardModal";

import SplitBillModalTable from "../../../Table/Outlet/Order/SplitBill/TableSplitBill";
import SavedBillModalTable from "../../../Table/Outlet/Order/SavedBill/TableSavedBill";

// Import Notifications
import {
  failedPOSReservation,
  successPOSReservation,
} from "../../../Reusable/Notification/Notification";

// Modal
const { confirm } = Modal;

// CODE
export default function ModalAddTableAvailability(props) {
  // PROPS
  const { openModal, closeModal, selectedData, handleRefresh } = props;

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, headerData, detailData } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [headers, setHeaders] = useState([]);
  const [details, setDetails] = useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState([]);
  const [guestOpen, setGuestOpen] = useState(false);
  const [guestFromCard, setGuestFromCard] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      form.setFieldsValue({
        table_id: selectedData.table_id,
        start_date: selectedData.start_date,
      });
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // GET GUEST NAME
  const getGuestName = (value) => {
    const _guest = value;
    console.log(_guest);

    setGuestFromCard(true);

    form.setFieldsValue({
      name: _guest?.length > 0 ? _guest[0]?.full_name : _guest.full_name,
      phone_number:
        _guest?.length > 0 ? _guest[0]?.phone_number : _guest.phone_number,
    });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setGuestFromCard(false);
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log("ON FINISH >>>", e);
    showModalConfirm(e);
  };

  const onFinishFailed = (e) => {};

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Add POS Table Reservation`,
      content: `Are you sure want to Add this POS Table Reservation ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        handleCancel();
        // setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    let submit = value;
    submit["start_date"] = moment(LocalizationDate(value?.start_date));
    submit["end_date"] = moment(LocalizationDate(value?.end_date));
    submit["created_by"] = user_name;
    await axios
      .post(`${baseurl}/pos/reservation`, submit, {
        headers: {
          Authorization: `Bearer ${token || ""}`,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Add POS Reservation => ", response);
          successPOSReservation(response, { method: 0 });
          handleRefresh();
          handleCancel();
        } else {
          failedPOSReservation(response, { method: 0 });
        }
      })
      .catch((error) => {
        console.log("Error Add POS Reservation => ", error);
        failedPOSReservation(error, { method: 0 });
        // setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        className="saved-bill-modal"
        title={
          <>
            <Row className="saved-bill-modal-row">
              <PlusCircleTwoTone
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`POS Add Table Reservation`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          className="form-pos-add-table-resv"
          key="form-pos-add-table-resv"
          name="form-pos-add-table-resv"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ padding: "0px 36px 0px" }}
        >
          <Row gutter={[30, 30]} justify="start" className="row-outlet-loc">
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please, input the visitor name!",
                  },
                ]}
              >
                <Input
                  className="name-input"
                  placeholder="Name"
                  disabled={guestFromCard ? true : false}
                  addonAfter={
                    <Tooltip className="modal-tooltip" title="Find Guest Name">
                      <MdOutlineDashboardCustomize
                        className="suffix-icons"
                        onClick={() => {
                          setGuestOpen(true);
                        }}
                        style={{
                          fontSize: 20,
                          color: "#3699FF",
                          marginTop: 3,
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Phone Number"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Options!",
                  },
                ]}
              >
                <Input
                  placeholder="Phone No."
                  disabled={guestFromCard ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Pax"
                name="pax"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Options!",
                  },
                ]}
              >
                <InputNumber placeholder="pax" min={1} />
              </Form.Item>
            </Col>

            {/* <Col>
              <Form.Item label="Outlet ID" name="outlet_id" hidden>
                <Input placeholder="Outlet ID" disabled />
              </Form.Item>

              <Form.Item label="Outlet Loc" name="label_outlet_location" hidden>
                <Input placeholder="Outlet Loc" disabled />
              </Form.Item>
            </Col> */}
          </Row>

          <Row gutter={[30, 30]} justify="start" className="row-outlet-loc">
            <Col span={8}>
              <Form.Item
                label="Table"
                name="table_id"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Options!",
                  },
                ]}
              >
                <Input placeholder="Table ID" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Start Time"
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: "Please, input the start time!",
                  },
                ]}
              >
                <TimePicker
                  format={"HH"}
                  disabledTime={() => ({
                    ...{
                      disabledHours: () => [
                        moment(selectedData?.start_date).hours(),
                      ],
                      disabledMinutes: () => [0],
                      disabledSeconds: () => [0],
                    },
                  })}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="End Time"
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: "Please, input the end time!",
                  },
                ]}
              >
                <TimePicker format={"HH"} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[30, 30]} justify="start" className="row-outlet-loc">
            <Col span={24}>
              <Form.Item
                label="Remarks"
                name="remarks"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Choose an Options!",
                //   },
                // ]}
              >
                <Input.TextArea placeholder="Remarks" />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="pos-add-table-resv-modal-form-divider-form"
            style={{ margin: "15px 0px" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              onClick={(e) => {
                // selectedTable(tableKeys);
                // handleCancel();
              }}
              loading={loading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
      <ModalGuestCard
        openModal={guestOpen}
        closeModal={setGuestOpen}
        getGuestName={getGuestName}
      />
    </>
  );
}

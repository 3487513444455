// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Icons
import { RiContactsBook2Fill, RiReservedFill } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import {
  DeleteOutlined,
  FileExcelFilled,
  FormOutlined,
  PlusCircleFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ImCheckmark } from "react-icons/im";
import { TbCircleCheck, TbCircleX } from "react-icons/tb";

// Import Page Components
import TableGuest from "../../../Components/Table/FrontOffice/Guest/Table";

// Import Master
import MasterIdCardType from "../../../Components/Reusable/Master/IdCardType/IdCardType";
import MasterTitleType from "../../../Components/Reusable/Master/Title/Title";
import MasterCountry from "../../../Components/Reusable/Master/Country/Country";
import MasterSexType from "../../../Components/Reusable/Master/Sex/Sex";
import MasterPaymentType from "../../../Components/Reusable/Master/PaymentType/PaymentType";

// Import Notification
import {
  failedFetch,
  failedGuest,
  incomplete,
  incompleteGuest,
  successGuest,
} from "../../../Components/Reusable/Notification/Notification";

// Import Global Styles
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";
import downloadExcel from "../../../Components/Reusable/Functions/ExportXLSX/ExportExcel";
import TableCompany from "../../../Components/Table/Master/CompanyList/Table";

// MODAL
const { confirm } = Modal;

// CODE
export default function GuestCardListPage() {
  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Get Fetch Data
  const [data, setData] = useState([]);
  const [guest, setGuest] = useState(null);
  // TYPE
  const [type, setType] = useState(1);
  // Modal
  const [open, setOpen] = useState(false);
  // Refresh State
  const [refresh, setRefresh] = useState();
  // Search Key
  const [value, setValue] = useState("");
  // Search
  const [search, setSearch] = useState(null);
  // Find
  const [alert, setAlert] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATION
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async (e) => {
    console.log("Type: ", e, type);

    let _type = "";
    let _value = e > 0 ? e : type;

    switch (_value) {
      case 1:
        _type = "card";
        break;

      case 2:
      case 3:
        _type = "company";
        break;

      default:
        break;
    }

    await getGuest({
      guest: "guest",
      type: _type,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        setData(response?.data?.msg);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  // useEffect(() => {
  //   fetchData();
  // }, []);

  // Handle Change
  const handleChange = (e) => {
    // console.log("Type: ", e);
    setType(e);

    // fetchData(e);
  };

  // Handle Nav
  const move = () => {
    navigate("/front-office/reservation/add-reservation");
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // ONCHANGE
  const handleSearch = (value) => {
    value.preventDefault();
    // console.log("Handle Search: ", value.target.value);
    // console.log("Set Search: ", search);

    setValue(value.target.value);
    search;
    // setSearch()
  };

  // CONSOLE
  // console.log("Same ID --> ", guest);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="guest-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <RiContactsBook2Fill
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Guest Card List`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 15px 22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Guest Type`}</Typography>

                  <Select
                    className="guest-select"
                    key="guest-select"
                    defaultValue="Individual"
                    style={{
                      width: 205,
                    }}
                    onChange={handleChange}
                    options={[
                      {
                        value: 1,
                        label: "Individual",
                      },
                      {
                        value: 2,
                        label: "Company",
                      },
                      {
                        value: 3,
                        label: "OTA",
                      },
                    ]}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 15px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Keywords`}</Typography>

                  <Input
                    allowClear
                    placeholder="Name"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={search}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>

              {/* <Col span={12}>
                <Row justify="end">
                  <Button
                    type="primary"
                    key="to-reserv-btn"
                    className="to-reserv-btn"
                    onClick={move}
                    style={{
                      margin: "30px 0px 0px",
                    }}
                    icon={<FormOutlined />}
                  >
                    Create Reservation
                  </Button>
                </Row>
              </Col> */}
            </Row>

            {type === 1 ? (
              <TableGuest searchKey={value} is_search={setSearch} />
            ) : (
              <TableCompany
                searchKey={value}
                is_search={setSearch}
                is_company={type}
              />
            )}
          </Col>
        </Content>
      </Layout>
    </>
  );
}

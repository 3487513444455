// Import React's Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
  Divider,
  DatePicker,
  Typography,
} from "antd";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import {
  token,
  user_name,
} from "../../../../API/Global/Payload";

// Import React Icons Components
import { FiEdit } from "react-icons/fi";

// Import Notification
import {
  masterSuccessNotification,
  masterFailedNotification,
  masterIncomplete,
} from "../../../Reusable/Notification/MasterNotif/Notification";

//Import Table
import StockOpnameArticleTable from "../../../Table/BackOffice/Inventory/StockOpnameArticle/Table";

// Modal
const { confirm } = Modal;
const { RangePicker } = DatePicker;

// CODE
export default function StockOpnameCreateModal(props) {
  // PROPS
  const { is_open, is_close, is_refresh, _id_storage } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataCheck, setDataCheck] = useState([]);
  const [open, setOpen] = useState(false);
  const [id_storage, setId_storage] = useState(null);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    setId_storage(_id_storage);
  },[_id_storage]);

  useEffect(() => {
    console.log("ID Storage >> ", id_storage);
  },[id_storage])

  useEffect(() => {
    console.log("Real Data: ", data);
  }, [data]);

  useEffect(() => {
    console.log("Open Parameter Modal: ", is_open);
    if(is_open == true){
      console.log("Opened");
      setOpen(true);
    } else {
      console.log("Closed");
      setOpen(false);
    }
  }, [is_open]);

  
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      // console.log("Modal State: ", is_open);
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
      form.setFieldsValue({
        request_date: moment(),
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);

    // console.log("USERS METADATA: ", approval_level, department, user_level);
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);

    setModalOpen(false);
    setDataCheck([])

    setData([]);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    const error = {
      errorFields: [
        {
          errors: ["Please, Check an Article!"],
        },
      ],
    };

    console.log("DATA ONSUBMIT >> ", e);

    if (dataCheck.length > 0) {
      showModalConfirm(dataCheck, e.plan_date[0], e.plan_date[1]);
    } else {
      masterIncomplete(error);
    }

  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  const handleDataCheck = (val) => {
    setDataCheck(val);
  };

  // ON FINISH SUBMIT
  const onSubmit = () => {
    const error = {
      errorFields: [
        {
          errors: ["Please, Check an Article!"],
        },
      ],
    };

    console.log("DATA ONSUBMIT >> ", dataCheck);

    if (dataCheck.length > 0) {
      showModalConfirm(dataCheck);
    } else {
      masterIncomplete(error);
    }

  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value, start, end) => {
    const _data = value;
    const _start = start;
    const _end = end;

    // console.log("SHOW MODAL CONFIRM STAGE >> ", _data);
    // console.log("submitEdit: ", submitEdit, _status);

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Submit a Stock Opname?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleFinish(_data, _start, _end);
      },

      onCancel() {

      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Finish
  const handleFinish = async (_value, _start, _end) => {
    let value = _value;
    let start = _start;
    let end = _end;

    let article = [];
      
    value.map((x) => {
      article.push({
        id_wa: x.id_wa,
        id_warehouse: x.id_storage,
        id_article: x.id_article,
        created_by: user_name,
      })
    });

    let allBody = {};

    allBody.data = article;
    allBody.header = {
      created_by: user_name,
      so_start_date:moment(start).format("YYYY-MM-DD HH:mm"),
      so_end_date: moment(end).format("YYYY-MM-DD HH:mm"),
      id_warehouse: value.length > 0 ? value[0].id_storage : null,
    }

      console.log("ALL BODY EDIT >> ", allBody);

      handleSubmit(allBody);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("Data About to POST: ", contentSubmit);

    await axios
      .post(`${baseurl}/inventory/stock-opname/article-list`, contentSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("Response Add Article List Stock Opname: ", response);

        masterSuccessNotification(response, {
          method: 0,
          source: "Article List Stock Opname",
        });
        handleClose();
        is_refresh(true);
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: "Article List Stock Opname",
        });
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <>
      <Modal
        className="stock-opname-req-modal"
        key="stock-opname-req-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Create Stock Opname`}</Typography>
            </Row>
          </>
        }
        centered
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={1000}
      >
        <Form
          className="purchase-req-form"
          key="purchase-req-form"
          name="purchase-req-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="pay-row" gutter={30}>
                <Col span={12} className="pay-col">
                  <Form.Item
                    label="Stock Opname Plan Date"
                    name="plan_date"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Stock Opname Plan Date!",
                      },
                    ]}
                  >
                    <RangePicker
                      showTime={{
                        format: 'HH:mm',
                      }}
                      format="YYYY-MM-DD HH:mm"
                      onChange={(value, dateString) => {
                        console.log('Selected Time: ', value);
                        console.log('Formatted Selected Time: ', dateString[0]);
                        console.log('Formatted Selected Time: ', dateString[1]);
                      }}
                      // onOk={onOk}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Storage Article List`}
              </Divider>

              <StockOpnameArticleTable
                _is_close={is_close}
                _id_storage={id_storage}
                _dataCheck={handleDataCheck}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">

                <Button
                  className="submit-btn"
                  type="primary"
                  // onClick={onSubmit}
                  htmlType="submit"
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React from "react";

// Import Ant Design Components
import { Card, Col, Row, Typography } from "antd";

// Import React Icons
import { EllipsisOutlined } from "@ant-design/icons";
import ChartAreaRevenue from "../../Data/Charts/ChartRevenue";

// Styling
const styleSubtext = {
  color: "#B5B5C3",
  fontSize: 14,
  fontWeight: 500,
  padding: "0px 30px 0px",
};
const styleText = {
  color: "#000000",
  fontSize: 20,
  fontWeight: 500,
  padding: "0px 30px 0px",
};

// CODE
export default function RevenueCard() {
  return (
    <>
      <Card style={{ borderRadius: 12 }}>
        <Row className="row-revenue">
          <Col span={24}>
            <Typography style={styleText}>{`Revenue Stats`}</Typography>
            <Typography style={styleSubtext}>
              {`Total Revenue Annualy`}
            </Typography>
          </Col>
        </Row>

        <Row
          className="row-revenue-chart"
          style={{ padding: 30, margin: "30px 0px 8px" }}
        >
          <Col span={24}>
            <ChartAreaRevenue />
          </Col>
        </Row>
      </Card>
    </>
  );
}

const nol = "---";
const min = "minus";

let Satuan = [
    "",
    "Satu",
    "Dua",
    "Tiga",
    "Empat",
    "Lima",
    "Enam",
    "Tujuh",
    "Delapan",
    "Sembilan",
  ],
  Ribuan = ["", "Ribu", "Juta", "Miliar", "Triliun", "Kuadriliun"],
  s = [];

const single_digit = [
  "",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
];

const double_digit = [
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];

const below_hundred = [
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

// CODE
export const AmountInWords = (props) => {
  // PROPS
  const { total_price, param_word, currency } = props;
  let result = "";
  let cur = "Rupiah";

  switch (currency) {
    case "USD":
      cur = "Dollar";
      break;

    case "EUR":
      cur = "Euro";
      break;

    case "GBP":
      cur = "Poundsterling";
      break;

    case "CNY":
      cur = "Yuan";
      break;

    case "JPY":
      cur = "Yen";
      break;

    case "KRW":
      cur = "Won";
      break;

    default:
      break;
  }

  console.log("Amount =>", props);

  // Indonesian Terbilang
  const handleIndonesian = (value) => {
    let x = value;

    console.log(x);

    if (!x) return nol;

    if (x < 0) {
      if (min) s = [min];
      x = -x;
    }

    for (let k = 0; x; k++) {
      let g = x % 1e3;

      if (g === 1 && k === 1) s.unshift("Seribu");
      else {
        let u =
          g > 99
            ? [(g > 199 ? Satuan[(g / 100) | 0] + " R" : "Ser") + "atus"]
            : [];

        if ((g %= 100)) {
          if (g > 9 && g < 20)
            u.push(
              g < 11
                ? "Sepuluh"
                : (g < 12 ? "se" : Satuan[g % 10] + " ") + "belas"
            );
          else {
            if (g > 19) u.push(Satuan[(g / 10) | 0] + " Puluh");
            if ((g %= 10)) u.push(Satuan[g]);
          }
        }
        k && u.push(Ribuan[k]);
        s.unshift(u.join(" "));
      }

      x = Math.floor(x / 1e3);
    }

    return s.join(" ") + cur;
  };

  // English Amount in Words
  const handleEnglish = (value) => {
    if (value < 0) return false;

    if (value === 0) return "Zero";

    function translate(n) {
      let word = "";

      if (n < 10) {
        word = single_digit[n] + " ";
      } else if (n < 20) {
        word = double_digit[n - 10] + " ";
      } else if (n < 100) {
        let rem = translate(n % 10);

        word =
          below_hundred[(n - (n % 10)) / 10 - 2] +
          `${n % 2 === 0 ? " " : "-"}` +
          rem;
      } else if (n < 1000) {
        word =
          single_digit[Math.trunc(n / 100)] + " Hundred " + translate(n % 100);
      } else if (n < 1000000) {
        word =
          translate(parseInt(n / 1000)).trim() +
          " Thousand " +
          // `${n % 2 === 0 ? "and " : ""}` +
          translate(n % 1000);
      } else if (n < 1000000000) {
        word =
          translate(parseInt(n / 1000000)).trim() +
          " Million " +
          // `${n % 2 === 0 ? "and " : ""}` +
          translate(n % 1000000);
      } else {
        word =
          translate(parseInt(n / 1000000000)).trim() +
          " Billion " +
          // `${n % 2 === 0 ? "and " : ""}` +
          translate(n % 1000000000);
      }

      return word;
    }

    const result = translate(value);

    // console.log("Results: ", result);

    return result + cur + " Only";
  };

  // Conditional Rendering
  // if (param_word === "english") {
  //   result = handleEnglish(total_price);
  // } else if (param_word === "indonesian") {
  //   result = handleIndonesian(total_price);
  // } else {
  //   result = nol;
  // }

  switch (param_word) {
    case "english":
      result = handleEnglish(total_price);
      break;

    case "indonesia":
      result = handleIndonesian(total_price);
      break;

    default:
      result = nol;
      break;
  }

  return result;
};

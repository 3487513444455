// Import React Components
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Form,
  Input,
  Tooltip,
  Divider,
  Checkbox,
  Result,
} from "antd";
import {
  ExclamationCircleOutlined,
  FormOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";

// Import Master Components
import {
  handleAuthorization,
  authorized_by,
  authorized_dept,
  authorized_roles,
  authorized_token,
} from "../../../API/Global/Permission";

// Import Notifications
import {
  failedCheckIn,
  failedPermit,
  failedRoomStatus,
  incomplete,
  successCheckIn,
  successPermit,
  successRoomStatus,
} from "../../Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ModalAuthorized(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    // State Source,
    params,
    // Success
    is_permitted,
  } = props;

  // CONTEXT
  const { requestPermissions } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  // Open Modals
  const [open, setOpen] = useState(false);
  // URL
  const [permitUrl, setPermitUrl] = useState(`permissions-admin`);
  const [postUrl, setPostUrl] = useState(`out-of-market`);
  const [authorizedRoles, setAuthorizedRoles] = useState(`ROLE_ADMIN`);
  const [department, setDepartment] = useState(`ALL`);
  // Load
  const [loading, setLoading] = useState(false);
  const [successed, setSuccessed] = useState(1);
  // IS DELETE
  const [isDelete, setIsDelete] = useState(false);

  // USEFORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      // console.log(openModal);
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (params > 0) {
      handleStatus(params);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // USE EFFECT POST
  useEffect(() => {
    let cleanUp = false;

    if (open && authorized_token && !cleanUp) {
      console.log("Token: ", authorized_token);
      console.log("By: ", authorized_by);
      console.log("Dept: ", authorized_dept);
      console.log("Roles: ", authorized_roles);

      if (
        authorized_token &&
        authorized_dept == department &&
        authorized_roles == authorizedRoles
      ) {
        setSuccessed(2);
        is_permitted(true);
      } else {
        setSuccessed(1);
        failedPermit("User Unauthorized");
      }
    } else {
      setSuccessed(1);
    }

    return () => {
      cleanUp = true;
    };
  }, [authorized_token, authorized_roles, authorized_dept]);

  // HANDLE STATUS
  const handleStatus = async (val) => {
    const stat = val;

    console.log("Status: ", stat);

    switch (stat) {
      case 2:
        break;

      default:
        break;
    }
  };

  // ON FINISH
  const onFinish = async (val) => {
    const value = val;

    handlePermit(value);
  };

  // HANDLE PERMIT
  const handlePermit = async (val) => {
    const _submit = val;

    await requestPermissions({
      auth: "auth",
      permit: `${permitUrl}`,
      email: _submit.email,
      password: _submit.password,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Permit: ", response);

        if (response?.data?.msg == "Access Granted") {
          // setCredentials(response?.data?.data);
          setDepartment(response.data.data.user.user_metadata.department);

          localStorage.setItem(
            "permit",
            response?.data?.data?.session?.access_token
          );

          handleAuthorization(response?.data?.data?.session?.access_token);
        } else {
          failedPermit(response);
        }

        successPermit(response);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Permit Err: ", error);

        failedPermit(error);
        setLoading(false);
        setSuccessed(3);
      },
    });
  };

  /*
  // HANDLE SUBMIT
  const handleSubmit = async () => {
    const _content = data;

    console.log("POSTED >>> ", _content);

    if (
      authorized_token &&
      authorized_dept == department &&
      authorized_roles == authorizedRoles
    ) {
      // await axios
      //   .post(
      //     `${baseurl}/room/${postUrl}`,
      //     {
      //       desc: _content?.description
      //         ? _content.description
      //         : data.description,

      //       res_id: _content?.room_status || data.reservation_id,
      //       room_num:
      //         _content?.room_number > 0
      //           ? _content.room_number
      //           : data.room_number,
      //       room_category:
      //         _content?.room_category_name || data.room_category_name,
      //       night: _content.night > 0 ? _content.night : data.night,
      //       created_by: authorized_by,
      //       date: _content?.date ? _content.date : data.date,
      //     },
      //     {
      //       headers: { Authorization: `Bearer ${authorized_token}` },
      //     }
      //   )
      //   .then((response) => {
      //     console.log("Response OM/OOO =  ", response);
      //     successRoomStatus(response);

      //     handleClose();
      //     is_refresh(true);
      //      localStorage.clear()
      //   })
      //   .catch((error) => {
      //     console.log("Err  OM/OOO = ", error);
      //     failedRoomStatus(error);
      //   });
      console.log("API POST: ", `${baseurl}/room/${postUrl}`);
    } else {
      failedRoomStatus("error");
      setSuccessed(3);
    }
  };
  */

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Failed >>> ", error);

    incomplete(error);
    // setOpen(true);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    closeModal(false);
    setOpen(false);
  };

  // console.log("INIT >>>", initialForm);

  return (
    <>
      <Modal
        className="modal-permit"
        title={
          <>
            <Row>
              <ExclamationCircleOutlined
                className="icon-exclam"
                style={{ color: "#faad14", fontSize: 24 }}
              />
              <Typography style={{ color: "#faad14", marginLeft: 15 }}>
                {`Authorized Permissions`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={690}
      >
        <Form
          name="authorized-form"
          className="authorized-form"
          key="authorized-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 16 }}
          //   wrapperCol={{ span: 12 }}
          autoComplete="off"
          layout="vertical"
          // initialValues={{
          //   room_modal: initialForm?.length > 0 ? initialForm : null,
          // }}
        >
          <Row className="modal-row" justify="center" style={{ width: "100%" }}>
            <Typography
              className="warning"
              style={{
                fontSize: 18,
                fontWeight: 750,
                textAlign: "center",
                color: "#ff4d4f",
                // margin: "0px 0px Super0px",
                padding: "15px 0px",
              }}
            >
              <WarningOutlined />
              {`Warning! This operation needs an Authorization Access and Official Reports! Please, Check Again!`}
              <br />
              {`Needs an Admin Permissions!`}
            </Typography>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            {successed != 2 ? (
              <Row
                className="auth-row"
                justify="center"
                style={{ width: "100%" }}
              >
                <Col
                  span={24}
                  className="auth-col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input your Email!",
                      },
                    ]}
                    style={{
                      width: "50%",
                    }}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input your Email!",
                      },
                    ]}
                    style={{
                      width: "50%",
                    }}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Col className="succeeded-col" span={24}>
                <Result
                  status={successed == 2 ? "success" : "error"}
                  className="permissions-result"
                  title="Authorization Granted!"
                  subTitle="You can close this dialog and do some Changes!"
                />
              </Col>
            )}
            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              justify="end"
              align="middle"
              style={{ padding: "0px 0px 5px", width: "100%" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                key={"submit"}
                danger
                disabled={successed == 2 ? true : false}
                // onClick={onFinish}
                loading={loading}
                className="submit-room"
                style={{
                  // backgroundColor: "#1BC5BD",
                  // borderColor: "#1BC5BD",
                  marginRight: 30,
                }}
              >
                {`Confirm`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleClose}
              >
                {`Close`}
              </Button>
            </Row>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Card, Col, Row, Space, Typography } from "antd";

// Import Icons
import { EllipsisOutlined } from "@ant-design/icons";
import { GiExitDoor } from "react-icons/gi";
import { failedFetch } from "../../Reusable/Notification/Notification";

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 35,
  fontWeight: 500,
  textAlign: "center",
};

const styleText1 = {
  color: "#b3b3b3",
  fontSize: 18,
  fontWeight: 500,
  textAlign: "center",
};

const styleText2 = {
  color: "#EE9D01",
  fontSize: 14,
  fontWeight: 500,
  textAlign: "center",
};

const cardItemStyle = {
  margin: "-60px 0px 0px 0px",
  borderRadius: 12,
  padding: 10,
  paddingLeft: 30,
  width: "100%",
  backgroundColor: "#FFFFFF",
  boxShadow: "5px 5px 5px #d9d9d9",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  minHeight: 150,
};

const cardBodyStyle = {
  display: "flex",
  justifyContent: "center",
};

export default function ArrivalDeparture() {
  // STATE MANAGEMENT
  const { getArrivalDeparture } = useContext(MainContext);

  const [dataArrivalDeparture, setDataArrivalDeparture] = useState(null);
  const [departedTodayRooms, setDepartedTodayRooms] = useState(null);
  const [departedTodayPersons, setDepartedTodayPersons] = useState(null);
  const [departingRooms, setDepartingRooms] = useState(null);
  const [departingPersons, setDepartingPersons] = useState(null);
  const [checkedInTodayRooms, setCheckedInTodayRooms] = useState(null);
  const [checkedInTodayPersons, setCheckedInTodayPersons] = useState(null);
  const [arrivingRooms, setArrivingRooms] = useState(null);
  const [arrivingPersons, setArrivingPersons] = useState(null);

  // FETCH DATA
  const fetchArrivalDeparture = () => {
    getArrivalDeparture({
      arrivalDeparture: "housekeeping",
      type: "arrival-departure",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Arrival Departure => ", response);
        setDataArrivalDeparture(response.data.msg);

        // console.log(dataDepartedToday.rooms);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log("Error ", error);
        failedFetch(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchArrivalDeparture();
  }, []);

  useEffect(() => {
    setDepartedTodayRooms(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_departed_check_out_stay[0].rooms
          ? dataArrivalDeparture[0].get_departed_check_out_stay[0].rooms
          : 0
        : 0
    );

    setDepartedTodayPersons(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_departed_check_out_stay[0].persons
          ? dataArrivalDeparture[0].get_departed_check_out_stay[0].persons
          : 0
        : 0
    );

    setDepartingRooms(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_departing[0].rooms
          ? dataArrivalDeparture[0].get_departing[0].rooms
          : 0
        : 0
    );

    setDepartingPersons(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_departing[0].persons
          ? dataArrivalDeparture[0].get_departing[0].persons
          : 0
        : 0
    );

    setCheckedInTodayRooms(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_checkin_today[0].rooms
          ? dataArrivalDeparture[0].get_checkin_today[0].rooms
          : 0
        : 0
    );

    setCheckedInTodayPersons(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_checkin_today[0].persons
          ? dataArrivalDeparture[0].get_checkin_today[0].persons
          : 0
        : 0
    );

    setArrivingRooms(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_arriving[0].rooms
          ? dataArrivalDeparture[0].get_arriving[0].rooms
          : 0
        : 0
    );

    setArrivingPersons(
      dataArrivalDeparture
        ? dataArrivalDeparture[0].get_arriving[0].persons
          ? dataArrivalDeparture[0].get_arriving[0].persons
          : 0
        : 0
    );
  }, [dataArrivalDeparture]);

  return (
    <Card
      title={
        <>
          <Row gutter={30} style={{ minHeight: 22, padding: "0px 30px 50px" }}>
            <Col span={24}>
              <Typography
                style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
              >
                Arrival & Departure
              </Typography>
            </Col>
          </Row>
        </>
      }
      className="card-room-arrival-depart"
      headStyle={{
        background: `var(--button-primary-hover, linear-gradient(262deg, #187de4 2.77%, rgba(160, 0, 174, 0.6) 139.4%))`,
        borderRadius: "12px 12px 0px 0px",
      }}
      bodyStyle={{
        backgroundColor: "#fafafa",
        borderRadius: 12,
      }}
      style={{
        borderRadius: 12,
        margin: "0px 30px 1px 0px",
      }}
    >
      <Row gutter={[20, 80]}>
        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {departedTodayRooms ? departedTodayRooms : 0}
              </Typography>
              <Typography style={styleText2}>Rooms</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {departedTodayPersons ? departedTodayPersons : 0}
              </Typography>
              <Typography style={styleText2}>Persons</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText1}>Departed Today</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {departingRooms ? departingRooms : 0}
              </Typography>
              <Typography style={styleText2}>Rooms</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {departingPersons ? departingPersons : 0}
              </Typography>
              <Typography style={styleText2}>Persons</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText1}>Departing</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {(departedTodayRooms ? departedTodayRooms : 0) +
                  (departingRooms ? departingRooms : 0)}
              </Typography>
              <Typography style={styleText2}>Rooms</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {(departedTodayPersons ? departedTodayPersons : 0) +
                  (departingPersons ? departingPersons : 0)}
              </Typography>
              <Typography style={styleText2}>Persons</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText1}>Total Departure</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {checkedInTodayRooms ? checkedInTodayRooms : 0}
              </Typography>
              <Typography style={styleText2}>Rooms</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {checkedInTodayPersons ? checkedInTodayPersons : 0}
              </Typography>
              <Typography style={styleText2}>Persons</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText1}>Check-In Today</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {arrivingRooms ? arrivingRooms : 0}
              </Typography>
              <Typography style={styleText2}>Rooms</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {arrivingPersons ? arrivingPersons : 0}
              </Typography>
              <Typography style={styleText2}>Persons</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText1}>Arriving</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {(checkedInTodayRooms ? checkedInTodayRooms : 0) +
                  (arrivingRooms ? arrivingRooms : 0)}
              </Typography>
              <Typography style={styleText2}>Rooms</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                minWidth: 60,
              }}
            >
              <Typography style={styleNumber}>
                {(checkedInTodayPersons ? checkedInTodayPersons : 0) +
                  (arrivingPersons ? arrivingPersons : 0)}
              </Typography>
              <Typography style={styleText2}>Persons</Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText1}>Total Arrival</Typography>
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </Card>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { sub_children } from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { IoOptions } from "react-icons/io5";
import { MdChair, MdLayers } from "react-icons/md";
import { BsCalculatorFill, BsDoorOpenFill, BsLayersFill } from "react-icons/bs";
import { VscLibrary } from "react-icons/vsc";
import { FaBusinessTime, FaConciergeBell } from "react-icons/fa";
import { GiOpenBook } from "react-icons/gi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Global Styles
import {
  cardBody,
  cardHeader,
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../Style/Global/Global";
import { AiTwotoneFolderOpen } from "react-icons/ai";

// ROUTES
const listCard = [
  {
    path: "accounting-params",
    title: "Accounting",
    id_modules: 87,
    icon: <BsCalculatorFill style={globalIcon} />,
  },
  {
    path: "back-office-params",
    title: "Back-Office",
    id_modules: 88,
    icon: <VscLibrary style={globalIcon} />,
  },
  {
    path: "dashboard-params",
    title: "Dashboard Params",
    id_modules: 111,
    icon: <BsLayersFill style={globalIcon} />,
  },
  {
    path: "front-office-params",
    title: "Front-Office",
    id_modules: 89,
    icon: <MdChair style={globalIcon} />,
  },
  // {
  //   path: "house-keeping-params",
  //   title: "House Keeping",
  //   id_modules: 90,
  //   icon: <BsDoorOpenFill style={globalIcon} />,
  // },
  // {
  //   path: "outlet-params",
  //   title: "F & B Params",
  //   id_modules: 91,
  //   icon: <FaConciergeBell style={globalIcon} />,
  // },
  {
    path: "general-report-params",
    title: "General Report Params",
    id_modules: 92,
    icon: <AiTwotoneFolderOpen style={globalIcon} />,
  },
  {
    path: "working-hours-params",
    title: "Working Hours (Shift)",
    id_modules: 93,
    icon: <FaBusinessTime style={globalIcon} />,
  },
];

// CODE
export default function ParamsPage() {
  // STATE MANAGEMENT
  // List Items
  const [listItems, setlistItems] = useState([]);

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };
  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const grandModule = sub_children?.length > 0 ? sub_children : [];

    const list = listCard.map((record) => {
      if (grandModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      } else {
        return null;
      }
    });

    // console.log("Grand Children Modules: ", sub_children);
    // console.log("Filtered: ", list);

    setlistItems(list);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="article-sales-main-layout">
          <Row
            className="row-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              margin: "0px 0px 30px",
              borderRadius: 5,
            }}
          >
            <IoOptions
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Parameters Configuration`}
            </Typography>
          </Row>

          <Row className="row-body" justify="start" style={mainBody}>
            {listItems}

            {/* <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("accounting-params")}
                  // type="primary"
                  icon={<BsCalculatorFill style={globalIcon} />}
                  style={linkStyle}
                >
                 
                  <Typography className="text-page" style={globalText}>
                    {`Accounting`}
                  </Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`back-office-params`)}
                  icon={<VscLibrary style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Back Office`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move("front-office-params")}
                  // type="primary"
                  icon={<MdChair style={globalIcon} />}
                  style={linkStyle}
                >
                  
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Front Office`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`house-keeping-params`)}
                  icon={<BsDoorOpenFill style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`House Keeping`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`outlet-params`)}
                  icon={<FaConciergeBell style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Outlet`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`general-report-params`)}
                  icon={<AiTwotoneFolderOpen style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`General Report`}</Typography>
                </Button>
              </Card>
            </Col>

            <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Card className="card-link" style={gridStyle}>
                <Button
                  className="move-btn"
                  onClick={() => move(`working-hours-params`)}
                  icon={<FaBusinessTime style={globalIcon} />}
                  style={linkStyle}
                >
                  <Typography
                    className="text-page"
                    style={globalText}
                  >{`Working Hours (Shift)`}</Typography>
                </Button>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

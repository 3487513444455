// REACT COMPONENTS
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Space,
  Typography,
} from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// GLOBAL STYLES
import { cardHeader, mainBody } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import BSReportTable from "../../../../Table/Reports/Accounting/Monthly/BalanceSheet/Main/Table";
import { useReactToPrint } from "react-to-print";

export default function BSReportLayout() {
  const [date, setDate] = useState(null);
  // USE REF
  const componentRef = useRef();
  // USE NAVIGATE
  const navigate = useNavigate();

  // HANDLE SET DATE
  const handleDate = (e) => {
    setDate(e);
  };

  // HANDLE RETURN
  const move = () => {
    navigate(
      "/report/accounting/monthly-accounting-report/balance-sheet-report"
    );
  };

  // HANDLE PRINT
  const handlePrint = () => {
    handlePrintOut();
  };

  // HANDLE REACT PRINT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Balance Sheet Report - ${moment(date).format(
      "DD-MM-YYYY"
    )}`,
    onPrintError: (error) => {
      console.log("Print Error: ", error);
    },
  });

  return (
    <>
      <Row
        // gutter={[30, 30]}
        className="row-body"
        justify="start"
        style={mainBody}
      >
        <Col span={24} className="balance-sheet-main-layout">
          <Row
            className="row-search-component"
            justify="space-between"
            style={cardHeader}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Pick a Date`}
                </Typography>

                <DatePicker
                  picker="month"
                  className="date-pick-bsr"
                  allowClear
                  placeholder="Pick a Date"
                  onChange={handleDate}
                  style={{ width: 250 }}
                />
              </Col>
            </Space>

            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="print-btn"
                  type="primary"
                  icon={
                    <PrinterOutlined
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handlePrint}
                >
                  {`Print BSR`}
                </Button>
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={move}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Card
            ref={componentRef}
            className="bsr-card"
            style={{ padding: 20 }}
            bordered={false}
          >
            <BSReportTable
              // is_printed={isPrinted}
              dataDate={date}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import React from "react";

// ANTD COMPONENTS
import { Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { BsCoin } from "react-icons/bs";

// PAGE COMPONENTS
import BSReportLayout from "../../../../../../../Components/PrintLayout/Accounting/Monthly/BalanceSheetMain/Report";
import { HiScale } from "react-icons/hi2";

export default function BalanceSheetMainPage() {
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <HiScale
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Main Balance Sheet Report`}
              </Typography>
            </Row>

            <BSReportLayout />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_module, sub_children } from "../../API/Global/Payload";

// Import Page Components
import MasterPage from "../../Pages/Configuration/Master/Master";
import GuestCardListPage from "../../Pages/FrontOffice/GuestList/GuestList";
import AllotmentPage from "../../Pages/Master/Allotment/Allotment";
import ArticleMainGroupPage from "../../Pages/Master/ArticleMainGroup/ArticleMainGroup";
import ArticleSubGroupPage from "../../Pages/Master/ArticleSubGroup/ArticleSubGroup";
import ArticleListPage from "../../Pages/Master/ArticleList/ArticleList";
import BSTemplatePage from "../../Pages/Master/BSTemplate/BSTemplate";
import ComplimentArticlePage from "../../Pages/Master/ComplimentArticle/ComplimentArticle";
import CountryPage from "../../Pages/Master/Country/Country";
import LetterPage from "../../Pages/Master/Letter/Letter";
import DepartmentPage from "../../Pages/Master/Department/Department";
import PaymentCardPage from "../../Pages/Master/PaymentCard/PaymentCard";
import PaymentTypePage from "../../Pages/Master/PaymentType/PaymentType";
import RatePeriodicPage from "../../Pages/Master/RatePeriodic/RatePeriodic";
import RoomPage from "../../Pages/Master/Room/Room";
import RoomCategoryPage from "../../Pages/Master/RoomCategory/RoomCategory";
import RateCodePage from "../../Pages/Master/RateCode/RateCode";
import SalesmanPage from "../../Pages/Master/Salesman/Salesman";
import TablePage from "../../Pages/Master/Table/Table";
import TableCategoryPage from "../../Pages/Master/TableCategory/TableCategory";
import MainSegmentPage from "../../Pages/Master/Segment/Segment";
import SourcePage from "../../Pages/Master/Source/Source";
import RoomAvailabilityPage from "../../Pages/Master/RoomAvailability/RoomAvailability";
import OutletLocationPage from "../../Pages/Master/OutletLocation/OutletLocation";
import UOMPage from "../../Pages/Master/UOM/UOM";

import ArrangementPage from "../../Pages/Master/Arrangement/Arrangement";
import ArticleInventoryPage from "../../Pages/Master/ArticleInventory/ArticleInventory";
import ArticleSummaryPage from "../../Pages/Master/ArticleSummary/ArticleSummary";
import GroupMenuPage from "../../Pages/Master/GroupMenu/GroupMenu";

import NotFound from "../../Pages/NotFound/NotFound";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";

// ROUTES
const grandRoute = [
  {
    path: "article-list",
    name: "article-list",
    id_modules: 68,
    element: <ArticleListPage />,
  },
  {
    path: "article-main-group",
    name: "article-main-group",
    id_modules: 69,
    element: <ArticleMainGroupPage />,
  },
  {
    path: "article-sub-group",
    name: "article-sub-group",
    id_modules: 70,
    element: <ArticleSubGroupPage />,
  },
  {
    path: "country",
    name: "country",
    id_modules: 71,
    element: <CountryPage />,
  },
  {
    path: "department",
    name: "department",
    id_modules: 72,
    element: <DepartmentPage />,
  },
  {
    path: "guest-card-file",
    name: "guest-card-file",
    id_modules: 73,
    element: <GuestCardListPage />,
  },
  {
    path: "outlet-location",
    name: "outlet-location",
    id_modules: 74,
    element: <OutletLocationPage />,
  },
  {
    path: "payment-card",
    name: "payment-card",
    id_modules: 75,
    element: <PaymentCardPage />,
  },
  {
    path: "payment-type",
    name: "payment-type",
    id_modules: 76,
    element: <PaymentTypePage />,
  },
  {
    path: "rate-code",
    name: "rate-code",
    id_modules: 77,
    element: <RateCodePage />,
  },
  {
    path: "room-availability",
    name: "room-availability",
    id_modules: 78,
    element: <RoomAvailabilityPage />,
  },
  {
    path: "room-category",
    name: "room-category",
    id_modules: 79,
    element: <RoomCategoryPage />,
  },
  {
    path: "room",
    name: "room-list",
    id_modules: 80,
    element: <RoomPage />,
  },
  {
    path: "salesman",
    name: "salesman",
    id_modules: 81,
    element: <SalesmanPage />,
  },
  {
    path: "main-segment",
    name: "main-segment",
    id_modules: 82,
    element: <MainSegmentPage />,
  },
  {
    path: "sources",
    name: "sources",
    id_modules: 83,
    element: <SourcePage />,
  },
  {
    path: "table-category",
    name: "table-category",
    id_modules: 84,
    element: <TableCategoryPage />,
  },
  {
    path: "table",
    name: "table",
    id_modules: 85,
    element: <TablePage />,
  },
  {
    path: "unit-measurement",
    name: "unit-measurement",
    id_modules: 86,
    element: <UOMPage />,
  },
];

// CODE
export default function MasterRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = grandRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes Master: ", proRoute);
    // console.log("Protected Arr: ", _mods);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        {/* <Route path="/article-summary" element={<ArticleSummaryPage />} /> */}
        <Route path="/" element={<MasterPage />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}

        <Route path="/letter" element={<LetterPage />} />
        <Route path="/allotment" element={<AllotmentPage />} />
        <Route path="/rate-periodic" element={<RatePeriodicPage />} />
        <Route path="/compliment-article" element={<ComplimentArticlePage />} />
        <Route path="/bs-template" element={<BSTemplatePage />} />

        <Route path="/not-found" element={<NotFound />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />
      </Routes>
      <Outlet />
    </>
  );
}

// Import React Components
import React, { useState, useEffect } from "react";

// Import Antd Components
import {
  Card,
  Col,
  Row,
  Form,
  Typography,
  DatePicker,
  InputNumber,
  Button,
  List,
  Divider,
  Skeleton,
  Image,
  Layout,
} from "antd";

// Import Global Styles
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";
import { Content } from "antd/lib/layout/layout";

const { Title } = Typography;

// LIST
const dataList = [
  {
    key: 1,
    title: "7 DAYS ADVANCE PURCHASE, GET 20% OFF ",
    description:
      "Good things come to those who book early. 7 days advance purchase, get 20% off",
    imageSource: "/assets/img/foto-dashboard-1.jpg",
    children: [
      "Not applicable to groups.",
      "Subject to availability.",
      "Terms and conditions apply",
    ],
  },
  {
    key: 2,
    title: "BEST PICKS",
    description:
      "Take advantage of exceptional offers with added benefits including: 2 pax of Lunch/Dinner bento box for first night only, Complimentary 1x Arrival OR Departure Airport Transfer (max 2 adult and 2 child below 12 years old /car), Free 1 dessert/snacks at hotel restaurant",
    imageSource: "/assets/img/foto-dashboard-2.jpg",
    children: [
      "Complimentary benefits (‘picks’) are subject to change without notice.",
      "Picks cannot be exchanged for cash.",
      "Subject to availability.",
      "Terms and conditions apply.",
    ],
  },
  {
    key: 3,
    title: "SCHOOL HOLIDEALS",
    description:
      "20% OFF! Big savings for big memories, Including Breakfast for 2 persons, Valid for reservations via the hotel website and direct reservations via phone/email only (not valid for walk-ins)",
    imageSource: "/assets/img/foto-dashboard-3.jpg",
    children: [
      "Including Breakfast for 2 persons",
      "10% discount on Laundry with min purchase IDR 50.000",
      "10% discount on F&B / restaurant that was managed by the hotel with min purchase IDR 100.000",
      "Complimentary breakfast for 1 child below 12 years old (sharing the bed with parents).",
      "Late check-out until 03.00 pm (based on hotel room availability).",
      "Valid for reservations via the hotel website and direct reservations via phone/email only (not valid for walk-ins).",
    ],
  },
];

// CODE
export default function ManagerDashboard() {
  // STATE MANAGEMENT
  const [hidden, setHidden] = useState(true);
  const [selected, setSelected] = useState(null);
  const [list, setList] = useState([]);
  // Loading
  const [loading, setLoading] = useState(true);
  //   const [first, setfirst] = useState();

  // Create List
  const createList = (value) => {
    const select = value;

    // console.log(select);

    const list = select.children.map((items) => {
      //   console.log(items);
      return items;
      //   setList(<li>{items}</li>);
    });

    // console.log(list);
    setList(list);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  //   console.log(selected);

  return (
    <>
      <Row style={mainBody} className="row-main-dashboard" hidden={!hidden}>
        <Col span={24} style={{ padding: 30 }}>
          <Row>
            <Title level={2} className="text-dashboard">
              Dashboard Info
            </Title>
          </Row>

          <Divider
            className="divider-form"
            style={{
              backgroundColor: "#000000",
              margin: "5px 0px",
            }}
          />

          <Row className="row-dashboard">
            <Col span={24}>
              <List
                className="demo-loadmore-list"
                itemLayout="vertical"
                // loading={true}
                // loadMore={loadMore}
                dataSource={dataList}
                renderItem={(item) => (
                  //   {
                  //   console.log(item);
                  // }}
                  <List.Item
                    actions={[
                      <Button
                        key={item.key}
                        className="list-loadmore-more"
                        type="primary"
                        onClick={(e) => {
                          console.log(item.key);
                          setHidden(!hidden);
                          setSelected(item);
                          createList(item);
                        }}
                      >
                        {`Details >>>`}
                      </Button>,
                    ]}
                    extra={
                      <img
                        style={{ maxWidth: 272 }}
                        width="100%"
                        alt="logo"
                        // src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                        src={item.imageSource}
                      />
                    }
                  >
                    <Skeleton loading={loading} active className="skeleton">
                      <List.Item.Meta
                        // avatar={<Avatar src={item.picture.large} />}
                        title={
                          <Typography
                            className="text-item"
                            style={{
                              fontSize: 22,
                              fontWeight: 750,
                            }}
                          >
                            {item.title}
                          </Typography>
                        }
                        // description={item.description}
                      />
                      {item.description}
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Content className="row-details" hidden={hidden}>
        <Row className="row-title" justify="start" style={cardHeader}>
          <Typography
            style={{
              margin: 0,
              padding: "5px 30px",
              fontSize: 20,
              fontWeight: 750,
            }}
          >
            Special Offer - Info & Detail
          </Typography>
        </Row>

        <Row className="row-search-component" justify="start" style={cardBody}>
          <Col
            className="col-searh"
            style={{
              textAlign: "start",
              padding: "10px 30px 5px",
              margin: 0,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            <Typography style={{ marginBottom: 10 }}>
              {hidden == false ? selected.title : ""}
            </Typography>
          </Col>
        </Row>

        <Row className="row-search-component" justify="start" style={mainBody}>
          <Col span={18} style={{ padding: 10 }}>
            <Image
              className={`img-${selected?.key || 0}`}
              src={hidden == false ? selected.imageSource : ""}
              width="100%"
              alt="logo"
              preview={false}
              style={{
                margin: "0px 0px 30px",
              }}
            />

            <Typography
              style={{
                margin: "10px 0px 0px",
                fontWeight: 750,
              }}
            >
              {hidden == false ? selected.title : ""}
            </Typography>
            <Typography>
              {hidden == false ? selected.description : ""}
            </Typography>

            <Typography
              style={{
                margin: "10px 0px 0px",
                fontWeight: 750,
              }}
            >{`Rate includes: `}</Typography>
            {hidden == false && (
              <ul className="list-data">
                {list.map((it) => {
                  return <li key={it}>{it}</li>;
                })}
              </ul>
            )}

            <Typography
              style={{
                fontWeight: 750,
                margin: "10px 0px 0px",
              }}
            >
              {`Room types available for this special offer:`}
            </Typography>
            <Typography>{`Superior Room, Deluxe Room, Executive Room`}</Typography>

            <Typography
              style={{
                fontWeight: 750,
                margin: "10px 0px 0px",
              }}
            >{`Suite Meal plans: `}</Typography>
            <Typography>{`Breakfast Included`}</Typography>

            <Button
              className="back-btn"
              onClick={() => {
                setHidden(!hidden);
              }}
              type="primary"
              style={{
                margin: "30px 0px",
              }}
            >{`<<< Back`}</Button>
          </Col>

          <Col
            span={6}
            style={{
              padding: 10,
              backgroundColor: "#f0f0f0",
              borderRadius: "0px 5px 5px 0px",
            }}
          >
            <Typography
              className="text-poliices"
              style={{
                fontWeight: 750,
                margin: "0px 0px 10px",
              }}
            >
              Cancellation Policies
            </Typography>

            <Typography>
              - Not allowed for cancellations at any time.
            </Typography>

            <Typography>
              - Failure to arrive at your hotel will be treated as a No-Show and
              no refund will be given.
            </Typography>
          </Col>
        </Row>
      </Content>
    </>
  );
}

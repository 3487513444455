export const CheckType = (types) => {
  console.log("Check: ", types);
  let msg = "";

  switch (types) {
    case 0:
      msg = "Create";
      break;

    case 1:
      msg = "Update";
      break;

    case 2:
      msg = "Delete";
      break;

    case 3:
      msg = "Blocked";
      break;

    case 4:
      msg = "Un-Blocked";
      break;

    case 5:
      msg = "Approved";
      break;

    case 6:
      msg = "Rejected";
      break;

    case 7:
      msg = "Freeze";
      break;

    case 8:
      msg = "Get";
      break;

    case 9:
      msg = "Executed";
      break;

    default:
      break;
  }

  return msg;
};

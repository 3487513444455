// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { sub_children } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiOpenBook } from "react-icons/gi";
import {
  AiFillAppstore,
  AiFillFolderOpen,
  AiOutlineAppstore,
  AiFillContainer,
} from "react-icons/ai";
import {
  BsCalculatorFill,
  BsDoorOpenFill,
  BsFillClipboardCheckFill,
  BsFillPersonBadgeFill,
  BsJournalCheck,
  BsTable,
} from "react-icons/bs";
import { VscInbox, VscLibrary, VscServer } from "react-icons/vsc";
import { GoPackageDependents } from "react-icons/go";
import { CiBoxes } from "react-icons/ci";
import { RiInboxArchiveFill } from "react-icons/ri";

// Import Global Styles
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../Style/Global/Global";
import { FaParachuteBox } from "react-icons/fa";
import { FaCheckToSlot } from "react-icons/fa6";

// List
const listCard = [
  {
    path: "receiving",
    title: "Stock Incoming",
    id_modules: 52,
    icon: <VscInbox style={globalIcon} />,
  },
  {
    path: "approval-receiving",
    title: "Stock Incoming Approval",
    id_modules: 49,
    icon: <FaCheckToSlot style={globalIcon} />,
  },
  {
    path: "warehouse",
    title: "General Store",
    id_modules: 53,
    icon: <VscServer style={globalIcon} />,
  },
  {
    path: "transfer-request",
    title: "Stock Transfer/Request",
    id_modules: 54,
    icon: <GoPackageDependents style={globalIcon} />,
  },
  {
    path: "stock-opname",
    title: "Stock Opname",
    id_modules: 55,
    icon: <BsJournalCheck style={globalIcon} />,
  },
  // {
  //   path: "stock-outgoing",
  //   title: "Stock Outgoing",
  //   id_modules: 51,
  //   icon: <FaParachuteBox style={globalIcon} />,
  // },
  {
    path: "stock-on-hand",
    title: "Stock On Hand",
    id_modules: 56,
    icon: <CiBoxes style={globalIcon} />,
  },
  {
    path: "transfer-request-approval",
    title: "Transfer/Request Approval",
    id_modules: 103,
    icon: <BsFillClipboardCheckFill style={globalIcon} />,
  },
  {
    path: "transfer-request-receiving",
    title: "Transfer/Request Receiving",
    id_modules: 104,
    icon: <RiInboxArchiveFill style={globalIcon} />,
  },
  {
    path: "standard-recipe",
    title: "Standard Recipe",
    id_modules: 105,
    icon: <AiFillContainer style={globalIcon} />,
  },
];

// CODE
export default function InventoryPage() {
  // STATE MANAGEMENT
  // List Items
  const [listItems, setlistItems] = useState([]);

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  // USE EFFECTS
  useEffect(() => {
    authFilter();
  }, []);

  // HANDLE SEARCH
  const authFilter = () => {
    const grandModule = sub_children?.length > 0 ? sub_children : [];

    const list = listCard.map((record) => {
      if (grandModule.includes(record.id_modules)) {
        return (
          <Col
            xxl={8}
            xl={8}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className={record.id_modules + "-col"}
            key={record.id_modules}
          >
            <Card key={record.title} className="card-link" style={gridStyle}>
              <Button
                className="move-btn"
                onClick={() => move(record.path)}
                icon={record.icon}
                style={linkStyle}
              >
                <Typography className="text-page" style={globalText}>
                  {record.title}
                </Typography>
              </Button>
            </Card>
          </Col>
        );
      } else {
        return null;
      }
    });

    // console.log("Grand Children Modules: ", sub_children);
    // console.log("Filtered: ", list);

    setlistItems(list);
  };

  // console.log("You are in config master");

  return (
    <Layout>
      <Content>
        <Col span={24} className="report-main-layout">
          <Row
            className="row-title"
            justify="start"
            style={{
              backgroundColor: "#FFFFFF",
              margin: "0px 0px 30px",
              borderRadius: 5,
            }}
          >
            <AiFillFolderOpen
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Inventory`}
            </Typography>
          </Row>

          <Row
            // gutter={[30, 30]}
            className="row-body"
            justify="start"
            style={mainBody}
          >
            {listItems}
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/InventoryContext/InventoryContext";

// Import Ant Design Components
import {
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Typography,
  DatePicker,
  Tag,
} from "antd";

// Import Modal
import SOCreateModal from "../../../../Modals/Inventory/StockOpname/ModalCreate";
import SODetailModal from "../../../../Modals/Inventory/StockOpname/ModalDetail";

// Import Global Styles
import { cardHeader } from "../../../../../Style/Global/Global";

// Import React Icons
import {
  FileExcelFilled,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Functions
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// Confirm Modal
const { confirm } = Modal;

// CODE
export default function StockOpnameTable(props) {
  const { id_storage } = props;
  // CONTEXT
  const { getStockOpname } = useContext(MasterContext);
  
  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [date, setDate] = useState(null);
  const [datePrint, setDatePrint] = useState(null);
  const [modalDate, setModalDate] = useState(null);
  const [soStatus, setSoStatus] = useState(null);

  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [soDetail, setSoDetail] = useState([]);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getStockOpname({
      source: "stock-opname/header",
      id_warehouse: id_storage,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Article Stock Opname => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _sort = _res.sort((a, b) => {
            return b.id - a.id;
          });

          let mapData = _sort.map((item) => {
            return {
              ...item,
              storage_name: item.warehouse_name
            }
          })

          setData(mapData);
          setBulks(mapData);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, [id_storage]);

  useEffect(() => {
    console.log("Data Article >> ", data);
  }, [data]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={24} className="col-edit">
                <Button
                  type="primary"
                  className="submit-btn"
                  key={"check-in-btn"}
                  style={{ backgroundColor: "#1BC5BD", borderColor: "#1BC5BD" }}
                  onClick={() => {handleOpenDetail(record);
                    console.log("RECORD DETAIL >> ", record)}
                  }
                >
                  {`Detail`}
                </Button>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // width: 300,
      // fixed: "left",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_name - b.article_name,
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Storage Name",
      dataIndex: "storage_name",
      key: "storage_name",
      // width: 300,
      // fixed: "left",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_name - b.article_name,
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      // width: 175,
      // fixed: "left",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      // width: 175,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_main_name - b.article_main_name,
      render: (date) => {
        return moment(date).format("DD MMM YYYY");
      },
    },
    {
      title: "Start Date",
      dataIndex: "so_start_date",
      key: "so_start_date",
      // width: 175,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_main_name - b.article_main_name,
      render: (date) => {
        if (date) {
          return moment(date).format("DD MMM YYYY HH:mm");
        } else {
          return "-";
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "so_end_date",
      key: "so_end_date",
      // width: 175,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_main_name - b.article_main_name,
      render: (date) => {
        if (date) {
          return moment(date).format("DD MMM YYYY HH:mm");
        } else {
          return "-";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "so_status",
      key: "so_status",
      // width: 175,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.article_main_name - b.article_main_name,
      render: (x) => {
        return (x == '1') ? <Tag color="#3b5999">{`Planned`}</Tag>
        : (x == '2') ? <Tag color="#1890ff">{`On Process`}</Tag>
        : (x == '3') ? <Tag color="#87d068">{`Completed`}</Tag>
        : 'others'
      },
    },
  ];

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenDetail = (record) => {
    setOpenDetail(true);
    setModalDate(moment());
    setSoDetail(record);
    setSoStatus(record.so_status);
    console.log("Property Detail >> ", record);
  };

  useEffect(() => {
    console.log("Open Parameter: ", open);
  }, [open]);

  // Handle Close
  const handleClose = (val) => {
    setOpen(false);
    setOpenDetail(false);
    setSoDetail([]);
    setSoStatus(null);
    setEdit(false);
  };

  // Handle Refresh
  const handleRefresh = () => {
    fetchData();
    setDatePrint(null);
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);

    downloadExcel(data, `Stock Opname`);
  };

  const handleDate = (val) => {
    const value = moment(val).format("YYYY-MM-DD");
    // e.preventDefault();

    console.log("Date: ", value);

    setDate(value);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryDate =
      date != "Invalid date" && date != null
        ? moment(date).format("YYYY-MM-DD")
        : null;
    setDatePrint(queryDate);

    console.log("Search Date: ", date);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const dep = moment(item?.created_date).format("YYYY-MM-DD");

        if (queryDate == null) {
          return item;
        } else {
          return moment(dep).isSame(queryDate);
        }
      });

      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="row-title" justify="start" style={cardHeader}>
        <Typography style={{ margin: "22px 30px", fontWeight: 500 }}>
          {`Stock Opname History`}
        </Typography>
      </Row>
      <Row className="row-title" justify="start" style={cardHeader}>
        <Space
          align="end"
          size={15}
          className="header-space"
          wrap={true}
          style={{
            padding: "10px 30px 20px 30px",
          }}
        >
          <Col
            style={{
              textAlign: "start",
              // margin: "22px 30px",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            <Typography style={{ marginBottom: 10 }}>{`Date`}</Typography>

            <DatePicker
              allowClear
              placeholder="Date"
              className="pr-datepicker"
              onChange={handleDate}
              style={{
                minWidth: 150,
                maxWidth: 250,
              }}
            />
          </Col>

          <Col
            style={{
              textAlign: "start",
              // margin: "22px 30px",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            <Button
              className="search-btn"
              type="primary"
              icon={<SearchOutlined />}
              onClick={handleSearch}
            >
              {`Search`}
            </Button>
          </Col>
        </Space>
      </Row>
      <Row className="article-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="btn-row" justify="start" align="top" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row
                justify="end"
                gutter={[0, 15]}
                align="top"
                className="row-btn"
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Create New Stock Opname`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="art-list-table"
              name="art-list-table"
              key="art-list-table"
              loading={loading}
              columns={columns}
              // dataSource={data}
              dataSource={data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
      <SOCreateModal
        is_open={open}
        is_close={handleClose}
        _id_storage={id_storage}
        is_refresh={handleRefresh}
      />
      <SODetailModal
        is_open={openDetail}
        is_close={handleClose}
        soDetail={soDetail}
        modalDate={modalDate}
        is_refresh={handleRefresh}
      />
    </>
  );
}

// Import React's Components
import React, { useState } from "react";

// Import Ant Design Components
import { Col, Input, Layout, Row, Space, Typography, Button } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdOutlineManageAccounts } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";

// Import Page Components
import AccessControlTable from "../../../Components/Table/Auth/AccessControl/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function AccessControlPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState(null);

  const handleSearch = (val) => {
    val.preventDefault();

    const _value = val.target.value;

    setValue(_value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="users-main-layout">
            <Row className="users-title" justify="start" style={cardHeader}>
              <MdOutlineManageAccounts
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`User's Control Access`}
              </Typography>
            </Row>

            <Row className="search-component" justify="start" style={cardBody}>
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    // width: 205,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Keywords`}</Typography>

                  <Input
                    allowClear
                    placeholder="User's Name"
                    onChange={handleSearch}
                    style={{ minWidth: 150 }}
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={search}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>
          </Col>

          <AccessControlTable is_search={setSearch} searchKey={value} />
        </Content>
      </Layout>
    </>
  );
}

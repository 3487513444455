// REACT COMPONENTS
import React, { useEffect, useState } from "react";

// ANTD COMPONENTS
import { Col, Modal, Row, Table, Typography } from "antd";

// REACT ICONS
import { MdOutlineWorkHistory } from "react-icons/md";

// Import Page COMPONENTS
import { PriceTax } from "../../../Reusable/Functions/PriceTax/PriceTax";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";

export default function ModalSupplierTurnoverArticle(props) {
  // PROPS
  const { is_open, is_close, dataArticle, params_acc } = props;

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [totalPrice, setTotalPrice] = useState({
    sub_total: 0,
    vat_total: 0,
    total: 0,
  });
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Modals
  const [modalOpen, setModalOpen] = useState(false);

  // Tax
  const _tax = params_acc?.tax_percentage / 100;

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open && !cleanUp) {
      handleOpen();
      setData(dataArticle);
      handleTotal(dataArticle);
    } else {
      setModalOpen(false);
      is_close(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // HANDLE CLOSE MODAL
  const handleClose = () => {
    is_close(false);
    setModalOpen(false);

    setIsLoading(false);
    setData([]);
  };

  // HANDLE OPEN MODAL
  const handleOpen = () => {
    setModalOpen(true);
  };

  // Handle Total
  const handleTotal = (val) => {
    const _arr = val;
    console.log("Data: ", _arr);

    let total_tax = PriceTax({
      arr_article: _arr,
      tax_percent: _tax,
    });

    console.log("Price: ", total_tax);

    setTotalPrice({
      sub_total: total_tax.sub_total,
      vat_total: total_tax.vat_total,
      total: total_tax.total,
    });
  };

  // Columns
  const columns = [
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price, record) => {
        return `${unit_price > 0 ? unit_price : 0}*`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "D-Unit",
      dataIndex: "d-unit",
      key: "d-unit",
    },
    {
      title: "Qty Order",
      dataIndex: "quantity_order",
      key: "quantity_order",
      render: (quantity_order, record) => {
        return `${quantity_order}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Qty Remaining",
      dataIndex: "si_quantity_remaining",
      key: "si_quantity_remaining",
      render: (si_quantity_remaining, record) => {
        return `${si_quantity_remaining}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      render: (total_price, record) => {
        return `${total_price > 0 ? total_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Desc",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Modal
        className="vendor-management-modal"
        key="vendor-management-modal"
        title={
          <Row className="modal-title-row" justify="start" align="middle">
            <MdOutlineWorkHistory
              className="modal-icon"
              style={{
                color: "#3699FF",
                fontSize: 24,
              }}
            />
            <Typography
              className="modal-txt"
              style={{ marginLeft: 15 }}
            >{`Detail Articles`}</Typography>
          </Row>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={1000}
      >
        <Row className="table-main-row" justify="start" style={mainBody}>
          <Col span={24} style={{ padding: 30 }}>
            <Row style={{ width: "100%" }}>
              <Table
                className="vendor-management-table"
                name="vendor-management-table"
                key="vendor-management-table"
                loading={isLoading}
                bordered={true}
                size="small"
                columns={columns}
                dataSource={data}
                pagination={false}
                //   defaultPageSize: 5,
                //   pageSizeOptions: [10, 20, 50, 100],
                //   showSizeChanger: true,
                //   showTotal: (total, range) =>
                //     `Showing ${range[0]}-${range[1]} of ${total} entries`,
                // }}
                scroll={{
                  x: true,
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
                rowKey={(record) =>
                  record.id_article ? record.id_article : record.key
                }
                summary={() => {
                  return (
                    <>
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} />

                          <Table.Summary.Cell index={0}>
                            <b
                              style={{ fontSize: 12, alignSelf: "end" }}
                            >{`Sub-Total`}</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4} colSpan={2}>
                            <b style={{ fontSize: 12 }}>
                              {`${currency} ${totalPrice.sub_total}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} />

                          <Table.Summary.Cell index={3}>
                            <b style={{ fontSize: 12 }}>{`VAT`}</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4} colSpan={2}>
                            <b style={{ fontSize: 12 }}>
                              {`${currency} ${totalPrice.vat_total}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} />

                          <Table.Summary.Cell index={3}>
                            <b style={{ fontSize: 12 }}>{`Grand Total`}</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4} colSpan={2}>
                            <b style={{ fontSize: 12 }}>
                              {`${currency} ${totalPrice.total}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    </>
                  );
                }}
              />
            </Row>

            <Typography className="text-notes" style={{ fontSize: 10 }}>
              {`* Prices before VAT`}
            </Typography>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

// Import React Components
import React from "react";

// Import Ant Design Components
import { Col, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Page Components
import RoomOccupancyOverview from "../../../Components/Widgets/HouseKeeping/Occupancy";
import ArrivalDeparture from "../../../Components/Widgets/HouseKeeping/ArrivalDeparture";
import HouseKeepingActivity from "../../../Components/Widgets/HouseKeeping/Activity";

// Style
const style = {
  background: "#FFFFFF",
  marginRight: "30px",
  height: "100%",
};

// CODE
export default function RoomMonitorPage() {
  return (
    <>
      <Layout className="room-dashboard-layout">
        <Content className="room-dashboard-content">
          <Row
            justify="space-evenly"
            gutter={[10, 30]}
            className="row-room-monitoring"
          >
            {/*
            <Col span={8}>
              <div style={style}>Menu 1</div>
            </Col>
            <Col span={8}>
              <div style={style}>Menu 2</div>
            </Col>

            <Col span={8}>
              <Row
                justify="center"
                style={{
                  backgroundColor: "#FFFFFF",
                  marginBottom: 30,
                  height: 500,
                }}
              >
                Menu 3
              </Row>

              <Row
                justify="center"
                style={{ backgroundColor: "#FFFFFF", height: 500 }}
              >
                Menu 4
              </Row>
            </Col>
          */}
            <Col span={24} className="col-room-occupancy">
              <RoomOccupancyOverview />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="col-arrival-departure"
            >
              <ArrivalDeparture />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="col-house-activity"
            >
              <HouseKeepingActivity />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

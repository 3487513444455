// CODE
export default function GroupingBill(props) {
  // PROPS
  const { dataArticle, tax_name, service_name, bill_type } = props;

  // Array Buckets
  var _merge = [];
  let _all = [];

  console.log("PROPS: ", props);

  // GROUP BY ARTICLE ID
  const _filtered = Object.groupBy(dataArticle, (items) => items.article_id);

  console.log("Res Group By: ", _filtered);

  // FIND MAIN ARTICLES
  for (const key in _filtered) {
    const element = _filtered[key];
    // console.log("ELEMENTS: ", element);

    let _newObj = {};

    const res = element.forEach(function (_objects, _index) {
      for (let _keys in _objects) {
        const _article_names = _objects?.article_name
          ? _objects.article_name.toLowerCase()
          : null;

        if (_keys.includes("article_name") && _article_names) {
          _newObj[_objects[_keys]] = _objects?.article_name;
          // _newObj[_keys + "_" + (_index + 1)] = _objects[_keys];
        }

        if (_objects[_keys] != null) {
          if (_article_names.includes(tax_name)) {
            _newObj["total_tax"] = _objects["price"];
            _newObj["tax"] = _objects["price"] / _objects["quantity"];
            _newObj["coa_tax"] = _objects["chart_of_account"];
            _newObj["article_tax_name"] = _objects["article_name"];
          } else if (_article_names.includes(service_name)) {
            _newObj["total_service"] = _objects["price"];
            _newObj["service"] = _objects["price"] / _objects["quantity"];
            _newObj["coa_service"] = _objects["chart_of_account"];
            _newObj["article_service_name"] = _objects["article_name"];
          } else {
            _newObj[_keys] = _objects[_keys];
            _newObj["unit"] = _objects["price"] / _objects["quantity"];
            _newObj["total"] = _objects["price"];
            _newObj["id"] = _objects["article_id"];
            _newObj["is_deleted"] = _objects["is_deleted"];
            _newObj["coa_article"] = _objects["chart_of_account"];
            _newObj["quantity"] = _objects["quantity"];
          }
        }
      }
    });

    // console.log("Res Reduces: ", res);
    // console.log("New Object: ", _newObj);

    _merge.push(_newObj);
  }

  let _new = _merge.map((items) => {
    return {
      ...items,
      total_price: bill_type
        ? items.total
        : items.total + items?.total_service + items?.total_tax,
      unit_price: bill_type
        ? items.unit
        : items.unit + items?.service + items?.tax,
    };
  });

  // console.log("Total Price: ", _new);
  _all = _new;

  return _all;
}

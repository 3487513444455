// Import React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import { baseurl } from "../../API/Config/Api";

// Import Ant Design Components
import { Button, Drawer, List, Timeline, Tree, Typography } from "antd";

// Import Functions
import { VersionControl } from "../../Components/Reusable/Functions/VersionControl/VersionControl";

// Import Notifications
import { failedFetch } from "../../Components/Reusable/Notification/Notification";

// CODE
export default function DrawerVersions(props) {
  // PROPERTIES
  const { is_open, is_close, list_data, version } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [vers, setVers] = useState(null);
  const [element, setElement] = useState([]);
  // Open State
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Handle Close
  const handleClose = () => {
    setOpen(false);
    is_close(false);
  };

  // Fetch Data
  const fetchData = async () => {
    await axios
      .get(`${baseurl}/version/web-changelog`)
      .then((response) => {
        console.log("Response Version: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];
          const _data = response.data.msg;

          console.log("Results: ", _data);

          setVers(_res?.version);
          setData(_data);

          handleFilter({
            version: _res?.version,
            array: _data,
          });
        } else {
          setVers("1.0.0");
          setData([]);
        }
        // setLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log(err);
        failedFetch(err);
        // setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("IS DRAWER: ", is_open);

    if (is_open == true && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // Handle Move
  const handleMove = () => {
    setIsLoading(true);
    const path = "/changelog-versions";

    setTimeout(() => {
      navigate(path);
      setOpen(false);
      setIsLoading(false);
    }, 1500);
  };

  // Handle Filter
  const handleFilter = (val) => {
    const _filtered = val.array.filter((items) => {
      return items.version.includes(val.version);
    });

    console.log("Filtered Version: ", _filtered);

    const _elements = VersionControl({ array: _filtered });
    console.log("Version Elements: ", _elements);

    setElement(_elements);
  };

  return (
    <>
      <Drawer
        title="Changelog Versions"
        placement="right"
        onClose={handleClose}
        open={open}
        className="ver-drawer"
        closable
        size={"large"}
        extra={
          <Button
            className="nav-btn"
            name="nav-btn"
            key="nav-btn"
            type="link"
            onClick={handleMove}
            loading={isLoading}
          >{`Full Changelog`}</Button>
        }
      >
        <Timeline className="version-timeline">
          <Timeline.Item>
            <Typography.Title level={3}>{vers}</Typography.Title>
            <p>{`Stable version ${vers}`}</p>

            <Tree
              className="version-tree"
              showIcon
              showLine
              // allowDrop={false}
              titleRender={(record) => (
                <span
                  key={record?.key}
                  // onClick={() => toggleExpandNode(record.key)}
                  style={{}}
                >
                  {record?.title}
                </span>
              )}
              autoExpandParent={true}
              defaultExpandAll={true}
              // defaultSelectedKeys={[element[0]?.key]}
              // switcherIcon={<GoDotFill />}
              treeData={element}
            />
          </Timeline.Item>
        </Timeline>
      </Drawer>
    </>
  );
}

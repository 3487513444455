// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tooltip,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import MasterRoomCat from "../../../Reusable/Master/RoomCat/RoomCat";
import MasterSegment from "../../../Reusable/Master/Segment/Segment";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { Grossing } from "../../../Reusable/Functions/GrossBreakdown/Grossing";
import { TaxesParameter } from "../../../Reusable/Functions/Taxes/Taxes";
import { AccountingParams } from "../../../Reusable/Functions/Parameters/ParamsAcc";
import { Pricing } from "../../../Reusable/Functions/Pricing/Pricing";

// Import Master Notification
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function RateCodeTable(props) {
  // PROPS
  const {
    // SEGMENT
    // dataRate,
    segmentation,
    // Fetch
    is_fetch,
    // segmentId,
    getRateCode,
    resetSearch,
    // Render
    is_rerender,
  } = props;

  // CONTEXT
  const { getMasterRateCode, getMasterRateSegment } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // Params
  const currency = CurrencySymbols().code;
  const taxes = TaxesParameter();
  const accounting = AccountingParams();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Edit
  const [editData, setEditData] = useState(null);
  const [rows, setRows] = useState(null);
  // Segment
  const [segmentName, setSegmentName] = useState(null);
  const [segmentID, setSegmentID] = useState(null);
  // Rate
  const [rateCode, setRateCode] = useState(null);
  // Button
  const [disable, setDisable] = useState(true);
  // Loading
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [percentages, setPercentages] = useState({
    tax: taxes?.tax_percentage,
    service: taxes?.service_percentage,
  });

  // Modal
  const [isDeleted, setIsDeleted] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-rate-code-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();

                      setEdit(true);
                      setEditData(record);

                      // form.setFieldsValue({ ...record });
                      setFormFields(record);
                      setIsDeleted(false);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      setIsDeleted(true);
                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category_name",
      key: "room_category_name",
    },
    {
      title: "Rate Code",
      dataIndex: "rate_code",
      key: "rate_code",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.rate_code.localeCompare(b.rate_code),
    },
    {
      title: "Total Breakfast",
      dataIndex: "room_breakfast",
      key: "room_breakfast",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.room_breakfast - b.room_breakfast,
    },
    {
      title: "Occupancy",
      dataIndex: "room_occupancy",
      key: "room_occupancy",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.room_occupancy - b.room_occupancy,
    },
    {
      title: "Nett",
      dataIndex: "nett",
      key: "nett",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.nett - b.nett,
      render: (price, record) => {
        return price > 0
          ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "0,00";
      },
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.gross - b.gross,
      render: (price, record) => {
        return price > 0
          ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "0,00";
      },
    },
    {
      title: "Descriptions",
      dataIndex: "description",
      key: "description",
    },
  ];

  // Fetch Data
  const fetchData = async (value, key) => {
    // console.log(segmentID);
    // console.log(value);
    setDisable(false);

    await getMasterRateSegment({
      segment_compl: "master-segment",
      segment:
        value?.main_segment_id > 0
          ? value?.main_segment_id
          : segmentName?.main_segment_id,
      // sub_segment_id: segmentID?.sub_id > 0 ? segmentID?.sub_id : segmentId?.sub_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Rate Code => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
        setRows(null);
        is_fetch(false);
        // resetSearch(false);
      },
      onReject: (error) => {
        console.log("Error = ", error);

        setLoading(false);
        failedFetch(error);
        // resetSearch(false);
      },
    });
  };

  // USE EFFECT Search
  useEffect(() => {
    let cleanUp = false;
    // console.log(segmentation);
    // console.log(segmentId);

    // if (segmentId?.main_id > 0 || segmentId?.sub_id > 0) {
    if (segmentation && !cleanUp) {
      console.log("ITS HITS ", segmentation);

      setSegmentName(segmentation);
      // setSegmentID(segmentId);

      fetchData(segmentation);
    }

    // if (dataRate) {
    //   setDisable(false);
    //   setData(dataRate);
    // }

    return () => {
      cleanUp = true;
    };
  }, [segmentation]);

  useEffect(() => {
    // let cleanUp = false;

    if (segmentName) {
      // console.log(segmentName);
      getSegment();
    }

    // return () => {
    //   cleanUp = true;
    // };
  }, [segmentName]);

  // Handle Row Selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);

      setRateCode(selectedRows);
      setRows(selectedRowKeys);
      getRateCode(selectedRows, segmentName);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: rows,
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDeleted(false);

    getSegment();
  };

  // Set Form Fields
  const setFormFields = (value) => {
    const _record = value;

    form.setFieldsValue({
      rate_code: _record?.rate_code,
      main_segment_id: _record?.main_segment_id,
      main_segment_code: _record?.main_segment_code,
      main_segment_name: _record?.main_segment_name,

      room_category_code: _record?.room_category_code,
      room_category_name: _record?.room_category_name,
      room_category_id: _record?.room_category_id,
      room_breakfast: _record?.room_breakfast,
      room_occupancy: _record?.room_occupancy,
      nett: _record?.nett,
      service: parseInt(_record?.service),
      tax: parseInt(_record?.tax),
      gross: _record?.gross,

      description: _record?.description,

      created_by: _record?.created_by,
      created_date: _record?.created_date,
      updated_by: _record?.updated_by,
      updated_date: _record?.updated_date,
    });
  };

  // GET SEGMENT
  const getSegment = async () => {
    // console.log(segmentName);

    form.setFieldsValue({
      main_segment_name: segmentName?.main_segment_name || "Not Selected",
      main_segment_id:
        segmentName?.main_segment_id || segmentation?.main_segment_id,
      main_segment_code:
        segmentName?.main_segment_code || segmentation?.main_segment_code,
      // sub_segment: segmentName?.sub_segment || "Not Selected",
      // sub_segment_id: segmentID?.sub_id || segmentId?.sub_id,
      compliment_type: "No Compliment",
    });
  };

  // Get Room Category Code
  const onChangeRoomCat = (value, code) => {
    const _value = value;
    console.log("Room Code: ", _value);
    // console.log(code);

    form.setFieldsValue({
      room_category_name: _value?.name,
      room_category_code: _value?.code,
      room_category_id: _value?.cat_id,
    });
  };

  // SET GROSS PRICE
  const setGross = async (netto) => {
    const _service = percentages.service / 100;
    const _tax = percentages.tax / 100;
    let _net = netto;
    let _gross = 0;

    // console.log("Nett Price = ", _net);

    let _srv = parseInt(_net * _service);
    let _taxs = parseInt((_net + _srv) * _tax);

    _gross = _srv + _taxs + _net;

    // console.log("Gross Price = ", _gross);

    let _newPrice = Pricing({
      nett: _net || 0,
      tax: percentages.tax || 0,
      service: percentages.service || 0,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient,
    });

    console.log("Gross Price Results = ", _newPrice);

    form.setFieldsValue({
      gross: _newPrice.gross,
      tax: _newPrice.taxes,
      service: _newPrice.services,
    });
  };

  // Handle Gross
  const handleGross = (val) => {
    const _gross = val;

    console.log("Gross: ", _gross);
    console.log("Gross: ", accounting);

    const _grossing = Grossing({
      gross: _gross,
      tax: percentages.tax,
      service: percentages.service,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient_tax_service,
    });

    console.log("Results: ", _grossing);

    // handleGrossing(_grossing.nett, isService, isTax);
    form.setFieldsValue({
      nett: _grossing.nett,
      tax: _grossing.taxes,
      service: _grossing.services,
    });
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setEditData(null);
    setIsDeleted(true);

    setButtonLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    setButtonLoading(true);

    const _content = e;
    let str = _content.rate_code;

    console.log(_content);
    // handleSubmit(e);

    _content.rate_code = str.toUpperCase();

    if (!_content.description) {
      _content.description = `${
        _content?.room_category_name || "GROUP"
      } ROOM for ${_content.room_occupancy} Adult(s) and ${
        _content.room_breakfast
      } Pax Breakfast(s)`;
    }

    showModalConfirm(_content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // SHOW MODAL DELETE
  const showModalConfirm = async (record) => {
    let deleteContent = record;

    console.log("In Modal = ", deleteContent);

    confirm({
      className: "delete-guest-confirm",
      title:
        isDeleted == true
          ? `Are you sure want to delete Rate Code named ${deleteContent?.rate_code.toUpperCase()} from the table?`
          : `Are you sure want to ${edit == true ? "Update a " : "Add a New "}
          Rate Code named ${deleteContent?.rate_code.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDeleted == true
          ? handleDelete(deleteContent)
          : handleSubmit(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setButtonLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentSubmit = e;

    console.log("ON SUBMIT ==> ", contentSubmit);
    // console.log("POST OR PUT ==> ", _obj);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/room/rate-code`,
          {
            rate_code: contentSubmit.rate_code,
            main_segment_id:
              contentSubmit?.main_segment_id > 0
                ? contentSubmit.main_segment_id
                : segmentName?.main_segment_id,
            main_segment_code:
              contentSubmit?.main_segment_code ||
              segmentName?.main_segment_code,
            main_segment_name:
              contentSubmit?.main_segment_name ||
              segmentName?.main_segment_name,
            description: contentSubmit.description,
            room_category_code: contentSubmit?.room_category_code || null,
            room_category_name: contentSubmit?.room_category_name || null,
            room_category_id: contentSubmit?.room_category_id || null,
            room_breakfast:
              contentSubmit?.room_breakfast > 0
                ? contentSubmit.room_breakfast
                : 0,
            room_occupancy:
              contentSubmit?.room_occupancy > 0
                ? contentSubmit.room_occupancy
                : 0,
            nett: contentSubmit?.nett > 0 ? contentSubmit.nett : 0,
            service: parseInt(contentSubmit.service),
            tax: parseInt(contentSubmit.tax),
            gross: contentSubmit?.gross > 0 ? contentSubmit.gross : 0,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Add Rate Code => ", response);

          // if (response.status === 200) {
          setButtonLoading(false);
          masterSuccessNotification(response, {
            method: 0,
            source: "Rate Code",
          });
          fetchData();
          handleCancel();
          // }
        })
        .catch((error) => {
          console.log("Error Rate Code => ", error);

          masterFailedNotification(error, { method: 0, source: "Rate Code" });
          setButtonLoading(false);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/room/rate-code`,
          {
            id: parseInt(editData?.id),
            rate_code: contentSubmit?.rate_code || editData?.rate_code,
            main_segment_id:
              contentSubmit?.main_segment_id > 0
                ? contentSubmit.main_segment_id
                : editData?.main_segment_id,
            main_segment_code:
              contentSubmit?.main_segment_code || editData?.main_segment_code,
            main_segment_name:
              contentSubmit?.main_segment_name || editData?.main_segment_name,
            description: contentSubmit.description || editData?.description,
            room_category_code:
              contentSubmit?.room_category_code || editData?.room_category_code,
            room_category_name:
              contentSubmit?.room_category_name || editData?.room_category_name,
            room_category_id: contentSubmit?.room_category_id,
            room_breakfast:
              contentSubmit?.room_breakfast > 0
                ? contentSubmit.room_breakfast
                : 0,
            room_occupancy:
              contentSubmit?.room_occupancy > 0
                ? contentSubmit.room_occupancy
                : editData?.room_occupancy,
            nett: contentSubmit?.nett > 0 ? contentSubmit.nett : editData?.nett,
            service: parseInt(contentSubmit.service) || editData?.service,
            tax: parseInt(contentSubmit.tax) || editData?.tax,
            gross:
              contentSubmit?.gross > 0 ? contentSubmit.gross : editData?.gross,
            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Update Rate Code => ", response);

          // if (response.status === 200) {
          setButtonLoading(false);
          masterSuccessNotification(response, {
            method: 1,
            source: "Rate Code",
          });
          handleCancel();
          fetchData();
          // }
        })
        .catch((error) => {
          console.log("Error Update Rate Code => ", error);

          masterFailedNotification(error, { method: 1, source: "Rate Code" });
          setButtonLoading(false);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (value) => {
    const contentDelete = value.id;

    console.log("content delete >>>>>>", contentDelete);

    let obj = {
      ["id"]: contentDelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/room/rate-code`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Rate => ", response);

        masterSuccessNotification(response, { method: 2, source: "Rate Code" });
        fetchData();
      })
      .catch((error) => {
        console.log("DEL Rate => ", error);
        masterFailedNotification(error, { method: 2, source: "Rate Code" });
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = (value) => {
    const query = value.toLowerCase(); // searchKey;
    console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.rate_code.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row className="rate-code-main-table" justify="center" style={mainBody}>
        <Col
          className="col-table"
          span={24}
          style={{
            padding: 30,
          }}
        >
          <Row className="row-search-btn" justify="start" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="search-col"
            >
              <Input.Search
                addonBefore={"Find Rate"}
                placeholder="Find Rate Code"
                // onChange={handleSearch}
                enterButton
                onSearch={handleSearch}
                allowClear
                className="input-search"
                style={{
                  margin: "0px 0px 30px",
                }}
              />
            </Col>
          </Row>

          <Row className="row-modal-btn" justify="start" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                {/* <Button
                  className="export-btn"
                  type="primary"
                  onClick={<></>}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  Download Excel
                </Button> */}

                <Space
                  size="small"
                  align="start"
                  // direction="horizontal"
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                  }}
                >
                  <Typography className="segment-text">{`Segment: `}</Typography>

                  <Typography
                    className="segment-name-text"
                    style={{ fontWeight: 750, color: "#1890ff" }}
                  >
                    {segmentName?.main_segment_name
                      ? segmentName.main_segment_name.toUpperCase()
                      : " - "}
                  </Typography>

                  {/* <Typography>{`Sub-Segment: ${
                    segmentName?.sub_segment
                      ? segmentName.sub_segment.toUpperCase()
                      : " - "
                  }`}</Typography> */}
                </Space>
                {/*
                <MasterSegment getSegment={getSegment} /> */}
              </Row>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  disabled={disable}
                  icon={<PlusOutlined />}
                >
                  {`Add Rate Code`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ margin: "30px 0px", width: "100%" }}>
            <Table
              className="rate-code-table"
              key="rate-code-table"
              name="rate-code-table"
              loading={loading}
              // rowSelection={{
              //   type: "radio",
              //   ...rowSelection,
              // }}
              bordered
              // size="small"
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1500,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-rate-code-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Add Rate Code`}</Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Edit Rate Code`}</Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="add-edit-rate-code-modal-form"
          className="add-edit-rate-code-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            nett: 0,
            room_occupancy: null,
            room_breakfast: null,
          }}
          style={{ padding: "15px 36px 0px" }}
        >
          <Row gutter={30} className="row-rate-code-id">
            <Col span={16}>
              <Form.Item
                label="Main Segment"
                name="main_segment_name"
                style={{
                  minWidth: "100%",
                }}
              >
                <Input placeholder="Main Segment Name" disabled />
              </Form.Item>

              <Form.Item
                label="Main Segment ID"
                name="main_segment_id"
                hidden={true}
              >
                <Input placeholder="Main Segment ID" disabled />
              </Form.Item>

              <Form.Item
                label="Main Segment Code"
                name="main_segment_code"
                hidden={true}
              >
                <Input placeholder="Main Segment Code" disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Compliment Type"
                name="compliment_type"
                style={{
                  minWidth: "100%",
                }}
              >
                <Input placeholder="Compliment Type" disabled />
              </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={30} className="row-rate-code-id">
            <Col span={16}>
              <Form.Item
                label="Sub Segment"
                name="sub_segment"
                style={{
                  minWidth: "100%",
                }}
              >
                <Input placeholder="Sub Segment" disabled />
              </Form.Item>

              <Form.Item
                label="Sub Segment ID"
                name="sub_segment_id"
                hidden={true}
              >
                <Input placeholder="Sub Segment ID" disabled />
              </Form.Item>
            </Col>
          </Row> */}

          <Divider
            className="divider-form"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-rate-code-id">
            <Col className="room-cat-col" span={8}>
              <Form.Item
                label="Room Category"
                name="room_category_name"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input a Rate Code!",
                //   },
                // ]}
              >
                <MasterRoomCat
                  onChangeRoomCat={onChangeRoomCat}
                  is_fetch={true}
                  disable={false}
                  cat={
                    editData?.room_category_name
                      ? editData.room_category_name
                      : null
                  }
                />
              </Form.Item>

              <Form.Item
                label="Room Category Code"
                name="room_category_code"
                hidden
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Room Category ID"
                name="room_category_id"
                hidden
              >
                <InputNumber />
              </Form.Item>
            </Col>

            <Col className="rate-code-col" span={16}>
              <Form.Item
                label="Rate Code"
                name="rate_code"
                rules={[
                  {
                    required: true,
                    message: "Please, Input the Rate Code Name!",
                  },
                ]}
              >
                <Input placeholder="Rate Code Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="price-row">
            <Col className="nett-col" span={8}>
              <Form.Item
                label="Nett Price"
                name="nett"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Nett Price!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Nett Price"
                  onChange={setGross}
                  min={0}
                  addonBefore={currency}
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col className="service-col" span={8}>
              <Form.Item
                label="Service"
                name="service"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input the Percentages of Service!",
                //   },
                // ]}
              >
                <InputNumber
                  placeholder="Service"
                  min={0}
                  addonBefore={currency}
                  disabled
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col className="tax-col" span={8}>
              <Form.Item
                label="Tax"
                name="tax"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input the Percentages of Tax!",
                //   },
                // ]}
              >
                <InputNumber
                  min={0}
                  addonBefore={currency}
                  placeholder="Tax"
                  disabled
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="occ-row">
            <Col className="gross-col" span={8}>
              <Form.Item
                label="Gross"
                name="gross"
                rules={[
                  {
                    required: true,
                    message:
                      "Please, Input the Nett Price to Show the Gross Price!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Gross"
                  addonBefore={currency}
                  onChange={handleGross}
                  min={0}
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col className="occupants-col" span={8}>
              <Form.Item
                label="Occupants"
                name="room_occupancy"
                rules={[
                  {
                    required: true,
                    message: "Please, Input maximum Room Occupants!",
                  },
                  {
                    validator: async (_, value) => {
                      const _bf = form.getFieldValue("room_breakfast");

                      // console.log("Values: ", _, value);
                      // console.log("Values BF: ", _bf);

                      if (value < _bf) {
                        return Promise.reject(
                          new Error(
                            "The Numbers of Room Occupant can not be less than the total number of Breakfasts!"
                          )
                        );
                      }
                    },
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  placeholder="Room Occupancies"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col className="bf-col" span={8}>
              <Form.Item
                label="Total Breakfast"
                name="room_breakfast"
                rules={[
                  {
                    required: true,
                    message: "Please, Input the Amounts of Breakfast!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Breakfast Counts"
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="descriptions-row" gutter={30}>
            <Col className="desc-col" span={16}>
              <Form.Item
                label="Descriptions"
                name="description"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, Input the Amounts of Breakfast!",
                //   },
                // ]}
              >
                <Input.TextArea
                  placeholder="Rate Code Descriptions"
                  maxLength={300}
                  showCount
                  style={{ width: "100%", height: 100 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-rate-code">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="updated_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-rate-code">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              disabled={buttonLoading}
              loading={buttonLoading}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

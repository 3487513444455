// REACT COMPONENTS
import React from "react";

// ANTD COMPONENTS
import { Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { TbReportMoney } from "react-icons/tb";

// PAGE COMPONENTS
import PnLMainReportLayout from "../../../../../../../Components/PrintLayout/Accounting/Monthly/ProfitnLossMain/Report";

export default function PnLMainReport() {
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="pnl-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <TbReportMoney
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Main Profit & Loss Report`}
              </Typography>
            </Row>

            <PnLMainReportLayout />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useState, useContext, useEffect } from "react";

// AUTH
import { roles, token } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

import QueueAnim from "rc-queue-anim";

import CountUp from "react-countup";

// Import Ant Design Components
import { Card, Col, Row, Typography } from "antd";

// Import Icons
import { EllipsisOutlined } from "@ant-design/icons";
import { GiExitDoor } from "react-icons/gi";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Styles
import {
  rowWidgets,
  styleNumbers,
  stylePrimaryText,
  widgetDash,
} from "../../../Style/Global/Global";

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 35,
  fontWeight: 500,
};
const styleText = {
  color: "#b3b3b3",
  fontSize: 16,
  fontWeight: 500,
};

// CODE
export default function RoomSoldCard() {
  // CONTEXT
  const { getDashboard } = useContext(MainContext);

  // STATE MANAGEMENT
  const [data, setData] = useState({
    today: 0,
    mtd: 0,
    budget: 0,
  });
  // Loading
  const [loading, setLoading] = useState(false);

  // FETCH DATA
  const fetchData = async () => {
    await getDashboard({
      type: "dashboard",
      category: "room-sold",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Dashboard Room Sold --> ", response);

        if (response?.data) {
          const _res = response.data;

          setData({
            today: _res?.today,
            mtd: _res?.mtd,
            budget: _res?.budget_mtd,
          });
        } else {
          setData({
            today: 0,
            mtd: 0,
            budget: 0,
          });
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Dashboard Room Sold err --> ", error);
        setLoading(false);
        failedFetch(error);
      },
    });

    // setTimeout(() => {
    //   setLoading(false);
    // }, 2500);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <QueueAnim type="bottom" duration={1000}>
        <Card
          key="card-header"
          title={
            <>
              <Row
                gutter={30}
                style={{ minHeight: 22, padding: "0px 30px 50px" }}
              >
                <Col span={24}>
                  <Typography
                    style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
                  >
                    {`Room Sold`}
                  </Typography>
                </Col>
              </Row>
            </>
          }
          className="card-room-sold"
          headStyle={{
            background:
              "var(--button-primary-hover, linear-gradient(262deg, #187de4 2.77%, rgba(160, 0, 174, 0.6) 139.4%))",
            borderRadius: "12px 12px 0px 0px",
          }}
          bodyStyle={{
            backgroundColor: "#fafafa",
            borderRadius: 12,
            backgroundImage: "url(/assets/bg/dashboard1.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          style={{
            borderRadius: 12,
            // margin: "0px 30px 1px 0px",
          }}
        >
          <Row gutter={[30, 30]} style={rowWidgets}>
            <Col span={12} style={{ height: "100%", width: "100%" }}>
              <div className="col-widget" style={widgetDash}>
                <Typography style={styleNumber}>
                  <CountUp end={data.today} />
                </Typography>

                <Typography
                  style={stylePrimaryText}
                >{`Actual Today`}</Typography>
              </div>
            </Col>

            <Col span={12} style={{ height: "100%", width: "100%" }}>
              <div className="col-widget" style={widgetDash}>
                <Typography style={styleNumber}>
                  <CountUp end={data.mtd} />
                </Typography>
                <Typography style={stylePrimaryText}>{`Actual MTD`}</Typography>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              padding: "0px 30px",
              fontSize: 20,
              fontWeight: 600,
              color: "#000000",
              width: "100%",
              // margin: 0,
            }}
          >
            <Col span={12} style={{ height: "100%", width: "100%" }}>
              <div
                className="col-widget"
                style={{
                  margin: "30px 0px 0px",
                  padding: 20,
                  borderRadius: 12,
                  backgroundColor: "#FFFFFF",
                  // boxShadow: "5px 5px 5px #d9d9d9",
                  width: 150,
                  height: 120,
                }}
              >
                <Typography style={styleNumber}>
                  <CountUp end={data.budget} />
                </Typography>
                <Typography style={stylePrimaryText}>{`Budget MTD`}</Typography>
              </div>
            </Col>

            <Col
              span={12}
              style={{
                margin: "30px 0px 0px",
                // padding: 30,
                // border: "1 solid",
                // borderRadius: 12,
                // backgroundColor: "#FFFFFF",
                // boxShadow: "1px 2px 9px #d9d9d9",
              }}
            >
              {/* <GiExitDoor style={{ fontSize: 200, color: "#5EADFF" }} /> */}
            </Col>
          </Row>
        </Card>
      </QueueAnim>
    </>
  );
}

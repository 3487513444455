// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// REACT ICONS
import { TbDirectionSignFilled } from "react-icons/tb";

// ANTD COMPONENTS
import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Table, Tag, Typography } from "antd";

// NOTIFICATIONS
import { failedFetch } from "../../Reusable/Notification/Notification";

// GLOBAL API
import { baseurl } from "../../../API/Config/Api";
import { id_warehouse, token, user_level } from "../../../API/Global/Payload";

export default function WidgetToDoTransferRequest() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    let bodyReq = {};

    if (user_level > 2) {
      bodyReq = {
        id_warehouse: id_warehouse,
        user_level: user_level,
      };
    }

    await axios
      .post(`${baseurl}/approval/transfer-request`, bodyReq, {
        headers: { Authorization: `Bearer ${token || ""}` },
      })
      .then((response) => {
        console.log("Approval T/R Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // REDIRECT
  const handleRedirect = () => {
    const _path = "/back-office/inventory/transfer-request-approval";

    navigate(_path);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Document Number",
      dataIndex: "document_number",
      key: "document_number",
      width: 200,
    },
    {
      title: "Posting Date",
      dataIndex: "created_date",
      key: "created_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
      render: (date) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.needed_date.localeCompare(b.needed_date),
      render: (date) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "From Store",
      dataIndex: "initial_warehouse",
      key: "initial_warehouse",
    },
    {
      title: "To Store",
      dataIndex: "destination_warehouse",
      key: "destination_warehouse",
    },
    {
      title: "Type of Moving",
      dataIndex: "transfer_type",
      key: "transfer_type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Card
        style={{ borderRadius: 12 }}
        className="todo-card"
        title={
          <>
            <Row gutter={30}>
              <Col span={24}>
                <Typography
                  style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
                >
                  {`Task List`}
                </Typography>
                <Typography
                  style={{ fontSize: 20, fontWeight: 750, color: "#FFFFFF" }}
                >
                  {`Needs Attention!`}
                </Typography>
              </Col>
            </Row>
          </>
        }
        headStyle={{
          background: `var(--volcano-gradient, linear-gradient(262deg, #610b00 -60%, #fa541c 100%))`,
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <Row
          className="row-approvals"
          style={{
            padding: 30,
            // background: `var(--red-gradient, linear-gradient(262deg, #FF005C 100%, #EE7E2D 100%))`,
          }}
        >
          <Col span={24} className="widget-col">
            <Row className="btn-row" gutter={[0, 15]}>
              <Col
                className="title-col"
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Typography
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                  }}
                >{`Stock Transfer/Request`}</Typography>
              </Col>

              <Col
                className="modal-col"
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
              >
                <Row justify="end" gutter={[0, 15]}>
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={<SyncOutlined />}
                    onClick={fetchData}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Refresh`}
                  </Button>

                  <Button
                    className="next-btn"
                    type="primary"
                    icon={
                      <TbDirectionSignFilled
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleRedirect}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Redirect`}
                  </Button>
                </Row>
              </Col>
            </Row>

            <Row
              className="table-col"
              style={{
                margin: "30px 0px 0px",
              }}
            >
              <Table
                className="purchase-req-table"
                name="purchase-req-table"
                key="purchase-req-table"
                size="small"
                loading={loading}
                bordered
                columns={columns}
                dataSource={data}
                pagination={{
                  defaultPageSize: 5,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} entries`,
                }}
                scroll={{
                  x: true,
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
                rowKey={(record) => {
                  return record.document_number;
                }}
              />
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
}

// Import React Components
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../../../../../../API/Config/Api";

// Pay Load AUTH
import {
  authorized_token,
  authorized_by,
  handleAuthorization,
} from "../../../../../../API/Global/Permission";
import { token, user_name } from "../../../../../../API/Global/Payload";

// CONTEXT
// import { Context as FinanceContext } from "../../../../../../API/Context/FinanceContext/FinanceContext";
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import {
  SyncOutlined,
  FormOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PiTextboxFill } from "react-icons/pi";

// Import Page Components
import ModalAuthorized from "../../../../../Modals/Permissions/ModalCancel";

// Import Master Components
import MasterNumbers from "../../../../../Reusable/Master/Numbers/Numbers";
import MasterBudget from "../../../../../Reusable/Master/Budget/Budget";
import MasterSegment from "../../../../../Reusable/Master/Segment/Segment";
import MasterRateCodeMultiple from "../../../../../Reusable/Master/RateCode/MasterRate";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../../../../Reusable/Notification/Notification";
import {
  failedParams,
  successParams,
} from "../../../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Body
import { mainBody } from "../../../../../../Style/Global/Global";

// MODAL
const { confirm } = Modal;

// CODE
export default function MasterSegmentationTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // Use Locations & Navigate
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [orderViewList, setOrderViewList] = useState([]);
  // Is Edit
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showStatus, setShowStatus] = useState("No Show");
  // Segments & Rate Code
  const [segmentGroup, setSegmentGroup] = useState([]);
  const [rateGroup, setRateGroup] = useState([]);
  // Modal State
  const [permitted, setPermitted] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Modals
  const [open, setOpen] = useState(false);

  // Use Forms
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getParameters({
      params: "master-parameter",
      type: "segment-header",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          // console.log(sort);

          // setData(sort);
          setData(filtered);
          setBulks(filtered);
          handleOrderNumber(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });

    handleSuccess();
  };

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (authorized_token && !cleanUp) {
      handleAuthorization("");
      localStorage.clear();
    }

    return () => {
      cleanUp = true;
    };
  }, []);

  // Use Effects
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      // fixed: "left",
      render: (_, record) => (
        <Row
          className="action-table-btn"
          justify="center"
          style={{ width: "100%" }}
        >
          <Tooltip
            title={permitted ? "Edit Disabled" : "Edit Data"}
            className="edit-tooltip"
          >
            <Button
              className="edit-btn"
              shape="circle"
              size="small"
              // disabled={permitted}
              icon={
                <FiEdit
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);

                    setIsEdit(true);
                    setEditData(record);

                    handleOpen();
                    setFormFields(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    // cursor: "pointer",
                  }}
                />
              }
              style={{
                borderColor: "transparent",
                background: "inherit",
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Segmentation Header Name",
      dataIndex: "header_name",
      key: "header_name",
    },
    {
      title: "Total Label Name",
      dataIndex: "total_name",
      key: "total_name",
    },
    {
      title: "Order View",
      dataIndex: "order_view",
      key: "order_view",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Show in Report",
    //   dataIndex: "is_show",
    //   key: "is_show",
    //   align: "center",
    //   render: (bool, record) => {
    //     let txt = "";
    //     let clr = "";

    //     switch (bool) {
    //       case true:
    //         txt = "Show";
    //         clr = "green";
    //         break;

    //       default:
    //         txt = "No Show";
    //         clr = "volcano";
    //         break;
    //     }

    //     return (
    //       <Tag
    //         className="show-tag"
    //         color={clr}
    //         key={"tag-key"}
    //         style={{
    //           fontSize: 14,
    //           fontWeight: 500,
    //           // margin: "0px 0px 0px 15px",
    //         }}
    //       >
    //         {txt}
    //       </Tag>
    //     );
    //   },
    // },
  ];

  // Handle Order View
  const handleOrderNumber = (value) => {
    const _arr = value;

    let _numbered = _arr.map((val) => val.order_view);

    console.log("Picked Numbers: ", _numbered);
    setOrderViewList(_numbered);
  };

  // SET FORM FIELDS
  const setFormFields = (record) => {
    const _label = record;

    if (_label.is_show === true) {
      setShowStatus("Show");
    }

    form.setFieldsValue({
      ..._label,
      is_show: _label?.is_show,
      budget_label: _label.budget_coa,
      source_name: _label?.segment,
      source_name_id: _label?.main_segment_id,
      group_id: _label?.rate_code_id,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const _submit = value;

    console.log("Submit Data: ", _submit);

    setIsLoading(true);
    showModal(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _submit = value;

    incomplete(value);
    console.log("Submit Data Failed: ", _submit);
  };

  // Handle Open
  const handleOpen = () => {
    if (!permitted) {
      setOpen(true);
    } else {
      setModalOpen(true);
    }
  };

  // Move
  const move = (e) => {
    const path =
      "/configuration/parameters/general-report-params/report-params";
    const _authorized = localStorage.getItem("permit");

    if (_authorized || authorized_token) {
      handleSuccess();
    }

    navigate(`${path}`);
  };

  // Order View
  const getOrderView = (val) => {
    const _numb = val;

    form.setFieldsValue({
      order_view: _numb,
    });
  };

  // Handle Permissions
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setPermitted(false);
        setOpen(true);
        break;

      default:
        break;
    }
  };

  // Handle Showing
  const handleShow = (e) => {
    const value = e.target.checked;
    // console.log("Key: ", e.target.checked);

    switch (value) {
      case true:
        setShowStatus("Show");
        break;

      default:
        setShowStatus("No Show");
        break;
    }
    // setShowStatus(e);
  };

  // Modal Confirm
  const showModal = (val) => {
    const _submitted = val;

    confirm({
      className: "modal-confirm",
      title: (
        <>
          {`Are you sure you want ${
            isEdit ? "Update" : "Add a New "
          } label parameters of: `}
          <mark>{_submitted.header_name}</mark>
          {` to the Table`}?
        </>
      ),
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_submitted);
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
      },
      cancelButtonProps: {
        className: "cancel-btn",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Update
  const handleSubmit = async (value) => {
    const submitData = value;

    switch (isEdit) {
      case true:
        handleCreate(submitData);
        break;

      case false:
        handleUpdate(submitData);
        break;

      default:
        break;
    }
  };

  // Handle Create
  const handleCreate = async (value) => {
    const submitData = value;
    await axios
      .put(
        `${baseurl}/master-parameter/segment-header`,
        {
          header_name: submitData?.header_name || editData?.header_name,
          total_name: submitData?.total_name || editData?.total_name,
          description: submitData?.description || editData?.description,
          order_view: submitData?.order_view || editData?.order_view,
          is_show: submitData?.is_show || editData?.is_show,

          updated_by: authorized_by ? authorized_by : user_name,
          id: editData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Update Label Success: ", response);
        successParams(response, { method: 6 });

        fetchData();
        handleClose();
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error Label: ", error);
        failedParams(error, { method: 6 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = async (value) => {
    const submitData = value;

    await axios
      .post(
        `${baseurl}/master-parameter/segment-header`,
        {
          header_name: submitData?.header_name || editData?.header_name,
          total_name: submitData?.total_name || editData?.total_name,
          description: submitData?.description || editData?.description,
          order_view: submitData?.order_view || null,
          is_show: submitData?.is_show,

          created_by: authorized_by ? authorized_by : user_name,
          id: editData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("POST Label Success: ", response);
        successParams(response, { method: 6 });

        fetchData();
        handleClose();
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error Label: ", error);
        failedParams(error, { method: 6 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setEditData(null);
    setShowStatus("No Show");

    form.resetFields();
  };

  // Handle Success
  const handleSuccess = () => {
    setPermitted(true);
    setModalOpen(false);

    localStorage.clear();
    handleAuthorization("");
  };

  // Handle Search
  const handleSearch = () => {
    const _keys = searchKey ? searchKey.toLowerCase() : null;

    if (bulks.length > 0) {
      let filtered = bulks.filter((items) => {
        const _names = items?.header_name
          ? items.header_name.toLowerCase()
          : "";

        return _names.indexOf(_keys) != -1;
      });

      setData(filtered);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="account-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="end" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              {/* <Row justify="end" className="row-table"> 
              <Button
                className="print-btn"
                type="primary"
                onClick={handleOpen}
                icon={
                  <FiEdit
                    className="edit-icons"
                    style={{
                      margin: "0px 5px 0px 5px",
                    }}
                  />
                }
                style={{
                  // backgroundColor: "#1BC5BD",
                  // borderColor: "#1BC5BD",

                  marginRight: 15,
                }}
              >
                {`Enable Edit`}
              </Button> */}

              <Button
                className="submit-btn"
                type="primary"
                onClick={handleOpen}
                icon={
                  <PlusOutlined
                    className="next-icons"
                    style={{
                      margin: "0px 5px 0px 5px",
                    }}
                  />
                }
                style={{
                  marginRight: 15,
                }}
              >
                {`Add New Details`}
              </Button>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="segment-params-table"
              name="segment-params-table"
              key="segment-params-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="label-modal"
        key="label-modal"
        title={
          <>
            <Row className="title-row">
              <PiTextboxFill style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography
                className="modal-title-text"
                style={{ margin: "0px 0px 0px 15px" }}
              >
                {isEdit ? `Edit Data` : `Add New Data`}
              </Typography>
            </Row>
          </>
        }
        open={open}
        centered
        closable={true}
        onCancel={handleClose}
        width={800}
        footer={null}
      >
        <Form
          className="label-form"
          name="label-form"
          key="label-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Row
            className="main-row"
            justify="center"
            style={{ width: "100%", padding: "15px 30px 0px" }}
          >
            <Col span={24} className="main-col">
              <Row className="source-row" gutter={30}>
                <Col className="source-col" span={16}>
                  <Form.Item
                    label="Segment Header's Name"
                    name="header_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Segmentation Header's Name!",
                      },
                    ]}
                  >
                    <Input
                      className="name-input"
                      placeholder="Segmentation Header's Name"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="source-row" gutter={30}>
                <Col className="source-col" span={16}>
                  <Form.Item
                    label="Total Label Name"
                    name="total_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Total Label's Name!",
                      },
                    ]}
                  >
                    <Input
                      className="name-input"
                      placeholder="Segmentation Total Label's Name"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="showing-row" gutter={30}>
                <Col className="showing-col" span={16}>
                  <Form.Item
                    label="Order View"
                    name="order_view"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, Select an Option!",
                    //   },
                    // ]}
                  >
                    <MasterNumbers
                      existedNumb={open ? editData?.order_view : 0}
                      getNumbers={getOrderView}
                      pickedNumb={
                        orderViewList?.length > 0 ? orderViewList : []
                      }
                    />
                  </Form.Item>
                </Col>

                <Col className="source-col" span={8}>
                  <Form.Item
                    label="Showing Status"
                    name="is_show"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Checkbox className="show-check" onChange={handleShow}>
                      {showStatus}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="source-row" gutter={30}>
                <Col className="source-col" span={16}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Description"
                      className="desc-name"
                      showCount
                      allowClear
                      maxLength={100}
                      minLength={5}
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "15px 0px", background: "#EBEDF3" }}
              />

              {isEdit ? (
                <>
                  <Row className="creation-row" gutter={30}>
                    <Col className="createsby-col" span={8}>
                      <Form.Item label="Created By" name="created_by">
                        <Input
                          placeholder="Created By"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col className="createdat-col" span={8}>
                      <Form.Item label="Created Date" name="created_date">
                        <Input
                          placeholder="Created Date"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="modified-row" gutter={30}>
                    <Col className="source-col" span={8}>
                      <Form.Item label="Updated By" name="updated_by">
                        <Input
                          placeholder="Updated By"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col className="source-col" span={8}>
                      <Form.Item label="Updated Date" name="updated_date">
                        <Input
                          placeholder="Updated Date"
                          className="form-input"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider
                    className="form-divider"
                    style={{ margin: 0, background: "#EBEDF3" }}
                  />
                </>
              ) : null}

              <Row
                justify="end"
                align="middle"
                style={{ padding: "10px 0px 0px" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  key={"submit"}
                  className="submit-btn"
                  loading={isLoading}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>

      <ModalAuthorized
        openModal={modalOpen}
        closeModal={setModalOpen}
        is_permitted={handlePermit}
        params={1}
      />
    </>
  );
}

// Import React's Component
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Typography,
  Tag,
  Tooltip,
  Anchor,
  Modal,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  ExclamationCircleTwoTone,
} from "@ant-design/icons/lib/icons";

// Import Notifications
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// CONST MODAL
const { confirm } = Modal;

// CODE
export default function MasterAvailabilityTable(props) {
  // PROPS
  const { data_avail, is_refresh, is_refreshed } = props;

  // Create Columns
  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 75,
      //   fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="room-table-btn" style={{ width: "100%" }}>
              <Col span={24} className="col-del-btn">
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      // if (record.number_of_rooms_1 == 0) {
                      // }
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Room Category Name",
      dataIndex: "room_category_name",
      key: "room_category_name",
      //   width: 200,
    },
    // {
    //   title: "Room Category Code",
    //   dataIndex: "room_category_code",
    //   key: "room_category_code",
    //   //   width: 200,
    // },
    {
      title: "Total Room",
      dataIndex: "number_of_rooms",
      key: "number_of_rooms",
      render: (room, record) => {
        let total = record?.off_market + room;

        return total;
      },
    },
    // {
    //   title: "29-05-2023",
    //   dataIndex: "room_available_1",
    //   key: "room_available_1",
    // },
    // {
    //   title: "30-05-2023",
    //   dataIndex: "room_available_2",
    //   key: "room_available_2",
    // },
  ];

  // CONTEXT
  const { getMasterRoomCat, getMasterAvail, getMasterRoomAvail } =
    useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataAvail, setDataAvail] = useState([]);
  //  Dynamic Columns
  const [newColumns, setNewColumns] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  // Update Room Availability
  const fetchData = async () => {
    // await fetchGetRoom();

    await getMasterAvail({
      type: "category/availability",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Update Room Category Availability => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            if (!val.is_deleted) {
              return val;
            }
          });

          console.log("Availability FILTERED => ", filtered);
          setData(filtered);
          data_avail(filtered);
        } else {
          setData([]);
          data_avail([]);
        }

        setLoading(false);
        // fetchGetRoom();
      },
      onReject: (error) => {
        failedFetch(error);
        console.log("ERROR ==> ", error);
        setLoading(false);
      },
    });
  };

  // Fetch Room Availability
  const fetchGetRoom = async () => {
    await getMasterRoomAvail({
      type: "category/availability",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Get Room Category Availability => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            if (!val.is_deleted) {
              return val;
            }
          });

          console.log("Availability FILTERED => ", filtered);
          setData(filtered);
          data_avail(filtered);
        } else {
          setData([]);
          data_avail([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR ==> ", error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();

    if (is_refresh === true) {
      is_refreshed(false);
    }
  }, [is_refresh]);

  // useEffect(() => {
  //   let cleanUp = false;

  //   if (is_refresh == true && !cleanUp) {
  //    fetchData()
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [is_refresh]);

  // MODAL DELETE MODAL
  const showDeleteModal = (val) => {
    let deleteContent = val;

    confirm({
      className: "delete-confirm",
      title: `Are you sure want to Delete the Availabilities of ${
        deleteContent?.room_category ||
        deleteContent?.room_category_name ||
        "----"
      } Category from the Room Availability Table?`,
      icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: "Delete",
      // okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentDelete =
      record?.room_category_id > 0 ? record?.room_category_id : 0;

    console.log("content delete >>>>>>", contentDelete);

    var obj = {
      ["room_category"]: contentDelete,
      ["deleted_by"]: user_name,
    };

    console.log(obj);

    await axios
      .delete(`${baseurl}/room/category/availability`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Room => ", response);
        masterSuccessNotification(obj, {
          method: 2,
          source: "Room Availibility",
        });

        fetchData();
      })
      .catch((error) => {
        console.log("DEL Room => ", error);
        masterFailedNotification(obj, {
          method: 2,
          source: "Room Availibility",
        });
      });
  };

  return (
    <>
      <Row
        className="room-availability-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} style={{ padding: 0 }}>
          {/* <Row className="row-table-btn">
            <Col span={8} className="col-date-available"></Col>

            <Col span={16} className="col-refresh-btn">
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 30px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row> */}

          <Content style={{ width: "100%" }}>
            <Col flex={24}>
              <Table
                // title={onForm == true ? () => "Room Availability" : () => {}}
                className="room-avail-table"
                name="room-avail-table"
                key="room-avail-table"
                bordered
                // size={onForm == true ? "small" : "middle"}
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                  defaultPageSize: 5,
                  pageSizeOptions: [5, 10, 25, 50, 100],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                }}
                // scroll={{
                //     y: 270,
                // }}
                rowClassName={(record, index) => {
                  return index % 2 == 0 ? "even" : "odd";
                }}
                rowKey={(record) => (record.id ? record.id : record.key)}
              />
            </Col>
          </Content>
        </Col>
      </Row>
    </>
  );
}

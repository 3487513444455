// REACT COMPONENTS
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, InputNumber, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";

// REACT ICONS
import { FaPeopleCarryBox } from "react-icons/fa6";
import { IoReturnUpBackOutline } from "react-icons/io5";

// PAGE COMPONENTS
import MasterArticleMain from "../../../../../Components/Reusable/Master/ArticleMain/ArticleMain";
import SlowMovingTable from "../../../../../Components/Table/Reports/BackOffice/Inventory/SlowMoving/Table";

export default function SlowMovingPage() {
  // STATE
  const [mainGroup, setMainGroup] = useState(null);
  const [days, setDays] = useState(30);

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report";

    navigate(`${path}`);
  };

  // HANDLE GET ARTICLE MAIN GROUP
  const handleGetArticleMain = (value) => {
    console.log("VALUE ARTICLE MAIN GORUP : ", value);
    setMainGroup(value);
  };

  // HANDLE SEARCH DAYS COUNT
  const handleSearch = (value) => {
    console.log("Searching for:", value);
    setDays(value);
  };

  // HANDLE CHANGE DEBOUNCE DAYS COUT
  let debounceTimer;
  const handleChange = (event) => {
    const value = event;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => handleSearch(value), 500);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="slow-moving-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaPeopleCarryBox
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Slow Moving Stock List Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Select Article Main Group`}</Typography>

                <MasterArticleMain
                  getArticleMain={handleGetArticleMain}
                  mainName={null}
                  isDisabled={false}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Days`}</Typography>

                <InputNumber
                  min={30}
                  defaultValue={days}
                  placeholder="Days"
                  onChange={handleChange}
                  style={{ minWidth: 150 }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {mainGroup && days >= 30 ? (
            <SlowMovingTable
              mainGroupId={mainGroup?.main_id}
              daysCount={days}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

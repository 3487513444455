// Import React's Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// Import ANTD Component
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
  Divider,
  DatePicker,
  Typography,
  Input,
  Tag,
} from "antd";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import {
  approval_level,
  department,
  department_id,
  token,
  user_level,
  user_name,
} from "../../../../API/Global/Payload";

// Import React Icons Components
import {
  FileProtectOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import SODetailTable from "../../../../Components/Table/BackOffice/Inventory/SODetail/Table";

// Import Notification
import { incomplete } from "../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
  masterIncomplete,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Modal
const { confirm } = Modal;

// CODE
export default function StockOpnameDetailModal(props) {
  // PROPS
  const { is_open, is_close, is_refresh, soDetail, modalDate } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [tableDetail, setTableDetail] = useState([]);

  // Disable
  const [status, setStatus] = useState([]);

  // Modals
  const [modalOpen, setModalOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      // console.log("Modal State: ", is_open);
      handleOpen();
      setStatus(soDetail?.so_status);
      form.setFieldsValue({
        created_date: moment(soDetail?.created_date),
        start_date: moment(soDetail?.so_start_date),
        end_date: moment(soDetail?.so_end_date),
      });
    } else {
      setModalOpen(false);
      is_close(false);
      form.setFieldsValue({
        // created_date: moment(soDetail?.created_date).format("YYYY-MM-DD"),
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  useEffect(() => {
    setStatus(soDetail.status);
  },[soDetail.status]);

  // Handle Open
  const handleOpen = () => {
    console.log("USERS METADATA: ", approval_level, department, user_level);
    setModalOpen(true);
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);

    setDataEdit(null);
    setTableDetail(null);
    setModalOpen(false);
    setStatus(null);

    setData([]);

    form.resetFields();
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // ON SAVE AS DRAFT
  const handleOpenOpname = () => {

    console.log("DATA SoDetail >> ", soDetail);
    showModalConfirm(1);

  };

  // ON SAVE AS DRAFT
  const handleCloseOpname = () => {

    console.log("DATA SoDetail >> ", soDetail);
    showModalConfirm(2);

  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (source) => {
    let _source = source

    confirm({
      className: "modals-confirm",
      title: _source == 1 ? `Are you sure want to Open Opname?` : `Are you sure want to Close Opname?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleFinish(_source);
      },

      onCancel() {

      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Finish
  const handleFinish = async (source) => {

    let _source = source

    if(_source == 1){
      let allBody = {
        created_by: user_name,
        id_so:soDetail?.id_so,
      };
      console.log("ALL BODY >> ", allBody);
      handleSubmit(allBody, _source);
    } else {
      let allBody = {
        so_created_by: user_name,
        id_so:soDetail?.id_so,
      };
      console.log("ALL BODY >> ", allBody);
      handleSubmit(allBody, _source);
    }

    
    
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value, source) => {
    const contentSubmit = value;
    const _source = source;

    console.log("Data About to PUT/POST: ", contentSubmit);
    
    if(_source == 1){
      await axios
        .post(`${baseurl}/inventory/stock-opname/freeze`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Open Opname: ", response);
  
          masterSuccessNotification(response, {
            method: 0,
            source: "Open Opname",
          });
          // handleClose();
          is_refresh(true);
          setStatus(2);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 0,
            source: "Open Opname",
          });
        })
        .finally(() => {
          // handleClose();
        });
    } else {
      await axios
        .put(`${baseurl}/inventory/stock-opname/status`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Close Opname: ", response);
  
          masterSuccessNotification(response, {
            method: 0,
            source: "Close Opname",
          });
          // handleClose();
          is_refresh(true);
          setStatus(3);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 0,
            source: "Close Opname",
          });
        })
        .finally(() => {
          // handleClose();
        });
    }
  };

  return (
    <>
      <Modal
        className="purchase-req-modal"
        key="purchase-req-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Stock Opname Detail`}</Typography>
            </Row>
          </>
        }
        centered
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={1250}
      >
        <Form
          className="purchase-req-form"
          key="purchase-req-form"
          name="purchase-req-form"
          layout="vertical"
          form={form}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="pay-row" gutter={30}>
                <Col span={8} className="pay-col">
                  <Form.Item
                    label="Storage"
                    name="storage"
                  >
                    <Input placeholder={soDetail.storage_name} disabled/>
                  </Form.Item>
                </Col>

                <Col span={8} className="pay-col">
                  <Form.Item
                    label="Created by"
                    name="created_by"
                  >
                    <Input placeholder={soDetail.created_by} disabled/>
                  </Form.Item>
                </Col>

                <Col span={8} className="pay-col">
                  <Form.Item
                    label="Status"
                    name="status"
                  >
                    {  (status == '1') ? <Tag color="#3b5999">{`Planned`}</Tag>
                      : (status == '2') ? <Tag color="#1890ff">{`On Process`}</Tag>
                      : (status == '3') ? <Tag color="#87d068">{`Completed`}</Tag>
                      : 'others'
                    }  
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30}>
                <Col span={8} className="req-date-col">
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                  >
                    <DatePicker
                      placeholder="Created Date"
                      className="created_date"
                      // defaultValue={moment()}
                      allowClear
                      format="YYYY-MM-DD HH:mm"
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="req-date-col">
                  <Form.Item
                    label="Start Date"
                    name="start_date"
                  >
                    <DatePicker
                      placeholder="Start Date"
                      className="start_date"
                      // defaultValue={moment()}
                      allowClear
                      format="YYYY-MM-DD HH:mm"
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="req-date-col">
                  <Form.Item
                    label="End Date"
                    name="end_date"
                  >
                    <DatePicker
                      placeholder="End Date"
                      className="end_date"
                      // defaultValue={moment()}
                      allowClear
                      format="YYYY-MM-DD HH:mm"
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <SODetailTable
                soDetail={soDetail}
                status = {status}
                is_open = {is_open}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">

                <Button
                  className="submit-btn"
                  type="primary"
                  icon={<FileProtectOutlined />}
                  onClick={handleOpenOpname}
                  disabled={
                    (moment(modalDate) > moment(soDetail?.so_start_date) && status == 1) ? false : true
                  }
                  // loading={isCreate}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Open Opname`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleCloseOpname}
                  icon={<FileProtectOutlined />}
                  disabled={status =='2' ? false : true}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Close Opname`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";

// REACT ICONS
import { PiTextboxFill } from "react-icons/pi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// MODAL
import ModalAuthorized from "../../../../../../Modals/Permissions/ModalCancel";

// GLOBAL API
import {
  authorized_token,
  handleAuthorization,
} from "../../../../../../../API/Global/Permission";
import { token } from "../../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../../API/Config/Api";
import axios from "axios";

// NOTIFICATIONS
import {
  failedParams,
  successParams,
} from "../../../../../../Reusable/Notification/MasterNotif/Notification";
import {
  failedFetch,
  incomplete,
} from "../../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MasterContext } from "../../../../../../../API/Context/MasterContext/MasterContext";

// Modal
const { confirm } = Modal;

export default function ReportMasterBSDetailParamsTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // STATE
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [group, setGroup] = useState(null);
  const [subGroup, setSubGroup] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [subGroupOptions, setSubGroupOptions] = useState([]);

  // Modal State
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [permitted, setPermitted] = useState(true);

  //  LOADING
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    fetchData();
    fetchDataGroup();

    if (authorized_token && !cleanUp) {
      handleAuthorization("");
      localStorage.clear();
    }

    return () => {
      cleanUp = true;
    };
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // FETCH DATA
  const fetchData = async () => {
    await getParameters({
      params: "parameter",
      type: "master-coa-detail",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let filtered = _res
            .filter((filt) => {
              if (filt.is_deleted == false || filt.is_deleted == null) {
                return filt;
              }
            })
            .map((item) => {
              return {
                id: item.id,
                account_code: item.account_code,
                description: item.description,
                group_id_neraca: item.group_id_neraca,
                sub_group_id_neraca: item.sub_group_id_neraca,
              };
            });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // FETCH DATA GROUP
  const fetchDataGroup = async () => {
    setIsLoading(true);

    await axios
      .get(`${baseurl}/parameter/accounting/balance-sheet-header`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res BS Header: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filtOptions = _res.map((item) => {
            return {
              key: item.id,
              label: item.description,
              value: item.description,
              group_id_neraca: item.id,
            };
          });

          console.log(filtOptions);
          setGroupOptions(filtOptions);
        } else {
          setGroupOptions([]);
        }
      })
      .catch((error) => {
        console.log("Res BS Header Error: ", error);
        failedFetch(error);
      });

    await axios
      .get(`${baseurl}/parameter/accounting/balance-sheet-detail`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res BS Detail: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filtOptions = _res.map((item) => {
            return {
              key: item.id,
              label: item.sub_description,
              value: item.sub_description,
              sub_group_id_neraca: item.id,
            };
          });

          console.log(filtOptions);
          setSubGroupOptions(filtOptions);
        } else {
          setSubGroupOptions([]);
        }
      })
      .catch((error) => {
        console.log("Res BS Detail Error: ", error);
        failedFetch(error);
      });

    setIsLoading(false);
  };

  // SET FORM FIELDS
  const setFormFields = (record) => {
    const _filtGroup = groupOptions.filter(
      (item) => record.group_id_neraca == item.group_id_neraca
    );
    const _filtSubGroup = subGroupOptions.filter(
      (item) => record.sub_group_id_neraca == item.sub_group_id_neraca
    );

    console.log("Filt Group ->> ", _filtGroup);
    console.log("Filt Sub Group ->> ", _filtSubGroup);

    form.setFieldsValue({
      group: _filtGroup[0]?.value || null,
      subgroup: _filtSubGroup[0]?.value || null,
      created_by: record?.created_by || "-",
      created_date: record?.created_date
        ? moment(record.created_date).format("DD-MM-YYYY")
        : "-",
      updated_by: record?.updated_by || "-",
      updated_date: record?.updated_date
        ? moment(record.updated_date).format("DD-MM-YYYY")
        : "-",
    });
  };

  // HANDLE SET GROUP PNL
  const handleSetGroupPnl = (e, i) => {
    console.log(e);
    console.log(i);

    let _value = e;
    let _allKeys = i;

    setGroup(_allKeys);

    form.setFieldsValue({
      group: _value,
    });
  };

  // HANDLE SET SUB GROUP PNL
  const handleSetSubGroupPnl = (e, i) => {
    console.log(e);
    console.log(i);

    let _value = e;
    let _allKeys = i;

    setSubGroup(_allKeys);

    form.setFieldsValue({
      subgroup: _value,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const _submit = value;

    console.log("Submit Data: ", _submit);

    showModalConfirm(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _submit = value;

    incomplete(value);
    console.log("Submit Data Failed: ", _submit);
  };

  // HANDLE SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    const _submitted = val;

    confirm({
      className: "modal-confirm",
      title: (
        <>
          {`Are you sure you want to Update Description parameters of: `}
          <mark>{_submitted?.description}</mark>?
        </>
      ),
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(_submitted);
      },
      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
      },
      cancelButtonProps: {
        className: "cancel-btn",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE UPDATE
  const handleUpdate = async (val) => {
    const submitData = val;

    await axios
      .put(
        `${baseurl}/parameter/master-coa-for-neraca`,
        {
          id: editData?.id,
          group_id_neraca: group?.group_id_neraca || null,
          sub_group_id_neraca: subGroup?.sub_group_id_neraca || null,
          //   updated_by: authorized_by ? authorized_by : user_name,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Update Description Success: ", response);
        successParams(response, { method: 6 });

        fetchData();
        handleClose();
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error Label: ", error);
        failedParams(error, { method: 6 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANCLE SUCCESS
  const handleSuccess = () => {
    setPermitted(true);
    setModalOpen(false);

    localStorage.clear();
    handleAuthorization("");
  };

  // HANDLE PERMISSION
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setPermitted(false);
        setOpen(true);
        break;

      default:
        break;
    }
  };

  // HANDLE CLOSE PERMISSION
  const handleClosePermit = (val) => {
    setModalOpen(false);
  };

  // HANDLE OPEN MODAL PERMISSION
  const handleOpen = () => {
    if (!permitted) {
      setOpen(true);
    } else {
      setModalOpen(true);
    }
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setIsEdit(false);
    setEditData(null);
    setOpen(false);
    setGroup(null);
    setSubGroup(null);

    form.resetFields();
  };

  // RETURN
  const move = () => {
    const path =
      "/configuration/parameters/general-report-params/monthly-report-params";

    const _authorized = localStorage.getItem("permit");

    if (_authorized || authorized_token) {
      handleSuccess();
    }

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const _keys = searchKey ? searchKey.toLowerCase() : null;

    if (bulks?.length > 0 && _keys) {
      let filtered = bulks.filter((item) => {
        const _name = item?.description?.toLowerCase() || "";

        return _name.indexOf(_keys) !== -1;
      });

      setData(filtered);
    } else {
      setData([]);
    }
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <Row
          className="action-table-btn"
          justify="center"
          style={{ width: "100%" }}
        >
          <Tooltip
            title={permitted ? "Edit Disabled" : "Edit Data"}
            className="edit-tooltip"
          >
            <Button
              className="edit-btn"
              shape="circle"
              size="small"
              // disabled={permitted}
              icon={
                <FiEdit
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);

                    setIsEdit(true);
                    setEditData(record);
                    handleOpen();

                    setFormFields(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                  }}
                />
              }
              style={{
                borderColor: "transparent",
                background: "inherit",
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Account Code",
      dataIndex: "account_code",
      key: "account_code",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Group ID",
      dataIndex: "group_id_neraca",
      key: "group_id_neraca",
    },
    {
      title: "Sub Group ID",
      dataIndex: "sub_group_id_neraca",
      key: "sub_group_id_neraca",
    },
  ];

  return (
    <>
      <Row
        className="balance-sheet-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="end" gutter={[0, 15]}>
            <Col
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
              className="refresh-col"
            >
              <Row gutter={[0, 15]} justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="balancesheet-table"
              name="balancesheet-table"
              key="balancesheet-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>

        <Modal
          className="label-modal"
          key="label-modal"
          title={
            <>
              <Row className="title-row">
                <PiTextboxFill style={{ color: "#3699FF", fontSize: 24 }} />
                <Typography
                  className="modal-title-text"
                  style={{ margin: "0px 0px 0px 15px" }}
                >
                  {"Edit Data"}
                </Typography>
              </Row>
            </>
          }
          open={open}
          centered
          closable={true}
          onCancel={handleClose}
          width={800}
          footer={null}
        >
          <Form
            className="label-form"
            name="label-form"
            key="label-form"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              is_show: false,
            }}
            layout="vertical"
          >
            <Row
              className="main-row"
              justify="center"
              style={{ width: "100%", padding: "15px 30px 0px" }}
            >
              <Col span={24} className="main-col">
                <Row className="source-row" gutter={30}>
                  <Col className="source-col" span={12}>
                    <Form.Item
                      label="Group Balance Sheet"
                      name="group"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, Input a Group Balance Sheet!",
                      //   },
                      // ]}
                    >
                      <Select
                        className="type-select"
                        key="type-select"
                        allowClear
                        showSearch
                        value={isEdit ? form.getFieldValue("group") : null}
                        onChange={handleSetGroupPnl}
                        options={groupOptions}
                        placeholder="Select Group"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="source-col" span={12}>
                    <Form.Item
                      label="Sub Group Balance Sheet"
                      name="subgroup"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, Input a SubGroup Balance Sheet!",
                      //   },
                      // ]}
                    >
                      <Select
                        className="type-select"
                        key="type-select"
                        allowClear
                        showSearch
                        value={isEdit ? form.getFieldValue("subgroup") : null}
                        onChange={handleSetSubGroupPnl}
                        options={subGroupOptions}
                        placeholder="Select Sub Group"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: "15px 0px", background: "#EBEDF3" }}
                />

                <Row className="creation-row" gutter={30}>
                  <Col className="createsby-col" span={12}>
                    <Form.Item label="Created By" name="created_by">
                      <Input
                        placeholder="Created By"
                        className="form-input"
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  <Col className="created-date-col" span={12}>
                    <Form.Item label="Created Date" name="created_date">
                      <Input
                        placeholder="Created Date"
                        className="form-input"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="modified-row" gutter={30}>
                  <Col className="source-col" span={12}>
                    <Form.Item label="Updated By" name="updated_by">
                      <Input
                        placeholder="Updated By"
                        className="form-input"
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  <Col className="source-col" span={12}>
                    <Form.Item label="Updated Date" name="updated_date">
                      <Input
                        placeholder="Updated Date"
                        className="form-input"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: 0, background: "#EBEDF3" }}
                />

                <Row
                  justify="end"
                  align="middle"
                  style={{ padding: "10px 0px 0px" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    key={"submit"}
                    className="submit-btn"
                    loading={isLoading}
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {`Submit`}
                  </Button>

                  <Button
                    className="cancel-btn"
                    type="default"
                    onClick={handleClose}
                  >
                    {`Cancel`}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>

        <ModalAuthorized
          openModal={modalOpen}
          closeModal={handleClosePermit}
          is_permitted={handlePermit}
          params={1}
        />
      </Row>
    </>
  );
}

// CONTEXT
import CreateContext from "../CreateContext";

// BASE URL API
import baseAPI from "../../Config/Api";

// REDUCERS
const mainReducer = (state, action) => {
  if ((action.type = "getData")) {
    return {
      ...state,
      data: action.payload,
    };
  }
};

// CONTEXT MAIN GET DATA
// Get Data .....
const getData =
  (dispatch) =>
  async ({ mainData, onAwait, onSuccess, onReject }) => {
    let query = "/" + mainData;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Permissions
const requestPermissions =
  (dispatch) =>
  async ({ auth, permit, email, password, onAwait, onSuccess, onReject }) => {
    let query = "/" + auth + "/" + permit;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { email, password })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Reservation
const getReservation =
  (dispatch) =>
  async ({ reservation, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + reservation + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Reservation By Source
const getReservationBySource =
  (dispatch) =>
  async ({ reservation, type, source, onAwait, onSuccess, onReject }) => {
    let query = "/" + reservation + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { source })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Guest
const getGuest =
  (dispatch) =>
  async ({ guest, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + guest + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Non Stay Guest
const getNonStay =
  (dispatch) =>
  async ({ guest_type, nonstay, onAwait, onSuccess, onReject }) => {
    let query = "/" + guest_type + "/" + nonstay;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Non Stay Guest
const getHistoryNonStay =
  (dispatch) =>
  async ({ res_type, nonstay, onAwait, onSuccess, onReject }) => {
    let query = "/" + res_type + "/" + nonstay;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Guest In House
const getGuestInHouse =
  (dispatch) =>
  async ({ guestInHouse, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + guestInHouse + "/" + type;
    if (onAwait) onAwait();

    // try {
    //   const response = baseAPI.get(query);
    //   dispatch({ type: "getData", payload: response.data });
    //   if (onSuccess) onSuccess(response);
    // } catch (error) {
    //   if (onReject) onReject(error);
    // }
    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Expected Departure
const getExpectedDeparture =
  (dispatch) =>
  async ({ expectedDeparture, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + expectedDeparture + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Expected Departure
const getHistoryGuest =
  (dispatch) =>
  async ({ guest, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + guest + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Article Room Price Sungle
const getArticleRoom =
  (dispatch) =>
  async ({
    articleRoomPrice,
    type,
    res_id,
    prc_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/" + articleRoomPrice + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, prc_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Article Room Price Group
const getArticleRoomGroup =
  (dispatch) =>
  async ({ article, type, reservation_id, onAwait, onSuccess, onReject }) => {
    let query = "/" + article + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { reservation_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Reservation Foreign
const getForeign =
  (dispatch) =>
  async ({ guest, foreign, onAwait, onSuccess, onReject }) => {
    let query = "/" + guest + "/" + foreign;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Reservation Deposit
const getResDeposit =
  (dispatch) =>
  async ({ reservation, deposit, onAwait, onSuccess, onReject }) => {
    let query = "/" + reservation + "/" + deposit;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Master Rate Price
const getRatePrice =
  (dispatch) =>
  async ({ rate, price, rate_code, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + rate + "/" + price;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { rate_code })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Room Charts
const getRoomHistory =
  (dispatch) =>
  async ({
    reservation,
    type,
    reservation_id,
    room_number,
    date,
    is_step,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/" + reservation + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { reservation_id, room_number, date, is_step })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Room Availability
const getReservations =
  (dispatch) =>
  async ({
    category,
    type,
    date,
    room_category,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/room" + "/" + category + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { date, room_category })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Reservation Leader
const getReservationLeader =
  (dispatch) =>
  async ({ leader, reservation_id, onAwait, onSuccess, onReject }) => {
    let query = "/guest/" + leader;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { reservation_id })
      .then((response) => {
        dispatch({ type: "guest", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// ARTICLE
// Get Article Stay
const getArticleStay =
  (dispatch) =>
  async ({ article_stay, res_id, prc_id, onAwait, onSuccess, onReject }) => {
    let query = "/reservation" + "/" + article_stay;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, prc_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Transaction Master
const getMasterBillTrx =
  (dispatch) =>
  async ({ bill, type, res_id, onAwait, onSuccess, onReject }) => {
    let query = "/" + bill + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET DEPOSIT
const getDeposit =
  (dispatch) =>
  async ({ type, res_id, onAwait, onSuccess, onReject }) => {
    let query = "/reservation" + "/" + type;
    if (onAwait) onAwait();

    await baseAPI
      .post(query, { res_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject();
      });
  };

// PRE-BILL
// Get Bill Article Room
const getPreBillRoom =
  (dispatch) =>
  async ({
    articleRoom,
    type,
    res_id,
    prc_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/" + articleRoom + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, prc_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Article Stay
const getPreBillArticle =
  (dispatch) =>
  async ({
    articleStay,
    type,
    res_id,
    prc_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/" + articleStay + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, prc_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// HISTORY GUEST BILL
// Get Bill Article Room
const getBillRoom =
  (dispatch) =>
  async ({
    articleRoom,
    type,
    res_id,
    prc_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/" + articleRoom + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, prc_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Article Stay
const getBillStay =
  (dispatch) =>
  async ({
    articleStay,
    type,
    res_id,
    prc_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/" + articleStay + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, prc_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// BILL
// Bill Headers
const getBillHeader =
  (dispatch) =>
  async ({ bill, res_id, price_id, onAwait, onSuccess, onReject }) => {
    let query = "/bill/" + bill;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, price_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Bill Details
const getBillDetail =
  (dispatch) =>
  async ({ bill, res_id, prc_id, onAwait, onSuccess, onReject }) => {
    let query = "/bill/" + bill;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id, prc_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Bill Details
const getMasterDetail =
  (dispatch) =>
  async ({ bill, res_id, onAwait, onSuccess, onReject }) => {
    let query = "/bill/" + bill;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { res_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// HOUSE KEEPING
// Get Room Status
const getRoomStatus =
  (dispatch) =>
  async ({ status, onAwait, onSuccess, onReject }) => {
    let query = "/room" + "/" + status;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Lost & Found
const getLostFound =
  (dispatch) =>
  async ({ lostFound, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + lostFound + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Housekeeping Activity
const getHouseKeepingAct =
  (dispatch) =>
  async ({ activity, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + activity + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Departed Today
const getArrivalDeparture =
  (dispatch) =>
  async ({ arrivalDeparture, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + arrivalDeparture + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Housekeeping Room Queue
const getRoomQueue =
  (dispatch) =>
  async ({ roomQueue, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + roomQueue + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// BACK OFFICE/NIGHT AUDIT
// POST NIGHT AUDIT
const postRunNA =
  (dispatch) =>
  async ({ runNA, type, created_by, onAwait, onSuccess, onReject }) => {
    let query = "/" + runNA + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { created_by })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET NIGHT AUDIT
const getNightAudit =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/" + "night-audit" + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET BY POST NIGHT AUDIT
const getByPostNightAudit =
  (dispatch) =>
  async ({ type, reservation_id, price_id, onAwait, onSuccess, onReject }) => {
    let query = "/" + "night-audit" + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { reservation_id, price_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET LAST AUDIT DATE
const getDateAudit =
  (dispatch) =>
  async ({ runNA, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + runNA + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// GET DASHBOARD HOUSE KEEPING
// GET ROOM SOLD
const getDashboard =
  (dispatch) =>
  async ({ type, category, onAwait, onSuccess, onReject }) => {
    let query = "/" + type + "/" + category;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// ROOM OCC
const getDashRoomOccupancy =
  (dispatch) =>
  async ({ dashRoomOccupancy, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + dashRoomOccupancy + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };
// AVERAGE RATE
const getDashRateAvg =
  (dispatch) =>
  async ({ dashRateAvg, type, onAwait, onSuccess, onReject }) => {
    let query = "/" + dashRateAvg + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Reservation MICE
const getReservationMice =
  (dispatch) =>
  async ({ mice, type, onAwait, onSuccess, onReject }) => {
    let query = "/fnb" + "/" + mice + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };
// Get Data Reservation MICE
const getArticleMice =
  (dispatch) =>
  async ({
    articleMice,
    type,
    reservation_id,
    onAwait,
    onSuccess,
    onReject,
  }) => {
    let query = "/fnb" + "/" + articleMice + "/" + type;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { reservation_id })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Vendor (Supplier) List
const getVendorList =
  (dispatch) =>
  async ({ vendor, onAwait, onSuccess, onReject }) => {
    let query = "/supplier-management-systems" + "/" + vendor;

    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Vendor Article List by ID Supplier
const getArticleVendorList =
  (dispatch) =>
  async ({ article_vendor, id_supplier, onAwait, onSuccess, onReject }) => {
    let query = "/supplier-management-systems" + "/" + article_vendor;
    if (onAwait) onAwait();

    baseAPI
      .post(query, { id_supplier })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Article Vendor List
const getVendorListArticle =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/article" + "/" + type;

    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Warehouse List
const getWarehouseList =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/master" + "/" + type;

    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Report Purchasing
const getPurchasingList =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/purchasing/report/" + type;

    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Report Purchase Order
const getPurchaseOrderList =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/purchasing/report/" + type;

    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get Data Report Inventory
const getInventoryReport =
  (dispatch) =>
  async ({ type, onAwait, onSuccess, onReject }) => {
    let query = "/inventory/report/" + type;

    if (onAwait) onAwait();

    baseAPI
      .get(query)
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// Get by Post Data Report Inventory
const getByPostInventoryReport =
  (dispatch) =>
  async ({ type, bodyReq, onAwait, onSuccess, onReject }) => {
    let query = "/inventory/report/" + type;

    if (onAwait) onAwait();

    baseAPI
      .post(query, { ...bodyReq })
      .then((response) => {
        dispatch({ type: "getData", payload: response.data });
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onReject) onReject(error);
      });
  };

// EXPORT CONTEXT and PROVIDER
export const { Provider, Context } = CreateContext(
  mainReducer,
  {
    // AUTH PERMISSIONS
    requestPermissions,

    // MAIN DATA CONTEXT
    // Main Dashboard
    getDashboard,
    getDashRoomOccupancy,
    getDashRateAvg,

    // FRONT OFFICE
    // Reservation
    getData,
    getReservation,
    getGuest,
    getGuestInHouse,
    getExpectedDeparture,
    getHistoryGuest,
    getForeign,
    getResDeposit,
    // Room List
    getArticleRoom,
    getArticleRoomGroup,
    // Segmentations and Rate Code
    getRatePrice,

    // Room Charts
    getRoomHistory,

    // Room Availability
    getReservations,

    // Reservation
    getReservationBySource,

    // Non Stay
    getNonStay,
    getHistoryNonStay,

    // Article before Check-In
    // getArticleNonStay,
    getArticleStay,

    // Master Article
    getMasterBillTrx,

    // Reservation Leaders
    getReservationLeader,

    // Deposit
    getDeposit,
    // Article After Check-In
    // PRE-BILL
    getPreBillRoom,
    getPreBillArticle,

    // Article After Check-Out
    // History Article
    getBillRoom,
    getBillStay,

    // Bill
    getBillHeader,
    getBillDetail,
    getMasterDetail,

    // HOUSE KEEPING
    // Room Monitoring
    getRoomStatus,
    getLostFound,
    getArrivalDeparture,
    getHouseKeepingAct,
    getRoomQueue,

    // BACK OFFICE
    // Night Audit
    postRunNA,
    getNightAudit,
    getByPostNightAudit,
    getDateAudit,

    // Vendor Management
    getVendorList,
    getArticleVendorList,
    getVendorListArticle,

    // Warehouse
    getWarehouseList,

    //GR Purchasing
    getPurchasingList,
    getPurchaseOrderList,

    // GR Inventory
    getInventoryReport,
    getByPostInventoryReport,
    // F & B
    // MICE
    getReservationMice,
    getArticleMice,
  },
  { data: [] }
);

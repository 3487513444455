// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Layout,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// REACT ICONS
import { FaMoneyBills } from "react-icons/fa6";
import { IoReturnUpBackOutline } from "react-icons/io5";

// PAGE COMPONENTS
import ListBillTable from "../../../Components/Table/MasterFolio/ListBill/Table";
import ListBillPayTable from "../../../Components/Table/MasterFolio/ListBillPay/Table";
import BillList from "../../../Components/Reusable/MasterFolio/BillList";
import GuestBillModal from "../../../Components/Modals/GuestBillList/Modal";
import { AppstoreFilled } from "@ant-design/icons";
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import NOTIFICATIONS

// DATA
export default function MasterFolioPage() {
  // CONTEXT
  const { getReservationBySource } = useContext(MainContext);

  // STATE MANAGEMENT
  // DATE
  const [value, setValue] = useState("");
  const [articleData, setArticleData] = useState([]);
  const [returnArticleData, setReturnArticleData] = useState(null);
  const [guestData, setGuestData] = useState(null);
  const [billReceiver, setBillReceiver] = useState([]);
  const [source, setSource] = useState(-1);
  const [type, setType] = useState(0);
  const [status, setStatus] = useState(1);
  const [element, setElement] = useState(null);
  const [bill, setBill] = useState({});
  // Receiver Bill
  const [optionValue, setOptionValue] = useState([]);
  const [receiver, setReceiver] = useState(null);
  // Search
  const [search, setSearch] = useState();
  // Refresh
  const [refresh, setRefresh] = useState(false);
  // Key Selector
  const [keySource, setKeySource] = useState(0);
  const [keyType, setKeyType] = useState(0);
  const [keyStatus, setKeyStatus] = useState(0);
  const [keyBill, setKeyBill] = useState(0);
  // Loading
  const [loading, setLoading] = useState(false);
  // open modal
  const [open, setOpen] = useState(false);
  const [isJoin, setIsJoin] = useState(false);

  // USEEFFECT CHANGE BILL
  // useEffect(() => {
  //   console.log("Selected Bill ->> ", bill);
  //   let cleanUp = false;

  //   if (
  //     Object.keys(bill).length > 0 &&
  //     Object.keys(bill).length === 1 &&
  //     Object.values(bill).every(
  //       (arr) => arr.length > 0 && arr.some((item) => item)
  //     ) &&
  //     !cleanUp
  //   ) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [bill]);

  // USEEFFECT CHANGE BILL RECEIVER
  useEffect(() => {
    let cleanUp = false;

    if (receiver && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [receiver]);

  // USE EFFECT FETCHDATA
  useEffect(() => {
    fetchData();
  }, []);

  //  FETCH DATA MASTER FOLIO BILL
  const fetchData = async () => {
    await getReservationBySource({
      reservation: "bill",
      type: "master-folio",
      source: [1, 2, 3],
      // source: typeof source == "object" ? [...source] : [source],
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          console.log("Response Master Folio => ", _response);

          // Handle duplicate response
          const filterUniqueReservations = (data) => {
            const uniqueReservations = [];

            data.forEach((item) => {
              if (
                !uniqueReservations.some(
                  (reservation) =>
                    reservation.reservation_id === item.reservation_id
                )
              ) {
                uniqueReservations.push(item);
              }
            });

            return uniqueReservations;
          };

          const _filtered = filterUniqueReservations(_response);
          // const filterShowData = _filtered.filter((val) => val.is_show);

          const mappedOptions = _filtered.map((item) => {
            let _status = "";

            switch (item?.payment_status) {
              case 1:
                _status = "Charge To Room";
                break;
              case 2:
                _status = "Cash Basis";
                break;

              default:
                _status = "-";
                break;
            }

            return {
              label: `${item?.reservation_id} - ${item?.guest_name
                ?.replace(/\d+$/, "")
                .trim()} `,
              value: item?.reservation_id,
              reservation_id: item?.reservation_id,
              guest_id: item?.guest_id,
              guest_name: item?.guest_name,
              start_date: item?.start_date,
              end_date: item?.end_date,
              payment_status: _status,
              payment_details: item?.payment_details,
              payment_type: item?.payment_type,
            };
          });

          setOptionValue(mappedOptions);
        } else {
          setOptionValue([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // HANDLE CHANGE BILL RECEIVER SELECTOR
  const handleChangeReceiver = (value, allKeys) => {
    console.log("Bill List All Keys ->> ", allKeys);

    setReceiver(allKeys);
    // console.log("Selected Values ->> ", selectedValues);
    // getBill(selectedValues);
  };

  // TYPE OPTIONS
  const groupOptions = [
    {
      label: "INDIVIDUAL",
      value: 1,
    },
    {
      label: "GROUP",
      value: 2,
    },
  ];

  // TYPE OPTIONS
  const typeOptions = [
    {
      label: "NORMAL BILL",
      value: 0,
    },
    {
      label: "SPLIT BILL",
      value: 1,
    },
    {
      label: "JOIN BILL",
      value: 2,
    },
  ];

  // TYPE OPTIONS
  const sourceOptions = [
    {
      label: "RESERVATION",
      value: 1,
    },
    {
      label: "MICE",
      value: 2,
    },
    {
      label: "OUTLET",
      value: 3,
    },
  ];

  // HANDLE REMOVE UNUSED KEYS
  const removeUnusedKeys = (bill, allKeys) => {
    // ambil label dari allKeys
    const validLabels = allKeys.map((item) => item.label);

    // loop each key in bill
    Object.keys(bill).forEach((key) => {
      // jika key tidak ada di allKeys, hapus key dari bill
      if (!validLabels.includes(key)) {
        delete bill[key];
      }
    });

    return bill;
  };

  // HANDLE CHANGE SOURCE
  const handleChangeSource = (value, allKeys) => {
    console.log("Value Source ->> ", value);
    console.log("AllKeys Source ->> ", allKeys);

    setSource(value);

    if (allKeys?.length > 0) {
      setElement(
        allKeys.map((item, index) => {
          return (
            <Col
              key={index}
              style={{
                textAlign: "start",
                // margin: "22px 30px",
                fontSize: 14,
                fontWeight: 400,
                maxWidth: 250,
                minWidth: 200,
              }}
            >
              Select {item.label} Bill:
              <BillList
                keyBill={keyBill}
                index={index}
                label={item.label}
                value={item.value}
                type={type}
                getBill={setBill}
                status={status}
              />
            </Col>
          );
        })
      );
    } else if (allKeys) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            // margin: "22px 30px",
            fontSize: 14,
            fontWeight: 400,
            maxWidth: 250,
            minWidth: 200,
          }}
        >
          Select {allKeys.label} Bill:
          <BillList
            keyBill={keyBill}
            index={0}
            label={allKeys.label}
            value={allKeys.value}
            type={type}
            getBill={setBill}
            status={status}
          />
        </Col>
      );
    } else {
      setElement(null);
    }

    // if bill not empty
    if (
      Object.keys(bill).length > 0 &&
      Object.values(bill).every((arr) => arr.length > 0)
    ) {
      // if type == join
      if (type == 2) {
        console.log("Bill Change ->> ", bill);
        const removeKeys = removeUnusedKeys(bill, allKeys); // sesuaikan bill berdasarkan allKeys/source yg dipilih
        setBill(removeKeys);
      } else {
        handleClearSource();
      }
    }
  };

  // HANDLE CLEAR SOURCE
  const handleClearSource = () => {
    setKeyBill(keyBill + 1);
    setBill({});
  };

  // HANDLE CHANGE TYPE
  const handleChangeType = (value) => {
    setType(value);

    if (source !== -1) {
      handleClearType();
    }
  };

  // HANDLE CHANGE STATUS
  const handleChangeStatus = (value) => {
    setStatus(value);

    if (type !== -1 && source !== -1) {
      handleClearStatus();
    }
  };

  // HANDLE CLEAR STATUS
  const handleClearStatus = () => {
    setKeyType(keyType + 1);
    setKeySource(keySource + 1);
    setKeyBill(keyBill + 1);

    setType(0);
    setSource(-1);
    setBill({});
    setElement(null);
  };

  // HANDLE CLEAR TYPE
  const handleClearType = () => {
    // handleClear();
    // change key for re-render
    setKeySource(keySource + 1);
    setKeyBill(keyBill + 1);
    // reset source and bill
    setSource(-1);
    setBill({});
    setElement(null);
    // setResId(null);
  };

  // Navigate
  const navigate = useNavigate();

  // HANDLE RETURN TO MENU Bill OUTSTANDING
  const handleReturn = () => {
    const path = "/front-office/bill-outstanding-list";

    navigate(`${path}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="master-folio-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaMoneyBills
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Master Folio`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 200,
                  }}
                >
                  Select Status:
                  <Select
                    style={{
                      width: "100%",
                    }}
                    defaultValue={status}
                    onChange={handleChangeStatus}
                    options={groupOptions}
                    allowClear
                    placeholder="Status"
                    showSearch
                    onClear={handleClearStatus}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 200,
                  }}
                >
                  Select Type:
                  <Select
                    key={keyType} // key untuk paksa re-render
                    style={{
                      width: "100%",
                    }}
                    defaultValue={type}
                    value={type}
                    onChange={handleChangeType}
                    options={typeOptions}
                    allowClear
                    placeholder="Type"
                    showSearch
                    onClear={handleClearType}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    marginRight: 15,
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 200,
                  }}
                >
                  Select Bill Receiver:
                  <Row
                    className="master-row"
                    align="top"
                    justify="start"
                    gutter={0}
                  >
                    <Col span={22} className="select-col">
                      <Select
                        className="bill-list"
                        // key={optionValue.key}
                        style={{ width: "100%" }}
                        // value={null}
                        placeholder="Receiver Bill"
                        showSearch
                        allowClear
                        // disabled={is_disable}
                        onChange={handleChangeReceiver}
                        // onClear={handleClear}
                        options={optionValue}
                      />
                    </Col>

                    <Col span={2} className="select-col">
                      <Tooltip
                        className="redirect-tooltip"
                        title={"Open Guest Bill"}
                      >
                        <Button
                          className="master-btn"
                          icon={
                            <AppstoreFilled
                              onClick={() => setOpen(true)}
                              style={{ fontSize: 20, color: "#3699FF" }}
                            />
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>

                {isJoin ? (
                  <Col
                    style={{
                      textAlign: "start",
                      // margin: "22px 30px",
                      fontSize: 14,
                      fontWeight: 400,
                      maxWidth: 250,
                      minWidth: 200,
                    }}
                  >
                    Select Source Member:
                    <Select
                      key={keySource} // key untuk paksa re-render
                      style={{
                        width: "100%",
                      }}
                      mode={type == 2 ? "multiple" : null}
                      onChange={handleChangeSource}
                      options={sourceOptions}
                      allowClear
                      placeholder="Source"
                      showSearch
                      onClear={handleClearSource}
                    />
                  </Col>
                ) : null}

                {element}

                <Col
                  className="col-search-btn"
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleReturn}
                  >
                    {`Return`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <Row gutter={[30, 30]} className="table-row">
              <Col
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="col-table"
              >
                <ListBillTable
                  returnArticleData={returnArticleData}
                  getArticleData={setArticleData}
                  getGuestData={setGuestData} // delete soon
                  getReturnArticleData={setReturnArticleData}
                  getBillReceiver={setBillReceiver}
                  is_search={setSearch} // delete soon
                  searchKey={value} // delete soon
                  getType={setType} // delete soon
                  is_refresh={refresh}
                  // selector baru
                  type={type}
                  source={source}
                  bill={bill}
                />

                {/* <ListBillTable
                getSource={setSource}
                getType={setType}
                fetchToggleStateReturn={fetchToggleStateReturn}
                fetchToggleState={(e) => {
                  setFetchToggleState(e);
                }}
                getSelectedBillData={setSelectedBillData}
                is_search={setSearch}
                searchKey={value}
              /> */}
              </Col>

              <Col
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="col-table"
              >
                <ListBillPayTable
                  articleData={articleData}
                  guestData={guestData} // delete soon
                  billReceiver={billReceiver}
                  getReturnArticleData={setReturnArticleData}
                  getArticleData={setArticleData}
                  typeData={type}
                  is_refresh={setRefresh}
                />

                {/* <ListBillPayTable
                fetchToggleStateReturn={(e) => {
                  setFetchToggleStateReturn(e);
                }}
                fetchToggleState={fetchToggleState}
                selectedBillData={selectedBillData}
                sourceData={source}
                typeData={type}
              /> */}
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>

      <GuestBillModal
        openModal={open}
        closeModal={setOpen}
        isJoin={setIsJoin}
        getType={handleChangeType}
        reservationData={receiver}
      />
    </>
  );
}

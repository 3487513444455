// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { GiOpenBook } from "react-icons/gi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import ArticleListTable from "../../../Components/Table/Master/ArticleList/Table";
import ArticleInventoryTable from "../../../Components/Table/Master/ArticleInventory/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// Options of Article Type
const options = [
  {
    label: "Article Sales",
    value: 1,
  },
  {
    label: "Article Inventory",
    value: 2,
  },
];

// CODE
export default function ArticleListPage() {
  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Article Type
  const [articleType, setArticleType] = useState(null);
  const [element, setElement] = useState(null);

  // Handle Articles
  const handleArticle = (val) => {
    const _type = val;
    console.log("Type: ", _type);

    setArticleType(_type);

    if (_type == 1) {
      setElement(<ArticleListTable />);
    } else if (_type == 2) {
      setElement(<ArticleInventoryTable />);
    } else {
      null;
    }
  };

  // NAVIGATE
  const handleReturn = () => {
    const path = "/configuration/master";

    // console.log("Pathname: ", pathname);

    navigate(`${path}`);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="article-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <GiOpenBook
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Article List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Article Types`}
                </Typography>

                <Select
                  className="article-type-select"
                  key="article-type-select"
                  allowClear
                  showSearch
                  value={articleType}
                  onChange={handleArticle}
                  options={options}
                  placeholder="Select an Article Types"
                  style={{
                    // minWidth: 150,
                    // maxWidth: 250,
                    width: 250,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {element}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Table's Column
const dataSource = [
  {
    key: "1",
    payment_type: "Debit",
    name: "bukopin-IDR",
    desc: "BUKOPIN",
    coa: "200-01-030",
  },
];

const { TextArea } = Input;
const { confirm } = Modal;

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// CODE
export default function GroupMenuTable() {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <FormOutlined
                  className="edit-table-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);

                    setOpen(true);
                    setEdit(true);
                  }}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              </Col>

              <Col span={12}>
                <DeleteFilled
                  className="delete-table-btn"
                  onClick={() => {
                    console.log("ABOUT TO DELETE > ", record);

                    showDeleteModal(record);
                  }}
                  style={{ fontSize: 20, color: "#F64E60" }}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ];

  // CONTEXT
  const { state } = useContext(MasterContext);

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // STATE MANAGEMENT
  //const [open, setOpen] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = () => {
    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete guest named .... from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
  };

  // useEffect(() => {
  //     fetchRecords()
  // }, [])

  // const fetchRecords = () => {
  //     setLoading(true)
  //     fetch(`http://192.168.88.103:3100/supabase/reservation`).then(res => {
  //         res.json().then(response => {
  //             setDataSource(response.msg)
  //             setLoading(false)
  //             //console.log(response)
  //         }).catch((error) => { console.log(error) });
  //     })
  // }

  return (
    <>
      <Row
        className="payment-card-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-table" style={{ padding: "30px" }}>
          <Row className="row-modal-btn" align="top" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-exp-btn"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  // onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Group Menu`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={fetchRecords}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="group-menu-table"
              key="group-menu-table"
              name="group-menu-table"
              loading={loading}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={columns}
              dataSource={dataSource}
              //onChange={onChange}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-group-menu-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                Add Article Summary
              </Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                Edit Article Summary
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="group-menu-modal-form"
          className="group-menu-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row className="row-group-menu-id">
            <Col span={8}>
              <Form.Item
                label="Code"
                name="code"
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Amount" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Name" name="name" style={{ width: 205 }}>
                <Input placeholder="Amount" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-group-menu-desc">
            <Col span={16}>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  showCount
                  maxLength={200}
                  style={{
                    height: 100,
                    width: 440,
                  }}
                  // onChange={onChangeTextArea}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created_date-group-menu">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created By" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified By" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-group-menu">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="row-submit-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Space size="middle">
              <Button
                type="primary"
                htmlType="submit"
                key={"submit"}
                // onClick={onFinish}
                className="submit-btn"
                style={{
                  backgroundColor: "#1BC5BD",
                  borderColor: "#1BC5BD",
                  marginRight: 30,
                }}
              >
                Submit
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

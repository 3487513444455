// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
// import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// BASEAPI
import {
  user_name,
  token,
  user_level,
  department,
  department_id,
} from "../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../API/Config/Api";

// Import ANTD Component
import { Button, Row, Col, Table, Tooltip, Modal, Form, Tag } from "antd";

// Import React Icons Components
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

import { LocalizationDate } from "../../../../../Reusable/Functions/Localizations/Localization";

// Import Page Components
import ReceivingApprovalModal from "../../../../../Modals/Inventory/ReceivingApproval/Modal";
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
// import ReceivingApprovalModal from "../../../../../Modals/Inventory/ReceivingApproval/Modal";
// import PurchaseApprovalModal from "../../../../Modals/Purchasing/Approvals/Modal";
// import ReceivingApprovalModal from "../../../../Modals/Inventory/ReceivingApproval/Modal";
// import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";
// import PurchaseRequestModal from "../../../../Modals/Purchasing/PurchaseRequest/Modal";

// Import Notification
import { failedFetch } from "../../../../../Reusable/Notification/Notification";
// import {
//   successArticlePR,
//   failedArticlePR,
//   failedFetch,
// } from "../../../../Reusable/Notification/Notification";
// import { masterIncomplete } from "../../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../../../Style/Global/Global";
// import { mainBody } from "../../../../../Style/Global/Global";

// MODALS
const { confirm } = Modal;

// CODE
export default function ApprovalReceivingTable(props) {
  // PROPS
  const { searchKey, request_date, is_search } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // const [bulks, setBulks] = useState([]);
  const [dataApprove, setDataApprove] = useState(null);
  // Boolean
  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    let _body = {};

    if (user_level == 4 || user_level == 5 || user_level == 10) {
      _body = {
        user_level: user_level,
      };
    }

    await axios
      .post(`${baseurl}/inventory/receiving-approval`, _body, {
        headers: { Authorization: `Bearer ${token || ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.length > 0) {
          const _res = response.data;
          // const _res = response.data.filter((e) => {
          //   if (e.si_status == 2 || e.si_status == 4) {
          //     return e;
          //   }
          // });
          console.log("RES >>>", _res);

          setData(_res);
          setBulks([]);
        } else {
          setData([]);
          setBulks([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, request_date]);

  // Handle Return
  const handleReturn = () => {
    const path = "/back-office/inventory";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryName = searchKey ? searchKey.toLowerCase() : null;
    const queryDate =
      request_date != "Invalid date" && request_date != null
        ? moment(request_date).format("YYYY-MM-DD")
        : null;

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.pr_number ? item?.pr_number.toLowerCase() : null;
        const dep = moment(item?.request_date).format("YYYY-MM-DD");

        // console.log(isNaN(Date.parse(queryDate)));

        return _name.indexOf(queryName) !== -1;
      });

      // console.log("UpdateList => ", updatedList)
      setData(updatedList);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-btn" align="middle">
              <Col span={24} className="action-col">
                <Tooltip
                  title="Approve Stock Incoming"
                  className="edit-tooltip"
                >
                  <CheckCircleOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setDataApprove(record);
                    }}
                    style={{
                      fontSize: 30,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Doc No.",
      dataIndex: "document_number",
      key: "document_number",
      width: 125,
    },
    {
      title: "Receiving Status",
      dataIndex: "si_status",
      key: "si_status",
      width: 175,
      sorter: (a, b) => a.id - b.id,
      render: (amount, record) => {
        let _tags = null;

        switch (record.si_status) {
          case 1:
            _tags = <Tag color="volcano">with PO & Non Direct</Tag>;
            break;

          case 2:
            _tags = <Tag color="magenta">w/o PO & Non Direct</Tag>;
            break;

          case 3:
            _tags = <Tag color="cyan">with PO & Direct</Tag>;
            break;

          case 4:
            _tags = <Tag color="purple">w/o PO & Direct</Tag>;
            break;

          case 5:
            _tags = <Tag color="green">DML & Non Direct</Tag>;
            break;

          case 6:
            _tags = <Tag color="gold">DML & Direct</Tag>;
            break;

          default:
            break;
        }

        return _tags;
      },
    },
    {
      title: "PO No.",
      dataIndex: "po_number",
      key: "po_number",
      width: 125,
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      key: "invoice",
    },
    {
      title: "Department",
      dataIndex: "dept_name",
      key: "dept_name",
      render: (e) => "-",
    },
    {
      title: "Arrival Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      // width: 125,
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Approval Status",
      dataIndex: "approved_list",
      key: "approved_list",
      render: (approval) => {
        if (approval?.length > 0) {
          let _tags = approval.map((value, index) => {
            // console.log("Value: ", value);
            // console.log("Index: ", _id);

            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {`Approve ${index + 1}`}
                </Tag>
              );
            } else if (value === 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`Rejected ${index + 1}`}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          // console.log(_tags);

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
        }
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approved_date",
      key: "approved_date",
      render: (date, record) => {
        if (date?.length > 0) {
          let _tags = record?.approved_list.map((value, index) => {
            // console.log("Value: ", value);
            // console.log("Index: ", _id);

            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {moment(LocalizationDate(date[index])).format(
                    "DD-MM-YYYY, HH:mm"
                  )}
                </Tag>
              );
            } else if (value == 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {moment(LocalizationDate(date[index])).format(
                    "DD-MM-YYYY, HH:mm"
                  )}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          // console.log(_tags);

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`-`}</Tag>;
        }
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Notes",
      dataIndex: "approval_name",
      key: "approval_name",
      render: (app, record) => {
        let color = "";
        let txt = "";

        switch (app) {
          case "Opex":
            color = "#1890ff";
            // txt = "Capital Expenditures"
            break;

          case "Capex":
            color = "#13c2c2";
            // txt = "Operational Expenditures"
            break;

          default:
            color = "default";
            // txt = "Operational Expenditures"
            break;
        }

        return (
          <Tag color={color} key={record?.pr_number}>
            {app ? app.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
  ];

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Purchase Request");
  };

  // Handle Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
    } else {
      fetchData();
    }
  };

  // Handle Close
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              {/* <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  // onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row> */}
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  // onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="purchase-req-table"
              name="purchase-req-table"
              key="purchase-req-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
                // x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record.document_number
                  ? record.document_number
                  : record.document_number;
              }}
            />
          </Row>
        </Col>
      </Row>

      <ReceivingApprovalModal
        is_open={modalOpen}
        is_close={handleClose}
        dataPurchase={dataApprove}
        is_refresh={handleRefresh}
      />
    </>
  );
}

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { IoFileTrayFullSharp } from "react-icons/io5";
import { GiCapitol } from "react-icons/gi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import InactiveTable from "../../../Components/Table/FrontOffice/NonStay/Inactive/Table";
import NonstayTable from "../../../Components/Table/FrontOffice/NonStay/Active/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function NonStayPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState(1);
  const [fetchToggle, setFetchToggle] = useState(false);
  const [fetchToggleReverse, setFetchToggleReverse] = useState(false);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="nonstay-guest-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <IoFileTrayFullSharp
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Non-Stay`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                className="col-input-search"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  className="input-search"
                  allowClear
                  placeholder="Guest's Name"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                className="col-search-btn"
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <Row gutter={[30, 30]} className="table-row">
            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              // span={12}
              className="col-table"
            >
              <InactiveTable
                selection={(e) => {
                  setSelected(e);
                }}
                fetchToggleState={(e) => {
                  setFetchToggle(e);
                }}
                fetchToggleStateReverse={fetchToggleReverse}
                is_search={setSearch}
                searchKey={value}
              />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              // span={12}
              className="col-table"
            >
              <NonstayTable
                getSelectorValue={selected}
                fetchToggleState={fetchToggle}
                fetchToggleStateReverse={(e) => {
                  setFetchToggleReverse(e);
                }}
                // dataArrangement={arrangement}
              />
            </Col>
          </Row>
        </Col>
      </Content>
    </Layout>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// API
import { token, user_name } from "../../../../API/Global/Payload";
import { baseurl } from "../../../../API/Config/Api";

// AMTD COMPONENTS
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";

// REACT ICONS
import { FiEdit } from "react-icons/fi";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// NOTIFICATION
import {
  ArticleIncomplete,
  failedFetch,
} from "../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// PAGE COMPONENT
import VendorArticleTable from "../../../Table/BackOffice/Article/Table";
import ArticleVendor from "../../../Reusable/Master/ArticleVendor/ArticleVendor";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Global Styles
import { cardBody } from "../../../../Style/Global/Global";

// Modals
const { confirm } = Modal;

// CODE
export default function ModalArticleVendorManagement(props) {
  const { is_open, is_close, dataVendor, params } = props;

  //CONTEXT
  const { getArticleVendorList } = useContext(MainContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [articleTable, setArticleTable] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [countArticleData, setCountArticleData] = useState(0);
  const [dataEdit, setDataEdit] = useState(null);
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(null);

  // SEARCH
  const [value, setValue] = useState("");
  const [search, setSearch] = useState();

  // Modals
  const [modalOpen, setModalOpen] = useState(false);

  // Loadings
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // // Reset Row Selection & Refresh
  const [refresh, setRefresh] = useState();
  const [selected, setSelected] = useState();

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      setData(dataVendor);

      handleOpen();
      fetchData();
      handleFormField(dataVendor);
    } else {
      setModalOpen(false);
      is_close(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // USE EFFECT DELETE
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // USE EFFECT EDIT
  useEffect(() => {
    let cleanUp = false;

    if (dataEdit && !cleanUp) {
      console.log("Data Edit == ", dataEdit);
      setFormFields(dataEdit);
    }

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataEdit, refresh]);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    console.log("DATA VENDORR >>>>> ", dataVendor);
    await getArticleVendorList({
      article_vendor: "supplier-article-list",
      id_supplier: dataVendor?.id_supplier
        ? dataVendor.id_supplier
        : data?.id_supplier,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("RESPONSE SUPPLIER ARTICLE LIST>> ", response);

        if (response?.data.msg != null) {
          let filtered = response.data.msg.filter((val) => {
            if (val.is_deleted == null || val.is_deleted == false) {
              return val;
            }
          });

          setArticleTable(filtered);
        } else {
          setArticleTable([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error >> ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // Handle Form Fields Edit
  const setFormFields = (value) => {
    const data = value;

    console.log("FORMS >>>>> ", data);

    form.setFieldsValue({
      article_name: data?.article_name,
      price: data?.price,
      is_ppn: data?.is_ppn,
    });
  };

  // Articles
  const selectedArticle = (value) => {
    console.log("ARTICLE NAME >>>>>> ", value);

    const _name = value?.article_name ? value.article_name : "";
    setSelectedData(value);

    form.setFieldsValue({
      article_name: _name,
    });
  };

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;

    form.setFieldsValue({
      supplier_code: _record.supplier_code,
      supplier_name: _record.supplier_name,
      is_ppn: false,
    });
  };

  // Handle Check
  const handleTax = (val) => {
    const _check = val.target.checked;
    const _fields = form.getFieldValue("price");

    let _ppn = (_fields * params?.tax_percentage) / 100;
    let _price = _fields + _ppn;

    // console.log("Params: ", params);
    // console.log("Price HPP: ", _fields);
    // console.log("Price after PPN: ", _ppn, " + ", _fields, " = ", _price);

    form.setFieldsValue({
      is_ppn: _check,
      ppn_price: _check ? _price : _fields,
    });
  };

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);
    setModalOpen(false);

    setIsLoading(false);

    setData([]);
    setDataEdit(null);
    setSelected([]);
    setUpdate(null);

    setCountArticleData(0);

    setEdit(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = async (e) => {
    const _article = e;

    console.log("Article to Add: ", _article);

    // check item in article table
    const findIndexItem = articleTable.findIndex((item) => {
      return (
        item.id === selectedData.article_id ||
        item.id_article === selectedData.article_id
      );
    });

    if (findIndexItem !== -1) {
      // if item found, return the alert notif 'item is exists'
      ArticleIncomplete(selectedData, { method: 1 });
    } else {
      // if item not found, set item to article table
      // console.log("Price: ", e.price);
      setArticleTable([
        ...(articleTable?.length > 0 ? articleTable : []),
        {
          ...selectedData,
          id: selectedData?.article_id,
          article_number: selectedData?.article_number
            ? selectedData.article_number
            : "",
          price: _article?.price > 0 ? _article.price : 0,
          is_ppn: _article?.is_ppn ? true : false,
          ppn_price: _article?.ppn_price > 0 ? _article.ppn_price : 0,
          is_new: true,
          // gross: selectedData?.gross ? selectedData.gross : 0,
          // department_id: selectedData?.department_id
          //   ? selectedData.department_id
          //   : null,
          // department_name: selectedData?.department_name
          //   ? selectedData.department_name
          //   : null,
          // article_name: selectedData?.article_name
          //   ? selectedData.article_name
          //   : "",
          // unit_price: selectedData.unit_price > 0 ? selectedData.unit_price : 0,
          // description: selectedData?.description
          //   ? selectedData.description
          //   : "",
          // created_by: selectedData?.created_by ? selectedData.created_by : "",
          // created_date: selectedData?.created_date
          //   ? selectedData.created_date
          //   : "",
          // updated_by: selectedData?.updated_by ? selectedData.updated_by : "",
          // updated_date: selectedData?.updated_date
          //   ? selectedData.updated_date
          //   : "",
          // deleted_by: selectedData?.deleted_by ? selectedData.deleted_by : "",
          // deleted_date: selectedData?.deleted_date
          //   ? selectedData.deleted_date
          //   : "",
          // is_deleted: selectedData?.is_deleted
          //   ? selectedData.is_deleted
          //   : false,
          // article_main_id: selectedData?.article_main_id
          //   ? selectedData.article_main_id
          //   : null,
          // article_main_name: selectedData?.article_main_name
          //   ? selectedData.article_main_name
          //   : "",
          // chart_of_account: selectedData?.chart_of_account
          //   ? selectedData.chart_of_account
          //   : "",
          // article_sub_id: selectedData?.article_sub_id
          //   ? selectedData.article_sub_id
          //   : null,
          // article_sub_name: selectedData?.article_sub_name
          //   ? selectedData.article_sub_name
          //   : null,
          // tax: selectedData?.tax ? selectedData.tax : null,
          // is_tax: selectedData?.is_tax ? selectedData.is_tax : false,
          // service: selectedData?.service ? selectedData.service : null,
          // is_service: selectedData?.is_service
          //   ? selectedData.is_service
          //   : false,
          // uom_id: selectedData?.uom_id ? selectedData.uom_id : null,
          // uom: selectedData?.uom ? selectedData.uom : "",
          // d_unit_id: selectedData?.d_unit_id ? selectedData.d_unit_id : null,
          // d_unit: selectedData?.d_unit ? selectedData.d_unit : "",
          // content_id: selectedData?.content_id ? selectedData.content_id : null,
          // content: selectedData?.content ? selectedData.content : "",
          // r_unit_id: selectedData?.r_unit_id ? selectedData.r_unit_id : null,
          // r_unit: selectedData?.r_unit ? selectedData.runit : "",
          // min_stock: parseInt(selectedData?.min_stock),
          // average_price: parseInt(selectedData?.min_stock),
          // article_type: selectedData?.article_type,
          // converted_uom: selectedData?.converted_uom
          //   ? selectedData.converted_uom
          //   : null,
        },
      ]);

      setCountArticleData((state) => state + 1);
    }

    form.setFieldsValue({
      price: selectedData.price,
    });

    form.resetFields(["article_name", "is_ppn", "ppn_price"]);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // ON FINISH SUBMIT
  const onSubmit = () => {
    const error = {
      errorFields: [
        {
          errors: ["Please, Add an Articles!"],
        },
      ],
    };

    setIsLoading(true);
    // console.log(articleTable);

    if (articleTable.length > 0) {
      showModalConfirm();
    } else {
      masterIncomplete(error);
    }
  };

  // Show Conform Modal
  const showModalConfirm = () => {
    // const submitContent = dataEdit;
    const submitEdit = form.getFieldsValue();

    console.log("Data Edit = ", dataEdit);
    console.log("Data About to Confirm = ", submitEdit);
    // console.log("Edit: ", edit);

    confirm({
      className: "article-vendor-confirm",
      title: `Are you sure want to ${
        edit == true
          ? `Update Article with named ${
              dataEdit?.article_name.toUpperCase() || " ---- "
            }?`
          : `Add New Article to Supplier/Vendors ${
              submitEdit?.supplier_name.toUpperCase() || " ---- "
            }?`
      }`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        handleFinish(submitEdit);
      },
      onCancel() {
        setIsLoading(false);
      },
      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Finish
  const handleFinish = (value) => {
    const _submit = value;

    console.log("Article Table: ", articleTable);
    console.log("Article Submit: ", _submit);

    if (edit === true) {
      let editData = {
        price: _submit?.price,
        updated_by: user_name,
        id_supplier_article: dataEdit.id_supplier_article,
        is_ppn: _submit.is_ppn,
        ppn_price: _submit.ppn_price,
        is_new: false,
      };

      // handleSubmit(editData);
      handleUpdate(editData);
    } else {
      let bodyReq = [];

      for (let i = 0; i < articleTable?.length; i++) {
        if (articleTable[i].is_new === true) {
          console.log("Article Table ID: ", articleTable[i], countArticleData); // , articleTable[i].id

          bodyReq.push({
            id_article: articleTable[i].id,
            id_supplier: dataVendor?.id_supplier,
            created_by: user_name,
            price: articleTable[i].price ? articleTable[i].price : 0,
            is_ppn: articleTable[i].is_ppn ? true : false,
          });
        }
      }

      // console.log("Body about to Submit: ", bodyReq);
      handleSubmit(bodyReq);
    }
  };

  // Handle Submit
  const handleSubmit = async (value) => {
    const contentSubmit = value;
    console.log("KONTEN SUBMIT: ", contentSubmit);

    await axios
      .post(
        `${baseurl}/supplier-management-systems/supplier-article`,
        { data: contentSubmit },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Response Add Article: ", response);
        masterSuccessNotification(response, { method: 0, source: "Article" });
        handleClose();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, { method: 0, source: "Article" });
        setIsLoading(false);
      });
  };

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  // Handle Submit Update
  const handleUpdate = async (value) => {
    const contentUpdate = value;

    if (contentUpdate.id_supplier_article) {
      const itemFilter = articleTable.map((item) =>
        item.id == dataEdit.id ? { ...item, price: contentUpdate.price } : item
      );

      // console.log("ITEM FILTER >>> ", itemFilter);

      setArticleTable(itemFilter);

      await axios
        .put(
          `${baseurl}/supplier-management-systems/supplier-article`,
          contentUpdate,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("Response Update Article: ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Article",
          });
          handleCancelUpdate();
          fetchData();
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, { method: 1, source: "Article" });
          setIsLoading(false);
        });
    } else {
      const itemFilter = articleTable.map((item) =>
        item.id == dataEdit.id ? { ...item, price: contentUpdate.price } : item
      );

      // console.log("ITEM FILTER >>> ", itemFilter);

      setArticleTable(itemFilter);
      masterSuccessNotification(dataEdit, {
        method: 1,
        source: "Article",
      });
      handleCancelUpdate();
    }
  };

  // Handle Cancel Update
  const handleCancelUpdate = () => {
    setSelected([]);
    setEdit(false);
    setDataEdit([]);
  };

  return (
    <>
      <Modal
        className="vendor-management-modal"
        key="vendor-management-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Vendor Management`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        getContainer={false}
        width={1000}
      >
        <Form
          className="purchase-req-form"
          key="purchase-req-form"
          name="purchase-req-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="info-row" gutter={30}>
                <Col span={12}>
                  <Form.Item
                    label="Supplier Code"
                    name="supplier_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Supplier Code!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Supplier Name"
                    name="supplier_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Supplier Name!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px", borderColor: "#EBEDF3" }}
              />

              <Row className="info-row" gutter={30} justify="start">
                <Col span={12}>
                  <Form.Item
                    label="Article Name"
                    name="article_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, select an Article Name!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <ArticleVendor
                      selectedArticle={selectedArticle}
                      article_name={
                        dataEdit != null ? dataEdit?.article_name : null
                      }
                      is_disable={edit ? true : false}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Price"
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please, input Price!",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <InputNumber
                      placeholder="Price"
                      addonBefore={currency}
                      className="price-input"
                      min={0}
                      formatter={(price) => {
                        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end" className="checklist-tax" gutter={30}>
                <Col span={12}>
                  <Form.Item
                    // label="Price Includes PPN"
                    name="is_ppn"
                    valuePropName="checked"
                  >
                    <Checkbox
                      className="check-ppn"
                      onChange={handleTax}
                    >{`Article Includes VAT (Current rate ${params.tax_percentage}%)`}</Checkbox>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Price After Tax" name="ppn_price">
                    <InputNumber
                      placeholder="Price"
                      addonBefore={currency}
                      disabled
                      className="price-input"
                      min={0}
                      formatter={(price) => {
                        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="article-row" justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                  hidden={edit == true ? true : false}
                  disabled={edit == true ? true : false}
                >{`Add Article Inventory`}</Button>

                <Button
                  className="next-btn"
                  type="primary"
                  hidden={edit == true ? false : true}
                  style={{ marginRight: "10px" }}
                  onClick={showModalConfirm}
                >{`Update`}</Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  hidden={edit == true ? false : true}
                  onClick={handleCancelUpdate}
                >{`Cancel`}</Button>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Article Inventory List`}
              </Divider>

              <Row
                className="row-search-component"
                justify="start"
                style={cardBody}
                // wrap={true}
              >
                <Space
                  align="end"
                  size={15}
                  className="header-space"
                  wrap={true}
                >
                  <Col
                    style={{
                      textAlign: "start",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <Typography
                      style={{ marginBottom: 10 }}
                    >{`Keywords`}</Typography>

                    <Input
                      allowClear
                      placeholder="Article Name"
                      onChange={handleSearch}
                      style={{
                        minWidth: 150,
                      }}
                    />
                  </Col>

                  <Col
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <Button
                      className="search-btn"
                      type="primary"
                      onClick={search}
                      icon={<SearchOutlined />}
                    >
                      {`Search`}
                    </Button>
                  </Col>
                </Space>
              </Row>

              <VendorArticleTable
                article={articleTable}
                set_article={setArticleTable}
                is_search={setSearch}
                searchKey={value}
                is_refresh={setRefresh}
                editArticle={setDataEdit}
                is_edited={setEdit}
                is_loading={loading}
                is_selected={setSelected}
                is_reset={selected}
                is_view={false}
                count={setCountArticleData}
                params_tax={params}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="submit-btn"
                  type="primary"
                  loading={isLoading}
                  onClick={onSubmit}
                  style={{
                    marginRight: 15,
                  }}
                  disabled={countArticleData > 0 ? false : true}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

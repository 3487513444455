// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// API
import { baseurl } from "../../../API/Config/Api";
// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  Typography,
  Layout,
  Form,
  InputNumber,
  Result,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  FileSearchOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Page Components
import BillReceipt from "../../../Components/Forms/Bill/BillReceipt";
import ModalCheckOutForm from "../../../Components/Forms/CheckOut/Form";
import MasterBill from "../../../Components/Forms/Bill/MasterBill";
import JoinBillTrx from "../../../Components/Forms/PreBill/JoinBillTrx";
import PreBillForm from "../../../Components/Forms/PreBill/PreBill";

// Master Components
import PreBillFormTrx from "../../../Components/Forms/PreBill/PreBillTrx";

// IMPORT GLOBAL
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Notifications
import { masterIncomplete } from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import {
  failedBill,
  failedCheckOut,
  successBill,
  successCheckOut,
} from "../../../Components/Reusable/Notification/Notification";
import POSReceiptBillFormTrx from "../../../Components/Forms/POSReceiptBill/POSReceiptBillTrx";

// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function POSPaymentSuccessPage() {
  // USE LOCATION & NAVIGATE
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  // Data
  const data = state;

  // STATE MANAGEMENT
  // Data
  const [leaders, setLeaders] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  // MODALS
  const [bill, setBill] = useState(false);
  const [master, setMaster] = useState(false);
  const [tyLetter, setTyLetter] = useState(false);
  // IS SUCCESS Check-Out
  const [isExist, setIsExist] = useState(true);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (state != null && !cleanUp) {
      console.log("Data CO >>>", state);

      setIsExist(true);
    } else {
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate(`${pathname.substring(0, pathname.length - 16)}`);
  };

  //   console.log("dataRes >>>", state);
  //   console.log("dataLeads >>>", leaders);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="expected-arrival-main-layout">
            <Row
              className="row-search-component"
              justify="start"
              gutter={[30, 30]}
              // style={mainBody}
            >
              <Col
                className="bill-col"
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Row className="row-test" style={mainBody}>
                  <Result
                    status={isExist ? "success" : "500"}
                    title={isExist ? "POS Order Successfully Paid !" : "ERROR!"}
                    subTitle={
                      isExist
                        ? `Payment ID : ${state?.payment_id}`
                        : `Sorry, something went wrong!`
                    }
                    extra={[
                      <Row
                        className="extra-row"
                        key={"extra-row"}
                        justify="center"
                        gutter={[0, 100]}
                      >
                        <Col hidden={!isExist} className="print-col" span={24}>
                          <Space
                            className="success-space"
                            wrap={true}
                            size="middle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="primary"
                              className="print-btn"
                              key="print-btn"
                              icon={<PrinterOutlined />}
                              onClick={() => {
                                setBill(true);
                              }}
                            >
                              {`Print Bill`}
                            </Button>
                          </Space>
                        </Col>

                        <Col className="return-col" span={24}>
                          <Space className="btn-space" size="large">
                            <Tooltip
                              className="return-tooltip"
                              title="Return to POS Table Order Page"
                              color="geekblue"
                              placement="right"
                            >
                              <Button
                                className="cancel-btn"
                                key="return-btn"
                                onClick={handleReturn}
                              >
                                {`Return`}
                              </Button>
                            </Tooltip>
                            {/* <Button
                            onClick={() => {
                              navigate("/front-office/guest-in-house");
                            }}
                          >
                            Go to Guest In House Menu
                          </Button> */}
                          </Space>
                        </Col>
                      </Row>,
                    ]}
                    style={{
                      width: "100%",
                      padding: "100px 0px 15px 0px",
                    }}
                  >
                    {isExist ? null : (
                      <>
                        <Space
                          className="result-space"
                          direction="vertical"
                          size={5}
                        >
                          <Paragraph>
                            <Text
                              strong
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {`The content you submitted may has the following error:`}
                            </Text>
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You are trying to reach success page without doing any process!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You're request is not valid!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`Please, check and modify the following information before resubmitting!`}
                          </Paragraph>
                        </Space>
                      </>
                    )}
                  </Result>
                </Row>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>

      <POSReceiptBillFormTrx
        openModal={bill}
        closeModal={setBill}
        payment_id={state?.payment_id}
      />

      <ModalCheckOutForm
        openModal={tyLetter}
        closeModal={setTyLetter}
        dataGuest={tyLetter == true ? state : ""}
        dataLead={tyLetter == true ? leaders : []}
      />
    </>
  );
}

// Import React's Components
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Col, Popover, Row, Tooltip, Typography } from "antd";

// Import React's Icons
import { FiEdit } from "react-icons/fi";
import { DeleteFilled } from "@ant-design/icons";
import { TfiMenuAlt } from "react-icons/tfi";
import { BsPatchExclamationFill } from "react-icons/bs";

// Import Page Components
import ModalGuestHistory from "../../Modals/GuestHistory/Modal";
import ModalEditGuest from "../../Modals/Guest/Modal";

// CODE
export default function GuestCardPopover(props) {
  // PROPS
  const { details, is_refresh, is_delete, optionValue } = props;

  // STATE MANAGEMENT
  // Data
  const [recordData, setRecordData] = useState(null);
  // Boolean
  const [open, setOpen] = useState(false);
  // MODALS Boolean
  const [history, setHistory] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  // Set Data
  const detail = details;

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);
  };

  // Handle Delete
  const handleDelete = (value) => {
    is_delete(value);
  };

  return (
    <>
      <Tooltip title={"Guest Card"} className="guest-card-tooltip">
        <Popover
          className="guest-card-title"
          key="guest-card-title"
          name="guest-card-title"
          title={
            <Row>
              <Col span={24}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    type: "primary",
                    fontWeight: 400,
                  }}
                >
                  {`Guest Card Option`}
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Row
                justify="start"
                //hidden
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  //   disabled={handleDisableCO(detail)}
                  icon={
                    <BsPatchExclamationFill
                      style={{
                        color: "#1890ff",
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  onClick={() => {
                    console.log("About to Show Visit History: ", optionValue);

                    setRecordData(optionValue);

                    setTimeout(() => {
                      setHistory(true);
                      handleClose();
                    }, 150);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Show Visit History`}
                </Button>
              </Row>

              <Row
                justify="start"
                //hidden
                style={{ width: 250 }}
              >
                <Button
                  className="row-reserve-opt"
                  //   disabled={handleDisableCO(detail)}
                  icon={
                    <FiEdit
                      style={{
                        color: "#1BC5BD",
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  onClick={() => {
                    console.log("About to Edit: ", detail);

                    setRecordData(detail);

                    setTimeout(() => {
                      setEdit(true);
                      handleClose();
                    }, 150);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                  }}
                >
                  {`Edit Data`}
                </Button>
              </Row>

              <Row justify="start" style={{ width: 250 }}>
                <Button
                  className="row-reserve-opt"
                  icon={
                    <DeleteFilled
                      style={{
                        color: "#F64E60",
                        fontSize: 20,
                        margin: "0px 7px 0px 15px",
                      }}
                    />
                  }
                  //   disabled={handleDisable(detail)}
                  onClick={() => {
                    console.log("About to Delete: ", detail);

                    // setRecordData(detail);

                    setTimeout(() => {
                      handleDelete(detail);
                      handleClose();
                    }, 150);
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 0px 10px",
                    width: 250,
                    height: 40,
                    borderColor: "transparent",
                    color: "#F64E60",
                  }}
                >
                  {`Delete Data`}
                </Button>
              </Row>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{
            margin: 0,
            padding: 0,
            backgroundColor: "#ff7875",
          }}
          placement="right"
        >
          <TfiMenuAlt
            className="menu-btn"
            style={{
              fontSize: 18,
              color: "#3699FF",
              display: "flex",
              justifyContent: "center",
              margin: "0px 5px 0px",
              cursor: "pointer",
            }}
          />
        </Popover>
      </Tooltip>

      <ModalGuestHistory
        is_open={history}
        is_close={setHistory}
        guest_id={history == true ? recordData : null}
      />

      <ModalEditGuest
        openModal={edit}
        closeModal={setEdit}
        dataGuest={edit == true ? recordData : null}
        is_refresh={is_refresh}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
  Alert,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined } from "@ant-design/icons";
import {
  PlusCircleTwoTone,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib/icons";

// Import Master Components
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterArticleMain from "../../../Reusable/Master/ArticleMain/ArticleMain";
import MasterArticleSub from "../../../Reusable/Master/ArticleSub/ArticleSub";
import MasterCOA from "../../../Reusable/Accounting/MainAccount/MainAccount";
import ArticleSales from "../../../Reusable/Master/ArticleSales/ArticleSales";
import MasterArticleList from "../../../Reusable/Master/ArticleList/ArticleList";
import MasterArticleInventory from "../../../Reusable/Master/ArticleInventory/ArticleInventory";

// Import Functions
import { Pricing } from "../../../Reusable/Functions/Pricing/Pricing";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { TaxesParameter } from "../../../Reusable/Functions/Taxes/Taxes";

// Import Global Styles
// Notification
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import {
  ArticleIncomplete,
  failedFetch,
} from "../../../Reusable/Notification/Notification";

// Confirm Modal
const { confirm } = Modal;

// CODE
export default function ModalArticleSales(props) {
  // PROPS
  const { is_open, is_close, article, is_refresh } = props;

  // Params
  const currency = CurrencySymbols().code;
  const taxes = TaxesParameter();

  // CONTEXT
  const { getArticleInventory } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [show, setShow] = useState(false);
  const [isArticleFound, setArticleFound] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isArticleSales, setIsArticleSales] = useState(false);
  // Data Edit
  const [data, setData] = useState([]);
  // Dept, Main and Sub Group
  const [dept, setDept] = useState({
    department_name: "",
    department_id: 0,
  });
  const [mainGroup, setMainGroup] = useState({
    article_main_name: "",
    article_main_id: 0,
  });
  const [subGroup, setSubGroup] = useState({
    article_sub_name: "",
    article_sub_id: 0,
  });
  // Tax & Service
  const [isServiceTax, setIsServiceTax] = useState(false);
  const [isTax, setIsTax] = useState(false);
  const [isService, setIsService] = useState(false);
  const [percentages, setPercentages] = useState({
    tax: taxes?.tax_percentage,
    service: taxes?.service_percentage,
  });
  // Loading
  const [isDelete, setIsDelete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open === true && !cleanUp) {
      handleOpen();
      fetchData();
    } else {
      handleCancel();
      setIsArticleSales(false);
    }

    if (article && !cleanUp) {
      setEdit(true);
      setData(article);
      setShow(true);

      handleFormFields(article);
    } else {
      setEdit(false);
      setData(null);
      setShow(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [article, is_open]);

  // Fetch Data
  const fetchData = async () => {
    await getArticleInventory({
      inventory: "list",
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response article => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted && val?.article_type === 1;
          });

          console.log("FILTERED ARTICLE : ", filtered);

          setArticleList(filtered);
        } else {
          setArticleList([]);
        }
        setIsLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setIsLoading(false);
        failedFetch(error);
      },
    });
  };

  // SET FIELDS FORM
  const handleFormFields = (val) => {
    const _record = val;
    console.log("About to Edit: ", _record);

    setIsService(_record?.is_service);
    setIsTax(_record?.is_tax);
    setIsServiceTax(_record?.is_service_taxed);

    form.setFieldsValue({
      department_id: _record?.department_id,
      department_name: _record?.department_name,
      article_main_id: _record?.article_main_id,
      article_main_name: _record?.article_main_name,
      article_sub_id: _record?.article_sub_id,
      article_sub_name: _record?.article_sub_name,

      article_id: _record?.id,
      article_number: _record?.article_number,
      article_name: _record?.article_name,
      description: _record?.description,
      unit_price: _record?.unit_price,
      cost: _record?.cost,
      chart_of_account: _record?.chart_of_account,
      // coa_invt_id: _record.coa_invt_id,

      is_tax: _record?.is_tax,
      is_service: _record?.is_service,
      is_service_taxed: _record?.is_service_taxed,
      tax: _record?.tax,
      service: _record?.service,
      gross: _record.gross,

      created_by: _record?.created_by,
      created_date: _record?.created_date,
      modified_by: _record?.updated_by,
      modified_date: _record?.updated_date,
    });
  };

  // HANDLE PERCENTAGES
  const handlePercent = async (val) => {
    const _params = val;

    console.log("Params Taxes: ", _params);

    setPercentages({
      service: _params.service_percentage,
      tax: _params.tax_percentage,
    });
  };

  // HANDLE PRICING
  const handlePricing = (val, servs, taxes, servtaxes) => {
    let servBool = typeof servs == "boolean" ? servs : isService;
    let taxBool = typeof taxes == "boolean" ? taxes : isTax;
    let servTaxBool = typeof servtaxes == "boolean" ? servtaxes : isServiceTax;

    let _price = val;

    // console.log("Nett Unit Price: ", _price);
    console.log("Percentages: ", percentages);
    // console.log(`IS TAX: ${isTax} && `, `IS SERVICE: ${isService}`);

    let _serviceOnly = _price * (percentages.service / 100);
    let _taxOnly = _price * (percentages.tax / 100);
    // Gross Service & Tax
    let _grossSer = _price + _serviceOnly;
    let _grossTax = _price + _taxOnly;

    let _serOnly = parseFloat(_serviceOnly).toFixed(0);
    let _taxed = parseFloat(_taxOnly).toFixed(0);

    let _grossService = parseFloat(_grossSer).toFixed(0);
    let _grossTaxed = parseFloat(_grossTax).toFixed(0);

    // console.log("Service Only --> ", _serOnly, _grossService);
    // console.log("Tax Only --> ", _taxed, _grossTaxed);

    if (taxBool && servBool && servTaxBool) {
      let _newPrice = Pricing({
        nett: _price,
        tax: percentages.tax,
        service: percentages.service,
      });

      //   console.log("New Price: ", _newPrice);

      form.setFieldsValue({
        gross: _newPrice._gross,
        service: _newPrice._service,
        tax: _newPrice._taxed,
      });
    } else if (taxBool && servBool && !servTaxBool) {
      var _ser = _price * (percentages.service / 100);
      var _tax = _price * (percentages.tax / 100);
      var _gro = _price + _ser + _tax;

      form.setFieldsValue({
        gross: parseFloat(_gro).toFixed(0),
        service: parseFloat(_ser).toFixed(0),
        tax: parseFloat(_tax).toFixed(0),
      });
    } else if (taxBool && !servBool && !servTaxBool) {
      form.setFieldsValue({
        gross: _grossTaxed,
        service: 0,
        tax: _taxed,
      });
    } else if (!taxBool && servBool && !servTaxBool) {
      form.setFieldsValue({
        gross: _grossService,
        service: _serOnly,
        tax: 0,
      });
    } else {
      form.setFieldsValue({
        gross: _price,
        service: 0,
        tax: 0,
      });
    }
  };

  // Article Main & Article Sub
  const getDepartment = (value) => {
    console.log(value);
    // setEditDataDept(value);

    setDept({
      department_name: value?.name,
      department_id: value?.dept_id,
    });

    form.setFieldsValue({
      department_name: value?.name,
      department_id: value?.dept_id,
      article_name_addon: isArticleFound && value?.code,
    });
  };

  // Article Main & Article Sub
  const getArticleMain = (value) => {
    console.log("Article Main: ", value);

    setMainGroup({
      article_main_name: value?.name,
      article_main_id: value?.main_id,
    });

    form.setFieldsValue({
      article_main_name: value?.name,
      article_main_id: value?.main_id,
    });
  };

  // Article Main & Article Sub
  const getArticleSub = (value) => {
    console.log(value);

    setSubGroup({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });

    form.setFieldsValue({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });
  };

  // Master COA
  const getCoaName = (value) => {
    console.log(value);

    form.setFieldsValue({
      chart_of_account: value?.code,
    });
  };

  // Checked Service Tax
  const onCheckServiceTax = (value) => {
    const _bool = value.target.checked;
    const _nett = form.getFieldValue("unit_price");

    setIsServiceTax(_bool);

    if (edit == true) {
      // data?.tax = 0;
      data.is_service_taxed = _bool;
    }

    form.setFieldsValue({
      is_service_taxed: _bool,
      // tax: 0,
    });

    handlePricing(_nett, isService, isTax, _bool);
  };

  // Checked Tax & Service
  const onCheckTax = (value) => {
    const _bool = value.target.checked;
    const _nett = form.getFieldValue("unit_price");

    setIsTax(_bool);

    if (edit == true) {
      // data?.tax = 0;
      data.is_tax = _bool;
    }

    form.setFieldsValue({
      is_tax: _bool,
      // tax: 0,
    });

    handlePricing(_nett, isService, _bool, isServiceTax);
  };

  const onCheckService = (value) => {
    const _bool = value.target.checked;
    const _nett = form.getFieldValue("unit_price");

    setIsService(_bool);

    if (edit == true) {
      // data?.service = 0;
      data.is_service = _bool;
    }

    form.setFieldsValue({
      is_service: _bool,
      // service: 0,
    });

    handlePricing(_nett, _bool, isTax, isServiceTax);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
    setIsArticleSales(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    is_close();
    setOpen(false);
    setEdit(false);
    setIsDelete(true);

    setDept({
      department_name: "",
      department_id: 0,
    });

    setMainGroup({
      article_main_name: "",
      article_main_id: 0,
    });

    setSubGroup({
      article_sub_name: "",
      article_sub_id: 0,
    });

    setIsService(false);
    setIsTax(false);
    setIsServiceTax(false);
    setIsLoading(false);

    setShow(false);
    setArticleFound(false);

    setSelectedArticle(null);
    setIsArticleSales(false);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const content = value;

    console.log("Submit => ", content);

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to ${
        edit ? "Update" : "Add a New"
      } Article with named ${content?.article_name.toUpperCase() || " --- "}?`,
      // icon: <ExclamationCircleTwoTone twoToneColor="red" />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    // console.log(e);
    console.log(dept);
    console.log(mainGroup);
    console.log(subGroup);

    // handleSubmit(e);
    setIsLoading(true);
    showModalConfirm(e);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log("ON FINISH FAILED: ", e.values);

    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentSubmit = e;
    console.log("Handle Submit ==> ", contentSubmit);

    if (edit == false) {
      const findIndexItem = articleList.findIndex((item) => {
        if (contentSubmit?.article_name_addon) {
          return (
            contentSubmit.article_name +
              " " +
              contentSubmit.article_name_addon ===
            item.article_name
          );
        } else {
          return contentSubmit?.article_name === item.article_name;
        }
      });

      if (findIndexItem !== -1) {
        ArticleIncomplete(contentSubmit, { method: 1 });
        setIsLoading(false);
      } else {
        await axios
          .post(
            `${baseurl}/article/list`,
            {
              department_id: dept.department_id,
              department_name:
                contentSubmit?.department_name || dept.department_name,

              article_main_id: mainGroup.article_main_id,
              article_main_name:
                contentSubmit?.article_main_name || mainGroup.article_main_name,

              article_sub_id: subGroup.article_sub_id,
              article_sub_name:
                contentSubmit?.article_sub_name || subGroup.article_sub_name,

              // article_id: contentSubmit?.article_id
              //   ? parseInt(contentSubmit.article_id)
              //   : 0,
              article_number: contentSubmit?.article_number
                ? contentSubmit.article_number
                : null,
              article_name:
                (contentSubmit?.article_name_addon
                  ? contentSubmit?.article_name +
                    " " +
                    contentSubmit?.article_name_addon
                  : contentSubmit?.article_name) || " ",
              unit_price: contentSubmit?.unit_price
                ? contentSubmit.unit_price
                : 0,
              // cost: contentSubmit?.cost ? contentSubmit.cost : "",

              description: contentSubmit?.description
                ? contentSubmit.description
                : "",

              gross:
                contentSubmit?.gross > 0
                  ? parseInt(contentSubmit.gross).toFixed(0)
                  : 0,

              // tax: isTax == true ? contentSubmit.tax : 0,
              // is_tax: isTax ? isTax : false,
              // service: isService == true ? contentSubmit.service : 0,
              // is_service: isService ? isService : false,
              tax:
                contentSubmit?.tax > 0
                  ? parseInt(contentSubmit.tax).toFixed(0)
                  : 0,
              is_tax: contentSubmit?.tax > 0 ? true : false,
              service:
                contentSubmit?.service > 0
                  ? parseInt(contentSubmit.service).toFixed(0)
                  : 0,
              is_service: contentSubmit?.service > 0 ? true : false,
              // is_service_taxed: contentSubmit?.service > 0 && contentSubmit?.tax > 0 && contentSubmit?.is_service_taxed == true ? true : false,
              chart_of_account: contentSubmit?.chart_of_account
                ? contentSubmit.chart_of_account
                : "",

              // uom: null,
              // d_unit: null,
              // content: null,
              // r_unit: null,
              min_stock: null,
              average_price: null,
              article_type: 1,
              // converted_uom: null,

              mess_unit: null,
              delivery_unit: null,
              delivery_content: null,
              recipe_unit: null,
              mess_content: null,

              is_dml: false,

              created_by: user_name,
            },
            { headers: { Authorization: `Bearer ${token ? token : ""}` } }
          )
          .then((response) => {
            console.log("POST Art List => ", response);
            masterSuccessNotification(response, {
              method: 0,
              source: "Article Sales",
            });

            is_refresh();
            handleCancel();
          })
          .catch((error) => {
            console.log("POST Art List => ", error);
            masterFailedNotification(error, {
              method: 0,
              source: "Article Sales",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      await axios
        .put(
          `${baseurl}/article/list`,
          {
            id: data?.id,

            department_id:
              dept.department_id > 0 ? dept.department_id : data?.department_id,
            department_name:
              contentSubmit?.department_name || data?.department_name,

            article_main_id:
              mainGroup.article_main_id > 0
                ? mainGroup.article_main_id
                : data?.article_main_id,
            article_main_name: contentSubmit?.article_main_name
              ? contentSubmit.article_main_name
              : data?.article_main_name,

            article_sub_id:
              subGroup.article_sub_id > 0
                ? subGroup.article_sub_id
                : data?.article_sub_id,
            article_sub_name: contentSubmit.article_sub_name
              ? contentSubmit.article_sub_name
              : data?.article_sub_name,

            // article_id: contentSubmit.article_id
            //   ? parseInt(contentSubmit.article_id)
            //   : data?.article_id,
            article_number: contentSubmit?.article_number
              ? contentSubmit.article_number
              : data?.article_number,
            article_name: contentSubmit?.article_name
              ? contentSubmit.article_name
              : data?.article_name,
            unit_price: contentSubmit?.unit_price
              ? contentSubmit.unit_price
              : data?.unit_price,
            // cost: contentSubmit?.cost ? contentSubmit.cost : data?.cost,

            chart_of_account: contentSubmit?.chart_of_account
              ? contentSubmit.chart_of_account
              : data?.chart_of_account,

            description: contentSubmit?.description
              ? contentSubmit.description
              : null,

            gross:
              contentSubmit?.gross > 0
                ? parseInt(contentSubmit.gross).toFixed(0)
                : data?.gross,

            tax:
              contentSubmit?.tax > 0
                ? parseInt(contentSubmit.tax).toFixed(0)
                : 0,
            is_tax: contentSubmit?.tax > 0 ? true : false,
            service:
              contentSubmit?.service > 0
                ? parseInt(contentSubmit.service).toFixed(0)
                : 0,
            is_service: contentSubmit?.service > 0 ? true : false,
            is_service_taxed: contentSubmit?.service > 0 && contentSubmit?.tax > 0 && contentSubmit?.is_service_taxed == true ? true : false,

            // uom: null,
            // d_unit: null,
            // content: null,
            // r_unit: null,
            // min_stock: null,
            // average_price: null,
            // converted_uom: null,
            // article_type: data?.article_type > 0 ? data?.article_type : 1,

            // uom: null,
            // d_unit: null,
            // content: null,
            // r_unit: null,
            min_stock: null,
            average_price: null,
            article_type: 1,
            // converted_uom: null,

            mess_unit: null,
            delivery_unit: null,
            delivery_content: null,
            recipe_unit: null,
            mess_content: null,

            is_dml: false,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("PUT Art List => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Article Sales",
          });

          is_refresh();
          handleCancel();
        })
        .catch((error) => {
          console.log("PUT Art List => ", error);
          masterFailedNotification(error, {
            method: 1,
            source: "Article Sales",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE FIND ARTICLE
  const handleFind = (value) => {
    // console.log("FIND E : ", e);
    // const _name = e?.article_name_find;
    setArticleFound(false);
    setShow(false);

    if (value) {
      // const findData = articleList.filter(
      //   (item) => item?.article_name.toLowerCase() === _name.toLowerCase()
      // );

      // if (findData.length !== 0) {
      // setArticleInventory(findData[0]);
      setArticleFound(true);
      setShow(true);

      form.setFieldsValue({
        article_name: value?.article_name,
        article_number: value?.article_number,
      });
    } else {
      setArticleFound(false);
      setShow(true);
    }
  };

  // HANDLE NEW ARTICLE
  const handleNewArticle = () => {
    setArticleFound(false);
    setShow(true);

    setSelectedArticle(null);
    form.resetFields();
  };

  // HANDLE SELECTED ARTICLE
  const handleSelectedArticle = (value) => {
    setSelectedArticle(value);
  };

  return (
    <>
      <Modal
        className="add-edit-article-list-modal"
        title={
          edit == false ? (
            <Row className="row-modal-title">
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Article Sales`}
              </Typography>
            </Row>
          ) : (
            <Row className="row-modal-title">
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Article Sales`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="article-list-modal-form"
          className="article-list-modal-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            unit_price: 0,
          }}
          layout="vertical"
          style={{ padding: "10px 36px" }}
        >
          {!edit ? (
            <>
              <Row gutter={30} className="row-id-card-type">
                <Col span={12}>
                  <Form.Item label="Article Name">
                    <ArticleSales
                      selectedArticle={handleSelectedArticle}
                      article_name={
                        selectedArticle?.article_name
                          ? selectedArticle.article_name
                          : null
                      }
                      is_disable={false}
                      is_article_sales={isArticleSales}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Row className="btn-row" justify="start">
                    <Button
                      className="next-btn"
                      type="primary"
                      icon={<SearchOutlined />}
                      onClick={() => {
                        handleFind(selectedArticle);
                      }}
                      style={{ marginTop: 30, marginRight: 25 }}
                    >
                      {`Select Data`}
                    </Button>

                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        handleNewArticle();
                      }}
                      style={{ marginTop: 30, marginRight: 25 }}
                    >
                      {`New Article`}
                    </Button>
                  </Row>
                </Col>
              </Row>

              {isArticleFound ? (
                <Row justify="center" style={{ marginBottom: 20 }}>
                  <Col span={24} style={{ width: "100%" }}>
                    <Alert
                      message="Article Found!"
                      description={`Article Number = ${form.getFieldValue(
                        "article_number"
                      )}, Article Name = ${form.getFieldValue(
                        "article_name"
                      )}, Article Add-On = ${
                        form.getFieldValue("article_name_addon") || "-"
                      }`}
                      type="info"
                      showIcon
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              ) : null}
              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          {show ? (
            <Row justify="start" className="row-main-modal">
              <Col span={24} className="col-main-modal">
                <Row gutter={30} className="row-art-list-id">
                  <Col span={8}>
                    <Form.Item
                      label="Department"
                      name="department_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, select a Department!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <MasterDepartment
                        getDepartment={getDepartment}
                        department_name={edit ? data?.department_name : null}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Article Main-Group"
                      name="article_main_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, select an Article Main-Group!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <MasterArticleMain
                        getArticleMain={getArticleMain}
                        getDepartmentId={dept.department_id}
                        mainName={edit ? data?.article_main_name : null}
                        addArticleState={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Article Sub-Group"
                      name="article_sub_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, select an Article Sub-Group!",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <MasterArticleSub
                        getArticleSub={getArticleSub}
                        articleSubName={edit ? data?.article_sub_name : null}
                        getArticleMainId={mainGroup.article_main_id}
                        getDepartmentId={dept.department_id}
                        addArticleState={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  style={{ margin: "5px 0px", background: "#EBEDF3" }}
                />

                <Row
                  gutter={30}
                  className="row-art-list-id"
                  justify="start"
                  align="bottom"
                >
                  <Col span={isArticleFound ? 8 : 12}>
                    <Form.Item
                      label="Article Name"
                      name="article_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, input Article Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Article Name"
                        disabled={isArticleFound ? true : false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={isArticleFound ? 8 : 12} className="col-art-no">
                    <Form.Item
                      label="Article Number/Code"
                      name="article_number"
                      rules={[
                        {
                          required: true,
                          message: "Please, input Article Number/Code!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Article Number"
                        disabled={isArticleFound ? true : false}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={8}
                    className="col-art-no"
                    hidden={isArticleFound ? false : true}
                  >
                    <Form.Item
                      label="Add-On"
                      name="article_name_addon"
                      rules={
                        !edit && [
                          {
                            required: true,
                            message: "Please, input Article Add-On!",
                          },
                        ]
                      }
                    >
                      <Input placeholder="Add-On" disabled />
                    </Form.Item>
                  </Col>

                  <Form.Item label="Article ID" name="article_id" hidden>
                    <InputNumber
                      disabled
                      placeholder="Article ID"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Row>

                <Row
                  gutter={30}
                  className="row-art-list-coa"
                  justify="start"
                  align="bottom"
                >
                  <Col span={7} className="col-price">
                    <Form.Item
                      label="Price (Nett)"
                      name="unit_price"
                      rules={[
                        {
                          required: true,
                          message: "Please, input Article's price!",
                        },
                      ]}
                    >
                      <InputNumber
                        addonBefore={currency}
                        placeholder="Article Price"
                        min={0}
                        onChange={handlePricing}
                        formatter={(unit_price) =>
                          `${unit_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        // parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col span={8} className="col-cost">
                  <Form.Item
                  label="Cost"
                  name="cost"
                  style={{
                    width: "100%",
                  }}
                  >
                  <Input placeholder="Cost" />
                  </Form.Item>
                </Col> */}

                  <Col span={6} className="col-service">
                    <Form.Item
                      name="is_service"
                      valuePropName="checked"
                      style={{ fontSize: 15 }}
                    >
                      <Checkbox
                        // checked={isTax}
                        onChange={onCheckService}
                        className="tax-checkbox"
                      >
                        {`Include Service`}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={5} className="col-tax">
                    <Form.Item
                      name="is_tax"
                      valuePropName="checked"
                      style={{ fontSize: 15 }}
                    >
                      <Checkbox
                        // checked={isTax}
                        onChange={onCheckTax}
                        className="tax-checkbox"
                      >
                        {`Include Tax`}
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {edit == true ? (<Col span={6} className="col-tax">
                    <Form.Item
                      name="is_service_taxed"
                      valuePropName="checked"
                      style={{ fontSize: 15 }}
                    >
                      <Checkbox
                        // checked={isTax}
                        onChange={onCheckServiceTax}
                        className="service-tax-checkbox"
                        disabled={isService && isTax ? false : true}
                      >
                        {`Include Service Tax`}
                      </Checkbox>
                    </Form.Item>
                  </Col>) : null}
                </Row>

                {/* <Divider 
            className="divider-form"
           style={{ margin: "5px 0px", color: "#EBEDF3" }}
        /> */}

                <Row gutter={30} className="row-coa">
                  <Col span={16} className="col-coa">
                    <Form.Item
                      label="COA (Chart of Account)"
                      name="chart_of_account"
                    >
                      <MasterCOA
                        getCoaName={getCoaName}
                        coa_name={edit == true ? data?.chart_of_account : ""}
                        // is_fromRoom={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  style={{ margin: "5px 0px", background: "#EBEDF3" }}
                />

                <Row
                  className="row-tax-service"
                  gutter={30}
                  style={{ width: "100%" }}
                >
                  <Col span={8} className="col-tax">
                    <Row className="row-tax">
                      <Form.Item label="Tax" name="tax">
                        <InputNumber
                          disabled
                          // ={isTax == true ? false : true}
                          min={0}
                          // max={100}
                          placeholder="Tax"
                          addonBefore={currency}
                          formatter={(tax) =>
                            ` ${tax}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                        />
                      </Form.Item>
                    </Row>
                  </Col>

                  <Col span={8} className="col-service">
                    <Row className="row-service">
                      <Form.Item label="Service" name="service">
                        <InputNumber
                          disabled
                          // ={isService == true ? false : true}
                          placeholder="Service"
                          addonBefore={currency}
                          min={0}
                          formatter={(service) =>
                            ` ${service}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          // max={100}
                        />
                      </Form.Item>
                    </Row>
                  </Col>

                  <Col span={8} className="col-gross">
                    <Row className="row-gross">
                      <Form.Item
                        label="Gross"
                        name="gross"
                        rules={[
                          {
                            required: true,
                            message: "Please, input Gross!",
                          },
                        ]}
                        // style={{ margin: "5px 0px 0px" }}
                      >
                        <InputNumber
                          disabled
                          min={0}
                          // max={100}
                          placeholder="Gross"
                          addonBefore={currency}
                          formatter={(gross) =>
                            ` ${gross}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  style={{ margin: "5px 0px", background: "#EBEDF3" }}
                />

                <Row className="row-art-list-desc">
                  <Col span={16} className="desc-col">
                    <Form.Item label="Description" name="description">
                      <Input.TextArea
                        showCount
                        allowClear
                        maxLength={200}
                        rows={4}
                        placeholder="Descriptions"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  style={{ margin: "5px 0px", background: "#EBEDF3" }}
                />

                {edit == true ? (
                  <>
                    <Row gutter={30} className="created_date-art-list">
                      <Col span={8}>
                        <Form.Item
                          label="Created By"
                          name="created_by"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Created by" disabled />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Created Date"
                          name="created_date"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Created Date" disabled />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Upated By"
                          name="upated_by"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Upated by" disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="mod-date-art-sum">
                      <Col span={8}>
                        <Form.Item
                          label="Upated Date"
                          name="upated_date"
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Upated Date" disabled />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider
                      style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
                    />
                  </>
                ) : null}

                <Row className="row-modal-btn" justify="end" align="middle">
                  <Button
                    type="primary"
                    htmlType="submit"
                    key={"submit"}
                    // onClick={onFinish}
                    loading={isLoading}
                    className="submit-btn"
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {`Submit`}
                  </Button>

                  <Button
                    type="default"
                    className="cancel-btn"
                    onClick={handleCancel}
                  >
                    {`Cancel`}
                  </Button>
                </Row>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_children } from "../../API/Global/Payload";

// Import Page Components
import RolesPage from "../../Pages/Configuration/Roles/RolesPage";
import Register from "../../Pages/Auth/Register/Register";
import AccessControlPage from "../../Pages/Auth/Access/AccessControl";
import BlockingPage from "../../Pages/Auth/Block/BlockPage";
import NotFound from "../../Pages/NotFound/NotFound";
import UserManagementPage from "../../Pages/Auth/Management/Management";
import UserProfilePage from "../../Pages/Auth/Profile/Profile";

import InternalError from "../../Pages/NotFound/InternalError";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";

// List Route
const subRoute = [
  {
    path: "access-control",
    name: "access-control",
    id_modules: 94,
    element: <AccessControlPage />,
  },
  {
    path: "access-control/manage-role",
    name: "manage-roles",
    id_modules: 94,
    element: <UserManagementPage />,
  },
  {
    path: "registration",
    name: "registration",
    id_modules: 95,
    element: <Register />,
  },
  {
    path: "blocking",
    name: "blocking",
    id_modules: 96,
    element: <BlockingPage />,
  },
];

// CODE
export default function UserControlRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = subRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _mods);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<RolesPage />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/user-profile" element={<UserProfilePage />} /> */}

        {protectedRoutes}

        {/* <Route path="/access-control" element={<AccessControlPage />} />
        <Route
          path="/access-control/manage-role"
          element={<UserManagementPage />}
        />
        <Route path="/registration" element={<Register />} />
        <Route path="/blocking" element={<BlockingPage />} /> */}
      </Routes>
      <Outlet />
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterRoomCat(props) {
  // PROPS
  const { onChangeRoomCat, name, cat, options, disable, is_fetch } = props;

  // CONTEXT
  const { getMasterRoomCat } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [roomcat, setRoomcat] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  const [code, setCode] = useState(null);

  // Fetch Data
  const fetchData = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        // FILTER IS DELETED
        if (response?.data?.msg?.length > 0) {
          let filtered = response?.data?.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          // CREATE OPTION
          let option = filtered.map((dev) => ({
            value: dev?.room_category_name,
            label: dev?.room_category_name,
            code: dev?.room_category_code,
            id: dev?.id,
          }));

          console.log("ALL OPTION >>>> ", option);
          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    // fetchData();
    let cleanUp = false;

    if (options?.length > 0 && !cleanUp) {
      console.log("OPTION VALUE >>> ", options);
      console.log("DISABLE VALUE >>> ", disable);

      setOptionValue(options);
    }

    if (is_fetch && !cleanUp) {
      // console.log("is Fetch >>> ", is_fetch);
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [options]);

  useEffect(() => {
    let cleanUp = false;

    if (cat && !cleanUp) {
      setRoomcat(cat);
    } else {
      setRoomcat(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [cat, disable]);

  // Handle Change
  const handleChange = (val) => {
    const _value = val.values;
    const _allKey = val.allKeys;

    console.log("Select Rooms => ", val);
    console.log("Isi Room Cat => ", roomcat);

    setRoomcat(_value);
    onChangeRoomCat({
      name: _value,
      code: _allKey?.code,
      form_index: name,
      cat_id: _allKey?.id,
    });
  };

  return (
    // <Select
    //   key={optionValue.code}
    //   style={{ width: 205 }}
    //   value={roomcat}
    //   onChange={(e) => {
    //     setRoomcat(e);
    //     console.log("Select: ", e);

    //     getRoomCat(e);

    //     let coderoom = optionValue.filter((code) => code.value == e);

    //     setCode(coderoom[0].code);
    //     getCode(coderoom[0].code);
    //     //   console.log(code);
    //     //  if (onSet) onSet(deviceid);
    //   }}
    //   options={optionValue}
    // />

    <Select
      className="room-cat-select"
      key="room-cat-select"
      placeholder="Select a Room Category!"
      disabled={disable}
      value={roomcat}
      options={optionValue}
      onChange={(e, opt) => {
        handleChange({
          values: e,
          allKeys: opt,
        });
      }}
      style={{
        minWidth: 150,
      }}
    />
    /* {optionValue.map((ov) => {
        return (
          <Select.Option key={ov.value} value={ov.value} children={ov.code}>
            {ov.label}
          </Select.Option>
        );
      })}
    </Select> */
  );
}

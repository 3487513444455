// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterArticleInventory(props) {
  // PROPS
  const { getArticle, article_name, disable, departmentid } = props;

  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Bulks
  const [bulks, setBulks] = useState([]);
  const [articleName, setArticleName] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/article-list");
  };

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getArticleList({
      list: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);
        // console.log("deptid on article inventory >> ", departmentid);
        // console.log("deptid on article inventory >> ", departmentid);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _filter = _res.filter((val) => {
            if (val.article_type == 2 && !val?.is_deleted) {
              return val;
            }
          });

          handleMap(_filter);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (departmentid > 0 && bulks.length > 0 && !cleanUp) {
      console.log("Department ID: ", departmentid);
      handleFilter(departmentid);
    } else {
      setOptionValue([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [departmentid, bulks]);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (article_name && !cleanUp) {
      console.log("Edits: ", article_name);
      setArticleName(article_name);
    } else {
      setArticleName(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [article_name]);

  // Handle Map
  const handleMap = (val) => {
    let _arr = val;

    let _filtered = _arr.map((value) => ({
      key: value.id,
      value: value.article_name,
      label: value.article_name,
      unit: value?.delivery_unit,
      coa: value?.chart_of_account,
      article_number: value?.article_number,
      id: value?.id,
      dept_id: value?.department_id,
      content: value?.delivery_content,
      average_price: value?.average_price,
    }));

    console.log("Article Sub == ", _arr);

    setBulks(_filtered);
  };

  // Handle Map
  const handleFilter = (id) => {
    let _dept = id;

    let _filtered = bulks.filter((value) => {
      return value.dept_id === _dept;
    });

    setOptionValue(_filtered);
  };

  // Handle Change
  const handleChange = (val, allKey) => {
    const _value = val;
    const _allKey = allKey;

    setArticleName(_value);
    getArticle({
      key: _allKey.key,
      value: _allKey.value,
      unit: _allKey?.unit,
      coa: _allKey?.coa,
      article_number: _allKey?.article_number,
      id: _allKey?.id,
      dept_id: _allKey?.dept_id,
      content: _allKey?.content,
      average_price: _allKey?.average_price,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setArticleName(null);
  };

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={20} className="select-col">
          <Select
            key={optionValue.code}
            style={{ width: "100%" }}
            value={articleName}
            showSearch
            allowClear
            placeholder="Select an Article!"
            disabled={disable}
            onChange={handleChange}
            onClear={handleClear}
            options={optionValue}
          />
        </Col>

        <Col span={4} className="select-col">
          <Tooltip
            className="redirect-tooltip"
            title={"Redirect to Master Article"}
          >
            <Button
              className="master-btn"
              icon={
                <AppstoreFilled
                  onClick={move}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

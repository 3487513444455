// REACT COMPONENTS
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";

// GLOBAL STYLES
import {
  mainBody,
  printPreview,
} from "../../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import { CurrencySymbols } from "../../../../../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// GLOBAL API
import { token } from "../../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../../API/Config/Api";
import axios from "axios";
import SpinnerComponents from "../../../../../../Reusable/Spinner/Spinner";

const { Text, Link, Title } = Typography;

export default function BSDetailReportTable(props) {
  // PROPS
  const { dataDate, dataDetail } = props;
  const tablesContainerRef = useRef(null);

  // STATE
  const [finalData, setFinalData] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(moment().format("MMMM YYYY"));
  const [detail, setDetail] = useState(null);
  const [summaryTotal, setSummaryTotal] = useState({
    current_month: 0,
    last_month: 0,
  });

  // Params
  const currency = CurrencySymbols().code;

  // USEEFFECTS
  useEffect(() => {
    // console.log(is_printed);
    let cleanUp = false;

    if (dataDate && dataDetail && !cleanUp) {
      setDate(moment(dataDate).format("MMM YYYY"));
      setDetail(dataDetail);
      fetchData();
    } else {
      setSummaryTotal({
        current_month: 0,
        last_month: 0,
      });
      setTablesData([]);
    }

    // if (is_printed && !cleanUp) {
    //   setIsPrinted(true);
    // } else {
    //   setIsPrinted(false);
    // }

    return () => {
      cleanUp = true;
    };
  }, [dataDate, dataDetail]);

  // HANDLE CREATE TABLE
  useEffect(() => {
    if (finalData.length > 0) {
      console.log("Hit");
      handleCreateTable(finalData);
    }
  }, [finalData]);

  // Table Break
  useEffect(() => {
    if (tablesContainerRef.current) {
      const tables =
        tablesContainerRef.current.querySelectorAll(".table-wrapper");

      tables.forEach((table, index) => {
        const observer = new ResizeObserver(() => {
          if (index > 0) {
            const previousTableBottom =
              tables[index - 1].offsetTop + tables[index - 1].offsetHeight;
            if (table.offsetTop < previousTableBottom) {
              table.classList.add("table-break");
            } else {
              table.classList.remove("table-break");
            }
          }
        });
        observer.observe(table);
      });
    }
  }, [tablesData]);

  // FETCH DATA
  const fetchData = async () => {
    let _subData = [];
    let _detailData = [];

    setIsLoading(true);

    await axios
      .post(
        `${baseurl}/parameter/accounting/balance-sheet-detail-by-id-header`,
        {
          group_id_neraca: dataDetail?.id_group_neraca,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Res History: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filtSubDescription = _res.map((item) => {
            return {
              sub_description: item.sub_description,
              group_id_neraca: item.group_id_neraca,
              sub_group_id_neraca: item.id,
            };
          });

          // setSubData(filtSubDescription);
          _subData = filtSubDescription;
        } else {
          // setSubData([]);
          _subData = [];
        }
      })
      .catch((error) => {
        console.log("Res History Error: ", error);
        failedFetch(error);
      });

    await axios
      .post(
        `${baseurl}/accounting/balance-sheet-detail`,
        {
          date: dataDate ? dataDate : date,
          group_id_neraca: dataDetail?.id_group_neraca,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Res History: ", response);

        if (typeof response?.data === "object" && response?.data?.length > 0) {
          const _res = response.data;

          _detailData = _res;
          setSummaryTotal(calculateTotals(_res));
        } else {
          _detailData = [];
          setSummaryTotal({
            current_month: 0,
            last_month: 0,
          });
        }
      })
      .catch((error) => {
        console.log("Res History Error: ", error);
        failedFetch(error);
      });

    manipulateResponse(_subData, _detailData);

    setIsLoading(false);
  };

  // HANDLE MANIPULATE RESPONSE
  const manipulateResponse = (_sub, _detail) => {
    console.log(_sub);
    console.log(_detail);

    const result = _sub.map((header, index) => {
      return {
        key: index,
        id: header.sub_group_id_neraca,
        sub_description: header.sub_description,
        details: _detail
          ? _detail.filter(
              (detail) =>
                detail.sub_group_id_neraca === header.sub_group_id_neraca
            )
          : [],
      };
    });

    console.log(result);

    setFinalData(result);
    // handleCreateTable(result);
  };

  // HANDE CALCULATE TOTAL OF CURRENT MONTH & LAST MONTH
  const calculateTotals = (details) => {
    return details?.reduce(
      (totals, detail) => {
        totals.current_month += detail.current_month;
        totals.last_month += detail.last_month;
        return totals;
      },
      { current_month: 0, last_month: 0 }
    );
  };

  // HANDLE CREATE TABLE
  const handleCreateTable = (value) => {
    console.log(value);
    const _bulks = value;
    let _tables = [];

    for (let i = 0; i < _bulks.length; i++) {
      _tables.push(
        <div
          // ref={(el) => (tablesContainerRef.current = el)}
          key={`table-${i}`}
          className="table-wrapper"
          style={{ width: "100%" }}
        >
          <Table
            className={"dsr-table"}
            name={"dsr-table-" + i}
            key={"dsr-table-" + i}
            title={() => (
              <Typography
                className="table-title"
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                }}
              >
                {_bulks[i].sub_description || `Data-${i}`}
              </Typography>
            )}
            dataSource={_bulks[i].details}
            columns={columns}
            pagination={false}
            size="small"
            bordered={true}
            loading={isLoading}
            rowKey={(record) => (record?.id ? record.id : record?.account_code)}
            // scroll={{
            //   x: 1000,
            // }}
            summary={(record) => {
              let total = {
                current_month: 0,
                last_month: 0,
              };

              if (record?.length > 0) {
                const sumTotal = record?.reduce((prev, curr) => {
                  return {
                    current_month: prev.current_month + curr.current_month,
                    last_month: prev.last_month + curr.last_month,
                  };
                });

                total = sumTotal;
              }

              return (
                <>
                  <Table.Summary
                    fixed
                    key="summary-tables"
                    className="sum-table"
                  >
                    <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                      <Table.Summary.Cell
                        className={"summary-head"}
                        index={0}
                        align="right"
                      >
                        <Text
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {`TOTAL ${_bulks[i].sub_description}`}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.current_month}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.last_month}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    {i === _bulks.length - 1 && _bulks.length > 1 ? (
                      <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                        <Table.Summary.Cell
                          className={"summary-head"}
                          index={0}
                          align="right"
                        >
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {`TOTAL ${dataDetail ? dataDetail?.value : "---"}`}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal.current_month}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal.last_month}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    ) : null}
                  </Table.Summary>
                </>
              );
            }}
            style={{ padding: "0px 0px 10px 0px", width: "100%" }}
          />
        </div>
      );
      // }
    }

    console.log("Table Results: ", _tables);
    setTablesData(_tables);
  };

  // HANDLE TITLE HEADER
  const handleTitleHeader = (values) => {
    const _formatted = values.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <Text
        className="header-text"
        style={{
          fontSize: 12,
          fontWeight: 500,
        }}
      >
        {_formatted}
      </Text>
    );
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: () => {
        return handleTitleHeader("Descripton");
      },
      dataIndex: "account_name",
      key: "account_name",
      render: (description, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 12,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${description}`
              .replace(/-+/g, " ")
              .replace(/(^\w|\s\w)/g, (val) => val)}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Current Month");
      },
      dataIndex: "current_month",
      key: "current_month",
      width: 200,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Last Month");
      },
      dataIndex: "last_month",
      key: "last_month",
      width: 200,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
  ];

  if (isLoading === true) {
    return <SpinnerComponents />;
  }

  return (
    <Row className="bsr-main-table" justify="center" style={mainBody}>
      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Typography.Title level={4} style={{ margin: 0 }} align="center">
          {`${detail ? detail.value : "Balance Sheet Detail"} `}
        </Typography.Title>

        <Typography.Title
          level={5}
          style={{ margin: "0px 0px 0px 0px", color: "#8c8c8c" }}
          align="center"
        >
          {`Look Hotel Bandung`}
        </Typography.Title>

        <Typography.Title
          level={5}
          style={{ margin: "0px 0px 20px 0px", color: "#8c8c8c" }}
          align="center"
        >
          {date}
        </Typography.Title>

        <Divider
          className="report-divider"
          style={{ margin: "15px 0px", background: "#EBEDF3" }}
        />

        <Row ref={tablesContainerRef}>
          {tablesData?.length > 0 ? tablesData : null}
        </Row>
      </Col>
    </Row>
  );
}

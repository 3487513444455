// REACT COMPONENTS
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

// PAGE COMPONENTS
import SpinnerComponents from "../../../../../../Reusable/Spinner/Spinner";

// ANTD COMPONENTS
import { Col, Divider, Row, Table, Typography } from "antd";

// GLOBAL STYLES
import {
  mainBody,
  printPreview,
} from "../../../../../../../Style/Global/Global";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// GLOBAL API
import { token } from "../../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../../API/Config/Api";
import axios from "axios";

const { Text } = Typography;

export default function PnLDetailReportTable(props) {
  // PROPS
  const { dataDate, dataDetail, is_printed } = props;

  // USEREF
  const tablesContainerRef = useRef(null);

  // STATE
  const [tablesData, setTablesData] = useState([]);
  const [date, setDate] = useState(moment().format("MMMM YYYY"));
  const [detail, setDetail] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [summaryTotal, setSummaryTotal] = useState({
    actual: 0,
    budget: 0,
    last_month: 0,
    last_year: 0,
    ytd_actual: 0,
    ytd_budget: 0,
    ytd_last_year: 0,
    account_name: "-",
  });

  const [isPrinted, setIsPrinted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USEEFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (dataDate && dataDetail && !cleanUp) {
      setDate(moment(dataDate).format("MMMM YYYY"));
      setDetail(dataDetail);
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDate, dataDetail]);

  // HANDLE CRATE TABLE
  useEffect(() => {
    if (finalData.length > 0) {
      console.log("Hit");
      handleCreateTable(finalData);
    }
  }, [finalData]);

  // USEEFFECT PRITN
  useEffect(() => {
    let cleanUp = false;

    if (is_printed && !cleanUp) {
      console.log(is_printed);
      setIsPrinted(true);
    } else {
      setIsPrinted(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_printed]);

  // USEEFFECT RESIZE VIEWPORT FOR TABLE BREAK
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tablesData]);

  // HANDLE RESIZE VIEWPORT FOR TABLE BREAK
  const handleResize = () => {
    if (tablesContainerRef?.current) {
      const tables =
        tablesContainerRef.current.querySelectorAll(".table-wrapper");

      let currentPageHeight = window.innerHeight;
      let currentSpaceLeft = currentPageHeight;

      tables.forEach((table, index) => {
        const observer = new ResizeObserver(() => {
          // reset viewport empty space for new page
          if (index === 0) {
            currentPageHeight = window.innerHeight;
            currentSpaceLeft = currentPageHeight;
          }

          // cek only second table and more
          if (index > 0) {
            const previousTable = tables[index - 1];
            const previousTableBottom =
              previousTable.offsetTop + previousTable.offsetHeight;

            if (table.offsetTop < previousTableBottom) {
              const estimatedTableHeight = table.offsetHeight;

              if (estimatedTableHeight <= currentSpaceLeft) {
                currentSpaceLeft -= estimatedTableHeight;
                table.classList.remove("table-break");
              } else {
                currentSpaceLeft = currentPageHeight - estimatedTableHeight;
                table.classList.add("table-break");
              }
            }
          }
        });

        observer.observe(table);
      });
    }
  };

  // FETCH DATA
  const fetchData = async () => {
    let _subData = [];
    let _detailData = [];

    setIsLoading(true);

    await axios
      .post(
        `${baseurl}/parameter/accounting/pnl-detail-by-id-header`,
        {
          group_id_pnl: dataDetail?.group_id_pnl,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Res PnL: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filtSubDescription = _res.map((item) => {
            return {
              description: item.description,
              id: item.id,
            };
          });

          _subData = filtSubDescription;
        } else {
          _subData = [];
        }
      })
      .catch((error) => {
        console.log("Res PnL Error: ", error);
        failedFetch(error);
      });

    await axios
      .post(
        `${baseurl}/accounting/pnl-detail`,
        {
          group_id_pnl: dataDetail?.group_id_pnl,
          date: dataDate ? dataDate : date,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Res PnL Detail: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const sumTotal = _res?.reduce(
            (prev, curr) => {
              return {
                actual: prev.actual + curr.actual,
                budget: prev.budget + curr.budget,
                last_month: prev.last_month + curr.last_month,
                last_year: prev.last_year + curr.last_year,
                ytd_actual: prev.ytd_actual + curr.ytd_actual,
                ytd_budget: prev.ytd_budget + curr.ytd_budget,
                ytd_last_year: prev.ytd_last_year + curr.ytd_last_year,
                account_name: "TOTAL EXPENSES",
              };
            },
            {
              actual: 0,
              budget: 0,
              last_month: 0,
              last_year: 0,
              ytd_actual: 0,
              ytd_budget: 0,
              ytd_last_year: 0,
              account_name: "TOTAL EXPENSES",
            }
          );

          setSummaryTotal(sumTotal);
          _detailData = _res;
        } else {
          _detailData = [];
          setSummaryTotal({
            actual: 0,
            budget: 0,
            last_month: 0,
            last_year: 0,
            ytd_actual: 0,
            ytd_budget: 0,
            ytd_last_year: 0,
            account_name: "TOTAL EXPENSES",
          });
        }
      })
      .catch((error) => {
        console.log("Res PnL Error: ", error);
        failedFetch(error);
      });

    manipulateResponse(_subData, _detailData);

    setIsLoading(false);
  };

  // HANDLE MANIPULATE RESPONSE
  const manipulateResponse = (_sub, _detail) => {
    console.log(_sub);
    console.log(_detail);
    const result = _sub.map((sub) => {
      return {
        id: sub.id,
        description: sub.description,
        details: _detail.filter((detail) => detail.sub_group_id_pnl === sub.id),
      };
    });

    console.log(result);

    setFinalData(result);
    // handleCreateTable(result);
  };

  // HANDLE TITLE HEADER
  const handleTitleHeader = (values) => {
    const _formatted = values.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <Text
        className="header-text"
        style={{
          fontSize: 10,
          fontWeight: 700,
        }}
      >
        {_formatted}
      </Text>
    );
  };

  // HANDLE CREATE TABLE
  const handleCreateTable = (value) => {
    console.log(value);
    const _bulks = value;
    let _tables = [];

    for (let i = 0; i < _bulks.length; i++) {
      // console.log(`Bulks ke-${i}: `, _bulks[i].details);
      _tables.push(
        <div className="table-wrapper" style={{ width: "100%" }}>
          <Table
            className={"dsr-table"}
            name={"pnl-table-" + i}
            key={"pnl-table-" + i}
            title={() => (
              <Typography
                className="table-title"
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                }}
              >
                {_bulks[i]?.description || `Data-${i}`}
              </Typography>
            )}
            dataSource={_bulks[i].details}
            columns={columns}
            pagination={false}
            size="small"
            bordered={true}
            loading={isLoading}
            rowKey={(record) => (record?.id ? record.id : record?.account_code)}
            // scroll={{
            //   x: 1000,
            // }}
            summary={(record) => {
              let total = {
                actual: 0,
                budget: 0,
                last_month: 0,
                last_year: 0,
                ytd_actual: 0,
                ytd_budget: 0,
                ytd_last_year: 0,
              };

              if (record?.length > 0) {
                const sumTotal = record?.reduce((prev, curr) => {
                  return {
                    actual: prev.actual + curr.actual,
                    budget: prev.budget + curr.budget,
                    last_month: prev.last_month + curr.last_month,
                    last_year: prev.last_year + curr.last_year,
                    ytd_actual: prev.ytd_actual + curr.ytd_actual,
                    ytd_budget: prev.ytd_budget + curr.ytd_budget,
                    ytd_last_year: prev.ytd_last_year + curr.ytd_last_year,
                  };
                });

                total = sumTotal;
              }
              return (
                <>
                  <Table.Summary
                    fixed
                    key="summary-tables"
                    className="sum-table"
                  >
                    <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                      <Table.Summary.Cell
                        className={"summary-head"}
                        index={0}
                        align="right"
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.actual}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {0}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.budget}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {0}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.last_month}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {0}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.last_year}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {0}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`TOTAL ${_bulks[i].description}`}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.ytd_actual}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {0}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.ytd_budget}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {0}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {`${total.ytd_last_year}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={0} align="right">
                        <Text
                          style={{
                            alignSelf: "start",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {0}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    {i === _bulks.length - 1 && _bulks.length > 1 ? (
                      <Table.Summary.Row style={{ backgroundColor: "#eaeaea" }}>
                        <Table.Summary.Cell
                          className={"summary-head"}
                          index={0}
                          align="right"
                        >
                          <Text
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal?.actual}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {0}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal?.budget}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {0}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal?.last_month}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {0}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal?.last_year}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {0}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`TOTAL EXPENSES`}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal?.ytd_actual}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {0}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal?.ytd_budget}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {0}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {`${summaryTotal?.ytd_last_year}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {0}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    ) : null}
                  </Table.Summary>
                </>
              );
            }}
            style={{ padding: "0px 0px 10px 0px", width: "100%" }}
          />
        </div>
      );
      // }
    }

    console.log("Table Results: ", _tables);
    setTablesData(_tables);
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: () => {
        return handleTitleHeader("Actual");
      },
      dataIndex: "actual",
      key: "actual",
      width: 150,
      align: "right",
      render: (actual, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {actual > 0 ? `${actual}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("%");
      },
      dataIndex: "-",
      key: "-",
      width: 30,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Budget");
      },
      dataIndex: "budget",
      key: "budget",
      width: 150,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("%");
      },
      dataIndex: "-",
      key: "-",
      width: 30,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Last Month");
      },
      dataIndex: "last_month",
      key: "last_month",
      width: 150,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("%");
      },
      dataIndex: "-",
      key: "-",
      width: 30,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Last Year");
      },
      dataIndex: "last_year",
      key: "last_year",
      width: 150,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("%");
      },
      dataIndex: "-",
      key: "-",
      width: 30,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Description");
      },
      dataIndex: "account_name",
      key: "account_name",
      width: 350,
      align: "left",
      render: (desc, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${desc}`.replace(/-+/g, " ").replace(/(^\w|\s\w)/g, (val) => val)}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("YTD Actual");
      },
      dataIndex: "ytd_actual",
      key: "ytd_actual",
      width: 150,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("%");
      },
      dataIndex: "-",
      key: "-",
      width: 30,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("YTD Budget");
      },
      dataIndex: "ytd_budget",
      key: "ytd_budget",
      width: 150,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("%");
      },
      dataIndex: "-",
      key: "-",
      width: 30,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("YTD Last Year");
      },
      dataIndex: "ytd_last_year",
      key: "ytd_last_year",
      width: 150,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("%");
      },
      dataIndex: "-",
      key: "-",
      width: 30,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 10,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
  ];

  if (isLoading === true) {
    return <SpinnerComponents />;
  }

  return (
    <Row className="pnl-main-table" justify="center" style={mainBody}>
      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Typography.Title level={4} style={{ margin: 0 }} align="center">
          {`${detail ? detail.value : "Profit & Loss Detail Report"} `}
        </Typography.Title>

        <Typography.Title
          level={5}
          style={{ margin: "0px 0px 0px 0px", color: "#8c8c8c" }}
          align="center"
        >
          {`Look Hotel Bandung`}
        </Typography.Title>

        <Typography.Title
          level={5}
          style={{ margin: "0px 0px 20px 0px", color: "#8c8c8c" }}
          align="center"
        >
          {`Accounting Period: ${date}`}
        </Typography.Title>

        <Divider
          className="report-divider"
          style={{ margin: "15px 0px", background: "#EBEDF3" }}
        />

        <Row ref={tablesContainerRef}>
          {tablesData?.length > 0 ? tablesData : null}
        </Row>
      </Col>
    </Row>
  );
}

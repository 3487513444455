// REACT COMPONENTS
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, DatePicker, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// PAGE COMPONENTS
import WarehouseList from "../../../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";
import AnnualReceivingTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/AnnualReceiving/Table";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { BsBoxSeam } from "react-icons/bs";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

export default function AnnualReceivingReportPage() {
  const [warehouse, setWarehouse] = useState(null);

  const [date, setDate] = useState(null);
  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/incoming-stock";

    navigate(`${path}`);
  };

  // HANDLE SELECTED WAREHOUSE
  const handleSelectedWarehouse = (value) => {
    setWarehouse(value);
  };

  // HANDLE DATE
  const handleDate = (date) => {
    const _date = date !== null && date !== "Invalid date" ? date : null;
    setDate(_date);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="annual-receiving-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <BsBoxSeam
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Annual Receiving Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                  marginRight: 13,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Warehouse`}</Typography>

                <WarehouseList
                  selectedItem={handleSelectedWarehouse}
                  warehouse_name={null}
                  is_disable={false}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`To Month`}</Typography>

                <DatePicker
                  onChange={handleDate}
                  picker="month"
                  placeholder="To Month"
                  style={{ width: "100%" }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          {warehouse && date ? (
            <AnnualReceivingTable
              id_warehouse={warehouse?.id_warehouse}
              to_month={date}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import axios from "axios";
import { useReactToPrint } from "react-to-print";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Typography,
  Image,
  Table,
} from "antd";

// GLOBAL API
import { baseurl } from "../../../API/Config/Api";
import { token } from "../../../API/Global/Payload";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// Import React Icons
import { FormOutlined, PrinterOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

// Import Functions
import { AmountInWords } from "../../Reusable/Functions/AmountInWords/AmountInWord";
import { PriceTax } from "../../Reusable/Functions/PriceTax/PriceTax";
import { TaxesParameter } from "../../Reusable/Functions/Taxes/Taxes";
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function PurchaseOrderForm(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    is_fromReceiving,
    // Data PO
    dataPO,
    params,
    params_form,
  } = props;

  // STATE MANAGEMENT
  // Use Ref
  const componentRef = useRef();

  // Data
  const [data, setData] = useState(null);
  const [details, setDetails] = useState([]);
  const [totalPrice, setTotalPrice] = useState({
    sub_total: 0,
    vat_total: 0,
    total: 0,
  });
  const [supplierTurnover, setSupplierTurnover] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // Tax
  const _params = TaxesParameter();
  const _tax = params?.tax_percentage / 100;
  // const _validity = _params?.validity_po_period;

  // Params
  const currency = CurrencySymbols().code;

  // FETCH DATA
  const fetchData = async (e) => {
    // setIsLoading(true);

    await axios
      .post(
        `${baseurl}/supplier-managament-systems/supplier-turnover`,
        { id_supplier: e?.id_supplier },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Supplier Turnover ", response);
        if (response?.data.msg != null) {
          let filterData = response.data.msg.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) return val;
          });

          setSupplierTurnover(filterData[0]?.total_price);
        } else {
          setSupplierTurnover(null);
        }
        // setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
        // setIsLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(true);

      if (dataPO) {
        setData(dataPO);
        setDetails(dataPO?.details);
        handleTotal(dataPO?.details);
        fetchData(dataPO);
      }
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataPO]);

  // ON FINISH
  const onFinish = () => {
    handlePrint();
  };

  // Handle Total
  const handleTotal = (val) => {
    const _arr = val;

    let total_tax = PriceTax({
      arr_article: _arr,
      tax_percent: _tax,
    });

    console.log("Price: ", total_tax);

    setTotalPrice({
      sub_total: total_tax.sub_total,
      vat_total: total_tax.vat_total,
      total: total_tax.total,
    });
  };

  // SEQUENTIAL BUTTON SUBMIT
  const [counter, setCounter] = useState(0);
  const myclick = () => {
    setCounter((counter) => counter + 1);
    if (counter == 0);
    else if (counter == 1) {
      //   move();
      setCounter(0);
    }
  };

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity_order",
      key: "quantity_order",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Delivery Unit",
      dataIndex: "d-unit",
      key: "d-unit",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Item Desc",
      dataIndex: "description",
      key: "description",
      // render: (e) => e,
    },
    {
      title: "Unit Price (Rp)",
      dataIndex: "unit_price",
      key: "unit_price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        let _ppn = price * _tax;
        let _price = price + "*";
        let _is_ppn = record?.is_ppn ? "**" : "*";

        switch (record.is_ppn) {
          case true:
            _price = _ppn + price + "**";
            break;

          default:
            break;
        }

        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + _is_ppn;
      },
    },
    {
      title: "Total Price (Rp)",
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      render: (total, record) => {
        let _ppn = total * _tax;
        let _price = total + "*";

        switch (record.is_ppn) {
          case true:
            _price = _ppn + total + "**";
            break;

          default:
            break;
        }

        return `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setCounter(0);
    setData([]);
    // setEdit(null);

    form.resetFields();
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    // PrintCount({
    //   data: data,
    //   is_update: true,
    //   counter_list: {
    //     bill: countPrint?.bill + 1,
    //     pre_master_bill: countPrint?.pre_master_bill,
    //     pre_bill: countPrint?.pre_bill,
    //     checkout_letter_history: countPrint?.checkout_letter_history,
    //     registration_form: countPrint?.registration_form,
    //     guarantee_letter: countPrint?.guarantee_letter,
    //     thankyou_letter: countPrint?.thankyou_letter,
    //     master_bill: countPrint?.master_bill,
    //     reservation_letter: countPrint?.reservation_letter,
    //   },
    // });

    handlePrintOut();
  };

  // Handle Print
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `PO Form ${
      data?.po_number ? data.po_number : " ---- "
    } (${moment().format("DD-MM-YYYY")})`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  // CONSOLE LOG
  // console.log(params_form);

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row>
              <FormOutlined style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Purchase Order (PO) Form`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        closable
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          labelWrap={{
            span: 12,
          }}
          wrapperCol={{
            span: 16,
          }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          autoComplete="off"
          name="bill-form-modal"
          key="bill-form-modal"
          className="bill-form-modal"
          layout="horizontal"
        >
          <Content
            ref={componentRef}
            className="content-receipt-form"
            style={{
              // padding: "30px 80px 30px 80px",
              padding: "40px 20px 40px 20px",
            }}
          >
            <Row gutter={30} className="row-header">
              <Col span={12} className="col-title-form">
                <Typography.Title level={2} className="text-form-title">
                  {`Purchase Order (PO) Form`}
                </Typography.Title>
              </Col>

              <Col span={12} align="middle" className="col-img-logo">
                <Image
                  src="/looklogo(color).png"
                  className="img-form-logo"
                  preview={false}
                  style={{
                    width: "25%",
                  }}
                />

                <Typography.Title level={4} className="text-form-id">
                  {`Look Hotel Bandung`}
                </Typography.Title>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-receipt-no"
              style={{
                height: 100,
                width: "100%",
                margin: 0,
              }}
            >
              <Col span={10} className="col-receipt">
                <Form.Item
                  label="PO No."
                  className="receipt_number"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-bill-id" style={textForm}>
                    {data?.po_number ? data.po_number : ""}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Order Date"
                  className="date"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-trx-date" style={textForm}>
                    {data?.order_date ? data.order_date : ""}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Delivery / Arrival Date"
                  className="delivery_date"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-trx-date" style={textForm}>
                    {data?.delivery_date ? data.delivery_date : ""}
                  </Typography>
                </Form.Item>
              </Col>

              <Col span={10} className="col-receipt">
                <Form.Item
                  label="Term Of Payment"
                  className="credit_term"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-trx-date" style={textForm}>
                    {data?.credit_term ? `${data.credit_term} Day(s)` : ""}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Start Contract"
                  className="start_contract_date"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-trx-date" style={textForm}>
                    {data?.start_contract_date
                      ? `${data.start_contract_date}`
                      : "-"}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="End Contract"
                  className="end_contract_date"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <Typography className="text-trx-date" style={textForm}>
                    {data?.end_contract_date
                      ? `${data.end_contract_date}`
                      : "-"}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>

            <Row className="row-payment-title" justify="start">
              <Col span={12}>
                <Typography
                  className="text-payment"
                  style={{
                    margin: "30px 0px 0px",
                    fontSize: 14,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Purchase Order (PO) Details`}
                </Typography>
              </Col>
              <Col span={12}>
                <Typography
                  className="text-payment"
                  style={{
                    margin: "30px 0px 0px",
                    fontSize: 14,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Supplier Details`}
                </Typography>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-guest-details"
              gutter={[20, 0]}
              style={{
                width: "100%",
                backgroundImage:
                  countPrint?.bill >= 1
                    ? "url(/assets/logos/copyopac.png)"
                    : null,
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
                backgroundPosition: "center",
                // transform: "rotate(45deg)",
              }}
            >
              <Col span={12} className="col-pay-type">
                <Form.Item
                  label="PR Number"
                  className="pr_number"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-type" style={textForm}>
                    {`${data?.pr_number ? data.pr_number : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Department Name"
                  className="department_name"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${data?.department_name ? data.department_name : ""}`}
                  </Typography>
                </Form.Item>

                {/* <Form.Item
                  label="PR Remarks"
                  className="Remarks"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${data?.remarks ? data.remarks : ""}`}
                  </Typography>
                </Form.Item> */}
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Supplier Name"
                  className="supplier_name"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.supplier_name ? data.supplier_name : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Supplier Address"
                  className="supplier_address"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.supplier_address ? data.supplier_address : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Contact Person"
                  className="contact_person"
                  style={{ margin: 0 }}
                  // labelCol={{ span:  }}
                  // wrapperCol={{ span: 20 }}
                >
                  <Typography className="text-guest-name" style={textForm}>
                    {`${
                      data?.contact_person_supplier
                        ? data.contact_person_supplier
                        : ""
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Email"
                  className="email"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.email_supplier ? data.email_supplier : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Phone"
                  className="phone"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.cp_phone_number ? data?.cp_phone_number : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Fax"
                  className="fax"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.telefax ? data?.telefax : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Accumulated Total Bussiness"
                  className="supplier_turnover"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${currency} ${
                      supplierTurnover ? supplierTurnover : "-"
                    }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              orientation="left"
              orientationMargin={0}
              style={{ margin: "10px 0px", borderColor: "#d9d9d9" }}
            >
              {`Special Notes`}
            </Divider>

            <Form.Item
              label=""
              className="description"
              style={{ padding: "0px 10px" }}
              // labelCol={{ span: 5 }}
              // wrapperCol={{ span: 19 }}
            >
              <Typography className="text-payment-details">
                {`${data?.description ? data.description : ""}`}
              </Typography>
            </Form.Item>

            <Divider
              className="divider-form"
              orientation="left"
              orientationMargin={0}
              style={{ margin: "10px 0px", borderColor: "#d9d9d9" }}
            >
              {`Buying List`}
            </Divider>

            <Row
              className="row-table"
              justify="start"
              style={{ padding: "10px 0px" }}
            >
              <Table
                className="transaction-table"
                key="transaction-table"
                name="transaction-table"
                loading={loading}
                columns={columns}
                dataSource={details}
                bordered={true}
                size="small"
                style={{
                  padding: 5,
                  border: "1px solid #000000",
                }}
                pagination={false}
                rowKey={(record) => (record.id ? record.id : record.id_article)}
                summary={() => {
                  return (
                    <>
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={5} />

                          <Table.Summary.Cell index={3}>
                            <b style={{ fontSize: 12 }}>{`Sub-Total`}</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            <b style={{ fontSize: 12 }}>
                              {`${currency} ${totalPrice.sub_total}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={5} />

                          <Table.Summary.Cell index={3}>
                            <b style={{ fontSize: 12 }}>{`VAT`}</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            <b style={{ fontSize: 12 }}>
                              {`${currency} ${totalPrice.vat_total}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={5} />

                          <Table.Summary.Cell index={3}>
                            <b style={{ fontSize: 12 }}>{`Grand Total`}</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4}>
                            <b style={{ fontSize: 12 }}>
                              {`${currency} ${totalPrice.total}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    </>
                  );
                }}
              />
            </Row>

            <Typography className="text-notes" style={{ fontSize: 10 }}>
              {`* Prices are not subject to VAT`}
            </Typography>
            <Typography className="text-notes" style={{ fontSize: 10 }}>
              {`** Prices are subject to VAT (PPN ${params?.tax_percentage}%)`}
            </Typography>

            <Typography className="text-notes" style={{ fontSize: 10 }}>
              {`notes: Please, keep this form as warranty! Valid for ${
                params_form?.validity_po_period
              } Day(s) forward or will be Expired at
              ${moment(
                data?.end_contract_date != null
                  ? data?.end_contract_date
                  : data?.needed_date
              )
                .add(params_form?.validity_po_period, "days")
                .format("DD-MM-YYYY")}`}
            </Typography>

            <div className="page-break" />

            <Row
              className="signature"
              justify="end"
              style={{ padding: "30px 0px 0px" }}
              gutter={[50, 0]}
            >
              <Col span={16}>
                <Row gutter={[20, 20]}>
                  {data?.id_approval_flow == 2 ? (
                    <Col
                      span={12}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {`Owner Signature`}

                      <div
                        className="box-sign"
                        style={{
                          border: "1px solid #000000",
                          borderRadius: 5,
                          height: 60,
                        }}
                      />

                      {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
                    </Col>
                  ) : (
                    <></>
                  )}

                  <Col
                    span={12}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {`General Manager Signature`}

                    <div
                      className="box-sign"
                      style={{
                        border: "1px solid #000000",
                        borderRadius: 5,
                        height: 60,
                      }}
                    />

                    {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
                  </Col>
                  <Col
                    span={12}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {`Head of Finance Signature`}

                    <div
                      className="box-sign"
                      style={{
                        border: "1px solid #000000",
                        borderRadius: 5,
                        height: 60,
                      }}
                    />

                    {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
                  </Col>
                  <Col
                    span={12}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {`Purchasing Signature`}

                    <div
                      className="box-sign"
                      style={{
                        border: "1px solid #000000",
                        borderRadius: 5,
                        height: 60,
                      }}
                    />

                    {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row gutter={[20, 20]}>
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {`Supplier Signature`}

                    <div
                      className="box-sign"
                      style={{
                        border: "1px solid #000000",
                        borderRadius: 5,
                        height: 60,
                      }}
                    />

                    {/* {`Sign at ${
                  headers?.payment_date
                    ? moment(LocalizationDate(headers?.payment_date)).format(
                        "HH:mm, DD-MM-YYYY"
                      )
                    : ""
                }`} */}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              className="desc"
              // justify="end"
              style={{ padding: "30px 0px 0px" }}
              gutter={[50, 0]}
            >
              <Col
                span={24}
                style={{
                  textAlign: "start",
                }}
              >
                {`Descriptions (Amount in Words)`}

                <div
                  className="desc"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #000000",
                    borderRadius: 5,
                    minHeight: 60,
                    alignItems: "center",
                    fontStyle: "italic",
                    fontWeight: 700,
                    flexDirection: "column",
                  }}
                >
                  {open
                    ? `${AmountInWords({
                        total_price: totalPrice.total,
                        param_word: params_form?.amount_in_words,
                        currency: _params?.currency,
                      })}`
                    : null}
                </div>
              </Col>
            </Row>
          </Content>

          <>
            <Row
              justify="end"
              align="middle"
              className="row-form-btn"
              style={{ padding: "0px 0px 0px 0px" }}
            >
              <Divider
                className="divider-form"
                style={{ margin: "15px 0px" }}
              />

              <Button
                className="print-btn"
                type="primary"
                htmlType="submit"
                icon={<PrinterOutlined />}
                // onClick={() => {
                //   handlePrint();
                //   if (counter == 0) {
                //     myclick();
                //   }
                // }}
                disabled={
                  is_fromReceiving ||
                  (data?.po_status == 1 &&
                    moment(moment().format("YYYY-MM-DD")).isAfter(
                      data?.delivery_date
                    ) &&
                    data?.is_outstanding == true)
                    ? true
                    : false ||
                      (data?.po_status == 2 &&
                        moment(moment().format("YYYY-MM-DD")).isAfter(
                          data?.delivery_date
                        ))
                    ? true
                    : false
                }
                style={{
                  // backgroundColor: "#1BC5BD",
                  // borderColor: "#1BC5BD",
                  marginRight: 30,
                }}
              >
                {`Print & Download`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleCancel}
              >
                {`Cancel`}
              </Button>
            </Row>
          </>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASE URL
import { baseurl } from "../../../../API/Config/Api";

// TOKEN AUTH
import {
  authorized_by,
  authorized_token,
  handleAuthorization,
} from "../../../../API/Global/Permission";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Page Components
import ModalAuthorized from "../../../Modals/Permissions/ModalCancel";
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../../Reusable/Notification/Notification";
import {
  failedOutlet,
  successOutlet,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Improt Global Style
import { mainBody } from "../../../../Style/Global/Global";

// CONST
const { confirm } = Modal;

// CODE
export default function OutletLocationTable({ is_search, searchKey }) {
  // Navigate
  const navigate = useNavigate();

  // CONTEXT
  const { getMasterTable } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [editData, setEditData] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [permitted, setPermitted] = useState(false);
  // Delete
  const [isDelete, setIsDelete] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterTable({
      outlet: "master",
      type: "outlet-location",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: async (response) => {
        console.log("Outlet Location List: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }

        handleSuccess();
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECTS
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="table-row" gutter={30} justify="center">
              <Col span={24} className="col-edit">
                <Tooltip className="edit-tooltip" title="Edit Data">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);

                      setEditData(record);

                      handleFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              {/* <Col span={12} className="col-del">
                <Tooltip className="edit-tooltip" title="Delete Data">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      setEditData(record);
                      handleDeletion(record);
                      // showConfirmModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col> */}
            </Row>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "location_name",
      key: "location_name",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Code",
      dataIndex: "location_code",
      key: "location_code",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Status",
      dataIndex: "register_status",
      key: "register_status",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) =>
      //   a.table_category_code.localeCompare(b.table_category_code),
      render: (value) => {
        let status = "";
        let color = "";

        switch (value) {
          case true:
            status = "Registered";
            color = "green";
            break;

          default:
            status = "Not Registered";
            color = "volcano";
            break;
        }

        return (
          <Tag className="status-tag" key={"status-tag"} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ];

  // Handle Form Fields
  const handleFormField = (value) => {
    const _value = value;

    form.setFieldsValue({
      location_code: _value.location_code,
      location_name: _value.location_name,

      description: _value?.description,

      created_date: _value?.created_date,
      created_by: _value?.created_by,
      updated_date: _value?.updated_date,
      updated_by: _value?.updated_by,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setIsDelete(false);

    console.log("Is Permit: ", permitted);

    switch (permitted) {
      case true:
        setOpen(true);
        break;

      case false:
        setShowModal(true);
        handleAuthorization("");
        break;

      default:
        break;
    }
  };

  // Handle Deletion
  const handleDeletion = (value) => {
    const _data = value;

    console.log("Is Permit: ", permitted);

    switch (permitted) {
      case true:
        showConfirmModal(_data);
        break;

      case false:
        setShowModal(true);
        handleAuthorization("");
        break;

      default:
        break;
    }
  };

  // ON FINISH
  const onFinish = (val) => {
    const _value = val;

    console.log("On Finish: ", _value);
    showConfirmModal(_value);
    setIsLoading(true);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _value = val;

    console.log("On Finish Failed: ", _value.values);
    incomplete(_value);
  };

  // SHOW MODAL DELETE
  const showConfirmModal = (value) => {
    const _data = value;

    confirm({
      className: "modal-confirm",
      title: isDelete
        ? `Are you sure want to Delete Location ${
            _data?.location_name || " --- "
          }?`
        : `Are you sure want to ${
            edit ? "Update" : "Add New"
          } Outlet Location?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(_data) : handleSubmit(_data);
        // handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Submit
  const handleSubmit = (val) => {
    switch (edit) {
      case true:
        handleUpdate(val);
        break;

      case false:
        handleCreate(val);
        break;

      default:
        break;
    }
  };

  // Handle Create
  const handleCreate = async (val) => {
    const _submit = val;
    console.log("ON OK ==> ", _submit);

    await axios
      .post(
        `${baseurl}/pos/master/outlet-location`,
        {
          location_code: _submit?.location_code,
          location_name: _submit?.location_name,
          register_status: _submit?.register_status,
          description: _submit?.description,

          created_by: authorized_by,
        },
        { headers: `Bearers ${authorized_token ? authorized_token : ""}` }
      )
      .then((response) => {
        console.log("Res Creates: ", response);

        if (response.status === 200) {
          successOutlet(response, { method: 1, params: 1 });
          handleCancel();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 1, params: 1 });
        setIsLoading(false);
      });
  };

  // Handle Update
  const handleUpdate = async (val) => {
    const _submit = val;
    console.log("ON OK ==> ", _submit);

    await axios
      .put(
        `${baseurl}/pos/master/outlet-location`,
        {
          id: editData?.id,
          location_code: _submit?.location_code || editData?.location_name,
          location_name: _submit?.location_name || editData?.location_code,
          register_status:
            _submit?.register_status || editData?.register_status,
          description: _submit?.description || editData?.description,

          updated_by: authorized_by,
        },
        { headers: `Bearers ${authorized_token ? authorized_token : ""}` }
      )
      .then((response) => {
        console.log("Res Creates: ", response);

        if (response.status === 200) {
          successOutlet(response, { method: 2, params: 1 });
          handleCancel();
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedOutlet(error, { method: 2, params: 1 });
        setIsLoading(false);
      });
  };

  // Handle Delete
  const handleDelete = async (val) => {
    const _data = val;
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setIsDelete(true);
    setIsLoading(false);

    setEditData(null);

    form.resetFields();
  };

  const handleClosePermit = (val) => {
    setShowModal(false);
  };

  // Handle Permission
  const handlePermit = (val) => {
    console.log("Is permissions: ", val);

    if (isDelete === false) {
      switch (val) {
        case true:
          setPermitted(true);
          setOpen(true);
          break;

        default:
          break;
      }
    } else {
      switch (val) {
        case true:
          setPermitted(true);
          showConfirmModal();
          break;

        default:
          break;
      }
    }
  };

  // Handle Success
  const handleSuccess = () => {
    setPermitted(false);
    setShowModal(false);

    localStorage.clear();
    handleAuthorization("");
  };

  // Handle Export
  const handleExport = () => {
    downloadExcel(data, `Outlet Location`);
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey;

    // console.log("Type = ", query);
    // console.log("Bulk = ", bulks);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item);

        return (
          item?.location_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      });

      // Trigger render with updated values
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="master-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-modal-btn" gutter={[0, 15]}>
            <Col
              sm={24}
              xl={12}
              xxl={12}
              lg={12}
              md={24}
              xs={24}
              className="col-exp-btn"
            >
              <Row justify="start" className="row-exp-btn">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              sm={24}
              xl={12}
              xxl={12}
              lg={12}
              md={24}
              xs={24}
              className="col-modal-btn"
            >
              <Row justify="end" className="row-modal-btn" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Data Table`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="master-table"
              key="master-table"
              name="master-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => {
                return record.id ? record.id : record.key;
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-table-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>{`Add Data`}</Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>{`Edit Data`}</Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="master-modal-form"
          className="master-modal-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-table-id">
            <Col span={16} className="location-name-col">
              <Form.Item
                label="Location Name"
                name="location_name"
                rules={[
                  {
                    required: true,
                    message: "Please, Input a Location Name!",
                  },
                ]}
              >
                <Input placeholder="Location Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-table-id">
            <Col span={16} className="location-code-col">
              <Form.Item
                label="Location Code"
                name="location_code"
                rules={[
                  {
                    required: true,
                    message: "Please, Input a Location Code!",
                  },
                ]}
              >
                <Input placeholder="Location Code" />
              </Form.Item>
            </Col>

            <Col span={8} className="register-status">
              <Form.Item
                label="Register Status"
                name="register_status"
                valuePropName="checked"
              >
                <Checkbox disabled className="register-status" value={false}>
                  {`Registered`}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-table-desc">
            <Col span={16}>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  showCount
                  maxLength={200}
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "10px 0px ", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created_date-table">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created By" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-table">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "10px 0px ", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row className="row-table-submit-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>

      <ModalAuthorized
        openModal={showModal}
        closeModal={handleClosePermit}
        is_permitted={handlePermit}
        params={1}
      />
    </>
  );
}

// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// BASEAPI
import { user_name, token } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";

// Import ANTD Component
import { Button, Row, Col, Table, Tooltip, Modal, Form, Tag } from "antd";

// Import React Icons Components
import {
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import PurchaseRequestModal from "../../../../Modals/Purchasing/PurchaseRequest/Modal";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
  masterIncomplete,
} from "../../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";
import DailyMarketListModal from "../../../../Modals/Purchasing/DailyMarketList/Modal";

// MODALS
const { confirm } = Modal;

// CODE
export default function DailyMarketListTable(props) {
  // PROPS
  const { searchKey, request_date, is_search, searchDept } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // const [bulks, setBulks] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  // Boolean
  const [edit, setEdit] = useState(false);
  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // CONTEXT
  const { getPurchasing } = useContext(FinanceContext);

  // Navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    await getPurchasing({
      type: "purchase-request",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.is_dml == true) {
              return filt;
            }
          });

          setBulks(filtered);
          setData(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    fetchData();
  }, [modalOpen]);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, request_date, searchDept]);

  // Handle Return
  const handleReturn = () => {
    const path = "/back-office/purchasing";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    // console.log(
    //   "table >> handleSearch >> searchKey, searchDept, request_date",
    //   searchKey,
    //   searchDept,
    //   request_date
    // );

    const queryName = searchKey ? searchKey.toLowerCase() : null;
    const queryDept = searchDept > 1 ? searchDept : null;
    const queryDate =
      request_date != "Invalid date" && request_date != null
        ? moment(request_date).format("YYYY-MM-DD")
        : null;

    // console.log(
    //   "table >> handleSearch >> queryName, queryDept, queryDate",
    //   queryName,
    //   queryDept,
    //   queryDate
    // );

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const name = item?.pr_number ? item?.pr_number.toLowerCase() : null;
        const dept = item?.id_department > 0 ? item?.id_department : null;
        const dep = moment(item?.request_date).format("YYYY-MM-DD");

        // console.log(isNaN(Date.parse(queryDate)));

        if (queryName == null && queryDept == null && queryDate != null) {
          // console.log("PRNo:, Dept:, Date:1");
          return moment(dep).isSame(queryDate);
        } else if (
          queryName == null &&
          queryDept != null &&
          queryDate == null
        ) {
          // console.log("PRNo:, Dept:1, Date:");
          return dept === queryDept;
        } else if (
          queryName == null &&
          queryDept != null &&
          queryDate != null
        ) {
          // console.log("PRNo:, Dept:1, Date:1");
          return dept === queryDept && moment(dep).isSame(queryDate);
        } else if (
          queryName != null &&
          queryDept == null &&
          queryDate == null
        ) {
          // console.log("PRNo:1, Dept:, Date:");
          return name.indexOf(queryName) !== -1;
        } else if (
          queryName != null &&
          queryDept == null &&
          queryDate != null
        ) {
          // console.log("PRNo:1, Dept:, Date:1");
          return (
            name.indexOf(queryName) !== -1 && moment(dep).isSame(queryDate)
          );
        } else if (
          queryName != null &&
          queryDept != null &&
          queryDate == null
        ) {
          // console.log("PRNo:1, Dept1:, Date:");
          return name.indexOf(queryName) !== -1 && dept === queryDept;
        } else if (
          queryName != null &&
          queryDept != null &&
          queryDate != null
        ) {
          // console.log("PRNo:1, Dept:1, Date:1");
          return (
            name.indexOf(queryName) !== -1 &&
            dept === queryDept &&
            moment(dep).isSame(queryDate)
          );
        } else {
          // console.log("PRNo:, Dept:, Date:");
          return item;
        }
      });

      // console.log("UpdateList => ", updatedList)
      setData(updatedList);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      setEdit(true);
                      handleOpen();
                      setDataEdit(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "DML No.",
      dataIndex: "pr_number",
      key: "pr_number",
    },
    {
      title: "Department",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Budget Allocation",
      dataIndex: "approval_flow",
      key: "approval_flow",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Status",
      dataIndex: "pr_status",
      key: "pr_status",
      render: (pr_status) => {
        return pr_status == true ? (
          <Tag color="#87d068">Requested</Tag>
        ) : (
          <Tag color="grey">Draft</Tag>
        );
      },
    },
  ];

  // Handle Open
  const handleOpen = () => {
    setModalOpen(true);
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Purchase Request");
  };

  // Handle Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
      setEdit(false);
    } else {
      fetchData();
    }
  };

  // Handle Close
  const handleClose = (val) => {
    setModalOpen(false);
    setEdit(false);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (value) => {
    const del = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Cancel a Purchase Request for Department ${
        del?.name.toUpperCase() || " ----- "
      }?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = (record) => {
    const contentdelete = record;

    console.log(contentdelete);

    var obj = {
      ["id_pr"]: contentdelete?.id_pr,
      ["deleted_by"]: user_name,
    };

    axios
      .delete(`${baseurl}/purchasing/purchase-request`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Budget => ", response);

        masterSuccessNotification(response, {
          method: 2,
          source: `Purchase Request`,
        });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, {
          method: 2,
          source: `Purchase Request`,
        });
        console.log("DEL Budget => ", error);
      });
  };

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  // onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={
                    <PlusOutlined
                      className="pay-icon"
                      style={{
                        fontSize: 20,
                        margin: "0px 15px 0px",
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 15px 10px 5px",
                    // height: 40,
                    minWidth: 75,
                    borderColor: "transparent",
                  }}
                >
                  {`Add New Request`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  // onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="purchase-req-table"
              name="purchase-req-table"
              key="purchase-req-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              //   rowSelection={{
              //     type: "checkbox",
              //     ...rowSelection,
              //   }}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record.id_pr ? record.id_pr : record.pr_number;
              }}
            />
          </Row>
        </Col>
      </Row>

      <DailyMarketListModal
        is_open={modalOpen}
        is_close={handleClose}
        is_edit={edit}
        selected={dataEdit}
        is_refresh={handleRefresh}
      />
    </>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Const
const options = [
  {
    value: "restaurants",
    label: "RESTAURANT",
    id: 1,
    key: 1,
  },
  {
    value: "room_service",
    label: "ROOM SERVICE",
    id: 2,
    key: 2,
  },
];

// CODE
export default function MasterOutletLocation({
  getOutletLocation,
  outletLocation,
}) {
  // CONTEXT
  const { getMasterTable } = useContext(Context);

  // STATE MANAGEMENT
  // Data
  const [outletLoc, setOutletLoc] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterTable({
      outlet: "master",
      type: "outlet-location",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Outlet Location List: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleFilter(_res);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log("Error List: ", error);
      },
    });
  };

  // Use Effects
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (outletLocation && !cleanUp) {
      setOutletLoc(outletLocation);
    }

    return () => {
      cleanUp = true;
    };
  }, [outletLocation]);

  // Handle Filter
  const handleFilter = (value) => {
    const _data = value;

    let _mapped = _data.map((val) => ({
      label: val?.location_name,
      value: val?.location_name,
      code: val?.location_code,
      id: val?.id,
    }));

    // console.log("Map results: ", _mapped);
    setOptionValue(_mapped);
  };

  // Handle Change
  const handleChange = (val, code) => {
    const _value = val;
    const _allKeys = code;

    // console.log("Values: ", _value);
    // console.log("All Keys: ", _allKeys);

    setOutletLoc(_value);
    getOutletLocation({
      value: _value,
      id: _allKeys?.id,
      // key: _allKeys?.key,
      label: _allKeys?.label,
      code: _allKeys?.code,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setOutletLoc(null);
  };

  return (
    <>
      <Select
        className="outlet-loc-select"
        key="outlet-loc-select"
        placeholder="Choose Outlet Locations"
        allowClear
        showSearch
        value={outletLoc}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{
          width: "100%",
        }}
      />
    </>
  );
}

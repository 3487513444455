// Import React Components
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";

// Import React Icons
import { FaConciergeBell } from "react-icons/fa";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../../Reusable/Notification/Notification";
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";

// CODE
export default function ModalGuestCard(props) {
  // PROPS
  const { openModal, closeModal, getGuestName } = props;

  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Select
  const [selected, setSelected] = useState([]);
  // Boolean
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Forms
  const [form] = Form.useForm();

  // FETCH DATA
  const fetchData = async () => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      fetchData();
      setOpen(true);
    } else {
      setData([]);
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // COLUMNS
  const columns = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.full_name.localeCompare(b.reservation_id),
    },
    {
      title: "Adress",
      dataIndex: "address",
      key: "address",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.address.localeCompare(b.reservation_id),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.city.localeCompare(b.reservation_id),
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.nationality.localeCompare(b.reservation_id),
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.phone_number.localeCompare(b.reservation_id),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.reservation_id),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    type: "checkbox",
    hideSelectAll: true,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      setSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: selected?.length === 1 ? record?.id !== selected[0]?.id : false,
    }),
  };

  // ON FINISH
  const onFinish = (val) => {
    const _data = val;
    setLoading(true);

    console.log("Data: ", _data, selected);
    getGuestName(selected);

    setTimeout(() => {
      setSelected([]);
      setLoading(false);
      handleCancel();
    }, 1000);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _error = val;

    console.log("on Finish Failed: ", _error?.values);
    // masterIncomplete(_error);
  };

  // Handle Search
  const onChangeSearch = (val) => {
    val.preventDefault();
    let _searchKey = val.target.value;
    // console.log(_searchKey);

    handleSearch(_searchKey);
  };

  const handleSearch = (val) => {
    let _searchKey = val ? val.toLowerCase() : "";

    // console.log(val);
    if (bulks.length > 0) {
      let _filtered = bulks.filter((value) => {
        return value.guest_name.toLowerCase().includes(_searchKey);
      });

      console.log("Filter by Name: ", _filtered);
      setData(_filtered);
    } else {
      setData([]);
    }
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setSelected([]);
    setOpen(false);
    closeModal(false);
    setIsLoading(false);

    form.resetFields();
  };

  return (
    <>
      <Modal
        className="guest-modal-guest"
        title={
          <>
            <Row className="modaltitle-row">
              <FaConciergeBell style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Guest Card List`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Form
          name="guesthouse-modal"
          className="guesthouse-modal"
          key="guesthouse-modal"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ padding: "0px 10px" }}
        >
          <Row gutter={30} className="form-main-row">
            <Col span={24} className="form-main-col">
              <Form.Item label="Find Guest" name="guest_name">
                <Input.Search
                  className="search-input"
                  allowClear
                  placeholder="Find Guest Name"
                  onChange={onChangeSearch}
                  onSearch={handleSearch}
                  onKeyDown={(e) => e.stopPropagation()}
                  //   addonAfter={}
                />
              </Form.Item>

              <Table
                className="guest-inhouse-table"
                key="guest-inhouse-table"
                name="guest-inhouse-table"
                loading={loading}
                columns={columns}
                dataSource={data}
                bordered={true}
                rowSelection={rowSelection}
                size="small"
                pagination={{
                  defaultPageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} entries`,
                }}
                rowClassName={(record, index) => {
                  return index % 2 === 1 ? "odd" : "even";
                }}
                scroll={{
                  x: true,
                }}
                rowKey={(record) => (record.id ? record.id : record.key)}
              />
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px", background: "#EBEDF3" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              loading={isLoading}
              disabled={selected.length === 0 ? true : false}
              className="submit-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
// import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaMoneyBillWave } from "react-icons/fa";

// Import Notification
// import {
//   failedRoomCat,
//   successRoomCat,
// } from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// Modal Form
const { confirm } = Modal;

// CODE
export default function JournalHeaderTable({
  searchKey,
  is_search,
  dataDetail,
}) {
  // Table Columns
  const columnsArticle = [
    /*
    {
      title: "Action",
      dataIndex: "action",
      width: 60,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-journal-header-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={24} className="col-details">
                <Tooltip title="See Detail" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      dataDetail(record.reservation_id);
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    */
    {
      title: "Journal ID",
      dataIndex: "journal_alias",
      key: "journal_alias",
      // responsive: ["xl"],
      // sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
      // sorter: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // responsive: ["xl"],
      sortDirections: ["descend", "ascend"],
      render: (date, record) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    // {
    //   title: "Total Credit",
    //   dataIndex: "total_credit",
    //   key: "total_credit",
    //   // responsive: ["xl"],
    //   // sortDirections: ["descend", "ascend"],
    //   render: (price, record) => {
    //     return price > 0
    //       ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //       : `0`;
    //   },
    // },
    {
      title: "Journal Type",
      dataIndex: "journal_type",
      key: "journal_type",
      // responsive: ["xl"],
      // sortDirections: ["descend", "ascend"],
      render: (jtype, record) => {
        let type = "";
        let clr = "";

        switch (jtype) {
          case 1:
            type = "Transaction";
            clr = "green";
            break;

          case 2:
            type = "Payment";
            clr = "geekblue";
            break;

          case 3:
            type = "Deposit";
            clr = "orange";
            break;

          case 4:
            type = "Manuals";
            clr = "magenta";
            break;

          default:
            break;
        }

        return (
          <Tag color={clr} key={type}>
            {type}
          </Tag>
        );
      },
    },
    // {
    //   title: "Total Debit",
    //   dataIndex: "total_debt",
    //   key: "total_debt",
    //   // responsive: ["xl"],
    //   // sortDirections: ["descend", "ascend"],
    //   render: (price, record) => {
    //     return price > 0
    //       ? `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //       : `0`;
    //   },
    // },
    // {
    //   title: "Balance",
    //   // dataIndex: "total_credit",
    //   // key: "total_credit",
    //   dataIndex: "trx_total",
    //   key: "trx_total",
    //   // responsive: ["xl"],
    //   // sortDirections: ["descend", "ascend"],
    //   render: (price, record) => {
    //     return `${price > 0 ? price : 0}`.replace(
    //       /\B(?=(\d{3})+(?!\d))/g,
    //       ","
    //     );
    //     // : ` 0`;
    //   },
    // },
  ];

  // CONTEXT
  const { getBillHeader, getJournalHead } = useContext(FinanceContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // ID
  const [editData, setEditData] = useState(null);
  const [roomCode, setRoomCode] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = () => {
    setLoading(true);

    getJournalHead({
      type: "header",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Bill Header Data => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
      },
    });
    /* getBillHeader({
    //   billHeader: "header",
    //   onAwait: () => {},
    //   onSuccess: (response) => {
    //     console.log("Bill Header Data => ", response);

    //     let filtered = response.data.msg.filter((filt) => {
    //       if (filt.is_deleted == false || filt.is_deleted == null) {
    //         return filt;
    //       }
    //     });

    //     setData(filtered);
    //     setLoading(false);
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //     setLoading(false);
    //   },
    // });
    */
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Handle Row Selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      // setRateCode(selectedRows);
      // getRateCode(selectedRows);
      dataDetail(
        // selectedRows?.length > 0 ? selectedRows[0].bill_alias : []
        selectedRows?.length > 0 ? selectedRows[0] : []
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    form.resetFields();
    setEditData(null);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let del = record;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete room ${record.room_category_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(del);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log(value);
    let content = value;

    handleSubmit(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = (e) => {
    console.log("ON OK ==> ", e);
    let contentSubmit = e;

    // if (edit == false) {
    //   axios
    //     .post(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       created_by: "DEVELOPER",
    //       created_date: moment().format("YYYY-MM-DD"),
    //       // updated_by: "17",
    //       // deleted_by: "NULL",
    //       // is_deleted: null,
    //       // deleted_date: null,
    //       // updated_date: null,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       successRoomCat(response, { method: 0 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       failedRoomCat(error, { method: 0 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // } else {
    //   axios
    //     .put(`${baseurl}/room/category`, {
    //       room_category_id: contentSubmit.room_category_id,
    //       room_category_code: contentSubmit.room_category_code,
    //       room_category_name: contentSubmit.room_category_name,
    //       room_category_description: contentSubmit.room_category_description,
    //       id: editData.id,
    //       created_by: editData.created_by,
    //       created_date: editData.created_date,
    //       updated_by: "DEVELOPER",
    //       updated_date: moment().format("YYYY-MM-DD"),
    //       // is_deleted: false,
    //       // created_by: "DEVELOPER",
    //       // chart_of_account: contentsubmit.chart_of_account,
    //       // departement_id: contentsubmit.departement_id,
    //       // is_transaction: "",
    //       // liquiditas: "",
    //     })
    //     .then((response) => {
    //       console.log("Add Resp => ", response);
    //       successRoomCat(response, { method: 1 });
    //       form.resetFields();
    //       handleCancel();
    //     })
    //     .catch((error) => {
    //       console.log("Error Add => ", error);
    //       failedRoomCat(error, { method: 1 });
    //     })
    //     .finally(() => {
    //       fetchData();
    //     });
    // }
  };

  // HANDLE DELETE
  const handleDelete = (record) => {
    const contentdelete = record.id;

    console.log("contentDelete >>>>>>", contentdelete);

    // var obj = {
    //   ["id"]: contentdelete,
    //   ["deleted_by"]: "DEVELOPER",
    // };

    // axios
    //   .delete(`${baseurl}/room/category`, {
    //     data: obj,
    //   })
    //   .then((response) => {
    //     console.log("Del Room Cat => ", response);
    //     successRoomCat(response, { method: 2 });
    //   })
    //   .catch((error) => {
    //     failedRoomCat(error, { method: 2 });
    //     console.log("Del Room => ", error);
    //   })
    //   .finally(() => {
    //     fetchData();
    //   });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? moment(searchKey).format("YYYY-MM-DD") : null;
    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _date = moment(item?.date).format("YYYY-MM-DD");
        // console.log("ITEM => ", _date);

        return moment(_date).isSame(query);
      });

      // Trigger render with updated values
      console.log("Filtered by Date: ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={12}>
              <Typography
                className="text-journal-header"
                style={{
                  fontSize: 15,
                  fontWeight: 750,
                }}
              >
                {`Journal Header`}
              </Typography>
            </Col>

            <Col className="col-btn" span={12}>
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="journal-header-cat"
              name="journal-header-cat"
              key="journal-header-cat"
              columns={columnsArticle}
              dataSource={data}
              bordered
              loading={loading}
              size="small"
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              // scroll={{
              //   x: 1000,
              // }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>

          {/* <Row justify="end" className="row-bill-btn" style={{ width: "100%" }}>
            <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              //   icon={<PlusOutlined />}
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Process Bill`}
            </Button>
          </Row> */}
        </Col>
      </Row>

      <Modal
        className="add-edit-room-cat-modal"
        title={
          <Row>
            <FaMoneyBillWave
              type="primary"
              style={{ fontSize: 24, color: "#3699FF" }}
            />
            <Typography style={{ marginLeft: 15 }}>Pay</Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 15,
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={(e) => onFinish(e)}
          // onFinishFailed={onFinishFailed}
          name="journal-header-modal-form"
          className="journal-header-modal-form"
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} justify="start">
            <Col span={24}>
              <Form.Item label="Table / Room" name="table_room">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "t1",
                      label: "T1",
                    },
                    {
                      value: "t2",
                      label: "T2",
                    },
                    {
                      value: "EXE401",
                      label: "EXE401",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Table
              className="journal-header-cat"
              name="journal-header-cat"
              key="journal-header-cat"
              columns={columnsArticle}
              dataSource={data}
              bordered
              loading={loading}
              size="small"
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Receipient" name="receipient">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "hanah",
                      label: "Hanah",
                    },
                    {
                      value: "jonas",
                      label: "Jonas",
                    },
                    {
                      value: "george",
                      label: "George",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="PPN Entire Sale" name="ppn">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Service Entire Sale" name="service">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Discount" name="discount">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Amount Due" name="amount_due">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} justify="start">
            <Col span={16}>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea placeholder="Notes" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30} justify="start">
            <Col span={24}>
              <Form.Item name="nonstay_guest">
                <Checkbox>Non Stay Guest</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Payment Type" name="payment_type">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "cash",
                      label: "Cash",
                    },
                    {
                      value: "transfer",
                      label: "Transfer",
                    },
                    {
                      value: "credit",
                      label: "Credit",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Payment Type Detail" name="payment_detail">
                <Select
                  style={{ width: "100%" }}
                  options={[
                    {
                      value: "bni",
                      label: "BNI",
                    },
                    {
                      value: "mandiri",
                      label: "Mandiri",
                    },
                    {
                      value: "bca",
                      label: "BCA",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Transfer Ref" name="transfer_ref">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {/* <Table
              className="journal-header-cat"
              name="journal-header-cat"
              key="journal-header-cat"
              columns={columnsData}
              dataSource={data}
              bordered
              loading={loading}
              size="small"
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={500}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            /> */}
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row gutter={30} justify="start">
            <Col span={8}>
              <Form.Item label="Sub Total" name="sub_total">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Total Bill" name="total">
                <Input placeholder="..." />
              </Form.Item>
            </Col>
          </Row>

          {edit == true ? (
            <>
              <Divider style={{ margin: "0px 0px 15px" }} />
              <Row gutter={30} className="created_date-room-cat">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Created by" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Created Date" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Modified by" />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-room-cat">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input disabled placeholder="Modified Date" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              // onClick={onFinish}
              icon={<PrinterOutlined />}
              className="print-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Print Bill`}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Finish Transaction`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

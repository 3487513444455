// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// API & Payload
import { baseurl } from "../../../../../../API/Config/Api";
import {
  authorized_by,
  authorized_token,
  handleAuthorization,
} from "../../../../../../API/Global/Permission";

// CONTEXT
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";

// Import React Icons
import {
  CloseCircleOutlined,
  FormOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PiTextboxFill } from "react-icons/pi";

// Import Master Components
import MasterBudget from "../../../../../Reusable/Master/Budget/Budget";
import ModalAuthorized from "../../../../../Modals/Permissions/ModalCancel";
import MasterNumbers from "../../../../../Reusable/Master/Numbers/Numbers";

// Import Notifications
import {
  failedFetch,
  incomplete,
} from "../../../../../Reusable/Notification/Notification";
import {
  failedParams,
  successParams,
} from "../../../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../../../Style/Global/Global";
import { token, user_name } from "../../../../../../API/Global/Payload";

// Modal
const { confirm } = Modal;

// CODE
export default function ReportMasterDetailTable(props) {
  // PROPS
  const { searchKey, is_search } = props;

  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // USE LOCATION & NAVIGATE
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [orderViewList, setOrderViewList] = useState([]);
  // EDITING
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showStatus, setShowStatus] = useState("No Show");
  // Modal State
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [permitted, setPermitted] = useState(true);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getParameters({
      params: "parameters",
      type: "source-detail-dsr",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setData(filtered);
          setBulks(filtered);
          handleOrderNumber(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });

    handleSuccess();
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (authorized_token && !cleanUp) {
      handleAuthorization("");
      localStorage.clear();
    }

    return () => {
      cleanUp = true;
    };
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Handle Order View
  const handleOrderNumber = (value) => {
    const _arr = value;

    let _numbered = _arr.map((val) => val.order_view);

    console.log("Picked Numbers: ", _numbered);
    setOrderViewList(_numbered);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      // fixed: "left",
      render: (_, record) => (
        <Row
          className="action-table-btn"
          justify="center"
          style={{ width: "100%" }}
        >
          <Tooltip
            title={permitted ? "Edit Disabled" : "Edit Data"}
            className="edit-tooltip"
          >
            <Button
              className="edit-btn"
              shape="circle"
              size="small"
              icon={
                <FiEdit
                  className="edit-btn"
                  onClick={() => {
                    console.log("ABOUT TO EDIT > ", record);

                    setIsEdit(true);
                    setEditData(record);
                    handleOpen();

                    setFormFields(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    // cursor: "pointer",
                  }}
                />
              }
              style={{
                borderColor: "transparent",
                background: "inherit",
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
    {
      title: "Label Name",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Total Label Name",
      dataIndex: "total_label_name",
      key: "total_label_name",
    },
    {
      title: "Order View",
      dataIndex: "order_view",
      key: "order_view",
    },
    // {
    //   title: "Budget Account",
    //   dataIndex: "group_coa",
    //   key: "group_coa",
    // },
    {
      title: "Descriptions",
      dataIndex: "description",
      key: "description",
    },
  ];

  // ON FINISH
  const onFinish = (value) => {
    const _submit = value;

    console.log("Submit Data: ", _submit);

    showModal(_submit);
    setIsLoading(true);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _submit = value;

    incomplete(value);
    console.log("Submit Data Failed: ", _submit);
  };

  // SET FORM FIELDS
  const setFormFields = (record) => {
    const _label = record;

    if (_label.is_show === true) {
      setShowStatus("Show");
    }

    form.setFieldsValue({
      ..._label,
      is_show: _label?.is_show,
      budget_label: _label?.budget_coa,
    });
  };

  // HANDLE BUDGET
  const getBudget = (value) => {
    const content = value;
    console.log("Value: ", content);

    form.setFieldsValue({
      budget_coa: content?.account_code,
      budget_label: content?.value,
    });
  };

  // Order View
  const getOrderView = (val) => {
    const _numb = val;

    form.setFieldsValue({
      order_view: _numb,
    });
  };

  // Handle Showing
  const handleShow = (e) => {
    const value = e.target.checked;
    // console.log("Key: ", e.target.checked);

    switch (value) {
      case true:
        setShowStatus("Show");

        break;

      default:
        setShowStatus("No Show");
        break;
    }
    // setShowStatus(e);
  };

  // Handle Permissions
  const handlePermit = (val) => {
    const _permit = val;

    console.log("IS Permission Granted? ", _permit);

    switch (_permit) {
      case true:
        setPermitted(false);
        setOpen(true);
        break;

      default:
        break;
    }
  };

  // Return
  const move = () => {
    const path =
      "/configuration/parameters/general-report-params/report-params";
    // console.log(pathname);
    // console.log(search);
    const _authorized = localStorage.getItem("permit");

    if (_authorized || authorized_token) {
      handleSuccess();
    }

    navigate(`${path}`);
  };

  // Handle Open Modal Permissions
  const handleOpen = () => {
    if (!permitted) {
      setOpen(true);
    } else {
      console.log("OPEN!!!", permitted);
      setModalOpen(true);
    }
  };

  // HANDLE CLOSE
  const handleClose = () => {
    form.resetFields();

    setIsEdit(false);
    setEditData(null);
    setShowStatus("No Show");

    setOpen(false);
  };

  // Modal Confirm
  const showModal = (val) => {
    const _submitted = val;

    confirm({
      className: "modal-confirm",
      title: (
        <>
          {`Are you sure you want ${
            isEdit ? "Update " : "Create a New "
          } label parameters of: `}
          <mark>{_submitted.label}</mark> {` to Table?`}
        </>
      ),
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_submitted);
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
      },
      cancelButtonProps: {
        className: "cancel-btn",
      },
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Update
  const handleSubmit = async (value) => {
    const submitData = value;

    switch (isEdit) {
      case true:
        handleUpdate(submitData);
        break;

      case false:
        handleCreate(submitData);
        break;

      default:
        break;
    }
  };

  // Creating
  const handleCreate = async (value) => {
    const submitData = value;

    await axios
      .post(
        `${baseurl}/parameters/source-detail-dsr`,
        {
          label: submitData?.label || null,
          group_coa: submitData?.group_coa || null,
          total_label_name: submitData?.total_label_name || null,
          id: editData?.id,
          order_view: submitData?.order_view || null,
          is_show: submitData?.is_show,
          description: submitData?.description || null,

          created_by: authorized_by ? authorized_by : user_name,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Add Label Success: ", response);
        successParams(response, { method: 6 });

        fetchData();
        handleClose();
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error Label: ", error);
        failedParams(error, { method: 6 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Updating
  const handleUpdate = async (value) => {
    const submitData = value;

    await axios
      .put(
        `${baseurl}/parameters/source-detail-dsr`,
        {
          label: submitData?.label || editData?.label,
          group_coa: submitData?.group_coa || editData?.group_coa,
          total_label_name:
            submitData?.total_label_name || editData?.total_label_name,
          id: editData?.id,
          order_view: submitData?.order_view || editData?.order_view,
          is_show: submitData?.is_show || editData?.is_show,
          description: submitData?.description || null,

          updated_by: authorized_by ? authorized_by : user_name,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Update Label Success: ", response);
        successParams(response, { method: 6 });

        fetchData();
        handleClose();
        handleSuccess();
      })
      .catch((error) => {
        console.log("Error Label: ", error);
        failedParams(error, { method: 6 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle Success
  const handleSuccess = () => {
    setPermitted(true);
    setModalOpen(false);

    localStorage.clear();
    handleAuthorization("");
  };

  // Handle Search
  const handleSearch = () => {
    const _names = searchKey ? searchKey.toLowerCase() : null;

    if (bulks.length > 0) {
      let filtered = bulks.filter((items) => {
        const _keys = items?.label.toLowerCase();

        return _keys.indexOf(_names) != -1;
      });

      setData(filtered);
    } else {
      setData([]);
    }
  };

  return (
    <>
      <Row className="account-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="end" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              {/* <Row justify="end" className="row-table"> */}
              <Button
                className="submit-btn"
                type="primary"
                onClick={handleOpen}
                icon={
                  <PlusOutlined
                    className="edit-icons"
                    style={{
                      margin: "0px 5px 0px 5px",
                    }}
                  />
                }
                style={{
                  marginRight: 15,
                }}
              >
                {`Add New Data`}
              </Button>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="daterange-col"
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={move}
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="statistic-params-table"
              name="statistic-params-table"
              key="statistic-params-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>

        <Modal
          className="label-modal"
          key="label-modal"
          title={
            <>
              <Row className="title-row">
                <PiTextboxFill style={{ color: "#3699FF", fontSize: 24 }} />
                <Typography
                  className="modal-title-text"
                  style={{ margin: "0px 0px 0px 15px" }}
                >
                  {isEdit ? `Edit Data` : `Add New Data`}
                </Typography>
              </Row>
            </>
          }
          open={open}
          centered
          closable={true}
          onCancel={handleClose}
          width={800}
          footer={null}
        >
          <Form
            className="label-form"
            name="label-form"
            key="label-form"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row
              className="main-row"
              justify="center"
              style={{ width: "100%", padding: "15px 30px 0px" }}
            >
              <Col span={24} className="main-col">
                <Row className="source-row" gutter={30}>
                  <Col className="source-col" span={16}>
                    <Form.Item
                      label="Detail Name"
                      name="label"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input a Detail Master Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Master Detail Name"
                        className="source-name"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="source-row" gutter={30}>
                  <Col className="source-col" span={16}>
                    <Form.Item
                      label="Total Label Name"
                      name="total_label_name"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input a Total Label Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Total Label Name"
                        className="label-name"
                      />
                    </Form.Item>
                  </Col>

                  <Col className="source-col" span={16}>
                    <Form.Item
                      label="Budget Account Code"
                      name="budget_label"
                      hidden
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, Select a Code!",
                      //   },
                      // ]}
                    >
                      <MasterBudget
                        fromParams={"LABEL_PARAMS"}
                        budget={open ? editData?.budget_coa : null}
                        getBudgetData={getBudget}
                      />
                    </Form.Item>

                    <Form.Item label="Budget Code" name="budget_coa" hidden>
                      <Input
                        placeholder="Label Name"
                        className="label-name"
                        hidden
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="orderview-row" gutter={30}>
                  <Col className="view-col" span={16}>
                    <Form.Item
                      label="Order View"
                      name="order_view"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, Choose an Options!",
                      //   },
                      // ]}
                    >
                      <MasterNumbers
                        existedNumb={open ? editData?.order_view : 0}
                        getNumbers={getOrderView}
                        pickedNumb={
                          orderViewList?.length > 0 ? orderViewList : []
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col className="source-col" span={8}>
                    <Form.Item
                      label="Showing Status"
                      name="is_show"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Please, Choose an Options!",
                        },
                      ]}
                    >
                      <Checkbox className="show-check" onChange={handleShow}>
                        {showStatus}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: "15px 0px", background: "#EBEDF3" }}
                />

                <Row className="desc-row" gutter={30}>
                  <Col className="desc-col" span={16}>
                    <Form.Item label="Description" name="description">
                      <Input.TextArea
                        placeholder="Descriptions"
                        className="desc-input"
                        showCount
                        allowClear
                        rows={3}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="form-divider"
                  style={{ margin: "15px 0px", background: "#EBEDF3" }}
                />

                {isEdit ? (
                  <>
                    <Row className="creation-row" gutter={30}>
                      <Col className="createsby-col" span={8}>
                        <Form.Item label="Created By" name="created_by">
                          <Input
                            placeholder="Created By"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col className="createdat-col" span={8}>
                        <Form.Item label="Created Date" name="created_date">
                          <Input
                            placeholder="Created Date"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className="modified-row" gutter={30}>
                      <Col className="source-col" span={8}>
                        <Form.Item label="Updated By" name="updated_by">
                          <Input
                            placeholder="Updated By"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col className="source-col" span={8}>
                        <Form.Item label="Updated Date" name="updated_date">
                          <Input
                            placeholder="Updated Date"
                            className="form-input"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider
                      className="form-divider"
                      style={{ margin: 0, background: "#EBEDF3" }}
                    />
                  </>
                ) : null}

                <Row
                  justify="end"
                  align="middle"
                  style={{ padding: "10px 0px 0px" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    key={"submit"}
                    loading={isLoading}
                    className="submit-btn"
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {`Submit`}
                  </Button>

                  <Button
                    className="cancel-btn"
                    type="default"
                    onClick={handleClose}
                  >
                    {`Cancel`}
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>

        <ModalAuthorized
          openModal={modalOpen}
          closeModal={setModalOpen}
          is_permitted={handlePermit}
          params={1}
        />
      </Row>
    </>
  );
}

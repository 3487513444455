// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Antd Components
import { Select, Space } from "antd";

// Import Notifications
import { failedFetch } from "../../Notification/Notification";

// Option Type
const optionsType = [
  {
    label: "Individual",
    value: 1,
  },
  {
    label: "Company",
    value: 2,
  },
  {
    label: "OTA",
    value: 3,
  },
];

// CODE
export default function MasterCompany(props) {
  // PROPS
  const {
    getMasterComp,
    masterComp,
    masterCompId,
    // Type guest
    typeGuest,
  } = props;

  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [mainComp, setMainComp] = useState(null);
  const [typeComp, setTypeComp] = useState(2);
  const [companyID, setCompanyID] = useState(0);
  // Data Individual Guest & Company
  const [allGuest, setAllGuest] = useState([]);
  const [allComp, setAllComp] = useState([]);
  const [allOta, setAllOta] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState([]);
  // Options
  const [optionValue, setOptionValue] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // FETCH DATA
  const fetchData = async () => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          let _options = filtered.map((val) => ({
            label: val?.first_name,
            value: val?.first_name,
            main_comp_id: val?.id,
          }));

          setAllGuest(_options);
        }
      },
      onReject: (error) => {
        console.log(error);
        // failedFetch(error);
      },
    });

    await getGuest({
      guest: "guest",
      type: "company-and-ota",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          let _options = filtered.map((val) => ({
            label: val?.company_name,
            value: val?.company_name,
            main_comp_id: val?.id,
            is_ota: val?.is_ota,
          }));

          setAllCompanyData(_options);
          setOptionValue(_options);
          handleFilter(_options);
        } else {
          setAllCompanyData([]);
          setAllComp([]);
          setAllOta([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    console.log("Type: ", typeGuest);
    console.log("Company Info: ", masterComp, masterCompId);

    if (masterComp && masterCompId > 0 && !cleanUp) {
      setCompanyID(masterCompId);

      if (allComp.length > 0 && allComp.length > 0) {
        handleEdit(masterCompId);
      }
    } else {
      setCompanyID(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [masterComp, allComp, allGuest, allOta, typeGuest]);

  // Handle Edit
  const handleEdit = (val) => {
    const _compId = val;
    console.log(_compId);

    let _filterComp = allComp.filter((val) => {
      return val.main_comp_id === _compId;
    });

    console.log("EDIT: ", _filterComp);

    if (_filterComp?.length > 0) {
      setMainComp(_filterComp[0]?.value);

      if (_filterComp[0]?.is_ota === true) {
        setOptionValue(allOta);
      } else {
        setOptionValue(allComp);
      }
    }
  };

  // Handle Type
  const onChangeType = (value) => {
    setTypeComp(value);
    setMainComp(null);

    // handleFilter();
    switch (value) {
      case 1:
        setOptionValue(allGuest);
        setIsDisabled(true);
        break;

      case 2:
        setOptionValue(allComp);
        setIsDisabled(false);
        break;

      case 3:
        setOptionValue(allOta);
        setIsDisabled(false);
        break;

      default:
        break;
    }
  };

  // Handle Filter
  const handleFilter = (arr) => {
    let _array = arr;
    let _comp = [];
    let _ota = [];

    if (_array?.length > 0) {
      _array.filter((value) => {
        if (value?.is_ota == false) {
          return _comp.push(value);
        } else {
          return _ota.push(value);
        }
      });
    }

    setAllComp(_comp);
    setAllOta(_ota);
  };

  // Handle Clear
  const handleClear = () => {
    setMainComp(null);
  };

  return (
    <>
      <Space
        className="master-comp-space"
        align="start"
        size={0}
        style={{ width: "100%" }}
      >
        <Select
          className="type-guest-select"
          key="type-guest-select"
          onChange={onChangeType}
          defaultValue={typeComp}
          value={typeComp}
          options={optionsType}
        />
        <Select
          key={"rate-code-select"}
          className={"rate-code-select"}
          options={optionValue}
          value={mainComp}
          disabled={isDisabled}
          allowClear
          showSearch
          onChange={(value, allKey) => {
            setMainComp(value);

            getMasterComp({
              value: value,
              master_comp_id: allKey?.main_comp_id,
            });
          }}
          onClear={handleClear}
        />
      </Space>
    </>
  );
}

// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// BASE URL
import { baseurl } from "../../../../API/Config/Api";

// Import CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Table,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import {
  DeleteFilled,
  EditOutlined,
  FileAddOutlined,
  FileExcelFilled,
  FormOutlined,
  PlusOutlined,
  SyncOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { TbCircleCheck, TbCircleX } from "react-icons/tb";

// Import Page Components
import MasterCompany from "../../../Reusable/Master/Company/MasterComp";
import MasterSexType from "../../../Reusable/Master/Sex/Sex";
import MasterCountry from "../../../Reusable/Master/Country/Country";
import MasterIdCardType from "../../../Reusable/Master/IdCardType/IdCardType";
import MasterPaymentType from "../../../Reusable/Master/PaymentType/PaymentType";
import MasterTitleType from "../../../Reusable/Master/Title/Title";
import ModalEditGuest from "../../../Modals/Guest/Modal";
import GuestCardPopover from "../../../Popover/GuestCard/Popover";
import ModalCompanyOTA from "../../../Modals/Company/Modal";

// Import Functions
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";
import { Capitalization } from "../../../Reusable/Functions/Capitalization/Capitalization";

// Import Notification
import {
  failedFetch,
  failedGuest,
  incomplete,
  incompleteGuest,
  successGuest,
} from "../../../Reusable/Notification/Notification";

// Import Global Styles

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function TableGuest(props) {
  // PROPS
  const {
    is_refresh,
    // IS SEARCH
    is_search,
    searchKey,
    is_post,
    dataTable,
  } = props;

  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // Use Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [guest, setGuest] = useState(null);
  // Data About to Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Data Type
  const [value, setValue] = useState();
  // Modal Open
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [optionValue, setOptionValue] = useState([]);
  const [companyOpen, setCompanyOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  // Find
  const [alert, setAlert] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const [form] = Form.useForm();

  // MODAL
  const openModal = () => {
    setOpen(!open);
  };

  // Fetch Data
  const fetchData = async (e) => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
          // dataTable(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    await getGuest({
      guest: "guest",
      type: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest Master => ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          let _response = response.data.msg;

          let filtered = _response.filter((val) => {
            return !val?.is_deleted;
          });

          let newData = filtered.map((gst) => ({
            value: gst?.full_name,
            label: gst?.full_name,
            // first_name: gst?.first_name,
            nationality: gst?.nationality,
            id: gst?.id,
            birthdate: gst?.birthdate,
            rate_code: gst?.rate_code,
            rate_id: gst?.rate_code_id,
            segment_name: gst?.main_segment_name,
            segment_id: gst?.main_segment_id,
            company_name: gst?.company_name,
            company_id: gst?.master_comp_id,
            is_allow: gst?.allow_ledger,
            credit_limit: gst?.credit_limit,
          }));

          // console.log("GUEST LIST == ", filtered);
          setOptionValue(newData);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // FETCH DATA
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);

    console.log("Search Key: ", searchKey);
  }, [searchKey]);

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action-column",
      key: "action-column",
      width: 100,
      fixed: "center",
      render: (_, record) => {
        const _guest = optionValue.filter((item) => {
          return item.id == record.id;
        });

        const _value = _guest.length > 0 ? _guest[0] : _guest;

        return (
          <>
            <Row
              className="action-guest-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Create Reservation" className="create-tooltip">
                  <FileAddOutlined
                    className="create-btn"
                    onClick={() => handleMove(record)}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <GuestCardPopover
                  details={record}
                  is_refresh={handleRefresh}
                  is_delete={showDeleteModal}
                  optionValue={_value}
                />
              </Col>

              {/* <Col span={8}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      setEdit(true);
                      setDataEdit(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={8}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col> */}
            </Row>
          </>
        );
      },
    },
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
      // fixed: "left",
      first_name: 300,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Birthdate",
      dataIndex: "birthdate",
      key: "birthdate",
      render: (date, record) => {
        // console.log(record);
        return moment(LocalizationDate(date)).format("DD MMM YYYY");
      },
    },
    {
      title: "ID Card",
      dataIndex: "id_card_type",
      key: "id_card_type",
    },
    {
      title: "ID Card No",
      dataIndex: "id_card_number",
      key: "id_card_number",
      // render: (id, record) => {
      //   if (record.full_name.includes("Park")) {
      //     console.log(`${record.full_name}: `, id);
      //   }

      //   return id;
      // },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  // Handle Open
  const handleOpen = (e) => {
    // switch (type) {
    //   case 1:
    setOpen(true);
    //     break;

    //   case 2:
    //   case 3:
    //     setCompanyOpen(true);
    //     break;

    //   default:
    //     break;
    // }
  };

  // Handle Move
  const handleMove = (record) => {
    const _record = record;
    const _res = "/front-office/reservation/add-reservation";
    const _master = "/configuration/master";

    console.log("ABOUT TO CREATE > ", _record);

    if (record?.id) {
      navigate(_res, {
        state: _record,
      });
    } else {
      navigate(_master);
    }
  };

  // Handle Change
  const handleChange = (e) => {
    // console.log("Type: ", e);
    // setType(e);

    fetchData(e);
  };

  const handleRefresh = (value) => {
    if (value == true) {
      fetchData();
      setRefresh(false);
    }
  };

  // HANDLE SELECTOR MASTER
  // Get ID Card Type
  const getIdCardType = (value) => {
    form.setFieldsValue({
      id_card_type: value,
    });
  };
  // Get Title Type
  const getTitleType = (value) => {
    form.setFieldsValue({
      title: value,
    });
  };
  // Get Sex
  const getSexType = (value) => {
    form.setFieldsValue({
      sex: value,
    });
  };
  // Get Payment Type
  const getPaymentType = (value) => {
    console.log("Value: ", value);

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };
  // Get Payment Type
  const getPayTypeId = (value) => {
    form.setFieldsValue({
      payment_type_id: value,
    });
  };
  // Get Country and Nationality
  const getCountry = (value) => {
    // console.log(value);

    form.setFieldsValue({
      country: value,
      nationality: value,
    });
  };

  // GET MASTER COMP
  const getMasterComp = (value) => {
    console.log("Company: ", value);

    form.setFieldsValue({
      master_comp: value,
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    const values = e;
    setIsLoading(true);
    // console.log("SUBMITTED ==> ", values);
    // values.birthdate = moment(values["birthdate"]).format("YYYY-MM-DD");

    // handleSubmit(values);
    showModalConfirm(values);

    // const letter = Capitalization({ names: e?.full_name });

    // console.log("Letter Results: ", letter);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("On Finish Failed", error);

    incomplete(error);
  };

  // MODAL CONFIRM
  const showModalConfirm = async (e) => {
    const submitContent = e;

    console.log("Modals --> ", submitContent);

    confirm({
      className: "submit-guest-confirm",
      title: `Are you sure want to Add a New Guest named ${
        submitContent?.full_name?.toUpperCase() || "Unknown"
      }?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(submitContent);
        // console.log("Guest deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const handleSubmit = async (values) => {
    const newGuest = values;
    console.log("ON OK ==> ", newGuest);

    let restricted = moment().subtract(17, "years");
    const full_name = Capitalization({ names: newGuest?.full_name });
    const first_name = Capitalization({ names: newGuest?.first_name });
    // console.log(values.birthdate);
    // console.log(restricted);

    console.log(values.birthdate > restricted);

    if (values.birthdate > restricted) {
      incompleteGuest(values);
    } else {
      await axios
        .post(
          `${baseurl}/guest/card`,
          {
            full_name: full_name ? full_name : newGuest?.full_name,
            first_name: first_name ? first_name : newGuest?.first_name,
            id_card_type: newGuest?.id_card_type ? newGuest.id_card_type : null,
            id_card_number: newGuest?.id_card_num ? newGuest.id_card_num : null,
            phone_number: newGuest?.phone_number ? newGuest.phone_number : null,
            birthdate: newGuest?.birthdate
              ? moment(newGuest?.birthdate).format("YYYY-MM-DD")
              : null,
            nationality: newGuest?.nationality ? newGuest.nationality : "",
            country: newGuest?.country ? newGuest.country : "",
            province: newGuest?.province || "",
            local_region: newGuest?.local_region || "",
            city: newGuest?.city || null,
            zip_code: newGuest?.zip_code || null,
            address: newGuest?.address,
            sex: newGuest?.sex || null,
            email: newGuest?.email || null,
            payment_type: newGuest?.payment_type || null,
            master_comp: newGuest?.master_comp?.value || null,
            main_segment: newGuest?.main_segment ? newGuest.main_segment : "",
            credit_limit: newGuest?.credit_limit ? newGuest.credit_limit : 0,
            fax: newGuest?.fax ? newGuest.fax : 0,
            comment: newGuest?.comment ? newGuest.comment : "",
            is_dummy: true,
            guest_title: newGuest?.title || null,
            master_comp_id: newGuest?.master_comp?.master_comp_id || null,

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("Add Guest --> ", response);

          if (response?.data?.status === 409) {
            failedGuest(response, { method: 3, main_type: 1 });
          } else {
            successGuest(response, { method: 0, main_type: 1 });
            setOpen(false);

            setRefresh(true);
            handleCancel();
            fetchData();
          }
        })
        .catch((error) => {
          console.log(error);
          failedGuest(error, { method: 0, main_type: 1 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // CLOSE ADD GUEST MODAL
  const handleCancel = () => {
    setOpen(false);
    setShow(false);
    setAlert(false);
    setMessage("");
    setGuest(null);

    form.resetFields();
  };

  // HANDLE FIND
  const handleFind = async (e) => {
    // console.log("Handle Find --> ", e);

    setShow(false);
    setAlert(false);

    if (e?.id_card_type && e?.id_card_number) {
      const findData = data.filter((filt) => {
        if (
          filt.id_card_type == e?.id_card_type &&
          filt.id_card_number == e?.id_card_number
        ) {
          return filt;
        }
      });

      setGuest(findData[0]);

      // console.log("Guest --> ", findData);

      if (findData?.length == 0) {
        setShow(true);
        // console.log("noSame");
      } else {
        setAlert(true);
        setMessage(2);
      }
    } else {
      setAlert(true);
      setMessage(1);
    }
  };

  // SHOW DELETE CONFIRMATION
  const showDeleteModal = (record) => {
    let deleteContent = record;

    console.log(deleteContent);

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete guest named ${
        deleteContent?.first_name?.toUpperCase() || "Unknown"
      } from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(deleteContent);
        // console.log("Guest deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    // console.log(record);
    const content = record;

    console.log(content);

    var obj = {
      ["id_card_number"]: content?.id_card_number,
      id: content?.id,
      deleted_by: user_name,
    };

    await axios
      .delete(`${baseurl}/guest/card`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);

        successGuest(response, { method: 2, main_type: 1 });
        fetchData();
      })
      .catch((error) => {
        failedGuest(error, { method: 2, main_type: 1 });
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);
    // let _title = "";

    // switch (type) {
    //   case 1:
    //     _title = "Guest List";
    //     break;

    //   case 2:
    //   case 3:
    //     _title = "Guest List";
    //     break;

    //   default:
    //     break;
    // }

    downloadExcel(data, "Guest List");
  };

  // HANDLE SEARCH
  const handleSearch = async (_value) => {
    const query = searchKey || _value;
    // console.log("Search Key = ", query);

    var updatedList = bulks;
    // console.log("Update List = ", updatedList);

    updatedList = updatedList.filter((item) => {
      // console.log("ITEM => ", item);
      return item.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    // Trigger render with updated values
    // console.log("FILTERED => ", updatedList);
    setData(updatedList);
    // dataTable(updatedList);
  };

  // CONSOLE LOG
  // console.log("DATA EDIT > ", dataEdit);

  return (
    <>
      <Row
        className="guest-card-main-table"
        justify="center"
        gutter={[0, 15]}
        style={{
          backgroundColor: "#FFFFFF",
          minHeight: "100%",
          padding: 30,
          borderRadius: 5,
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="col-export"
        >
          <Row justify="start" className="row-export">
            <Button
              className="export-btn"
              type="primary"
              onClick={handleExport}
              icon={<FileExcelFilled />}
              style={{
                fontWeight: 500,
                // margin: "0px 30px 0px 0px",
              }}
            >
              {`Export to Excel`}
            </Button>

            {/* <Select
              defaultValue="Individual"
              style={{
                width: 205,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 1,
                  label: "Individual",
                },
                {
                  value: 2,
                  label: "Company",
                },
                {
                  value: 3,
                  label: "OTA",
                },
              ]}
            /> */}
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="col-table-btn"
        >
          <Row justify="end" className="row-table-btn" gutter={[0, 15]}>
            <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                // marginRight: 15,
                fontWeight: 500,
              }}
            >
              {`Add New Guest`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
              style={{
                fontWeight: 500,
                margin: "0px 0px 0px 15px",
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={
                <IoReturnUpBackOutline
                  className="return-icons"
                  style={{
                    margin: "0px 5px 0px 0px",
                  }}
                />
              }
              onClick={handleMove}
              style={{
                margin: "0px 0px 0px 15px",
              }}
            >
              {`Return`}
            </Button>
          </Row>
        </Col>

        <Row className="row-table" style={{ marginTop: 35, width: "100%" }}>
          <Col span={24} style={{ marginTop: 10 }}>
            <Table
              className="guest-list-table"
              key="guest-list-table"
              bordered
              loading={loading}
              dataSource={data}
              columns={columns}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1750,
              }}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Col>
        </Row>
      </Row>

      <ModalEditGuest
        openModal={edit}
        closeModal={setEdit}
        dataGuest={edit == true ? dataEdit : ""}
        is_refresh={handleRefresh}
      />

      <Modal
        className="add-modal-guest"
        title={
          <>
            <Row>
              <PlusCircleFilled style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Individual Guest`}
              </Typography>
            </Row>
          </>
        }
        forceRender={true}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          name="guest-modal-form"
          className="guest-modal-form"
          layout="vertical"
          initialValues={{
            id_card_type: "KTP",
            country: "Indonesia",
            nationality: "Indonesia",
            sex: "Male",
            title: "Mr.",
            city: "",
            local_region: "",
            province: "",
            payment_type: "",
          }}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-id-card-type">
            <Col span={8}>
              <Form.Item
                label="ID Card Type"
                name={"id_card_type"}
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Identity Card Type!",
                  },
                ]}
                style={{ width: 205, marginRight: 30 }}
              >
                <MasterIdCardType getIdCardType={getIdCardType} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="ID Card No"
                name={"id_card_num"}
                rules={[
                  {
                    required: true,
                    message: "Please, Input Guest's Identity Number!",
                  },
                ]}
                style={{ width: 205, marginRight: 30 }}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Row className="btn-row" justify="space-between">
                <Form.Item>
                  <Button
                    className="next-btn"
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      handleFind({
                        id_card_type: form.getFieldValue("id_card_type"),
                        id_card_number: form.getFieldValue("id_card_num"),
                      });
                    }}
                    style={{ marginTop: 30 }}
                  >
                    {`Find Data`}
                  </Button>
                </Form.Item>

                {show == false ? (
                  <Tooltip className="icon-tooltip" title="ERROR!">
                    <TbCircleX
                      className="safe-icon"
                      style={{
                        margin: "10px 0px 0px",
                        fontSize: 50,
                        color: "#F64E60",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip className="icon-tooltip" title="SAFE!">
                    <TbCircleCheck
                      className="safe-icon"
                      style={{
                        margin: "10px 0px 0px",
                        fontSize: 50,
                        color: "#0BB7AF",
                      }}
                    />
                  </Tooltip>
                )}
              </Row>
            </Col>
          </Row>

          {alert == true ? (
            <Row
              className="row-alert"
              style={{
                padding: "15px 0px",
              }}
            >
              <Col span={24}>
                <Alert
                  message={
                    message == 1
                      ? "You Haven't Select ID Card Type/ID Card Number!"
                      : "ID Card Number is Already Taken!"
                  }
                  description={
                    message == 1
                      ? "Please, Choose an ID Card Type and Input the ID Card Number to Proceed!"
                      : "Same ID Card Number has been detected! You can use the Guest's Identity to Create Reservations!"
                  }
                  type="error"
                  showIcon
                  style={{
                    textAlign: "justify",
                    margin: "0px 0px 15px",
                  }}
                />

                {message == 2 ? (
                  <Alert
                    message={`The ID is belonged to a Guest named:`}
                    description={
                      <Typography
                        className="text-info"
                        style={{
                          fontWeight: 750,
                          fontSize: 20,
                        }}
                      >
                        {`${
                          guest?.full_name ? guest.full_name.toUpperCase() : ""
                        } 
                          `}
                      </Typography>
                    }
                    type="info"
                    showIcon
                    // style={{
                    // fontWeight: 750,
                    // }}
                  />
                ) : null}
              </Col>
            </Row>
          ) : null}

          {show == true ? (
            <>
              <Row gutter={30} className="row-guest-name">
                <Col span={8}>
                  <Form.Item
                    label="Full Name"
                    name={"full_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Guest's Full Name!",
                      },
                    ]}
                    style={{ width: 205, marginRight: 30 }}
                  >
                    <Input placeholder="Full Name" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="First Name"
                    name={"first_name"}
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Guest's First Name!",
                      },
                    ]}
                    style={{ width: 205, marginRight: 30 }}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Title"
                    name={"title"}
                    style={{ width: 205 }}
                  >
                    <MasterTitleType getTitleType={getTitleType} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="row-guest-phone">
                <Col span={8}>
                  <Form.Item
                    label="Phone"
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Phone Number!",
                        type: "number",
                        min: 10,
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Phone Number"
                      style={{ marginRight: 30, width: 205 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Birth Date"
                    name="birthdate"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Guest's Birthdate!",
                      },
                      {
                        validator: async (_, birthdate) => {
                          // var restricted = moment().
                          // console.log("UNDERSCORE = ", _);
                          // console.log("BIRTHDATE = ", birthdate);

                          let restricted = moment().subtract(17, "years");

                          console.log(restricted);

                          if (birthdate > restricted)
                            return Promise.reject(
                              "Guest Must be 17 Years Old or already have Personal ID Card!"
                            );
                        },
                      },
                    ]}
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[
                      {
                        required: true,
                        message: "Please, Select Guest's Nationality!",
                      },
                    ]}
                    style={{ width: 205 }}
                  >
                    <MasterCountry getCountry={getCountry} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="row-guest-country">
                <Col span={8}>
                  <Form.Item
                    label="Country"
                    name="country"
                    // onChange={getCountry}
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <MasterCountry getCountry={getCountry} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Province"
                    name="province"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Provinsi" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Local Region"
                    name="local_region"
                    style={{ width: 205 }}
                  >
                    <Input placeholder="Local Region" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="row-guest-address">
                <Col span={8}>
                  <Form.Item
                    label="City"
                    name="city"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="City" />
                  </Form.Item>

                  <Form.Item
                    label="Zip Code"
                    name="zip_code"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <InputNumber
                      placeholder="Zip Code"
                      type={"number"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={16} style={{ width: 205 }}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message:
                          "Guest Address must be filled and cannot be empty!",
                      },
                    ]}
                    style={{ width: 440 }}
                  >
                    <Input.TextArea placeholder="Address" rows={5} />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: 0, background: "#EBEDF3" }}
              />

              <Row gutter={30} className="row-guest-gender">
                <Col span={8}>
                  <Form.Item
                    label="Sex"
                    name="sex"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <MasterSexType getSexType={getSexType} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                      },
                    ]}
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item label="Fax" name="fax">
                    <InputNumber
                      placeholder="Fax"
                      type={"number"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col> */}
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 5px", background: "#EBEDF3" }}
              />

              {/* <Row gutter={30}>
                <Col span={8}>
                  <Form.Item label="Main Segment" name="main_segment">
                    <Input.TextArea placeholder="Main Segment" rows={3} />
                  </Form.Item>
                </Col>

                <Col span={16}>
                <Form.Item label="Comment" name="comment">
                    <Input.TextArea placeholder="Comment" rows={3} />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row gutter={30} className="row-guest-pay">
                <Col span={8}>
                  <Form.Item
                    label="Payment Type"
                    name="payment_type"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <MasterPaymentType
                      getPaymentType={getPaymentType}
                      // getPayTypeId={getPayTypeId}
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item label="Credit Limit" name="credit_limit">
                  <InputNumber
                      placeholder="Credit Limit"
                      type={"number"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col> */}

                <Col span={16}>
                  <Form.Item
                    label="Master Comp"
                    name="master_comp"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    {/* <Input placeholder="Master Comp" /> */}
                    <MasterCompany getMasterComp={getMasterComp} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}

          <Divider
            className="form-divider"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              disabled={!show}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

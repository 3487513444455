// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import { roles, token, user_name } from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Tooltip,
  Modal,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  DeleteTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import {
  failedArticleStay,
  successArticleStay,
} from "../../../Reusable/Notification/Notification";
import moment from "moment";

// Import Page Components

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function ArticleTable(props) {
  // PROPS
  const {
    dataAddArticle,
    editArticle,
    is_loading,
    is_edited,
    is_deleted,
    is_refresh,
    is_reset,
    is_selected,
  } = props;

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // Modal State
  const [loading, setLoading] = useState(true);
  // Select
  const [select, setSelect] = useState([]);

  // Handle Selection Table
  const handleSelection = {
    selectedRowKeys: select,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);

      setSelect(selectedRowKeys);
      setDataEdit(selectedRows[0]);
      editArticle(selectedRows[0]);
      is_edited(true);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataAddArticle.length > 0 && !cleanUp) {
      setLoading(true);
      // let date = moment().toISOString();

      // console.log("Data Articles => ", dataAddArticle);

      // setData(dataAddArticle);
      handleData(dataAddArticle);
    }

    if (is_loading && !cleanUp) {
      setLoading(is_loading);
    } else {
      setLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataAddArticle, is_loading]);

  useEffect(() => {
    handleReset();
  }, [is_reset]);

  // Column's Table
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 75,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    // {
    //   title: "Article Sub",
    //   dataIndex: "article_sub_name",
    //   key: "article_sub_name",
    //   width: 100,
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.article_sub_name - b.article_sub_name,
    // },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      width: 150,
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name - b.article_name,
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
      width: 70,
      render: (qty) => {
        return `${qty > 0 ? qty : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.item_quantity - b.item_quantity,
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "gross",
      key: "gross",
      width: 110,
      sortDirections: ["descend", "ascend"],
      render: (unit_price, record) => {
        return `${unit_price > 0 ? unit_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
      //sorter: (a, b) => a.unit_price.localeCompare(b.unit_price),
    },
    {
      title: `Total ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      width: 100,
      render: (total, record) => {
        return `${total > 0 ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      // sortDirections: ["descend", "ascend"],
      //sorter: (a, b) => a.arrival.localeCompare(b.arrival),
    },
    {
      title: "Order for",
      dataIndex: "order_date",
      key: "order_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
      // width: 100,
      // sortDirections: ["descend", "ascend"],
      //sorter: (a, b) => a.arrival.localeCompare(b.arrival),
    },
  ];

  // Handle Data Tables
  const handleData = (arr) => {
    const _arr = arr;
    console.log("Arrays: ", _arr, loading);

    setData([]);

    setTimeout(() => {
      setLoading(false);
      setData(_arr);
    }, 50);
  };

  // Handle Reset
  const handleReset = () => {
    // setData([]);
    setDataEdit(null);
    setSelect([]);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (value) => {
    let deleteContent = value;

    confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to Cancel Article ${deleteContent?.article_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // handleCancel();
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (e) => {
    const contentdelete = e;
    console.log("contentDelete >>>>>>", contentdelete);

    if (contentdelete.id) {
      var obj = {
        ["id"]: contentdelete?.id,
        ["reservation_id"]: contentdelete?.reservation_id,
        ["created_by"]: user_name,
      };
      // console.log(obj);
      await axios
        .delete(`${baseurl}/reservation/article-stay`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Delete => ", response);
          successArticleStay(response, { method: 2 });
          is_refresh(true);
        })
        .catch((error) => {
          failedArticleStay(error, { method: 2 });
          console.log("Delete => ", error);
        });
    } else {
      const filter = data.filter((value) => {
        // is_loading(true);
        if (value.key != contentdelete.key) {
          return value;
        }
      });
      is_deleted(filter);
      // is_loading(false);
      console.log("Filter Delete >>>>>>", filter);
    }
  };

  // console.log("TEST DATA TABEL", dataAddArticle);
  // setData(dataAddArticle);

  return (
    <>
      <Table
        className="article-stay-table"
        name="article-stay-table"
        key={"article-stay-table"}
        rowSelection={{
          type: "radio",
          ...handleSelection,
        }}
        columns={columns}
        dataSource={data}
        bordered
        size="middle"
        loading={loading}
        pagination={{
          defaultPageSize: 5,
          // pageSizeOptions: [10, 20, 50, 100],
          // showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} entries`,
        }}
        scroll={{
          // x: 800,
          y: true, //500,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? "odd" : "even";
        }}
        rowKey={(record) => (record.id ? record.id : record.key)}
      />
    </>
  );
}

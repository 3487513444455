import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";
import { FaClipboardList } from "react-icons/fa";
import { Content } from "antd/lib/layout/layout";
import { HiArchiveBoxArrowDown } from "react-icons/hi2";
import PRReportTable from "../../../../../Components/Table/Reports/BackOffice/Purchasing/PurchaseRequest/Table";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function PRReportPage() {
  // STATE MANAGEMENT
  // DATA
  const [type, setType] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/purchasing-report";

    navigate(`${path}`);
  };

  // HANDLE FILTER
  const handleFilter = (val) => {
    setType(val);
    setFilterValue(val);
  };

  const options = [
    {
      label: "Purchase Request",
      value: 1,
    },
    {
      label: "Daily Market List",
      value: 2,
    },
  ];

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <HiArchiveBoxArrowDown
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Purchase Request Reports`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Select Type`}
                  </Typography>

                  <Select
                    className="article-type-select"
                    key="article-type-select"
                    allowClear
                    showSearch
                    value={filterValue}
                    onChange={handleFilter}
                    options={options}
                    placeholder="Select Type"
                    style={{
                      width: 250,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleReturn}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Return`}
                  </Button>
                </Col>
              </Space>
            </Row>
            {type ? <PRReportTable type={type} /> : null}
          </Col>
        </Content>
      </Layout>
    </>
  );
}

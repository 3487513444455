// Import React's Components
import React, { useContext, useState, useEffect } from "react";

// CONTEXT
import {Context as InventoryContext} from "../../../../API/Context/InventoryContext/InventoryContext"

// Import Antd Components
import { Select } from "antd";

// CODE
export default function PurchaseOrderList({ getPONumber, po }) {
  // CONTEXT
  const { getPurchaseOrderList } = useContext(InventoryContext);
  // Data
  const [purchase, setPurchase] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getPurchaseOrderList({
      type: "/purchase-order-list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Purchase => ", response);

        if (response.data.msg?.length > 0) {
          let _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.id_po,
            label: `${dev.po_number} / ${dev.name}`,
            po_number: dev?.po_number,
            delivery_date: dev?.delivery_date,
            details: dev?.details
          }));

          console.log(option);
           setOptionValue(option);
        } else {
          setOptionValue([])
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Purchase ID ", po);

    if (po && !cleanUp) {
      setPurchase(po);
    }

    return () => {
      cleanUp = true;
    };
  }, [po]);

  // Handle Change
  const handleChange = (val, allKey) => {
    const _value = val;
    const _allKey = allKey;

    console.log(_value, _allKey);

    setPurchase(_value);
    getPONumber({
      value: _value,
      id: _allKey?.po_number,
      dev_date: _allKey?.delivery_date,
      details: _allKey?.details
    });
  };

  // Handle Clear
  const handleClear = () => {
    setPurchase(null);
  };

  return (
    <>
      <Select
        className="account-type-selector"
        key="account-type-selector"
        name="account-type-selector"
        allowClear
        showSearch
        value={purchase}
        // fieldNames={{
        //   label: "name",
        //   value: "name",
        //   code: "financial_statement_id",
        // }}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
    </>
  );
}
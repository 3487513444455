// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Button,
  Divider,
  Input,
  Modal,
  InputNumber,
  Card,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import { IoReturnUpBackOutline } from "react-icons/io5";
import { HiMiniChartBar } from "react-icons/hi2";
import { AiFillFolderOpen } from "react-icons/ai";

// Import Master Components

// Import Global Styles
import {
  cardHeader,
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../Style/Global/Global";

// Import Page Components
import ModalAuhtorized from "../../../../Components/Modals/Permissions/ModalCancel";
import InputSkeleton from "../../../../Components/Reusable/Skeleton/InputSkeleton";
import { RightCircleFilled } from "@ant-design/icons";

// Import Notification

// CODE
export default function ReportParams() {
  // USE NAVIGATE & LOCATIONS
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Handle Move
  const move = (e) => {
    navigate(`${pathname}/${e}`);
  };

  const moveReturn = () => {
    const _returnPath = "/configuration/parameters/general-report-params";
    console.log("Pathname: ", pathname);

    navigate(`${_returnPath}`);
  };
  // CONSOLE
  // console.log(data);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <HiMiniChartBar
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Daily Report Parameters & Settings`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row
              className="row-title"
              justify="start"
              align="middle"
              // gutter={[30, 30]}
              style={mainBody}
            >
              <Col
                className="col-second"
                span={24}
                style={{
                  padding: 30,
                  display: "flex",
                  gap: 5,
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}
              >
                <Row
                  className="return row"
                  justify="end"
                  align="top"
                  gutter={[15, 30]}
                >
                  <Button
                    type="default"
                    className="cancel-btn"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={moveReturn}
                  >{`Return`}</Button>
                </Row>

                <Card
                  className="masters-card-header"
                  // title={
                  //   <Typography
                  //     className="text-page"
                  //     style={{
                  //       // color: "#49D1CA",
                  //       fontSize: 20,
                  //     }}
                  //   >{`Daily Report Masters`}</Typography>
                  // }
                  bordered
                  style={{
                    margin: "30px 0px 0px",
                    border: "2px solid #f0f0f0",
                    borderRadius: 5,
                  }}
                  // bodyStyle={{
                  //   padding: "30px 0px 0px",
                  // }}
                >
                  <Card
                    className="card-link"
                    bordered
                    type="inner"
                    title={
                      // <Space className="title-card" direction="horizontal">
                      //   <GrDocumentConfig
                      //     className="params-icon"
                      //     style={{
                      //       color: "#49D1CA",
                      //     }}
                      //   />
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >{`Statistics`}</Typography>
                      /* </Space> */
                    }
                    // extra={
                    //   <Button
                    //     className="next-btn"
                    //     type="primary"
                    //     onClick={() => move("statistics")}
                    //     //   icon={<RightCircleFilled style={globalIcon} />}
                    //     //   style={linkStyle}
                    //   >{`Go to Page`}</Button>
                    // }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                    }}
                  >
                    {/* {`Parameters of Label and Data Statistics for Daily Revenue Report.`} */}
                    <Button
                      className="next-btn"
                      type="primary"
                      onClick={() => move("statistics")}
                      //   icon={<RightCircleFilled style={globalIcon} />}
                      //   style={linkStyle}
                    >{`Go to Page`}</Button>
                  </Card>

                  <Card
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Headers`}
                      </Typography>
                    }
                    bordered
                    // extra={
                    //   <Button
                    //     className="next-btn"
                    //     type="primary"
                    //     onClick={() => move("report-master-headers")}
                    //     //   icon={<RightCircleFilled style={globalIcon} />}
                    //     //   style={linkStyle}
                    //   >{`Go to Page`}</Button>
                    // }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    {/* {`Master for Header's Report`} */}
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("report-master-headers")}
                          // icon={<RightCircleFilled classID="icons-btn" style={globalIcon} />}
                          // style={linkStyle}
                        >
                          {`Go to Master's Page`}
                        </Button>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="params-col"
                      >
                        {/* {`Go to Parameters Page`} */}
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("drr-header-params")}
                          //   icon={<RightCircleFilled style={globalIcon} />}
                          //   style={linkStyle}
                        >{`Go to Parameter's Page`}</Button>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Details`}
                      </Typography>
                    }
                    bordered
                    // extra={
                    //   <Button
                    //     className="next-btn"
                    //     type="primary"
                    //     onClick={() => move("report-master-details")}
                    //     //   icon={<RightCircleFilled style={globalIcon} />}
                    //     //   style={linkStyle}
                    //   >{`Go to Page`}</Button>
                    // }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    {/* {`Master for Detail's Report`} */}
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("report-master-details")}
                          //   icon={<RightCircleFilled style={globalIcon} />}
                          //   style={linkStyle}
                        >{`Go to Master Detail's Page`}</Button>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="params-col"
                      >
                        {/* {`Go to Parameters Page`} */}
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("drr-detail-params")}
                          //   icon={<RightCircleFilled style={globalIcon} />}
                          //   style={linkStyle}
                        >{`Go to Parameter's Page`}</Button>
                      </Col>
                    </Row>
                  </Card>

                  <Card
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Segmentations`}
                      </Typography>
                    }
                    bordered
                    // extra={
                    // <Button
                    //   className="next-btn"
                    //   type="primary"
                    //   onClick={() => move("report-master-segmentations")}
                    //   //   icon={<RightCircleFilled style={globalIcon} />}
                    //   //   style={linkStyle}
                    // >{`Go to Page`}</Button>
                    // }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    {/* {`Master for Segment Statistics`} */}
                    <Row
                      className="main-params-row"
                      gutter={[30, 30]}
                      align="top"
                      justify="start"
                    >
                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="masters-col"
                      >
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("report-master-segmentations")}
                          //   icon={<RightCircleFilled style={globalIcon} />}
                          //   style={linkStyle}
                        >{`Go to Master's Page`}</Button>
                      </Col>

                      <Col
                        xxl={12}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        className="params-col"
                      >
                        {/* {`Go to Parameters Page`} */}
                        <Button
                          className="next-btn"
                          type="primary"
                          onClick={() => move("segmentations")}
                          //   icon={<RightCircleFilled style={globalIcon} />}
                          //   style={linkStyle}
                        >{`Go to Parameter's Page`}</Button>
                      </Col>
                    </Row>
                  </Card>
                </Card>

                {/* <Card
                  className="master-card-header"
                  title={
                    <Typography
                      className="text-page"
                      style={{
                        // color: "#49D1CA",
                        fontSize: 20,
                      }}
                    >{`Daily Report Parameters`}</Typography>
                  }
                  bordered
                  style={{
                    margin: "30px 0px 0px",
                    border: "2px solid #f0f0f0",
                    borderRadius: 5,
                  }}
                >
                  <Card
                    className="card-link"
                    bordered
                    type="inner"
                    title={
                      // <Space className="title-card" direction="horizontal">
                      //   <GrDocumentConfig
                      //     className="params-icon"
                      //     style={{
                      //       color: "#49D1CA",
                      //     }}
                      //   />
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >{`Statistics`}</Typography>
                      /* </Space> 
                    }
                    extra={
                      <Button
                        className="next-btn"
                        type="primary"
                        onClick={() => move("statistics")}
                        //   icon={<RightCircleFilled style={globalIcon} />}
                        //   style={linkStyle}
                      >{`Go to Page`}</Button>
                    }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                    }}
                  >
                    {`Parameters of Label and Data Statistics for Daily Revenue Report.`}
                  </Card>

                  <Card
                    className="card-link"
                    bordered
                    type="inner"
                    title={
                      // <Space className="title-card" direction="horizontal">
                      //   <GrDocumentConfig
                      //     className="params-icon"
                      //     style={{
                      //       color: "#49D1CA",
                      //     }}
                      //   />
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >{`Segmentations`}</Typography>
                      /* </Space> 
                    }
                    extra={
                      <Button
                        className="next-btn"
                        type="primary"
                        onClick={() => move("segmentations")}
                        //   icon={<RightCircleFilled style={globalIcon} />}
                        //   style={linkStyle}
                      >{`Go to Page`}</Button>
                    }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    {`Parameters of Label and Data Segmentations Daily Revenue Report.`}
                  </Card>

                  <Card
                    className="card-link"
                    bordered
                    type="inner"
                    title={
                      // <Space className="title-card" direction="horizontal">
                      //   <GrDocumentConfig
                      //     className="params-icon"
                      //     style={{
                      //       color: "#49D1CA",
                      //     }}
                      //   />
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >{`Header's Account`}</Typography>
                      /* </Space> 
                    }
                    extra={
                      <Button
                        className="next-btn"
                        type="primary"
                        onClick={() => move("drr-header-params")}
                        //   icon={<RightCircleFilled style={globalIcon} />}
                        //   style={linkStyle}
                      >{`Go to Page`}</Button>
                    }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    {`Parameters of Label and Data Header of Daily Revenue Report.`}
                  </Card>

                  <Card
                    className="card-link"
                    bordered
                    type="inner"
                    title={
                      <Typography
                        className="text-page"
                        style={{
                          // color: "#49D1CA",
                          fontSize: 25,
                        }}
                      >
                        {`Detail's Account`}
                      </Typography>
                    }
                    extra={
                      <Button
                        className="next-btn"
                        type="primary"
                        onClick={() => move("drr-detail-params")}
                        //   icon={<RightCircleFilled style={globalIcon} />}
                        //   style={linkStyle}
                      >{`Go to Page`}</Button>
                    }
                    style={{
                      border: "2px solid #f0f0f0",
                      borderRadius: 5,
                      margin: "30px 0px 0px",
                    }}
                  >
                    {`Parameters of Label and Data Details of Daily Revenue Report.`}
                  </Card>
                </Card> */}
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BiListPlus } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import MainSegmentTable from "../../../Components/Table/Master/Segment/Main/Table";
import SubSegmentTable from "../../../Components/Table/Master/Segment/Sub/Table";
// import ArrangementTable from "../../../Components/Table/Master/Arrangement/Table";
// import ArrangementArticleTable from "../../../Components/Table/Master/ArrangementArticle/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function MainSegmentPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState("");
  // Selected Arrangement
  const [arrangement, setArrangement] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  // console.log("PASSED ARRANGEMENT => ", arrangement);

  return (
    <Layout>
      <Content>
        <Col span={24} className="segment-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <BiListPlus
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Segment List`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Segment's Name"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          {/* <Row gutter={[30, 30]} className="row-supplier-table">
            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              // span={12}
              className="col-table"
            > */}
          <MainSegmentTable is_search={setSearch} searchKey={value} />
          {/* </Col>

            <Col
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              // span={12}
              className="col-table"
            >
              <SubSegmentTable is_search={setSearch} searchKey={value} />
            </Col> 
          </Row>
          */}
        </Col>
      </Content>
    </Layout>
  );
}

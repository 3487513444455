// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Table, Button, Col, Row, Tag, DatePicker, Typography } from "antd";

// Import React Icons
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons/lib/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Functions
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// CODE
export default function ReservationReportTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Table's Column
  const columns = [
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      fixed: "left",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guestname",
      fixed: "left",
      width: 250,
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">PRIVATE</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 75,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      // width: 120,
      render: (arrival, record) => {
        return moment(arrival).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Reservation Status",
      dataIndex: "reservation_status",
      key: "reservation_status",
      // width: 150,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      // width: 150,
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            color={clr}
            style={{
              fontSize: 13,
              fontWeight: 500,
              borderColor: "transparent",
            }}
          >
            {gst}
          </Tag>
        );
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      // width: 120,
      sorter: (a, b) => moment(a.departure) - moment(b.departure),
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "roomno",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      width: 200,
      render: (group, record) => {
        let grp = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            break;

          case 1:
            grp = "Group Leader Guest";
            break;

          case 2:
            grp = "Group Member Guest";
            break;

          default:
            break;
        }

        return grp;
      },
    },
    {
      title: "Memo",
      dataIndex: "memo_room",
      key: "memo_room",
      width: 300,
    },
  ];

  //CONTEXT
  const { getReservation } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  // Date
  const [datePicked, setDatePicked] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const location = useLocation();

  // GET DATA FUNCTION
  const fetchData = async () => {
    setLoading(true);

    await getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          setData(_data);
          setBulks(_data);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT Fetching Data
  useEffect(() => {
    fetchData();

    if (refresh == true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(
      data,
      `Reservation List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // Handle Filter
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const _arrival = moment(val.arrival).format("YYYY-MM-DD");

      return moment(_arrival).isBetween(_start, _end, undefined, "[]");
    });

    console.log("Today's Reservation: ", _filtered);
    setData(_filtered);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey;
    console.log("Searching = ", query);
    // console.log("Update List = ", updatedList);

    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        //   console.log("ITEM => ", item);
        return (
          item.guest_category !== "INCOGNITO" &&
          item.guest_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      });
      // Trigger render with updated values
      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/report/front-office";

    navigate(`${path}`);
  };

  return (
    <>
      <Row className="reservation-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }} className="col-table">
          <Row justify="start" className="row-btn">
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="col-export"
            >
              <Typography
                style={{ marginBottom: 10 }}
              >{`Pick Date (Arrival's)`}</Typography>

              <DatePicker.RangePicker
                className="date-btn"
                allowClear
                onChange={handleDate}
                // placeholder=""
                style={{
                  width: 300,
                }}
              />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="col-table-btn"
            >
              <Row
                justify="end"
                align="top"
                className="row-table-btn"
                gutter={[0, 15]}
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<FileExcelFilled />}
                >
                  {`Export to Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    fontWeight: 500,
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table--main-row" style={{ width: "100%" }}>
            {/* <Search
              placeholder="Input search text"
              key="search-bar"
              onSearch={handleSearch}
              // style={{
              //   margin: 30,
              // }}
            /> */}

            <Table
              className="table-reservation"
              name="table-reservation"
              key="table-reservation"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              size="middle"
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                // let check = checkAvailability(record)
                // console.log("Check > ", check)

                return index % 2 === 0 ? "even" : "odd";
              }}
              // onChange={(e) => {
              //   console.log("Data disini --> ", e);
              // }}
              scroll={{
                x: 1800,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

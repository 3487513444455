// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Tag,
  Typography,
} from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  SyncOutlined,
} from "@ant-design/icons";

// REACT ICONS
import { BsFillClipboardCheckFill } from "react-icons/bs";

// PAGE COMPONENTS
import StockTransferRequestApprovalDetailTable from "../../../Table/BackOffice/Inventory/TransferRequest/StockTransferRequestApprovalDetail/Table";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../../API/Config/Api";
import {
  id_warehouse,
  is_store_admin,
  token,
  user_level,
  user_name,
} from "../../../../API/Global/Payload";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { ApprovalIncomplete } from "../../../Reusable/Notification/Notification";

// MODALS
const { confirm } = Modal;

export default function StockTransferRequestModal(props) {
  // PROPS
  const { is_open, is_close, is_refresh, dataApproval } = props;

  // STATE MANAGEMENT
  // Data
  const [details, setDetails] = useState([]);
  const [defaultReqDetail, setDefaultReqDetail] = useState([]);
  // Table Edit
  const [updateData, setUpdateData] = useState([]);
  const [approvalList, setApprovalList] = useState([]);
  // Boolean
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_open && !cleanUp) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }

    if (dataApproval && !cleanUp) {
      console.log("DATA APPROVAL >> ", dataApproval);

      setDetails(dataApproval?.details);

      const filter = dataApproval?.details?.map((item) => {
        return {
          id_warehouse_transfer_detail: item.id_warehouse_transfer_detail,
          quantity_approved:
            item.quantity_approved !== null
              ? item.quantity_approved
              : item.quantity_requested,
          chart_of_account: item.chart_of_account
            ? item.chart_of_account
            : null,
        };
      });

      setDefaultReqDetail(filter);
      handleFormFields(dataApproval);
    } else {
      setDetails([]);
      setUpdateData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, dataApproval]);

  // HANDLE FORM FIELDS
  const handleFormFields = (rec) => {
    const _record = rec;
    const approval = rec?.approved_list?.length > 0 ? rec.approved_list : [];

    console.log("RECORD >> ", rec);

    let _arr = [];

    form.setFieldsValue({
      document_number: _record?.document_number,
      department_name: _record?.department_name,
      created_date: _record?.created_date
        ? moment(_record?.created_date)
        : null,
      needed_date: _record?.needed_date ? moment(_record?.needed_date) : null,
      transfer_type: _record?.transfer_type,
      initial_warehouse: _record?.initial_warehouse,
      destination_warehouse: _record?.destination_warehouse,
      description: _record?.description,
    });

    if (approval?.length > 0) {
      let _tags = approval.map((value, index) => {
        if (value == 1) {
          return (
            <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
              {`Approve ${index + 1}`}
            </Tag>
          );
        } else if (value === 2) {
          return (
            <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
              {`Rejected ${index + 1}`}
            </Tag>
          );
        } else {
          return (
            <Tag color="geekblue" key={index + 1} icon={<SyncOutlined spin />}>
              {`Waiting ${index + 1}`}
            </Tag>
          );
        }
      });

      _arr.push(_tags);
    } else {
      return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
    }

    // console.log("Approval List: ", rec.approval_list);
    console.log("Approval List: ", _arr);

    if (_arr.length > 0) {
      setApprovalList(_arr);
    } else {
      setApprovalList([]);
    }
  };

  // ON FINISH
  const onFinish = (value) => {
    const _val = value;

    console.log("Submit: ", _val);
    console.log("Updated Data: ", updateData);

    setIsLoading(true);
    showModalConfirm(_val);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _fail = value?.values;

    masterIncomplete(value);
    console.log("Submit Failed: ", _fail);
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    is_close();
    setModalOpen(false);

    setUpdateData([]);
    setApprovalList([]);
    setIsLoading(false);
    setDefaultReqDetail([]);

    form.resetFields();
  };

  // HANDLE EDIT DETAIL
  const handleEditDetail = (val) => {
    console.log("Edit data -- : ", val);

    if (updateData.length > 0) {
      const newData = [...updateData];
      const index = updateData.findIndex((item) => {
        // console.log(item);
        return (
          val.id_warehouse_transfer_detail === item.id_warehouse_transfer_detail
        );
      });

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          id_warehouse_transfer_detail: val.id_warehouse_transfer_detail,
          chart_of_account: val.chart_of_account,
          quantity_approved:
            val.quantity_approved !== null
              ? val.quantity_approved
              : val.quantity_requested,
        });

        console.log("Edited: ", newData);
        setUpdateData(newData);
      } else {
        newData.push({
          id_warehouse_transfer_detail: val.id_warehouse_transfer_detail,
          chart_of_account: val.chart_of_account,
          quantity_approved:
            val.quantity_approved !== null
              ? val.quantity_approved
              : val.quantity_requested,
        });
        setUpdateData(newData);
      }
    } else {
      setUpdateData([
        {
          id_warehouse_transfer_detail: val.id_warehouse_transfer_detail,
          chart_of_account: val.chart_of_account,
          quantity_approved:
            val.quantity_approved !== null
              ? val.quantity_approved
              : val.quantity_requested,
        },
      ]);
    }
  };

  // MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _submit = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to ${
        _submit?.is_approve === 1 ? "Approve" : "Reject"
      } this Stock Transfer/Request?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleApprove(_submit);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE APPROVAL DATA
  const handleApprove = async (val) => {
    console.log("UPDATE DATA > ", updateData);
    const _submit = val;

    const requestUpdate = details.map((detail) => {
      const updateInfo = updateData.find(
        (update) =>
          update.id_warehouse_transfer_detail ===
          detail.id_warehouse_transfer_detail
      );

      if (updateInfo) {
        return {
          ...detail,
          quantity_approved: updateInfo.quantity_approved,
          chart_of_account: updateInfo.chart_of_account || null,
        };
      } else {
        return {
          ...detail,
          quantity_approved: detail.quantity_approved
            ? detail.quantity_approved
            : detail.quantity_requested,
          chart_of_account: detail.chart_of_account || null,
        };
      }
    });

    const result = requestUpdate.map((detail) => ({
      id_warehouse_transfer_detail: detail.id_warehouse_transfer_detail,
      quantity_approved: detail.quantity_approved,
      chart_of_account: detail.chart_of_account,
    }));

    console.log("RESULT : ", result);

    const hasNullCOA = result.some((item) => item.chart_of_account === null);

    // if CC n has null coa, then invalid
    if (user_level > 3 && hasNullCOA) {
      // notif approval invalid
      ApprovalIncomplete();
      isLoading(false);
    } else {
      return await axios
        .put(
          `${baseurl}/approval/transfer-request`,
          {
            id_warehouse_transfer: dataApproval.id_warehouse_transfer,
            user_level: user_level,
            updated_by: user_name,
            approval_value: _submit?.is_approve,
            id_warehouse: id_warehouse,
            details: result.length > 0 ? result : defaultReqDetail,
            // is_warehouse_admin: is_store_admin,
          },
          {
            headers: { Authorization: `Bearer ${token || ""}` },
          }
        )
        .then((response) => {
          console.log("Success Approve/Rejects: ", response);

          masterSuccessNotification(response, {
            method: 5,
            source: "Transfer Request",
          });

          handleCancel();
          is_refresh();
        })
        .catch((error) => {
          console.log("Failed Approve/Rejects: ", error);

          masterFailedNotification(error, {
            method: 5,
            source: "Transfer Request",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Modal
        className="approval-modal"
        key={"approval-modal"}
        title={
          <Row className="title-row">
            <BsFillClipboardCheckFill
              color="#1890ff"
              style={{ fontSize: 24 }}
            />
            <Typography style={{ marginLeft: 15 }}>
              {`Stock Transfer/Request Approval`}
            </Typography>
          </Row>
        }
        onCancel={handleCancel}
        closable
        open={modalOpen}
        width={1100}
        footer={null}
      >
        <Form
          className="approval-form"
          key="approval-form"
          name="approval-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{
            padding: "0px 36px",
          }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="pay-row" gutter={30}>
                <Col span={8} className="pay-col">
                  <Form.Item label="Document Number" name="document_number">
                    <Input className="form-input" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30}>
                <Col span={8} className="pay-col">
                  <Form.Item label="Department" name="department_name">
                    <Input className="form-input" disabled />
                  </Form.Item>

                  <Form.Item label="Department ID" name="department_id" hidden>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8} className="post-date-col">
                  <Form.Item
                    label="Posting Date"
                    name="created_date"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, Select a Posting Date!",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      placeholder="Posting Date"
                      className="date"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="post-date-col">
                  <Form.Item
                    label="Needed Date"
                    name="needed_date"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, Select a Needed Date!",
                    //   },
                    // ]}
                  >
                    <DatePicker
                      placeholder="Needed Date"
                      className="date"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30}>
                <Col span={8} className="pay-col">
                  <Form.Item label="Type of Moving" name="transfer_type">
                    <Input className="form-input" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="pay-col">
                  <Form.Item label="From Store" name="initial_warehouse">
                    <Input className="form-input" disabled />
                  </Form.Item>
                </Col>

                <Col span={8} className="pay-col">
                  <Form.Item label="To Store" name="destination_warehouse">
                    <Input className="form-input" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30}>
                <Col span={12} className="pay-col">
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      disabled
                      className="form-input"
                      placeholder="Description"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                Approval List
              </Divider>

              {approvalList}

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Article Inventory List`}
              </Divider>

              <StockTransferRequestApprovalDetailTable
                article={details}
                getEditDetail={handleEditDetail}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row className="approve-row" justify="start">
                <Col span={24} className="app-col">
                  <Form.Item
                    label="Approve?"
                    name="is_approve"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Radio.Group
                      className="radio-group"
                      // onChange={handleReject}
                    >
                      <Radio value={1}>{`Approve`}</Radio>
                      <Radio value={2}>{`Rejects`}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row className="pay-row" gutter={30} justify="start">
                <Col span={24} className="pay-col">
                  <Form.Item
                    label="Approval/Rejects Remarks"
                    name="information"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Rejection's Reason!",
                      },
                    ]}
                    // hidden={!isReject}
                  >
                    <Input.TextArea
                      // disabled={is_edit ? 1 : 0}
                      className="remarks-area"
                      placeholder="Rejections remarks"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />
              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  loading={isLoading}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleCancel}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Components
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// REDUX
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../../../API/AuthActions/authAction";

// Token
import { user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import { FiEdit } from "react-icons/fi";
import { ImBlocked } from "react-icons/im";

// Import Notifications
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

const { confirm } = Modal;

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch Update --> ", dispatch);

  return {
    disBlock: (values) =>
      dispatch(
        auth.blockUser(values.id, values.ban_duration, values.blocked_by)
      ),
    disReset: (val) => dispatch(auth.resetRegister(val)),
  };
};

// Maps to Props
const mapStateToProps = (state) => {
  let response = state;
  let blockByAdmin = response?.rootReducer?.auth;

  // console.log("Return to Parents: ", blockByAdmin);

  return { blockByAdmin };
};

// CODE
const ModalAuthBlock = (props) => {
  // PROPS
  const { is_open, is_close, userData, is_refresh } = props;

  // STATE MANAGEMENT
  // Data
  const [dataUser, setDataUser] = useState(null);
  // BOOL
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (userData && !cleanUp) {
      console.log("Data Users: ", userData);

      setDataUser(userData);
      handleFormField(userData);
    } else {
      setDataUser(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open]);

  // AUTH UPDATE BY ADMIN
  useEffect(() => {
    let cleanUp = false;

    if (props?.blockByAdmin?.block_status === true && !cleanUp) {
      console.log("AUTH! Block is Success: ", props);
      props.disReset(true);

      is_refresh(true);
      handleClose();
    } else {
      setIsLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [props?.blockByAdmin?.block_status]);

  const handleFormField = (value) => {
    const _record = value;
    const _metadata = value?.user_metadata;

    form.setFieldsValue({
      user_name: _metadata?.user_name,
      email: _record?.email,
      department: _metadata?.department,
      roles: _metadata?.roles,
    });
  };

  // ON FINISH
  const onFinish = (val) => {
    const submit = val;

    console.log("Submit: ", submit);

    setIsLoading(true);
    showModalConfirm(submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);
    setOpen(false);

    setDataUser(null);
    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Block an account with named ${
        _data?.user_name?.toUpperCase() || " - - - "
      }? Once you confirm this Operations, the selected user would not be able to Sign-In until ${moment(
        _data?.ban_duration
      ).format("HH:mm:ss, DD-MM-YYYY")}! Please, Check the data again!`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleBlock(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Block
  const handleBlock = async (val) => {
    const _data = val;
    const _duration = moment
      .duration(_data?.ban_duration.diff(moment.now()))
      .asHours();
    //   .toFixed(0);

    if (_data) {
      props.disBlock({
        id: dataUser?.id,
        ban_duration: _duration,
        blocked_by: user_name,
      });
    } else {
      setIsLoading(false);
    }

    // console.log(
    //   "BLOCKS: ",
    //   moment.duration(_data?.ban_duration.diff(moment.now())).asSeconds() +
    //     " seconds"
    // );
    // console.log(
    //   "BLOCKS: ",
    //   moment.duration(_data?.ban_duration.diff(moment.now())).asMinutes() +
    //     " minutes"
    // );
    // console.log(
    //   "BLOCKS: ",
    //   moment.duration(_data?.ban_duration.diff(moment.now())).asHours() +
    //     " hours"
    // );

    // await axios
    //   .put(
    //     `${baseurl}/auth/admin/block`,
    //     {
    //       id: dataUser?.id,
    //       //   blocked_status: 2,
    //       ban_duration: `${_duration}h`,
    //       blocked_by: user_name,
    //     },
    //     {
    //       headers: { Authorization: `Bearer ${token ? token : null}` },
    //     }
    //   )
    //   .then((response) => {
    //     console.log("Ban Success: ", response);

    //     masterSuccessNotification(response, { method: 3, source: "User" });
    //     handleClose();
    //   })
    //   .catch((error) => {
    //     console.log("Ban Failed: ", error);
    //     masterFailedNotification(error, { method: 3, source: "User" });
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  return (
    <>
      <Modal
        className="modal-access"
        key="modal-access"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <ImBlocked
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Block Confirmation`}</Typography>
            </Row>
          </>
        }
        open={open}
        footer={false}
        centered
        closable
        destroyOnClose={true}
        onCancel={handleClose}
        width={1000}
      >
        <Form
          form={form}
          className="form-user"
          key="form-user"
          name="form-user"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="main-modal-row">
            <Col span={24} className="main-modal-col">
              <Row className="user-name" gutter={30}>
                <Col span={12} className="user-name-col">
                  <Form.Item name="user_name" label="User Name">
                    <Input
                      placeholder="User Name"
                      className="user-input"
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="user-name-col">
                  <Form.Item name="email" label="E-mail">
                    <Input placeholder="User Name" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "10px 0px", borderColor: "#EBEDF3" }}
              >
                {`Department & Roles`}
              </Divider>

              <Row className="dept-row" gutter={30}>
                <Col span={12} className="user-name-col">
                  <Form.Item name="department" label="Department">
                    <Input
                      placeholder="Department"
                      className="user-input"
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={12} className="user-name-col">
                  <Form.Item name="roles" label="Roles">
                    <Input placeholder="Roles" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px", borderColor: "#EBEDF3" }}
              />

              <Row className="access" gutter={30}>
                <Col span={12} className="module-col">
                  <Form.Item
                    name="ban_duration"
                    label="Block Until"
                    rules={[
                      { required: true, message: "Please, Select a Date!" },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      showTime={{
                        format: "HH:mm",
                      }}
                      format="YYYY-MM-DD HH:mm"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", borderColor: "#EBEDF3" }}
              />

              <Row className="user_name" gutter={30} justify="end">
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{
                    margin: "0px 15px 0px",
                  }}
                >{`Submit`}</Button>

                <Button
                  className="cancel-btn"
                  onClick={handleClose}
                  type="default"
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAuthBlock);

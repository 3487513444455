// REACT COMPONENTS
import React, { useState } from "react";

// ANTD COMPONENTS
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// REACT ICONS
import { TbCheckupList } from "react-icons/tb";

// PAGE COMPONENTS
import APListTable from "../../../../Components/Table/BackOffice/AP/APList/Table";

export default function APListPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  // Search
  const [search, setSearch] = useState();

  // HANDLE Date
  const handleDate = (e) => {
    const value = e;
    // e.preventDefault();

    console.log("DATE Value: ", value);

    setDateRange(value);
  };

  // HANDLE Input
  const handleInput = (e) => {
    const value = e;
    // e.preventDefault();

    console.log("DATE Value: ", value);

    setDateRange(value);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    setSearch(true);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <TbCheckupList
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`A/P Payment List`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 150,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Select Date Range`}</Typography>

                  <DatePicker.RangePicker
                    allowClear
                    className="range-date"
                    onChange={handleDate}
                    style={{
                      maxWidth: 250,
                      minWidth: 150,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <APListTable
              date_range={dateRange}
              searchKey={value}
              is_search={search}
              is_research={setSearch}
            />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Card, Col, Row, Typography } from "antd";

// Import Icons
import { EllipsisOutlined } from "@ant-design/icons";
import { GiExitDoor } from "react-icons/gi";
import { Content } from "antd/lib/layout/layout";
import { failedFetch } from "../../Reusable/Notification/Notification";

// Styling
const styleNumber = {
  color: "#EE9D01",
  fontSize: 35,
  fontWeight: 500,
};

const styleText = {
  color: "#b3b3b3",
  fontSize: 14,
  fontWeight: 500,
};

const cardItemStyle = {
  margin: "-60px 0px 0px 0px",
  borderRadius: 12,
  padding: 10,
  paddingLeft: 30,
  width: "100%",
  backgroundColor: "#FFFFFF",
  boxShadow: "5px 5px 5px #d9d9d9",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  minHeight: 150,
};

const cardBodyStyle = {
  display: "flex",
  justifyContent: "center",
};

export default function HouseKeepingActivity() {
  // STATE MANAGEMENT
  const { getHouseKeepingAct, getExpectedDeparture } = useContext(MainContext);
  const [data, setData] = useState(null);
  const [dataExpDepart, setDataExpDepart] = useState(null);
  const [dataVacantCleanChecked, setDataVacantCleanChecked] = useState(null);
  const [dataVacantCleanUnchecked, setDataVacantCleanUnchecked] =
    useState(null);
  const [dataOccupiedClean, setDataOccupiedClean] = useState(null);
  const [dataOccupiedDirty, setDataOccupiedDirty] = useState(null);
  const [dataVacantDirty, setDataVacantDirty] = useState(null);
  const [dataExpectedDeparture, setDataExpectedDeparture] = useState(null);

  // FETCH ROOM STATUS
  const fetchHouseKeepingAct = () => {
    getHouseKeepingAct({
      activity: "housekeeping",
      type: "activity",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);
        setData(response.data.msg);
      },
      onReject: (error) => {
        console.log("Error ", error);
        failedFetch(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchHouseKeepingAct();
  }, []);

  useEffect(() => {
    setDataVacantCleanChecked(
      data
        ? data.find((e) => e.room_status_id == 2)
          ? data.find((e) => e.room_status_id == 2).amount_of_room_status
          : 0
        : 0
    );

    setDataVacantCleanUnchecked(
      data
        ? data.find((e) => e.room_status_id == 1)
          ? data.find((e) => e.room_status_id == 1).amount_of_room_status
          : 0
        : 0
    );

    setDataOccupiedClean(
      data
        ? data.find((e) => e.room_status_id == 4)
          ? data.find((e) => e.room_status_id == 4).amount_of_room_status
          : 0
        : 0
    );

    setDataOccupiedDirty(
      data
        ? data.find((e) => e.room_status_id == 3)
          ? data.find((e) => e.room_status_id == 3).amount_of_room_status
          : 0
        : 0
    );

    setDataVacantDirty(
      data
        ? data.find((e) => e.room_status_id == 5)
          ? data.find((e) => e.room_status_id == 5).amount_of_room_status
          : 0
        : 0
    );
  }, [data]);

  // Fetch Data
  const fetchExpDeparture = () => {
    // setLoading(true);

    getExpectedDeparture({
      expectedDeparture: "guest-in-house",
      type: "stay-dep",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Expected Departure => ", response);

        setDataExpDepart(response?.data?.msg);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
        // failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchExpDeparture();
    // is_search(() => handleSearch);
  }, []);

  // {data
  //   ? data.find((e) => e.room_status_id == 2)
  //       .amount_of_room_status
  //   : 0}

  //   console.log(dataVacantCleanChecked);

  return (
    <Card
      title={
        <>
          <Row gutter={30} style={{ minHeight: 22, padding: "0px 30px 50px" }}>
            <Col span={24}>
              <Typography
                style={{ fontSize: 20, fontWeight: 600, color: "#FFFFFF" }}
              >
                House Keeping Activity
              </Typography>
            </Col>
          </Row>
        </>
      }
      className="card-room-sold"
      headStyle={{
        background: `var(
          --button-secondary-hover,
          linear-gradient(262deg, #0a8781 0%, #2fd9d0 99.19%)
        )`,
        borderRadius: "12px 12px 0px 0px",
      }}
      bodyStyle={{
        backgroundColor: "#fafafa",
        borderRadius: 12,
      }}
      style={{
        borderRadius: 12,
        margin: "0px 30px 1px 0px",
      }}
    >
      <Row gutter={[20, 80]}>
        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {dataVacantCleanChecked ? dataVacantCleanChecked : 0}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Vacant Clean Checked</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {dataVacantCleanUnchecked ? dataVacantCleanUnchecked : 0}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Vacant Clean Unchecked</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {dataOccupiedClean ? dataOccupiedClean : 0}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Occupied Clean</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {(dataVacantCleanChecked ? dataVacantCleanChecked : 0) +
                  (dataVacantCleanUnchecked ? dataVacantCleanUnchecked : 0) +
                  (dataOccupiedClean ? dataOccupiedClean : 0)}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Total Cleaned</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {dataOccupiedDirty ? dataOccupiedDirty : 0}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Occupied Dirty</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {dataVacantDirty ? dataVacantDirty : 0}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Vacant Dirty</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {dataExpDepart?.length > 0 ? dataExpDepart.length : 0}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Expected Departure</Typography>
            </Card.Grid>
          </Card>
        </Col>

        <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }}>
          <Card
            bordered={false}
            style={cardItemStyle}
            bodyStyle={cardBodyStyle}
          >
            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleNumber}>
                {(dataOccupiedDirty ? dataOccupiedDirty : 0) +
                  (dataVacantDirty ? dataVacantDirty : 0)}
              </Typography>
            </Card.Grid>

            <Card.Grid
              style={{
                padding: 0,
                boxShadow: "none",
                width: "100%",
              }}
            >
              <Typography style={styleText}>Total Dirty</Typography>
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </Card>
  );
}

// Body Functions
const AccessControlJSON = [
  {
    1: {
      9: [],
      10: [],
      11: [],
      12: [],
      13: [],
      14: [],
      15: [],
      16: [],
      17: [],
      18: [],
      19: [],
      20: [],
      21: [],
      22: [],
      23: [],
      24: [],
    },
    2: {
      37: [],
      38: [],
      39: [],
      40: [],
      41: [],
    },
    3: {
      4: {
        29: [],
        30: [],
        31: [],
        32: [],
        33: [],
        35: [],
        36: [],
      },
      25: [],
      26: {
        97: [],
        98: [],
        99: [],
      },
      27: {
        47: [],
        48: [],
        49: [],
        50: [],
        51: [],
        101: [],
      },
      28: {
        52: [],
        53: [],
        54: [],
        55: [],
        56: [],
        100: [],
        103: [],
        104: [],
      },
    },
    5: {
      42: {
        57: [],
        58: [],
      },
      43: {
        59: [],
        60: [],
        61: [],
        62: [],
        105: [],
      },
    },
    6: [],
    7: {
      63: [],
      64: [],
      65: [],
      66: [],
      67: [],
    },
    8: {
      44: {
        68: [],
        69: [],
        70: [],
        71: [],
        72: [],
        73: [],
        74: [],
        75: [],
        76: [],
        77: [],
        78: [],
        79: [],
        80: [],
        81: [],
        82: [],
        83: [],
        84: [],
        85: [],
        86: [],
      },
      45: {
        87: [],
        88: [],
        89: [],
        90: [],
        91: [],
        92: [],
        93: [],
      },
      46: {
        94: [],
        95: [],
        96: [],
      },
    },
  },
];

// EXPORTS
export { AccessControlJSON };

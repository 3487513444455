// ANTD COMPONENTS
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Tag, Tooltip } from "antd";

// REACT COMPONENTS
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MdLocalPrintshop } from "react-icons/md";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import ModalStockTransferRequest from "../../../../../Modals/Inventory/StockTransferRequest/Modal";
import StockTransferRequestForm from "../../../../../Forms/StockTransferRequest/Form";

// CONTEXT
import { Context as InventoryContext } from "../../../../../../API/Context/InventoryContext/InventoryContext";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../../../Reusable/Notification/MasterNotif/Notification";

// GLOBAL API
import { token, user_name } from "../../../../../../API/Global/Payload";
import axios from "axios";
import { baseurl } from "../../../../../../API/Config/Api";

// MODAL CONFIRM
const { confirm } = Modal;

export default function StockTransferRequestTable(props) {
  // Props
  const {
    is_search,
    searchKey,
    requestDate,
    transferRequestType,
    initialWarehouse,
    destinationWarehouse,
  } = props;

  // Context
  const { getStockTransferRequestList } = useContext(InventoryContext);

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [dataPrint, setDataPrint] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);
  // Modal add/edit
  const [open, setOpen] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [edit, setEdit] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // USE EFFECT FETCH DATA
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [
    searchKey,
    transferRequestType,
    requestDate,
    initialWarehouse,
    destinationWarehouse,
  ]);

  // FETCH DATA
  const fetchData = async () => {
    await getStockTransferRequestList({
      type: "transfer-request-stocks",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("TRANSFER/REQUEST RESPONSE => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    setRefresh(false);
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    var obj = {
      ["id_warehouse_transfer"]: contentdelete.id_warehouse_transfer,
      ["deleted_by"]: user_name,
    };

    axios
      .delete(`${baseurl}/inventory/transfer-request-stocks`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        masterSuccessNotification(response, {
          method: 2,
          source: "Transfer/Request",
        });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, {
          method: 2,
          source: "Transfer/Request",
        });
      });
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "delete-warehouse-confirm",
      title: `Are you sure want to delete Transfer/Request with Doc Number named ${_data?.document_number.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
      },

      onCancel() {
        setLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE EXPORT EXCEL
  const handleExport = () => {
    downloadExcel(bulks, "Stock Transfer/Request List");
  };

  // HANDLE RETURN TO MENU INVENTORY
  const handleReturn = () => {
    const path = "/back-office/inventory";

    navigate(`${path}`);
  };

  // HANDLE OPEN
  const handleOpen = () => {
    setOpen(true);
  };

  // HANDLE OPEN PRINT
  const handleOpenPrint = () => {
    setOpenPrint(true);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 140,
      align: "center",
      render: (_, record) => {
        let isRejected =
          record?.approved_list?.length > 0
            ? record.approved_list[0] === 2
              ? true
              : false
            : false;

        return (
          <>
            <Row
              className="action-table-btn"
              style={{ width: "100%" }}
              justify={!isRejected && "center"}
            >
              <Col span={8} className="col-print">
                <Tooltip className="print-tooltip" title="Print Data">
                  <MdLocalPrintshop
                    className="edit-btn"
                    onClick={() => {
                      handleOpenPrint();
                      setDataPrint(record);
                    }}
                    style={{
                      fontSize: 24,
                      color: "#3699FF",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              {isRejected ? (
                <>
                  <Col span={8} className="col-edit">
                    <Tooltip className="edit-tooltip" title="Edit Data">
                      <FiEdit
                        className="edit-btn"
                        onClick={() => {
                          handleOpen();
                          setEdit(true);
                          setDataEdit(record);
                        }}
                        style={{
                          fontSize: 20,
                          color: "#1BC5BD",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Col>

                  <Col span={8} className="col-del">
                    <Tooltip className="del-tooltip" title="Delete Data">
                      <DeleteFilled
                        className="delete-btn"
                        onClick={() => {
                          showModalConfirm(record);
                        }}
                        style={{ fontSize: 20, color: "#F64E60" }}
                      />
                    </Tooltip>
                  </Col>
                </>
              ) : null}
            </Row>
          </>
        );
      },
    },
    {
      title: "Document Number",
      dataIndex: "document_number",
      key: "document_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.document_number.localeCompare(b.document_number),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.department_name.localeCompare(b.department_name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Type of Moving",
      dataIndex: "transfer_type",
      key: "transfer_type",
    },
    {
      title: "Posting Date",
      dataIndex: "created_date",
      key: "created_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
      render: (date) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Needed Date",
      dataIndex: "needed_date",
      key: "needed_date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.needed_date.localeCompare(b.needed_date),
      render: (date) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "From Store",
      dataIndex: "initial_warehouse",
      key: "initial_warehouse",
    },
    {
      title: "To Store",
      dataIndex: "destination_warehouse",
      key: "destination_warehouse",
    },

    {
      title: "Approval Status",
      dataIndex: "approved_list",
      key: "approved_list",
      width: 150,
      render: (approval) => {
        if (approval?.length > 0) {
          let _tags = approval.map((value, index) => {
            if (value == 1) {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {`Approve ${index + 1}`}
                </Tag>
              );
            } else if (value === 2) {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`Rejected ${index + 1}`}
                </Tag>
              );
            } else {
              return (
                <Tag
                  color="geekblue"
                  key={index + 1}
                  icon={<SyncOutlined spin />}
                >
                  {`Waiting ${index + 1}`}
                </Tag>
              );
            }
          });

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`NO DATA`}</Tag>;
        }
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approved_date",
      key: "approved_date",
      width: 250,
      render: (date, record) => {
        if (date?.length > 0) {
          let _tags = date.map((value, index) => {
            if (value !== "-") {
              return (
                <Tag color="green" key={index + 1} icon={<CheckCircleFilled />}>
                  {moment(value).format("DD-MM-YYYY, h:mm:ss a")}
                </Tag>
              );
            } else {
              return (
                <Tag color="red" key={index + 1} icon={<CloseCircleFilled />}>
                  {`-`}
                </Tag>
              );
            }
          });

          return _tags;
        } else {
          return <Tag color="black" key="unknown">{`-`}</Tag>;
        }
      },
    },
    {
      title: "Receive Status",
      dataIndex: "is_received",
      key: "is_received",
      render: (is_received, record) => {
        const isApporoved = !record.approved_list.includes(0);

        if (is_received && isApporoved) {
          return <Tag color="green">Received</Tag>;
        } else if (!is_received && !isApporoved) {
          return <Tag color="red">Pending</Tag>;
        } else {
          return <Tag color="blue">Waiting for Receive</Tag>;
        }
      },
    },
    {
      title: "Received By",
      dataIndex: "received_by",
      key: "received_by",
    },
  ];

  // HANDLE SEARCH
  const handleSearch = () => {
    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    const query = searchKey?.toLowerCase() || null;
    const type = transferRequestType?.toLowerCase() || null;
    const date =
      requestDate && requestDate !== "Invalid date"
        ? formatDate(requestDate)
        : null;
    const initial = initialWarehouse?.toLowerCase() || null;
    const destination = destinationWarehouse?.toLowerCase() || null;

    if (!bulks?.length) {
      setData([]);
      return;
    }

    const filters = (item) => {
      const docNumber = item.document_number?.toLowerCase() || "";
      const transferType = item.transfer_type?.toLowerCase() || "";
      const postDate = formatDate(item.created_date);
      const initWarehouse = item.initial_warehouse?.toLowerCase() || "";
      const destWarehouse = item.destination_warehouse?.toLowerCase() || "";

      return (
        (!query || docNumber.includes(query)) &&
        (!type || type === transferType) &&
        (!date || date === postDate) &&
        (!initial || initial === initWarehouse) &&
        (!destination || destination === destWarehouse)
      );
    };

    const updatedList = bulks.filter(filters);
    setData(updatedList);
  };

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Request/Transfer`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="transfer-request-table"
              name="transfer-request-table"
              key="transfer-request-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1950,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.document_number}
            />
          </Row>
        </Col>
      </Row>

      <ModalStockTransferRequest
        openModal={open}
        closeModal={setOpen}
        dataStock={dataEdit}
        is_refresh={fetchData}
        is_edit={edit}
        set_edit={setEdit}
      />

      <StockTransferRequestForm
        openModal={openPrint}
        closeModal={setOpenPrint}
        dataPrint={dataPrint}
      />
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterPaymentCardEdit(props) {
  // PROPS
  const {
    getPayCard,
    payCard,
    // CODE
    getPayTypeId,
    getCode,
    name,
    is_split,
  } = props;

  // CONTEXT
  const { getMasterPaymentCard } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [paycard, setPaycard] = useState(null);
  const [optionValue, setOptionValue] = useState([]);
  const [bulks, setBulks] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getMasterPaymentCard({
      paymentCard: "payment-card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0 || response?.data != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.name,
            label: dev?.name,
            code: dev?.code,
            id: dev?.id,
            pay_id: dev?.payment_type_id,
            coa: dev?.chart_of_account,
          }));

          // console.log(option);
          setBulks(option);
        } else {
          setBulks([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (getPayTypeId > 0 && !cleanUp) {
      console.log("Filter must be run! ", getPayTypeId);
      // handleFilter(getPayTypeId);
      fetchData();
    } else {
      setPaycard(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [getPayTypeId]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("NAMES/KEYS: ", name);

    if (bulks.length > 0 && !cleanUp) {
      handleFilter();
    }

    return () => {
      cleanUp = true;
    };
  }, [bulks]);

  // Handle Existed
  const handleFilter = () => {
    // const _idTypePay = idPay;

    // console.log("PAY CARD TYPE: ", _idTypePay);
    console.log("Option value: ", getPayTypeId, payCard);

    let findCoa = bulks.filter((value) => {
      return value?.pay_id == getPayTypeId;
    });

    console.log("Filtered Payment Cards: ", findCoa);

    if (findCoa?.length > 0) {
      setOptionValue(findCoa);
      setPaycard(findCoa[0]?.value);

      if (is_split == true) {
        getPayCard({
          name: findCoa[0]?.value,
          id: findCoa[0]?.id,
          coa: findCoa[0]?.coa,
          keys: name,
        });
      } else {
        getPayCard({
          name: findCoa[0]?.value,
          id: findCoa[0]?.id,
          coa: findCoa[0]?.coa,
        });
      }
    } else {
      setOptionValue([]);
      setPaycard(null);
      getPayCard({
        name: null,
        id: 0,
        coa: null,
      });
    }
  };

  // Handle Change
  const handleChange = (val, alls) => {
    const _name = val;
    const _allkeys = alls;

    setPaycard(_name);

    if (is_split == true) {
      getPayCard({
        id: _allkeys?.id,
        coa: _allkeys?.coa,
        name: _name,
        keys: name,
      });
    } else {
      getPayCard({
        id: _allkeys?.id,
        coa: _allkeys?.coa,
        name: _name,
      });
    }
  };

  // Handle Clear
  const handleClear = (value) => {
    console.log("On Clear: ", value);

    setPaycard(null);
  };

  // console.log("PAYCARD >>>", paycard);

  return (
    <>
      <Select
        key={"payment-details-select"}
        className="payment-details-select"
        allowClear
        placeholder="Select Payment Details!"
        showSearch
        value={paycard}
        onChange={(value, allKey, index) => {
          handleChange(value, allKey);
        }}
        options={optionValue}
        onClear={handleClear}
        style={{ width: "100%" }}
      />
    </>
  );
}

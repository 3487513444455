// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../src/API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Form,
  Input,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import { FaConciergeBell, FaUserFriends } from "react-icons/fa";
import { ImEnter } from "react-icons/im";
import {
  CheckCircleFilled,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons/lib/icons";

// Notification
import {
  failedAddReserv,
  failedCheckIn,
  incomplete,
  successAddReserv,
  successCheckIn,
} from "../../Reusable/Notification/Notification";

// Import Page Components
import MasterRoomNumberCkIn from "../../Reusable/Master/RoomNumber/RoomCheckIn";
import MasterRoomCatCheckIn from "../../Reusable/Master/RoomCat/OptionRoomCategory";
import RegistrationForm from "../../Forms/Registration/Form";

// MODAL ANTD
const { confirm } = Modal;

// CODE
export default function ArrivalPopover({ details, is_refresh }) {
  //CONTEXT
  const { getReservation, getGuest, getArticleRoom } = useContext(MainContext);

  // STATE MANAGEMENT
  // Open Pop Over
  const [open, setOpen] = useState(false);
  // Data
  const [detail, setDetail] = useState(null);
  const [data, setData] = useState(null);
  const [roomCatCode, setRoomCatCode] = useState(null);
  const [roomNumberId, setRoomId] = useState(null);
  // Chart of Account
  const [coa, setCoa] = useState(null);
  // const [roomPricePick, setRoomPricePick] = useState(null);
  const [roomNumberCode, setRoomNumberCode] = useState(null);
  const [dataGuest, setDataGuest] = useState(null);
  // Price List
  const [price, setPrice] = useState(null);
  // Modals
  const [openForm, setOpenForm] = useState(false);
  //   const [details, setDetails] = useState()

  // TOKEN
  const bearer = token;
  const config = { Authorization: `Bearer ${bearer ? bearer : ""}` };

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchDataPrice = () => {
    getArticleRoom({
      articleRoomPrice: "reservation",
      type: "article-room-single",
      res_id: detail?.reservation_id
        ? detail?.reservation_id
        : details?.reservation_id,
      prc_id: detail?.price_id ? detail?.price_id : details?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Article Room => ", response);

        setPrice(response?.data);
        // setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // GET DATA RESERVATION
  const fetchData = () => {
    getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        let filtered = response.data.msg.filter((e) => {
          if (detail.id == e.id) {
            return e;
          }
        });

        console.log("filtered Res >>>>", filtered);
        console.log(detail);
        setData(filtered?.length > 0 ? filtered[0] : []);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // Fetch Data Guest
  const fetchDataGuest = () => {
    getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        let filtered = response.data.msg.filter((e) => {
          if (details.guest_name == e.full_name) {
            return e;
          }
        });

        console.log("Filter Guest >>> ", filtered);
        setDataGuest(filtered.length > 0 ? filtered[0] : []);
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setOpen(!open);

    // setCoa(null);
    // setData(null);
    // setDataGuest(null);
    // setRoomCatCode(null);
    // setDetail(null);
    // setPrice(null);
    // setRoomNumberCode(null);
  };

  // USE EFFECT
  useEffect(() => {
    if (open == true && details != null) {
      // fetchData();
      fetchDataGuest();
      fetchDataPrice();

      // Set Data
      setDetail(details);

      form.setFieldsValue({
        reservation_id: details?.reservation_id
          ? details.reservation_id
          : detail.reservation_id,
        room_number: details?.room_number > 0 ? details.room_number : 0,
        room_category: details?.room_category
          ? details.room_category
          : detail.room_category,
      });
    }
  }, [open, openForm, details]);

  // GET ROOM NUMBER
  const getRoomNumber = (value, code) => {
    console.log(value, code);

    setRoomNumberCode(code);
    // setCoa(code);
    setRoomId(value);

    form.setFieldsValue({
      room_number: value,
    });
  };

  // COA
  const getRoomCoa = (value, code) => {
    console.log(value, code);

    setCoa(value);
  };

  // ON FINISH
  const onFinish = async (value) => {
    const submit = value;

    console.log("ON FINISH --> ", submit);
    console.log("Details --> ", detail);

    // if (detail?.room_number == 0) {
    handleSubmit(submit);
    // } else {
    //   fetchData();
    //   fetchDataGuest();

    //   setTimeout(() => {
    //     setOpenForm(!openForm);
    //   }, 2000);
    // }
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    // e.preventDefault();
    const submit = e;

    console.log("EDITED > ", submit);

    await axios
      .put(
        `${baseurl}/reservation/room-number`,
        {
          room_number: roomNumberCode
            ? roomNumberCode
            : detail?.room_number
            ? detail.room_number
            : submit?.room_number,
          price_id: detail?.price_id ? detail.price_id : submit?.price_id,
          reservation_id: detail?.reservation_id
            ? detail.reservation_id
            : submit?.reservation_id,
          updated_by: user_name,
        },
        { headers: config }
      )
      .then((response) => {
        console.log("Room Number Updated: ", response);

        fetchData();
        fetchDataGuest();
        fetchDataPrice();

        successCheckIn(response, { method: 1 });
        // is_refresh(true);

        setTimeout(() => {
          setOpenForm(!openForm);
        }, 2000);
      })
      .catch((error) => {
        console.log("Error Check In: ", error);
        failedCheckIn(error, { method: 1 });
      });
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Failed >>> ", error);

    incomplete(error);
    setOpen(true);
  };

  // console.log(" >>>>>>", roomPricePick);
  // console.log("POPOVER ARRIVAL => ", details);
  // console.log("Token Bearer => ", config);

  return (
    <>
      <Tooltip title="Check-In" className="checkin-tooltip">
        <Popover
          className="pop-over-checkin"
          name="pop-over-checkin"
          key="pop-over-checkin"
          placement="right"
          title={
            <Row className="row-content-popover">
              <Col span={4} className="col-content-popover">
                <UnorderedListOutlined
                  className="popover-btn"
                  style={{
                    padding: "8px 8px 8px 0px",
                    fontSize: 14,
                    color: "#13c2c2",
                  }}
                />
              </Col>

              <Col span={20}>
                <Typography
                  style={{
                    padding: 5,
                    fontSize: 14,
                    color: "#13c2c2",
                    fontWeight: 400,
                  }}
                >
                  Check-In Option
                </Typography>
              </Col>
            </Row>
          }
          content={
            <>
              <Form
                name="checkin-form"
                className="checkin-form"
                key="checkin-form"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Row
                  className="row-checkin-popover"
                  justify="start"
                  style={{ width: 250, padding: "0px 20px" }}
                >
                  <Form.Item
                    label={<b style={{ fontSize: "12px" }}>Reservation No.</b>}
                    name="reservation_id"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ width: "100%" }}
                  >
                    <Input
                      placeholder="Reservation No."
                      disabled
                      style={{ fontSize: 12, width: 200 }}
                    />
                  </Form.Item>
                </Row>

                <Row
                  className="row-checkin-popover"
                  justify="start"
                  style={{ width: 250, padding: "0px 20px" }}
                >
                  <Form.Item
                    label={<b style={{ fontSize: "12px" }}>Room Category</b>}
                    name="room_category"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ width: "100%" }}
                  >
                    <Input
                      placeholder="Room Category"
                      disabled
                      style={{ fontSize: 12, width: 200 }}
                    />
                  </Form.Item>
                </Row>

                <Row
                  className="row-room-no"
                  justify="start"
                  style={{ width: 250, padding: "0px 20px" }}
                >
                  <Form.Item
                    label={<b style={{ fontSize: "12px" }}>Room No.</b>}
                    name="room_number"
                    rules={[
                      {
                        required: true,
                        message: "Room Number is Empty!",
                      },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ width: "100%" }}
                  >
                    <MasterRoomNumberCkIn
                      rateRoomCatg={roomCatCode}
                      getRoomNumber={getRoomNumber}
                      // getRoomNumberCode={setRoomNumberCode}
                      getRoomCoa={getRoomCoa}
                      room_number={
                        details?.room_number ? details.room_number : 0
                      }
                      existed_coa={setCoa}
                    />

                    {/* 
                    <MasterRoomCatCheckIn
                      convertRoomCat={details.room_category}
                      returnRoomCatCode={setRoomCatCode}
                    /> */}

                    {/* <ArticleRoomPrice
                      reservationId={details.reservation_id}
                      priceId={details.price_id}
                      pickRoomPrice={setRoomPricePick}
                    /> */}
                  </Form.Item>
                </Row>

                <Row
                  className="row-submit-btn"
                  justify="start"
                  style={{ width: 250, padding: 20 }}
                >
                  <Button
                    className="check-in-btn"
                    type="primary"
                    htmlType="submit"
                    key={"submit"}
                    icon={<CheckCircleFilled />}
                    onClick={() => {
                      handleClose();
                      setData(detail);
                      // setTimeout(() => {
                      //   setOpenModRegForm(!openModRegForm);
                      // }, 2000);
                    }}
                  >
                    Check In
                  </Button>
                </Row>
              </Form>
            </>
          }
          open={open}
          onOpenChange={handleClose}
          trigger="click"
          style={{ margin: 0, padding: 0 }}
        >
          <Button
            type="primary"
            className="popover-btn"
            icon={
              <ImEnter
                style={{
                  // fontSize: 20,
                  // padding: "0px 1px",
                  margin: "0px 10px 0px 0px",
                }}
              />
            }
            style={{ backgroundColor: "#13c2c2", borderColor: "#13c2c2" }}
          >
            Check In
          </Button>
        </Popover>
      </Tooltip>

      <RegistrationForm
        openModal={openForm}
        closeModal={setOpenForm}
        dataRes={openForm == true ? data : ""}
        dataGuest={openForm == true ? dataGuest : ""}
        dataRoom={openForm == true ? price : ""}
        dataCoa={openForm == true ? coa : ""}
        is_refresh={is_refresh}
      />
    </>
  );
}

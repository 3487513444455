// Import React's Components
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token } from "../../../API/Global/Payload";

// Import's Auth Components
import { connect } from "react-redux";
import * as auth from "../../../API/AuthActions/authAction";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  List,
  Modal,
  Radio,
  Result,
  Row,
  Skeleton,
  Space,
  Tabs,
  Typography,
} from "antd";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import InputSkeleton from "../../Reusable/Skeleton/InputSkeleton";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";

// Modals
const { confirm } = Modal;

// Form Layout
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch Update --> ", dispatch);

  return {
    disUpdateByAdmin: (values) => dispatch(auth.updateByAdmin(values)),
    disReset: (val) => dispatch(auth.resetRegister(val)),
  };
};

// Maps to Props
const mapStateToProps = (state) => {
  let response = state;
  let updateByAdmin = response?.rootReducer?.auth;

  // console.log("Return to Parents: ", updateByAdmin);

  return { updateByAdmin };
};

// CODE
const AuthTabs = (props) => {
  // PROPS
  //   const { items } = props;

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE LOCATION
  const { state, pathname } = useLocation();

  // Smallest ID
  const _small = state?.user_metadata?.module.sort()[0];

  // STATE MANAGEMENT
  // Data
  const [dataItems, setDataItems] = useState([]);
  const [activeKey, setActiveKey] = useState(
    _small > 0 ? _small.toString() : null
  );
  const [bulks, setBulks] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  // IS UPDATE
  const [isUpdate, setIsUpdate] = useState(0);

  // FORM
  const [form] = Form.useForm();

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    await axios
      .get(`${baseurl}/auth/admin/module`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleFilterModule(_res);
        } else {
          setDataItems(defaultItems);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
      });
  };

  // USE EFFECTs
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (
      dataItems?.length > 0 &&
      state?.user_metadata?.access_control?.length > 0 &&
      !cleanUp
    ) {
      console.log("State: ", state);
      // console.log("Pathname: ", pathname);
      // console.log("Props: ", props);

      handleFormField(state.user_metadata.access_control[0]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataItems, state]);

  // AUTH UPDATE BY ADMIN
  useEffect(() => {
    let cleanUp = false;

    if (
      props?.updateByAdmin?.is_admin_update === true &&
      isUpdate == 1 &&
      !cleanUp
    ) {
      props.disReset(true);
      console.log("AUTH! Update Success: ", props?.updateByAdmin);

      // if (isUpdate == 1) {
      handleClear();
      setIsUpdate(isUpdate + 1);
    } else {
      setIsLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [props?.updateByAdmin?.is_admin_update]);

  // ON FINISH
  const onFinish = (val) => {
    const _submit = val;

    console.log("Submitted: ", _submit);

    setIsLoading(true);
    showModalConfirm(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _fail = val?.values;

    console.log("Failed: ", _fail);
  };

  // ON Change
  const onChange = (val) => {
    const _fail = val;

    console.log("On Change Key: ", _fail);
    setActiveKey(_fail);
  };

  // ON CHECK
  const getAccess = (val) => {
    const _fail = val;

    console.log("On Change Checkbox: ", _fail);
    // setActiveKey(_fail);
  };

  // Handle Form Field
  const handleFormField = (value) => {
    console.log("EDIT: ", value);
    console.log("Form: ", form);

    form.setFieldsValue({ ...value });
  };

  // Handle Clear
  const handleClear = () => {
    console.log("UPDATE SUCCESS!!!");
    // is_refresh(true);

    handleReturn();
  };

  // Handle Return
  const handleReturn = () => {
    const _path = "/configuration/user-management/access-control";
    // console.log(pathname);

    navigate(`${_path}`);
  };

  // Results
  const ResultElement = () => {
    return (
      <Col
        span={24}
        className="zero-col"
        style={{ minHeight: "25vh", width: "100%" }}
      >
        <Row className="default-row" justify="center" align="middle">
          <Result
            title="This user's Module data is not Assigned yet! Please, assign it first and return to this page later!"
            className="result-info"
            status="info"
            extra={
              <Button
                className="refresh-btn"
                type="default"
                key="console"
                onClick={handleReturn}
                icon={
                  <IoReturnUpBackOutline
                    className="return-icons"
                    style={{
                      margin: "0px 5px 0px 0px",
                    }}
                  />
                }
              >
                {`Return`}
              </Button>
            }
            style={{
              padding: "60px 0px 30px",
            }}
          />
        </Row>
      </Col>
    );
  };

  // Handle Filter Module & Sub-Module
  const handleFilterModule = (val) => {
    const _user_module =
      state?.user_metadata?.module?.length > 0
        ? state?.user_metadata?.module
        : [];
    const _user_sub =
      state?.user_metadata?.sub_module?.length > 0
        ? state?.user_metadata?.sub_module
        : [];
    const _data = val;

    // Arr Temp
    let _arr = [];

    // Mapped Modules
    const _filter_main = _data.map((_parent) => {
      if (_user_module.includes(_parent?.id)) {
        return {
          disabled: false,
          label: _parent?.name,
          title: _parent?.name,
          value: _parent?.id,
          code: _parent?.code,
          id_module: _parent?.id_module,
          key: _parent?.id.toString(),
          id: _parent?.id,
          children: _parent.children,
        };
      } else {
        return {
          disabled: true,
          label: _parent?.name,
          title: _parent?.name,
          value: _parent?.id,
          code: _parent?.code,
          id_module: _parent?.id_module,
          key: _parent?.id.toString(),
          id: _parent?.id,
          children: _parent.children,
        };
      }
    });

    // Mapped Sub-Modules
    const _filter_sub = _filter_main.map((record) => {
      const _children = record?.children?.length > 0 ? record.children : [];
      // console.log("Record Parents: ", record);

      const _filtered = _children.map((_detail) => {
        if (_user_sub.includes(_detail?.id)) {
          return {
            disabled: false,
            label: _detail?.name,
            title: _detail?.name,
            value: _detail?.id,
            code: _detail?.code,
            id_module: _detail?.id_module,
            key: _detail?.id.toString(),
            id: _detail?.id,
            parent_id: _detail?.parent_id,
            children: _detail?.children,
          };
        } else {
          return {
            disabled: true,
            label: _detail?.name,
            title: _detail?.name,
            value: _detail?.id,
            code: _detail?.code,
            id_module: _detail?.id_module,
            key: _detail?.id.toString(),
            id: _detail?.id,
            parent_id: _detail?.parent_id,
            children: _detail?.children,
          };
        }
      });

      let _sorted = _filtered.sort((a, b) => a.id - b.id);

      // console.log("Sorted: ", _sorted);
      return { ...record, children: _sorted };
    });

    console.log("Filter Modules: ", _filter_main);
    console.log("Filter Sub-Modules: ", _filter_sub);

    if (_filter_sub.length > 0) {
      handleGrandChildren(_filter_sub);
    } else {
      setDataItems(defaultItems);
    }
  };

  // Handle Filter Sub-Module
  const handleGrandChildren = (val) => {
    const _user_grand =
      state?.user_metadata?.grand_module?.length > 0
        ? state?.user_metadata?.grand_module
        : [];
    const _parents = val;

    console.log("Grand-Module: ", _user_grand);

    const _filter_grand = _parents.map((record) => {
      const child = record?.children?.length > 0 ? record?.children : [];

      const _filterChild = child.map((record_child) => {
        const _grand =
          record_child?.children?.length > 0 ? record_child.children : [];

        const _filtGrand = _grand.map((record_grand) => {
          // console.log(`Grand-Childrens ${_rec.name.toUpperCase()}`, _rec);

          if (_user_grand.includes(record_grand?.id)) {
            // console.log("Grand Children is check: ", _rec);
            return {
              disable: false,
              title: record_grand?.name,
              key: record_grand?.id.toString(),
              id: record_grand?.id,
              label: record_grand?.name,
              value: record_grand?.id,
              children_id: record_grand?.children_id,
              id_module: record_grand?.id_module,
            };
          } else {
            return {
              disable: true,
              title: record_grand?.name,
              key: record_grand?.id.toString(),
              id: record_grand?.id,
              label: record_grand?.name,
              value: record_grand?.id,
              children_id: record_grand?.children_id,
              id_module: record_grand?.id_module,
            };
          }
        });

        // console.log(`Array Grand ${rec.label} & ${rec.id}`, _arr_grand);
        let _sorted = _filtGrand.sort((a, b) => a.id - b.id);

        // console.log("Sorted: ", _sorted);

        return { ...record_child, children: _sorted };
      });

      return { ...record, children: _filterChild };
    });

    // console.log("Filter: ", _filter);
    console.log("Filter Grand Children: ", _filter_grand);

    if (_filter_grand?.length > 0) {
      handleGenerate(_filter_grand);
    } else {
      setDataItems(defaultItems);
    }
  };

  // Handle Generate
  const handleGenerate = (val) => {
    const _data = val;
    // console.log("Before Mapped: ", val);
    // handleFilter(val);

    let _map = _data.map((value) => {
      if (value?.id === 6) {
        return {
          label: value?.label,
          key: (value?.id).toString(),
          forceRender: true,
          disabled: value?.disabled,
          children: (
            <>
              <Form.Item
                key={value?.id_module}
                label={`${value?.label} Access`}
                name={`${value?.id}`}
                style={{
                  padding: 30,
                }}
              >
                <Checkbox.Group
                  key={value?.id}
                  disabled={value?.disabled}
                  className="method-check"
                >
                  <Row className="checkbox-row" gutter={30}>
                    <Col span={6}>
                      <Checkbox
                        value={"READ"}
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        READ
                      </Checkbox>
                    </Col>

                    <Col span={6}>
                      <Checkbox
                        value="CREATE"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        CREATE
                      </Checkbox>
                    </Col>

                    <Col span={6}>
                      <Checkbox
                        value="UPDATE"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        UPDATE
                      </Checkbox>
                    </Col>

                    <Col span={6}>
                      <Checkbox
                        value="DELETE"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        DELETE
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </>
          ),
        };
      } else {
        return {
          label: value?.label,
          key: (value?.id).toString(),
          forceRender: true,
          disabled: value?.disabled,
          children: (
            <>
              <List
                className="sub-module-list"
                key={value?.id_module}
                itemLayout="horizontal"
                dataSource={value?.children}
                style={{
                  padding: 10,
                }}
                renderItem={(submodule) => {
                  //   let submodule = submod?.p_module;
                  //   console.log("Value: ", submodule);
                  //   console.log("Sub-Module: ", submodule);

                  if (submodule?.children?.length > 0) {
                    return (
                      <>
                        <Divider
                          className="line-divider"
                          orientation="left"
                          orientationMargin={0}
                          style={{
                            margin: "10px 0px",
                            borderColor: "#EBEDF3",
                          }}
                        >
                          {`${submodule.label}:`}
                        </Divider>

                        <List
                          className="grand-module-list"
                          key={submodule?.id_module}
                          itemLayout="horizontal"
                          dataSource={submodule.children}
                          grid={{
                            column: 2,
                          }}
                          style={{
                            margin: "0px 0px 15px",
                          }}
                          renderItem={(grand) => {
                            // console.log("Grand Children: ", grand);
                            return (
                              <>
                                <Form.Item
                                  key={grand?.id_module}
                                  label={`${grand?.label}`}
                                  name={[
                                    `${value?.id}`,
                                    `${submodule?.id}`,
                                    `${grand?.id}`,
                                  ]}
                                  // valuePropName="checked"
                                  // initialValue={}
                                  // noStyle
                                >
                                  <Checkbox.Group
                                    className="grand-check"
                                    key={grand?.id}
                                    disabled={grand?.disable}
                                  >
                                    <Row className="checkbox-row" gutter={30}>
                                      <Col span={6}>
                                        <Checkbox
                                          value={"READ"}
                                          style={{
                                            lineHeight: "32px",
                                          }}
                                        >
                                          {`READ`}
                                        </Checkbox>
                                      </Col>

                                      <Col span={6}>
                                        <Checkbox
                                          value="CREATE"
                                          style={{
                                            lineHeight: "32px",
                                          }}
                                        >
                                          {`CREATE`}
                                        </Checkbox>
                                      </Col>

                                      <Col span={6}>
                                        <Checkbox
                                          value="UPDATE"
                                          style={{
                                            lineHeight: "32px",
                                          }}
                                        >
                                          {`UPDATE`}
                                        </Checkbox>
                                      </Col>

                                      <Col span={6}>
                                        <Checkbox
                                          value="DELETE"
                                          style={{
                                            lineHeight: "32px",
                                          }}
                                        >
                                          {`DELETE`}
                                        </Checkbox>
                                      </Col>
                                    </Row>
                                  </Checkbox.Group>
                                </Form.Item>
                              </>
                            );
                          }}
                        />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Form.Item
                          key={submodule?.id_module}
                          label={`${submodule?.label} Access`}
                          name={[`${value?.id}`, `${submodule?.id}`]}
                          style={{
                            padding: 0,
                          }}
                        >
                          <Checkbox.Group
                            className="method-check"
                            key={submodule?.id}
                            disabled={submodule?.disabled}
                          >
                            <Row className="checkbox-row" gutter={30}>
                              <Col span={6}>
                                <Checkbox
                                  value={"READ"}
                                  style={{
                                    lineHeight: "32px",
                                  }}
                                >
                                  {`READ`}
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox
                                  value="CREATE"
                                  style={{
                                    lineHeight: "32px",
                                  }}
                                >
                                  {`CREATE`}
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox
                                  value="UPDATE"
                                  style={{
                                    lineHeight: "32px",
                                  }}
                                >
                                  {`UPDATE`}
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox
                                  value="DELETE"
                                  style={{
                                    lineHeight: "32px",
                                  }}
                                >
                                  {`DELETE`}
                                </Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </>
                    );
                  }
                }}
              />
            </>
          ),
        };
      }
    });

    console.log("Hasil Map: ", _map);

    if (_map?.length > 0) {
      setDataItems(_map);
      setLoading(false);
      setIsUpdate(1);

      if (state?.user_metadata?.module?.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setDataItems(defaultItems);
      setIsUpdate(0);
    }
  };

  // Default tabs item
  const defaultItems = [
    {
      label: "General",
      key: "default",
      children: <ResultElement />,
    },
    {
      label: "Front Office",
      key: "1",
      disabled: true,
      children: null,
    },
    {
      label: "House Keeping",
      key: "2",
      children: "Tab 2",
      disabled: true,
    },
    {
      label: "Back Office",
      key: "3",
      children: "Tab 3",
      disabled: true,
    },
    {
      label: "Accounting",
      key: "4",
      children: "Tab 4",
      disabled: true,
    },
    {
      label: "F & B",
      key: "5",
      children: "Tab 5",
      disabled: true,
    },
    {
      label: "Dashboard",
      key: "6",
      children: "Tab 6",
      disabled: true,
    },
    {
      label: "General Reports",
      key: "7",
      children: "Tab 7",
      disabled: true,
    },
    {
      label: "Configuration",
      key: "8",
      children: "Tab 8",
      disabled: true,
    },
  ];

  // Show Modal Confirm
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Assign this Changes of ${
        state?.user_metadata?.user_name?.toUpperCase() || " - - - "
      }'s data Access Control & Permission?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Update
  const handleUpdate = (value) => {
    const body = {
      ...state?.user_metadata,
      access_control: [value],
      uid: state?.id,
      email: state?.email,
    };

    if (body) {
      console.log("Ini value didalam handel Update: ", body);
      console.log("Props: ", props);

      props.disUpdateByAdmin(body);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row className="space-component" justify="start" style={mainBody}>
        <Col
          span={24}
          className="auth-col-name"
          style={{ padding: "30px 30px 0px" }}
        >
          <Descriptions
            className="user-desc"
            title="User Info"
            layout="vertical"
            bordered
            style={{
              padding: "0px 0px 15px",
            }}
          >
            <Descriptions.Item label="User Name">
              <Typography.Title level={4}>
                {state?.user_metadata?.user_name || "ERROR!"}
              </Typography.Title>
            </Descriptions.Item>

            <Descriptions.Item label="Email">
              <Typography.Title level={4}>
                {state?.email || "ERROR!"}
              </Typography.Title>
            </Descriptions.Item>

            <Descriptions.Item label="Department">
              <Typography.Title level={4}>
                {state?.user_metadata?.department || "ERROR!"}
              </Typography.Title>
            </Descriptions.Item>
          </Descriptions>
        </Col>

        {/* <Button type="default" onClick={fetchData}>{`Refresh`}</Button> */}

        <Col
          span={24}
          className="tabs-col"
          style={{
            minHeight: "50vh",
            padding: "0px 0px 30px",
          }}
        >
          <Form
            {...formItemLayout}
            labelAlign="left"
            form={form}
            name="user-form"
            className="user-form"
            key="user-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="horizontal"
            labelWrap={true}
            style={{ padding: "0px 30px 30px", fontSize: 8 }}
          >
            <Divider
              className="divider"
              orientation="left"
              orientationMargin={15}
              style={{
                margin: "10px 0px",
                borderColor: "#EBEDF3",
              }}
            >
              {`Manage Permissions`}
            </Divider>

            {!loading ? (
              <Tabs
                type="card"
                onChange={onChange}
                key="user-tabs"
                name="user-tabs"
                className="user-tabs"
                items={dataItems}
                activeKey={activeKey}
              />
            ) : (
              <InputSkeleton />
            )}

            {isDisabled && !loading ? <ResultElement /> : null}

            <Divider
              className="divider"
              style={{
                margin: "10px 0px",
                background: "#EBEDF3",
              }}
            />

            <Row justify="end" className="row-btn">
              <Button
                className="submit-btn"
                htmlType="submit"
                type="primary"
                disabled={isDisabled}
                loading={isLoading}
                style={{
                  margin: "0px 15px 0px",
                }}
              >{`Submit`}</Button>

              <Button
                className="refresh-btn"
                type="default"
                onClick={handleReturn}
              >{`Cancel`}</Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthTabs);

// React Components
import React from "react";

// Import Antd Components
import { Row, Spin } from "antd";
import { ImSpinner6 } from "react-icons/im";
import { CgSpinnerTwoAlt } from "react-icons/cg";
import {
  LoadingOutlined,
  ReloadOutlined,
  SmileOutlined,
} from "@ant-design/icons";

// CODE
export default function SpinnerComponents() {
  return (
    <>
      <Row
        justify="center"
        align="middle"
        className="spinner-row"
        style={{
          padding: 30,
        }}
      >
        <Spin
          className="spinner"
          size="large"
          spinning={true}
          // indicator={
          //   <LoadingOutlined
          //     className="spin-icons"
          //     spin
          //     // size={50}
          //     style={{
          //       fontSize: 75,
          //       // animation: "spin 2s linear infinite",
          //       // transform: "rotate(90deg)",
          //       // motionRotation: ''
          //     }}
          //   />
          // }
          wrapperClassName="spin-wrap"
        />
      </Row>
    </>
  );
}

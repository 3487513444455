// Import React Components
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../../API/AuthActions/authAction";

// AUTH
import {
  department,
  email,
  user_name,
  roles,
  approval_level,
} from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { PiUserFocusBold } from "react-icons/pi";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";

// CONFIRM
const { confirm } = Modal;

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch Update --> ", dispatch);

  return {
    disUpdate: (values) => dispatch(auth.updateUser(values)),
    disReset: (val) => dispatch(auth.resetRegister(val)),
  };
};

// Maps to Props
const mapStateToProps = (state) => {
  let response = state;
  let updateByUser = response?.rootReducer?.auth;

  console.log("Return to Parents: ", updateByUser);

  return { updateByUser };
};

// CODE
const UserProfilePage = (props) => {
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState({
    email: email,
    user_name: user_name,
    department: department,
    roles: roles,
    approval_level: approval_level,
  });
  const [avatar, setAvatar] = useState("");
  const [roleName, setRoleName] = useState(null);
  // IS EDIT
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (roles && !cleanUp) {
      handleAvatar(roles);
    }

    if (data && roleName && !cleanUp) {
      handleFormField(data);
    }

    return () => {
      cleanUp = true;
    };
  }, [roles, data, roleName]);

  // AUTH UPDATE BY ADMIN
  useEffect(() => {
    let cleanUp = false;

    if (props?.updateByUser?.is_update === true && !cleanUp) {
      // console.log("AUTH! Update is Success: ", props);
      props.disReset(true);

      setIsEdit(false);

      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 500);
    } else {
      setIsLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [props?.updateByUser?.is_update]);

  // ON FINISH
  const onFinish = (value) => {
    const _submit = value;

    setIsLoading(true);

    console.log("Submit: ", _submit);
    showModalConfirm(_submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _submit = value;

    console.log("Failed: ", _submit?.values);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure you wanted to Update your Username?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleUpdate(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Avatar
  const handleAvatar = (ava) => {
    const user_roles = ava;

    switch (user_roles) {
      case "ROLE_SUPER_ADMIN":
        setAvatar("G");
        setRoleName("Super-Admin");
        break;

      case "ROLE_ADMIN":
        setAvatar("A");
        setRoleName("Admin");
        break;

      case "ROLE_STAFF":
        setAvatar("S");
        setRoleName("Staff");
        break;

      case "ROLE_SUPERVISOR":
        setAvatar("SV");
        setRoleName("SV/HOD");
        break;

      case "ROLE_COST_CONTROL":
        setAvatar("CC");
        setRoleName("Cost Control");
        break;

      case "ROLE_FINANCE_LEADER":
        setAvatar("HoF");
        setRoleName("Head of Finance");
        break;

      case "ROLE_MANAGER":
        setAvatar("M");
        setRoleName("Manager");
        break;

      case "ROLE_GENERAL_MANAGER":
        setAvatar("GM");
        setRoleName("Gen. Manager");
        break;

      case "ROLE_OWNER":
        setAvatar("O");
        setRoleName("Owner/Director");
        break;

      default:
        setAvatar("?");
        setRoleName("?");
        break;
    }

    // console.log("Users: ", user_name);
  };

  // Handle Edit
  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  // Handle Return
  const handleReturn = () => {
    const _path = "/dashboard";

    sessionStorage.setItem("locationState", JSON.stringify(["dashboard", ""]));

    navigate(_path);
  };

  // Handle Update
  const handleUpdate = (value) => {
    const _data = value;

    if (_data) {
      props.disUpdate({
        email: _data?.email,
        password: _data?.password,
        new_user_name: _data?.user_name,
      });
    } else {
      setIsLoading(false);
    }
  };

  // Handle Form Field
  const handleFormField = (record) => {
    const _record = record;

    form.setFieldsValue({
      ..._record,
      roles: roleName,
    });
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <PiUserFocusBold
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`User Profiles`}
              </Typography>
            </Row>

            <Row className="row-body" justify="start" style={mainBody}>
              <Col
                span={16}
                className="main-col"
                style={{
                  padding: 30,
                }}
              >
                <Form
                  form={form}
                  className="user-form"
                  key="user-form"
                  name="user-form"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="horizontal"
                  labelAlign="left"
                  labelCol={{
                    span: 8,
                  }}
                  labelWrap={{
                    span: 16,
                  }}
                >
                  <Row className="avatar-row" justify="center">
                    <Col span={24} className="avatar-col">
                      <Row className="avatar-row" justify="center">
                        <Form.Item name="avatar">
                          <Avatar
                            shape="circle"
                            className="avatar-username"
                            size={{
                              xs: 24,
                              sm: 32,
                              md: 40,
                              lg: 64,
                              xl: 80,
                              xxl: 100,
                            }}
                            style={{
                              color: "#1BC5BD",
                              fontWeight: "bolder",
                              fontSize: "50px",
                              backgroundColor: "#C9F7F5",
                              // height: 27,
                              margin: "5px 0px 0px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {avatar}
                          </Avatar>
                        </Form.Item>
                      </Row>

                      <Row className="uname-row" justify="start">
                        <Col span={24}>
                          <Form.Item label="User Name" name="user_name">
                            <Input
                              className="user-input"
                              size="large"
                              placeholder="User Name"
                              disabled={!isEdit}
                              addonAfter={
                                <Button
                                  type="primary"
                                  className="next-btn"
                                  onClick={handleEdit}
                                  style={{
                                    width: 100,
                                    height: 38,
                                  }}
                                >
                                  {isEdit ? "Cancel" : "Edit"}
                                </Button>
                              }
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row className="dept-row" justify="start">
                        <Col span={24}>
                          <Form.Item label="Department" name="department">
                            <Input placeholder="Department" disabled />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row className="email-row" justify="start">
                        <Col span={24}>
                          <Form.Item label="Email" name="email">
                            <Input placeholder="E-Mail" disabled />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row className="roles-row" justify="start">
                        <Col span={24}>
                          <Form.Item label="Roles" name="roles">
                            <Input placeholder="Roles" disabled />
                          </Form.Item>
                        </Col>
                      </Row>

                      {isEdit ? (
                        <Divider
                          className="divider-form"
                          style={{
                            margin: "5px 0px 10px",
                            borderColor: "#EBEDF3",
                          }}
                        />
                      ) : null}

                      <Row className="pw-row" justify="start">
                        <Col span={24}>
                          <Form.Item
                            label="Password"
                            name="password"
                            hidden={!isEdit}
                            rules={[
                              {
                                required: true,
                                message: "Please, Input Your Password!",
                              },
                            ]}
                          >
                            <Input.Password placeholder="Password" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Divider
                        className="divider-form"
                        style={{ margin: "5px 0px", borderColor: "#EBEDF3" }}
                      />

                      <Row
                        className="roles-row"
                        justify="end"
                        style={{
                          margin: "30px 0px 0px",
                        }}
                      >
                        <Button
                          className="submit-btn"
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                          disabled={!isEdit}
                          style={{
                            margin: "0px 15px 0px 0px",
                          }}
                        >
                          {`Submit & Update`}
                        </Button>

                        <Button
                          className="cancel-btn"
                          type="default"
                          onClick={handleReturn}
                        >
                          {`Cancel`}
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col span={8}>{null}</Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);

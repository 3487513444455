// Import React Components
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Antd Components
import {
  Col,
  Layout,
  Row,
  Typography,
  Form,
  Button,
  Divider,
  Input,
  DatePicker,
  Modal,
  InputNumber,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons Components
import {
  ExclamationCircleTwoTone,
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  SearchOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { RiHomeGearFill } from "react-icons/ri";

// Import Page Components
import MasterAvailabilityTable from "../../../Components/Table/Master/RoomAvailability/Table";

// Import Notifications
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";
import {
  failedRoomCat,
  masterFailedNotification,
  masterSuccessNotification,
  successRoomCat,
} from "../../../Components/Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";

// Modal Confirm
const { confirm, warning } = Modal;

// CODE
export default function RoomAvailabilityPage() {
  // GET TODAY
  const today = new Date(Date.now());

  // Navigate
  const navigate = useNavigate();

  // CONTEXT
  const { getMasterRoomCat, getMasterAvail, getMasterRoomAvail } =
    useContext(MasterContext);

  // STATE MANAGEMENT
  // Total Count
  const [length, setLength] = useState(0);
  // Option Value
  const [optionValue, setOptionValue] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataRoomCat, setDataRoomCat] = useState([]);
  // Disable
  const [isDisabled, setIsDisabled] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH ROOM CAT
  const fetchRoomCat = async () => {
    console.log("This is supposed to be called!");

    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Master Room Code => ", response);

        // FILTER IS DELETED
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          // CREATE OPTION
          let option = filtered.map((dev) => ({
            value: dev?.room_category_name,
            label: dev?.room_category_name,
            code: dev?.room_category_code,
            number_of_rooms: dev?.number_of_rooms,
            room_available: dev?.number_of_rooms,
            room_unavailable: dev?.room_unavailable,
            id: dev?.id,
          }));

          setDataRoomCat(option);
        } else {
          setDataRoomCat([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchRoomCat();
    // fetchRoomAvailibility();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (dataTable.length > 0 || (dataRoomCat.length > 0 && !cleanUp)) {
      // fetchRoom();
      handleOptionValue();
    } else {
      setOptionValue([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataRoomCat, dataTable]);

  // Handle Option
  const handleOption = (value) => {
    console.log("Data Tabel: ", value);
    // console.log(optionVal);
    // console.log(optionValue);

    setDataTable(value);
  };

  // Handle Option Value
  const handleOptionValue = () => {
    // let _notExisted = dataTable.filter();
    console.log("Data Room Category: ", dataRoomCat);
    console.log("Data Room Available: ", dataTable);

    const results = dataRoomCat.filter(
      ({ id }) =>
        !dataTable.some(({ room_category_id }) => room_category_id == id)
    );

    console.log("Options Value: ", results);
    setOptionValue(results?.length > 0 ? results : []);
  };

  // Get Room Category Code
  const onChangeRoomCat = (value, e) => {
    // console.log(value);
    console.log(e);
    // setRoomCat(e);

    form.setFieldsValue({
      room_category: e,
    });
  };

  const getRoomCode = (value, code) => {
    console.log(value, code);

    // console.log(optionVal);

    form.setFieldsValue({
      room_category: value,
      room_category_id: code?.id,
      number_of_rooms: code?.number_of_rooms || 0,
      room_available: code?.room_available || 0,
      room_unavailable: 0,
    });
  };

  // On Clear
  const handleClear = () => {
    form.setFieldsValue({
      room_category: null,
    });
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    let content = val;

    console.log("Modal = ", content);

    confirm({
      className: "modal-room-confirm",
      title: `Are you sure want to Add Room Category of ${
        content?.room_category || "----"
      } to Room Availability ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(content);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentSubmit = e;
    console.log("ON SUBMIT ==> ", contentSubmit);

    const allBody = [];

    for (let i = 0; i < 90; i++) {
      allBody.push({
        date_availability: today
          ? moment(today).add(i, "days").format("YYYY-MM-DD")
          : "",
        room_category_id: contentSubmit?.room_category_id
          ? contentSubmit.room_category_id
          : 0,
        room_available: 0,
        room_unavailable: 0,
        number_of_rooms: 0,
      });
    }
    // console.log("allBody >>>", allBody);

    await axios
      .post(
        `${baseurl}/room/category/add-availability`,
        { data: allBody },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Add Room Avalability => ", response);
        masterSuccessNotification(response, {
          method: 0,
          source: "Room Availibility",
        });

        handleCancel();

        setTimeout(() => {
          setRefresh(true);
          setIsLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log("Add Room Avalability => ", error);

        masterFailedNotification(error, {
          method: 0,
          source: "Room Availibility",
        });
        setIsLoading(false);
      });
    // .finally(() => {
    // });
  };

  // HANDLE REFRESH
  const handleRefresh = async () => {
    setRefresh(true);
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);

    // fetchRoomCat();
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    // setId(null);
    setIsLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;
    setIsLoading(true);

    console.log("ON FINISH >>> ", content);
    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;

    // successRoomCat(error, { method: 3 });
    // failedRoomCat(error, { method: 3 });
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <RiHomeGearFill
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Room Availability`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                span={24}
                className="col-first"
                style={{ padding: 30, height: "100%" }}
              >
                <Row
                  className="row-search-component"
                  justify="end"
                  gutter={[0, 15]}
                  // style={cardBody}
                >
                  <Button
                    className="submit-btn"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleOpen}
                  >
                    {`Add Availability`}
                  </Button>

                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={<SyncOutlined />}
                    onClick={handleRefresh}
                    style={{ margin: "0px 0px 15px 15px" }}
                  >
                    {`Refresh`}
                  </Button>

                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleReturn}
                    style={{
                      margin: "0px 0px 0px 15px",
                    }}
                  >
                    {`Return`}
                  </Button>
                </Row>

                <MasterAvailabilityTable
                  is_refresh={refresh}
                  is_refreshed={setRefresh}
                  data_avail={handleOption}
                />
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>

      <Modal
        className="add-edit-pay-type-modal"
        title={
          <Row>
            <PlusCircleTwoTone style={{ fontSize: 24 }} />
            <Typography style={{ marginLeft: 15 }}>
              {`Add Room Availability`}
            </Typography>
          </Row>
          // edit == false ? (
          //   <Row>
          //     <PlusCircleTwoTone style={{ fontSize: 24 }} />
          //     <Typography style={{ marginLeft: 15 }}>
          //       Add Payment Type
          //     </Typography>
          //   </Row>
          // ) : (
          //   <Row>
          //     <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
          //     <Typography style={{ marginLeft: 15 }}>
          //       Edit Payment Type
          //     </Typography>
          //   </Row>
          // )
        }
        centered
        open={open}
        onCancel={handleCancel}
        closable
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="pay-type-modal-form"
          name="pay-type-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="room-cat-row">
            <Col span={16} className="roomcat-col">
              <Form.Item
                label="Room Category"
                name="room_category"
                rules={[
                  {
                    required: true,
                    message: "Please, Select Room Category!",
                  },
                  // {
                  //   validator: async (_, room_category_code) => {
                  //     if (room_category_code) {
                  //       if (roomAmount >= numberOfRoom) {
                  //         return Promise.reject(
                  //           "Please, Select Another Room Category!"
                  //         );
                  //       }
                  //     }
                  //   },
                  // },
                ]}
              >
                <Select
                  className="avail-select"
                  options={optionValue}
                  allowClear
                  showSearch
                  onClear={handleClear}
                  disabled={isDisabled}
                  onChange={getRoomCode}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item
                hidden
                label="Room Category Id"
                name="room_category_id"
              >
                <InputNumber placeholder="Category ID" min={0} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px" }}
          />

          <Row gutter={30} className="row-room-id">
            <Col span={8} className="col-room-id">
              <Form.Item
                label="Room Available"
                name="room_available"
                // hidden
                rules={[
                  {
                    required: true,
                    message: "Field cannot be Empty!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <InputNumber
                  placeholder="Room Available"
                  disabled
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* <Col span={8} className="col-room-name">
              <Form.Item
                label="Room Unavailable"
                name="room_unavailable"
                rules={[
                  {
                    required: true,
                    message: "Field cannot be Empty!",
                  },
                ]}
                style={{ marginRight: 30, width: 205 }}
              >
                <Input placeholder="Room Unavailable" disabled />
              </Form.Item>
            </Col> */}

            <Col span={8} className="col-room-no">
              <Form.Item
                label="Number of Rooms"
                name="number_of_rooms"
                rules={[
                  {
                    required: true,
                    message: "Field cannot be Empty!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Number of Rooms"
                  min={0}
                  disabled
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {/* {edit == true ? (
            <>
              <Row gutter={30} className="created-date-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )} */}

          <Row
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 15px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterArticleList(props) {
  // PROPS
  const {
    getArticle,
    articleName,
    getArticleSub,
    addArticleState,
    isDisabled,
    is_FOparams,
  } = props;

  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [bulks, setBulks] = useState([]);
  const [article, setArticle] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/article-list");
  };

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getArticleList({
      list: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filterData = _res.filter((val) => {
            if (
              (val.is_deleted === false || val.is_deleted === null) &&
              val.article_type === 1
            )
              return val;
          });

          let fillData = filterData.filter((val) => {
            // console.log(addArticleState);
            if (addArticleState === true)
              return val.article_sub_id === getArticleSub;
            else {
              return filterData;
            }
          });

          handleMap(filterData);
          // setBulks(filterData);
        } else {
          setOptionValue([]);
          setBulks([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // FETCH FILTERED
  const fetchFilter = () => {
    console.log("Filter Article List", getArticleSub);

    if (bulks.length > 0) {
      handleFilter({ data: bulks, id: getArticleSub });
    } else {
      fetchData();
    }

    // await getArticleList({
    //   list: "list",
    //   onAwait: () => {},
    //   onSuccess: (response) => {
    //     // console.log("Response Sub Filter => ", response);

    //     // Filtering
    //     if (response?.data?.msg?.length > 0) {
    //       const _res = response.data.msg;

    //       let _filteredSales = _res.filter((val) => {
    //         if (
    //           (val.is_deleted == false || val.is_deleted == null) &&
    //           val.article_type == 1
    //         ) {
    //           return val;
    //         }
    //       });

    //       let getArticle = _filteredSales.filter((val) => {
    //         if (val.article_sub_id == getArticleSub) {
    //           return val;
    //         }
    //       });

    //       console.log("Filter: ", getArticle);

    //       // Creating Option
    //       let optionFilter = (
    //         is_FOparams == false ? getArticle : _filteredSales
    //       ).map((val) => ({
    //         key: val.id,
    //         value: val.articleName,
    //         label: val.articleName,
    //         code: val.id,
    //         main: val.article_main_name,
    //         sub: val.article_sub_name,
    //         sub_id: val.article_sub_id,
    //         price: val.unit_price,
    //         coa: val.chart_of_account,
    //         service: val?.service > 0 ? val.service : 0,
    //         tax: val?.tax > 0 ? val.tax : 0,
    //         gross: val?.gross > 0 ? val.gross : 0,
    //       }));

    //       // console.log("Article Sub == ", optionFilter);
    //       setOptionValue(optionFilter);
    //     } else {
    //       setOptionValue([]);
    //     }
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //   },
    // });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (getArticleSub > 0 && !cleanUp) {
      // fetchFilter();
      console.log("Article Sub ID: ", getArticleSub);
      fetchFilter();
    }

    if (articleName && !cleanUp) {
      console.log("Art Name: ", articleName);

      setArticle(articleName);
    } else {
      setArticle(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleName, getArticleSub]);

  // Handle Mapping
  const handleMap = (value) => {
    const _filtered = value;

    // console.log("Data: ", _filtered[0]);

    let optionFilter = _filtered.map((val) => ({
      key: val?.id,
      value: val?.article_name,
      label: val?.article_name,
      code: val?.id,
      id: val?.id,
      dept: val?.department_name,
      dept_id: val?.department_id,
      main: val?.article_main_name,
      main_id: val?.article_main_id,
      sub: val?.article_sub_name,
      sub_id: val?.article_sub_id,
      coa: val?.chart_of_account,
      price: val?.unit_price,
      service: val?.service > 0 ? val.service : 0,
      tax: val?.tax > 0 ? val.tax : 0,
      gross: val?.gross > 0 ? val.gross : 0,
    }));

    setBulks(optionFilter);

    if (is_FOparams === true) {
      setOptionValue(optionFilter);
    } else {
      setOptionValue([]);
    }

    if (getArticleSub > 0) {
      handleFilter({
        data: optionFilter,
        id: getArticleSub,
      });
    }

    if (articleName) {
      setArticle(articleName);
    } else {
      setArticle(null);
    }
  };

  // Handle Filter
  const handleFilter = (value) => {
    const _arr = value?.data;
    const _id = value?.id;

    // console.log("Data: ", _arr);

    let _filter = _arr.filter((value) => {
      if (value?.sub_id == _id) {
        return value;
      }
    });

    // console.log("Data Filter: ", _filter);

    setOptionValue(_filter);
  };

  // Handle Change
  const handleChange = (e, allKeys) => {
    const _value = e;
    const _keys = allKeys;

    console.log("INI CODE ", _value, _keys);

    setArticle(_value);
    getArticle({
      article_id: _keys?.id,
      article_name: _value,
      code: _keys?.code,
      coa: _keys?.coa,
      dept_id: _keys?.dept_id,
      sub_id: _keys?.sub_id,
      main_id: _keys?.main_id,
      price: _keys?.price,
      service: _keys?.service,
      tax: _keys?.tax,
      gross: _keys?.gross,
    });
    //  if (onSet) onSet(deviceid);
  };

  // Handle Clear
  const handleClear = () => {
    setArticle(null);
  };

  // console.log(article);

  return (
    <>
      <Row className="master-row" align="top" justify="start" gutter={0}>
        <Col span={20} className="select-col">
          <Select
            key={optionValue.code}
            style={{ width: "100%" }}
            value={article}
            showSearch
            allowClear
            disabled={isDisabled}
            placeholder="Article Sales"
            onChange={handleChange}
            onClear={handleClear}
            options={optionValue}
          />
        </Col>

        <Col span={4} className="select-col">
          <Tooltip
            className="redirect-tooltip"
            title={"Redirect to Master Article"}
          >
            <Button
              className="master-btn"
              icon={
                <AppstoreFilled
                  onClick={move}
                  style={{ fontSize: 20, color: "#3699FF" }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}

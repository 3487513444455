// Import React Components
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// AUTH
import { roles, token, user_name } from "../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
} from "antd";

// Import React Icons
import { BiSolidFilePdf } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

// Import Notification
import {
  failedCheckOut,
  failedFetch,
  successCheckOut,
} from "../../Reusable/Notification/Notification";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import MasterIdCardType from "../../Reusable/Master/IdCardType/IdCardType";
import MasterTitle from "../../Reusable/Master/Title/Title";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// Import Functions
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ModalCheckOutForm(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    // Data
    dataLead,
    dataGuest,
    amount,
    // Refresh
    is_refresh,
    is_early,
    // Check Out
    is_checkOut,
  } = props;

  // CONTEXT
  const { getMasterPrint } = useContext(MasterContext);

  // Params
  const param_fo = FrontOfficeParams();

  // Use Ref
  const componentRef = useRef();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [amountTotal, setAmountTotal] = useState(0);
  // NAME
  const [name, setName] = useState("");
  const [room, setRoom] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH PRINT FORM
  const fetchPrint = async (value) => {
    const _data = value;

    await getMasterPrint({
      reservation_id: _data?.reservation_id || dataGuest[0]?.reservation_id,
      price_id: _data?.price_id > 0 ? _data.price_id : dataGuest[0]?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: _data || dataLead[0],
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count teuing timana: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      console.log(openModal, param_fo);
      setOpen(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    let cleanUp = false;

    if (is_checkOut === false && !cleanUp) {
      if (dataGuest?.length > 0 && dataLead?.length > 0) {
        console.log("Data Guest History --> ", dataGuest);
        console.log("Data Leaders History --> ", dataLead);

        setData(dataGuest);
        setFormFields(dataGuest);
        setName(dataLead[0]?.guest_name);

        // Fetch Print
        fetchPrint(dataLead[0]);
      }
    } else {
      if (dataGuest || dataGuest?.length > 0) {
        console.log("Data Guest CO --> ", dataGuest);
        console.log("Data Leaders CO --> ", dataLead);

        setData(dataGuest);
        setFormFields(dataGuest);

        if (dataLead?.length > 0) {
          if (dataLead[0]?.guest_name) {
            setName(dataLead[0].guest_name);
          } else {
            setName(dataGuest?.guest_name);
          }
        } else {
          if (dataLead?.guest_name) {
            setName(dataLead.guest_name);
          } else {
            setName(dataGuest?.guest_name);
          }
        }

        fetchPrint(dataLead);
      }
    }

    if (amount > 0) {
      // console.log(amount);
      setAmountTotal(amount);
    }

    return () => {
      cleanUp = true;
    };
  }, [amount, dataGuest, dataLead]);

  // SET FORMS
  const setFormFields = (value) => {
    const _data = value;
    var _room = [];

    if (_data?.length > 0) {
      // _name = _data[0].guest_name;

      _data.forEach(({ room_number }) => {
        return _room.push(room_number);
      });
    } else {
      _room.push(_data?.room_number);
      // _name = _data?.guest_name;
    }

    // console.log("Room: ", _room);
    setRoom(_room);

    form.setFieldsValue({
      room_number: _room.join(", "),
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 1250);
  };

  // SEQUENTIAL BUTTON SUBMIT
  const [counter, setCounter] = useState(0);
  const myclick = () => {
    setCounter((counter) => counter + 1);
    console.log(counter);
    if (counter == 0);
    else if (counter == 1) {
      //   move();
      setCounter(0);
    }
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);

    form.resetFields();
    setCounter(0);
    setIsLoading(true);
    // setEdit(null);
  };

  // HANDLE CHECK OUT
  const handleDepart = () => {
    setOpen(false);
    closeModal(false);
    form.resetFields();

    setCounter(0);
    is_checkOut(true);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    PrintCount({
      data: dataLead?.length > 0 ? dataLead[0] : dataLead,
      is_update: true,
      counter_list: {
        thankyou_letter: countPrint?.thankyou_letter + 1,
        master_bill: countPrint?.master_bill,
        bill: countPrint?.bill,
        pre_master_bill: countPrint?.pre_master_bill,
        pre_bill: countPrint?.pre_bill,
        checkout_letter_history: countPrint?.checkout_letter_history,
        registration_form: countPrint?.registration_form,
        guarantee_letter: countPrint?.guarantee_letter,
        reservation_letter: countPrint?.reservation_letter,
      },
    });

    handlePrintOut();
  };

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Check-Out Form ${dataGuest?.reservation_id}`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  // CONSOLE LOG
  // console.log("DATA MODAL Early Check Out >> ", dataGuest);
  // console.log("DATA AMOUNT >> ", amountTotal);

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row>
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Thank You Letter`}
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 1000,
          }}
          //   onFinish={(e) => handleCheckOut(e)}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          autoComplete="off"
          name="checkout-form-modal"
          className="checkout-form-modal"
          key="checkout-form-modal"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              className="content-check-out-form"
              ref={componentRef}
              style={{
                //   border: "solid 1px",
                padding: "30px 80px 30px 80px",
              }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Thank You Letter`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "grey" }}
              />

              <Row
                style={{ width: "100%", padding: "10px 0px" }}
                className="row-date-letter"
              >
                <Typography.Title level={5} className="text-date-letter">
                  {moment().format("DD-MMM-YYYY, HH:mm")}
                </Typography.Title>
              </Row>

              <Row style={{ width: "100%" }} className="row-greets">
                <Typography className="text-dear">{`Dear, `}</Typography>
              </Row>

              <Row
                style={{ width: "100%", padding: "5px 0px" }}
                className="row-guest-name"
              >
                <Typography
                  className="text-guest-name"
                  style={{ fontSize: 20, fontWeight: 750 }}
                >
                  {name}
                </Typography>
              </Row>

              <Row
                className="row-room-number"
                style={{ width: "100%", padding: "5px 0px" }}
              >
                <Typography
                  className="text-room-no"
                  style={{ fontSize: 20, fontWeight: 750 }}
                >
                  {`ROOM ${room.length > 0 ? room.join(",") : room[0]}`}
                </Typography>
              </Row>

              <Row
                className="main-content-row"
                style={{
                  margin: "30px 0px 0px 0px",
                  backgroundImage:
                    countPrint?.thankyou_letter >= 1
                      ? "url(/assets/logos/copyopac.png)"
                      : null,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  backgroundPosition: "center",
                }}
              >
                <Col span={24} className="content-col">
                  <Row className="row-content-one">
                    <Typography className="text-content-one">
                      On the behalf of Management and staff we would like to
                      thank you for staying at this hotel during your
                      work/holiday. We do hope you enjoy your stay at this
                      hotel.
                    </Typography>
                  </Row>

                  <Row
                    className="row-content-two"
                    style={{ margin: "20px 0px 20px 0px" }}
                  >
                    <Typography className="text-content-two">
                      Our records indicate that you will be leaving today and we
                      would like to inform you that our check out time is at
                      12.00 noon, should you change your check out time, we
                      would be very pleased if you could inform us at your
                      earliest convenience.
                    </Typography>
                  </Row>

                  <Row
                    className="row-content-three"
                    style={{ margin: "20px 0px 20px 0px" }}
                  >
                    <Typography className="text-content-three">
                      Please contact our Reception in extention. for further
                      assistance you might required.
                    </Typography>
                  </Row>

                  <Row
                    className="row-content-closure"
                    style={{ margin: "20px 0px 20px 0px", width: "100%" }}
                  >
                    <Typography className="text-content-closure">
                      We are looking forward to welcoming you on your next visit
                    </Typography>
                  </Row>
                </Col>
              </Row>

              <Row
                className="row-sign"
                style={{
                  fontSize: 15,
                  fontWeight: 750,
                  margin: "50px 0px 75px",
                }}
              >
                <Typography className="text-sign">Sincerely Yours,</Typography>
              </Row>

              <Row className="row-dot">
                <Typography>{`. . . . . . . . . . . . . . . . . . . .`}</Typography>
              </Row>

              <Row className="row-sign-by" align={"bottom"} style={{}}>
                <Typography className="text-sign-by">
                  {`( ${param_fo?.check_out_sign_by} )`}
                </Typography>
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              icon={<PrinterOutlined />}
              type="primary"
              onClick={() => {
                handlePrint();
                if (counter == 0) {
                  myclick();
                }
              }}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>
            {/* 
            <Button
              className="check-out-btn"
              type="primary"
              hidden={is_checkOut == true ? true : false}
              onClick={() => {
                is_early == true ? showCheckOutModal() : handleDepart();
              }}
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
              disabled={counter != 0 ? false : true}
            >
              Check Out
            </Button> */}

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

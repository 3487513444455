// REACT COMPONENTS
import React from "react";

// ANTD COMPONENTS
import { Col, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// REACT ICONS
import { MdOutlinePlaylistAddCircle } from "react-icons/md";

// PAGE COMPONENTS
import APPaymentTable from "../../../../Components/Table/BackOffice/AP/APPayment/Table";

export default function APPaymentPage() {
  return (
    <Layout>
      <Content>
        <Col span={24} className="ooo-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <MdOutlinePlaylistAddCircle
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`A/P Payment`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              {/* <Col
                style={{
                  textAlign: "start",
                  margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  maxWidth: 250,
                  minWidth: 150,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Payment Details`}</Typography>

                <MasterPaymentCardEdit getPayCard={handlePayment} />
                <MasterPaymentCard getPayCard={handlePayment} />
              </Col>

              <Col
                style={{
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={handleSearch}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col> */}
            </Space>
          </Row>

          <APPaymentTable
          // selected={data}
          // is_search={search}
          // is_research={setSearch}
          />
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Icons
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../../API/Config/Api";
import { token } from "../../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Antd Components
import { Button, Col, Row, Table, Tag } from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CODE
export default function EarlyCOTable(props) {
  // PROPS
  const { searchKey, is_search } = props;

  // CONTEXT
  const { getReservation } = useContext(MainContext);

  // Routers
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // STATE MANAGEMENT
  // DATA
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Boolean
  const [loading, setLoading] = useState(false);

  // FETCH DATA
  const fetchData = async () => {
    await axios
      .post(
        `${baseurl}/reservation/stay-info`,
        {
          reservation_info_id: 4,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log(response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(response.data.msg);
        } else {
          setData([]);
          setBulks([]);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // COLUMNs
  const columns = [
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">PRIVATE</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 75,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      // width: 120,
      render: (arrival, record) => {
        return moment(arrival).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Reservation Status",
      dataIndex: "reservation_status",
      key: "reservation_status",
      // width: 150,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      // width: 150,
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            color={clr}
            style={{
              fontSize: 13,
              fontWeight: 500,
              borderColor: "transparent",
            }}
          >
            {gst}
          </Tag>
        );
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      // width: 120,
      sorter: (a, b) => moment(a.departure) - moment(b.departure),
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room Number",
      dataIndex: "room_number",
      key: "roomno",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      width: 200,
      render: (group, record) => {
        let grp = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            break;

          case 1:
            grp = "Group Leader Guest";
            break;

          case 2:
            grp = "Group Member Guest";
            break;

          default:
            break;
        }

        return grp;
      },
    },
    {
      title: "Done By",
      dataIndex: "reservation_info_created_by",
      key: "reservation_info_created_by",
      // width: 150,
    },
    {
      title: "Done Date",
      dataIndex: "reservation_info_created_date",
      key: "reservation_info_created_date",
      render: (date) => {
        return moment().format("HH:mm:ss, DD MMM YYYY");
      },
    },
  ];

  // Handle Return
  const handleReturn = () => {
    const _path = "/report/front-office";

    navigate(`${_path}`);
  };

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(data, `Early Check-Out`);
  };

  // Handle Search
  const handleSearch = () => {
    const _key = searchKey ? searchKey.toLowerCase() : null;

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item)
        return (
          item.guest_category != "INCOGNITO" &&
          item.guest_name.toLowerCase().indexOf(_key.toLowerCase()) !== -1
        );
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setBulks([]);
    }
  };

  return (
    <>
      <Row style={mainBody}>
        <Col
          className="col-table"
          span={24}
          style={{
            padding: 30,
          }}
        >
          <Row className="row-search-btn" justify="start" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="search-col"
            ></Col>
          </Row>

          <Row className="row-modal-btn" justify="start" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            ></Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<FileExcelFilled />}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Export to Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ margin: "30px 0px", width: "100%" }}>
            <Table
              className="report-table"
              key="report-table"
              name="report-table"
              loading={loading}
              bordered
              size="middle"
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 == 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

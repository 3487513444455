// Import React Components
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Avatar,
  Button,
  Col,
  Layout,
  List,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { AiFillFolderOpen, AiTwotoneFolderOpen } from "react-icons/ai";
import { BsCalculatorFill, BsDoorOpenFill } from "react-icons/bs";
import { VscLibrary } from "react-icons/vsc";
import { FaConciergeBell } from "react-icons/fa";
import { MdChair } from "react-icons/md";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { HiMiniChartBar } from "react-icons/hi2";

// Import Global Styles
import {
  cardHeader,
  globalIcon,
  mainBody,
  pageIcons,
} from "../../../../Style/Global/Global";

// STYLES

// DATA LIST
const data = [
  // {
  //   key: 1,
  //   title: "Accounting Report Parameters",
  //   icons: <BsCalculatorFill className="list-icons" style={pageIcons} />,
  //   path: "/accounting-params",
  // },
  // {
  //   key: 2,
  //   title: "Back-Office Report Parameters",
  //   icons: <VscLibrary className="list-icons" style={pageIcons} />,
  //   path: "/back-office-params",
  // },
  // {
  //   key: 3,
  //   title: "F & B Report Parameters",
  //   icons: <FaConciergeBell className="list-icons" style={pageIcons} />,
  //   path: "/f-and-b-params",
  // },
  // {
  //   key: 4,
  //   title: "Front-Office Report Parameters",
  //   icons: <MdChair className="list-icons" style={pageIcons} />,
  //   path: "/front-office-params",
  // },
  // {
  //   key: 5,
  //   title: "House Keeping Report Parameters",
  //   icons: <BsDoorOpenFill className="list-icons" style={pageIcons} />,
  //   path: "/house-keeping-params",
  // },
  {
    key: 6,
    title: "Daily Report Parameters",
    icons: <HiMiniChartBar className="list-icons" style={pageIcons} />,
    path: "/report-params",
  },
  {
    key: 7,
    title: "Monthly Report Parameters",
    icons: <HiMiniChartBar className="list-icons" style={pageIcons} />,
    path: "/monthly-report-params",
  },
];

// CODE
export default function MainReportPage() {
  // USE LOCATION & NAVIGATE
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // ON MOVE
  const move = (value) => {
    const _path = value;

    console.log("Move to: ", _path);
    console.log("Pathname: ", pathname);

    navigate(`${pathname}` + `${_path}`);
  };

  // Return Page
  const moveReturn = () => {
    const _returnPath = "/configuration/parameters";
    console.log("Pathname: ", pathname);

    navigate(`${_returnPath}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="article-sales-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <AiFillFolderOpen
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`General Report Parameters`}
              </Typography>
            </Row>

            <Row className="row-divider" style={{ margin: "15px 0px" }} />

            <Row className="row-title" justify="start" style={mainBody}>
              <Col
                className="col-second"
                span={24}
                style={{
                  padding: 30,
                  display: "flex",
                  gap: 5,
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}
              >
                <List
                  className="page-list"
                  key="page-list"
                  bordered
                  header={
                    <>
                      <Row
                        className="header-title-row"
                        justify="space-evenly"
                        align="middle"
                        gutter={30}
                      >
                        <Col span={12} className="header-text-col">
                          <Typography.Title
                            level={4}
                            className="header-list-text"
                            key="header-list-txt"
                          >
                            {`General Report Page List`}
                          </Typography.Title>
                        </Col>

                        <Col
                          span={12}
                          className="btn-text-col"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="default"
                            className="cancel-btn"
                            icon={
                              <IoReturnUpBackOutline
                                className="return-icons"
                                style={{
                                  margin: "0px 5px 0px 0px",
                                }}
                              />
                            }
                            onClick={moveReturn}
                          >{`Return`}</Button>
                        </Col>
                      </Row>
                    </>
                  }
                  itemLayout="horizontal"
                  dataSource={data}
                  rowKey={(item) => {
                    return item.key;
                  }}
                  renderItem={(item) => (
                    <List.Item
                      className="page-list-item"
                      actions={[
                        <Tooltip
                          className="list-tooltip"
                          title={item.title}
                          color="#1BC5BD"
                        >
                          <Button
                            className="next-btn"
                            type="primary"
                            onClick={() => move(item.path)}
                            key="list-btn"
                          >
                            {`Go to Page`}
                          </Button>
                        </Tooltip>,
                      ]}
                    >
                      <List.Item.Meta
                        className="page-list-meta"
                        key={item.key}
                        avatar={item.icons}
                        title={
                          <Typography className="link-text">
                            {item.title}
                          </Typography>
                        }
                        description={"Desc"}
                      />
                    </List.Item>
                  )}
                  style={{
                    borderRadius: 5,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterUOM(props) {
  // PROPS
  const { getUnit, unit_name } = props;

  // CONTEXT
  const { getFetchMaster } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Segment
  const [units, setUnits] = useState([]);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getFetchMaster({
      master: "unit-of-measurement",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("UNIT => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response?.data?.msg?.filter((filt) => {
            return !filt.is_deleted;
          });

          // Mapping to Option Value
          let option = filtered.map((dev) => ({
            value: dev?.name + ` (${dev?.code})`,
            label: dev?.name + ` (${dev?.code})`,
            id: dev?.id,
            key: dev?.id,
            code: dev?.code,
            name: dev?.name,
            qty: dev?.converted_qty,
            description: dev?.description || null,
          }));

          //   console.log("UNITS --> ", option);
          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (unit_name && !cleanUp) {
      setUnits(unit_name);
    } else {
      setUnits(null);
    }

    return () => {
      cleanUp = false;
    };
  }, [unit_name]);

  // Handle Change
  const handleChange = (value, allKeys) => {
    setUnits(value);
    console.log("UOM => ", value, allKeys);

    getUnit({
      uom_code: allKeys?.code,
      uom_id: allKeys?.id,
      uom_name: allKeys?.value,
      uom_desc: allKeys?.description,
      uom_qty: allKeys?.qty,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setUnits(null);
  };

  return (
    <>
      <Select
        key="unit-select"
        className="unit-select"
        value={units}
        allowClear
        showSearch
        placeholder="Unit of Measurements (UOM)"
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
      />
    </>
  );
}

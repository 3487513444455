// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Context
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
} from "antd";

// Import React Icons
import { DeleteFilled, EditOutlined, FormOutlined } from "@ant-design/icons";

// Import React Icons
import {
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaMoneyBillWave } from "react-icons/fa";

// Import Page Components
import PaymentTableOrder from "./Payment";

// ROW SELECTIONS
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const dataSource = [
  {
    key: "1",
    table: "1",
    items: "Bebek",
    quantity: 2,
    serving_que: "First",
    unit_price: "20.000",
    sub_total: "40.000",
  },
  {
    key: "2",
    table: "2",
    items: "Ayam",
    quantity: 3,
    serving_que: "Second",
    unit_price: "20.000",
    sub_total: "60.000",
  },
  {
    key: "3",
    table: "1",
    items: "Bebek",
    quantity: 2,
    serving_que: "First",
    unit_price: "20.000",
    sub_total: "40.000",
  },
  {
    key: "4",
    table: "2",
    items: "Ayam",
    quantity: 3,
    serving_que: "Second",
    unit_price: "20.000",
    sub_total: "60.000",
  },
];

const { confirm } = Modal;

// ON SELECT
const onChangeSelect = (value) => {
  console.log(`selected ${value}`);
};

// ON SEARCH
const onSearch = (value) => {
  console.log("search:", value);
};

export const Contents = () => {
  // USE FORM
  const [form] = Form.useForm();

  // On Finish
  const onFinish = (value) => {
    console.log(value);
  };

  return (
    <>
      <Form
        name="add-art-supplier-modal-form"
        className="add-art-supplier-modal-form"
        autoComplete="off"
        form={form}
        onFinish={(e) => onFinish(e)}
      >
        <Row justify="start" style={{ marginBottom: 30 }}>
          <Col span={24}>
            <Form.Item name="article_name">
              <Select
                showSearch
                placeholder="Select an Option"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "laundry",
                    label: "Laundry",
                  },
                  {
                    value: "no laundry",
                    label: "No Laundry",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="start" style={{ marginBottom: 15 }}>
          <Col span={24}>
            <Button
              className="submit-btn"
              //   onClick={handleSubmit}
              htmlType="submit"
              type="primary"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

// SHOW MODAL DELETE
export const showDeleteModal = (record) => {
  return confirm({
    className: "delete-guest-confirm",
    title: `Are you sure want to delete guest named .... from the table?`,
    // icon: <ExclamationCircleTwoTone />,
    okText: "Delete",
    okType: "danger",
    cancelText: "Cancel",
    centered: true,

    onOk() {
      // handleDelete(deleteContent);
      // console.log("Article deleted");
    },

    onCancel() {
      // console.log("Confirmation Cancelled");
    },

    okButtonProps: {
      className: "submit-btn",
      type: "primary",
    },

    cancelButtonProps: {
      className: "cancel-btn",
      type: "default",
    },

    width: 750,
    bodyStyle: {
      padding: 30,
      borderRadius: 10,
    },
  });
};

// CODE
export default function TablePerOrder(handleFilter) {
  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "",
      width: 100,
      render: (_, record) => {
        return (
          <Row className="action-guest-table-btn" style={{ width: "100%" }}>
            <Col span={12}>
              <FormOutlined
                className="edit-guest-btn"
                onClick={() => {
                  console.log("ABOUT TO EDIT > ", record);
                  setOpen(open);
                  setDataEdit(record);
                }}
                style={{ fontSize: 20, color: "#3699FF" }}
              />
            </Col>

            <Col span={12}>
              <DeleteFilled
                className="delete-guest-btn"
                onClick={() => {
                  console.log("ABOUT TO DELETE > ", record);
                  showDeleteModal(record);
                }}
                style={{ fontSize: 20, color: "#F64E60" }}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.items.localeCompare(b.items),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.quantity.localeCompare(b.quantity),
    },
    {
      title: "Serving Que",
      dataIndex: "serving_que",
      key: "serving_que",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.serving_que.localeCompare(b.serving_que),
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.unit_price.localeCompare(b.unit_price),
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      key: "sub_total",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.sub_total.localeCompare(b.sub_total),
    },
  ];

  // CONTEXT
  const { state } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [selectionType, setSelectionType] = useState("checkbox");
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [dataView, setDataView] = useState();

  const [dataEdit, setDataEdit] = useState(null);
  const [value, setValue] = useState();
  const [edit, setEdit] = useState();
  // Modal Open
  const [open, setOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = () => {};

  // USE EFFECT
  useEffect(() => {
    fetchData();
    setFilterData(handleFilter);
  }, [handleFilter]);

  const selected = parseInt(filterData.handleFilter);
  if (selected === null) {
    return this.setState({
      dataSource,
    });
  }
  const mejaMap = {
    1: "1",
    2: "2",
  };

  const selectedMeja = mejaMap[selected];

  const filteredEvents = dataSource.filter(
    ({ table }) => table === selectedMeja
  );

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  //console.log("vvvvvvvvvvvv", filteredEvents[0]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="start">
            <h3>Table {`${filterData.handleFilter}`}</h3>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 30, width: "100%" }}>
        <Table
          loading={loading}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={filteredEvents}
          //onChange={onChange}
          pagination={{
            pageSize: 5,
          }}
          // scroll={{
          //     y: 270,
          // }}
          rowKey={(record) => (record.id ? record.id : record.key)}
        />
      </Row>

      <PaymentTableOrder
        handleFilter={handleFilter}
        openModal={open}
        closeModal={setOpen}
        // dataTableOrder={dataEdit}
      />
    </>
  );
}

// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  InputNumber,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { MdRoomPreferences } from "react-icons/md";
import { TbBedOff } from "react-icons/tb";
import { FiRefreshCw } from "react-icons/fi";
import {
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  UndoOutlined,
} from "@ant-design/icons";

// Import Page Components
import OffMarketTable from "../../../Components/Table/HouseKeep/OffMarket/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function OffMarketPages() {
  // STATE MANAGEMENT
  const [room, setRoom] = useState(null);
  // const [name, setName] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearchRoom = (e) => {
    e.preventDefault();

    // console.log("HANDLE SEARCH = ", e.target.value);
    setRoom(e.target.value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="offmarket-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <MdRoomPreferences
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`OFF-MARKET`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  className="col-title"
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Room Number`}</Typography>

                  <Input
                    className="input-search"
                    allowClear
                    placeholder="Room Number"
                    onChange={handleSearchRoom}
                    style={{ minWidth: 150 }}
                  />
                </Col>

                <Col
                  className="col-search-btn"
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                    style={{ width: 91 }}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <OffMarketTable is_search={setSearch} searchKey={room} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { HiTemplate } from "react-icons/hi";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import BSTemplateTable from "../../../Components/Table/Master/BSTemplate/Table";

// Import Global Styles
import { cardHeader, mainBody } from "../../../Style/Global/Global";

// CODE
export default function BSTemplatePage() {
  return (
    <Layout>
      <Content>
        <Col span={24} className="bill-template-main-layout">
          <Row className="bill-template-title" justify="start" style={mainBody}>
            <HiTemplate
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />

            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Balance Sheet Template`}
            </Typography>
          </Row>

          <BSTemplateTable />
        </Col>
      </Content>
    </Layout>
  );
}

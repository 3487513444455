// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../../API/Context/FinanceContext/FinanceContext";
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// AUTH
import AuditDate from "../../../../../Reusable/DateAudit/Date";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Collapse,
  Table,
  Typography,
  Card,
  Divider,
  ConfigProvider,
} from "antd";

// Import Icons
import { MdPrint } from "react-icons/md";
import { PrinterOutlined } from "@ant-design/icons";

// Import Page Components
import SpinnerComponents from "../../../../../Reusable/Spinner/Spinner";
import EmptyTableComponents from "../../../../../Reusable/Empty/EmptyTable";

// Import Notifications
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody, printPreview } from "../../../../../../Style/Global/Global";

// Const
const { Panel } = Collapse;
const { Text, Link, Title } = Typography;

// CODE
export default function SegmentStatisticTable(props) {
  // PROPS
  const { is_printed, dataDate } = props;

  // CONTEXT
  const { getDSR, getDailyHistory } = useContext(FinanceContext);
  const { getParameters } = useContext(MasterContext);
  const tablesContainerRef = useRef(null);

  // Date Now
  const date = new Date(Date.now());
  const _date = AuditDate().audit;
  const today = moment(date).format("DD MMMM YYYY");

  // STATE MANAGEMENT
  // Data
  const [bulks, setBulks] = useState([]);
  const [dataMaster, setDataMaster] = useState([]);
  const [dateNow, setDateNow] = useState(_date);
  // Tables
  const [tablesData, setTablesData] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [totalData, setTotalData] = useState({
    total_actual_today: 0,
    total_budget_today: 0,
    total_variance_today: 0,
    total_actual_mtd: 0,
    total_variance_mtd_: 0,
    total_budget_mtd: 0,
    total_actual_ytd: 0,
    total_budget_ytd: 0,
    total_variance_ytd: 0,
  });
  // Data
  // Boolean State
  const [isPrinted, setIsPrinted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);

  // Columns Table
  const column = [
    {
      title: () => {
        return handleTitleHeader("Descripton");
      },
      dataIndex: "description",
      key: "description",
      width: 150,
      //   ellipsis: true,
      render: (description, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={
              is_printed === true
                ? printPreview
                : {
                    fontSize: 10,
                    fontWeight: 500,
                    // textTransform: "capitalize",
                  }
            }
          >
            {`${description}`
              .replace(/-+/g, " ")
              .replace(/(^\w|\s\w)/g, (val) => val)}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Today");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("Revenue");
          },
          dataIndex: "actual_today",
          key: "actual_today",
          width: 100,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Rooms");
          },
          dataIndex: "room_today",
          key: "room_today",
          width: 75,
          render: (act, record) => {
            const values = parseInt(act);

            if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("ARR");
          },
          dataIndex: "arr_today",
          key: "arr_today",
          width: 100,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_today",
          key: "budget_today",
          width: 100,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
      ],
    },
    {
      title: () => {
        return handleTitleHeader("Month to Date (MTD)");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("Revenue");
          },
          dataIndex: "actual_mtd",
          key: "actual_mtd",
          width: 100,
          render: (mtd, record) => {
            const values = parseInt(mtd).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Rooms");
          },
          dataIndex: "room_mtd",
          key: "room_mtd",
          width: 75,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("ARR");
          },
          dataIndex: "arr_mtd",
          key: "arr_mtd",
          width: 100,
          render: (mtd, record) => {
            const values = parseInt(mtd).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_mtd",
          key: "budget_mtd",
          render: (mtd, record) => {
            const values = parseInt(mtd).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
      ],
    },
    {
      title: () => {
        return handleTitleHeader("Year to Date (YTD)");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("Revenue");
          },
          dataIndex: "actual_ytd",
          key: "actual_ytd",
          width: 100,
          render: (ytd, record) => {
            const values = parseInt(ytd).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Rooms");
          },
          dataIndex: "room_ytd",
          key: "room_ytd",
          width: 75,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("ARR");
          },
          dataIndex: "arr_ytd",
          key: "arr_ytd",
          width: 100,
          render: (ytd, record) => {
            const values = parseInt(ytd).toFixed(0);

            if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_ytd",
          key: "budget_ytd",
          // width: 100,
          render: (ytd, record) => {
            const values = parseInt(ytd).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
      ],
    },
  ];

  // Fetch Data
  const fetchData = async () => {
    await getDSR({
      type: "dsr-segment-detail",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response: ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          // setStatistics(_res);
          // handleTotal(_res);
          handleData(_res);
        } else {
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Failed Revenue DRR: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });

    await getParameters({
      params: "master-parameter",
      type: "segment-header",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setDataMaster(filtered);
        } else {
          setDataMaster([]);
        }
      },
      onReject: (error) => {
        console.log("Error get Master: ", error);
      },
    });
  };

  // FETCH HISTORY
  const fetchHistory = async (vals) => {
    await getDailyHistory({
      type: "dsr-segment-detail",
      date: moment(vals).format("YYYY-MM-DD"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response History: ", response);

        if (response?.data?.length > 0) {
          let _res = response.data;

          // setRevenue(_res);
          handleData(_res);
        } else {
          console.log("ITS SUPPOSED TOBE NULL!");
          setBulks([]);
          setTablesData([]);
        }
      },
      onReject: (error) => {
        console.log("Failed Revenue DRR: ", error);
        // setLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (!cleanUp) fetchData();
    // destructureResult();

    return () => {
      cleanUp = true;
    };
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (is_printed === true && !cleanUp) {
      setIsPrinted(true);
    } else {
      setIsPrinted(false);
    }

    if (dataDate && !cleanUp) {
      // console.log("Tanggal: ", dataDate);
      setDateNow(dataDate);

      fetchHistory(dataDate);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_printed, dataDate]);

  // Create Tables
  useEffect(() => {
    let cleanUp = false;

    if (dataMaster.length > 0 && bulks.length > 0 && !cleanUp) {
      handleSorter();
    }

    return () => {
      cleanUp = true;
    };
  }, [dataMaster, bulks]);

  // Table Break
  useEffect(() => {
    if (tablesContainerRef.current) {
      const tables =
        tablesContainerRef.current.querySelectorAll(".table-wrapper");

      tables.forEach((table, index) => {
        const observer = new ResizeObserver(() => {
          if (index > 0) {
            const previousTableBottom =
              tables[index - 1].offsetTop + tables[index - 1].offsetHeight;
            if (table.offsetTop < previousTableBottom) {
              table.classList.add("table-break");
            } else {
              table.classList.remove("table-break");
            }
          }
        });
        observer.observe(table);
      });
    }
  }, [tablesData]);

  // Handle Sorting based on Parameters
  const handleSorter = () => {
    const result = [];

    dataMaster.forEach(function (key) {
      var found = false;

      // console.log("KEY: ", key)

      let items = bulks.filter(function (item) {
        // console.log("Items: ", item)

        if (!found && item[0].group_id === key.id) {
          // console.log("Item is true: ", item[0].group_id)
          // console.log("Item is matched with: ", key)
          result.push(item);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });

    console.log("Results of sorting: ", result);
    handleCreateTable(result);
  };

  // HANDLE DATA
  const handleData = (value) => {
    const _valRes = value;

    let result = _valRes.reduce(function (r, a) {
      r[a.group_id] = r[a.group_id] || [];
      r[a.group_id].push(a);

      return r;
    }, []);

    console.log("Data Mapped: ", result);
    setBulks(result);
    // handleCreateTable(result);
  };

  // Handle Table
  const handleCreateTable = (data) => {
    const _bulks = data;
    let _tables = [];

    for (let i = 0; i <= _bulks.length; i++) {
      if (_bulks[i] !== null && _bulks[i] !== undefined) {
        // console.log(`Loop-${i}: `, _bulks[i]);

        _tables.push(
          <div
            // ref={(el) => (tablesContainerRef.current = el)}
            key={`table-${i}`}
            className="table-wrapper"
            style={{ width: "100%" }}
          >
            <Table
              className={"dsr-table"}
              name={"dsr-table-" + i}
              key={"dsr-table-" + i}
              title={() => (
                <Typography
                  className="table-title"
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {_bulks[i][0]?.label_header || `Data-${i}`}
                </Typography>
              )}
              dataSource={_bulks[i]}
              columns={column}
              pagination={false}
              size="small"
              bordered={true}
              loading={loading}
              rowKey={(record) => {
                return record?.id > 0 ? record.id : record?.description;
              }}
              // scroll={{
              //   x: 1000,
              // }}
              summary={(pageData) => {
                let totalToday = 0;
                let totalBudgetToday = 0;
                let totalRoomToday = 0;
                let avg_today = 0;

                let totalMtd = 0;
                let totalBudgetMtd = 0;
                let totalRoomMtd = 0;
                let avg_mtd = 0;

                let totalYtd = 0;
                let totalBudgetYtd = 0;
                let totalRoomYtd = 0;
                let avg_ytd = 0;

                pageData.forEach(
                  ({
                    budget_today,
                    budget_mtd,
                    budget_ytd,
                    actual_today,
                    actual_mtd,
                    actual_ytd,
                    arr_today,
                    arr_mtd,
                    arr_ytd,
                    room_today,
                    room_mtd,
                    room_ytd,
                  }) => {
                    totalToday += actual_today;
                    totalRoomToday += room_today;
                    totalBudgetToday += budget_today ?? 0;
                    avg_today += arr_today;

                    totalMtd += actual_mtd;
                    totalRoomMtd += room_mtd;
                    totalBudgetMtd += budget_mtd ?? 0;
                    avg_mtd += arr_mtd;

                    totalYtd += actual_ytd;
                    totalRoomYtd += room_ytd;
                    totalBudgetYtd += budget_ytd;
                    avg_ytd += arr_ytd;
                  }
                );

                return (
                  <>
                    <Table.Summary
                      fixed
                      key="summary-tables"
                      className="sum-table"
                    >
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          className={"summary-head"}
                          index={0}
                          // colSpan={3}
                          align="right"
                        >
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {_bulks[i][0]?.total_label_name || "Total"}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={1}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalToday)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={2}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalRoomToday)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={3}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(
                              avg_today > 0 ? avg_today.toFixed(0) : 0
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={4}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetToday)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={5}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalMtd)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={6}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalRoomMtd)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={7}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(
                              avg_mtd > 0 ? avg_mtd.toFixed(0) : 0
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={8}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetMtd)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={9}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalYtd)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={10}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalRoomYtd)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={11}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(
                              avg_ytd > 0 ? avg_ytd.toFixed(0) : 0
                            )}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={12}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetYtd)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      <Table.Summary.Row
                        className="row-space-2"
                        key={"row-space-2"}
                      >
                        <Table.Summary.Cell
                          key="row-cell-space"
                          className="row-cell-space"
                          index={0}
                          colSpan={9}
                        />
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
              style={{ padding: "0px 0px 10px 0px" }}
            />
          </div>
        );
      }
    }

    console.log("Table Results: ", _tables);
    setLoading(false);
    setTablesData(_tables);

    setIsProcessing(false);
  };

  // Handle Negative Bracket
  const handleNegativeBracket = (values) => {
    return `${values}`
      .replace(/-(.*)/, "($1)")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle Header Title
  const handleTitleHeader = (values) => {
    // let _parse = /^-?\d+$/.test(values);

    // let _number = _parse == true ? parseInt(values) : values;

    // console.log("Numbers: ", _number);

    const _formatted = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // values;
    // _parse;

    return (
      <Text
        className="header-text"
        style={
          isPrinted === true
            ? printPreview
            : {
                fontSize: 10,
                fontWeight: 500,
              }
        }
      >
        {_formatted}
      </Text>
    );
  };

  // Handle Refresh
  const handleRefresh = (value) => {
    // console.log(value);

    // fetchData();

    if (dateNow) {
      fetchHistory(dateNow);
    } else {
      fetchData();
    }
  };

  if (isProcessing === true) {
    return <SpinnerComponents />;
  }

  return (
    <>
      <Row
        // gutter={[30, 30]}
        className="row-body"
        justify="start"
        style={mainBody}
      >
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {`Statistics Report by Segment`}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 0px 0px", color: "#8c8c8c" }}
          >
            {`Idea's Hotel Bandung`}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 20px 0px", color: "#8c8c8c" }}
          >
            {moment(dateNow ? dateNow : _date).format("DD MMM YYYY")}
          </Typography.Title>

          <Row ref={tablesContainerRef}>
            {tablesData?.length > 0 ? (
              tablesData
            ) : (
              <EmptyTableComponents is_refresh={handleRefresh} />
            )}
          </Row>

          {/* <Table
            className="dsr-table"
            name="statistics-table"
            key="statistics-table"
            dataSource={statistics}
            columns={column}
            pagination={false}
            size="small"
            bordered={true}
            loading={loading}
            rowKey={(record) =>
              record?.order_view > 0 ? record.order_view : record?.description
            }
            // scroll={{
            //   x: 700,
            // }}
            summary={(pageData) => {
              let totalToday = 0;
              let totalBudgetToday = 0;

              let totalMtd = 0;
              let totalBudgetMtd = 0;

              let totalYtd = 0;
              let totalBudgetYtd = 0;

              pageData.forEach(
                ({
                  budget_today,
                  budget_mtd,
                  budget_ytd,
                  actual_today,
                  actual_mtd,
                  actual_ytd,
                }) => {
                  totalToday += actual_today;
                  totalBudgetToday += Math.floor(budget_today ?? 0);

                  totalMtd += actual_mtd;
                  totalBudgetMtd += Math.floor(budget_mtd ?? 0);

                  totalYtd += actual_ytd;
                  totalBudgetYtd += budget_ytd;
                }
              );
              return (
                <>
                  <Table.Summary key={"table-summaries"}>
                    <Table.Summary.Row
                      className="row-space-2"
                      key={"row-space-2"}
                    >
                      <Table.Summary.Cell
                        key="row-cell-space"
                        className="row-cell-space"
                        index={0}
                        colSpan={10}
                      >
                        <Table.Summary.Row />
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        className={"summary-head"}
                        index={0}
                        // colSpan={9}
                        align="right"
                      >
                        <Text
                          strong
                          style={{
                            fontSize: isPrinted ? "6px" : "12px",
                            textAlign: "end",
                          }}
                        >
                          {`Total`}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={1}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalBudgetToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalToday - totalBudgetToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalBudgetMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalMtd - totalBudgetMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalBudgetYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}>
                        <Text
                          strong
                          style={{ fontSize: isPrinted ? "6px" : "12px" }}
                        >
                          {handleNegativeBracket(totalYtd - totalBudgetYtd)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                </>
              );
            }}
          /> */}
        </Col>
      </Row>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Checkbox,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import MasterCOA from "../../../Reusable/Accounting/MainAccount/MainAccount";
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterIncomplete,
  masterSuccessNotification,
  masterFailedNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Confirm Modal
const { confirm } = Modal;

// CODE
export default function DepartmentTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getArticleDept } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Modal
  const [isDelete, setIsDelete] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // navigate
  const navigate = useNavigate();

  // USE FORM
  const [form] = Form.useForm();

  // Table Components
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleFormField(record);

                      handleOpen();
                      setEdit(true);
                      setDataEdit(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="delete-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 250,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Chart of Accounts",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // Fetch Data
  const fetchData = async () => {
    await getArticleDept({
      department: "department",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Dept => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
        // console.log();
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT DATA
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Handle Form Fields
  const handleFormField = (val) => {
    const _rec = val;

    form.setFieldsValue({
      code: _rec?.code,
      name: _rec?.name,
      department_id: _rec?.department_id,
      description: _rec?.description,
      is_show_pos: _rec?.is_show_pos,

      created_by: _rec?.created_by,
      created_date: moment(_rec?.created_date).format("DD MMM YYYY"),
      updated_by: _rec?.updated_by ? _rec.updated_by : "",
      updated_date: _rec?.updated_date
        ? moment(_rec?.updated_date).format("DD MMM YYYY")
        : null,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setIsDelete(false);
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setIsDelete(true);
    setDataEdit(null);

    setIsLoading(false);
    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: isDelete
        ? `Are you sure want to delete a Department ${
            _data?.name.toUpperCase() || " - - - "
          } from the table?`
        : `Are you sure you wanted to ${
            edit ? "Update " : "Add a "
          } Department named ${_data?.name.toUpperCase() || " - - - "}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(_data) : handleSubmit(_data);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log("On Finish Dept = ", e);

    // handleSubmit(e);
    setIsLoading(true);
    showModalConfirm(e);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);

    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    // console.log("ON OK ==> ", contentsubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/article/department`,
          {
            // department_id: contentsubmit?.department_id || null,
            code: contentsubmit?.code,
            name: contentsubmit?.name,
            description: contentsubmit?.description || null,
            non_profit: 0,
            is_show_pos: contentsubmit?.is_show_pos,
            chart_of_account: contentsubmit.chart_of_account
              ? contentsubmit.chart_of_account
              : null,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Create dept => ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Department",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("Create dept =>", error);

          masterFailedNotification(error, { method: 0, source: "Department" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/article/department`,
          {
            id: dataEdit?.id,
            code: contentsubmit?.code || dataEdit?.code,
            name: contentsubmit.name || dataEdit?.name,
            // department_id:
            //   contentsubmit.department_id || dataEdit?.department_id,
            description: contentsubmit.description || null,
            is_show_pos: contentsubmit?.is_show_pos,
            non_profit: 0,
            updated_by: user_name,
            chart_of_account: contentsubmit.chart_of_account
              ? contentsubmit.chart_of_account
              : null,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Update Dept => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Department",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("Update Dept => ", error);

          masterFailedNotification(error, { method: 1, source: "Department" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record.id;

    console.log("contentDelete >>>>>>", contentdelete);

    var obj = {
      ["id"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/article/department`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log(response);

        masterSuccessNotification(response, {
          method: 2,
          source: "Department",
        });
        handleCancel();
        fetchData();
      })
      .catch((error) => {
        console.log(error);

        masterFailedNotification(error, { method: 2, source: "Department" });
      });
  };

  // HANDLE EXPERT
  const handleExport = (value) => {
    // console.log("Export EXCEL: ", value);
    // console.log("Data About to Exports: ", data);

    downloadExcel(data, "Department List");
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey.toLowerCase();
    // console.log("Search Key = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        let _name = item?.name.toLowerCase();
        // console.log("ITEM => ", item);
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  // GET SELECTED ITEM
  const getSelectedItem = (value) => {
    form.setFieldsValue({
      chart_of_account: value?.code,
    });
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  return (
    <>
      <Row className="row-dept-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Department`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="dept-list-table"
              name="dept-list-table"
              key="dept-list-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1250,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) =>
                record.id ? record.id : record.department_id
              }
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-dept-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Data Department`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Data Department`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="dept-dept-modal-form"
          className="dept-dept-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          initialValues={{
            is_show_pos: edit === true ? null : false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-dept-id">
            <Col span={12}>
              <Form.Item
                label="Department Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please, input Department Code!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Department Code" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Department Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please, input Department Name!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Department Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-coa-dept">
            <Col span={24}>
              <Form.Item
                label="Chart Of Account"
                name="chart_of_account"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please, select a Chart Of Account!",
                //   },
                // ]}
                style={{ width: "100%" }}
              >
                <MasterCOA
                  coa_name={edit ? dataEdit?.chart_of_account : null}
                  disable={false}
                  getCoaName={getSelectedItem}
                  is_all={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-desc-dept" gutter={30}>
            <Col span={16} className="desc-col">
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  className="desc-txt"
                  showCount
                  allowClear
                  maxLength={200}
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>

            <Col span={8} className="checkbox-col">
              <Form.Item
                label="Show In POS"
                name="is_show_pos"
                valuePropName="checked"
              >
                <Checkbox
                  className="pos-chckbx"
                  // type={''}
                >
                  {`Show`}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "15px 0px 30px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-dept-dept">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created By" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated By" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-dept">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row className="row-modal-btn" justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

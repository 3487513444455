// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { FileExcelFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Table, Typography } from "antd";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// GLOBAL STYLES
import { mainBody } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import downloadExcel from "../../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../../../API/Context/MainContext/MainContext";

export default function JournalIncomingStockTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { getInventoryReport } = useContext(MainContext);
  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  const [total, setTotal] = useState({
    quantity: 0,
    amount: 0,
  });
  // Date
  const [datePicked, setDatePicked] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);

  // USE NAVIGATE
  const navigate = useNavigate();

  // GET DATA FUNCTION
  const fetchData = async () => {
    setLoading(true);

    await getInventoryReport({
      type: "journal-incoming-stock",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("Response >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _data = response.data.msg;

          const filter = _data.map((item, index) => {
            return {
              ...item,
              key: index,
              label: item.article_name,
              value: item.article_name,
            };
          });

          const sumTotal =
            filter.length > 0
              ? filter.reduce(
                  (prev, curr) => {
                    return {
                      quantity: prev.quantity + curr.quantity_total,
                      amount: prev.amount + curr.total_price,
                    };
                  },
                  { quantity: 0, amount: 0 }
                )
              : { quantity: 0, amount: 0 };

          setData(_data);
          setBulks(_data);
          setTotal(sumTotal);
        } else {
          setData([]);
          setBulks([]);
          setTotal({
            quantity: 0,
            amount: 0,
          });
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);

        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // USE EFFECT Fetching Data
  useEffect(() => {
    fetchData();

    if (refresh == true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  // HANDLE PRINT
  const handlePrint = () => {
    downloadExcel(
      data,
      `Journal of Incoming Stock List ${
        datePicked?.length > 0
          ? moment(datePicked[0]).format("YYYY-MM-DD")
          : "-"
      } - ${
        datePicked?.length > 0
          ? moment(datePicked[1]).format("YYYY-MM-DD")
          : "-"
      }`
    );
  };

  // USEEFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE DATE
  const handleDate = (val) => {
    const _date = val;

    console.log("DATE: ", _date);

    setDatePicked(_date);

    if (bulks.length > 0 && _date) {
      if (_date.length === 0 || _date === "Invalid date") {
        setData(bulks);
      } else {
        handleFilter(_date);
      }
    }
  };

  // HANDLE FILTER DATE
  const handleFilter = (value) => {
    const _start =
      value?.length > 0 ? moment(value[0]).format("YYYY-MM-DD") : null;
    const _end =
      value?.length > 0 ? moment(value[1]).format("YYYY-MM-DD") : null;

    let _filtered = bulks.filter((val) => {
      const _receivedDate = moment(val.received_date).format("YYYY-MM-DD");

      return moment(_receivedDate).isBetween(_start, _end, undefined, "[]");
    });

    console.log("Request Date Filtered: ", _filtered);
    setData(_filtered);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey : null;
    console.log("Searching = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        return (
          item.article_number.toLowerCase().indexOf(query?.toLowerCase()) !== -1
        );
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/incoming-stock";

    navigate(`${path}`);
  };

  // Params
  const currency = CurrencySymbols().code;

  // TABLE COLUMNS
  const columns = [
    {
      title: "Received Date",
      dataIndex: "received_date",
      key: "received_date",
      render: (date, record) => {
        return moment(date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_number.localeCompare(b.article_number),
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.article_name.localeCompare(b.article_name),
    },
    {
      title: "Quantity Request",
      dataIndex: "quantity_request",
      key: "quantity_request",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Received",
      dataIndex: "quantity_received",
      key: "quantity_received",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Broken",
      dataIndex: "quantity_broken",
      key: "quantity_broken",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Quantity Total",
      dataIndex: "quantity_total",
      key: "quantity_total",
      render: (qty, record) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Total (${currency})`,
      dataIndex: "total_price",
      key: "total_price",
      render: (avg, record) => {
        return avg > 0 ? `${avg}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: "Chart of Account",
      dataIndex: "chart_of_account",
      key: "chart_of_account",
    },
    {
      title: "Delivery Note",
      dataIndex: "delivery_note",
      key: "delivery_note",
    },
  ];

  return (
    <>
      <Row className="inventory-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: 30 }} className="col-table">
          <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Pick Date (Received Date)`}</Typography>

                  <DatePicker.RangePicker
                    className="date-btn"
                    allowClear
                    onChange={handleDate}
                    style={{
                      width: 250,
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handlePrint}
                  icon={<FileExcelFilled />}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table-main-row" style={{ width: "100%" }}>
            <Table
              className="table-inventory"
              name="table-inventory"
              key="table-inventory"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              size="large"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              scroll={{
                x: 1800,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={6}>
                          <b style={{ alignSelf: "end" }}>{`TOTAL`}</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.quantity}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0}>
                          <b>
                            {`${total.amount}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
              style={{
                margin: "30px 0px 0px",
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { cardBody, cardHeader } from "../../../../Style/Global/Global";
import { GoPackageDependents } from "react-icons/go";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import { RiInboxArchiveFill } from "react-icons/ri";
import StockTransferRequestTable from "../../../../Components/Table/BackOffice/Inventory/TransferRequest/StockTransferRequest/Table";
import StockTransferRequestReceivingTable from "../../../../Components/Table/BackOffice/Inventory/TransferRequest/StockTransferRequestReceiving/Table";

export default function StockTransferRequestReceivingPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [reqDate, setReqDate] = useState(null);

  // SEARCH
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  // HANDE REQUEST DATE
  const handleRequestDate = (e) => {
    setReqDate(moment(e).format("YYYY-MM-DD"));
  };
  return (
    <Layout>
      <Content>
        <Col span={24} className="transfer-request-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <RiInboxArchiveFill
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Receiving Stock Transfer/Request`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 100,
                  maxWidth: 180,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Keywords`}</Typography>

                <Input
                  allowClear
                  placeholder="Document Number"
                  onChange={handleSearch}
                  style={{
                    minWidth: 100,
                    maxWidth: 180,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  maxWidth: 180,
                  minWidth: 100,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Posting Date`}</Typography>

                <DatePicker
                  allowClear
                  placeholder="Posting Date"
                  className="tr-datepicker"
                  onChange={handleRequestDate}
                  style={{
                    minWidth: 100,
                    maxWidth: 180,
                  }}
                />
              </Col>

              <Col
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <StockTransferRequestReceivingTable
            is_search={setSearch}
            searchKey={value}
            postingDate={reqDate}
          />
        </Col>
      </Content>
    </Layout>
  );
}

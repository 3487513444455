// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { Col, Divider, Row, Table, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../../../../Style/Global/Global";

// NOTIFICATIONS
import { failedFetch } from "../../../../../../Reusable/Notification/Notification";

// GLOBAL API
import { token } from "../../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../../API/Config/Api";
import axios from "axios";

// PAGE COMPONENTS
import SpinnerComponents from "../../../../../../Reusable/Spinner/Spinner";

const { Text } = Typography;

export default function BSReportTable(props) {
  // PROPS
  const { is_printed, dataDate } = props;

  // STATE
  const [date, setDate] = useState(null);
  const [tablesData, setTablesData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [summaryTotal, setSummaryTotal] = useState([]);

  // DATE TODAY
  const _date = new Date();

  // USEEFFECTS
  useEffect(() => {
    let cleanUp = false;

    fetchData();

    if (dataDate && !cleanUp) {
      setDate(dataDate);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataDate]);

  // HANDLE CRATE TABLE
  useEffect(() => {
    if (finalData.length > 0) {
      console.log("Hit");
      handleCreateTable(finalData);
    }
  }, [finalData]);

  // FETCH DATA
  const fetchData = async () => {
    let _topData = [];
    let _mainData = [];
    let _headerData = [];

    setIsLoading(true);

    await axios
      .get(`${baseurl}/parameter/accounting/balance-sheet-top`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res Top Neraca: ", response);

        if (response?.data?.msg?.length > 0) {
          const _resTop = response.data.msg;

          console.log(_resTop);

          _topData = _resTop;
        } else {
          _topData = [];
        }
      })
      .catch((error) => {
        console.log("Res Top Neraca Error: ", error);
        failedFetch(error);
      });

    await axios
      .post(
        `${baseurl}/accounting/balance-sheet-main`,
        {
          date: dataDate ? dataDate : _date,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Res History: ", response);

        if (
          response?.data?.main_neraca?.length > 0 &&
          response?.data?.header_neraca?.length > 0
        ) {
          const _resMain = response.data.main_neraca.filter(
            (item) => item.description !== null
          );
          const _resHeader = response.data.header_neraca.filter(
            (item) => item.main_id_neraca !== null
          );

          console.log(_resMain);
          console.log(_resHeader);

          _mainData = _resMain;
          _headerData = _resHeader;
        } else {
          _mainData = [];
          _headerData = [];
        }
      })
      .catch((error) => {
        console.log("Res Main Neraca Error: ", error);
        failedFetch(error);
      });

    manipulateResponse(_topData, _mainData, _headerData);

    setIsLoading(false);
  };

  // HANDLE MANIPULATE API RESPONSE FOR CREATE TABLE
  const manipulateResponse = (top, main, header) => {
    console.log(top);
    console.log(main);
    console.log(header);

    const result = top.map((topItem) => {
      const mains = main
        .filter((mainItem) => mainItem.top_id_neraca === topItem.id)
        .map((mainItem) => {
          const details = header
            .filter((headerItem) => headerItem.main_id_neraca === mainItem.id)
            .map((headerItem) => ({
              id: headerItem?.id,
              sub_description: headerItem?.description,
              last_month: headerItem?.last_month,
              current_month: headerItem?.current_month,
              main_id_neraca: headerItem?.main_id_neraca,
              closing_date: headerItem?.closing_date,
              closing_date_time: headerItem?.closing_date_time,
            }));
          return {
            id: mainItem?.id,
            top_id_neraca: mainItem?.top_id_neraca,
            description: mainItem?.description,
            details: details,
          };
        });
      return {
        id: topItem.id,
        top_description: topItem.description,
        main: mains,
      };
    });

    console.log(result);

    setSummaryTotal(calculateTotals(result));
    setFinalData(result);

    // handleCreateTable(result);
  };

  // HANDLE CREATE TABLE
  const handleCreateTable = (value) => {
    const _data = value;
    console.log("Data Before Create Table: ", _data);

    let _tables = [];

    for (let i = 0; i < _data.length; i++) {
      for (let j = 0; j < _data[i].main.length; j++) {
        _tables.push(
          <>
            {j === 0 ? (
              <Typography
                className={`table-title ${
                  _tables.length > 0 ? "table-break" : ""
                }`}
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  margin: "25px 0 10px 0",
                }}
              >
                {_data[i]?.top_description || `Data -`}
              </Typography>
            ) : null}

            <Table
              className={`dsr-table`}
              name={"dsr-table-" + i}
              key={"dsr-table-" + i + "-" + j}
              title={() => (
                <Typography
                  className="table-title"
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  {_data[i].main[j].description || `Data-${i}`}
                </Typography>
              )}
              dataSource={_data[i].main[j].details}
              columns={columns}
              pagination={false}
              size="small"
              bordered={true}
              loading={isLoading}
              rowKey={(record) =>
                record?.id ? record.id : record?.account_code
              }
              summary={(record) => {
                let total = {
                  current_month: 0,
                  last_month: 0,
                };

                if (record?.length > 0) {
                  const sumTotal = record?.reduce((prev, curr) => {
                    return {
                      current_month: prev.current_month + curr.current_month,
                      last_month: prev.last_month + curr.last_month,
                    };
                  });

                  total = sumTotal;
                }

                return (
                  <>
                    <Table.Summary
                      fixed
                      key="summary-tables"
                      className="sum-table"
                    >
                      <Table.Summary.Row style={{ backgroundColor: "#fafafa" }}>
                        <Table.Summary.Cell
                          className={"summary-head"}
                          index={0}
                          align="right"
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {`TOTAL ${_data[i].main[j].description}`}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {total.current_month > 0
                              ? `${total.current_month}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              : 0}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={0} align="right">
                          <Text
                            style={{
                              alignSelf: "start",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {total.last_month > 0
                              ? `${total.last_month}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              : 0}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      {j === _data[i].main.length - 1 &&
                      _data[i].main.length > 1 ? (
                        <>
                          <Table.Summary.Row
                            style={{
                              backgroundColor: "#eaeaea",
                            }}
                          >
                            <Table.Summary.Cell
                              className={"summary-head"}
                              index={0}
                              align="right"
                            >
                              <Text
                                style={{
                                  alignSelf: "start",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`TOTAL ${_data[i]?.top_description}`}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={0} align="right">
                              <Text
                                style={{
                                  alignSelf: "start",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {summaryTotal[i]?.current_month > 0
                                  ? `${summaryTotal[i]?.current_month}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  : 0}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={0} align="right">
                              <Text
                                style={{
                                  alignSelf: "start",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                {summaryTotal[i]?.last_month > 0
                                  ? `${summaryTotal[i]?.last_month}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  : 0}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      ) : null}
                    </Table.Summary>
                  </>
                );
              }}
            />
          </>
        );
      }
    }

    console.log("Table Results: ", _tables);
    setTablesData(_tables);
  };

  // HANDLE TITLE HEADER
  const handleTitleHeader = (values) => {
    const _formatted = values.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <Text
        className="header-text"
        style={{
          fontSize: 12,
          fontWeight: 500,
        }}
      >
        {_formatted}
      </Text>
    );
  };

  // HANDE CALCULATE TOTAL OF CURRENT MONTH & LAST MONTH
  const calculateTotals = (data) => {
    const _totals = data.map((item) => {
      const totals = {
        current_month: 0,
        last_month: 0,
      };

      item.main.forEach((mainItem) => {
        mainItem.details.forEach((detail) => {
          if (detail.current_month !== null) {
            totals.current_month += detail.current_month;
          }
          if (detail.last_month !== null) {
            totals.last_month += detail.last_month;
          }
        });
      });

      return totals;
    });

    return _totals;
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: () => {
        return handleTitleHeader("NAME");
      },
      dataIndex: "sub_description",
      key: "sub_description",
      render: (description, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 12,
              fontWeight: 500,
              // textTransform: "capitalize",
            }}
          >
            {`${description}`
              .replace(/-+/g, " ")
              .replace(/(^\w|\s\w)/g, (val) => val)}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("CURRENT MONTH");
      },
      dataIndex: "current_month",
      key: "current_month",
      width: 200,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("LAST MONTH");
      },
      dataIndex: "last_month",
      key: "last_month",
      width: 200,
      align: "right",
      render: (amount, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={{
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {amount > 0 ? `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
          </Text>
        );
      },
    },
  ];

  if (isLoading === true) {
    return <SpinnerComponents />;
  }

  return (
    <>
      <Row className="bsr-main-table" justify="center" style={mainBody}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={4} style={{ margin: 0 }} align="center">
            {"Balance Sheet Report"}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 0px 0px", color: "#8c8c8c" }}
            align="center"
          >
            {`Look Hotel Bandung`}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 20px 0px", color: "#8c8c8c" }}
            align="center"
          >
            {moment(date ? date : _date).format("MMMM YYYY")}
          </Typography.Title>

          <Divider
            className="report-divider"
            style={{ margin: "15px 0px", background: "#EBEDF3" }}
          />

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            {tablesData?.length > 0 ? tablesData : null}
          </Row>
        </Col>
      </Row>
    </>
  );
}

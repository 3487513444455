// React Components
import React from "react";

// Import Antd Components
import { Row, Spin } from "antd";
import { ImSpinner6 } from "react-icons/im";
import { CgSpinnerTwoAlt } from "react-icons/cg";
import {
  Loading3QuartersOutlined,
  LoadingOutlined,
  ReloadOutlined,
  SmileOutlined,
} from "@ant-design/icons";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";

// CODE
export default function LoadingComponents() {
  return (
    <>
      <Row
        justify="center"
        align="middle"
        className="spinner-row"
        style={mainBody}
      >
        <Spin
          className="spinner"
          size="large"
          spinning={true}
          indicator={
            <Loading3QuartersOutlined
              className="spin-icons"
              spin
              //   color=""
              // size={50}
              style={{
                fontSize: 75,
                // animation: "spin 2s linear infinite",
                // transform: "rotate(90deg)",
                // motionRotation: ''
              }}
            />
          }
          wrapperClassName="loading-wrap"
          style={{
            padding: "60px 30px",
          }}
        />
      </Row>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";
import { token, user_name } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Steps,
  Progress,
  Alert,
  message,
  Checkbox,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { PrinterOutlined } from "@ant-design/icons";

// Import Page Components
import MasterRateAudit from "../../../../Reusable/Master/RateCode/RateAudit";
import MasterComplimentType from "../../../../Reusable/Master/Compliment/Compliment";
import ParametersBreakfast from "../../../../Reusable/Parameters/ParamsBF";
import MasterRateCode from "../../../../Reusable/Master/RateCode/RateCode";

// Functions
import { FrontOfficeParams } from "../../../../Reusable/Functions/Parameters/ParamsFO";
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";
import { Grossing } from "../../../../Reusable/Functions/GrossBreakdown/Grossing";
import { Pricing } from "../../../../Reusable/Functions/Pricing/Pricing";
import { AccountingParams } from "../../../../Reusable/Functions/Parameters/ParamsAcc";
import { LocalizationDate } from "../../../../Reusable/Functions/Localizations/Localization";

// Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../../Reusable/Notification/MasterNotif/Notification";
import { useReactToPrint } from "react-to-print";

// CONST
const { Text, Link, Title } = Typography;

const { confirm } = Modal;

// CODE
export default function UnpaidOrderTable(props) {
  // PROPS
  const { dataRoomRate, dateAudit } = props;

  // Form
  const [form] = Form.useForm();

  const accounting = AccountingParams();

  // Params FO
  const breakfast = FrontOfficeParams().article_id;
  const param_breakfast = ParametersBreakfast().breakfast;
  const bill_type = FrontOfficeParams().bill_type;
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getNightAudit } = useContext(MainContext);

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [date_audit, setDate_Audit] = useState(null);
  // New rate
  const [currentRate, setCurrentRate] = useState({
    value: "",
    label: "",
    id: 0,
    main_segment_id: 0,
    main_segment_name: "",
    main_segment_code: "",
    room: "",
    nett: 0,
    service: 0,
    tax: 0,
    gross: 0,
    description: null,
  });
  const [newRate, setNewRate] = useState({
    value: "",
    label: "",
    id: 0,
    main_segment_id: 0,
    main_segment_name: "",
    main_segment_code: "",
    room: "",
    nett: 0,
    service: 0,
    tax: 0,
    gross: 0,
    description: null,
  });
  // Modal
  const [open, setOpen] = useState(false);
  const [permitted, setPermitted] = useState(false);
  const [comply, setComply] = useState(false);
  const [compliment, setCompliment] = useState({
    comply: 2,
    id: 2,
    name: "No Compliment",
  });
  const [isNew, setIsNew] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);
  const [lodingBtn, setLodingBtn] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [isPrint, setIsPrint] = useState(false);

  // Dummy
  const unpaidOrders = [
    {
      order_id: 1,
      date: "2024-10-13",
      sales_type: "Dine-in",
      table_code: "T02",
      name: "Alice Brown",
      shift: "Afternoon",
      user: "cashier1",
      location: "Outlet A",
      price: 300000
    },
    {
      order_id: 2,
      date: "2024-10-13",
      sales_type: "Dine-in",
      table_code: "T03",
      name: "Bob Green",
      shift: "Afternoon",
      user: "cashier1",
      location: "Outlet A",
      price: 450000
    },
    {
      order_id: 3,
      date: "2024-10-13",
      sales_type: "Takeaway",
      table_code: "N/A",
      name: "Charlie Davis",
      shift: "Evening",
      user: "cashier2",
      location: "Outlet B",
      price: 200000
    }
  ];
  

  // Find ABF TODAYS
  const FindABF = (val) => {
    // console.log("Find ABF: ", _date);
    const _arr = val.abf;
    const _date = moment(val.date).format("YYYY-MM-DD");
    const _price_id = val.price_id;

    let _total = 0;

    const _selected = _arr.filter((items) => {
      let _order = moment(items.order_date).format("YYYY-MM-DD");

      if (_order == _date && items.price_id == _price_id) return items;
    });

    if (_selected?.length > 0) {
      let _find = _selected.forEach(({ total_price }) => {
        _total += total_price;
      });
    }

    // console.log("Total: ", _total);
    return _total;
  };

  // Fetch Data Night Audit Room Rate
  const fetchData = async () => {
    await getNightAudit({
      type: "room-rate",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          handleId(_response);
          // setData(_response);
          // dataRoomRate(_response);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // Fetch Data Re-Night Audit
  const fetchReAudit = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseurl}/re-night-audit/room-rate`,
        {
          date: moment(dateAudit).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          setData(_response);
          dataRoomRate(_response);
        } else {
          dataRoomRate([]);
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dateAudit != date_audit && !cleanUp) {
      setDate_Audit(dateAudit);
      fetchReAudit();
    } else {
      if (date_audit) {
        setData([]);
        dataRoomRate([]);
      } else {
        fetchData();
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [dateAudit]);

  useEffect(() => {
    let cleanUp = false;

    if (isPrint && !cleanUp) {
      printOut();
    }

    return () => {
      cleanUp = true;
    };
  }, [isPrint]);

  // COLUMN
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      hidden: false,
      sorter: (a, b) => a.order_id - b.order_id,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      hidden: false,
      sorter: (a, b) => a.date - b.date,
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Sales Type",
      dataIndex: "sales_type",
      key: "sales_type",
      hidden: false,
      render: (text, record) => {
        return `${text} (${record.table_code})`
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      hidden: false,
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      hidden: false,
    },
    {
      title: `User`,
      dataIndex: "user",
      key: "user",
      // align: "right",
      hidden: false,
    },
    {
      title: `Location`,
      dataIndex: "location",
      key: "location",
      // align: "right",
      hidden: false,
    },
    {
      title: `Price`,
      dataIndex: "price",
      key: "price",
      align: "right",
      hidden: false,
      render: (text) => {
        return `${text}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," ) 
      },
    },
  ].filter((x) => !x.hidden);

  // Get Rate
  const getRateCode = (val) => {
    console.log("New Rate: ", val);

    setNewRate(val);

    form.setFieldsValue({
      new_rate_code: val.value || "",
      new_room_price: val.gross || 0,
    });
  };

  // Get Old Rate
  const getOldRate = (val) => {
    console.log("Old Rate: ", val);

    setCurrentRate({ ...val });

    form.setFieldsValue({
      gross: val.gross || 0,
    });
  };

  // Compliment
  const getCompliment = (val, keys) => {
    console.log("Comply: ", val, keys);
    setCompliment({
      comply: keys,
      id: keys,
      name: val,
    });

    if (keys == 2) {
      setComply(false);

      form.setFieldsValue({
        compliment_type: val,
      });
    } else {
      setComply(true);

      form.setFieldsValue({
        new_room_price: 0,
        compliment_type: val,
      });
    }
  };

  // Handle Change
  const handleChange = () => {
    setPermitted(!permitted);

    if (permitted) {
      form.resetFields(["new_room_price", "new_rate_code"]);
    }
  };

  // Handle Change
  const handleCheck = (val) => {
    const _check = val.target.checked;

    setIsNew(_check);

    // if (permitted) {
    form.setFieldsValue({
      is_new: _check,
      room_occupancy: 1,
      room_breakfast: 0,
      nett: 0,
      service: 0,
      tax: 0,
    });
    // }
  };

  // Handle New Rate Nett
  const handleNett = (val) => {
    const _nett = val;
    let tax = accounting.tax_percentage;
    let service = accounting.service_percentage;

    let _newPrice = Pricing({
      nett: _nett || 0,
      tax: tax,
      service: service,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient_tax_service,
    });

    console.log("New Prices: ", _newPrice);

    form.setFieldsValue({
      nett: _nett,
      service: _newPrice.services,
      tax: _newPrice.taxes,
      new_room_price: _newPrice.gross,
    });
  };

  // Handle New Rate Gross
  const handleGross = (val) => {
    const _gross = val;
    let tax = accounting.tax_percentage;
    let service = accounting.service_percentage;

    let _newPrice = Grossing({
      gross: _gross || 0,
      tax: tax,
      service: service,
      is_service_taxed: accounting?.is_service_taxed,
      coefficient: accounting?.coefficient_tax_service,
    });

    console.log("New Prices: ", _newPrice);

    form.setFieldsValue({
      nett: _newPrice.nett,
      service: _newPrice.services,
      tax: _newPrice.taxes,
      new_room_price: _gross,
    });
  };

  // On Finish
  const onFinish = (value) => {
    const _data = value;
    console.log("Finish: ", value);

    setLodingBtn(true);
    showModalConfirm(_data);
  };

  // On Finish
  const onFinishFailed = (value) => {
    console.log("Finish Failed: ", value?.values);
  };

  // SHOW SUBMIT CONFIRMATION
  const showModalConfirm = (e) => {
    const submitContent = e;

    console.log("On finish-->", submitContent);
    console.log("On finish-->", newRate);

    confirm({
      className: "submit-deposit-confirm",
      title: `Are you sure want to Change guest Reservation ID ${
        submitContent?.reservation_id || " - - - - "
      } Rate Code to ${
        submitContent.new_rate_code
      }? This action cannot be undone once it's changed!`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isNew
          ? handleCreateNewRate(submitContent)
          : handleSubmit({
              content: submitContent,
              new_rate: newRate,
            });
        // console.log("Guest deleted");
      },
      onCancel() {
        setLodingBtn(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE ID
  const handleId = (val) => {
    const _arr = val;
    let _id = 1;

    let _valued = _arr.map((value) => ({
      ...value,
      id: _id++,
    }));

    setData(_valued);
    dataRoomRate(_valued);
  };

  // Handle Cancel
  const handleCancel = () => {
    form.resetFields();

    setPermitted(false);
    setDataEdit(null);
    setLodingBtn(false);
    setIsRender(false);

    setNewRate({
      value: "",
      label: "",
      id: 0,
      main_segment_id: 0,
      main_segment_name: "",
      main_segment_code: "",
      room: "",
      nett: 0,
      service: 0,
      tax: 0,
      gross: 0,
      description: null,
    });
    setCurrentRate({
      value: "",
      label: "",
      id: 0,
      main_segment_id: 0,
      main_segment_name: "",
      main_segment_code: "",
      room: "",
      nett: 0,
      service: 0,
      tax: 0,
      gross: 0,
      description: null,
    });

    setCompliment({
      comply: 2,
      id: 2,
      name: "No Compliment",
    });

    setIsNew(false);
    setComply(false);
    setOpen(false);
  };

  // POST NEW RATE CODE
  const handleCreateNewRate = async (val) => {
    const contentSubmit = val;
    let desc = "";

    console.log("ON SUBMIT ==> ", contentSubmit);
    console.log("Rate Current ==> ", currentRate);
    console.log("Rate Current ==> ", newRate);
    // console.log("POST OR PUT ==> ", _obj);

    desc = `${contentSubmit?.room_category_name || "GROUP"} ROOM for ${
      contentSubmit.room_occupancy
    } Adult(s) and ${contentSubmit.room_breakfast} Pax Breakfast(s)`;

    const _name = {
      rate_code: contentSubmit.rate_code || null,
      main_segment_id: currentRate.main_segment_id || 0,
      main_segment_code: currentRate.main_segment_code || null,
      main_segment_name: currentRate.main_segment_name || null,
      description: desc,
      room_category_code: currentRate.room_category_code || null,
      room_category_name: currentRate?.room_category_name || null,
      room_category_id: dataEdit?.room_category_id || null,
      room_breakfast:
        contentSubmit?.room_breakfast > 0 ? contentSubmit.room_breakfast : 0,
      room_occupancy:
        contentSubmit?.room_occupancy > 0 ? contentSubmit.room_occupancy : 0,
      nett: contentSubmit?.nett > 0 ? contentSubmit.nett : 0,
      service: parseInt(contentSubmit.service),
      tax: parseInt(contentSubmit.tax),
      gross:
        contentSubmit?.new_room_price > 0 ? contentSubmit.new_room_price : 0,
      created_by: user_name,
    };

    // console.log("New Rate Current ==> ", _name);

    await axios
      .post(
        `${baseurl}/room/rate-code`,
        {
          rate_code: contentSubmit.new_rate_code || null,
          main_segment_id: currentRate.main_segment_id || 0,
          main_segment_code: currentRate.main_segment_code || null,
          main_segment_name: currentRate.main_segment_name || null,
          description: desc,
          room_category_code: currentRate.room_category_code || null,
          room_category_name: dataEdit?.room_category_name || null,
          room_category_id: dataEdit?.room_category_id || null,
          room_breakfast:
            contentSubmit?.room_breakfast > 0
              ? contentSubmit.room_breakfast
              : 0,
          room_occupancy:
            contentSubmit?.room_occupancy > 0
              ? contentSubmit.room_occupancy
              : 0,
          nett: contentSubmit?.nett > 0 ? contentSubmit.nett : 0,
          service: parseInt(contentSubmit.service),
          tax: parseInt(contentSubmit.tax),
          gross:
            contentSubmit?.new_room_price > 0
              ? contentSubmit.new_room_price
              : 0,
          created_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Add Rate Code => ", response);

        if (response.data?.msg) {
          const _new = response.data.msg;

          masterSuccessNotification(response, {
            method: 0,
            source: "Rate Code",
          });

          setNewRate(_new);
          handleSubmit({
            new_rate: _new,
            content: contentSubmit,
          });
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: "Rate Code",
          });
          setLodingBtn(false);
        }
      })
      .catch((error) => {
        console.log("Error Rate Code => ", error);

        masterFailedNotification(error, { method: 0, source: "Rate Code" });
        setLodingBtn(false);
      });
  };

  // Handle Change Rate COde
  const handleSubmit = async (value) => {
    const _data = value.content;
    const _data_new = value.new_rate;

    const submit = {
      reservation_id: _data?.reservation_id,
      rate_code: _data?.new_rate_code,
      price_id: dataEdit?.price_id,
      tax: comply ? 0 : _data_new.tax,
      service: comply ? 0 : _data_new.service,
      gross: comply ? 0 : _data_new.gross,
      room_rate_id: [
        _data?.new_rate_code,
        _data_new.main_segment_id,
        _data_new.id,
      ],
      room_number: dataEdit?.room_number,
      date: dataEdit?.date,
      article_id: breakfast,
      order_date: moment(),
      compliment_type: _data?.compliment_type,
      segment: _data_new.main_segment_name,
      main_segment_id: _data_new?.main_segment_id,
      main_compliment_id: compliment.id,
      with_breakfast: dataEdit?.is_abf ? true : false,
      rate_code_id: _data_new?.id,
      quantity: _data_new.room_breakfast,

      updated_by: user_name,
    };

    console.log("Change Rate: ", value);

    await axios
      .put(
        `${baseurl}/night-audit/change-rate-code`,
        {
          reservation_id: _data?.reservation_id,
          rate_code: _data?.new_rate_code,
          price_id: dataEdit?.price_id,
          tax: comply
            ? 0
            : _data_new?.tax - _data_new.room_breakfast * param_breakfast.tax,
          service: comply
            ? 0
            : _data_new?.service -
              param_breakfast.service * _data_new?.room_breakfast,
          gross: comply
            ? 0
            : _data_new?.gross -
              param_breakfast.gross * _data_new?.room_breakfast,
          room_price: comply
            ? 0
            : _data_new?.nett -
              param_breakfast?.unit_price * _data_new?.room_breakfast,
          room_rate_id: [
            _data?.new_rate_code,
            _data_new.main_segment_id,
            _data_new.id,
          ],
          room_number: dataEdit?.room_number,
          // date: dataEdit?.date,
          article_id: breakfast,
          // order_date: moment(),
          compliment_type: _data?.compliment_type,
          segment: _data_new?.main_segment_name,
          main_segment_id: _data_new.main_segment_id,
          main_compliment_id: compliment.id,
          with_breakfast: _data_new.room_breakfast > 0 ? true : false,
          rate_code_id: _data_new.id,
          quantity: _data_new.room_breakfast,
          room_id: dataEdit?.room_id > 0 ? dataEdit.room_id : 0,

          updated_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Succeess: ", response);

        masterSuccessNotification(response, {
          method: 1,
          source: "Change Rate Code",
        });

        fetchData();
        handleCancel();
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 1,
          source: "Change Rate Code",
        });
      })
      .finally(() => {
        setLodingBtn(false);
      });
  };

  // Handle Print
  const componentRef = useRef();
  const pageStyle = "@media print{@page {size: landscape}}";

  const handlePrint = () => {
    // printOut();
    setIsPrint(true);
  };

  const printOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${moment().format("YYYY-MM-DD")} Room Rate - Night Audit`,
    onAfterPrint: () => {
      setIsPrint(false);
    },

    pageStyle: pageStyle,
  });

  return (
    <>
      <Row justify="end" style={{ marginTop: 30, width: "100%" }}>
        <Col>
          <Button
            icon={<PrinterOutlined />}
            className="next-btn"
            type="primary"
            onClick={handlePrint}
          >{`Download`}</Button>
        </Col>
      </Row>

      <Row
        ref={componentRef}
        style={{ marginTop: 15, padding: 20, width: "100%" }}
      >
        <Typography.Title level={5}>
          {isPrint
            ? `Rate Code - Night Audit ${moment().format("DD-MM-YYYY")}`
            : null}
        </Typography.Title>

        <Table
          className="na-roomrate-table"
          key="na-roomrate-table"
          name="na-roomrate-table"
          loading={loading}
          columns={columns}
          // dataSource={data}
          dataSource={unpaidOrders}
          bordered
          size="small"
          pagination={
            false
            //   {
            //   pageSizeOptions: [5, 10, 25, 50, 100],
            //   defaultPageSize: 5,
            //   showSizeChanger: true,
            //   showTotal: (total, range) =>
            //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            // }
          }
          scroll={{
            x: 1000,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => (record.room_id > 0 ? record.room_id : record.id)}
          summary={(pageData) => {
            let totalPrice = 0;
            let totalGross = 0;
            let totalBreakfast = 0;
            // let service = 0;

            pageData.forEach(
              ({ gross, room_price, article_bf, price_id, date }) => {
                totalGross += gross;
                totalPrice += room_price;

                if (article_bf?.length > 0) {
                  // console.log("ABF: ", article_bf);

                  let _sum = FindABF({
                    date: date,
                    abf: article_bf,
                    price_id: price_id,
                  });

                  totalBreakfast += _sum;
                }
              }
            );

            // tax = (totalPrice + service) * 0.11;

            return (
              <>
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={3} colSpan={7}>
                      <b style={{ fontSize: 12 }}>Total</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={9} align="right">
                      <b style={{ fontSize: 12 }}>
                        {`${totalGross + totalBreakfast}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              </>
            );
          }}
        />
      </Row>

      <Modal
        className="room-status-modal"
        title={
          <Row>
            <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography style={{ marginLeft: 15 }}>
              {`Change Rate Code`}
            </Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="room-status-modal-form"
          className="room-status-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            compliment_type: 2,
          }}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="space-row" justify="start" align="top">
            <Col span={24}>
              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item label="Reservation ID" name="reservation_id">
                    <Input placeholder="Reservation ID" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Guest Name" name="guest_name">
                    <Input placeholder="Guest Name" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Date" name="date">
                    <Input placeholder="Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item label="Room Category" name="room_category">
                    <Input placeholder="Room Category" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Room No." name="room_number">
                    <InputNumber
                      placeholder="Room No."
                      disabled
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Change Rate">
                    <Button
                      type="primary"
                      className="submit-btn"
                      onClick={handleChange}
                    >
                      {permitted ? `Cancel` : `Change Rate`}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item label="Current Rate Code" name="rate_code">
                    <Input placeholder="Current Rate Code" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Current Rate/Price" name="gross">
                    <InputNumber
                      placeholder="Current Rate/Price"
                      disabled
                      addonBefore={currency}
                      formatter={(prc) =>
                        ` ${prc > 0 ? prc : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  {permitted ? (
                    <Form.Item
                      name={"is_new"}
                      valuePropName="checked"
                      style={{
                        margin: "25px 0px 0px",
                      }}
                    >
                      <Checkbox className="checkbox-new" onChange={handleCheck}>
                        {`New Rate Code`}
                      </Checkbox>
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row className="rate-desc" style={{ width: "100%" }}>
                <Alert
                  className="desc-alert"
                  type="warning"
                  closable={false}
                  showIcon
                  message={"Current Segments & Rate Code Descriptions:"}
                  description={
                    <Typography
                      className="desc-text"
                      style={{ fontWeight: 500 }}
                    >
                      {`SEGMENTS: ${
                        currentRate.main_segment_name
                          ? currentRate.main_segment_name.toUpperCase()
                          : ""
                      } --- ${currentRate.description || ""}`}
                    </Typography>
                  }
                  style={{ width: "100%" }}
                />
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item
                    label="Choose New Rate Code"
                    name="new_rate_code"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose/Type a New Rate!",
                      },
                    ]}
                  >
                    {isNew ? (
                      <Input placeholder="Input New Rate Name" />
                    ) : (
                      <MasterRateAudit
                        getRateCode={getRateCode}
                        getOldRate={getOldRate}
                        is_abf={open ? dataEdit?.is_abf : false}
                        old_rate={open ? dataEdit?.room_rate_id : []}
                        // segment_name={segmentation}
                        room_cat={open ? dataEdit?.room_category_id : 0}
                        segment_id={currentRate.main_segment_id}
                        // qty={roomQty}
                        // allSegment={
                        //   companyInfo?.segment_id?.length > 0
                        //     ? companyInfo.segment_id
                        //     : []
                        // }
                        // sub_segment_id={subSegment}
                        is_render={isRender}
                        is_rerender={setIsRender}
                        // is_company={companyInfo?.rate_id || []}
                        is_permitted={permitted}
                        is_find={false}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="New Rate/Price" name="new_room_price">
                    <InputNumber
                      placeholder="New Rate/Price"
                      disabled={!isNew}
                      onChange={handleGross}
                      addonBefore={currency}
                      formatter={(prc) =>
                        ` ${prc > 0 ? prc : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="Compliment Type" name="compliment_type">
                    <MasterComplimentType
                      is_disable={!permitted}
                      getCompliment={getCompliment}
                      compl={"No Compliment"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {isNew ? (
                <>
                  <Row gutter={30} className="row-new-rate">
                    <Col span={8} className="col-current-room">
                      <Form.Item
                        label="Nett price"
                        name="nett"
                        rules={[
                          {
                            required: isNew == true ? true : false,
                            message: "Please, Input New Rate!",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Nett Price"
                          min={0}
                          addonBefore={currency}
                          onChange={handleNett}
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-current-room">
                      <Form.Item label="Service " name="service">
                        <InputNumber
                          placeholder="Service Price"
                          min={0}
                          disabled
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          addonBefore={currency}
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-current-room">
                      <Form.Item label="Tax " name="tax">
                        <InputNumber
                          placeholder="Tax Price"
                          min={0}
                          addonBefore={currency}
                          disabled
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={30} className="row-new-rate">
                    <Col span={8} className="col-current-room">
                      <Form.Item
                        label="Occupants"
                        name="room_occupancy"
                        rules={[
                          {
                            required: isNew == true ? true : false,
                            message: "Please, Input New Rate!",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Room Occupants"
                          min={1}
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className="col-current-room">
                      <Form.Item
                        label="Total Breakfast "
                        name="room_breakfast"
                        rules={[
                          {
                            required: isNew === true ? true : false,
                            message: "Please, Input New Rate!",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Breakfast Count"
                          min={0}
                          formatter={(prc) =>
                            `${prc > 0 ? prc : 0}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : null}

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row className="rate-desc" style={{ width: "100%" }}>
                <Alert
                  className="desc-alert"
                  type="info"
                  closable={false}
                  showIcon
                  message={"New Segments & Rate Code Descriptions:"}
                  description={
                    <Typography
                      className="desc-text"
                      style={{ fontWeight: 500 }}
                    >
                      {`SEGMENTS: ${
                        newRate.main_segment_name
                          ? newRate.main_segment_name.toUpperCase()
                          : ""
                      } --- ${newRate.description || ""}`}
                    </Typography>
                  }
                  style={{ width: "100%" }}
                />
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px 15px", background: "#EBEDF3" }}
              />

              <Row justify="end" style={{ width: "100%" }}>
                <Form.Item>
                  <Button
                    className="submit-btn"
                    type="primary"
                    loading={lodingBtn}
                    htmlType="submit"
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {`Submit`}
                  </Button>
                </Form.Item>

                <Button
                  className="cancel-btn"
                  onClick={handleCancel}
                >{`Cancel`}</Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// REACT COMPONENTS
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Select, Space, Typography, Form, } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";

// CONTEXT
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// PAGE COMPONENTS
import { TbDevicesCancel } from "react-icons/tb";
import WarehouseList from "../../../../../../Components/Reusable/Inventory/Warehouse/WarehouseList";
import ExpiredOutgoingReportTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/OutgoingStock/ExpiredOutgoing/Table";
import MasterArticleMain from "../../../../../../Components/Reusable/Master/ArticleMain/ArticleMain";

export default function ExpiredOutgoingReportPage() {
  // STATE
  const [toStorage, setToStorage] = useState(null);
  const [element, setElement] = useState(null);
  const [articleMain, setArticleMain] = useState(null);
  const [filterElement, setFilterElement] = useState(null);
  const [fromStorage, setFromStorage] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // USE FORM
  const [form] = Form.useForm();

  // NAVIGATE
  const navigate = useNavigate();

  // CONTEXT
  const { getArticleInventory } = useContext(MasterContext);

  
  // USEEFFECT
  useEffect (() => {console.log("articleMain>",articleMain)},[articleMain]);
  useEffect (() => {console.log("fromStorage>",fromStorage)},[fromStorage]);
  useEffect (() => {console.log("toStorage>",toStorage)},[toStorage]);

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report/outgoing-stock/";
    
    navigate(`${path}`);
  };

  const handleArticleMain = (value) => {
    console.log("handlearticleMain val >> ", value);
    if(value.name){
      setArticleMain(value);
      setFilterElement(<Col
        style={{
          textAlign: "start",
          fontSize: 14,
          fontWeight: 400,
          minWidth: 250,
          marginRight: 10,
        }}
        >
        <Typography style={{ marginBottom: 10 }}>
          {`From Storage`}
        </Typography>
        <Form name="fromStorage">
          <WarehouseList
            selectedItem={handleFromStorage}
            warehouse_name={null}
            is_disable={false}
            />
        </Form>
      </Col>);
    } else {
      setArticleMain(null);
      setFilterElement(null);
      setFromStorage(null);
      setElement(null);
      setToStorage(null);
    }
  };

  const handleFromStorage = (value) => {
    if (value.id_warehouse) {
      setFromStorage(value);
      setElement(
        <Col
          style={{
            textAlign: "start",
            fontSize: 14,
            fontWeight: 400,
            minWidth: 250,
          }}
        >
          <Typography style={{ marginBottom: 10 }}>{`To Storage`}</Typography>

          <WarehouseList
            selectedItem={handleToStorage}
            warehouse_name={null}
            is_disable={false}
          />
        </Col>
      );
    } else {
      setFromStorage(null);
      setElement(null);
      setToStorage(null);
    }
  };

  const handleToStorage = (value) => {
    if(value.id_warehouse){
      setToStorage(value);
    } else {
      setToStorage(null);
    };
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="incoming-stock-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <TbDevicesCancel
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Expired Outgoing Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Article Main Group`}
                </Typography>

                <MasterArticleMain
                  getArticleMain={handleArticleMain}
                  mainName={null}
                  isDisabled={false}
                />
              </Col>

              {filterElement}

              {element}

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          { articleMain && fromStorage && toStorage ? (
            <ExpiredOutgoingReportTable
            _articleMain={articleMain}
            _fromStorage={fromStorage}
            _toStorage={toStorage}
            />
          ) : null}
        </Col>
      </Content>
    </Layout>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  InputNumber,
  Layout,
  Menu,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  MdChair,
  MdDoorSliding,
  MdFreeCancellation,
  MdOutlinePersonOff,
  MdOutlineReplayCircleFilled,
} from "react-icons/md";
import {
  FaBookOpen,
  FaHistory,
  FaPlaneArrival,
  FaPlaneDeparture,
} from "react-icons/fa";
import {
  PiStackPlusFill,
  PiColumnsBold,
  PiArrowArcLeftBold,
  PiFastForwardCircleFill,
} from "react-icons/pi";
import { HiMiniSquaresPlus } from "react-icons/hi2";

// Import Global Styles
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../Style/Global/Global";

// CODE
export default function FOReportPage() {
  // STATE MANAGEMENT

  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <MdChair
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Front Office Reports`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("reservation-list")}
                    // type="primary"
                    icon={<FaBookOpen style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Reservations`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("arrival-list")}
                    // type="primary"
                    icon={<FaPlaneArrival style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Expected Arrival`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("inhouse-list")}
                    // type="primary"
                    icon={<MdDoorSliding style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Guest In-House`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("departure-list")}
                    // type="primary"
                    icon={<FaPlaneDeparture style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Expected Departure`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("history-guest")}
                    // type="primary"
                    icon={<FaHistory style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`History`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("cancel-reservation")}
                    // type="primary"
                    icon={<MdFreeCancellation style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Cancelled Reservations`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("noshow-reservation")}
                    // type="primary"
                    icon={<MdOutlinePersonOff style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`No Show`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("reactivate-reservations")}
                    // type="primary"
                    icon={<PiArrowArcLeftBold style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Re-Activate Check-In Reservations`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("extended-reservation")}
                    // type="primary"
                    icon={<HiMiniSquaresPlus style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Extended Reservations`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("early-check-out")}
                    // type="primary"
                    icon={<PiFastForwardCircleFill style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Early Check-Out`}</Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("recheck-in-check-out-guest")}
                    // type="primary"
                    icon={<MdOutlineReplayCircleFilled style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography
                      className="text-page"
                      style={globalText}
                    >{`Re-Check-In C/O Reservation`}</Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

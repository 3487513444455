// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BiSolidFilePdf } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Master Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";

// Import Functions
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// CODE
export default function GuaranteeLetter(props) {
  // PROPS
  const { openModal, closeModal, dataGuest } = props;

  // CONTEXT
  const { getGuest, getReservation } = useContext(MainContext);
  const { getMasterPrint } = useContext(MasterContext);

  // USE REF
  const componentRef = useRef();
  const param_fo = FrontOfficeParams();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [guest, setGuest] = useState(null);
  const [totalAdult, setTotalAdult] = useState(null);
  const [totalGuest, setTotalGuest] = useState(null);
  const [company, setCompany] = useState(null);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);
  // Boolean
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(openModal);
    }

    if (dataGuest) {
      setData(dataGuest);

      fetchGuest();
      fetchPrint();

      if (dataGuest?.group === 1) {
        fetchReservation();
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest]);

  // USE EFFECT TOTAL ADULTS
  useEffect(() => {
    let cleanUp = false;

    if (totalAdult != null && !cleanUp) {
      let total = totalAdult.reduce((accumulator, initValues) => {
        // console.log("ACC ", accumulator);
        // console.log("Init ", initValues);

        return accumulator + parseInt(initValues.adult);
      }, 0);

      // console.log("Total Adults >> ", total);
      console.log("Total Adults >> ", totalAdult);
      setTotalGuest(total);
    }

    return () => {
      cleanUp = true;
    };
  }, [totalAdult]);

  // GET GUEST DATA
  const fetchGuest = async () => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        // console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let findGuest = _res.filter((value) => {
            if (value?.id == dataGuest?.guest_id) {
              return value;
            }
          });

          console.log("The Guest >>> ", findGuest);

          setGuest(findGuest.length > 0 ? findGuest[0] : []);

          if (findGuest[0]?.master_comp_id > 0) {
            fetchCompany(findGuest[0]);
          }
        } else {
          setGuest(null);
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setIsLoading(false);
      },
    });
  };

  // GET RESERVATION
  const fetchReservation = async () => {
    await getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          let findAdult = _response.filter((value) => {
            if (value?.reservation_id == dataGuest?.reservation_id) {
              return value;
            }
          });

          // console.log("Adults >>> ", findAdult);
          setTotalAdult(findAdult);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
      },
    });
  };

  // Fetch Company
  const fetchCompany = async (comp) => {
    const comp_id = comp?.master_comp_id;

    await getGuest({
      guest: "guest",
      type: "company-and-ota",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Company => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          let filtered = _response.filter((val) => {
            if (val?.id === comp_id) {
              return val;
            }
          });

          console.log("Companies: ", filtered);

          setCompany(filtered?.length > 0 ? filtered[0] : null);
        } else {
          setCompany(null);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
      },
    });
  };

  // FETCH PRINT FORM
  const fetchPrint = async () => {
    // third;
    await getMasterPrint({
      reservation_id: dataGuest?.reservation_id || data?.reservation_id,
      price_id: dataGuest?.price_id > 0 ? dataGuest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg[0];
          setCountPrint(_res);
        } else {
          PrintCount({
            data: dataGuest || data,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    // Modal
    setOpen(false);
    closeModal(false);

    // Data
    setData(null);
    setGuest(null);

    form.resetFields();
    // setEdit(null);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    PrintCount({
      data: data,
      is_update: true,
      counter_list: {
        guarantee_letter: countPrint?.guarantee_letter + 1,
        registration_form: countPrint?.registration_form,
        pre_bill: countPrint?.pre_bill,
        thankyou_letter: countPrint?.thankyou_letter,
        checkout_letter_history: countPrint?.checkout_letter_history,
        bill: countPrint?.bill,
        master_bill: countPrint?.master_bill,
        pre_master_bill: countPrint?.pre_master_bill,
        reservation_letter: countPrint?.reservation_letter,
      },
    });

    handlePrintOut();
  };

  // HANDLE PRINT OUT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Guarantee Letter (${
      data?.reservation_id ? data.reservation_id : dataGuest.reservation_id
    })`,
    onafterprint: () => alert("Print Success"),
  });

  // CONSOLE LOG
  // console.log("DATA MODAL > ", submitDataResvForm);
  // console.log("Data Modal Guarantee >>> ", data);
  // console.log("Data Guest >>> ", guest);

  return (
    <>
      <Modal
        className="guarantee-letter-modal"
        name="guarantee-letter-modal"
        key="guarantee-letter-modal"
        title={
          <>
            <Row>
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Guarantee Letter`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="guarantee-letter-modal"
          className="guarantee-letter-modal"
          key="guarantee-letter-modal"
          form={form}
          labelCol={{ span: 8 }}
          labelWrap={{ span: 16 }}
          labelAlign="left"
          // labelCol={{
          //   span: 8,
          // }}
          // wrapperCol={{
          //   span: 16,
          // }}
          // initialValues={{
          //   guest_name: dataGuest?.guest_name,
          //   adult: dataGuest?.adult,
          //   arrival: moment(dataGuest?.arrival).format("DD MMM YYYY"),
          //   departure: moment(dataGuest?.departure).format("DD MMM YYYY"),
          // }}
          autoComplete="off"
          layout="horizontal"
          style={{
            maxWidth: 1000,
          }}
        >
          {!isLoading ? (
            <Content
              className="content-change-guarantee-letter"
              ref={componentRef}
              style={{
                //   border: "solid 1px",
                padding: "30px 80px 30px 80px",
              }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Guarantee Letter`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "grey" }}
              />

              <Row gutter={30} className="row-heading-address">
                <Col span={24} className="col-heading">
                  <Row
                    // align="middle"
                    style={{ padding: 5 }}
                    className="row-heading-to"
                  >
                    <Typography
                      className="text-heading-to"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        marginRight: 30,
                      }}
                    >
                      To:{" "}
                    </Typography>

                    <Typography
                      className="text-heading-to-address"
                      style={{
                        fontWeight: 500,
                        padding: "5px 0px 0px",
                      }}
                    >
                      {`Idea's Hotel Bandung`}
                    </Typography>
                  </Row>

                  <Row
                    // align="middle"
                    className="row-heading-about"
                    style={{ padding: 5 }}
                  >
                    <Typography
                      className="text-heading-re"
                      level={4}
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                        marginRight: 30,
                      }}
                    >
                      Re:
                    </Typography>

                    <Typography
                      className="text-heading-re-address"
                      style={{
                        fontWeight: 500,
                        padding: "5px 0px 0px",
                      }}
                    >
                      Room Reservation
                    </Typography>
                  </Row>
                </Col>
              </Row>

              <Row
                className="row-greetings"
                style={{
                  margin: "30px 0px 0px",
                  width: "100%",
                  backgroundImage:
                    countPrint?.guarantee_letter >= 1
                      ? "url(/assets/logos/copyopac.png)"
                      : null,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  backgroundPosition: "center",
                }}
              >
                <Col span={24} className="col-greetings">
                  <Typography className="text-greetings">
                    We would like to Inform you that our bellow guest will stay
                    in Idea's Hotel Bandung:
                  </Typography>

                  <Row
                    span={24}
                    className="row-guest-details"
                    style={{ height: 30 }}
                  >
                    <Form.Item
                      label="Guest Name"
                      // name="guest_name"
                      style={{ width: "100%", marginRight: 30 }}
                    >
                      <Typography
                        className="text-guest-name"
                        style={{
                          fontSize: 20,
                          fontWeight: 750,
                        }}
                      >
                        {data?.guest_name ? data.guest_name : ""}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    span={24}
                    className="row-guest-total"
                    style={{ height: 30 }}
                  >
                    <Form.Item
                      label="Number of Person(s)"
                      // name="adult"
                      style={{ width: "100%", marginRight: 30 }}
                    >
                      <Typography
                        className="text-guest-total"
                        style={{
                          fontSize: 20,
                          fontWeight: 750,
                        }}
                      >
                        Adult(s): {totalGuest ? totalGuest : ""}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row span={24} style={{ height: 30 }}>
                    <Form.Item
                      label="Phone"
                      name="phone"
                      style={{ width: "100%", marginRight: 30 }}
                    >
                      <Typography
                        className="text-guest-phone"
                        style={{
                          fontSize: 20,
                          fontWeight: 750,
                        }}
                      >
                        {`(+) ${guest?.phone_number ? guest.phone_number : ""}`}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row span={24} style={{ height: 30 }}>
                    <Form.Item
                      label="Arrival Date"
                      // name="arrival"
                      style={{ width: "100%", marginRight: 30 }}
                    >
                      <Typography
                        className="text-guest-arrival"
                        style={{
                          fontSize: 20,
                          fontWeight: 750,
                        }}
                      >
                        {data?.arrival
                          ? moment(data.arrival).format("DD-MMM-YYYY")
                          : ""}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row span={24} style={{ height: 30 }}>
                    <Form.Item
                      label="Departure Date"
                      // name="departure"
                      style={{ width: "100%", marginRight: 30 }}
                    >
                      <Typography
                        className="text-guest-departure"
                        style={{
                          fontSize: 20,
                          fontWeight: 750,
                        }}
                      >
                        {data?.departure
                          ? moment(data.departure).format("DD-MMM-YYYY")
                          : ""}
                      </Typography>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>

              <Row className="row-body-letter" style={{ margin: "20px 0px" }}>
                <Typography className="text-body-letter">
                  Please accept by way of this letter, our confirmation that we
                  will effect payment of cost incurred by the above named guest
                  upon
                </Typography>
              </Row>

              <Row className="row-body-letter-check">
                <Typography className="text-body-letter">
                  Please, note that charge by our company only: ( V )
                </Typography>
              </Row>

              <Checkbox.Group
                className="checkbox-group-list"
                // disabled
                value={false}
                style={{
                  width: "100%",
                  padding: 10,
                  fontSize: 18,
                }}
              >
                <Row
                  className="row-body-checkbox-list"
                  style={{ margin: "0px 0px 30px", width: "100%" }}
                >
                  <Col span={12} className="1st-row-1st-col">
                    <Checkbox checked={false} className="sup_room">
                      Superior Room
                    </Checkbox>
                  </Col>

                  <Col span={12} className="1st-row-2nd-col">
                    <Checkbox checked={false} className="dlx_room">
                      Deluxe Room
                    </Checkbox>
                  </Col>

                  <Col span={12} className="2nd-row-1st-col">
                    <Checkbox checked={false} className="breakfast">
                      Breakfast
                    </Checkbox>
                  </Col>

                  <Col span={12} className="2nd-row-2nd-col">
                    <Checkbox checked={false} className="lunch">
                      Lunch
                    </Checkbox>
                  </Col>

                  <Col span={12} className="3rd-row-1st-col">
                    <Checkbox checked={false} className="dinner">
                      Dinner
                    </Checkbox>
                  </Col>

                  <Col span={12} className="3rd-row-2nd-col">
                    <Checkbox checked={false} className="call">
                      Local Call
                    </Checkbox>
                  </Col>

                  <Col span={12} className="4th-row-1st-col">
                    <Checkbox checked={false} className="int_call">
                      International Call
                    </Checkbox>
                  </Col>

                  <Col span={12} className="4th-row-2nd-col">
                    <Checkbox checked={false} className="minibar">
                      Minibar
                    </Checkbox>
                  </Col>

                  <Col span={12} className="5th-row-1st-col">
                    <Checkbox checked={false} className="room_service">
                      Room Service/Add Meal
                    </Checkbox>
                  </Col>

                  <Col span={12} className="5th-row-2nd-col">
                    <Checkbox checked={false} className="laundry">
                      Laundry
                    </Checkbox>
                  </Col>

                  <Col span={12} className="6th-row-1st-col">
                    <Checkbox checked={false} className="internet">
                      Internet Connection
                    </Checkbox>
                  </Col>

                  <Col span={12} className="6th-row-2nd-col">
                    <Checkbox checked={false} className="trans_service">
                      Transportation Service
                    </Checkbox>
                  </Col>

                  <Col span={12} className="7th-row-1st-col">
                    <Checkbox checked={false} className="others">
                      Others (any item not yet item above)
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>

              <Row
                justify="center"
                style={{ width: "100%", margin: "75px 0px 0px" }}
                className="row-sign"
              >
                <Col
                  className="col-sign"
                  span={12}
                  style={{
                    fontSize: 15,
                    fontWeight: 750,
                  }}
                >
                  <Row className="row-sign" justify="center">
                    <Typography className="text-sign">
                      Yours faithfully,
                    </Typography>
                  </Row>

                  <Row
                    className="row-sign-by"
                    justify="center"
                    style={{
                      margin: "90px 0px 10px",
                      textDecoration: "underline dotted",
                    }}
                  >
                    <Typography>{`( ${param_fo.registration_sign_by} )`}</Typography>
                  </Row>
                </Col>

                <Col
                  span={12}
                  className="col-address"
                  style={{
                    border: "solid 1px",
                    padding: 5,
                  }}
                >
                  <Row justify="center" className="row-bill-instruction">
                    <Typography className="text-bill-instruction">
                      {`Billing Instruction`}
                    </Typography>
                  </Row>

                  <Row
                    className="row-company"
                    // justify="space-around"
                    style={{ height: 20 }}
                  >
                    <Typography
                      className="text-company-name"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Company Name : ${company?.company_name || "-"}`}
                    </Typography>
                  </Row>

                  <Row className="row-phone" style={{ height: 20 }}>
                    <Typography
                      className="text-phone"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Phone : ${
                        company?.company_phone ? company.company_phone : "-"
                      }`}
                    </Typography>
                  </Row>

                  <Row className="row-contact" style={{ height: 20 }}>
                    <Typography
                      className="text-contact-person"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Contact Person :
                      ${
                        company?.contact_person
                          ? company.contact_person + " - " + company?.cp_number
                          : "-"
                      }`}
                    </Typography>
                  </Row>

                  <Row
                    className="row-address"
                    // style={{ height: 20 }}
                  >
                    <Typography
                      className="text-address"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Address : ${company?.address ? company.address : "-"}`}
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              icon={<PrinterOutlined />}
              onClick={handlePrint}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Download & Print`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

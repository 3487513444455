// Import React Components
import React, { useEffect, useState } from "react";

// Import Antd Components
import { Avatar, List, Skeleton } from "antd";

// CODE
export default function InputSkeleton({ is_loading }) {
  // STATE MANAGEMENT
  // Loading
  const [loading, setLoading] = useState(false);

  // USE EFFECT
  useEffect(() => {
    if (is_loading == true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [is_loading]);

  return (
    <>
      <Skeleton.Input
        className="skeleton"
        paragraph
        block
        active={true}
        loading={loading}
        style={{
          margin: "15px 0px",
        }}
      />
      <Skeleton.Input
        className="skeleton"
        paragraph
        block
        active={true}
        loading={loading}
        style={{
          margin: "15px 0px",
        }}
      />
      <Skeleton.Input
        className="skeleton"
        paragraph
        block
        active={true}
        loading={loading}
        style={{
          margin: "15px 0px",
        }}
      />
      <Skeleton.Input
        className="skeleton"
        paragraph
        block
        active={true}
        loading={loading}
        style={{
          margin: "15px 0px",
        }}
      />
      <Skeleton.Input
        className="skeleton"
        paragraph
        block
        active={true}
        loading={loading}
        style={{
          margin: "15px 0px",
        }}
      />
      <Skeleton.Input
        className="skeleton"
        paragraph
        block
        active={true}
        loading={loading}
        style={{
          margin: "15px 0px",
        }}
      />
    </>
  );
}

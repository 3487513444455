// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

// Import React Icons
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  masterSuccessNotification,
  masterFailedNotification
} from "../../../Reusable/Notification/MasterNotif/Notification";

export default function CaptainModalCancelOrder(props) {
  // PROPS
  const { openModal, closeModal, deleteItem, isHeader, is_refresh } = props;

  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();
  const { Text } = Typography;

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = async (e) => {
    const _data = e;
    console.log("ABOUT TO DELETE ", _data);
    console.log("deleteItem", deleteItem);

    if (isHeader) {
      let obj =
        {
          order_id_alias: deleteItem?.order_id_alias,
          deleted_by: user_name,
          deleted_reason: e?.deleted_reason,
        };
      await axios
        .delete(`${baseurl}/pos/order-header`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("DEL POS Bill Header >> ", response);
            masterSuccessNotification(response, {
              method: 2,
              source: `Captain Order`,
            });
            is_refresh(true);
            handleCancel();
          }
        })
        .catch((error) => {
          console.log("ERROR DEL POS Bill Header >> ", error);
          masterFailedNotification(error, {
            method: 2,
            source: `Captain Order`,
          });
        });
    } else {
      let obj =
        {
          id: deleteItem?.id,
          order_id_alias: deleteItem?.order_id_alias,
          deleted_by: user_name,
          deleted_reason: e?.deleted_reason,
        };
      
      console.log("obj", obj)
      await axios
        .delete(`${baseurl}/pos/order-detail`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("DEL POS Bill Detail >> ", response);

            masterSuccessNotification(response, {
              method: 2,
              source: `Captain Order Item`,
            });
            // fetchTable();
            is_refresh(true);
            handleCancel();
          }
        })
        .catch((error) => {
          console.log("ERROR DEL POS Bill Detail >> ", error);
          masterFailedNotification(error, {
            method: 2,
            source: `Captain Order Item`,
          });
        });
    }
  };

  const onFinishFailed = (e) => {};

  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={
          <>
            <Row className="cancel-order-modal-row">
              <AiOutlineInfoCircle
                style={{
                  color: "#f5222d",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Cancel/Delete ${isHeader ? "Order" : "Article"}`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={500}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={
            {
              // deleted_reason: "CANCEL ORDER",
            }
          }
          style={{ padding: "0px 36px" }}
        >
          <Row
            className="cancel-order-modal-form-serve-row"
            //   justify="center"
            //   gutter={[30, 30]}
            style={{ margin: "0px 40px 10px 0px" }}
          >
            <Text>{`Are you sure want to cancel/delete this ${isHeader ? "order" : "article"}?`}</Text>
          </Row>

          <Row
            className="serve-row"
            justify="center"
            style={{ margin: "0px 40px" }}
          >
            <Col span={24} className="serve-col">
              <Form.Item
                label="Delete Reason"
                name={"deleted_reason"}
                rules={[
                  {
                    required: true,
                    message: "Please, Select an Option!",
                  },
                ]}
              >
                <Select
                  //   defaultValue="CANCEL ORDER"
                  placeholder={"Select Option"}
                  //   style={{
                  //     width: 120,
                  //   }}
                  onChange={() => {}}
                  options={[
                    {
                      value: "CANCEL ORDER",
                      label: "CANCEL ORDER",
                    },
                    {
                      value: "WRONG POSTING",
                      label: "WRONG POSTING",
                    },
                    {
                      value: "WRONG QUANTITY",
                      label: "WRONG QUANTITY",
                    },
                    {
                      value: "OTHERS",
                      label: "OTHERS",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="cancel-order-modal-form-divider-form"
            style={{ margin: "0px 0px 10px 0px" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              onClick={(e) => {
                // selectedTable(tableKeys);
                // handleCancel();
              }}
              loading={loading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Yes`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`No`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

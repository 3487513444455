// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  PrinterFilled,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// MODAL
const { confirm } = Modal;

// CODE
export default function HistoryNonstayTable({
  searchKey,
  is_search,
  searchDate,
}) {
  // CONTEXT
  const { getHistoryNonStay } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [bulks, setBulks] = useState([]);
  const [data, setData] = useState([]);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  // Date
  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  //const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Table Columns
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   width: 30,
    //   render: (_, record) => {
    //     return (
    //       <>
    //         <Row
    //           className="action-history-nonstay-table-btn"
    //           style={{ width: "100%" }}
    //         >
    //           <Button
    //             type="primary"
    //             onClick={() => {
    //               handleMove(record);
    //               console.log(record);
    //             }}
    //             style={{
    //               backgroundColor: "#1BC5BD",
    //               borderColor: "#1BC5BD",
    //               marginRight: 15,
    //             }}
    //           >
    //             <ArrowLeftOutlined />
    //             Move
    //           </Button>
    //         </Row>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Reservation No",
      dataIndex: "reservation_id",
      // fixed: "left",
      width: 145,
      key: "reservation_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // fixed: "left",
      width: 200,
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (arrival, record) => {
        return moment(arrival).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.departure) - moment(b.departure),
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
      filters: [
        {
          text: "DELUXE",
          value: "DELUXE",
        },
        {
          text: "STANDARD",
          value: "STANDARD",
        },
        {
          text: "SUPERIOR",
          value: "SUPERIOR",
        },
        {
          text: "SUITE",
          value: "SUITE",
        },
        {
          text: "EXECUTIVE",
          value: "EXECUTIVE",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.room_category === value;
      },
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.roomno - b.roomno,
    },
    {
      title: "Nonstay Count",
      dataIndex: "nonstay_count",
      key: "nonstay_count",
    },
    {
      title: "Nonstay Date",
      dataIndex: "nonstay_date",
      key: "nonstay_date",
      render: (nonstay, record) => {
        // const date = new Date(nonstay);

        return moment(LocalizationDate(nonstay)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
    },
    {
      title: "Nonstay By",
      dataIndex: "nonstay_by",
      key: "nonstay_by",
    },
  ];

  // USE EFFECT FETCH
  useEffect(() => {
    fetchData();
  }, []);

  // EFFECT SEARCH
  useEffect(() => {
    let cleanUp = false;

    if (searchDate?.length > 0 && !cleanUp) {
      console.log("Date Range: ", searchDate);

      setDateRange({
        start_date: moment(searchDate[0]).format("YYYY-MM-DD"),
        end_date: moment(searchDate[1]).format("YYYY-MM-DD"),
      });

      handleFilter(searchDate);
    }

    is_search(() => handleSearch);

    return () => {
      cleanUp = true;
    };
  }, [searchKey, searchDate]);

  // Fetch Data
  const fetchData = async () => {
    await getHistoryNonStay({
      res_type: "reservation",
      nonstay: "nonstay-history",
      onAwait: () => {
        "on Await";
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
          setBulks(_res);
        } else {
          setData([]);
          setBulks([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        setLoading(false);
        failedFetch(error);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log(e);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
  };

  const handleExport = () => {
    // console.log("ON OK ==> ", e);

    downloadExcel(
      data,
      `History Non-Stay List ${
        dateRange.start_date + " - " + dateRange.end_date
      }`
    );
  };

  // HANDLE SEARCH
  const handleSearch = async () => {
    const queryName = searchKey ? searchKey.toLowerCase() : null;

    let _data = data;

    if (_data.length > 0) {
      let updatedList = _data.filter((item) => {
        // console.log("ITEM => ", item)
        const name = item?.guest_name ? item?.guest_name.toLowerCase() : "";

        return name.indexOf(queryName) !== -1;
      });

      // console.log("FILTERED => ", updatedList);
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Filter
  const handleFilter = (value) => {
    const _start = moment(value[0]).format("YYYY-MM-DD");
    const _end = moment(value[1]).format("YYYY-MM-DD");

    if (bulks.length > 0) {
      let _filtered = bulks.filter((val) => {
        const _nonstay = moment(val.nonstay_date).format("YYYY-MM-DD");

        return moment(_nonstay).isBetween(_start, _end, undefined, "[]");
      });

      console.log("Filter Date: ", _filtered);
      setData(_filtered);
    } else {
      setData([]);
    }
  };

  // console.log(dateRange);

  return (
    <>
      <Row
        className="history-nonstay-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-history-nonstay" style={{ padding: 30 }}>
          <Row
            className="row-modal-btn"
            justify="start"
            align="middle"
            gutter={[0, 30]}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="exportcol-btn"
            >
              <Button
                className="print-btn"
                type="primary"
                icon={<PrinterFilled />}
                onClick={handleExport}
              >
                {`Export Excel`}
              </Button>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="refreshcol-btn"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className="refresh-btn"
                type="default"
                icon={<SyncOutlined />}
                onClick={fetchData}
              >
                {`Refresh`}
              </Button>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="history-nonstay-table"
              key="history-nonstay-table"
              name="history-nonstay-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              //onChange={onChange}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                // y: 270,
                x: 2000,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// REACT COMPONENTS
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

// ANTD COMPONENTS
import { FormOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { FiEdit } from "react-icons/fi";

// GLOBAL STYLES
import { textForm } from "../../../Style/Global/Global";

// GLOBAL API
import { user_name } from "../../../API/Global/Payload";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";

// PAGE COMPONENTS

// CODE
export default function StockTransferRequestForm(props) {
  // PROPS
  const { openModal, closeModal, dataPrint } = props;

  // USE REF
  const componentRef = useRef();

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  // Data
  const [data, setData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  // Loading
  const [isLoading, setIsLoading] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(openModal);
    }

    if (dataPrint && !cleanUp) {
      console.log("Data Print: ", dataPrint);
      setData(dataPrint);
      setDataSource(dataPrint.details);

      handleLoading();
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataPrint]);

  // HANDLE CANCEL
  const handleCancel = () => {
    closeModal(false);
    setOpen(false);

    setData(null);
    setDataSource([]);

    setIsLoading(true);

    form.resetFields();
  };

  // Handle Loading
  const handleLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1250);
  };

  // HANDLE REACT PRINT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Stock Request Form ${data?.document_number}`,
    onAfterPrint: () => alert("Print Success"),
  });

  const onFinish = () => {};
  const onFinishFailed = () => {};

  // Table Columns
  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      render: (_, record) => dataSource.indexOf(record) + 1,
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Qty Req",
      dataIndex: "quantity_requested",
      key: "quantity_requested",
      width: 75,
      render: (quantity_requested, record) => {
        return `${quantity_requested > 0 ? quantity_requested : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Qty Approved",
      dataIndex: "quantity_approved",
      key: "quantity_approved",
      width: 75,
      render: (quantity_approved, record) => {
        return quantity_approved !== null
          ? `${quantity_approved > 0 ? quantity_approved : 0}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )
          : "-";
      },
    },
    {
      title: "Qty Received",
      dataIndex: "quantity_received",
      key: "quantity_received",
      width: 75,
      render: (quantity_received, record) => {
        return quantity_received !== null
          ? `${quantity_received > 0 ? quantity_received : 0}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )
          : "-";
      },
    },
    {
      title: `Total Amount ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      render: (total_price, record) => {
        return `${total_price > 0 ? total_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      <Modal
        className="res-form-modal"
        name="res-form-modal"
        key="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <FiEdit style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography
                className="text-modal-title"
                style={{ marginLeft: 15 }}
              >
                {`Form Stock Request`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="article-form"
          key="article-form"
          className="article-form"
          form={form}
          labelCol={{ span: 10 }}
          labelWrap={{ span: 14 }}
          labelAlign="left"
          autoComplete="off"
          layout="horizontal"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{
            padding: 30,
          }}
        >
          {!isLoading ? (
            <Content className="content-stock-request-form" ref={componentRef}>
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Stock Request`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    src="/looklogo(color).png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Look Hotel Bandung`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                gutter={30}
                className="row-stock-request"
                style={{ width: "100%", fontWeight: 500 }}
              >
                <Col span={12} className="col-stock-request-details">
                  <Row
                    style={{ width: "100%", height: 22 }}
                    className="row-document-number"
                  >
                    <Form.Item
                      label="Document Number"
                      className="document-number"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Typography
                        className="Document-number-text"
                        style={textForm}
                      >
                        {data?.document_number ? data.document_number : "-"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-department-name"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Department Name"
                      className="department-name"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        className="department-name-text"
                        style={textForm}
                      >
                        {data?.department_name ? data.department_name : "-"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-type"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Type of Moving"
                      className="transfer-type"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        className="transfer-type-text"
                        style={textForm}
                      >
                        {data?.transfer_type ? data.transfer_type : "-"}
                      </Typography>
                    </Form.Item>
                  </Row>
                  <Row
                    className="row-posting-date"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Posting Date"
                      className="posting-date"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        className="posting-date-text"
                        style={textForm}
                      >
                        {data?.posting_date ? data.posting_date : "-"}
                      </Typography>
                    </Form.Item>
                  </Row>
                </Col>

                <Col span={12} className="col-request-details">
                  <Row style={{ width: "100%", height: 22 }}>
                    <Form.Item
                      label="Initial Warehouse"
                      className="initial-warehouse"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        className="initial-warehouse-text"
                        style={textForm}
                      >
                        {data?.initial_warehouse ? data.initial_warehouse : "-"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row style={{ width: "100%", height: 22 }}>
                    <Form.Item
                      label="Destination Warehouse"
                      className="destination-warehouse"
                      style={{ width: "100%" }}
                    >
                      <Typography
                        className="destination-warehouse-text"
                        style={textForm}
                      >
                        {data?.destination_warehouse
                          ? data.destination_warehouse
                          : "-"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-description"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Description"
                      className="description"
                      style={{ width: "100%" }}
                    >
                      <Typography className="description-text" style={textForm}>
                        {data?.description ? data.description : "-"}
                      </Typography>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-received"
                    style={{ width: "100%", height: 22 }}
                  >
                    <Form.Item
                      label="Received By"
                      className="received"
                      style={{ width: "100%" }}
                    >
                      <Typography className="received-text" style={textForm}>
                        {data?.is_received ? data.received_by : "Pending"}
                      </Typography>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row className="row-title-remarks">
                <Typography
                  className="text-title-remarks"
                  style={{
                    fontSize: 20,
                    fontWeight: 750,
                    margin: "0px 0px 10px",
                  }}
                >
                  Article List
                </Typography>
              </Row>

              <Row
                className="row-remarks"
                style={{
                  width: "100%",
                }}
              >
                <Col
                  span={24}
                  className="col-remarks"
                  style={{
                    width: "100%",
                  }}
                >
                  <Table
                    className="pr-detail-table"
                    name="pr-detail-table"
                    key="pr-detail-table"
                    loading={isLoading}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    size="small"
                    bordered
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "odd" : "even";
                    }}
                    rowKey={(record) => {
                      return record.article_number
                        ? record.article_number
                        : record.key;
                    }}
                  />
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row className="row-by" style={{ width: "100%", height: 25 }}>
                <Form.Item
                  className="printed-by"
                  label="Printed by"
                  labelCol={{ span: 5 }}
                  style={{ width: "100%", height: 25 }}
                >
                  <Typography className="text-roles" style={textForm}>
                    {user_name ? user_name : "unknown"}
                  </Typography>
                </Form.Item>
              </Row>

              <Row className="row-by" style={{ width: "100%", height: 25 }}>
                <Form.Item
                  label="Printed Date"
                  className="date_time"
                  labelCol={{ span: 5 }}
                  style={{ width: "100%", margin: 0 }}
                >
                  <Typography className="text-date" style={textForm}>
                    {moment().format("DD-MM-YYYY HH:MM:ss")}
                  </Typography>
                </Form.Item>
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            className="row-res-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 0px" }}
          >
            <Divider
              className="divider-form"
              style={{ margin: "15px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Button
              className="print-btn"
              icon={<PrinterOutlined />}
              type="primary"
              onClick={handlePrintOut}
              style={{
                marginRight: 30,
              }}
            >
              {`Save & Download`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

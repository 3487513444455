// Import React's Components
import React, { useContext, useState, useEffect } from "react";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Antd Components
import { Select } from "antd";

// CODE
export default function WarehouseList({ getWarehouseId, wh, expenseType }) {
  // CONTEXT
  const { getWarehouseList } = useContext(MainContext);

  // Data
  const [warehouse, setWarehouse] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = async () => {
    await getWarehouseList({
      type: "warehouse",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Warehouse => ", response);

        if (response.data.msg?.length > 0) {
          let _res = response.data.msg;
          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.id_warehouse,
            label: `${dev.warehouse_name}`,
            ...dev,
          }));

          console.log(option);
          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    console.log("TEST WH >>>");
  }, []);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Warehouse ", wh);

    if (wh && !cleanUp) {
      let x = optionValue.filter((e) => {
        if (e?.id_warehouse == wh) {
          return e;
        }
      });
      console.log("TEST ARR >>>", x);
      setWarehouse(x);
      if (optionValue.length > 0 && x.length > 0) {
        getWarehouseId(x[0]?.id_warehouse, x[0]);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [wh, optionValue]);

  // useEffect(() => {
  //   let cleanUp = false;

  //   if (wh && !cleanUp) {
  //     let x = optionValue.filter((e) => {
  //       if (e?.id_warehouse == wh) {
  //         return e;
  //       }
  //     });
  //     console.log("TEST ARR >>>", x);
  //     setWarehouse(x);
  //     if (optionValue.length > 0 && x.length > 0) {
  //       getWarehouseId(x[0]?.id_warehouse, x[0]);
  //     }
  //   }
  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [optionValue]);

  // Handle Change
  const handleChange = (val, allKey) => {
    const _value = val;
    const _allKey = allKey;

    console.log(_value, _allKey);

    setWarehouse(_value);
    getWarehouseId(val, allKey);
  };

  // Handle Clear
  const handleClear = () => {
    setWarehouse(null);
  };

  console.log("TEST SELECTOR WH");

  return (
    <>
      <Select
        className="warehouse-selector"
        key="warehouse-selector"
        name="warehouse-selector"
        allowClear
        showSearch
        value={warehouse}
        // fieldNames={{
        //   label: "name",
        //   value: "name",
        //   code: "financial_statement_id",
        // }}
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        disabled={expenseType == true ? true : false}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
      />
    </>
  );
}

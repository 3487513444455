// Import React's Components
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { baseurl } from "../../../../API/Config/Api";

// REDUX
import { connect } from "react-redux";

// Import's Auth Components
import * as auth from "../../../../API/AuthActions/authAction";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
} from "antd";
import { FiEdit } from "react-icons/fi";
import { SyncOutlined } from "@ant-design/icons";

// Import Master Components
import ModuleTabs from "../../../Tabs/Modules/ModuleTab";
import MasterDepartment from "../../../Reusable/Master/Department/Department";
import MasterRoles from "../../../Reusable/Auth/Roles/MasterRoles";
import MasterApproval from "../../../Reusable/Auth/Approval/ApprovalLevel";
import MasterWarehouseAdmin from "../../../Reusable/Inventory/Warehouse/WarehouseAdmin";
import WarehouseList from "../../../Reusable/Inventory/Warehouse/WarehouseList";
import MasterModules from "../../../Reusable/Auth/Modules/MasterModules";
import MasterSubModules from "../../../Reusable/Auth/SubModules/MasterSubModules";
import CascaderModule from "../../../Cascader/Modules/Cascader";
import MasterAccessPermit from "../../../Reusable/Auth/Access/MasterAccess";
import InputSkeleton from "../../../Reusable/Skeleton/InputSkeleton";

// Import Notifications
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CONFIRM
const { confirm } = Modal;

// Auth Dispatch
const mapDispatchToProps = (dispatch) => {
  // console.log("Dispatch Update --> ", dispatch);

  return {
    disUpdateByAdmin: (values) => dispatch(auth.updateByAdmin(values)),
    disReset: (val) => dispatch(auth.resetRegister(val)),
  };
};

// Maps to Props
const mapStateToProps = (state) => {
  let response = state;
  let updateByAdmin = response?.rootReducer?.auth;

  // console.log("Return to Parents: ", updateByAdmin);

  return { updateByAdmin };
};

// CODE
const ModalAccessControl = (props) => {
  // PROPS
  const { is_open, is_close, userData, is_refresh } = props;

  // STATE MANAGEMENT
  // Data
  const [dataUser, setDataUser] = useState(null);
  const [newData, setNewData] = useState(null);
  const [dept, setDept] = useState({
    name: "",
    code: "",
    dept_id: 0,
    dept_uuid: "",
  });
  // Roles
  const [roleLevel, setRoleLevel] = useState(0);
  // BOOL
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isAdminStore, setIsAdminStore] = useState(false);
  // IS UPDATE
  const [isUpdate, setIsUpdate] = useState(0);
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      setLoading(true);
      setOpen(true);
      setIsUpdate(1);
    } else {
      setOpen(false);
      setIsUpdate(0);
    }

    if (userData && !cleanUp) {
      console.log("Data Users: ", userData);

      setDataUser(userData);
      handleFormField(userData);
    } else {
      setDataUser(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, userData]);

  // AUTH UPDATE BY ADMIN
  useEffect(() => {
    let cleanUp = false;

    if (
      props?.updateByAdmin?.is_admin_update === true &&
      isUpdate == 1 &&
      !cleanUp
    ) {
      props.disReset(true);
      console.log("AUTH! Update Success: ", props?.updateByAdmin);

      // if (isUpdate == 1) {
      handleClear();
      setIsUpdate(isUpdate + 1);
    } else {
      setIsLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [props?.updateByAdmin?.is_admin_update]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (newData && !cleanUp) {
      // console.log("New Data: ", userData);
      handleUpdate(newData);
    }

    return () => {
      cleanUp = true;
    };
  }, [newData]);

  // Handle Form Field
  const handleFormField = (value) => {
    form.resetFields();

    const _record = value;
    const _metadata = value?.user_metadata;

    setRoleLevel(_metadata?.user_level);
    setIsApprove(_metadata?.user_level > 0 ? true : false);
    setIsAdminStore(_metadata?.is_warehouse_admin ? true : false);

    form.setFieldsValue({
      user_name: _metadata?.user_name,
      uid: _record?.id,
      email: _record?.email,
      approval_level: _metadata?.approval_level,
      department: _metadata?.department,
      dept_id: _metadata?.dept_id,
      dept_uuid: _metadata?.dept_uuid,
      hotel_id: _metadata?.hotel_id,
      module: _metadata?.module?.length > 0 ? _metadata?.module : [],
      sub_module:
        _metadata?.sub_module?.length > 0 ? _metadata?.sub_module : [],
      roles: _metadata?.roles,
      is_approve: _metadata?.user_level > 0 ? true : false,
      user_level: _metadata?.user_level > 0 ? _metadata.user_level : 0,
      is_warehouse_admin: _metadata?.is_warehouse_admin ? true : false,
      id_warehouse:
        _metadata?.id_warehouse?.length > 0 ? _metadata.id_warehouse : [],

      confirmed_at: _record?.confirmed_at
        ? moment(_record?.confirmed_at).format("HH:mm:ss, DD-MM-YYYY")
        : null,
      created_date: _record?.created_at
        ? moment(_record?.created_at).format("HH:mm:ss, DD-MM-YYYY")
        : null,
    });

    setDept({
      name: _metadata?.department,
      dept_id: _metadata?.dept_id,
      dept_uuid: _metadata?.dept_uuid,
    });

    setRoleLevel(_metadata?.user_level);
  };

  // ON FINISH
  const onFinish = (val) => {
    const submit = val;

    console.log("Submit: ", submit);

    setIsLoading(true);
    handleSubmit(submit);
    // showModalConfirm(submit);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // GET Department
  const getDepartment = (val) => {
    const _value = val;
    let _dept = _value?.name?.toLowerCase().includes("admin")
      ? "ALL"
      : _value?.name;

    console.log("Department: ", _value);
    console.log("Department IS ADMIN: ", _dept);
    setDept(_value);

    form.setFieldsValue({
      department: _dept,
      dept_id: _value?.dept_id,
      dept_uuid: _value?.dept_uuid,
    });
  };

  // Get Roles
  const getRole = (value) => {
    const _value = value;

    console.log("Get Roles: ", _value);

    setRoleLevel(_value?.level);

    form.setFieldsValue({
      roles: _value?.role,
      approval_level: null,
      user_level: _value?.level,
    });
  };

  // Get Approval
  const getApproval = (value) => {
    const _value = value;

    console.log("Level: ", _value);

    form.setFieldsValue({
      approval_level: _value?.level,
    });
  };

  // Handle Check
  const handleCheck = (e) => {
    const _check = e.target.checked;
    // console.log(e);

    setIsApprove(_check);
  };

  // Handle Check
  const handleWareHouse = (e) => {
    const _check = e.target.checked;
    // console.log(e);

    setIsAdminStore(_check);
  };

  // Handle Warehouse
  const getWarehouseId = (val) => {
    const _check = val;
    console.log("Warehouse: ", _check);

    form.setFieldsValue({
      id_warehouse: _check,
    });
  };

  // Get All Module
  const getAllModule = async (value) => {
    // console.log("Time Map: ", moment().format("HH:mm:ss. SSS, DD-MM-YYYY"));

    const _value = value;
    console.log("Fields from Tabs: ", _value);
    console.log("Data Users: ", dataUser);

    // console.log("Obj assigned: ", _assigned);
    // console.log("Obj mapped: ", _map);

    if (open == true && dataUser) {
      setTimeout(() => {
        handleFormModule(_value);
      }, 2500);
    }
  };

  // Handle Form Fields
  const handleFormModule = async (value) => {
    const _field = await form.getFieldsValue();

    console.log("Get all fields: ", _field);

    if (_field.length > 0) {
      const _all_fields = {
        1: {
          // 9: _value[0]?.children[0]?.is_check ? true : false,
          9: _field[1][9] ? true : false,
          10: _field[1][10] ? true : false,
          11: _field[1][11] ? true : false,
          12: _field[1][12] ? true : false,
          13: _field[1][13] ? true : false,
          14: _field[1][14] ? true : false,
          15: _field[1][15] ? true : false,
          16: _field[1][16] ? true : false,
          17: _field[1][17] ? true : false,
          18: _field[1][18] ? true : false,
          19: _field[1][19] ? true : false,
          20: _field[1][20] ? true : false,
          21: _field[1][21] ? true : false,
          22: _field[1][22] ? true : false,
          23: _field[1][23] ? true : false,
          24: _field[1][24] ? true : false,
        },
        2: {
          37: _field[2][37] ? true : false,
          38: _field[2][38] ? true : false,
          39: _field[2][39] ? true : false,
          40: _field[2][40] ? true : false,
          41: _field[2][41] ? true : false,
        },
        3: {
          4: {
            29: _field[3][4][29] ? true : false,
            30: _field[3][4][30] ? true : false,
            31: _field[3][4][31] ? true : false,
            32: _field[3][4][32] ? true : false,
            33: _field[3][4][33] ? true : false,
            34: _field[3][4][34] ? true : false,
            35: _field[3][4][35] ? true : false,
            36: _field[3][4][36] ? true : false,
            106: _field[3][4][106] ? true : false,
          },
          25: _field[3][25] ? true : false,
          26: {
            97: _field[3][26][97] ? true : false,
            98: _field[3][26][98] ? true : false,
            99: _field[3][26][99] ? true : false,
          },
          27: {
            47: _field[3][27][47] ? true : false,
            48: _field[3][27][48] ? true : false,
            49: _field[3][27][49] ? true : false,
            50: _field[3][27][50] ? true : false,
            51: _field[3][27][51] ? true : false,
            101: _field[3][4][101] ? true : false,
          },
          28: {
            52: _field[3][28][52] ? true : false,
            53: _field[3][28][53] ? true : false,
            54: _field[3][28][54] ? true : false,
            55: _field[3][28][55] ? true : false,
            56: _field[3][28][56] ? true : false,
            100: _field[3][28][100] ? true : false,
            103: _field[3][28][103] ? true : false,
            104: _field[3][28][104] ? true : false,
          },
          107: {
            108: _field[3][107][108] ? true : false,
            109: _field[3][107][109] ? true : false,
            110: _field[3][107][110] ? true : false,
          },
        },
        5: {
          42: {
            57: _field[5][42][57] ? true : false,
            58: _field[5][42][58] ? true : false,
          },
          43: {
            59: _field[5][43][59] ? true : false,
            60: _field[5][43][60] ? true : false,
            61: _field[5][43][61] ? true : false,
            62: _field[5][43][62] ? true : false,
            105: _field[5][43][105] ? true : false,
          },
        },
        6: _field[6] ? true : false,
        7: {
          63: _field[7][63] ? true : false,
          64: _field[7][64] ? true : false,
          65: _field[7][65] ? true : false,
          66: _field[7][66] ? true : false,
          67: _field[7][67] ? true : false,
        },
        8: {
          44: {
            68: _field[8][44][68] ? true : false,
            69: _field[8][44][69] ? true : false,
            70: _field[8][44][70] ? true : false,
            71: _field[8][44][71] ? true : false,
            72: _field[8][44][72] ? true : false,
            73: _field[8][44][73] ? true : false,
            74: _field[8][44][74] ? true : false,
            75: _field[8][44][75] ? true : false,
            76: _field[8][44][76] ? true : false,
            77: _field[8][44][77] ? true : false,
            78: _field[8][44][78] ? true : false,
            79: _field[8][44][79] ? true : false,
            80: _field[8][44][80] ? true : false,
            81: _field[8][44][81] ? true : false,
            82: _field[8][44][82] ? true : false,
            83: _field[8][44][83] ? true : false,
            84: _field[8][44][84] ? true : false,
            85: _field[8][44][85] ? true : false,
            86: _field[8][44][86] ? true : false,
          },
          45: {
            87: _field[8][45][87] ? true : false,
            88: _field[8][45][88] ? true : false,
            89: _field[8][45][89] ? true : false,
            90: _field[8][45][90] ? true : false,
            91: _field[8][45][91] ? true : false,
            92: _field[8][45][92] ? true : false,
            93: _field[8][45][93] ? true : false,
            111: _field[8][45][111] ? true : false,
          },
          46: {
            94: _field[8][46][94] ? true : false,
            95: _field[8][46][95] ? true : false,
            96: _field[8][46][96] ? true : false,
          },
        },
      };
      form.setFieldsValue(_all_fields);
    }

    setLoading(false);
  };

  // Handle Submit
  const handleSubmit = (value) => {
    const _submit = value;

    let _arr_module = [];
    let _arr_sub_module = [];
    let _arr_grand_module = [];

    // last Keys
    let _lastKey = 0;
    let _lastKeySub = 0;

    for (const [key, value] of Object.entries(_submit)) {
      // console.log(`${key}: ${JSON.stringify(value)}`);

      const _keys = parseInt(key);

      if (_keys > 0) {
        if (typeof value === "object") {
          Object.keys(value).forEach((key) => {
            const _key_sub = parseInt(key);

            // console.log("Last Keys: ", _lastKey);

            if (
              _key_sub > 0 &&
              typeof value[_key_sub] === "boolean" &&
              value[_key_sub] === true
            ) {
              _arr_sub_module.push(_key_sub);

              if (_lastKey !== _keys) {
                _lastKey = _keys;
                _arr_module.push(_keys);
              }
            } else if (_key_sub > 0 && typeof value[_key_sub] === "object") {
              Object.keys(value[_key_sub]).forEach((keys) => {
                const _grand = value[_key_sub];
                const _key_child = parseInt(keys);
                // console.log("Keys: " + _key_child + " & " + _key_sub);

                if (_key_child > 0 && _grand[_key_child] === true) {
                  _arr_grand_module.push(_key_child);

                  if (_lastKeySub !== _key_sub) {
                    _lastKeySub = _key_sub;
                    _arr_sub_module.push(_key_sub);

                    if (_lastKey !== _keys) {
                      _lastKey = _keys;
                      _arr_module.push(_keys);
                    }
                    // console.log("Keys: " + _keys + "  Values: " + JSON.stringify(value))
                    // console.log("Values: " + JSON.stringify(value))
                  }
                }
              });
            }
          });
        } else if (value === true) {
          // console.log(`${key}: ${JSON.stringify(value)}`);
          _arr_module.push(_keys);
        }
        // _arr.push(_keys);
      }
    }

    // console.log("Array Module: ", _arr_module);
    // console.log("Array Sub Module: ", _arr_sub_module);
    // console.log("Array Grand Module: ", _arr_grand_module);
    console.log("Data Users: ", dataUser);

    let _newData = {
      approval_level: value?.approval_level,
      department: value?.department,
      dept_id: value?.dept_id,
      dept_uuid: value?.dept_uuid,
      email: value?.email,
      is_approve: value?.is_approve,
      roles: value?.roles,
      uid: value?.uid,
      user_level: value?.user_level,
      user_name: value?.user_name,
      module: _arr_module,
      sub_module: _arr_sub_module,
      grand_module: _arr_grand_module,
      id_warehouse: value?.id_warehouse,
      is_warehouse_admin: value?.is_warehouse_admin,
      access_control: dataUser?.user_metadata?.access_control,
    };

    console.log("New Data: ", _newData);

    // setNewData(_newData);
    showModalConfirm(_newData);
  };

  // Handle Close
  const handleClose = () => {
    form.resetFields();

    setLoading(false);
    setDataUser(null);
    setIsLoading(false);
    setNewData(null);
    setIsApprove(false);
    setIsAdminStore(false);
    setRoleLevel(0);
    setIsUpdate(0);

    setDept({
      code: "",
      dept_id: 0,
      dept_uuid: "",
      name: "",
    });

    setTimeout(() => {
      setOpen(false);
      is_close(false);
    }, 1000);
  };

  // Handle Clear
  const handleClear = () => {
    is_refresh(true);

    handleClose();
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;
    console.log("About to Submit & Update: ", _data);

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Update ${
        _data?.user_name?.toUpperCase() || " - - - "
      }'s data?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        setNewData(_data);
      },

      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Update
  const handleUpdate = (values) => {
    console.log("Ini Value hasil mapping: ", newData);
    console.log("Ini value didalam handel Update: ", values);

    if (newData) {
      console.log("Props: ", props);

      props.disUpdateByAdmin(newData);
    } else {
      setIsLoading(false);
    }
  };

  // console.log("Is Open: ", is_open, open);

  return (
    <>
      <Modal
        className="modal-access"
        key="modal-access"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Edit User's Data`}</Typography>
            </Row>
          </>
        }
        open={open}
        footer={false}
        closable
        onCancel={handleClose}
        width={1000}
      >
        {/* <Form.Provider
          onFormChange={(name, { values, forms }) => {
            const _data = forms.module_list.getFieldsValue();
            // console.log(name, values, );

            // console.log("Form name: ", name);
            // console.log("Change Field: ", values);
            console.log("Forms: ", forms.module_list.getFieldsValue());
            getAllModule(_data);
          }}
          // onFormFinish={(name, { values, forms }) => {
          //   const { basicForm } = forms;
          //   const users = basicForm.module_list.getFieldsValue();

          //   console.log("module_list: ", basicForm);
          //   console.log("Basic Form: ", basicForm);
          // }}
        > */}
        <Form
          className="form-user"
          key="form-user"
          name="form-user"
          layout="vertical"
          form={form}
          labelWrap
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="main-modal-row">
            <Col span={24} className="main-modal-col">
              <Row className="user-name" gutter={30}>
                <Col span={12} className="user-name-col">
                  <Form.Item
                    name="user_name"
                    label="User Name"
                    style={{
                      margin: 0,
                    }}
                  >
                    <Input
                      placeholder="User Name"
                      className="user-input"
                      disabled
                    />
                  </Form.Item>

                  <Form.Item name="uid" label="Unique ID" hidden>
                    <Input placeholder="UID" className="user-input" />
                  </Form.Item>
                </Col>

                <Col span={12} className="user-name-col">
                  <Form.Item
                    name="email"
                    label="E-mail"
                    style={{
                      margin: 0,
                    }}
                  >
                    <Input placeholder="User Name" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "5px 0px", borderColor: "#EBEDF3" }}
              >
                {`Department & Roles`}
              </Divider>

              <Row className="dept-row" gutter={30}>
                <Col span={12} className="user-name-col">
                  <Form.Item
                    name="department"
                    label="Department"
                    style={{
                      margin: 0,
                    }}
                  >
                    <MasterDepartment
                      getDepartment={getDepartment}
                      department_name={dataUser?.user_metadata?.department}
                      disabled={loading}
                    />
                  </Form.Item>

                  <Form.Item hidden name="dept_uuid" label="Department">
                    <Input />
                  </Form.Item>

                  <Form.Item hidden name="dept_id" label="Department">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12} className="user-name-col">
                  <Form.Item
                    name="roles"
                    label="Roles"
                    style={{
                      margin: 0,
                    }}
                  >
                    <MasterRoles
                      getRole={getRole}
                      roles={dataUser?.user_metadata?.roles}
                      is_disable={loading}
                    />
                  </Form.Item>

                  <Form.Item
                    className="form-item-register"
                    label="User Level"
                    name={"user_level"}
                    hidden
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="appr-level" gutter={30} justify="end">
                <Col span={12} className="user-name-col">
                  <Form.Item
                    valuePropName="checked"
                    label="Admin Store/Kitchen"
                    name="is_warehouse_admin"
                    style={{
                      margin: 0,
                    }}
                  >
                    <Checkbox
                      disabled={
                        dataUser?.user_metadata?.user_level > 2 ? false : true
                      }
                      className="is-approve"
                      onChange={handleWareHouse}
                    >
                      {`Assign Store/Kitchen`}
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={8} className="module-col" hidden={true}>
                  <Form.Item
                    name="approval_level"
                    label="Approval Level"
                    // rules={[
                    //   {
                    //     required: roleLevel > 3 && isApprove ? false : true,
                    //     message: "Please, Select an Options!",
                    //   },
                    // ]}
                  >
                    <MasterApproval
                      getApproval={getApproval}
                      disabled={loading}
                      roleLevel={roleLevel}
                      approvals={dataUser?.user_metadata?.approval_level}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="module-col" hidden={true}>
                  <Form.Item
                    valuePropName="checked"
                    label="No Approval Needed"
                    name="is_approve"
                  >
                    <Checkbox
                      disabled={roleLevel > 3 ? false : true}
                      className="is-approve"
                      onChange={handleCheck}
                    >
                      {`None`}
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={12} className="module-col">
                  <Form.Item
                    label="Store"
                    name="id_warehouse"
                    style={{
                      margin: 0,
                    }}
                  >
                    <MasterWarehouseAdmin
                      selectedItem={getWarehouseId}
                      is_disable={!isAdminStore}
                      is_multiple={true}
                      store_id={
                        dataUser?.user_metadata?.id_warehouse?.length > 0
                          ? dataUser?.user_metadata?.id_warehouse
                          : []
                      }
                      department_id={
                        dataUser?.user_metadata?.dept_id
                          ? dataUser?.user_metadata?.dept_id
                          : null
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="loads" align="middle" justify="center">
                {!loading ? (
                  <Divider
                    className="divider-form"
                    orientation="left"
                    orientationMargin={0}
                    style={{ margin: "5px 0px", borderColor: "#EBEDF3" }}
                  >
                    {`Main Modules, Sub-Modules & Sub-Children Modules`}
                  </Divider>
                ) : (
                  <SyncOutlined
                    spin={loading}
                    style={{
                      fontSize: 50,
                      color: "#187DE4",
                    }}
                  />
                )}
              </Row>

              <Row className="modules" gutter={30}>
                <Col span={24} className="module-col">
                  <Form.Item name={"all_module"} label="Modules">
                    <ModuleTabs
                      is_render={open}
                      getAllModule={getAllModule}
                      subModule={
                        dataUser?.user_metadata?.sub_module?.length > 0
                          ? dataUser?.user_metadata?.sub_module
                          : []
                      }
                      modules={
                        dataUser?.user_metadata?.module?.length > 0
                          ? dataUser?.user_metadata?.module
                          : []
                      }
                      grandmodules={
                        dataUser?.user_metadata?.grand_module?.length > 0
                          ? dataUser?.user_metadata?.grand_module
                          : []
                      }
                      is_disabled={loading}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "0px 0px 5px", borderColor: "#EBEDF3" }}
              />

              <Row className="access" gutter={30}>
                <Col span={12} className="module-col">
                  <Form.Item
                    name="created_date"
                    label="Created Date"
                    style={{
                      margin: 0,
                    }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={12} className="module-col">
                  <Form.Item
                    name="confirmed_at"
                    label="Registered Date"
                    style={{
                      margin: 0,
                    }}
                  >
                    <Input placeholder="Registered Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", borderColor: "#EBEDF3" }}
              />

              <Row className="user_name" gutter={30} justify="end">
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{
                    margin: "0px 15px 0px",
                  }}
                >{`Submit`}</Button>

                <Button
                  className="cancel-btn"
                  onClick={handleClose}
                  type="default"
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
        {/* </Form.Provider> */}
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAccessControl);

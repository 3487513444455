// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Select } from "antd";

// Import React Icons

// CODE
export default function MasterTitle({ getTitleType, title }) {
  // CONTEXT
  const { getMasterTitle } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [titleType, setTitleType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Fetch Data
  const fetchData = () => {
    getMasterTitle({
      title: "title",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response => ", response);

        let option = response.data.msg.map((dev) => ({
          value: dev.title,
          label: dev.title,
        }));

        setOptionValue(option);
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchData();
    // setTitleType(selectedTitleType)
  }, []);

  // console.log(optionValue);

  return (
    <>
      <Select
        key={optionValue.title}
        style={{ width: "100%" }}
        value={titleType ? titleType : title}
        onChange={(title_) => {
          setTitleType(title_);

          console.log(title_);

          getTitleType(title_);
          //  if (onSet) onSet(deviceid);
        }}
        options={optionValue}
      />
    </>
  );
}

// Import React's Components
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// Import ANT Design's Components
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  Typography,
  Image,
  Divider,
  Table,
  Layout,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  FormOutlined,
} from "@ant-design/icons";

// Create Styles
const styles = {
  textAlign: "center",
  fontSize: "30px",
  fontWeight: "bold",
  color: "#096dd9",
  paddingTop: "25px",
};

// Create Table's Columns
const columns = [
  // {
  //   title: "Reservation No",
  //   dataIndex: "reservation_id",
  //   // fixed: "left",
  //   width: 175,
  //   key: "reservation_id",
  // },
  {
    title: "Guest Name",
    dataIndex: "guest_name",
    key: "guest_name",
    ellipsis: true,
  },
  {
    title: "Arrival",
    dataIndex: "arrival",
    key: "arrival",
    // width: 100,
    render: (arrival, record) => {
      return moment(arrival).format("DD-MM-YYYY");
    },
  },
  {
    title: "Departure",
    dataIndex: "departure",
    key: "departure",
    // width: 100,
    render: (depart, record) => {
      return moment(depart).format("DD-MM-YYYY");
    },
  },
  {
    title: "Night",
    dataIndex: "night",
    key: "night",
    // width: 50,
  },
  {
    title: "Room",
    dataIndex: "room_category",
    key: "room_category",
  },
  {
    title: "Room No",
    dataIndex: "room_number",
    key: "room_number",
  },
  {
    title: "Segment",
    dataIndex: "segment",
    key: "segment",
    ellipsis: true,
  },
  {
    title: "Rate Code",
    dataIndex: "room_rate_id",
    key: "room_rate_id",
    render: (rate) => {
      return rate?.length > 0 ? rate[0] : " - ";
    },
  },
  // {
  //   title: "Guest Type",
  //   dataIndex: "group",
  //   key: "group",
  //   render: (group, record) => {
  //     let grp = "";

  //     switch (group) {
  //       case 0:
  //         grp = "Individual Guest";
  //         break;

  //       case 1:
  //         grp = "Group Leader Guest";
  //         break;

  //       case 2:
  //         grp = "Group Member Guest";
  //         break;

  //       default:
  //         break;
  //     }

  //     return grp;
  //   },
  // },
  {
    title: "Memo Room",
    dataIndex: "memo_room",
    key: "memo_room",
  },
];

class GuestReportList extends React.Component {
  // export default function ComponentToPrint() {
  render() {
    console.log("Ini print : ", this?.props?.value?.data);

    return (
      <>
        <Layout>
          <Content
            className="content-print-form"
            // ref={componentRef}
            style={{
              //   border: "solid 1px",
              padding: 30,
            }}
          >
            <Row gutter={30} className="row-header">
              <Col span={12} className="col-title-form">
                <Typography.Title level={2} className="text-form-title">
                  Guest In House List
                </Typography.Title>
              </Col>

              <Col span={12} align="middle" className="col-img-logo">
                <Image
                  src="/looklogo(color).png"
                  className="img-form-logo"
                  preview={false}
                  style={{
                    width: "25%",
                  }}
                />

                <Typography.Title level={4} className="text-form-id">
                  Idea's Hotel Bandung
                </Typography.Title>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "grey" }}
            />

            <Typography
              className="text-date"
              style={{
                padding: "0px 0px 30px",
                fontWeight: 750,
              }}
            >
              Date: {`${moment().format("DD-MM-YYYY")}`}
            </Typography>

            <Table
              className="arrival-list-table"
              key="arrival-list-table"
              name="arrival-list-table"
              columns={columns}
              dataSource={this?.props?.value?.data || []}
              bordered
              pagination={false}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-form" : "odd-form";
              }}
            />
          </Content>
        </Layout>
      </>
    );
  }
}

// EXPORT DEFAULT
export default GuestReportList;

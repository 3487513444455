// Import React Components
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import { Table } from "antd";

// CODE
export default function CompanyLedgersTable(props) {
  // CONTEXT
  const { getLedger } = useContext(FinanceContext);

  // PROPS
  const { companyData, get_ledger, is_refresh, is_loading, is_edit } = props;

  // STATE MANAGEMENTS
  // Data
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  // Columns Table
  const columns = [
    {
      title: "No.",
      dataIndex: "",
      key: "index",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Credit Limit",
      dataIndex: "initial_credit_limit",
      key: "initial_credit_limit",
      render: (credits) => {
        return `${credits > 0 ? credits : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    // {
    //   title: "Current Credit",
    //   dataIndex: "current_credit_limit",
    //   key: "current_credit_limit",
    //   render: (credits) => {
    //     return `${credits > 0 ? credits : 0}`.replace(
    //       /\B(?=(\d{3})+(?!\d))/g,
    //       ","
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (status) => {
        return status === true ? "Active" : "In-Active";
      },
    },
    {
      title: "Started Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => {
        const _data = date ? moment(date).format("DD-MM-YYYY") : "";

        return _data;
      },
    },
    {
      title: "Exp Date",
      dataIndex: "expired_date",
      key: "expired_date",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
  ];

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("Company's: ", companyData);
    if (companyData?.length > 0 && !cleanUp) {
      setData(companyData);
    } else {
      setData([]);
      setSelected([]);
    }

    if (is_loading && !cleanUp) {
      setLoading(is_loading);
    } else {
      setLoading(is_loading);
    }

    return () => {
      cleanUp = true;
    };
  }, [companyData, is_loading]);

  // Handle Selection Table
  const handleSelection = {
    selectedRowKeys: selected,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);

      setSelected(selectedRowKeys);
      // setDataEdit(selectedRows[0]);
      get_ledger(selectedRows[0]);
      is_edit(true);
    },
  };

  return (
    <>
      <Table
        className="company-ledger-table"
        key="company-ledger-table"
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        size="middle"
        rowSelection={{
          type: "radio",
          ...handleSelection,
        }}
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} entries`,
        }}
        rowClassName={(_, record) => {
          return record % 2 === 0 ? "odd" : "even";
        }}
        rowKey={(record) => (record.id ? record.id : record.key)}
      />
    </>
  );
}

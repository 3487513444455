// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../API/Global/Payload";
import {
  authorized_by,
  authorized_token,
  handleAuthorization,
} from "../../../API/Global/Permission";

// Import React Print
import { useReactToPrint } from "react-to-print";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../src/API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BiSolidFilePdf } from "react-icons/bi";
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";

// Import Notification
import {
  failedCheckIn,
  successCheckIn,
} from "../../Reusable/Notification/Notification";
import { FaSmoking, FaSmokingBan } from "react-icons/fa";
import {
  MdOutlineBedroomChild,
  MdOutlineBedroomParent,
  MdOutlineKingBed,
} from "react-icons/md";

// Import Functions
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
// import ArticleRoomPrice from "../../Reusable/Reservation/ArticleRoomPrice";

// MODAL CONFIRM
const { confirm } = Modal;

// Text Styles
const textForm = {
  fontWeight: 750,
  padding: "5px 0px 0px",
};

// CODE
export default function RegistrationForm(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    // Data Reservation
    dataRes,
    // Data Guest
    dataGuest,
    // Data COA
    dataCoa,
    // Room
    dataRoom,
    dataArticle,
    // Is Refresh???
    is_refresh,
    is_no_show,
    is_checkin,
    dataForm,
  } = props;

  // CONTEXT
  const { state, getReservation, getArticleRoom, getPreBillArticle } =
    useContext(MainContext);
  const { getMasterRoom, getMasterPrint } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;
  const param_fo = FrontOfficeParams();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [guest, setGuest] = useState(null);
  // Data Room
  const [smoke, setSmoke] = useState(null);
  const [room, setRoom] = useState([]);
  const [article, setArticle] = useState([]);
  const [price, setPrice] = useState(0);
  const [totalGuest, setTotalGuest] = useState(0);
  // No Show
  const [notShowing, setNotShowing] = useState(false);
  // Chart Of Account
  const [coa, setCoa] = useState(null);
  // Modal
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // FETCH
  // Fetch Data Room
  const fetchDataPrice = async () => {
    await getArticleRoom({
      articleRoomPrice: "reservation",
      type: "article-room-single",
      res_id: dataRes?.reservation_id
        ? dataRes?.reservation_id
        : data.reservation_id,
      prc_id: dataRes?.price_id ? dataRes?.price_id : data.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Article Room => ", response);

        setRoom(response.data);

        if (response?.data?.length > 0 || response?.data) {
          let newStay = response.data.map((val) => ({
            article_name: val?.article_name ? val.article_name : "",
            item_quantity: val?.item_quantity ? val.item_quantity : 0,
            unit_price: val?.unit_price ? val.unit_price : 0,
            total_price: val?.total_price ? val.total_price : 0,
            id: val?.id ? val.id : 0,
            tax: val?.tax,
            service: val?.service,
            gross: val?.gross,
          }));

          setArticle(newStay);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });

    await getPreBillArticle({
      articleStay: "bill",
      type: "article-res-stay-guest",
      res_id: dataGuest?.reservation_id
        ? dataGuest.reservation_id
        : data.reservation_id,
      prc_id: dataGuest?.price_id >= 0 ? dataGuest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Bill Article => ", response);

        if (response?.data?.length > 0 || response?.data) {
          let newStay = response.data.map((val) => ({
            article_name: val?.article_name ? val.article_name : "",
            item_quantity: val?.item_quantity ? val.item_quantity : 0,
            unit_price: val?.unit_price ? val.unit_price : 0,
            total_price: val?.total_price ? val.total_price : 0,
            id: val?.id ? val.id : 0,
            tax: val?.tax,
            service: val?.service,
            gross: val?.gross,
          }));

          setArticle(newStay);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // Fetch Room Type
  const fetchRoomType = async () => {
    await getMasterRoom({
      main: "main",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("ISI Room List >> ", response);

        let filtered = response.data.msg.filter((val) => {
          if (!val.is_deleted) {
            if (val.room_number == dataRes.room_number) {
              return val;
            }
          }
        });

        console.log("ISI Filtered >> ", filtered);
        setSmoke(filtered?.length > 0 ? filtered[0] : []);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // FETCH PRINT FORM
  const fetchPrint = async () => {
    // third;
    await getMasterPrint({
      reservation_id: dataRes?.reservation_id || data?.reservation_id,
      price_id: dataRes?.price_id > 0 ? dataRes.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: dataRes || data,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(openModal);
    }

    if (dataGuest && !cleanUp) {
      console.log("Data Guest --> ", dataGuest);
      setGuest(dataGuest);
    }

    if (is_no_show == true && !cleanUp) {
      console.log("Guest Is Not Showing ! --> ", is_no_show);
      setNotShowing(is_no_show);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest, is_no_show]);

  // USE EFFECT HANDLE SHOW
  useEffect(() => {
    let cleanUp = false;

    if (open == true && dataRes != null && !cleanUp) {
      console.log("Data Reservasi --> ", dataRes);
      // console.log("Data Guest --> ", dataGuest);
      // console.log("Data Guest --> ", guest);

      setLoading(true);

      setData(dataRes);

      let _total_guest = dataRes?.adult + dataRes?.child + dataRes?.infant;
      setTotalGuest(_total_guest);
      // fetchDataPrice();
      fetchPrint();
      fetchRoomType();
      setCoa(dataCoa);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataRes, open, dataCoa]);

  // Price
  useEffect(() => {
    let cleanUp = false;

    if (is_checkin && !cleanUp) {
      if ((dataRoom?.length > 0 || dataArticle?.length > 0) && !cleanUp) {
        console.log("Art Room: ", dataRoom);
        console.log("Art Stay: ", dataArticle);

        setArticle(dataArticle);
        setRoom(dataRoom);
        handleTotal(dataRoom, dataArticle);

        setTimeout(() => {
          setLoading(false);
        }, 1250);
      }
    } else {
      // console.log("Data Form: ", is_checkin, " = ", dataForm);

      handleIsCheck(dataForm);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataRoom, dataArticle, dataForm]);

  // HANDLE TOTAL
  const handleTotal = async (roomArr, articleArr) => {
    let _room = roomArr;
    let _art = articleArr?.length > 0 ? articleArr : [];

    let _merged = [..._room, ..._art];

    let totalPrice = _merged.reduce((accumulator, initValues) => {
      // console.log("ACC ", accumulator);
      // console.log("Init ", initValues);

      return accumulator + initValues.total_price;
    }, 0);

    console.log("Total Price >> ", totalPrice);
    setPrice(totalPrice);
  };

  // Handle Check-In
  const handleIsCheck = (value) => {
    const _data = value;

    let _total_guest = 0;
    let _total_article = 0;
    let _room_no = [];
    let _room_category = [];

    // _total_guest =
    //   _data?.total_adults + _data?.total_children + _data?.total_infants;
    _total_article = _data?.total_room + _data?.total_stay || 0;
    _room_no = _data?.room_numbers;
    _room_category = _data?.room_category;

    // console.log("Rooms: ", _total_guest);

    // setTotalGuest(_total_guest);
    setGuest(_data);
    setPrice(_total_article);
    // setCategory(_room_category);
    setRoom(_room_no);

    setTimeout(() => {
      setLoading(false);
    }, 1250);
  };

  // ON FINISH
  const onFinish = async () => {
    showSubmitModal();
    setIsLoading(true);
  };

  // SEQUENTIAL BUTTON SUBMIT
  const [counter, setCounter] = useState(0);

  const myclick = () => {
    // console.log(counter);
    setCounter((counter) => counter + 1);
    handlePrint();
    setIsLoading(false);

    // if (counter == 1) {
    //   // showSubmitModal();
    //   setCounter(0);
    // } else {
    //   setCounter(1);
    // }
  };

  // SHOW SUBMIT CONFIRMATION
  const showSubmitModal = async () => {
    let submitContent = data;

    // console.log(deleteContent);
    console.log("Coa ==> ", coa);

    confirm({
      className: "submit-reservation-confirm",
      title: `Are you sure want to Check-In with guest named ${
        submitContent?.guest_name?.toUpperCase() || "Unknown"
      } ?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(submitContent);
        setCounter(0);
        // setOpen(false);
        // closeModal(false);
      },
      onCancel() {
        setCounter(0);
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    const allBody = [];

    console.log("ON OK ==> ", contentsubmit);
    console.log("Coa ==> ", coa);

    allBody.push({
      res_id: contentsubmit?.reservation_id ? contentsubmit.reservation_id : "",
      room_num: contentsubmit?.room_number
        ? contentsubmit.room_number
        : room[0]?.room_number,
      prc_id: contentsubmit?.price_id
        ? contentsubmit?.price_id
        : room[0]?.price_id,
      coa: coa ? coa : null,
      is_no_show: notShowing,

      created: authorized_by ? authorized_by : user_name,
    });

    await axios
      .post(
        `${baseurl}/guest-in-house/checkin-reservation`,
        {
          data: allBody,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Check-In = ", response);

        handleCancel();
        is_refresh(true);
        successCheckIn(response, { method: 0 });

        localStorage.clear();
        handleAuthorization("");
      })
      .catch((error) => {
        console.log("Check-In Failed = ", error);
        failedCheckIn(error, { method: 0 });
        setCounter(counter + 1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    form.resetFields();

    setCounter(0);
    setOpen(false);

    closeModal(false);

    setRoom([]);
    setArticle([]);

    setData(null);
    setGuest(null);
    setPrice(null);
    setSmoke(null);

    setIsLoading(false);
    // setEdit(null);
  };

  // HANDLE REACT PRINT
  const componentRef = useRef();

  const handlePrint = () => {
    PrintCount({
      data: data,
      is_update: true,
      counter_list: {
        registration_form: countPrint?.registration_form + 1,
        guarantee_letter: countPrint?.guarantee_letter,
        pre_bill: countPrint?.pre_bill,
        thankyou_letter: countPrint?.thankyou_letter,
        checkout_letter_history: countPrint?.checkout_letter_history,
        bill: countPrint?.bill,
        master_bill: countPrint?.master_bill,
        pre_master_bill: countPrint?.pre_master_bill,
        reservation_letter: countPrint?.reservation_letter,
      },
    });

    handlePrintOut();
  };

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Registration Form (${
      smoke?.smoking == 1 ? "Smoking" : "Non-Smoking"
    }) ${data?.length > 0 ? data[0]?.reservation_id : data?.reservation_id}`,
    // onafterprint: () => alert("Print Success"),
  });

  // CONSOLE LOG
  // console.log("DATA RESERVATION >> ", data);
  // console.log("DATA Guest >> ", guest);
  // console.log("DATA Room >> ", room);
  // console.log("DATA Smoke >> ", smoke);
  // console.log("DATA COA >> ", coa);

  return (
    <>
      <Modal
        className="reg-form-modal"
        name="reg-form-modal"
        key="reg-form-modal"
        title={
          <>
            <Row className="row-title-modal">
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Registration Form`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="registration-form"
          className="registration-form"
          key="registration-form"
          form={form}
          labelAlign="left"
          labelCol={{ span: 8 }}
          labelWrap={{ span: 16 }}
          onFinish={onFinish}
          style={{
            maxWidth: 1000,
          }}
          autoComplete="off"
          layout="horizontal"
        >
          {!loading ? (
            <Content
              ref={componentRef}
              style={{
                padding: 30,
                backgroundImage:
                  countPrint?.registration_form >= 1
                    ? "url(/assets/logos/copyopac.png)"
                    : null,
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
                backgroundPosition: "center",
              }}
            >
              <Row className="row-header">
                <Col span={12} className="col-title-form">
                  <Row>
                    <Typography.Title level={3} className="text-form-title">
                      {`Registration Form`}
                    </Typography.Title>
                  </Row>

                  <Row>
                    <Typography.Title level={5} className="text-form-smoke">
                      {smoke?.smoking == 1 ? "Smoking" : "Non-Smoking"}
                    </Typography.Title>
                  </Row>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "20%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px 5px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-room-details"
                style={{ width: "100%", margin: 0, height: 35 }}
              >
                <Col span={4} className="col-room-no">
                  <Form.Item
                    label="Room No"
                    className="room_number"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-room-no" style={textForm}>
                      {room?.length && is_checkin ? room[0].room_number : room}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item
                    label="Total Guest"
                    className="total-guest"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-adult" style={textForm}>
                      {totalGuest > 0 ? totalGuest : 0}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Rate Code"
                    className="room_rate_id"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-rate-code" style={textForm}>
                      {/* {data?.room_rate_id?.length > 0 ? data.room_rate_id[0] : ""} */}
                      {data?.rate_code ? data.rate_code : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Room Price"
                    name="room_price"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-room-price" style={textForm}>
                      {`${currency} ${price > 0 ? price : 0}.00`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "5px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-room-details"
                style={{ width: "100%", height: 30 }}
              >
                <Col span={4}>
                  <Form.Item
                    label="Night"
                    name="total-night"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-night" style={textForm}>
                      {`${data?.night ? data.night : ""}`}
                      {/* {data?.folio ? data.folio : ""} */}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item
                    label="Room Qty "
                    name="room_qty"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-room-qty" style={textForm}>
                      {`${data?.room_qty ? data.room_qty : ""}`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Room Type"
                    name="room_type"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-room-cat" style={textForm}>
                      {data?.room_category
                        ? data.room_category
                        : guest?.room_category}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Breakfast/Room Only"
                    name="is_abf"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-receipt" style={textForm}>
                      {data?.is_abf ? "BREAKFAST" : "ROOM ONLY"}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row className="row-guest" style={{ height: 30, width: "100%" }}>
                <Col span={16} className="col-guest-name">
                  <Form.Item
                    label="Name"
                    className="guest_name"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography
                      className="text-guest-name"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      {`${
                        data?.guest_name ? data.guest_name : guest?.full_name
                      }, ${
                        guest?.guest_title
                          ? guest.guest_title
                          : guest?.sex == "Male"
                          ? "Mr."
                          : guest?.sex == "Female"
                          ? "Mrs."
                          : " "
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-eta">
                  <Form.Item
                    label="Arrival Time"
                    className="arrival_time"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-eta" style={textForm}>
                      {!is_checkin && data?.checkin_date
                        ? moment(LocalizationDate(data.checkin_date)).format(
                            "HH:mm"
                          )
                        : `${moment().format("HH:mm")}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-group"
                style={{ height: 25, width: "100%" }}
              >
                <Col span={16} className="col-group-name">
                  <Form.Item
                    label="Group / Company"
                    className="group_name"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ width: "100%" }}
                  >
                    <Typography
                      className="text-group"
                      style={{
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      {data?.group_name
                        ? data.group_name
                        : guest?.master_comp
                        ? guest.master_comp
                        : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-arrival">
                  <Form.Item
                    label="Arrival Date"
                    className="arrival_date"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-arrival" style={textForm}>
                      {data?.arrival
                        ? moment(data.arrival).format("DD-MM-YYYY")
                        : ""}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                style={{ height: 25, margin: 0 }}
                className="row-guest-details"
              >
                <Col span={8} className="col-nation">
                  <Form.Item
                    label="Nationality"
                    name="nationality"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography className="text-nation" style={textForm}>
                      {guest?.nationality ? guest.nationality : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-id-card">
                  <Form.Item
                    label="ID Card"
                    className="id_card_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography className="text-id-card" style={textForm}>
                      {guest?.id_card_number ? guest.id_card_number : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-departure">
                  <Form.Item
                    label="Departure Date"
                    name="departure_date"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-depart" style={textForm}>
                      {`${moment(data?.departure ? data.departure : "").format(
                        "DD-MM-YYYY"
                      )}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              />

              <Row
                justify="start"
                style={{ width: "100%", height: 30, textAlign: "start" }}
              >
                <Col span={16} className="col-email">
                  <Form.Item
                    label="Email Address"
                    className="email"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ width: "100%" }}
                  >
                    <Typography
                      className="text-email"
                      style={{
                        fontSize: 15,
                        fontWeight: 750,
                      }}
                    >
                      {data?.email
                        ? data.email
                        : guest?.email
                        ? guest.email
                        : null}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Check Out Time"
                    className="departure_time"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    // labelAlign="right"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Typography className="text-room-price" style={textForm}>
                      {/* {`${moment(data?.departure ? data.departure : "").format(
                    "DD-MM-YYYY"
                  )}, 12.00`} */}
                      {/* 12:00 */}
                      {`${moment(param_fo.checkout_time).format("HH:mm")}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row className="row-guest-address" style={{ height: 30 }}>
                <Col span={8} className="col-address">
                  <Form.Item
                    label="Address"
                    className="address"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                  >
                    <Typography
                      className="text-address"
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {guest?.address ? guest.address : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-birth">
                  <Form.Item
                    label="Birth Date"
                    className="birthdate"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-birthdate" style={textForm}>
                      {guest?.birthdate
                        ? moment(guest.birthdate).format("DD-MM-YYYY")
                        : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-phone">
                  <Form.Item
                    label="Phone"
                    className="phone_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Typography className="text-phone" style={textForm}>
                      {`(+) ${guest?.phone_number ? guest.phone_number : ""}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                style={{
                  margin: "10px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              />

              <Row
                className="row-payment"
                style={{ width: "100%", height: 60, margin: 0 }}
              >
                <Col span={8} className="col-payment-type">
                  <Form.Item
                    label="Payment Type"
                    className="payment_type"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-pay-type" style={textForm}>
                      {data?.payment_type ? data.payment_type : ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Member Card No."
                    className="member_card_no"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-id-card" style={textForm}>
                      {guest?.member_id ? guest.member_id : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={8} className="col-payment-details">
                  <Form.Item
                    label="Payment Details"
                    className="payment-details"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {data?.payment_details ? data.payment_details : ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Credit Card No. "
                    className="credit_card"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-id-card" style={textForm}>
                      {/* {data?.payment_details ? data.payment_details : ""} */}
                      {`____________`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col
                  span={8}
                  className="col-checkbox"
                  style={{ width: "100%", margin: 0 }}
                >
                  <Checkbox.Group
                    className="checkbox-group-list"
                    // disabled
                    value={false}
                    style={{
                      width: "100%",
                      // padding: 10,
                      fontSize: 18,
                    }}
                  >
                    <Row
                      className="row-body-checkbox"
                      style={{ margin: 0, width: "100%" }}
                    >
                      <Col span={12} className="1st-row-1st-col">
                        <Checkbox checked={false} className="visa">
                          VISA
                        </Checkbox>
                      </Col>

                      <Col span={12} className="1st-row-2nd-col">
                        <Checkbox checked={false} className="master-card">
                          Master
                        </Checkbox>
                      </Col>

                      <Col span={12} className="2nd-row-1st-col">
                        <Checkbox checked={false} className="Dinners">
                          JBF
                        </Checkbox>
                      </Col>

                      <Col span={12} className="2nd-row-2nd-col">
                        <Checkbox checked={false} className="other">
                          Other
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Payment Status:"
                    className="payment_status"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-check-out" style={textForm}>
                      {` ${
                        data?.payment_details == 1
                          ? "CASH BASIS"
                          : "CHARGE TO ROOM"
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-sign-guest"
                gutter={30}
                style={{ padding: "0px 0px 10px" }}
              >
                <Col
                  className="col-statement-guest"
                  span={16}
                  style={{ width: "100%", fontSize: 12 }}
                >
                  <Row
                    className="row-statement-to"
                    style={{
                      width: "100%",
                      fontWeight: 750,
                      // padding: "0px 30px 0px 0px",
                    }}
                  >
                    <Typography className="text-to">
                      {`To : Idea's Hotel Bandung`}
                    </Typography>
                  </Row>

                  <Row
                    className="row-statement"
                    style={{
                      margin: 0,
                      fontSize: 12,
                      textAlign: "justify",
                    }}
                  >
                    <Typography className="text-statement">
                      I Knowledge that I'm jointly and severally liable with the
                      fore-going person, company or association (and if more
                      than one all of them) for payment of the amount of any
                      charges payable or incurred in connecting with all service
                      provided by you under registration.
                    </Typography>
                  </Row>

                  <Row
                    className="row-statement"
                    style={{
                      margin: 0,
                      fontSize: 12,
                      textAlign: "justify",
                    }}
                  >
                    <Typography className="text-statement">
                      Please be informed that you are not allowed to bring
                      Durian in to your room. For your valuable belonging
                      (jewels, money, etc) could safe in the safe deposit box in
                      your room or in the front office.
                    </Typography>
                  </Row>
                </Col>

                <Col
                  className="col-sign"
                  span={8}
                  style={{
                    width: "100%",
                    height: 100,
                    // border: "1px solid #000000",
                    padding: "0px 30px",
                  }}
                >
                  <Row className="row-sign" justify="center">
                    <Typography
                      className="text-guest-sign"
                      style={{
                        fontWeight: 750,
                        // border: "1px solid #000000",
                      }}
                    >
                      Guest Signature
                    </Typography>
                  </Row>

                  <Typography
                    className="text-box"
                    style={{
                      fontWeight: 750,
                      height: 75,
                      border: "1px solid #000000",
                      textAlign: "center",
                    }}
                  ></Typography>
                </Col>
              </Row>

              {/* <Divider
              className="divider-form"
              style={{
                margin: "0px 0px 10px 0px",
                backgroundColor: "#d9d9d9",
              }}
            /> */}

              <Row
                className="row-notes"
                style={{
                  height: 60,
                  border: "1px solid #000000",
                  padding: "0px 30px",
                  margin: "5px 0px",
                }}
              >
                <Form.Item
                  label="Notes"
                  className="notes"
                  style={{ width: "100%", margin: 0 }}
                >
                  <Typography className="text-notes" style={textForm}>
                    {data?.memo_room ? data.memo_room : ""}
                  </Typography>
                </Form.Item>
              </Row>

              {/* <Divider
              className="divider-form"
              style={{
                margin: "10px 0px",
                backgroundColor: "#d9d9d9",
              }}
            /> */}

              <Row
                className="row-travel"
                style={{
                  width: "100%",
                  margin: "10px 0px 0px",
                  fontSize: 12,
                  fontWeight: 750,
                }}
              >
                <Typography
                  className="text-questions"
                  style={{ margin: "0px 50px 0px 0px" }}
                >
                  Have you travelled out of this country in the last day 14 days
                  ?
                </Typography>

                <Checkbox checked={false}>Yes</Checkbox>

                <Checkbox checked={false}>No</Checkbox>
              </Row>

              <Row
                className="row-where"
                style={{ margin: "5px 0px", fontSize: 12 }}
              >
                <Typography className="text-where">
                  If so, where to? ________________________________
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              {/*
              <Row
                className="row-desc"
                style={{ width: "100%", margin: 0, height: 30 }}
              >
                <Col span={8} className="col-depo">
                   <Form.Item
                  label="Deposit Box Number"
                  className="deposit_box_number"
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                  style={{ width: "100%" }}
                /> 
                </Col>

                <Col span={8}>
                  {/* <Form.Item
                    label="Issued By"
                    name="created_by"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-issued" style={textForm}>
                      {user_name}
                    </Typography>
                  </Form.Item> 
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Date"
                    name="date"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Typography className="text-date" style={textForm}>
                      {`${moment().format("DD-MM-YYYY")}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />
                  */}

              <Row
                className="row-signature"
                justify="start"
                // gutter={30}
                style={{ width: "100%", fontWeight: 750 }}
              >
                <Col
                  span={12}
                  className="col-check-in-by"
                  style={{ padding: "0px 30px 0px 0px" }}
                >
                  <Typography className="text-check-in">
                    {`Checked-In & Issued By`}
                  </Typography>

                  <Typography
                    className="text-box"
                    style={{ height: 60, border: "1px solid #000000" }}
                  />
                  {/* </Typography> */}

                  <Typography
                    className="text-box"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {`( ${is_checkin ? user_name : data?.checkin_by} )`}
                  </Typography>
                </Col>

                <Col
                  span={12}
                  className="col-check-out-by"
                  hidden={!is_checkin}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {smoke?.smoking == 1 ? (
                    <>
                      <Space direction="horizontal" align="start" size="large">
                        <Space direction="vertical">
                          SMOKING ROOM
                          <FaSmoking
                            className="smoking-icon"
                            style={{
                              textAlign: "center",
                              fontSize: 50,
                              color: "#52c41a",
                              // padding: "10px 0px 0px",
                            }}
                          />
                        </Space>

                        <Space direction="vertical">
                          {smoke?.room_bedsetup
                            ? smoke?.room_bedsetup
                            : "BED UNKNOWN"}

                          {smoke?.room_bedsetup == "SINGLE BED" ? (
                            <MdOutlineBedroomChild
                              className="bed-icon"
                              style={{
                                textAlign: "center",
                                fontSize: 75,
                                color: "#1890ff",
                                // padding: "10px 0px 0px",
                              }}
                            />
                          ) : (
                            <MdOutlineBedroomParent
                              className="bed-icon"
                              style={{
                                textAlign: "center",
                                fontSize: 75,
                                color: "#1890ff",
                                // padding: "10px 0px 0px",
                              }}
                            />
                          )}
                        </Space>
                      </Space>
                    </>
                  ) : (
                    <>
                      <Space direction="horizontal" align="start" size="large">
                        <Space direction="vertical">
                          NON SMOKING ROOM
                          <FaSmokingBan
                            className="nonsmoking-icon"
                            style={{
                              textAlign: "center",
                              fontSize: 75,
                              color: "#f5222d",
                              // padding: "10px 0px 0px",
                            }}
                          />
                        </Space>

                        <Space direction="vertical">
                          {smoke?.room_bedsetup
                            ? smoke?.room_bedsetup
                            : "BED UNKNOWN"}

                          {smoke?.room_bedsetup == "SINGLE BED" ? (
                            <MdOutlineBedroomChild
                              className="bed-icon"
                              style={{
                                textAlign: "center",
                                fontSize: 75,
                                color: "#1890ff",
                                // padding: "10px 0px 0px",
                              }}
                            />
                          ) : (
                            <MdOutlineBedroomParent
                              className="bed-icon"
                              style={{
                                textAlign: "center",
                                fontSize: 75,
                                color: "#1890ff",
                                // padding: "10px 0px 0px",
                              }}
                            />
                          )}
                        </Space>
                      </Space>
                    </>
                  )}
                  {/* 
                <Typography className="text-check-out">
                  {`Payment Status : ${
                    data?.payment_details == 1
                      ? "CASH BASIS"
                      : "MASTER BILL ACTIVE"
                  }`}
                </Typography>

                <Typography
                  className="text-box"
                  style={{ height: 60, border: "1px solid #000000" }}
                >
                </Typography>
              */}
                </Col>
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 0px" }}
          >
            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Button
              type="primary"
              icon={<PrinterOutlined />}
              key={"print"}
              onClick={myclick}
              className="print-btn"
              loading={loading}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              loading={isLoading}
              disabled={counter > 0 ? false : true}
              hidden={!is_checkin}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {/* {counter === 0 ? "Download" : "Check In"} */}
              {`Check-In`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

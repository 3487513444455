// Import React Components
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token, user_name } from "../../../API/Global/Payload";
import { authorized_token } from "../../../API/Global/Permission";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Form,
  Input,
  Tooltip,
  Divider,
  Checkbox,
  InputNumber,
} from "antd";
import { FormOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Master Components
import MasterRoomNumberCkIn from "../../Reusable/Master/RoomNumber/RoomCheckIn";
import OptionRoomCategory from "../../Reusable/Master/RoomCat/OptionRoomCategory";
import RegistrationForm from "../../Forms/Registration/Form";
import MasterRoomCatCheckIn from "../../Reusable/Master/RoomCat/OptionRoomCategory";
// import MasterRoomCatCheckIn from "../../Reusable/Master/RoomCat/RoomCatCheckIn";

// Import Notifications
import {
  failedCheckIn,
  incomplete,
  successCheckIn,
} from "../../Reusable/Notification/Notification";

// CODE
export default function ModalArrival(props) {
  // PROPS
  const { dataGuest, is_refresh, openModal, closeModal } = props;

  // CONTEXT
  const { getMasterRoomCat } = useContext(MasterContext);
  const { getReservation, getGuest, getArticleRoom, getPreBillArticle } =
    useContext(MainContext);

  // STATE MANAGEMENT
  // Open Pop Over
  const [open, setOpen] = useState(false);
  // Data
  const [detail, setDetail] = useState(null);
  const [guest, setGuest] = useState(null);
  const [room, setRoom] = useState([]);
  const [article, setArticle] = useState([]);
  const [data, setData] = useState(null);
  const [info, setInfo] = useState(null);
  const [roomSettings, setRoomSettings] = useState({
    room_category: "",
    room_category_code: "",
    room_number: 0,
    chart_of_acount: "",
    id: 0,
  });
  const [codeCat, setCodeCat] = useState(null);
  // Forms State
  // Modals
  const [openForm, setOpenForm] = useState(false);
  const [notShowing, setNotShowing] = useState(false);
  // BOOLEAN
  const [rendered, setRendered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkBox, setCheckBox] = useState({
    is_smoke: false,
    is_connect: false,
  });

  // GET CODE CATEGORY
  // const code = OptionRoomCategory().optionValue;

  // console.log("CODE CATEGORY --> ", code);

  // USEFORM
  const [form] = Form.useForm();

  // FETCH ROOM CATEGORY
  const fetchRoomCode = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Room Category => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          let filtered = _response.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              if (filt?.room_category_id == dataGuest?.room_category_id) {
                return filt;
              }
            }
          });
          //   setOptionValue(filtered);

          console.log("Room Category Code: ", filtered);

          setRoomSettings({
            room_category_code: filtered[0]?.room_category_code
              ? filtered[0].room_category_code
              : filtered?.room_category_code,
            room_category: filtered[0]?.room_category_name
              ? filtered[0].room_category_name
              : filtered?.room_category_name,
            id: filtered[0]?.id,
            // ...roomSettings,
          });

          setCodeCat(
            filtered[0]?.room_category_id
              ? filtered[0].room_category_id
              : filtered?.room_category_id
          );
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // Fetch Data
  const fetchDataPrice = async () => {
    await getArticleRoom({
      articleRoomPrice: "reservation",
      type: "article-room-single",
      res_id: detail?.reservation_id
        ? detail?.reservation_id
        : dataGuest?.reservation_id,
      prc_id: detail?.price_id ? detail?.price_id : dataGuest?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Article Room => ", response);

        if (response?.data?.length > 0 || response?.data) {
          let newStay = response.data.map((val) => {
            return { ...val, total_price: val?.gross };
          });

          setRoom(newStay);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });

    await getPreBillArticle({
      articleStay: "bill",
      type: "article-res-stay-guest",
      res_id: dataGuest?.reservation_id
        ? dataGuest.reservation_id
        : data?.reservation_id,
      prc_id: dataGuest?.price_id >= 0 ? dataGuest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Bill Article => ", response);

        if (response?.data != null) {
          let newStay = response.data.map((val) => ({
            article_name: val?.article_name ? val.article_name : "",
            item_quantity: val?.item_quantity ? val.item_quantity : 0,
            unit_price: val?.unit_price ? val.unit_price : 0,
            total_price: val?.total_price ? val.total_price : 0,
            id: val?.id ? val.id : 0,
            tax: val?.tax,
            service: val?.service,
            gross: val?.gross,
          }));

          setArticle(newStay);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // GET DATA RESERVATION
  const fetchData = async () => {
    await getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("ISI >>>> ", response);

        let filtered = response.data.msg.filter((e) => {
          if (detail?.id == e.id || dataGuest?.id == e.id) {
            return e;
          }
        });

        console.log("filtered Res >>>>", filtered);
        console.log(detail);
        setData(filtered?.length > 0 ? filtered[0] : []);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // Fetch Data Guest
  const fetchDataGuest = async () => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((e) => {
            if (dataGuest?.guest_id == e.id) {
              return e;
            }
          });

          console.log("Filter Guest >>> ", filtered);
          setGuest(filtered.length > 0 ? filtered[0] : null);
        } else {
          setGuest(null);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(true);

      // fetchData();
    }

    if (dataGuest && !cleanUp) {
      setDetail(dataGuest);
      console.log("Modal Open: ", dataGuest);

      setFieldForm(dataGuest);
      // Set Data
      fetchDataGuest(dataGuest);
      fetchDataPrice(dataGuest);

      if (dataGuest?.room_category_id > 0) {
        setCodeCat(dataGuest.room_category_id);
      } else {
        fetchRoomCode(dataGuest);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest]);

  // useEffect(() => {
  //   let cleanUp = false;

  //   if (detail && !cleanUp) {
  //     console.log("This Run First!", detail);

  //     setFieldForm(detail);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [detail]);

  // SET FIELDS FORM
  const setFieldForm = async (val) => {
    const details = val;

    form.setFieldsValue({
      reservation_id: details?.reservation_id
        ? details.reservation_id
        : detail.reservation_id,
      guest_name:
        details?.guest_category === "INCOGNITO"
          ? "PRIVATE"
          : details?.guest_name,
      room_number: details?.room_number > 0 ? details.room_number : 0,
      room_category: details?.room_category
        ? details.room_category
        : detail.room_category,
      memo_room: details?.memo_room || detail?.memo_room,
    });
  };

  // SET COA
  const setCoa = (val) => {
    console.log("Existed COA --> ", val);

    setRoomSettings({
      chart_of_acount: val,
      // ...roomSettings,
    });
  };

  // GET ROOM
  const setRoomInfo = (val) => {
    const info = val?.length > 0 ? val[0] : val;
    console.log("Room Informations --> ", info);

    setCheckBox({
      is_connect: info?.is_connecting,
      is_smoke: info?.is_smoking,
    });

    setInfo(info);

    form.setFieldsValue({
      room_number: info?.label,
      number: info?.number,
      room_overlook: info?.room_overlook || "Data Unavailable",
      room_bedsetup: info?.room_type || "Data Unavailable",
      room_floor: info?.floor || "Data Unavailable",
      smoking_room: info?.is_smoking,
      connecting_room: info?.is_connecting,
    });
  };

  // GET ROOM NUMBER
  const getRoomNumber = (val, id, index) => {
    console.log("Get Room Numbers: ", val, id, index);

    setRoomSettings({
      // ...roomSettings,
      room_number: val?.number,
      chart_of_acount: val?.coa,
    });
  };

  // ON FINISH
  const onFinish = async (value) => {
    const submit = value;
    const number = {
      errorFields: [
        {
          errors: ["Room Number is Empty!"],
        },
      ],
    };

    console.log("ON FINISH --> ", submit);
    console.log("Details --> ", roomSettings);

    setIsLoading(true);

    if (value?.number > 0) {
      handleSubmit(submit);
    } else {
      onFinishFailed(number);
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Failed >>> ", error);

    incomplete(error);
    setOpen(true);
    setIsLoading(false);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    // e.preventDefault();
    const submit = e;

    // console.log("EDITED > ", roomSettings);

    await axios
      .put(
        `${baseurl}/reservation/room-number-group`,
        {
          data: [
            {
              room_number:
                roomSettings?.room_number > 0
                  ? roomSettings.room_number
                  : detail?.room_number > 0
                  ? detail.room_number
                  : submit?.number,
              price_id: detail?.price_id ? detail.price_id : submit?.price_id,
              reservation_id: detail?.reservation_id
                ? detail.reservation_id
                : submit?.reservation_id,
              group_id: detail?.group,
            },
          ],
          // updated_by: user_name,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Room Number Updated: ", response);

        successCheckIn(response, { method: 1 });
        // is_refresh(true);

        setNotShowing(submit?.is_no_show);
        fetchData();
        // fetchDataGuest();
        fetchDataPrice();

        setTimeout(() => {
          setOpenForm(!openForm);

          handleClose();
          setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log("Error Check In: ", error);
        failedCheckIn(error, { method: 1 });
        setIsLoading(false);
      });
  };

  // HANDLE CLOSE
  const handleClose = () => {
    form.resetFields();

    setIsLoading(false);
    setOpen(!open);

    closeModal(false);
    setRendered(true);

    setInfo(null);

    setCheckBox({
      is_connect: false,
      is_smoke: false,
    });
  };

  return (
    <>
      <Modal
        className="modal-check-in"
        title={
          <>
            <Row>
              <FiEdit style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Modal Check In`}</Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={800}
      >
        <Form
          name="checkin-form"
          className="checkin-form"
          key="checkin-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // labelCol={{ span: 12 }}
          //   wrapperCol={{ span: 12 }}
          initialValues={{
            is_no_show: false,
          }}
          autoComplete="off"
          layout="vertical"
        >
          <Row className="modal-row" justify="center" style={{ padding: 15 }}>
            <Col className="reservation-col" span={12}>
              <Row
                className="row-checkin-modal"
                justify="start"
                style={{ width: "100%", padding: "0px 10px" }}
              >
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Reservation No.</b>}
                  name="reservation_id"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Reservation No."
                    disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row
                className="row-checkin-modal"
                justify="start"
                style={{ width: "100%", padding: "0px 10px" }}
              >
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Guest Name</b>}
                  name="guest_name"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Guest Name"
                    disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row
                className="row-checkin-modal"
                justify="start"
                style={{ width: "100%", padding: "0px 10px" }}
              >
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Room Category</b>}
                  name="room_category"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Room Category"
                    disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row
                className="row-room-no"
                justify="start"
                style={{ width: "100%", padding: "0px 10px" }}
              >
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Room No.</b>}
                  name="room_number"
                  rules={[
                    {
                      required: true,
                      message: "Room Number is Empty!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <MasterRoomNumberCkIn
                    roomCode={roomSettings?.id > 0 ? roomSettings.id : codeCat}
                    getRoomNumber={getRoomNumber}
                    // getRoomNumberCode={setRoomNumberCode}
                    roomInfo={setRoomInfo}
                    // getRoomCoa={getRoomCoa}
                    // isClose={rendered}
                    // isClosed={setRendered}
                    room_number={
                      detail?.room_number ? detail.room_number : null
                    }
                    existed_coa={setCoa}
                    isGroup={false}
                  />
                </Form.Item>

                <Form.Item hidden label="Number" name="number">
                  <InputNumber className="room-number" />
                </Form.Item>
              </Row>

              <Row
                className="row-checkin-modal"
                justify="start"
                style={{ width: "100%", padding: "0px 10px" }}
              >
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>{`Memo Room`}</b>}
                  name="memo_room"
                  style={{ width: "100%" }}
                >
                  <Input.TextArea
                    placeholder="Memo Room"
                    disabled
                    style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row
                className="row-checkin-modal"
                justify="start"
                style={{ width: "100%", padding: "0px 10px" }}
              >
                <Form.Item
                  name="is_no_show"
                  valuePropName="checked"
                  label="Guest's Showing Status"
                  wrapperCol={{
                    // offset: 8,
                    span: 24,
                  }}
                >
                  <Checkbox>
                    <Typography
                      style={{ fontWeight: 750 }}
                    >{`No Show`}</Typography>
                  </Checkbox>
                </Form.Item>
              </Row>
            </Col>

            <Col
              className="room-info-col"
              span={12}
              style={{
                padding: "0px 30px 0px",
                border: "1px solid #000000",
                borderRadius: 5,
              }}
            >
              <Row style={{ fontSize: 20, fontWeight: 750 }}>
                Room Informations
              </Row>

              <Row>
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Room Floor</b>}
                  name="room_floor"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Room Floor"
                    value={info?.room_floor}
                    // disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Room Bedsetup</b>}
                  name="room_bedsetup"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Room Bedstup"
                    value={info?.room_bedsetup}
                    // disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label={
                    <b style={{ fontSize: "12px" }}>Room Overlook/Overview</b>
                  }
                  name="room_overlook"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Room Overlook"
                    value={info?.room_overlook}
                    // disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Col span={12} style={{ width: "100%" }}>
                  <Form.Item
                    label={<b style={{ fontSize: "12px" }}>Smoking Room</b>}
                    name="smoking_room"
                    // valuePropName="checked"
                    style={{ width: "100%" }}
                  >
                    <Checkbox
                      checked={checkBox.is_smoke}
                      // placeholder="Room Overlook"
                      // disabled
                      // style={{ fontSize: 12, width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ width: "100%" }}>
                  <Form.Item
                    label={<b style={{ fontSize: "12px" }}>Connecting Room</b>}
                    name="connecting_room"
                    // valuePropName="checked"
                    style={{ width: "100%" }}
                  >
                    <Checkbox
                      checked={checkBox.is_connect}
                      // placeholder="Room Overlook"
                      // disabled
                      // style={{ fontSize: 12, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px" }} />

          <Row justify="end" align="middle" className="btn-row">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>

      <RegistrationForm
        openModal={openForm}
        closeModal={setOpenForm}
        dataRes={openForm == true ? data : null}
        dataGuest={openForm == true ? guest : null}
        dataRoom={openForm == true ? room : []}
        dataArticle={openForm == true ? article : []}
        dataCoa={openForm == true ? roomSettings?.chart_of_acount : null}
        is_refresh={is_refresh}
        is_no_show={notShowing}
        is_checkin={true}
      />
    </>
  );
}

// Import React's Component
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Col, Layout, Row, Typography, Input, Space } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaShop } from "react-icons/fa6";
import { SearchOutlined } from "@ant-design/icons";

// Import Page Components
import OutletLocationTable from "../../../Components/Table/Master/OutletLocation/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function OutletLocationPage() {
  // STATE MANAGEMENT
  // Data
  const [value, setValue] = useState("");
  // Search
  const [search, setSearch] = useState(null);

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="table-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaShop
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Outlet Location`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              align="bottom"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Keywords`}</Typography>

                  <Input
                    allowClear
                    placeholder="Name"
                    onChange={handleSearch}
                    style={{
                      maxWidth: 300,
                      minWidth: 150,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="next-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <OutletLocationTable is_search={setSearch} searchKey={value} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// ANTD COMPONNETS
import { Button, Col, Form, Modal, Row, Table, Tooltip } from "antd";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// REACT ICONS
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// PAGE COMPONENTS
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";

// GLOBAL STYLE
import { mainBody } from "../../../../../Style/Global/Global";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// NOTIFICATIONS
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../../../Reusable/Notification/MasterNotif/Notification";

// GLOBAL API
import { baseurl } from "../../../../../API/Config/Api";
import { token, user_name } from "../../../../../API/Global/Payload";
import ModalWarehouse from "../../../../Modals/Inventory/Warehouse/Modal";

const { confirm } = Modal;

export default function WarehouseTable({ is_search, searchKey }) {
  // CONTEXT
  const { getWarehouseList } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState();
  const [bulks, setBulks] = useState(data);
  const [dataEdit, setDataEdit] = useState(null);
  // Loadings
  const [loading, setLoading] = useState(false);
  // Set Refresh
  const [refresh, setRefresh] = useState(false);
  // Modal add/edit
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // navigate
  const navigate = useNavigate();

  // Fetch Data
  const fetchData = async () => {
    await getWarehouseList({
      type: "warehouse",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("WAREHOUSE RESPONSE => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    setRefresh(false);
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USE EFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "delete-warehouse-confirm",
      title: `Are you sure want to delete warehouse named ${_data?.warehouse_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(_data);
      },

      onCancel() {
        setLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    var obj = {
      ["id_warehouse"]: contentdelete.id_warehouse,
      ["deleted_by"]: user_name,
    };

    axios
      .delete(`${baseurl}/master/warehouse`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        masterSuccessNotification(response, { method: 2, source: "Warehouse" });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Warehouse" });
      });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.warehouse_name.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  // HANDLE EXPORT EXCEL
  const handleExport = () => {
    downloadExcel(bulks, "Warehouse List");
  };

  // HANDLE RETURN TO MENU INVENTORY
  const handleReturn = () => {
    const path = "/back-office/inventory";

    navigate(`${path}`);
  };

  // Handle Open
  const handleOpen = () => {
    setOpen(true);
  };

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 100,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12} className="col-edit">
                <Tooltip className="edit-tooltip" title="Edit Data">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      handleOpen();
                      setEdit(true);

                      setDataEdit(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12} className="col-del">
                <Tooltip className="del-tooltip" title="Delete Data">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Warehouse Code",
      dataIndex: "warehouse_code",
      key: "warehouse_code",
    },
    {
      title: "Warehouse Name",
      dataIndex: "warehouse_name",
      key: "warehouse_name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.warehouse_name.localeCompare(b.warehouse_name),
    },
    {
      title: "Warehouse Location",
      dataIndex: "warehouse_location",
      key: "warehouse_location",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Created By",
    //   dataIndex: "created_by",
    //   key: "created_by",
    // },
    // {
    //   title: "Created Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   render: (created, record) => {
    //     return moment(created).format("DD MMM YYYY");
    //   },
    //   sorter: (a, b) => moment(a.created) - moment(b.created),
    // },
    // {
    //   title: "Updated By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    // },
    // {
    //   title: "Updated Date",
    //   dataIndex: "updated_date",
    //   key: "updated_date",
    //   render: (updated, record) => {
    //     return updated ? moment(updated).format("DD MMM YYYY") : null;
    //   },
    //   sorter: (a, b) => moment(a.updated) - moment(b.updated),
    // },
  ];

  return (
    <>
      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24} style={{ padding: 30 }}>
          <Row className="row-btn" gutter={[0, 15]}>
            <Col
              className="export-col"
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              className="modal-col"
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Warehouse`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 60, width: "100%" }}>
            <Table
              className="warehouse-table"
              name="warehouse-table"
              key="warehouse-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 700,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <ModalWarehouse
        openModal={open}
        closeModal={setOpen}
        dataWarehouse={dataEdit}
        is_refresh={fetchData}
        is_edit={edit}
        set_edit={setEdit}
      />
    </>
  );
}

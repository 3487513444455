// CONTEXT
import CreateContext from "../CreateContext";

// REDUCERS
const mainReducer = (state, action) => {
  return { ...state, ...action.value };
};

const addToStore =
  (dispatch) =>
  async ({ value }) => {
    console.log("VALUE WANT TO STORED IN CONTEXT >>>", value);
    dispatch({ type: "getData", value });
  };

// EXPORT CONTEXT and PROVIDER
export const { Provider, Context } = CreateContext(
  mainReducer,
  { addToStore },
  null
);

//* IMPORT STATE MANAGEMENT CONTEXT DI SETIAP KOMPONEN
// import { Context as StateManagementContext } from "../../../API/Context/StateManagementContext/StateManagementContext";

//* DEFINE VARIABEL DI SETIAP KOMPONEN
// const { addToStore, state: stateManagement } = useContext(
//   StateManagementContext
// );

//* FUNCTION UNTUK DIPANGGIL DI SETIAP KOMPONEN. NAMA KEY (ex: data1) BISA DIUBAH SESUAI KEBUTUHAN, UNTUK KEY VALUE KALO BISA FIX JANGAN DIUBAH
// addToStore({
//   value: {
//     data1: "TEST STATE PERTAMA",
//   },
// });

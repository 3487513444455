// Import React Components
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXt
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token, user_name } from "../../../API/Global/Payload";

// Import AntD Components
import {
  Col,
  Layout,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Checkbox,
  Modal,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icon Components
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import {
  BarsOutlined,
  DeleteFilled,
  DollarOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SplitCellsOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// Import Page Components
import FOBillModal from "../../../Components/Modals/Bill/Modal";
import FOCashierTable from "../../../Components/Table/FrontOffice/Cashier/Table";
import MasterPaymentTypeEdit from "../../../Components/Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterPaymentCardEdit from "../../../Components/Reusable/Master/PaymentCard/PaymentCardEdit";

// Import Functions
import { CurrencySymbols } from "../../../Components/Reusable/Functions/Currency/Currency";
import { BillArticleShow } from "../../../Components/Reusable/Functions/BillType/BillType";
import { AccountingParams } from "../../../Components/Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../../Components/Reusable/Functions/Parameters/ParamsFO";
import { LocalizationDate } from "../../../Components/Reusable/Functions/Localizations/Localization";

// Import Notifications
import {
  failedBill,
  failedCheckOut,
  failedFetch,
  successBill,
} from "../../../Components/Reusable/Notification/Notification";
import { masterIncomplete } from "../../../Components/Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";

// MODALS
const { confirm } = Modal;

// CODE
export default function FOPaymentPage(props) {
  // PROPS
  const { source } = props;

  // CONTEXT
  const { getDeposit } = useContext(MainContext);
  const { getMasterPaymentType } = useContext(MasterContext);

  // USE NAV
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  // Params
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;
  const accounting = AccountingParams();

  // STATE MANAGEMENT
  // DATA
  const [sources, setSources] = useState(0);
  const [cardData, setCardData] = useState(null);
  const [detail, setDetail] = useState([]);
  const [total, setTotal] = useState({
    total: 0,
    deposit: 0,
  });
  const [subTotal, setSubTotal] = useState({
    grand: 0,
    total: 0,
    tax: 0,
    service: 0,
  });
  // Payment
  const [payCode, setPayCode] = useState({
    id: 0,
    name: "",
    coa: "",
  });
  const [cards, setCards] = useState({
    id: 0,
    name: "",
  });
  const [cardSplit, setCardSplit] = useState(null);
  const [billType, setBillType] = useState([]);
  // Payment Types
  const [fieldsSplit, setFieldsSplit] = useState([]);
  const [optionValue, setOptionValue] = useState([]);
  // BOOL
  const [billModal, setBillModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [disable, setDisable] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async (value) => {
    await getDeposit({
      type: "deposit-res-stay",
      res_id: value?.reservation_id
        ? value.reservation_id
        : cardData?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Res Deposit: ", response);

        if (response?.data?.length > 0) {
          const _response = response.data;

          setTotal({
            deposit:
              _response[0]?.deposit_amount > 0
                ? _response[0].deposit_amount
                : 0,
            total: value?.total_price > 0 ? value?.total_price : total.total,
          });

          form.setFieldsValue({
            deposit_amount:
              _response[0]?.deposit_amount > 0
                ? _response[0].deposit_amount
                : 0,
          });
        } else {
          console.log("Depo is Zero: ", total, value);
          setTotal({
            deposit: 0,
            total: value?.total_price > 0 ? value.total_price : total.total,
          });

          form.setFieldsValue({
            deposit_amount: 0,
          });
        }
      },
      onReject: (error) => {
        console.log("ERROR: ", error);
        failedFetch(error);
      },
    });

    await axios
      .get(`${baseurl}/master/bill-status`)
      .then((response) => {
        console.log("Response: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _filter = _res.filter((items) => {
            return !items.is_deleted;
          });

          setBillType(_filter);
        } else {
          setBillType([]);
        }
      })
      .catch((error) => {
        console.log("Response Error: ", error);
      });
  };

  // Fetch Data
  const fetchDataPayment = async () => {
    await getMasterPaymentType({
      payment_type: "payment-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Payment Type => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          let option = filtered.map((dev) => ({
            value: dev?.payment_type_name,
            label: dev?.payment_type_name,
            code: dev?.payment_type_code,
            id: dev?.id,
          }));
          // console.log(option);

          setOptionValue(option);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    fetchDataPayment();

    if (source > 0 && !cleanUp) {
      setSources(source);
    }

    if (state && !cleanUp) {
      console.log("STATE: ", state);
    }

    if (reset && !cleanUp) {
      fetchData();
      if (optionValue.length === 0) fetchDataPayment();
      setReset(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state, cards, reset]);

  // Handle Open
  const handleOpen = () => {
    setBillModal(true);
  };

  // Handle Selected
  const handleSelected = async (value) => {
    const _data = value;
    console.log("Selected Data: ", _data);

    await fetchData(_data);

    setCardData(_data);
    form.resetFields([
      "payment_details",
      "payment_type",
      "split_payment",
      "payment_amount",
    ]);
    form.setFieldsValue({
      total_amount: _data?.total_price > 0 ? _data.total_price : total.total,
      change_amount: 0,
    });
  };

  // Payment Type
  const getPaymentType = (value, code, name) => {
    console.log("Payment type: ", value, code, name);

    // console.log(payCode);

    if (value?.id > 0) {
      setCards({
        id: value?.id,
        name: value?.name,
      });
    } else {
      setCards({
        id: 0,
        name: "",
      });
    }

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };

  // Payment Type Split
  const getPaymentTypeSplit = (value) => {
    const _values = value;
    const _index = value?.keys;

    const fields = form.getFieldsValue();
    const { split_payment } = fields;

    Object.assign(split_payment[_index], {
      payment_type: _values?.name,
      payment_type_id: _values?.id,
    });

    const _arr_type = split_payment.map((items) => {
      return items?.payment_type_id;
    });

    const _newOptions = optionValue.filter((items) => {
      if (!_arr_type.includes(items?.id)) {
        return items;
      }
    });

    form.setFieldsValue({
      split_payment,
    });

    console.log("New Options: ", _newOptions);

    setCardSplit(split_payment);
    setFieldsSplit(_newOptions);
  };

  // Payment Details/Card
  const getPayCard = (value, code, acc) => {
    console.log("Pay Card: ", value, code, acc);
    // const fieldvalue = form.getFieldsValue();

    // console.log(fieldvalue);

    if (value?.id) {
      setPayCode({
        id: value?.id,
        name: value?.name,
        coa: value?.coa,
      });
    } else {
      setPayCode({
        id: 0,
        name: "",
        coa: "",
      });
    }

    form.setFieldsValue({
      payment_details: value?.name,
    });
  };

  // GET CODE PAY
  const getCodePay = (values) => {
    console.log("GET PAY CARD CODE: ", values);
  };

  // Payment Card Split
  const getPayCardSplit = (value) => {
    const _values = value;
    const _index = value?.keys;

    // setCardSplit(cardSplit, _values);

    console.log("Value: ", _values);

    const fields = form.getFieldsValue();
    const { split_payment } = fields;
    console.log("Field Value: ", fields);

    Object.assign(split_payment[_index], {
      payment_details: _values?.name,
      payment_details_id: _values?.id,
      chart_of_account: _values?.coa,
    });

    form.setFieldsValue({
      split_payment,
    });

    setCardSplit(split_payment);
  };

  // ON FINISH
  const onFinish = (val) => {
    const _data = val;

    console.log("Finish: ", _data);
    console.log("Payment Details: ", payCode);

    const bill = billType.map((x) => {
      return x.bill_status === cardData?.bill_status;
    });

    console.log("Bill Status: ", bill);

    setLoading(true);

    if (billType.length > 0) {
      if (isSplit) {
        if (_data?.split_payment.length > 0) {
          showModalConfirm(_data);
        } else {
          onFinishFailed();
        }
      } else {
        setLoading(false);
        showModalConfirm(_data);
      }
    } else {
      fetchData();
      onFinishFailed();
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    setLoading(false);
    const _data = val?.values;

    const error = {
      errorFields: [
        {
          errors: ["Please, Add a Payment before Submit!"],
        },
      ],
    };

    console.log("Finish Failed: ", _data);
    console.log("Card Split: ", cardSplit);

    masterIncomplete(!_data ? error : val);
  };

  // SHOW MODAL CHECK-OUT
  const showModalConfirm = async (record) => {
    const _data = record;
    let _amount = `${total.total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    confirm({
      className: "checkout-confirm",
      title: `Payment Confirmations`,
      icon: (
        <DollarOutlined className="confirm-icon" style={{ color: "#1890ff" }} />
      ),
      content: `Are you sure you want to Submit a Payment for Bill ID: ${
        cardData?.bill_master || " - - - - - "
      } of Reservation ID ${
        cardData?.reservation_id || " - - - - - "
      } with total payment is ${currency} ${_amount}?`,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      okButtonProps: {
        type: "primary",
        className: "submit-btn",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        setLoading(false);
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Total Amount
  const handleTotal = (val) => {
    const _data = val?.total > 0 ? val.total : 0;
    if (val?.deposit == true) {
      fetchData();
    }

    console.log("TOTAL: ", _data);

    setIsSplit(false);
    setTotal({
      total: _data > 0 ? _data : total.total,
      deposit: total.deposit > 0 ? total.deposit : 0,
    });

    form.setFieldsValue({
      total_amount: _data,
      payment_amount: 0,
      change_amount: 0,
    });
  };

  // HANDLE CHANGE
  const handleChange = (val) => {
    let _change = val - (total.total - total.deposit);

    form.setFieldsValue({
      change_amount: _change,
    });
  };

  // Handle Total Split
  const handleTotalSplit = (key, value, is_del) => {
    const fields = form.getFieldsValue();
    const { split_payment } = fields;

    // console.log("Split Totals: ", split_payment);
    // console.log("Total Splitting: ", key, value);

    let _total = split_payment.reduce((acc, init) => {
      if (init?.payment_amount > 0) return (acc += init?.payment_amount);
    }, 0);

    // console.log("FIELDS: ", split_payment);
    console.log("Totals: ", _total, " Must pay: ", total.total);
    console.log("Disabling: ", _total >= total.total);

    if (is_del == true) {
      let _delTotal = _total - split_payment[key]?.payment_amount;

      form.setFieldsValue({
        split_payment: split_payment,
        change_amount: _delTotal - total.total,
        total_split: _delTotal > 0 ? _delTotal : 0,
      });
    } else {
      form.setFieldsValue({
        split_payment: split_payment,
        change_amount: _total - total.total,
        total_split: _total > 0 ? _total : 0,
      });
    }

    if (_total >= total.total) {
      console.log("BUTTON DISABLE");
      setDisable(true);
    } else {
      console.log("BUTTON ABLE");
      setDisable(false);
    }
  };

  // Handle Cancel
  const handleCancel = () => {
    form.resetFields();
    setTotal({
      deposit: 0,
      total: 0,
    });
    setCardData(null);

    setFieldsSplit(optionValue);
  };

  // Handle Split Payments
  const handleSplitPay = () => {
    setIsSplit(true);

    form.setFieldsValue({
      change_amount: 0,
      split_payment: [],
      total_split: 0,
    });
  };

  // Handel Cancel Split Payments
  const handleCancelSplit = () => {
    setIsSplit(false);
    setDisable(false);

    form.setFieldsValue({
      change_amount: 0,
    });
  };

  // Handle Add Split
  const handleAddSplit = (value) => {
    const _data = value.field.reduce((acc, init) => {
      return (acc += init?.payment_amount);
    }, 0);
    const _index = value.next;
    const payment_amount = total.total - _data;

    console.log("Add Split Payments: ", value);
    console.log("Data: ", _data);
    console.log("Next Index: ", _index);
    console.log("Payment amount: ", payment_amount);

    return payment_amount;
  };

  // Handle Submit
  const handleSubmit = async (val) => {
    const _data = val;
    let _success_array = [];
    let _response = [];

    let _allBody =
      _data?.split_payment?.length > 0
        ? _data.split_payment.map((items) => {
            return {
              bill_master: cardData?.bill_master,
              bill_amount: _data.total_amount > 0 ? _data.total_amount : 0,
              created_by: user_name,
              paid_amount: items.payment_amount > 0 ? items.payment_amount : 0,
              bill_status: billType.map((x) => {
                return x.bill_status === cardData?.bill_status;
              }).id,
              chart_of_account: items.chart_of_account,
              bill_receiver: cardData?.bill_receiver,
            };
          })
        : [
            {
              bill_master: cardData?.bill_master,
              bill_amount: _data.total_amount > 0 ? _data.total_amount : 0,
              created_by: user_name,
              paid_amount: _data.payment_amount > 0 ? _data.payment_amount : 0,
              bill_status: billType.map((x) => {
                return x.bill_status === cardData?.bill_status;
              }).id,
              chart_of_account: payCode.coa,
              bill_receiver: cardData?.bill_receiver,
            },
          ];

    console.log("Submitted: ", _data);
    console.log("Body: ", _allBody);

    for (let i = 0; i < _allBody.length; i++) {
      const element = _allBody[i];
      let _error = false;

      await axios
        .post(`${baseurl}/guest/payment-reservation`, element, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Payments: ", response);

          if (response.status == 200) {
            _success_array.push(true);
            _response.push(response.data.msg);
          }
        })
        .catch((error) => {
          console.log("Error Payments: ", error);

          _error = true;
          failedBill(error, { method: 0 });
        })
        .finally(() => {
          setLoading(false);
        });

      if (_error == true) {
        break;
      }
    }

    // console.log(_success_array);
    if (_allBody?.length != 0 && _success_array.length == _allBody?.length) {
      handleSuccess({ data: _data, response: _response });

      successBill(_response[0], { method: 0 });
    } else {
      onFinishFailed(null);
    }
  };

  // HANDLE SUCCESS
  const handleSuccess = (val) => {
    const data = {
      data: val?.data,
      response: val?.response,
      bill_header: cardData,
      list: detail,
    };

    console.log("Success: ", data);

    setTimeout(() => {
      setLoading(false);

      navigate(`${pathname}/success`, {
        state: data,
      });
    }, 1000);
  };

  return (
    <>
      <Col
        span={24}
        className="main-space"
        align="start"
        style={{ width: "100%" }}
      >
        <Row
          className="mainspace-row"
          align="start"
          justify="space-evenly"
          gutter={[30, 30]}
        >
          <Col
            className="article-list-col"
            xxl={{
              span: 16,
              order: 1,
            }}
            xl={{
              span: 16,
              order: 1,
            }}
            lg={{
              span: 16,
              order: 1,
            }}
            md={{
              span: 24,
              order: 1,
            }}
            sm={{
              span: 24,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 1,
            }}
          >
            <Row
              justify="space-between"
              className="row-btn"
              style={{
                padding: "30px 30px 0px",
                background: "#FFFFFF",
                borderRadius: 5,
              }}
            >
              <Typography.Title level={2}>{`Bill Invoice`}</Typography.Title>

              <Button
                className="next-btn"
                icon={<BarsOutlined />}
                type="primary"
                onClick={handleOpen}
              >
                {`Choose Bill`}
              </Button>
            </Row>

            <Divider
              className="divider-form"
              style={{
                margin: "5px 0px",
                backgroundColor: "#EBEDF3",
              }}
            />

            <FOCashierTable
              source={sources}
              bill_header={billModal ? null : cardData}
              bill_detail={billModal ? [] : detail}
              getTotal={handleTotal}
              selected_total={subTotal}
              // is_reset={reset}
              is_rereset={setReset}
            />
          </Col>

          <Col
            className="order-col"
            xxl={{
              span: 8,
              order: 2,
            }}
            xl={{
              span: 8,
              order: 2,
            }}
            lg={{
              span: 8,
              order: 2,
            }}
            md={{
              span: 24,
              order: 2,
            }}
            sm={{
              span: 24,
              order: 2,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Row
              justify="end"
              className="row-btn"
              style={{
                padding: 30,
                background: "#FFFFFF",
                borderRadius: 5,
              }}
            >
              <Col span={24} className="ordertable-col">
                <Row
                  className="row-payment"
                  justify="center"
                  align="middle"
                  style={{
                    background: `var(
              --button-secondary-hover,
              linear-gradient(262deg, #0a8781 0%, #2fd9d0 99.19%)
            )`,
                    borderRadius: 5,
                    // width: "100%",
                    // minHeight: "100%",
                  }}
                >
                  <Typography.Title
                    level={4}
                    className="text-form-title"
                    style={{
                      height: 67,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 750,
                      color: "#FFFFFF",
                    }}
                  >
                    {`Cashiers`}
                  </Typography.Title>
                </Row>

                <Divider
                  className="divider-form"
                  style={{
                    backgroundColor: "#000000",
                    margin: "10px 0px",
                    height: 2,
                  }}
                />

                <Row className="row-payment" justify="start" align="middle">
                  <Form
                    name="resv-form-modal"
                    className="resv-form-modal"
                    form={form}
                    labelCol={{ span: 8 }}
                    labelWrap={true}
                    wrapperCol={{ span: 16 }}
                    labelAlign="left"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="horizontal"
                    style={{
                      padding: 15,
                      width: "100%",
                      background: "#f5f5f5",
                    }}
                  >
                    <Row className="row-cashier" justify="start" align="middle">
                      {isSplit ? (
                        <Col span={24}>
                          <Form.Item
                            label="Total Amount"
                            name="total_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Sum Split Payment"
                            name="total_split"
                            rules={[
                              {
                                required: true,
                              },
                              {
                                validator: async (_, value) => {
                                  if (value < total.total)
                                    return Promise.reject(
                                      new Error(
                                        "The Split Amount is must be Equals or More than Total Amount!"
                                      )
                                    );
                                },
                              },
                            ]}
                            style={{
                              //   margin: "30px 0px 15px",
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Total Sum of Split Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Change Amount"
                            name="change_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Deposit Amount"
                            name="deposit_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Divider
                            className="divider-form"
                            style={{
                              margin: "5px 0px",
                              backgroundColor: "#EBEDF3",
                            }}
                          />

                          <Form.List name={"split_payment"}>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  // {
                                  //   console.log("KEY => ", key);
                                  //   console.log("NAME => ", name);
                                  //   console.log(...restField)
                                  <Col span={24} key={`paycol-${key + 1}`}>
                                    <Row
                                      className={`split-payments-${key}`}
                                      key={`${key}`}
                                    >
                                      <Form.Item
                                        {...restField}
                                        label={`Payment Type - ${name + 1}`}
                                        name={[name, "payment_type"]}
                                        wrapperCol={{
                                          span: 10,
                                        }}
                                        labelCol={{
                                          span: 14,
                                        }}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please, Select the Payment Type!",
                                          },
                                        ]}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <MasterPaymentTypeEdit
                                          getPaymentType={getPaymentTypeSplit}
                                          name={name}
                                          pay={null}
                                          is_split={true}
                                          split={fieldsSplit}
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        name={[name, "payment_type_id"]}
                                        hidden
                                      >
                                        <InputNumber />
                                      </Form.Item>
                                    </Row>

                                    <Row>
                                      <Form.Item
                                        label={`Payment Details - ${name + 1}`}
                                        name={[name, "payment_details"]}
                                        wrapperCol={{
                                          span: 10,
                                        }}
                                        labelCol={{
                                          span: 14,
                                        }}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please, Select the Payment Details!",
                                          },
                                        ]}
                                        style={{ minWidth: "100%" }}
                                      >
                                        <MasterPaymentCardEdit
                                          getPayCard={getPayCardSplit}
                                          getPayTypeId={
                                            cardSplit?.length > 0
                                              ? cardSplit[name]?.payment_type_id
                                              : 0
                                          }
                                          payCard={null}
                                          name={name}
                                          is_split={true}
                                          getCode={setPayCode}
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        name={[name, "payment_details_id"]}
                                        hidden
                                      >
                                        <InputNumber />
                                      </Form.Item>

                                      <Form.Item
                                        name={[name, "chart_of_account"]}
                                        hidden
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Row>

                                    <Row>
                                      <Form.Item
                                        label={`Payment Amount - ${name + 1}`}
                                        name={[name, "payment_amount"]}
                                        wrapperCol={{
                                          span: 10,
                                        }}
                                        labelCol={{
                                          span: 14,
                                        }}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please input the payment amount from customer!",
                                          },
                                          {
                                            validator: async (_, value) => {
                                              const _newTotal = total.total - 1;
                                              let _sum =
                                                form.getFieldsValue()
                                                  .total_split;

                                              const amounts =
                                                value > 0 ? value : 0;

                                              if (amounts == 0) {
                                                setDisable(true);

                                                return Promise.reject(
                                                  new Error(
                                                    "Amount cannot be Zero!"
                                                  )
                                                );
                                              }
                                              if (name == 0) {
                                                if (value > _newTotal) {
                                                  return Promise.reject(
                                                    new Error(
                                                      "Total Split Payment must be less than Total Price! If payments are Equals don't use Split Payment!"
                                                    )
                                                  );
                                                }
                                              } else {
                                                if (_sum > total.total) {
                                                  return Promise.reject(
                                                    new Error(
                                                      "Total Amount is cannot be Exceeded!"
                                                    )
                                                  );
                                                }
                                              }
                                            },
                                          },
                                        ]}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <InputNumber
                                          min={0}
                                          placeholder="Amount"
                                          addonBefore={currency}
                                          // disabled
                                          formatter={(unit_price) => {
                                            return `${
                                              unit_price > 0 ? unit_price : 0
                                            }`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            );
                                          }}
                                          style={{ width: "100%" }}
                                          onChange={(value) => {
                                            handleTotalSplit(
                                              name,
                                              value,
                                              false
                                            );
                                          }}
                                        />
                                      </Form.Item>
                                    </Row>

                                    <Row justify="end">
                                      <Tooltip
                                        className="del-tip"
                                        title="Delete / Remove"
                                      >
                                        <DeleteFilled
                                          color="#F64E60"
                                          onClick={() => {
                                            const category =
                                              form.getFieldsValue()
                                                .split_payment;

                                            handleTotalSplit(
                                              name,
                                              category,
                                              true
                                            );
                                            remove(name);
                                            setDisable(false);
                                          }}
                                          style={{
                                            fontSize: 30,
                                            color: "#F64E60",
                                            margin: 0,
                                          }}
                                        />
                                      </Tooltip>
                                    </Row>

                                    <Divider
                                      className="divider-form"
                                      style={{
                                        margin: "5px 0px",
                                        backgroundColor: "#EBEDF3",
                                      }}
                                    />
                                  </Col>
                                ))}

                                <Row justify="space-between">
                                  <Col>
                                    <Button
                                      type="primary"
                                      className="next-btn"
                                      onClick={() => {
                                        const category =
                                          form.getFieldsValue().split_payment;
                                        const _index =
                                          fields.length > 0
                                            ? fields[fields.length - 1]?.name +
                                              1
                                            : 0;

                                        const _results = handleAddSplit({
                                          field: category,
                                          next: _index,
                                        });

                                        if (_index > 0) {
                                          add({ payment_amount: _results });
                                          handleTotalSplit(
                                            _index,
                                            _results,
                                            false
                                          );
                                        } else {
                                          setFieldsSplit(optionValue);
                                          add();
                                        }

                                        setDisable(true);
                                      }}
                                      disabled={disable}
                                      icon={<PlusOutlined />}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      {`Add Payments Method`}
                                    </Button>
                                  </Col>

                                  <Col>
                                    <Button
                                      className="refresh-btn"
                                      onClick={handleCancelSplit}
                                      type="default"
                                    >
                                      {`Cancel`}
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Form.List>

                          <Divider
                            className="divider-form"
                            orientationMargin={0}
                            style={{
                              margin: "5px 0px",
                              backgroundColor: "#EBEDF3",
                            }}
                          />

                          <Row className="split-btn" justify="end">
                            <Button
                              htmlType="submit"
                              className="submit-btn"
                              type="primary"
                              icon={<DollarOutlined />}
                              loading={loading}
                            >
                              {`Submit & Pay`}
                            </Button>
                          </Row>
                        </Col>
                      ) : (
                        <Col span={24}>
                          <Form.Item
                            label="Total Amount"
                            name="total_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Payment Amount"
                            name="payment_amount"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input the payment amount from customer!",
                              },
                              {
                                validator: async (_, value) => {
                                  if (value < total.total)
                                    return Promise.reject(
                                      new Error(
                                        "The Payment Amount is must be Equals or More than Total Amount!"
                                      )
                                    );
                                },
                              },
                            ]}
                            style={{
                              //   margin: "30px 0px 15px",
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              // disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                              onChange={handleChange}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Change Amount"
                            name="change_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Deposit Amount"
                            name="deposit_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={currency}
                              disabled
                              formatter={(unit_price) => {
                                return `${
                                  unit_price > 0 ? unit_price : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Divider orientation="left" orientationMargin={0}>
                            {`Choose Payment`}
                          </Divider>

                          <Row>
                            <Form.Item
                              label="Payment Type"
                              name="payment_type"
                              rules={[
                                {
                                  required: true,
                                  message: "Please, Select the Payment Type!",
                                },
                              ]}
                              style={{ minWidth: "100%" }}
                            >
                              <MasterPaymentTypeEdit
                                getPaymentType={getPaymentType}
                                pay={null}
                              />
                            </Form.Item>
                          </Row>

                          <Row>
                            <Form.Item
                              label="Payment Details"
                              name="payment_details"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please, Select the Payment Details!",
                                },
                              ]}
                              style={{ minWidth: "100%" }}
                            >
                              <MasterPaymentCardEdit
                                getPayCard={getPayCard}
                                getPayTypeId={cards.id}
                                payCard={null}
                                // getCode={getCodePay}
                              />
                            </Form.Item>
                          </Row>

                          <Divider
                            className="divider-form"
                            style={{
                              margin: "15px 0px",
                              backgroundColor: "#EBEDF3",
                            }}
                          />

                          <Row
                            justify="end"
                            align="middle"
                            className="row-form-btn"
                            style={{ padding: 15 }}
                            gutter={[30, 30]}
                          >
                            <Button
                              className="submit-btn"
                              type="primary"
                              icon={<DollarOutlined />}
                              htmlType="submit"
                              loading={loading}
                              disabled={total.total > 0 ? false : true}
                              style={{
                                margin: "0px 15px 0px 0px",
                              }}
                            >
                              {`Submit & Pay`}
                            </Button>

                            <Button
                              className="next-btn"
                              type="primary"
                              icon={<SplitCellsOutlined />}
                              onClick={handleSplitPay}
                              loading={loading}
                              disabled={total.total > 0 ? false : true}
                            >
                              {`Split Payments?`}
                            </Button>

                            <Button
                              className="cancel-btn"
                              type="default"
                              onClick={handleCancel}
                              style={{
                                margin: "0px 0px 0px 15px",
                              }}
                            >
                              {`Cancel`}
                            </Button>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Form>
                </Row>
              </Col>
            </Row>

            <Row className="table-bill-row"></Row>
          </Col>
        </Row>
      </Col>

      <FOBillModal
        selected={handleSelected}
        selected_detail={setDetail}
        selected_total={setSubTotal}
        sources={sources}
        is_open={billModal}
        is_close={setBillModal}
        is_type={billType}
      />
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Input,
  List,
  Row,
  Space,
  Typography,
} from "antd";
import { IoRestaurant } from "react-icons/io5";
import { FaConciergeBell } from "react-icons/fa";
import { SearchOutlined } from "@ant-design/icons";

// Import Functions
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";

// Import Page Components
import EmptyListComponents from "../../../../Reusable/Empty/EmptyList";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CODE
export default function ArticleItemList(props) {
  // PROPS
  const { sub_selected, item_selected, menuDisabled, refresh } = props;

  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [bulkSearch, setBulkSearch] = useState([]);
  // Search Values
  const [searchKey, setSearchKey] = useState(null);
  // Loading
  const [loading, setLoading] = useState(true);

  // Fetch Data
  const fetchData = async () => {
    await getArticleList({
      list: "list",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        // console.log("Response Article List => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let _filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          console.log("Response Article => ", _filtered);
          setBulks(_filtered);
          // setBulks(filtered);
        } else {
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if(refresh && !cleanUp){
      setData([]);
      handleRefresh(true)
    };

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // USE EFFECTS FILTER
  useEffect(() => {
    let cleanUp = false;

    console.log(sub_selected);
    if (sub_selected && !cleanUp) {
      setLoading(true);
      handleFilter(sub_selected);
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [sub_selected]);

  // Handle Filter
  const handleFilter = (value) => {
    const _sub_id = value?.id;

    if (bulks.length > 0) {
      let _filtered = bulks.filter((val) => {
        if (val?.article_sub_id === _sub_id) {
          return val;
        }
      });

      // console.log("Sub-Group: ", _filtered);
      // console.log("All Articles: ", bulks);

      setData(_filtered);
      setBulkSearch(_filtered);
    } else {
      setData([]);
      setBulkSearch([]);
    }

    setLoading(false);
  };

  // Handle Pick
  const handlePick = (value) => {
    const _main = value;
    // console.log("Values: ", _main);

    item_selected(_main);
  };

  // Handle Change
  const handleChange = (e) => {
    let _values = e.target.value;
    e.preventDefault();

    setSearchKey(_values);

    handleSearch(_values);
  };

  // Handle Search
  const handleSearch = (val) => {
    const _searchValue = val.toLowerCase();

    if (bulkSearch.length > 0) {
      let _search = bulkSearch.filter((value) => {
        const _art = value?.article_name.toLowerCase();

        return _art.indexOf(_searchValue) !== -1;
      });

      // console.log("Search results: ", _search);
      setData(_search);
    } else {
      setData([]);
    }
  };

  // Refresh
  const handleRefresh = (val) => {
    if (val === true) {
      fetchData();
    }
  };

  return (
    <>
      <Col span={24} className="article-main-view">
        <ConfigProvider
          key="provider-list"
          renderEmpty={() => {
            return <EmptyListComponents is_refresh={handleRefresh} />;
          }}
        >
          <Row
            className="search-row"
            justify="start"
            align="top"
            style={{
              width: "100%",
            }}
          >
            <Space
              className="search-space"
              align="center"
              size={"middle"}
              wrap={true}
              style={{
                width: "100%",
                padding: "0px 0px 15px",
              }}
            >
              <Input
                className="search-input"
                onChange={handleChange}
                placeholder="Search Article Items"
                allowClear
                size="middle"
                style={{
                  width: "100%",
                }}
              />
            </Space>
          </Row>

          <Row className="list-row" justify="start" align="top">
            <Col span={24} className="main-list-col">
              <List
                className="articleitem-list"
                key={"articleitem-list"}
                itemLayout="vertical"
                dataSource={data}
                loading={loading}
                grid={{
                  gutter: 8,
                  // column: 2,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 2,
                  xxl: 2,
                }}
                renderItem={(item, index) => (
                  <List.Item key={item?.id} className="items-list-article">
                    <Card
                      className="card-table"
                      key={item?.id}
                      hoverable
                      loading={loading}
                      style={{
                        background: "rgba(246, 78, 96, 0.15)",
                        borderRadius: 5,
                        height: "100%",
                        width: "auto",
                      }}
                      bodyStyle={{
                        padding: 10,
                      }}
                      onClick={() => {
                        if(menuDisabled) {
                          handlePick(item, index);
                        } else {
                          null;
                        }
                        // setTableKey(item.table);
                      }}
                      //   onDoubleClick={() => {
                      //     handleOpen();
                      //   }}
                    >
                      <Row className="row-table" justify="start" align="middle">
                        <Col span={16} className="titletable-col">
                          <Typography
                            className="txt-h2"
                            style={{
                              fontWeight: 500,
                              fontSize: 12,
                            }}
                          >
                            {item?.article_name}
                          </Typography>

                          <Typography
                            className="price-txt"
                            style={{
                              fontWeight: 500,
                              fontSize: 12,
                              color: "#7E8299",
                            }}
                          >
                            {`${currency} ${item?.gross}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </Typography>
                        </Col>

                        <Col
                          span={8}
                          className="icontable-col"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            flexCirection: "column",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          <Row className="titletable-row">
                            <IoRestaurant
                              className="bell-icons"
                              color="#000000"
                              size={25}
                              style={{ margin: 0 }}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                )}
                style={{
                  border: "1px solid #E4E6EF",
                  borderRadius: 4,
                  padding: 15,
                }}
              />
            </Col>
          </Row>
        </ConfigProvider>
      </Col>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../API/Context/FinanceContext/FinanceContext";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  DatePicker,
  Tag,
  Checkbox,
} from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import MasterCOA from "../../../../Reusable/Accounting/MainAccount/MainAccount";

// Import Functions
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";
import downloadExcel from "../../../../Reusable/Functions/ExportXLSX/ExportExcel";
import { LocalizationDate } from "../../../../Reusable/Functions/Localizations/Localization";

// Import Global Style
import { mainBody } from "../../../../../Style/Global/Global";
import MasterStatusBudget from "../../../../Reusable/Master/StatusBudget/StatusBudget";

// Modal Confirm
const { confirm } = Modal;

const { Text, Link, Title } = Typography;

// CODE
export default function BudgetTable(props) {
  // PROPS
  const { is_search, searchKey, yearValue, statusValue } = props;

  // CONTEXT
  const { getBudget } = useContext(FinanceContext);

  // Navigate
  const navigate = useNavigate();

  // Params
  const currency = CurrencySymbols().code;

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      setOpen(true);
                      setEdit(true);
                      setId(record.id);
                      setEditData(record);
                      setIsDelete(false);

                      setFieldsForm(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      // width: 250,
      render: (budget, record) => {
        let bdgt = budget > 0 ? budget : 0;
        let unit = "";

        switch (record?.unit) {
          case "Rp":
            unit = currency;
            break;

          case "Unit":
            unit = "Unit";
            break;

          case "%":
            unit = "%";
            break;

          default:
            break;
        }

        return bdgt > 0
          ? `${bdgt}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ` ( ${unit} )`
          : "-";
      },
    },
    {
      title: "COA",
      dataIndex: "account_code",
      key: "account_code",
      width: 120,
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      // width: 250,
    },
    {
      title: "Approved By",
      dataIndex: "approved_by",
      key: "approved_by",
      // render: (approve) => {
      //   let txt = ""
      //   let clr = ""

      //   switch (approve) {
      //     case true:
      //       txt = "Approved"
      //       clr = 'green'
      //       break;

      //     case false:
      //       txt = "Approved"
      //       clr = 'green'
      //       break;

      //     default:
      //       break;
      //   }

      //   return
      //  }
    },
    {
      title: "Approved Date",
      dataIndex: "approved_date",
      key: "approved_date",
      render: (date) => {
        return date
          ? moment(LocalizationDate(date)).format("HH:mm:ss, DD-MM-YYYY")
          : null;
      },
    },
    {
      title: "Budget Status",
      dataIndex: "status_budget",
      key: "status_budget",
      render: (stat, record) => {
        let txt = "";
        let clr = "";

        // console.log("Status Budget: ", stat);

        switch (stat) {
          case 0:
            txt = "Proposed";
            clr = "processing";
            break;

          case 1:
            txt = "Approved";
            clr = "#2db7f5";
            break;

          case 2:
            txt = "Rejected";
            clr = "red";
            break;

          case 3:
            txt = "Subject To Revise";
            clr = "warning";
            break;

          case 4:
            txt = "Inactive";
            clr = "default";
            break;

          case 5:
            txt = "Active";
            clr = "success";
            break;

          default:
            // txt = "UNSET";
            // clr = "purple";
            break;
        }

        return stat != null ? (
          <Tag color={clr} className="status-tag" key={"status-tag"}>
            {txt}
          </Tag>
        ) : null;
      },
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (year, record) => {
        return year ? moment(year).format("YYYY") : "-";
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Edit
  const [editData, setEditData] = useState([]);
  const [filteredList, setFilteredList] = useState(data);
  const [yearVal, setYearVal] = useState(null);
  const [satuan, setSatuan] = useState(null);
  const [budgetValueMonthly, setBudgetValueMonthly] = useState([
    {
      month_1: 0,
      month_2: 0,
      month_3: 0,
      month_4: 0,
      month_5: 0,
      month_6: 0,
      month_7: 0,
      month_8: 0,
      month_9: 0,
      month_10: 0,
      month_11: 0,
      month_12: 0,
    },
  ]);
  // Year Filter
  const [yearFilter, setYearFilter] = useState(yearValue);
  // ID
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  // Budget State
  const [isBudget, setIsBudget] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getBudget({
      type: "budget",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          let filtered = _response.filter((filt) => {
            return !filt.is_deleted;
          });

          setFilteredList(filtered);
          setData(filtered);
        } else {
          setData([]);
          setFilteredList([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Failed Get Budget: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    let cleanUp = false;

    is_search(() => handleSearch);

    if (yearValue !== yearFilter && yearValue > 0 && !cleanUp) {
      console.log("Year Value: ", yearValue);
      console.log("Year Filter: ", yearFilter);

      setYearFilter(yearValue);
      handleFilter(yearValue);
    }

    if (statusValue != null && !cleanUp) {
      console.log("Status Value: ", statusValue);

      handleStatus(statusValue);
    }

    return () => {
      cleanUp = true;
    };
  }, [searchKey, yearValue, statusValue]);

  // SET FORM FIELDS
  const setFieldsForm = (val) => {
    const record = val;

    setBudgetValueMonthly([
      {
        month_1: record?.month_1,
        month_2: record?.month_2,
        month_3: record?.month_3,
        month_4: record?.month_4,
        month_5: record?.month_5,
        month_6: record?.month_6,
        month_7: record?.month_7,
        month_8: record?.month_8,
        month_9: record?.month_9,
        month_10: record?.month_10,
        month_11: record?.month_11,
        month_12: record?.month_12,
      },
    ]);

    form.setFieldsValue({
      budget: record?.budget > 0 ? record.budget : 0,
      unit: record?.unit,
      account_code: record?.account_code,
      account_name: record?.account_name,
      year: moment(record?.year).format("YYYY"),

      created_by: record?.created_by,
      created_date: record?.created_date,
      updated_by: record?.updated_by,
      updated_date: record?.updated_date,
      approve_by: record?.approved_by,
      approve_date: record?.approved_date,

      month_1: record?.month_1,
      month_2: record?.month_2,
      month_3: record?.month_3,
      month_4: record?.month_4,
      month_5: record?.month_5,
      month_6: record?.month_6,
      month_7: record?.month_7,
      month_8: record?.month_8,
      month_9: record?.month_9,
      month_10: record?.month_10,
      month_11: record?.month_11,
      month_12: record?.month_12,
      is_budget: isBudget,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
    setIsDelete(false);
  };

  // Handle Return
  const handleReturn = () => {
    const _path = "/back-office/budget";

    navigate(_path);
  };

  // Handle Change Checkbox
  const handleIsBudget = (value) => {
    const checked = value.target.checked;

    setIsBudget(checked);
  };

  // HANDLE FILTER BY YEAR
  const handleFilter = (val) => {
    setLoading(true);

    const _res = val;

    let _filtered = filteredList.filter((value) => {
      let _year = value?.year ? moment(value.year).year() : 0;

      // console.log(`YEAR is EQUALS: ${_year} === ${_res}`);

      if (_year === _res) {
        return value;
      }
    });

    console.log("Filtered by Year: ", _filtered);

    setData(_filtered);
    setLoading(false);
  };

  // HANDLE FILTER BY STATUS
  const handleStatus = (val) => {
    setLoading(true);

    const _stat = val;

    if (filteredList.length > 0) {
      let _filtered = filteredList.filter((value) => {
        const _status = value?.status_budget >= 0 ? value.status_budget : null;

        // console.log(`${_year} === ${_isYear}`);

        if (_status === _stat) {
          return value;
        }
      });

      console.log("Filtered by Status Budget: ", _filtered);

      setData(_filtered);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  // Master COA
  const getCoaName = (value) => {
    const _val = value;
    console.log(value);

    form.setFieldsValue({
      account_code: _val?.code,
      account_name: _val?.name,
    });
  };

  // Get Year
  const getYear = (_, e) => {
    console.log("Get Year: ", e);

    const _yearSelected = e;
    setYearVal(_yearSelected);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();
    setSatuan(null);
    setEditData([]);
    setId(null);

    setIsLoading(false);

    setIsDelete(true);
    setIsBudget(false);
    // setEditData(null);

    setYearVal(null);
    setBudgetValueMonthly([
      {
        month_1: 0,
        month_2: 0,
        month_3: 0,
        month_4: 0,
        month_5: 0,
        month_6: 0,
        month_7: 0,
        month_8: 0,
        month_9: 0,
        month_10: 0,
        month_11: 0,
        month_12: 0,
      },
    ]);

    setEdit(false);
    setOpen(false);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let del = record;

    confirm({
      className: "delete-confirm",
      title: isDelete
        ? `Are you sure want to delete ${del?.account_name.toUpperCase()} from the table?`
        : `Are you sure want to ${
            edit ? "Update" : "Add New"
          } Budget ${del?.account_name.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: isDelete ? "Delete" : "Confirm",
      okType: isDelete ? "danger" : "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(del) : handleSubmit(del);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      okButtonProps: {
        className: isDelete ? "danger-btn" : "submit-btn",
        type: "primary",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    // console.log(value);
    const content = value;

    setIsLoading(true);

    // handleSubmit(content);
    showDeleteModal(content);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log("Failed: ", e?.values);
    masterIncomplete(e);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;

    console.log("ON OK ==> ", contentsubmit);
    // console.log("ROUNDED ==> ", contentsubmit?.month_1.toFixed(0));

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/accounting/budget`,
          {
            created_by: user_name,
            budget:
              contentsubmit?.budget > 0 ? parseInt(contentsubmit.budget) : null,
            unit: contentsubmit?.unit,
            month_1: contentsubmit?.month_1.toFixed(0) || null,
            month_2: contentsubmit?.month_2.toFixed(0) || null,
            month_3: contentsubmit?.month_3.toFixed(0) || null,
            month_4: contentsubmit?.month_4.toFixed(0) || null,
            month_5: contentsubmit?.month_5.toFixed(0) || null,
            month_6: contentsubmit?.month_6.toFixed(0) || null,
            month_7: contentsubmit?.month_7.toFixed(0) || null,
            month_8: contentsubmit?.month_8.toFixed(0) || null,
            month_9: contentsubmit?.month_9.toFixed(0) || null,
            month_10: contentsubmit?.month_10.toFixed(0) || null,
            month_11: contentsubmit?.month_11.toFixed(0) || null,
            month_12: contentsubmit?.month_12.toFixed(0) || null,
            account_code: contentsubmit?.account_code,
            account_name: contentsubmit?.account_name,
            year: moment(yearVal).format("YYYY-MM-DD"),
            status_budget: 0,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("POST Budget => ", response);

          masterSuccessNotification(response, { method: 0, source: "Budget" });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("POST Budget => ", error);

          masterFailedNotification(error, { method: 0, source: "Budget" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/accounting/budget`,
          {
            budget:
              contentsubmit?.budget >= 0
                ? parseInt(contentsubmit.budget)
                : parseInt(editData?.budget),
            unit: contentsubmit?.unit || editData?.unit,
            account_code: contentsubmit?.account_code || editData?.account_code,
            account_name: contentsubmit?.account_name || editData?.account_name,
            month_1: contentsubmit?.month_1 || editData?.month_1,
            month_2: contentsubmit?.month_2 || editData?.month_2,
            month_3: contentsubmit?.month_3 || editData?.month_3,
            month_4: contentsubmit?.month_4 || editData?.month_4,
            month_5: contentsubmit?.month_5 || editData?.month_5,
            month_6: contentsubmit?.month_6 || editData?.month_6,
            month_7: contentsubmit?.month_7 || editData?.month_7,
            month_8: contentsubmit?.month_8 || editData?.month_8,
            month_9: contentsubmit?.month_9 || editData?.month_9,
            month_10: contentsubmit?.month_10 || editData?.month_10,
            month_11: contentsubmit?.month_11 || editData?.month_11,
            month_12: contentsubmit?.month_12 || editData?.month_12,
            year: yearVal
              ? moment(yearVal).format("YYYY-MM-DD")
              : moment(editData?.year).format("YYYY-MM-DD"),
            status_budget:
              contentsubmit?.status_budget >= 0
                ? contentsubmit.status_budget
                : editData?.status_budget,
            id: id,
            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("UPDATE Budget => ", response);

          masterSuccessNotification(response, { method: 1, source: "Budget" });

          handleCancel();
          fetchData();
          form.resetFields();
        })
        .catch((error) => {
          console.log("UPDATE Budget => ", error);

          masterFailedNotification(error, { method: 1, source: "Budget" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record;

    console.log(contentdelete);

    const obj = {
      ["id"]: contentdelete?.id,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/accounting/budget`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Budget => ", response);

        masterSuccessNotification(response, { method: 2, source: "Budget" });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Budget" });
        console.log("DEL Budget => ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle Satuan
  const handleSatuan = (e) => {
    setSatuan(e);

    form.setFieldsValue({
      unit: e,
    });
  };

  // HANDLE CHANGE
  const handleBudgetStatus = (value) => {
    const bdgt = value;

    // console.log("Budget Value: ", bdgt);

    form.setFieldsValue({
      status_budget: bdgt?.value,
    });
  };

  // HANDLE BUDGET MONTHLY
  const handleBudgetYearly = (e) => {
    const val = e;
    let arr = [];
    let obj = {};

    console.log("Satuan: ", satuan);

    for (let i = 1; i < 13; i++) {
      obj[`month_${i}`] = satuan == "%" ? val : val / 12;
    }

    arr.push(obj);

    console.log("Array Handle: ", obj);

    setBudgetValueMonthly(arr);
    form.setFieldsValue(obj);
    // console.log(Object.keys(arr[1]));
  };

  // Handle Budget
  const handleBudgetMonthly = (e, v) => {
    const val = e;
    const month = v;

    let arr = [];
    let obj = {};

    obj[`month_${month}`] = val;

    const join = Object.assign({}, budgetValueMonthly[0], obj);

    const sumValues = Object.values(join).reduce((a, b) => a + b, 0);

    console.log("OBJ: ", obj);
    console.log("Joined: ", join);
    console.log("Total Summaries: ", sumValues);

    arr.push(join);

    setBudgetValueMonthly(arr);

    if (satuan == "%") {
      form.setFieldsValue({
        budget: sumValues / 12,
      });
    } else {
      form.setFieldsValue({
        budget: sumValues,
      });
    }
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Budget List");
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    // console.log("Type = ", query);
    // console.log("Update List = ", updatedList);

    if (filteredList.length > 0) {
      let updatedList = filteredList.filter((item) => {
        // console.log("ITEM => ", item)
        const _names = item?.account_name.toLowerCase();

        return _names.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setData(updatedList);
      // console.log("FILTERED => ", updatedList);
    } else {
      setData([]);
    }
  };

  // console.log("Edit Data >>>", editData);
  //   console.log(form.getFieldValue().unit);

  return (
    <>
      <Row
        className="payment-type-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            className="row-modal-btn"
            gutter={[0, 15]}
            style={{ width: "100%" }}
          >
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Budget`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="pay-type-table"
              key="pay-type-table"
              name="pay-type-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-pay-type-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add New Budget`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Budget`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="pay-type-modal-form"
          name="pay-type-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            is_budget: isBudget,
          }}
          style={{ padding: "0px 36px" }}
        >
          <Form.Item name="unit" label="Unit" hidden>
            <Input placeholder="Unit" />
          </Form.Item>

          <Form.Item name="account_name" hidden>
            <Input placeholder="Account" />
          </Form.Item>

          <Row gutter={30} className="pay-type-coa">
            <Col span={8}>
              <Form.Item
                label="Year"
                name="year"
                valuePropName={"year"}
                rules={[
                  {
                    required: true,
                    message: "Please, input Year!",
                  },
                ]}
                style={{
                  width: "100%",
                }}
              >
                <DatePicker
                  onChange={getYear}
                  value={
                    yearVal != null
                      ? moment(yearVal)
                      : editData.year
                      ? moment(`${editData.year}`, "YYYY")
                      : null
                  }
                  picker="year"
                  placeholder="Year"
                  format={"YYYY"}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Change Status Budget"
                name="status_budget"
                hidden={!edit}
              >
                <MasterStatusBudget
                  getStatus={handleBudgetStatus}
                  status={open ? editData?.status_budget_name : null}
                  is_approve={false}
                  is_disable={editData?.status_budget == 0 ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-budget-coa">
            <Col span={16}>
              <Form.Item
                label="COA"
                name="account_code"
                rules={[
                  {
                    required: true,
                    message: "Please, input Account Code (COA)!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <MasterCOA
                  getCoaName={getCoaName}
                  coa_name={edit == true ? editData.account_code : ""}
                  // is_fromRoom={false}
                  is_all={true}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-type-coa">
            <Col span={16}>
              <Form.Item
                label="Unit"
                name="unit"
                rules={[
                  {
                    required: true,
                    message: "Please, input the Unit!",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  className="unit-select"
                  onChange={(e) => {
                    handleSatuan(e);
                  }}
                  options={[
                    {
                      value: "Rp",
                      label: "Rp",
                    },
                    {
                      value: "%",
                      label: "% (Percentages/Averages)",
                    },
                    {
                      value: "Unit",
                      label: "Unit",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="checkbox">
              <Form.Item
                label="Input Budget"
                name="is_budget"
                valuePropName="checked"
              >
                <Checkbox
                  className="is_budget"
                  onChange={handleIsBudget}
                >{`Input Budget`}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-type-coa">
            <Col span={24}>
              <Form.Item
                label="Annual Budget"
                name="budget"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  disabled={edit}
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetYearly(e);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            orientation="left"
            orientationMargin="0"
            plain
            style={{ margin: "5px 0px" }}
          >
            {`Budget Per Month`}
          </Divider>

          <Row gutter={30} className="pay-type-coa">
            <Col span={8}>
              <Form.Item
                label="January"
                name="month_1"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 1);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="February"
                name="month_2"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 2);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="March"
                name="month_3"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 3);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-type-coa">
            <Col span={8}>
              <Form.Item
                label="April"
                name="month_4"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 4);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="May"
                name="month_5"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 5);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="June"
                name="month_6"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 6);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-type-coa">
            <Col span={8}>
              <Form.Item
                label="July"
                name="month_7"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 7);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="August"
                name="month_8"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 8);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="September"
                name="month_9"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 9);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-type-coa">
            <Col span={8}>
              <Form.Item
                label="October"
                name="month_10"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 10);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="November"
                name="month_11"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 11);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Desember"
                name="month_12"
                rules={[
                  {
                    required: isBudget,
                    message: "Please, input Budget Nominal!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <InputNumber
                  placeholder="Input Budget"
                  addonBefore={satuan == "Rp" ? currency : null}
                  addonAfter={satuan != "Rp" ? satuan : null}
                  min={0}
                  formatter={(e) => {
                    return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }}
                  onChange={(e) => {
                    handleBudgetMonthly(e, 12);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "5px 0px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="mod-date-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Approve/Rejected By"
                    name="approve_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Approve/Rejected by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Approve/Rejected Date"
                    name="approve_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Approve/Rejected Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row justify="end" align="middle" style={{ padding: "5px 0px" }}>
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                margin: "0px 30px 0px 0px",
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// AUTH
import { user_level } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import { Button, Col, Row, Select, Tooltip } from "antd";
import { AppstoreFilled } from "@ant-design/icons";

// CODE
export default function MasterApprovalType(props) {
  // PROPS
  const { approvalTypeEdit, ApprovalType, disabled } = props;

  // CONTEXT
  const { getFetchMaster } = useContext(MasterContext);

  // STATE MANAGEMENT
  const [appType, setAppType] = useState(null);
  const [optionValue, setOptionValue] = useState([]);

  // Use Navigation
  const navigate = useNavigate();

  // Move to Page
  const move = () => {
    navigate("/configuration/master/department");
  };

  // FETCH GUEST DATA
  const fetchData = async () => {
    await getFetchMaster({
      master: "approval-type",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Approval Type => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filterData = _res.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) {
              console.log("User Levels: ", user_level);

              if (user_level > 3) {
                return val;
              } else {
                return val?.id < 3;
              }
            }
          });

          handleMap(filterData);
        } else {
          setOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    // console.log("UE select Approval Flow >> ", approvalTypeEdit);

    if (approvalTypeEdit && !cleanUp) {
      // console.log("UE select dept >> ", department_name);
      setAppType(approvalTypeEdit);
    } else {
      setAppType(null);
    }

    return () => {
      cleanUp = true;
    };
  }, [approvalTypeEdit]);

  // Handle Map
  const handleMap = (val) => {
    const _data = val;

    let filtered = _data.map((app_type) => {
      return {
        value: app_type?.id,
        label: app_type?.name,
      };
    });

    console.log("Value: ", filtered);
    setOptionValue(filtered);
  };

  // Handle Change
  const handleChange = (e, allKeys) => {
    const _value = e;
    const _keys = allKeys;

    setAppType(_value);
    ApprovalType({
      id: _keys?.value,
    });
  };

  // Handle Clear
  const handleClear = () => {
    setAppType(null);
  };

  return (
    <>
      <Select
        className="master-dept"
        name="master-dept"
        key="master-dept"
        value={appType}
        disabled={disabled}
        showSearch
        allowClear
        placeholder="Budget Allocation"
        onChange={handleChange}
        onClear={handleClear}
        options={optionValue}
        style={{ width: "100%" }}
      />
    </>
  );
}

// Import React's Component
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { FaHistory } from "react-icons/fa";
import { FiRefreshCw } from "react-icons/fi";
import { PlusOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import HistoryGuestTable from "../../../Components/Table/FrontOffice/HistoryGuest/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// CODE
export default function HistoryGuestPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [date, setDate] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = async (e) => {
    e.preventDefault();
    setValue(e.target.value);

    // console.log("HANDLE SEARCH = ", e.target.value);
  };

  // HANDLE DATE
  const onChangeDate = async (val) => {
    const content = moment(val).format("YYYY-MM-DD");

    console.log("Date --> ", content);
    setDate(content);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="history-guest-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaHistory
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                className="text-list"
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Departed Guest List`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  className="col-search"
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    className="text-search"
                    style={{ marginBottom: 10 }}
                  >
                    {`Reservation Name`}
                  </Typography>

                  <Input
                    className="input-search"
                    allowClear
                    placeholder="Guest's Name"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                    }}
                  />
                </Col>

                <Col
                  className="col-date-picker"
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography style={{ marginBottom: 10 }}>
                    {`Departed Date`}
                  </Typography>

                  <Input.Group>
                    <DatePicker
                      className="history-date-picker"
                      allowClear
                      onChange={onChangeDate}
                      style={{ minwidth: "100%" }}
                    />
                  </Input.Group>
                </Col>

                <Col
                  className="col-search-btn"
                  style={{
                    // margin: "54px 0px 0px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <HistoryGuestTable
              is_search={setSearch}
              searchKey={value}
              searchDate={date}
            />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

// REACT COMPONENTS
import React from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Card, Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { FaTruckRampBox } from "react-icons/fa6";
import { ImBoxAdd } from "react-icons/im";
import {
  TbArticleOff,
  TbDevicesCancel,
  TbReportAnalytics,
  TbTruckReturn,
} from "react-icons/tb";
import {
  BsBoxSeam,
  BsFillBoxSeamFill,
  BsFillJournalBookmarkFill,
} from "react-icons/bs";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { MdPriceCheck } from "react-icons/md";

// GLOBAL STYLES
import {
  globalIcon,
  globalText,
  gridStyle,
  linkStyle,
  mainBody,
} from "../../../../../Style/Global/Global";

export default function IncomingStockReportPage() {
  // NAVIGATE
  const navigate = useNavigate();

  // MOVE
  const move = (value) => {
    console.log("move --> ", value);

    navigate(`${value}`);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row
              className="row-title"
              justify="start"
              style={{
                backgroundColor: "#FFFFFF",
                margin: "0px 0px 30px",
                borderRadius: 5,
              }}
            >
              <FaTruckRampBox
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Incoming Stock Reports`}
              </Typography>
            </Row>

            <Row
              // gutter={[30, 30]}
              className="row-body"
              justify="start"
              style={mainBody}
            >
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("stock-article-incoming")}
                    // type="primary"
                    icon={<ImBoxAdd style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Stock Article Incoming`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("receiving")}
                    // type="primary"
                    icon={<TbReportAnalytics style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Receiving`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("receiving-without-po")}
                    // type="primary"
                    icon={<TbArticleOff style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Receiving W/O PO`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("journal-incoming-stock")}
                    // type="primary"
                    icon={<BsFillJournalBookmarkFill style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Journal of Incoming Stock`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              {/* <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("stock-article-return")}
                    // type="primary"
                    icon={<TbTruckReturn style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Stock Article Return`}
                    </Typography>
                  </Button>
                </Card>
              </Col> */}

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("cancelled-receiving")}
                    // type="primary"
                    icon={<TbDevicesCancel style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Cancelled Receiving`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("receiving-summary")}
                    // type="primary"
                    icon={<HiOutlinePencilSquare style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Receiving Summary`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("price-discrepancy")}
                    // type="primary"
                    icon={<MdPriceCheck style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Price Discrepancy`}
                    </Typography>
                  </Button>
                </Card>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Card className="card-link" style={gridStyle}>
                  <Button
                    className="move-btn"
                    onClick={() => move("annual-receiving")}
                    // type="primary"
                    icon={<BsBoxSeam style={globalIcon} />}
                    style={linkStyle}
                  >
                    <Typography className="text-page" style={globalText}>
                      {`Annual Receiving`}
                    </Typography>
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}

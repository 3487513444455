// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import { Button, Col, DatePicker, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Icons
import { SearchOutlined } from "@ant-design/icons";
import { BsJournalCheck } from "react-icons/bs";

// Import Global Styles
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// PAGE COMPONENTS
import ClosingMonthTable from "../../../../Components/Table/Accounting/Closing/Table";
import moment from "moment";

// CODE
export default function MonthlyClosingPage() {
  // STATE MANAGAMENT
  // Search Key
  const [value, setValue] = useState(null);
  const [datePicked, setDatePicked] = useState(null);

  // HANDLE DATE YEAR MONTH
  const handleDate = (val) => {
    setDatePicked(val);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    if (datePicked) {
      setValue(datePicked);

      console.log("Date Picked: ", datePicked);
    } else {
      setValue(null);
    }
  };

  // HANDLE DISABLE FUTURE DATE
  const disabledDate = (current) => {
    return current && current > moment().startOf("day");
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="close-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <BsJournalCheck
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Closing Period`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    width: 200,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Select Month & Year`}</Typography>

                  <DatePicker
                    allowClear
                    disabledDate={disabledDate}
                    placeholder="Month & Year"
                    className="closing-datepicker"
                    picker="month"
                    onChange={handleDate}
                    style={{
                      width: "100%",
                    }}
                  />
                </Col>

                <Col
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <ClosingMonthTable yearMonth={value} />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

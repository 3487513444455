// Import React Components
import React from "react";
import { Link, useLocation } from "react-router-dom";

// Import Ant Design Components
import { Breadcrumb, Row, Typography } from "antd";
import { BsDot } from "react-icons/bs";

// Breadcumb List Item
import { breadcrumbNameMap, breadcrumbTitleMap } from "./BreadCrumbItem";

// CODE
export default function BreadCrumbComponents() {
  const location = useLocation();
  // console.log("BREADCUMB LOCATION => ", location);

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  // console.log("Path Snippet", pathSnippets);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    // console.log("url => ", url);
    // const className = "breadcumb-item-list";

    return (
      <Breadcrumb.Item
        key={url}
        // separator={
        //   <BsDot
        //     className="separator-icon"
        //     style={{
        //       color: "#B5B5C3",
        //       // margin: "5px 0px 0px",
        //     }}
        //   />
        // }
        className="breadcumb-item"
      >
        <Link
          key={`breadcumb-link`}
          to={url}
          style={{
            color: "#B5B5C3",
            fontSize: 13,
            // paddingTop: 10,
            // listStyleType: "none",
          }}
        >
          {breadcrumbNameMap[url] || " --- "}
        </Link>
      </Breadcrumb.Item>
    );
  });

  // console.log("extra breadcumbs", extraBreadcrumbItems);

  const breadcrumbItems = [
    <Breadcrumb key={"page-title"} className="breadcumb-title" separator={"-"}>
      <Typography
        style={{
          fontSize: 15,
          fontWeight: 500,
          color: "#3F4254",
          marginRight: 15,
          // paddingBottom: 15,
          // listStyleType: "none",
          // display: "flex",
          // flexDirection: "row",
        }}
      >
        {breadcrumbTitleMap[pathSnippets[0]]}
      </Typography>
      {/* <Breadcrumb.Separator children={"  "} /> */}
    </Breadcrumb>,
  ];
  // .concat(extraBreadcrumbItems);

  // console.log(myClass[9]);
  // console.log(element);
  // console.log(document.querySelector("li:not([class])"));

  return (
    <>
      {/* {breadcrumbItems} */}
      <Typography
        className="breadcrumb-title-text"
        style={{
          color: `#3F4254`,
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          margin: "0px 15px 0px 0px",
        }}
      >
        {breadcrumbTitleMap[pathSnippets[0]]}
      </Typography>

      <Breadcrumb
        className="main-breadcrumb"
        separator={
          <BsDot
            className="icon-separator"
            style={{
              fontSize: 10,
              color: "#B5B5C3",
              // width: 3,
              // height: 3,
              // margin: 0,
            }}
          />
        }
      >
        {extraBreadcrumbItems}
      </Breadcrumb>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Page Components
import MasterSegment from "../../../Reusable/Master/Segment/Segment";
import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedPayType,
  failedSalesman,
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
  successPayType,
  successSalesman,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Style
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function SalesmanTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // CONTEXT
  const { state, getMasterSalesman } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState(data);
  const [editData, setEditData] = useState(null);
  // ID
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Segment
  const [segment, setSegment] = useState({
    main_segment_id: [],
    main_segment_code: [],
    main_segment_name: [],
  });

  // Modal
  const [isDeleted, setIsDeleted] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      handleOpen();
                      setEdit(true);

                      setId(record.id);
                      setEditData(record);
                      setSegment({
                        main_segment_id: record?.main_segment_id,
                        main_segment_code: record?.main_segment_code,
                        main_segment_name: record?.main_segment_name,
                      });

                      setFormFields(record);
                      setIsDeleted(false);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Salesman Code",
      dataIndex: "sales_code",
      key: "sales_code",
    },
    {
      title: "Salesman Name",
      dataIndex: "sales_name",
      key: "sales_name",
    },
    {
      title: "Segment Code",
      dataIndex: "main_segment_code",
      key: "main_segment_code",
      render: (code, record) => {
        let _code = typeof code;
        let _txt = "";

        // console.log("CODE: ", _code, code);

        switch (_code) {
          case "object":
            _txt = code?.length > 0 ? code.join(", ") : code;
            break;

          case "string":
            _txt = code;
            break;

          default:
            break;
        }

        return _txt;
      },
    },
    {
      title: "Segment Name",
      dataIndex: "main_segment_name",
      key: "main_segment_name",
      render: (code, record) => {
        let _code = typeof code;
        let _txt = "";

        // console.log("Name: ", _code);

        switch (_code) {
          case "object":
            _txt = code?.length > 0 ? code.join(", ") : code;
            break;

          case "string":
            _txt = code;
            break;

          default:
            break;
        }

        return _txt;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];
  // Fetch Data
  const fetchData = async () => {
    await getMasterSalesman({
      sales: "sales",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let filtered = response.data.msg.filter((filt) => {
            return !filt.is_deleted;
          });

          setData(filtered);
          setBulks(filtered);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Segment
  const getSegment = (value, id, code) => {
    console.log("Value: ", value);
    console.log("ID: ", id);
    console.log("CODE: ", code);

    setSegment({
      main_segment_id: id,
      main_segment_name: value,
      main_segment_code: code,
    });

    form.setFieldsValue({
      main_segment_id: id,
      main_segment_code: code,
      main_segment_name: value,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setIsDeleted(false);
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);

    setId(null);

    setEditData(null);
    setIsDeleted(true);

    setIsLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (value) => {
    // console.log(value);
    let content = value;

    // handleSubmit(content);
    setIsLoading(true);
    showModalConfirm(content);
  };
  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    console.log(e);
    masterIncomplete(e);
  };

  // HANDLE FORM FIELDS
  const setFormFields = (value) => {
    const record = value;

    form.setFieldsValue({
      sales_code: record?.sales_code,
      sales_name: record?.sales_name,

      main_segment_id: record?.main_segment_id,
      main_segment_code: record?.main_segment_code,
      main_segment_name: record?.main_segment_name,

      description: record?.description,

      created_by: record.created_by,
      created_date: record.created_date,
      updated_by: record.updated_by,
      updated_date: record.updated_date,
    });
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (record) => {
    const deleteContent = record;

    // console.log("In Modal = ", deleteContent);

    confirm({
      className: "delete-confirm",
      title: isDeleted
        ? `Are you sure want to delete Salesman named ${deleteContent?.sales_name.toUpperCase()} from the table?`
        : `Are you sure want to ${edit == true ? "Update" : "Add a New"}
          Sales named ${deleteContent?.sales_name.toUpperCase()}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: isDeleted == true ? "Delete" : "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDeleted == true
          ? handleDelete(deleteContent)
          : handleSubmit(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentsubmit = value;
    console.log("ON OK ==> ", contentsubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/master/sales`,
          {
            sales_code: contentsubmit?.sales_code,
            sales_name: contentsubmit?.sales_name,

            main_segment_id:
              contentsubmit?.main_segment_id || segment?.main_segment_id,
            main_segment_code:
              contentsubmit?.main_segment_code || segment?.main_segment_code,
            main_segment_name:
              contentsubmit?.main_segment_name || segment?.main_segment_name,

            description: contentsubmit?.description || null,

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("POST Salesman => ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Salesman",
          });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("POST Salesman => ", error);

          masterFailedNotification(error, { method: 0, source: "Salesman" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(
          `${baseurl}/master/sales`,
          {
            id: editData?.id,
            sales_code: contentsubmit?.sales_code || editData?.sales_code,
            sales_name: contentsubmit?.sales_name || editData?.sales_name,
            main_segment_id:
              contentsubmit?.main_segment_id || editData?.main_segment_id,
            main_segment_code:
              contentsubmit?.main_segment_code || editData?.main_segment_code,
            main_segment_name:
              contentsubmit?.main_segment_name || editData?.main_segment_name,
            description: contentsubmit?.description || editData?.description,

            created_by: contentsubmit?.user_name || editData?.created_by,
            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("UPDATE Salesman => ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Salesman",
          });

          fetchData();
          handleCancel();
        })
        .catch((error) => {
          console.log("UPDATE Salesman => ", error);

          masterFailedNotification(error, { method: 1, source: "Salesman" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = (record) => {
    const contentdelete = record;

    console.log(contentdelete);

    var obj = {
      ["id"]: contentdelete?.id,
      ["deleted_by"]: user_name,
    };

    axios
      .delete(`${baseurl}/master/sales`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Salesman => ", response);

        masterSuccessNotification(response, { method: 2, source: "Salesman" });
        fetchData();
      })
      .catch((error) => {
        masterFailedNotification(error, { method: 2, source: "Salesman" });

        console.log("DEL Salesman => ", error);
      });
  };

  // HANDLE EXPORT EXCEL
  const handleExport = () => {
    downloadExcel(bulks, "Salesman List");
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;
    // console.log("Type = ", query);

    if (bulks?.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item?.sales_name.toLowerCase();
        // console.log("ITEM => ", item)
        return _name.indexOf(query) !== -1;
      });
      // Trigger render with updated values
      setBulks(updatedList);
    } else {
      setData([]);
    }
    // console.log("FILTERED => ", updatedList);
  };

  return (
    <>
      <Row
        className="payment-type-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            className="row-modal-btn"
            gutter={[0, 15]}
            style={{ width: "100%" }}
          >
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="col-exp-btn"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="col-modal-btn"
            >
              <Row
                justify="end"
                className="row-modal-btn"
                align="top"
                gutter={[0, 15]}
              >
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Salesman`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="pay-type-table"
              key="pay-type-table"
              name="pay-type-table"
              loading={loading}
              columns={columns}
              dataSource={bulks}
              bordered
              pagination={{
                pageSizeOptions: [5, 10, 25, 50, 100],
                defaultPageSize: 5,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 1000,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-pay-type-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Add Salesman`}</Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Edit Salesman`}</Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="pay-type-modal-form"
          name="pay-type-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-pay-type-id">
            <Form.Item noStyle name="main_segment_id" hidden={true}>
              <></>
            </Form.Item>
            <Col span={12}>
              <Form.Item
                label="Salesman Code"
                name="sales_code"
                rules={[
                  {
                    required: true,
                    message: "Please, input Salesman Code!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Salesman Code" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Salesman Name"
                name="sales_name"
                rules={[
                  {
                    required: true,
                    message: "Please, input Salesman Name!",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Salesman Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="pay-type-coa">
            <Col span={12}>
              <Form.Item
                label="Segment Name"
                name="main_segment_name"
                rules={[
                  {
                    required: true,
                    message: "Please, input Segment Name !",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <MasterSegment
                  getSegment={getSegment}
                  sgmnt={edit ? editData?.main_segment_name : []}
                  is_fromSalesman={true}
                  // is_render={isRender}
                  // is_rerender={setIsRender}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Segment Code"
                name="main_segment_code"
                rules={[
                  {
                    required: true,
                    message: "Please, input Segment Code !",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input placeholder="Segment Code" disabled={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-pay-type-desc">
            <Col span={16}>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  showCount
                  allowClear
                  maxLength={200}
                  rows={4}
                  placeholder="Descriptions"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "15px 0px", background: "#EBEDF3" }}
          />

          {edit === true ? (
            <>
              <Row gutter={30} className="created-date-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Updated By"
                    name="updated_by"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-pay-type">
                <Col span={8}>
                  <Form.Item
                    label="Updated Date"
                    name="updated_date"
                    style={{ width: "100%" }}
                  >
                    <Input placeholder="Updated Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : null}

          <Row
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 15px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

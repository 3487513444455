// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons/lib/icons";

// Import Master Notification
import {
  failedRateNo,
  masterIncomplete,
  successRateNo,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master
import MasterRoomNumber from "../../../Reusable/Master/RoomNumber/RoomNumber";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function RateRoomNoTable({ getRateRoom }) {
  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getMasterRateRoomNo } = useContext(MasterContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  // Rate Room
  const [rateRoom, setRateRoom] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    setLoading(true);

    await getMasterRateRoomNo({
      rate: "rate-code",
      number: "number",
      rate_code: rateRoom[0].rate_code_id,
      room_cat_code: rateRoom[0].room_category_code,
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("Rate Room Number >> ", response);

        if (response.data !== null) {
          let filtered = response.data.filter((val) => {
            if (val.is_deleted == false || val.is_deleted == null) {
              return val;
            }
          });
          setData(filtered);
        } else {
          setData(response.data);
        }

        // setFilteredList(filtered);
        setLoading(false);
        // console.log("ISI Room List >> ", filtered);
      },
      onReject: (error) => {
        failedFetch(error);
        setLoading(false);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    if (rateRoom) fetchData();
  }, [rateRoom]);

  // USE EFFECT SELECTOR
  useEffect(() => {
    setRateRoom(getRateRoom);
  }, [getRateRoom]);

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row
              className="action-room-rate-cat-table-btn"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FormOutlined
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);
                      setOpen(true);
                      setEdit(true);
                      setEditData(record);
                      form.setFieldsValue({
                        room_id: record.room_id,
                        unit_price: record.unit_price,
                        created_by: record.created_by,
                        created_date: record.created_date,
                        modified_by: record.updated_by,
                        modified_date: record.updated_date,
                      });
                    }}
                    style={{ fontSize: 20, color: "#3699FF" }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);
                      showDeleteModal(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    // {
    //   title: "Room Category",
    //   dataIndex: "room_category_name",
    //   key: "room_category_name",
    // },
    {
      title: "Room ID",
      dataIndex: "room_id",
      key: "room_id",
    },
    {
      title: "Price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Get Room Number
  const getRoomNumber = (value) => {
    console.log(value);

    if (edit) {
      editData.room_id = value;
    }

    form.setFieldsValue({
      room_id: value,
    });
  };

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setEditData(null);
    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    // console.log(e);
    handleSubmit(e);
  };

  // ON FINISH FAILED
  const onFinishFailed = (e) => {
    // console.log(e);
    masterIncomplete(e);
  };

  // SHOW MODAL DELETE
  const showDeleteModal = (record) => {
    let deleteContent = record;

    return confirm({
      className: "delete-guest-confirm",
      title: `Are you sure want to delete Rate Room Number ${deleteContent.room_id.toUpperCase()} from the table?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(deleteContent);
        // console.log("Article deleted");
      },

      onCancel() {
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (val) => {
    let contentSubmit = val;

    console.log("About to SUBMIT => ", contentSubmit);
    let room_no = contentSubmit.room_id.slice(3, 6);

    console.log(room_no);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/room/rate/number`,
          {
            rate_code_id: rateRoom[0]?.rate_code_id,
            rate_code_name: rateRoom[0]?.rate_code_name,
            room_category_code: rateRoom[0]?.room_category_code,
            room_category_name: rateRoom[0]?.room_category_name,
            room_id: contentSubmit.room_id,
            room_number: parseInt(room_no),
            unit_price: contentSubmit.unit_price,
            description: null,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Add Rate RN => ", response);
          successRateNo(response, { method: 0 });
          handleCancel();
        })
        .catch((error) => {
          console.log("Add Rate RN => ", error);
          failedRateNo(error, { method: 0 });
        })
        .finally(() => {
          fetchData();
        });
    } else {
      await axios
        .put(
          `${baseurl}/room/rate/number`,
          {
            id: editData.id,
            rate_code_id: editData.rate_code_id,
            rate_code_name: editData.rate_code_name,
            room_category_code: editData.room_category_code,
            room_category_name: editData.room_category_name,
            room_id: contentSubmit.room_id,
            room_number: parseInt(room_no),
            unit_price: contentSubmit.unit_price,
            description: null,
            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Update Rate RN => ", response);
          successRateNo(response, { method: 1 });
          handleCancel();
        })
        .catch((error) => {
          console.log("Update Rate RN => ", error);
          failedRateNo(error, { method: 1 });
        })
        .finally(() => {
          fetchData();
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (value) => {
    const contentDelete = value.id;

    console.log("content delete >>", contentDelete);

    var obj = {
      ["id"]: contentDelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/room/rate/number`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("DEL Rate RN => ", response);
        successRateNo(response, { method: 2 });
      })
      .catch((error) => {
        console.log("DEL Rate RN => ", error);
        failedRateNo(error, { method: 2 });
      })
      .finally(() => {
        fetchData();
      });
  };

  console.log("Di Room Number => ", rateRoom);

  return (
    <>
      <Row
        className="rate-room-no-main-table"
        justify="center"
        style={mainBody}
      >
        <Col
          span={24}
          style={{
            padding: "30px 30px 15px",
          }}
        >
          <Row>
            <Col span={12}>
              <Row justify="start">
                <Typography
                  key={"room-cat-desc"}
                  style={{ fontWeight: 600, fontSize: 20, color: "#3F4254" }}
                >
                  {`Room Category: ${
                    rateRoom
                      ? rateRoom[0].room_category_name.toUpperCase()
                      : "-"
                  }`}
                </Typography>
              </Row>
            </Col>

            <Col span={12}>
              <Row justify="end">
                <Button
                  className="submit-btn"
                  disabled={rateRoom ? false : true}
                  onClick={handleOpen}
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{
                    backgroundColor: "#1BC5BD",
                    borderColor: "#1BC5BD",
                    marginRight: 15,
                  }}
                >
                  Add Room
                </Button>
              </Row>
            </Col>

            <Row style={{ marginTop: 30, width: "100%" }}>
              <Table
                className="rate-room-no-table"
                name="rate-room-no-table"
                key="rate-room-no-table"
                loading={loading}
                bordered
                size="small"
                columns={columns}
                dataSource={data}
                pagination={{
                  defaultPageSize: 5,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} entries`,
                }}
                rowKey={(record) => (record.id ? record.id : record.key)}
                rowClassName={(record, index) => {
                  return index % 2 == 0 ? "odd" : "even";
                }}
              />
            </Row>
          </Row>
        </Col>
      </Row>

      <Modal
        className="add-edit-room-rate-cat-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Add Room Number`}
              </Typography>
            </Row>
          ) : (
            <Row>
              <FormOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Edit Room Number`}
              </Typography>
            </Row>
          )
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          name="room-rate-cat-art-modal-form"
          className="room-rate-cat-art-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-room-rate-cat-id">
            <Col span={16}>
              <Form.Item
                label="Room Number"
                name="room_id"
                rules={[
                  {
                    required: true,
                    message: "Please, select a Room Number!",
                  },
                ]}
                style={{ marginRight: 30, width: 440 }}
              >
                {/* <Input placeholder="Room Category" /> */}
                <MasterRoomNumber
                  getRoomNumber={getRoomNumber}
                  room_number={editData ? editData.room_id : ""}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Price"
                name="unit_price"
                rules={[
                  {
                    required: true,
                    message: "Please, input unit price!",
                  },
                ]}
              >
                <InputNumber
                  addonBefore={currency}
                  formatter={(unit_price) => {
                    return `${unit_price}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  placeholder="Price"
                  style={{ width: 205 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "15px 0px 30px" }} />

          {edit == true ? (
            <>
              <Row gutter={30} className="created-date-room-rate-cat">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-room-rate-cat">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: 205 }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: "0px 0px 15px" }} />
            </>
          ) : (
            <></>
          )}

          <Row
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              className="submit-btn"
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

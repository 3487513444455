// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  Layout,
  Row,
  Typography,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Page Components
import CloseCashierSteps from "../../../Components/Steps/FandB/Outlet/CloseCashier/Steps"

// Import Global Styles
import { mainBody, cardBody } from "../../../Style/Global/Global";
import { FaCheckToSlot } from "react-icons/fa6";
import { IoReturnUpBackOutline } from "react-icons/io5";
import ModalOutletLocation from "../../../Components/Modals/Outlet/OutletLocation/Location/Modal";

// CODE
export default function CloseCashierPage() {
  // Modal State
  const [open, setOpen] = useState(false);
  const [outletLocation, setOutletLocation] = useState(null);
  const [data, setData] = useState([]);
  
  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname } = useLocation();

  // USE EFFECT
  useEffect(() => {
    let currentOutlet = sessionStorage.getItem("pos_outlet_location"); 
    
    if (currentOutlet) {
      setOpen(false);
      setOutletLocation(currentOutlet);
    } else {
      setOpen(true);
    }
  },[]);

  const handleNavigateReturn = () => {
    navigate(`${pathname}`.substring(0, pathname.length - 14), {
      state: {},
    });
  };

  // Handle Select
  const handleSelect = (value, secondValue) => {
    const _selected = value;

    console.log("Selected Data: ", _selected);

    if (_selected?.outlet_location && _selected?.outlet_id > 0) {
      setOutletLocation(_selected.label_outlet_location);

      setData(secondValue);
      sessionStorage.setItem("pos_outlet_location", _selected.label_outlet_location);

    }

    // setOpen(false);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="bill-template-main-layout">
          <Row className="bill-template-title" justify="start" style={mainBody}>
            <FaCheckToSlot
              style={{
                fontSize: 30,
                // height: 24,
                // width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />

            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              Close Cashier
            </Typography>
          </Row>
          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: "10px 30px 15px",
              }}
            >
              <Col
                className="location-col"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Location`}</Typography>

                <Button
                  className="submit-btn"
                  // onClick={handleOpen}
                  type="primary"
                  style={{
                    width: 205,
                  }}
                  disabled
                >
                  {outletLocation}
                </Button>
              </Col>

              <Col
                className="btn-col"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleNavigateReturn}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
            </Space>
          </Row>

          <CloseCashierSteps />
        </Col>
        <ModalOutletLocation
          closeModal={setOpen}
          openModal={open}
          getLocs={handleSelect}
          // getData={setData}
          // is_roomService={setIsRoomService}
        />
      </Content>
    </Layout>
  );
}

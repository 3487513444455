// Import React Components
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Form,
  Input,
  Tooltip,
  Divider,
  Checkbox,
  Result,
} from "antd";
import {
  ExclamationCircleOutlined,
  FormOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";

// Import Master Components
import {
  handleAuthorization,
  authorized_by,
  authorized_dept,
  authorized_roles,
  authorized_token,
} from "../../../API/Global/Permission";

// Import Notifications
import {
  failedCheckIn,
  failedPermit,
  failedRoomStatus,
  incomplete,
  successCheckIn,
  successPermit,
  successRoomStatus,
} from "../../Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ModalPermission(props) {
  // PROPS
  const {
    // Data
    dataSubmit,
    is_refresh,
    // State Source,
    roomStatus,
    // Modal State
    openModal,
    closeModal,
    // Deleted
    is_deleted,
    // Return State
    is_authorized,
    is_loading,
    is_loadingFalse,
    is_success,
  } = props;

  // CONTEXT
  const { requestPermissions } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  // Data To Forms
  const [detail, setDetail] = useState(
    "and will Cut the Total Room of the Room Sold and cannot be changed again until the duration is complete or by an Authorized Users!"
  );
  const [headers, setHeaders] = useState("OFF-MARKET/OUT OF SERVICE");
  // Open Modals
  const [open, setOpen] = useState(false);
  // URL
  const [permitUrl, setPermitUrl] = useState(`manager-hk`);
  const [postUrl, setPostUrl] = useState(`out-of-market`);
  const [authorizedRoles, setAuthorizedRoles] = useState(`ROLE_MANAGER`);
  const [department, setDepartment] = useState(`house`);
  // Loads
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successed, setSuccessed] = useState(1);
  const [isDeleted, setIsDeleted] = useState(false);

  // USEFORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      // console.log("Permission Modals: ", openModal);
      setOpen(openModal);

      console.log(authorized_token);
      console.log("Data Submitted: ", dataSubmit);
      // console.log("IS DELETED? ", is_deleted);

      setData(dataSubmit);
      setIsDeleted(is_deleted);

      if (authorized_token) {
        setSuccessed(2);
      } else {
        setSuccessed(1);
      }
    }
    // else {
    //   closeModal(false);
    //   setOpen(false);
    // }

    if (roomStatus > 0) {
      handleStatus(roomStatus);
    }

    if (is_loading === true) {
      setIsLoading(false);
      is_loadingFalse(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, is_deleted, is_loading, dataSubmit]);

  // USE EFFECT POST
  useEffect(() => {
    let cleanUp = false;

    if (authorized_token && !cleanUp) {
      console.log("Token: ", authorized_token);
      console.log("By: ", authorized_by);
      console.log("Dept: ", authorized_dept + " = " + department);
      console.log("Roles: ", authorized_roles);

      // handleSubmit();
      if (
        authorized_token &&
        authorized_dept.toLowerCase().includes(department) &&
        authorized_roles == authorizedRoles
      ) {
        setSuccessed(2);
      } else {
        console.log("This should've not be called!");
        setSuccessed(1);
      }
    }

    if (is_success == true && !cleanUp) {
      console.log("Success State: ", is_success);
      closeModal(false);
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [authorized_token, authorized_roles, authorized_dept, is_success]);

  // HANDLE STATUS
  const handleStatus = async (val) => {
    const stat = val;

    // console.log("Status: ", stat);

    switch (stat) {
      case 2:
        setDetail(
          `in the Room Availability and should be changed again if it is available again!`
        );
        setHeaders(`OUT OF ORDER`);
        setPermitUrl(`supervisor-hk`);
        setPostUrl(`out-of-order`);
        setAuthorizedRoles(`ROLE_SUPERVISOR`);
        break;

      case 3:
        setDetail(null);
        setHeaders(null);
        setPermitUrl(`supervisor-fo`);
        setPostUrl(null);
        setAuthorizedRoles(`ROLE_SUPERVISOR`);
        setDepartment(`front`);
        break;

      case 4:
        setDetail(null);
        setHeaders(null);
        setPermitUrl(`supervisor-fo`);
        setPostUrl(null);
        setAuthorizedRoles(`ROLE_SUPERVISOR`);
        setDepartment(`front`);
        break;

      case 5:
        setDetail(null);
        setHeaders(null);
        setPermitUrl(`supervisor-fo`);
        setPostUrl(null);
        setAuthorizedRoles(`ROLE_SUPERVISOR`);
        setDepartment(`front`);
        break;

      default:
        break;
    }
  };

  // ON FINISH
  const onFinish = async (val) => {
    const value = val;

    handlePermit(value);
  };

  // HANDLE PERMIT
  const handlePermit = async (val) => {
    const _submit = val;

    // auth: "auth",
    // permit: "permissions-supervisor-fo",

    await requestPermissions({
      auth: "auth",
      permit: `permissions-${permitUrl}`,
      email: _submit.email,
      password: _submit.password,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Permit: ", response);

        if (response?.data?.msg == "Access Granted") {
          // setCredentials(response?.data?.data);

          localStorage.setItem(
            "permit",
            response?.data?.data?.session?.access_token
          );

          handleAuthorization(response?.data?.data?.session?.access_token);
        } else {
          failedPermit(response);
        }

        successPermit(response);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Permit Err: ", error);

        failedPermit(error);
        setLoading(false);
        setSuccessed(3);
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = () => {
    const _record_token = {
      auth_token: authorized_token,
      auth_roles: authorized_roles,
      auth_by: authorized_by,
      auth_dept: authorized_dept,
    };

    setIsLoading(true);

    switch (roomStatus) {
      case 1:
      case 2:
        handlePost();
        break;

      case 3:
        is_authorized(_record_token);
        break;

      default:
        break;
    }
  };

  // HANDLE POST
  const handlePost = async () => {
    const _content = data;
    const _night = data?.night;

    let obj = {
      start_date: _content?.start_cancel_date
        ? _content.start_cancel_date
        : data.start_cancel_date,
      reservation_id: _content?.room_status_id || data.reservation_id,
      deleted_by: authorized_by,
      end_date:
        _night > 0
          ? moment(_content?.date).add(_night, "days")
          : data.start_cancel_date,
      room_number:
        _content?.room_number > 0 ? _content.room_number : data?.room_number,
      room_category: _content?.room_category_id,
    };

    console.log("POSTED >>> ", _content);

    switch (isDeleted) {
      case true:
        if (
          authorized_token &&
          authorized_dept.toLowerCase().includes(department) &&
          authorized_roles == authorizedRoles
        ) {
          await axios
            .delete(`${baseurl}/room/${postUrl}`, {
              data: obj,
              headers: { Authorization: `Bearer ${authorized_token}` },
            })
            .then((response) => {
              console.log("Response OM/OOO =  ", response);
              successRoomStatus(response);

              handleClose();
              is_refresh(true);
              localStorage.clear();
              setSuccessed(1);
            })
            .catch((error) => {
              console.log("Err  OM/OOO = ", error);
              failedRoomStatus(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          failedRoomStatus("Err");
          setSuccessed(3);
        }

        // console.log("DATA SUBMIT: ", _data);
        // console.log("API DELETED: ", `${baseurl}/room/${postUrl}`);
        break;

      default:
        if (
          authorized_token &&
          authorized_dept.toLowerCase().includes(department) &&
          authorized_roles == authorizedRoles
        ) {
          await axios
            .post(
              `${baseurl}/room/${postUrl}`,
              {
                desc: _content?.description ? _content.description : null,

                res_id: _content?.room_status || data.reservation_id,
                room_num:
                  _content?.room_number > 0
                    ? _content.room_number
                    : data.room_number,
                night: _content.night > 0 ? _content.night : data.night,
                created_by: authorized_by,
                date: _content?.date ? _content.date : data.date,
                room_category: _content?.room_category_id,
              },
              {
                headers: { Authorization: `Bearer ${authorized_token}` },
              }
            )
            .then((response) => {
              console.log("Response OM/OOO =  ", response);
              successRoomStatus(response);

              handleClose();
              is_refresh(true);
              localStorage.clear();
              setSuccessed(1);
            })
            .catch((error) => {
              console.log("Err  OM/OOO = ", error);
              failedRoomStatus(error);
            })
            .finally(() => {
              setIsLoading(false);
            });

          // let _data = {
          //   desc: _content?.description
          //     ? _content.description
          //     : data.description,

          //   res_id: _content?.room_status || data.reservation_id,
          //   room_num:
          //     _content?.room_number > 0
          //       ? _content.room_number
          //       : data.room_number,
          //   room_category:
          //     _content?.room_category_name || data.room_category_name,
          //   night: _content.night > 0 ? _content.night : data.night,
          //   created_by: authorized_by,
          //   date: _content?.date ? _content.date : data.date,

          //   headers: { Authorization: `Bearer ${authorized_token}` },
          // };

          // console.log("DATA SUBMIT: ", _data);
          // console.log("API POST: ", `${baseurl}/room/${postUrl}`);
        } else {
          failedRoomStatus("Error");
          setSuccessed(3);
        }

        break;
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Failed >>> ", error);

    incomplete(error);
    // setOpen(true);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    setIsLoading(false);
    closeModal(false);
    setOpen(false);
  };

  // console.log("INIT >>>", initialForm);
  // console.log("Success State >>>", successed);

  return (
    <div aria-modal="true" aria-hidden="false">
      <Modal
        className="modal-permit"
        title={
          <>
            <Row>
              <ExclamationCircleOutlined
                className="icon-exclam"
                style={{ color: "#faad14", fontSize: 24 }}
              />
              <Typography style={{ color: "#faad14", marginLeft: 15 }}>
                {roomStatus < 2
                  ? `${isDeleted ? "CANCEL" : "CREATES"} ${headers} ROOM`
                  : `Authorized Permissions`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={690}
      >
        <Form
          name="checkin-form"
          className="checkin-form"
          key="checkin-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 16 }}
          //   wrapperCol={{ span: 12 }}
          autoComplete="off"
          layout="vertical"
          // initialValues={{
          //   room_modal: initialForm?.length > 0 ? initialForm : null,
          // }}
        >
          <Row className="modal-row" justify="start" style={{ width: "100%" }}>
            {isDeleted ? (
              <Typography
                className="warning-title"
                style={{
                  fontSize: 15,
                  textAlign: "justify",
                  fontWeight: 750,
                }}
              >
                {`Are you sure you wanted to cancel status ${
                  data?.room_status_id.toUpperCase() || "---"
                } of Room ${data?.room_number || "UNKNOWN"}?`}
              </Typography>
            ) : roomStatus < 2 ? (
              <>
                <Typography
                  className="warning-title"
                  style={{
                    fontSize: 15,
                    textAlign: "justify",
                    // color: "red",
                    fontWeight: 750,
                    // margin: "15px 0px 0px",
                  }}
                >
                  {`Are you sure want to change Room Status of Room ${
                    data?.room_id || "UNKNOWN"
                  } to ${data?.room_status || "---"} for ${
                    data?.night || 0
                  } night(s)?`}
                </Typography>

                <Typography
                  className="warning-title"
                  style={{
                    textAlign: "justify",
                  }}
                >
                  {`This Room will not be available from ${
                    data?.date ? moment(data.date).format("DD-MM-YYYY") : 0
                  } until the next ${data?.night || 0} day(s), ${detail}`}
                </Typography>
              </>
            ) : null}

            <Typography
              className="warning"
              style={{
                fontSize: 18,
                fontWeight: 750,
                textAlign: "center",
                color: "#ff4d4f",
                margin: "15px 0px 0px",
                padding: "15px 0px",
              }}
            >
              <WarningOutlined />
              {`Warning! This operation needs an Authorization Access and Official Reports! Please, Check Again!`}
              <br />
              {`Needs a ${
                authorized_roles == "ROLE_SUPERVISOR" ? "Supervisor" : "Manager"
              } Permissions!`}
            </Typography>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            {successed != 2 ? (
              <Row
                className="auth-row"
                justify="center"
                style={{ width: "100%" }}
              >
                <Col
                  span={24}
                  className="auth-col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input your Email!",
                      },
                    ]}
                    style={{
                      width: "50%",
                    }}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input your Email!",
                      },
                    ]}
                    style={{
                      width: "50%",
                    }}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Col className="succeeded-col" span={24}>
                <Result
                  status={successed == 2 ? "success" : "error"}
                  className="permission-result"
                  title="Authorization Granted!"
                  subTitle="Click Proceed to Finish the Operations!"
                  extra={
                    <>
                      <Button
                        className="submit-btn"
                        type="primary"
                        onClick={handleSubmit}
                        loading={isLoading}
                        style={{
                          borderColor: "transparent",
                        }}
                      >
                        {`Proceed`}
                      </Button>
                    </>
                  }
                />
              </Col>
            )}

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              justify="end"
              align="middle"
              style={{ padding: "0px 0px 5px", width: "100%" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                key={"submit"}
                danger
                hidden={successed == 2 ? true : false}
                // onClick={onFinish}
                loading={loading}
                className="submit-room"
                style={{
                  // backgroundColor: "#1BC5BD",
                  // borderColor: "#1BC5BD",
                  marginRight: 30,
                }}
              >
                {`Confirm`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleClose}
              >
                {`Cancel`}
              </Button>
            </Row>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

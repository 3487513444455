// Import React's Component
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext"
import { Context as InventoryContext } from "../../../../API/Context/InventoryContext/InventoryContext"

// Import ANTD Component
import { Button, Row, Col, Modal, Form, Divider, InputNumber, Typography, Input, Select, } from "antd";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { approval_level, department, department_id, token, user_level, user_name, } from "../../../../API/Global/Payload";

// Import React Icons Components
import { PlusOutlined, } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import { masterSuccessNotification, masterFailedNotification, masterIncomplete, } from "../../../Reusable/Notification/MasterNotif/Notification";
import StandardRecipeDetailTable from "../../../Table/BackOffice/Inventory/StandardRecipe/StandardRecipeDetail/Table";

// Modal
const { confirm } = Modal;

// CODE
export default function StandardRecipeModal(props) {
  // PROPS
  const { is_open, is_close, is_refresh, is_edit, selectedData } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [tableDetail, setTableDetail] = useState([]);
  const [dataLength, setDataLength] = useState(0);

  // Data Header
  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  const [recipeTypeChange, setRecipeTypeChange] = useState(null);
  const [recipeName, setRecipeName] = useState(null);
  const [recipeNumber, setRecipeNumber] = useState(null);
  const [category, setCategory] = useState(null);
  const [portion, setPortion] = useState(null);
  const [cost, setCost] = useState(0);
  const [description, setDescription] = useState(null);
  
  // Data Item
  const [articleOptionValue, setArticleOptionValue] = useState([]);
  const [articleChange, setArticleChange] = useState(null);
  const [recipeOptionValue, setRecipeOptionValue] = useState([]);
  const [recipeChange, setRecipeChange] = useState(null);
  const [nettQuantity, setNettQuantity] = useState(null);
  const [averagePrice, setAveragePrice] = useState(null);
  
  // Key Tables
  const [lastKey, setLastKey] = useState(0);
  const key = 1;

  // Modals
  const [modalOpen, setModalOpen] = useState(false);
  // Loadings
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  // CONTEXT
  const { getArticleSub, getArticleList } = useContext(MasterContext);
  const { getStandardRecipe } = useContext(InventoryContext);

  // USE FORM
  const [form] = Form.useForm();

  // HANDLE FORM
  const handleRecipeName = (value) => { setRecipeName(value.target.value); };
  const handleRecipeNumber = (value) => { setRecipeNumber(value.target.value); };
  const handleCategory = (value) => { setCategory(value); };
  const handlePortion = (value) => { setPortion(value); };
  const handleDescription = (value) => { setDescription(value.target.value); };

  useEffect(() => {
    console.log("Edit Data Detail: ", tableDetail);
  },[tableDetail]);

  useEffect(() => {
    console.log("Nilai Cost >> ", cost);
  },[cost]);

  useEffect(() => {
    if(is_edit == true) {
      if(tableDetail.length > 0){
        setDataLength(1);
      } else {
        setDataLength(0);
      }
    } else {
      if(data.length > 0){
        setDataLength(1);
      } else {
        setDataLength(0);
      }
    }
  },[data, tableDetail])

  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      // console.log("Modal State: ", is_open);
      handleOpen();
    } else {
      setModalOpen(false);
      is_close(false);
    }

    if (is_edit == true && !cleanUp) {
      console.log("Edit Data: ", selectedData);
      setEdit(true);

      if (selectedData) {
        setDataEdit(selectedData);
        let recipe = selectedData.details.map((x, i) => ({
          key: i + 1,
          id_detail: x.id,
          item_name: x.article_name,
          nett_qty: x.nett_qty,
          lost_factor: x.lost_factor,
          content: x.content,
          r_unit: x.recipe_unit,
          average_price: x.cost / x.nett_qty,
          cost: x.cost,
          id_item: x.id_article,
          cat_item: x.item_type,
        }));
        console.log("GETAPI MAP >> ", recipe);
        console.log("selectedData.length >> ", selectedData.details.length);
        setTableDetail(recipe);
        setLastKey(selectedData.details.length);
        
        handleFormField(selectedData);

        setRecipeName(selectedData?.recipe_name);
        setRecipeNumber(("000" + selectedData?.recipe_number).slice(-4));
        setCategory(selectedData?.article_sub_id);
        setPortion(selectedData?.portion);
        setCost(selectedData?.cost);
        setRecipeTypeChange(selectedData?.recipe_type);
        setDescription(selectedData?.description);
      } else {
        setDataEdit(null);
        setTableDetail([]);
      }
    } else {
      setEdit(false);
      if(is_open){
        form.resetFields();
      } else {
        null
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, is_edit, selectedData]);

  useEffect(() => {
    fetchCategoryData();
    fetchArticleData();
    fetchRecipeData();
  }, []);

  const fetchCategoryData = async () => {
    await getArticleSub({
      subgroup: "sub-group",
      onAwait: () => {},
      onSuccess: (response) => {
        if(response?.data?.msg?.length > 0) {
          const res = response.data.msg;

          let filter = res.filter((x) => {
            if ((x.is_deleted == false || x.is_deleted == null)  && x.department_id == 5) return x;
          });

          let map = filter.map((x) => ({
            value: x.id,
            label: x.name,            
          }));

          console.log("DataCategory >> ", filter);

          setCategoryOptionValue(map);
        } else {
          setCategoryOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      }
    })
  }

  const fetchArticleData = async () => {
    await getArticleList({
      list: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        if(response?.data?.msg?.length > 0) {
          const res = response.data.msg;

          let filter = res.filter((x) => {
            if ((x.is_deleted == false || x.is_deleted == null) && x.department_id == 5) return x;
          });

          let map = filter.map((x) => ({
            value: x.article_name,
            label: x.article_name,
            average_price: x.average_price,
            recipe_unit: x.recipe_unit,
            mess_content: x.mess_content,
            id_article: x.id,
            article_number: x.article_number,
            key: x.id,
          }));

          console.log("DataArticle >> ", res);

          setArticleOptionValue(map);
        } else {
          setArticleOptionValue([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      }
    })
  };

  const fetchRecipeData = async () => {
    await getStandardRecipe({
      type: "standard-recipe",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filter = _res.filter((x) => {
            if (x.is_deleted == null || x.is_deleted == false) return x;
          });

          let map = filter.map((x) => ({
            value: x.recipe_name,
            label: x.recipe_name,
            cost: x.cost,
            id_recipe: x.id,
            recipe_number: x.recipe_number,
            key: x.id,
          }));

          setRecipeOptionValue(map);
        } else {
          setRecipeOptionValue([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  const handleRecipeTypeChange = (value) => {
    const _value = value;

    setRecipeTypeChange(_value);
    form.setFieldsValue({
      article_name: null,
      recipe_item_name: null,
      lost_factor: null,
      nett_quantity: null,
      cost_item: null,
      average_price: null,
      content: null,
      r_unit: null,
    });

    if(_value == 2) {
      setRecipeChange(null);
    };
  };

  const handleArticleChange = (value, others) => {
    const _value = value;
    const _others = others;

    console.log("Isi HandleRecipe >> ", others);
    
    if(_value == undefined) {
      setArticleChange(null);
      form.setFieldsValue({
        average_price: null,
        r_unit: null,
        cost_item: null,
        content: null,
        id_item: null,
        item_number: null,
        cat_item: null,
      });
    } else {
      setArticleChange(_value);
      setAveragePrice(others?.average_price)
      form.setFieldsValue({
        recipe_item_name: null,
        average_price: others?.average_price,
        r_unit: others?.recipe_unit,
        cost_item: (nettQuantity != null && nettQuantity > 0 && others?.average_price != null && others?.average_price > 0) ? nettQuantity * others?.average_price : null,
        content: others?.mess_content,
        id_item: others?.id_article,
        item_number: others?.article_number,
        cat_item: 1,
      });
    }
  };

  const handleRecipeChange = (value, others) => {
    const _value = value;
    

    if(_value == undefined) {
      setRecipeChange(null);
      form.setFieldsValue({
        cost_item: null,
        average_price: null,
        id_item: null,
        item_number: null,
        cat_item: null,
      });
    } else {
      setRecipeChange(_value);
      setAveragePrice(others?.cost);
      form.setFieldsValue({
        article_name: null,
        cost_item: (nettQuantity != null && nettQuantity > 0 && others?.cost != null && others?.cost > 0) ? nettQuantity * others?.cost : null,
        average_price: others?.cost,
        id_item: others?.id_recipe,
        item_number: others?.recipe_number,
        cat_item: 2,
      });
    }
  };

  const handleNettQuantity = (value) => { 
    setNettQuantity(value);
    form.setFieldsValue({
      cost_item: (value != null && value > 0 && averagePrice != null && averagePrice > 0) ? value * averagePrice : null,
    });
  };

  // Handle Form Field
  const handleFormField = (value) => {
    const _record = value;
    console.log("Handle Form: ", _record);

    form.setFieldsValue({
      recipe_name: _record?.recipe_name ? _record?.recipe_name : null,
      recipe_number: _record?.recipe_number ? ("000" + _record?.recipe_number).slice(-4) : null,
      category_name: _record?.article_sub_id ? _record?.article_sub_id : null,
      portion: _record?.portion ? _record?.portion : null,
      cost: _record?.cost ? _record?.cost : null,
      recipe_type: _record?.recipe_type ? _record?.recipe_type.toString() : null,
      description: _record?.description ? _record?.description : null,
      });

  };

  // Handle Open
  const handleOpen = () => {
    console.log("USERS METADATA: ", approval_level, department, user_level);
    setModalOpen(true);
  };

  // Handle Cancel
  const handleCancel = () => {
    setIsCreate(false);
    setIsDraft(false);
  };

  // Handle Close
  const handleClose = () => {
    is_close(false);

    setRecipeTypeChange(null);
    setRecipeName(null);
    setRecipeNumber(null);
    setCategory(null);
    setPortion(null);
    setCost(0);
    setDescription(null);

    setArticleChange(null);
    setRecipeChange(null);
    setNettQuantity(null);
    setAveragePrice(null);

    setEdit(false);
    setDataEdit(null);
    setTableDetail([]);
    setModalOpen(false);
    setIsCreate(false);
    setIsDraft(false);
    setLastKey(0);

    setDataLength(0);

    setData([]);

    form.resetFields();
  };

  // ON FINISH EDIT
  const onFinishEdit = (e) => {
    const _submit = e;

    let _new_item = {
      id_detail: null,
      id_item: _submit?.id_item ? _submit?.id_item : null,
      item_name: _submit?.article_name ? _submit?.article_name : _submit?.recipe_item_name,
      item_number: _submit?.item_number ? _submit?.item_number : null,
      cat_item: _submit?.cat_item ? _submit?.cat_item : null,
      r_unit: _submit?.recipe_item_name ? null : _submit?.r_unit,
      nett_qty: _submit?.nett_quantity ? _submit?.nett_quantity : null,
      cost: _submit?.cost_item ? _submit?.cost_item : null,
      unit: _submit?.recipe_item_name ? null : _submit?.r_unit,
      content: _submit?.recipe_item_name ? null : _submit?.content,
      average_price: _submit?.average_price ? _submit?.average_price : null,
      lost_factor: _submit?.lost_factor ? _submit?.lost_factor : null,
      key: lastKey + key,
      created_by: user_name,
    };

    // Cek apakah jumlah data lebih dari 0?
    if (tableDetail?.length > 0) {
      console.log("OnFinishEdit >> Sudah Ada Data");
      // console.log("Data Ada", data);
      // console.log("Data ID", _submit?.id_article);

      // Cek apakah article sudah tersedia (cek menggunakan id_article)
      if (tableDetail.some((item) => item.id_item === _submit?.id_item && item.cat_item === _submit?.cat_item)) {
        console.log("OnFinishEdit >> Sudah Ada Data >> Article sama ada");
        let _new = tableDetail.map((a) => {
          if (a.id_item === _submit?.id_item && a.cat_item === _submit?.cat_item) {
                return {
                  ...a,
                  nett_qty: a.nett_qty + _submit?.nett_quantity,
                  lost_factor: a.lost_factor + _submit?.lost_factor,
                  cost: (a.nett_qty + _submit?.nett_quantity) * a.average_price,
                }
          } else {
            // No changes
            // console.log("NO CHANGES >> ", a);
            return a;
          }
        });

        // // console.log("New Article: ", _new);
        setTableDetail(_new);
        // // setAdd(_new);

        form.resetFields([
          "article_name",
          "recipe_item_name",
          "lost_factor",
          "nett_quantity",
          "cost_item",
          "average_price",
          "content",
          "r_unit",
          "id_item",
          "item_number",
          "cat_item",
        ]);
        setCost(cost + _submit?.cost_item);
        form.setFieldsValue({
          cost: cost + _submit?.cost_item,
        });
        setArticleChange(null);
        setRecipeChange(null);
      } else {
        console.log("OnFinishEdit >> Sudah Ada Data >> Article sama blm ada");
        setTableDetail([
          ...(tableDetail?.length > 0 ? tableDetail : []),
          _new_item,
        ]);

        form.resetFields([
          "article_name",
          "recipe_item_name",
          "lost_factor",
          "nett_quantity",
          "cost_item",
          "average_price",
          "content",
          "r_unit",
          "id_item",
          "item_number",
          "cat_item",
        ]);
        setCost(cost + _submit?.cost_item);
        form.setFieldsValue({
          cost: cost + _submit?.cost_item,
        });
        setArticleChange(null);
        setRecipeChange(null);

        setLastKey(lastKey + 1);
      }

      // console.log("INDEX OF >> ", data);
    } else {
      console.log("OnFinishEdit >> Tidak Ada Data");

      setTableDetail([_new_item]);

      form.resetFields([
        "article_name",
        "recipe_item_name",
        "lost_factor",
        "nett_quantity",
        "cost_item",
        "average_price",
        "content",
        "r_unit",
        "id_item",
        "item_number",
        "cat_item",
      ]);
      setCost(cost + _submit?.cost_item);
      form.setFieldsValue({
        cost: cost + _submit?.cost_item,
      });
      setArticleChange(null);
      setRecipeChange(null);
      setLastKey(lastKey + 1);
    }

    console.log("PROCESS ON FINISH SUCCESS", e);
    console.log("tableDetail Length: ", tableDetail.length);
  };

  // On Finish
  const onFinish = (e) => {
    const _submit = e;

    console.log("Data in onFinish >> ", e);

    let _new_item = {
      item_name: _submit?.article_name ? _submit?.article_name : _submit?.recipe_item_name,
      id_item: _submit?.id_item ? _submit?.id_item : null,
      item_number: _submit?.item_number ? _submit?.item_number : null,
      cat_item: _submit?.cat_item ? _submit?.cat_item : null,
      r_unit: _submit?.recipe_item_name ? null : _submit?.r_unit,
      nett_qty: _submit?.nett_quantity ? _submit?.nett_quantity : null,
      cost: _submit?.cost_item ? _submit?.cost_item : null,
      unit: _submit?.recipe_item_name ? null : _submit?.r_unit,
      content: _submit?.recipe_item_name ? null : _submit?.content,
      average_price: _submit?.average_price ? _submit?.average_price : null,
      lost_factor: _submit?.lost_factor ? _submit?.lost_factor : null,
      key: lastKey + key,
      created_by: user_name,
    };

    // Cek apakah jumlah data lebih dari 0?
    if (data?.length > 0) {
      console.log("Data Ada", data);

      // Cek apakah article sudah tersedia (cek menggunakan id_article)
      if (data.some((item) => item.id_item === _submit?.id_item && item.cat_item === _submit?.cat_item)) {
        console.log("MODE ARTICLE SUDAH TERSEDIA >> ", data)
        let _new = data.map((a) => {
          if (a.id_item === _submit?.id_item && a.cat_item === _submit?.cat_item) {
            return {
              ...a,
              nett_qty: a.nett_qty + _submit?.nett_quantity,
              lost_factor: a.lost_factor + _submit?.lost_factor,
              cost: (a.nett_qty + _submit?.nett_quantity) * a.average_price,
            };
          } else {
            // No changes
            // console.log("NO CHANGES >> ", a);
            return a;
          }
        });

        // console.log("New Article: ", _new);
        setData(_new);
        // setAdd(_new);

        form.resetFields([
          "article_name",
          "recipe_item_name",
          "lost_factor",
          "nett_quantity",
          "cost_item",
          "average_price",
          "content",
          "r_unit",
          "id_item",
          "item_number",
          "cat_item",
        ]);
        setArticleChange(null);
        setRecipeChange(null);
        setCost(cost + _submit?.cost_item);
        form.setFieldsValue({
          cost: cost + _submit?.cost_item,
        });
      } else {
        console.log("Mode >> Nambah Data Baru");
        setData([...(data?.length > 0 ? data : []), _new_item]);

        form.resetFields([
          "article_name",
          "recipe_item_name",
          "lost_factor",
          "nett_quantity",
          "cost_item",
          "average_price",
          "content",
          "r_unit",
          "id_item",
          "item_number",
          "cat_item",
        ]);
        setArticleChange(null);
        setRecipeChange(null);
        setCost(cost + _submit?.cost_item);
        form.setFieldsValue({
          cost: cost + _submit?.cost_item,
        });
        
        setLastKey(lastKey + 1);
      }

      // console.log("INDEX OF >> ", data);
    } else {
      console.log("Mode >> Add Data Pertama");

      setData([...(data?.length > 0 ? data : []), _new_item]);

      form.resetFields([
        "article_name",
        "recipe_item_name",
        "lost_factor",
        "nett_quantity",
        "cost_item",
        "average_price",
        "content",
        "r_unit",
        "id_item",
        "item_number",
        "cat_item",
      ]);
      setCost(_submit?.cost_item);
      form.setFieldsValue({
        cost: _submit?.cost_item,
      });
      setArticleChange(null);
      setRecipeChange(null);
      setLastKey(lastKey + 1);
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const submit = val;

    console.log("Failed: ", submit?.values);

    masterIncomplete(submit);
  };

  // ON FINISH SUBMIT
  const onSubmit = () => {
    setIsCreate(true);

    const errorRecipeName = { errorFields: [ { errors: ["Please, input a Recipe Name!"], }, ], };
    const errorRecipeNumber = { errorFields: [ { errors: ["Please, lengthen Recipe Number to 4 characters!"], }, ], };
    const errorCategory = { errorFields: [ { errors: ["Please, select a Category!"], }, ], };
    const errorPortion = { errorFields: [ { errors: ["Please, input number of Portion!"], }, ], };
    const errorRecipeTypeChange = { errorFields: [ { errors: ["Please, select a Recipe's Type!"], }, ], };
    const errorData = { errorFields: [ { errors: ["Please, add an Articles/Recipe!"], }, ], };


    console.log("DATA ONSUBMIT >> ", data);

    if (edit == false) {
      if (recipeName != '' && recipeName != null) {
        if (recipeNumber != null && recipeNumber != '' && recipeNumber?.length == 4) {
          if (category != null) {
            if (portion > 0 && portion != null) {
              if (recipeTypeChange != null) {
                if (data.length > 0) {
                  showModalConfirm();
                } else {
                  masterIncomplete(errorData);
                }
              } else {
                masterIncomplete(errorRecipeTypeChange);
              }
            } else {
              masterIncomplete(errorPortion);
            }
          } else {
            masterIncomplete(errorCategory);
          }
        } else {
          masterIncomplete(errorRecipeNumber);
        }
      } else {
        masterIncomplete(errorRecipeName);
      }
    } else {
      console.log("Data ONSAVE EDIT: ", data);
      // showModalConfirm(data, true);
      if (recipeName != '' && recipeName != null) {
        if (recipeNumber != null && recipeNumber != '' && recipeNumber?.length == 4) {
          if (category != null) {
            if (portion > 0 && portion != null) {
              if (recipeTypeChange != null) {
                if (tableDetail.length > 0) {
                  showModalConfirm();
                } else {
                  masterIncomplete(errorData);
                }
              } else {
                masterIncomplete(errorRecipeTypeChange);
              }
            } else {
              masterIncomplete(errorPortion);
            }
          } else {
            masterIncomplete(errorCategory);
          }
        } else {
          masterIncomplete(errorRecipeNumber);
        }
      } else {
        masterIncomplete(errorRecipeName);
      }
    }
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = () => {

    confirm({
      className: "modals-confirm",
      title:
        // _status == true
        //   ? `Are you sure want to Submit and Create a Purchase Request?`
        //   : `Are you sure want to Save a Purchase Request as Draft?`,
           `Are you sure want to Create a Standard Recipe?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        if (edit == false) {
          // handleFinish(data, _status);
          handleFinish();
        } else {
          // handleFinish(submitEdit, _status);
          handleFinish();
        }
      },

      onCancel() {
        setIsDraft(false);
        setIsCreate(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Finish
  const handleFinish = async () => {
    console.log("TABLE DETAIL >> ", tableDetail);

    if(is_edit == false) {
      let detail = [];
  
      let body = {
        recipe_name: recipeName,
        recipe_number: recipeNumber,
        portion: portion,
        cost: cost,
        description: description,
        article_sub_id: category,
        created_by: user_name,
        recipe_type: recipeTypeChange
      };
  
      data.map((x) => {
        detail.push({
          article_name: x.item_name,
          id_article: x.id_item,
          lost_factor: x.lost_factor,
          recipe_unit: x.r_unit,
          content: x.content,
          nett_qty: x.nett_qty,
          cost: x.cost,
          item_type: x.cat_item
        });
      });

      body.details = detail;

      handleSubmit(body);
    } else {
      let detail = [];
      let add = [];
  
      let body = {
        id: selectedData?.id,
        recipe_name: recipeName,
        recipe_number: recipeNumber,
        portion: portion,
        cost: cost,
        description: description,
        article_sub_id: category,
        updated_by: user_name,
        recipe_type: recipeTypeChange
      };

      tableDetail.filter((a) => a.id_detail != null).map((x) => {
        detail.push({
          id: x.id_detail,
          article_name: x.item_name,
          id_article: x.id_item,
          lost_factor: x.lost_factor,
          recipe_unit: x.r_unit,
          content: x.content,
          nett_qty: x.nett_qty,
          cost: x.cost,
          item_type: x.cat_item
        });
      });

      tableDetail.filter((a) => a.id_detail == null).map((x) => {
        add.push({
          article_name: x.item_name,
          id_article: x.id_item,
          id_header: selectedData?.id,
          lost_factor: x.lost_factor,
          recipe_unit: x.r_unit,
          content: x.content,
          nett_qty: x.nett_qty,
          cost: x.cost,
          item_type: x.cat_item,
          created_by: user_name
        });
      });

      body.details = detail;

      body.add = add;
      
      handleSubmit(body);
    }
  };

  // Handle Delete
  const handleAboutDelete = (val) => {
    // console.log("Data: ", data);

    showModalDelete(val);
  };

  // Modal Delete
  const showModalDelete = (val) => {
    console.log("Delete: ", val);

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Delete an Item ${
        val?.item_name.toUpperCase() || " ----- "
      } for this Recipe?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDelete(val);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Delete
  const handleEditDetail = (val) => {
    console.log("Edit data: ", val);
    if (edit) {
      setTableDetail(val);
      let _costEdit = val.reduce((accumulator, currentValue) => accumulator + currentValue.cost, 0);
      setCost(_costEdit);
      form.setFieldsValue({
        cost: _costEdit,
      });
    } else {
      setData(val);

      let _costEdit = val.reduce((accumulator, currentValue) => accumulator + currentValue.cost, 0);
      setCost(_costEdit);
      form.setFieldsValue({
        cost: _costEdit,
      });
    }

  };

  // HANDLE SUBMIT
  const handleSubmit = async (value, detail) => {
    const contentSubmit = value;
    const contentDetail = detail;

    console.log("Data About to PUT/POST: ", contentSubmit, contentDetail);

    if (edit == false) {
      // POST HEADER
      await axios
        .post(`${baseurl}/kitchen/standard-recipe`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Add Standard Recipe: ", response);

          masterSuccessNotification(response, {
            method: 0,
            source: "Standard Recipe",
          });
          handleClose();
          is_refresh(true);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 0,
            source: "Standard Recipe",
          });
        })
        .finally(() => {
          handleClose();
          is_refresh(true);
        });

    } else {
      await axios
        .put(`${baseurl}/kitchen/standard-recipe`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Update Standard Recipe: ", response);

          masterSuccessNotification(response, {
            method: 1,
            source: "Standard Recipe",
          });
          handleClose();
          is_refresh(true);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 1,
            source: "Standard Recipe",
          });
        })
        .finally(() => {
          handleCancel();
        })
    }
  };

  // Handle Delete
  const handleDelete = async (val) => {
    let _deleted = data.filter((value) => {
      console.log("value, val >> ", value.id_item, val.id_item);
      return value.id_item != val.id_item;
    });

    let _deletedEdit = tableDetail?.filter((value) => {
      console.log("value, val >> ", value.id_item, val.id_item);
      return value.id_item != val.id_item;
    });

    console.log("VAL >> ", val);
    console.log("DATA >> ", dataEdit);
    console.log("DetailLength", dataEdit?.details?.length);

    if (edit === false) {
      setData(_deleted);
      setCost(cost - val?.cost);
      form.setFieldsValue({
        cost: (cost - val?.cost),
      });
      masterSuccessNotification(null, {
        method: 2,
        source: "Item Recipe",
      });
    } else {
      setTableDetail(_deletedEdit);
      setCost(cost - val?.cost);
      form.setFieldsValue({
        cost: (cost - val?.cost),
      });

      let obj = {
        id: val.id_detail,
        deleted_by: user_name,
      };
      
      await axios
        .delete(`${baseurl}/kitchen/standard-recipe-detail`, {
          data: obj,
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        })
        .then((response) => {
          console.log("DEL Item => ", response);

          masterSuccessNotification(response, {
            method: 2,
            source: "Item Standard Recipe",
          });
          setTableDetail(_deletedEdit);
          is_refresh(false);
        })
        .catch((error) => {
          masterFailedNotification(error, {
            method: 2,
            source: "Item Standard Recipe",
          });
          console.log("DEL Item => ", error);
        });
    }
  };

  return (
    <>
      <Modal
        className="purchase-req-modal"
        key="purchase-req-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Standard Recipe`}</Typography>
            </Row>
          </>
        }
        centered
        footer={null}
        open={modalOpen}
        closable
        onCancel={handleClose}
        width={1000}
      >
        <Form
          className="purchase-req-form"
          key="purchase-req-form"
          name="purchase-req-form"
          layout="vertical"
          form={form}
          initialValues={{
            department: department,
            department_id: department_id,
          }}
          onFinish={edit ? onFinishEdit : onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top">
            <Col className="form-col" span={24}>
              <Row className="recipe-name-row" gutter={30}>
                <Col span={8} className="recipe-name-col">
                  <Form.Item
                    label="Recipe's Name"
                    name="recipe_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, input a Recipe's Name!",
                      },
                    ]}
                  >
                    <Input
                      onChange={handleRecipeName}
                      placeholder="Recipe's Name"
                      className="recipe_name"
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="recipeno-col">
                  <Form.Item
                    label="Recipe's Number"
                    name="recipe_number"
                    rules={[
                      {
                        required: true,
                        message: "Please, input a Recipe's Number!",
                      },
                    ]}
                  >
                    <Input
                      onChange={handleRecipeNumber}
                      minLength={4}
                      placeholder="Recipe's Number"
                      className="recipe_number"
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="catname-col">
                  <Form.Item
                    label="Category"
                    name="category_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Category!",
                      },
                    ]}
                  >
                    <Select
                      onChange={handleCategory}
                      placeholder="Recipe's Category"
                      options={categoryOptionValue}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="desc-row" gutter={30}>
                <Col span={8} className="portion-col">
                  <Form.Item
                    label="Portion"
                    name="portion"
                    rules={[
                      {
                        required: true,
                        message: "Please, input number of Portion!",
                      },
                    ]}
                  >
                    <InputNumber
                      className="portion"
                      onChange={handlePortion}
                      placeholder="Portion"
                      min={0}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="cost-col">
                  <Form.Item label="Cost" name="cost">
                    <InputNumber
                      className="cost"
                      placeholder="Cost"
                      style={{
                        width: "100%",
                      }}
                      formatter={(e) => {
                        return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="recipe-type-col">
                  <Form.Item
                    label="Recipe's Type"
                    name="recipe_type"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Recipe's Type!",
                      },
                    ]}
                  >
                    <Select
                      // style={{ width: 120 }}
                      // allowClear
                      disabled={dataLength ? true : false}
                      onChange={handleRecipeTypeChange}
                      placeholder="Recipe's Type"
                      options={[
                        {
                          value: "2",
                          label: "Basic Recipe",
                        },
                        {
                          value: "1",
                          label: "Recipe Under Recipe",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="desc-row" gutter={30} justify="start">
                <Col span={24} className="desc-col">
                  <Form.Item label="Description" name="description">
                    <Input.TextArea
                      // disabled={is_edit ? 1 : 0}
                      onChange={handleDescription}
                      className="desc-area"
                      placeholder="Description"
                      min={0}
                      maxLength={150}
                      allowClear
                      showCount
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Select Items`}
              </Divider>

              <Row className="article-name-row" gutter={30} justify="start">
                <Col span={6} className="article-name-col">
                  <Form.Item
                    label="Article Item Name"
                    name="article_name"
                    rules={[
                      {
                        required: ( recipeTypeChange == 2 || (recipeTypeChange == 1 && recipeChange == null)) ? true : false,
                        message: ( recipeTypeChange == 2 || (recipeTypeChange == 1 && recipeChange == null)) ? "Please, select an Article Item!" : null,
                      },
                    ]}
                  >
                    <Select
                      // style={{ width: 120 }}
                      allowClear
                      onChange={handleArticleChange}
                      placeholder="Article Item's Name"
                      options={articleOptionValue}
                      disabled={(recipeTypeChange != null && recipeChange == null) ? false : true}
                    />
                  </Form.Item>
                  <Form.Item name="id_item" hidden><Input /></Form.Item>
                  <Form.Item name="item_number" hidden><Input /></Form.Item>
                  <Form.Item name="cat_item" hidden><Input /></Form.Item>
                </Col>

                <Col span={6} className="article-number-col">
                  <Form.Item
                    label="Recipe Item Name"
                    name="recipe_item_name"
                    rules={[
                      {
                        required: ( recipeTypeChange == 1 && articleChange == null ) ? true : false,
                        message: ( recipeTypeChange == 1 && articleChange == null ) ? "Please, select an Recipe Item!" : null,
                      },
                    ]}
                  >
                    <Select
                      // style={{ width: 120 }}
                      allowClear
                      onChange={handleRecipeChange}
                      placeholder="Recipe Item's Name"
                      options={recipeOptionValue}
                      disabled={(recipeTypeChange == 1 && articleChange == null) ? false : true}
                    />
                  </Form.Item>
                </Col>

                <Col span={6} className="nett-qty-col">
                  <Form.Item
                    label="Nett Quantity"
                    name="nett_quantity"
                    rules={[
                      {
                        required: recipeTypeChange != null ? true : false,
                        message: "Please, input number of Nett Quantity!",
                      },
                    ]}
                  >
                    <InputNumber
                      className="nett-qty-input"
                      placeholder="Nett Quantity"
                      onChange={handleNettQuantity}
                      min={0}
                      // formatter={(qty) =>
                      //   ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      // }
                      style={{
                        width: "100%",
                      }}
                      disabled={recipeTypeChange != null ? false : true}
                    />
                  </Form.Item>
                </Col>

                <Col span={6} className="lost-factor-col">
                  <Form.Item
                    label="Lost Factor (%)"
                    name="lost_factor"
                    rules={[
                      {
                        required: recipeTypeChange != null ? true : false,
                        message: "Please, input number of Lost Factor!",
                      },
                    ]}
                  >
                    <InputNumber
                      className="lost-factor-input"
                      placeholder="Lost Factor"
                      min={0}
                      // formatter={(qty) =>
                      //   ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      // }
                      style={{
                        width: "100%",
                      }}
                      disabled={recipeTypeChange != null ? false : true}
                    />
                  </Form.Item>
                </Col>

                
              </Row>

              <Row className="content-row" gutter={30} justify="start">
              <Col span={6} className="cost-item-col">
                  <Form.Item label="Cost Item" name="cost_item">
                    <InputNumber
                      disabled
                      className="cost-item-input"
                      placeholder="Cost Item"
                      min={0}
                      formatter={(e) => {
                        return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} className="avg-price-col">
                  <Form.Item
                    label={recipeChange ? "Cost Item /Portion" : "Average Price"}
                    name="average_price"
                  >
                    <InputNumber
                      disabled
                      className="avg-price-input"
                      placeholder="Average Price"
                      formatter={(e) => {
                        return `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} className="content-col">
                  <Form.Item label="Content" name="content" hidden={recipeChange != null ? true : false}>
                    <InputNumber
                      disabled
                      className="content-input"
                      placeholder="Content"
                      min={0}
                      // formatter={(qty) =>
                      //   ` ${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      // }
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} className="r-unit-col">
                  <Form.Item label="R-Unit" name="r_unit" hidden={recipeChange != null ? true : false}>
                    <Input
                      disabled
                      className="r-unit"
                      placeholder="R-Unit"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="article-row" justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  icon={<PlusOutlined />}
                  htmlType="submit"
                >{`Add Item`}</Button>
              </Row>

              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Item List`}
              </Divider>

              <StandardRecipeDetailTable
                item={edit ? tableDetail : data}
                getDeleteDetail={handleAboutDelete}
                getEditDetail={handleEditDetail}
                is_open={is_open}
                is_close={is_close}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={onSubmit}
                  // disabled={isDraft}
                  // loading={isCreate}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React Components
import React, { useContext, useEffect, useState } from "react";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// FUNCTION CODE
// export default function PreTotalPriceFunction(dataGuest) {
//   // console.log("Data Guest: ", dataGuest);
//   // CONTEXT
//   const { getArticleRoom, getPreBillArticle } = useContext(MainContext);

//   // STATE
//   // Data
//   const [room, setRoom] = useState([]);
//   const [article, setArticle] = useState([]);
//   // Return
//   const [articleList, setArticleList] = useState([]);
//   const [totalPrice, setTotalPrice] = useState({
//     nett: 0,
//     service: 0,
//     tax: 0,
//     gross: 0,
//   });

//   // FETCH DATA
//   // Fetch Room
//   const fetchData = async (value) => {
//     const _data = value;
//     console.log("Value: ", _data);

//     await getArticleRoom({
//       articleRoomPrice: "reservation",
//       type: "article-room-single",
//       res_id: _data?.reservation_id,
//       prc_id: _data?.price_id,
//       onAwait: () => {},
//       onSuccess: (response) => {
//         console.log("Article Room => ", response);

//         if (response?.data?.length > 0) {
//           let _response = response?.data;
//           let _mapped = _response.map((value) => {
//             return {
//               article_name: `Room ${e?.room_category || "Unknown"} ${
//                 e?.room_number || 0
//               }`,
//               item_quantity: e?.night || 1,
//               unit_price: e?.room_price || 0,
//               total_price: e?.room_price || 0,
//               tax: e?.tax,
//               gross: e?.gross,
//               service: e?.service,
//               id: id++,
//             };
//           });

//           setRoom(_mapped);
//         }
//       },
//       onReject: (error) => {
//         console.log(error);
//         // setLoading(false);
//       },
//     });

//     await getPreBillArticle({
//       articleStay: "bill",
//       type: "article-res-stay-guest",
//       res_id: _data?.reservation_id,
//       prc_id: _data?.price_id,
//       onAwait: () => {},
//       onSuccess: (response) => {
//         console.log("Response Article List => ", response);

//         if (response?.data?.length > 0 || response?.data) {
//           let _arr = response?.data?.length;
//           let _mapped = _arr.map((value) => {
//             return {
//               article_name: value?.article_name
//                 ? val.article_name + " - " + val?.room_number
//                 : "",
//               item_quantity: value?.item_quantity ? value.item_quantity : 0,
//               unit_price: value?.unit_price ? value.unit_price : 0,
//               total_price: value?.unit_price
//                 ? value.unit_price * value?.item_quantity
//                 : 0,
//               tax: value?.tax > 0 ? value.tax * value.item_quantity : 0,
//               gross: value?.gross > 0 ? value.gross * value.item_quantity : 0,
//               service:
//                 value?.service > 0 ? value.service * value.item_quantity : 0,
//               id: value?.id > 0 ? value.id : 0,
//             };
//           });

//           setArticle(_mapped);
//         }
//       },
//       onReject: (error) => {
//         console.log(error);
//       },
//     });
//   };

//   // FETCH DATA
//   useEffect(() => {
//     let cleanUp = false;

//     if (dataGuest && !cleanUp) {
//       fetchData(dataGuest);
//     }

//     return () => {
//       cleanUp = true;
//     };
//   }, [dataGuest]);

//   // USE EFFECT ROOM ARTICLE
//   useEffect(() => {
//     let cleanUp = false;

//     if ((article?.length > 0 || article?.length > 0) && !cleanUp) {
//       // console.log(articleRoom);
//       handleTotal(room, article);
//     }

//     return () => {
//       cleanUp = true;
//     };
//   }, [article, room, guest, is_saved]);

//   // HANDLE TOTAL PRICE
//   const handleTotal = (arr, art) => {
//     let _room = arr;
//     let _stay = art;

//     let totalNett = 0;
//     let totalTax = 0;
//     let totalService = 0;
//     let totalGross = 0;

//     const _join = [..._room, ..._stay];

//     console.log("Article Room => ", _room);
//     console.log("Article Stay => ", _stay);

//     if (_join.length > 0) {
//       _join.forEach(({ total_price, gross, tax, service }) => {
//         totalNett += total_price;
//         totalService += service;
//         totalTax += tax;
//         totalGross += gross;
//       });
//     }

//     console.log("Joined Array >> ", _join);
//     console.log("Total Price >> ", total);

//     setTotalPrice({
//       nett: totalNett,
//       service: totalService,
//       tax: totalTax,
//       gross: totalGross,
//     });
//     setArticleList(_join);
//   };

//   return { articleList, totalPrice };
// }

export function PreTotalPriceFunction(params) {
  const _arr = params;

  console.log("Array passed in Functions: ", _arr);

  let totalNett = 0;
  let totalTax = 0;
  let totalService = 0;
  let totalGross = 0;
  let unitPrice = 0;

  // const _join = [..._room, ..._stay];

  // console.log("Article Room => ", _room);
  // console.log("Article Stay => ", _stay);

  if (_arr.length > 0) {
    _arr.forEach(({ total_price, gross, tax, service, unit_price }) => {
      totalNett += total_price;
      totalService += service;
      totalTax += tax;
      totalGross += gross;
      unitPrice += unit_price;
    });
  }

  // console.log("Joined Array >> ", _arr);

  const totalPrice = {
    nett: totalNett,
    service: totalService,
    tax: totalTax,
    gross: totalGross,
    unit_price: unitPrice,
  };

  console.log("Total Price >> ", totalPrice);
  // setArticleList(_join);

  return totalPrice;
}

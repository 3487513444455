// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASE API
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";

// Import React Icons
import { FiEdit } from "react-icons/fi";
import { DeleteFilled } from "@ant-design/icons";

// Import Master Components
import MasterCOA from "../../../Reusable/Accounting/MainAccount/MainAccount";

// Import Notifications
import {
  failedCreateJournal,
  incomplete,
  successCreateJournal,
} from "../../../Reusable/Notification/Notification";
import { journalIsNotBalance } from "../../../Reusable/Notification/MasterNotif/Notification";

// MODAL CONFIRM
const { confirm } = Modal;
const { open } = notification;

// CODE
export default function ModalCreateJournal(props) {
  // Properties
  const { openModal, closeModal, is_refresh } = props;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [keyCurrent, setKeyCurrent] = useState(1);
  // Journal Table

  // Boolean
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Row className="action-table-btn" justify="center">
            <Col span={12} className="col-del-btn">
              <Tooltip title="Delete Data" className="del-tooltip">
                <DeleteFilled
                  className="delete-btn"
                  onClick={() => {
                    console.log("ABOUT TO DELETE > ", record);

                    showModalDelete(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#F64E60",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Account Code",
      dataIndex: "account_code",
      key: "account_code",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Desc",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Notes",
    //   dataIndex: "notes",
    //   key: "notes",
    // },
  ];

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // ON FINISH
  const onFinish = (val) => {
    const _finish = val;
    // setIsLoading(true);

    console.log("ON FINISH: ", _finish);
    handleGenerateJournal(_finish);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Finish Failed: ", val.values);
    incomplete(error);
  };

  // Handle Finish
  const handleFinish = (value) => {
    let _debt = 0;
    let _credit = 0;

    console.log("SUMM: ", data);
    console.log("ON FINISH VALUE: ", value);

    if (data.length > 0) {
      let _summaries = data.forEach(({ credit, debit }) => {
        // console.log("SUMM: ", credit);
        _debt += debit;
        _credit += credit;
      });
    } else {
      _debt = 0;
      _credit = 0;
    }

    console.log("DEBT: ", _debt);
    console.log("CREDIT: ", _credit);

    if (_debt - _credit !== 0) {
      journalIsNotBalance(1);
    } else if (data.length === 0) {
      journalIsNotBalance(2);
    } else {
      showModalConfirm();
    }
  };

  // Handle Cancel
  const handleCancel = () => {
    closeModal(false);
    setOpen(false);
    setData([]);

    setIsLoading(false);

    form.resetFields();
  };

  // COA GET
  const getCoaName = (val) => {
    console.log(val);

    form.setFieldsValue({
      account_name: val?.name,
      account_code: val?.code,
    });
  };

  // Handle Debit/Credit
  const handleBalance = (val, code) => {
    console.log(val, code);
  };

  // HAndle Genereate Journal
  const handleGenerateJournal = (value) => {
    const _arr = value;
    let _newJournal = [];

    if (_arr.account_balance === 0) {
      _newJournal.push({
        account_name: _arr?.account_name,
        account_code: _arr?.account_code,
        debit: _arr?.amount,
        credit: 0,
        description: _arr?.description,
        notes: _arr?.notes,
        id: keyCurrent,
      });
    } else {
      _newJournal.push({
        account_name: _arr?.account_name,
        account_code: _arr?.account_code,
        debit: 0,
        credit: _arr?.amount,
        description: _arr?.description,
        notes: _arr?.notes,
        id: keyCurrent,
      });
    }

    setKeyCurrent(keyCurrent + 1);

    if (_newJournal.length > 0) {
      console.log("New Journal: ", _newJournal);
      if (data?.length > 0) {
        console.log("Data: ", data);
        setData([...data, ..._newJournal]);
      } else {
        console.log("Data: ", data);
        setData(_newJournal);
      }
    } else {
      setData([]);
    }

    setLoading(false);

    form.resetFields([
      "account_name",
      "account_code",
      "debit",
      "credit",
      "description",
      "notes",
      "amount",
      "account_balance",
    ]);
  };

  // MODAL CONFIRM
  const showModalConfirm = (value) => {
    setIsLoading(true);

    confirm({
      className: "modal-confrim",
      title: "Are you sure you wanted to created a Journal?",
      closable: true,
      centered: true,
      okText: "Confirm",
      width: 800,

      onCancel() {
        setIsLoading(false);
      },

      onOk() {
        handleSubmit(value);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "refresh-btn",
        type: "default",
      },
    });
  };

  // MODAL CONFIRM DELETE
  const showModalDelete = (value) => {
    const _content = value;

    confirm({
      className: "modal-confrim",
      title: `Are you sure you wanted to delete Journal with named ${
        _content?.account_name.toUpperCase() || " --- "
      }?`,
      closable: true,
      centered: true,
      okText: "Confirm",
      width: 800,

      onCancel() {
        setIsLoading(false);
      },

      onOk() {
        handleDelete(_content);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "refresh-btn",
        type: "default",
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _submit = value;
    let _array = [];

    console.log("HANDLE SUBMIT: ", _submit);

    for (let i = 0; i < data?.length; i++) {
      _array.push({
        article_name: data[i]?.account_name,
        chart_of_account: data[i]?.account_code,
        credit: data[i]?.credit,
        debit: data[i]?.debit,
        created_by: user_name,
      });
    }

    console.log("Array: ", _array);

    await axios
      .post(
        `${baseurl}/accounting/journal/manual`,
        { data: _array },
        { headers: { Authorization: `Bearer ${token || ""}` } }
      )
      .then((response) => {
        console.log("Res Create Journal Manuals: ", response);

        successCreateJournal(response);
        is_refresh(true);

        handleCancel();
      })
      .catch((error) => {
        console.log("Failed Create Journal: ", error);

        failedCreateJournal(error);
        setIsLoading(false);
      });
  };

  // HANDLE DELETE
  const handleDelete = (value) => {
    const _submit = value;

    let _filtered = data.filter((value) => {
      return value.id !== _submit.id;
    });

    console.log("AFTER DELETE: ", _filtered);
    setData(_filtered);
  };

  return (
    <>
      <Modal
        className="create-journal-modal"
        key="create-journal-modal"
        title={
          <Row>
            <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography style={{ marginLeft: 15 }}>
              {`Create Journal Manual`}
            </Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={1000}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          className="create-journal-form"
          key="create-journal-form"
          name="create-journal-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{
            padding: "0px 36px",
          }}
        >
          <Row
            gutter={30}
            className="form-main-row"
            justify="start"
            align="top"
          >
            <Col span={24} className="form-main-col">
              <Row className="coa-row" gutter={30}>
                <Col span={16} className="coa-col">
                  <Form.Item
                    label="Account Code"
                    name="account_name"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <MasterCOA is_all={true} getCoaName={getCoaName} />
                  </Form.Item>

                  <Form.Item label="Account Code" name="account_code" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="balance-row" gutter={30}>
                <Col span={8} className="coa-col">
                  <Form.Item
                    label="Debit/Credit"
                    name="account_balance"
                    rules={[
                      {
                        required: true,
                        message: "Please, Choose an Options!",
                      },
                    ]}
                  >
                    <Select
                      className="balance-select"
                      key="balance-select"
                      allowClear
                      placeholder="Select an Option!"
                      onChange={handleBalance}
                      options={[
                        {
                          label: "Debit",
                          value: 0,
                        },
                        {
                          label: "Credit",
                          value: 1,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="amount-col">
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please, Input an Amount!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Amount"
                      className="amount-input"
                      key="amount-input"
                      min={0}
                      formatter={(price) => {
                        return `${price > 0 ? price : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row className="descr-row" gutter={30}>
                <Col span={16} className="coa-col">
                  <Form.Item label="Journal Description" name="description">
                    <Input.TextArea
                      placeholder="Journal Description"
                      allowClear
                      showCount
                      rows={3}
                      maxLength={120}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              /> */}

              <Row className="trans-row" gutter={30}>
                <Col span={16} className="coa-col">
                  <Form.Item label="Transaction Notes" name="notes" hidden>
                    <Input.TextArea
                      placeholder="Transaction Notes"
                      allowClear
                      showCount
                      rows={3}
                      maxLength={120}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "5px 0px", background: "#EBEDF3" }}
              />

              <Row className="btn-row" justify="end" align="top">
                <Button className="next-btn" type="primary" htmlType="submit">
                  {`Add Journal`}
                </Button>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "10px 0px", background: "#EBEDF3" }}
              />

              <Row className="table-row" align="top">
                <Col span={24} className="table-col">
                  <Form.Item label="Table Journals" name="table_details">
                    <Table
                      className="create-table"
                      key="create-journal-tables"
                      bordered
                      dataSource={data}
                      columns={columns}
                      loading={loading}
                      size="middle"
                      pagination={{
                        defaultPageSize: 5,
                        pageSizeOptions: [10, 20, 50, 100],
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                          `Showing ${range[0]}-${range[1]} of ${total} entries`,
                      }}
                      scroll={{
                        x: true,
                      }}
                      rowKey={(record) => {
                        return record.id;
                      }}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "odd" : "even";
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="btn-row" justify="end" align="top">
                <Button
                  className="submit-btn"
                  type="primary"
                  //   htmlType="submit"
                  loading={isLoading}
                  onClick={handleFinish}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  onClick={handleCancel}
                >{`Cancel`}</Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  failedDepartmentList,
  successDepartmentList,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import GuestInHousePopover from "../../../Popover/GuestInHouse/Popover";
import DepartureForm from "../../../Forms/Departure/Form";

// Import Function
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

const { confirm } = Modal;

// CODE
export default function GuestInHouseTable(props) {
  // PROPS
  const { is_search, searchKey, searchDate } = props;

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24} className="col-menu">
                <Tooltip title="Option" className="option-tooltip">
                  <GuestInHousePopover
                    details={record}
                    is_refresh={setRefresh}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      width: 150,
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
      // render: (_, record) => {
      //   if (record.group == 2) {
      //     return "";
      //   } else {
      //     return record.reservation_id;
      //   }
      // },
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      width: 200,
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      width: 110,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
            {gst}
          </Tag>
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 75,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Check-In Date",
      dataIndex: "checkin_date",
      key: "checkin_date",
      sortDirections: ["descend", "ascend"],
      render: (checkin, record) => {
        return moment(LocalizationDate(checkin)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
      sorter: (a, b) => moment(a.checkin_date) - moment(b.checkin_date),
    },
    {
      title: "Expected Departure",
      dataIndex: "departure",
      key: "departure",
      width: 200,
      sortDirections: ["descend", "ascend"],
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.departure) - moment(b.departure),
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      width: 100,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.adult.localeCompare(b.adult),
    },
    {
      title: "Showing Status",
      dataIndex: "is_no_show",
      key: "is_no_show",
      render: (show, record) => {
        let clr = "";
        let txt = "";

        switch (show) {
          case true:
            clr = "#f5222d";
            txt = "NO-SHOW";
            break;

          case false:
            clr = "#52c41a";
            txt = "SHOWING";
            break;

          default:
            clr = "#52c41a";
            txt = "SHOWING";
            break;
        }

        return (
          <Tag className="show-tag" key={record.id} color={clr}>
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "Breakfast",
      dataIndex: "is_abf",
      key: "is_abf",
      align: "center",
      render: (abf) => {
        return (
          <Tag key={"abf-keytag"} color={abf === true ? `green` : `geekblue`}>
            {abf === true ? `RB` : `RO`}
          </Tag>
        );
      },
      // width: 150,
    },
    {
      title: "Rate Code",
      dataIndex: "rate_code",
      key: "rate_code",
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      render: (group, record) => {
        let grp = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            break;

          case 1:
            grp = "Group Leader";
            break;

          case 2:
            grp = "Group Member";
            break;

          default:
            break;
        }

        return grp;
      },
    },
  ];

  // CONTEXT
  const { getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  const [resvId, setResvId] = useState();
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Laoding
  const [loading, setLoading] = useState(false);
  // Refresh State
  const [refresh, setRefresh] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [isPrint, setIsPrint] = useState(false);

  const openModal = () => {
    setOpen(!open);
  };

  // Fetch Data
  const fetchData = async () => {
    await getGuestInHouse({
      guestInHouse: "guest-in-house",
      type: "stay",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);
        // let filtered = response.data.msg.filter((filt) => {
        //   if (filt.is_deleted == false || filt.is_deleted == null) {
        //     return filt;
        //   }
        // });

        setData(response?.data?.msg || []);
        setBulks(response?.data?.msg || []);
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // is_search(() => handleSearch);
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, searchDate]);

  // Use Effect Refresh
  useEffect(() => {
    if (refresh == true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  // HANDLE PRINT
  const handlePrint = async () => {
    setIsPrint(true);
  };

  // CHECK COMPLETENESS
  const checkAvailability = (param) => {
    return !Object.keys(
      (({
        // group_name,
        // arrival_flight,
        // eta,
        // departure_flight,
        // etd,
        // is_dummy,
        // is_group,
        // reservation_id,
        // letter,

        // created_at,
        // created_by,

        // memo_room,
        // room_type,
        // arrangement,
        // deposit,
        // room_rate_id,
        // is_no_show,
        // reason,

        // updated_by,
        // updated_date,
        // sub_segment_id,
        // rate_code,
        // arrival,
        // departure,
        // night,
        // room_category,
        // room_number,
        // room_qty,
        // adult,
        // child,
        // infant,
        // reservation_status,
        // compliment_type,
        // currency,
        // guest_name,
        // bill_receiver,
        // bill_instruction,
        // group,
        // segment,
        // source,
        // sales,
        // payment_type,
        // payment_details,
        // guest_category,
        // price_id,
        // id,
        // guest_id,
        // is_cm,
        // is_step,
        // step_date,
        // step_by,

        // is_checkout,
        // checkout_date,
        // checkout_by,

        // is_deleted,
        // deleted_date,
        // deleted_by,

        // is_checkin,
        // checkin_date,
        // checkin_by,

        // payment_status,
        // is_abf,
        // is_nonstay,
        // nonstay_date,
        // nonstay_by,
        // nonstay_count,

        // is_late_checkout,
        // late_checkout_date,
        // late_checkout_by,

        // is_audit,
        // audit_at,
        // audit_by,
        // hotel_id,
        // room_category_id,
        // is_re_audit,
        // re_audit_at,
        // re_audit_by,
        group_name,
        arrival_flight,
        eta,
        departure_flight,
        etd,
        is_dummy,
        is_group,
        letter,
        created_by,
        created_date,
        nationality,
        memo_room,
        room_type,
        arrangement,
        deposit,
        room_rate_id,
        is_no_show,
        reason,

        updated_by,
        updated_date,
        sub_segment_id,
        rate_code,

        is_checkin,
        checkin_date,
        checkin_by,

        step_date,
        step_by,

        is_cm,
        is_step,
        is_deleted,
        is_checkout,

        checkout_by,
        checkout_date,

        deleted_by,
        deleted_date,

        is_nonstay,
        nonstay_by,
        nonstay_count,
        nonstay_date,

        is_audit,
        audit_at,
        audit_by,
        is_late_checkout,
        late_checkout_by,
        late_checkout_date,

        re_audit_at,
        is_re_audit,
        re_audit_by,
        ...o
      }) => o)(param)
    ).some((key) => {
      // console.log("Params: ", param);
      // console.log(
      //   "FILTERED ==> ",
      //   param[key] === "" || param[key] === null || param[key] === undefined
      // );

      return (
        param[key] === "" || param[key] === null || param[key] === undefined
        // param[key] == []
      );
    });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    setLoading(true);

    const queryName = searchKey ? searchKey.toLowerCase() : null;
    const queryDate = searchDate
      ? moment(searchDate).format("YYYY-MM-DD")
      : null;

    // console.log("Date = ", queryDate);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const name = item?.guest_name ? item?.guest_name.toLowerCase() : null;

        // console.log("ITEM => ", item)

        return (
          item.guest_category != "INCOGNITO" && name.indexOf(queryName) !== -1
        );
      });

      console.log(updatedList);

      if (queryDate != "Invalid date" && queryDate !== null) {
        handleChekcIn(updatedList?.length > 0 ? updatedList : bulks, queryDate);
      } else {
        setData(updatedList);
      }
    } else {
      setData([]);
    }

    setLoading(false);
  };

  // FILTER BY DATE
  const handleChekcIn = (value, date) => {
    //   console.log(value);
    //   console.log("Date = ", date);
    let _newData = value.filter((items) => {
      const dep = moment(items?.checkin_date).format("YYYY-MM-DD");
      // console.log(dep);

      return moment(dep).isSame(date);
    });

    console.log("New Date: ", _newData);

    setData(_newData);
    setLoading(false);
  };

  return (
    <>
      <Row
        className="guest-inhouse-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            justify="start"
            className="row-search-btn"
            align="top"
            gutter={[0, 30]}
          >
            {/* <Button
              className="submit-btn"
              type="primary"
              onClick={openModal}
              icon={<PlusOutlined />}
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              Add Article
            </Button> */}
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
              align="start"
              // direction="horizontal"
            >
              <Button
                type="primary"
                className="print-btn"
                icon={<PrinterOutlined />}
                onClick={handlePrint}
              >{`Print & Download List`}</Button>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="refresh-col"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Button
                className="refresh-btn"
                type="default"
                icon={<SyncOutlined />}
                onClick={fetchData}
              >
                {`Refresh`}
              </Button>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="guest-inhouse-table"
              key="guest-inhouse-table"
              name="guest-inhouse-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                const _bool = checkAvailability(record);

                console.log("Bool: ", _bool);

                return _bool ? "completed" : "incompleted";
              }}
              scroll={{
                x: 2100,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>

          {/* <ModalAddArticle openModal={open} closeModal={setOpen} /> */}
        </Col>
      </Row>

      <DepartureForm
        modalOpen={isPrint}
        modalClose={setIsPrint}
        dataDepart={isPrint == true ? data : null}
      />
    </>
  );
}

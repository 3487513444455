// Import React Components
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// BASE API
import { token } from "../../../../../../API/Global/Payload";
import { baseurl } from "../../../../../../API/Config/Api";

// CONTEXT
import { Context as FinanceContext } from "../../../../../../API/Context/FinanceContext/FinanceContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Form,
  Tooltip,
  Tag,
  Modal,
  Typography,
  Input,
  Select,
  InputNumber,
  DatePicker,
} from "antd";

// Import React Icons
import { IoReturnUpBackOutline } from "react-icons/io5";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons/lib/icons";
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// Import Functions
import { CurrencySymbols } from "../../../../../Reusable/Functions/Currency/Currency";

// Import Global Style
import { mainBody } from "../../../../../../Style/Global/Global";
import { render } from "@testing-library/react";

// CODE
export default function TrialBalanceTable() {
  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getTrialBalance } = useContext(FinanceContext);

  // COLUMNS
  const columns = [
    // {
    //   title: "No",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 50,
    //   render: (_, record, index) => {
    //     return index + 1;
    //   },
    // },
    {
      title: "Account Code",
      dataIndex: "account_code",
      key: "account_code",
      //   render: (_, record, index) => {
      //     return index + 1;
      //   },
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      //   render: (_, record, index) => {
      //     return index + 1;
      //   },
    },
    {
      title: `Begining Balance ( ${currency} )`,
      dataIndex: "initial_balance",
      key: "initial_balance",
      align: "right",
      render: (init) => {
        return `${init > 0 ? init : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    // {
    //   title: `Last Month Balance ( ${currency} )`,
    //   dataIndex: "initial_balance",
    //   key: "initial_balance",
    //   align: "right",
    //   render: (init) => {
    //     return `${init > 0 ? init : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   },
    // },
    {
      title: `Debit ( ${currency} )`,
      dataIndex: "debit",
      key: "debit",
      align: "right",
      render: (debit) => {
        return `${debit > 0 ? debit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Credit ( ${currency} )`,
      dataIndex: "credit",
      key: "credit",
      align: "right",
      render: (credit) => {
        return `${credit > 0 ? credit : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Ending Balance (${currency})`,
      dataIndex: "final_balance",
      key: "final_balance",
      align: "right",
      render: (final) => {
        return `${final > 0 ? final : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    // {
    //   title: `This Month Balance (${currency})`,
    //   dataIndex: "final_balance",
    //   key: "final_balance",
    //   align: "right",
    //   render: (final) => {
    //     return `${final > 0 ? final : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   },
    // },
    {
      title: `MTD (${currency})`,
      dataIndex: "mtd",
      key: "mtd",
      align: "right",
      render: (mtd) => {
        return `${mtd > 0 ? mtd : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `YTD (${currency})`,
      dataIndex: "ytd",
      key: "ytd",
      align: "right",
      render: (ytd) => {
        return `${ytd > 0 ? ytd : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Budget (${currency})`,
      dataIndex: "budget",
      key: "budget",
      align: "right",
      render: (budget) => {
        return `${budget > 0 ? budget : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (code, record) => {
        return `${code} - ${record.code_name}`;
      },
    },
    // {
    //   title: "Code Name",
    //   dataIndex: "code_name",
    //   key: "code_name",
    // },
    {
      title: `Net Change `,
      dataIndex: "net_change",
      key: "net_change",
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [monthPicked, setMonthPicked] = useState(null);
  // const [total, setTotal] = useState({
  //   credit: 0,
  //   debit: 0,
  //   credit_final_balance: 0,
  //   debit_final_balance: 0,
  //   credit_initial_balance: 0,
  //   debit_initial_balance: 0,
  // });
  // Loading
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // FETCH DATA
  const fetchData = async () => {
    // await getTrialBalance({
    //   type: "trial-balance-mtd",
    //   onAwait: () => {
    setLoading(true);
    //   },
    //   onSuccess: (response) => {
    //     console.log("Balance Sheet --> ", response);

    //     setData(response.data?.msg);
    //     setLoading(false);
    //   },
    //   onReject: (error) => {
    //     console.log("Err --> ", error);
    //     setLoading(false);
    //   },
    // });

    await axios
      .post(
        `${baseurl}/accounting/trial-balance-history`,
        {
          date: moment(monthPicked).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Res History: ", response);

        if (typeof response?.data === "object") {
          const _res = response.data;

          // const _data = _res.filter(
          //   (item) => item.account_type_id !== 98 && item.account_type_id !== 99
          // );

          // let _total = {
          //   credit: 0,
          //   debit: 0,
          //   credit_final_balance: 0,
          //   debit_final_balance: 0,
          //   credit_initial_balance: 0,
          //   debit_initial_balance: 0,
          // };

          // _res.filter((item) => {
          //   if (item.account_type_id === 98)
          //     _total = {
          //       ..._total,
          //       credit: item.credit,
          //       credit_final_balance: item.final_balance,
          //       credit_initial_balance: item.initial_balance,
          //     };

          //   if (item.account_type_id === 99)
          //     _total = {
          //       ..._total,
          //       debit: item.debit,
          //       debit_final_balance: item.final_balance,
          //       debit_initial_balance: item.initial_balance,
          //     };
          // });

          setData(_res);
          // setTotal(_total);
        } else {
          setData([]);
          // setTotal({
          //   credit: 0,
          //   debit: 0,
          //   credit_final_balance: 0,
          //   debit_final_balance: 0,
          //   credit_initial_balance: 0,
          //   debit_initial_balance: 0,
          // });
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log("Res History Error: ", error);
        failedFetch(error);
        setLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (monthPicked && !cleanUp) {
      // console.log("Month Picked: ", monthPicked);

      fetchData();
    } else {
      setData([]);
      // setTotal({
      //   credit: 0,
      //   debit: 0,
      //   credit_final_balance: 0,
      //   debit_final_balance: 0,
      //   credit_initial_balance: 0,
      //   debit_initial_balance: 0,
      // });
    }

    return () => {
      cleanUp = true;
    };
  }, [monthPicked]);

  // Return
  const move = () => {
    const path = "/report/accounting/monthly-accounting-report";
    // console.log(pathname);
    // console.log(search);

    navigate(`${path}`);
  };

  // HANDLE MONTH
  const handleMonth = (val) => {
    const _month = val;
    console.log("MONTH: ", _month);

    setMonthPicked(_month);
  };

  return (
    <>
      <Row className="account-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: 30 }}>
          <Row justify="space-between" className="row-table">
            {/* <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Add COA (Chart of Accounts)`}
            </Button> */}

            <Col
              className="title-col"
              xxl={12}
              xl={12}
              md={12}
              lg={24}
              sm={24}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/*   <Typography 
            //     className="title-text"
            //     style={{
            //       fontWeight: 750,
            //       fontSize: 20,
            //       color: "#3699FF",
            //     }}
            //   >
            //     {`Trial Balance MTD`}
           </Typography> */}
              {`Select a Month`}
              <DatePicker.MonthPicker
                placeholder="Select a Month!"
                allowClear
                onChange={handleMonth}
                style={{
                  minWidth: 150,
                  maxWidth: 250,
                }}
              />
            </Col>

            <Col
              className="title-col"
              xxl={12}
              xl={12}
              md={12}
              lg={24}
              sm={24}
              xs={24}
            >
              <Row
                className="btn-row"
                justify="end"
                gutter={[0, 15]}
                align="top"
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={() => monthPicked && fetchData()}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={move}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="trial-balance-table"
              name="trial-balance-table"
              key="trial-balance-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              //     {
              //     defaultPageSize: 5,
              //     pageSizeOptions: [10, 20, 50, 100],
              //     showSizeChanger: true,
              //     showTotal: (total, range) =>
              //       `Showing ${range[0]}-${range[1]} of ${total} entries`,
              //   }}
              scroll={{
                x: 1800,
              }}
              rowKey={(record) =>
                record.id ? record.id : record.chart_of_account
              }
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              // summary={() => {
              //   return (
              //     <>
              //       <Table.Summary fixed>
              //         <Table.Summary.Row>
              //           <Table.Summary.Cell index={0} colSpan={2}>
              //             <b>{"TOTAL DEBIT"}</b>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={0} align="right">
              //             <b>
              //               {`${total?.debit_initial_balance}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </b>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={0} rowSpan={2} align="right">
              //             <b>
              //               {`${total?.debit}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </b>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={0} rowSpan={2} align="right">
              //             <b>
              //               {`${total?.credit}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </b>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={0} align="right">
              //             <b>
              //               {`${total?.debit_final_balance}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </b>
              //           </Table.Summary.Cell>

              //           {/* <Table.Summary.Cell index={0}>
              //             <b>
              //               <Tag color="geekblue">DEBIT</Tag>
              //             </b>
              //           </Table.Summary.Cell> */}
              //         </Table.Summary.Row>

              //         <Table.Summary.Row>
              //           <Table.Summary.Cell index={0} colSpan={2}>
              //             <b>{"TOTAL CREDIT"}</b>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={0} align="right">
              //             <b>
              //               {`${total?.credit_initial_balance}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </b>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={0} align="right">
              //             <b>
              //               {`${total?.credit_final_balance}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </b>
              //           </Table.Summary.Cell>

              //           {/* <Table.Summary.Cell index={0}>
              //             <b>
              //               <Tag color="green">CREDIT</Tag>
              //             </b>
              //           </Table.Summary.Cell> */}
              //         </Table.Summary.Row>
              //       </Table.Summary>
              //     </>
              //   );
              // }}
              // summary={(pageData) => {
              //   let totalDebit = 0;
              //   let totalCredit = 0;

              //   pageData.forEach(({ debit, credit }) => {
              //     totalDebit += debit;
              //     totalCredit += credit;
              //   });

              //   return (
              //     <>
              //       <Table.Summary fixed>
              //         <Table.Summary.Row>
              //           <Table.Summary.Cell index={3} colSpan={3}>
              //             <Typography
              //               className="total-text"
              //               style={{
              //                 fontWeight: 750,
              //                 fontSize: 17,
              //               }}
              //             >
              //               TOTAL
              //             </Typography>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={4}>
              //             <Typography
              //               className="total-text"
              //               style={{
              //                 fontWeight: 750,
              //                 fontSize: 17,
              //               }}
              //             >
              //               {`${currency}. ${totalDebit}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </Typography>
              //           </Table.Summary.Cell>

              //           <Table.Summary.Cell index={4}>
              //             <Typography
              //               className="total-text"
              //               style={{
              //                 fontWeight: 750,
              //                 fontSize: 17,
              //               }}
              //             >
              //               {`${currency}. ${totalCredit}`.replace(
              //                 /\B(?=(\d{3})+(?!\d))/g,
              //                 ","
              //               )}
              //             </Typography>
              //           </Table.Summary.Cell>
              //         </Table.Summary.Row>
              //       </Table.Summary>
              //     </>
              //   );
              // }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import {
  Col,
  Row,
  Layout,
  Divider,
  Space,
  Button,
  Modal,
  Select,
  Input,
  InputNumber,
  Form,
  Typography,
} from "antd";

// Import React Icons
import {
  PlusOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons/lib/icons";
import { FaPlusCircle } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";

// Import Page Components
import ReservationDepositTable from "../../../Components/Table/FrontOffice/ReservationDeposit/Table";
import ReservationDepositModalTable from "../../../Components/Modals/ReservationDeposit/Modal";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

const { Content } = Layout;

// CODE
export default function ReservationDepositPage() {
  // STATE MANAGEMENT
  // Modal State
  const [search, setSearch] = useState(null);
  const [value, setValue] = useState(null);

  // Handle Change
  const handleChange = (val) => {
    const _val = val.target.value;

    setValue(_val);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="reservation-deposit-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <GiMoneyStack
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`List Data Deposit`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Reservation Number`}
                </Typography>

                <Input
                  placeholder="Reservation Numbers"
                  allowClear
                  onChange={handleChange}
                />
              </Col>

              <Col
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <ReservationDepositTable searchKey={value} is_search={setSearch} />
        </Col>
      </Content>
    </Layout>
  );
}

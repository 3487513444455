// Import React's Component
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// AUTH
import { sub_children } from "../../API/Global/Payload";

// Import Page Components
import ParamsPage from "../../Pages/Configuration/Params/Params";
import AccountingParams from "../../Pages/Parameters/Accounting/AccountingParams";
import BackOfficeParams from "../../Pages/Parameters/BackOffice/BackOffice";
import FrontOfficeParams from "../../Pages/Parameters/FrontOffice/FrontOfficeParams";
import ReportParamsRoutes from "./Reports/Routes";
import HouseKeepParams from "../../Pages/Parameters/HouseKeeping/HouseKeepParams";
import OutletParams from "../../Pages/Parameters/Outlet/OutletParams";
import ShifParams from "../../Pages/Parameters/Shift/Shift";
import NotFound from "../../Pages/NotFound/NotFound";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";
import DashboardParams from "../../Pages/Parameters/Dashboard/DashboardParams";

import MasterRoutes from "../Master/Routes";
import ReportParams from "../../Pages/Parameters/Report/MainPage/ReportParams";
import StatisticReportPage from "../../Pages/Parameters/Report/Children/Statistic";
import SegmentParamsPage from "../../Pages/Parameters/Report/Children/SegmentParam";
import HeaderParamsPage from "../../Pages/Parameters/Report/Children/HeaderDsr";
import DetailParamsPage from "../../Pages/Parameters/Report/Children/DetailDsr";

// List Route
const subRoute = [
  {
    path: "accounting-params",
    name: "accounting-params",
    id_modules: 87,
    element: <AccountingParams />,
  },
  {
    path: "back-office-params",
    name: "back-office-params",
    id_modules: 88,
    element: <BackOfficeParams />,
  },
  {
    path: "dashboard-params",
    name: "dashboard-params",
    id_modules: 88,
    element: <DashboardParams />,
  },
  {
    path: "front-office-params",
    name: "front-office-params",
    id_modules: 89,
    element: <FrontOfficeParams />,
  },
  {
    path: "house-keeping-params",
    name: "house-keeping-params",
    id_modules: 90,
    element: <HouseKeepParams />,
  },
  {
    path: "outlet-params",
    name: "outlet-params",
    id_modules: 91,
    element: <OutletParams />,
  },
  {
    path: "general-report-params/*",
    name: "general-report-params",
    id_modules: 92,
    element: <ReportParamsRoutes />,
  },
  {
    path: "working-hours-params",
    name: "shift-params",
    id_modules: 93,
    element: <ShifParams />,
  },
];

// CODE
export default function ParamsRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Config: ", sub_module);
    const _mods = sub_children?.length > 0 ? sub_children : []; // values.module;

    const proRoute = subRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route path={value.path} element={value.element} key={value.name} />
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes BO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<ParamsPage />} />
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}
      </Routes>
      <Outlet />
    </>
  );
}

// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// BASE API
import { baseurl } from "../../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Radio,
  Space,
  Tooltip,
  Typography,
  notification,
  Checkbox,
  DatePicker,
  Tabs,
} from "antd";

// Import React Icons
import { FiEdit, FiShoppingCart } from "react-icons/fi";
import {
  DeleteFilled,
  ArrowRightOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

// Import Notifications
import {
  failedCreateJournal,
  incomplete,
  failedFetch,
  successCreateJournal,
  successSI,
  failedSI,
} from "../../../Reusable/Notification/Notification";

// Import Page Components
import ReceivingDetailModalTable from "../../../Table/BackOffice/Inventory/Receiving/ReceivingDetailModal/Table";
import PurchaseOrderList from "../../../Reusable/Inventory/Receiving/PurchaseOrderList";
import PODetailModalTable from "../../../Table/BackOffice/Inventory/Receiving/ReceivingPODetailModal/Table";
// "../../../Table/BackOffice/Inventory/Receiving/ReceivingPODetailModal/Table";
import ModalReceivingPermission from "./ModalPermission";
import ArticleListModalTable from "../../../Table/BackOffice/Inventory/Receiving/ArticleListModal/Table";
// "../../../Table/BackOffice/Inventory/Receiving/ArticleListModal/Table";

// Import Master Components
import MasterSupplier from "../../../Reusable/Master/Supplier/MasterSupplier";
import WarehouseList from "../../../Reusable/Inventory/Receiving/WarehouseList";
import ParametersBackOffice from "../../../Reusable/Parameters/ParamsBO";
import MasterCurrency from "../../../Reusable/Master/Currency/Currency";

// Import Global Styles
import { styleActive, styleInactive } from "../../../../Style/Global/Global";
import MasterDepartment from "../../../Reusable/Master/Department/Department";

// MODAL CONFIRM
const { confirm } = Modal;
const { open } = notification;

const { TextArea } = Input;

const { Title } = Typography;

// CODE
export default function ModalReceivingDraft(props) {
  // CONTEXT
  const { getMasterSupplierArticleList } = useContext(MasterContext);

  // Properties
  const { openModal, closeModal, is_refresh, selectedData } = props;

  // Params Back Office
  const params = ParametersBackOffice().params;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [keyCurrent, setKeyCurrent] = useState(1);
  const [dataParams, setDataParams] = useState([]);
  // Journal Table

  // Boolean
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tabKey, setTabKey] = useState("2");

  // FORM
  const [form] = Form.useForm();

  // Modal State
  const [editData, setEditData] = useState(null);
  const [edit, setEdit] = useState(false);

  const [dataPODetail, setDataPODetail] = useState([]);
  const [dataPODetailFirst, setDataPODetailFirst] = useState([]);
  const [dataReceiveDetail, setDataReceiveDetail] = useState([]);

  const [dataSelectedPO, setDataSelectedPO] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [selectedSupplierData, setSelectedSupplierData] = useState([]);

  const [orderTypeValue, setOrderTypeValue] = useState(1);
  const [dmlTypeValue, setDmlTypeValue] = useState(false);
  const [expenseTypeValue, setExpenseTypeValue] = useState(false);
  const [expenditureTypeValue, setExpenditureTypeValue] = useState(1);
  const [departmentId, setDepartmentId] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalPermissionOpen, setModalPermissionOpen] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [authBy, setAuthBy] = useState(null);

  const [nextState, setNextState] = useState(true);

  // COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <Row className="action-table-btn" justify="center">
            <Col span={12} className="col-del-btn">
              <Tooltip title="Delete Data" className="del-tooltip">
                <DeleteFilled
                  className="delete-btn"
                  onClick={() => {
                    console.log("ABOUT TO DELETE > ", record);

                    showModalDelete(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#F64E60",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Article Id",
      dataIndex: "account_code",
      key: "account_code",
    },
    {
      title: "Article",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Order Qty",
      dataIndex: "credit",
      key: "credit",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Unit Price",
      dataIndex: "debit",
      key: "debit",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Order Amount",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Receipt Qty",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Receipt Amount",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Total Amount",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Deliv D Unit",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Last Arrival",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Delivery Note",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Notes",
    //   dataIndex: "notes",
    //   key: "notes",
    // },
  ];

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      let e = 1;
      switch (true) {
        case selectedData?.si_status == 1 || selectedData?.si_status == 3:
          e = 1;
          break;
        case selectedData?.si_status == 2 || selectedData?.si_status == 4:
          e = 2;
          break;
        case selectedData?.si_status == 5 || selectedData?.si_status == 6:
          e = 3;
          break;

        default:
          e = 1;
          break;
      }
      // setDataReceiveDetail(selectedData?.details);

      let arr = selectedData?.details.map((e) => {
        let z = e.si_quantity_remaining;
        // (e.quantity_received - e.si_quantity_remaining) -
        // // (e.quantity - e.remaining_quantity) -
        // (dataReceiveDetail.length > 0
        //   ? dataReceiveDetail
        //       .filter((x) => {
        //         if (x.id_article === e.id_article) {
        //           return x;
        //         }
        //       })
        //       .reduce(
        //         (accumulator, i) => accumulator + i.quantity_received,
        //         0
        //       )
        //   : 0);
        return { ...e, remaining_quantity_dummy: z };
      });
      console.log(arr);
      setDataReceiveDetail(arr);

      setOrderTypeValue(selectedData?.order_type == true ? 1 : 2);
      setExpenseTypeValue(
        selectedData?.si_status == 1 ||
          selectedData?.si_status == 2 ||
          selectedData?.si_status == 5
          ? false
          : true
      );
      setDmlTypeValue(e == 3 ? true : false);

      form.setFieldsValue({
        order_type: e,
        is_direct:
          selectedData?.si_status == 1 ||
          selectedData?.si_status == 2 ||
          selectedData?.si_status == 5
            ? false
            : true,
        po_number: selectedData?.po_number,
        invoice: selectedData?.invoice,
        arrival_date: selectedData?.delivery_date,
        warehouse_name: selectedData?.warehouse_name,
        // supplier_name: selectedData?.supplier_name,
        supplier_name: selectedData?.supplier_name,
        id_warehouse: selectedData?.details[0].id_warehouse,
        created_by: selectedData?.created_by,
        created_date: selectedData?.created_date,
        updated_by: selectedData?.updated_by,
        updated_date: selectedData?.updated_date,
        is_opex: selectedData?.approval_flow,
        id_department: selectedData?.id_department,
      });
      if (selectedData?.order_type == false) {
        fetchData(selectedData?.id_supplier, selectedData?.id_department);
      }
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    if (
      openModal == true &&
      dataPODetail.length > 0 &&
      dataReceiveDetail.length > 0
    ) {
      console.log("DATA PO DETAIL USE EFFECT >>>", dataPODetail);
      console.log("DATA RECEIVE DETAIL USE EFFECT >>>", dataReceiveDetail);

      let arr = dataPODetail.map((e) => {
        // Need fixed data of remaining quantity based on last fetch, so we make dummy for the realtime calculation
        e["remaining_quantity_dummy"] = e.si_quantity_remaining;
        if (
          dataReceiveDetail.find((x) =>
            orderTypeValue != 2 ? x.id_article == e.id_article : x.id == e.id
          )
        ) {
          let remainingFromPO =
            dataReceiveDetail[
              dataReceiveDetail.findIndex((x) =>
                orderTypeValue != 2
                  ? x.id_article == e.id_article
                  : x.id == e.id
              )
            ].quantity;
          console.log("Remaining From PO >>>", remainingFromPO);
          // get remaining quantity of article from another existing PO
          let remainingFromOther = e.quantity_order - e.si_quantity_remaining;
          console.log("Remaining From Other >>>", remainingFromOther);
          // set dummy from realtime calculation
          // z = (e.quantity - e.remaining_quantity) - remainingFromOther
          let z =
            e.quantity_order -
            remainingFromOther -
            (isNaN(remainingFromPO) ? 0 : remainingFromPO);
          console.log("Z >>>", z);
          e["quantity"] = z;
          e["remaining_quantity_dummy"] = z;
        }
        return e;
      });

      console.log("DATA PR DETAIL USE EFFECT >>>", arr);
      setDataPODetail(arr);
    }
  }, [dataReceiveDetail]);

  // Fetch Data
  const fetchData = async (e, f) => {
    await getMasterSupplierArticleList({
      supplier: "supplier-article-list",
      //   id_supplier: e["id_supplier"],
      id_supplier: e,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Article List Data => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            // if (filt.journal_type === 4) {
            return (
              !filt.is_deleted &&
              filt.article_type == 2 &&
              filt.department_id == f
            );
            // }
          });

          filtered.forEach((e) => {
            e.quantity_delivery = 0;
          });

          setDataPODetail(filtered);
          setDataPODetailFirst(filtered);
        } else {
          setDataPODetail([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  useEffect(() => {
    let cleanUp = false;

    if (params && !cleanUp) {
      //   console.log("Params: ", params);
      setDataParams(params);
    }

    return () => {
      cleanUp = true;
    };
  }, [params]);

  useEffect(() => {
    let cleanUp = false;

    if (authToken && authBy && !cleanUp) {
      onFinish(2);
    }

    return () => {
      cleanUp = true;
    };
  }, [authToken]);

  // Handle Close
  const handleClose = () => {
    setData([]);
    setDataPODetail([]);
    setDataReceiveDetail([]);
    setDataSelectedPO([]);
    setSelectedSupplierData([]);
    setDataPODetailFirst([]);
    setOrderTypeValue(true);
    setExpenseTypeValue(false);
    setNextState(true);
    setTabKey("2");
    setDmlTypeValue(false);
    setAuthToken(null);
    setAuthBy(null);

    closeModal(false);
    // is_edit(false);
    // setIsDelete(true);
    setEdit(false);
    setModalOpen(false);

    setIsLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (val) => {
    const _finish = val;
    // setIsLoading(true);

    console.log("ON FINISH: ", _finish);
    showModalConfirm(_finish);
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Finish Failed: ", val.values);
    incomplete(error);
  };

  // Handle Finish
  const handleFinish = (value) => {
    //  let _debt = 0;
    //  let _credit = 0;
    //
    console.log("SUMM: ", data);
    console.log("ON FINISH VALUE: ", value);

    //  if (data.length > 0) {
    //    let _summaries = data.forEach(({ credit, debit }) => {
    //      // console.log("SUMM: ", credit);
    //      _debt += debit;
    //      _credit += credit;
    //    });
    //  } else {
    //    _debt = 0;
    //    _credit = 0;
    //  }
    //
    //  console.log("DEBT: ", _debt);
    //  console.log("CREDIT: ", _credit);
    //
    //  if (_debt - _credit !== 0) {
    //    journalIsNotBalance(1);
    //  } else if (data.length === 0) {
    //    journalIsNotBalance(2);
    //  } else {
    //    showModalConfirm();
    //  }
  };

  // HANDLE SUPPLIER
  const getSupplier = (val, allKey) => {
    console.log(val);
    console.log(allKey);

    form.setFieldsValue({
      //   id_supplier: val,
      id_supplier: "10899a43-f9cf-4ada-a0be-52fc626a12c8",
      supplier_name: allKey?.label,
      telefax: allKey?.telefax,
    });
  };

  // HANDLE PURCHASE ORDER (PO)
  const getPurchaseOrder = (value, allKey) => {
    console.log(value);
    console.log(allKey);

    form.setFieldsValue({
      id_po: allKey.value,
      delivery_date: allKey.delivery_date,
      telefax: allKey.telefax,
      remarks: allKey.remarks,
      description: allKey.description,
      id_currency: allKey.id_currency,
      id_department: allKey.id_department,
    });

    let x = allKey.details.map((e) => {
      e["key"] = e.id_article;
      e["request_date"] = allKey.request_date;
      e["needed_date"] = allKey.needed_date;
      e["quantity_delivery"] = null;
      e["remaining_quantity_dummy"] = e.si_quantity_remaining;
      // e["remaining_quantity_dummy"] =
      //   e.si_quantity_remaining +
      //   (dataReceiveDetail.length > 0
      //     ? dataReceiveDetail
      //         .filter((x) => {
      //           if (x.id_article === e.id_article) {
      //             return x;
      //           }
      //         })
      //         .reduce((accumulator, i) => accumulator + i.quantity_received, 0)
      //     : 0);
      return e;
    });
    // .filter((x) => {
    //   if (x.remaining_quantity != 0) {
    //     return x;
    //   }
    // })
    console.log(x);
    setDataPODetail(x);
    setDataPODetailFirst(x);
    // form.setFieldsValue({
    //   department: val,
    //   id_department: code,
    // });
  };

  // Get Financial State
  const getPONumber = (value) => {
    const _val = value;
    console.log("PONumber >>> ", value);

    form.setFieldsValue({
      po_number: _val?.value,
      id_po: _val?.id,
      delivery_date: moment(_val?.dev_date),
    });
  };

  const getWarehouse = (value, allKey) => {
    console.log(value);
    console.log(allKey);

    form.setFieldsValue({
      // warehouse_name: allKey.warehouse_name,
      id_warehouse: allKey.id_warehouse,
    });
  };

  // HANDLE DEPARTMENT
  const getDepartment = (value) => {
    console.log("DEPARTMENT >> ", value);
    setDepartmentId(value?.dept_id);

    form.setFieldsValue({
      id_department: value?.dept_id,
    });
  };

  // Handle Add
  const handleAdd = async () => {
    let arr = [];
    await form
      .validateFields()
      .then((values) => {
        console.log("Validates: ", values);

        let _validators = form.getFieldsValue(true);
        console.log(_validators);
        // _validators["delivery_date"] = moment(
        //   _validators["delivery_date"]
        // ).format("YYYY-MM-DD");
        // _validators["paydate"] = moment(_validators["paydate"]).format(
        //   "YYYY-MM-DD"
        // );

        // let arr = Object.values(
        //   [...dataSelectedPO, ...dataReceiveDetail].reduce(
        //     (acc, { id_article, quantity_delivery }) => {
        //       acc[id_article] = {
        //         ...acc,
        //         id_article,
        //         quantity_delivery:
        //           (acc[id_article] ? acc[id_article].quantity_delivery : 0) +
        //           quantity_delivery,
        //       };
        //       return acc;
        //     },
        //     {}
        //   )
        // );

        let arr = dataReceiveDetail;
        console.log("DATA SELECTED PO >>>", dataSelectedPO);
        console.log("DATA SELECTED RECEIVE >>>", dataReceiveDetail);

        if (dataReceiveDetail.length > 0) {
          console.log("RCV > 0");
          dataSelectedPO.forEach((e, idx) => {
            if (dataReceiveDetail.some((x) => x.id_article == e.id_article)) {
              console.log("i.id_article == e.id_article");
              idx = dataReceiveDetail.findIndex(
                (val) => val.id_article == e.id_article
              );
              arr[idx].quantity_received = arr[idx].quantity_received +=
                e.quantity_received;
              arr[idx].quantity = arr[idx].quantity_received;
              // if (e.quantity_received > 0) {
              //   (arr[idx].quantity_update = true),
              //     (arr[idx].quantity_difference = e.quantity);
              // }
            } else {
              console.log("i.id_article != e.id_article");
              arr.push({ ...e, ..._validators, quantity: e.quantity_received });
            }
          });
        } else {
          console.log("RCV == 0");
          for (let e = 0; e < dataSelectedPO.length; e++) {
            arr.push({ ...dataSelectedPO[e], ..._validators });
          }
        }

        console.log("HASIL CALCULATION >>>", arr);
        setDataReceiveDetail([...arr]);
        setDataSelectedPO([]);
        setDataPODetail(dataPODetailFirst);

        // let index = dataReceiveDetail.findIndex(
        //   (x) => x.id_article === dataSelectedPO["id_article"]
        // );
        // console.log("GET INDEX >>>", index);
        // if (index == -1) {
        //   console.log("NOT FOUND, ADD NEW >>>>");
        //   // arr.push(_validators);
        //   setDataReceiveDetail([...dataReceiveDetail, ...dataSelectedPO]);
        // } else {
        //   console.log("FOUND, EDIT >>>>");
        //   setDataReceiveDetail(
        //     dataReceiveDetail.map((e, idx) => {
        //       if (idx === index) {
        //         return {
        //           ...e,
        //           delivery_quantity:
        //             e.delivery_quantity + dataSelectedPO["delivery_quantity"],
        //         };
        //       }
        //       return e;
        //     })
        //   );
        // }
        // console.log("Arr Next: ", arrNext);
        // let arrFinal = arrNext.map((v, i) => ({ ...v, id: i }));
        // setDataPODetail(arrFinal);
      })
      .catch((errorInfo) => {
        console.log("Validator: ", errorInfo);

        incomplete(errorInfo);
      });

    console.log(dataReceiveDetail);
    setTabKey("2");
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const _data = value;

    confirm({
      className: "modals-confirm",
      title: `Are you sure want to Create a Good Receive List ?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleDataAssignPostReceiveList(_data);
      },

      onCancel() {
        localStorage.clear();
        setAuthToken(null);
        setAuthBy(null);
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const handleDataAssignPostReceiveList = (val) => {
    let arr_x = [];
    let arr_y = [];
    let siStatus = 1;
    switch (true) {
      case orderTypeValue == true && expenseTypeValue == false:
        siStatus = 1;
        break;
      case orderTypeValue == false && expenseTypeValue == false:
        siStatus = 2;
        break;
      case orderTypeValue == true && expenseTypeValue == true:
        siStatus = 3;
        break;
      case orderTypeValue == false && expenseTypeValue == true:
        siStatus = 4;
        break;

      default:
        siStatus = 1;
        break;
    }
    console.log("DATA RECEIVE >>>", dataReceiveDetail);
    for (let i = 0; i < dataReceiveDetail.length; i++) {
      if ("id_sid" in dataReceiveDetail[i]) {
        if ("price_update" in dataReceiveDetail[i]) {
          arr_x.push({
            id_sid: dataReceiveDetail[i].id_sid,
            quantity_received: dataReceiveDetail[i].quantity_received,
            price: dataReceiveDetail[i].price,
            price_update: dataReceiveDetail[i].price_update,
          });
        } else {
          arr_x.push({
            id_sid: dataReceiveDetail[i].id_sid,
            quantity_received: dataReceiveDetail[i].quantity_received,
            price: dataReceiveDetail[i].price,
          });
        }
      } else {
        arr_y.push({
          id_article: dataReceiveDetail[i]?.id_article,
          id_pod: dataReceiveDetail[i]?.id_po_detail,
          quantity_received: dataReceiveDetail[i].quantity_received,
          quantity_broken: 0,
          received_date: moment(),
          received_by: authBy ? authBy : user_name,
          created_by: authBy ? authBy : user_name,
          ...(expenseTypeValue
            ? { id_warehouse: dataReceiveDetail[i].id_warehouse }
            : { id_warehouse: dataReceiveDetail[0].id_warehouse }),
          delivery_note: "eas",
          price: dataReceiveDetail[i].price,
          ...(expenseTypeValue
            ? // ? { id_department: dataReceiveDetail[0]?.id_department }
              { id_department: 0 }
            : { id_department: 0 }),
          price_update:
            "price_update" in dataReceiveDetail[i]
              ? dataReceiveDetail[i].price_update
              : false,
        });
      }
    }
    let obj = {
      is_draft: val != 1 ? true : false,
      id_stock_incoming: selectedData?.id_stock_incoming,
      updated_by: authBy ? authBy : user_name,
      update: arr_x,
      add: arr_y,
    };
    let arr = [];
    arr.push(obj);

    console.log(arr);
    handleSubmit(arr[0]);
  };

  // MODAL CONFIRM DELETE
  const showModalDelete = (value) => {
    const _content = value;

    confirm({
      className: "modal-confrim",
      title: `Are you sure you wanted to delete Journal with named ${
        _content?.account_name.toUpperCase() || " --- "
      }?`,
      closable: true,
      centered: true,
      okText: "Confirm",
      width: 800,

      onCancel() {
        setIsLoading(false);
      },

      onOk() {
        handleDelete(_content);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },
      cancelButtonProps: {
        className: "refresh-btn",
        type: "default",
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _submit = value;
    let _array = [];

    console.log("HANDLE SUBMIT: ", _submit);

    //for (let i = 0; i < data?.length; i++) {
    //  _array.push({
    //    id_po: data[i]?.id_po,
    //    order_type: data[i]?.order_type,
    //    id_currency: data[i]?.id_currency,
    //    telefax: data[i]?.telefax,
    //    delivery_date: data[i]?.delivery_date,
    //    description: data[i]?.description,
    //    created_by: user_name,
    //  });
    //}

    // _submit.created_by = user_name;
    // _submit.delivery_date = moment(_submit?.delivery_date).format("YYYY-MM-DD");

    console.log("Array: ", _submit);

    await axios
      .put(`${baseurl}/inventory/receiving`, _submit, {
        headers: {
          Authorization: `Bearer ${authToken ? authToken : token || ""}`,
        },
      })
      .then((response) => {
        console.log("Receiving PUT Response: ", response);

        successSI(response, { method: value.is_draft != true ? 0 : 1 });
        localStorage.clear();
        is_refresh(true);
        handleClose();
      })
      .catch((error) => {
        console.log("Receiving FAILED Response: ", error);

        failedSI(error, { method: value.is_draft != true ? 0 : 1 });
        setIsLoading(false);
      });
  };

  // HANDLE DELETE
  const handleDelete = (value) => {
    const _submit = value;

    let _filtered = data.filter((value) => {
      return value.id !== _submit.id;
    });

    console.log("AFTER DELETE: ", _filtered);
    setData(_filtered);
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <Button
            style={
              tabKey == "1"
                ? {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 120,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {orderTypeValue == true ? " PO Article List" : " Article List"}
          </Button>
        </>
      ),
      children: (
        <>
          <Row
            style={{
              justifyContent: "space-between",
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Col span={12}>
              <Title
                level={5}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "more",
                }}
              >
                Select Article From
                {orderTypeValue == true
                  ? " Purchase Order (PO)"
                  : " Article List"}
              </Title>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  icon={<ShoppingCartOutlined />}
                  disabled={dataSelectedPO.length == 0 ? true : false}
                  onClick={handleAdd}
                >{`Add to Stock Incoming List`}</Button>
              </Row>
            </Col>
          </Row>
          {orderTypeValue == true ? (
            <PODetailModalTable
              dataDetail={dataPODetail}
              getSelectedPODetail={setDataSelectedPO}
              dataEdit={setDataPODetail}
              isEdit={() => {}}
            />
          ) : (
            <ArticleListModalTable
              dataArticleList={dataPODetail}
              getSelectedArticleList={setDataSelectedPO}
              dataEdit={setDataPODetail}
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <Button
            style={
              tabKey == "2"
                ? {
                    // height: 35,
                    width: 150,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#FFFFFF",
                    backgroundColor: "#1890ff",
                    borderColor: "#1890ff",
                  }
                : {
                    // height: 35,
                    width: 150,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 0,
                    color: "#1890ff",
                    backgroundColor: "#E9EBF2",
                    borderColor: "#E9EBF2",
                  }
            }
          >
            {"Stock Incoming List"}
          </Button>
        </>
      ),
      children: (
        <>
          <Divider
            className="divider-form"
            orientation="left"
            orientationMargin={0}
            style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
          >
            {`Article Detail Stock Incoming List`}
          </Divider>

          <ReceivingDetailModalTable
            dataDetail={dataReceiveDetail}
            getAfterDelete={setDataReceiveDetail}
            dataEdit={setDataReceiveDetail}
            is_edit={() => {}}
          />

          {/* <Divider
            className="divider-form"
            style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
          /> */}

          <Row justify="end" align="middle" className="modal-btn-form">
            <Button
              className="next-btn"
              type="primary"
              // htmlType="submit"
              disabled={dataReceiveDetail.length == 0 ? true : false}
              loading={isLoading}
              onClick={() => {
                onFinish(2);
              }}
              style={{
                marginRight: 15,
              }}
            >
              {`Save As Draft`}
            </Button>

            <Button
              className="submit-btn"
              type="primary"
              // htmlType="submit"
              disabled={dataReceiveDetail.length == 0 ? true : false}
              loading={isLoading}
              onClick={() => {
                onFinish(1);
              }}
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </>
      ),
    },
  ];

  console.log(dataPODetail);

  return (
    <>
      <Modal
        className="receiving-modal"
        key="receiving-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Stock Incoming Draft`}</Typography>
            </Row>
          </>
        }
        centered
        confirmLoading={isLoading}
        footer={null}
        open={modalOpen}
        closable
        destroyOnClose={true}
        onCancel={handleClose}
        width={1000}
      >
        <Form
          className="receiving-form"
          key="receiving-form"
          name="receiving-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          //   initialValues={{
          //     order_type: true,
          //     is_direct: false,
          //     arrival_date: moment().format("YYYY-MM-DD"),
          //     id_warehouse: params.id_warehouse,
          //   }}
          style={{ padding: "0px 36px" }}
        >
          <Row className="form-row" justify="center" align="top" gutter={[50]}>
            <Col className="form-col" span={24}>
              <Row className="pay-row" gutter={30}>
                <Col span={4} className="pay-col">
                  <Form.Item
                    label="Order Type"
                    name="order_type"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Order Type!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        if (e.target.value != 3) {
                          setOrderTypeValue(e.target.value);
                          setDmlTypeValue(false);
                          if (e.target.value == 2) {
                            form.resetFields(["id_po"]);
                          }
                        } else {
                          setOrderTypeValue(e.target.value);
                          setDmlTypeValue(true);
                        }
                      }}
                    >
                      <Space direction="vertical">
                        <Radio disabled={nextState ? true : false} value={1}>
                          With PO
                        </Radio>
                        <Radio disabled={nextState ? true : false} value={2}>
                          Without PO
                        </Radio>
                        <Radio disabled={nextState ? true : false} value={3}>
                          Daily Market List (DML)
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4} className="pay-col">
                  <Form.Item
                    label="Expense Type"
                    name="is_direct"
                    rules={[
                      {
                        required: true,
                        message: "Please, select a Expense Type!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setExpenseTypeValue(e.target.value);
                        if (e.target.value == true) {
                          form.resetFields(["id_warehouse"]);
                        } else {
                          form.setFieldsValue({
                            id_warehouse: params.id_warehouse,
                          });
                        }
                      }}
                      value={false}
                    >
                      <Space direction="vertical">
                        <Radio
                          disabled={nextState ? true : false}
                          value={false}
                        >
                          Non Direct Issue
                        </Radio>
                        <Radio disabled={nextState ? true : false} value={true}>
                          Direct Issue
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={16} className="pay-col">
                  <Form.Item
                    label="Purchase Order (PO)"
                    name="id_po"
                    rules={[
                      {
                        required: orderTypeValue == true ? true : false,
                        message: "Please, select a Purchase Request!",
                      },
                    ]}
                  >
                    <PurchaseOrderList
                      getPONumber={getPurchaseOrder}
                      po={
                        form.getFieldsValue([
                          "po_number",
                          "arrival_date",
                          "supplier_name",
                        ]) ?? ""
                      }
                      orderType={nextState ? 2 : orderTypeValue}
                      is_dml={dmlTypeValue}
                      next={nextState}
                    />
                  </Form.Item>
                  <Form.Item
                    label="PO Delivery Date"
                    name="delivery_date"
                    hidden
                  ></Form.Item>
                  <Form.Item
                    label="Supplier Telefax"
                    name="telefax"
                    hidden
                  ></Form.Item>
                  <Form.Item
                    label="PO Currency"
                    name="id_currency"
                    hidden
                  ></Form.Item>
                  <Form.Item
                    label="PO ID Department"
                    name="id_department"
                    hidden
                  ></Form.Item>
                  <Form.Item
                    label="PO Remarks"
                    name="remarks"
                    hidden
                  ></Form.Item>
                  <Form.Item
                    label="PO Description"
                    name="description"
                    hidden
                  ></Form.Item>
                </Col>
              </Row>

              <Row className="pay-row" gutter={30}>
                <Col span={8}>
                  <Form.Item
                    label="Invoice Number"
                    name="invoice"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, input the No. Invoice!",
                    //   },
                    // ]}
                  >
                    <Input
                      disabled={nextState ? true : false}
                      placeholder="Input Invoice No."
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="To Store / Warehouse"
                    name="id_warehouse"
                    // rules={[
                    //   {
                    //     required: orderTypeValue == true ? true : false,
                    //     message: "Please, Input Start Contract Date term!",
                    //   },
                    // ]}
                  >
                    <WarehouseList
                      getWarehouseId={getWarehouse}
                      wh={
                        expenseTypeValue == true
                          ? form.getFieldsValue(["id_warehouse"])
                              .id_warehouse ?? ""
                          : params.id_warehouse
                      }
                      expenseType={nextState ? true : !expenseTypeValue}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Arrival Date"
                    name="arrival_date"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please, input the No. Invoice!",
                    //   },
                    // ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="info-row" gutter={30} justify="start">
                {orderTypeValue == 2 ? (
                  <>
                    <Col span={8}>
                      <Form.Item
                        label="Supplier Name"
                        name="supplier_name"
                        rules={[
                          {
                            required: true,
                            message: "Please, select the Supplier Name!",
                          },
                        ]}
                      >
                        <MasterSupplier
                          getSupplier={getSupplier}
                          supplier={
                            form.getFieldsValue(["supplier_name"]) ?? ""
                          }
                          nextState={nextState}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Supplier ID"
                        name="id_supplier"
                        hidden
                      ></Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Department"
                        name="id_department"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please, select the Department Destination!",
                          },
                        ]}
                      >
                        <MasterDepartment
                          getDepartment={getDepartment}
                          department_id={
                            form.getFieldsValue(["id_department"])
                              .id_department ?? 0
                          }
                          disabled={nextState}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} className="pay-col">
                      <Form.Item
                        label="Expenditure Type"
                        name="is_opex"
                        rules={[
                          {
                            required: true,
                            message: "Please, select a Expenditure Type!",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={(e) => {
                            setExpenditureTypeValue(e.target.value);
                          }}
                          value={1}
                        >
                          <Space direction="horizontal">
                            <Radio
                              disabled={nextState ? true : false}
                              value={1}
                            >
                              OPEX
                            </Radio>
                            <Radio
                              disabled={nextState ? true : false}
                              value={2}
                            >
                              CAPEX
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <></>
                )}

                <Col span={24}>
                  <Row
                    className="article-row"
                    justify="end"
                    style={{ marginTop: 30 }}
                  >
                    <Button
                      disabled={nextState ? true : false}
                      className="next-btn"
                      type="primary"
                      icon={<ArrowRightOutlined />}
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            console.log("Validates: ", values);
                            if (orderTypeValue == 2) {
                              fetchData(form.getFieldsValue(["id_supplier"]));
                            }
                            setNextState(true);
                          })
                          .catch((errorInfo) => {
                            console.log("Validator: ", errorInfo);

                            incomplete(errorInfo);
                          });
                      }}
                    >{`Next`}</Button>
                  </Row>
                </Col>
              </Row>
              {/* <Divider
                className="divider-form"
                // orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              ></Divider> */}

              {nextState == true ? (
                <Tabs
                  defaultActiveKey="1"
                  activeKey={tabKey}
                  items={items}
                  onChange={(e) => {
                    setTabKey(e);
                  }}
                  type="card"
                />
              ) : (
                <></>
              )}

              <Divider type="horizontal" style={{ borderColor: "#d9d9d9" }} />

              <Row className="info-row" gutter={30} justify="start">
                <Col span={8}>
                  <Form.Item label="Created By" name="created_by">
                    <Input disabled={true} placeholder="Created By" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Created Date" name="created_date">
                    <Input disabled={true} placeholder="Created Date" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Updated By" name="updated_by">
                    <Input disabled={true} placeholder="Updated By" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="info-row" gutter={30} justify="start">
                <Col span={8}>
                  <Form.Item label="Updated Date" name="updated_date">
                    <Input disabled={true} placeholder="Created Date" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* <Col span={2}>
              <Divider
                type="vertical"
                style={{ marginLeft: 30, height: 750, borderColor: "#d9d9d9" }}
              />
            </Col>
            <Col className="form-col" span={11}>
              <Divider
                className="divider-form"
                orientation="left"
                orientationMargin={0}
                style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
              >
                {`Article Detail Receiving List`}
              </Divider>

              <ReceivingDetailModalTable
                dataDetail={dataReceiveDetail}
                getAfterDelete={setDataReceiveDetail}
                dataEdit={setDataReceiveDetail}
              />

              <Divider
                className="divider-form"
                style={{ margin: "15px 0px 10px", background: "#EBEDF3" }}
              />

              <Row justify="end" align="middle" className="modal-btn-form">
                <Button
                  className="next-btn"
                  type="primary"
                  // htmlType="submit"
                  disabled={dataPODetail.length == 0 ? true : false}
                  loading={isLoading}
                  onClick={() => {
                    onFinish(2);
                  }}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Save As Draft`}
                </Button>

                <Button
                  className="submit-btn"
                  type="primary"
                  // htmlType="submit"
                  disabled={dataPODetail.length == 0 ? true : false}
                  loading={isLoading}
                  onClick={() => {
                    onFinish(1);
                  }}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Submit`}
                </Button>

                <Button
                  className="cancel-btn"
                  type="default"
                  onClick={handleClose}
                >
                  {`Cancel`}
                </Button>
              </Row>
            </Col> */}
          </Row>
        </Form>
      </Modal>
      <ModalReceivingPermission
        openModal={modalPermissionOpen}
        closeModal={(e) => {
          setModalPermissionOpen(e);
        }}
        is_authorized={(e, x) => {
          console.log("AUTH TOKEN >>>", e, x);
          setAuthToken(e);
          setAuthBy(x);
        }}
      />
    </>
  );
}

// Import React's Components
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token } from "../../../API/Global/Payload";

// Import's Auth Components
import * as auth from "../../../API/AuthActions/authAction";

// Import Ant Design Components
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  List,
  Modal,
  Radio,
  Result,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tree,
  TreeSelect,
  Typography,
} from "antd";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Import Page Components
import InputSkeleton from "../../Reusable/Skeleton/InputSkeleton";
import ResultError from "../../Reusable/Result/Error/Result";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../Style/Global/Global";
import { SyncOutlined } from "@ant-design/icons";
import moment from "moment";

// Modals
const { confirm } = Modal;

// CODE
export default function ModuleTabs(props) {
  // PROPS
  const {
    subModule,
    modules,
    grandmodules,
    getAllModule,
    is_disabled,
    is_render,
  } = props;

  // STATE MANAGEMENT
  // Data
  const [dataItems, setDataItems] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [bulks, setBulks] = useState([]);
  const [formValues, setFormValues] = useState(null);
  // Check All
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  // Loading
  const [loading, setLoading] = useState(true);

  // FORM
  const [form] = Form.useForm();

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);
    setDataItems([]);
    setFormValues([]);

    await axios
      .get(`${baseurl}/auth/admin/module`, {
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Res: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleFilterModule(_res);
          setBulks(_res);
        } else {
          setDataItems([]);
          setBulks([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
      });
  };

  // USE EFFECTs
  useEffect(() => {
    let cleanUp = false;

    // setLoading(true);
    // console.log("TABS is render: ", is_render);
    // console.log("Module: ", subModule, grandmodules, modules);

    if (
      is_render == true &&
      (subModule.length > 0 || grandmodules.length > 0 || modules.length > 0)
    ) {
      fetchData();
    } else {
      setDataItems([]);
      setFormValues([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [is_render, subModule, grandmodules, modules]);

  useEffect(() => {
    let cleanUp = false;

    if (dataItems?.length > 0 && formValues?.length > 0 && !cleanUp) {
      // console.log("Form Fields Value: ", formValues);

      // console.log("Time: ", moment().format("HH:mm:ss. SSS, DD-MM-YYYY"));

      setTimeout(() => {
        getAllModule(formValues);
        // setLoading(false);
      }, 1000);
    } else {
    }

    return () => {
      cleanUp = true;
    };
  }, [dataItems, formValues]);

  // Handle Change
  const handleRefresh = (value) => {
    if (value === true) {
      fetchData();
    }
  };

  // ON Change
  const onChange = (val) => {
    const _value = val;

    // console.log("On Change Key: ", _value);
    setActiveKey(_value);
  };

  // Handle Filter Module
  const handleFilterModule = (val) => {
    let _arr = val;
    const _main_metadata = modules?.length > 0 ? modules : [];
    const _sub_metadata = subModule?.length > 0 ? subModule : [];

    let _arr_main = [];
    let _arr_sub = [];

    let _filterMain = _arr.filter((record) => {
      if (_main_metadata.includes(record?.id)) {
        return _arr_main.push({
          label: record?.name,
          title: record?.name,
          value: record?.id,
          code: record?.code,
          id_module: record?.id_module,
          key: record?.id.toString(),
          id: record?.id,
          children: record?.children,
          is_check: true,
          disabled: record?.is_deleted,
        });
      } else {
        return _arr_main.push({
          label: record?.name,
          title: record?.name,
          value: record?.id,
          code: record?.code,
          id_module: record?.id_module,
          key: record?.id.toString(),
          id: record?.id,
          children: record?.children,
          is_check: false,
          disabled: record?.is_deleted,
        });
      }
    });

    // Filter Main
    let _filter = _arr_main.filter((record) => {
      let p_module = record?.children?.length > 0 ? record?.children : [];
      let _arr_filter_1 = [];

      let _filtered = p_module.filter((_detail) => {
        if (_sub_metadata.includes(_detail?.id)) {
          return _arr_filter_1.push({
            label: _detail?.name,
            title: _detail?.name,
            value: _detail?.id,
            code: _detail?.code,
            id_module: _detail?.id_module,
            key: _detail?.id.toString(),
            id: _detail?.id,
            parent_id: _detail?.parent_id,
            children: _detail?.children,
            is_check: true,
            disabled: _detail?.is_deleted,
          });
        } else {
          return _arr_filter_1.push({
            label: _detail?.name,
            title: _detail?.name,
            value: _detail?.id,
            code: _detail?.code,
            id_module: _detail?.id_module,
            key: _detail?.id.toString(),
            id: _detail?.id,
            parent_id: _detail?.parent_id,
            children: _detail?.children,
            is_check: false,
            disabled: _detail?.is_deleted,
          });
        }
      });

      let _sorted = _arr_filter_1.sort((a, b) => a.id - b.id);
      _arr_sub.push({ ...record, children: _sorted });
    });

    console.log("To Grand Parent: ", _arr_sub);

    if (_arr_sub.length > 0) {
      handleGrandChildren(_arr_sub);
    } else {
      setDataItems([]);
      setLoading(false);
    }
  };

  // Handle Map Grand Children
  const handleGrandChildren = (val) => {
    const _arr = val;
    const _child_metadata = grandmodules?.length > 0 ? grandmodules : [];
    // [
    //   47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64,
    //   65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
    //   83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    // ];

    // Array temp for Parent & Child
    let _parent = [];
    let _child = [];

    // Last Parent ID
    let idp = 0;
    let idc = 0;

    let _filter_grand = _arr.filter((record) => {
      let child = record?.children?.length > 0 ? record?.children : [];

      let _filterChild = child.filter((rec) => {
        let _grand = rec?.children?.length > 0 ? rec.children : [];
        let _arr_grand = [];

        let _filtGrand = _grand.filter((_rec) => {
          // console.log(`Grand-Childrens ${_rec.name.toUpperCase()}`, _rec);

          if (_child_metadata.includes(_rec?.id)) {
            // console.log("Grand Children is check: ", _rec);
            return _arr_grand.push({
              is_check: true,
              title: _rec?.name,
              key: _rec?.id.toString(),
              id: _rec?.id,
              label: _rec?.name,
              value: _rec?.id,
              id_module: _rec?.id_module,
              disabled: _rec?.is_deleted,
            });
          } else {
            return _arr_grand.push({
              is_check: false,
              title: _rec?.name,
              key: _rec?.id.toString(),
              id: _rec?.id,
              label: _rec?.name,
              value: _rec?.id,
              id_module: _rec?.id_module,
              disabled: _rec?.is_deleted,
            });
          }
        });

        // console.log(`Array Grand ${rec.label} & ${rec.id}`, _arr_grand);
        if (_arr_grand.length > 0) {
          let _sorted = _arr_grand.sort((a, b) => a.id - b.id);

          // if (idc !== record.id) {
          //   idc = record.id;
          if (rec.parent_id === record.id) {
            // console.log(`Result Sorting: `, _sorted);
            //     console.log(`Parent: `, record);
            //     console.log(`Children: `, rec);
            // console.log(`Grand Children: `, _sorted);
            return _child.push({
              ...rec,
              children: _sorted,
            });
          }
        } else {
          return _child.push({
            children: [],
            ...rec,
          });
        }
        // }
      });
    });

    console.log("Children: ", _child);
    // console.log("Parents: ", _parent);

    if (_child.length > 0) {
      handleCombine(_arr, _child);
    } else {
      setDataItems([]);
      setLoading(false);
    }
  };

  // Handle Filter Sub-Module
  const handleCombine = (par, chil) => {
    const _arr_parents = par;
    const _arr_child = chil;

    // console.log("Parents Array: ", _arr_parents);
    // console.log("Children Mapped Array: ", _arr_child);

    const _merge = _arr_parents.map((_parent) => {
      const _children = _arr_child.filter(
        (nums) => nums["parent_id"] === _parent["id"]
      );

      if (!_children.length) {
        _parent.children = _children;
        return _parent;
      }

      _parent.children = _children.map((num) => ({ ...num }));
      return _parent;
    });

    console.log("Merged: ", _merge);

    if (_merge?.length > 0) {
      handleGenerate(_merge);
    } else {
      setDataItems([]);
      setLoading(false);
    }
  };

  // Handle Generate
  const handleGenerate = (val) => {
    const _arr = val;
    console.log("Before Mapped to TABS: ", _arr);
    // handleFilter(val);

    let _map = _arr.map((value) => {
      if (value?.id === 6) {
        // console.log("Value: ", value);

        return {
          label: value?.label,
          key: value?.id, //.toString(),
          forceRender: true,
          disabled: value?.disabled,
          children: (
            <>
              <Form.Item
                key={value?.id_module}
                //   label={`${value?.label}`}
                name={`${value?.id}`}
                valuePropName="checked"
                initialValue={value?.is_check}
                style={{
                  padding: 30,
                }}
              >
                <Checkbox
                  key={value?.id}
                  className="method-check"
                  onChange={handleChange}
                  disabled={value?.disabled}
                  // checked={value?.is_check}
                  // defaultChecked={value?.is_check}
                >
                  {value?.label}
                </Checkbox>
              </Form.Item>
            </>
          ),
        };
      } else {
        return {
          label: value?.label,
          key: value?.id, //.toString(),
          forceRender: true,
          disabled: value?.disabled,
          children: (
            <>
              <List
                className="sub-module-list"
                key={value?.id_module}
                itemLayout="horizontal"
                dataSource={value?.children}
                grid={{
                  gutter: 8,
                  // column: 2,
                  xs: 1,
                  sm: 1,
                  md: 2,
                  lg: 2,
                  xl: 3,
                  xxl: 3,
                }}
                renderItem={(submodule) => {
                  //   let submodule = submod?.p_module;
                  // console.log("Value: ", submodule?.children);
                  // console.log(
                  //   "Sub-Module: ",
                  //   submodule?.is_check,
                  //   " id: ",
                  //   submodule?.id
                  // );

                  if (submodule?.children?.length > 0) {
                    return (
                      <>
                        {`${submodule.label}:`}

                        <List
                          className="grand-module-list"
                          key={submodule?.id_module}
                          itemLayout="horizontal"
                          dataSource={submodule.children}
                          grid={{
                            column: 1,
                          }}
                          style={{
                            margin: "0px 0px 15px",
                          }}
                          renderItem={(grand) => {
                            return (
                              <>
                                <Form.Item
                                  key={grand?.id_module}
                                  // label={`${grand?.label}`}
                                  initialValue={grand?.is_check}
                                  name={[
                                    `${value?.id}`,
                                    `${submodule?.id}`,
                                    `${grand?.id}`,
                                  ]}
                                  valuePropName="checked"
                                  noStyle
                                >
                                  <Checkbox
                                    className="method-check"
                                    key={grand?.id}
                                    onChange={handleChangeGrand}
                                    disabled={grand?.disabled}
                                    // checked={grand?.is_check}
                                  >
                                    {grand.label}
                                  </Checkbox>
                                </Form.Item>
                              </>
                            );
                          }}
                        />
                        {/* <Tree
                            className="mods-tree"
                            checkable={true}
                            autoExpandParent={true}
                            multiple={true}
                            showLine
                            onExpand={onExpand}
                            onCheck={handleChange}
                            treeData={submodule?.children}

                          >
                            {submodule.label}
                          </Tree> */}
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Form.Item
                          key={submodule?.id_module}
                          // label={`${submodule?.label}`}
                          initialValue={submodule?.is_check}
                          name={[`${value?.id}`, `${submodule?.id}`]}
                          valuePropName="checked"
                        >
                          <Checkbox
                            className="method-check"
                            key={submodule?.id}
                            onChange={handleChange}
                            disabled={submodule?.disabled}
                            // checked={submodule?.is_check}
                            // defaultChecked={submodule?.is_check}
                          >
                            {submodule?.label}
                          </Checkbox>
                        </Form.Item>
                      </>
                    );
                  }
                }}
                style={{
                  padding: 30,
                }}
              />
            </>
          ),
        };
      }
    });

    // console.log("Hasil Map: ", _map);

    if (_map?.length > 0) {
      // console.log("Time Map: ", moment().format("HH:mm:ss. SSS, DD-MM-YYYY"));

      setDataItems(_map);
      setActiveKey(_map[0]?.key);
      setFormValues(_arr);

      setLoading(false);
    } else {
      setDataItems([]);
      setFormValues([]);
    }
  };

  // Handle Change
  const handleChange = (value, index) => {
    const check = value?.target?.checked ? value.target.checked : value;

    console.log("Check: ", check);
    // console.log("Index: ", index);
  };
  const handleChangeGrand = (value, index) => {
    const check = value?.target?.checked ? value.target.checked : value;

    // console.log("Check Grand: ", check);
    // console.log("Index: ", index);
  };

  const onChangeAll = (list) => {
    console.log("Index list: ", list);
    setCheckedList(list);
    // setIndeterminate(!!list.length && list.length < plainOptions.length);
    // setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    const _value = e.target.checked ? e.target.value : [];
    console.log("Check All: ", _value);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <>
      <Col span={24} className="tabs-col">
        {!loading && dataItems.length > 0 ? (
          <Tabs
            className="module-tabs"
            key="module-tabs"
            type="card"
            onChange={onChange}
            name="module-tabs"
            items={dataItems}
            activeKey={activeKey}
            placeholder="Tabs Module"
            tabPosition="left"
            style={{
              border: "1px solid #EBEDF3",
            }}
          />
        ) : !loading && dataItems.length === 0 ? (
          <ResultError is_refresh={handleRefresh} />
        ) : (
          <InputSkeleton />
        )}
        {/* <SyncOutlined onClick={fetchData} /> */}
      </Col>
    </>
  );
}

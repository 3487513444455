// REACT COMPONENTS
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { Button, Col, Layout, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// REACT ICONS
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaClipboardList } from "react-icons/fa";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../Style/Global/Global";

// PAGE COMPONENTS
import MasterArticleMain from "../../../../../Components/Reusable/Master/ArticleMain/ArticleMain";
import MinimumOnHandTable from "../../../../../Components/Table/Reports/BackOffice/Inventory/MinimumOnHand/Table";
import ArticleVendor from "../../../../../Components/Reusable/Master/ArticleVendor/ArticleVendor";
import { SearchOutlined } from "@ant-design/icons";

export default function MinimumOnHandPage() {
  // STATE
  const [mainGroup, setMainGroup] = useState(null);
  const [article, setArticle] = useState(null);
  const [search, setSearch] = useState();

  // NAVIGATE
  const navigate = useNavigate();

  // HANDLE RETURN
  const handleReturn = () => {
    const path = "/report/back-office/inventory-report";

    navigate(`${path}`);
  };

  // HANDLE GET ARTICLE MAIN GROUP
  const handleGetArticleMain = (value) => {
    console.log("VALUE ARTICLE MAIN GORUp : ", value);
    setMainGroup(value);
  };

  // HANDLE SELECTED ARTICLE
  const handleGetSelectedArticle = (value) => {
    console.log("Selected Article : ", value);
    setArticle(value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="minimum-on-hand-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <FaClipboardList
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Minimum On Hand List Reports`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  minWidth: 250,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Select Article Main Group`}</Typography>

                <MasterArticleMain
                  getArticleMain={handleGetArticleMain}
                  mainName={null}
                  isDisabled={false}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                  marginRight: 15,
                  width: 250,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Article Name`}</Typography>

                <ArticleVendor
                  selectedArticle={handleGetSelectedArticle}
                  article_name={null}
                  is_disable={false}
                />
              </Col>

              <Col
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>

              {/* <Col
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Col> */}
            </Space>
          </Row>

          {/* {mainGroup ? ( */}
          <MinimumOnHandTable
            is_search={setSearch}
            mainGroupId={mainGroup?.main_id}
            articleNumber={article?.article_number}
          />
          {/* ) : null} */}
        </Col>
      </Content>
    </Layout>
  );
}

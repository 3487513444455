// REACT COMPONENTS
import React, { useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { SearchOutlined } from "@ant-design/icons";

// REACT ICONS
import { BsFillClipboardCheckFill } from "react-icons/bs";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../Style/Global/Global";

// PAGE COMPONENTS
import StockTransferRequestApprovalTable from "../../../../Components/Table/BackOffice/Inventory/TransferRequest/StockTransferRequestApproval/Table";

export default function StockTransferRequestApprovalPage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [postDate, setPostDate] = useState(null);
  // SEARCH
  const [search, setSearch] = useState();

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;

    console.log("Search Value: ", value);

    setValue(value.trim());
  };

  // POSTING DATE
  const handleDate = (val) => {
    const value = moment(val).format("YYYY-MM-DD");
    console.log("Posting Date: ", value);

    setPostDate(value);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="ooo-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <BsFillClipboardCheckFill
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Approval Stock Transfer/Request`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 150,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Document Number`}</Typography>

                  <Input
                    allowClear
                    placeholder="Keyword"
                    className="search-input"
                    onChange={handleSearch}
                    style={{
                      minWidth: 150,
                      maxWidth: 250,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 200,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Posting Date`}</Typography>

                  <DatePicker
                    allowClear
                    placeholder="Posting Date"
                    className="pr-datepicker"
                    onChange={handleDate}
                    style={{
                      minWidth: 200,
                      maxWidth: 250,
                    }}
                  />
                </Col>

                <Col
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="search-btn"
                    type="primary"
                    onClick={search}
                    icon={<SearchOutlined />}
                  >
                    {`Search`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <StockTransferRequestApprovalTable
              is_search={setSearch}
              searchKey={value}
              posting_date={postDate}
            />
          </Col>
        </Content>
      </Layout>
    </>
  );
}

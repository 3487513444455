// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// AUTH
import {
  user_name,
  roles,
  department,
  token,
} from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Popover,
  Tooltip,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FiEdit } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";
import {
  masterIncomplete,
  masterSuccessNotification,
  masterFailedNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

import downloadExcel from "../../../Reusable/Functions/ExportXLSX/ExportExcel";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Const Components
const { confirm } = Modal;

// CODE
export default function CountryTable(props) {
  // PROPS
  const { is_search, searchKey } = props;

  // Table Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 125,
      render: (_, record) => {
        return (
          <>
            <Row className="action-guest-table-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO EDIT > ", record);

                      // setOpen(true);
                      handleOpen();
                      setEdit(true);
                      setDataEdit(record);

                      setFormField(record);
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>

              <Col span={12}>
                <Tooltip title="Delete Data" className="del-tooltip">
                  <DeleteFilled
                    className="delete-btn"
                    onClick={() => {
                      console.log("ABOUT TO DELETE > ", record);

                      showModalConfirm(record);
                    }}
                    style={{ fontSize: 20, color: "#F64E60" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Code ISO-3",
      dataIndex: "countries_iso3",
      key: "countries_iso3",
      // width: 250,
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Code",
      dataIndex: "countries_iso",
      key: "countries_iso",
      sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.price.localeCompare(b.price),
    },
  ];

  // CONTEXT
  const { getMasterCountry } = useContext(MasterContext);

  // Navigate
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  //const [dataSource, setDataSource] = useState([]);
  // ID Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchData = async () => {
    await getMasterCountry({
      country: "countries",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Country: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt?.is_deleted;
          });

          setData(filtered);
          setFilteredList(filtered);
        } else {
          setData([]);
          setFilteredList([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error = ", error);

        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // Modal Open Set State
  const handleOpen = () => {
    setIsDelete(false);
    setOpen(true);
  };

  // Set Form Fields
  const setFormField = (val) => {
    const _record = val;

    form.setFieldsValue({
      countries_iso: _record.countries_iso,
      country: _record.country,
      countries_printable_name: _record?.countries_printable_name,
      countries_iso3: _record?.countries_iso3,
      num_code: _record.num_code,

      created_by: _record?.created_by,
      created_date: _record?.created_date,
      updated_by: _record?.updated_by,
      updated_date: _record?.updated_date,
    });
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    setEdit(false);
    setDataEdit(null);

    setIsDelete(true);

    setIsLoading(false);

    form.resetFields();
  };

  // SHOW MODAL DELETE
  const showModalConfirm = (record) => {
    const country = record;

    confirm({
      className: "modal-confirm",
      title: isDelete
        ? `Are you sure want to Delete Country named ${
            country?.country.toUpperCase() || " --- "
          } from the table?`
        : `Are you sure want to ${
            edit ? "Update " : "Add a New "
          } Country with named ${country?.country.toUpperCase() || " --- "}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        isDelete ? handleDelete(country) : handleSubmit(country);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    setIsLoading(true);
    console.log("About to Submit == ", value);

    // handleSubmit(value);

    showModalConfirm(value);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    console.log("Failed Input == ", value?.values);

    masterIncomplete(value);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const submit = value;
    console.log("SUBMITTED ==> ", submit);

    if (edit == true) {
      await axios
        .put(
          `${baseurl}/master/countries`,
          {
            countries_iso: submit.countries_iso || dataEdit?.countries_iso,
            country: submit.country || dataEdit?.country,
            countries_printable_name: submit.countries_printable_name || null,
            countries_iso3: submit.countries_iso3,
            num_code: submit.num_code,
            updated_by: user_name,

            id: dataEdit?.id,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Add Country => ", response);
          masterSuccessNotification(response, { method: 1, source: "Country" });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("Add Country => ", error);

          masterFailedNotification(error, { method: 1, source: "Country" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .post(
          `${baseurl}/master/countries`,
          {
            countries_iso: submit.countries_iso,
            country: submit.country,
            countries_printable_name: submit.countries_printable_name || null,
            countries_iso3: submit.countries_iso3,
            num_code: submit.num_code,

            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token ? token : ""}` } }
        )
        .then((response) => {
          console.log("Update Country => ", response);
          masterSuccessNotification(response, { method: 0, source: "Country" });

          handleCancel();
          fetchData();
        })
        .catch((error) => {
          console.log("Update Country => ", error);

          masterFailedNotification(error, { method: 0, source: "Country" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // HANDLE DELETE
  const handleDelete = async (record) => {
    const contentdelete = record.id;

    console.log(contentdelete);

    var obj = {
      ["id"]: contentdelete,
      ["deleted_by"]: user_name,
    };

    await axios
      .delete(`${baseurl}/master/countries`, {
        data: obj,
        headers: { Authorization: `Bearer ${token ? token : ""}` },
      })
      .then((response) => {
        console.log("Del Country = ", response);

        masterSuccessNotification(response, { method: 2, source: "Country" });
        fetchData();
      })
      .catch((error) => {
        console.log("Del Country = ", error);

        masterFailedNotification(error, { method: 2, source: "Country" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE EXPORT
  const handleExport = () => {
    downloadExcel(data, "Country List");
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/configuration/master";

    navigate(`${path}`);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    // console.log("Type = ", query);

    if (data.length > 0) {
      let updatedList = data.filter((item) => {
        const _name = item?.country.toLowerCase();

        // console.log("ITEM => ", item)

        return _name.indexOf(query) !== -1;
      });

      setFilteredList(updatedList);
    } else {
      setFilteredList([]);
    }
  };

  return (
    <>
      <Row
        className="article-inventory-main-table"
        justify="space-around"
        style={mainBody}
      >
        <Col
          span={24}
          className="col-table"
          style={{ width: "100%", padding: 30 }}
        >
          <Row
            className="row-table-btn"
            justify="start"
            align="top"
            gutter={[0, 30]}
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
            >
              <Row justify="start">
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="col-modal-btn"
            >
              <Row justify="end" gutter={[0, 15]}>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleOpen}
                  icon={<PlusOutlined />}
                >
                  {`Add Country`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Refresh`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleReturn}
                  style={{
                    margin: "0px 0px 0px 15px",
                  }}
                >
                  {`Return`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="country-table"
              name="country-table"
              key="country-table"
              loading={loading}
              bordered
              columns={columns}
              dataSource={filteredList}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 25, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>

      {/* <Col span={12}>
          <div
            className="col-region"
            style={{
              background: "#FFFFFF",
              padding: 30,
              height: "100%",
            }}
          >
            <Row justify="end">
              <Col>
                <Row>
                  <Popover
                    content={popover1}
                    title="Add Region"
                    trigger="click"
                  >
                    <Button
                      className="submit-btn"
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{
                        backgroundColor: "#1BC5BD",
                        borderColor: "#1BC5BD",
                        marginRight: 15,
                      }}
                    >
                      Add Region
                    </Button>
                  </Popover>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginTop: 30, width: "100%" }}>
              <Table
                loading={loading}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                columns={columns2}
                dataSource={dataSource2}
                //onChange={onChange}
                pagination={{
                  pageSize: 5,
                }}
                // scroll={{
                //     y: 270,
                // }}
                rowKey={(record) => (record.id ? record.id : record.key)}
              />
            </Row>
          </div>
        </Col> */}
      {/* </Row> */}

      <Modal
        className="add-edit-country-modal"
        title={
          edit == false ? (
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Add Country`}</Typography>
            </Row>
          ) : (
            <Row>
              <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Edit Country`}</Typography>
            </Row>
          )
        }
        forceRender={true}
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="country-modal-form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-country-id">
            <Col span={8}>
              <Form.Item
                label="Country Code"
                name="countries_iso"
                rules={[
                  {
                    required: true,
                    max: 2,
                    message: "Please, Input a Code!",
                  },
                  {
                    validator: async (_, code) => {
                      // console.log("Code: ", code, code?.length);

                      if (code?.length > 2) {
                        return Promise.reject(
                          "Country Code cannot be longer than 2 characters!"
                        );
                      }
                    },
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Country ISO" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Country Name"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please, Input the name of the country!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Country Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-country-iso3">
            <Col span={8}>
              <Form.Item
                label="Printable Name"
                name="countries_printable_name"
                style={{ width: "100%" }}
              >
                <Input placeholder="Printable Name" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="ISO-3 Code"
                name="countries_iso3"
                rules={[
                  {
                    required: true,
                    max: 2,
                    message: "Country Code cannot be longer than 2 characters!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="ISO-3 Code" />
              </Form.Item>
            </Col>

            {/* <Row>
              <Form.Item
                label="Number Code"
                name="num_code"
                style={{ marginRight: 30, width: "100%" }}
              >
                <Input placeholder="Number Code" />
              </Form.Item>
            </Row> */}
          </Row>

          <Divider
            className="form-divider"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          {edit == true ? (
            <>
              <Row gutter={30} className="created_date-country">
                <Col span={8}>
                  <Form.Item
                    label="Created By"
                    name="created_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created by" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Created Date"
                    name="created_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Created Date" disabled />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Modified By"
                    name="modified_by"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified by" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="mod-date-country">
                <Col span={8}>
                  <Form.Item
                    label="Modified Date"
                    name="modified_date"
                    style={{ marginRight: 30, width: "100%" }}
                  >
                    <Input placeholder="Modified Date" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
              />
            </>
          ) : null}

          <Row
            className="country-modal-btn"
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

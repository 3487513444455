// Import React Components
import React, { useState, useEffect } from "react";

// Import Ant Design Components
import { Select } from "antd";

// CODE
export default function MasterBoolean({ getBoolValue, bool }) {
  // STATE MANAGEMENT
  const [value, setValue] = useState(0);

  // Options Value
  const options = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  useEffect(() => {
    if (bool == 0) {
      console.log(bool);
      setValue(0);
    } else {
      setValue(1);
    }
  }, [bool]);

  return (
    <>
      <Select
        className="master-bool"
        name="master-bool"
        key={options.label}
        fieldNames={{ label: "label", value: "value" }}
        value={bool ? bool : value}
        onChange={(value) => {
          console.log("Value Bool => ", value);
          setValue(value);

          getBoolValue(value);
        }}
        options={options}
      />
    </>
  );
}

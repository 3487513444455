// REACT COMPONENTS
import React, { useEffect, useState } from "react";

// ANTD COMPONENTS
import { Col, Form, Input, InputNumber, Row, Table, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";

// REACT ICONS
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

// PAGE COMPONENTS
import MasterMainAccount from "../../../../../Reusable/Accounting/MainAccount/MasterCoa";

// GLOBAL API
import { user_level } from "../../../../../../API/Global/Payload";

export default function StockTransferRequestReceivingDetailTable(props) {
  // PROPS
  const { article, getEditDetail } = props;

  // STATE
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  // FORM
  const [form] = useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (article?.length > 0 && !cleanUp) {
      setData(article);
    } else {
      setData([]);
    }
  }, [article]);

  const isEditing = (record) =>
    record.id_warehouse_transfer_detail === editingKey;

  const cancel = () => {
    setEditingKey("");
  };

  const edit = (record) => {
    console.log("key: ", record);

    form.setFieldsValue({
      quantity_received: record.quantity_received
        ? record.quantity_received
        : record.quantity_approved,
      ...record,
    });

    setEditingKey(record.id_warehouse_transfer_detail);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];

      console.log("Check Key in save >> ", key);
      console.log("Check Row in save >> ", row);
      console.log("Check Data in save >> ", data);

      const index = newData.findIndex((item) => {
        // console.log(item);
        return (
          key.id_warehouse_transfer_detail === item.id_warehouse_transfer_detail
        );
      });
      console.log("Check Index in save >> ", index);

      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
          quantity_received: row.quantity_received
            ? row.quantity_received
            : row.quantity_approved,
        });

        console.log("Edited: ", newData);
        setData(newData);
        getEditDetail({ ...item, ...row });
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        getEditDetail(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // EDITABLE TABLE CELL
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    // console.log("Record Editable Cell : ", record);
    const inputNode =
      inputType === "number" ? (
        <InputNumber min={0} max={record.quantity_approved} />
      ) : (
        <Input />
      );

    let childNode = children;

    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      children
    );

    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 75,
      fixed: "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Row className="action-btn" style={{ width: "100%" }}>
              <Col span={12}>
                <FaCheckCircle
                  className="edit-btn"
                  onClick={() => {
                    // handleSave(record);
                    save(record);
                  }}
                  style={{
                    fontSize: 20,
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                />
              </Col>

              <Col>
                <FaTimesCircle
                  className="delete-btn"
                  onClick={cancel}
                  style={{ fontSize: 20, color: "#F64E60", cursor: "pointer" }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              className="action-guest-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={12}>
                <Tooltip title="Edit Data" className="edit-tooltip">
                  <FiEdit
                    className="edit-btn"
                    disabled={editingKey !== ""}
                    onClick={() => edit(record)}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Article Number",
      dataIndex: "article_number",
      key: "article_number",
    },
    // {
    //   title: "Chart of Account",
    //   dataIndex: "chart_of_account",
    //   key: "chart_of_account",
    //   width: 150,

    //   render: (chart_of_account) => {
    //     return chart_of_account ? chart_of_account : "-";
    //   },
    // },
    {
      title: "Stock On Hand",
      dataIndex: "delivery_stock",
      key: "delivery_stock",
    },
    {
      title: "Price (Rp.)",
      dataIndex: "price",
      key: "price",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Qty Req",
      dataIndex: "quantity_requested",
      key: "quantity_requested",
      render: (quantity_requested, record) => {
        return `${quantity_requested}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Qty Approved",
      dataIndex: "quantity_approved",
      key: "quantity_approved",

      render: (quantity_approved, record) => {
        return quantity_approved !== null
          ? `${quantity_approved}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : `${record.quantity_requested}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            );
      },
    },
    {
      title: "Qty Received",
      dataIndex: "quantity_received",
      key: "quantity_received",
      editable: true,
      render: (quantity_received, record) => {
        return quantity_received
          ? `${quantity_received}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : `${record.quantity_approved}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Total Amount (Rp.)",
      dataIndex: "total_price",
      key: "total_price",
      sortDirections: ["descend", "ascend"],
      render: (total_price, record) => {
        return `${total_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  // COLUMNS EDITABLE
  const columnz = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    // console.log("Col editable: ", col);

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "quantity_received" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={components}
          bordered
          dataSource={data}
          columns={columnz}
          size="small"
          pagination={{
            onChange: cancel,
            defaultPageSize: 5,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} entries`,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          scroll={{
            x: 1200,
          }}
          rowKey={(record) => {
            return record?.article_name ? record.article_name : record.id;
          }}
        />
      </Form>
    </>
  );
}

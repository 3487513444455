// REACT COMPONENTS
import React, { useState } from "react";

// ANTD COMPONENTS
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../../../../Style/Global/Global";

// REACT ICONS
import { TbTruckReturn } from "react-icons/tb";

// PAGE COMPONENTS
import MasterVendor from "../../../../../../Components/Reusable/Master/Vendor/MasterVendor";
import StockArticleReturnTable from "../../../../../../Components/Table/Reports/BackOffice/Inventory/StockArticleReturn/Table";

export default function StockArticleReturnReportPage() {
  // STATE MANAGEMENT
  // DATA
  const [supplier, setSupplier] = useState(null);
  // Search
  const [search, setSearch] = useState(null);

  // HANDLE SELECTED SUUPLIER
  const handleSelectedSupplier = (value) => {
    console.log("Selected Supplier Value: ", value);
    setSupplier(value);
  };
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <TbTruckReturn
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Stock Article Return List Reports`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Col
                style={{
                  textAlign: "start",
                  margin: "22px 15px 22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                  width: 250,
                  marginRight: 25,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Supplier`}</Typography>

                <MasterVendor
                  selectedSupplier={handleSelectedSupplier}
                  supplier_name={null}
                  is_disable={false}
                />
              </Col>

              <Col
                className="search-btn-col"
                style={{
                  margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  type="primary"
                  className="search-btn"
                  icon={<SearchOutlined />}
                  onClick={search}
                >
                  {`Search`}
                </Button>
              </Col>
            </Row>

            <StockArticleReturnTable
              is_search={setSearch}
              searchKey={supplier?.id_supplier}
            />
          </Col>
        </Content>
      </Layout>
    </>
  );
}
